import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
// import { UserDetail } from "src/app/models/user-detail";
import { AccountServiceService } from "src/app/services/account-service.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";

@Component({
  selector: "app-account-verification",
  templateUrl: "./account-verification.component.html",
  styleUrls: ["./account-verification.component.less"],
})
export class AccountVerificationComponent implements OnInit {
  private token: string;
  public userDetail: any; // UserDetail
  public error: any;

  // Account Verification Steps
  public steps: AccountVerificationStep[] = [
    {
      name: "Email",
      completed: false,
    },
    {
      name: "SMS",
      completed: false,
    },
    {
      name: "Password",
      completed: false,
    },
  ];
  public stepNumber = 1;
  public requestStatus: "requesting" | "success" | "failed";

  public accountVerificationFormGroup: FormGroup = new FormGroup({
    otp: new FormControl(null, [Validators.required]),
    password: new FormControl(null, [Validators.required]),
    password_confirmation: new FormControl(null, [Validators.required]),
  });

  constructor(private route: ActivatedRoute, private router: Router, private accountService: AccountServiceService) {
    //
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.token = params["token"];

      // if (!this.steps[0].completed) {
      //   this.verifyEmail();
      // }
    });

    this.accountVerificationFormGroup.valueChanges.subscribe((value: any) => {
    });

    this.userDetail = JSON.parse(sessionStorage.getItem("userDetail"));


    if (this.userDetail) {
      // Check if the account is already verified.

      this.steps[0].completed = this.userDetail.emailActivationDate;
      this.steps[1].completed = this.userDetail.mobileActivationDate;
      this.steps[2].completed = this.userDetail.isPasswordSet;

      if (!this.steps[0].completed) {
        this.verifyEmail();
      }

      if (this.isVerificationComplete()) {
        this.stepNumber = 3;
        return;
      }

      if (this.userDetail.emailActivationDate && this.userDetail.mobileActivationDate) {
        // TO-DO, verify password as well. Maybe via a verified_at field?
        this.steps[0].completed = true;
        this.steps[1].completed = true;
        this.goToStep(3);
      }
    } else {
      this.verifyEmail();
    }
  }

  /**
   * Verify the user's email address via API.
   */
  public verifyEmail(): void {

    this.requestStatus = "requesting";

    this.accountService.verifyEmail(this.token).subscribe(
      (response: any) => {
        this.requestStatus = "success";

        this.userDetail = response.data.details.details;
        sessionStorage.setItem("userDetail", JSON.stringify(this.userDetail));

        this.steps[this.stepNumber - 1].completed = true;
        // this.stepNumber = 2; // Automatically proceed to next step.
      },
      (errorResponse: any) => {
        this.requestStatus = "failed";


        // this.error = errorResponse;

        if (errorResponse.error) {
          // Handle parameter errors
          if (errorResponse.error.errors) {
            this.error = errorResponse.error.errors;

            // Implicitly conceal the account verification route when there are parameter errors.
            // this.router.navigate(["/404"]);
          }

          // Handle supposed HTTP 409 error, where the email is already verified.
          if (errorResponse.error.result) {
            if (errorResponse.error.result.code.includes("ActivatedAlready")) {
              // We use includes() to check because the API has excess space.
              this.steps[this.stepNumber - 1].completed = true;
              this.stepNumber = 2; // Automatically proceed to next step.
              return;
            }
          }

          // Unknown error
          if (!errorResponse.error.errors && !errorResponse.error.result) {
            this.error = "A server error occured. Please check back later or contact support.";
          }
        }

        // Unknown error
        // ...
      }
    );
  }

  /**
   * Verify the user's SMS via API.
   */
  public verifySMS(): void {

    this.accountService
      .verifySMS(this.token, this.accountVerificationFormGroup.get("otp").value)
      .pipe(
        finalize(() => {
        })
      )
      .subscribe(
        (response: any) => {
          this.requestStatus = "success";


          this.steps[this.stepNumber - 1].completed = true;
          this.stepNumber = 3; // Automatically proceed to next step.
          this.userDetail.mobileActivationDate = true;
          sessionStorage.setItem("userDetail", JSON.stringify(this.userDetail));
        },
        (errorResponse: any) => {
          this.requestStatus = "failed";

          // this.error = errorResponse;

          if (errorResponse.error) {
            // Handle parameter errors
            if (errorResponse.error.errors) {
              this.error = errorResponse.error.errors;

              // Implicitly conceal the account verification route when there are parameter errors.
              // this.router.navigate(["/404"]);
            }

            // Handle supposed HTTP 409 error, where the OTP is already verified.
            if (errorResponse.error.result) {
              if (errorResponse.error.result.code.includes("ActivatedAlready")) {
                // We use includes() to check because the API has excess space.
                this.steps[this.stepNumber - 1].completed = true;
                this.stepNumber = 3; // Automatically proceed to next step.
                return;
              }
              if (errorResponse.error.result.succeeded) {
                this.steps[this.stepNumber - 1].completed = true;
                this.stepNumber = 3; // Automatically proceed to next step.
                return;
              } else {
                this.error = errorResponse.error.result.message;
                return;
              }
            }

            // Unknown error
            if (!errorResponse.error.errors && !errorResponse.error.result) {
              this.error = "A server error occured. Please check back later or contact support.";
            }
          }

          // Unknown error
          // ...
        }
      );
  }

  /**
   * Submit the user's password via API.
   */
  public resetPassword(): void {

    this.requestStatus = "requesting";

    this.accountService
      .resetPassword(this.token, this.accountVerificationFormGroup.get("password").value)
      .pipe(
        finalize(() => {
        })
      )
      .subscribe(
        (response: any) => {
          this.requestStatus = "success";


          this.steps[this.stepNumber - 1].completed = true;
          this.requestStatus = "success";
          this.error = null;

          this.userDetail.isPasswordSet = true;
          sessionStorage.setItem("userDetail", JSON.stringify(this.userDetail));
        },
        (errorResponse: any) => {
          this.requestStatus = "failed";

          

          // this.error = errorResponse;

          if (errorResponse.error) {
            // Handle parameter errors
            if (errorResponse.error.errors) {
              this.error = errorResponse.error.errors;

              // Implicitly conceal the account verification route when there are parameter errors.
              // this.router.navigate(["/404"]);
            }

            // Handle supposed HTTP 409 error, where the email is already activated.
            if (errorResponse.error.result) {
              if (!errorResponse.error.result.succeeded) {
                // We use includes() to check because the API has excess space.
                // this.steps[this.stepNumber - 1].completed = true;
                // this.stepNumber = 2; // Automatically proceed to next step.
                // return;
                this.error = errorResponse.error.result.message;
              }
            }

            // Unknown error
            if (!errorResponse.error.errors && !errorResponse.error.result) {
              this.error = "A server error occured. Please check back later or contact support.";
            }
          }

          this.requestStatus = "failed";

          // Unknown error
          // ...
        }
      );
  }

  /**
   * Go to the given step number.
   *
   * @param stepNumber The step number to go to.
   */
  public goToStep(stepNumber: number): void {
    // Do the operation for the current step.

    // Do nothing if verification is already completed, assuming the user is already in the last step.
    if (this.isVerificationComplete()) {
      return;
    }

    this.error = null;

    if (stepNumber > this.stepNumber) {
      if (this.steps[this.stepNumber - 1].name == "Email") {
        this.verifyEmail();
      }

      if (this.steps[this.stepNumber - 1].name == "SMS" && !this.steps[this.stepNumber - 1].completed) {
        this.verifySMS();
      }

      if (this.steps[this.stepNumber - 1].name == "Password") {
        this.resetPassword();
      }
    }

    if (stepNumber > this.stepNumber) {
      if (this.steps[stepNumber] != undefined) {
        if (this.steps[this.stepNumber].completed) {
          this.stepNumber = stepNumber;
        }
      }
    } else {
      if (this.steps[stepNumber] != undefined) {
        this.stepNumber = stepNumber;
      }
    }
  }

  public isPreviousButtonEnabled(): boolean {
    return this.stepNumber - 1 >= 1;
  }

  public isNextButtonEnabled(): boolean {
    // return this.stepNumber + 1 <= this.steps.length;

    return (
      (this.stepNumber + 1 <= this.steps.length && this.steps[this.stepNumber].completed == true) ||
      this.accountVerificationFormGroup.get("otp").valid ||
      this.steps[this.stepNumber - 1].completed
    );
  }

  public isVerificationComplete(): boolean {
    for (let i = 0; i < this.steps.length; i++) {
      if (this.steps[i].completed == false) {
        return false;
      }
    }

    return true;
  }

  public typeOfVariable(variable: any): any {
    return typeof variable;
  }
}

interface AccountVerificationStep {
  name: string;
  completed: boolean;
}
