import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { CompanyUsersService } from "src/data/services/web/company-users.service";
import { ModalConfirmationComponent } from "../modal-confirmation/modal-confirmation.component";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.less"],
})
export class ForgotPasswordComponent implements OnInit {
  public userFormGroup: FormGroup;
  public emptyString = "";

  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public bsModalRefConfirmation: BsModalRef;
  public modalService: BsModalService;
  public roleLevelId: any;
  public roleLevelName: any;
  public verifyingForgotPassword = false;
  constructor(
    private router: Router,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    modalService: BsModalService,
    private route: ActivatedRoute
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.modalService = modalService;
  }

  ngOnInit() {
    this.initUserFormGroup();
    this.localStorageCacheService.removeStorage("email_address");
    this.localStorageCacheService.removeStorage("access_token");
    this.localStorageCacheService.removeStorage("refresh_token");
  }

  initUserFormGroup(): void {
    this.userFormGroup = new FormGroup({
      email: new FormControl(this.emptyString, Validators.required),
    });
  }

  verifyForgotPasswordStatus = false;

  verifyForgotPassword(): void {
    this.verifyingForgotPassword = true;
    const email = this.userFormGroup.controls.email.value;

    this.authService.doResetPassword(email).subscribe(
      (result: any) => {
        // this.errorDialog("Please check your email to reset your password.");
        this.verifyForgotPasswordStatus = true;
        this.verifyingForgotPassword = false;
        // window.location.href = `${route}/dashboard`;
      },
      (error) => {
        this.verifyingForgotPassword = false;
        if (error.status === 401 || error.status === 403 || error.status === 400) {
          this.errorDialog("Account does not exist.");
        }
      }
    );
  }

  errorDialog(message): void {
    const initialState = {
      meta: {
        title: "ForgotPassword",
        message: message,
        btnTitle: "Ok",
        showCancel: false,
      },
    };

    this.bsModalRefConfirmation = this.modalService.show(ModalConfirmationComponent, {
      initialState,
    });

    this.bsModalRefConfirmation.content.clickevent.subscribe((response) => {
      if (response.result) {
        //
      }
    });
  }

  writeUserDetails(): void {
    this.authService.validateAccessToken(this.roleLevelId).then((res) => {});
  }
}
