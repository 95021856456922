import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Select2OptionData } from "ng-select2";
import { BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { CashierUsersService } from "src/data/services/web/cashier-users.service";
import { ManagerUsersService } from "src/data/services/web/manager-users.service";
import { RolesService } from "src/data/services/web/roles.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-cashier-user-list",
  templateUrl: "./cashier-user-list.component.html",
  styleUrls: ["./cashier-user-list.component.less"],
})
export class CashierUserListComponent implements OnInit {
  public cashierUserFilterFormGroup: FormGroup;
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public cashierUsersService: CashierUsersService;
  public managerUsersService: ManagerUsersService;
  public emptyString = "";
  public roles: any;
  public role: any;
  public statuses: any;
  public status: any;
  public modalService: BsModalService;

  public accessToken: string;
  public newCashierUser: boolean;
  public users: any;
  public pageCount: any;
  public addCashierStatus: any = false;

  // Filters
  public listFilterForm = new FormGroup({
    searchString: new FormControl(""),
    searchStringField: new FormControl(""),
  });
  public filterByOptions: Array<Select2OptionData>;
  public filterBy: any;
  public routeRole : any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    cashierUsersService: CashierUsersService,
    modalService: BsModalService,
    managerUsersService: ManagerUsersService,
    private modService: NgbModal
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.cashierUsersService = cashierUsersService;
    this.modalService = modalService;
    this.managerUsersService = managerUsersService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
  }
 roleLevel : any;
  ngOnInit(): void {
    this.roleLevel = this.localStorageCacheService.getStorage("role_level");
    this.routeRole = this.localStorageCacheService.getStorage("role_level") == "2" ? "Manager" : "Admin" ;
    this.isAllowedToAddCashier();
    this.rolesService.getRoles(3).then((roles) => {
      this.roles = roles;
      this.getCashierUsers();
    });

    this.filterByOptions = [
      {
        id: "",
        disabled: false,
        text: "",
      },
      {
        id: "employeeID",
        text: "Employee ID",
      },
      {
        id: "username",
        text: "Username",
      },
      {
        id: "email",
        text: "Email",
      },
      {
        id: "firstName",
        text: "First Name",
      },
      {
        id: "lastName",
        text: "Last Name",
      },
      {
        id: "roleName",
        text: "Role Name",
      },
    ];
  }

  isAllowedToAddCashier() {
    this.managerUsersService.isAllowedToAddCashier().subscribe((result) => {
      this.addCashierStatus = true;
    });
  }

  private _sortBy: any;
  private _sort: any;

  getCashierUsers(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    if (fromSearch) {
      this.cashierId = this.emptyString;
      this.username = this.emptyString;
      this.email = this.emptyString;
      this.firstName = this.emptyString;
      this.lastName = this.emptyString;
      this.roleName = this.emptyString;
    }

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_searchString: string = this.listFilterForm.get("searchString").value
      ? `searchString=${this.listFilterForm.get("searchString").value}&`
      : "";
    const queryString_filterBy: string = this.filterBy ? `filterBy=${this.filterBy}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_searchString + queryString_filterBy;

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/CashierUsers/${this.routeRole}?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/CashierUsers/${this.routeRole}${queryString}`;
      }
    }

    this.cashierUsersService.getCashierUsers(endPointUrl).subscribe((result) => {
   
      if (result.data.length == 0 && result.pageNumber != 1) {
        this.getCashierUsers(result.previousPage, _sortBy, _sort);
      } else {
        this.users = result;
      }
    });
  }

  cashierId = "";
  username = "";
  email = "";
  firstName = "";
  lastName = "";
  roleName = "";
  searchString = "";

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.cashierId = field == "cashierId" ? "th-inner sortable both " + sortBy : "";
    this.username = field == "username" ? "th-inner sortable both " + sortBy : "";
    this.email = field == "email" ? "th-inner sortable both " + sortBy : "";
    this.firstName = field == "firstName" ? "th-inner sortable both " + sortBy : "";
    this.lastName = field == "lastName" ? "th-inner sortable both " + sortBy : "";
    this.roleName = field == "roleName" ? "th-inner sortable both " + sortBy : "";
  
    this.getCashierUsers(
      `${environment.API_URL}/api/CashierUsers/${this.routeRole}?pageNumber=${page ? page : this.users.pageNumber}`,
      this._sortBy,
      this._sort
    );
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.users?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }

  // tslint:disable-next-line: variable-name
  getRole(role_id: any): string {
    if (role_id) {
      return this.roles.filter((x) => x.id === role_id)[0].name;
    } else {
      return "No Role";
    }
  }

  p:Number =1;
  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p = pageNumber;
    return `${environment.API_URL}/api/CashierUsers/${this.routeRole}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  buildUserPhoto(user: any): any {
    return user.cashierUser.photoFormat + "," + user.cashierUser.photo;
  }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }

  viewCashierUserProfile(id: any): void {
  
    const encId = this.cryptoService.set(this.data.authentication.privateKey, id);

    if(this.roleLevel == 2){
      this.router.navigate(["manager", "cashier-user-view", { id: encId }]);
    } else if(this.roleLevel == 1){
      this.router.navigate(["acm", "cashier-user-view", { id: encId }]);
    }
    
  }

  editCashierUserProfile(id: any): void {
    
    const encId = this.cryptoService.set(this.data.authentication.privateKey, id);
    this.router.navigate(["manager", "cashier-user-edit", { id: encId }]);
  }

  createCashierUser(): void {
    this.router.navigate(["manager", "cashier-user-create"]);
  }

  modalSmsTransaction;
  mobileNo;
  openSendSms(smsModal, mobileNo) {
    this.mobileNo = mobileNo;
    this.modalSmsTransaction = this.modService.open(smsModal, {
      centered: true,
      size: "xl",
      backdrop: "static",
      keyboard: false,
    });
  }

  closeModal(){
    this.modalSmsTransaction.close();
  }
}
