<div [formGroup]="parentFormGroup" class="form-group row" [class.error]="error">

  <label class="col-sm-3 col-form-label">{{ label }} </label>
  <div class="col-sm-9">
    <input type="number" 
      [formControlName]="controlName" 
      [id]="controlName" 
      [placeholder]="placeholder"
      [upper-case]="upperCase" 
      [no-special-char]="noSpecialChar" 
      [maxlength]="maxLength" 
      [tabindex]="tabIndexStart"
      [readonly]="readOnly()" 
      class="form-control" 
      style="border-color: #294896;"
      />
      <span *ngIf="error" class="error-msg"
      ><small><div [innerHTML]="errorMessage"></div></small></span
    >
  </div>
</div>
