<div id="main" class="layout-column flex">
  <!-- Header -->
  <!-- /Header -->

  <!-- ############ Content START-->
  <div id="content" class="flex">
    <!-- ############ Main START-->
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">JuanPay <small>Account</small></h2>
          <!-- <small class="text-muted" *ngIf="userDetails"
            >{{ user.firtName }}, thank you for verifying you email address {{ user.email }}.</small
          > -->
        </div>
        <!-- <div>
          <a
            href="https://themeforest.net/item/basik-responsive-bootstrap-web-admin-template/23365964"
            class="btn btn-md text-muted"
          >
            <span class="d-none d-sm-inline mx-1">Buy this Item</span>
            <i data-feather="arrow-right"></i>
          </a>
        </div> -->
      </div>
    </div>

    <!-- tmp -->
    <!-- <p class="text-info">userDetail: {{ userDrtail | json }}</p> -->

    <!-- Page Content -->
    <div class="page-content page-container" id="page-content">
      <div class="padding">
        <div class="card col-md-6 offset-md-3">
          <div class="card-header">
            <strong>Account Verification</strong>
          </div>
          <div class="card-body px-0">
            <form>
              <ul class="nav mb-3 d-flex justify-content-center">
                <!-- Account Verification Steps -->
                <li class="nav-item" *ngFor="let step of steps; let i = index">
                  <a class="nav-link text-center" [class.active]="stepNumber == i + 1" (click)="goToStep(i + 1)">
                    <span
                      class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-success"
                      >{{ i + 1 }}</span
                    >
                    <div class="mt-2">
                      <div class="text-muted">{{ step.name }}</div>
                    </div>
                  </a>
                </li>
              </ul>

              <!-- tmp -->
              <!-- <ul>
                <li>requestStatus: {{ requestStatus }}</li>
                <li>steps n: {{ steps.length }}</li>
                <li>steps: {{ steps | json }}</li>
                <li>stepNumber: {{ stepNumber }}</li>
                <li>userDetail: {{ userDetail | json }}</li>
                <li>error: {{ error | json }}</li>
                <li>error type: {{ typeOfVariable(error) }}</li>
              </ul> -->

              <div class="alert alert-danger alert-dismissible fade show" *ngIf="error">
                <h6 class="alert-heading">Errors</h6>
                <p *ngIf="isEmailActivatedAlready">The email is already activated.</p>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <p *ngIf="typeOfVariable(error) == 'string'">{{ error }}</p>
                <ul *ngIf="typeOfVariable(error) == 'object'">
                  <li *ngFor="let errorItem of error | keyvalue">{{ errorItem.value }}</li>
                </ul>
              </div>

              <!-- Email Verification Form -->
              <form class="p-3" *ngIf="stepNumber == 1" [formGroup]="accountVerificationFormGroup">
                <div class="alert alert-success" *ngIf="steps[stepNumber - 1].completed">
                  <h5 class="alert-heading">Thank you {{ userDetail?.firstName }}!</h5>
                  <p class="text-success">Your email address has been verified. Please proceed to the next step.</p>
                </div>
              </form>

              <!-- /Email Verification Form -->

              <!-- SMS Verification Form -->
              <form *ngIf="stepNumber == 2" [formGroup]="accountVerificationFormGroup">
                <div class="alert alert-success" *ngIf="steps[stepNumber - 1].completed">
                  <h5 class="alert-heading">Phone Verified</h5>
                  <p class="text-success">
                    Your phone number {{ userDetail?.contactNumber }} has been verified. Please proceed to the next
                    step.
                  </p>
                </div>

                <ng-container *ngIf="!steps[stepNumber - 1].completed">
                  <div class="form-group">
                    <label>One Time PIN (OTP) for {{ userDetail?.contactNumber }}</label>
                    <input type="text" class="form-control" required autofocus formControlName="otp" />
                    <!-- <small>Enter the OTP code sent to your phone.</small> -->
                  </div>
                  <!-- <div class="form-group">
                    <label>Did not receive OTP?</label>
                    <p>
                      <button class="btn btn-outline-secondary">Re-send</button>
                    </p>
                  </div> -->
                </ng-container>
              </form>
              <!-- /SMS Verification Form -->

              <!-- /Password Reset Form -->
              <form *ngIf="stepNumber == 3" [formGroup]="accountVerificationFormGroup">
                <div class="alert alert-success" *ngIf="steps[stepNumber - 1].completed">
                  <h5 class="alert-heading">Account Verified</h5>
                  <p class="text-success">
                    Thank you {{ userDetail?.firstName }}, your account has been verified. You may now Sign-In your
                    account.
                  </p>
                </div>

                <div>
                  <h4 class="mt-3">Password must be</h4>
                  <ul>
                    <li>between 8 and 15 characters</li>
                    <li>
                      an alphanumeric
                      <span class="text-muted">(ABC123)</span>
                    </li>
                    <li>
                      a mix of capital and small letters
                      <span class="text-muted">(ABCde)</span>
                    </li>
                    <li>
                      a special character
                      <span class="text-muted">(ex. _ ^ % $ # @ , .)</span>
                    </li>
                  </ul>
                </div>

                <ng-container *ngIf="!steps[stepNumber - 1].completed">
                  <div class="form-group">
                    <label>Reset password</label>
                    <input
                      type="password"
                      class="form-control"
                      maxlength="15"
                      id="password"
                      required
                      autofocus
                      formControlName="password"
                    />
                  </div>
                  <div class="form-group">
                    <label>Confirm password</label>
                    <input
                      type="password"
                      class="form-control"
                      maxlength="15"
                      id="passwordConfirm"
                      required
                      confirmed
                      formControlName="password_confirmation"
                    />
                  </div>
                </ng-container>
              </form>
              <!-- /Password Reset Form -->
            </form>

            <!-- tmp -->
            <!-- <p>isNextButtonEnabled: {{ isNextButtonEnabled() }}</p>
            <p>{{ accountVerificationFormGroup.value | json }}</p> -->
          </div>
          <div class="card-footer">
            <div class="float-right p-1" *ngIf="!isVerificationComplete()">
              <button
                class="btn btn-light mr-1"
                [disabled]="!isPreviousButtonEnabled()"
                (click)="goToStep(stepNumber - 1)"
              >
                <i class="fa fa-arrow-left fa-fw"></i> Previous
              </button>
              <button class="btn btn-primary" [disabled]="!isNextButtonEnabled()" (click)="goToStep(stepNumber + 1)">
                Next <i class="fa fa-arrow-right fa-fw"></i>
              </button>
            </div>
            <div class="float-right p-1" *ngIf="isVerificationComplete()">
              <a class="btn btn-primary" routerLink="/login">Proceed to Sign In</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->

    <!-- ############ Main END-->
  </div>
  <!-- ############ Content END-->

  <!-- ############ Footer START-->
  <div id="footer" class="page-footer">
    <div class="d-flex p-3">
      <span class="text-center text-sm text-muted flex">&trade; www.govipcenter.com</span>
      <!-- <div class="text-sm text-muted">Version 1.1.2</div> -->
    </div>
  </div>
  <!-- ############ Footer END-->
</div>
