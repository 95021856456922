<app-loader *ngIf="loader"></app-loader>

<div class="card col-lg-5" style="float: left;">
    <div class="card-header">
        <strong>Status and Settlement Details</strong>
        <div class="mt-2 mb-2" [formGroup]="generalFormGroup">
            <div  class="form-check form-check-inline">
                <input formControlName="userSystemOrigin" class="form-check-input" type="radio" value="[NEW] GOVIPCENTER">
                <label class="form-check-label" for="inlineRadio1"><span class="badge badge-success" >[NEW] GOVIPCENTER</span></label>
            </div>
            <div class="form-check form-check-inline">
                <input formControlName="userSystemOrigin" class="form-check-input" type="radio" value="[OLD] ENTERPRISE">
                <label class="form-check-label" for="inlineRadio2"><span class="badge badge-primary" >[OLD] ENTERPRISE</span></label>
            </div>
        </div>
    </div>
        <div class="card-body">
            <table class="table table-hover" >   
                <tr>
                    <th  style="width: 180px;">Transactions Date</th>
                    <td style="text-align: left;">{{ transaction?.transactionDate | date: "MM/dd/yyyy" }} </td>
                </tr>
                <tr>
                    <th  style="width: 180px;">Transactions Count</th>
                    <td style="text-align: left;">{{ transaction?.transactionCount}} </td>
                </tr>
                <tr>
                    <th  style="width: 180px;">Transactions Fee</th>
                    <td style="text-align: left;">{{  transaction?.fee | number : "1.2-2" }} </td>
                </tr>
                <tr>
                    <th  style="width: 180px;">Transactions Amount</th>
                    <td style="text-align: left;">{{  (transaction?.netPayable - (transaction?.fee * transaction?.transactionCount)) | number : "1.2-2" }} </td>
                </tr>
                
                <tr>
                    <th>Net Amount Payable</th>
                    <td style="text-align: left; font-size: 20px; font-weight: bolder;">{{ transaction?.netPayable | number : "1.2-2"}}</td>
                </tr>
                <tr>
                    <th>Settlement Type</th>
                    <td *ngIf="generalFormGroup.controls.userSystemOrigin.value == '[NEW] GOVIPCENTER'" style="text-align: left;">
                        <div  [formGroup]="generalFormGroup">
                            <ng-select  [items]="settlementTypesOption" 
                            placeholder="Settlement Type"
                            bindLabel="text" 
                            bindValue="id" 
                            formControlName="settlementId">
                            </ng-select></div>
                    </td>
                    <td *ngIf="generalFormGroup.controls.userSystemOrigin.value == '[OLD] ENTERPRISE'" style="text-align: left;">
                        {{ generalFormGroup.controls.settlementId.value }} 
                    </td>
                </tr>
            </table>
            <table  class="table table-hover" >     
                <tr>
                    <th style="width: 180px;">System Origin</th>
                    <td style="text-align: left;">{{ transaction?.userSystemOrigin}} </td>
                </tr>
                <!-- <tr>
                    <th>Completed By</th>
                    <td style="text-align: left;">{{ transaction?.completedBy}} </td>
                </tr>
                <tr>
                    <th>Completed Date</th>
                    <td style="text-align: left;">{{ transaction?.completedDate  | date: "MM/dd/yyyy" }}</td>
                </tr> -->
                <tr>
                    <th>Transaction Status</th>
                    <td style="text-align: left;"> <app-status-table-data [status]="transaction?.statusId"></app-status-table-data> </td>
                </tr>
                <tr>
                    <th>Uploaded Date</th>
                    <td style="text-align: left;">{{ transaction?.uploadedDate | date: "MM/dd/yyyy" }} </td>
                </tr>
                <tr>
                    <th>Uploaded By</th>
                    <td style="text-align: left;">{{ transaction?.uploadedBy}}</td>
                </tr>
            </table>
           
        </div>
</div>

<div class="card col-lg-7" style="float: right;">

    <div class="card-header">
        <strong>Bank Settlement Details</strong>
        <button *ngIf="generalFormGroup.controls.userSystemOrigin.value == '[NEW] GOVIPCENTER'" class="btn btn-sm btn-success mt-2 float-right" (click)="getLatestBankDetails()">
            <i class="fa fa-upload"></i> &nbsp; Fetch Latest Bank Details
        </button>
    </div>

    <div class="card-body">
        <table  class="table table-hover"  *ngIf="generalFormGroup.controls.userSystemOrigin.value == '[NEW] GOVIPCENTER'">    
            <!-- *ngIf="transaction?.settlementId == 'VIA_BANK_TRANSFER'"  -->
            <tr >
                <th>Bank Name</th>
                <td style="text-align: left;">{{ getSettlementBank(transaction?.bankSettlementName)}} </td>
            </tr>
            <tr>
                <th>Bank Account Number</th>
                <td style="text-align: left; font-size: 20px;"><strong>{{ transaction?.bankAccountNumber}}</strong></td>
            </tr>
            <tr>
                <th>Bank Account Name</th>
                <td style="text-align: left;">{{ transaction?.bankAccountName}} </td>
            </tr>
        </table>
        <table  class="table table-hover"  *ngIf="generalFormGroup.controls.userSystemOrigin.value == '[OLD] ENTERPRISE'"> 
            <tr>
                <th>Bank Name</th>
                <td style="text-align: left;">
                    <app-dropdown
                    place-holder="Bank Name"
                    [parent-form-group]="generalFormGroup"
                    control-name="bankSettlementName"
                    label=""
                    [data]="settlementBanksArray"
                    [read-only]="false"
                    [error]="generalFormGroup?.controls.bankSettlementName.errors | estatus"
                    [error-message]="generalFormGroup?.controls.bankSettlementName.errors | econtent"
                  >
                  </app-dropdown>
                </td>
            </tr>
            <tr>
                <th>Bank Account Number</th>
                <td>
                    <div  [formGroup]="generalFormGroup" >
                        <input
                        type="text"
                        placeholder="Bank Account Number"
                        class="form-control w-100"
                        formControlName="bankAccountNumber"
                        style="background-color: rgb(255, 255, 255); color: black"
                    />
                    </div>
                </td>
            </tr>
            <tr>
                <th>Bank Account Name</th>
                <td>
                    <div  [formGroup]="generalFormGroup" >
                        <input
                        type="text"
                        placeholder="Bank Account Name"
                        class="form-control w-100"
                        formControlName="bankAccountName"
                        style="background-color: rgb(255, 255, 255); color: black"
                    />
                    </div>
                </td>
            </tr>
        </table>

        
       
    </div>

    <div class="card-header">
        <strong>Store Details</strong>
       
    </div>
    <div class="card-body">
    <table  class="table table-hover" >   
        
        <tr>
            <th style="width: 150px;">[AC]Store Name</th>
            <td style="text-align: left;">  {{ transaction?.storeNameFromEncash  }}</td>
        </tr>
        <tr *ngIf="generalFormGroup.controls.userSystemOrigin.value == '[NEW] GOVIPCENTER'">
            <th style="width: 150px;">Store Name</th>
            <td style="text-align: left;">    
                <div  [formGroup]="generalFormGroup">
                <ng-select  [items]="storesOption" 
                placeholder="Store Name"
                bindLabel="text" 
                bindValue="id" 
                formControlName="storeName"
                (change)="getSettlementbyStoreName()">
                </ng-select></div></td>
        </tr>
        <tr>
            <th>Email Address</th>
            <td style="text-align: left;">  
                <label *ngIf="generalFormGroup.controls.userSystemOrigin.value == '[NEW] GOVIPCENTER'">{{ transaction?.email  }}</label>
                <div  [formGroup]="generalFormGroup" *ngIf="generalFormGroup.controls.userSystemOrigin.value == '[OLD] ENTERPRISE'">
                    <input
                    type="text"
                    placeholder="Email Address"
                    class="form-control w-100"
                    formControlName="managerEmailAddress"
                    style="background-color: rgb(255, 255, 255); color: black"
                />
                </div>
            </td>
        </tr>
    <tr>
        <th>Manager Username</th>
        <td style="text-align: left;">  
            <label *ngIf="generalFormGroup.controls.userSystemOrigin.value == '[NEW] GOVIPCENTER'">{{ transaction?.transactBy  }}</label>
            <div  [formGroup]="generalFormGroup" *ngIf="generalFormGroup.controls.userSystemOrigin.value == '[OLD] ENTERPRISE'">
                <input
                type="text"
                placeholder="Username"
                class="form-control w-100"
                formControlName="managerUsername"
                style="background-color: rgb(255, 255, 255); color: black"
                (keyup.enter)="checkMemberDetails()"
            />
            </div>
        </td>
    </tr>
    <tr>
        <th>Manager Name</th>
        <td style="text-align: left;">  
            <label *ngIf="generalFormGroup.controls.userSystemOrigin.value == '[NEW] GOVIPCENTER'">{{ transaction?.name  }}</label>
            <div  [formGroup]="generalFormGroup" *ngIf="generalFormGroup.controls.userSystemOrigin.value == '[OLD] ENTERPRISE'">
                <input
                type="text"
                placeholder="Manager Name"
                class="form-control w-100"
                formControlName="managerName"
                style="background-color: rgb(255, 255, 255); color: black"
            />
            </div>
        </td>
    </tr>
  
    <tr>
        <th>Contact Number</th>
        <td style="text-align: left;">  
            <label *ngIf="generalFormGroup.controls.userSystemOrigin.value == '[NEW] GOVIPCENTER'">{{ transaction?.contactNumber  }}</label>
            <div  [formGroup]="generalFormGroup" *ngIf="generalFormGroup.controls.userSystemOrigin.value == '[OLD] ENTERPRISE'">
                <input
                type="text"
                placeholder="Contact Number"
                class="form-control w-100"
                formControlName="managerContactNumber"
                style="background-color: rgb(255, 255, 255); color: black"
            />
            </div>
        </td>
    </tr>

    <tr  *ngIf="generalFormGroup.controls.settlementId.value == 'VIA_BANK_TRANSFER'">
        <th>Deposit RefNo.</th>
        <td style="text-align: left;">  
            <div  [formGroup]="generalFormGroup">
                <input
                type="text"
                placeholder="Deposit Reference Number"
                class="form-control w-100"
                formControlName="depositReferenceNumber"
                style="background-color: rgb(255, 255, 255); color: black"
            />
            </div>
        </td>
    </tr>

    <tr >
        <th>Remarks</th>
        <td style="text-align: left;">  
            <div  [formGroup]="generalFormGroup">
                <textarea
                placeholder="Remarks"
                class="form-control w-100"
                formControlName="remarks"
                style="background-color: rgb(255, 255, 255); color: black"
                ></textarea>
            </div>
        </td>
    </tr>

    
    </table>
      

        <button class="btn btn-sm btn-success mt-2 float-right" (click)="saveAndCompleteSettlement()">
            <i class="fa fa-check"></i> &nbsp; Save and Complete
        </button>
        <button style="margin: 0px 10px;" class="btn btn-sm btn-primary mt-2 float-right" (click)="saveSettlement()">
            <i class="fa fa-save"></i> &nbsp; Save
        </button>
        <!-- <button class="btn btn-sm btn-danger mt-2 float-right" (click)="saveSettlement()">
            <i class="fa fa-trash"></i> &nbsp; Declined
        </button> -->
</div>
</div>



 
