import { CurrencyPipe, DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormGroupName, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { zip } from 'lodash';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { ExcelService } from 'src/data/services/web/excel.service';
import { JntService } from 'src/data/services/web/jnt.service';
import { StandardInsuranceService } from 'src/data/services/web/standard-insurance.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';

PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5";

@Component({
  selector: 'app-jnt',
  templateUrl: './jnt.component.html',
  styleUrls: ['./jnt.component.less']
})
export class JntComponent implements OnInit {

  @Input("application") application: any;
  jntFormGroup : FormGroup;
  formBuilder : FormBuilder;
  public emptyString = "";
  public billers : any = [];
  public biller : any;
  public billerCode : any;
  public selectedBillerCode : any;

  public activeTab = 1;
  public loader = true;
  public fee : any;
  public amount : any;

  public clientTypes : any = [];
  public industryTypes : any = [];
  public productTypes : any = [];
  public premiumTypes : any = [];
  public mvTypes : any = [];
  public titles : any = [];
  public registrationTypes : any = [];
  public taxTypes : any = [];

  public bodyTypes : any = [];
  public fmvManufacturers : any = [];
  public fmvModels : any = [];
  public subBodyTypes : any = [];
  public vehicleTypes : any = [];

  @Output("closeModal") closeModal = new EventEmitter();
  
    constructor(
    private jntService : JntService,
    private router: Router,
    localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    private modalService: NgbModal,
    formBuilder : FormBuilder,
    private webService: WebSettingsService,
    private datepipe: DatePipe,
    private excelService: ExcelService,
    private http: HttpClient) {
    this.formBuilder = formBuilder;
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.loadPdf();
    this.initSenderFormGroup();
    this.initReceiverFormGroup();
    this.initJnTFormGroup();
    this.initItemFormGroup();
    this.loadExcelData();
  }

  imagePreview: string;
  loadPdf(): void {
    const pdfPath = 'assets/file/JT-Express.pdf';

    this.http.get(pdfPath, { responseType: 'arraybuffer' })
      .subscribe(response => {
        const bytes = new Uint8Array(response);
        const binaryString = bytes.reduce((data, byte) => {
          return data + String.fromCharCode(byte);
        }, '');
        this.imagePreview = btoa(binaryString);
      });
  }

  initJnTFormGroup(): void {
    this.jntFormGroup = new FormGroup({
      ordertype: new FormControl(1, [Validators.required]),
      servicetype: new FormControl(6, [Validators.required]),
      deliverytype: new FormControl(1, [Validators.required]),
      isCOD: new FormControl(false, [Validators.required]),
      paytype: new FormControl(1, [Validators.required]),
      weight: new FormControl(null, [Validators.required, Validators.min(0.01), Validators.max(50)]),
      totalquantity: new FormControl(null, [Validators.required, Validators.min(1)]),
      itemsvalue: new FormControl(null, [Validators.required, Validators.min(85), Validators.max(50000)]),
      valuationFee: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(50000)]),
      serviceFee: new FormControl(1, [Validators.required, Validators.min(1), Validators.max(50000)]),
      remark: new FormControl(this.emptyString),
      sender: this.senderFormGroup,
      receiver: this.receiverFormGroup,
      pinCode: new FormControl(this.emptyString),
    });
  }

  senderFormGroup: FormGroup;
  selectedSenderCity;
  initSenderFormGroup(): void {
    this.senderFormGroup = new FormGroup({
      id: new FormControl(0),
      prov: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required]),
      area: new FormControl(null, [Validators.required]),
      name: new FormControl(null, [Validators.required]),
      postcode: new FormControl(null, [Validators.required]),
      phone: new FormControl(null, [Validators.required]),
      address: new FormControl(null, [Validators.required]),
      isCollapsed: new FormControl(true)
    });

    this.senderFormGroup.controls.prov.valueChanges.subscribe((result) => {
      if(result){
        this.senderFormGroup.controls.city.patchValue("");
        this.senderFormGroup.controls.area.patchValue("");
        this.senderCity = this.getUniqueCities(this.senderAddresslist, result);
      } else {
        this.senderFormGroup.controls.city.patchValue("");
        this.senderFormGroup.controls.area.patchValue("");
      }
    });
    
    this.senderFormGroup.controls.city.valueChanges.subscribe((result) => {
      if(result){
        this.selectedSenderCity = result;
        this.senderFormGroup.controls.area.patchValue("");
        this.senderBgry = this.getUniqueBarangays(this.senderAddresslist, result);
      } else {
        this.senderFormGroup.controls.area.patchValue("");
      }
    });

    this.senderFormGroup.controls.area.valueChanges.subscribe((result) => {
      if(result){
        var zipcode = this.senderAddresslist.find(x => x.CITY == this.selectedSenderCity && x.BARANGAY == result).ZIPCODE;
        this.senderFormGroup.controls.postcode.patchValue(zipcode);
      } else {
        if(result){
          this.senderFormGroup.controls.postcode.patchValue("");
        }
      }
    });
  }

  receiverFormGroup:FormGroup;
  receiverCity:any;
  selectedReceiverCity:any;
  receiverBgry:any;
  initReceiverFormGroup(): void {
    this.receiverFormGroup = new FormGroup({
      id: new FormControl(0),
      prov: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required]),
      area: new FormControl(null, [Validators.required]),
      name: new FormControl(null, [Validators.required]),
      postcode: new FormControl(null, [Validators.required]),
      phone: new FormControl(null, [Validators.required]),
      address: new FormControl(null, [Validators.required]),
      isCollapsed: new FormControl(false)
    });

    this.receiverFormGroup.controls.prov.valueChanges.subscribe((result) => {
      if(result){
        this.receiverFormGroup.controls.city.patchValue("");
        this.receiverFormGroup.controls.area.patchValue("");
        this.receiverCity = this.getUniqueCities(this.senderAddresslist, result);
      } else {
        this.receiverFormGroup.controls.city.patchValue("");
        this.receiverFormGroup.controls.area.patchValue("");
      }
    });
    
    this.receiverFormGroup.controls.city.valueChanges.subscribe((result) => {
      if(result){
        this.selectedReceiverCity = result;
        this.receiverFormGroup.controls.area.patchValue("");
        this.receiverBgry = this.getUniqueBarangays(this.senderAddresslist, result);
      } else {
        this.receiverFormGroup.controls.area.patchValue("");
      }
    });

    this.receiverFormGroup.controls.area.valueChanges.subscribe((result) => {
      if(result){
        var zipcode = this.senderAddresslist.find(x => x.CITY == this.selectedReceiverCity && x.BARANGAY == result).ZIPCODE;
        this.receiverFormGroup.controls.postcode.patchValue(zipcode);
      } else {
        this.receiverFormGroup.controls.postcode.patchValue("");
      }
    });
  }

  itemsFormGroup: FormGroup;
  itemsList: any = [];
  initItemFormGroup() {
    this.itemsFormGroup = new FormGroup({
      itemname: new FormControl(null, [Validators.required]),
      number: new FormControl(null, [Validators.required, Validators.min(1)]),
      itemvalue: new FormControl(null, [Validators.required, Validators.min(1)]),
      desc: new FormControl(null, [Validators.required])
    });

    this.itemsFormGroup.controls.number.valueChanges.subscribe((result) => {
      this.jntFormGroup.controls.totalquantity.patchValue(result);
    });

    this.itemsFormGroup.controls.itemvalue.valueChanges.subscribe((value) => {
      let val = value.replaceAll(',', '');
      if(val >= 500){
        var fee = val * 0.01;
        let com = Math.round(fee);
        this.jntFormGroup.controls.valuationFee.patchValue(com);
      } else if(val < 500 && val >= 0){
        this.jntFormGroup.controls.valuationFee.patchValue(5);
      } else {
        this.jntFormGroup.controls.valuationFee.patchValue(null);
      }
    });
  }

  toggleAccordion(): void {
    this.senderFormGroup.get('isCollapsed').setValue(!this.senderFormGroup.get('isCollapsed').value);
    this.receiverFormGroup.get('isCollapsed').setValue(!this.receiverFormGroup.get('isCollapsed').value);
  }

  toggleReceiverAccordion(): void {
    this.receiverFormGroup.get('isCollapsed').setValue(!this.receiverFormGroup.get('isCollapsed').value);
    this.senderFormGroup.get('isCollapsed').setValue(!this.senderFormGroup.get('isCollapsed').value);
  }

  uniqueProvinces: any;
  loadExcelData(): void {
    this.loader = true;
    this.excelService.getExcelData()
    .then((data) => {
      this.senderAddresslist = data;
      //console.log('Excel Data as JSON:', this.senderAddresslist);

      // Extract unique provinces
      this.uniqueProvinces = this.getUniqueProvinces(data);
      //console.log('Unique Provinces:', this.uniqueProvinces);

      this.loader = false;
    })
    .catch((error) => {
      console.error('Error reading Excel file:', error);

      this.loader = false;
    });
  }

  getUniqueProvinces(data: any[]): any[] {
    const uniqueProvincesSet = new Set<string>();
    const uniqueProvinces: any[] = [];
  
    data.forEach((address) => {
      if (address.PROVINCE && !uniqueProvincesSet.has(address.PROVINCE)) {
        uniqueProvincesSet.add(address.PROVINCE);
        uniqueProvinces.push({ id: address.PROVINCE, text: address.PROVINCE });
      }
    });
  
    // Sort the unique provinces by province name
    return uniqueProvinces?.sort((a, b) => a?.text?.localeCompare(b?.text));
  }

  senderCity: any;
  getUniqueCities(data: any[], selectedProvince: string): any[] {
    const uniqueCitiesSet = new Set<string>();
    const uniqueCities: any[] = [];
  
    data
      .filter(address => address.PROVINCE === selectedProvince)
      .forEach((address) => {
        if (address.CITY && !uniqueCitiesSet.has(address.CITY)) {
          uniqueCitiesSet.add(address.CITY);
          uniqueCities.push({ id: address.CITY, text: address.CITY });
        }
      });
  
    // Sort the unique cities by city name
    return uniqueCities?.sort((a, b) => a?.text?.localeCompare(b?.text));
  }
  
  senderBgry: any;
  getUniqueBarangays(data: any[], selectedCity: string): any[] {
    const uniqueBarangaysSet = new Set<string>();
    const uniqueBarangays: any[] = [];
  
    data
      .filter(address => address.CITY === selectedCity)
      .forEach((address) => {
        if (address.BARANGAY && !uniqueBarangaysSet.has(address.BARANGAY)) {
          uniqueBarangaysSet.add(address.BARANGAY);
          uniqueBarangays.push({ id: address.BARANGAY, text: address.BARANGAY });
        }
      });
  
    // Sort the unique barangays by barangay name
    return uniqueBarangays?.sort((a, b) => a?.text?.localeCompare(b?.text));
  }

  senderAddresslist: any[];
  receiverAddresslist: any[];
  initAddress(){
    this.webService.getWebSettingsByIdentifier("ADDRESS_REFERENCE").subscribe((result) => {
      this.senderAddresslist = result.data[0].value;
      this.receiverAddresslist = result.data[0].value;
    });
  }

  setActiveTab(){
    debugger
    if(this.activeTab == 1){
      if (this.jntFormGroup.invalid) {
        this.jntFormGroup.markAllAsTouched();

        if(this.itemsFormGroup.invalid){
          this.itemsFormGroup.markAllAsTouched();
        } 

        PNotify.error({
          title: "Request denied",
          text: "Please fill up all the required fields.",
        });
        
        // Check for custom errors on the 'itemsvalue' control
        // if (this.jntFormGroup.get('itemsvalue').hasError('required')) {
        //   PNotify.error({
        //     title: 'Validation Error',
        //     text: 'Amount is required.',
        //   });
        // } else if (this.jntFormGroup.get('itemsvalue').hasError('min')) {
        //   PNotify.error({
        //     title: 'Validation Error',
        //     text: 'Amount must be greater than 0.',
        //   });
        // } else if (this.jntFormGroup.get('itemsvalue').hasError('max')) {
        //   PNotify.error({
        //     title: 'Validation Error',
        //     text: 'Amount exceeds the maximum allowed value. (P50,000.00)',
        //   });
        // }
      
        // // Check for custom errors on the 'weight' control
        // else if (this.jntFormGroup.get('weight').hasError('required')) {
        //   PNotify.error({
        //     title: 'Validation Error',
        //     text: 'Weight is required.',
        //   });
        // } else if (this.jntFormGroup.get('weight').hasError('min')) {
        //   PNotify.error({
        //     title: 'Validation Error',
        //     text: 'Weight must be greater than 0.',
        //   });
        // } else if (this.jntFormGroup.get('weight').hasError('max')) {
        //   PNotify.error({
        //     title: 'Validation Error',
        //     text: 'Weight exceeds the maximum allowed value. (50KG)',
        //   });
        // }

        // else if (this.itemsFormGroup.get('itemvalue').hasError('required')) {
        //   PNotify.error({
        //     title: 'Validation Error',
        //     text: 'Item Value is required.',
        //   });
        // } else if (this.itemsFormGroup.get('itemvalue').hasError('min')) {
        //   PNotify.error({
        //     title: 'Validation Error',
        //     text: 'Item Value must be greater than 0.',
        //   });
        // } else if (this.itemsFormGroup.get('itemvalue').hasError('max')) {
        //   PNotify.error({
        //     title: 'Validation Error',
        //     text: 'Item Value exceeds the maximum allowed value. (P50,000.00)',
        //   });
        // } else if (this.itemsFormGroup.get('number').hasError('min')) {
        //   PNotify.error({
        //     title: 'Validation Error',
        //     text: 'No of Items must be greater than 0.',
        //   });
        // }

        // else {
        //   PNotify.error({
        //     title: "Error",
        //     text: "All fields are required to proceed to next step.",
        //   });
        // }
      
      } else if(this.senderFormGroup.invalid){
        this.senderFormGroup.markAllAsTouched();
      } else if(this.receiverFormGroup.invalid){
        this.receiverFormGroup.markAllAsTouched();
      } else {
        this.activeTab++;
        this.loader = false;
      }
    } else if(this.activeTab == 2){
      var hasError : boolean  = false;
      if(hasError){
        PNotify.error({
          title: "Request denied",
          text: "Please fill up all the required fields.",
        });
      } else {

      }
    }
  }

  getStatus(controlName: string, parentFormGroup): boolean {
    const control = parentFormGroup.get(controlName);
    return control ? control.touched && control.invalid : false;
  }

  getErrorMessage(controlName: string, parentFormGroup): string {
    const control = parentFormGroup.get(controlName);
    if (control && control.errors) {
      // You may need to adjust this logic based on the structure of your error messages
      if (control.errors.required) {
        return "This field is required.";
      } else if (control.errors.maxlength) {
        return `Maximum length exceeded (${control.errors.maxlength.requiredLength} characters allowed).`;
      } else if (control.errors.min) {
        return `The minimum amount is ${control.errors.min.min}.`;
      } else if (control.errors.max) {
        return `Value must be less than ${control.errors.max.max}.`;
      } // Add more error message handling logic as needed
    }
    return "";
  }

  setActiveTabPrev(){
    if(this.activeTab == 1){
      PNotify.error({
        title: "Error",
        text: "Action Denied.",
      });
    }else{
      this.activeTab--;
    }
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  codeStatus : boolean = false;
  code : any;
  
  onCodeCompleted(code: string) {
    this.code = code;
    this.codeStatus = code.length == 6;
  }

  
  processPayment(){
    this.loader = true;
    this.jntFormGroup.controls.pinCode.patchValue(this.code);
    this.jntService.processJNT(this.jntFormGroup.value, this.itemsFormGroup.value)
      .subscribe((result) => {
        PNotify.success({
          title: "Transaction Success",
          text: "Kindly wait for a rider to pick-up the order",
        });
        this.loader = false;
        this.code = "";
        this.codeStatus=false;
        this.closeModal.emit();
      
    }, error => {
      PNotify.error({
        title: "Failed",
        text: error.error.reason,
      });
      this.loader = false;
      this.code = "";
      this.codeStatus = false;
    });
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  additemmodalref: NgbModalRef;
  openAddItemModal(content: any){
    this.additemmodalref = this.modalService.open(content, { backdrop: 'static', centered: true, size: "md" });
  }

  shippingRateRef: NgbModalRef;
  openShippingRateModal(content: any){
    this.shippingRateRef = this.modalService.open(content, { backdrop: 'static', centered: true, size: "lg" });
  }

  addItem(){
    if(this.itemsFormGroup.valid){
      this.itemsList.push(this.itemsFormGroup.value);
    
      this.updateTotal();
      this.itemsFormGroup.reset();
      this.additemmodalref.close();
    } else {
      PNotify.error({
        title: "Error Adding an item",
        text: "Please fill all fields",
      });
    }
  }

  closeAddItemModal(){
    this.itemsFormGroup.reset();
    this.additemmodalref.close();
  }

  updateTotal(){
    var totalNoOfItem = this.itemsList.reduce((total, item) => total + (Number(item.number) || 0), 0);
    var totalItemValue = this.itemsList.reduce((total, item) => total + (Number((item?.itemvalue?.toString()).replaceAll(",","")) * Number(item.number) || 0), 0);
    var totalItemWeight = this.itemsList.reduce((total, item) => total + (Number((item?.weight?.toString()).replaceAll(",","")) * Number(item.number) || 0), 0);

    this.jntFormGroup.controls.totalquantity.patchValue(totalNoOfItem);
    this.jntFormGroup.controls.itemsvalue.patchValue(this.makeMoney((totalItemValue.toString()).replaceAll(",","")));
    this.jntFormGroup.controls.weight.patchValue(this.makeMoney((totalItemWeight.toString()).replaceAll(",","")));
  }

  removeItem(itemToRemove: any): void {
    this.itemsList = this.itemsList.filter(item => item !== itemToRemove);
    this.itemsList.push(this.itemsFormGroup.value);
  }

  getTotalAmount(){
    return this.makeMoney(Number(this.jntFormGroup.controls.itemsvalue.value) + Number(this.jntFormGroup.controls.valuationFee.value) + 1);
  }

}
