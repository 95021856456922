import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[upper-case]",
})
export class UpperCaseDirective {
  @Input("upper-case") activateUpperCase: boolean;

  private nativeElement: HTMLElement;

  constructor(elementRef: ElementRef) {
    this.nativeElement = elementRef.nativeElement;
  }

  // @HostListener('input')
  // public onInput(): void {
  //   if (this.activateUpperCase) {

  //     this.nativeElement.style.textTransform
  //       = (this.nativeElement['value'] != null && this.nativeElement['value'] != '')
  //         ? 'uppercase' : 'unset';
  //   }
  // }

  @HostListener("ngModelChange")
  public onChange(): void {
    if (this.activateUpperCase) {
      this.nativeElement.style.textTransform =
        this.nativeElement["value"] != null && this.nativeElement["value"] != "" ? "uppercase" : "unset";
    }
  }
}
