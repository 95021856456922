import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { ActivatedRoute} from '@angular/router';
import { CryptoService } from 'src/data/crypto/crypto.service';
import { Data } from 'src/data/data';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
import { filter,conforms, over } from 'lodash';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaginationService } from 'src/app/services/pagination.service';
import { ExportExcelService } from 'src/data/services/web/export-excel.service';
import * as firebase from 'firebase';
import { switchMap } from 'rxjs/operators';
import { VipcoTransactionService } from 'src/data/services/web/vipco-transaction.service';


@Component({
  selector: 'app-vips-rider-application-history',
  templateUrl: './vips-rider-application-history.component.html',
  styleUrls: ['./vips-rider-application-history.component.less']
})
export class VipsRiderApplicationHistoryComponent implements OnInit {

  constructor(
    private store: AngularFirestore,
    private localStorageCacheService: LocalStorageCacheService,
    private vipcoTransactionService: VipcoTransactionService,
    private route: ActivatedRoute,
    private cryptoService: CryptoService,
    private data: Data,
    private modalServe: NgbModal,
    public page: PaginationService,
    public ete: ExportExcelService
  ) { }
    user: Observable<any>;
    searchText: any;
    phoneNo: any;
    riders: any;
    username: any;

  ngOnInit(): void {
    //this.initAllRiders();
    this.page.reset();
    this.username = this.localStorageCacheService.getStorage("username");
    this.initRiders();
    this.route.params.subscribe((params) => {
      const id = decodeURI(params.id || "");
      this.phoneNo = this.cryptoService.get(this.data.authentication.privateKey,id);
      // this.initRider(this.phoneNo);
      // this.store.collection("rider_registration").get().subscribe((total)=>{
      //   this.riders = total.size;
      // });
      // this.loadItems();
    })
  }

  loader: boolean = false;
  dbRiders = [];
  async initAllRiders(){
    await this.vipcoTransactionService.getAllRiders().subscribe((result) => {
      this.dbRiders = result;
    });
  }

  async initRiders(){
    await this.page.init('rider_registration','time_stamp', { reverse: true, prepend:false });
  }

  showAllData(){
    this.page.getAllData();
    setTimeout(() => {
      location.reload();
    }, 15000);
  }

  imgSrc:string;
  onClick(event){
    const imgElem = event.target;
    var target = event.target || event.srcElement || event.currentTarget;
    var srcAttr = target.attributes.src;
    this.imgSrc = srcAttr.nodeValue;
  }

  scrollHandler(e){
    if (e === 'bottom'){
      this.page.more()
    }
  }

  selectedRider:any;
  remarkModal: NgbModalRef;
openRemark(content, rider){
  this.selectedRider = rider;
  this.remarkModal = this.modalServe.open(content, { centered: true, size: "md" });
}

riderListExcel:any;
  // exportexcel(){
  //   this.store.collection('rider_registration').valueChanges().subscribe((val: any) => {
  //     // this.riderListExcel = val;
  //     let riderList = val.filter(x => x.application_status != null && x.application_rejected != null);
  //     this.generateExcel(riderList,"Rider Application");
  //   });
  // }
  startDate: string;
  endDate: string;
  async exportexcel() {
    this.loader = true;
    const riderIds = ['09000000088', '09950141501', '09618242643', '09055555552'];

    var riderList = [];
    await this.store.firestore.collection("rider_registration")
    .where(firebase.default.firestore.FieldPath.documentId(), 'not-in', riderIds)
    .get()
    .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          riderList.push({ docId : doc.id, docData: doc.data(), products: [] });
        });
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });

    await this.vipcoTransactionService.getAllRiders().subscribe((result) => {
      this.dbRiders = result;
      this.generateExcel(riderList, result, 'Rider Application');
    });
    
  }
  
  dataForExcel = [];
  Transactions : any;
  generateExcel(riderList: any, result: any, name: any): void {
    this.Transactions = [];
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
      
      for(var i = 0; i < riderList.length; i++){

        let status = "";
        let riderListName = "";

        if(riderList[i].docData?.application_rejected == true){
          status = "Rejected"
        }  else if (riderList[i].docData?.application_rejected == false){
          status = "Approved"
        } else {
          status = "Pending"
        }
        
        debugger
        var linkedStore = result.find(x => x.mobileNo == riderList[i].docId);

        if(status != "Pending"){
          let meta = [
            {
                "MobileNo": riderList[i].docId,
                "Created Date": riderList[i].docData.timestamp?.toDate().toLocaleDateString('en-US', options).replace(/\b(\d{1})\b/g, '0$1'), 
                "Rider Name": this.getFullName(riderList[i]),
                "Rider Address":	riderList[i].docData?.address_address + '' +
                riderList[i].docData?.address_barangay + ', ' +
                riderList[i].docData?.address_municipalty + ' ' +
                riderList[i].docData?.address_province,
                "Municipality": riderList[i].docData?.address_municipalty?.toUpperCase(),
                "Plate Number": riderList[i].docData?.vehicle_plate_no?.toUpperCase(),
                "Date of Approval": riderList[i].docData?.timestamp?.toDate().toLocaleDateString('en-US', options).replace(/\b(\d{1})\b/g, '0$1'),
                "Status": status,
                "Linked Store": linkedStore?.linkedStore,
                "Linked User": linkedStore?.userFname,
                "Linked Type": linkedStore?.usertype
            }
          ];
  
          this.Transactions.push(meta);
        }
      }


    for(var x = 0; x < this.Transactions.length; x++){
      this.dataForExcel.push(Object.values(this.Transactions[x][0]));
    }

    let reportData = {
      title: name + ' Report',
      data: this.dataForExcel,
      headers: Object.keys(this.Transactions[0][0])
    }

    this.ete.exportExcel(reportData, name);
    this.loader = false;
  }

  getFullName(user: any): string {

    return `${user.docData.first_name?.toUpperCase()} ${user.docData.last_name?.toUpperCase()}`;
  
  }
}

