import { CurrencyPipe } from "@angular/common";
import { Component, HostListener, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { CashierUsersService } from "src/data/services/web/cashier-users.service";
import { RolesService } from "src/data/services/web/roles.service";
import { StoreService } from "src/data/services/web/store.service";
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { UserService } from "src/data/services/web/user.service";
import { StoreItemService } from "src/data/services/web/store-item.service";
import { environment } from "src/environments/environment";
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5
@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.less']
})
export class StoreComponent implements OnInit {

  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public storeItemService: StoreItemService;
  public userService: UserService;
  public storeService: StoreService;
  public emptyString = "";
  public roles: any;
  public role: any;

  public modalService: NgbModal;
  public modalConfirmService: NgbModal;

  public viewItemModal: NgbModalRef;
  public confirmPurchaseModal: NgbModalRef;

  public accessToken: string;
  public newuser: boolean;
  public user: any;
  public userId: any;
  public roleLevelId: any;
  public username: any;

  public storeItems: any;
  public storeItem: any;
  public storeImagesCount: any;
  public loader = false;
  public filterBy: any;
  public innerWidth: any;

  public listFilterForm = new FormGroup({
    searchString: new FormControl(""),
    searchStringField: new FormControl(""),
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    private currencyPipe: CurrencyPipe,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    storeItemService: StoreItemService,
    storeService: StoreService,
    modalService: NgbModal,
    modalConfirmService: NgbModal,
    userService: UserService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.storeItemService = storeItemService;
    this.storeService = storeService;
    this.modalService = modalService;
    this.userService= userService;
    this.modalConfirmService = modalConfirmService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.loader = true;
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.username = this.localStorageCacheService.getStorage("username");
    this.userId = this.localStorageCacheService.getStorage("userId");
    
    this.getStoreItem();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    //console.log(this.innerWidth);
  }

  private _sortBy: any;
  private _sort: any;

  getStoreItem(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_searchString: string = this.listFilterForm.get("searchString").value
      ? `searchString=${this.listFilterForm.get("searchString").value}&`
      : "";
    const queryString = queryString_orderby + queryString_order + queryString_searchString ;

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/StoreItem/StoreItems?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/StoreItem/StoreItems${queryString}`;
      }
    }

    this.storeItemService.getStoreItems(endPointUrl).subscribe((result) => {
      if (result.data.length == 0 && result.pageNumber != 1) {
        this.getStoreItem(result.previousPage, _sortBy, _sort);
      } else {
        this.storeItems = result.data;
        this.loader = false;
        console.log(this.storeItems);
      }
    });
  }

  createdDate = "";
  searchString = "";

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.createdDate = field == "createdDate" ? "th-inner sortable both " + sortBy : "";

    this.getStoreItem(
      `${environment.API_URL}/api/StoreItem/StoreItems?pageNumber=${page ? page : this.storeItems.pageNumber}`,
      this._sortBy,
      this._sort
    );
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.storeItems?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }

  p:Number =1;
  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p = pageNumber;
    return `${environment.API_URL}/api/StoreItem/StoreItems?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }
  
  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  viewDetails(detailModal, item) {
    this.storeItem = item;
    this.storeImagesCount = Object.keys(item.storeItemImages).length;

    const modal = this.modalService.open(detailModal, { centered: true, size: "md" });
    this.viewItemModal = modal;
  }

  confirmPurchase(confirmModal, item) {
    this.storeItem = item;
    const modal = this.modalConfirmService.open(confirmModal, { centered: true, size: "md" });
    this.confirmPurchaseModal = modal;
  }

  proceedPurchase(storeItem: any){
    this.storeItemService.storePurchase(storeItem).subscribe(
      (result) => {
        PNotify.success({
          title: "Success",
          text: "Purchase successful.",
        });

        this.confirmPurchaseModal.close();
        this.viewItemModal.close();
      },
      (error) => {
        if (error.status === 400) {
          PNotify.error({
            title: "Error",
            text: "Bad request. Please try again.",
          });
        } else {
          PNotify.error({
            title: "Error",
            text: "Internal server error.",
          });
        }
      }
    );
  }
}
