import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors } from "@angular/forms";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AccountServiceService {
  private httpClientNoInterceptor: HttpClient;
  private baseAPIUrl = environment.API_URL;

  constructor(private httpClient: HttpClient, httpBackend: HttpBackend) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
  }

  /**
   * Verify the email of the user attached given to the token.
   *
   * @param token The token string
   * @param expiration The expiration string
   */
  public verifyEmail(token: string): Observable<any> {
    return this.httpClient.post<any>(`${this.baseAPIUrl}/api/Account/VerifyEmail`, {
      token: token,
    });
  }

  /**
   * Verify the SMS of the user attached given to the token.
   *
   * @param token The token string
   * @param otp The One Time PIN (OTP)
   */
  public verifySMS(token: string, otp: string): Observable<any> {
    return this.httpClient.post<any>(`${this.baseAPIUrl}/api/Account/VerifySms`, {
      token: token,
      otp: otp,
    });
  }

  /**
   * Reset the password of the user attached to the given token.
   *
   * @param token The token string
   * @param password The password of the user
   */
  public resetPassword(token: string, password: string): Observable<any> {
    return this.httpClient.post<any>(`${this.baseAPIUrl}/api/Account/GeneratePassword`, {
      token: token,
      password: password,
    });
  }

  public verifyEmailResetPassword(token: string): Observable<any> {
    return this.httpClient.post<any>(`${this.baseAPIUrl}/api/Account/VerifyEmailResetPassword`, {
      token: token,
    });
  }

  public verifyEmailResetPIN(token: string): Observable<any> {
    return this.httpClient.post<any>(`${this.baseAPIUrl}/api/Account/VerifyEmailResetPIN`, {
      token: token,
    });
  }

  public sendSMSOTP(token: string): Observable<any> {
    return this.httpClient.post<any>(`${this.baseAPIUrl}/api/Account/SendOTP/Anonymous`, {
      token: token,
    });
  }

  /**
   * Verify the SMS of the user attached given to the token.
   *
   * @param token The token string
   * @param otp The One Time PIN (OTP)
   */
  public verifySMSResetPassword(token: string, otp: string): Observable<any> {
    return this.httpClient.post<any>(`${this.baseAPIUrl}/api/Account/VerifySmsResetPassword`, {
      token: token,
      otp: otp,
    });
  }

  public static matchValues(
    matchTo: string // name of the control to match to
  ): (AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.parent && !!control.parent.value && control.value === control.parent.controls[matchTo].value
        ? null
        : { isMatching: false };
    };
  }
}
