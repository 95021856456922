<app-loader *ngIf="loader"></app-loader>
<div class="modal-body" *ngIf="activeTab != 3">
  <form id=" form" novalidate="">
    <div id="rootwizard">
      <ul class="nav mb-3">
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 1 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >1</span
            >
            <div class="mt-2">
              <div class="text-muted">VIP Protect</div>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 2 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >2</span
            >
            <div class="mt-2">
              <div class="text-muted">Confirmation</div>
            </div>
          </a>
        </li>
        <li  class="nav-item" style="margin-left: auto">
          <a class="nav-link text-center" [ngClass]="activeTab == 3 ? 'active' : ''">
            <img [src]="application.value.thumbnail.logo" height="50" class="img-responsive" style="object-fit: contain" />
          </a>
        </li>
      </ul>

      <hr class="bg-dark" />

      <div class="tab-content p-3">
        <div class="tab-pane" [ngClass]="activeTab == 1 ? 'active' : ''" id="selectBillerTab">
          <div class="row">
            <!-- <div class="col-sm-12 col-md-2 d-flex justify-content-center align-items-center">
              <img
                [src]="application.value.thumbnail.logo"
                height="100"
                width="100"
                class="img-responsive"
                style="object-fit: cover"
              />
            </div> -->
            <div class="col-sm-12 col-md-12 d-flex flex-wrap">
              <h4>{{ application.value.thumbnail.title }}</h4>
              <p>
                {{ application.value.acquired.description }}
              </p>
              
            <hr/>
            </div>
            <div class="col-sm-12 col-md-2"></div>
            <div class="col-sm-12 col-md-8">


              <div class="form-group">
                <div  class="form-group row">
                <div class="col-sm-3">
                <label>Amount: </label>
            </div>
                <div class="col-sm-9">
                <input type="text" class="form-control " tabindex="-1" aria-hidden="true" value="{{vipProtectFormGroup?.controls.amount.value | number: '1.2-2'}}" readonly="read-only"/>
              </div></div>
            </div>

              <app-textbox
                [parent-form-group]="vipProtectFormGroup"
                tab-index-start="0"
                control-name="customerName"
                [error]="vipProtectFormGroup?.controls.customerName.errors"
                read-only="false"
                no-special-char="false"
                label="Name"
                placeholder="Name"
                max-length="50"
              >
              </app-textbox>

              <app-datepicker
                [parent-form-group]="vipProtectFormGroup"
                tab-index-start="1"
                control-name="birthDate"
                [error]="vipProtectFormGroup?.controls.birthDate.errors"
                read-only="false"
                label="Birthdate"
                placeholder="Birthdate"
              >
              </app-datepicker>

              <app-textbox
                [parent-form-group]="vipProtectFormGroup"
                tab-index-start="2"
                control-name="beneficiary"
                [error]="vipProtectFormGroup?.controls.beneficiary.errors"
                read-only="false"
                no-special-char="false"
                label="Beneficiary"
                placeholder="Beneficiary"
                max-length="50"
              >
              </app-textbox>

              <app-textbox
                [parent-form-group]="vipProtectFormGroup"
                tab-index-start="3"
                control-name="relationship"
                [error]="vipProtectFormGroup?.controls.relationship.errors"
                read-only="false"
                no-special-char="false"
                label="Relationship"
                placeholder="Relationship"
                max-length="50"
              >
              </app-textbox>

              <app-textbox
                [parent-form-group]="vipProtectFormGroup"
                tab-index-start="3"
                control-name="contactNumber"
                [error]="vipProtectFormGroup?.controls.contactNumber.errors"
                read-only="false"
                no-special-char="false"
                label="Contact Number"
                placeholder="Contact Number"
                max-length="50"
              >
              </app-textbox>

              <app-textarea
                [parent-form-group]="vipProtectFormGroup"
                tab-index-start="4"
                control-name="residence"
                [error]="vipProtectFormGroup?.controls.residence.errors | estatus"
                read-only="false"
                label="Residence"
                placeholder="Residence"
              >
              </app-textarea>
            </div>
            <div class="col-sm-12 col-md-2"></div>
          </div>
        </div>

        <div class="tab-pane" [ngClass]="activeTab == 2 ? 'active' : ''" id="newTransTab">
          <div class="form-group">
            <p>
              <strong>VIP Protect Confirmation</strong><br />
              <small>Please make sure that the all details is correct.</small>
            </p>
            <div class="card">
              <div class="list list-row">
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Product Name</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">VIP Protect</div>
                  </div>
                </div>
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Name</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ vipProtectFormGroup.controls.customerName.value }}
                    </div>
                  </div>
                </div>
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Birthday</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ vipProtectFormGroup.controls.birthDate.value | date: "yyyy-MM-dd"  }}
                    </div>
                  </div>
                </div>
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Beneficiary</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ vipProtectFormGroup.controls.beneficiary.value }}
                    </div>
                  </div>
                </div>
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Relationship</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ vipProtectFormGroup.controls.relationship.value }}
                    </div>
                  </div>
                </div>
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Residence</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ vipProtectFormGroup.controls.residence.value }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Contact Number</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ vipProtectFormGroup.controls.contactNumber.value }}
                    </div>
                  </div>
                </div>

                <hr />
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Total Amount</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ vipProtectFormGroup.controls.amount.value }}
                    </div>
                  </div>
                </div>
                <hr />
                <div class="col-sm-12 col-md-12">
                  <div class="col-sm-12 col-md-6" style="float: none; margin: auto">
                    <div class="item-feed h-2x text-center">ENTER PINCODE</div>
                    <code-input
                      [isCodeHidden]="true"
                      [codeLength]="6"
                      (codeChanged)="onCodeChanged($event)"
                      (codeCompleted)="onCodeCompleted($event)"
                      [code]="code"
                    >
                    </code-input>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>

        <!-- previous next -->
        <div class="row py-3">
          <div class="col-6">
            <a class="btn btn-white button-previous" (click)="setActiveTabPrev()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-arrow-left"
              >
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
              &nbsp; Previous
            </a>
          </div>
          <div class="col-6">
            <div class="d-flex justify-content-end">
              <a *ngIf="activeTab != 2" class="btn btn-white button-next" (click)="setActiveTab()">
                Next &nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-arrow-right"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </a>
              <a
                *ngIf="activeTab == 2 && codeStatus"
                appDebounceClick 
                [debounceTime]="500" 
                (debounceClick)="processPayment()"
                class="btn btn-info button-next"
                style="color: white"
              >
                Process VIP Protect &nbsp;
                <span class="fa fa-hand-pointer"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
