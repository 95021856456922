<app-loader *ngIf="loader"></app-loader>
<div class="container-fluid --home">
    <div class="row">
        <div class="col-12">
            <div class="header mt-3">
                <div class="text">
                    <img src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file260fa82a-0150-4c07-a413-e289dffd98fb.png" width="65px" height="65px" alt=""> 
                    VIPS PROFILE
                </div>
            </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 pl-5 pr-4" style="max-height: 316px;">
          <div class="box p-0 m-0">
            <app-widget-basic-profile></app-widget-basic-profile>
        </div>
        </div>
        <div class="col-lg-7 col-md-12 col-sm-12 pl-4 pr-5" style="max-height: 316px;">
            <div class="row box">
                <div class="col-12 pt-2">
                    <h5>Vips Franchise Details</h5>
                    <table class="table table-striped --tablee">
                        <tbody>
                          <tr>
                            <td style="width:110px">Full Name</td>
                            <th style="max-width: 110px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;" class="text-nowrap" scope="row">{{ user?.managerUser?.firstName + ' ' + user?.managerUser?.lastName }}</th>

                            <td style="width:110px">Birthday</td>
                            <th style="text-align: right;" class="text-nowrap" scope="row">{{ user?.managerUser?.birthDate | date: 'MMM dd, yyyy'}}</th>
                          </tr>
                          <tr>
                            <td style="width:110px">Email Address</td>
                            <th class="text-nowrap" scope="row">{{ user?.email }}</th>

                            <td style="width:110px">Mobile No</td>
                            <th style="text-align: right;" class="text-nowrap" scope="row">{{ user?.managerUser?.contactNumber }}</th>
                          </tr>
                          <tr>
                            <td style="width:110px">Complete Address</td>
                            <th class="text-nowrap" scope="row" colspan="3">{{ user?.managerUser?.address }}</th>
                          </tr>
                        </tbody>
                      </table>
                </div>

                <div class="col-12 mt-2" style="height: 87.5px;">
                    <!-- <h5>Important Remarks</h5>
                    <div class="row p-2">
                        <div class="col-12 pr-0">
                            <table class="table table-striped --tablee">
                                <tbody>
                                  <tr>
                                    <td style="width:110px">Message</td>
                                    <th style="word-break: break-all;" scope="row">
                                        
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>

        
    <div class="card m-0 p-0 col-12 pl-5 pr-5 mt-4">
      <div class="modal-header">
        <h4 class="modal-title font-weight-bold">Custom App Theme</h4>
      </div>
    
      <div class="modal-body pr-0 pt-0">
        <div class="row p-0 m-0">
          <div class="col-9 box-2 row p-0 m-0" [formGroup]="customFormGroup">
            <div class="col-12 pb-2">
              <div class="w-100 d-flex justify-content-center">
                <button 
                      class="btn btn-light mt-2 ml-5" 
                      style="position: absolute; top: 0; left: 0; z-index: 1;"
                      (click)="uploadLandscape(landscapeModal)"
                  >
                    <i class="fa fa-edit"></i> &nbsp; Upload Logo (Landscape)
                </button>

                <img [src]="customFormGroup.controls.imageLandscapeUrl.value" alt="" height="170" width="auto" style="max-width: 800px">
              </div>
            </div>
  
            <div class="col-3 w-100 d-flex justify-content-center">
              <!-- <img class="box-2" [src]="customFormGroup.controls.imageUrl.value" alt="" height="150" width="150"> -->
              <div class="w-100">
                <div class="mmedia mmedia-2x1 r" style="position: relative; display: flex; justify-content: center; align-items: center;">
                  <button 
                      class="btn btn-light mt-1 ml-5" 
                      style="position: absolute; top: 0; left: 0; z-index: 1;"
                      (click)="uploadLogo(logoModal)"
                  >
                      <i class="fa fa-edit"></i>
                  </button>

                  <img class="box-2" [src]="customFormGroup.controls.imageUrl.value" alt="" height="160" width="160">
                  <!-- <img [src]="customFormGroup.controls.imageUrl.value" alt="" style="max-width: 100%; max-height: 500px;"> -->
                </div>
              </div>
            </div>
            <div class="col-6 d-flex w-100 justify-content-center align-items-center">
              <div class="d-flex flex-column justify-content-center">
                <div class="d-flex">
                  <button 
                        class="btn btn-light mr-2" 
                        (click)="openUpdateCustomName(editCustomAppName)"
                      >
                        <i class="fa fa-edit"></i>
                  </button>
                  <p style="font-size: 28px; font-weight: bolder; color: black; margin: 0;">
                    {{ customFormGroup.controls.customAppName.value }} 
                  </p>
                </div>
                <h5 class="ml-5">Username: {{ username }} </h5>
              </div>
            </div>
            <div class="col-3 d-flex">
              <div class="w-100 d-flex flex-column justify-content-center" style="height: 150;">
                <div class="w-100 d-flex justify-content-end">
                  <h5>Mode: </h5>
                  <label class="switch ml-2 mr-5" title="Mode: To set the mood of your custom app">
                    <span class="sun"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g fill="#ffd43b"><circle r="5" cy="12" cx="12"></circle><path d="m21 13h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm-17 0h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm13.66-5.66a1 1 0 0 1 -.66-.29 1 1 0 0 1 0-1.41l.71-.71a1 1 0 1 1 1.41 1.41l-.71.71a1 1 0 0 1 -.75.29zm-12.02 12.02a1 1 0 0 1 -.71-.29 1 1 0 0 1 0-1.41l.71-.66a1 1 0 0 1 1.41 1.41l-.71.71a1 1 0 0 1 -.7.24zm6.36-14.36a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm0 17a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm-5.66-14.66a1 1 0 0 1 -.7-.29l-.71-.71a1 1 0 0 1 1.41-1.41l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.29zm12.02 12.02a1 1 0 0 1 -.7-.29l-.66-.71a1 1 0 0 1 1.36-1.36l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.24z"></path></g></svg></span>
                    <span class="moon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="m223.5 32c-123.5 0-223.5 100.3-223.5 224s100 224 223.5 224c60.6 0 115.5-24.2 155.8-63.4 5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6-96.9 0-175.5-78.8-175.5-176 0-65.8 36-123.1 89.3-153.3 6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"></path></svg></span>   
                    <input type="checkbox" class="input" formControlName="mode">
                    <span class="slider"></span>
                  </label>
                </div>
                <!-- <div class="w-100 d-flex justify-content-end" *ngIf="customFormGroup.controls.status.value == 'Approved'" [formGroup]="customFormGroup">
                  <h5>Extras: </h5>
                  <label class="switch1 ml-2 mr-3">
                    <input class="toggle1" type="checkbox" formControlName="extras">
                    <span class="slider1"></span>
                    <span class="card-side"></span>
                  </label>
                </div> -->
              </div>
              
            </div>
            <div class="col-6 pt-3">
              <div class="form-group row">
                <label class="col-sm-7">
                  Background Color: 
                  <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                    title="This will set the background color of the app."></i>
                </label>
                <div class="col-sm-5">
                  <div>
                    <input 
                        class="input"
                        [colorPicker]="customFormGroup.controls.backgroundColor.value" 
                        [style.background]="customFormGroup.controls.backgroundColor.value" 
                        formControlName="backgroundColor"
                        (colorPickerChange)="updateBackgroundColor($event, 'backgroundColor')"
                    />
                  </div>
                </div>
              </div>
              
  
              <div class="form-group row">
                <label class="col-sm-7">
                  Button Color:
                  <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                    title="This will set the color of the buttons. For best results, please match the colors in Button Color, Primary Color and Primary Font Color."></i>
                </label>
                <div class="col-sm-5 d-flex">
                  <div class="align-items-end">
                    <input 
                        class="input"
                        [colorPicker]="customFormGroup.controls.buttonColor.value" 
                        [style.background]="customFormGroup.controls.buttonColor.value" 
                        formControlName="buttonColor"
                        (colorPickerChange)="updateBackgroundColor($event,'buttonColor')"
                    />
                  </div>
                </div>
              </div>
  
              <div class="form-group row">
                <label class="col-sm-7">
                  Button Font Color:
                  <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                  title="This will set the font color of the buttons."></i>
                </label>
                <div class="col-sm-5 d-flex">
                  <div class="align-items-end">
                    <input 
                        class="input"
                        [colorPicker]="customFormGroup.controls.buttonFontColor.value" 
                        [style.background]="customFormGroup.controls.buttonFontColor.value" 
                        formControlName="buttonFontColor"
                        (colorPickerChange)="updateBackgroundColor($event,'buttonFontColor')"
                    />
                  </div>
                </div>
              </div>
  
              <div class="form-group row">
                <label class="col-sm-7">
                  Primary Color:
                  <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                  title="This will set the colors for the wallet background icons. For best results, please match the colors in Button Color, Primary Color and Primary Font Color."></i>
                </label>
                <div class="col-sm-5 d-flex">
                  <div class="align-items-end">
                    <input 
                        class="input"
                        [colorPicker]="customFormGroup.controls.primaryColor.value" 
                        [style.background]="customFormGroup.controls.primaryColor.value" 
                        formControlName="primaryColor"
                        (colorPickerChange)="updateBackgroundColor($event,'primaryColor')"
                    />
                  </div>
                </div>
              </div>
  
              <div class="form-group row">
                <label class="col-sm-7">
                  Primary Font Color:
                  <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                  title="	This will set the color for the Vips Kabuhayan Program. For best results, please match the colors in Button Color, Primary Color and Primary Font Color."></i>
                </label>
                <div class="col-sm-5 d-flex">
                  <div class="align-items-end">
                    <input 
                        class="input"
                        [colorPicker]="customFormGroup.controls.primaryFontColor.value" 
                        [style.background]="customFormGroup.controls.primaryFontColor.value" 
                        formControlName="primaryFontColor"
                        (colorPickerChange)="updateBackgroundColor($event,'primaryFontColor')"
                    />
                  </div>
                </div>
              </div>
  
              <div class="form-group row">
                <label class="col-sm-7">
                  Primary Wallet Font Color:
                  <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                  title="This will set the font colors for the wallet and Vips Kabuhayan Program"></i>
                </label>
                <div class="col-sm-5 d-flex">
                  <div class="align-items-end">
                    <input 
                        class="input"
                        [colorPicker]="customFormGroup.controls.primaryWalletFontColor.value" 
                        [style.background]="customFormGroup.controls.primaryWalletFontColor.value" 
                        formControlName="primaryWalletFontColor"
                        (colorPickerChange)="updateBackgroundColor($event,'primaryWalletFontColor')"
                    />
                  </div>
                </div>
              </div>
  
            </div>
            <div class="col-6 pt-3">
              <!-- <div>
                <button class="slide-btn prev ml-2" (click)="prevImage()" *ngIf="images.length > 1">❮</button>
                <button class="slide-btn next mr-2" (click)="nextImage()" *ngIf="images.length > 1">❯</button>
                <img class="card-image" [src]="images[currentImageIndex].path" alt="Card image cap" class="w-100 border border-dark">
              </div> -->
              <!-- <div class="mt-3">
                <div class="slider2">
                    <div class="slider2-wrapper pt-1 pb-2" [style.width.px]="imageWidth * images.length + (images.length * 5)">
                        <div class="slider2-item" *ngFor="let image of images">
                            <img class="item-img" (mouseover)="changeBigImage(image.path)" [src]="image.path" [style.width.px]="imageWidth">
                        </div>
                    </div>
                    <button class="slider2-arrow left" *ngIf="images.length >= 6" (click)="scroll(-1)">&lt;</button>
                    <button class="slider2-arrow right" *ngIf="images.length >= 6" (click)="scroll(1)">&gt;</button>
                </div>
              </div> -->
              <div class="w-100">
                <div class="d-flex">
                  <h5 class="mb-0">Banners</h5>
                  <div class="flex"></div>
                  <button 
                    class="btn btn-primary float-right mb-2" 
                    (click)="addBanner(addBannerModal)"
                    [disabled]="images.length >= 10"
                  >
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        fill="" 
                        viewBox="0 0 24 24" 
                        width="16" 
                        height="16"
                    >
                        <g stroke-width="0" id="SVGRepo_bgCarrier"></g>
                        <g stroke-linejoin="round" stroke-linecap="round" id="SVGRepo_tracerCarrier"></g>
                        <g id="SVGRepo_iconCarrier">
                            <path 
                                fill="" 
                                d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z" 
                                clip-rule="evenodd" 
                                fill-rule="evenodd"
                            ></path> 
                        </g>
                    </svg>
                    &nbsp; Add Banner
                  </button>
                </div>
                <div class="mmedia mmedia-2x1 r border border-dark" style="position: relative; display: flex; justify-content: center; align-items: center; max-width: 100%;">
                  <button class="btn btn-light mt-1 ml-1" style="position: absolute; top: 0; left: 0; z-index: 1;" disabled>
                    {{images.length}}/10
                  </button>
              
                  <button 
                      class="btn btn-light mt-1 mr-1" 
                      style="position: absolute; top: 0; right: 0; z-index: 1; border-radius: 50%; font-weight: bolder; background-color: rgba(255, 255, 255, 0.3); transition: background-color 0.3s, box-shadow 0.3s;"
                      (click)="removeBanner()"
                  >
                      <i class="fas fa-times"></i> <!-- Font Awesome X icon -->
                  </button>
              
                  <img [src]="images.length > 0 ? images[currentImageIndex]?.path : '../../../../assets/vips2/empty2.png'" alt="" style="max-width: 100%; max-height: 215.42px; height: 215.42px;">
                  <!-- Navigation buttons -->
                  <button class="slide-btn prev" (click)="prevImage()" *ngIf="images.length > 1">❮</button>
                  <button class="slide-btn next" (click)="nextImage()" *ngIf="images.length > 1">❯</button>
                </div>
              </div>
            </div>
            
          </div>
          
          <div class="col-3 p-2 w-100 d-flex flex-column">
            <div class="w-100 d-flex justify-content-center">
              <h3 class="font-weight-bold">My QR</h3>
            </div>
            <div class="w-100 d-flex justify-content-center">
              <ngx-qrcode
                [elementType]="elementType"
                [errorCorrectionLevel]="correctionLevel"
                [value]="qrCode"
                cssClass="bshadow"
                [width]="200"
                style='border-radius: 2%'
              ></ngx-qrcode>
            </div>
            <div class="w-100 d-flex justify-content-center">
              <h5>Status: (
                <span class="text-primary" *ngIf="customFormGroup.controls.status.value == 'Approved'">
                  {{ customFormGroup.controls.status.value }} 
                  <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                  title="Approved: Your Custom App is now published"></i>
                </span>
                <span class="text-info" *ngIf="customFormGroup.controls.status.value == 'Applied'">
                  {{ customFormGroup.controls.status.value }} 
                  <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                  title="Applied: Your application is now pending for approval"></i>
                </span>
                <span class="text-success" *ngIf="customFormGroup.controls.status.value == 'Saved'">
                  {{ customFormGroup.controls.status.value }}
                  <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                    title="Saved: Your customization has been saved. &#13;To apply, just hit the Send Application button and wait for approval.">
                  </i>
                </span>
                <span class="text-danger" *ngIf="customFormGroup.controls.status.value == 'Rejected'">
                  {{ customFormGroup.controls.status.value }}
                  <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                  [title]="customVips?.remarks ? 'Rejected: ' + customVips.remarks : ''"></i>
                </span>
                <span class="text-dark" *ngIf="customFormGroup.controls.status.value == ''">
                  Unsaved
                </span>
                )
              </h5>
            </div>
            <div class="d-flex flex-column align-items-center">
              <!--*ngIf="customFormGroup.controls.status.value == 'Applied'" <button class="button-confirm mb-2 mt-2" style="width: 100px;" (click)="openConfirmation(verifyApplicationModal, selectedItem)">
                  Approve
              </button>
              <button class="button-confirm" style="width: 100px;"  (click)="openConfirmation(rejectApplicationModal, selectedItem)">
                  Reject
              </button> -->
              <button class="button-confirm mb-2 mt-2" style="width: 160px;" (click)="previewChanges(designViewModal)">Preview Changes <i class="fa fa-eye"></i></button>
              <button class="button-confirm mb-5" style="width: 160px;" (click)="openConfirmReset(confirmReset)">Delete Custom App <i class="fa fa-eye"></i></button>
              <button class="button-confirm mb-2" style="width: 160px;" (click)="saveChanges()">Save Changes <i class="fa fa-save"></i></button>
              <button class="button-confirm" style="width: 160px;" (click)="applyChanges()">Send Application <i class="fa fa-arrow-right"></i></button>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="b-b">
        <div class="nav-active-border b-primary bottom">
          <ul class="nav" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active font-weight-bold" id="home-tab" data-toggle="tab" 
              href="#home3" role="tab" aria-controls="home" aria-selected="true">Vips Customization</a>
            </li>
            <li class="nav-item">
              <a class="nav-link  font-weight-bold" id="profile-tab" data-toggle="tab" 
              href="#profile3" role="tab" aria-controls="profile" aria-selected="false">Banners</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="tab-content pl-3 pl-3 pb-0 pt-0">
        <div class="tab-pane fade active show" id="home3" role="tabpanel" aria-labelledby="home-tab">
          <div class="col-12 mt-2 ml-0 mr-0 mb-0 p-0" style="max-height: 316px;">
            <div class="row box-2" [formGroup]="customFormGroup">
              <div class="col-12 p-2 d-flex justify-content-between align-items-center">
                <div>
                  <h5 class="mr-5">Status: (
                    <span class="text-primary" *ngIf="customFormGroup.controls.status.value == 'Approved'">
                      {{ customFormGroup.controls.status.value }} 
                      <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                      title="Approved: Your Custom App is now published"></i>
                    </span>
                    <span class="text-info" *ngIf="customFormGroup.controls.status.value == 'Applied'">
                      {{ customFormGroup.controls.status.value }} 
                      <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                      title="Applied: Your application is now pending for approval"></i>
                    </span>
                    <span class="text-success" *ngIf="customFormGroup.controls.status.value == 'Saved'">
                      {{ customFormGroup.controls.status.value }}
                      <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                        title="Saved: Your customization has been saved. &#13;To apply, just hit the Send Application button and wait for approval.">
                      </i>
                    </span>
                    <span class="text-danger" *ngIf="customFormGroup.controls.status.value == 'Rejected'">
                      {{ customFormGroup.controls.status.value }}
                      <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                      [title]="customVips?.remarks ? 'Rejected: ' + customVips.remarks : ''"></i>
                    </span>
                    <span class="text-dark" *ngIf="customFormGroup.controls.status.value == ''">
                      Unsaved
                    </span>
                  )
                </h5>
                </div>
                <div class="d-flex"></div>
                <div class="d-flex">
                  <h5>Mode: </h5>
                  <label class="switch ml-2 mr-5" title="Mode: To set the mood of your custom app">
                    <span class="sun"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g fill="#ffd43b"><circle r="5" cy="12" cx="12"></circle><path d="m21 13h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm-17 0h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm13.66-5.66a1 1 0 0 1 -.66-.29 1 1 0 0 1 0-1.41l.71-.71a1 1 0 1 1 1.41 1.41l-.71.71a1 1 0 0 1 -.75.29zm-12.02 12.02a1 1 0 0 1 -.71-.29 1 1 0 0 1 0-1.41l.71-.66a1 1 0 0 1 1.41 1.41l-.71.71a1 1 0 0 1 -.7.24zm6.36-14.36a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm0 17a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm-5.66-14.66a1 1 0 0 1 -.7-.29l-.71-.71a1 1 0 0 1 1.41-1.41l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.29zm12.02 12.02a1 1 0 0 1 -.7-.29l-.66-.71a1 1 0 0 1 1.36-1.36l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.24z"></path></g></svg></span>
                    <span class="moon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="m223.5 32c-123.5 0-223.5 100.3-223.5 224s100 224 223.5 224c60.6 0 115.5-24.2 155.8-63.4 5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6-96.9 0-175.5-78.8-175.5-176 0-65.8 36-123.1 89.3-153.3 6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"></path></svg></span>   
                    <input type="checkbox" class="input" formControlName="mode">
                    <span class="slider"></span>
                  </label>
                  
                  <button class="button-confirm mr-2" (click)="previewChanges(designViewModal)">Preview Changes <i class="fa fa-eye"></i></button>
                  <button class="button-confirm ml-5" (click)="saveChanges()">Save Changes <i class="fa fa-save"></i></button>
                  <button class="button-confirm ml-1" (click)="applyChanges()">Send Application <i class="fa fa-arrow-right"></i></button>
                </div>
              </div>
              <div class="col-4">
                <h6>Custom Color</h6>
                <hr>

                <div class="form-group row">
                  <label class="col-sm-7">Custom App Name:</label>
                  <div class="col-sm-5">
                    <div>
                      <input 
                        class="input"
                        formControlName="customAppName"
                      />
                    </div>
                  </div>
                </div>
                
                <div class="form-group row">
                  <label class="col-sm-7">
                    Background Color: 
                    <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                      title="This will set the background color of the app."></i>
                  </label>
                  <div class="col-sm-5">
                    <div>
                      <input 
                        class="input"
                        [colorPicker]="customFormGroup.controls.backgroundColor.value" 
                        [style.background]="customFormGroup.controls.backgroundColor.value" 
                        formControlName="backgroundColor"
                        (colorPickerChange)="updateBackgroundColor($event, 'backgroundColor')"
                      />
                    </div>
                  </div>
                </div>
                

                <div class="form-group row">
                  <label class="col-sm-7">
                    Button Color:
                    <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                      title="This will set the color of the buttons. For best results, please match the colors in Button Color, Primary Color and Primary Font Color."></i>
                  </label>
                  <div class="col-sm-5 d-flex">
                    <div class="align-items-end">
                      <input 
                        class="input"
                        [colorPicker]="customFormGroup.controls.buttonColor.value" 
                        [style.background]="customFormGroup.controls.buttonColor.value" 
                        formControlName="buttonColor"
                        (colorPickerChange)="updateBackgroundColor($event,'buttonColor')"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-7">
                    Button Font Color:
                    <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                    title="This will set the font color of the buttons."></i>
                  </label>
                  <div class="col-sm-5 d-flex">
                    <div class="align-items-end">
                      <input 
                        class="input"
                        [colorPicker]="customFormGroup.controls.buttonFontColor.value" 
                        [style.background]="customFormGroup.controls.buttonFontColor.value" 
                        formControlName="buttonFontColor"
                        (colorPickerChange)="updateBackgroundColor($event,'buttonFontColor')"
                      />
                      </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-7">
                    Primary Color:
                    <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                    title="This will set the colors for the wallet background icons. For best results, please match the colors in Button Color, Primary Color and Primary Font Color."></i>
                  </label>
                  <div class="col-sm-5 d-flex">
                    <div class="align-items-end">
                      <input 
                        class="input"
                        [colorPicker]="customFormGroup.controls.primaryColor.value" 
                        [style.background]="customFormGroup.controls.primaryColor.value" 
                        formControlName="primaryColor"
                        (colorPickerChange)="updateBackgroundColor($event,'primaryColor')"
                      />
                      </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-7">
                    Primary Font Color:
                    <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                    title="	This will set the color for the Vips Kabuhayan Program. For best results, please match the colors in Button Color, Primary Color and Primary Font Color."></i>
                  </label>
                  <div class="col-sm-5 d-flex">
                    <div class="align-items-end">
                      <input 
                        class="input"
                        [colorPicker]="customFormGroup.controls.primaryFontColor.value" 
                        [style.background]="customFormGroup.controls.primaryFontColor.value" 
                        formControlName="primaryFontColor"
                        (colorPickerChange)="updateBackgroundColor($event,'primaryFontColor')"
                      />
                      </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-7">
                    Primary Wallet Font Color:
                    <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                    title="This will set the font colors for the wallet and Vips Kabuhayan Program"></i>
                  </label>
                  <div class="col-sm-5 d-flex">
                    <div class="align-items-end">
                      <input 
                        class="input"
                        [colorPicker]="customFormGroup.controls.primaryWalletFontColor.value" 
                        [style.background]="customFormGroup.controls.primaryWalletFontColor.value" 
                        formControlName="primaryWalletFontColor"
                        (colorPickerChange)="updateBackgroundColor($event,'primaryWalletFontColor')"
                      />
                      </div>
                  </div>
                </div>

              </div>
              <div class="col-4 pl-5 pr-5 pb-2"> 
                <h6>
                  Custom Image (Landscape) 
                  <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                    title="This logo will appear as the logo header in the app."></i>
                </h6>
                <hr>
                <div class="d-flex justify-content-center align-items-center" style="height: 170.69px;">
                  <img class="pb-2" [src]="croppedImage" style="max-height: 170.69px; max-width: 450px;" />
                </div>
                
                <button class="input float-right" (click)="uploadLandscape(landscapeModal)">Upload Image</button>

              </div>
              <div class="col-4 pl-5 pr-5 pb-2">
                <h6>Custom Image (Logo)
                  <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                    title="This logo will replace all the existing Vips logo in the app."></i>
                </h6>
                <hr>
                <div class="d-flex justify-content-center align-items-center">
                  <img class="pb-2" [src]="croppedLogo" style="height: 170.69px"/>
                </div>

                <button class="input float-right" (click)="uploadLogo(logoModal)">Upload Logo</button>

              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="profile3" role="tabpanel" aria-labelledby="profile-tab">
          <div class="col-12 mt-2 ml-0 mr-0 mb-0 p-0" style="max-height: 316px;">
            <div class="row box-2" [formGroup]="customFormGroup">
              <div class="col-12 p-2 d-flex justify-content-between align-items-center">
                <div>
                </div>
                <div class="d-flex"></div>
                <div class="d-flex">                 
                </div>
              </div>
              <div class="col-12 row">
                <div class="col-6 p-3" style="border-radius: 15px;">
                  <div>
                      <img class="card-image" [src]="cardImage" alt="Card image cap">
                  </div>
                </div>
                <div class="col-6" style="border-radius: 15px;">
                  <div class="mt-3">
                    <div class="slider1">
                        <div class="slider1-wrapper pt-1 pb-2" [style.width.px]="imageWidth * images.length + (images.length * 5)">
                            <div class="slider1-item" *ngFor="let image of images">
                                <img class="item-img" (mouseover)="changeBigImage(image.path)" [src]="image.path" [style.width.px]="imageWidth">
                            </div>
                        </div>
                        <button class="slider1-arrow left" *ngIf="images.length >= 6" (click)="scroll(-1)">&lt;</button>
                        <button class="slider1-arrow right" *ngIf="images.length >= 6" (click)="scroll(1)">&gt;</button>
                    </div>
                    <div class="mt-2">
                        <button class="item-img btn btn-sm btn-primary text-dark" (click)="addBanner(addBannerModal)"><i class="fa fa-plus"></i></button>
                    </div>
                  </div>
                </div>

              
              </div>

            </div>
          </div>
        </div>
      </div> -->
    </div> 

        
    </div>
</div>

  <div #govipxQR hidden>
    <ngx-qrcode
      [elementType]="elementType"
      [errorCorrectionLevel]="correctionLevel"
      [value]="value"
      cssClass="bshadow"
      [width]="300"
      style='margin-left:21px; margin-top:290px; border-radius: 2%'
    ></ngx-qrcode>
  </div>
  
  <div #vipLogo hidden>
    <img src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file9828bf15-729f-4650-97a6-476c13f02ce0.png" alt="Go-VIP-Logo-Logo-Small" />
  </div>
  
  <ng-template #myQRTemplateModal let-modal>
    <!-- background: url(../../../assets/images/png\ QR.png) !important 
    linear-gradient(#044660, #02554c)-->
    
    <button class="btn btn-dark btn-lg" (click)="getQR(myQRTemplate)">Print &nbsp; <i class="fa fa-print"></i></button>
    <div class="d-flex justify-content-center">
      <div #myQRTemplate >
          <div style="background: url(../../../assets/images/png\ QR.png);
          background-repeat: no-repeat !important;
          background-size: 210mm 297mm !important;
          width: 210mm !important;
          height: 297mm !important;" 
          >
            <div style='display:flex; justify-content: center'>
              <ngx-qrcode
                [elementType]="elementType"
                [errorCorrectionLevel]="correctionLevel"
                [value]="value"
                cssClass="bshadow"
                [width]="300"
                style='margin-left:21px; margin-top:290px; border-radius: 2%'
              ></ngx-qrcode>
            </div>
            <div style='display:flex; justify-content: center; padding-top: 45px'>
                <h3 
                style='margin-left:27px; font-family: "Segoe UI", Arial, sans-serif; max-width: 360px; text-align:center; font-weight: 700'
                >
                    <!-- {{dti}} -->
                </h3>
            </div>
          </div>
        </div>
    </div>
  </ng-template>

  <ng-template #editCustomAppName let-modal>
    <div class="modal-header">
      <h5 class="modal-title">Custom App Name</h5>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body pt-0">
      <div class="pl-1 pr-1 mb-2" [formGroup]="customFormGroup">
        <input 
          class=" w-100 border border-dark"
          formControlName="customAppName"
        />
      </div>
      <button class="btn btn-light float-right" (click)="modal.dismiss('Cross click')">
        Set
      </button>
    </div>
  </ng-template>

  <ng-template #landscapeModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Upload Logo (Landscape)</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body pt-0">
      <input type="file" (change)="fileLandScapeChangeEvent($event)" />
      <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="false"
          [aspectRatio]="10 / 2.5"
          format="png"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded($event)"
          (cropperReady)="cropperReady()"
          (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
      <hr>
      <button class="input float-right" (click)="uploadCustomImage()">
        Upload
      </button>
    </div>
  </ng-template>

  <ng-template #logoModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Upload Logo (Square)</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body pt-0">
      <input type="file" (change)="fileChangeEvent($event)" />
      <image-cropper
          [imageChangedEvent]="logoChangedEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="1 / 1"
          format="png"
          (imageCropped)="logoCropped($event)"
          (imageLoaded)="logoLoaded($event)"
          (cropperReady)="cropperLogoReady()"
          (loadImageFailed)="loadLogoFailed()"
      ></image-cropper>
      <hr>
      <button class="input float-right" (click)="uploadCustomLogo()">
        Upload
      </button>
    </div>
  </ng-template>

  <ng-template #designViewModal let-modal>
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Mobile Preview</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- Your content goes here -->
        <div class="box-2 mobile-preview" [style.background-color]="customFormGroup.controls.backgroundColor.value">
          <!-- Start Notif Bar -->
            <div class="d-flex pt-2 pr-3 pl-3 pb-2">
              <div>
                <p class="font-weight-bold mb-0 p-0">9:41 AM</p>
              </div>
              <div class="flex"></div>
              <div>
                <i class="fa fa-signal"></i>
                <i class="fa fa-wifi ml-2"></i>
                <i class="fa fa-battery-full ml-2"></i>
              </div>
            </div>
            <!-- End Notif Bar -->

            <!-- Logo -->
            <div class="d-flex pl-3 pr-3" [style.background-color]="customFormGroup.controls.backgroundColor.value">
              <div>
                 <img class="pt-2" [src]="croppedImage" style="height: 45px"/>
              </div>
              <div class="flex"></div>
              <div>
                <i class="fa fa-bell" [style.color]="colorServiceInstance.colorShade700(customFormGroup.controls.primaryColor.value.replace('#',''))"></i>
              </div>
            </div>
            <!-- End Logo -->

            <!-- Wallet Container -->
            <div class="card wallet-container mt-2 ml-3 mr-3 mb-2" >
              <div class="d-flex pt-2 pl-2 pr-2" [style.background-color]="colorServiceInstance.colorShade400(customFormGroup.controls.primaryColor.value.replace('#',''))">
                <div>
                    <p class="wallet mb-0 pb-0" [style.color]="customFormGroup.controls.primaryWalletFontColor.value">
                      <span style="font-size: 11px; margin-right: 2px;">PHP</span> 
                      <span style="font-size: 13px; font-weight: bold;">99,000.00</span>
                    </p>
                    <p style="font-size: 10px;" [style.color]="customFormGroup.controls.primaryWalletFontColor.value">VIPco Balance</p>
                </div>
                <div class="flex"></div>
                <div>
                  <i class="fa fa-eye" [style.color]="customFormGroup.controls.primaryWalletFontColor.value"></i>
                </div>
              </div>

              <div class="d-flex pl-2 pr-2 justify-content-end" [style.background-color]="colorServiceInstance.colorShade400(customFormGroup.controls.primaryColor.value.replace('#',''))">
                <div>
                  <p style="font-size: 9px; margin-bottom: 5px;" [style.color]="customFormGroup.controls.primaryWalletFontColor.value">Account No. 900220001049</p>
                </div>
              </div>

              <div class="d-flex pl-2 pr-2" [style.background-color]="colorServiceInstance.colorShade700(customFormGroup.controls.primaryColor.value.replace('#',''))">
                <div>
                  <p class="wallet mb-0 pb-0" [style.color]="customFormGroup.controls.primaryWalletFontColor.value">
                    <span style="font-size: 10px; margin-right: 2px;">PHP</span> 
                    <span style="font-size: 11px; font-weight: bold;">1,300.00</span>
                  </p>
                  <p style="font-size: 10px; margin-bottom: 5px;" [style.color]="customFormGroup.controls.primaryWalletFontColor.value">VIPS KABUHAYAN PROGRAM</p>
                </div>
                <div class="flex"></div>
                <div style="height: 39px;" class="d-flex justify-content-center align-items-center mr-1">
                  <i class="fa fa-angle-down" style="font-size: 20px; font-weight: bold;" [style.color]="customFormGroup.controls.primaryWalletFontColor.value"></i>
                </div>
              </div>
            </div>
            <!-- Wallet Container -->

            <!-- Live Container -->
            <div class="card ml-3 mr-3 mb-3" [style.background-color]="customFormGroup.controls.backgroundColor.value">
              <div class="d-flex">
                <div class="wallet-container mr-2 p-1 d-flex flex-column justify-content-center align-items-center" [style.background-color]="colorServiceInstance.colorShade50(customFormGroup.controls.primaryColor.value.replace('#',''))">
                  <!-- <img src="../../../../assets/vipsicon/Icon.png" alt="" width="25px"> -->
                  <div class="fa fa-headset" style="font-size: 20px;" [style.color]="colorServiceInstance.colorShade900(customFormGroup.controls.primaryColor.value.replace('#',''))"></div>
                  <p style="font-size: 8px; margin-bottom: 0; font-weight: bold;" [style.color]="colorServiceInstance.colorShade900(customFormGroup.controls.primaryColor.value.replace('#',''))">Live Chat</p>
                </div>
                <div class="wallet-container d-flex flex p-1" [style.background-color]="colorServiceInstance.colorShade50(customFormGroup.controls.primaryColor.value.replace('#',''))">
                  <div class="d-flex flex-column">
                    <p style="font-size: 7px; margin-bottom: 6px;" [style.color]="colorServiceInstance.colorShade900(customFormGroup.controls.primaryColor.value.replace('#',''))">Check out our video libraries created <br>just for you</p>
                    <p style="font-size: 7px; margin-bottom: 0; font-weight: bold;" [style.color]="colorServiceInstance.colorShade900(customFormGroup.controls.primaryColor.value.replace('#',''))">PLAY VIDEO TUTORIALS</p>
                  </div>
                  <div class="flex"></div>
                  <div style="height: 39px;" class="d-flex justify-content-center align-items-center mr-2">
                    <i class="fa fa-play" style="font-size: 20px; font-weight: bold;" [style.color]="colorServiceInstance.colorShade900(customFormGroup.controls.primaryColor.value.replace('#',''))"></i>
                  </div>
                </div>
              </div>
            </div>
            <!-- Live Container -->

            <!-- Services -->

            <div class="card ml-3 mr-3 mb-2" [style.background-color]="customFormGroup.controls.backgroundColor.value">
              <div class="d-flex flex-wrap">
                
                <div class="col-3 mb-2">
                  <div class="wallet-container flex-fill p-1 d-flex flex-column justify-content-center align-items-center">
                    <div class="fa fa-qrcode" style="font-size: 20px;" [style.color]="colorServiceInstance.colorShade700(customFormGroup.controls.primaryColor.value.replace('#',''))"></div>
                    <div class="flex"></div>
                    <p style="font-size: 6px; margin-bottom: 5px; font-weight: bold;" [style.color]="colorServiceInstance.colorShade700(customFormGroup.controls.primaryColor.value.replace('#',''))">MY QR</p>
                  </div>
                </div>
            
                <div class="col-3 mb-2">
                  <div class="wallet-container flex-fill p-1 d-flex flex-column justify-content-center align-items-center">
                    <div class="fa fa-expand" style="font-size: 20px;" [style.color]="colorServiceInstance.colorShade700(customFormGroup.controls.primaryColor.value.replace('#',''))"></div>
                    <div class="flex"></div>
                    <p style="font-size: 6px; margin-bottom: 5px; font-weight: bold;" [style.color]="colorServiceInstance.colorShade700(customFormGroup.controls.primaryColor.value.replace('#',''))">SCAN QR</p>
                  </div>
                </div>
            
                <div class="col-3 mb-2">
                  <div class="wallet-container flex-fill p-1 d-flex flex-column justify-content-center align-items-center">
                    <div class="fa fa-paper-plane" style="font-size: 20px;" [style.color]="colorServiceInstance.colorShade700(customFormGroup.controls.primaryColor.value.replace('#',''))"></div>
                    <div class="flex"></div>
                    <p style="font-size: 6px; margin-bottom: 5px; font-weight: bold;" [style.color]="colorServiceInstance.colorShade700(customFormGroup.controls.primaryColor.value.replace('#',''))">SEND</p>
                  </div>
                </div>
            
                <div class="col-3 mb-2">
                  <div class="wallet-container flex-fill p-1 d-flex flex-column justify-content-center align-items-center">
                    <div class="fa fa-receipt" style="font-size: 20px;" [style.color]="colorServiceInstance.colorShade700(customFormGroup.controls.primaryColor.value.replace('#',''))"></div>
                    <div class="flex"></div>
                    <p style="font-size: 6px; margin-bottom: 5px; font-weight: bold;" [style.color]="colorServiceInstance.colorShade700(customFormGroup.controls.primaryColor.value.replace('#',''))">PAYBILLS</p>
                  </div>
                </div>
            
                <div class="col-3 mb-2">
                  <div class="wallet-container flex-fill p-1 d-flex flex-column justify-content-center align-items-center">
                    <div class="fa fa-wallet" style="font-size: 20px;" [style.color]="colorServiceInstance.colorShade700(customFormGroup.controls.primaryColor.value.replace('#',''))"></div>
                    <div class="flex"></div>
                    <p style="font-size: 6px; margin-bottom: 5px; font-weight: bold;" [style.color]="colorServiceInstance.colorShade700(customFormGroup.controls.primaryColor.value.replace('#',''))">TOP UP</p>
                  </div>
                </div>
            
                <div class="col-3 mb-2">
                  <div class="wallet-container flex-fill p-1 d-flex flex-column justify-content-center align-items-center">
                    <div class="fa fa-credit-card" style="font-size: 20px;" [style.color]="colorServiceInstance.colorShade700(customFormGroup.controls.primaryColor.value.replace('#',''))"></div>
                    <div class="flex"></div>
                    <p style="font-size: 6px; margin-bottom: 5px; font-weight: bold;" [style.color]="colorServiceInstance.colorShade700(customFormGroup.controls.primaryColor.value.replace('#',''))">CASHOUT</p>
                  </div>
                </div>
            
                <div class="col-3 mb-2">
                  <div class="wallet-container flex-fill p-1 d-flex flex-column justify-content-center align-items-center">
                    <div class="fa fa-birthday-cake" style="font-size: 20px;" [style.color]="colorServiceInstance.colorShade700(customFormGroup.controls.primaryColor.value.replace('#',''))"></div>
                    <div class="flex"></div>
                    <p style="font-size: 6px; margin-bottom: 5px; font-weight: bold;" [style.color]="colorServiceInstance.colorShade700(customFormGroup.controls.primaryColor.value.replace('#',''))">FOOD</p>
                  </div>
                </div>
            
                <div class="col-3 mb-2">
                  <div class="wallet-container flex-fill p-1 d-flex flex-column justify-content-center align-items-center">
                    <div class="fa fa-box" style="font-size: 20px;" [style.color]="colorServiceInstance.colorShade700(customFormGroup.controls.primaryColor.value.replace('#',''))"></div>
                    <div class="flex"></div>
                    <p style="font-size: 6px; margin-bottom: 5px; font-weight: bold;" [style.color]="colorServiceInstance.colorShade700(customFormGroup.controls.primaryColor.value.replace('#',''))">EXPRESS</p>
                  </div>
                </div>
            
                <div class="col-3 mb-2">
                  <div class="wallet-container flex-fill p-1 d-flex flex-column justify-content-center align-items-center">
                    <div class="fa fa-shopping-cart" style="font-size: 20px;" [style.color]="colorServiceInstance.colorShade700(customFormGroup.controls.primaryColor.value.replace('#',''))"></div>
                    <div class="flex"></div>
                    <p style="font-size: 6px; margin-bottom: 5px; font-weight: bold;" [style.color]="colorServiceInstance.colorShade700(customFormGroup.controls.primaryColor.value.replace('#',''))">PABILI</p>
                  </div>
                </div>
            
                <div class="col-3 mb-2">
                  <div class="wallet-container flex-fill p-1 d-flex flex-column justify-content-center align-items-center">
                    <div class="fa fa-map" style="font-size: 20px;" [style.color]="colorServiceInstance.colorShade700(customFormGroup.controls.primaryColor.value.replace('#',''))"></div>
                    <div class="flex"></div>
                    <p style="font-size: 6px; margin-bottom: 5px; font-weight: bold;" [style.color]="colorServiceInstance.colorShade700(customFormGroup.controls.primaryColor.value.replace('#',''))">TRACKER</p>
                  </div>
                </div>
            
                <div class="col-3 mb-2">
                  <div class="wallet-container flex-fill p-1 d-flex flex-column justify-content-center align-items-center">
                    <div class="fa fa-play" style="font-size: 20px;" [style.color]="colorServiceInstance.colorShade700(customFormGroup.controls.primaryColor.value.replace('#',''))"></div>
                    <div class="flex"></div>
                    <p style="font-size: 6px; margin-bottom: 5px; font-weight: bold;" [style.color]="colorServiceInstance.colorShade700(customFormGroup.controls.primaryColor.value.replace('#',''))">VIPSTREAM</p>
                  </div>
                </div>
                
              </div>
            </div>
            
            <!-- Services -->

            <!-- Banner -->

            <div class="d-flex mb-3">
              <div class="wallet-container bg-secondary mr-2" style="height: 70px; width: 220px">
                <p style="font-size: 10px;">BANNER ADDS OF <br>YOUR CHOICE</p>
              </div>
              <div class="wallet-container bg-secondary" style="height: 70px; width: 100%;">
                <p style="font-size: 10px;">BANNER ADDS OF <br>YOUR CHOICE</p>
              </div>
            </div>

            <!-- Banner -->

            <!-- Footer -->

            <div class="d-flex justify-content-around align-items-center" style="height: 45px" [style.background-color]="colorServiceInstance.colorShade400(customFormGroup.controls.primaryColor.value.replace('#',''))">
              <div>
                <div class="fa fa-users" style="font-size: 20px;" [style.color]="customFormGroup.controls.primaryWalletFontColor.value"></div>
              </div>
              <div>
                <div class="fa fa-clone" style="font-size: 20px;" [style.color]="customFormGroup.controls.primaryWalletFontColor.value"></div>
              </div>
              <div>
                <div class="fa fa-th-large" style="font-size: 20px;" [style.color]="customFormGroup.controls.primaryWalletFontColor.value"></div>
              </div>
              <div>
                <div class="fa fa-file-pdf" style="font-size: 20px;" [style.color]="customFormGroup.controls.primaryWalletFontColor.value"></div>
              </div>
              <div>
                <div class="fa fa-user-circle" style="font-size: 20px;" [style.color]="customFormGroup.controls.primaryWalletFontColor.value"></div>
              </div>
            </div>

            <!-- Footer -->
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #addImageModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Add Image</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
        <ngx-dropzone [multiple]="false" (change)="onSelect($event)" style="height:250px">
            <ngx-dropzone-label>Click to upload or drop the image here!</ngx-dropzone-label>
          
            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" 
            [removable]="true" (removed)="onRemove(f)">
              <ngx-dropzone-label></ngx-dropzone-label>
            </ngx-dropzone-image-preview>
        </ngx-dropzone>
      <!-- <app-stock-item [stockItem]="selectedItem"></app-stock-item> -->
      <hr>

        <div>
          <button class="btn btn-sm btn-primary float-right" (click)="uploadImage()">Upload Image</button>
          <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
            Cancel
          </button>
        </div>
    </div>
  </ng-template>

  <ng-template #addBannerModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Upload Banner</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body pt-0">
      <input type="file" (change)="fileBannerChangeEvent($event)" />
      <image-cropper
          [imageChangedEvent]="bannerChangedEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="12 / 5"
          format="png"
          (imageCropped)="bannerCropped($event)"
          (imageLoaded)="bannerLoaded($event)"
          (cropperReady)="cropperReady()"
          (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
      <hr>
      <button class="input float-right" (click)="uploadBanner()">
        Upload
      </button>
    </div>
  </ng-template>

  <ng-template #confirmReset let-modal>
    <div class="modal-header">
      <h6 class="modal-title">Are you sure you want to delete your customization?</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body pt-0">
      <button class="btn btn-sm btn-danger float-right" (click)="proceedReset()">Proceed</button>
      <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
        Cancel
      </button>
    </div>
  </ng-template>
