<div id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="pt-5 d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">Newsletter Builder</h2>
          <small class="">JuanPay newsletter builder</small>
        </div>
        <div class="flex"></div>
      </div>
    </div>

    <div class="page-content page-container" id="page-content">
      <div class="mt-4" style="padding-right: 0px">
        <div class="card p-3 w-100" *ngIf="webSettingsFormGroup">
          <div class="w-100 d-flex justify-content-end">
            <button
              class="btn btn-sm btn-primary"
              (click)="saveAnnouncenment(webSettingsFormGroup.controls.values.controls[0].controls)"
              style="width: 120px"
            >
              Save &nbsp; <i class="fa fa-save"></i>
            </button>
          </div>
          <hr />

          <div class="w-100">
            <angular-editor
              [(ngModel)]="htmlContent"
              [config]="config"
              [parent-form-group]="webSettingsFormGroup"
            ></angular-editor>
            <!--<br>
                <h5 class="html-header">HTML Output</h5>
                 <div class="html">
                    {{ htmlContent }}
                </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>
