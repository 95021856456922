<div [formGroup]="parentFormGroup" class="form-group row">
  <label class="col-sm-3 col-form-label">{{ label }}</label>
  <div class="col-sm-9">
    <div class="input-group mb-3">
      <input type="hidden" [formControlName]="controlName" />
      <input
        type="text"
        class="form-control"
        aria-label="Text input with dropdown button"
        [id]="id"
        [placeholder]="placeholder"
        [value]="value"
        readonly
      />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary dropdown-toggle"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Choose
        </button>
        <div
          class="dropdown-menu"
          x-placement="bottom-start"
          style="
            position: absolute;
            transform: translate3d(377px, 34px, 0px);
            top: 0px;
            left: 0px;
            will-change: transform;
            max-height: 200px;
            overflow-y: scroll;
          "
        >
          <a class="dropdown-item" *ngFor="let option of data | sort:'key'" (click)="selectedOption(option)">{{ option.value }}</a>
        </div>
      </div>
    </div>

    <span *ngIf="error" class="error-msg"
    ><small><div [innerHTML]="errorMessage"></div></small></span
  >
  </div>
</div>
