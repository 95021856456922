import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SpxRemittanceService {

  private localStorageCacheService: LocalStorageCacheService;

  constructor(
    private httpClient: HttpClient,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
  }
  private httpClientNoInterceptor: HttpClient;

  private baseAPIUrl = environment.API_URL;

  public processSPXRemittance(spx:any,code:any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SPXRemittance/Process`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      spx.pinCode  = code;
      
    return this.httpClient.post(`${endPointUrl}`, spx, { headers: httpHeaders });
  }

  public getSPXTransaction(requestId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SPXRemittance/SPXTransaction/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getTransactions(url: string): Observable<any> {
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${url}`, { headers: httpHeaders });
  }

  public getRiderDetailsByLifetimeID(lifetimeID: string): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SPXRemittance/SPXRiderDetails/${lifetimeID}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getSPXSettlementById(id: string): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SPXRemittance/SPXRemittanceByID/${id}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }
  
  public getGVCSettlementById(id: string): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SPXRemittance/GVCRemittanceByID/${id}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public uploadSPXReceipt(id :any, base64: any, format: any, isgovip): Observable<any> {
    debugger
    const endPointUrl = `${this.baseAPIUrl}/api/SPXRemittance/UploadReceipt`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
  
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");
  
      var json = {
        id : id,
        base64: base64,
        format: format,
        isGoVIP: isgovip
      };
  
      return this.httpClient.put(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public postRiderList(riderList: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SPXRemittance/RiderList`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(`${endPointUrl}`, riderList , { headers: httpHeaders });
  }

  public postDeactivationList(riderList: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SPXRemittance/DeacRiderList`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(`${endPointUrl}`, riderList , { headers: httpHeaders });
  }

  public postRider(riderList: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SPXRemittance/Rider`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(`${endPointUrl}`, riderList , { headers: httpHeaders });
  }

  public putRider(id: any, status: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SPXRemittance/UpdateRiderStatus`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        id: id,
        status: status
      };

    return this.httpClient.put(`${endPointUrl}`, json , { headers: httpHeaders });
  }
}
