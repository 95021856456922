<div class="govip--express--body">



   <!-- <div class="sticky-top inquiry--nav">...</div> -->
   <!-- <nav>
      <div class="inquiry--navbar">
            <i class="fas fa-phone"></i>
            <span> Call us at (02) 8-567-4071 or Message Us:</span>
      </div>
   </nav> -->

<!-- GoVIP Express Navbar -->


   <nav class="navbar navbar-expand-lg navbar-light">
      <a class="navbar-brand" href="/vipco">
         <img class="h-100 w-100 d-inline-block" src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/fileb384beec-970a-44b6-86b6-5d8e11872df3.png" alt="govip-express logo">
      </a>

      <button class="navbar-toggler main--nav--button" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
         <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse nav justify-content-end" id="navbarSupportedContent">
         <ul class="nav justify-content-end">
            <li class="nav-item active">
               <a class="nav-link" href="/vipco">Home <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
               <a class="nav-link" href="vipco/#about">About Us</a>
            </li>
            <li class="nav-item dropdown">
               <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
               Services
               </a>
               <div class="dropdown-menu" aria-labelledby="navbarDropdown">
               <a class="dropdown-item" href="vipco/#services">Express Earn</a>
               <a class="dropdown-item" href="vipco//#services">Express Receive</a>
               <a class="dropdown-item" href="vipco/#services">Express Send</a>
               <a class="dropdown-item" href="vipco/#services">Express Order</a>
               <a class="dropdown-item" href="vipco/#services">Express Buy</a>
               <a class="dropdown-item" href="vipco/#services">Express Pay</a>
               <a class="dropdown-item" href="vipco/#services">Express Exchange</a>
               <a class="dropdown-item" href="vipco/#services">Express Play</a>
               <div class="dropdown-divider"></div>
               <a class="dropdown-item" href="#">Go Back to GoVIP Center</a>
               </div>
            </li>
            <li class="nav-item">
               <a class="nav-link">Contact Us</a>
            </li>
         </ul>
      </div>
   </nav>

   <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#carouselExampleCaptions" 
            *ngFor="let json of contentJson; let i = index" ngClass="{{ i == 0 ? 'active' : '' }}"
            attr.data-slide-to="{{i}}" >
         </li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item" *ngFor="let json of contentJson; let i = index" ngClass="{{ i == 0 ? 'active' : '' }}">
          <img src="{{ json.value.image_url }}" class="d-block w-100" alt="...">
          <div class="carousel-caption d-none d-md-block" ngClass="{{ json.value.position == 'Left' ? 'carousel--right' : 'carousel--left' }}">
            <h5>{{ json.value.header }}</h5>
            <p>{{ json.value.content }}</p>
          </div>
        </div>
      </div>
    </div>

<!-- GoVIPExpress Service Section -->
   <div class="service--section express--section">
      <div class="container-fluid">
         <div id="services" class="section--title">
            <h2>OUR SERVICES</h2>
         </div>

         <div class="card-group px-3">
            <div class="row">
                  <div class="card text-center col-md-2 d-flex justify-content-center" *ngFor="let json of contentJson | slice:1">
                     <img class="card-img-top" src="{{ json.value.thumbnail.icon_url }}" alt="Card image cap" >
                     <div class="card-body">
                        <h5 class="card-title">{{ json.value.thumbnail.title }}</h5>
                        <p class="card-text">{{ json.value.thumbnail.description }}</p>
                     </div>
                  </div>
            </div>
          </div>
      </div> 
   </div>

<!-- GoVIPExpress AboutUs Section -->
   <div id="about" class="aboutus--section express--section">
      <div class="section--title">
         <h2>
            ABOUT US
         </h2>
      </div>
      <div class="aboutus--content">


         <p>
            <strong>VIPCO</strong> is a MEGA App. It aims to provide its users with everything they need and more. From delivery service, to an electronic wallet, even up to being your partner in saving and earning. <strong>VIPCO</strong> provides all services you can possibly think of in a singular app. 
         </p>
         <ul>
            <li><strong>Earn</strong> - points while using the app</li>
            <li><strong>Receive</strong> - money, points using the app</li>
            <li><strong>Send</strong> - money, points using the app</li>
            <li><strong>Order</strong> - delivery service and/or pasabuy, pagawa service</li>
            <li><strong>Buy</strong> - food, grocery items, and more</li>
            <li><strong>Pay</strong> - all your bills using the app</li>
            <li><strong>Exchange</strong> - money, points, cryptocurrency</li>
            <li><strong>Play</strong> - popular games and exclusive content</li>
         </ul> 
         <p>
            <strong>VIPCO</strong> is another product of ACM Business Solution Inc. - the same company that birthed the country's largest payment center, GoVIPCenter. With VIPCO, the company expands its footprint in helping reach every corner of the country, and soon, the rest of the world.
         </p>
      </div>
   </div>

   <!-- GoVIPExpress Careers Section -->
   <div class="career--section express--section">
      <div class="section--title">
         <h2>
            CAREERS
         </h2>
      </div>
      <div class="career--content">
         <h4>
            Join Our Team
         </h4>
         <p>
            Learn more about our promising career opportunities at ACM Business Solutions Inc.
            Search below for our current openings today to find the best fit for you and your career goals.

         </p>
         <p>
            We would love you to be part of our growing family. You can directly email your application letter and CV at <strong>paulgardon@acmbusinesssolutions.net</strong> and we will get back to you as soon as we can for any other opportunities we have for you. All positions require Full-time employment and will work in our Main Office located in Lemon Square Building - Muñoz - EDSA

         </p>
         <h4>
            Current Available Positions
         </h4>

          <div class="card-deck">
            <div class="container-fluid">
               <div class="row">
                  <div class="card border-success mb-3 col-auto">
                     <div class="card-body text-success">
                       <h5 class="card-title">Banking and Finance Graduates or Equivalent</h5>
                       <p class="card-text">Business Area: Finance</p>
                     </div>
                   </div>
      
                   <div class="card border-success mb-3 col-auto">
                     <div class="card-body text-success">
                       <h5 class="card-title">Procurement Officer</h5>
                       <p class="card-text">Business Area: Finance</p>
                     </div>
                   </div>
                   
                   <div class="card border-success mb-3 col-auto">
                     <div class="card-body text-success">
                       <h5 class="card-title">Full Stack Developers</h5>
                       <p class="card-text">Business Area: I.T. Department</p>
                     </div>
                   </div>
      
                   <div class="card border-success mb-3 col-auto">
                     <div class="card-body text-success">
                       <h5 class="card-title">Technical Support Representative</h5>
                       <p class="card-text">Business Area: I.T. Department</p>
                     </div>
                   </div>
               </div>
               <div class="row">
                  <div class="card border-success mb-3 col-auto">
                     <div class="card-body text-success">
                       <h5 class="card-title">Project Manager</h5>
                       <p class="card-text">Business Area: I.T. Department</p>
                     </div>
                   </div>
      
                   <div class="card border-success mb-3 col-auto">
                     <div class="card-body text-success">
                       <h5 class="card-title">Business Analyst</h5>
                       <p class="card-text">Business Area: I.T. Department</p>
                     </div>
                   </div>
      
                   <div class="card border-success mb-3 col-auto">
                     <div class="card-body text-success">
                       <h5 class="card-title">Quality Assurance</h5>
                       <p class="card-text">Business Area: I.T. Department</p>
                     </div>
                   </div>
      
                   <div class="card border-success mb-3 col-auto">
                     <div class="card-body text-success">
                       <h5 class="card-title">UI / UX Designer</h5>
                       <p class="card-text">Business Area: I.T. Department</p>
                     </div>
                   </div>
               </div>
            </div>
          </div>
      </div>
   </div>

   <div class="uniform--section">

      <div class="accordion" id="accordionExample">
         <div class="card">
           <div class="card-header" id="headingOne">
             <h2 class="mb-0">
               <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  PARTNER DRIVER APPLICATION PROCESS
               </button>
             </h2>
           </div>
       
           <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
             <div class="card-body">
               <ol>
                  <li>Download GoVIPExpress app via our website and proceed with the registration.</li>
                  <li>After completing registration, you will be given a code that you need to present to our partner stores as well as the list of requirements you need to submit.</li>
                  <li>Search for the nearest VIP Payment Center store within your location. You may use the app or website to view the location of our stores.</li>
                  <li>Present your registration code and submit all your requirements to your preferred branch.</li>
                  <li>Your preferred branch will review all your requirements and will text you regarding the status of your application.</li>
                  <li>Once your application has been approved, you will receive a confirmation text/SMS from your partner store. Take note that it will take 5-7 days to approve your application.</li>
                  <li>Go to ACM Business Solution Inc. main branch located at Lemon Square Bldg, Munoz, Quezon City to get your uniform, delivery bag and ID. Necessary fees may apply.
                  </li>
                  <li>Congratulations you may now start receiving bookings from your GoVIPExpress driver’s app.
                  </li>
               </ol>
               <div>
                  <img src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/filecaf2ec7b-d66a-4c66-a768-ba526be2e512.png" alt=""
                  width="800px">
               </div>
             </div>
           </div>
         </div>
       </div>

   </div>

   <footer id="footer" class="footer">
  	 <div class="container">
  	 	<div class="row">
  	 		<div class="footer-col">
  	 			<h4>company</h4>
  	 			<ul>
  	 				<li><a>about us</a></li>
  	 				<li><a>our services</a></li>
  	 				<li><a>privacy policy</a></li>
  	 			</ul>
  	 		</div>
  	 		<div class="footer-col">
  	 			<h4>get help</h4>
  	 			<ul>
  	 				<li><a>FAQ</a></li>
  	 				<li><a>Help Centre</a></li>
  	 				<li><a>Contact Us</a></li>
  	 			</ul>
  	 		</div>
  	 		<div class="footer-col">
  	 			<h4>Join Our Team</h4>
  	 			<ul>
  	 				<li><a>Be our Partners</a></li>
  	 				<li><a>Be a Rider</a></li>
  	 			</ul>
  	 		</div>
  	 		<div class="footer-col">
  	 			<h4>Follow Us</h4>
  	 			<div class="social-links">
  	 				<a><i class="fab fa-facebook-f"></i></a>
  	 				<a><i class="fab fa-twitter"></i></a>
  	 				<a><i class="fab fa-instagram"></i></a>
  	 				<a><i class="fab fa-linkedin-in"></i></a>
  	 			</div>
  	 		</div>
  	 	</div>
  	 </div>
  </footer>
</div>