import { Component, OnInit } from "@angular/core";
import { Select2OptionData } from "ng-select2";

@Component({
  selector: "app-examples",
  templateUrl: "./examples.component.html",
  styleUrls: ["./examples.component.less"],
})
export class ExamplesComponent implements OnInit {
  public fruitList: Array<Select2OptionData>;
  public fruit: any;

  constructor() {
    //
  }

  ngOnInit(): void {
   

    this.fruitList = [
      {
        id: "basic1",
        text: "Basic 1",
      },
      {
        id: "basic2",
        disabled: true,
        text: "Basic 2",
      },
      {
        id: "basic3",
        text: "Basic 3",
      },
      {
        id: "basic4",
        text: "Basic 4",
      },
    ];
  }
}
