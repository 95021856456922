<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex" >
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">Message Blast Scheduling</h2>
          <small class="text-highlight font-weight-normal font-italic">Please make sure that the content is correct</small>
        </div>
        <div class="flex"></div>
        <div>
          <div (click)="openSendMessageCreate(sendMessageModal)" class="btn btn-md btn-info">
            <span class="d-none d-sm-inline mx-1">Create Message Blast</span>
            <i data-feather="arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="">
   

        <div class="card mt-2">
          <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
            <thead>
              <tr>
                <th data-sortable="true" data-field="owner">Last Updated By</th>
                <th data-sortable="true" data-field="owner">Message</th>
                <th data-sortable="true" data-field="owner">Start Date</th>
                <th data-sortable="true" data-field="owner">End Date</th>
                <th data-sortable="true" data-field="owner">Times</th>
                <th data-sortable="true" data-field="owner">Created Date</th>
                <th data-sortable="true" data-field="owner">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let message of messages"
                style="cursor: pointer"
                class="odd"
                role="row"
                (click)="getRealtimeMessageSingle(message.id)"
              >
               
                <td>
                  <small class="">{{ message?.username }}</small>
                </td>

                <td>
                  <small class="">{{ message?.message }}</small>
                </td>
                  <td>
                    <small class="">{{ message?.startDate | date: "MMMM dd, yyyy" }}</small>   
                  </td>
                  <td>
                    <small class="">{{ message?.endDate| date: "MMMM dd, yyyy" }}</small>
                  </td>
                  <td>
                    <small *ngFor='let time of convertToJSON(message?.times)' class="text-primary">{{ time.time }}<br/></small>
                  </td>

                  <td>
                      <small class="">{{ message?.createdDate | date: "MMMM dd, yyyy hh:mm:ss a"}}</small>
                    </td>

                    <td>
                      <small [ngClass]="message?.status == true ? 'text-success' : 'text-danger'">{{ message?.status == true ? "Active" : "Deactivated"}}</small>
                    </td>
                
              </tr>
            </tbody>
          </table>
        </div>

     
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<ng-template #sendMessageModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Message Blast Scheduling</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
   
        <form class="" role="form" [formGroup]="messageFormGroup" >
          <div class="card-header">
            <strong>Message</strong>
            <button *ngIf="selectedRealtimeMessage" type="button" class="btn btn-primary mb-4" style="float: right;" (click)="postMessage()">Save Message</button>
            <button *ngIf="!selectedRealtimeMessage" type="button" class="btn btn-primary mb-4" style="float: right;" (click)="postMessage()">Create Message</button>
            <br/>
          </div>
          <div class="card-body">
            <div class="form-group">
                <textarea formControlName="message" type="text" class="form-control" placeholder="Enter message here!">
                </textarea>
            </div>
          </div>
            <div class="col-lg-6" style="float: left;">
              <div class="card-header">
                <strong>Activation Date</strong>
              </div>
              <div class="card-body">
            <app-datepicker
            [parent-form-group]="messageFormGroup"
            tab-index-start="0"
            control-name="startDate"
            [error]="messageFormGroup?.controls.startDate.errors | estatus"
            [error-message]="messageFormGroup?.controls.startDate.errors | econtent"
            label="Start Date "
            [read-only]=false
            placeholder="Start Date "
            ></app-datepicker>

            <app-datepicker
            [parent-form-group]="messageFormGroup"
            tab-index-start="0"
            control-name="endDate"
            [error]="messageFormGroup?.controls.endDate.errors | estatus"
            [error-message]="messageFormGroup?.controls.endDate.errors | econtent"
            label="End Date "
            [read-only]=false
            placeholder="End Date "
            ></app-datepicker>

            <app-boolslider
            *ngIf="true"
            [parentFormGroup]="messageFormGroup"
            controlName="status"
            label="Status"
          >
          </app-boolslider>
          </div>
        </div>
          <div class="col-lg-6" style="float: right;">
            
        <div class="card-header">
          <strong>Clocks</strong>
         

          <div class="input-group mb-3" [formGroup]="timeToAddFormGroup">
            <div class="input-group-prepend green" style="cursor: pointer; height: 34px; ">
              <span class="input-group-text" id=""
              (click)="addTimeWithDataValues()"
                ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-plus"
              >
                <line x1="12" y1="5" x2="12" y2="19"></line>
                <line x1="5" y1="12" x2="19" y2="12"></line>
              </svg></span>
            </div>
            <label>
                <input type="time" formControlName="time" class="form-control" placeholder="Time">
            </label>

          </div>

       
        </div>
        <div class="card-body">
          <div *ngFor="let time of messageFormGroup?.controls.times.controls; let i = index">
            <div [formGroup]="time" class="input-group mb-3">
              <div (click)="deleteTimeValues(i)" class="input-group-prepend red" style="cursor: pointer; height: 34px; ">
                <span class="input-group-text" id=""
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-x"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line></svg></span>
              </div>
          
            
              <label>
                <input readonly="read-only" formControlName="time"  class="form-control">
            </label>

            </div>
          </div>
        </div>

          </div>
       
           
        </form>

       
  </div>
</ng-template>

