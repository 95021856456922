<div style="background-color: white; overflow: hidden;" class="m-0 p-3">

<div class="padding pb-0 pt-4 bg-dark">
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-6">
      <!-- <span><strong>Event League</strong> : Most Transacted NetBank with 150 and Up number of Transactions</span><br/> -->
      <span><strong>Event League</strong> : Most numbers of NetBank and Sunlife with at least 450 NetBank and 90 Sunlife Grepa total transactions</span><br/>
      <span><strong>Event Dates</strong> : From June 12, 2023 to July 12, 2023</span><br/>
      <span><strong>Status</strong> : Realtime Score</span><br/>
      <span><strong>Prizes will be awarded on : </strong>July 13, 2023</span><br/>
 

      <div *ngIf="!ownRank && isManagerOrCashier" class="col-sm-12 col-md-12 col-lg-12" style="text-align: center">
        <h1>No Rank : 0 Transactions</h1>
      </div>
     <div *ngIf="gameActive && secondsCounter > 0">
        <span class="badge" style="font-weight: bold; font-size: 1.0rem">Event will end in:</span>
        <span class="badge" style="font-weight: bold; font-size: 3.0rem">{{secondsCounter | secondsToHhmmss}}</span>
      </div>
      <div *ngIf="!(gameActive && secondsCounter > 0)">
        <span class="badge" style="font-weight: bold; font-size: 3.0rem">The event has ended</span>
      </div>
      <table
        *ngIf="ownRank"
        id="datatable"
        class="table table-striped table-hover col-sm-12 col-md-12 col-lg-12"
        role="grid"
        style="text-align: center;"
        aria-describedby="datatable_info"
      >
        <tbody>
          <tr style="text-align: center">
            <td class="flex pb-0"  style="vertical-align: middle !important;">
              <a href="#">
                <span class="text-align:center !important;">
                
                    <img
                      *ngIf="myRankNumber == 0"
                      class="w-32 avatar"
                      src="../../../assets/images/rankings/gold.png"
                      alt="../../../assets/images/rankings/steel.png"
                    />
                    <img
                      *ngIf="myRankNumber == 1"
                      class="w-32 avatar"
                      src="../../../assets/images/rankings/silver.png"
                      alt="../../../assets/images/rankings/steel.png"
                    />
                    <img
                      *ngIf="myRankNumber ==2"
                      class="w-32 avatar"
                      src="../../../assets/images/rankings/bronze.png"
                      alt="../../../assets/images/rankings/steel.png"
                    />
  
                    <h3 *ngIf="myRankNumber > 2">
                      <span class="badge btn-warning  h-3x" style="font-size: 1.5rem">{{ myRankNumber + 1 }}</span>
                    </h3>
                  
                </span>
              </a>
            </td>
  
            <td class="flex pb-0" style="vertical-align: middle">
              <div class="item-except h-3x" style="text-align: middle;">
                <span class="badge bg-dark" style="font-weight: bold;font-size: 1.0rem;white-space: pre-wrap;
              "> {{ ownRank.store_name.toUpperCase() }}</span>
              </div>
            </td>
  
            <td class="flex pb-0"  style="vertical-align: middle">
              <div class="item-except h-3x" style="text-align: middle;">
                  <span class="badge bg-dark" style="font-weight: bold; font-size: 1.0rem">
                    {{ getPercentage(ownRank.transaction_count) | number: "1.2-2" }}%</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
  
          <!-- //PRIZES -->
  
        
  
          <!-- //PRIZES -->
  
  
    </div>
   

    <div class="col-sm-12 col-md-12 col-lg-6" style="text-align: start">
      
   <strong>   GOVIPCENTER REACH YOUR GOAL MECHANICS:</strong> <br/>
   1. All outlets who have access to the GoVIPCenter system can participate.<br/>
2. The basis for computation is the number of successful transactions from the start date until the end date (June 12, 12:00 AM to July 12, 11:59 PM).<br/>
3. Once the event has ended, prizes will be made available the following day.<br/><br/>

<strong> HOW TO OBTAIN YOUR REWARD</strong><br/>
1. Cashiers must download the VIPS Citizen app. Once the promotions has ended, the reward will be credited to their VIPS account.
<br/><br/>
    </div>


  
  </div>
</div>



<div class="row">
  <div  *ngIf="gameActive" class="col-sm-12 col-md-7 col-lg-7">
    <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
      <thead>
        <tr style="text-align: center">
          <th data-sortable="true" data-field="owner">Ranking</th>
          <th data-sortable="true" data-field="id">
            <div>Store Outlet Name</div>
          </th>
          <th data-field="finish"><span class="d-none d-sm-block">Score</span></th>
          <th data-field="finish"><span class="d-none d-sm-block">V-Wallet Reward</span></th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let outlet_score of topTenResult.slice(0, 10); let i = index"
          style="cursor: pointer"
          class="odd"
          role="row"
          style="text-align: center"
        >
          <td style="padding: 8px;">
            <a href="#">
              <span class="text-align:center !important;">
                <center>
                  <img
                    *ngIf="i == 0"
                    class="w-40 avatar"
                    src="../../../assets/images/rankings/gold.png"
                    alt="../../../assets/images/rankings/steel.png"
                  />
                  <img
                    *ngIf="i == 1"
                    class="w-40 avatar"
                    src="../../../assets/images/rankings/silver.png"
                    alt="../../../assets/images/rankings/steel.png"
                  />
                  <img
                    *ngIf="i == 2"
                    class="w-40 avatar"
                    src="../../../assets/images/rankings/bronze.png"
                    alt="../../../assets/images/rankings/steel.png"
                  />
                  <!-- <img *ngIf="i > 2"
                    class="w-32 avatar"
                    src="../../../assets/images/rankings/steel.png"
                    alt="../../../assets/images/rankings/steel.png"
                  /> -->
                  <h5 *ngIf="i > 2">
                    <span class="badge bg-secondary">{{ i + 1 }}</span>
                  </h5>
                </center>
              </span>
            </a>
          </td>

          <td *ngIf="i == 0" class="flex" style="vertical-align: middle; text-align: middle;padding: 0px;">
            <div class="item-except h-2x" style="font-size: 1rem">
              <span style="color: #040e21; font-weight: bold"> {{ outlet_score.store_name.toUpperCase() }}</span>
            </div>
          </td>

          <td *ngIf="i == 1" class="flex" style="vertical-align: middle; text-align: middle;padding: 0px;">
            <div class="item-except h-2x" style="font-size: 1rem">
              <span style="color: #040e21; font-weight: bold"> {{ outlet_score.store_name.toUpperCase() }}</span>
            </div>
          </td>

          <td *ngIf="i == 2" class="flex" style="vertical-align: middle; text-align: middle;padding: 0px;">
            <div class="item-except h-2x" style="font-size: 1rem">
              <span style="color: #040e21; font-weight: bold"> {{ outlet_score.store_name.toUpperCase() }}</span>
            </div>
          </td>

          <td *ngIf="i > 2" class="flex" style="vertical-align: middle; text-align: middle;padding: 0px;">
            <div class="item-except h-2x" style="font-size: 0.8rem">
              <span style="color: #040e21; font-weight: bold"> {{ outlet_score.store_name.toUpperCase() }}</span>
            </div>
          </td>

          <td *ngIf="i == 0" class="flex pb-0" style="padding: 0px;">
            <div class="item-except h-2x" style="text-align: end">
              <h2>
                <span class="badge" style="font-weight: bold; font-size: 1.1rem; color: #29a829">
                  {{ getPercentage(outlet_score.transaction_count) | number: "1.2-2" }}%</span
                >
              </h2>
            </div>
          </td>

          <td *ngIf="i == 1" class="flex pb-0" style="padding: 0px;">
            <div class="item-except h-2x" style="text-align: end">
              <h2>
                <span class="badge" style="font-weight: bold; font-size: 1.1rem; color: #29a829">
                  {{ getPercentage(outlet_score.transaction_count) | number: "1.2-2" }}%</span
                >
              </h2>
            </div>
          </td>

          <td *ngIf="i == 2" class="flex pb-0" style="padding: 0px;">
            <div class="item-except h-2x" style="text-align: end">
              <h2>
                <span class="badge" style="font-weight: bold; font-size: 1.1rem; color: #29a829">
                  {{ getPercentage(outlet_score.transaction_count) | number: "1.2-2" }}%</span
                >
              </h2>
            </div>
          </td>

          <td *ngIf="i > 2" class="flex pb-0" style="padding: 0px;">
            <div class="item-except h-2x" style="text-align: end">
              <h2>
                <span class="badge" style="font-weight: bold; font-size: 1rem; color: black">
                  {{ getPercentage(outlet_score.transaction_count) | number: "1.2-2" }}%</span
                >
              </h2>
            </div>
          </td>

          <td *ngIf="i == 0" class="flex pb-0" style="padding: 0px;">
            <div class="item-except h-2x" style="text-align: end">
              <h2>
                <span class="badge" style="font-weight: bold; font-size: 1.1rem; color: #29a829">
                  {{ rewardList[i] | number: "1.2-2" }}</span
                >
              </h2>
            </div>
          </td>
          <td *ngIf="i == 1" class="flex pb-0" style="padding: 0px;">
            <div class="item-except h-2x" style="text-align: end">
              <h2>
                <span class="badge" style="font-weight: bold; font-size: 1.1rem; color: #29a829">
                  {{ rewardList[i] | number: "1.2-2" }}</span
                >
              </h2>
            </div>
          </td>

          <td *ngIf="i == 2" class="flex pb-0" style="padding: 0px;">
            <div class="item-except h-2x" style="text-align: end">
              <h2>
                <span class="badge" style="font-weight: bold; font-size: 1.1rem; color: #29a829">
                  {{ rewardList[i] | number: "1.2-2" }}</span
                >
              </h2>
            </div>
          </td>
          <td *ngIf="i > 2" class="flex pb-0" style="padding: 0px;">
            <div class="item-except h-2x" style="text-align: end">
              <h2>
                <span class="badge" style="font-weight: bold; font-size: 1rem; color: black">
                  {{ rewardList[i] | number: "1.2-2" }}</span
                >
              </h2>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div  *ngIf="!gameActive" class="col-sm-12 col-md-7 col-lg-7" style="width: 100%;  text-align: center; vertical-align: middle">
    <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
         
          <tbody>
            <tr
              class="odd"
              role="row"
              style="text-align: center"
            >
              <td style="padding: 8px;">
                  <span class="text-align:center !important;">
                    <center>
                      <img
                        class="w-40 avatar"
                        src="../../../assets/images/rankings/gold.png"
                        alt="../../../assets/images/rankings/steel.png"
                      />
                      <!-- <img
                        *ngIf="i == 1"
                        class="w-40 avatar"
                        src="../../../assets/images/rankings/silver.png"
                        alt="../../../assets/images/rankings/steel.png"
                      />
                      <img
                        *ngIf="i == 2"
                        class="w-40 avatar"
                        src="../../../assets/images/rankings/bronze.png"
                        alt="../../../assets/images/rankings/steel.png"
                      /> -->
                    
                     
                    </center>
                  </span>
              </td>
    
              <td class="flex" style="vertical-align: middle; text-align: middle;padding: 0px;">
                <div class="item-except h-3x" style="font-size: 2rem">
                  <span style="color: #040e21; font-weight: bold"> First Place Prize</span>
                </div>
              </td>
    
              <td class="flex pb-0" style="padding: 0px;">
                <div class="item-except h-3x" style="text-align: end">
               
                    <span class="badge" style="font-weight: bold; font-size: 2rem; color: #29a829">
                      {{ rewardList[0] | number: "1.2-2" }}</span
                    >
                
                </div>
              </td>
            </tr>
            <tr
            class="odd"
            role="row"
            style="text-align: center"
          >
            <td style="padding: 8px;">
                <span class="text-align:center !important;">
                  <center>
                    
                    <img
                      class="w-40 avatar"
                      src="../../../assets/images/rankings/silver.png"
                      alt="../../../assets/images/rankings/steel.png"
                    />
                    <!-- <img
                      class="w-40 avatar"
                      src="../../../assets/images/rankings/bronze.png"
                      alt="../../../assets/images/rankings/steel.png"
                    /> -->
                  
                   
                  </center>
                </span>
            </td>
  
            <td class="flex" style="vertical-align: middle; text-align: middle;padding: 0px;">
              <div class="item-except h-3x" style="font-size: 2rem">
                <span style="color: #040e21; font-weight: bold"> Second Place Prize</span>
              </div>
            </td>
  
            <td class="flex pb-0" style="padding: 0px;">
              <div class="item-except h-3x" style="text-align: end">
             
                  <span class="badge" style="font-weight: bold; font-size: 2rem; color: #29a829">
                    {{ rewardList[1] | number: "1.2-2" }}</span
                  >
              
              </div>
            </td>
          </tr>

          <tr
          class="odd"
          role="row"
          style="text-align: center"
        >
          <td style="padding: 8px;">
              <span class="text-align:center !important;">
                <center>
                  
                
                  <img
                    class="w-40 avatar"
                    src="../../../assets/images/rankings/bronze.png"
                    alt="../../../assets/images/rankings/steel.png"
                  />
                
                 
                </center>
              </span>
          </td>

          <td class="flex" style="vertical-align: middle; text-align: middle;padding: 0px;">
            <div class="item-except h-3x" style="font-size: 2rem">
              <span style="color: #040e21; font-weight: bold"> Third Place Prize</span>
            </div>
          </td>

          <td class="flex pb-0" style="padding: 0px;">
            <div class="item-except h-3x" style="text-align: end">
           
                <span class="badge" style="font-weight: bold; font-size: 2rem; color: #29a829">
                  {{ rewardList[2] | number: "1.2-2" }}</span
                >
            
            </div>
          </td>
        </tr>
          </tbody>
        </table>
    <div class="card" >
      <div class="card-body">
    <span class="badge" style="font-weight: bold; font-size: 3.0rem">Event will start in:</span><br/>
    <span class="badge" style="font-weight: bold; font-size: 6.0rem">{{seconds | secondsToHhmmss}}</span>

        <!-- //PRIZES -->

      

        <!-- //PRIZES -->

  </div>
  </div>
  </div>
  <div class="col-sm-12 col-md-5 col-lg-5">
    <img
      class="d-block m-auto"
      style="width: 100%;  text-align: center"
      src="https://govipcenterstorage.blob.core.windows.net/govipstore/file1dfa82b3-28f3-4b34-a243-c5926980a383.png"
      alt="../../../assets/images/rankings/steel.png"
    />
  </div>
</div>
</div>