import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';

@Component({
  selector: 'app-video-collections',
  templateUrl: './video-collections.component.html',
  styleUrls: ['./video-collections.component.less']
})
export class VideoCollectionsComponent implements OnInit {

 // safeSrc: SafeResourceUrl;
  videoTutorials : any= [];
  constructor(private sanitizer: DomSanitizer,private webService: WebSettingsService) { 
  //  this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/c9F5kMUfFKk");
  }

  ngOnInit(): void {
    this.initVideoTutorials();
  }

  initVideoTutorials() {
    this.webService.getWebSettingsByIdentifier("VIDEO_COLLECTION").subscribe((result) => {
      result.data.forEach(category => {
        category.value.forEach(meta => {
          meta.url = this.safeSource(meta.url)
         });
         this.videoTutorials.push(category)
      });
       
    });
  }

  safeSource(url:any) : SafeResourceUrl{
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
