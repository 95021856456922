import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Select2OptionData } from 'ng-select2';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { SettlementsService } from 'src/data/services/web/settlements.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'ts-xlsx';
import * as FileSaver from 'file-saver';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SpxRemittanceService } from 'src/data/services/web/spx-remittance.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { result } from 'lodash';
import { ExportExcelService } from 'src/data/services/web/export-excel.service';
import { VipcoTransactionService } from 'src/data/services/web/vipco-transaction.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-account-reactivation',
  templateUrl: './account-reactivation.component.html',
  styleUrls: ['./account-reactivation.component.less']
})
export class AccountReactivationComponent implements OnInit {

  loader = false;
  searchByFormGroup: FormGroup;
  public fileUploadFormGroup: FormGroup;
  public deacFileUploadFormGroup: FormGroup;
  public riderFormGroup: FormGroup;
  p: number = 1;
  public pageRows: any = 10;
  emptyString = '';
  roleId: any;
  
  createdDate = "";
  searchString = "";

  public showRowsOption: Array<Select2OptionData>;
  public hubOption: Array<Select2OptionData>;
  public users: any = [];
  public usersExcel: any = [];
  public transactionsExcel: any = [];
  public transaction: any;
  public totals : any;

  
  private _sortBy: any;
  private _sort: any;
  
  constructor(
    private settlementService : SettlementsService,
    private vipsService : VipcoTransactionService,
    private localStorageCacheService: LocalStorageCacheService,
    private websettings : WebSettingsService,
    private modalServe: NgbModal,
    public datepipe: DatePipe,
    public ete: ExportExcelService
  ) { }

  ngOnInit(): void {
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    this.initSearchFormGroup();

    this.getShowRows();
    this.getVipsUser();
  }

  initSearchFormGroup(){
    this.searchByFormGroup = new FormGroup({
      searchString : new FormControl,
      dateRange : new FormControl,
      showRows: new FormControl
    });
  }

  getFullName(user: any): string {
    const userFname = user?.userFname;
    const userLname = user?.userLname;

    const firstName = userFname ? userFname.toUpperCase() : '';
    const lastName = userLname ? userLname.toUpperCase() : '';

    return `${firstName} ${lastName}`;
    //return `${user?.user?.userFname?.toUpperCase()} ${user?.user?.userLname?.toUpperCase()}`;
  }

  searchData(){
    this.pageRows = 1;
    this.getVipsUser(this.buildPageUrl(1,
      this.searchByFormGroup.controls.showRows.value
      ?this.searchByFormGroup.controls.showRows.value : 10),null,null,true);
  }

  buildPageUrl(pageNumber: any, pageSize: any): any {
    //debugger
    this.p = pageNumber;
    return `${environment.API_URL}/api/GoVIPX/DeactivatedAccounts?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  getVipsUser(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader =true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    const searchString : string = this.searchByFormGroup.controls.searchString.value;
    const showRows : string = this.searchByFormGroup.controls.showRows.value;
    const dateRange : string = this.searchByFormGroup.controls.dateRange.value;

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_showRows: string = showRows ? `showRows=${showRows}&` : "showRows=10&";
    const queryString_searchString: string = searchString ? `searchString=${searchString}&` : "";
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_showRows + queryString_searchString + queryString_dateRange;
 
    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/GoVIPX/DeactivatedAccounts?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/GoVIPX/DeactivatedAccounts?${queryString}`;
      }
    }

    this.vipsService.getVipsUsers(endPointUrl.slice(0, -1)).subscribe((result) => {
      this.loader =false;

      if (result.data.length == 0 && result.pageNumber != 1) {
        this.getVipsUser(result.previousPage, _sortBy, _sort);
      } else {
        this.users = result;
      }
    });
  }

  getShowRows() {
    this.websettings.getWebSettingsByIdentifier("SHOW_ROWS").subscribe((result) => {
     
      this.showRowsOption = result.data.map((status) => {
        return {
          id: status.parameter,
          text: status.value,
        };
      });
       
    });
  }

  selectedUser: any;
  confirmModal: NgbModalRef
  confirmReactivate(content, user){
    this.selectedUser = user;

    this.confirmModal = this.modalServe.open(content, { centered: true, size: "sm" });
  }

  activateAccount(userId){
    this.loader = true;
    this.vipsService.reactivateAccount(userId).subscribe((result) => {
      if(result.item1){
        PNotify.success({
          title: "Success",
          text: "Account is now active.",
        });

        this.getVipsUser();
      } else {
        PNotify.notice({
          title: "Oops!",
          text: result.item2,
        });
        this.loader = false;
      }

      this.confirmModal.close();
    }, (error) => {
      PNotify.error({
        title: "Something went wrong!",
        text: "",
      });
      this.loader = false;
      this.confirmModal.close();
    });
  }

  getLevel(level){
    if(level == "LEVEL_6" || level == "LEVEL_7"){
      return 'Merchant';
    } else if (level == "LEVEL_8" || level == "LEVEL_9"){
      return 'Rider';
    } else {
      return 'User (' + level + ')';
    }
  }
}
