import { CurrencyPipe, DatePipe } from "@angular/common";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { TransactionService } from "src/data/services/web/transaction.service";
import { RolesService } from "src/data/services/web/roles.service";
import { environment } from "src/environments/environment";
import { UserService } from "src/data/services/web/user.service";
import { Select2OptionData } from "ng-select2";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WebSettingsService } from "src/data/services/web/web-settings.service";
import { CtiTransactionsComponent } from "src/app/billersTransactions/cti-transactions/cti-transactions.component";
import { CtiService } from "src/data/services/web/cti.service";
import { MultisysService } from "src/data/services/web/multisys.service";
import { MultisysTransactionComponent } from "src/app/billers/multisys/transaction/multisys-transaction/multisys-transaction.component";
import { MultisysTransactionsComponent } from "src/app/billersTransactions/multisys-transactions/multisys-transactions.component";
import { ECPayService } from "src/data/services/web/ecpay.service";
import { EcpayBillTransactionsComponent } from "src/app/billersTransactions/ecpay-bill-transactions/ecpay-bill-transactions.component";
import { EcpayCashTransactionsComponent } from "src/app/billersTransactions/ecpay-cash-transactions/ecpay-cash-transactions.component";
import { EcpayTopupTransactionsComponent } from "src/app/billersTransactions/ecpay-topup-transactions/ecpay-topup-transactions.component";
import { ExportExcelService } from "src/data/services/web/export-excel.service";
import { ServiceChargeComponent } from "src/app/billers/service-charge/service-charge.component";
import { ServiceChargeTransactionComponent } from "src/app/billers/service-charge/transaction/service-charge-transaction/service-charge-transaction.component";
import { ServiceChargeService } from "src/data/services/web/service-charge.service";
import { IbayadService } from "src/data/services/web/ibayad.service";
import { IbayadTransferTransactionsComponent } from "src/app/billersTransactions/ibayad-transfer-transactions/ibayad-transfer-transactions.component";
import { VipProtectTransactionComponent } from "src/app/billers/vip-protect/transaction/vip-protect-transaction/vip-protect-transaction.component";
import { VipProtectTransactionsComponent } from "src/app/billersTransactions/vip-protect-transactions/vip-protect-transactions.component";
import { VipProtectService } from "src/data/services/web/vip-protect.service";
import { IbayadMeralcoService } from "src/data/services/web/ibayad-meralco.service";
import { IbayadMeralcoTransactionComponent } from "src/app/billers/ibayad-meralco/ibayad-meralco-transaction/ibayad-meralco-transaction.component";
import { IbayadMeralcoTransactionsComponent } from "src/app/billersTransactions/ibayad-meralco-transactions/ibayad-meralco-transactions.component";
import { SunlifeGrepaTransactionsComponent } from "src/app/billersTransactions/sunlife-grepa-transactions/sunlife-grepa-transactions.component";
import { SunlifeGrepaService } from "src/data/services/web/sunlife-grepa.service";
import { SpxRemittanceTransactionComponent } from "src/app/billers/spx-rider-remittance/spx-rider-transaction/spx-remittance-transaction.component";
import { SpxRemittanceTransactionsComponent } from "src/app/billersTransactions/spx-remittance-transactions/spx-remittance-transactions.component";
import { NetbankTransactionsComponent } from "src/app/billersTransactions/netbank-transactions/netbank-transactions.component";
import { NetbankService } from "src/data/services/web/netbank.service";
import { BayadTransactionsComponent } from "src/app/billersTransactions/bayad-transactions/bayad-transactions.component";
import { SpxRemittanceService } from "src/data/services/web/spx-remittance.service";
import { BayadService } from "src/data/services/web/bayad.service";
import { StandardInsuranceTransactionsComponent } from "src/app/billersTransactions/standard-insurance-transactions/standard-insurance-transactions.component";
import { UgiTransactionsComponent } from "src/app/billersTransactions/ugi-transactions/ugi-transactions.component";
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { IglooTransactionsComponent } from "src/app/billersTransactions/igloo-transactions/igloo-transactions.component";
import { IglooCtplTransactionsComponent } from "src/app/billersTransactions/igloo-ctpl-transactions/igloo-ctpl-transactions.component";
import { CebuanaTransactionsComponent } from "src/app/billersTransactions/cebuana-transactions/cebuana-transactions.component";
import { CebuanaPayoutTransactionsComponent } from "src/app/billersTransactions/cebuana-payout-transactions/cebuana-payout-transactions.component";
import { PaygramTransactionsComponent } from "src/app/billersTransactions/paygram-transactions/paygram-transactions.component";
import { JntTransactionsComponent } from "src/app/billersTransactions/jnt-transactions/jnt-transactions.component";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-igloo-admin-transactions',
  templateUrl: './igloo-admin-transactions.component.html',
  styleUrls: ['./igloo-admin-transactions.component.less']
})
export class IglooAdminTransactionsComponent implements OnInit {
  @ViewChild("viewMultisys") viewMultisys: TemplateRef<any>;

  public transactionFilterFormGroup: FormGroup;
  public searchByUsernameFormGroup: FormGroup;
  public searchFormGroup: FormGroup;

  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public transactionService: TransactionService;
  public websettings: WebSettingsService;
  public userService: UserService;
  public emptyString = "";
  public statuses: any;
  public status: any;
  public modalService: BsModalService;

  public accessToken: string;
  public newTransaction: boolean;
  public transactions: any = [];
  public transaction: any;
  public pageCount: any;
  public roleLevelId: any;

  public cashiersAndManagers: any;

  public processUpdate: any = false;

  public bsModalRefConfirmation: BsModalRef;
  public selectedUserId: any = 0;

  public userIdFilterOptions: Array<Select2OptionData>;
  public providersOption: Array<Select2OptionData>;
  public statusOPtion: Array<Select2OptionData>;
  public userIdFilter: any = [];
  public providerIdFilter: any = [];
  public statusIdFilter: any = [];
  public billerIDFilter: any = [];
  public ctiService: CtiService;
  public multisysService: MultisysService;
  public eCPayService: ECPayService;
  public serviceChargeService: ServiceChargeService;
  public vipProtectService: VipProtectService;
  public iBayadService: IbayadService;
  public iBayadMeralcoService: IbayadMeralcoService;

  public isDataNotNull = false;
  dataForExcel = [];

  public billerOption: Array<Select2OptionData>;
  public Transactions = [];

  loader = false;

  @ViewChild(IglooTransactionsComponent) IglooTransactionsComponent: IglooTransactionsComponent;
  @ViewChild(IglooCtplTransactionsComponent) IglooCtplTransactionsComponent: IglooCtplTransactionsComponent;

  p = 1;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService, 
    private data: Data,
    private currencyPipe: CurrencyPipe,
    public datepipe: DatePipe,
    private modalBService: NgbModal,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    transactionService: TransactionService,
    modalService: BsModalService,
    bsModalRefConfirmation: BsModalRef,
    userService: UserService,
    websettings: WebSettingsService,
    ctiService: CtiService,
    multisysService: MultisysService,
    vipProtectService: VipProtectService,
    iBayadService: IbayadService,
    eCPayService: ECPayService,
    serviceChargeService: ServiceChargeService,
    private ibayadService: IbayadService,
    private bayadService: BayadService,
    private netBankService: NetbankService,
    private ibayadMeralcoService: IbayadMeralcoService,
    private sunlifeGrepaService: SunlifeGrepaService,
    private spxService: SpxRemittanceService,
    public ete: ExportExcelService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.transactionService = transactionService;
    this.modalService = modalService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
    this.bsModalRefConfirmation = bsModalRefConfirmation;
    this.userService = userService;
    this.websettings = websettings;
    this.spxService = spxService;
    this.ctiService = ctiService;
    this.multisysService = multisysService;
    this.ibayadService = ibayadService;
    this.ibayadMeralcoService = ibayadMeralcoService;
    this.vipProtectService = vipProtectService;
    this.sunlifeGrepaService = sunlifeGrepaService;
    this.eCPayService = eCPayService;
    this.serviceChargeService = serviceChargeService;
  }
  
  userId;
  billers: any;
  billerCode: any = [];
  provider: any;
  ngOnInit(): void {
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.userId = this.roleLevelId == 1 ? 0 : this.localStorageCacheService.getStorage("userId");

    this.initSearchByUsernameFormGroup();
    this.initSearchFormGroup();
    this.getCashiersAndManagers();
    this.getProviders();
    this.getStatus();
    // this.getTransaction();

    this.searchFormGroup.controls.provider.valueChanges.subscribe((e) => {
      this.billers = null;
      this.billerCode = [];
      this.billerOption = null;
      this.provider = this.searchFormGroup.controls.provider.value;
    });
  }

  
  initSearchByUsernameFormGroup(): void {
    this.searchByUsernameFormGroup = new FormGroup({
      username: new FormControl(this.userId),
      dateRange: new FormControl(""),
      requestId: new FormControl(""),
    });

    this.searchByUsernameFormGroup.controls.username.valueChanges.subscribe((e) => {
      this.selectedUser(e);
      this.searchByUsernameFormGroup.controls.dateRange.setValue("");
      this.searchByUsernameFormGroup.controls.requestId.setValue("");
    });
  }

  initSearchFormGroup(): void {
    this.searchFormGroup = new FormGroup({
      provider: new FormControl(null),
      biller: new FormControl(null),
      userId: new FormControl(null),
      dateRange: new FormControl(this.emptyString),
      status: new FormControl(null),
      accountNo: new FormControl(this.emptyString),
      requestId: new FormControl(this.emptyString),
    });
  }

  private _sortBy: any;
  private _sort: any;

  selectedUser(e) {
    this.selectedUserId = e;
    
  }

  getProviders() {
    // this.websettings.getWebSettingsByIdentifier("PROVIDERS").subscribe((result) => {
      
    //   this.providersOption = result.data.map((provider) => {
    //     return {
    //       id: provider.parameter,
    //       text: provider.value,
    //     };
    //   });
      
    // });

    this.providersOption = [
      { id: "Igloo" , text: "Igloo" },
      { id: "Igloo CTPL" , text: "Igloo CTPL" }
    ]
  }

  getStatus() {
    this.websettings.getWebSettingsByIdentifier("STATUS").subscribe((result) => {
      
      this.statusOPtion = result.data.map((status) => {
        return {
          id: status.parameter,
          text: status.value,
        };
      });
 
    });
  }

  getCashiersAndManagers() {
    this.userService.GetCashierUsernames().subscribe((result) => {
      this.cashiersAndManagers = result;
      // Set the username options from the API data
      this.userIdFilterOptions = result.map((user) => {
        return {
          id: user.id,
          text: user.name,
        };
      });

      this.searchByUsernameFormGroup.controls.username.setValue(result[0].id, { onlySelf: true });
    });
  }

  statusOfAmount(money: any): boolean {
    return money < 0 ? false : true;
  }

  getTransaction(): void {
    if (this.provider == "Igloo"){
      this.IglooTransactionsComponent.getTransaction(null, null, null, null);
    } else if (this.provider == "Igloo CTPL"){
      this.IglooCtplTransactionsComponent.getTransaction(null, null, null, null);
    } else {
      this.isDataNotNull = false;
    }

    //this.getTransactionForExcel();
    this.isDataNotNull = true;
    
  }

  getTransactionForExcel(): void {
    if (this.provider == "Igloo"){
      this.IglooTransactionsComponent.getTransactionForExcel(null, null, null, null);
    } else if (this.provider == "Igloo CTPL"){
      this.IglooCtplTransactionsComponent.getTransactionForExcel(null, null, null, null);
    } else {
      this.isDataNotNull = false; 
    }
  }

  //CTI

  exportToExcel() {
    this.Transactions = [];
    this.dataForExcel = [];
    
    if (this.provider == "Igloo") {
      this.getIglooTransactionForExcel(null, null, null, null);
    } if(this.provider == "Igloo CTPL") {
      this.getIglooCTPLTransactionForExcel(null, null, null, null);
    } else {

    }
  }

  generateExcel(provider: any, name: any): void {
    this.Transactions = [];

    //

    for (let i = 0; i < provider.data.length; i++) {
      let status = "";
      const providerName = "";

      if (provider.data[i].statusId == "1") {
        status = "Failed";
      } else if (provider.data[i].statusId == "2") {
        status = "Completed";
      } else {
        status = "Pending";
      }

      if (this.provider.data == "CTI") {
        const meta = [
          {
            "Biller Name": name,
            "Request ID": provider.data[i].requestId,
            "Account Number": provider.data[i].mobileNumber,
            "Biller/Product Name": provider.data[i].productName,
            "Category": provider.data[i].productCategory,
            "Biller Partner": provider.data[i].billerPartnerRefNo,
            "Transact by": provider.data[i].transactByUser.username,
            "Manager": provider.data[i].managerUsername,
            "Completed date": provider.data[i].completedDate,
            "Amount": provider.data[i].walletAmount,
            "Biller Fee": "N/A",
            "Balance Before": this.makeMoney(provider.data[i].transactByOpeningBalance),
            "Balance After": this.makeMoney(provider.data[i].transactByOpeningBalance - provider.data[i].walletAmount),
            Status: status,
          },
        ];
  
        this.Transactions.push(meta);
      } else if (this.provider == "MULTISYS") {
        const meta = [
          {
            "Biller Name": name,
            "Request ID": provider[i].requestId,
            "Account Number": provider[i].billerAccountNo,
            "Biller/Product Name": provider[i].billerName,
            "ItemCode": provider[i].requestJson,
            "Category": provider[i].billerCategory,
            "Biller Partner": provider[i].billerPartnerRefNo,
            "Transact by": provider[i].transactByUser.username,
            "Manager": provider[i].managerUsername,
            "Completed date": provider[i].completedDate,
            "Amount": provider[i].amount,
            "Biller Fee": provider[i].billerFee,
            "Balance Before": this.makeMoney(provider[i].transactByOpeningBalance),
            "Balance After": this.makeMoney(provider[i].transactByOpeningBalance - provider[i].amount),
            Status: status,
          },
        ];
  
        this.Transactions.push(meta);
      } else if (this.provider == "ECPAY") {
        const meta = [
          {
            "Biller Name": name,
            "Request ID": provider[i].requestId,
            "Account Number": provider[i].billerAccountNo,
            "Biller/Product Name": provider[i].billerName,
            "Category": provider[i].billerCategory,
            "Biller Partner": provider[i].billerPartnerRefNo,
            "Transact by": provider[i].transactByUser.username,
            "Manager": provider[i].managerUsername,
            "Completed date": provider[i].completedDate,
            "Amount": provider[i].amount,
            "Biller Fee": provider[i].billerFee,
            "Balance Before": this.makeMoney(provider[i].transactByOpeningBalance),
            "Balance After": this.makeMoney(provider[i].transactByOpeningBalance - provider[i].amount),
            Status: status,
          },
        ];
  
        this.Transactions.push(meta);
      } else if (this.provider == "ECCASH") {
        const meta = [
          {
            "Biller Name": name,
            "Request ID": provider[i].requestId,
            "Account Number": provider[i].billerAccountNo,
            "Biller/Product Name": provider[i].billerName,
            "Category": provider[i].billerCategory,
            "Biller Partner": provider[i].billerPartnerRefNo,
            "Transact by": provider[i].transactByUser.username,
            "Completed date": provider[i].completedDate,
            "Amount": provider[i].amount,
            "Biller Fee": provider[i].billerFee,
            "Balance Before": this.makeMoney(provider[i].transactByOpeningBalance),
            "Balance After": this.makeMoney(provider[i].transactByOpeningBalance - provider[i].amount),
            Status: status,
          },
        ];
  
        this.Transactions.push(meta);
      } else if (this.provider == "ECTOPUP") {
        const meta = [
          {
            "Biller Name": name,
            "Request ID": provider[i].requestId,
            "Account Number": provider[i].cellphoneNumber,
            "Biller/Product Name": provider[i].extensionTag,
            "Category": provider[i].telco,
            "Biller Partner": provider[i].billerPartnerRefNo,
            "Transact by": provider[i].transactByUser.username,
            "Completed date": provider[i].completedDate,
            "Amount": provider[i].amount,
            "Biller Fee": provider[i].billerFee,
            "Balance Before": this.makeMoney(provider[i].transactByOpeningBalance),
            "Balance After": this.makeMoney(provider[i].transactByOpeningBalance - provider[i].amount),
            Status: status,
          },
        ];
  
        this.Transactions.push(meta);
      } else if (this.provider == "INSTAPAY") {
        const meta = [
          {
            // "Biller Name": name,
            // "Request ID": provider[i].requestId,
            // "Account Number": provider[i].cellphoneNumber,
            // "Biller/Product Name": provider[i].extensionTag,
            // "Category": provider[i].telco,
            // "Biller Partner": provider[i].billerPartnerRefNo,
            "Transact by": provider[i].transactByUser.username,
            "Completed date": provider[i].completedDate,
            // "Amount": provider[i].amount,
            // "Biller Fee": provider[i].billerFee,
            // "Balance Before": this.makeMoney(provider[i].transactByOpeningBalance),
            // "Balance After": this.makeMoney(provider[i].transactByOpeningBalance - provider[i].amount),
            Status: status,
          },
        ];
  
        this.Transactions.push(meta);
      }  else if (this.provider == "PESONET") {
        const meta = [
          {
            // "Biller Name": name,
            // "Request ID": provider[i].requestId,
            // "Account Number": provider[i].cellphoneNumber,
            // "Biller/Product Name": provider[i].extensionTag,
            // "Category": provider[i].telco,
            // "Biller Partner": provider[i].billerPartnerRefNo,
            // "Transact by": provider[i].transactByUser.username,
            "Completed date": provider[i].completedDate,
            // "Amount": provider[i].amount,
            // "Biller Fee": provider[i].billerFee,
            // "Balance Before": this.makeMoney(provider[i].transactByOpeningBalance),
            // "Balance After": this.makeMoney(provider[i].transactByOpeningBalance - provider[i].amount),
            Status: status,
          },
        ];
  
        this.Transactions.push(meta);
      } else if (this.provider == "VIP_PROTECT") {
        const meta = [
          {
            // "Biller Name": name,
            // "Request ID": provider[i].requestId,
            // "Account Number": provider[i].cellphoneNumber,
            // "Biller/Product Name": provider[i].extensionTag,
            // "Category": provider[i].telco,
            // "Biller Partner": provider[i].billerPartnerRefNo,
            // "Transact by": provider[i].transactByUser.username,
            "Completed date": provider[i].completedDate,
            // "Amount": provider[i].amount,
            // "Biller Fee": provider[i].billerFee,
            // "Balance Before": this.makeMoney(provider[i].transactByOpeningBalance),
            // "Balance After": this.makeMoney(provider[i].transactByOpeningBalance - provider[i].amount),
            Status: status,
          },
        ];
  
        this.Transactions.push(meta);
      } 
      
      
      else {
      }

    }

   
    for (let x = 0; x < this.Transactions.length; x++) {
      this.dataForExcel.push(Object.values(this.Transactions[x][0]));
      
    }

    const reportData = {
      title: name + " Transactions",
      data: this.dataForExcel,
      headers: Object.keys(this.Transactions[0][0]),
    };

    this.ete.exportExcel(reportData, name);
  }

  makeMoney(money: any) {
    if (money) {
      return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
    } else if (money == 0) {
      return "0.00";
    } else {
      return "N/A";
    }
  }

  createdDate = "";
  searchString = "";
  
  getIglooTransactionForExcel(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader =true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    const biller : string = this.searchFormGroup.controls.biller.value;
    const accountNo : string = this.searchFormGroup.controls.accountNo.value;
    const status : string = this.searchFormGroup.controls.status.value;
    const requestId : string = this.searchFormGroup.controls.requestId.value;
    const userId : string = this.searchFormGroup.controls.userId.value;
    const dateRange : string = this.searchFormGroup.controls.dateRange.value;
    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_biller: string = biller ? `biller=${biller}&` : "";
    const queryString_accountNo: string = accountNo ? `accountNo=${accountNo}&` : "";
    const queryString_status: string = status ? `status=${status}&` : "";
    const queryString_requestId: string = requestId ? `requestId=${requestId}&` : "";
    const queryString_userId: string = userId ? `id=${userId}&` : "";
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_biller + queryString_accountNo+ queryString_status+ queryString_userId+ queryString_requestId + queryString_dateRange;

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/Igloo/TransactionsHistory?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/Igloo/TransactionsHistory?${queryString}`;
      }
    }

    this.spxService.getTransactions(endPointUrl.slice(0, -1)).subscribe((result) => {
      this.loader = false;
      
      if(result){
        PNotify.success({
          title: "Export success",
          text: "Transaction file request will be sent to your email shortly.",
        });
      } else {
        PNotify.error({
          title: "Something went wrong",
          text: "Please try again later.",
        });
      }
    });
  }

  getIglooCTPLTransactionForExcel(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader =true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    const biller : string = this.searchFormGroup.controls.biller.value;
    const accountNo : string = this.searchFormGroup.controls.accountNo.value;
    const status : string = this.searchFormGroup.controls.status.value;
    const requestId : string = this.searchFormGroup.controls.requestId.value;
    const userId : string = this.searchFormGroup.controls.userId.value;
    const dateRange : string = this.searchFormGroup.controls.dateRange.value;
    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_biller: string = biller ? `biller=${biller}&` : "";
    const queryString_accountNo: string = accountNo ? `accountNo=${accountNo}&` : "";
    const queryString_status: string = status ? `status=${status}&` : "";
    const queryString_requestId: string = requestId ? `requestId=${requestId}&` : "";
    const queryString_userId: string = userId ? `id=${userId}&` : "";
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_biller + queryString_accountNo+ queryString_status+ queryString_userId+ queryString_requestId + queryString_dateRange;

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/Igloo/TransactionsCTPLHistory?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/Igloo/TransactionsCTPLHistory?${queryString}`;
      }
    }

    this.spxService.getTransactions(endPointUrl.slice(0, -1)).subscribe((result) => {
      this.loader = false;
      
      if(result){
        PNotify.success({
          title: "Export success",
          text: "Transaction file request will be sent to your email shortly.",
        });
      } else {
        PNotify.error({
          title: "Something went wrong",
          text: "Please try again later.",
        });
      }
    });
  }

  generateTransactionExcel(Transaction: any, name: any): void {

    this.transactions = Transaction;
   
    for (let x = 0; x < this.Transactions.length; x++) {
      this.dataForExcel.push(Object.values(this.Transactions[x][0]));
      
    }

    const reportData = {
      title: name,
      data: this.dataForExcel,
      headers: Object.keys(this.Transactions[0][0]),
    };

    this.ete.exportExcel(reportData, name);
  }

}
