import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'ts-xlsx';
import * as FileSaver from 'file-saver';
import { GovipxService } from 'src/data/services/web/govipx.service';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { Select2OptionData } from 'ng-select2';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { StoreItemService } from 'src/data/services/web/store-item.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-purchase-order-admin',
  templateUrl: './purchase-order-admin.component.html',
  styleUrls: ['./purchase-order-admin.component.less']
})
export class PurchaseOrderAdminComponent implements OnInit {

  loader: any = false;
  uom: any = [];
  categories: any = [];
  emptyString: any = "";
  purchaseOrder: any = [
    {
      pono: "PO0012345",
      totalAmount: 1500,
      status: 1,
      trackingStatus: "Preparing to ship",
      createdDate: Date.now
    }
  ];

  activeTab: any = 4;
  username: any;
  public categoryFormGroup: FormGroup;
  public uomFormGroup: FormGroup;

  public userIdFilterOptions: Array<Select2OptionData>;

  constructor(
    private modalServe: NgbModal,
    private webService: WebSettingsService,
    public datepipe: DatePipe,
    public storeItemService: StoreItemService,
    private currencyPipe: CurrencyPipe,
    private localStorageCacheService: LocalStorageCacheService
  ) { 

  }

  ngOnInit(): void {
    this.getCategories();
    this.getUoM();
    this.initCategoryFormGroup();
    this.initUomFormGroup();

    this.getPO();
    this.getToShip();
    this.getToReceive();
    this.getCompleted();
    this.getCancelled();
    this.getReturnPO();
    this.getInventory();

    this.username = this.localStorageCacheService.getStorage("username");
  }

  initCategoryFormGroup(): void {
    this.categoryFormGroup= new FormGroup({
      category: new FormControl(this.emptyString)
    });
  }

  initUomFormGroup(): void {
    this.uomFormGroup= new FormGroup({
      unit: new FormControl(this.emptyString),
      abrUnit: new FormControl(this.emptyString)
    });
  }

  getActiveTab(){
    if(this.toPay.length == 0 && this.toShip.length == 0 && this.toReceive.length == 0 && this.completed.length == 0){
      this.activeTab = 1;
    } else if(this.toPay.length == 0 && this.toShip.length == 0 && this.toReceive.length == 0 && this.completed.length > 0){
      this.activeTab = 4;
    } else if(this.toPay.length == 0 && this.toShip.length == 0 && this.toReceive.length > 0){
      this.activeTab = 3;
    } else if(this.toPay.length == 0 && this.toShip.length > 0){
      this.activeTab = 2;
    } else {
      this.activeTab = 1;
    }
  }

  Inventory: any;
  async getInventory(){
    await this.storeItemService.getInventory().subscribe((result) => {
      this.Inventory = result;
    });
  }

  toShip: any = [];
  getToShip(){
    this.storeItemService.getPObyAdmin(3).subscribe((result) => {
      this.toShip = result;
      this.getActiveTab();
    });
  }

  toPay: any = [];
  getPO(){
    this.storeItemService.getPObyAdmin(2).subscribe((result) => {
      this.toPay = result;
      this.getActiveTab();
    });
  }

  toReceive: any = [];
  getToReceive(){
    this.storeItemService.getPObyAdmin(4).subscribe((result) => {
      this.toReceive = result;
      this.getActiveTab();
    });
  }

  completed: any = [];
  getCompleted(){
    this.storeItemService.getPObyAdmin(5).subscribe((result) => {
      this.completed = result;
      this.getActiveTab();
    });
  }

  cancelled: any = [];
  getCancelled(){
    this.storeItemService.getPObyAdmin(6).subscribe((result) => {
      this.cancelled = result;
    });
  }

  returnPO: any = [];
  getReturnPO(){
    this.storeItemService.getPObyAdmin(7).subscribe((result) => {
      this.returnPO = result;
    });
  }

  getCategories(){
    this.storeItemService.getCategories().subscribe((result) => {
      this.categories = result;
    });
  }

  getUoM(){
    this.storeItemService.getUoM().subscribe((result) => {
      this.uom = result;
    });
  }

  addCategoryModelRef: NgbModalRef;
  AddCategory(content: any){
    this.addCategoryModelRef = this.modalServe.open(content, { centered: true, size: "md" });
  }
  
  addUoMModelRef: NgbModalRef;
  AddUoM(content: any){
    this.addUoMModelRef = this.modalServe.open(content, { centered: true, size: "md" });
  }
  
  viewPOModelRef: NgbModalRef;
  selectedPO: any;
  openPO(content: any, po: any){
    this.selectedPO = po;
    this.viewPOModelRef = this.modalServe.open(content, { centered: true, size: "xl" });
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  closeModal(){
    this.modalServe.dismissAll();
  }
}
