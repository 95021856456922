
import {take, tap, scan} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { BehaviorSubject ,  Observable } from 'rxjs';




interface QueryConfig {
  path: string, //  path to collection
  field: string, // field to orderBy
  limit: number, // limit per query
  reverse: boolean, // reverse order?
  prepend: boolean // prepend to source?
}

@Injectable({
  providedIn: 'root'
})
export class PaginationService {

  private _done = new BehaviorSubject(false);
  private _loading = new BehaviorSubject(false);
  private _data = new BehaviorSubject([]);
  private allData: any[] = [];

  private query: QueryConfig;

  data: Observable<any>;
  done: Observable<boolean> = this._done.asObservable();
  loading: Observable<boolean> = this._loading.asObservable();
  totalOf:any;

  constructor(private afs: AngularFirestore) { }

  init(path, field, opts?){
    this.query = {
      path,
      field,
      limit: 10,
      reverse: false,
      prepend: false,
      ...opts
    }

    const first = this.afs.collection(this.query.path, ref => {
      return ref
      //.where('first_name', 'array-contains', '')
      .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
      .limit(this.query.limit)
    })

    this.mapAndUpdate(first)

    this.data = this._data.asObservable().pipe(
    scan((acc, val) => {
      return this.query.prepend ? val.concat(acc) : acc.concat(val)
    }))
    this.afs.collection(this.query.path).get().subscribe((total)=>{
      this.totalOf = total.size;
    });
  }

  getAllData() {
    const all = this.afs.collection(this.query.path, ref => {
      return ref.orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
    })

    all.snapshotChanges().subscribe(arr => {
      let values = arr.map(snap => {
        const data = snap.payload.doc.data() as Record<string, any>;
        const doc = snap.payload.doc;
        return { ...data, doc };
      });

      // update allData with new values
      this.allData = values;

      // update source with new values, done loading
      this._data.next(values)
      this._done.next(true)
    })
  }

  more() {
    const cursor = this.getCursor()

    const more = this.afs.collection(this.query.path, ref => {
      return ref
              .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
              .limit(this.query.limit)
              .startAfter(cursor)
    })
    this.mapAndUpdate(more)
  }

  private getCursor() {
    const current = this._data.value
    if (current.length) {
      return this.query.prepend ? current[0].doc : current[current.length - 1].doc
    }
    return null
  }

  private mapAndUpdate(col: AngularFirestoreCollection<any>) {

    if (this._done.value || this._loading.value) { return };

    // loading
    this._loading.next(true)

    // Map snapshot with doc ref (needed for cursor)
    return col.snapshotChanges().pipe(
      tap(arr => {
        let values = arr.map(snap => {
          const data = snap.payload.doc.data()
          const doc = snap.payload.doc
          return { ...data, doc }
        })

        // If prepending, reverse the batch order
        values = this.query.prepend ? values.reverse() : values

        // update source with new values, done loading
        this._data.next(values)
        this._loading.next(false)

        // no more values, mark done
        if (!values.length) {
          this._done.next(true)
        }
    }),
    take(1),)
    .subscribe()
  }

    // Reset the page
    reset() {
      this._data.next([])
      this._done.next(false)
    }
}
