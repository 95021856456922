import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class Data {
  authentication = {
    privateKey: "123456$#@$^@1ERF",
  };
}
