import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { ECPayService } from 'src/data/services/web/ecpay.service';

import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { CurrencyPipe } from '@angular/common';
import { PrintService } from 'src/data/services/web/print.service';
import { CtiService } from 'src/data/services/web/cti.service';
import { UgiService } from 'src/data/services/web/ugi.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-ugi',
  templateUrl: './ugi.component.html',
  styleUrls: ['./ugi.component.less']
})
export class UgiComponent implements OnInit {

  @Input("application") application: any;
  @Input("shortcutCode") shortcutCode: any;

  billerFormGroup : FormGroup;
  formBuilder : FormBuilder;
  public emptyString = "";
  public billers : any = [];
  public biller : any;
  public billerCode : any;
  public billerCategories : any = [];
  public billersByBillerCategories : any = [];
  public billerTypes : any = [];
  public billersByBillerTypes : any = [];
  public selectedBillerCode : any;

  public activeTab = 1;
  public loader = true;
  public fee : any;

  public detail : any;

  
  @Output("closeModal") closeModal = new EventEmitter();
  

  constructor(private ugiService : UgiService, 
    private router: Router,
    localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    formBuilder : FormBuilder) {
    this.formBuilder = formBuilder;
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.initBillerTypeFormGroup();
    this.getBillers();

    if(this.shortcutCode){
      setTimeout(() => {
        //this.initShortcut();
      }, 1500);
    }
  }

  // initShortcut(): void {
  //   this.billerCode = this.shortcutCode.itemCode;
  //   debugger
  //   this.billerFormGroup.controls.billerCategory.patchValue(this.shortcutCode.category);
  //   this.getBillersByBillerCategory(this.shortcutCode.category);
  //   this.billerFormGroup.controls.billerType.patchValue(this.shortcutCode.product_type);
  //   //this.getBillersByBillerType(this.shortcutCode.product_type);
  //   this.billerFormGroup.controls.billerCode.patchValue(this.shortcutCode.itemCode);
  //   this.setBillerCode(this.shortcutCode.itemCode);
  // }

  initBillerTypeFormGroup(): void {
    this.billerFormGroup = new FormGroup({
      billerCode: new FormControl(this.emptyString, [Validators.required]),
      mobileNumber: new FormControl(this.emptyString),
      amount: new FormControl(this.emptyString),
      params: this.formBuilder.group({})
    });
  }

  reInitBillerTypeFormGroup(): void {
    this.billerFormGroup = new FormGroup({
      billerCode: new FormControl(this.billerFormGroup.controls.billerCode.value, [Validators.required]),
      mobileNumber: new FormControl(this.billerFormGroup.controls.mobileNumber.value),
      amount: new FormControl(this.billerFormGroup.controls.amount.value),
      params: this.formBuilder.group({})
    });
  }

  getBillers() {
    this.ugiService.getProducts().subscribe((billers) => {
       this.billers = billers;
      this.getBillerCategories(billers);
    });
  }
  
  getBillerCategories(billers : any){
    var lookup = {};
    var items = billers;
    
    for (var item, i = 0; item = items[i++];) {
      var category = item.productType;
    
      if (!(category in lookup)) {
        lookup[category] = 1;
        this.billerCategories.push(category);
      }
    }
    this.loader = false;
  }

  getBillerTypes(billers : any){
    this.billerTypes = [];
    var lookup = {};
    var items = billers;
  
    for (var item, i = 0; item = items[i++];) {
      var type = item.productName;
    
      if (!(type in lookup)) {
        lookup[type] = 1;
        this.billerTypes.push(type);
      }
    }
    this.loader = false;
  }



public billerCategory : any; 
public billerType : any; 
public selectedProduct : any; 
public walletFee : any;



getBillersByBillerCategory(billerCategory : any){
  this.billersByBillerCategories = this.billers.filter(x =>x.productType == billerCategory);
  this.billerCategory = billerCategory;
  this.getBillerTypes(this.billersByBillerCategories);
}


  getBillersByBillerType(billerType : any){
    this.billersByBillerTypes = this.billers.filter(x => x.productName == billerType && x.productType == this.billerCategory);
    this.billerType = billerType;
    this.setBillerCode(billerType);
    //alert(this.billersByBillerTypes);
  }

  setBillerCode(billerCode : any){
    this.billerCode = billerCode;
    this.selectedProduct = this.billersByBillerTypes.filter(x => x.productName == this.billerCode && x.productType == this.billerCategory)[0];
   
    this.detail = this.selectedProduct.description;
    
    this.billerFormGroup.controls.amount.setValue(this.currencyPipe
      .transform(this.selectedProduct.amount.toString().replace(/,/g, ""), "PHP")
      .replace("PHP", ""));

      this.walletFee = this.currencyPipe
        .transform((this.selectedProduct.amount - (this.selectedProduct.amount * 0.03)).toString().replace(/,/g, ""), "PHP")
        .replace("PHP", "");

        // this.walletFee = this.currencyPipe
        // .transform((this.selectedProduct.deductedAmount + (this.selectedProduct.amount * 0.03)).toString().replace(/,/g, "")).replace("PHP", "");
  }

  getBiller() {
    this.activeTab++;
    this.biller= this.billers.filter(x=>x.code == this.billerCode)[0];
    this.addBillerParam(this.biller.meta);
    this.fee = this.biller.fee;
  }

  inquireBiller() {
    this.loader = true;
  //  this.ctiService.inquireBiller(this.paramsFormGroup().value,this.billerCode, this.fee).subscribe((biller) => {
      
        PNotify.info({
          title: "Validation will be execute after submitting",
          text: "Accepted",
        });
        this.loader = false;
        this.code = "";
        this.activeTab = 3;
        this.codeStatus=false;
      
  //  }, error => {
  //    PNotify.error({
  //      title: "Payment Validation",
  //      text: error.error.reason,
   //   });
   //   this.loader = false;

 //   });
  }//

  paramsFormGroup() : FormGroup{
    return this.billerFormGroup.get('params') as FormGroup;
  } 


  addBillerParam(meta: any){
    if (this.selectedBillerCode != this.billerFormGroup.controls.billerCode.value){
        this.reInitBillerTypeFormGroup();
        
        meta.forEach(param => {
          (this.billerFormGroup.get("params") as FormGroup).addControl(
            param.field,
            new FormControl("")
          );
        });
   }
    this.selectedBillerCode = this.billerFormGroup.controls.billerCode.value;
  }

  setActiveTab(){
    console.log(this.billerFormGroup.value);
    if(this.activeTab == 1){
      if(this.billerFormGroup.status == "INVALID"){
        PNotify.error({
          title: "Error",
          text: "All fields are required to proceed to next step.",
        });
      }
      else{
        this.getBiller();
      }
    }
    
    // else if(this.activeTab == 2){

    //   var hasError : boolean  = false;
    //   this.biller.meta.forEach(param => {
    //     if(!this.paramsFormGroup().controls[param.field].value && param.is_required){
    //       this.paramsFormGroup().controls[param.field].setErrors({'required': param.label + " is required."});
    //       hasError = true;
    //     }
    //   });


    //   if(hasError){
    //     PNotify.error({
    //       title: "Request denied",
    //       text: "Please fill up all the required fields.",
    //     });
    //   }else{
    //     this.inquireBiller();
    //   }

     
    // }


  }

  setActiveTabPrev(){
    if(this.activeTab == 1){
      PNotify.error({
        title: "Error",
        text: "Action Denied.",
      });
    }else{
      this.activeTab--;
    }
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  codeStatus : boolean = false;
  code : any;
  onCodeCompleted(code: string) {
    this.code = code;
    this.codeStatus = code.length == 6;
  }


  processPayment(){
      this.loader = true;
      this.ugiService.processProduct(
        this.selectedProduct.productName,
        this.billerFormGroup.controls.mobileNumber.value,
        this.code)
        .subscribe((biller) => {
          PNotify.success({
            title: "Payment Success",
            text: biller.reason,
          });
          this.loader = false;
          this.code = "";
          this.codeStatus=false;
          this.closeModal.emit();
        
      }, error => {
        PNotify.error({
          title: "Payment Failed",
          text: error.error.reason,
        });
        this.loader = false;
        this.code = "";
        this.codeStatus = false;
      });
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  totalAmount(fee:any,amount:any){
    try  {
    return this.makeMoney(Number(fee) + Number(amount));
    } catch(e){
      return "0.00";
    }
    }

    optionValue(param)
  {

    return param.options.filter(x=>x.value == this.paramsFormGroup().controls[param.field].value)[0].key;
  }
}
