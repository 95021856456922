<div [formGroup]="parentFormGroup" class="form-group row" [class.error]="error">
 
    <label  class="col-sm-3 col-form-label">{{ label }}</label>
    <div class="col-sm-9">
    <textarea 
      type="text" 
      [formControlName]="controlName" 
      [id]="controlName" 
      [placeholder]="placeholder"
      [upper-case]="upperCase"
      [no-special-char]="noSpecialChar"
      [maxlength]="maxLength"
      [tabindex]="tabIndexStart"
      class="form-control"
      [readonly]="isReadonly === 'true' ? 'readonly' : null"
      style="border-color: #294896; font-weight: 500;"
       ></textarea>
       <span *ngIf="error" class="error-msg"
       ><small><div [innerHTML]="errorMessage"></div></small></span
     >
  </div>
  </div>
  
  
