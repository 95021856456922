<div [formGroup]="parentFormGroup" class="o-form_input marker form-group" [class.error]="error">
    <span class="title">{{ label }}</span>
    <input 
      type="password" 
      [formControlName]="controlName" 
      [id]="controlName" 
      [placeholder]="placeholder"
      [upper-case]="upperCase"
      [no-special-char]="noSpecialChar"
      [maxlength]="maxLength"
      [tabindex]="tabIndexStart"
      class="form-control" 
      style="border-color: #294896;"
      />
    
    <span *ngIf="error" class="error-msg" [@default]>{{ errorMessage }}</span>
  </div>