import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { CompanyUsersService } from "src/data/services/web/company-users.service";
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "src/data/services/web/user.service";
import { ModalConfirmationComponent } from "../../modal-confirmation/modal-confirmation.component";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5
declare let LiveAgent: any;
@Component({
  selector: 'app-minified-version-login',
  templateUrl: './minified-version-login.component.html',
  styleUrls: ['./minified-version-login.component.less']
})
export class MinifiedVersionLoginComponent implements OnInit {

  

  @ViewChild('pinModal') pinModal: TemplateRef<any>;
  @ViewChild('pinEnrollModal') pinEnrollModal: TemplateRef<any>;
  @ViewChild('confirmChangePINModal') confirmChangePINModal: TemplateRef<any>;

  public userFormGroup: FormGroup;
  public emptyString = "";

  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public bsModalRefConfirmation: BsModalRef;
  public modalService: BsModalService;
  public roleLevelId: any;
  public roleLevelName: any;
  public verifyingLogin = false;
  public loader = false;
  public code = false;
  constructor(
    private router: Router,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    modalService: BsModalService,
    private route: ActivatedRoute,
    private modalBService: NgbModal,
    private userService: UserService,
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.modalService = modalService;
    PNotifyButtons;
  }

  ngOnInit() {

    this.userService.getSystemSettings().subscribe((value) => {
      var systemSettings = value.data[0].isHoliday;
      if(systemSettings == true){
        this.router.navigate(["holiday"]);
      }
     });


    this.localStorageCacheService.removeStorage("from_login");

 

    this.initUserFormGroup();
    this.localStorageCacheService.removeStorage("email_address");
    this.localStorageCacheService.removeStorage("access_token");
    this.localStorageCacheService.removeStorage("refresh_token");


    const scriptUrl = "https://juanpay.ladesk.com/scripts/track.js";
    const node = document.createElement("script");
    node.src = scriptUrl;
    node.id = "la_x2s6df8d";
    node.type = "text/javascript";
    node.async = true;
    node.charset = "utf-8";
    node.onload = function (e) {
    
    LiveAgent.createButton("f19q1v7l", document.getElementById("chatButton"));
    };
    document.getElementsByTagName("head")[0].appendChild(node);
  }

  
  

  initUserFormGroup(): void {
    this.userFormGroup = new FormGroup({
      email: new FormControl(this.emptyString, Validators.required),
      password: new FormControl(this.emptyString, Validators.required),
      code: new FormControl(this.emptyString, Validators.required),
    });
  }

  verifyLogin(): void {
   
    this.verifyingLogin = true;
    const email = this.userFormGroup.controls.email.value;
    const password = this.userFormGroup.controls.password.value;
    const code = this.userFormGroup.controls.code.value;
    var currentTimeInMilliseconds= Date.now();
    const footPrint = `${currentTimeInMilliseconds}${email}`;
    this.authService.getAccessToken(email, password,code,footPrint).subscribe(
      (token: any) => {
        if(token.result.status == 6){
          this.verifyingLogin = false;
            this.openPinEnroll(); 
        }
        else if(token.result.status == 5){
          this.verifyingLogin = false;
           if(token.loggedInStatus){
            var geolocation = JSON.parse(token.geolocation);
            if(confirm(`Your account is currently logged onto another device. By clicking [OK] other session will be logged out. Country Code : ${geolocation.country_code}, Country Name : ${geolocation.country_name}, City : ${geolocation.city}, Postal Code : ${geolocation.postal}, Latitude : ${geolocation.latitude}, Longtitude : ${geolocation.longitude}, IPV4 : ${geolocation.IPv4}, State : ${geolocation.state}.`)) {


              this.openPin(); 
            }
           }else{
            this.openPin(); 
           }
        }else{
         
          this.localStorageCacheService.setStorage("footprint", footPrint, token.details.expiresIn);
          this.localStorageCacheService.setStorage("username", token.details.username, token.details.expiresIn);
          this.localStorageCacheService.setStorage("roleId", token.details.roleId, token.details.expiresIn);
          this.localStorageCacheService.setStorage("userId", token.details.id, token.details.expiresIn);
          this.localStorageCacheService.setStorage("role_level", token.details.roleLevel, token.details.expiresIn);
          this.localStorageCacheService.setStorage("email_address", token.details.email, token.details.expiresIn);
          this.localStorageCacheService.setStorage("access_token", token.details.accessToken, token.details.expiresIn);
          this.localStorageCacheService.setStorage("role_name", token.details.role, token.details.expiresIn);
          this.localStorageCacheService.setStorage(
            "photo",
            `${token.details.photoFormat},${token.details.photo}`,
            token.details.expiresIn
          );
          const route: string =
            token.details.roleLevel == 1 ? "acm" : token.details.roleLevel == 2 ? "manager" :  token.details.roleLevel == 4 ? "sales" : "cashier";
          this.localStorageCacheService.setStorage("from_login", true, token.details.expiresIn);
            if(token.details.roleLevel != 1){
              this.userService.PostContact().subscribe(
                (result) => {
                   
                    window.location.href = `${route}/dashboard`;
                }, (error) => {
                 
                  window.location.href = `${route}/dashboard`;
                });
            }else{
              if(token.details.roleId == 10){
                window.location.href = `partner-sunlife-grepa/reports`;
              } else if(token.details.roleId == 12){
                window.location.href = `partner-vip-protect/reports`;
              }else{
                window.location.href = `${route}/dashboard`;
              }
            }
        }
      },
      (error) => {
        this.verifyingLogin = false;
      
        if (error.status === 401 || error.status === 403 || error.status === 400) {
          this.errorDialog(error.error.result.message);
        }
      }
    );
  }

  errorDialog(message): void {
    const initialState = {
      meta: {
        title: "Login",
        message: message,
        btnTitle: "Ok",
        showCancel: false,
      },
    };

    this.bsModalRefConfirmation = this.modalService.show(ModalConfirmationComponent, {
      initialState,
    });

    this.bsModalRefConfirmation.content.clickevent.subscribe((response) => {
      if (response.result) {
        //
      }
    });
  }

  
  writeUserDetails(): void {
    this.authService.validateAccessToken(this.roleLevelId).then((res) => {});
  }

  openPin() {

    
      this.modalBService.open(this.pinModal, { centered: true, size: "md" ,backdrop : 'static', keyboard : false});
  
  }

  openPinEnroll() {

    this.modalBService.open(this.pinEnrollModal, { centered: true, size: "md" ,backdrop : 'static', keyboard : false});

}

  showLoginPin(){
    this.modalBService.dismissAll();
    this.openPin();
  }

  doLoginPin(code){
    this.userFormGroup.controls.code.setValue(code);
    this.verifyLogin();
  }
  
  closeTransaction(){
    this.initUserFormGroup();
    this.modalBService.dismissAll();
  }

  confirmChangePIN(){
    this.modalBService.open(this.confirmChangePINModal, { centered: true, size: "md" ,backdrop : 'static', keyboard : false});
  }

  proceedToChangePIN(){
    this.loader = true;

    this.userService.resetPINCode(this.userFormGroup.controls.email.value).subscribe((result) => {
      //alert(this.userFormGroup.controls.email.value);

      this.userFormGroup.controls.email.setValue("");
      this.userFormGroup.controls.password.setValue("");
      this.modalBService.dismissAll();

      this.loader = false;
      PNotify.success({
        title: "PIN Code Reset",
        text: "Your Request for Reset PIN Code has been sent to your email. Kindly check and verify to proceed.",
      });

      
    });

  }
}
