import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { CryptoService } from 'src/data/crypto/crypto.service';
import { environment } from 'src/environments/environment';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { StandardInsuranceService } from 'src/data/services/web/standard-insurance.service';
import { IglooService } from 'src/data/services/web/igloo.service';

@Component({
  selector: 'app-igloo-transactions',
  templateUrl: './igloo-transactions.component.html',
  styleUrls: ['./igloo-transactions.component.less']
})
export class IglooTransactionsComponent implements OnInit {


  @ViewChild('viewIgloo') viewIgloo: TemplateRef<any>;

  @Input("formGroupSearch") formGroupSearch: FormGroup;
  billerFormGroup : FormGroup;
  formBuilder : FormBuilder;
  iglooService : IglooService;
  loader : boolean = false;
  emptyString : any = "";
  public transactions: any = [];
  public transactionsForExcel: any = [];
  public transaction: any;
  p: number = 1;


  constructor(iglooService : IglooService, 
    private router: Router,
    private currencyPipe : CurrencyPipe,
    formBuilder : FormBuilder,
    private route: ActivatedRoute, 
    private cryptoService: CryptoService,
    public datepipe: DatePipe,
    localStorageCacheService: LocalStorageCacheService,
    private modalBService: NgbModal) {
    this.iglooService = iglooService;
    this.formBuilder = formBuilder;
    PNotifyButtons;
  }


  ngOnInit(): void {

  }


  // public getTransactions(biller : any, mobileNumber:any,  code:any): Observable<any> {
  //   //?pageNumber=1&pageSize=10&biller=WSAYA20&accountNo=09462374088&requestId=00051202104300244000916898&status=1
  //   const endPointUrl = `${this.baseAPIUrl}/api/CTI/Products/Process`;
  //   const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  getTransaction(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader =true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    
    const biller : string = this.formGroupSearch.controls.biller?.value?.replace("&","GOVIPSNITCH");
    const accountNo : string = this.formGroupSearch.controls.accountNo.value;
    const status : string = this.formGroupSearch.controls.status.value;
    const requestId : string = this.formGroupSearch.controls.requestId.value;
    const userId : string = this.formGroupSearch.controls.userId.value;
    const dateRange : string = this.formGroupSearch.controls.dateRange.value;
    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_biller: string = biller ? `biller=${biller}&` : "";
    const queryString_accountNo: string = accountNo ? `accountNo=${accountNo}&` : "";
    const queryString_status: string = status ? `status=${status}&` : "";
    const queryString_requestId: string = requestId ? `requestId=${requestId}&` : "";
    const queryString_userId: string = userId ? `id=${userId}&` : "";
    const queryString_showRows: string = `showRows=10&`;
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_biller + queryString_accountNo+ queryString_status+ queryString_userId+ queryString_requestId + queryString_dateRange + queryString_showRows;

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/Igloo/Transactions?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/Igloo/Transactions?${queryString}`;
      }
    }

    this.iglooService.getTransactions(endPointUrl.slice(0, -1)).subscribe((result) => {
      this.loader =false;
      if(result){
        if (result.data.length == 0 && result.pageNumber != 1) {
          this.getTransaction(result.previousPage, _sortBy, _sort);
        } else {
          this.transactions = result;
        }
      }
     
    });
  }

  getTransactionForExcel(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader =true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    const biller : string = this.formGroupSearch.controls.biller.value;
    const accountNo : string = this.formGroupSearch.controls.accountNo.value;
    const status : string = this.formGroupSearch.controls.status.value;
    const requestId : string = this.formGroupSearch.controls.requestId.value;
    const userId : string = this.formGroupSearch.controls.userId.value;
    const dateRange : string = this.formGroupSearch.controls.dateRange.value;
    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_biller: string = biller ? `biller=${biller}&` : "";
    const queryString_accountNo: string = accountNo ? `accountNo=${accountNo}&` : "";
    const queryString_status: string = status ? `status=${status}&` : "";
    const queryString_requestId: string = requestId ? `requestId=${requestId}&` : "";
    const queryString_userId: string = userId ? `id=${userId}&` : "";
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_biller + queryString_accountNo+ queryString_status+ queryString_userId+ queryString_requestId + queryString_dateRange;

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/Igloo/TransactionsHistory?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/Igloo/TransactionsHistory?${queryString}`;
      }
    }

    this.iglooService.getTransactions(endPointUrl.slice(0, -1)).subscribe((result) => {
      this.loader =false;
      if(result){
        if (result.data.length == 0 && result.pageNumber != 1) {
          this.getTransaction(result.previousPage, _sortBy, _sort);
        } else {
          this.transactionsForExcel = result;
        }
      }
     
    });
  }

  createdDate = "";
  searchString = "";
  
  private _sortBy: any;
  private _sort: any;

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.createdDate = field == "createdDate" ? "th-inner sortable both " + sortBy : "";
  
    this.getTransaction(
      `${environment.API_URL}/api/Igloo/Transactions?pageNumber=${
        page ? page : this.transactions.pageNumber
      }`,
      this._sortBy,
      this._sort
    );
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.transactions?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }

  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p = pageNumber;
    return `${environment.API_URL}/api/Igloo/Transactions?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }

  statusOfAmount(money: any): boolean {
    return money < 0 ? false : true;
  }

  makeMoney(money: any) {
    if(money){
      return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
    } else if(money == "0"){
      return "0.00";
    } else {
      return "N/A";
    }
  }

  requestId;
  setSelectedTransaction(transaction: any) {
  
    this.transaction = transaction;
    this.requestId = this.transaction.requestId;
    this.openTransDetail(this.viewIgloo);
  }
  modalCreateTransaction:any;
  openTransDetail(viewModal) {
    this.modalCreateTransaction = this.modalBService.open(viewModal, { centered: true, size: "xl" });
  }


  closeModal(){
    this.modalCreateTransaction.close();
    this.getTransaction(null,null,null,null);
  }

  getReference(transaction){
    var json = JSON.parse(transaction);

    return json.issirefno ? json.issirefno[0] : "-----"; 
  }
}
