import { Component,  Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl  } from '@angular/platform-browser';

@Component({
  selector: 'app-external-partner',
  templateUrl: './external-partner.component.html',
  styleUrls: ['./external-partner.component.less']
})
export class ExternalPartnerComponent implements OnInit {


  @Input("urlPath") urlPath: any;
  url: SafeResourceUrl;

  constructor (public sanitizer:DomSanitizer) {
  }
  ngOnInit() {
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlPath);      
  }


}
