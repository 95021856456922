<div id="content" class="flex ">
    <!-- ############ Main START-->
    <div class="d-flex flex fixed-content">
        <div class="aside aside-sm" id="content-aside">
            <div class="d-flex flex-column w-xl modal-dialog bg-body" id="chat-nav">
                <div class="navbar">
                    <div class="input-group flex bg-light rounded">
                        <input type="text" class="form-control no-bg no-border no-shadow search" placeholder="Search" required="">
                        <span class="input-group-append">
              <button class="btn no-bg no-shadow" type="button"><i data-feather="search" class="text-fade"></i></button>
            </span>
                    </div>
                </div>
                <div class="scrollable hover">
                    <div class="list list-row">
                        <div class="list-item " data-id="1">
                            <div>
                                <span class="avatar-status on"></span>
                            </div>
                            <div>
                                <a href="app.message.html">
                                    <span class="w-40 avatar gd-primary">
                      <img src="../assets/img/a1.jpg" alt=".">
                </span>
                                </a>
                            </div>
                            <div class="flex">
                                <a href="app.message.html" class="item-author text-color ">Joyce McCoy</a>
                                <div class="item-except text-muted text-sm h-1x">
                                    In WordPress Tutorial, we’ll streamline the process for you by pointing out the all key features of the WordPress
                                </div>
                            </div>
                            <div>
                            </div>
                        </div>
                        <div class="list-item " data-id="14">
                            <div>
                                <span class="avatar-status off"></span>
                            </div>
                            <div>
                                <a href="app.message.html">
                                    <span class="w-40 avatar gd-warning">
                      B
                </span>
                                </a>
                            </div>
                            <div class="flex">
                                <a href="app.message.html" class="item-author text-color ">Benjamin Fields</a>
                                <div class="item-except text-muted text-sm h-1x">
                                    Managing data in all software or applications becomes simplified when you make use of the best
                                    <a href='#'>#DatabaseMan</a>
                                </div>
                            </div>
                            <div>
                            </div>
                        </div>
                        <div class="list-item " data-id="20">
                            <div>
                                <span class="avatar-status on"></span>
                            </div>
                            <div>
                                <a href="app.message.html">
                                    <span class="w-40 avatar gd-warning">
                      G
                </span>
                                </a>
                            </div>
                            <div class="flex">
                                <a href="app.message.html" class="item-author text-color ">Gloria Williams</a>
                                <div class="item-except text-muted text-sm h-1x">
                                    Netflix hackathon creates eye tracking option for iOS app
                                </div>
                            </div>
                            <div>
                                <span class="item-amount d-none d-sm-block badge badge-pill gd-warning">
                      50
                    </span>
                            </div>
                        </div>
                        <div class="list-item " data-id="6">
                            <div>
                                <span class="avatar-status on"></span>
                            </div>
                            <div>
                                <a href="app.message.html">
                                    <span class="w-40 avatar gd-danger">
                      <img src="../assets/img/a6.jpg" alt=".">
                </span>
                                </a>
                            </div>
                            <div class="flex">
                                <a href="app.message.html" class="item-author text-color ">Wayne Gray</a>
                                <div class="item-except text-muted text-sm h-1x">
                                    This makes me believe there are good people in the world still
                                </div>
                            </div>
                            <div>
                                <span class="item-amount d-none d-sm-block badge badge-pill gd-warning">
                      2
                    </span>
                            </div>
                        </div>
                        <div class="list-item " data-id="16">
                            <div>
                                <span class="avatar-status on"></span>
                            </div>
                            <div>
                                <a href="app.message.html">
                                    <span class="w-40 avatar gd-info">
                      F
                </span>
                                </a>
                            </div>
                            <div class="flex">
                                <a href="app.message.html" class="item-author text-color ">Frances Stewart</a>
                                <div class="item-except text-muted text-sm h-1x">
                                    When it comes to artificial intelligence, little things can add up in big ways
                                </div>
                            </div>
                            <div>
                                <span class="item-amount d-none d-sm-block badge badge-pill gd-warning">
                      20
                    </span>
                            </div>
                        </div>
                        <div class="list-item " data-id="9">
                            <div>
                                <span class="avatar-status on"></span>
                            </div>
                            <div>
                                <a href="app.message.html">
                                    <span class="w-40 avatar gd-info">
                      <img src="../assets/img/a9.jpg" alt=".">
                </span>
                                </a>
                            </div>
                            <div class="flex">
                                <a href="app.message.html" class="item-author text-color ">Steven Cruz</a>
                                <div class="item-except text-muted text-sm h-1x">
                                    Build a progressive web app using jQuery
                                </div>
                            </div>
                            <div>
                                <span class="item-amount d-none d-sm-block badge badge-pill gd-warning">
                      5
                    </span>
                            </div>
                        </div>
                        <div class="list-item " data-id="11">
                            <div>
                                <span class="avatar-status on"></span>
                            </div>
                            <div>
                                <a href="app.message.html">
                                    <span class="w-40 avatar gd-info">
                      K
                </span>
                                </a>
                            </div>
                            <div class="flex">
                                <a href="app.message.html" class="item-author text-color ">Kenneth Pierce</a>
                                <div class="item-except text-muted text-sm h-1x">
                                    @DataAPI Implemented new API for transation data from client to server.
                                </div>
                            </div>
                            <div>
                            </div>
                        </div>
                        <div class="list-item " data-id="19">
                            <div>
                                <span class="avatar-status on"></span>
                            </div>
                            <div>
                                <a href="app.message.html">
                                    <span class="w-40 avatar gd-warning">
                      T
                </span>
                                </a>
                            </div>
                            <div class="flex">
                                <a href="app.message.html" class="item-author text-color ">Tiffany Baker</a>
                                <div class="item-except text-muted text-sm h-1x">
                                    We design and implement native iOS apps that harmonize simplicity of design and complexity of function
                                </div>
                            </div>
                            <div>
                                <span class="item-amount d-none d-sm-block badge badge-pill gd-warning">
                      100
                    </span>
                            </div>
                        </div>
                    </div>
                    <div class="no-result hide">
                        <div class="p-4 text-center">
                            No Results
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex pr-md-3" id="content-body">
            <div class="d-flex flex-column flex card m-0 mb-md-3" id="chat-list" data-plugin="chat">
                <div class="navbar flex-nowrap b-b">
                    <button data-toggle="modal" data-target="#content-aside" data-modal class="d-md-none btn btn-sm btn-icon no-bg">
                        <span>
                  <i data-feather="menu"></i>
            </span>
                    </button>
                    <span class="text-ellipsis flex mx-1">
            <span class="text-md text-highlight mx-2">Team messages</span>
                    </span>
                    <span class="flex"></span>
                    <div>
                        <div class="d-flex flex-wrap align-items-center avatar-group">
                            <a href="app.message.html" class="w-24 avatar circle bg-danger-lt">
                                <img src="../assets/img/a10.jpg" alt=".">
                            </a>
                            <a href="app.message.html" class="w-24 avatar circle bg-primary-lt">
                                <img src="../assets/img/a3.jpg" alt=".">
                            </a>
                            <a href="app.message.html" class="w-24 avatar circle bg-info-lt">
                                A
                            </a>
                            <a href="app.message.html" class="w-24 avatar circle bg-warning-lt">
                                T
                            </a>
                            <a class="w-24 avatar circle bg-primary">
                                <i data-feather="plus"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="scrollable hover">
                    <div class="loading m-3"></div>
                    <div class="list hide">
                        <div class="p-3">
                            <div class="chat-list">
                                <div class="chat-item" data-class>
                                    <a href="#" class="avatar w-40">
                                        <img src="../assets/img/a2.jpg" alt=".">
                                    </a>
                                    <div class="chat-body">
                                        <div class="chat-content rounded msg bg-body">
                                            New photos coming...
                                        </div>
                                        <div class="w-md my-3">
                                            <div class="row row-xs">
                                                <div class="col-4">
                                                    <div class="media media-4x3 r box-shadows">
                                                        <a class="media-content" style="background-image: url(../assets/img/b1.jpg);"></a>
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="media media-4x3 r box-shadows">
                                                        <a class="media-content" style="background-image: url(../assets/img/b7.jpg);"></a>
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="media media-4x3 r box-shadows">
                                                        <a class="media-content" style="background-image: url(../assets/img/b16.jpg);"></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="chat-date date">
                                            2 days ago
                                        </div>
                                    </div>
                                </div>
                                <div class="chat-item" data-class="alt">
                                    <a href="#" class="avatar w-40">
                                        <img class="image" src="../assets/img/a0.jpg" alt=".">
                                    </a>
                                    <div class="chat-body">
                                        <div class="chat-content rounded msg bg-body">
                                            Hi, Jacqueline Reid...
                                        </div>
                                        <div class="w-md my-3">
                                            <div class="row row-xs">
                                                <div class="col-12">
                                                    <div class="media media-2x1 r box-shadows">
                                                        <a class="media-content" style="background-image: url(../assets/img/b11.jpg);"></a>
                                                        <div class="media-action active">
                                                            <a href="#" class="btn btn-md btn-icon btn-white btn-rounded">
                                                                <i data-feather="play" width="12" height="12"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="chat-date date">
                                            8 hrs ago
                                        </div>
                                    </div>
                                </div>
                                <div class="chat-item" data-class>
                                    <a href="#" class="avatar w-40">
                                        <img class="image" src="../assets/img/a2.jpg" alt=".">
                                    </a>
                                    <div class="chat-body">
                                        <div class="chat-content rounded msg block bg-primary--lt">
                                            <a href="#">document.pdf <span class="text-muted">2.5mb</span>
                                                <i class="text-muted" data-feather="download"></i>
                                            </a>
                                        </div>
                                        <div class="chat-date date">
                                            2 hrs ago
                                        </div>
                                    </div>
                                </div>
                                <div class="chat-item" data-class>
                                    <a href="#" class="avatar w-40">
                                        <img class="image" src="../assets/img/a2.jpg" alt=".">
                                    </a>
                                    <div class="chat-body">
                                        <div class="chat-content rounded msg bg-body">
                                            Typing...
                                        </div>
                                        <div class="chat-date date">
                                            Just now
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="hide">
                                <div class="chat-item" id="chat-item" data-class>
                                    <a href="#" class="avatar w-40">
                                        <img class="image" src="../assets/img/a0.jpg" alt=".">
                                    </a>
                                    <div class="chat-body">
                                        <div class="chat-content rounded msg block bg-body">
                                        </div>
                                        <div class="chat-date date"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-auto b-t" id="chat-form">
                    <div class="p-2">
                        <div class="px-3">
                            <div class="toolbar my-1">
                                <a href="#" class="text-muted mx-1">
                                    <i data-feather="image" width="14" height="14"></i>
                                </a>
                                <a href="#" class="text-muted mx-1">
                                    <i data-feather="camera" width="14" height="14"></i>
                                </a>
                                <a href="#" class="text-muted mx-1">
                                    <i data-feather="map-pin" width="14" height="14"></i>
                                </a>
                                <a href="#" class="text-muted mx-1">
                                    <i data-feather="paperclip" width="14" height="14"></i>
                                </a>
                            </div>
                        </div>
                        <div class="input-group">
                            <input type="text" class="form-control p-3 no-shadow no-border" placeholder="Say something" id="newField">
                            <button class="btn btn-icon btn-rounded gd-success" type="button" id="newBtn">
                                <i data-feather="arrow-up"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ############ Main END-->
</div>