import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CryptoService } from 'src/data/crypto/crypto.service';
import { Data } from 'src/data/data';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { ProductCategory } from 'src/data/models/product_category.model';
import { idLocale } from 'ngx-bootstrap/chronos';
import { CurrencyPipe } from '@angular/common';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { sortBy } from 'lodash';
import { Select2OptionData } from "ng-select2";
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { PaginationService } from 'src/app/services/pagination.service';
import * as XLSX from 'xlsx'
import { ExportExcelService } from 'src/data/services/web/export-excel.service';
import * as firebase from 'firebase';
import { VipcoTransactionService } from 'src/data/services/web/vipco-transaction.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-vips-merchant-store-history',
  templateUrl: './vips-merchant-store-history.component.html',
  styleUrls: ['./vips-merchant-store-history.component.less']
})
export class VipsMerchantStoreHistoryComponent implements OnInit {
  title = 'angular-app';
  fileName = 'MerchantApplicationSheet.xlsx'
  user: Observable<any>;
  searchText:any;
  selected:any;
  constructor(
    public page : PaginationService,
    private route: ActivatedRoute,
    private modalServe: NgbModal,
    private localStorageCacheService: LocalStorageCacheService,
    private vipcoTransactionService: VipcoTransactionService,
    private cryptoService: CryptoService,
    private store: AngularFirestore,
    private data: Data,
    public ete: ExportExcelService

    ) {
   }
  phoneNo: any;
  productCategory: ProductCategory
  merchants: any;
  categories: any;
  products: any;
  hours: any;
  selectedProduct: any;
  selectedCategory: any;
  username: any;
  ngOnInit(): void {
    //this.initAllMerchants();
    this.page.reset();
    this.username = this.localStorageCacheService.getStorage("username");
    this.route.params.subscribe((params) => {
      const id = decodeURI(params.id || "");
      this.phoneNo = this.cryptoService.get(this.data.authentication.privateKey, id);
      this.initMerchant(this.phoneNo);
    });
  }

async initMerchant(phoneNo: any){
  await this.page.init('business_registration','timestamp', {reverse:true, prepend:false});
}

showAllData(){
  this.page.getAllData();
  setTimeout(() => {
    location.reload();
  }, 15000);
}

selectedMerchant:any;
remarkModal: NgbModalRef;
openRemark(content, merchant){
  debugger
  this.selectedMerchant = merchant;
  this.remarkModal = this.modalServe.open(content, { centered: true, size: "md" });
}

imgSrc:string;
  onClick(event){
    const imgElem = event.target;
    var target = event.target || event.srcElement || event.currentTarget;
    var srcAttr = target.attributes.src;
    this.imgSrc = srcAttr.nodeValue;
  }

scrollHandler(e){
  if (e === 'bottom'){
    this.page.more()
  }
}

  merchantListExcel:any;
  // exportexcel(){
  //   this.store.collection('business_registration',ref => ref.orderBy("timestamp","desc")).valueChanges().subscribe((val: any) => {
  //     let merchantList = val.filter(x => x.application_status != null && x.application_rejected != null);
  //     this.generateExcel(merchantList,"Merchant Application");
  //   });
  // }

  dbMerchants = [];
  async initAllMerchants(){
    await this.vipcoTransactionService.getAllMerchants().subscribe((result) => {
      this.dbMerchants = result;
    });
  }
  
  startDate: string;
  endDate: string;
  loader: boolean = false;
  async exportexcel() {
    this.loader = true;
    const orderIds = ['09111222223', '09556025238','09515424280','09000000432','09060000001','09066666662','09081111111','09085555599','09111111188', '09112222333'];

    // this.store
    // .collection('business_registration', ref => ref.where(firebase.default.firestore.FieldPath.documentId(), 'not-in', orderIds))
    // .get()
    // .subscribe((querySnapshot) => {
    //   const riderList = querySnapshot.docs
    //     .filter(doc => doc.exists)
    //     .map(doc => doc.data() as { application_status: string, application_rejected: boolean }) // type assertion here
    //     .filter(data => data.application_status != null && data.application_rejected != null);

    //   this.generateExcel(riderList, 'Merchant Application');
    // });

    var riderList = [];
    await this.store.firestore.collection("business_registration")
    .where(firebase.default.firestore.FieldPath.documentId(), 'not-in', orderIds)
    .get()
    .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          riderList.push({ docId : doc.id, docData: doc.data(), products: [] });
        });
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });

    //this.generateExcel(riderList, 'Merchant Application');
    await this.vipcoTransactionService.getAllMerchants().subscribe((result) => {
      this.dbMerchants = result;
      this.generateExcel(riderList, result, 'Merchant Application');
    });
  }

  dataForExcel = [];
  Transactions : any;

  generateExcel(merchantList: any, result : any, name: any): void {
    this.Transactions = [];
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
      for(var i = 0; i < merchantList.length; i++){

        let status = "";
        let merchantListName = "";

        if(merchantList[i].docData.application_rejected == true){
          status = "Rejected"
        }  else if (merchantList[i].docData.application_rejected == false){
          status = "Approved"
        } else {
          status = "Pending"
        }

        var linkedStore = result.find(x => x.mobileNo == merchantList[i].docId);
        debugger
        if(status != "Pending"){
          let meta = [
            {
                "MobileNo": merchantList[i].docId,
                "Created Date": merchantList[i].docData.timestamp?.toDate().toLocaleDateString('en-US', options).replace(/\b(\d{1})\b/g, '0$1'), 
                "Store Name": merchantList[i].docData.business_name?.toUpperCase(),
                "Owner's Name": merchantList[i].docData?.first_name?.toUpperCase() + " " + merchantList[i].docData.last_name?.toUpperCase(),
                "Store Address":	merchantList[i].docData.business_address_address + " " +
                merchantList[i].docData.business_address_barangay + ", " +
                merchantList[i].docData.business_address_municipalty + " " +
                merchantList[i].docData.business_address_province,
                "Municipality":merchantList[i].docData.business_address_municipalty,
                "Owner's Email": merchantList[i].docData.email_address,
                "Date of Approval": merchantList[i].docData.timestamp?.toDate().toLocaleDateString('en-US', options).replace(/\b(\d{1})\b/g, '0$1'), 
                "Food Type": merchantList[i].docData.business_food_type,
                "Status": status,
                "Linked Store": linkedStore?.linkedStore,
                "Linked User": linkedStore?.userFname,
                "Linked Type": linkedStore?.usertype
            }
          ]
  
          this.Transactions.push(meta);
        }
      }



    for(var x = 0; x < this.Transactions.length; x++){
      this.dataForExcel.push(Object.values(this.Transactions[x][0]));
    }



    let reportData = {
      title: name + ' Report',
      data: this.dataForExcel,
      headers: Object.keys(this.Transactions[0][0])
    }

    this.loader = false;
    this.ete.exportExcel(reportData, name);
  }
}
