<app-loader *ngIf="!salesUserFormGroup"></app-loader>
<app-loader *ngIf="processCreate"></app-loader>
<div *ngIf="salesUserFormGroup" id="content" class="flex">
  <!-- ############ Main START-->

    <div class="container-fluid">
      <div class="page-content page-container mt-3">

        <div class="shadow-lg mb-3 p-4 bg-white rounded">
          <div class="row">
            <div class="col">
                <h5 class="card-title text-uppercase font-weight-bold mb-0" style="color: #0e1b82;">Activation Center</h5>
                <span class="h5 mb-0">Make sure all the information are correct</span>
            </div>
            <div class="col-auto">
                <div class="icon icon-shape text-dark rounded-circle shadow" style="background-color: #9dcb5f;">
                    <i class="fas fa-toggle-on"></i>
                </div>
            </div>
          </div>
        </div>

        <!-- [ngClass]="isActivated == 2 ? 'card--activated' : 'card--enlist'" -->
        <div class="card p-3" *ngIf="salesUserFormGroup" [ngClass]="isActivated == 2 ? 'card--activated' : ''">
          <!-- <button class="btn btn-primary btn-sm float-right">Save &nbsp; <i class="fa fa-save"></i></button> -->
          <div class="row pl-3 pr-3" [formGroup]="salesUserFormGroup">
            <div class="col-sm-12 col-md-12 col-lg-12 pr-1 pb-2">

              <button 
                class="btn btn-sm float-right" 
                style="background-color: #000f87; color: rgb(255, 255, 255);"
                (click)="actionEventCreateCompanyUser()"
                *ngIf="isActivated != 2">
                Submit &nbsp; <i class="fa fa-paper-plane"></i>
              </button>
              
              <button 
                class="btn btn-sm float-right btn-info mr-2" 
                style="color: rgb(255, 255, 255);"
                (click)="actionEventDraftCompanyUser()"
                *ngIf="isActivated != 2">
                Save &nbsp; <i class="fa fa-save"></i>
              </button>

              <button 
                class="btn btn-sm btn-secondary mr-2 float-right"
                (click)="backTolist()">
                 <i class="fa fa-arrow-circle-left"></i> &nbsp; Back to List
              </button>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 p-2">
              <div class="shadow-lg mb-3 rounded">
                <div class="card-header">
                  <strong>Account Information</strong>
                </div>
                <div class="card-body">
                  <form>

                    <app-textbox
                      [parent-form-group]="salesUserFormGroup"
                      control-name="username"
                      read-only="{{isActivated}}"
                      label="Username"
                      placeholder="Username"
                    >
                    </app-textbox>

                    <app-textbox
                      [parent-form-group]="salesUserFormGroup"
                      control-name="email"
                      read-only="{{isActivated}}"
                      label="Email Address"
                      placeholder="Email Address"
                    >
                    </app-textbox>

                    <!-- <app-dropdown
                      place-holder="Role"
                      [parent-form-group]="salesUserFormGroup"
                      control-name="roleId"
                      label="Role"
                      [data]="roles"
                      [error]="salesUserFormGroup?.controls.roleId.errors | estatus"
                      [error-message]="salesUserFormGroup?.controls.roleId.errors | econtent"
                    >
                    </app-dropdown> -->

                    <!-- <app-boolslider
                      *ngIf="true"
                      [parentFormGroup]="salesUserFormGroup"
                      controlName="status"
                      label="Account"
                    >
                    </app-boolslider> -->
                  </form>
                </div>
              </div>  
              
              <div class="shadow-lg mb-3 rounded">
                <div class="card-header">
                <strong>Personal Information</strong>
              </div>
              <div class="card-body">
                <form>
                  <app-textbox
                    [parent-form-group]="salesUserFormGroup"
                    control-name="firstName"
                    read-only="{{isActivated}}"
                    no-special-char="false"
                    label="First name"
                    placeholder="First name"
                  >
                  </app-textbox>

                  <app-textbox
                    [parent-form-group]="salesUserFormGroup"
                    control-name="middleName"
                    read-only="{{isActivated}}"
                    no-special-char="false"
                    label="Middle name"
                    placeholder="Middle name"
                  >
                  </app-textbox>

                  <app-textbox
                    [parent-form-group]="salesUserFormGroup"
                    control-name="lastName"
                    read-only="{{isActivated}}"
                    no-special-char="false"
                    label="Last name"
                    placeholder="Last name"
                  >
                  </app-textbox>

                  <app-datepicker
                    [parent-form-group]="salesUserFormGroup"
                    control-name="birthDate"
                    no-special-char="false"
                    label="Birth Date "
                    placeholder="Birth Date "
                  >
                  </app-datepicker>

                  <app-dropdown
                    place-holder="Gender"
                    [parent-form-group]="salesUserFormGroup"
                    control-name="gender"
                    label="Gender"
                    [data]="genders"
                  >
                  </app-dropdown>

                <app-textbox
                  [parent-form-group]="salesUserFormGroup"
                  control-name="contactNumber"
                  read-only="{{isActivated}}"
                  label="Contact No."
                  placeholder="Contact No."
                >
                </app-textbox>

                <app-textarea
                    [parent-form-group]="salesUserFormGroup"
                    control-name="address"
                    read-only="{{isActivated}}"
                    label="Address"
                    placeholder="Address"
                  >
                </app-textarea>

                </form>
              </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 p-2">
              <div class="shadow-lg mb-3 rounded">
                <div class="card-header">
                  <strong>Settlement Account Details</strong>
                </div>
                <div class="card-body">
                  <form>
                      <!-- <app-textbox
                        [parent-form-group]="salesUserFormGroup"
                        control-name="bankName"
                        [error]="salesUserFormGroup?.controls.bankName.errors | estatus"
                        [error-message]="salesUserFormGroup?.controls.bankName.errors | econtent"
                        read-only="{{isActivated}}"
                        label="Bank Name"
                        placeholder="Bank Name"
                      >
                      </app-textbox> -->

                      <app-dropdown
                        place-holder="Bank Name"
                        [parent-form-group]="salesUserFormGroup"
                        control-name="bankName"
                        label="Bank Name"
                        [data]="bankNames"
                      >
                      </app-dropdown>

                      <app-textbox
                        [parent-form-group]="salesUserFormGroup"
                        control-name="bankAccountNumber"
                        read-only="{{isActivated}}"
                        label="Account Number"
                        placeholder="Bank Account Number"
                      >
                      </app-textbox>

                      <app-textbox

                        [parent-form-group]="salesUserFormGroup"
                        control-name="bankAccountName"
                        read-only="{{isActivated}}"
                        label="Account Name"
                        placeholder="Bank Account Name"
                      >
                      </app-textbox>
                      
                  </form>
                </div>
              </div>

                <div class="shadow-lg mb-3 rounded">
                  <div class="card-header">
                    <strong>Activation Details</strong>
                  </div>
                  <div class="card-body pt-1">
                    
                    <form>
                        <!-- <app-textbox
                          [parent-form-group]="salesUserFormGroup"
                          tab-index-start="0"
                          control-name="membersId"
                          [error]="salesUserFormGroup?.controls.membersId.errors | estatus"
                          [error-message]="salesUserFormGroup?.controls.membersId.errors | econtent"
                          read-only="false"
                          label="Members ID"
                          placeholder="Members ID"
                        >
                        </app-textbox> -->
                        <div class="row">
                          <div class="col-12">
                            <button type="button" *ngIf="isActivated != 2 && isActivated != 3" class="btn btn-light btn-sm float-right mb-2" (click)="editActivation()">Edit activation</button>
                          </div>
                        </div>
                        <div class="row mb-3" *ngIf="!hasMeta">
                          <label class="col-3 col-form-label d-flex align-items-center" for="sponsor">Sponsor</label>                          
                          <div class="col-9">
                            <ng-select [items]="salesUser" 
                                [parent-form-group]="salesUserFormGroup"
                                placeholder="Sponsor"
                                bindLabel="text" 
                                bindValue="id" 
                                control-name="sponsorId"
                                class="custom"
                                [searchable]="true"
                                [clearable]="true"
                                [virtualScroll]="true"
                                [loading]="loader"
                                (clear)="onRemoveSponsor($event)"
                                (change)="getValues($event)"
                                
                                
                                (search)="onSearch($event)"
                                (keyup.enter)="onEnter()"
                                [ngModelOptions]="{standalone: true}">
                            </ng-select>
                            <!-- (open)="onOpen()" -->
                          </div>
                        </div>

                        <!-- <app-textbox
                          *ngIf="hasMeta"
                          [parent-form-group]="salesUserFormGroup"
                          tab-index-start="0"
                          control-name="sponsorId"
                          [error]="salesUserFormGroup?.controls.sponsorId.errors | estatus"
                          [error-message]="salesUserFormGroup?.controls.sponsorId.errors | econtent"
                          read-only="true"
                          label="Sponsor"
                          placeholder="Sponsor"
                        >
                        </app-textbox> -->

                        <div class="row mb-3" *ngIf="hasMeta">
                          <label class="col-sm-3 col-form-label">Sponsor</label>
                          <div class="col-sm-9">
                            <input
                              type="text"
                              value="{{sponsor.username}}"
                              readonly="true"
                              class="form-control"
                            />
                          </div>
                        </div>
                        

                        <div class="row mb-3" *ngIf="hasSponsor && !hasMeta">
                          <label class="col-3 col-form-label d-flex align-items-center" for="sponsor">Connected To</label>        
                          <div class="col-9">
                            <ng-select [items]="uplineUser" 
                                placeholder="Connected To"
                                [parent-form-group]="salesUserFormGroup"
                                bindLabel="text" 
                                bindValue="id" 
                                control-name="uplineId"
                                class="custom"
                                (clear)="onRemoveUpline($event)"
                                (change)="getPlacements($event)"
                                
                                (search)="onSearchUpline($event)"
                                (keyup.enter)="onEnterUpline()"
                                >
                            </ng-select>
                          </div>
                        </div>

                        <!-- <app-textbox
                          *ngIf="hasMeta"
                          [parent-form-group]="salesUserFormGroup"
                          tab-index-start="0"
                          control-name="uplineId"
                          [error]="salesUserFormGroup?.controls.uplineId.errors | estatus"
                          [error-message]="salesUserFormGroup?.controls.uplineId.errors | econtent"
                          read-only="true"
                          label="Upline"
                          placeholder="Upline"
                        >
                        </app-textbox> -->

                        <div class="row mb-3" *ngIf="hasMeta">
                          <label class="col-sm-3 col-form-label">Connected To</label>
                          <div class="col-sm-9">
                            <input
                              type="text"
                              value="{{upline.username}}"
                              readonly="true"
                              class="form-control"
                            />
                          </div>
                        </div>

                        <div class="row mb-3" *ngIf="hasUpline && !hasMeta">
                          <label class="col-3 col-form-label d-flex align-items-center" for="sponsor">Placement</label> 
                          <div class="col-9">
                            <!-- <div class="radio-toolbar">
                              <input type="radio" class="p-1" formControlName="placement" id="placement" name="placement" (change)="placementChange('Left')" [attr.disabled]="hasLeft ? '' : null">
                              <label for="placement"> &nbsp;&nbsp; Left &nbsp;&nbsp; </label>
                          
                              <input type="radio" class="ml-2 p-1" formControlName="placement" id="placement" name="placement" (change)="placementChange('Right')" [attr.disabled]="hasRight ? '' : null">
                              <label for="placement"> &nbsp;&nbsp; Right &nbsp;&nbsp; </label>
                            </div> -->

                            <div class="radio-toolbar">
                              <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="inlineRadioOptions" formControlName="placement" (change)="placementChange('Left')" id="placement" name="placement" [attr.disabled]="hasLeft ? '' : null">
                              <label class="form-check-label" for="placement">Left</label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" name="inlineRadioOptions" formControlName="placement" (change)="placementChange('Right')" id="placement" name="placement" [attr.disabled]="hasRight ? '' : null">
                              <label class="form-check-label" for="placement">Right</label>
                            </div>
                            </div>

                            
                          </div>

                          
                        </div>

                        <app-textbox
                          *ngIf="hasMeta"
                          [parent-form-group]="salesUserFormGroup"
                          tab-index-start="0"
                          control-name="placement"
                          read-only="true"
                          label="Placement"
                          placeholder="Placement"
                        >
                        </app-textbox>

                        <!-- <app-dropdown
                          place-holder="Position"
                          [parent-form-group]="salesUserFormGroup"
                          control-name="position"
                          label="Position"
                          [data]="roles"
                          [error]="salesUserFormGroup?.controls.position.errors | estatus"
                          [error-message]="salesUserFormGroup?.controls.position.errors | econtent"
                        >
                        </app-dropdown> -->
                        <hr>

                        <!-- <app-textbox
                          *ngIf="hasMeta"
                          [parent-form-group]="salesUserFormGroup"
                          tab-index-start="0"
                          control-name="membersId"
                          [error]="salesUserFormGroup?.controls.membersId.errors | estatus"
                          [error-message]="salesUserFormGroup?.controls.membersId.errors | econtent"
                          read-only="true"
                          label="Members ID"
                          placeholder="Members ID"
                        >
                        </app-textbox> -->

                        <app-dropdown
                          place-holder="Registration Type"
                          [parent-form-group]="salesUserFormGroup"
                          control-name="registrationType"
                          label="Registration Type"
                          [data]="regsTypes"
                        >
                        </app-dropdown>
                    
                    </form>
                  </div>

                </div>
                <div class="shadow-lg mb-3 rounded">
                  <div class="card-header">
                    <strong>Files</strong>
                  </div>
                  <div class="card-body pt-1 pb-0 mb-0">
                    <div class="list list-row block">

                      <div class="list-item " data-id="20">
                          <div class="pointer" (click)="triggerFile(pop)">
                              <span class="w-40 avatar gd-info">
                                  <i class="fa fa-upload" aria-hidden="true"></i>
                                  <input #pop  type="file" (change)="onUploadFile($event,'pop')" style="display: none;"
                                  accept="image/jpeg,image/png,application/pdf" />
                              </span>
                          </div>
                          <div class="flex">
                              <a  class="item-author text-color"> <span class="text-danger font-weight-bold">*</span> &nbsp; Proof of Payment</a>
                              <div class="item-except text-muted text-sm h-1x" [ngClass]="{ 'is-invalid': submitted && fi.pop.errors }">
                                  <label *ngIf="!salesUserFormGroup.controls.pop.value && !fileFormGroup.controls.pop.value">Click the button to upload File...</label>
                                  <label *ngIf="salesUserFormGroup.controls.pop.value && !fileFormGroup.controls.pop.value" 
                                      (click)="preview(viewImageModal, salesUserFormGroup.controls.pop.value)" 
                                      style="color:blue; cursor: pointer;"
                                      >
                                  <span class="fa fa-eye">&nbsp;</span>View File
                                  </label>
                                  <label *ngIf="fileFormGroup.controls.pop.value" style="color:blue; cursor: pointer;">
                                      <span class="fa fa-tag">&nbsp;</span>Save to preview file
                                  </label>
                              </div>
                              <div *ngIf="submitted && fi.pop.errors" class="invalid-feedback">
                                  <div *ngIf="fi.pop.errors">Proof of Payment is required</div>
                              </div> 
                          </div>
                      </div>
                  
                    </div>
                    
                  </div>

                </div>

            </div>

          </div>

      </div>
    </div>
  </div>

  <!-- ############ Main END-->
</div>

<div id="modal-left" class="modal fade" data-backdrop="true">
  <div class="modal-dialog animate">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title text-md">Updating of User</div>
        <button class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="p-4 text-center">
          <p>Are you sure you want continue?</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
        <button type="button" (click)="actionEventCreateCompanyUser()" class="btn btn-primary" data-dismiss="modal">
          Save Changes
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
</div>

<ng-template #viewImageModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">File Viewer</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" >
    <div>
      <ngx-extended-pdf-viewer
          [base64Src]="imagePreview" *ngIf="fileType=='PDF'"
          backgroundColor="#ffffff"
          height="100%"
          [useBrowserLocale]="true"
          [handTool]="false"
          [showHandToolButton]="true" 
      >
      </ngx-extended-pdf-viewer>
      <img *ngIf="fileType=='IMAGE'"
          [src]="imagePreview"
          onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
          style="width: 100%"
        />
    </div>
  </div>
</ng-template>
