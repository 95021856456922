import { Pipe, PipeTransform } from "@angular/core";


@Pipe({
  name: 'sortdata',
  pure: false
})
export class ArrayNativeSortPipe  implements PipeTransform {
  transform(value: any[], property: string): any[] {
    if (!Array.isArray(value) || value.length <= 1 || !property) {
      return value;
    }

    return value.sort((a, b) => {
      return a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    });
  }
}