<app-loader *ngIf="!(users && roles)"></app-loader>
<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex" *ngIf="users && roles">
  <!-- ############ Main START-->
  <div class="pl-3 pr-3">
    <div id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="--spam">Manager Running Wallet</h2>
          <small class="--desc">JuanPay list of manager wallet</small>
        </div>
        <div class="flex"></div>
      </div>
    </div>
    <div id="page-content">
      <div class="card p-2">
        <!-- <div class="card mb-2 p-1" style="background-color: #182038"> -->
        <!-- Filters -->

        <div class="card mb-2 mt-0" style="box-shadow: 0 2px 4px 0 #182038, 0 2px 5px 0 #182038">
          <div class="row" [formGroup]="listFilterForm">
            <table id="datatable" class="table" role="grid" aria-describedby="datatable_info">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>
                    <button
                      class="btn btn-sm mt-2 btn-info button--search font-weight-bold"
                      style="float: right"
                      (click)="getManagerUsers(null, null, null, true)"
                    >
                      Search &nbsp; <i class="fa fa-search text-highlight"></i>
                    </button>

                    <button
                      class="btn btn-sm mr-2 mt-2 btn-success button--create font-weight-bold"
                      (click)="getManagerUsersForExcel()"
                    >
                      Export Data &nbsp; <i class="fa fa-file-export text-highlight"></i>
                    </button>

                    <!-- <button
                          class="btn btn-sm mr-2 mt-2 btn-primary button--create font-weight-bold"
                          *ngIf="roleId == 1"
                          (click)="createManagerUser(uploadCreateBilling)">
                          Create Manager &nbsp; <i class="fa fa-plus text-highlight"></i>
                      </button> -->
                  </th>
                  <th style="width: 50px"></th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor: pointer" class="odd" role="row">
                  <td class="td-title">
                    <label class="label-filter" style="color: #182038; font-weight: bold"> &nbsp;</label>
                  </td>
                  <td class="td-value">&nbsp;</td>

                  <td class="td-title" style="text-align: end; vertical-align: middle">
                    <label class="label-filter" style="color: #182038; font-weight: bold">Search</label>
                  </td>

                  <td class="td-value" style="width: 50%">
                    <input
                      type="text"
                      (keyup.enter)="getManagerUsers(null, null, null, true)"
                      placeholder="Search..."
                      class="form-control w-100"
                      formControlName="searchString"
                      style="background-color: rgb(255, 255, 255); color: black; height: 36px; border-color: #182038"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card mt-2">
          <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
            <thead>
              <tr>
                <th data-sortable="true" data-field="owner">Avatar</th>
                <th data-sortable="true" data-field="id">
                  <div [ngClass]="managerId" (click)="setPageSorting('managerId')">Account Details</div>
                </th>
                <th data-sortable="true" data-field="owner">
                  <div [ngClass]="username" (click)="setPageSorting('username')">Manager Details</div>
                </th>

                <th data-field="finish" style="text-align: right">
                  <span class="d-none d-sm-block">Current Wallet Balance</span>
                </th>

                <th data-sortable="true" data-field="project" style="text-align: right">
                  <div>Allocated Fund</div>
                </th>

                <th data-field="finish" style="text-align: right">
                  <span class="d-none d-sm-block">Total Running Balance</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let user of users?.data
                    | paginate: { itemsPerPage: 10, currentPage: p, totalItems: users.totalRecords }
                "
                (click)="selectManager(walletModal, user)"
                style="cursor: pointer"
                class="odd"
                role="row"
              >
                <td>
                  <a href="#">
                    <span>
                      <img
                        class="w-32 avatar"
                        *ngIf="user.managerUser?.photo"
                        [src]="buildUserPhoto(user) | authImage | async"
                      />
                      <img
                        class="w-32 avatar"
                        *ngIf="!user.managerUser?.photo"
                        src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file64407579-6a37-49ab-b083-b882c28eea4e.png"
                        alt="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file64407579-6a37-49ab-b083-b882c28eea4e.png"
                      />
                    </span>
                  </a>
                </td>
                <!-- <td>
                  {{ user.managerUser?.managerId }}
                </td> -->

                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    Username : <span style="color: cadetblue; font-weight: bold">{{ user?.username }}</span>
                  </div>
                  <div class="item-except text-sm h-1x">
                    Store Name : <span style="color: cadetblue; font-weight: bold">{{ user?.storeName }}</span>
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    Manager Name : <span style="color: cadetblue; font-weight: bold">{{ user.fullname }}</span>
                  </div>
                  <!-- <div class="item-except text-sm h-1x">
                    Manager ID : <span style="color: cadetblue; font-weight: bold;">{{user?.managerUser?.managerId}}</span>
                  </div>
                  <div class="item-except text-sm h-1x">
                    DTI : <span style="color: cadetblue; font-weight: bold;">{{user?.managerUser?.storeSetting?.name}}</span>
                  </div> -->
                </td>

                <td class="flex" style="text-align: right">
                  <div class="item-except text-sm h-1x">
                    <span style="color: green; font-weight: bold">
                      {{ makeMoney(user.balance) }}
                    </span>
                  </div>
                </td>

                <td class="flex" style="text-align: right">
                  <div class="item-except text-sm h-1x">
                    <span style="color: red; font-weight: bold">{{ makeMoney(user.totalCashierBalance) }}</span>
                  </div>
                </td>

                <td class="flex" style="text-align: right">
                  <div class="item-except text-sm h-1x align-left">
                    <span style="color: green; font-weight: bold">{{ makeMoney(user.totalRunningBalance) }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
              <div class="col-sm-12 col-md-12">
                <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                  Showing {{ (users?.pageNumber - 1) * users?.pageSize + 1 }} to
                  {{ (users?.pageNumber - 1) * users?.pageSize + users?.data?.length }} of
                  {{ users?.data?.length }} entries
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-7" style="float: right">
            <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
              <ul class="pagination" style="float: right">
                <pagination-controls (pageChange)="getManagerUsers(buildPageUrl($event, 10))"></pagination-controls>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<ng-template #walletModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Manager Running Wallet</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="px-4 py-4">
      <!-- <div class="card-header">
          <strong>Manager's Wallet</strong>
        </div> -->
      <!--DEBIT CREDIT-->

      <table id="datatable" class="table table-striped" role="grid" aria-describedby="datatable_info">
        <thead>
          <tr>
            <th class="table-dark no-hover" data-sortable="true" data-field="id">
              <div>Username</div>
            </th>
            <th class="table-dark no-hover" data-sortable="true" data-field="owner">
              <div>Name</div>
            </th>
            <th class="table-dark no-hover" data-sortable="true" data-field="project">
              <div>Wallet Balance</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr style="cursor: pointer" class="odd" role="row">
            <td class="table-info" style="text-align: right" colspan="3">
              <strong>MANAGER WALLET</strong>
            </td>
          </tr>

          <tr style="cursor: pointer" class="odd" role="row">
            <td>
              <strong>{{ selectedManager.username }}</strong>
            </td>
            <td>
              <strong>{{ selectedManager.fullname }}</strong>
            </td>
            <td style="text-align: right">
              <strong>{{ selectedManager.balance | number: "1.2-2" }}</strong>
            </td>
          </tr>

          <tr style="cursor: pointer" class="odd" role="row">
            <td colspan="3" class="table-info" style="text-align: right">
              <strong>CASHIER WALLET</strong>
            </td>
          </tr>

          <tr *ngFor="let cashier of selectedManager?.cashierList" style="cursor: pointer" class="odd" role="row">
            <td>
              <strong>{{ cashier.username }}</strong>
            </td>
            <td>
              <strong>{{ cashier.fullname }}</strong>
            </td>

            <td style="text-align: right">
              <strong>{{ cashier.balance | number: "1.2-2" }}</strong>
            </td>
          </tr>

          <tr style="cursor: pointer" class="odd" role="row">
            <td></td>
            <td style="text-align: center">
              <strong class="">WALLET GRAND TOTAL</strong>
            </td>
            <td style="text-align: right">
              <strong style="color: darkgreen; font-size: larger">{{
                selectedManager?.totalRunningBalance | number: "1.2-2"
              }}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
