import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { InvoiceComponent } from 'src/app/elements/invoice/invoice.component';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { ECPayService } from 'src/data/services/web/ecpay.service';
import { PrintService } from 'src/data/services/web/print.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { TransactionService } from 'src/data/services/web/transaction.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-ecpay-transaction',
  templateUrl: './ecpay-transaction.component.html',
  styleUrls: ['./ecpay-transaction.component.less']
})
export class EcpayTransactionComponent implements OnInit {

  @ViewChild(InvoiceComponent ) child: InvoiceComponent ; 
  @Output("closeModal") closeModal = new EventEmitter();

  @Input() requestId: string;
  public data :  any;
  public loader : boolean = false;
  public receipt: any;
  public isA4 = new FormControl();
  public isTicketActive : any = false;

  public transactionService: TransactionService;
  public dti: any;
  public tin: any;
  public address: any;
  
  constructor(private ecpayService : ECPayService, 
    private router: Router,
    private localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    public datepipe: DatePipe,
    public printService: PrintService,
    private webService: WebSettingsService, 
    transactionService: TransactionService) {
    this.transactionService = transactionService;
    this.ecpayService = ecpayService;
  }
  roleLevel:any;
  ngOnInit(): void {
    this.loader = true;
    this.getTransaction();
    this.initReceipt();
    this.roleLevel = this.localStorageCacheService.getStorage("role_level");
  }

  openTicket(){

    this.isTicketActive = true;
  }


  initReceipt() {
    this.webService.getWebSettingsByIdentifier("INVOICE_RECEIPT").subscribe((result) => {
      this.receipt = result.data;
    });
  }

  requestJson;

  getTransaction() {
    this.ecpayService.getTransaction(this.requestId).subscribe((billers) => {
      debugger
       this.data = billers;
       this.requestJson = JSON.parse(this.data.requestJson);
       this.loader = false;
       
       this.initReceiptDetails(billers.transactByUser.cashierUser.managerUserId);
    }, error => {
      this.loader = false;
    });
    
  }

  initReceiptDetails(managerUserId: any){
    this.transactionService.getReceiptDetails(managerUserId).subscribe((result) => {
      this.dti = result.dti;
      this.tin = result.tin != null ? result.tin : "***-***-***-***";
      this.address = result.address;
    });
  }

  buildUserPhoto(user: any): any {
    if(user){
    return user.photoFormat + "," + user.photo;
    }
  }

  


  printReceipt(){

    var completedDate = this.datepipe.transform(this.data.completedDate, 'MMM-dd-yyyy');
    var transTime = this.datepipe.transform(this.data.completedDate, 'HH:MM:SS a');
    var reqId = this.data.requestId;
    //var recValNo = this.data.responseJsonData.data.meta.ReceiptValidationNo;
    var cashier = this.data.transactByUser.cashierUser.firstName + ' ' + this.data.transactByUser.cashierUser.lastName;
    var billerName = this.data.billerName;
    var refNo = this.data.billerPartnerRefNo;
    var can = this.data.billerAccountNo;
    //var peyeeContact = this.data.billerData.payeeContactNumber;
    var billAmount = (parseFloat(this.data.amount) - parseFloat(this.data.billerFee)).toString(); 
    var amountPaid =  this.data.amount;
    var fee = this.data.billerFee;
    //var transNo = this.data.responseJsonData.data.meta.TransactionNo;
    var AccountNo = this.data.billerAccountNo;
    var billerCode = this.data.billerCategory;

    if(this.isA4.value){
      var Newstr = (this.receipt[1].value).replace("{{Date}}", completedDate);
      Newstr = (Newstr).replace("{{Time}}", transTime);
      Newstr = (Newstr).replace("{{Cashier}}", cashier);

      Newstr = (Newstr).replace("{{RefNo}}", refNo);
      Newstr = (Newstr).replace("{{RecValNo}}", "");
      Newstr = (Newstr).replace("{{TransactionNo}}", "");
      Newstr = (Newstr).replace("{{PayeeContact}}", "");
      
      Newstr = (Newstr).replace("{{AccountNo}}", AccountNo);
      Newstr = (Newstr).replace("{{BillerCode}}", billerCode);
      Newstr = (Newstr).replace("{{BillerName}}", billerName);
      Newstr = (Newstr).replace("{{BillAmount}}", parseFloat(billAmount).toFixed(2));
      Newstr = (Newstr).replace("{{AmountPaid}}", parseFloat(amountPaid).toFixed(2));
      Newstr = Newstr.replace("{{dti}}", this.dti);
      Newstr = Newstr.replace("{{tinNo}}", this.tin);
      Newstr = Newstr.replace("{{address}}", this.address);
 
      
      this.PrintReceipt(Newstr);
      } else {
        var Newstr = (this.receipt[0].value).replace("{{Date}}", completedDate);
        Newstr = (Newstr).replace("{{Time}}", transTime);
        Newstr = (Newstr).replace("{{RecID}}", reqId);
        Newstr = (Newstr).replace("{{RecValNo}}", "");
        Newstr = (Newstr).replace("{{ReferenceName}}", "Rec Val No.");
        Newstr = (Newstr).replace("{{ServiceRef}}", "");
        Newstr = (Newstr).replace("{{Cashier}}", cashier);
        Newstr = (Newstr).replace("{{BillerName}}", billerName);
        Newstr = (Newstr).replace("{{RefNo}}", refNo);
        Newstr = (Newstr).replace("{{CAN}}", can);
        Newstr = (Newstr).replace("{{PayeeContact}}", "");
        Newstr = (Newstr).replace("{{BillAmount}}", parseFloat(billAmount).toFixed(2));
        Newstr = (Newstr).replace("{{billerFee}}", fee);
        Newstr = (Newstr).replace("{{AmountPaid}}", parseFloat(amountPaid).toFixed(2));
        Newstr = Newstr.replace("{{dti}}", this.dti);
        Newstr = Newstr.replace("{{tinNo}}", this.tin);
        Newstr = Newstr.replace("{{address}}", this.address);
 
     this.PrintReceipt(Newstr);
   }
  }

  PrintReceipt(Newstr: any)
  {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write(Newstr);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    return true;
  }

  completeTransaction(){
    this.loader = true;
    this.ecpayService.completeTransactionPay(this.requestId).subscribe((billers) => {
      PNotify.success({
        title: "Information",
        text: "Transaction successfully completed.",
      });
      this.loader = false;
      this.closeModal.emit();
   }, error => {
    PNotify.error({
      title: "Failed",
      text: "There's a problem occured. Please contact JuanPay support.",
    });
     this.loader = false;
   });

  }

  rejectTransaction(){
    this.loader = true;
    this.ecpayService.rejectTransactionPay(this.requestId).subscribe((billers) => {
      PNotify.success({
        title: "Completed Status",
        text: "Transaction successfully rejected.",
      });
      this.loader = false;
      this.closeModal.emit();
   }, error => {
    PNotify.error({
      title: "Failed",
      text: "There's a problem occured. Please contact JuanPay support.",
    });
     this.loader = false;
   });
  
  }
  

  reProcessPayment(){
    this.loader = true;
   
   // return;
    this.ecpayService.reProcessPaymentPay(
      this.data.requestJson,
      this.data.billerName,
      this.data.billerCategory,
      this.requestId)
      .subscribe((biller) => {
        PNotify.success({
          title: "Payment Success",
          text: biller.reason,
        });
        this.loader = false;
        this.closeModal.emit();
      
    }, error => {

      if(error.error.status == 490){
        PNotify.error({
          title: "Payment Failed",
          text: error.error.reason,
        });
        this.loader = false;

      }else{
        PNotify.error({
          title: "Payment Failed",
          text: error.error.reason,
        });
        this.loader = false;
        this.closeModal.emit();

      }

    });
}

}
