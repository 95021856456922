

import { FormGroup } from "@angular/forms";
import { animate, style, transition, trigger } from "@angular/animations";
import { Component, Input, OnInit } from "@angular/core";
import { Meta } from "src/data/models/meta.model";

@Component({
  selector: "app-yearpicker",
  templateUrl: "./yearpicker.component.html",
  styleUrls: ["./yearpicker.component.less"],
  animations: [
    trigger("inOutAnimation", [transition(":leave", [animate("0.2s ease-out", style({ height: 0, opacity: 0 }))])]),
  ],
})
export class YearpickerComponent implements OnInit {
  @Input("parent-form-group") parentFormGroup: FormGroup;
  @Input("control-name") controlName: string;
  @Input() id: string;
  @Input() value = "";
  @Input() label: string;
  @Input() maxlength = "100";
  @Input("place-holder") placeholder: string;
data: any;
  @Input("error") error: boolean;
  @Input("error-message") errorMessage: string;
  toggle = false;
  constructor() {}

  onClickEventOutSide(val): void {
    this.toggle = val;
  }

  async ngOnInit() {
    
    this.data = [
      { value : "2015-01-01", key : "2015" },
      { value : "2016-01-01", key : "2016" },
      { value : "2017-01-01", key : "2017" },
      { value : "2018-01-01", key : "2018" },
      { value :  "2019-01-01", key : "2019" },
      { value :"2020-01-01", key : "2020" },
      { value : "2021-01-01", key : "2021" },
      { value : "2022-01-01", key : "2022" },
      { value :"2023-01-01", key : "2023" },
      { value :  "2024-01-01", key : "2024" },
      { value : "2025-01-01", key : "2025" },
      { value :  "2026-01-01", key : "2026" }
      
     ]

    if (this.parentFormGroup.controls[this.controlName].value) {
      const value: number = this.parentFormGroup.controls[this.controlName].value;
      this.value = this.data.filter((x) => x.value === value)[0].key;
    }
  }

  onClick(action): void {
    setTimeout(() => {
      this.toggle = action === "close" ? false : true;
    }, 200);
  }

  selectedOption(option): void {
    this.value = option.key;
    this.toggle = false;
    this.parentFormGroup.controls[this.controlName].setValue(option.value);
  }
}
