import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { InvoiceComponent } from 'src/app/elements/invoice/invoice.component';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { PrintService } from 'src/data/services/web/print.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { TransactionService } from 'src/data/services/web/transaction.service';
import { BayadService } from 'src/data/services/web/bayad.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-bayad-transaction',
  templateUrl: './bayad-transaction.component.html',
  styleUrls: ['./bayad-transaction.component.less']
})
export class BayadTransactionComponent implements OnInit {

  @ViewChild(InvoiceComponent ) child: InvoiceComponent ; 
  @ViewChild('vipLogo') vipLogo: ElementRef;
  
  @Input() requestId: string;
  public data :  any;
  public loader : boolean = false;
  public receipt: any;
  public isA4 = new FormControl();
  roleLevel:any;
  public isTicketActive : any = false;

  public transactionService: TransactionService;
  public dti: any;
  public tin: any;
  public address: any;
  
  constructor(private bayadService : BayadService, 
    private router: Router,
    private localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    public datepipe: DatePipe,
    private modalServe: NgbModal,
    public printService: PrintService,
    private webService: WebSettingsService, 
    transactionService: TransactionService) {
    this.transactionService = transactionService;
    this.bayadService = bayadService;
    PNotifyButtons;
  }

  
  @Output("closeModal") closeModal = new EventEmitter();


  ngOnInit(): void {
    this.loader = true;
    this.getTransaction();
    this.initReceipt();
    this.roleLevel = this.localStorageCacheService.getStorage("role_level");
  }

  openTicket(){

    this.isTicketActive = true;
  }

  initReceipt() {
    this.webService.getWebSettingsByIdentifier("INVOICE_RECEIPT").subscribe((result) => {
      this.receipt = result.data;
    });
  }

  getTransaction() {
    this.bayadService.getTransaction(this.requestId).subscribe((billers) => {
      debugger
      billers.requestJson = JSON.parse(billers.requestJson);
      billers.responseJson = JSON.parse(billers.responseJson);
       this.data = billers;
       
       this.loader = false;
       this.initReceiptDetails(billers.transactByUser.cashierUser.managerUserId);
    }, error => {
      this.loader = false;
    });
  }

  initReceiptDetails(managerUserId: any){
    this.transactionService.getReceiptDetails(managerUserId).subscribe((result) => {
      this.dti = result.dti;
      this.tin = result.tin != null ? result.tin : "***-***-***-***";
      this.address = result.address;
    });
  }

  updateStatusTransaction(){
    this.loader = true;
    this.bayadService.updateStatusTransaction(this.requestId).subscribe((billers) => {
      if(billers?.statusId == 1){
        PNotify.success({
          title: "Wallet Refunded to Outlet.",
          text: "Transaction status reponse from BAYAD was FAILED.",
        });
      }else if(billers?.statusId == 2){
        PNotify.success({
          title: "Transaction Successfully Completed.",
          text: "Transaction status reponse from BAYAD was PAYMENT POSTED.",
        });
      }else{
        PNotify.success({
          title: "Transaction Still Pending.",
          text: "Try again in a few moment or contact BAYAD to follow up the transaction.",
        });
      }
     
      billers.requestJson = JSON.parse(billers.requestJson);
      billers.responseJson = JSON.parse(billers.responseJson);
      this.data = billers;
       
      this.loader = false;
      this.initReceiptDetails(billers.transactByUser.cashierUser.managerUserId);
     
      
   }, error => {
    PNotify.error({
      title: "Failed",
      text: "There's a problem occured. Please contant ACM support.",
    });
     this.loader = false;
   });
  }

  completeTransaction(){
    this.loader = true;
    this.bayadService.completeTransaction(this.requestId).subscribe((billers) => {
      PNotify.success({
        title: "Information",
        text: "Transaction successfully completed.",
      });
      this.loader = false;
      this.verifyModal.close();
      this.closeModal.emit();
   }, error => {
    PNotify.error({
      title: "Failed",
      text: "There's a problem occured. Please contant JuanPay support.",
    });
     this.loader = false;
   });
  }

  rejectTransaction(){
    this.loader = true;
    this.bayadService.rejectTransaction(this.requestId).subscribe((billers) => {
      PNotify.success({
        title: "Failed Status",
        text: "Transaction successfully rejected.",
      });
      this.loader = false;
      this.verifyModal.close();
      this.closeModal.emit();
   }, error => {
    PNotify.error({
      title: "Failed",
      text: "There's a problem occured. Please contant ACM support.",
    });
     this.loader = false;
   });
  }

  reProcessPayment(){
    this.loader = true;
    this.bayadService.reProcessPayment(
      this.data.requestJsonData,
      this.data.billerName,
      this.data.billerCategory,
      this.requestId)
      .subscribe((biller) => {
        PNotify.success({
          title: "Payment Success",
          text: biller.reason,
        });
        this.loader = false;
        this.closeModal.emit();
      
    }, error => {

      if(error.error.status == 490){
        PNotify.error({
          title: "Payment Failed",
          text: error.error.reason,
        });
        this.loader = false;

      }else{
        PNotify.error({
          title: "Payment Failed",
          text: error.error.reason,
        });
        this.loader = false;
        this.closeModal.emit();

      }
    });
  }

  buildUserPhoto(user: any): any {
    if(user){
    return user.photoFormat + "," + user.photo;
    }
  }

  verifyModal: NgbModalRef;
  confirmModal(content){
    this.verifyModal = this.modalServe.open(content, { centered: true, size: "md" });
  }

  transformParamValue(param: any,requestJson : any){
    if(param.type == 'Calendar'){
      let stringDate =this.datepipe.transform(requestJson[param.field], param.date_format.replaceAll("D","d").replaceAll("Y","y"));
      return stringDate;
    }
    else if(param.type == 'Dropdown'){
      return (param.options.filter(x=>x.key ==requestJson[param.field] )[0]).value;
    }
    else{
      return requestJson[param.field];
    }
  }

  printReceipt(){
    debugger
    var completedDate = this.data.completedDate ? this.datepipe.transform(this.data.completedDate, 'MMM-dd-yyyy') : this.datepipe.transform(this.data.createdDate, 'MMM-dd-yyyy');
    var transTime = this.data.completedDate ? this.datepipe.transform(this.data.completedDate, 'hh:mm:ss a') : this.datepipe.transform(this.data.createdDate, 'hh:mm:ss a');
    var reqId = this.data.requestId;
    var recValNo = this.data?.responseJson?.data ? this.data?.responseJson?.data?.transactionId : this.data?.responseJson?.transactionId ? this.data?.responseJson?.transactionId : "--------";
    var cashier = this.data.transactByUser.cashierUser.firstName + ' ' + this.data.transactByUser.cashierUser.lastName;
    var billerName = this.data.billerName;
    var refNo = this.data?.billerPartnerRefNo ? this.data?.billerPartnerRefNo : "--------";
    var can = this.data.billerAccountNo;
    var peyeeContact = this.data.payeeContactNumber ? this.data.payeeContactNumber : null;
    var billAmount = (parseFloat(this.data.amount) - parseFloat(this.data.billerFee)).toString(); 
    var amountPaid =  this.data.amount;
    var fee = this.data?.billerData?.fee ? this.data?.billerData?.fee : 0.00;
    var transNo = this.data.responseJson ? this.data.responseJson.transactionId : null;
    var AccountNo = this.data.billerAccountNo;
    var billerCode = this.data?.billerData?.code ? this.data?.billerData?.code : "";
    var vipLogo = this.vipLogo.nativeElement.innerHTML;

    if(this.isA4.value){
      // if(this.data[i].statusId == 2){

      // }
      var Newstr = (this.receipt[1].value).replace("{{Date}}", completedDate);
      Newstr = (Newstr).replace("{{Time}}", transTime);
      Newstr = (Newstr).replace("{{Cashier}}", cashier);

      Newstr = (Newstr).replace("{{RefNo}}", refNo);
      Newstr = (Newstr).replace("{{RecValNo}}", recValNo);
      Newstr = (Newstr).replace("{{TransactionNo}}", transNo);
      Newstr = (Newstr).replace("{{PayeeContact}}", peyeeContact);
      
      Newstr = (Newstr).replace("{{AccountNo}}", AccountNo);
      Newstr = (Newstr).replace("{{BillerCode}}", billerCode);
      Newstr = (Newstr).replace("{{BillerName}}", billerName);
      Newstr = (Newstr).replace("{{BillAmount}}", parseFloat(billAmount).toFixed(2));
      Newstr = (Newstr).replace("{{AmountPaid}}", parseFloat(amountPaid).toFixed(2));
      Newstr = Newstr.replace("{{receiptLogo}}", vipLogo);
      Newstr = Newstr.replace("{{dti}}", this.dti);
        Newstr = Newstr.replace("{{tinNo}}", this.tin);
      Newstr = Newstr.replace("{{address}}", this.address);
      
      this.PrintReceipt(Newstr);
      } else {
  
        var Newstr = (this.receipt[0].value).replace("{{Date}}", completedDate);
        Newstr = (Newstr).replace("{{Time}}", transTime);
        Newstr = (Newstr).replace("{{RecID}}", reqId);
        Newstr = (Newstr).replace("{{ReferenceName}}", "Rec Val No.");
        Newstr = (Newstr).replace("{{ServiceRef}}", recValNo);
        Newstr = (Newstr).replace("{{Cashier}}", cashier);
        Newstr = (Newstr).replace("{{BillerName}}", billerName);
        Newstr = (Newstr).replace("{{RefNo}}", refNo);
        Newstr = (Newstr).replace("{{CAN}}", can);
        Newstr = (Newstr).replace("{{PayeeContact}}", peyeeContact);
        Newstr = (Newstr).replace("{{BillAmount}}", parseFloat(billAmount).toFixed(2));
        Newstr = (Newstr).replace("{{billerFee}}", parseFloat(fee).toFixed(2));
        Newstr = (Newstr).replace("{{AmountPaid}}", parseFloat(amountPaid).toFixed(2));
        Newstr = Newstr.replace("{{receiptLogo}}", vipLogo);
        Newstr = Newstr.replace("{{dti}}", this.dti);
        Newstr = Newstr.replace("{{tinNo}}", this.tin);
        Newstr = Newstr.replace("{{address}}", this.address);
 
     this.PrintReceipt(Newstr);
   }
  }

  PrintReceipt(Newstr: any)
  {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write(Newstr);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    return true;
  }
}
