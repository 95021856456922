import { CurrencyPipe, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { InvoiceComponent } from 'src/app/elements/invoice/invoice.component';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { PrintService } from 'src/data/services/web/print.service';
import { VipProtectService } from 'src/data/services/web/vip-protect.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { SunlifeGrepaService } from 'src/data/services/web/sunlife-grepa.service';


import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { CryptoService } from 'src/data/crypto/crypto.service';
import { Data } from 'src/data/data';
import { environment } from 'src/environments/environment';
import { TransactionService } from 'src/data/services/web/transaction.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5";


@Component({
  selector: 'app-sunlife-grepa-transaction',
  templateUrl: './sunlife-grepa-transaction.component.html',
  styleUrls: ['./sunlife-grepa-transaction.component.less']
})
export class SunlifeGrepaTransactionComponent implements OnInit {

  @ViewChild(InvoiceComponent ) child: InvoiceComponent ; 
  @ViewChild("termsAndConditionQR") termsAndConditionQR: ElementRef;
  @ViewChild('vipLogo') vipLogo: ElementRef;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

  @Input() requestId: string;
  public data :  any;
  public loader : boolean = false;
  fileUploadFormGroup : FormGroup;
  emptyString: any = '';
  public base64Photo: string | ArrayBuffer;
  roleLevelId : any;
  public receipt: any;
  public isA4 = new FormControl();
  private baseUrl = environment.ROOT_URL;

  public transactionService: TransactionService;
  public dti: any;
  public tin: any;
  public address: any;

  constructor(private sunlifeGrepaService : SunlifeGrepaService, 
    private router: Router,
    private localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    public printService: PrintService,
    private changeDetectorRef: ChangeDetectorRef,
    private modalServe: NgbModal,
    public datepipe: DatePipe,
    private webService: WebSettingsService,
    private cryptoService: CryptoService,
    private datakey: Data, 
    transactionService: TransactionService) {
    this.transactionService = transactionService;
      PNotifyButtons;
  }


  ngOnInit(): void {
    this.loader = true;
    this.initFileUploadFormGroup();
    this.getTransaction();
    this.initReceipt();
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
  }

  
  initReceipt() {
    this.webService.getWebSettingsByIdentifier("INVOICE_RECEIPT").subscribe((result) => {
      this.receipt = result.data;
    });
  }

  productDetail : any;
  qrCodeUrl : any;

  initSunLifeGrepaProduct(productCode) {
    this.webService.getWebSettingsByIdentifier("SUNLIFE_GREPA_PRODUCTS").subscribe((result) => {
      this.productDetail = result.data.filter(x=>x.parameter == productCode)[0]; 
      this.qrCodeUrl = this.baseUrl + this.productDetail.value.urlCode + '/' + encodeURIComponent(this.cryptoService.set(this.datakey.authentication.privateKey, this.requestId));
   console.log(this.qrCodeUrl);
    });
  }


  initFileUploadFormGroup(): void {
    this.fileUploadFormGroup = new FormGroup({
      photoFile: new FormControl(this.emptyString),
      purpose: new FormControl(this.emptyString),
      meta: new FormControl(this.emptyString)
    });
  }

  getTransaction() {
    this.sunlifeGrepaService.getTransaction(this.requestId).subscribe((sunlifeGrepa) => {
       this.data = sunlifeGrepa;
     this.initSunLifeGrepaProduct(this.data.productCode);
     
     this.initReceiptDetails(sunlifeGrepa.transactByUser.cashierUser.managerUserId);
     console.log(this.data);
    this.fileUploadFormGroup.patchValue({
      photoFile: this.data.photoFormat + ','+ this.data.photo,
    });
       this.loader = false;
    }, error => {
      this.loader = false;
    });
  }

  initReceiptDetails(managerUserId: any){
    this.transactionService.getReceiptDetails(managerUserId).subscribe((result) => {
      this.dti = result.dti;
      this.tin = result.tin != null ? result.tin : "***-***-***-***";
      this.address = result.address;
    });
  }

  buildUserPhoto(user: any): any {
    if(user){
    return user.photoFormat + "," + user.photo;
    }
  }

  public fileName: any = "Click here..";

    public onPhotoChange(event: any) {
      this.loader = true;
      const reader = new FileReader();
      
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);
        this.fileName = file.name;
        reader.onload = () => {

          this.base64Photo = reader.result;

          if(this.calculateImageSize(this.base64Photo)){
            this.uploadImage(this.base64Photo);
  
            this.fileUploadFormGroup.patchValue({
              photoFile: reader.result,
            });
    
            this.changeDetectorRef.markForCheck();

          }else{
            this.loader = false;
            PNotify.error({
              title: "Migration Form",
              text: "File is too big. Must be less than or equal to 2mb.",
            });
          }

        
        };
      }
    }

    uploadImage(photo) {
      this.sunlifeGrepaService.uploadImage(photo,this.data.id).subscribe((sunlifeGrepa) => {
         this.loader = false;
      }, error => {
        this.loader = false;
      });
    }



    
  
    calculateImageSize(base64String) : boolean {
      let padding;
      let inBytes;
      let base64StringLength;
      if (base64String.endsWith('==')) { padding = 2; }
      else if (base64String.endsWith('=')) { padding = 1; }
      else { padding = 0; }
  
      base64StringLength = base64String.length;
      console.log(base64StringLength);
      inBytes = (base64StringLength / 4) * 3 - padding;
      return inBytes < 2000000;
    }
  
   


    public uploadModal = null;
    preview(content: any){

      this.uploadModal = this.modalServe.open(content, { centered: true, size: "lg" });
    }


    public padIntegerLeftWithZeros(rawInteger: number, numberOfDigits: number): any {
      let paddedInteger: string = rawInteger + '';
      while (paddedInteger.length < numberOfDigits) {
        paddedInteger = '0' + paddedInteger;
      }
      return paddedInteger;
    }
  
    

    printReceipt(){
      
      var vipLogo = this.vipLogo.nativeElement.innerHTML;
      const termsAndConditionQR = this.termsAndConditionQR.nativeElement.innerHTML;

      var createdDate = this.datepipe.transform(this.data.completedDate, 'MMM-dd-yyyy');
      var transTime = this.datepipe.transform(this.data.completedDate, 'hh:mm:ss a');
      var reqId = this.data.requestId;
      var cashier = this.data.transactByUser.cashierUser.firstName + ' ' + this.data.transactByUser.cashierUser.lastName;
      var billerName = 'Sunlife Grepa';
   
      var amountPaid =  this.data.amountToBePaidByCustomer;
   
      var groupPolicyNumber = this.data.groupPolicyNumber;
      var plan = this.productDetail.value.plan;
      var CustomerName = this.data.customerName;
      var BirthDate = this.datepipe.transform(this.data.birthDate, 'MMM-dd-yyyy');;
      var Beneficiary = this.data.beneficiary;
      var Relationship = this.data.relationship;
      var ContactNumber = this.data.contactNumber;
      var Residence = this.data.residence;
      
      debugger
      if(this.isA4.value){
        var Newstr = ((this.receipt.filter(x=>x.parameter == 'IN58mm_SunlifeGrepa')[0]).value).replace("{{Date}}", createdDate);
        Newstr = (Newstr).replace("{{Time}}", transTime);
        Newstr = (Newstr).replace("{{Cashier}}", cashier);
  
        Newstr = (Newstr).replace("{{RecID}}", reqId);
        
        Newstr = (Newstr).replace("{{ProductName}}", billerName);
        Newstr = (Newstr).replace("{{ProductCategory}}", plan);
        Newstr = (Newstr).replace("{{GroupPolicyNumber}}", groupPolicyNumber);
        Newstr = (Newstr).replace("{{CustomerName}}", CustomerName);
        Newstr = (Newstr).replace("{{BirthDate}}", BirthDate);
        Newstr = (Newstr).replace("{{Beneficiary}}", Beneficiary);
        Newstr = (Newstr).replace("{{Relationship}}", Relationship);
        Newstr = (Newstr).replace("{{ContactNumber}}", ContactNumber);
        Newstr = (Newstr).replace("{{Residence}}", Residence);
        Newstr = (Newstr).replace("{{BillAmount}}", parseFloat(amountPaid).toFixed(2));
        Newstr = (Newstr).replace("{{AmountPaid}}", parseFloat(amountPaid).toFixed(2));
        Newstr = Newstr.replace("{{receiptLogo}}", vipLogo);
        Newstr = Newstr.replace("{{TermsAndCondtionQR}}", termsAndConditionQR);
        Newstr = Newstr.replace("{{dti}}", this.dti);
        Newstr = Newstr.replace("{{tinNo}}", this.tin);
        Newstr = Newstr.replace("{{address}}", this.address);
        
        this.PrintReceipt(Newstr);
        } else {
    
          var Newstr = ((this.receipt.filter(x=>x.parameter == 'IN58mm_SunlifeGrepa')[0]).value).replace("{{Date}}", createdDate);
        Newstr = (Newstr).replace("{{Time}}", transTime);
        Newstr = (Newstr).replace("{{Cashier}}", cashier);
  
        Newstr = (Newstr).replace("{{RecID}}", reqId);
        
        Newstr = (Newstr).replace("{{ProductName}}", billerName);
        Newstr = (Newstr).replace("{{ProductCategory}}", plan);
        Newstr = (Newstr).replace("{{GroupPolicyNumber}}", groupPolicyNumber);
        Newstr = (Newstr).replace("{{CustomerName}}", CustomerName);
        Newstr = (Newstr).replace("{{BirthDate}}", BirthDate);
        Newstr = (Newstr).replace("{{Beneficiary}}", Beneficiary);
        Newstr = (Newstr).replace("{{Relationship}}", Relationship);
        Newstr = (Newstr).replace("{{ContactNumber}}", ContactNumber);
        Newstr = (Newstr).replace("{{Residence}}", Residence);
        Newstr = (Newstr).replace("{{BillAmount}}", parseFloat(amountPaid).toFixed(2));
        Newstr = (Newstr).replace("{{AmountPaid}}", parseFloat(amountPaid).toFixed(2));
        Newstr = Newstr.replace("{{receiptLogo}}", vipLogo);
        Newstr = Newstr.replace("{{TermsAndCondtionQR}}", termsAndConditionQR);
        Newstr = Newstr.replace("{{dti}}", this.dti);
        Newstr = Newstr.replace("{{tinNo}}", this.tin);
        Newstr = Newstr.replace("{{address}}", this.address);
   
       this.PrintReceipt(Newstr);
     }
    }
  
    PrintReceipt(Newstr: any)
    {
      var mywindow = window.open('', 'PRINT', 'height=400,width=600');
      mywindow.document.write(Newstr);
  
      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
      mywindow.close();
  
      return true;
    }
}
