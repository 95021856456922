import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";

@Component({
  selector: "app-datepicker",
  templateUrl: "./datepicker.component.html",
  styleUrls: ["./datepicker.component.less"],
})
export class DatepickerComponent implements OnInit {
  dpConfig: Partial<BsDatepickerConfig>;

  @Input("parent-form-group") parentFormGroup: FormGroup;
  @Input("control-name") controlName: string;
  @Input("label") label: string;
  @Input("placeholder") placeholder: string;
  @Input("upper-case") upperCase: boolean;
  @Input("no-special-char") noSpecialChar: boolean;
  @Input("max-length") maxLength: number;
  @Input("tab-index-start") tabIndexStart: number;
  @Input("date-format") dateFormat: string;

  @Input("error") error: boolean;
  @Input("error-message") errorMessage: string;

  constructor() {}

  ngOnInit(): void {
    this.dpConfig = new BsDatepickerConfig( );
    this.dpConfig.containerClass = "theme-dark-blue";
    this.dpConfig.dateInputFormat = this.dateFormat ?? "YYYY-MM-DD";
    this.dpConfig.isAnimated = true;
  } 
}
