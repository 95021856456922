import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select2OptionData } from 'ng-select2';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CryptoService } from 'src/data/crypto/crypto.service';
import { Data } from 'src/data/data';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { AuthService } from 'src/data/services/authentication/auth.service';
import { RolesService } from 'src/data/services/web/roles.service';
import { SalesUserService } from 'src/data/services/web/sales-user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pairing',
  templateUrl: './pairing.component.html',
  styleUrls: ['./pairing.component.less']
})
export class PairingComponent implements OnInit {

  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public salesUserService: SalesUserService;
  public emptyString = "";
  public roles: any;
  public role: any;
  public statuses: any;
  public status: any;
  public modalService: BsModalService;

  public accessToken: string;
  public newCashierUser: boolean;
  public users: any;
  public pageCount: any;

  public listFilterForm = new FormGroup({
    searchString: new FormControl(""),
    searchStringField: new FormControl(""),
  });
  
  public filterByOptions: Array<Select2OptionData>;
  public filterBy: any;
  public routeRole : any;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    salesUserService: SalesUserService,
    modalService: BsModalService,
    private modService: NgbModal
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.salesUserService = salesUserService;
    this.modalService = modalService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
  }
 roleLevel : any;
  ngOnInit(): void {
    this.roleLevel = this.localStorageCacheService.getStorage("role_level");
    this.routeRole = this.localStorageCacheService.getStorage("role_level") == "2" ? "Manager" : "Admin" ;
    this.getPairingDetails();
 }

 getPairingDetails(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
  if (fromSearch) {
    this.createdDate = this.emptyString;
  }

  // const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
  // const queryString_order: string = _sort ? `sort=${_sort}&` : "";
  // const queryString_searchString: string = this.listFilterForm.get("searchString").value
  //   ? `searchString=${this.listFilterForm.get("searchString").value}&`
  //   : "";
  const queryString_filterBy: string = this.filterBy ? `filterBy=${this.filterBy}` : "";
  const queryString = queryString_filterBy;
  // const queryString = queryString_orderby + queryString_order + queryString_searchString + queryString_filterBy;
  let endPointUrl: string;
  if (url) {
    endPointUrl = `${url}&${queryString}`;
  } else {
    if (queryString) {
      endPointUrl = `${environment.API_URL}/api/VIPCOTransactions/PairingRewardsList?${queryString}`;
    } else {
      endPointUrl = `${environment.API_URL}/api/VIPCOTransactions/PairingRewardsList${queryString}`;
    }
  }

  this.salesUserService.getSalesAgentUsers(endPointUrl).subscribe((result) => {
    if (result.data.length == 0 && result.pageNumber != 1) {
      this.getPairingDetails(result.previousPage, _sortBy, _sort);
    } else {
      this.users = result;
    }
  });

  // this.users = [
  //   { data1: "data 1", data2: "data 1", data3: "data 1", data4: "data 1", status: false },
  //   { data1: "data 2", data2: "data 2", data3: "data 2", data4: "data 2", status: false },
  //   { data1: "data 3", data2: "data 3", data3: "data 3", data4: "data 3", status: true },
  //   { data1: "data 4", data2: "data 4", data3: "data 4", data4: "data 4", status: false }
  // ];
}

private _sortBy: any;
private _sort: any;

createdDate = "";
searchString = "";

setPageSorting(field: any, page: any = null) {
  let sortBy: any;
  let sort: any;

  if (this._sort == field) {
    sort = field;
    sortBy = this._sortBy == "desc" ? "asc" : "desc";
  } else {
    sort = field;
    sortBy = "asc";
  }
  this._sort = sort;
  this._sortBy = sortBy;

  this.createdDate = field == "createdDate" ? "th-inner sortable both " + sortBy : "";

  this.getPairingDetails(
    `${environment.API_URL}/api/SalesUsers/SalesAgentUsers?pageNumber=${page ? page : this.users.pageNumber}`,
    this._sortBy,
    this._sort
  );
}

getPageNumberClass(pageNumber: any) {
  if (pageNumber == this.users?.pageNumber) {
    return "paginate_button page-item active";
  } else {
    return "paginate_button page-item";
  }
}

p:Number =1;
buildPageUrl(pageNumber: any, pageSize: any): any {
  this.p = pageNumber;
  return `${environment.API_URL}/api/SalesUsers/SalesAgentUsers?pageNumber=${pageNumber}&pageSize=${pageSize}`;
}

totalPages(totalPages: any) {
  return Array(totalPages)
    .fill(1)
    .map((x, i) => i);
}

buildUserPhoto(user: any): any {
  return user.salesUser.photoFormat + "," + user.salesUser.photo;
}

}
