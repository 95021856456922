<div class="pl-3 pr-3 pt-1" style="background-color: rgb(247, 247, 247);">
    <div class="card card-timeline px-2 border-none">
        <ul class="bs4-order-tracking">
          <li class="step" [ngClass]="{'active': purchaseOrder.status >= 2}" [tooltip]="purchaseOrder.placedDate | date: 'MMMM dd, yyyy hh:mm:ss a'">
            <div >
              <i class="fas fa-user"></i>
            </div> Order Placed
          </li>
          <li class="step" [ngClass]="{'active': purchaseOrder.status >= 3}" [tooltip]="purchaseOrder.pickDate | date: 'MMMM dd, yyyy hh:mm:ss a'">
            <div >
              <i class="fas fa-bread-slice"></i>
            </div> In transit
          </li>
          <li class="step" [ngClass]="{'active': purchaseOrder.status >= 4}" [tooltip]="purchaseOrder.drDate | date: 'MMMM dd, yyyy hh:mm:ss a'">
            <div >
              <i class="fas fa-truck"></i>
            </div> Out for delivery
          </li>
          <li class="step" [ngClass]="{'active': purchaseOrder.status >= 5}" [tooltip]="purchaseOrder.receivedDate | date: 'MMMM dd, yyyy hh:mm:ss a'">
            <div>
              <i class="fas fa-birthday-cake"></i>
            </div> Delivered
          </li>
        </ul>
        <!-- <h5 class="text-center">
          <b>In transit</b>. The order has been shipped!
        </h5> -->
    </div>
    
    <div>
        <div class="card p-2 container-card">
            <div class="d-flex" >
                <div class="div-item ml-4 mr-4">
                    <h6 class="mb-0 pb-0">Image</h6>
                </div>
                <div class="div-item ml-3">
                    <div>
                        <h6 class="mb-0 pb-0">Item Description</h6>
                    </div>
                </div>
                <div class="flex"></div>

                <div class="div-item mr-5">
                    <div class="quantity">
                        <h6 class="mb-0 pb-0">Quantity</h6>
                    </div>
                </div>

                <div class="div-item ml-4 mr-3">
                    <h6 class="mb-0 pb-0">Amount</h6>
                </div>

            </div>
        </div>
    </div>

    <div>
        <div class="card p-2 container-card" *ngFor="let item of purchaseOrder.purchaseOrderItems">
            <div class="d-flex">
                <div>
                    <img class="item-img-s" [src]="item.storeItem.storeItemImages[0].path">
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <div>
                        <h5 class="p-0 m-0">{{ item.storeItem.name }}</h5>
                        <p class="text-muted description m-0 p-0">{{ item.storeItem.description }}</p>
                        <!-- <p class="text-muted">Quantity: <span class="color-blue font-weight-bolder">{{ item.orderQuantity }}</span></p> -->
                    </div>
                </div>
                <div class="flex"></div>
                <div class="d-flex justify-content-center align-items-center mr-5">
                    <p class="color-blue font-weight-bolder m-0 p-0">{{ item.orderQuantity }}</p>
                  </div>
                <div class="d-flex justify-content-center align-items-center ml-5 mr-2">
                    <span class="color-red font-weight-bolder">₱ {{ makeMoney(item.storeItem.price) }}</span>
                </div>
            </div>
        </div>

    </div>

    <div class="card p-2 mt-0 mb-2" *ngIf="purchaseOrder.status == 3 || purchaseOrder.status == 4 || purchaseOrder.status == 5 || purchaseOrder.status == 7">
        <div class="mt-0">
            <div class="d-flex">
                <div>
                    <h5 class="text-muted font-weight-bolder p-0 m-0">Proof of Delivery</h5>
                </div>
            </div>
            <div class="p-2">
                <div class="pt-1 pb-2 mr-2 d-flex align-items-center" style="flex-wrap: nowrap;" [style.width.px]="imageWidth * images.length + (images.length * 5)">
                    <div *ngFor="let image of images" class="mr-2">
                        <img class="item-img" [src]="image.path" [style.width.px]="imageWidth" (click)="showImage(displayPoDTemplate, image.path)">
                    </div>
                </div>
            </div>
        </div>
    </div>

    
    <div class="row">
        <div class="col-6 pr-0">
            <div class="card card-container p-2">
                <h5 class="text-muted font-weight-bolder">Delivery Address</h5>
                <h6 class="mb-0 font-weight-bolder">{{ purchaseOrder.name }}</h6>
                <p class="mb-0">{{ purchaseOrder.contact }}</p>
                <p class="text-muted">{{ purchaseOrder.address }}</p>
            </div>
        </div>
        <div class="col-6">
            <div class="card card-container p-2">
                <h5 class="text-muted font-weight-bolder">Total Summary</h5>
                <div class="d-flex align-items-center">
                    <div class=" font-weight-bold">Shipping Fee</div>
                    <div class="flex">

                    </div>
                    <span>
                        <span class="text-muted font-weight-bold color-red">(Not Included)</span>
                    </span>
                </div>
                <div class="d-flex align-items-center">
                    <div class=" font-weight-bold">Note</div>
                    <div class="flex">

                    </div>
                    <span>
                        <span class="text-muted">Shipping fee would be based on your location</span>
                    </span>
                </div>
                <hr class="mt-0">
                <div class="d-flex align-items-center">
                    <div class=" font-weight-bold">Total Payment</div>
                    <div class="flex">

                    </div>
                    <span class="font-weight-bold">
                        ₱ {{ makeMoney(purchaseOrder.totalAmount) }}
                    </span>
                </div>
            </div>
            
        </div>
    </div>

    <div class="card p-2 mt-0 mb-2">
        <div class="d-flex">
            <div>
                <label class="font-weight-bold p-0 m-0">Note: <span class="text-muted">{{ purchaseOrder.note ? purchaseOrder.note : "No message attached." }}</span></label>
                
            </div>
            <div class="flex"></div>
            <div class="d-flex justify-content-center align-items-center">
                <label class="font-weight-bold p-0 m-0">Payment: <span class="text-muted">{{ purchaseOrder.paymentMethod == 1 ? "V-Wallet" : "Vips" }}</span></label>
            </div>
        </div>
    </div>
</div>

<ng-template #displayPoDTemplate let-modal>
    <div class="modal-header">
      <h6 class="modal-title">Proof of Delivery</h6>
      <button type="button" class="close" aria-label="Close" (click)="closeDisplay()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body pt-0 d-flex justify-content-center align-items-center">
        <img [src]="displayImagePoD" alt="" style="max-width: 100%; height: auto;">
    </div>
</ng-template>