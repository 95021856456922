
  import { CurrencyPipe, DatePipe } from '@angular/common';
  import { Component, OnInit } from '@angular/core';
  import { FormControl, FormGroup } from '@angular/forms';
  import { ActivatedRoute, Router } from '@angular/router';
  import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
  import { Select2OptionData } from 'ng-select2';
  import { Data } from "src/data/data";
  import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
  import { UserService } from "src/data/services/web/user.service";
  import { CryptoService } from 'src/data/crypto/crypto.service';
  import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
  import { AuthService } from 'src/data/services/authentication/auth.service';
  import { ExportExcelService } from 'src/data/services/web/export-excel.service';
  import { RolesService } from 'src/data/services/web/roles.service';
  import { TransactionService } from 'src/data/services/web/transaction.service';
  import { WebSettingsService } from 'src/data/services/web/web-settings.service';
  import { environment } from 'src/environments/environment';
  import { forkJoin } from 'rxjs';
import { PaygramService } from 'src/data/services/web/paygram.service';
  
  @Component({
    selector: 'app-paygram-ledger',
    templateUrl: './paygram-ledger.component.html',
    styleUrls: ['./paygram-ledger.component.less']
  })
  export class PaygramLedgerComponent implements OnInit {
  
    public transactionFilterFormGroup: FormGroup;
    public searchFormGroup: FormGroup;
    public formGroupSearch: FormGroup;
  
    public authService: AuthService;
    public localStorageCacheService: LocalStorageCacheService;
    public transactionService: TransactionService;
    public websettings: WebSettingsService;
    public userService: UserService;
    public emptyString = "";
    public statuses: any;
    public status: any;
    public modalService: BsModalService;
  
    public accessToken: string;
    public newTransaction: boolean;
    public transactions: any = [];
    public transaction: any;
    public pageCount: any;
    public roleLevelId: any;
  
    public cashiersAndManagers: any;
  
    public processUpdate: any = false;
  
    public bsModalRefConfirmation: BsModalRef;
    public selectedUserId: any = 0;
  
    public userIdFilterOptions: Array<Select2OptionData>;
    public providersOption: Array<Select2OptionData>;
    public statusOPtion: Array<Select2OptionData>;
    public userIdFilter: any = [];
    public providerIdFilter: any = [];
    public statusIdFilter: any = [];
    public billerIDFilter: any = [];
  
    public showRowsOption: Array<Select2OptionData>;
    public showRowsFilter: any = [];
  
    public paygramService: PaygramService;
  
    public isDataNotNull = false;
    dataForExcel = [];
  
    public billerOption: Array<Select2OptionData>;
    public Transactions: any;
  
    public pageRows: any = 10;
    public selectedRowsId: any = 0;
    loader = false;
  
    p = 1;
    constructor(
      private route: ActivatedRoute,
      private router: Router,
      private cryptoService: CryptoService,
      private data: Data,
      private currencyPipe: CurrencyPipe,
      public datepipe: DatePipe,
      private modalBService: NgbModal,
      authService: AuthService,
      localStorageCacheService: LocalStorageCacheService,
      rolesService: RolesService,
      transactionService: TransactionService,
      modalService: BsModalService,
      bsModalRefConfirmation: BsModalRef,
      userService: UserService,
      websettings: WebSettingsService,
      paygramService: PaygramService,
      public ete: ExportExcelService
    ) {
      this.authService = authService;
      this.localStorageCacheService = localStorageCacheService;
      this.transactionService = transactionService;
      this.modalService = modalService;
      this.accessToken = this.localStorageCacheService.getStorage("access_token");
      this.bsModalRefConfirmation = bsModalRefConfirmation;
      this.userService = userService;
      this.websettings = websettings;
      this.paygramService = paygramService;
    }
    userId;
    billers: any;
    billerCode: any = [];
    provider: any;
    ngOnInit(): void {
      this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
      this.userId = this.roleLevelId == 1 ? 0 : this.localStorageCacheService.getStorage("userId");
  
      //this.initSearchByUsernameFormGroup();
      this.initSearchFormGroup();
      this.getTransaction();
    }
  
    getTransaction(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
      this.loader =true;
      if (fromSearch) {
        this.createdDate = this.emptyString;
      }
      const dateRange : string = this.searchFormGroup.controls.dateRange.value;
       
    
      const queryString_showRows: string = `showRows=10&`;
      const queryString_dateRange: string = dateRange ? `startDate=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')}&endDate=${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
      const queryString =  queryString_dateRange +  queryString_showRows;
     
      let endPointUrl: string;
      if (url) {
        endPointUrl = `${url}&${queryString}`;
      } else {
        if (queryString) {
          endPointUrl = `${environment.API_URL}/api/Paygram/PaygramLedger?${queryString}`;
        } else {
          endPointUrl = `${environment.API_URL}/api/Paygram/PaygramLedger?${queryString}`;
        }
      }
  
      this.paygramService.getPaygramLedger(endPointUrl.slice(0, -1)).subscribe((result) => {
        this.loader =false;
        if(result){
          if (result.data.length == 0 && result.pageNumber != 1) {
            this.getTransaction(result.previousPage, _sortBy, _sort);
          } else {
            this.transactions = result;
          }
        }
       
      });
    }
  
    getTransactionForExcel(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
      this.loader =true;
     
      const dateRange : string = this.searchFormGroup.controls.dateRange.value;
     
      
      const queryString_showRows: string = `showRows=100000&`;
      const queryString_dateRange: string = dateRange ? `startDate=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')}&endDate=${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
      const queryString =  queryString_dateRange +  queryString_showRows;
    
      let endPointUrl: string;
      if (url) {
        endPointUrl = `${url}&${queryString}`;
      } else {
        if (queryString) {
          endPointUrl = `${environment.API_URL}/api/Paygram/PaygramLedger?${queryString}`;
        } else {
          endPointUrl = `${environment.API_URL}/api/Paygram/PaygramLedger?${queryString}`;
        }
      }
      
      this.paygramService.getPaygramLedger(endPointUrl.slice(0, -1)).subscribe((result) => {
        this.loader =false;
        if(result){
          debugger
          this.generateExcel(result.data, "Paygram Ledger");
        }
       
      });
    }
  
    createdDate = "";
    searchString = "";
    
    private _sortBy: any;
    private _sort: any;
  
    setPageSorting(field: any, page: any = null) {
      let sortBy: any;
      let sort: any;
  
      if (this._sort == field) {
        sort = field;
        sortBy = this._sortBy == "desc" ? "asc" : "desc";
      } else {
        sort = field;
        sortBy = "asc";
      }
      this._sort = sort;
      this._sortBy = sortBy;
  
      this.createdDate = field == "createdDate" ? "th-inner sortable both " + sortBy : "";
      
      this.getTransaction(
        `${environment.API_URL}/api/Paygram/PaygramLedger?pageNumber=${
          page ? page : this.transactions.pageNumber
        }`,
        this._sortBy,
        this._sort
      );
    }
  
    getPageNumberClass(pageNumber: any) {
      if (pageNumber == this.transactions?.pageNumber) {
        return "paginate_button page-item active";
      } else {
        return "paginate_button page-item";
      }
    }
  
    buildPageUrl(pageNumber: any, pageSize: any): any {
      this.p = pageNumber;
      return `${environment.API_URL}/api/Paygram/PaygramLedger?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
  
    totalPages(totalPages: any) {
      return Array(totalPages)
        .fill(1)
        .map((x, i) => i);
    }
  
    statusOfAmount(money: any): boolean {
      return money < 0 ? false : true;
    }
  
    makeMoney(money: any) {
      if(money){
        return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
      }else{
        if(money == 0){
          return "0.00";
        } else {
          return "N/A";
        }
      }
    }
  
    requestId;
    setSelectedTransaction(transaction: any, content) {
    
      this.transaction = transaction;
      this.requestId = this.transaction.requestId;
      this.openTransDetail(content);
    }
  
    modalCreateTransaction:any;
    openTransDetail(viewModal) {
      this.modalCreateTransaction = this.modalBService.open(viewModal, { centered: true, size: "xl" });
    }
  
    closeModal(){
      this.modalCreateTransaction.close();
      this.getTransaction(null,null,null,null);
    }
  
    // initSearchByUsernameFormGroup(): void {
    //   this.searchByUsernameFormGroup = new FormGroup({
    //     username: new FormControl(this.userId),
    //     dateRange: new FormControl(""),
    //     requestId: new FormControl(""),
    //   });
  
    //   this.searchByUsernameFormGroup.controls.username.valueChanges.subscribe((e) => {
    //     this.selectedUser(e);
    //     this.searchByUsernameFormGroup.controls.dateRange.setValue("");
    //     this.searchByUsernameFormGroup.controls.requestId.setValue("");
    //   });
    // }
  
    // selectedUser(e) {
    //   this.selectedUserId = e;
    //   // this.getTransaction();
    // }
  
    initSearchFormGroup(): void {
      this.searchFormGroup = new FormGroup({
        dateRange: new FormControl(this.emptyString)
      });
  
 
    }

  
    selectedRows(e){
      this.selectedRowsId = e;
      this.pageRows = e;
      this.getTransaction(); 
    }
  

    getRows(){
        this.websettings.getWebSettingsByIdentifier("SHOW_ROWS").subscribe((result) => {
          this.showRowsOption = result.data.map((status) => {
            return {
              id: status.parameter,
              text: status.value,
            };
          });
        });
  
        this.getTransaction();
    }
  
    exportToExcel() {
      this.Transactions = [];
      this.dataForExcel = [];
  
      this.getTransactionForExcel();
    }
  
    generateExcel(paygramLedger: any, name: any): void {
      debugger
      this.Transactions = [];
  
       
        for(var i = 0; i < paygramLedger.length; i++){
          
          let status = "";
  
          if(paygramLedger[i].statusId == "1"){
            status = "Rejected"
          }  else if (paygramLedger[i].statusId == "2"){
            status = "Completed"
          } else {
            status = "Pending"
          }

            // Formatter for currency
          const currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
  
          let meta = [
            {
                "Transaction Type":	paygramLedger[i].transactionType,
                "Reference ID": paygramLedger[i].referenceId,
                "Amount": currencyFormatter.format(paygramLedger[i].amount),
                "Running Balance": currencyFormatter.format(paygramLedger[i].runningTotal),
                "Transacted by": paygramLedger[i].transactByUserId,
                "Completed by": paygramLedger[i].completedByUserId,
                "Created date": this.datepipe.transform(paygramLedger[i].createdDate, "MMM-dd-yyyy hh:mm:ss a"),
                "Completed date": this.datepipe.transform(paygramLedger[i].completedDate, "MMM-dd-yyyy hh:mm:ss a"),
                "Status":	status              
            }
          ]
  
          this.Transactions.push(meta);
        }
  
    
  
      for(var x = 0; x < this.Transactions.length; x++){
        this.dataForExcel.push(Object.values(this.Transactions[x][0]));
       
      }
  
       // Create a new Date object for the current date and time
      const currentDate = new Date();
      
      // Format the date and time - this will create a string like 'Jan-03-2024_10-28-51'
      const dateTimeFormat = currentDate.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      }).replace(/, /g, '_').replace(/ /g, '-').replace(/:/g, '-');
      
      let reportData = {
        title: `${name} History_${dateTimeFormat}`,
        data: this.dataForExcel,
        headers: Object.keys(this.Transactions[0][0])
      };
  
      this.ete.exportExcel(reportData, `${name}_History_${dateTimeFormat}`);
    }
  
  }
  