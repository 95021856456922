import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection  } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import firebase from 'firebase/app';
import '@firebase/firestore';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PaginationService } from 'src/app/services/pagination.service';

@Component({
  selector: 'app-merchant-location',
  templateUrl: './merchant-location.component.html',
  styleUrls: ['./merchant-location.component.less']
})
export class MerchantLocationComponent implements OnInit {
  localityFormGroup : FormGroup;
  private firestore: firebase.firestore.Firestore;
  searchText:any ='';
  data: any[] = [];
  localities: any[] = [];
  selectedLocality: string;
  subLocalities: any[];
  _subLocalities: any[];
  selectedSublocality: any;
  origData: any[];

  locality_input: boolean = false;
  localty: boolean = true;
  selectedLocalityOrig: string;
  allSubLocalities: any;

  constructor(
    private afs: AngularFirestore,
    private modalServe: NgbModal,
    public page : PaginationService,
  ) { this.firestore = firebase.firestore(); }

  ngOnInit(): void {
    this.initNewForm();
    this.initLocalities();
  }

  initLocalities(){
    this.afs
      .collection('business_registration', ref => ref.orderBy('current_place_locality', 'desc'))
      .valueChanges({ idField: 'id' })
      .subscribe((data: any) => {
        this.localities = Array.from(new Set(data.map((item: any) => item.current_place_locality)));
      });

    // Fetch all the data from Firestore and update the table
    this.afs
      .collection('business_registration',ref => ref.orderBy('timestamp','desc').limit(20))
      .valueChanges({ idField: 'id' })
      .subscribe((data: any) => {
        this.data = data;
      });

  }

  onRemove(){

  }

  getSubLocalities($event){
    let query: AngularFirestoreCollection<any> | firebase.firestore.CollectionReference | firebase.firestore.Query = this.firestore.collection('business_registration');
    if ($event) {
      query = query.where('current_place_locality', '==', $event);
    }
    // Group the documents by sub-Locality and get the count for each sub-Locality
    query.get().then((querySnapshot) => {
      const subLocalityCounts = {};
      querySnapshot.forEach((doc) => {
        const subLocality = doc.data().current_place_sub_locality;
        if (subLocality) {
          subLocalityCounts[subLocality] = (subLocalityCounts[subLocality] || 0) + 1;
        }
      });
      // Update the subLocality array with the sub-Localities and their counts
      this._subLocalities = [];
      Object.keys(subLocalityCounts).forEach((subLocality) => {
        this._subLocalities.push({
          name: subLocality,
          count: subLocalityCounts[subLocality]
        });
      });
      // Sort the subLocality array by name
      this._subLocalities.sort((a, b) => a.name.localeCompare(b.name));
    });
  }
  searchTerm: string = '';
  filterData: any[];
  search(){
    if(this.searchTerm){
      this.filterData = this.data.filter((item)=>{
        item?.business_name.toLowerCase().includes(this.searchTerm.toLowerCase())
      })
    } else {
      this.filterData = this.data;
    }
  }

  initNewForm(): void {
    this.localityFormGroup = new FormGroup({
      Newlocality: new FormControl('',Validators.required),
      Newsublocality: new FormControl(''),
    });
  }

  save:boolean = false;
  selectedItem:any;
  editModal:NgbModalRef;
  edit(content, item){
    this.selectedItem = item;
    this.locality_input = true;
    this.localty = false;
    this.save = true;
    this.editModal = this.modalServe.open(content, { centered: true, size: "md" });
    this.localityFormGroup.reset();
  }
  message:boolean = false;
  messagescss: boolean = false;
  // submitLocality(id:any){
  //   if(this.localityFormGroup.valid){
  //     this.afs.firestore
  //     .collection("business_registration")
  //     .doc(id.id)
  //     .set({
  //       current_place_locality: this.localityFormGroup.controls.Newlocality.value,
  //       current_place_sub_locality: this.localityFormGroup.controls.Newsublocality.value
  //     },
  //     {
  //       merge:true,
  //     });
  //     this.localityFormGroup.reset();
  //     this.messagescss = true;
  //     this.editModal.close();
  //     setTimeout(() => {
  //       this.messagescss = false;
  //     }, 4000);
  //   }
  //   else{
  //     this.message=true;
  //     setTimeout(() => {
  //       this.message = false;
  //     }, 2000);
  //     this.localityFormGroup.reset();
  //   }
  // }
  @ViewChild('confirmModal') confirmModal: ElementRef;
  submitLocality(id:any){
    if(this.localityFormGroup.valid){
      // Open confirmation modal
      this.modalServe.open(this.confirmModal, { centered: true, size: "md" }).result.then((result) => {
        if (result === 'confirm') {
          // User clicked "Yes" on the confirmation modal
          this.afs.firestore
          .collection("business_registration")
          .doc(id.id)
          .set({
            current_place_locality: this.localityFormGroup.controls.Newlocality.value,
            current_place_sub_locality: this.localityFormGroup.controls.Newsublocality.value
          },
          {
            merge:true,
          });
          this.localityFormGroup.reset();
          this.messagescss = true;
          this.editModal.close();
          setTimeout(() => {
            this.messagescss = false;
          }, 4000);
        }
        else {
          // User clicked "Cancel" on the confirmation modal
          this.localityFormGroup.reset();
        }
      }, (reason) => {
        // Handle modal dismissal
        this.localityFormGroup.reset();
      });
    }
    else {
      this.message=true;
      setTimeout(() => {
        this.message = false;
      }, 2000);
      this.localityFormGroup.reset();
    }
  }


  filterBySublocality() {
    if (this.selectedSublocality === 'All'){
      this.selectedLocality = this.selectedLocalityOrig;
      return;
    }
    // Create a reference to the Firestore collection and add a "where" clause for the sub-Locality
    let query: firebase.firestore.CollectionReference | firebase.firestore.Query = this.firestore.collection('business_registration');
    if (this.selectedSublocality) {
      query = query.where('current_place_sub_locality', '==', this.selectedSublocality);
    }
    // Execute the query and update the data array with the results
    query.onSnapshot(snapshot => {
      const data = [];
      snapshot.forEach(doc => {
        data.push({ id: doc.id, ...doc.data() });
      });
      this.data = data;
    });
  }

  resetTable(){
    this.data = this.origData;
    this.selectedLocality = '';
    this.selectedSublocality = '';
    setTimeout(() => {
      this.ngOnInit();
    }, 500);
  }

  getSubMunicipalityCount() {
    // Create a reference to the Firestore collection and add a "where" clause for the selected Locality
    let query: AngularFirestoreCollection<any> | firebase.firestore.CollectionReference | firebase.firestore.Query = this.firestore.collection('business_registration');
    if (this.selectedLocality) {
      query = query.where('current_place_locality', '==', this.selectedLocality);
    }
    // Group the documents by sub-Locality and get the count for each sub-Locality
    query.get().then((querySnapshot) => {
      const subLocalityCounts = {};
      querySnapshot.forEach((doc) => {
        const subLocality = doc.data().current_place_sub_locality;
        if (subLocality) {
          subLocalityCounts[subLocality] = (subLocalityCounts[subLocality] || 0) + 1;
        }
      });
      // Update the subLocality array with the sub-Localities and their counts
      this.subLocalities = [];
      Object.keys(subLocalityCounts).forEach((subLocality) => {
        this.subLocalities.push({
          name: subLocality,
          count: subLocalityCounts[subLocality]
        });
      });
      // Sort the subLocality array by name
      this.subLocalities.sort((a, b) => a.name.localeCompare(b.name));
    });
  }


  filterByLocality(){
    if (!this.selectedLocality) {
      // No Locality selected, fetch all data from Firestore and update the table
      this.afs
        .collection('business_registration')
        .valueChanges({ idField: 'id' })
        .subscribe((data: any) => {
          this.data = data;
        });
      return;
    }

    // Filter the data by the selected Locality
    const query = this.afs
      .collection('business_registration')
      .ref.where('current_place_locality', '==', this.selectedLocality);
    query
      .get()
      .then((snapshot) => {
        const filteredData = snapshot.docs.map((doc) => {
          return { id: doc.id, ...(doc.data() as any) };
        });
        this.data = filteredData;
      })
      .catch((error) => {
        console.log('Error fetching filtered data: ', error);
      });
  }

  }

