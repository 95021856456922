<!-- <canvas id="canvas" width="490" height="490" style="border: 1px solid black;"></canvas>
<p id="clear">clear</p> -->
<div class="container-fluid">
    <div class="row">
        <div class="col-12 p-5">
             <canvas #myCanvas style="border: 4px solid black; width: inherit; height: 80%;"></canvas>
             <button (click)="playRandom()">Random</button> 
             <button (click)="clear()">clear</button> 
        </div>
    </div>
</div>
