import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { RolesService } from "src/data/services/web/roles.service";
import { WebSettingsService } from "src/data/services/web/web-settings.service";
import { environment } from "src/environments/environment";

// PNotify
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: "app-web-settings-create",
  templateUrl: "./web-settings-create.component.html",
  styleUrls: ["./web-settings-create.component.less"],
})
export class WebSettingsCreateComponent implements OnInit {
  public webSettingsFormGroup: FormGroup;
  public webSettingValuesFormGroup: FormGroup;
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public webSettingsService: WebSettingsService;
  public emptyString = "";
  public bsModalRefConfirmation: BsModalRef;
  public modalService: BsModalService;

  public accessToken: string;
  public newWebSettings: boolean;
  public WebSettings: any;
  public loadFlag = false;
  public WebSettingsId: any;
  public base64Photo: string | ArrayBuffer;
  public roleLevelId: any;
  public formBuilder: FormBuilder;

  processCreate = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    webSettingsService: WebSettingsService,
    modalService: BsModalService,
    formBuilder: FormBuilder
  ) {
    this.formBuilder = formBuilder;
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.webSettingsService = webSettingsService;
    this.modalService = modalService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");

    PNotifyButtons; // Initiate PNotify buttons. Important!
  }

  ngOnInit(): void {
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.initWebSettingsFormGroup();
  }

  initWebSettingsFormGroup(): void {
    this.webSettingsFormGroup = new FormGroup({
      id: new FormControl(0),
      name: new FormControl(this.emptyString),
      description: new FormControl(this.emptyString),
      value: new FormControl(this.emptyString),
      status: new FormControl(false),
      values: this.formBuilder.array([]),
    });

    this.loadFlag = true;

    this.webSettingsFormGroup.valueChanges.subscribe((value) => {
    });
  }

  addWebSettingValues(): void {
    const webSettingValuesFromGroup = this.formBuilder.group({
      parameter: this.formBuilder.control(this.emptyString, Validators.required),
      value: this.formBuilder.control(this.emptyString, Validators.required),
    });

    (this.webSettingsFormGroup.controls.values as FormArray).push(webSettingValuesFromGroup);
  }

  deleteWebSettingValues(webSettingIndex: number): void {
    (this.webSettingsFormGroup.controls.values as FormArray).removeAt(webSettingIndex);
  }

  public actionEventSaveWebSettings(): void {
    
    this.processCreate = true;

    this.webSettingsService.createWebSettings(this.webSettingsFormGroup.value).subscribe(
      (result) => {
        PNotify.success({
          title: "Success",
          text: "The setting has been stored.",
        });

        this.processCreate = false;
        this.backToWebSettingsList();
      },
      (error) => {
        if (error.status === 400) {

          const obj = error.error.errors;
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              let str: string = key;
              str = str[0].toLowerCase() + str.slice(1);
              this.webSettingsFormGroup.controls[str].setErrors({
                required: Validators.required,
                apiMessage: obj[key],
              });
            }
          }

          this.processCreate = false;

          PNotify.notice({
            title: "Warning",
            text: "Could not store setting with errors.",
          });
        } else {
          // Other error
          this.processCreate = false;

          PNotify.error({
            title: "Error",
            text: "Something went wrong.",
          });
        }
      }
    );
  }

  backToWebSettingsList(): void {
    this.router.navigate(["acm", "web-settings-list"]);
  }
}
