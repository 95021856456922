import { Component, OnInit, ɵCodegenComponentFactoryResolver } from '@angular/core';
import { SettlementsService } from 'src/data/services/web/settlements.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5
import { Select2OptionData } from "ng-select2";
import { CryptoService } from 'src/data/crypto/crypto.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SunlifeGrepaExportExcelService } from 'src/data/services/web/sunlife-grepa-export-excel.service';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import * as XLSX from 'ts-xlsx';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-easydebit-settlement',
  templateUrl: './easydebit-settlement.component.html',
  styleUrls: ['./easydebit-settlement.component.less']
})
export class EasydebitSettlementComponent implements OnInit {


  public uploadModal = null;
  public fileUploadFormGroup: FormGroup;
  emptyString = '';
  p: number = 1;
  public pageRows: any = 10;
  
  constructor(private settlementService : SettlementsService,
    private websettings : WebSettingsService,
    private modalServe: NgbModal,
    private router: Router,
    private currencyPipe : CurrencyPipe,
    private formBuilder : FormBuilder,
    public ete: SunlifeGrepaExportExcelService,
    private route: ActivatedRoute, 
    private cryptoService: CryptoService,
    public datepipe: DatePipe,
    private settlementsService : SettlementsService) { }

  ngOnInit(): void {
    this.initFileUploadFormGroup();
    PNotifyButtons;
    this.initSearchFormGroup();
    this.getTransaction();
    this.getShowRows();
    this.getStatus();
    this.initTextFileDownloadFormGroup();
  }

  public textFileDownloadFormGroup: FormGroup;
  initTextFileDownloadFormGroup(): void {
    this.textFileDownloadFormGroup = new FormGroup({
      dateTime: new FormControl(this.emptyString)
    });
  }
  downloadModal = null;
  openTextFileDownload(content: any) : void {
    this.downloadModal = this.modalServe.open(content, { centered: true, size: "md" });
  }

  downloadTextFileBankSettlement(transactionType : any){
    const dateRange : string = this.textFileDownloadFormGroup.controls.dateTime.value;
    var datetime = `${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}`
    this.settlementService.downloadTextFileBankSettlement("Easydebit", transactionType,datetime)
    .subscribe((result) => {
      
      var blob = new Blob([result.data], {type: "text/plain;charset=utf-8"});
      FileSaver.saveAs(blob, result.fileName);

      PNotify.success({
        title: "Success",
        text: "Download Complete.",
      });
    }, error =>{
      PNotify.error({
        title: "Failed",
        text: "Please double check the data and try again.",
      });
    });
  }

  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type});
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        alert( 'Please disable your Pop-up blocker and try again.');
    }
  }
  loader = false;
  submitSettlement() : void {
    if (confirm("Are you sure you want to continue? [BATCH UPLOAD]")) {
    this.loader = true;

    this.settlementService.uploadEasydebitData(this.listItems)
    .subscribe((result) => {
      PNotify.success({
        title: "Success",
        text: "File uploaded successfully!",
      });
      this.fileUploadFormGroup.patchValue({
        photoFile: "",
      })
      this.loader = false;
      this.getTransaction();
      this.uploadModal.close();
    
    }, error => {
      if(error.error.status == 400){
        PNotify.error({
          title: "Failed",
          text: "Some store name and transaction date are uploaded already. Remove the existing item and try again.",
        });
      }else{
        PNotify.error({
          title: "Failed",
          text: "Error uploading file!",
        });
      }
    
      this.loader = false;
    });
  }
  }

  initFileUploadFormGroup(): void {
    this.fileUploadFormGroup = new FormGroup({
      reportFile: new FormControl(this.emptyString)
    });
  }

  


  openFileUpload(content: any) : void {
    this.listItems = [];
    this.uploadModal = this.modalServe.open(content, { centered: true, size: "xl" });
  }

  public selectedRowsId: any = 0;
  selectedRows(e){
    this.selectedRowsId = e;
    this.pageRows = e;
   
  }

  settlementBanksArray = [];
  getSettlementBank(id : any) {

    if(id){
    var settlementBanksArray = [
      { id: "0001", name: "UNION BANK" },
      { id: "0102", name: "ASIA UNITED BANK" },
      { id: "0053", name: "BANCO DE ORO" },
      { id: "0004", name: "BANK OF THE PHILIPPINE ISLANDS" },
      { id: "0030", name: "BDO NETWORK BANK" },
      { id: "0010", name: "CHINABANK" },
      { id: "0035", name: "LANDBANK OF THE PHILS" },
      { id: "0026", name: "METROBANK" },
      { id: "0008", name: "PHILIPPINE NATIONAL BANK" },
      { id: "0670", name: "QUEZON CAPITAL RURAL BANK" },
      { id: "0014", name: "SECURITY BANK" },
    ];

   return settlementBanksArray.filter(x=>x.id == id)[0].name;
  }else{
    return this.emptyString;
  }
  }


 listItems = [];
  
  public fileName: any = "Click here to choose file...";
  public onFileChange(event: any) {
    debugger
    const target: DataTransfer = <DataTransfer>(event.target);
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      reader.readAsBinaryString(target.files[0]);
      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.IWorkBook = XLSX.read(binarystr, { type: 'binary' });
  
        /* selected the first sheet */
        const wsname: string = wb.SheetNames[1];
        const ws: XLSX.IWorkSheet = wb.Sheets[wsname];
  
        /* save data */
        var listItems = [];
        const dataXLS = XLSX.utils.sheet_to_json(ws, { header:1 }); // to get 2d array pass 2nd parameter as object {header: 1}
        for (let index = 2; index < dataXLS.length; index++) {
          const element : any = dataXLS[index];
          //if(element.length < 14){
            var data = {
              storeName : element[1].replace('ACM - ',''),
              terminalId :  "",
              transactionCountFirst: parseInt(element[5]),
              amountFirst: parseFloat(element[6].replace(/,/g,'')) + (parseInt(element[5])*5.0),
              transactionCountSecond: parseInt(element[5]),
              amountSecond: parseFloat(element[6].replace(/,/g,'')) + (parseInt(element[5])*5.0),
              amountThird: parseFloat(element[6].replace(/,/g,'')) + (parseInt(element[5])*5.0),
              fee: 5.0,
              transactionCountThird: parseInt(element[5]),
              amountForth: parseFloat(element[6].replace(/,/g,'')) + (parseInt(element[5])*5.0),
              netPayable: parseFloat(element[6].replace(/,/g,'')) + (parseInt(element[5])*5.0),
              transactionDate: element[4],
            }
            this.listItems.push(data);

          //}
        }
        console.log(listItems); // Data will be logged in array format containing objects
      };
    }
  }

  searchByFormGroup: FormGroup;
  public showRowsOption: Array<Select2OptionData>;
  public transactions: any = [];
  public transaction: any;
  public totals : any;

  initSearchFormGroup(): void {
    this.searchByFormGroup = new FormGroup({
      showRows: new FormControl(null),
      dateRange: new FormControl(this.emptyString),
      userId: new FormControl(this.emptyString),
      storeName: new FormControl(null),
      status: new FormControl(null),
      requestId: new FormControl(null),
    });


    this.searchByFormGroup.controls.showRows.valueChanges.subscribe(e => {
      this.selectedRows(e);
    });
  }

  public statusIdFilterOptions: Array<Select2OptionData>;
  getStatus() {
    this.statusIdFilterOptions = [
      { id: "3", text: "PENDING" },
      { id: "2", text: "COMPLETED" }
      // { id: "1", text: "DECLINED" }
    ];
  }


  getShowRows() {
    this.websettings.getWebSettingsByIdentifier("SHOW_ROWS").subscribe((result) => {
     
      this.showRowsOption = result.data.map((status) => {
        return {
          id: status.parameter,
          text: status.value,
        };
      });
       
    });
  }


  getTransaction(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader =true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    
    const userId : string = this.searchByFormGroup.controls.userId.value;
    const storeName : string = this.searchByFormGroup.controls.storeName.value;
    const showRows : string = this.searchByFormGroup.controls.showRows.value;
    const dateRange : string = this.searchByFormGroup.controls.dateRange.value;
    const status : string = this.searchByFormGroup.controls.status.value;
    const requestId : string = this.searchByFormGroup.controls.requestId.value;
    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_userId: string = userId ? `userId=${userId}&` : "";
    const queryString_storeName: string = storeName ? `storeName=${storeName}&` : "";
    const queryString_requestId: string = requestId ? `requestId=${requestId}&` : "";
    const queryString_status: string = status ? `status=${status}&` : "";
    const queryString_showRows: string = showRows ? `showRows=${showRows}&` : "showRows=10&";
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    const queryString = queryString_orderby + queryString_order +  queryString_userId+ queryString_storeName + queryString_requestId +queryString_status+ queryString_dateRange + queryString_showRows;
 
    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/Settlements/Easydebit/Settlements?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/Settlements/Easydebit/Settlements?${queryString}`;
      }
    }

    this.settlementService.getSettlements(endPointUrl.slice(0, -1)).subscribe((result) => {
      this.loader =false;
      if(result){
        if (result.data.length == 0 && result.pageNumber != 1) {
          this.getTransaction(result.previousPage, _sortBy, _sort);
        } else {
          this.transactions = result.data;
          this.buildFormArrayForBatch(result.data);
          this.totals = result.totals;
        }
      }
     
    });
  }

//NEW

  batchFormGroup : FormGroup;

  buildFormArrayForBatch(data){
   this.batchFormGroup = this.initBatchFormGroup(data.data);
   
  }

  initBatchFormGroup(data: any): FormGroup {
    const batchFormGroup: FormGroup = new FormGroup({
      depositReferenceNumber: new FormControl(""),
      remarks: new FormControl(""),
      values: this.formBuilder.array([]),
    });
    this.populateBatchFormGroupValues(data, batchFormGroup);
    return batchFormGroup;
  }

  populateBatchFormGroupValues(dataVal: any, batchFormGroup: FormGroup): void {
    const values = dataVal;
    console.log(values);
    values.forEach((data) => {
      const batchValuesFormGroup = this.formBuilder.group({
        id: new FormControl(data.id),
        status: new FormControl(false),
      });

      (batchFormGroup.controls.values as FormArray).push(batchValuesFormGroup);
    });

   
  }

  getBatchFormGroup(id){
    const fa : FormArray = this.batchFormGroup.controls.values as FormArray;
    const fg : FormGroup = (fa.controls.filter(x=> x.value.id == id)[0]) as FormGroup; // fa.controls.filter(x => x.controls.id.value == id) as FormGroup;
   return fg;
  }

 

 

  checkSettlementIfReady(transaction){
   
    if(this.getBatchFormGroup(transaction.id).value.status){

      this.settlementsService.checkStatusEasydebitData(transaction,transaction.id).subscribe((result) => {
        PNotify.success({
          title: "Success",
          text: "Tag Successfully!",
        });
      }, error=>{
        this.getBatchFormGroup(transaction.id).controls.status.setValue(false);
        PNotify.error({
          title: "Failed",
          text: "Data not complete.",
        });
  
      });
    }
  
    
  }


  batchComplete()
  {

   
 
    const forBatchTransactions = [];
    const forBatchTransactionsCon = this.batchFormGroup.value.values.filter(x=>x.status == true);
    forBatchTransactionsCon.forEach(data => {
      var tranTemp = this.transactions.data.filter(x=>x.id == data.id)[0];
      tranTemp.depositReferenceNumber = this.batchFormGroup.value.depositReferenceNumber;
      tranTemp.remarks = this.batchFormGroup.value.remarks;
      forBatchTransactions.push(tranTemp);
    });

    if(forBatchTransactions.length <= 0){
      PNotify.error({
        title: "Failed",
        text: "No data selected.",
      });
      return;
    }

    if(!this.batchFormGroup.value.depositReferenceNumber || !this.batchFormGroup.value.remarks ){
      PNotify.error({
        title: "Failed",
        text: "All fields are required.",
      });
      return;
    }

    this.loader = true;
        if (confirm("Are you sure you want to continue? [BATCH COMPLETE]")) {
        this.settlementsService.updateAndCompleteBatchEasydebitData(forBatchTransactions).subscribe((result) => {
          this.loader = false;
          PNotify.success({
            title: "Success",
            text: "Batch Completed Successfully!",
          });
          this.closeBatchCompleteModal();
          this.searchData();
        }, error=>{
          this.loader = false;
          PNotify.error({
            title: "Saving Failed",
            text: "All fields are required.",
          });
        });
      }else{
        this.loader = false;
      }
  }

  public batchCompleteModal = null;

  openBatchCompleteView(content: any) : void {
    this.batchFormGroup.controls.depositReferenceNumber.setValue("");
    this.batchFormGroup.controls.remarks.setValue("");
    this.batchCompleteModal = this.modalServe.open(content, { centered: true, size: "md" });
  }
  closeBatchCompleteModal(){
    this.batchCompleteModal.close();
  }

 //NEW

  searchData(){
    this.pageRows = 1;
    this.getTransaction(this.buildPageUrl(1,
      this.searchByFormGroup.controls.showRows.value
      ?this.searchByFormGroup.controls.showRows.value : 10),null,null,true);

  }

  createdDate = "";
  searchString = "";
  
  private _sortBy: any;
  private _sort: any;

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.createdDate = field == "createdDate" ? "th-inner sortable both " + sortBy : "";
   
    this.getTransaction(
      `${environment.API_URL}/api/Settlements/Easydebit/Settlements?pageNumber=${
        page ? page : this.transactions.pageNumber
      }`,
      this._sortBy,
      this._sort
    );
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.transactions?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }

  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p = pageNumber;
    return `${environment.API_URL}/api/Settlements/Easydebit/Settlements?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }

  statusOfAmount(money: any): boolean {
    return money < 0 ? false : true;
  }

  makeMoney(money: any) {
    if(money){
      return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
    }else{
      return "N/A";
    }
   
  }


  
  public editModal = null;

  openEditView(content: any,transaction : any) : void {
    this.getSettlementbyId(content,transaction,true);
  }

  getSettlementbyId(content: any,transaction : any, openModal : any){
    this.settlementService.getSettlementbyIdEasydebit(transaction.id).subscribe((result) => {
      this.loader =false;
      if(result){
        this.transaction = result;
        if(openModal){
          this.editModal = this.modalServe.open(content, { centered: true, size: "xl" });
          transaction = result;
        }else{
          this.searchData();
        }
      }
    });
  }

 closeModal(){
  this.getSettlementbyId(null,this.transaction,false);
  this.editModal.close();
 }

  exportToExcel(){

  }

}
