import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import PNotify from "pnotify/dist/es/PNotify";
@Component({
  selector: 'app-newsletter-builder',
  templateUrl: './newsletter-builder.component.html',
  styleUrls: ['./newsletter-builder.component.less']
})
export class NewsletterBuilderComponent implements OnInit {
  htmlContent = '';

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '45rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  public webSettingsService: WebSettingsService;
  public formBuilder: FormBuilder;
  public webSettingsFormGroup: FormGroup;
  public webSettingValuesFormGroup: FormGroup;


  public emptyString = "";
  public loadFlag = false;

  constructor(
    webSettingsService: WebSettingsService,
    formBuilder: FormBuilder
    )
    {
      this.formBuilder = formBuilder;
      this.webSettingsService = webSettingsService;
     }

  ngOnInit(): void {
    this.getWebSettings(27);
  }

  initWebSettingsFormGroup(webSettings: any): FormGroup {
    const webSettingsFormGroup: FormGroup = new FormGroup({
      id: new FormControl(webSettings.id ? webSettings.id : this.emptyString),
      name: new FormControl(webSettings.name ? webSettings.name : this.emptyString),
      description: new FormControl(webSettings.description ? webSettings.description : this.emptyString),
      value: new FormControl(webSettings.value ? webSettings.value : this.emptyString),
      status: new FormControl(webSettings.status ? webSettings.status : this.emptyString),
      values: this.formBuilder.array([]),
    });

    this.populateWebSettingValues(webSettings.values, webSettingsFormGroup);

    return webSettingsFormGroup;
    // this.loadFlag = true;

    // this.webSettingsFormGroup.valueChanges.subscribe((value) => {
    // });
  }

  populateWebSettingValues(webSettings: any, webSettingsFormGroup: FormGroup): void {
    const values = JSON.parse(JSON.stringify(JSON.parse(webSettings)));
    values.forEach((data) => {
      const webSettingValuesFromGroup = this.formBuilder.group({
        parameter: new FormControl(data.parameter),
        value: new FormControl(data.value),
      });

      (webSettingsFormGroup.controls.values as FormArray).push(webSettingValuesFromGroup);
    });
  }


  getWebSettings(id: any): void {
    this.webSettingsService.getWebSettingsSingle(id).subscribe((result) => {
      this.webSettingsFormGroup = this.initWebSettingsFormGroup(result);
      const values = JSON.parse(JSON.stringify(JSON.parse(result.values)));
      //console.log(values);
      this.htmlContent = values[0].value;
      console.log("result for FG",result);
    });
    console.log(this.webSettingsFormGroup);
  }

  saveAnnouncenment(value: FormControl) {
    //console.log("a val:  ",value);
    //console.log("b html val: ", this.htmlContent);
    value.value.setValue(this.htmlContent);
    //console.log("c ",this.webSettingsFormGroup);
    this.webSettingsService.updateWebSettings(this.webSettingsFormGroup.value, 27).subscribe((result) =>
      {
        PNotify.success({
        title: "Success",
        text: "Web setting updated successfully.",
      });
    },
    (error) => {
      if (error.status === 400) {
        const obj = error.error.errors;
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            let str: string = key;
            str = str[0].toLowerCase() + str.slice(1);
            this.webSettingsFormGroup.controls[str].setErrors({
              required: Validators.required,
              apiMessage: obj[key],
            });
          }
        }
      } else {
        PNotify.error({
          title: "Error",
          text: "Internal server error.",
        });
      }
    });

  }
}
