import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { FormGroup } from "@angular/forms";
import { ConvertToApiDatetimePipe } from "src/app/pipes/convert-to-api-datetime.pipe";
import { DatePipe } from "@angular/common";
import { CryptoService } from "src/data/crypto/crypto.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ManagerUsersService {
  private localStorageCacheService: LocalStorageCacheService;
  private convertToApiDatetimePipe: ConvertToApiDatetimePipe;

  constructor(
    private httpClient: HttpClient,
    private datePipe: DatePipe,
    private cryptoService: CryptoService,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService,
    convertToApiDatetimePipe: ConvertToApiDatetimePipe
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
    this.convertToApiDatetimePipe = convertToApiDatetimePipe;
  }
  private httpClientNoInterceptor: HttpClient;

  private baseAPIUrl = environment.API_URL;

  public createManagerUser(managerUserMeta: FormGroup): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ManagerUsers`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.post(endPointUrl, managerUserMeta, { headers: httpHeaders });
  }

  public createManagerUserMigration(managerUserMeta: FormGroup): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ManagerUsers/PostCreateManagerUserMigration`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.post(endPointUrl, managerUserMeta, { headers: httpHeaders });
  }

  public createSalesUserMigration(managerUserMeta: FormGroup): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ManagerUsers/PostCreateSalesUserMigration`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.post(endPointUrl, managerUserMeta, { headers: httpHeaders });
  }

  public createSalesAgentUserMigration(managerUserMeta: FormGroup): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ManagerUsers/PostCreateSalesAgentUserMigration`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.post(endPointUrl, managerUserMeta, { headers: httpHeaders });
  }

  public postManagerUserMigration(managerUserMeta: FormGroup): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ManagerUsers/PostManagerUserMigration`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.post(endPointUrl, managerUserMeta, { headers: httpHeaders });
  }

  public postSalesUserMigration(managerUserMeta: FormGroup): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/PostSalesUserMigration`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.post(endPointUrl, managerUserMeta, { headers: httpHeaders });
  }

  public checkExistingSalesMigration(managerUserMeta: FormGroup): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/CheckMigrationEmail`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      return this.httpClient.post(`${endPointUrl}`, managerUserMeta, { headers: httpHeaders });
  }

  public postSignOffStatus(username : any, origin: any, json:any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ManagerUsers/PostSignOffStatus`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      var jsonData = {
        username  :username,
        origin : origin,
        train  : json
      }

    return this.httpClient.post(endPointUrl, jsonData, { headers: httpHeaders });
  }

  public postComment(username : any, origin: any, json:any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ManagerUsers/PostSignOffStatus`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      var jsonData = {
        username  :username,
        origin : origin,
        comments  : json
      }

    return this.httpClient.post(endPointUrl, jsonData, { headers: httpHeaders });
  }


    public postSalesComment(username : any, origin: any, json:any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/PostSignOffStatus`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      var jsonData = {
        username  :username,
        origin : origin,
        comments  : json
      }

    return this.httpClient.post(endPointUrl, jsonData, { headers: httpHeaders });
  }
  

  public getMigrationList(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ManagerUsers/GetMigrationList`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getMigrationSalesList(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/GetMigrationList`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }
  

  public getNeedMigrationList(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/VIPMembers`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getNeedMigrationSalesList(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/VIPExclusiveClubMembers`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }
  

  public editManagerUser(managerUserMeta: FormGroup, id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ManagerUsers/${id}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClientNoInterceptor.put(endPointUrl, managerUserMeta, { headers: httpHeaders });
  }

  public editManagerUserBankSettlement(managerUserBankSettlementMeta: FormGroup, id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ManagerUsers/PutManagerUserBankSettlement/${id}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClientNoInterceptor.put(endPointUrl, managerUserBankSettlementMeta, { headers: httpHeaders });
  }

  public putManagerUserMultipleCashiers( id: any, isActive : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ManagerUsers/MultipleCashiers/${id}/${isActive}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClientNoInterceptor.put(endPointUrl, {}, { headers: httpHeaders });
  }


  public getManagerUser(managerUserId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ManagerUsers/${managerUserId}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getManagerUserV2(managerUserId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ManagerUsers/v2/${managerUserId}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public isAllowedToAddCashier(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ManagerUsers/CashierStatus`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public storeAddresses(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ManagerUsers/StoreAddresses`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public storeAddressesCashiers(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ManagerUsers/StoreAddresses/Cashiers`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }


  public storeAddressesCount(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ManagerUsers/StoreAddresses/Count`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  
  

  public getManagerUsers(url: any): Observable<any> {
    let endPointUrl: any;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    endPointUrl = `${url}`;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  // tslint:disable-next-line: typedef
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  // tslint:disable-next-line: typedef
  private log(message: string) {
    //
  }

  public createManagerStore(storeSettingsFormGroupMeta: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreSettings`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.post(endPointUrl, storeSettingsFormGroupMeta, { headers: httpHeaders });
  }

  public updateManagerStore(storeSettingsFormGroupMeta, id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreSettings/${id}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");
    
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.put(endPointUrl, storeSettingsFormGroupMeta, { headers: httpHeaders });
  }

  public createManagerStoreAddress(storeAddressFormGroupMeta: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreAddresses`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.post(endPointUrl, storeAddressFormGroupMeta, { headers: httpHeaders });
  }

  public updateManagerStoreAddress(storeAddressFormGroupMeta: any, id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreAddresses/${id}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.put(endPointUrl, storeAddressFormGroupMeta, { headers: httpHeaders });
  }

  public createManagerStorActivation(storeActivationFormGroupMeta: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreActivations`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.post(endPointUrl, storeActivationFormGroupMeta, { headers: httpHeaders });
  }

  public getManagerVIPDetails(username: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/VIPDetails`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    const json: any = {
      username: username,
    };
    return this.httpClient.post(endPointUrl, json, { headers: httpHeaders });
  }

 
  public getManagerVIPDetailsMigration(credentials): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/VIPDetails/Migration`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");
    
    return this.httpClient.post(endPointUrl, credentials, { headers: httpHeaders });
  }

  public getManagerVIPExclusiveClubDetailsMigration(credentials): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/VIPECDetails/Migration`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");
    
    return this.httpClient.post(endPointUrl, credentials, { headers: httpHeaders });
  }

  public getManagerVIPDetailsMigrationACM(username): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/VIPDetails/MigrationACM`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        username : username
      }
    
    return this.httpClient.post(endPointUrl, json, { headers: httpHeaders });
  }

  public getManagerVIPExclusiveClubDetailsMigrationACM(username): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/VIPECDetails/MigrationACM`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        username : username
      }
    
    
    return this.httpClient.post(endPointUrl, json, { headers: httpHeaders });
  }

  public getSalesUserUsingUsername(loginForm: FormGroup): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/VIPECDetails/MigrationACM`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");
  
    return this.httpClient.post(endPointUrl, loginForm, { headers: httpHeaders });
  }

  public createSalesUser(salesUserMeta: FormGroup): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/ActivateSalesUserMigration`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.post(endPointUrl, salesUserMeta, { headers: httpHeaders });
  }

  public sendCommunityApproved(json: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/SendCommunityNotif/Approved`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.post(endPointUrl, json, { headers: httpHeaders });
  }

  public sendCommunityRejected(json: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/SendCommunityNotif/Rejected`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.post(endPointUrl, json, { headers: httpHeaders });
  }

  public updateManagerStorActivation(storeActivationFormGroupMeta: FormGroup, id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreActivations/${id}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.put(endPointUrl, storeActivationFormGroupMeta, { headers: httpHeaders });
  }

  public getStoreHours(id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreSettings/GetStoreHours/${id}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");
  
    return this.httpClient.get(endPointUrl, { headers: httpHeaders });
  }

  public updateStoreHours(storeHoursFormGroup: any, id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreSettings/StoreHours/${id}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    var json = {
      monday : {
        active: storeHoursFormGroup.monday,
        start: storeHoursFormGroup.mondayStart,
        end: storeHoursFormGroup.mondayEnd
      },
      tuesday : {
        active: storeHoursFormGroup.tuesday,
        start: storeHoursFormGroup.tuesdayStart,
        end: storeHoursFormGroup.tuesdayEnd
      },
      wednesday : {
        active: storeHoursFormGroup.wednesday,
        start: storeHoursFormGroup.wednesdayStart,
        end: storeHoursFormGroup.wednesdayEnd
      },
      thursday : {
        active: storeHoursFormGroup.thursday,
        start: storeHoursFormGroup.thursdayStart,
        end: storeHoursFormGroup.thursdayEnd
      },
      friday : {
        active: storeHoursFormGroup.friday,
        start: storeHoursFormGroup.fridayStart,
        end: storeHoursFormGroup.fridayEnd
      },
      saturday : {
        active: storeHoursFormGroup.saturday,
        start: storeHoursFormGroup.saturdayStart,
        end: storeHoursFormGroup.saturdayEnd
      },
      sunday : {
        active: storeHoursFormGroup.sunday,
        start: storeHoursFormGroup.sundayStart,
        end: storeHoursFormGroup.sundayEnd
      },
      holiday : {
        active: storeHoursFormGroup.holiday,
        start: storeHoursFormGroup.holidayStart,
        end: storeHoursFormGroup.holidayEnd
      },
      specific_holiday: storeHoursFormGroup.specific_holiday
    };

    return this.httpClient.put(endPointUrl, json, { headers: httpHeaders });
  }
}
