import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Select2OptionData } from 'ng-select2';
import { CryptoService } from 'src/data/crypto/crypto.service';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { AuthService } from 'src/data/services/authentication/auth.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { ManagerUsersService } from 'src/data/services/web/manager-users.service';
import { result } from 'lodash';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-store-hours-settings',
  templateUrl: './store-hours-settings.component.html',
  styleUrls: ['./store-hours-settings.component.less']
})
export class StoreHoursSettingsComponent implements OnInit {

  roleId: any;
  userId: any;

  managerService: ManagerUsersService;

  @Input("managerId") managerId: number;
  @Output('closeModal') closeModal = new EventEmitter();

  constructor(private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private currencyPipe: CurrencyPipe,
    private formBuilder : FormBuilder,
    private authService: AuthService,
    _managerService: ManagerUsersService,
    private localStorageCacheService: LocalStorageCacheService,
    private webSettingsService : WebSettingsService) { 
      this.managerService = _managerService;
    }

  ngOnInit(): void {
    this.userId = this.managerId == null ? this.localStorageCacheService.getStorage("userId") : this.managerId;
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    var storeHours = this.localStorageCacheService.getStorage("storeHours");
    if(storeHours == "N/A"){
      this.managerService.getStoreHours(this.managerId).subscribe((result) => {
        this.initStoreHoursFormGroup(result);
      });
    } else {
      this.initStoreHoursFormGroup(JSON.parse(storeHours));
    }
    
    this.initTimeTemplate();
  }

  public timeTemplateArray: Array<Select2OptionData>;
  initTimeTemplate(){
    this.webSettingsService.getWebSettingsByIdentifier("TIME_TEMPLATE").subscribe((result) => {
      this.timeTemplateArray = result.data.map((status) => {
        return {
          id: status.value,
          text: status.value,
        };
      });
    });
  }

  @ViewChild('mondayOpenSelect') mondayOpenSelect: NgSelectComponent;
  @ViewChild('mondayCloseSelect') mondayCloseSelect: NgSelectComponent;

  @ViewChild('tuesdayOpenSelect') tuesdayOpenSelect: NgSelectComponent;
  @ViewChild('tuesdayCloseSelect') tuesdayCloseSelect: NgSelectComponent;

  @ViewChild('wednesdayOpenSelect') wednesdayOpenSelect: NgSelectComponent;
  @ViewChild('wednesdayCloseSelect') wednesdayCloseSelect: NgSelectComponent;

  @ViewChild('thursdayOpenSelect') thursdayOpenSelect: NgSelectComponent;
  @ViewChild('thursdayCloseSelect') thursdayCloseSelect: NgSelectComponent;

  @ViewChild('fridayOpenSelect') fridayOpenSelect: NgSelectComponent;
  @ViewChild('fridayCloseSelect') fridayCloseSelect: NgSelectComponent;

  @ViewChild('saturdayOpenSelect') saturdayOpenSelect: NgSelectComponent;
  @ViewChild('saturdayCloseSelect') saturdayCloseSelect: NgSelectComponent;

  @ViewChild('sundayOpenSelect') sundayOpenSelect: NgSelectComponent;
  @ViewChild('sundayCloseSelect') sundayCloseSelect: NgSelectComponent;

  @ViewChild('holidayOpenSelect') holidayOpenSelect: NgSelectComponent;
  @ViewChild('holidayCloseSelect') holidayCloseSelect: NgSelectComponent;

  storeHoursFormGroup: FormGroup;
  emptyString: any = "";
  initStoreHoursFormGroup(storeHours: any){
    this.storeHoursFormGroup = new FormGroup({
      monday : new FormControl(storeHours == null ? true : storeHours.monday.active),
      mondayStart : new FormControl(storeHours == null ? true : storeHours.monday.start, [Validators.required]),
      mondayEnd : new FormControl(storeHours == null ? true : storeHours.monday.end, [Validators.required]),
      tuesday : new FormControl(storeHours == null ? true : storeHours.tuesday.active),
      tuesdayStart : new FormControl(storeHours == null ? true : storeHours.tuesday.start, [Validators.required]),
      tuesdayEnd : new FormControl(storeHours == null ? true : storeHours.tuesday.end, [Validators.required]),
      wednesday : new FormControl(storeHours == null ? true : storeHours.wednesday.active),
      wednesdayStart : new FormControl(storeHours == null ? true : storeHours.wednesday.start, [Validators.required]),
      wednesdayEnd : new FormControl(storeHours == null ? true : storeHours.wednesday.end, [Validators.required]),
      thursday : new FormControl(storeHours == null ? true : storeHours.thursday.active),
      thursdayStart : new FormControl(storeHours == null ? true : storeHours.thursday.start, [Validators.required]),
      thursdayEnd : new FormControl(storeHours == null ? true : storeHours.thursday.end, [Validators.required]),
      friday : new FormControl(storeHours == null ? true : storeHours.friday.active),
      fridayStart : new FormControl(storeHours == null ? true : storeHours.friday.start, [Validators.required]),
      fridayEnd : new FormControl(storeHours == null ? true : storeHours.friday.end, [Validators.required]),
      saturday : new FormControl(storeHours == null ? true : storeHours.saturday.active),
      saturdayStart : new FormControl(storeHours == null ? true : storeHours.saturday.start, [Validators.required]),
      saturdayEnd : new FormControl(storeHours == null ? true : storeHours.saturday.end, [Validators.required]),
      sunday : new FormControl(storeHours == null ? true : storeHours.sunday.active),
      sundayStart : new FormControl(storeHours == null ? true : storeHours.sunday.start, [Validators.required]),
      sundayEnd : new FormControl(storeHours == null ? true : storeHours.sunday.end, [Validators.required]),
      holiday : new FormControl(storeHours == null ? true : storeHours.holiday.active),
      holidayStart : new FormControl(storeHours == null ? true : storeHours.holiday.start, [Validators.required]),
      holidayEnd : new FormControl(storeHours == null ? true : storeHours.holiday.end, [Validators.required]),
      specific_holiday : new FormControl(storeHours == null ? null : storeHours.specific_holiday,[Validators.required])
    });

    if(storeHours?.monday.active == false){
      this.storeHoursFormGroup.get('mondayStart').clearValidators();
        this.storeHoursFormGroup.get('mondayEnd').clearValidators();

        this.storeHoursFormGroup.controls.mondayStart.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.mondayEnd.updateValueAndValidity({emitEvent:false, onlySelf:true});
    }

    if(storeHours?.tuesday.active == false){
      this.storeHoursFormGroup.get('tuesdayStart').clearValidators();
        this.storeHoursFormGroup.get('tuesdayEnd').clearValidators();

        this.storeHoursFormGroup.controls.tuesdayStart.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.tuesdayEnd.updateValueAndValidity({emitEvent:false, onlySelf:true});
    }

    if(storeHours?.wednesday.active == false){
      this.storeHoursFormGroup.get('wednesdayStart').clearValidators();
        this.storeHoursFormGroup.get('wednesdayEnd').clearValidators();

        this.storeHoursFormGroup.controls.wednesdayStart.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.wednesdayEnd.updateValueAndValidity({emitEvent:false, onlySelf:true});
    }

    if(storeHours?.thursday.active == false){
      this.storeHoursFormGroup.get('thursdayStart').clearValidators();
        this.storeHoursFormGroup.get('thursdayEnd').clearValidators();

        this.storeHoursFormGroup.controls.thursdayStart.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.thursdayEnd.updateValueAndValidity({emitEvent:false, onlySelf:true});
    }

    if(storeHours?.friday.active == false){
      this.storeHoursFormGroup.get('fridayStart').clearValidators();
        this.storeHoursFormGroup.get('fridayEnd').clearValidators();

        this.storeHoursFormGroup.controls.fridayStart.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.fridayEnd.updateValueAndValidity({emitEvent:false, onlySelf:true});
    }

    if(storeHours?.saturday.active == false){
      this.storeHoursFormGroup.get('saturdayStart').clearValidators();
        this.storeHoursFormGroup.get('saturdayEnd').clearValidators();

        this.storeHoursFormGroup.controls.saturdayStart.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.saturdayEnd.updateValueAndValidity({emitEvent:false, onlySelf:true});
    }

    if(storeHours?.sunday.active == false){
      this.storeHoursFormGroup.get('sundayStart').clearValidators();
        this.storeHoursFormGroup.get('sundayEnd').clearValidators();

        this.storeHoursFormGroup.controls.sundayStart.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.sundayEnd.updateValueAndValidity({emitEvent:false, onlySelf:true});
    }

    if(storeHours?.holiday.active == false){
      this.storeHoursFormGroup.get('holidayStart').clearValidators();
        this.storeHoursFormGroup.get('holidayEnd').clearValidators();
        this.storeHoursFormGroup.get('specific_holiday').clearValidators();

        this.storeHoursFormGroup.controls.holidayStart.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.holidayEnd.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.specific_holiday.updateValueAndValidity({emitEvent:false, onlySelf:true});
    }

    this.storeHoursFormGroup.controls.monday.valueChanges.subscribe((value) => {
           
      if(value == true){
        this.storeHoursFormGroup.controls.mondayStart.setValidators(Validators.required);
        this.storeHoursFormGroup.controls.mondayEnd.setValidators(Validators.required);

        this.storeHoursFormGroup.controls.mondayStart.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.mondayEnd.updateValueAndValidity({emitEvent:false, onlySelf:true});
      } else {
        this.storeHoursFormGroup.get('mondayStart').clearValidators();
        this.storeHoursFormGroup.get('mondayEnd').clearValidators();

        this.storeHoursFormGroup.controls.mondayStart.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.mondayEnd.updateValueAndValidity({emitEvent:false, onlySelf:true});

        this.storeHoursFormGroup.controls.mondayStart.patchValue("");
        this.storeHoursFormGroup.controls.mondayEnd.patchValue("");
      }
    });

    this.storeHoursFormGroup.controls.tuesday.valueChanges.subscribe((value) => {
           
      if(value == true){
        this.storeHoursFormGroup.controls.tuesdayStart.setValidators(Validators.required);
        this.storeHoursFormGroup.controls.tuesdayEnd.setValidators(Validators.required);

        this.storeHoursFormGroup.controls.tuesdayStart.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.tuesdayEnd.updateValueAndValidity({emitEvent:false, onlySelf:true});
      } else {
        this.storeHoursFormGroup.get('tuesdayStart').clearValidators();
        this.storeHoursFormGroup.get('tuesdayEnd').clearValidators();

        this.storeHoursFormGroup.controls.tuesdayStart.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.tuesdayEnd.updateValueAndValidity({emitEvent:false, onlySelf:true});

        this.storeHoursFormGroup.controls.tuesdayStart.patchValue("");
        this.storeHoursFormGroup.controls.tuesdayEnd.patchValue("");
      }
    });

    this.storeHoursFormGroup.controls.wednesday.valueChanges.subscribe((value) => {
           
      if(value == true){
        this.storeHoursFormGroup.controls.wednesdayStart.setValidators(Validators.required);
        this.storeHoursFormGroup.controls.wednesdayEnd.setValidators(Validators.required);

        this.storeHoursFormGroup.controls.wednesdayStart.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.wednesdayEnd.updateValueAndValidity({emitEvent:false, onlySelf:true});
      } else {
        this.storeHoursFormGroup.get('wednesdayStart').clearValidators();
        this.storeHoursFormGroup.get('wednesdayEnd').clearValidators();

        this.storeHoursFormGroup.controls.wednesdayStart.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.wednesdayEnd.updateValueAndValidity({emitEvent:false, onlySelf:true});

        this.storeHoursFormGroup.controls.wednesdayStart.patchValue("");
        this.storeHoursFormGroup.controls.wednesdayEnd.patchValue("");
      }
    });

    this.storeHoursFormGroup.controls.thursday.valueChanges.subscribe((value) => {
           
      if(value == true){
        this.storeHoursFormGroup.controls.thursdayStart.setValidators(Validators.required);
        this.storeHoursFormGroup.controls.thursdayEnd.setValidators(Validators.required);

        this.storeHoursFormGroup.controls.thursdayStart.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.thursdayEnd.updateValueAndValidity({emitEvent:false, onlySelf:true});
      } else {
        this.storeHoursFormGroup.get('thursdayStart').clearValidators();
        this.storeHoursFormGroup.get('thursdayEnd').clearValidators();

        this.storeHoursFormGroup.controls.thursdayStart.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.thursdayEnd.updateValueAndValidity({emitEvent:false, onlySelf:true});

        this.storeHoursFormGroup.controls.thursdayStart.patchValue("");
        this.storeHoursFormGroup.controls.thursdayEnd.patchValue("");
      }
    });

    this.storeHoursFormGroup.controls.friday.valueChanges.subscribe((value) => {
           
      if(value == true){
        this.storeHoursFormGroup.controls.fridayStart.setValidators(Validators.required);
        this.storeHoursFormGroup.controls.fridayEnd.setValidators(Validators.required);

        this.storeHoursFormGroup.controls.fridayStart.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.fridayEnd.updateValueAndValidity({emitEvent:false, onlySelf:true});
      } else {
        this.storeHoursFormGroup.get('fridayStart').clearValidators();
        this.storeHoursFormGroup.get('fridayEnd').clearValidators();

        this.storeHoursFormGroup.controls.fridayStart.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.fridayEnd.updateValueAndValidity({emitEvent:false, onlySelf:true});

        this.storeHoursFormGroup.controls.fridayStart.patchValue("");
        this.storeHoursFormGroup.controls.fridayEnd.patchValue("");
      }
    });

    this.storeHoursFormGroup.controls.saturday.valueChanges.subscribe((value) => {
           
      if(value == true){
        this.storeHoursFormGroup.controls.saturdayStart.setValidators(Validators.required);
        this.storeHoursFormGroup.controls.saturdayEnd.setValidators(Validators.required);

        this.storeHoursFormGroup.controls.saturdayStart.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.saturdayEnd.updateValueAndValidity({emitEvent:false, onlySelf:true});
      } else {
        this.storeHoursFormGroup.get('saturdayStart').clearValidators();
        this.storeHoursFormGroup.get('saturdayEnd').clearValidators();

        this.storeHoursFormGroup.controls.saturdayStart.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.saturdayEnd.updateValueAndValidity({emitEvent:false, onlySelf:true});

        this.storeHoursFormGroup.controls.saturdayStart.patchValue("");
        this.storeHoursFormGroup.controls.saturdayEnd.patchValue("");
      }
    });

    this.storeHoursFormGroup.controls.sunday.valueChanges.subscribe((value) => {
           
      if(value == true){
        this.storeHoursFormGroup.controls.sundayStart.setValidators(Validators.required);
        this.storeHoursFormGroup.controls.sundayEnd.setValidators(Validators.required);

        this.storeHoursFormGroup.controls.sundayStart.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.sundayEnd.updateValueAndValidity({emitEvent:false, onlySelf:true});
      } else {
        this.storeHoursFormGroup.get('sundayStart').clearValidators();
        this.storeHoursFormGroup.get('sundayEnd').clearValidators();

        this.storeHoursFormGroup.controls.sundayStart.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.sundayEnd.updateValueAndValidity({emitEvent:false, onlySelf:true});

        this.storeHoursFormGroup.controls.sundayStart.patchValue("");
        this.storeHoursFormGroup.controls.sundayEnd.patchValue("");
      }
    });

    this.storeHoursFormGroup.controls.holiday.valueChanges.subscribe((value) => {
           
      if(value == true){
        this.storeHoursFormGroup.controls.holidayStart.setValidators(Validators.required);
        this.storeHoursFormGroup.controls.holidayEnd.setValidators(Validators.required);
        this.storeHoursFormGroup.controls.specific_holiday.setValidators(Validators.required);

        this.storeHoursFormGroup.controls.holidayStart.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.holidayEnd.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.specific_holiday.updateValueAndValidity({emitEvent:false, onlySelf:true});
      } else {
        this.storeHoursFormGroup.get('holidayStart').clearValidators();
        this.storeHoursFormGroup.get('holidayEnd').clearValidators();
        this.storeHoursFormGroup.get('specific_holiday').clearValidators();

        this.storeHoursFormGroup.controls.holidayStart.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.holidayEnd.updateValueAndValidity({emitEvent:false, onlySelf:true});
        this.storeHoursFormGroup.controls.specific_holiday.updateValueAndValidity({emitEvent:false, onlySelf:true});

        this.storeHoursFormGroup.controls.holidayStart.patchValue("");
        this.storeHoursFormGroup.controls.holidayEnd.patchValue("");
        this.storeHoursFormGroup.controls.specific_holiday.patchValue("");
      }
    });
  }

  uploadStoreHours(){
    if(this.storeHoursFormGroup.invalid){
      PNotify.error({
        title: "Incomplete",
        text: "Please fill required fields. \nOpening and Closing Hours are required if day is selected.",
      });
    } else {
      this.managerService.updateStoreHours(this.storeHoursFormGroup.value, this.userId).subscribe((result) => {
        PNotify.success({
          title: "Success",
          text: "Store Hours has been successfully updated.",
        });
        this.localStorageCacheService.setStorage("storeHours", JSON.stringify(result) ,86400);
        this.localStorageCacheService.removeStorage("from_login");
        this.closeModal.emit();
      });
    }
  }
}
