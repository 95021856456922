import { Pipe, PipeTransform } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";

@Pipe({
  name: "authImage",
})
export class AuthImagePipe implements PipeTransform {
  constructor(
    private http: HttpClient,
    private httpBackend: HttpBackend,
    private localStorageCacheService: LocalStorageCacheService
  ) {
    this.http = new HttpClient(this.httpBackend);
  }

  async transform(src: string): Promise<string> {
    const accesToken = this.localStorageCacheService.getStorage("access_token");

    if (!accesToken) {
      throw "The user is not yet authenticated.";
    }

    const headers = new HttpHeaders({ Authorization: `Bearer ${accesToken}` });
    const imageBlob = await this.http.get(src, { headers, responseType: "blob" }).toPromise();
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(imageBlob);
    });
  }
}
