<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex">
  <!-- ############ Main START-->
  <div class="pl-3 pr-3">
    <div id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="--spam">JuanPay Store | <span class="color-blue fonr-weight-bold">Checkout</span></h2>
          <!-- <small class="--desc">GoVIPCenter list of Stock Item</small> -->
        </div>
        <div class="flex"></div>
      </div>
    </div>
    <div id="page-content">
      <div class="pl-3 pr-3 pt-1 pb-2" style="background-color: rgb(247, 247, 247)">
        <div>
          <div class="card p-2 container-card">
            <div class="d-flex">
              <div class="div-item ml-4 mr-5">
                <h6 class="mb-0 pb-0">Image</h6>
              </div>
              <div class="div-item ml-2">
                <div>
                  <h6 class="mb-0 pb-0">Item Description</h6>
                </div>
              </div>
              <div class="flex"></div>

              <div class="div-item mr-4 price-div">
                <div class="quantity">
                  <h6 class="mb-0 pb-0">Quantity</h6>
                </div>
              </div>

              <div class="div-item ml-5 mr-5 price-div">
                <h6 class="mb-0 pb-0">Amount</h6>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="card p-2 container-card" *ngFor="let item of cartItems; let i = index">
            <div class="d-flex">
              <div class="div-item mr-3">
                <img class="item-img" [src]="item?.storeItemImages[0]?.path" />
              </div>
              <div class="div-item">
                <div>
                  <h5 class="m-0 p-0">{{ item.name }}</h5>
                  <p class="text-muted description m-0 p-0">{{ item.description }}</p>
                </div>
              </div>
              <div class="flex"></div>

              <div class="div-item mr-5"><div class="vl"></div></div>
              <div class="div-item mr-5 price-div">
                <h5>{{ item.quantity }}</h5>
              </div>

              <div class="div-item mr-5"><div class="vl"></div></div>
              <div class="div-item mr-5 price-div">
                <span class="color-red font-weight-bolder">₱ {{ makeMoney(item.price) }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-4 pr-0">
            <div class="card mt-0 card-container p-2">
              <div class="d-flex">
                <div>
                  <h5 class="text-muted font-weight-bolder">Delivery Address</h5>
                </div>
                <div class="flex"></div>
                <div>
                  <button class="btn btn-warning btn-sm" (click)="changeAddressModal(changeAddressTemplate)">
                    Change
                  </button>
                </div>
              </div>
              <div>
                <h6 class="mb-0 font-weight-bolder">{{ selectedAddress?.name }}</h6>
                <p class="mb-0">{{ selectedAddress?.contact }}</p>
                <p class="text-muted">{{ selectedAddress?.address }}</p>
              </div>
            </div>
          </div>
          <div class="col-4 pr-0">
            <div class="card mt-0 card-container p-2">
              <div class="d-flex">
                <div>
                  <h5 class="text-muted font-weight-bolder">Payment Method</h5>
                </div>
                <div class="flex"></div>
                <div>
                  <button class="btn btn-warning btn-sm" (click)="changeMethod(changePaymentTemplate)">Change</button>
                </div>
              </div>
              <div>
                <h6 class="mb-0 font-weight-bold">{{ selectedPayment.method }}</h6>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="card mt-0 card-container p-2">
              <h5 class="text-muted font-weight-bolder">Total Summary</h5>
              <div class="d-flex align-items-center">
                <div class="font-weight-bold">Shipping Fee</div>
                <div class="flex"></div>
                <span class="mr-2">
                  <!-- <span>Note: shipping fee would be based <br>on your location and won't be included <br> on the indicated total amount.</span> -->
                  <span class="text-muted font-weight-bold color-red">(Not Included)</span>
                </span>
              </div>
              <div class="d-flex align-items-center">
                <div class="font-weight-bold">Note</div>
                <div class="flex"></div>
                <span class="mr-2">
                  <span class="text-muted">Shipping fee would be based on your location</span>
                </span>
              </div>
              <hr class="mt-0" />
              <div class="d-flex align-items-center">
                <div class="font-weight-bold">Total Payment</div>
                <div class="flex"></div>
                <span class="font-weight-bold mr-2">
                  <span class="color-red">₱ {{ makeMoney(totalAmount) }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="card p-2 mt-0 mb-2">
          <div class="d-flex">
            <div>
              <label class="font-weight-bold">Note: </label>
              <input
                class="ml-3 message-text"
                type="text"
                maxlength="50"
                [(ngModel)]="message"
                placeholder="Please leave a message..."
              />
            </div>
            <div class="flex"></div>
            <div class="d-flex justify-content-center align-items-center">
              <button class="btn btn-sm btn-secondary mr-2" style="width: 200px" (click)="backToCart()">
                Back to Cart
              </button>
              <button
                class="btn btn-sm button-blue mr-2"
                style="width: 200px"
                (click)="placeOrderModal(confirmPlaceOrderTemplate)"
              >
                Place Order
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<ng-template #changeAddressTemplate>
  <div class="modal-header">
    <h6 class="modal-title">Address</h6>
    <!-- <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button> -->
  </div>
  <div>
    <div class="pl-5 pr-5 pb-0">
      <div class="mb-3" *ngFor="let address of addresses">
        <label class="md-check">
          <input type="radio" name="radio" [(ngModel)]="selectedAddress" [value]="address" />
          <i class="indigo"></i>
          {{ "(" + address.user + " Address) " + address.address }}
        </label>
      </div>
    </div>
    <div class="d-flex p-2 mr-2">
      <div class="flex"></div>
      <div>
        <button class="btn btn-sm btn-danger" (click)="changeAddress(selectedAddress)">Change Address</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #changePaymentTemplate>
  <div class="modal-header">
    <h6 class="modal-title">Payment Method</h6>
    <!-- <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button> -->
  </div>
  <div>
    <div class="pl-5 pr-5 pb-0">
      <div class="mb-3" *ngFor="let method of paymentMethod; let i = index">
        <label class="md-check">
          <input
            type="radio"
            name="radio"
            [(ngModel)]="selectedPayment"
            [value]="method"
            [disabled]="method.status != 2"
            [checked]="i === 0"
          />
          <i class="indigo"></i>
          {{ method.method }} <span *ngIf="method.status == 1" class="color-red">(Coming Soon)</span>
        </label>
      </div>
    </div>
    <div class="d-flex p-2 mr-2">
      <div class="flex"></div>
      <div>
        <button class="btn btn-sm btn-danger" (click)="changePayment(selectedPayment)">Change Payment Method</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #confirmPlaceOrderTemplate let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Confirmation</h6>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body pt-0 pb-0">
    <p class="mb-0">Are you sure you want to Place Order?</p>
  </div>

  <div class="modal-body">
    <button class="btn btn-sm button-blue float-right" (click)="placeOrder()">Proceed</button>
    <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="closeModal()">Cancel</button>
  </div>
</ng-template>
