<!-- <app-loader *ngIf="!roles"></app-loader> -->

<div id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">List of Manager Files</h2>
          <small class="text-highlight font-weight-normal font-italic">JuanPay list of Manager Files</small>
        </div>
        <!-- <div class="flex"></div>
        <div>
          <div>
            <button class="btn btn-md btn-primary w-100" (click)="openFileUpload(uploadmodal)">
              <i class="fa fa-upload"></i> &nbsp; Upload File
            </button>
          </div>
        </div> -->
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="padding" style="padding-right: 0px">
        <div class="card p-1 mb-1" style="background-color: #182038">
          <div class="row">
            <div class="col-sm-12 col-md-7"></div>
            <div class="col-sm-12 col-md-5">
              <form class="flex" [formGroup]="listFilterForm">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control form-control-theme form-control-sm search"
                    placeholder="Search by Username..."
                    formControlName="searchString"
                    (keyup.enter)="getCompanyUsers(null, null, null, true)"
                    style="background-color: white; color: black"
                  />
                  <span class="input-group-append">
                    <button
                      class="btn btn-white no-border btn-sm"
                      type="button"
                      style="background-color: gainsboro"
                      (click)="getCompanyUsers(null, null, null, true)"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="card">
          <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
            <thead>
              <tr>
                <th data-sortable="true" data-field="name">Username</th>
                <th data-sortable="true" data-field="name">File Name</th>
                <th data-field="finish"><span class="d-none d-sm-block">Date Upload</span></th>
                <th data-sortable="true" data-field="name">Purpose</th>
              </tr>
            </thead>
            <tbody>
              <tr
                style="cursor: pointer"
                class="odd"
                role="row"
                *ngFor="let managerFile of managerFiles?.data"
                (click)="viewContent(contentModal, managerFile)"
              >
                <td>
                  <small class="">{{ managerFile?.managerUsername }}</small>
                </td>
                <td>
                  <small class="">{{ managerFile?.fileName }}</small>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ managerFile?.createdDate | date: "dd-MMM-yyyy hh:mm:ss a" }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">{{ managerFile?.purpose }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- <div class="row">
            <div class="col-sm-12 col-md-5">
              <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                <div class="col-sm-12 col-md-12">
                  <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                    Showing {{ roles?.from }} to {{ roles?.to }} of {{ roles?.data?.length }} entries
                  </div>
                </div>
              </div>
            </div>
  
            <div class="col-sm-12 col-md-7" style="float: right">
              <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                <ul class="pagination" style="float: right; margin-right: 36px">
                  <li
                    *ngFor="let page of roles?.links"
                    (click)="getRoles(page.url)"
                    [ngClass]="getPageNumberClass(page.label)"
                  >
                    <a class="page-link" [innerHTML]="page.label"></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="vip__page-table--pagination">
            <pagination-template
              *ngIf="flightTransactionList?.length > 10"
              #p="paginationApi"
              [id]="paginationConfig.id"
              [maxSize]="10"
              (pageChange)="paginationConfig.currentPage = $event"
            >
              <div class="pagination-container text-center margin-top-15">
                <div class="adms-flight-transaction-list__pagination">
                  <div class="adms-flight-transaction-list__pagination--pagination-previous">
                    <button type="button" [disabled]="p.isFirstPage()" (click)="p.previous()" class="btn btn-link">
                      <span class="cebcons-md cebcons-arrow-down rotate-left"></span>
                    </button>
                  </div>
                  <div *ngFor="let page of p.pages" class="adms-flight-transaction-list__pagination--pagination-item">
                    <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">{{ page.label }}</a>
                    <span *ngIf="p.getCurrent() === page.value">{{ page.label }}</span>
                  </div>
                  <div class="adms-flight-transaction-list__pagination--pagination-next">
                    <button type="button" [disabled]="p.isLastPage()" (click)="p.next()" class="btn btn-link">
                      <span class="cebcons-md cebcons-arrow-down rotate-right"></span>
                    </button>
                  </div>
                </div>
              </div>
            </pagination-template>
          </div> -->
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<ng-template #uploadmodal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Upload Files</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div>
      <div class="page-content page-container" id="page-content">
        <div class="padding">
          <p class="text-muted">
            DropzoneJS is an open source library that provides drag’n’drop file uploads with image previews.
          </p>
          <form
            action="api/dropzone"
            class="dropzone white b-a b-3x b-dashed b-primary p-a rounded p-5 text-center"
            data-plugin="dropzone"
            data-option="{url: '../assets/api/dropzone'}"
          >
            <div class="dz-message">
              <h4 class="my-4">Drop files here or click to upload.</h4>
              <span class="text-muted d-block mb-4"
                >(This is just a demo dropzone. Selected files are <strong>not</strong> actually uploaded.)</span
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contentModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ managerFile.fileName }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="p-4 text-center">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6">
          <p class="text-secondary text-left font-weight-bold">
            Manager User:
            <span class="font-weight-bold text-dark"> {{ managerFile.managerUsername }}</span>
          </p>
          <p class="text-secondary text-left font-weight-bold">
            Purpose:
            <span class="font-weight-bold text-dark"> {{ managerFile.purpose }}</span>
          </p>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <p class="text-secondary text-left font-weight-bold">
            Date Uploaded:
            <span class="font-weight-bold text-dark">
              {{ managerFile.createdDate | date: "dd-MMM-yyyy hh:mm:ss a" }}</span
            >
          </p>
        </div>
      </div>

      <img
        *ngIf="managerFile?.photo"
        [src]="buildUserPhoto(managerFile) | authImage | async"
        alt="VIP"
        onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
        style="width: 100%"
      />
      <img *ngIf="!managerFile?.photo" src="https://via.placeholder.com/64x64?text=VIP" alt="VIP" />
    </div>
  </div>
</ng-template>
