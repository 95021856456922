import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { clear } from "console";

@Component({
  selector: "app-blank",
  templateUrl: "./blank.component.html",
  styleUrls: ["./blank.component.less"],
})
export class BlankComponent implements OnInit {
  // private canvas: HTMLCanvasElement;
  // private context: CanvasRenderingContext2D;
  private paint: boolean;

  private clickX: number[] = [];
  private clickY: number[] = [];
  private clickDrag: boolean[] = [];

constructor() { }

  @ViewChild('myCanvas', { static: true })
  myCanvas: ElementRef<HTMLCanvasElement>;

  public ctx: CanvasRenderingContext2D;

  ngOnInit(): void { 
    this.ctx = this.myCanvas.nativeElement.getContext('2d');

    let image = new Image();
    image.src = '../../../assets/images/smiley.gif'
    this.ctx.drawImage(image, 200, 70, 15, 15);

    // this.ctx.fillStyle = 'red';
    // this.ctx.fillRect(295, 145, 5, 5);

    //this.redraw();
    //this.createUserEvents();
  }

  animate(): void {
    // this.ctx.fillStyle = 'red';
    // const square = new Square(this.ctx);
    // square.draw(5, 1, 20);
  }

  playRandom(): void {
    let image = new Image();
    image.src = '../../../assets/images/smiley.gif'

    // this.ctx.fillStyle = 'yellow';
    const square = new Square(this.ctx);

    let width = this.myCanvas.nativeElement.offsetWidth;
    let height = this.myCanvas.nativeElement.offsetHeight;

    let x = Math.floor(Math.random() * (285));
    let y = Math.floor(Math.random() * (135));
    square.drawImage(image, x, y, 15, 15);

    let a = Math.floor(Math.random() * (285));
    let b = Math.floor(Math.random() * (135));
    square.drawImage(image, a, b, 15, 15);

    let c = Math.floor(Math.random() * (285));
    let d = Math.floor(Math.random() * (135));
    square.drawImage(image, c, d, 15, 15);
  }

  clear(){
    this.ctx.clearRect(0,0, this.myCanvas.nativeElement.width, this.myCanvas.nativeElement.height);
  }
}



export class Square {
  constructor(private ctx: CanvasRenderingContext2D) {}
  
  drawImage(image: any, x: number, y: number, xx: number, yy: number){
    this.ctx.drawImage(image, x, y, xx, yy);
  }

  draw(x: number, y: number, xx: number, yy: number) {
    this.ctx.fillRect(x, y, xx, yy);
  }
}
