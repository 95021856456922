
    <ngx-extended-pdf-viewer
    [base64Src]="imagePreview"
    backgroundColor="#ffffff"
    height="100%"
    [useBrowserLocale]="true"
    [handTool]="false"
    [showHandToolButton]="true"
>
</ngx-extended-pdf-viewer>
       
