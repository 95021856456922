<app-loader *ngIf="!(users && roles)"></app-loader>

<div id="content" class="flex" *ngIf="users && roles">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">List of Users</h2>
          <small class="text-highlight font-weight-normal font-italic">JuanPay list of staffs</small>
        </div>
        <div class="flex"></div>
        <div>
          <div (click)="createCompanyUser()" class="btn btn-md btn-info">
            <span class="d-none d-sm-inline mx-1">Create New</span>
            <i data-feather="arrow-right"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="page-content page-container" id="page-content">
      <div class="">
        <div class="card mb-2 p-1" style="background-color: #182038">
          <div class="row">
            <div class="col-sm-12 col-md-7"></div>
            <div class="col-sm-12 col-md-5">
              <form class="flex" [formGroup]="listFilterForm">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control form-control-theme form-control-sm search"
                    placeholder="Search..."
                    formControlName="searchString"
                    (keyup.enter)="getCompanyUsers(null, null, null, true)"
                    style="background-color: white; color: black"
                  />
                  <span class="input-group-append">
                    <button
                      class="btn btn-white no-border btn-sm"
                      type="button"
                      style="background-color: gainsboro"
                      (click)="getCompanyUsers(null, null, null, true)"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>

          <!-- Filters -->

          <!-- <ng-select2
            [(ngModel)]="filterBy"
            [data]="filterByOptions"
            [placeholder]="'Filter By'"
            [allowClear]="true"
          ></ng-select2> -->

          <!-- List Toolbar Form -->

          <!-- Search Button -->
        </div>

        <div class="card mt-2">
          <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
            <thead>
              <tr>
                <th data-sortable="true" data-field="owner">Avatar</th>
                <th data-sortable="true" data-field="id">
                  <div [ngClass]="employeeId" (click)="setPageSorting('employeeId')">Employee ID</div>
                </th>
                <th data-sortable="true" data-field="owner">
                  <div [ngClass]="username" (click)="setPageSorting('username')">Username</div>
                </th>
                <th data-sortable="true" data-field="owner">
                  <div [ngClass]="email" (click)="setPageSorting('email')">Email</div>
                </th>
                <th data-sortable="true" data-field="project">
                  <div [ngClass]="firstName" (click)="setPageSorting('firstName')">First Name</div>
                </th>
                <th data-sortable="true" data-field="project">
                  <div [ngClass]="lastName" (click)="setPageSorting('lastName')">Last Name</div>
                </th>
                <th data-sortable="true" data-field="project">
                  <div [ngClass]="roleName" (click)="setPageSorting('roleName')">Role Name</div>
                </th>
                <th data-field="finish"><span class="d-none d-sm-block">Status</span></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let user of users?.data
                    | paginate: { itemsPerPage: 10, currentPage: p, totalItems: users.totalRecords }
                "
                (click)="viewCompanyUserProfile(user?.id)"
                style="cursor: pointer"
                class="odd"
                role="row"
              >
                <td>
                  <a>
                    <span>
                      <img
                        class="w-32 avatar"
                        *ngIf="user.companyUser?.photo"
                        [src]="buildUserPhoto(user) | authImage | async"
                      />
                      <img
                        class="w-32 avatar"
                        *ngIf="!user.companyUser?.photo"
                        src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/fileebd27018-2029-4596-8c77-0ecd48f3c7e3.png"
                        alt="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/fileebd27018-2029-4596-8c77-0ecd48f3c7e3.png"
                      />
                    </span>
                  </a>
                </td>
                <td>
                  <small class="">{{ user.companyUser?.employeeId }}</small>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ user?.username }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ user?.email }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ user.companyUser?.firstName }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ user.companyUser?.lastName }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ user.role.name }}
                  </div>
                </td>
                <td class="flex" style="max-width: 75px">
                  <div class="item-except text-sm h-1x">
                    <label *ngIf="user.companyUser?.status == true" class="ui-switch ui-switch-sm dark mt-1 mr-2">
                      <input type="checkbox" checked disabled />
                      <i></i>
                    </label>

                    <label *ngIf="user.companyUser?.status != true" class="ui-switch ui-switch-sm dark mt-1 mr-2">
                      <input type="checkbox" disabled />
                      <i></i>
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
              <div class="col-sm-12 col-md-12">
                <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                  Showing {{ (users?.pageNumber - 1) * users?.pageSize + 1 }} to
                  {{ (users?.pageNumber - 1) * users?.pageSize + users?.data?.length }} of
                  {{ users?.data?.length }} entries
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-7" style="float: right">
            <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
              <ul class="pagination" style="float: right">
                <pagination-controls (pageChange)="getCompanyUsers(buildPageUrl($event, 10))"></pagination-controls>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>
