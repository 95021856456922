import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChatService } from 'src/app/chat.service';
import { ManagerUsersService } from 'src/data/services/web/manager-users.service';
import { UserService } from 'src/data/services/web/user.service';

@Component({
  selector: 'app-migration-sales-list',
  templateUrl: './migration-sales-list.component.html',
  styleUrls: ['./migration-sales-list.component.less']
})
export class MigrationSalesListComponent implements OnInit {

  
  messageFormGroup : FormGroup;
  emptyString : any = '';
  migrationSalesList : any;
  migrationSalesListNeed : any;
  loader : any = true;

  constructor(private usersService: ManagerUsersService,
    private modService: NgbModal) { }

  ngOnInit(): void {
 
    this.getMigrationSalesList();
    // this.getMigrationSalesListNeed();

  }

  
  getMigrationSalesList() {
    this.usersService.getMigrationSalesList().subscribe((result) => {
      this.migrationSalesList = result;
      this.loader = false;
    });
  }

  // getMigrationSalesListNeed() {
  //   this.usersService.getNeedMigrationSalesList().subscribe((result) => {
  //     this.migrationSalesListNeed = result;
  //     //console.log(this.migrationSalesListNeed);
  //     this.loader = false;
  //   });
  // }

  
  modalMessageTransaction;
  mobileNo1;
  username;
  openSendMessage(messageModal,username) {
    debugger
    this.username = username;
    this.modalMessageTransaction = this.modService.open(messageModal, {
      centered: true,
      size: "xl",
      backdrop: "static",
      keyboard: false,
    });
  }

  closeModal(){
    this.modalMessageTransaction.close();
  }
}

