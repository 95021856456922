import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CryptoService } from 'src/data/crypto/crypto.service';
import { Data } from 'src/data/data';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { ProductCategory } from 'src/data/models/product_category.model';
import { idLocale } from 'ngx-bootstrap/chronos';
import { CurrencyPipe } from '@angular/common';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

interface Category {
  categories: string,
  products: string,
}

@Component({
  selector: 'app-vips-store-products',
  templateUrl: './vips-store-products.component.html',
  styleUrls: ['./vips-store-products.component.less']
})
export class VipsStoreProductsComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalServe: NgbModal,
    private localStorageCacheService: LocalStorageCacheService,
    private cryptoService: CryptoService,
    private data: Data,
    private store: AngularFirestore,
    private currencyPipe: CurrencyPipe
  ) {

  }

  productCategory: ProductCategory
  merchant: any;
  mobileNo: any;
  categories: any;
  products: any;
  hours: any;
  selectedProduct: any;
  selectedCategory: any;
  selectedOption: any;
  username: any;

  ngOnInit(): void {
    this.username = this.localStorageCacheService.getStorage("username");
    this.route.params.subscribe((params) => {
      const id = decodeURI(params.id || "");
      this.mobileNo = this.cryptoService.get(this.data.authentication.privateKey, id);

      this.initMerchant(this.mobileNo);
      this.initCategories(this.mobileNo);
      this.initStorehours(this.mobileNo);
    });

  }

  async initMerchant(mobileNo: any){
    await this.store.collection("business_registration").doc(mobileNo).valueChanges().subscribe((val) => {
      this.merchant = val;
    });
  }

  id: number;
  dataa : string;
  recipes: Array<{id: string, dataa: number}> = []
  //recipes: any = [];
  async initCategories(mobileNo: any){
    var productlist = [];
    await this.store.firestore.collection("business_registration").doc(mobileNo).collection("categories_for_review")
    .get()
    .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          productlist.push({ docId : doc.id, docData: doc.data(), products: [] });
        });
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });

    this.products = productlist;

    for(var x = 0; this.products.length > x; x++){
      await this.getProducts(mobileNo, this.products[x].docId)
      this.products[x].products = this.currentProducts;
    }
  }

  async initStorehours(mobileNo: any){
    var productlist = [];
    await this.store.firestore.collection("business_registration").doc(mobileNo).collection("operating_hours").orderBy("ref_no", "asc")
    .get()
    .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          productlist.push({ docId : doc.id, docData: doc.data(), hours: [] });
        });
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });

    this.hours = productlist;

    for(var x = 0; this.products.length > x; x++){
      await this.getProducts(mobileNo, this.products[x].docId)
      this.products[x].products = this.currentProducts;
    }
  }

  currentProducts: any;
  async getProducts(mobileNo: any, categoryId: any) {
    var prod = [];
    await this.store.firestore.collection("business_registration").doc(mobileNo).collection("categories_for_review").doc(categoryId).collection("products").orderBy("base_price", "asc")
    .get()
    .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          prod.push({ docId : doc.id, docData: doc.data(), products: [] });
        });
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });
    this.currentProducts = prod;
  }



  async viewProduct(productDocId: any, categoryDocId, product){
    this.selectedProduct = product;
    this.selectedCategory = categoryDocId;
    var productlist = [];
    await this.store.firestore.collection("business_registration")
    .doc(this.mobileNo).collection("categories_for_review")
    .doc(categoryDocId).collection("products")
    .doc(productDocId).collection("options")
    .get()
    .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          productlist.push({ docId : doc.id, docData: doc.data(), choices: [] });
        });
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });

    this.selectedOption = productlist;
    for(var x = 0; this.selectedOption.length > x; x++){
      await this.getChoices(this.selectedOption[x].docId, productDocId, categoryDocId)
      this.selectedOption[x].choices = this.currentOption;
    }
  }

  currentOption: any;
  async getChoices(docId: any, optionDocId: any, categoryDocId) {
    var prod = [];
    await this.store.firestore.collection("business_registration")
    .doc(this.mobileNo).collection("categories_for_review")
    .doc(categoryDocId).collection("products")
    .doc(optionDocId).collection("options")
    .doc(docId).collection("choices").orderBy("add_price", "asc")
    .get()
    .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          prod.push({ docId : doc.id, docData: doc.data() });
          //console.log("Choices: "+ doc.id, " => ", doc.data());
        });
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });

    this.currentOption = prod;
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  displayPrice(money: any) {
    var price = money - (money * 0.2);
    return price;
  }

















  remarksStr: any;

  rejectStore(id: any){
    debugger
    this.store
      .firestore
      .collection('business_registration')
      .doc(id)
      .set({
        application_store_status: 2,
        remarks: this.remarksStr
      }
      , {
        merge: true,
      });

      this.rejectModal.close();
      this.modalServe.dismissAll();
  }

  approveStore(mobileNo: any){
    debugger
    this.store
      .firestore
      .collection('business_registration')
      .doc(mobileNo)
      .set({
        application_store_status: 1
      }, {
        merge: true,
    });

    this.verifyModal.close();
    this.modalServe.dismissAll();
  }

  processVerification(status: any){
    if(status){
      this.approveStore(this.mobileNo);
    } else {
      this.rejectStore(this.mobileNo);
    }
  }

  remarkModal: NgbModalRef;
  openRemark(content){
    this.remarkModal = this.modalServe.open(content, { centered: true, size: "md" });
  }

  verifyModal: NgbModalRef;
  openVerify(content){
    this.verifyModal = this.modalServe.open(content, { centered: true, size: "md" });
  }

  rejectModal: NgbModalRef;
  openReject(content){
    this.rejectModal = this.modalServe.open(content, { centered: true, size: "md" });
  }

  activeIndex: number = 0; // The accordion at index 0 will be open by default

  toggleClass(i: number): void {
    this.activeIndex = i;
  }

  remarksText : any;
  onSearchChange(val: any, prodId: any, catId: any ): void {
    // alert("Val: " + val + "\nprodId: " + prodId + "\ncatId: " + catId);

    this.store
      .firestore
      .collection('business_registration')
      .doc(this.mobileNo).collection("categories_for_review")
      .doc(catId).collection("products")
      .doc(prodId)
      .set({
        product_remarks: val,
        last_updated_by: this.username,
        last_review_date: Date.now()
      }, {
        merge: true,
    });
    //window.location.reload();
    this.clearRemarks();
    PNotify.success({
      title: "Remarks successfully submitted",
      // text: "Username is required.",
    });
  }

  clearRemarks(){
    this.selectedCategory = [];
    this.remarksStr = "";
    this.remarkModal.close();
  }
}

