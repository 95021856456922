import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { InvoiceComponent } from 'src/app/elements/invoice/invoice.component';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { PrintService } from 'src/data/services/web/print.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { TransactionService } from 'src/data/services/web/transaction.service';
import { UgiService } from 'src/data/services/web/ugi.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-ugi-transaction',
  templateUrl: './ugi-transaction.component.html',
  styleUrls: ['./ugi-transaction.component.less']
})
export class UgiTransactionComponent implements OnInit {

  @ViewChild(InvoiceComponent ) child: InvoiceComponent ; 
  @ViewChild('vipLogo') vipLogo: ElementRef;

  @Input() requestId: string;
  public data :  any;
  public loader : boolean = false;
  public receipt: any;
  public isA4 = new FormControl();
  @Output("closeModal") closeModal = new EventEmitter();
  roleLevel : any;
  public isTicketActive : any = false;

  public transactionService: TransactionService;
  public dti: any;
  public tin: any;
  public address: any;
  
  constructor(private ugiService : UgiService, 
    private router: Router,
    private localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    public datepipe: DatePipe,
    public printService: PrintService,
    private webService: WebSettingsService, transactionService: TransactionService) {
    this.transactionService = transactionService;
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.loader = true;
    this.getTransaction();
    this.initReceipt();
    this.roleLevel = this.localStorageCacheService.getStorage("role_level");
  }

  openTicket(){

    this.isTicketActive = true;
  }


  initReceipt() {
    this.webService.getWebSettingsByIdentifier("INVOICE_RECEIPT").subscribe((result) => {
      this.receipt = result.data;
    });
  }

  getTransaction() {
    this.ugiService.getTransactionSingle(this.requestId).subscribe((billers) => {
       this.data = billers;
       this.loader = false;
       this.initReceiptDetails(billers.transactByUser.cashierUser.managerUserId);
    }, error => {
      this.loader = false;
    });
  }

  initReceiptDetails(managerUserId: any){
    this.transactionService.getReceiptDetails(managerUserId).subscribe((result) => {
      this.dti = result.dti;
      this.tin = result.tin != null ? result.tin : "***-***-***-***";
      this.address = result.address;
    });
  }


  buildUserPhoto(user: any): any {
    if(user){
    return user.photoFormat + "," + user.photo;
    }
  }

  printReceipt(){

    var completedDate = this.datepipe.transform(this.data.completedDate, 'MMM-dd-yyyy');
    var transTime = this.datepipe.transform(this.data.completedDate, 'hh:mm:ss a');
    var reqId = this.data.requestId;
    //var recValNo = this.data.responseJsonData.data.meta.ReceiptValidationNo;
    var cashier = this.data.transactByUser.cashierUser.firstName + ' ' + this.data.transactByUser.cashierUser.lastName;
    var billerName = this.data.productName;
    var refNo = this.data.billerPartnerRefNo;
    var can = this.data.mobileNumber;
    //var peyeeContact = this.data.billerData.payeeContactNumber;
    var billAmount = (parseFloat(this.data.loadAmount)).toString(); 
    var amountPaid = this.data.loadAmount;
    var fee = this.data.billerFee;
    //var transNo = this.data.responseJsonData.data.meta.TransactionNo;
    var AccountNo = this.data.mobileNumber;
    var billerCode = this.data.productCode;
    var vipLogo = this.vipLogo.nativeElement.innerHTML;
    var responseJson = JSON.parse(this.data.responseJson);
    var refJson = JSON.parse(responseJson);
    var serviceref = refJson.referenceNumber;
    //console.log(serviceref);

    if(this.isA4.value){
      var Newstr = (this.receipt[1].value).replace("{{Date}}", completedDate);
      Newstr = (Newstr).replace("{{Time}}", transTime);
      Newstr = (Newstr).replace("{{Cashier}}", cashier);

      Newstr = (Newstr).replace("{{RefNo}}", refNo);
      Newstr = (Newstr).replace("{{RecValNo}}", "");
      Newstr = (Newstr).replace("{{TransactionNo}}", "");
      Newstr = (Newstr).replace("{{PayeeContact}}", "");
      
      Newstr = (Newstr).replace("{{AccountNumber}}", AccountNo);
      Newstr = (Newstr).replace("{{BillerCode}}", billerCode);
      Newstr = (Newstr).replace("{{BillerName}}", billerName);
      Newstr = (Newstr).replace("{{BillAmount}}", parseFloat(billAmount).toFixed(2));
      Newstr = (Newstr).replace("{{AmountPaid}}", parseFloat(amountPaid).toFixed(2));
      Newstr = Newstr.replace("{{receiptLogo}}", vipLogo);
      Newstr = Newstr.replace("{{dti}}", this.dti);
      Newstr = Newstr.replace("{{tinNo}}", this.tin);
      Newstr = Newstr.replace("{{address}}", this.address);
      
      this.PrintReceipt(Newstr);
      } else {
        var Newstr = (this.receipt[0].value).replace("{{Date}}", completedDate);
        Newstr = (Newstr).replace("{{Time}}", transTime);
        Newstr = (Newstr).replace("{{RecID}}", reqId);
        Newstr = (Newstr).replace("{{ReferenceName}}", "Rerefence No.");
        Newstr = (Newstr).replace("{{ServiceRef}}", serviceref);
        Newstr = (Newstr).replace("{{Cashier}}", cashier);
        Newstr = (Newstr).replace("{{BillerName}}", billerName);
        Newstr = (Newstr).replace("{{RefNo}}", refNo);
        Newstr = (Newstr).replace("{{CAN}}", can);
        Newstr = (Newstr).replace("{{PayeeContact}}", "");
        Newstr = (Newstr).replace("{{BillAmount}}", parseFloat(billAmount).toFixed(2));
        Newstr = (Newstr).replace("{{billerFee}}", 0.00);
        Newstr = (Newstr).replace("{{AmountPaid}}", parseFloat(amountPaid).toFixed(2));
        Newstr = Newstr.replace("{{receiptLogo}}", vipLogo);
        Newstr = Newstr.replace("{{dti}}", this.dti);
        Newstr = Newstr.replace("{{tinNo}}", this.tin);
        Newstr = Newstr.replace("{{address}}", this.address);
 
     this.PrintReceipt(Newstr);
   }
  }

  PrintReceipt(Newstr: any)
  {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write(Newstr);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    return true;
  }


  rejectTransaction(){
    this.loader = true;
    this.ugiService.rejectTransaction(this.requestId).subscribe((billers) => {
      PNotify.success({
        title: "Failed Status",
        text: "Transaction successfully rejected.",
      });
      this.loader = false;
      this.closeModal.emit();
   }, error => {
    PNotify.error({
      title: "Failed",
      text: "There's a problem occured. Please contant ACM support.",
    });
     this.loader = false;
   });

  }

  completeTransaction(){
    this.loader = true;
    this.ugiService.completeTransaction(this.requestId).subscribe((billers) => {
      PNotify.success({
        title: "Information",
        text: "Transaction successfully completed.",
      });
      this.loader = false;
      this.closeModal.emit();
   }, error => {
    PNotify.error({
      title: "Failed",
      text: "There's a problem occured. Please contant ACM support.",
    });
     this.loader = false;
   });

  }

 

  reProcessPayment(){
    this.loader = true;
   // return;
    this.ugiService.reProcessLoad(
      this.data.requestJson,
      this.data.mobileNumber,
      this.requestId)
      .subscribe((biller) => {
        PNotify.success({
          title: "Payment Success",
          text: biller.reason,
        });
        this.loader = false;
        this.closeModal.emit();
      
    }, error => {

      if(error.error.status == 490){
        PNotify.error({
          title: "Payment Failed",
          text: error.error.reason,
        });
        this.loader = false;

      }else{
        PNotify.error({
          title: "Payment Failed",
          text: error.error.reason,
        });
        this.loader = false;
        this.closeModal.emit();

      }

    });
}

}
