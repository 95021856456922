<div id="bottombar--footer" class="d-flex p-2 fixed-bottom">
  <div class="flex">
    <p class="text-sm text-muted text--footer">
      Copyright. &copy;
      <span class="text-danger TM-logo">ACM GROUP OF COMPANIES</span> is a payment operator licensed by the Bangko
      Sentral ng Pilipinas.
    </p>
  </div>
  <div class="text-sm text-muted text--version">Version 2.0.3</div>
</div>
<div id="chatButton"></div>
