import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from "@angular/core";

@Directive({
  selector: "[click-outside]",
})
export class ClickOutsideDirective {
  @Input("click-outside") activateClickOutside: boolean;
  @Output("on-click-outside") onClickEmitter: EventEmitter<boolean>;

  private nativeElement: HTMLElement;

  constructor(elementRef: ElementRef) {
    this.nativeElement = elementRef.nativeElement;
    this.onClickEmitter = new EventEmitter<boolean>();
  }

  @HostListener("document: click", ["$event.target"])
  public onClick(eventTarget: EventTarget): void {
    if (this.activateClickOutside) {
      if (!this.nativeElement.contains(<Node>eventTarget)) {
        this.onClickEmitter.emit(true);
      }
    }
  }
}
