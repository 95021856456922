import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Select2OptionData } from "ng-select2";
import { BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { CompanyUsersService } from "src/data/services/web/company-users.service";
import { RolesService } from "src/data/services/web/roles.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-company-user-list",
  templateUrl: "./company-user-list.component.html",
  styleUrls: ["./company-user-list.component.less"],
})
export class CompanyUserListComponent implements OnInit {
  public companyUserFilterFormGroup: FormGroup;
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public companyUsersService: CompanyUsersService;
  public emptyString = "";
  public roles: any;
  public role: any;
  public statuses: any;
  public status: any;
  public modalService: BsModalService;

  public accessToken: string;
  public newCompanyUser: boolean;
  public users: any;
  public pageCount: any;

  // Filters
  public listFilterForm = new FormGroup({
    searchString: new FormControl(""),
    searchStringField: new FormControl(""),
  });
  public filterByOptions: Array<Select2OptionData>;
  public filterBy: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    companyUsersService: CompanyUsersService,
    modalService: BsModalService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.companyUsersService = companyUsersService;
    this.modalService = modalService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
  }

  ngOnInit(): void {
    this.rolesService.getRoles(1).then((roles) => {
      this.roles = roles;
      this.getCompanyUsers();
    });

    // this.listFilterForm = new FormGroup({
    //   searchString: new FormControl(""),
    //   searchStringField: new FormControl(""),
    // });

    this.filterByOptions = [
      {
        id: "",
        disabled: false,
        text: "",
      },
      {
        id: "employeeID",
        text: "Employee ID",
      },
      {
        id: "username",
        text: "Username",
      },
      {
        id: "email",
        text: "Email",
      },
      {
        id: "firstName",
        text: "First Name",
      },
      {
        id: "lastName",
        text: "Last Name",
      },
      {
        id: "roleName",
        text: "Role Name",
      },
    ];
  }

  private _sortBy: any;
  private _sort: any;

  getCompanyUsers(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
 
    if (fromSearch) {
      this.employeeId = this.emptyString;
      this.username = this.emptyString;
      this.email = this.emptyString;
      this.firstName = this.emptyString;
      this.lastName = this.emptyString;
      this.roleName = this.emptyString;
    }

  
    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_searchString: string = this.listFilterForm.get("searchString").value
      ? `searchString=${this.listFilterForm.get("searchString").value}&`
      : "";
    const queryString_filterBy: string = this.filterBy ? `filterBy=${this.filterBy}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_searchString + queryString_filterBy;

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/companyusers?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/companyusers${queryString}`;
      }
    }

    this.companyUsersService.getCompanyUsers(endPointUrl).subscribe((result) => {
    
      if (result.data.length == 0 && result.pageNumber != 1) {
        this.getCompanyUsers(result.previousPage, _sortBy, _sort);
      } else {
        this.users = result;
      }
    });
  }

  employeeId = "";
  username = "";
  email = "";
  firstName = "";
  lastName = "";
  roleName = "";
  searchString = "";

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.employeeId = field == "employeeId" ? "th-inner sortable both " + sortBy : "";
    this.username = field == "username" ? "th-inner sortable both " + sortBy : "";
    this.email = field == "email" ? "th-inner sortable both " + sortBy : "";
    this.firstName = field == "firstName" ? "th-inner sortable both " + sortBy : "";
    this.lastName = field == "lastName" ? "th-inner sortable both " + sortBy : "";
    this.roleName = field == "roleName" ? "th-inner sortable both " + sortBy : "";
   
    this.getCompanyUsers(
      `${environment.API_URL}/api/companyusers?pageNumber=${page ? page : this.users.pageNumber}`,
      this._sortBy,
      this._sort
    );
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.users?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }

  // tslint:disable-next-line: variable-name
  getRole(role_id: any): string {
    if (role_id) {
      return this.roles.filter((x) => x.id === role_id)[0].name;
    } else {
      return "No Role";
    }
  }
p : Number =1;
  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p=pageNumber;
    return `${environment.API_URL}/api/companyusers?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  buildUserPhoto(user: any): any {
    return user.companyUser.photoFormat + "," + user.companyUser.photo;
  }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }

  viewCompanyUserProfile(id: any): void {
    
    const encId = this.cryptoService.set(this.data.authentication.privateKey, id);
    this.router.navigate(["acm", "company-user-view", { id: encId }]);
  }

  editCompanyUserProfile(id: any): void {
 
    const encId = this.cryptoService.set(this.data.authentication.privateKey, id);
    this.router.navigate(["acm", "company-user-edit", { id: encId }]);
  }

  createCompanyUser(): void {
    this.router.navigate(["acm", "company-user-create"]);
  }
}

interface ListFilter {
  searchString: string; // The string to search
  searchStringField: string; // The field to search
}
