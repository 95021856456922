<!-- ############ Content START-->
<div id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">Dashboard</h2>
          <small class="text-highlight font-weight-normal font-italic">Welcome to your Dashboard page!</small>
        </div>
        <!-- <div class="flex"></div> -->
        <!-- <div>
          <a
            href="https://themeforest.net/item/basik-responsive-bootstrap-web-admin-template/23365964"
            class="btn btn-md text-muted"
          >
            <span class="d-none d-sm-inline mx-1">Buy this Item</span>
            <i data-feather="arrow-right"></i>
          </a>
        </div> -->
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="padding pt-0">
        <div *ngIf="roleLevelId == 3 || roleLevelId == 2">
          <div class="col-md-12 d-flex p-0">
            <img
              src="https://juanpaycdn.azureedge.net/juanpayassets/loginbgLandscape.png"
              style="width: 100%"
              class="img-app"
            />
          </div>

          <!-- <div id="carouselCaptions" class="carousel slide" data-ride="carousel">
            
            <div class="carousel-inner">
                <div class="carousel-item">
                    <div class="media media-16x9 gd-primary">
                        <div class="media-content" style="background-image: url('https://i.ibb.co/cNY5HyS/230606220-522838955490818-7412035284563395118-n.jpg');"></div>
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselCaptions" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselCaptions" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div> -->
        </div>
        <div *ngIf="roleLevelId == 2" class="row row-sm" style="margin-top: 15px">
          <!-- <div class="col-md-12 d-flex">
            <a class="" routerLink="/manager/market-place">
              <img
                src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file83b37f91-18ce-48d7-983c-cdb9387bde8b.png"
                class="img-app"
              />
            </a>
          </div> -->

          <div class="col-md-4 d-flex">
            <div
              class="card flex"
              data-sr-id="11"
              style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s"
            >
              <div class="card-body">
                <div class="row row-sm">
                  <div class="col-sm-12">
                    <div class="mb-2">
                      <small class="text-muted">Marketplace</small>
                    </div>
                    <div class="row row-sm" style="text-align: center">
                      <div class="col-4">
                        <div class="text-success text-md">{{ applicationData?.active }}</div>
                        <small>Installed</small>
                      </div>
                      <div class="col-4">
                        <div class="text-danger text-md">{{ applicationData?.app - applicationData?.active }}</div>
                        <small>Uninstalled</small>
                      </div>
                      <div class="col-4">
                        <div class="text-primary text-md">{{ applicationData?.soon }}</div>
                        <small>Coming Soon</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4 d-flex">
            <div
              class="card flex"
              data-sr-id="10"
              style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s"
            >
              <div class="card-body">
                <div class="d-flex align-items-center text-hover-success">
                  <div class="avatar w-56 m-2 no-shadow gd-success">
                    <span class="fa fa-unlock"></span>
                  </div>
                  <div class="px-4 flex">
                    <div>Last Login</div>
                    <div class="text-success mt-2">
                      {{
                        applicationData?.lastlogins == null
                          ? "No Recent Logs"
                          : (applicationData?.lastlogins[0].createdDate | date: "dd-MMM-yyyy hh:mm:ss a")
                      }}
                    </div>
                  </div>
                  <!-- <a href="" class="text-muted">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                      </a> -->
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4 d-flex">
            <div
              class="card flex"
              data-sr-id="10"
              style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s"
            >
              <div class="card-body">
                <div class="d-flex align-items-center text-hover-success">
                  <div class="avatar w-56 m-2 no-shadow gd-warning">
                    <span class="fa fa-user-plus"></span>
                  </div>
                  <div class="px-4 flex">
                    <div>Last Allocated Fund</div>
                    <div class="text-primary mt-2">
                      {{
                        applicationData?.lastAllocatedFund == null
                          ? "No Transaction"
                          : (applicationData?.lastAllocatedFund[0].amount | number: "1.2-2")
                      }}
                    </div>
                  </div>
                  <!-- <a href="" class="text-muted">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-arrow-right"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                      </a> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="roleLevelId == 1 && roleId != 10 && roleId != 12 && roleId != 19" class="row row-sm">
          <div class="col-md-3 d-flex">
            <div
              class="card flex"
              data-sr-id="10"
              style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s"
            >
              <div class="card-body">
                <div class="d-flex align-items-center text-hover-warning">
                  <div class="avatar w-56 m-2 no-shadow gd-warning">SMS</div>
                  <div class="px-4 flex">
                    <div>Balance</div>
                    <div class="text-warning mt-1">
                      <strong> {{ smsBalance }} </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3 d-flex">
            <div
              class="card flex"
              data-sr-id="10"
              style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s"
            >
              <div class="card-body">
                <div class="d-flex align-items-center text-hover-success">
                  <div class="avatar w-56 m-2 no-shadow gd-success">ECPAY</div>
                  <div class="px-4 flex">
                    <div>Balance</div>
                    <div class="text-success mt-1">
                      <strong> {{ ecPayBalance | number: "1.2-2" }} </strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3 d-flex">
            <div
              class="card flex"
              data-sr-id="10"
              style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s"
            >
              <div class="card-body">
                <div class="d-flex align-items-center text-hover-success">
                  <div class="avatar w-56 m-2 no-shadow gd-info">CTI-AT</div>
                  <div class="px-4 flex">
                    <div>Balance</div>
                    <div class="text-success mt-1">
                      <strong> {{ ctiBalance | number: "1.2-2" }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3 d-flex">
            <div
              class="card flex"
              data-sr-id="10"
              style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s"
            >
              <div class="card-body">
                <div class="d-flex align-items-center text-hover-success">
                  <div class="avatar w-56 m-2 no-shadow gd-primary">CTI-PIN</div>
                  <div class="px-4 flex">
                    <div>Balance</div>
                    <div class="text-primary mt-1">
                      <strong> {{ ctiPinsBalance | number: "1.2-2" }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3 d-flex">
            <div
              class="card flex"
              data-sr-id="10"
              style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s"
            >
              <div class="card-body">
                <div class="d-flex align-items-center text-hover-success">
                  <div class="avatar w-56 m-2 no-shadow gd-danger">IBAYAD</div>
                  <div class="px-4 flex">
                    <div>Balance</div>
                    <div class="text-danger mt-1">
                      <strong> {{ iBayadBalance | number: "1.2-2" }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3 d-flex">
            <div
              class="card flex"
              data-sr-id="10"
              style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s"
            >
              <div class="card-body">
                <div class="d-flex align-items-center text-hover-success">
                  <div class="avatar w-56 m-2 no-shadow gd-secondary">UGI</div>
                  <div class="px-4 flex">
                    <div>Balance</div>
                    <div class="text-secondary mt-1">
                      <strong> {{ ugiBalance | number: "1.2-2" }}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="col-md-3 d-flex">
    <div class="card flex" data-sr-id="10" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
        <div class="card-body">
            <div class="d-flex align-items-center text-hover-success">
                <div class="avatar w-56 m-2 no-shadow gd-warning">
                    N-PG
                </div>
                <div class="px-4 flex">
                    <div>Balance</div>
                    <div class="text-secondary mt-1">
                     <strong> {{paygramBalance?.netbank | number: "1.2-2" }}</strong>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
  </div>


  <div class="col-md-3 d-flex">
    <div class="card flex" data-sr-id="10" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
        <div class="card-body">
            <div class="d-flex align-items-center text-hover-success">
                <div class="avatar w-56 m-2 no-shadow gd-warning">
                    G-PG
                </div>
                <div class="px-4 flex">
                    <div>Balance</div>
                    <div class="text-secondary mt-1">
                     <strong> {{paygramBalance?.govip_paygram | number: "1.2-2" }}</strong>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
  </div>


  <div class="col-md-3 d-flex">
    <div class="card flex" data-sr-id="10" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
        <div class="card-body">
            <div class="d-flex align-items-center text-hover-success">
                <div class="avatar w-56 m-2 no-shadow gd-warning">
                    T-PG
                </div>
                <div class="px-4 flex">
                    <div>Balance</div>
                    <div class="text-secondary mt-1">
                     <strong> {{paygramBalance?.telegram_paygram == 'N/A' ? 'N/A' : paygramBalance?.telegram_paygram | number: "1.2-2" }}</strong>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
  </div> -->
        </div>

        <div *ngIf="roleLevelId == 2" class="row row-sm">
          <div class="col-md-12 d-flex">
            <div class="card flex">
              <app-chart-report
                title="Request Fund Daily Transaction"
                apiRoute="GetRequestFundTransactionsManager"
                hideBillers="true"
                [role]="roleLevelId"
              ></app-chart-report>
            </div>
          </div>

          <div class="col-md-12 d-flex">
            <div class="card flex">
              <app-chart-report
                title="Multisys Daily Transaction"
                apiRoute="GetMultiSysTransactionsManager"
                hideBillers="false"
                [role]="roleLevelId"
              ></app-chart-report>
            </div>
          </div>

          <div class="col-md-12 d-flex">
            <div class="card flex">
              <app-chart-report
                title="ECPay Bills Daily Transaction"
                apiRoute="GetECPayTransactionsManager"
                hideBillers="false"
                [role]="roleLevelId"
              ></app-chart-report>
            </div>
          </div>
          <div class="col-md-12 d-flex">
            <div class="card flex">
              <app-chart-report
                title="ECPay Remittance Daily Transaction"
                apiRoute="GetECCashTransactionsManager"
                hideBillers="false"
                [role]="roleLevelId"
              ></app-chart-report>
            </div>
          </div>

          <div class="col-md-12 d-flex">
            <div class="card flex">
              <app-chart-report
                title="ECPay Airtime Daily Transaction"
                apiRoute="GetECTopUpTransactionsManager"
                hideBillers="false"
                [role]="roleLevelId"
              ></app-chart-report>
            </div>
          </div>

          <div class="col-md-12 d-flex">
            <div class="card flex">
              <app-chart-report
                title="Smart E-Loading Daily Transaction"
                apiRoute="GetCTITransactionsManager"
                hideBillers="false"
                [role]="roleLevelId"
              ></app-chart-report>
            </div>
          </div>

          <div class="col-md-12 d-flex">
            <div class="card flex">
              <app-chart-report
                title="INSTAPAY Daily Transaction"
                apiRoute="GetInstapayTransactionsManager"
                hideBillers="true"
                [role]="roleLevelId"
              ></app-chart-report>
            </div>
          </div>

          <div class="col-md-12 d-flex">
            <div class="card flex">
              <app-chart-report
                title="PESONET Daily Transaction"
                apiRoute="GetPesonetTransactionsManager"
                hideBillers="true"
                [role]="roleLevelId"
              ></app-chart-report>
            </div>
          </div>

          <div class="col-md-12 d-flex">
            <div class="card flex">
              <app-chart-report
                title="VIP Protect Daily Transaction"
                apiRoute="GetVIPProtectTransactionsManager"
                hideBillers="true"
                [role]="roleLevelId"
              ></app-chart-report>
            </div>
          </div>

          <div class="col-md-12 d-flex">
            <div class="card flex">
              <app-chart-report
                title="Service Charge Daily Transaction"
                apiRoute="GetServiceChargeTransactionsManager"
                hideBillers="true"
                [role]="roleLevelId"
              ></app-chart-report>
            </div>
          </div>
        </div>

        <div *ngIf="roleLevelId == 1 && roleId != 10 && roleId != 12 && roleId != 19" class="row row-sm">
          <!-- ENTERPRISE -->

          <!-- <div class="col-md-12 d-flex">
      <div class="card flex"  >
      <app-chart-report title="Multisys Daily Transaction - ENTERPRISE"  apiRoute="GetEnterpriseTransactions" provider="MULTISYS" isEnterprise=true hideBillers=false  [role]="roleLevelId"></app-chart-report>
    </div>
    </div>

    <div class="col-md-12 d-flex">
      <div class="card flex"  >
      <app-chart-report title="ECPay Bills Daily Transaction - ENTERPRISE"  apiRoute="GetEnterpriseTransactions" provider="ECPAY-BILLS" isEnterprise=true hideBillers=false  [role]="roleLevelId"></app-chart-report>
    </div>
    </div>

    <div class="col-md-12 d-flex">
      <div class="card flex"  >
      <app-chart-report title="ECPay Remittance Daily Transaction - ENTERPRISE"  apiRoute="GetEnterpriseTransactions" provider="ECPAY-CASH" isEnterprise=true hideBillers=false  [role]="roleLevelId"></app-chart-report>
    </div>
    </div>

    <div class="col-md-12 d-flex">
      <div class="card flex"  >
      <app-chart-report title="ECPay Airtime Daily Transaction - ENTERPRISE"  apiRoute="GetEnterpriseTransactions" provider="ECPAY-TOPUP" isEnterprise=true hideBillers=false  [role]="roleLevelId"></app-chart-report>
    </div>
    </div>

    <div class="col-md-12 d-flex">
      <div class="card flex"  >
      <app-chart-report title="IBAYAD Daily Transaction - ENTERPRISE"  apiRoute="GetEnterpriseTransactions" provider="IBAYAD" isEnterprise=true hideBillers=false  [role]="roleLevelId"></app-chart-report>
    </div>
    </div> -->

          <!-- GOVIPCENTER -->

          <div class="col-md-12 d-flex">
            <div class="card flex">
              <app-chart-report
                title="Request Fund Daily Transaction"
                apiRoute="GetRequestFundTransactions"
                hideBillers="true"
                [role]="roleLevelId"
              ></app-chart-report>
            </div>
          </div>

          <div class="col-md-12 d-flex">
            <div class="card flex">
              <app-chart-report
                title="Multisys Daily Transaction"
                apiRoute="GetMultiSysTransactions"
                hideBillers="false"
                [role]="roleLevelId"
              ></app-chart-report>
            </div>
          </div>

          <div class="col-md-12 d-flex">
            <div class="card flex">
              <app-chart-report
                title="ECPay Bills Daily Transaction"
                apiRoute="GetECPayTransactions"
                hideBillers="false"
                [role]="roleLevelId"
              ></app-chart-report>
            </div>
          </div>
          <div class="col-md-12 d-flex">
            <div class="card flex">
              <app-chart-report
                title="ECPay Remittance Daily Transaction"
                apiRoute="GetECCashTransactions"
                hideBillers="false"
                [role]="roleLevelId"
              ></app-chart-report>
            </div>
          </div>

          <div class="col-md-12 d-flex">
            <div class="card flex">
              <app-chart-report
                title="ECPay Airtime Daily Transaction"
                apiRoute="GetECTopUpTransactions"
                hideBillers="false"
                [role]="roleLevelId"
              ></app-chart-report>
            </div>
          </div>

          <div class="col-md-12 d-flex">
            <div class="card flex">
              <app-chart-report
                title="Smart E-Loading Daily Transaction"
                apiRoute="GetCTITransactions"
                hideBillers="false"
                [role]="roleLevelId"
              ></app-chart-report>
            </div>
          </div>

          <div class="col-md-12 d-flex">
            <div class="card flex">
              <app-chart-report
                title="INSTAPAY Daily Transaction"
                apiRoute="GetInstapayTransactions"
                hideBillers="true"
                [role]="roleLevelId"
              ></app-chart-report>
            </div>
          </div>

          <div class="col-md-12 d-flex">
            <div class="card flex">
              <app-chart-report
                title="PESONET Daily Transaction"
                apiRoute="GetPesonetTransactions"
                hideBillers="true"
                [role]="roleLevelId"
              ></app-chart-report>
            </div>
          </div>

          <div class="col-md-12 d-flex">
            <div class="card flex">
              <app-chart-report
                title="VIP Protect Daily Transaction"
                apiRoute="GetVIPProtectTransactions"
                hideBillers="true"
                [role]="roleLevelId"
              ></app-chart-report>
            </div>
          </div>

          <div class="col-md-12 d-flex">
            <div class="card flex">
              <app-chart-report
                title="Service Charge Daily Transaction"
                apiRoute="GetServiceChargeTransactions"
                hideBillers="true"
                [role]="roleLevelId"
              ></app-chart-report>
            </div>
          </div>
        </div>

        <div
          *ngIf="roleLevelId != 1 && roleLevelId != 2"
          class="card col-lg-12"
          style="margin-top: 15px"
          [innerHtml]="dashboard"
        ></div>

        <div
          *ngIf="roleId == 10 || roleId == 12 || roleId == 19"
          class="card col-lg-12"
          style="margin-top: 15px"
          [innerHtml]="dashboard"
        ></div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->

  <ng-template *ngIf="roleLevelId != 1" #viewSplashScreeModal let-modal>
    <app-splash-screen (closeModal)="closeModal()"></app-splash-screen>
  </ng-template>

  <ng-template *ngIf="roleLevelId == 2" #viewStoreHoursSettingsModal let-modal>
    <div class="card">
      <div class="card-header">
        <h3 style="text-align: center">STORE HOURS</h3>
        <h5 style="text-align: center">Indicate your store's operating hours for a more manageable account</h5>
      </div>
      <div class="card-body">
        <app-store-hours-settings (closeModal)="closeSettingsModal()"></app-store-hours-settings>
      </div>
    </div>
  </ng-template>

  <ng-template #previewProductEventLeaderboard let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Product Event Leaderboard</h4>
      <button type="button" class="close" aria-label="Close" (click)="closePreviewProductEventLeaderboard()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-product-event-leaderboard></app-product-event-leaderboard>
    </div>
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Close</button>
    </div> -->
  </ng-template>
</div>
<!-- ############ Content END-->
