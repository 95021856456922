<!-- <div class="container">
  <div>
    <label for="municipality-filter">Filter by Municipality:</label>
    <select id="municipality-filter" name="municipality-filter" [(ngModel)]="selectedLocality" (ngModelChange)="filterByLocality()">
      <option value="">All</option>
      <option *ngFor="let municipality of municipalities" [value]="municipality">{{ municipality }}</option>
    </select>
  </div>

  <table class="table">
    <thead class="thead-light">
      <tr>
        <th scope="col">Store Name</th>
        <th scope="col">Locality</th>
        <th scope="col">Sub-Locality</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data;let i = index;">
        <td>{{ item?.business_name }}</td>
        <td>{{ item?.current_place_locality }}</td>
        <td>{{ item?.current_place_sub_locality }}</td>
      </tr>
    </tbody>
  </table>
</div>

 -->

 <!-- <div>
  <label for="municipalityDropdown">Select a municipality:</label>
  <select id="municipalityDropdown" [(ngModel)]="selectedLocality" (change)="getSubMunicipalityCount()" (change)="filterByLocality()">
    <option (click)="resetTable()" value="">All Locality</option>
    <option *ngFor="let locality of localities" [value]="locality">{{ locality }}</option>
  </select>
  <button (click)="resetTable()">Reset Table</button>
</div>


<div *ngIf="selectedLocality">
  <label for="sublocalityDropdown">Select a sub-municipality:</label>
  <select id="sublocalityDropdown" [(ngModel)]="selectedSublocality" (change)="filterBySublocality()">
    <option value="">All sub-municipalities</option>
    <option *ngFor="let subLocality of subLocalities" [value]="subLocality.name">{{subLocality.name}} ({{subLocality.count}})</option>
  </select>
</div>

<table>
  <thead>
    <tr>
      <th>Store Name</th>
      <th>Locality</th>
      <th>Sub-Locality</th>
      <th>Ano pa po ilalabas dito</th>
      <th>Ano pa po ilalabas dito</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of data">
      <td>{{item?.business_name}}</td>
      <td>{{item?.current_place_locality}}</td>
      <td>{{item?.current_place_sub_locality}}</td>
      <td></td>
      <td></td>
    </tr>
  </tbody>
</table> -->

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w==" crossorigin="anonymous" referrerpolicy="no-referrer" />
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css">
<div class="container-fluid" style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
  <div *ngIf="messagescss" class="alert alert-success" role="alert" style="position: absolute;
  top: 3%;
  right: 3%;
  padding: 20px;
  box-shadow: rgb(17 12 46 / 15%) 0px 48px 100px 0px;">
    Success! Locality has been updated.
  </div>
  <div class="container-fluid" style="padding-inline:10px; width: 95%;display: flex;justify-content: space-between; align-items: center;gap: 20px; margin-block: 5px; padding-right: 25px;">
    <div class="d-flex align-items-center header" style="gap: 15px;">
      <img src="../../../../../assets/vips/Vips_Merchants-Horizontal_01_PNG (1).png" width="170px" alt="">
      <h1 style="font-size: 24px; font-weight: 600;color: #5e676f;">Merchant Locality</h1>
  </div>
  <div class="search_s">
    <input type="text" class="search__input" placeholder="Search" [(ngModel)]="searchTerm" (input)="search()">
    <button class="search__button">
        <svg class="search__icon" aria-hidden="true" viewBox="0 0 24 24">
            <g>
                <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
            </g>
        </svg>
    </button>
  </div>
    <div class="search">
      <div style="align-items: center;
      display: flex;
      gap: 5px;">
        <label for="municipalityDropdown">Select locality:</label>
        <select id="municipalityDropdown" [(ngModel)]="selectedLocality" (change)="getSubMunicipalityCount()" (change)="filterByLocality()">
          <option (click)="resetTable()" value="">All Locality</option>
          <option *ngFor="let locality of localities" [value]="locality">{{ locality }}</option>
        </select>
        <span class="resetButton" (click)="resetTable()"><i class="bi bi-arrow-repeat"></i></span>
      </div>


      <div *ngIf="selectedLocality">
        <label for="sublocalityDropdown">Select sub-locality:</label>
        <select id="sublocalityDropdown" [(ngModel)]="selectedSublocality" (change)="filterBySublocality()">
          <option value="All">All sub-locality</option>
          <option *ngFor="let subLocality of subLocalities" [value]="subLocality.name">{{subLocality.name}} ({{subLocality.count}})</option>
        </select>
      </div>
  </div>
  </div>
  <div class="table" style="margin-top: 30px;width: 95%; max-height: 65vh !important;overflow-y: scroll;" scrollable >
      <table id="myTable" class="table table-striped">
          <thead class="w-100">
              <tr>
                  <th style="width: 340.59px;" class="fw-600">Business Name</th>
                  <th style="width: 340.59px;" class="fw-600">Owner's Name</th>
                  <th style="width: 340.59px;" class="fw-600">Mobile No.</th>
                  <th style="width: 262.05px;" class="fw-600">Locality</th>
                  <th style="width: 265.8px;" class="fw-600">Sub-Locality</th>
                  <th style="width: 101.35px;" class="fw-600">Date</th>
                  <!-- <th style="width: 110.03px;" class="fw-600">Status</th>
                  <th>
                </th> -->
                <th></th>
              </tr>
          </thead>
          <tbody *ngFor="let item of data || filterData | search: searchTerm; let i = index; ">
              <tr *ngIf="!item?.application_rejected && item?.timestamp" class="align-middle alert" role="alert">
                <td>
                  <div class="d-flex align-items-center" style="gap: 10px;">
                    <div class="img-container">
                        <img [src]="item?.business_store_logo"
                            alt="">
                    </div>
                    <div class="ps-3">
                        <div class="fw-400 pb-1">{{ item?.business_name }}</div>
                    </div>
                </div>
                </td>
                <td>
                  <div class="ps-3">
                    <div class="fw-400 pb-1">{{ item?.first_name + ' ' + item?.last_name }}</div>
                </div>
                </td>
                <td>
                  <div class="ps-3">
                    <div class="fw-400 pb-1">{{ item?.id }}</div>
                </div>
                </td>
                  <td>
                      <div class="fw-400 pb-1">{{item?.current_place_locality}}
                        <!-- <input style="
                      border: none;
                      box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;" *ngIf="locality_input" type="text" [(ngModel)]="localitystr"> -->
                      <i (click)="edit(Edit,item)" class="bi bi-pen"></i>&nbsp;
                      <!-- <i *ngIf="save" (click)="submitKms(selectedNumber)" class="bi text-success bi-check-circle"></i>&nbsp;
                      <i *ngIf="save" (click)="close()" class="bi text-danger bi-x-circle"></i> -->
                      </div>

                  </td>
                  <td>
                    <div class="fw-400">{{item?.current_place_sub_locality}}</div>
                  </td>
                  <td>
                    <div class="fw-400 text-muted" style="font-size: 12px;">{{ item?.timestamp?.toDate() | date:'MMM dd, yyyy h:mm a' }}</div>
                  </td>
                  <!-- <td>
                      <div class="d-inline-flex align-items-center active" *ngIf="!item.application_rejected">
                          <div class="circle"></div>
                          <div class="ps-2">Approved</div>
                      </div>
                      <div class="d-inline-flex align-items-center waiting" *ngIf="item?.application_rejected">
                        <div class="circle"></div>
                        <div class="ps-2 myDIV">Rejected</div>
                    </div>
                  </td> -->
                  <!-- <td>
                  </td> -->
                  <td></td>
              </tr>
          </tbody>
      </table>
  </div>
</div>

<ng-template #Edit let-modal>
  <form class="form_s" [formGroup]="localityFormGroup">
    Edit Locality
    <div class="card w-100">
      <div class="card-top-part">
        <div class="left-part">
          <div class="user-name mt-2">
            <p class="name h6 text-left">{{selectedItem?.business_name}}</p>
            <p class="role"> {{selectedItem?.business_food_type}} </p>
          </div>
          <div class="d-flex justify-content-between">
            <div class="user-position text-dark text-left h6">
              Locality:
              <p class="position">
                {{selectedItem?.current_place_locality}}
            </p>
            </div>
            <div class="user-position text-dark text-left h6">
              Sub-Locality:
              <p class="position">
                {{selectedItem?.current_place_sub_locality}}
            </p>
            <p *ngIf="!selectedItem?.current_place_sub_locality" class="position">
              NA
          </p>
            </div>
          </div>
        </div>
        <div class="right-part">
          <div class="user-photo">
            <img [src]="selectedItem?.business_store_logo" class="photo">
          </div>
        </div>
      </div>
      <div class="card-bottom-part">
        <div class="bottom-part">
          <a href="mailto: {{selectedItem?.email_address}}" class="link">
            <span class="icon">
              <svg viewBox="0 0 20 20" height="20" width="20" xmlns="http://www.w3.org/2000/svg" data-name="20" id="_20">
                <path transform="translate(1.25 3.75)" d="M16.25,12.5h-15A1.252,1.252,0,0,1,0,11.25v-10A1.252,1.252,0,0,1,1.25,0h15A1.251,1.251,0,0,1,17.5,1.25v10A1.251,1.251,0,0,1,16.25,12.5ZM1.25,1.819V11.25h15V1.819L9.106,6.763a.626.626,0,0,1-.713,0ZM2.625,1.25,8.75,5.487,14.875,1.25Z" id="Fill"></path>
              </svg>
            </span>
              Email
          </a>
        </div>
        <div class="bottom-part">
          <a href="tel: {{selectedItem?.id}}" class="link">
            <span class="icon">
              <svg viewBox="0 0 20 20" height="20" width="20" xmlns="http://www.w3.org/2000/svg" data-name="20" id="_20">
                <path transform="translate(1.869 1.875)" d="M14.381,16.25h-.106C2,15.544.249,5.179.006,2.019A1.874,1.874,0,0,1,1.731,0H5.175A1.243,1.243,0,0,1,6.337.787l.95,2.337a1.247,1.247,0,0,1-.275,1.35L5.681,5.818a5.875,5.875,0,0,0,4.738,4.75l1.356-1.344a1.25,1.25,0,0,1,1.356-.257l2.356.944a1.245,1.245,0,0,1,.769,1.163v3.3A1.877,1.877,0,0,1,14.381,16.25Zm-12.5-15a.625.625,0,0,0-.625.625v.05C1.545,5.648,3.4,14.375,14.343,15h.038a.625.625,0,0,0,.625-.589V11.075l-2.356-.944-1.794,1.781-.3-.038A6.733,6.733,0,0,1,5.429,8.553,8.171,8.171,0,0,1,4.381,5.7l-.038-.3L6.118,3.606,5.181,1.25Z" id="Fill"></path>
              </svg>
            </span>
              Phone
          </a>
        </div>
      </div>
    </div>
        <!-- <input formControlName="Newlocality" type="text" class="input_s" placeholder="New Locality" required> -->
        <!-- <input formControlName="Newsublocality" type="text" class="input_s" placeholder="New Sub-Locality" required> -->
        <label class="text-left" for="Newlocality">New Locality:</label>
        <p *ngIf="message" class="text-danger text-left h6">*required, please enter a valid locality or sub-locality.</p>
        <ng-select [items]="localities"
          [placeholder]="selectedItem?.current_place_locality"
          [parent-form-group]="localityFormGroup"
          bindLabel="text"
          bindValue="id"
          formControlName="Newlocality"
          class="custom"
          (clear)="onRemove()"
          (change)="getSubLocalities($event)"
          id="Newlocality"
          >
        </ng-select>

        <label class="text-left mt-3" for="Newsublocality">New Sub-Locality:</label>
        <ng-select [items]="_subLocalities"
          [placeholder]="selectedItem?.current_place_sub_locality"
          [parent-form-group]="localityFormGroup"
          bindLabel="name"
          bindValue="name"
          formControlName="Newsublocality"
          class="custom"
          id="Newsublocality"
          >
        </ng-select>

        <button (click)="submitLocality(selectedItem)">Submit</button>
    </form>
</ng-template>

<ng-template #confirmModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body align-self-start">
    Are you sure you want to update the locality?
  </div>
  <div class="modal-footer m-2">
    <button type="button" style="width: 50px;" class="btn btn-secondary" (click)="modal.dismiss('cancel')">Cancel</button>
    <button type="button" style="width: 50px;" class="btn btn-success" (click)="modal.close('confirm')">Yes</button>
  </div>
</ng-template>
