import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprise-dashboard',
  templateUrl: './enterprise-dashboard.component.html',
  styleUrls: ['./enterprise-dashboard.component.less']
})
export class EnterpriseDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
