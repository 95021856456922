import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select2OptionData } from 'ng-select2';
import { Data } from "src/data/data";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserService } from "src/data/services/web/user.service";
import { CryptoService } from 'src/data/crypto/crypto.service';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { AuthService } from 'src/data/services/authentication/auth.service';
import { ExportExcelService } from 'src/data/services/web/export-excel.service';
import { RolesService } from 'src/data/services/web/roles.service';
import { ServiceChargeService } from 'src/data/services/web/service-charge.service';
import { TransactionService } from 'src/data/services/web/transaction.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-service-charge-history',
  templateUrl: './service-charge-history.component.html',
  styleUrls: ['./service-charge-history.component.less']
})
export class ServiceChargeHistoryComponent implements OnInit {

  public transactionFilterFormGroup: FormGroup;
  public searchFormGroup: FormGroup;
  public formGroupSearch: FormGroup;

  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public transactionService: TransactionService;
  public websettings: WebSettingsService;
  public userService: UserService;
  public emptyString = "";
  public statuses: any;
  public status: any;
  public modalService: BsModalService;

  public accessToken: string;
  public newTransaction: boolean;
  public transactions: any = [];
  public transaction: any;
  public pageCount: any;
  public roleLevelId: any;

  public cashiersAndManagers: any;

  public processUpdate: any = false;

  public bsModalRefConfirmation: BsModalRef;
  public selectedUserId: any = 0;

  public userIdFilterOptions: Array<Select2OptionData>;
  public providersOption: Array<Select2OptionData>;
  public statusOPtion: Array<Select2OptionData>;
  public userIdFilter: any = [];
  public providerIdFilter: any = [];
  public statusIdFilter: any = [];
  public billerIDFilter: any = [];

  public showRowsOption: Array<Select2OptionData>;
  public showRowsFilter: any = [];

  public serviceChargeService: ServiceChargeService;

  public isDataNotNull = false;
  dataForExcel = [];

  public billerOption: Array<Select2OptionData>;
  public Transactions: any;

  public pageRows: any = 10;
  public selectedRowsId: any = 0;
  loader = false;

  p = 1;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    private currencyPipe: CurrencyPipe,
    public datepipe: DatePipe,
    private modalBService: NgbModal,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    transactionService: TransactionService,
    modalService: BsModalService,
    bsModalRefConfirmation: BsModalRef,
    userService: UserService,
    websettings: WebSettingsService,
    serviceChargeService: ServiceChargeService,
    public ete: ExportExcelService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.transactionService = transactionService;
    this.modalService = modalService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
    this.bsModalRefConfirmation = bsModalRefConfirmation;
    this.userService = userService;
    this.websettings = websettings;
    this.serviceChargeService = serviceChargeService;
  }
  userId;
  billers: any;
  billerCode: any = [];
  provider: any;
  ngOnInit(): void {
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.userId = this.roleLevelId == 1 ? 0 : this.localStorageCacheService.getStorage("userId");

    //this.initSearchByUsernameFormGroup();
    this.initSearchFormGroup();
    this.getCashiersAndManagers();
    
    
    //this.getTransaction();
  }

  getTransaction(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader =true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }
    const userId : string = this.searchFormGroup.controls.username.value;
    const requestId : string = this.searchFormGroup.controls.requestId.value;
    const dateRange : string = this.searchFormGroup.controls.dateRange.value;
     
    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_userId: string = userId ? `id=${userId}&` : "";
    const queryString_showRows: string = `showRows=10&`;
    const queryString_requestId: string = requestId ? `requestId=${requestId}&` : "";
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_requestId + queryString_dateRange + queryString_userId + queryString_showRows;
   
    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/ServiceCharge/Transactions?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/ServiceCharge/Transactions?${queryString}`;
      }
    }

    this.serviceChargeService.getTransactions(endPointUrl.slice(0, -1)).subscribe((result) => {
      this.loader =false;
      if(result){
        if (result.data.length == 0 && result.pageNumber != 1) {
          this.getTransaction(result.previousPage, _sortBy, _sort);
        } else {
          this.transactions = result;
        }
      }
     
    });
  }

  getTransactionForExcel(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader =true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }
    const userId : string = this.searchFormGroup.controls.username.value;
    const requestId : string = this.searchFormGroup.controls.requestId.value;
    const dateRange : string = this.searchFormGroup.controls.dateRange.value;
   
    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_userId: string = userId ? `id=${userId}&` : "";
    const queryString_showRows: string = `showRows=10000&`;
    const queryString_requestId: string = requestId ? `requestId=${requestId}&` : "";
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_requestId + queryString_dateRange + queryString_userId + queryString_showRows;
  
    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/ServiceCharge/Transactions?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/ServiceCharge/Transactions?${queryString}`;
      }
    }
    
    this.serviceChargeService.getTransactions(endPointUrl.slice(0, -1)).subscribe((result) => {
      this.loader =false;
      if(result){
        if (result.data.length == 0 && result.pageNumber != 1) {
          this.getTransaction(result.previousPage, _sortBy, _sort);
        } else {
          this.Transactions = result;
        
          this.generateExcel(this.Transactions.data, "Service Charge");
        }
      }
     
    });
  }

  createdDate = "";
  searchString = "";
  
  private _sortBy: any;
  private _sort: any;

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.createdDate = field == "createdDate" ? "th-inner sortable both " + sortBy : "";
    
    this.getTransaction(
      `${environment.API_URL}/api/ServiceCharge/Transactions?pageNumber=${
        page ? page : this.transactions.pageNumber
      }`,
      this._sortBy,
      this._sort
    );
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.transactions?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }

  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p = pageNumber;
    return `${environment.API_URL}/api/ServiceCharge/Transactions?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }

  statusOfAmount(money: any): boolean {
    return money < 0 ? false : true;
  }

  makeMoney(money: any) {
    if(money){
      return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
    }else{
      return "N/A";
    }
   
  }

  requestId;
  setSelectedTransaction(transaction: any, content) {
  
    this.transaction = transaction;
    this.requestId = this.transaction.requestId;
    this.openTransDetail(content);
  }

  modalCreateTransaction:any;
  openTransDetail(viewModal) {
    this.modalCreateTransaction = this.modalBService.open(viewModal, { centered: true, size: "xl" });
  }

  closeModal(){
    this.modalCreateTransaction.close();
    this.getTransaction(null,null,null,null);
  }

  // initSearchByUsernameFormGroup(): void {
  //   this.searchByUsernameFormGroup = new FormGroup({
  //     username: new FormControl(this.userId),
  //     dateRange: new FormControl(""),
  //     requestId: new FormControl(""),
  //   });

  //   this.searchByUsernameFormGroup.controls.username.valueChanges.subscribe((e) => {
  //     this.selectedUser(e);
  //     this.searchByUsernameFormGroup.controls.dateRange.setValue("");
  //     this.searchByUsernameFormGroup.controls.requestId.setValue("");
  //   });
  // }

  // selectedUser(e) {
  //   this.selectedUserId = e;
  //   // this.getTransaction();
  // }

  initSearchFormGroup(): void {
    this.searchFormGroup = new FormGroup({
      dateRange: new FormControl(this.emptyString),
      username: new FormControl(this.emptyString),
      requestId: new FormControl(this.emptyString)
    });

    this.searchFormGroup.controls.username.valueChanges.subscribe(e =>{
      this.selectedUser(e);
    });

    // this.searchFormGroup.controls.showRows.valueChanges.subscribe(e => {
    //   this.selectedRows(e);
    // });
  }

  selectedUser(e) {
    this.selectedUserId = e;
    this.getTransaction();
  }

  selectedRows(e){
    this.selectedRowsId = e;
    this.pageRows = e;
    this.getTransaction(); 
  }

  getCashiersAndManagers() {
    this.userService.GetCashierUsernames().subscribe((result) => {
      this.cashiersAndManagers = result;
      // Set the username options from the API data
      this.userIdFilterOptions = result.map((user) => {
        return {
          id: user.id,
          text: user.name,
        };
      }); 
    });
    this.getTransaction();
  }

  getRows(){
      this.websettings.getWebSettingsByIdentifier("SHOW_ROWS").subscribe((result) => {
        this.showRowsOption = result.data.map((status) => {
          return {
            id: status.parameter,
            text: status.value,
          };
        });
      });

      this.getTransaction();
  }

  exportToExcel() {
    this.Transactions = [];
    this.dataForExcel = [];

    this.getTransactionForExcel();
  }

  generateExcel(serviceCharge: any, name: any): void {
    this.Transactions = [];

     
      for(var i = 0; i < serviceCharge.length; i++){
        
        let status = "";

        if(serviceCharge[i].statusId == "1"){
          status = "Rejected"
        }  else if (serviceCharge[i].statusId == "2"){
          status = "Completed"
        } else {
          status = "Pending"
        }

        let meta = [
          {
              "Transaction Name":	"SERVICE CHARGE",
              "Transaction Type":	"SERVICE_CHARGE",
              "Request ID": serviceCharge[i].requestId,
              "Remarks": serviceCharge[i].remarks,
              "Transact by": serviceCharge[i].completedByUsername,
              "Completed date": this.datepipe.transform(serviceCharge[i].completedDate, "MMM-dd-yyyy hh:mm:ss t"),
              "Amount":	serviceCharge[i].amount,
              "Status":	status              
          }
        ]

        this.Transactions.push(meta);
      }

  

    for(var x = 0; x < this.Transactions.length; x++){
      this.dataForExcel.push(Object.values(this.Transactions[x][0]));
     
    }

    

    let reportData = {
      title: name + ' History',
      data: this.dataForExcel,
      headers: Object.keys(this.Transactions[0][0])
    }

    this.ete.exportExcel(reportData, name);
  }

}
