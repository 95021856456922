import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CryptoService } from 'src/data/crypto/crypto.service';
import { Data } from 'src/data/data';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { AuthService } from 'src/data/services/authentication/auth.service';
import { RolesService } from 'src/data/services/web/roles.service';
import { UserService } from 'src/data/services/web/user.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { SalesUserService } from 'src/data/services/web/sales-user.service';
import { Select2OptionData } from 'ng-select2';
import { environment } from 'src/environments/environment';
import { VipcoTransactionService } from 'src/data/services/web/vipco-transaction.service';

PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5
@Component({
  selector: 'app-send-vipco',
  templateUrl: './send-vipco.component.html',
  styleUrls: ['./send-vipco.component.less']
})
export class SendVipcoComponent implements OnInit {
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public vipcoTransactionService: VipcoTransactionService;
  public salesUserService: SalesUserService;
  public rolesService: RolesService;
  public userService: UserService;
  public emptyString = "";
  public accessToken: string;
  public roles: any;
  public role: any;
  public loader = false;
  public user: any;
  public userId: any;
  public roleLevelId: any;
  public username: any;
  public salesUser: Array<Select2OptionData>;
  public walletOption: Array<Select2OptionData>;

  walletFormGroup: FormGroup;

  public modalService: NgbModal;
  public modalConfirmService: NgbModal;

  public viewItemModal: NgbModalRef;
  public confirmPurchaseModal: NgbModalRef;

  public vipcoBalance: any;
  public spbalance: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    private currencyPipe: CurrencyPipe,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    vipcoTransactionService: VipcoTransactionService,
    rolesService: RolesService,
    modalService: NgbModal,
    modalConfirmService: NgbModal,
    userService: UserService,
    salesUserService: SalesUserService,
    public _decimalPipe: DecimalPipe,
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.vipcoTransactionService = vipcoTransactionService;
    this.rolesService = rolesService;
    this.modalService = modalService;
    this.userService= userService;
    this.salesUserService = salesUserService;
    this.modalConfirmService = modalConfirmService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.loader = true;
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.username = this.localStorageCacheService.getStorage("username");
    this.userId = this.localStorageCacheService.getStorage("userId");

    this.initWalletFormGroup();
    this.initSalesWallet();
    this.initWalletOption();
    this.getVIPCOTransactions();
  }

  pageNumber = 1;
  pageSize = 10;
  searchSalesString: any = null;

  initSalesUsers(pageNumber: any, pageSize: any): any {
    this.salesUserService.getSalesUserAll(pageNumber, pageSize, this.searchSalesString).subscribe((result) => {
      this.salesUser = result.data.map((user) => {
        return {
          id: user.userId,
          text: user.username,
        };
      });
    });
  }
  
  initWalletOption(): any {
      this.walletOption = [
        { id: "1", text: 'VIPCO' },
        { id: "2", text: 'SPECIAL POINTS' }
      ];
  }

  initWalletFormGroup(){
    this.walletFormGroup = new FormGroup({
      ReceiverUserId: new FormControl(null),
      amount: new FormControl("")
    });
  }

  initSalesWallet(){
    this.userService.GetSalesUserWallet(this.userId).subscribe((result) => {
      this.vipcoBalance = result.data.vipcoBalance;
      this.spbalance = result.data.specialPointBalance;
    });
  }

  onOpen($event){
    this.initSalesUsers(this.pageNumber, this.pageSize);
  }

  onScrollToEnd() {
    this.pageNumber = this.pageNumber + 1;

    this.salesUserService.getSalesUserAll(this.pageNumber, this.pageSize, this.searchSalesString).subscribe((result) => {
      this.salesUser = this.salesUser.concat(result.data.map((user) => {
        return {
          id: user.userId,
          text: user.username,
        };
      }));
    });
  }

  onSearchText: any = "";
  onSearch($event) {
    this.salesUser = [];
    this.onSearchText = $event.term;
    console.log($event);
  }

  onEnter(){
    this.salesUserService.getSalesUserAll(this.pageNumber, this.pageSize, this.onSearchText).subscribe((result) => {
      this.salesUser = result.data.map((user) => {
        return {
          id: user.userId,
          text: user.username,
        };
      });
    });
  }

  resetForm(){
    this.walletFormGroup.controls.ReceiverUserId.patchValue(null);
    this.walletFormGroup.controls.amount.patchValue("");
  }

  openConfirmation(content){
    if(this.walletFormGroup.controls.ReceiverUserId.value == null || this.walletFormGroup.controls.amount.value == ""){
      PNotify.error({
        title: "Invalid Transaction",
        text: "Please fill all fields.",
      });
    } else {
      this.modalConfirmService.open(content, { centered: true, size: "sm" });
    }
  }

  processCreate : boolean = false;
  public actionEventCreateAllocateFund(): void {
    this.processCreate = true;

    this.salesUserService.createVIPCOTransfer(this.walletFormGroup.value).subscribe(
      (result) => {
        debugger

        this.initSalesWallet();
        this.getVIPCOTransactions();
        this.resetForm();

        PNotify.success({
          title: "Success",
          text: "VIPCO Transfer successful.",
        });
        this.processCreate = false;
      },
      (error) => {
        if (error.status === 400) {
          const obj = error.error.errors;
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              let str: string = key;
              str = str[0].toLowerCase() + str.slice(1);
              this.walletFormGroup.controls[str].setErrors({
                required: Validators.required,
                apiMessage: obj[key],
              });
            }
          }

          this.processCreate = false;
        } else {
          // Other error
          this.processCreate = false;
        }
      }
    );
  }

  getVIPCOTransactions(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_userId: string = this.userId ? `userId=${this.userId}&` : "";
    const queryString_searchString: string = this.searchString ? `searchString=${this.searchString}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_userId + queryString_searchString;

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/VIPCOTransactions/Transfers?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/VIPCOTransactions/Transfers${queryString}`;
      }
    }

    this.vipcoTransactionService.getVIPCOTransactions(endPointUrl).subscribe((result) => {
      if (result.data.length == 0 && result.pageNumber != 1) {
        this.getVIPCOTransactions(result.previousPage, _sortBy, _sort);
      } else {
        this.transactions = result;
      }
    });
  }

  private _sortBy: any;
  private _sort: any;
  public transactions: any;
  createdDate = "";
  searchString = "";

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.createdDate = field == "createdDate" ? "th-inner sortable both " + sortBy : "";

    this.getVIPCOTransactions(
      `${environment.API_URL}/api/VIPCOTransactions/Transfers?pageNumber=${page ? page : this.transactions.pageNumber}`,
      this._sortBy,
      this._sort
    );
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.transactions?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }

  p:Number =1;
  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p = pageNumber;
    return `${environment.API_URL}/api/VIPCOTransactions/Transfers?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  statusOfAmount(money: any): boolean {
    return money < 0 ? false : true;
  }
}
