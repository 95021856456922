import { Injectable } from "@angular/core";
import { CryptoService } from "../crypto/crypto.service";
import { Data } from "../data";

@Injectable({
  providedIn: "root",
})
export class LocalStorageCacheService {
  constructor(private cryptoService: CryptoService, private data: Data) {}

  removeStorage(name): boolean {
    try {
      localStorage.removeItem(this.cryptoService.set(this.data.authentication.privateKey, name));
      localStorage.removeItem(this.cryptoService.set(this.data.authentication.privateKey, name + "_expiresIn"));
    } catch (e) {
      // console.log('removeStorage: Error removing key [' + name + '] from localStorage: ' + JSON.stringify(e));
      return false;
    }
    return true;
  }
  /*  getStorage: retrieves a key from localStorage previously set with setStorage().
        params:
            key <string> : localStorage key
        returns:
            <string> : value of localStorage key
            null : in case of expired key or failure
     */
  getStorage(key): any {
    const now = Date.now(); // epoch time, lets deal only with integer
    // set expiration for storage
    const valueExpiresIn = "0";
    const secretKey = this.data.authentication.privateKey;
    let expiresIn = localStorage.getItem(this.cryptoService.set(secretKey, key + "_expiresIn"));

    if (expiresIn === undefined || expiresIn === null) {
      expiresIn = valueExpiresIn;
    } else {
      expiresIn = this.cryptoService.get(secretKey, expiresIn);
    }

    if (Number(expiresIn) < now) {
      // Expired
      this.removeStorage(this.cryptoService.set(secretKey, key + "_expiresIn"));
      return null;
    } else {
      try {
        const value = localStorage.getItem(this.cryptoService.set(this.data.authentication.privateKey, key));
        return this.cryptoService.get(this.data.authentication.privateKey, value);
      } catch (e) {
        // console.log('getStorage: Error reading key [' + key + '] from localStorage: ' + JSON.stringify(e));
        return null;
      }
    }
  }
  /*  setStorage: writes a key into localStorage setting a expire time
        params:
            key <string>     : localStorage key
            value <string>   : localStorage value
            expires <number> : number of seconds from now to expire the key
        returns:
            <boolean> : telling if operation succeeded
     */
  setStorage(key, value, expires): boolean {
    if (expires === undefined || expires === null) {
      expires = 24 * 60 * 60; // default: seconds for 1 day
    } else {
      expires = Math.abs(expires); // make sure it's positive
    }

    const now = Date.now(); // millisecs since epoch time, lets deal only with integer
    const schedule = now + expires * 1000;
    try {
      localStorage.setItem(
        this.cryptoService.set(this.data.authentication.privateKey, key),
        this.cryptoService.set(this.data.authentication.privateKey, value)
      );
      localStorage.setItem(
        this.cryptoService.set(this.data.authentication.privateKey, key + "_expiresIn"),
        this.cryptoService.set(this.data.authentication.privateKey, schedule.toString())
      );
    } catch (e) {
      return false;
    }
    return true;
  }
}
