import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { InvoiceComponent } from 'src/app/elements/invoice/invoice.component';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { PrintService } from 'src/data/services/web/print.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { TransactionService } from 'src/data/services/web/transaction.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IglooService } from 'src/data/services/web/igloo.service';
import { PaygramComponent } from '../paygram/paygram.component';
import { PaygramService } from 'src/data/services/web/paygram.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-paygram-transaction',
  templateUrl: './paygram-transaction.component.html',
  styleUrls: ['./paygram-transaction.component.less']
})
export class PaygramTransactionComponent implements OnInit {

  @ViewChild(InvoiceComponent ) child: InvoiceComponent ; 
  @ViewChild('vipLogo') vipLogo: ElementRef;

  modalServe: NgbModal;
  previewModal: NgbModalRef;
  
  @Input() requestId: string;
  public data :  any;
  public loader : boolean = false;
  public receipt: any;
  public isA4 = new FormControl();
  roleLevel:any;
  public isTicketActive : any = false;

  public transactionService: TransactionService;
  public dti: any;
  public tin: any;
  public address: any;
  
  constructor(private paygramService : PaygramService, 
    private router: Router,
    private localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    public datepipe: DatePipe,
    public printService: PrintService,
    private webService: WebSettingsService, 
    transactionService: TransactionService,
    modalServe: NgbModal) {
    this.transactionService = transactionService;
    PNotifyButtons;
    this.modalServe = modalServe;
  }

  
  @Output("closeModal") closeModal = new EventEmitter();


  ngOnInit(): void {
    this.loader = true;
    this.getTransaction();
    this.initReceipt();
    this.roleLevel = this.localStorageCacheService.getStorage("role_level");
  }

  openTicket(){

    this.isTicketActive = true;
  }

  initReceipt() {
    this.webService.getWebSettingsByIdentifier("INVOICE_RECEIPT").subscribe((result) => {
      this.receipt = result.data;
    });
  }
  

  response: any;
  isIndividual: any;
  getTransaction() {
    this.paygramService.getTransaction(this.requestId).subscribe((result) => {
      debugger
      this.data = result;
      console.log(result);

      this.loader = false;
      this.initReceiptDetails(result.transactByUser.cashierUser.managerUserId);
    }, error => {
      this.loader = false;
    });
  }

  initReceiptDetails(managerUserId: any){
    this.transactionService.getReceiptDetails(managerUserId).subscribe((result) => {
      this.dti = result.dti;
      this.tin = result.tin != null ? result.tin : "***-***-***-***";
      this.address = result.address;
    });
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }


  buildUserPhoto(user: any): any {
    if(user){
      return user.photoFormat + "," + user.photo;
    }
  }

  onBeforePrint(){
    this.previewModal.close();
    //this.closeModal.emit();
  }

  printReceipt(){
    debugger
    //var resJson = JSON.parse(this.data.responseJson);
    var completedDate = this.data?.completedDate ? this.datepipe.transform(this.data?.completedDate, 'MMM-dd-yyyy') : this.datepipe.transform(this.data?.createdDate, 'MMM-dd-yyyy');
    var transTime = this.data?.completedDate ? this.datepipe.transform(this.data?.completedDate, 'hh:mm:ss a') : this.datepipe.transform(this.data?.createdDate, 'hh:mm:ss a');
    var reqId = this.data.requestId;
    var recValNo = this.data?.billerPartnerRefNo ? this.data?.billerPartnerRefNo : null;
    var cashier = this.data.transactByUser.cashierUser.firstName + ' ' + this.data.transactByUser.cashierUser.lastName;
    var billerName = this.data.billerName;
    var refNo = this.data.billerPartnerRefNo ? this.data.billerPartnerRefNo : null ;
    var can = this.data?.billerAccountNo;
    var peyeeContact = "N/A";
    var billAmount = this.data?.amount; 
    var amountPaid =  this.data.amount;
    var fee = 0;
    var transNo = this.data.billerPartnerRefNo ? this.data.billerPartnerRefNo : null;
    var AccountNo = this.data.billerAccountNo;
    var billerCode = this.data.billerName;
    var vipLogo = this.vipLogo.nativeElement.innerHTML;

    if(this.isA4.value){
      // if(this.data[i].statusId == 2){

      // }
      var Newstr = (this.receipt[1].value).replace("{{Date}}", completedDate);
      Newstr = (Newstr).replace("{{Time}}", transTime);
      Newstr = (Newstr).replace("{{Cashier}}", cashier);

      Newstr = (Newstr).replace("{{RefNo}}", refNo);
      Newstr = (Newstr).replace("{{RecValNo}}", recValNo);
      Newstr = (Newstr).replace("{{TransactionNo}}", transNo);
      Newstr = (Newstr).replace("{{PayeeContact}}", peyeeContact);
      
      Newstr = (Newstr).replace("{{AccountNo}}", AccountNo);
      Newstr = (Newstr).replace("{{BillerCode}}", billerCode);
      Newstr = (Newstr).replace("{{BillerName}}", billerName);
      Newstr = (Newstr).replace("{{BillAmount}}", parseFloat(billAmount).toFixed(2));
      Newstr = (Newstr).replace("{{AmountPaid}}", parseFloat(amountPaid).toFixed(2));
      Newstr = Newstr.replace("{{receiptLogo}}", vipLogo);
      Newstr = Newstr.replace("{{dti}}", this.dti);
      Newstr = Newstr.replace("{{tinNo}}", this.tin);
      Newstr = Newstr.replace("{{address}}", this.address);
      
      this.PrintReceipt(Newstr);
      } else {
  
        var Newstr = (this.receipt[0].value).replace("{{Date}}", completedDate);
        Newstr = (Newstr).replace("{{Time}}", transTime);
        Newstr = (Newstr).replace("{{RecID}}", reqId);
        Newstr = (Newstr).replace("{{ReferenceName}}", "Rec Val No.");
        Newstr = (Newstr).replace("{{ServiceRef}}", recValNo);
        Newstr = (Newstr).replace("{{Cashier}}", cashier);
        Newstr = (Newstr).replace("{{BillerName}}", billerName);
        Newstr = (Newstr).replace("{{RefNo}}", refNo);
        Newstr = (Newstr).replace("{{CAN}}", can);
        Newstr = (Newstr).replace("{{PayeeContact}}", peyeeContact);
        Newstr = (Newstr).replace("{{BillAmount}}", parseFloat(billAmount).toFixed(2));
        Newstr = (Newstr).replace("{{billerFee}}", fee);
        Newstr = (Newstr).replace("{{AmountPaid}}", parseFloat(amountPaid).toFixed(2));
        Newstr = Newstr.replace("{{receiptLogo}}", vipLogo);
        Newstr = Newstr.replace("{{dti}}", this.dti);
        Newstr = Newstr.replace("{{tinNo}}", this.tin);
        Newstr = Newstr.replace("{{address}}", this.address);
 
     this.PrintReceipt(Newstr);
   }
  }

  PrintReceipt(Newstr: any)
  {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write(Newstr);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    return true;
  }
}
