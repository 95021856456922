import { CurrencyPipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { CashierUsersService } from "src/data/services/web/cashier-users.service";
import { RolesService } from "src/data/services/web/roles.service";
import { StoreService } from "src/data/services/web/store.service";
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { UserService } from "src/data/services/web/user.service";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: "app-cashier-user-view",
  templateUrl: "./cashier-user-view.component.html",
  styleUrls: ["./cashier-user-view.component.less"],
})
export class CashierUserViewComponent implements OnInit {
  public userFormGroup: FormGroup;
  public cashierScheduleDaysFormGroup: FormGroup;
  public cashierScheduleHoursFormGroup: FormGroup;
  public customScheduleFormGroup: FormGroup;

  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public cashierUsersService: CashierUsersService;
  public userService: UserService;
  public storeService: StoreService;
  public emptyString = "";
  public roles: any;
  public role: any;
  public modalService: BsModalService;

  public accessToken: string;
  public newuser: boolean;
  public user: any;
  public userId: any;
  public roleLevelId: any;
  public roleId: any;

  public maxCashiersArray: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    private currencyPipe: CurrencyPipe,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    cashierUsersService: CashierUsersService,
    storeService: StoreService,
    modalService: BsModalService,
    userService: UserService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.cashierUsersService = cashierUsersService;
    this.storeService = storeService;
    this.modalService = modalService;
    this.userService= userService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
    PNotifyButtons;
  }

  ngOnInit(): void {
    
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    this.route.params.subscribe((params) => {
      const id = decodeURI(params.id || "");
      this.rolesService.getRoles(3).then((roles) => {
        this.roles = roles;
        if (id) {
          this.userId = this.cryptoService.get(this.data.authentication.privateKey, id);
          this.getUser(this.userId);
          this.setMaxCashiers();
        } else {
          this.authService.validateAccessToken(this.roleLevelId).then((res) => {
            const idDefault = this.localStorageCacheService.getStorage("id");
            this.userId = idDefault;
            this.getUser(this.userId);
            this.setMaxCashiers();
          });
        }
      });
    });
  }

  setMaxCashiers() {
    this.maxCashiersArray = [
      { id: 1, name: "1" },
      { id: 2, name: "2" },
      { id: 3, name: "3" },
      { id: 4, name: "4" },
      { id: 5, name: "5" },
      { id: 6, name: "6" },
      { id: 7, name: "7" },
      { id: 8, name: "8" },
      { id: 9, name: "9" },
      { id: 10, name: "10" },
    ];
  }

  isExpired: boolean = false;
  getUser(id: any): void {
    
    this.cashierUsersService.getCashierUser(id).subscribe((result) => {
      this.user = result.data;
      this.role = this.roles.filter((x) => x.id === this.user.roleId)[0].name;

      //debugger
      if(result.data.cashierUser.mobileActivationDate != null && result.data.cashierUser.emailActivationDate != null){
        const today = new Date();
        var milliseconds = today.getMilliseconds();
        const activationDate = new Date(result.data.cashierUser.emailActivationDate);
        var millisecondsActivationDate = activationDate.getMilliseconds();

        if(millisecondsActivationDate < milliseconds){
          this.isExpired = true;
        } else {
          this.isExpired = false;
        }
        //console.log("EXPIRY DATE: ", (this.isExpired));
      } else if(result.data.cashierUser.mobileActivationDate == null || result.data.cashierUser.emailActivationDate == null) {
        this.isExpired = true;
      }

      this.initCashierScheduleDaysFormGroup(this.user.cashierUser.schedule);
    });
  }

  
  loader : any = false;
  resendEmailVerification(id: any): void {
    this.loader = true;
    this.userService.resendEmailVerification(id).subscribe((result) => {
      this.loader = false;
      PNotify.success({
        title: "Success",
        text: "Account activation resend to email successfully.",
      });
    },(error)=>{
      this.loader = false
      PNotify.error({
        title: "Error",
        text: "Request is not possible at this time.",
      });

    });
  }

  backTousersList(): void {
    this.router.navigate(["manager", "cashier-user-list"]);
  }

  edituserProfile(): void {
    const encId = this.cryptoService.set(this.data.authentication.privateKey, this.userId);

    this.router.navigate(["manager", "cashier-user-edit", { id: encId }]);
  }

  daysSelection: any = ['Monday to Sunday', 'Monday to Saturday', 'Monday to Friday'];
  timeSelection: any = ['6:00 AM to 6:00 PM','7:00 AM to 4:00 PM','8:00 AM to 5:00 PM', '9:00 AM to 6:00 PM', '10:00 AM to 7:00 PM'];

  iniCustomScheduleFormGroup(): void {
    this.customScheduleFormGroup = new FormGroup({
      customDays: new FormControl(),
      customTime: new FormControl()
    });
  }

  initCashierScheduleDaysFormGroup(cashierSchedule: any): void {
    const schedule = JSON.parse(cashierSchedule);

    this.cashierScheduleDaysFormGroup = new FormGroup({
      monday: this.populateCashierScheduleHoursFormGroup(schedule ? schedule.monday : null),
      tuesday: this.populateCashierScheduleHoursFormGroup(schedule ? schedule.tuesday : null),
      wednesday: this.populateCashierScheduleHoursFormGroup(schedule ? schedule.wednesday : null),
      thursday: this.populateCashierScheduleHoursFormGroup(schedule ? schedule.thursday : null),
      friday: this.populateCashierScheduleHoursFormGroup(schedule ? schedule.friday : null),
      saturday: this.populateCashierScheduleHoursFormGroup(schedule ? schedule.saturday : null),
      sunday: this.populateCashierScheduleHoursFormGroup(schedule ? schedule.sunday : null),
    });

  }

  populateCashierScheduleHoursFormGroup(day: any): FormGroup {
    return new FormGroup({
      t1: new FormControl(day ? day.t1 : false),
      t2: new FormControl(day ? day.t2 : false),
      t3: new FormControl(day ? day.t3 : false),
      t4: new FormControl(day ? day.t4 : false),
      t5: new FormControl(day ? day.t5 : false),
      t6: new FormControl(day ? day.t6 : false),
      t7: new FormControl(day ? day.t7 : false),
      t8: new FormControl(day ? day.t8 : false),
      t9: new FormControl(day ? day.t9 : false),
      t10: new FormControl(day ? day.t10 : false),
      t11: new FormControl(day ? day.t11 : false),
      t12: new FormControl(day ? day.t12 : false),
      t13: new FormControl(day ? day.t13 : false),
      t14: new FormControl(day ? day.t14 : false),
      t15: new FormControl(day ? day.t15 : false),
      t16: new FormControl(day ? day.t16 : false),
      t17: new FormControl(day ? day.t17 : false),
      t18: new FormControl(day ? day.t18 : false),
      t19: new FormControl(day ? day.t19 : false),
      t20: new FormControl(day ? day.t20 : false),
      t21: new FormControl(day ? day.t21 : false),
      t22: new FormControl(day ? day.t22 : false),
      t23: new FormControl(day ? day.t23 : false),
      t24: new FormControl(day ? day.t24 : false),
    });
  }

  public processStoreSave = false;

  actionEvenUpdateManagerStore(): void {
    this.processStoreSave = true;
   
    this.cashierUsersService
      .updateCashierSchedule(this.cashierScheduleDaysFormGroup.value, this.user.cashierUser.id)
      .subscribe(
        (result) => {
          this.processStoreSave = false;
          PNotify.success({
            title: "Success",
            text: "Cashier schedule successfully saved.",
          });
        },
        (error) => {
          if (error.status === 400) {
            this.processStoreSave = false;
          } else {
            // Other error
            this.processStoreSave = false;
          }
        }
      );
  }

  buildUserPhoto(user: any): any {
    return user.cashierUser.photoFormat + "," + user.cashierUser.photo;
  }

  

  editCashierUserProfile(): void {
    const encId = this.cryptoService.set(this.data.authentication.privateKey, this.userId);
    debugger
    if(this.roleId == 1){
      this.router.navigate(["acm", "cashier-user-edit", { id: encId }]);
    } else if (this.roleLevelId == 2){
      this.router.navigate(["manager", "cashier-user-edit", { id: encId }]);
    }
    
  }
}
