import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { CompanyUsersService } from "src/data/services/web/company-users.service";
import { RolesService } from "src/data/services/web/roles.service";
import { UserService } from "src/data/services/web/user.service";
// PNotify
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { DatePipe } from "@angular/common";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5
@Component({
  selector: "app-company-user-view",
  templateUrl: "./company-user-view.component.html",
  styleUrls: ["./company-user-view.component.less"],
})
export class CompanyUserViewComponent implements OnInit {
  public companyUserFormGroup: FormGroup;
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public companyUsersService: CompanyUsersService;
  public userService: UserService;
  public emptyString = "";
  public roles: any;
  public role: any;
  public modalService: BsModalService;

  public accessToken: string;
  public newCompanyUser: boolean | string;
  public user: any;
  public userId: any;
  public roleLevelId: any;
  public roleId: any;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    companyUsersService: CompanyUsersService,
    modalService: BsModalService,
    userService: UserService,
    public datepipe: DatePipe
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.companyUsersService = companyUsersService;
    this.modalService = modalService;
    this.userService = userService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.route.params.subscribe((params) => {
      const id = decodeURI(params.id || "");
      const status = decodeURI(params.status || "");
      this.newCompanyUser = this.cryptoService.get(this.data.authentication.privateKey, status);
      this.rolesService.getRoles(1).then((roles) => {
        
        this.roles = roles;
        if (id) {
          this.userId = this.cryptoService.get(this.data.authentication.privateKey, id);
          this.getCompanyUser(this.userId);
        } else {
          this.authService.validateAccessToken(this.roleLevelId).then((res) => {
            const idDefault = this.localStorageCacheService.getStorage("id");
            this.userId = this.cryptoService.get(this.data.authentication.privateKey, id);
          
            this.getCompanyUser(idDefault);
          });
        }
      });
    });
  }

  buildUserPhoto(user: any): any {
    return user.companyUser.photoFormat + "," + user.companyUser.photo;
  }

  isExpired: boolean = false;
  getCompanyUser(id: any): void {
    this.userId = id;
    this.companyUsersService.getCompanyUser(id).subscribe((result) => {
      this.user = result.data;
      this.role = this.roles.filter((x) => x.id === this.user.roleId)[0].name;

      //debugger
      if(result.data.companyUser.mobileActivationDate != null && result.data.companyUser.emailActivationDate != null){
        const today = new Date();
        var milliseconds = today.getMilliseconds();
        const activationDate = new Date(result.data.companyUser.emailActivationDate);
        var millisecondsActivationDate = activationDate.getMilliseconds();

        if(millisecondsActivationDate < milliseconds){
          this.isExpired = true;
        } else {
          this.isExpired = false;
        }
        //console.log("EXPIRY DATE: ", (this.isExpired));
      } else if(result.data.companyUser.mobileActivationDate == null || result.data.companyUser.emailActivationDate == null) {
        this.isExpired = true;
      }
    });
  }


  loader : any = false;
  resendEmailVerification(id: any): void {
    this.loader = true;
    this.userService.resendEmailVerification(id).subscribe((result) => {
      this.loader = false;
      PNotify.success({
        title: "Success",
        text: "Account activation resend to email successfully.",
      });
    },(error)=>{
      this.loader = false
      PNotify.error({
        title: "Error",
        text: "Request is not possible at this time.",
      });

    });
  }


  backToCompanyUsersList(): void {
    this.router.navigate(["acm", "company-user-list"]);
  }

  editCompanyUserProfile(): void {
    
    const encId = this.cryptoService.set(this.data.authentication.privateKey, this.userId);

    this.router.navigate(["acm", "company-user-edit", { id: encId }]);
  }
}
