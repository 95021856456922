import { Injectable, Pipe, PipeTransform } from '@angular/core';
/*
 * Transform seconds (number) yo hh:mm:ss string
*/

@Pipe({name: 'secondsToHhmmss'})
export class SecondsToHhmmss implements PipeTransform {
  transform(totalSeconds: number): string {
    totalSeconds = parseInt(totalSeconds.toString());
    const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = (totalSeconds % 60);
  let result = `${minutes
    .toString()
    .padStart(1, '0')}:${seconds.toString().padStart(2, '0')}`;
  if (!!hours) {
    result = `${hours.toString()}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
  return result;
  }
}