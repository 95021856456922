import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { GovipxService } from 'src/data/services/web/govipx.service';
import { ManagerUsersService } from 'src/data/services/web/manager-users.service';
import { WalletService } from 'src/data/services/web/wallet.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5";
@Component({
  selector: 'app-vips-topup',
  templateUrl: './vips-topup.component.html',
  styleUrls: ['./vips-topup.component.less']
})
export class VipsTopupComponent implements OnInit {

  public localStorageCacheService: LocalStorageCacheService;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value:any = "";
  emptyString: any = "";

  searchByFormGroup: FormGroup;
  sendFormGroup: FormGroup;

  users: any = [];
  // [
  //   { "id": 1, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 2, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 3, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 4, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 5, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 6, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 7, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true }
  // ];
  
  transactions: any = [];
  // [
  //   { "id": 1, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10001, "rewards": 50, "status": 2 },
  //   { "id": 2, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10002, "rewards": 50, "status": 2 },
  //   { "id": 3, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10003, "rewards": 50, "status": 2 },
  //   { "id": 4, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10004, "rewards": 50, "status": 2 },
  //   { "id": 5, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10005, "rewards": 50, "status": 2 },
  //   { "id": 6, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10006, "rewards": 50, "status": 2 },
  //   { "id": 7, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10007, "rewards": 50, "status": 2 }
  // ];
  
  constructor(localStorageCacheService: LocalStorageCacheService,
    private modalServe: NgbModal,
    private usersService: ManagerUsersService,
    private walletService: WalletService,
    private govipxTopupService : GovipxService,
    public webSettingsService: WebSettingsService) { 
   
    this.localStorageCacheService = localStorageCacheService;
  }

  ngOnInit(): void {
    this.value = this.localStorageCacheService.getStorage("forQR");

    this.initGovipxTopupFormGroup();
    this.initSendFormGroup();
    this.initAPI();
    this.getManagerUser(this.localStorageCacheService.getStorage("id"));
    this.getWallet();
    this.initReceipt();
  }

  initAPI(){
    this.loader = false;
    this.govipxTopupService.getAuthenticated().subscribe((result) => {
      if(result){

      } else {
        PNotify.error({
          title: "Error",
          text: "Something went wrong. Please try again.",
        });
      }
    });
  }

  vipsTopupFormGroup : FormGroup;
  initGovipxTopupFormGroup(): void {
    this.vipsTopupFormGroup = new FormGroup({
      barcode: new FormControl(this.emptyString, [Validators.required]),
      amount: new FormControl(this.emptyString),
      mobileNo: new FormControl(this.emptyString),
      fullname: new FormControl(this.emptyString)
    });
  }


  initSendFormGroup(): void {
    this.sendFormGroup = new FormGroup({
      amount: new FormControl(this.emptyString),
      reference: new FormControl(this.emptyString),
      pinCode: new FormControl(this.emptyString),
      otp: new FormControl(this.emptyString)
    });
  }

  getWallet(): void {
    this.walletService.getWallet().subscribe((result) => {
      this.wallet = result.wallet;
    });
  }

  wallet: any;
  user: any;
  getManagerUser(id: any): void {
    this.usersService.getManagerUser(id).subscribe((result) => {
      this.user = result.data;
    });
  }

  searchData(){

  }

  initSearchFormGroup(): void {
    this.searchByFormGroup = new FormGroup({
      showRows: new FormControl(null),
      dateRange: new FormControl(this.emptyString),
      searchString: new FormControl(null),
    });
  }

  viewUser(id: any){

  }

  receipt: any;
  initReceipt() {
    this.webSettingsService.getWebSettingsByIdentifier("GoVIPX_QR_TEMPLATE").subscribe((result) => {
      this.receipt = result.data;
    });
  }

  openQR(content: any){
    this.modalServe.open(content, { centered: true, size: "lg" });
  }

  getQR(myQRTemplate: any){
    var qrtemplate = myQRTemplate.innerHTML;

    var Newstr = (this.receipt[0].value)
      .replace("{{QR}}", qrtemplate);
    
    this.PrintReceipt(Newstr);
  }

  PrintReceipt(Newstr: any)
  {
    var mywindow = window.open('', 'PRINT', 'height=700,width=1000');
    mywindow.document.write(Newstr);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    this.modalServe.dismissAll();

    return true;
  }

  userInfo: any;
  loader: any = true;
  processTopupModalRef : NgbModalRef;
  validateTopup(content){
    if(this.vipsTopupFormGroup.status == "INVALID"){
      PNotify.error({
        title: "Error",
        text: "All fields are required.",
      });
    }else{
      this.vipsTopupFormGroup.controls.barcode.patchValue(this.vipsTopupFormGroup.controls.barcode.value.toUpperCase());
      this.govipxTopupService.validateReference(this.vipsTopupFormGroup.controls.barcode.value).subscribe((result) => {
        debugger
        this.userInfo = result;
        this.vipsTopupFormGroup.controls.amount.patchValue(result.rAmount);
        this.vipsTopupFormGroup.controls.mobileNo.patchValue(result.userInformation.mobileNo);
        this.vipsTopupFormGroup.controls.fullname.patchValue(result.userInformation.fullName);
        this.loader = false;

        this.processTopupModalRef = this.modalServe.open(content, { centered: true, size: "md" });
      }, error => {
        PNotify.error({
          title: "Failed",
          text: "Reference number not found!",
        });
        this.loader = false;
      });
    }
  }

    // this called every time when user changed the code
    onCodeChanged(code: string) {
      this.codeStatus = code.length == 6;
    }
  
    // this called only if user entered full code
    codeStatus : boolean = false;
    code : any;
    
    onCodeCompleted(code: string) {
      this.code = code;
      this.codeStatus = code.length == 6;
    }

  processVerification(){
    this.loader = true;
    this.vipsTopupFormGroup.controls.barcode.patchValue(this.vipsTopupFormGroup.controls.barcode.value.toUpperCase());
    this.govipxTopupService.processGoVIPXTopup(this.vipsTopupFormGroup.value, this.code)
      .subscribe((result) => {
        PNotify.success({
          title: "Successful!",
          text: "Transaction completed.",
        });
        this.loader = false;
        this.code = "";
        this.codeStatus=false;
        this.processTopupModalRef.close();
      
    }, error => {
      PNotify.error({
        title: "Failed",
        text: error.error.reason,
      });
      this.loader = false;
      this.code = "";
      this.codeStatus = false;
    });
  }
}
