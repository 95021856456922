

  <ng-template #viewLoginModal let-modal>
       
    <div class="modal-body p-4">
        <center><img src="../../../assets/images/logo/GoVIP Logo Size  230.16 x 72 Pixels.png" alt="..." width="200"  /><br/>
            <h5 class="modal-title">Enterprise Migration Login</h5><br/></center>
        <div class="" [formGroup]="loginUserFormGroup" >
            <div class="form-group">
                <label>Username</label>
                <input type="text" class="form-control" placeholder="Enter username" formControlName="username">
            </div>
            <div class="form-group">
                <label>Password</label>
                <input type="password" class="form-control" placeholder="Password" formControlName="password">
            </div>

            <button type="button" (click)="loadData()" class="btn btn-primary mb-4" style="float:right;">Sign in to Migration</button>
          
        </div>
    </div>
</ng-template>

<app-loader *ngIf="loader || processCreate"> </app-loader>

<div *ngIf="validAccount" id="content" class="flex" >
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">

        <div style="float: left;"><img src="../../../assets/images/logo/GoVIP Logo Size  230.16 x 72 Pixels.png" alt="..." width="200"  /></div>
        <div class="page-title">
         
            
          <h2 class="text-md text-highlight">Migration Form</h2>
          <small class="text-highlight font-weight-normal font-italic">Make sure all the information are correct</small>
        </div>
        <div class="flex"></div>

        <div>
            <div 
            *ngIf="!username"
            (click)="logout()"
            class="btn btn-md btn-info"
          >
            <span class="d-none d-sm-inline mx-1">Logout</span>
            <i data-feather="arrow-right"></i>
          </div>
          <div 
          *ngIf="username"
          class="btn btn-md btn-info"
          (click)="createAccount()"
        >
          <span class="d-none d-sm-inline mx-1">Create Account</span>
          <i data-feather="arrow-right"></i>
        </div>
          &nbsp;
        
          <div
          (click)="submitChanges()"
            class="btn btn-md btn-primary"
          >
            <span class="d-none d-sm-inline mx-1">Save</span>
            <i data-feather="arrow-right"></i>
          </div>
        </div>

        <!-- (click)="saveManagerUser()"  -->
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
    <div class="padding">
        <div class="card" >
          
            <div class="list list-row block">
                <div class="list-item " data-id="17">
                    <div class="card-header">
                        <strong>Corporate Services</strong>
                    </div>
                    <div class="flex">
                        <a  class="item-author text-color "></a>
                        <div class="item-except text-muted text-sm h-1x">
                            
                        </div>
                    </div>

                  

                    <div class="no-wrap" *ngIf="username" >
                        <div class="btn-group">
                            <button type="button"  (click)="saveMigrationSignOff(2,'CORPSERV')" class="btn btn-outline-primary">Rejected</button>
                            <button type="button"  (click)="saveMigrationSignOff(0,'CORPSERV')" class="btn btn-outline-primary">Pending</button>
                            <button type="button"  (click)="saveMigrationSignOff(1,'CORPSERV')" class="btn btn-outline-primary">Completed</button>
                        </div>
                    </div>
                </div>
                <div *ngIf="corporateServicesSignOff.length == 0" class="list-item ">
                    <div ><center><span class="badge badge-warning text-uppercase">No Action</span> <br/></center></div>
                </div>
                <div class="list-item " *ngFor="let data of corporateServicesSignOff">
                    <div>
                        <a >
                            <span [ngClass]="(data.status == 0)?'w-48 avatar gd-warning' : (data.status == 1) ? 'w-48 avatar gd-success' :'w-48 avatar gd-danger'"></span>
                        </a>
                    </div>
                    <div class="flex">
                        <a  class="item-author text-color ">Triggered by {{data.username}}</a>
                        <div class="item-except text-muted text-sm h-1x">
                            {{data.status == 0 ?  'PENDING': data.status == 1 ? 'COMPLETED' : 'REJECTED'}}
                        </div>
                    </div>
                    <div class="no-wrap">
                        <div class="item-date text-muted text-sm d-none d-md-block">Date Action : {{data.dateTime | date: "dd-MMM-yyyy hh:mm:ss a" }}</div>
                    </div>
                </div>
           
        </div>
      
            <div class="list list-row block">
                <div class="list-item " data-id="17">
                    <div class="card-header">
                        <strong>Finance</strong>
                    </div>
                    <div class="flex">
                        <a  class="item-author text-color "></a>
                        <div class="item-except text-muted text-sm h-1x">
                            
                        </div>
                    </div>

                  

                    <div class="no-wrap" *ngIf="username">
                        <div class="btn-group" >
                            <button type="button"  (click)="saveMigrationSignOff(2,'FINANCE')" class="btn btn-outline-primary">Rejected</button>
                            <button type="button"  (click)="saveMigrationSignOff(0,'FINANCE')" class="btn btn-outline-primary">Pending</button>
                            <button type="button"  (click)="saveMigrationSignOff(1,'FINANCE')" class="btn btn-outline-primary">Completed</button>
                        </div>
                    </div>
                </div>
                <div *ngIf="financeSignOff.length == 0" class="list-item ">
                    <div ><center><span class="badge badge-warning text-uppercase">No Action</span> <br/></center></div>
                </div>
                <div class="list-item " *ngFor="let data of financeSignOff">
                  
                    <div>
                        <a >
                            <span [ngClass]="(data.status == 0)?'w-48 avatar gd-warning' : (data.status == 1) ? 'w-48 avatar gd-success' :'w-48 avatar gd-danger'"></span>
                        </a>
                    </div>
                    <div class="flex">
                        <a  class="item-author text-color ">Triggered by {{data.username}}</a>
                        <div class="item-except text-muted text-sm h-1x">
                            {{data.status == 0 ?  'PENDING': data.status == 1 ? 'COMPLETED' : 'REJECTED'}}
                        </div>
                    </div>
                    <div class="no-wrap">
                        <div class="item-date text-muted text-sm d-none d-md-block">Date Action : {{data.dateTime | date: "dd-MMM-yyyy hh:mm:ss a" }}</div>
                    </div>
                </div>
            </div>
      
       
            <div class="list list-row block">
                <div class="list-item " data-id="17">
                    <div class="card-header">
                        <strong>Support</strong>
                    </div>
                    <div class="flex">
                        <a  class="item-author text-color "></a>
                        <div class="item-except text-muted text-sm h-1x">
                            
                        </div>
                    </div>

                  

                    <div class="no-wrap" *ngIf="username">
                        <div class="btn-group">
                            <button type="button"  (click)="saveMigrationSignOff(2,'SUPPORT')" class="btn btn-outline-primary">Rejected</button>
                            <button type="button"  (click)="saveMigrationSignOff(0,'SUPPORT')" class="btn btn-outline-primary">Pending</button>
                            <button type="button"  (click)="saveMigrationSignOff(1,'SUPPORT')" class="btn btn-outline-primary">Completed</button>
                        </div>
                    </div>
                </div>
                <div *ngIf="supportSignOff.length == 0" class="list-item ">
                    <div ><center><span class="badge badge-warning text-uppercase">No Action</span> <br/></center></div>
                </div>
                <div class="list-item " *ngFor="let data of supportSignOff">
                    <div>
                        <a >
                            <span [ngClass]="(data.status == 0)?'w-48 avatar gd-warning' : (data.status == 1) ? 'w-48 avatar gd-success' :'w-48 avatar gd-danger'"></span>
                        </a>
                    </div>
                    <div class="flex">
                        <a  class="item-author text-color ">Triggered by {{data.username}}</a>
                        <div class="item-except text-muted text-sm h-1x">
                            {{data.status == 0 ?  'PENDING': data.status == 1 ? 'COMPLETED' : 'REJECTED'}}
                        </div>
                    </div>
                    <div class="no-wrap">
                        <div class="item-date text-muted text-sm d-none d-md-block">Date Action : {{data.dateTime | date: "dd-MMM-yyyy hh:mm:ss a" }}</div>
                    </div>
                </div>
            </div>
        
        </div>
        <div class="card" [formGroup]="managerUserFormGroup">
            <div class="card-header">
                <strong>Account Information</strong>
            </div>
            <div class="card-body">
                <div class="row row-sm">
                    <div class="col-sm-4">
                        <div class="md-form-group">
                            <input class="md-input input-readonly" readonly formControlName="username">
                            <label>Username</label>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="md-form-group">
                            <input class="md-input input-readonly" readonly formControlName="email">
                            <label>Email Address</label>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="md-form-group">
                            <input class="md-input input-readonly" readonly formControlName="roleId">
                            <label>Role</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-header">
            <strong>Personal Information</strong>
            </div>
            <div class="card-body">
                <div class="row row-sm">
        
            <div class="col-sm-4">
                <div class="md-form-group float-label">
                    <input class="md-input input-readonly" readonly formControlName="firstName">
                    <label>First Name</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="md-form-group float-label">
                    <input class="md-input input-readonly" readonly formControlName="lastName">
                    <label>Last Name</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="md-form-group float-label">
                    <input class="md-input input-readonly" readonly formControlName="middleName">
                    <label>Middle Name/Initial</label>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="md-form-group float-label">
                    <input class="md-input input-readonly" readonly formControlName="address">
                    <label>Address</label>
                </div>
            </div>
            <div class="col-sm-6">
                    <div class="md-form-group">
                        <input class="md-input input-readonly" readonly formControlName="contactNumber">
                        <label>Contact Number</label>
                        <span class="md-input-msg right"></span>
                    </div>
                
            </div>
            <div class="col-sm-3">
                <div class="md-form-group float-label">
                    <input class="md-input" value="" onkeyup="this.setAttribute('value', this.value);"
                    formControlName="longitude" >
                    <label>Longitude</label>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="md-form-group float-label">
                    <input class="md-input" value="" onkeyup="this.setAttribute('value', this.value);" 
                    formControlName="latitude" >
                    <label>Latitude</label>
                </div>
            </div>
        
            </div>
            </div>
            <div class="card-header">
                <strong>Store Settings and Activation Details</strong>
            </div>
            <div class="card-body">
                <div class="row row-sm">
        
            <div class="col-sm-8">
                <div class="md-form-group float-label">
                    <input class="md-input" value="" onkeyup="this.setAttribute('value', this.value);" required=""
                    formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                    <label><span class="text-danger font-weight-bold">*</span> &nbsp; Store Name</label>
                </div>
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors">Store Name is required</div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="md-form-group">
                    <input class="md-input  input-readonly" readonly formControlName="mainActivationDate">
                    <label>Activation Date</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="md-form-group">
                    <input class="md-input  input-readonly" readonly formControlName="enterpriseActivationDate">
                    <label>Enterprise Activation Date</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="md-form-group">
                    <input class="md-input input-readonly" readonly formControlName="sponsor">
                    <label>Sponsor</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="md-form-group">
                    <input class="md-input input-readonly" readonly formControlName="upline">
                    <label>Upline</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="md-form-group">
                    <input class="md-input input-readonly" readonly formControlName="placement">
                    <label>Placement</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="md-form-group">
                    <input class="md-input input-readonly" readonly formControlName="accountType">
                    <label>Account Type</label>
                </div>
            </div>

            <div class="col-sm-4">
                <div class="md-form-group float-label">
                    <select class="md-input" value="" onkeyup="this.setAttribute('value', this.value);" required="" formControlName="storeType" [ngClass]="{ 'is-invalid': submitted && f.storeType.errors }">
                        <option>Add Ons</option>
                        <option>Home Based</option>
                        <option>Stand Alone</option>
                    </select>
                    <label> <span class="text-danger font-weight-bold">*</span> &nbsp; Store Type</label>
                </div>
                <div *ngIf="submitted && f.storeType.errors" class="invalid-feedback">
                    <div *ngIf="f.storeType.errors">Store Type is required</div>
                </div>
            </div>
        
            </div>
            </div>
            <div class="card-header">
                <strong>Store Address</strong>
            </div>
            <div class="card-body">
                <div class="row row-sm">
        
            <div class="col-sm-4">
                <div class="md-form-group float-label">
                    <input class="md-input input-readonly" readonly formControlName="number" maxlength="49">
                    <label>House Number</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="md-form-group float-label">
                    <input class="md-input input-readonly" readonly formControlName="street">
                    <label>Street</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="md-form-group float-label">
                    <input class="md-input input-readonly" readonly formControlName="subdivision">
                    <label>Subdivision</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="md-form-group float-label">
                    <input class="md-input" value="" onkeyup="this.setAttribute('value', this.value);" required=""
                    formControlName="barangay" [ngClass]="{ 'is-invalid': submitted && f.barangay.errors }">
                    <label><span class="text-danger font-weight-bold">*</span> &nbsp; Barangay</label>
                </div>
                <div *ngIf="submitted && f.barangay.errors" class="invalid-feedback">
                    <div *ngIf="f.barangay.errors">Barangay is required</div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="md-form-group float-label">
                    <input class="md-input input-readonly" readonly formControlName="municipality">
                    <label>Municipality</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="md-form-group float-label">
                    <input class="md-input input-readonly" readonly formControlName="city">
                    <label>City</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="md-form-group float-label">
                    <input class="md-input input-readonly" readonly formControlName="region">
                    <label>Region</label>
                </div>
            </div>
        
            </div>
            </div>
            <div class="card-header"  *ngIf="username">
                <strong>Franchise Information</strong>
            </div>

            <div class="row row-sm"  *ngIf="username">
                <div class="col-sm-4">
                    <div class="md-form-group float-label margin-l-20">
                        <!-- <input class="md-input" value="" onkeyup="this.setAttribute('value', this.value);" required=""
                        formControlName="number"> -->
                        <div class="checkbox" >
                            <label class="ui-check ui-check-lg">
                                <input type="checkbox" formControlName="lifeTimeSystemUsage">
                                <i class="dark-white"></i>
                                Lifetime System Usage
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="md-form-group float-label">
                        <div class="checkbox" >
                            <label class="ui-check ui-check-lg">
                                <input type="checkbox" formControlName="areaProtection">
                                <i class="dark-white"></i>
                                Area Protection
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="md-form-group float-label">
                        <div class="checkbox" >
                            <label class="ui-check ui-check-lg">
                                <input type="checkbox" formControlName="completeEquipmentInclusion">
                                <i class="dark-white"></i>
                                Complete Equipment Inclusion
                            </label>
                        </div>
                    </div>
                
                </div>
            </div>

            <div class="card-header">
                <strong>Bank for ATM Withdrawal Settlement</strong>
            </div>
            <div class="card-body">
                <div class="row row-sm">
                    <div class="col-sm-4">
                        <div class="md-form-group float-label">
                            <input class="md-input" value="" onkeyup="this.setAttribute('value', this.value);" required=""
                            formControlName="bankName" [ngClass]="{ 'is-invalid': submitted && f.bankName.errors }">
                            <label><span class="text-danger font-weight-bold">*</span> &nbsp; Bank Name</label>
                        </div>
                        <div *ngIf="submitted && f.bankName.errors" class="invalid-feedback">
                            <div *ngIf="f.bankName.errors">Bank Name is required</div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="md-form-group float-label">
                            <input class="md-input" value="" onkeyup="this.setAttribute('value', this.value);" required=""
                            formControlName="bankAccountNumber" [ngClass]="{ 'is-invalid': submitted && f.bankAccountNumber.errors }">
                            <label><span class="text-danger font-weight-bold">*</span> &nbsp; Bank Account Number</label>
                        </div>
                        <div *ngIf="submitted && f.bankAccountNumber.errors" class="invalid-feedback">
                            <div *ngIf="f.bankAccountNumber.errors">Bank Account Number is required</div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="md-form-group float-label">
                            <input class="md-input" value="" onkeyup="this.setAttribute('value', this.value);" required=""
                            formControlName="bankAccountName" [ngClass]="{ 'is-invalid': submitted && f.bankAccountName.errors }">
                            <label><span class="text-danger font-weight-bold">*</span> &nbsp; Bank Account Name</label>
                        </div>
                        <div *ngIf="submitted && f.bankAccountName.errors" class="invalid-feedback">
                            <div *ngIf="f.bankAccountName.errors">Bank Account Name is required</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-header">
                <strong>Requirements</strong>
            </div>
            <div class="card-body">
                <div class="row row-sm">
                <div class="col-sm-4">
                    <div class="md-form-group float-label">
                        <input class="md-input" value="" onkeyup="this.setAttribute('value', this.value);" required=""
                        formControlName="dtiRegistrationName" [ngClass]="{ 'is-invalid': submitted && f.dtiRegistrationName.errors }">
                        <label><span class="text-danger font-weight-bold">*</span> &nbsp; DTI Registration Name</label>
                    </div> 
                    <div *ngIf="submitted && f.dtiRegistrationName.errors" class="invalid-feedback">
                        <div *ngIf="f.dtiRegistrationName.errors">DTI Registration Name is required</div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="md-form-group">
                        <input bsDatepicker class="md-input" value="" onkeyup="this.setAttribute('value', this.value);" required=""
                        formControlName="dtiRegistrationDate" autocomplete="off" container="app-root" 
                        [bsConfig]="dpConfig" readonly placeholder="mm/dd/yyyy" [ngClass]="{ 'is-invalid': submitted && f.dtiRegistrationDate.errors }">

                        <label><span class="text-danger font-weight-bold">*</span> &nbsp; DTI Registration Date</label>
                    </div>
                    <div *ngIf="submitted && f.dtiRegistrationDate.errors" class="invalid-feedback">
                        <div *ngIf="f.dtiRegistrationDate.errors">DTI Registration Date is required</div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="md-form-group float-label">
                        <select class="md-input" value="" onkeyup="this.setAttribute('value', this.value);" required="" formControlName="atmWithdrawalMachine" [ngClass]="{ 'is-invalid': submitted && f.atmWithdrawalMachine.errors }">
                            <option>Nationlink</option>
                            <option>EasyDebit</option>
                            <option>Encash</option>
                            <option>Others</option>
                            <option>Not Applicable</option>
                        </select>
                        <label><span class="text-danger font-weight-bold">*</span> &nbsp; Current ATM Withdrawal Machine</label>
                    </div>
                    <div *ngIf="submitted && f.atmWithdrawalMachine.errors" class="invalid-feedback">
                        <div *ngIf="f.atmWithdrawalMachine.errors">ATM Withdrawal Machine is required</div>
                    </div>
                </div>
            </div>


          
            </div>

            <div class="card-header" *ngIf="username">
                <strong>Last 5 Bills Transactions</strong>
            </div>

            <div class="card-body" *ngIf="username">
                <div class="row row-sm">
                <div class="col-sm-12">
            <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
                <thead>
                <tr>
                    <th data-sortable="true" data-field="owner">Account Number</th>
                    <th data-sortable="true" data-field="owner">Biller</th>
                    <th data-sortable="true" data-field="owner">Processed By</th>
                    <th data-sortable="true" data-field="owner" style="text-align: right;">Amount</th>
                    <th data-sortable="true" data-field="owner" style="text-align: right;">Created Date</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    *ngFor="let data of enterpriseData?.old.BillsTransactions"
                    style="cursor: pointer"
                    class="odd"
                    role="row"
                >
                    <td>
                    <small class="">{{ data?.AccountNumber }}</small>
                    </td>
                    <td>
                        <small class="">{{ data?.BillerCode }}</small>
                    </td>
                    <td>
                        <small class="">{{ data?.ProcessedBy  }}</small>
                    </td>
                    <td style="text-align: right;">
                        <small class="" >{{ data?.Points | number: "1.2-2" }}</small>
                    </td>
                    <td style="text-align: right;">
                        <small class="">{{ data?.CreatedDate | date: "dd-MMM-yyyy hh:mm:ss a" }}</small>
                    </td>
                </tr>
                </tbody>
            </table>
            </div>
        </div>
        
            </div>

            <div class="card-header" *ngIf="username">
                <strong>Last 5 Top Up</strong>
            </div>

            <div class="card-body" *ngIf="username">
                <div class="row row-sm">
                <div class="col-sm-12">
                        <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
                            <thead>
                            <tr>
                                <th data-sortable="true" data-field="owner">Reference Number</th>
                                <th data-sortable="true" data-field="owner" style="text-align: right;">Amount</th>
                                <th data-sortable="true" data-field="owner" style="text-align: right;">Created Date</th>
                                <th data-sortable="true" data-field="owner" style="text-align: right;">Completed Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                *ngFor="let data of enterpriseData?.old.TopUps"
                                style="cursor: pointer"
                                class="odd"
                                role="row"
                            >
                                <td>
                                <small class="">{{ data?.ReferenceNumber }}</small>
                                </td>
                                <td style="text-align: right;">
                                    <small class="" >{{ data?.Points | number: "1.2-2" }}</small>
                                </td>
                                <td style="text-align: right;">
                                    <small class="">{{ data?.CreatedDate | date: "dd-MMM-yyyy hh:mm:ss a" }}</small>
                                </td>
                                <td style="text-align: right;">
                                    <small class="">{{ data?.CompletedDate | date: "dd-MMM-yyyy hh:mm:ss a" }}</small>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                </div>
            </div>
            </div>



            

        </div>
    
    
    <div class="card" [formGroup]="fileFormGroup">
        <div class="card-header" >
            <strong>File Manager</strong>
        </div>
        <div class="card-body">
            <div class="row row-sm" >
                <div class="col-sm-4">
                    <div class="list list-row block">
                        <div class="list-item " data-id="20">
                            <div class="pointer" (click)="triggerFile(dtiForm)">
                                <span class="w-40 avatar gd-info">
                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                    <input #dtiForm type="file" (change)="onUploadFile($event,'dtiForm')" style="display: none;"
                                    accept="image/jpeg,image/png,application/pdf" />
                                </span>
                            </div>
                            <div class="flex">
                                <a  class="item-author text-color"><span class="text-danger font-weight-bold">*</span> &nbsp; DTI Registration</a>
                                <div class="item-except text-muted text-sm h-1x" [ngClass]="{ 'is-invalid': submitted && fi.dtiFormId.errors }">
                                    <label *ngIf="!managerUserFormGroup.controls.dtiFormId.value && !fileFormGroup.controls.dtiForm.value">Click the button to upload File...</label>
                                    <label *ngIf="managerUserFormGroup.controls.dtiFormId.value && !fileFormGroup.controls.dtiForm.value" (click)="preview(viewImageModal, managerUserFormGroup.controls.dtiFormId.value)" style="color:blue; cursor: pointer;">
                                        <span class="fa fa-eye">&nbsp;</span>View File
                                    </label>
                                    <label *ngIf="fileFormGroup.controls.dtiForm.value" style="color:blue; cursor: pointer;">
                                        <span class="fa fa-tag">&nbsp;</span>Save to preview file
                                    </label>
                                </div>
                                <div *ngIf="submitted && fi.dtiFormId.errors" class="invalid-feedback">
                                    <div *ngIf="fi.dtiFormId.errors">DTI Form is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="list-item " data-id="20">
                            <div class="pointer" (click)="triggerFile(applicationForm)">
                                <span class="w-40 avatar gd-info">
                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                    <input #applicationForm type="file" (change)="onUploadFile($event,'applicationForm')" style="display: none;"
                                    accept="image/jpeg,image/png,application/pdf" />
                                </span>
                            </div>
                            <div class="flex">
                                <a  class="item-author text-color">GoVIPCenter Application Form</a>
                                <div class="item-except text-muted text-sm h-1x">
                                    <label *ngIf="!managerUserFormGroup.controls.applicationFormId.value && !fileFormGroup.controls.applicationForm.value">Click the button to upload File...</label>
                                    <label *ngIf="managerUserFormGroup.controls.applicationFormId.value && !fileFormGroup.controls.applicationForm.value" 
                                    (click)="preview(viewImageModal, managerUserFormGroup.controls.applicationFormId.value)" style="color:blue; cursor: pointer;">
                                    <span class="fa fa-eye">&nbsp;</span>View File
                                    </label>
                                    <label *ngIf="fileFormGroup.controls.applicationForm.value" style="color:blue; cursor: pointer;">
                                        <span class="fa fa-tag">&nbsp;</span>Save to preview file
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="list-item " data-id="20">
                            <div class="pointer" (click)="triggerFile(validID1)">
                                <span class="w-40 avatar gd-info">
                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                    <input #validID1  type="file" (change)="onUploadFile($event,'validID1')" style="display: none;"
                                    accept="image/jpeg,image/png,application/pdf" />
                                </span>
                            </div>
                            <div class="flex">
                                <a  class="item-author text-color"><span class="text-danger font-weight-bold">*</span> &nbsp; [1] Valid ID</a>
                                <div class="item-except text-muted text-sm h-1x" [ngClass]="{ 'is-invalid': submitted && fi.validID1Id.errors }">
                                    <label *ngIf="!managerUserFormGroup.controls.validID1Id.value && !fileFormGroup.controls.validID1.value">Click the button to upload File...</label>
                                    <label *ngIf="managerUserFormGroup.controls.validID1Id.value && !fileFormGroup.controls.validID1.value" 
                                        (click)="preview(viewImageModal, managerUserFormGroup.controls.validID1Id.value)" 
                                        style="color:blue; cursor: pointer;"
                                        >
                                    <span class="fa fa-eye">&nbsp;</span>View File
                                    </label>
                                    <label *ngIf="fileFormGroup.controls.validID1.value" style="color:blue; cursor: pointer;">
                                        <span class="fa fa-tag">&nbsp;</span>Save to preview file
                                    </label>
                                </div>
                                <div *ngIf="submitted && fi.validID1Id.errors" class="invalid-feedback">
                                    <div *ngIf="fi.validID1Id.errors">Valid ID is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="list-item " data-id="20">
                            <div class="pointer" (click)="triggerFile(validID2)">
                                <span class="w-40 avatar gd-info">
                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                    <input #validID2  type="file" (change)="onUploadFile($event,'validID2')" style="display: none;"
                                    accept="image/jpeg,image/png,application/pdf" />
                                </span>
                            </div>
                            <div class="flex">
                                <a  class="item-author text-color"> <span class="text-danger font-weight-bold">*</span> &nbsp; [2] Valid ID</a>
                                <div class="item-except text-muted text-sm h-1x" [ngClass]="{ 'is-invalid': submitted && fi.validID2Id.errors }">
                                    <label *ngIf="!managerUserFormGroup.controls.validID2Id.value && !fileFormGroup.controls.validID2.value">Click the button to upload File...</label>
                                    <label *ngIf="managerUserFormGroup.controls.validID2Id.value && !fileFormGroup.controls.validID2.value" 
                                        (click)="preview(viewImageModal, managerUserFormGroup.controls.validID2Id.value)" 
                                        style="color:blue; cursor: pointer;"
                                        >
                                    <span class="fa fa-eye">&nbsp;</span>View File
                                    </label>
                                    <label *ngIf="fileFormGroup.controls.validID2.value" style="color:blue; cursor: pointer;">
                                        <span class="fa fa-tag">&nbsp;</span>Save to preview file
                                    </label>
                                </div>
                                <div *ngIf="submitted && fi.validID2Id.errors" class="invalid-feedback">
                                    <div *ngIf="fi.validID2Id.errors">Valid ID 2 is required</div>
                                </div> 
                            </div>
                        </div>
                    
                    
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="list list-row block">

                        <div class="list-item " data-id="20">
                            <div class="pointer" (click)="triggerFile(unionBankApplicationForm)">
                                <span class="w-40 avatar gd-info">
                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                    <input #unionBankApplicationForm  type="file" (change)="onUploadFile($event,'unionBankApplicationForm')" style="display: none;"
                                    accept="image/jpeg,image/png,application/pdf" />
                                </span>
                            </div>
                            <div class="flex">
                                <a  class="item-author text-color">Union Bank Application Form</a>
                                <div class="item-except text-muted text-sm h-1x">
                                    <label *ngIf="!managerUserFormGroup.controls.unionBankApplicationFormId.value && !fileFormGroup.controls.unionBankApplicationForm.value">Click the button to upload File...</label>
                                    <label *ngIf="managerUserFormGroup.controls.unionBankApplicationFormId.value && !fileFormGroup.controls.unionBankApplicationForm.value" (click)="preview(viewImageModal, managerUserFormGroup.controls.unionBankApplicationFormId.value)" style="color:blue; cursor: pointer;">
                                    <span class="fa fa-eye">&nbsp;</span>View File
                                    </label>
                                    <label *ngIf="fileFormGroup.controls.unionBankApplicationForm.value" style="color:blue; cursor: pointer;">
                                        <span class="fa fa-tag">&nbsp;</span>Save to preview file
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="list-item " data-id="20">
                            <div class="pointer" (click)="triggerFile(localRemittanceForm)">
                                <span class="w-40 avatar gd-info">
                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                    <input #localRemittanceForm  type="file" (change)="onUploadFile($event,'localRemittanceForm')" style="display: none;"
                                    accept="image/jpeg,image/png,application/pdf" />
                                </span>
                            </div>
                            <div class="flex">
                                <a  class="item-author text-color">Local Remittance Form</a>
                                <div class="item-except text-muted text-sm h-1x">
                                    <label *ngIf="!managerUserFormGroup.controls.localRemittanceFormId.value && !fileFormGroup.controls.localRemittanceForm.value">Click the button to upload File...</label>
                                    <label *ngIf="managerUserFormGroup.controls.localRemittanceFormId.value && !fileFormGroup.controls.localRemittanceForm.value" (click)="preview(viewImageModal, managerUserFormGroup.controls.localRemittanceFormId.value)" style="color:blue; cursor: pointer;">
                                    <span class="fa fa-eye">&nbsp;</span>View File
                                    </label>
                                    <label *ngIf="fileFormGroup.controls.localRemittanceForm.value" style="color:blue; cursor: pointer;">
                                        <span class="fa fa-tag">&nbsp;</span>Save to preview file
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="list-item " data-id="20">
                            <div class="pointer" (click)="triggerFile(atmWithdrawalApplicationForm)">
                                <span class="w-40 avatar gd-info">
                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                    <input #atmWithdrawalApplicationForm  type="file" (change)="onUploadFile($event,'atmWithdrawalApplicationForm')" style="display: none;"
                                    accept="image/jpeg,image/png,application/pdf" />
                                </span>
                            </div>
                            <div class="flex">
                                <a  class="item-author text-color">ATM Withdrawal Application Form</a>
                                <div class="item-except text-muted text-sm h-1x">
                                    <label *ngIf="!managerUserFormGroup.controls.atmWithdrawalApplicationFormId.value && !fileFormGroup.controls.atmWithdrawalApplicationForm.value">Click the button to upload File...</label>
                                    <label *ngIf="managerUserFormGroup.controls.atmWithdrawalApplicationFormId.value && !fileFormGroup.controls.atmWithdrawalApplicationForm.value" (click)="preview(viewImageModal, managerUserFormGroup.controls.atmWithdrawalApplicationFormId.value)" style="color:blue; cursor: pointer;">
                                    <span class="fa fa-eye">&nbsp;</span>View File
                                    </label>
                                    <label *ngIf="fileFormGroup.controls.atmWithdrawalApplicationForm.value" style="color:blue; cursor: pointer;">
                                        <span class="fa fa-tag">&nbsp;</span>Save to preview file
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="list-item " data-id="20">
                            <div class="pointer" (click)="triggerFile(mayorsPermit)">
                                <span class="w-40 avatar gd-info">
                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                    <input #mayorsPermit  type="file" (change)="onUploadFile($event,'mayorsPermit')" style="display: none;"
                                    accept="image/jpeg,image/png,application/pdf" />
                                </span>
                            </div>
                            <div class="flex">
                                <a  class="item-author text-color">Mayor's Permit</a>
                                <div class="item-except text-muted text-sm h-1x">
                                    <label *ngIf="!managerUserFormGroup.controls.mayorsPermitId.value && !fileFormGroup.controls.mayorsPermit.value">Click the button to upload File...</label>
                                    <label *ngIf="managerUserFormGroup.controls.mayorsPermitId.value && !fileFormGroup.controls.mayorsPermit.value" (click)="preview(viewImageModal, managerUserFormGroup.controls.mayorsPermitId.value)" style="color:blue; cursor: pointer;">
                                    <span class="fa fa-eye">&nbsp;</span>View File
                                    </label>
                                    <label *ngIf="fileFormGroup.controls.mayorsPermit.value" style="color:blue; cursor: pointer;">
                                        <span class="fa fa-tag">&nbsp;</span>Save to preview file
                                    </label>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="list list-row block">

                        <div class="list-item " data-id="20">
                            <div class="pointer" (click)="triggerFile(birRegistration)">
                                <span class="w-40 avatar gd-info">
                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                    <input #birRegistration  type="file" (change)="onUploadFile($event,'birRegistration')" style="display: none;"
                                    accept="image/jpeg,image/png,application/pdf" />
                                </span>
                            </div>
                            <div class="flex">
                                <a  class="item-author text-color">BIR Registration 2303</a>
                                <div class="item-except text-muted text-sm h-1x">
                                    <label *ngIf="!managerUserFormGroup.controls.birRegistrationId.value && !fileFormGroup.controls.birRegistration.value">Click the button to upload File...</label>
                                    <label *ngIf="managerUserFormGroup.controls.birRegistrationId.value && !fileFormGroup.controls.birRegistration.value" (click)="preview(viewImageModal, managerUserFormGroup.controls.birRegistrationId.value)" style="color:blue; cursor: pointer;">
                                    <span class="fa fa-eye">&nbsp;</span>View File
                                    </label>
                                    <label *ngIf="fileFormGroup.controls.birRegistration.value" style="color:blue; cursor: pointer;">
                                        <span class="fa fa-tag">&nbsp;</span>Save to preview file
                                    </label>
                                </div>
                            </div>
                        </div>
                        

                        <div class="list-item " data-id="20">
                            <div class="pointer" (click)="triggerFile(googleMapScreenShot)">
                                <span class="w-40 avatar gd-info">
                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                    <input #googleMapScreenShot  type="file" (change)="onUploadFile($event,'googleMapScreenShot')" style="display: none;"
                                    accept="image/jpeg,image/png,application/pdf" />
                                </span>
                            </div>
                            <div class="flex">
                                <a  class="item-author text-color">Google Map Location Screenshot</a>
                                <div class="item-except text-muted text-sm h-1x">
                                    <label *ngIf="!managerUserFormGroup.controls.googleMapScreenShotId.value && !fileFormGroup.controls.googleMapScreenShot.value">Click the button to upload File...</label>
                                    <label *ngIf="managerUserFormGroup.controls.googleMapScreenShotId.value && !fileFormGroup.controls.googleMapScreenShot.value" (click)="preview(viewImageModal, managerUserFormGroup.controls.googleMapScreenShotId.value)" style="color:blue; cursor: pointer;">
                                    <span class="fa fa-eye">&nbsp;</span>View File
                                    </label>
                                    <label *ngIf="fileFormGroup.controls.googleMapScreenShot.value" style="color:blue; cursor: pointer;">
                                        <span class="fa fa-tag">&nbsp;</span>Save to preview file
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="list-item " data-id="20">
                            <div class="pointer" (click)="triggerFile(shopFacadeImages)">
                                <span class="w-40 avatar gd-info">
                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                    <input #shopFacadeImages  type="file" (change)="onUploadFile($event,'shopFacadeImages')" style="display: none;"
                                    accept="image/jpeg,image/png,application/pdf" />
                                </span>
                            </div>
                            <div class="flex">
                                <a  class="item-author text-color">Shop Façade Images for Logistics Partners (Landscape)</a>
                                <div class="item-except text-muted text-sm h-1x">
                                    <label *ngIf="!managerUserFormGroup.controls.shopFacadeImagesId.value && !fileFormGroup.controls.shopFacadeImages.value">Click the button to upload File...</label>
                                    <label *ngIf="managerUserFormGroup.controls.shopFacadeImagesId.value && !fileFormGroup.controls.shopFacadeImages.value" (click)="preview(viewImageModal, managerUserFormGroup.controls.shopFacadeImagesId.value)" style="color:blue; cursor: pointer;">
                                    <span class="fa fa-eye">&nbsp;</span>View File
                                    </label>
                                    <label *ngIf="fileFormGroup.controls.shopFacadeImages.value" style="color:blue; cursor: pointer;">
                                        <span class="fa fa-tag">&nbsp;</span>Save to preview file
                                    </label>
                                </div>
                            </div>
                        </div>

                
                        <div class="list-item " data-id="20">
                            <div class="pointer" (click)="triggerFile(amlcCertificate)">
                                <span class="w-40 avatar gd-info">
                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                    <input #amlcCertificate  type="file" (change)="onUploadFile($event,'amlcCertificate')" style="display: none;"
                                    accept="image/jpeg,image/png,application/pdf" />
                                </span>
                            </div>
                            <div class="flex">
                                <a  class="item-author text-color">AMLC Certificate</a>
                                <div class="item-except text-muted text-sm h-1x">
                                    <label *ngIf="!managerUserFormGroup.controls.amlcCertificateId.value && !fileFormGroup.controls.amlcCertificate.value">Click the button to upload File...</label>
                                    <label *ngIf="managerUserFormGroup.controls.amlcCertificateId.value && !fileFormGroup.controls.amlcCertificate.value" (click)="preview(viewImageModal, managerUserFormGroup.controls.amlcCertificateId.value)" style="color:blue; cursor: pointer;">
                                    <span class="fa fa-eye">&nbsp;</span>View File
                                    </label>
                                    <label *ngIf="fileFormGroup.controls.amlcCertificate.value" style="color:blue; cursor: pointer;">
                                        <span class="fa fa-tag">&nbsp;</span>Save to preview file
                                    </label>
                                </div>
                            </div>
                        </div>

                    
                    
                    </div>
                </div>
            </div> 
        </div>
    </div>

    <div class="card" *ngIf="username">
        <div class="card-header" >
            <strong>Comment Section</strong>
        </div>

        <div class="card-body">
            <div *ngFor="let comment in managerUserFormGroup.controls.comment?.value; let i = index">
                <div formGroup="commentFormGroup" class="form-group row">
              
                    <div class="col-sm-12">
                    <textarea 
                      type="text"
                      value="comment"
                      class="form-control"
                      readonly="true"
                    ></textarea>
                  </div>
                </div>
            </div>
            <div>
                <app-textarea
                    [parent-form-group]="commentFormGroup"
                    tab-index-start="0"
                    control-name="comment"
                    [error]="commentFormGroup?.controls.comment?.errors | estatus"
                    [error-message]="commentFormGroup?.controls.comment?.errors | econtent"
                    [read-only]="roleLevelId == 3"
                    label="Comment"
                    placeholder="Enter your comment here..."> 
                </app-textarea>
            </div>
            <div class="d-flex justify-content-end">
                <button class="btn btn-xs btn-primary" (click)="saveComment()">
                    Send
                </button>
            </div>


            <div class="col-sm-12">
             
                <div class="list list-row block">
                    <div class="list-item " *ngFor="let comment of comments">
                        <div>
                            <a >
                                <span class="w-48 avatar gd-primary">
                                    <span class="fa fa-tag"></span>
                                </span>
                            </a>
                        </div>
                        <div class="flex" comments>
                            <a href="#" class="item-author text-color ">{{comment.comment}}</a>
                            <div class="item-except text-muted text-sm h-1x">
                                {{comment.username}}
                            </div>
                        </div>
                        <div class="no-wrap">
                            <div class="item-date text-muted text-sm d-none d-md-block">{{comment.createdDate | date: "dd-MMM-yyyy hh:mm:ss a"}}</div>
                        </div>
                    </div>
                   
            </div>
        </div>
    </div>


    </div>

    
    </div>
</div>
  <!-- ############ Main END-->
</div>

<div id="modal-left" class="modal fade" data-backdrop="true">
  <div class="modal-dialog animate">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title text-md">Migration Form</div>
        <button class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="p-4 text-center">
          <p>Are you sure you want continue?</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
        <button type="button" (click)="submitChanges()" class="btn btn-primary" data-dismiss="modal">
          Submit Changes
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  
<div id="chatButton"></div>
</div>

<ng-template #viewImageModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">File Viewer</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" >
      <div>
        <ngx-extended-pdf-viewer
            [base64Src]="imagePreview" *ngIf="fileType=='PDF'"
            backgroundColor="#ffffff"
            height="100%"
            [useBrowserLocale]="true"
            [handTool]="false"
            [showHandToolButton]="true"
        >
        </ngx-extended-pdf-viewer>
        <img *ngIf="fileType=='IMAGE'"
            [src]="imagePreview"
            onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
            style="width: 100%"
          />
      </div>
    </div>
  </ng-template>




    