import { BrowserModule } from "@angular/platform-browser";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from "@angular/core";

// Third Party Modules
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { CarouselModule } from "ngx-bootstrap/carousel";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { TextboxComponent } from "./elements/textbox/textbox.component";
import { CheckboxComponent } from "./elements/checkbox/checkbox.component";
import { ButtonComponent } from "./elements/button/button.component";
import { DropdownComponent } from "./elements/dropdown/dropdown.component";
import { DatepickerComponent } from "./elements/datepicker/datepicker.component";
import { DatepickerSchoolYearComponent } from "./elements/datepicker-school-year/datepicker-school-year.component";
import { BasikAdminComponent } from "./page-layouts/basik-admin/basik-admin.component";
import { RoleListComponent } from "./pages/role-list/role-list.component";
import { RoleCreateComponent } from "./pages/role-create/role-create.component";
import { RoleEditComponent } from "./pages/role-edit/role-edit.component";
import { LoginComponent } from "./pages/login/login.component";
import { HeaderComponent } from "./page-components/header/header.component";
import { FooterComponent } from "./page-components/footer/footer.component";
import { SideNavComponent } from "./page-components/side-nav/side-nav.component";
import { CompanyUserCreateComponent } from "./pages/company-user-create/company-user-create.component";
import { CompanyUserListComponent } from "./pages/company-user-list/company-user-list.component";
import { CompanyUserEditComponent } from "./pages/company-user-edit/company-user-edit.component";
import { CompanyUserViewComponent } from "./pages/company-user-view/company-user-view.component";
import { BankToListComponent } from "./pages/bank-to-list/bank-to-list.component";
import { BankToCreateComponent } from "./pages/bank-to-create/bank-to-create.component";
import { BankToEditComponent } from "./pages/bank-to-edit/bank-to-edit.component";
import { BankFromListComponent } from "./pages/bank-from-list/bank-from-list.component";
import { BankFromCreateComponent } from "./pages/bank-from-create/bank-from-create.component";
import { BankFromEditComponent } from "./pages/bank-from-edit/bank-from-edit.component";
import { TopUpListComponent } from "./pages/top-up-list/top-up-list.component";
import { TopUpCreateComponent } from "./pages/top-up-create/top-up-create.component";
import { TopUpEditComponent } from "./pages/top-up-edit/top-up-edit.component";
import { TopUpViewComponent } from "./pages/top-up-view/top-up-view.component";
import { TicketDiscussionComponent } from "./pages/ticket-discussion/ticket-discussion.component";
import { StoreTransactionListComponent } from "./pages/store-transaction-list/store-transaction-list.component";
import { StoreTransactionViewComponent } from "./pages/store-transaction-view/store-transaction-view.component";
import { WebSettingsCreateComponent } from "./pages/web-settings-create/web-settings-create.component";
import { WebSettingsEditComponent } from "./pages/web-settings-edit/web-settings-edit.component";
import { HomeComponent } from "./pages/home/home.component";
import { BrowserAnimationsModule, NoopAnimationsModule } from "@angular/platform-browser/animations";
import { UploadPhotoComponent } from "./elements/upload-photo/upload-photo.component";
import { LoaderComponent } from "./elements/loader/loader.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ClickOutsideDirective } from "./directives/click-outside.directive";
import { NumberOnlyDirective } from "./directives/number-only.directive";
import { UpperCaseDirective } from "./directives/uppercase.directive";
import { NoSpecialCharDirective } from "./directives/no-special-char.directive";
import { RemoveComponentTagDirective } from "./directives/remove-component-tag.directive";
import { AnimatedHideDirective } from "./directives/animated-hide.directive";
import { BlockCopyCutPasteDirective } from "./directives/block-copy-cut-paste.directive";
import { PasswordComponent } from "./elements/password/password.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { CryptoService } from "src/data/crypto/crypto.service";
import { TokenInterceptor } from "src/data/interceptors/token-interceptor";
import { ErrorContentPipe } from "./pipes/error-content.pipe";
import { ErrorStatusPipe } from "./pipes/error-status.pipe";
import { BsModalRef, ModalModule } from "ngx-bootstrap/modal";
import { ModalConfirmationComponent } from "./pages/modal-confirmation/modal-confirmation.component";
import { ConvertToApiDatetimePipe } from "./pipes/convert-to-api-datetime.pipe";
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from "@angular/common";
import { ManagerUserListComponent } from "./pages/manager-user-list/manager-user-list.component";
import { ManagerUserCreateComponent } from "./pages/manager-user-create/manager-user-create.component";
import { ManagerUserEditComponent } from "./pages/manager-user-edit/manager-user-edit.component";
import { TextboxNumberComponent } from "./elements/textbox-number/textbox-number.component";
import { TextboxCurrencyComponent } from "./elements/textbox-currency/textbox-currency.component";
import { CashierUserListComponent } from "./pages/cashier-user-list/cashier-user-list.component";
import { CashierUserCreateComponent } from "./pages/cashier-user-create/cashier-user-create.component";
import { CashierUserEditComponent } from "./pages/cashier-user-edit/cashier-user-edit.component";
import { CashierUserViewComponent } from "./pages/cashier-user-view/cashier-user-view.component";
import { AuthImagePipe } from "./pipes/auth-image.pipe";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { CashierUserScheduleEditComponent } from "./pages/cashier-user-schedule-edit/cashier-user-schedule-edit.component";
import { CashierUserScheduleComponent } from "./pages/cashier-user-schedule/cashier-user-schedule.component";
// import { CashierUserScheduleService } from "./services/cashier-user-schedule.service";
import { HomeHolidaysComponent } from "./pages/home-holidays/home-holidays.component";
import { TextareaComponent } from "./elements/textarea/textarea.component";
import { BoolsliderComponent } from "./elements/boolslider/boolslider.component";
import { ManagerUserViewComponent } from "./pages/manager-user-view/manager-user-view.component";
import { WebSettingsListComponent } from "./pages/web-settings-list/web-settings-list.component";
import { ScheduleTickerComponent } from "./elements/schedule-ticker/schedule-ticker.component";
import { RequestFundListComponent } from "./pages/request-fund-list/request-fund-list.component";
import { StatusTableDataComponent } from "./elements/status-table-data/status-table-data.component";
import { RequestFundCreateComponent } from "./pages/request-fund-create/request-fund-create.component";
import { DropdownIdentifierComponent } from "./elements/dropdown-identifier/dropdown-identifier.component";
import { TransactionListComponent } from "./pages/transaction-list/transaction-list.component";
import { TransactionService } from "src/data/services/web/transaction.service";
import { PosTransactionComponent } from "./pages/pos-transaction/pos-transaction.component";
import { PosTransactionDetailsComponent } from "./pages/pos-transaction-details/pos-transaction-details.component";
import { PartnersButtonComponent } from "./elements/partners-button/partners-button.component";
import { MarketPlaceComponent } from "./pages/market-place/market-place.component";
import { AccountVerificationComponent } from "./pages/account-verification/account-verification.component";
import { BlankComponent } from "./pages/blank/blank.component";
import { DropdownAlphabeticalComponent } from "./elements/dropdown-alphabetical/dropdown-alphabetical.component";
import { TransactionListAcmComponent } from "./pages/transaction-list-acm/transaction-list-acm.component";
import { AllocateFundListComponent } from "./pages/allocate-fund-list/allocate-fund-list.component";
import { AllocateFundCreateComponent } from "./pages/allocate-fund-create/allocate-fund-create.component";
import { BasikNonAdminComponent } from "./page-layouts/basik-non-admin/basik-non-admin.component";
import { ApplicationListComponent } from "./pages/application-list/application-list.component";
import { EmitterService } from "src/data/services/web/emitter.service";
import { MultisysComponent } from "./billers/multisys/multisys.component";
import { DropdownV2Component } from "./elements/dropdown-v2/dropdown-v2.component";
import { DropdownV3Component } from "./elements/dropdown-v3/dropdown-v3.component";
import { InvoiceComponent } from "./elements/invoice/invoice.component";
import { PrintLayoutComponent } from "./elements/print-layout/print-layout.component";
import { PrintService } from "src/data/services/web/print.service";
import { AccountResetPasswordComponent } from "./pages/account-reset-password/account-reset-password.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { AccountVerifyComponent } from "./pages/account-verify/account-verify.component";
import { A4InvoiceComponent } from "./elements/a4-invoice/a4-invoice.component";
import { NgxPrintModule } from "ngx-print";
import { CodeInputModule } from "angular-code-input";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { NgSelect2Module } from "ng-select2";
import { ExamplesComponent } from "./pages/examples/examples.component";
import { MultisysTransactionComponent } from "./billers/multisys/transaction/multisys-transaction/multisys-transaction.component";
import { PosTransactionListComponent } from "./pages/pos-transaction-list/pos-transaction-list.component";
import { PosTransactionItemComponent } from "./pages/pos-transaction-item/pos-transaction-item.component";
import { PinComponent } from "./pages/pin/pin.component";
import { PinEnrollComponent } from "./pages/pin-enroll/pin-enroll.component";
import { TransactionListAllComponent } from "./pages/transaction-list-all/transaction-list-all.component";
import { NgxPaginationModule } from "ngx-pagination";
import { ServiceChargeComponent } from "./billers/service-charge/service-charge.component";
import { ServiceChargeTransactionComponent } from "./billers/service-charge/transaction/service-charge-transaction/service-charge-transaction.component";
import { EcpayComponent } from "./billers/ecpay/ecpay/ecpay.component";
import { EccashComponent } from "./billers/eccash/eccash.component";
import { EctopupComponent } from "./billers/ectopup/ectopup.component";
import { EcSssValidateComponent } from "./billers/ecsssvalidate/ec-sss-validate/ec-sss-validate.component";
import { CtiComponent } from "./billers/cti/cti/cti.component";
import { EcpayTransactionComponent } from "./billers/ecpay/transaction/ecpay-transaction/ecpay-transaction.component";
import { VipProtectComponent } from "./billers/vip-protect/vip-protect.component";
import { VipProtectTransactionComponent } from "./billers/vip-protect/transaction/vip-protect-transaction/vip-protect-transaction.component";
import { CtiTransactionsComponent } from "./billersTransactions/cti-transactions/cti-transactions.component";
import { TransactionHistoryComponent } from "./pages/transaction-history/transaction-history.component";
import { MultisysTransactionsComponent } from "./billersTransactions/multisys-transactions/multisys-transactions.component";
import { EcpayBillTransactionsComponent } from "./billersTransactions/ecpay-bill-transactions/ecpay-bill-transactions.component";
import { EcpayCashTransactionsComponent } from "./billersTransactions/ecpay-cash-transactions/ecpay-cash-transactions.component";
import { EcpayTopupTransactionsComponent } from "./billersTransactions/ecpay-topup-transactions/ecpay-topup-transactions.component";
import { EccashTransactionComponent } from "./billers/eccash/transaction/eccash-transaction/eccash-transaction.component";
import { EctopupTransactionComponent } from "./billers/ectopup/transaction/ectopup-transaction/ectopup-transaction.component";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { CtiTransactionComponent } from "./billers/cti/transaction/cti-transaction/cti-transaction.component";
import { ManagerFileListComponent } from "./pages/manager-file-list/manager-file-list.component";
import { AllFilesListComponent } from "./pages/all-files-list/all-files-list.component";
import { NgxImageZoomModule } from "ngx-image-zoom";
import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";
import { PosTransactionPublicViewComponent } from "./pages/pos-transaction-public-view/pos-transaction-public-view.component";
import { ApplicationViewComponent } from "./pages/application-view/application-view.component";
import { YearpickerComponent } from "./elements/yearpicker/yearpicker.component";
import { MonthpickerComponent } from "./elements/monthpicker/monthpicker.component";
import { ChatService } from "./chat.service";
import { MessagesComponent } from "./pages/messages/messages.component";
import { AlphaNumericDirective } from "./directives/alpha-numeric.directive";
import { ArraySortPipe } from "./pipes/array-sort.pipe";
import { ArrayNativeSortPipe } from "./pipes/array-native-sort.pipe";
import { VideoCollectionsComponent } from "./pages/video-collections/video-collections.component";
import { AnnouncementBuilderComponent } from "./pages/announcement-builder/announcement-builder.component";
import { AnnouncementAreaComponent } from "./pages/announcement-area/announcement-area.component";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { ChartsModule } from "ng2-charts";
import { ChartReportComponent } from "./pages/chart-report/chart-report.component";
import { TicketCreateTransactionComponent } from "./pages/ticket-create-transaction/ticket-create-transaction.component";
import { InstapayComponent } from "./billers/instapay/instapay/instapay.component";
import { InstapayTransactionComponent } from "./billers/instapay/transaction/instapay-transaction/instapay-transaction.component";
import { VipProtectListComponent } from "./pages/vip-protect-list/vip-protect-list.component";
import { StatusTableDataV2Component } from "./elements/status-table-data-v2/status-table-data-v2.component";
import { VipProtectTermsAndConditionComponent } from "./pages/vip-protect-terms-and-condition/vip-protect-terms-and-condition.component";
import { GovipPrivacyPolicyComponent } from "./pages/govip-privacy-policy/govip-privacy-policy.component";
import { AuditWalletComponent } from "./pages/audit-wallet/audit-wallet.component";
import { ServiceChargeHistoryComponent } from "./pages/service-charge-history/service-charge-history.component";
import { ServiceChargeTransactionsComponent } from "./billersTransactions/service-charge-transactions/service-charge-transactions.component";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { IbayadTransferTransactionsComponent } from "./billersTransactions/ibayad-transfer-transactions/ibayad-transfer-transactions.component";
import { VipProtectTransactionsComponent } from "./billersTransactions/vip-protect-transactions/vip-protect-transactions.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { SplashScreenComponent } from "./pages/splash-screen/splash-screen.component";
import { MaintenanceComponent } from "./page-components/maintenance/maintenance.component";
import { AgmCoreModule } from "@agm/core";
import { AgmMapSingleComponent } from "./pages/agm-map-single/agm-map-single.component";
import { SmsSenderComponent } from "./pages/sms-sender/sms-sender.component";
import { RealtimeMessageComponent } from "./pages/realtime-message/realtime-message.component";
import { LocationTrackerComponent } from "./pages/location-tracker/location-tracker.component";
import { MigrationFormComponent } from "./pages/migration-form/migration-form.component";
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { LoaderPdfComponent } from "./pages/loader-pdf/loader-pdf.component";
import { MigrationListComponent } from "./pages/migration-list/migration-list.component";
import { EnterpriseDashboardComponent } from "./pages/enterprise-dashboard/enterprise-dashboard.component";
import { CashierHistoryItemsComponent } from "./pages/cashier-history-items/cashier-history-items.component";
import { SunlifeGrepaComponent } from "./billers/sunlife-grepa/sunlife-grepa/sunlife-grepa.component";
import { SunlifeGrepaTransactionComponent } from "./billers/sunlife-grepa/sunlife-grepa-transaction/sunlife-grepa-transaction.component";
import { AccountResetPincodeComponent } from "./pages/account-reset-pincode/account-reset-pincode.component";
import { GvaclComponent } from "./billers/sunlife-grepa/sunlife-grepa-tnc/gvacl/gvacl.component";
import { GvfacComponent } from "./billers/sunlife-grepa/sunlife-grepa-tnc/gvfac/gvfac.component";
import { GvacComponent } from "./billers/sunlife-grepa/sunlife-grepa-tnc/gvac/gvac.component";
import { GvlplComponent } from "./billers/sunlife-grepa/sunlife-grepa-tnc/gvlpl/gvlpl.component";
import { GvacppComponent } from "./billers/sunlife-grepa/sunlife-grepa-tnc/gvacpp/gvacpp.component";
import { TextboxNumberOnlyComponent } from "./elements/textbox-number-only/textbox-number-only.component";
import { SunlifeGrepaReportsComponent } from "./billers/sunlife-grepa/sunlife-grepa-reports/sunlife-grepa-reports.component";
import { VipProtectReportsComponent } from "./billers/vip-protect/vip-protect-reports/vip-protect-reports.component";
import { Da5CertificateComponent } from "./pages/da5-certificate/da5-certificate.component";
import { OrdinalDatePipe } from "./pipes/ordinal-date.pipe";
import { NationlinkSettlementComponent } from "./billers/settlements/nationlink-settlement/nationlink-settlement.component";
import { NationlinkSettlementEditComponent } from "./billers/settlements/nationlink-settlement-edit/nationlink-settlement-edit.component";
import { EasydebitSettlementComponent } from "./billers/settlements/easydebit-settlement/easydebit-settlement.component";
import { EncashSettlementComponent } from "./billers/settlements/encash-settlement/encash-settlement.component";
import { EncashSettlementEditComponent } from "./billers/settlements/encash-settlement-edit/encash-settlement-edit.component";
import { EasydebitSettlementEditComponent } from "./billers/settlements/easydebit-settlement-edit/easydebit-settlement-edit.component";
import { ShopeeSettlementComponent } from "./billers/settlements/shopee-settlement/shopee-settlement.component";
import { ShopeeSettlementEditComponent } from "./billers/settlements/shopee-settlement-edit/shopee-settlement-edit.component";
import { ApplicationDa5ViewComponent } from "./pages/application-da5-view/application-da5-view.component";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { ApplicationSendahViewComponent } from "./pages/application-sendah-view/application-sendah-view.component";
import { IbayadMeralcoComponent } from "./billers/ibayad-meralco/ibayad-meralco/ibayad-meralco.component";
import { IbayadMeralcoTransactionComponent } from "./billers/ibayad-meralco/ibayad-meralco-transaction/ibayad-meralco-transaction.component";
import { IbayadMeralcoTransactionsComponent } from "./billersTransactions/ibayad-meralco-transactions/ibayad-meralco-transactions.component";
import { SunlifeGrepaTransactionsComponent } from "./billersTransactions/sunlife-grepa-transactions/sunlife-grepa-transactions.component";
import { HolidayComponent } from "./page-components/holiday/holiday.component";
import { TimekeepingLogsComponent } from "./pages/timekeeping-logs/timekeeping-logs.component";
import { NgxSimpleCalendarModule } from "ngx-simple-calendar";
import { TimekeepingReportComponent } from "./pages/timekeeping-report/timekeeping-report.component";
import { MigrationSalesListComponent } from "./pages/migration-sales-list/migration-sales-list.component";
import { MinifiedVersionLoginComponent } from "./pages/rpi/minified-version-login/minified-version-login.component";
import { ExternalPartnerComponent } from "./billers/external-partner/external-partner.component";
import { MigrationSalesFormComponent } from "./pages/migration-sales-form/migration-sales-form.component";
import { SalesUserViewComponent } from "./pages/VIPCO/SALES_USER_MODULE/sales-user-view/sales-user-view.component";
import { StoreItemListComponent } from "./pages/VIPCO/STORE_MODULE/store-item-list/store-item-list.component";
import { StoreItemCreateComponent } from "./pages/VIPCO/STORE_MODULE/store-item-create/store-item-create.component";
import { StoreItemComponent } from "./pages/VIPCO/STORE_MODULE/store-item/store-item.component";
import { GeneologyComponent } from "./pages/geneology/geneology.component";
import { TreeDiagramModule } from "src/ng-tree-diagram/src/ng-tree-diagram";
import { CloudinaryModule } from "@cloudinary/angular-5.x";
import * as Cloudinary from "cloudinary-core";
import { NgxDropzoneModule } from "ngx-dropzone";
import { SideNavMobileComponent } from "./page-components/side-nav-mobile/side-nav-mobile.component";
import { SalesUserCreateComponent } from "./pages/VIPCO/SALES_USER_MODULE/sales-user-create/sales-user-create.component";
import { SalesUserEditComponent } from "./pages/VIPCO/SALES_USER_MODULE/sales-user-edit/sales-user-edit.component";
import { SalesAgentUserListComponent } from "./pages/VIPCO/SALES_USER_MODULE/sales-agent-user-list/sales-agent-user-list.component";
import { SalesDirectorUserListComponent } from "./pages/VIPCO/SALES_USER_MODULE/sales-director-user-list/sales-director-user-list.component";
import { SalesManagerUserListComponent } from "./pages/VIPCO/SALES_USER_MODULE/sales-manager-user-list/sales-manager-user-list.component";
import { ExecutiveDirectorUserListComponent } from "./pages/VIPCO/SALES_USER_MODULE/executive-director-user-list/executive-director-user-list.component";
import { StoreComponent } from "./pages/VIPCO/STORE_MODULE/store/store.component";
import { VipcoTransactionListComponent } from "./pages/VIPCO/VIP_TRANSACTION/vipco-transaction-list/vipco-transaction-list.component";
import { SendVipcoComponent } from './pages/VIPCO/VIP_TRANSACTION/send-vipco/send-vipco.component';
import { SalesActivationListComponent } from './pages/VIPCO/SALES_USER_MODULE/sales-activation-list/sales-activation-list.component';
import { GovipExpressComponent } from './pages/govip-express/govip-express.component';
import { DebounceClickDirective } from "./directives/debounce-click-directive";
import { StandardInsuranceTransactionComponent } from './billers/standard-insurance/standard-insurance-transaction/standard-insurance-transaction.component';
import { StandardInsuranceComponent } from "./billers/standard-insurance/standard-insurance/standard-insurance.component";
import { DatepickerWoLabelComponent } from './elements/datepicker-wo-label/datepicker-wo-label.component';
import { BranchLocatorComponent } from './pages/branch-locator/branch-locator.component';
import { PairingComponent } from './pages/VIPCO/SALES_USER_MODULE/pairing/pairing.component';
import { VipCloudComponent } from './pages/vip-cloud/vip-cloud.component';
import { environment } from '../environments/environment';
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { GovipxTopupComponent } from './billers/govipx-topup/govipx-topup.component';
import { CompanySalaryComponent } from './pages/company-salary/company-salary.component';
import { TextAllCapsComponent } from "./elements/text-all-caps/text-all-caps.component";
import { GovipxCashOutComponent } from './billers/govipx-cash-out/govipx-cash-out.component';
import { GovipxCashoutTransactionComponent } from './billers/govipx-cash-out/govipx-cashout-transaction/govipx-cashout-transaction.component';
import { GovipxTopupTransactionComponent } from './billers/govipx-topup/govipx-topup-transaction/govipx-topup-transaction.component';
import { GovipxAccountVerificationComponent } from './pages/GoVIPX/govipx-account-verification/govipx-account-verification.component';
import { SalaryComponent } from './pages/GoVIPX/salary/salary.component';
import { MultisysFavoriteComponent } from './favorites/multisys-favorite/multisys-favorite.component';
import { NetbankComponent } from "./billers/netbank/netbank/netbank.component";
import { NetbankTransactionComponent } from "./billers/netbank/netbank-transaction/netbank-transaction.component";
import { SpxRiderRemittanceComponent } from "./billers/spx-rider-remittance/spx-rider-remittance/spx-rider-remittance.component";
import { BayadComponent } from './billers/bayad/bayad/bayad/bayad.component';
import { BayadTransactionComponent } from './billers/bayad/bayad-transaction/bayad-transaction/bayad-transaction.component';
import { BayadTransactionsComponent } from './billersTransactions/bayad-transactions/bayad-transactions.component';
import { SpxRemittanceTransactionComponent } from './billers/spx-rider-remittance/spx-rider-transaction/spx-remittance-transaction.component';
import { SpxRemittanceReportComponent } from './billers/spx-rider-remittance/spx-remittance-report/spx-remittance-report.component';
import { SpxRemittanceTransactionsComponent } from './billersTransactions/spx-remittance-transactions/spx-remittance-transactions.component';
import { SpxRemittanceSettlementComponent } from './billers/settlements/spx-remittance-settlement/spx-remittance-settlement.component';
import { SpxRemittanceSettlementEditComponent } from './billers/settlements/spx-remittance-settlement-edit/spx-remittance-settlement-edit.component';
import { GvcSpxSettlementEditComponent } from './billers/settlements/gvc-spx-settlement-edit/gvc-spx-settlement-edit.component';
import { NetbankTransactionsComponent } from './billersTransactions/netbank-transactions/netbank-transactions.component';
import { StoreHoursSettingsComponent } from './pages/store-hours-settings/store-hours-settings.component';
import { ProductEventLeaderboardComponent } from './pages/product-event-leaderboard/product-event-leaderboard.component';
import { SecondsToHhmmss } from "./pipes/seconds-to-hhmmss.pipe";
import { SpxRiderListComponent } from './billers/spx-rider-remittance/spx-rider-list/spx-rider-list.component';
import { MerchantVerificationComponent } from "./pages/GoVIPX/merchant-verification/merchant-verification.component";
import { VipsDashboardComponent } from './pages/VIPS/vips-dashboard/vips-dashboard.component';
import { VipsProfileComponent } from './pages/VIPS/vips-profile/vips-profile.component';
import { VipsUsersComponent } from './pages/VIPS/vips-users/vips-users.component';
import { VipsMerchantComponent } from './pages/VIPS/vips-merchant/vips-merchant.component';
import { VipsRidersComponent } from './pages/VIPS/vips-riders/vips-riders.component';
import { truncate } from "lodash";
import { TruncatePipe } from "./pipes/truncate";
import { VipsSendComponent } from './pages/VIPS/vips-send/vips-send.component';
import { VipsTopupComponent } from './pages/VIPS/vips-topup/vips-topup.component';
import { WidgetBasicProfileComponent } from './pages/VIPS/Widget/widget-basic-profile/widget-basic-profile.component';
import { ArraySortDescPipe } from "./pipes/array-sort-desc.pipe";
import { VipsTutorialsComponent } from './pages/VIPS/vips-tutorials/vips-tutorials.component';
import { VipsRiderVerificationComponent } from './pages/GoVIPX/vips-rider-verification/vips-rider-verification.component';
import { VipsAdminComponent } from './pages/VIPS/admin/vips-admin/vips-admin.component';
import { VipsMerchantStoreApplicationComponent } from './pages/VIPS/MERCHANT/vips-merchant-store-application/vips-merchant-store-application.component';
import { VipsStoreProductsComponent } from './pages/VIPS/MERCHANT/vips-store-products/vips-store-products.component';
import { FoodOrderHistoryComponent } from './pages/VIPS/REPORTS/food-order-history/food-order-history.component';
import { VipsMerchantStoreHistoryComponent } from './pages/VIPS/REPORTS/vips-merchant-store-history/vips-merchant-store-history.component';
import { SearchPipe } from './pipes/search.pipe';
import { VipsRiderApplicationHistoryComponent } from './pages/VIPS/REPORTS/vips-rider-application-history/vips-rider-application-history.component';
import { ScrollableDirective } from './directives/scrollable.directive';
import { LoadingSpinnerComponent } from './pages/VIPS/Widget/loading-spinner/loading-spinner.component';
import { MerchantLocationComponent } from './pages/VIPS/MERCHANT/merchant-location/merchant-location.component';
import { NewsletterBuilderComponent } from './pages/newsletter-builder/newsletter-builder.component';
import { VipsRiderCreditComponent } from './pages/VIPS/vips-rider-credit/vips-rider-credit.component';
import { AlphaOnlyDirective } from "./directives/alpha-only.directive";
import { TextAlphaComponent } from "./elements/text-alpha/text-alpha.component";
import { AllowedDashDirective } from "./directives/allowed-dash.directive";
import { StandardInsuranceTransactionsComponent } from './billersTransactions/standard-insurance-transactions/standard-insurance-transactions.component';
import { VipsLiveAgentComponent } from './pages/VIPS/vips-live-agent/vips-live-agent.component';
import { VipcoTransactionComponent } from './pages/vipco-transaction/vipco-transaction.component';
import { VipLootComponent } from './pages/vip-loot/vip-loot.component';
import { VipsUsersListComponent } from './pages/VIPS/vips-users-list/vips-users-list.component';
import { ManagerWalletComponent } from './pages/manager-wallet/manager-wallet.component';
import { StockItemListComponent } from './pages/GOVIP-STORE/SETTINGS/stock-item-list/stock-item-list.component';
import { ItemSettingsListComponent } from "./pages/GOVIP-STORE/SETTINGS/item-settings-list/item-settings-list.component";
import { StockItemComponent } from "./pages/GOVIP-STORE/SETTINGS/stock-item/stock-item.component";
import { StockItemCreateComponent } from './pages/GOVIP-STORE/SETTINGS/stock-item-create/stock-item-create.component';
import { GovipStoreComponent } from './pages/GOVIP-STORE/OUTLET/govip-store/govip-store.component';
import { PurchaseOrderComponent } from './pages/GOVIP-STORE/OUTLET/purchase-order/purchase-order.component';
import { InventoryComponent } from './pages/GOVIP-STORE/ADMIN/inventory/inventory.component';
import { AddToCartComponent } from './pages/GOVIP-STORE/OUTLET/add-to-cart/add-to-cart.component';
import { CheckOutComponent } from './pages/GOVIP-STORE/OUTLET/check-out/check-out.component';
import { PurchaseOrderViewComponent } from './pages/GOVIP-STORE/OUTLET/purchase-order-view/purchase-order-view.component';
import { CartComponent } from './pages/GOVIP-STORE/OUTLET/cart/cart.component';
import { PurchaseOrderAdminComponent } from './pages/GOVIP-STORE/ADMIN/purchase-order-admin/purchase-order-admin.component';
import { PurchaseOrderAdminViewComponent } from './pages/GOVIP-STORE/ADMIN/purchase-order-admin-view/purchase-order-admin-view.component';
import { ReceiptComponent } from './pages/GOVIP-STORE/ADMIN/receipt/receipt.component';
import { RestrictCharactersDirective } from "./directives/restrict-characters.directive";
import { XpressHistoryComponent } from './pages/VIPS/REPORTS/xpress-history/xpress-history.component';
import { UgiComponent } from './billers/ugi/ugi/ugi.component';
import { UgiTransactionComponent } from './billers/ugi/transaction/ugi-transaction/ugi-transaction.component';
import { UgiTransactionsComponent } from './billersTransactions/ugi-transactions/ugi-transactions.component';
import { VipsLevel2VerificationComponent } from './pages/GoVIPX/vips-level2-verification/vips-level2-verification.component';
import { IglooComponent } from './billers/igloo/igloo/igloo.component';
import { IglooTransactionComponent } from './billers/igloo/igloo-transaction/igloo-transaction.component';
import { IglooCtplTransactionComponent } from './billers/igloo/igloo-ctpl-transaction/igloo-ctpl-transaction.component';
import { AccountReactivationComponent } from './pages/GoVIPX/account-reactivation/account-reactivation.component';
import { IglooTransactionsComponent } from './billersTransactions/igloo-transactions/igloo-transactions.component';
import { IglooCtplTransactionsComponent } from './billersTransactions/igloo-ctpl-transactions/igloo-ctpl-transactions.component';
import { CebuanaComponent } from './billers/cebuana/cebuana/cebuana.component';
import { CebuanaTransactionComponent } from './billers/cebuana/cebuana-transaction/cebuana-transaction.component';
import { CebuanaTransactionsComponent } from './billersTransactions/cebuana-transactions/cebuana-transactions.component';
import { CebuanaPayoutTransactionComponent } from './billers/cebuana/cebuana-payout-transaction/cebuana-payout-transaction.component';
import { CebuanaPayoutTransactionsComponent } from './billersTransactions/cebuana-payout-transactions/cebuana-payout-transactions.component';
import { SalesActivationPaymentComponent } from './pages/VIPCO/SALES_USER_MODULE/sales-activation-payment/sales-activation-payment.component';
import { JntComponent } from './billers/jnt/jnt/jnt.component';
import { JntTransactionComponent } from './billers/jnt/jnt-transaction/jnt-transaction.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ImageCropperModule } from 'ngx-image-cropper';
import { VipsCustomListComponent } from './pages/VIPS/vips-custom-list/vips-custom-list.component';
import { PaygramComponent } from './billers/paygram/paygram/paygram.component';
import { PinTextboxComponent } from './elements/pin-textbox/pin-textbox.component';
import { PaygramTransactionComponent } from "./billers/paygram/paygram-transaction/paygram-transaction.component";
import { PaygramTransactionsComponent } from './billersTransactions/paygram-transactions/paygram-transactions.component';
import { PaygramLedgerComponent } from './pages/paygram-ledger/paygram-ledger.component';
import { PaygramFundComponent } from './billers/paygram/paygram-fund/paygram-fund.component';
import { WaybillComponent } from './billers/jnt/waybill/waybill.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { JntTransactionsComponent } from './billersTransactions/jnt-transactions/jnt-transactions.component';
import { VipsCommunityComponent } from './pages/VIPS/vips-community/vips-community.component';
import { VipsCommunityListComponent } from './pages/VIPS/vips-community-list/vips-community-list.component';
import { AutoResizeDirective } from './directives/auto-resize.directive';
import { IglooAdminTransactionsComponent } from './billersTransactions/igloo-admin-transactions/igloo-admin-transactions.component';
import { PhpayComponent } from './billers/phpay/phpay/phpay.component';
import { PhpayTransactionComponent } from './billers/phpay/phpay-transaction/phpay-transaction.component';
import { PhpayTransactionsComponent } from './billersTransactions/phpay-transactions/phpay-transactions.component';
import { VipsHotelVerificationComponent } from './pages/GoVIPX/vips-hotel-verification/vips-hotel-verification.component';
import { SettlementStatusDataComponent } from './elements/settlement-status-data/settlement-status-data.component';
import { PaymentCollectionComponent } from './billers/payment-collection/payment-collection/payment-collection.component';
import { PaymentCollectionTransactionComponent } from './billers/payment-collection/payment-collection-transaction/payment-collection-transaction.component';
import { PaymentCollectionTransactionsComponent } from './billersTransactions/payment-collection-transactions/payment-collection-transactions.component';
@NgModule({
  schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ],
  declarations: [
    AppComponent,
    LoginComponent,
    TextboxComponent,
    TextAllCapsComponent,
    TextAlphaComponent,
    TextboxNumberComponent,
    TextboxCurrencyComponent,
    TextareaComponent,
    BoolsliderComponent,
    CheckboxComponent,
    ButtonComponent,
    DropdownComponent,
    DatepickerComponent,
    DatepickerSchoolYearComponent,
    BasikAdminComponent,
    RoleListComponent,
    RoleCreateComponent,
    RoleEditComponent,
    CompanyUserCreateComponent,
    CompanyUserListComponent,
    CompanyUserEditComponent,
    CompanyUserViewComponent,
    ManagerUserListComponent,
    ManagerUserCreateComponent,
    ManagerUserEditComponent,
    ManagerUserViewComponent,
    CashierUserListComponent,
    CashierUserCreateComponent,
    CashierUserEditComponent,
    CashierUserViewComponent,
    BankToListComponent,
    BankToCreateComponent,
    BankToEditComponent,
    BankFromListComponent,
    BankFromCreateComponent,
    BankFromEditComponent,
    TopUpListComponent,
    TopUpCreateComponent,
    TopUpEditComponent,
    TopUpViewComponent,
    TicketDiscussionComponent,
    TransactionListComponent,
    PosTransactionComponent,
    PosTransactionDetailsComponent,
    StoreTransactionListComponent,
    StoreTransactionViewComponent,
    WebSettingsListComponent,
    WebSettingsCreateComponent,
    WebSettingsEditComponent,
    HomeComponent,
    UploadPhotoComponent,
    LoaderComponent,

    // Directives
    ClickOutsideDirective,
    NumberOnlyDirective,
    UpperCaseDirective,
    NoSpecialCharDirective,
    RemoveComponentTagDirective,
    AnimatedHideDirective,
    BlockCopyCutPasteDirective,
    PasswordComponent,
    ErrorContentPipe,
    ErrorStatusPipe,
    ConvertToApiDatetimePipe,
    ModalConfirmationComponent,
    AuthImagePipe,
    AlphaNumericDirective,
    ArraySortPipe,
    ArraySortDescPipe,
    ArrayNativeSortPipe,
    DebounceClickDirective,
    SecondsToHhmmss,
    AlphaOnlyDirective,
    AllowedDashDirective,
    RestrictCharactersDirective,
    AutoResizeDirective,

    // Page Layout Components
    BasikAdminComponent,
    BasikNonAdminComponent,

    // Page Sub Components
    HeaderComponent,
    SideNavComponent,
    FooterComponent,

    // Page Components
    AccountVerificationComponent,
    BlankComponent,
    DashboardComponent,
    PageNotFoundComponent,
    CashierUserScheduleEditComponent,
    CashierUserScheduleComponent,
    HomeHolidaysComponent,
    ScheduleTickerComponent,
    RequestFundListComponent,
    RequestFundCreateComponent,
    StatusTableDataComponent,
    StatusTableDataV2Component,
    DropdownIdentifierComponent,
    TransactionListComponent,
    DropdownAlphabeticalComponent,
    TransactionListAcmComponent,
    PartnersButtonComponent,
    MarketPlaceComponent,
    AllocateFundListComponent,
    AllocateFundCreateComponent,
    ApplicationListComponent,
    InvoiceComponent,
    PrintLayoutComponent,
    AccountResetPasswordComponent,
    ForgotPasswordComponent,
    AccountVerifyComponent,
    A4InvoiceComponent,
    ExamplesComponent,
    MultisysComponent,
    DropdownV2Component,
    DropdownV3Component,
    A4InvoiceComponent,
    MultisysTransactionComponent,
    PosTransactionListComponent,
    PosTransactionItemComponent,
    PinComponent,
    PinEnrollComponent,
    TransactionListAllComponent,
    ServiceChargeComponent,
    ServiceChargeTransactionComponent,
    EcpayComponent,
    EccashComponent,
    EctopupComponent,
    EcSssValidateComponent,
    CtiComponent,
    EcpayTransactionComponent,
    VipProtectComponent,
    VipProtectTransactionComponent,
    CtiTransactionsComponent,
    TransactionHistoryComponent,
    MultisysTransactionsComponent,
    EcpayBillTransactionsComponent,
    EcpayCashTransactionsComponent,
    EcpayTopupTransactionsComponent,
    EccashTransactionComponent,
    EctopupTransactionComponent,
    CtiTransactionComponent,
    ManagerFileListComponent,
    AllFilesListComponent,
    PosTransactionPublicViewComponent,
    ApplicationViewComponent,
    YearpickerComponent,
    MonthpickerComponent,
    MessagesComponent,
    VideoCollectionsComponent,
    AnnouncementBuilderComponent,
    AnnouncementAreaComponent,
    ChartReportComponent,
    TicketCreateTransactionComponent,
    InstapayComponent,
    InstapayTransactionComponent,
    VipProtectListComponent,
    VipProtectTermsAndConditionComponent,
    GovipPrivacyPolicyComponent,
    AuditWalletComponent,
    IbayadTransferTransactionsComponent,
    VipProtectTransactionsComponent,
    ServiceChargeHistoryComponent,
    ServiceChargeTransactionsComponent,
    SplashScreenComponent,
    MaintenanceComponent,
    AgmMapSingleComponent,
    SmsSenderComponent,
    RealtimeMessageComponent,
    LocationTrackerComponent,
    MigrationFormComponent,
    LoaderPdfComponent,
    MigrationListComponent,
    EnterpriseDashboardComponent,
    CashierHistoryItemsComponent,
    SunlifeGrepaComponent,
    SunlifeGrepaTransactionComponent,
    AccountResetPincodeComponent,
    GvaclComponent,
    GvfacComponent,
    GvacComponent,
    GvlplComponent,
    GvacppComponent,
    TextboxNumberOnlyComponent,
    SunlifeGrepaReportsComponent,
    VipProtectReportsComponent,
    Da5CertificateComponent,
    NationlinkSettlementComponent,
    NationlinkSettlementEditComponent,
    EasydebitSettlementComponent,
    EncashSettlementComponent,
    EncashSettlementEditComponent,
    EasydebitSettlementEditComponent,
    ShopeeSettlementComponent,
    ShopeeSettlementEditComponent,
    ApplicationDa5ViewComponent,
    ApplicationSendahViewComponent,
    IbayadMeralcoComponent,
    IbayadMeralcoTransactionComponent,
    IbayadMeralcoTransactionsComponent,
    SunlifeGrepaTransactionsComponent,
    HolidayComponent,
    TimekeepingLogsComponent,
    TimekeepingReportComponent,
    MigrationSalesListComponent,
    MinifiedVersionLoginComponent,
    ExternalPartnerComponent,
    MigrationSalesFormComponent,
    SalesAgentUserListComponent,
    SalesUserViewComponent,
    StoreItemListComponent,
    StoreItemCreateComponent,
    StoreItemComponent,
    GeneologyComponent,
    SideNavMobileComponent,
    SalesUserCreateComponent,
    SalesUserEditComponent,
    SalesDirectorUserListComponent,
    SalesManagerUserListComponent,
    ExecutiveDirectorUserListComponent,
    StoreComponent,
    VipcoTransactionListComponent,
    SendVipcoComponent,
    SalesActivationListComponent,
    GovipExpressComponent,
    StandardInsuranceComponent,
    StandardInsuranceTransactionComponent,
    DatepickerWoLabelComponent,
    BranchLocatorComponent,
    PairingComponent,
    VipCloudComponent,
    GovipxTopupComponent,
    CompanySalaryComponent,
    GovipxCashOutComponent,
    GovipxCashoutTransactionComponent,
    GovipxTopupTransactionComponent,
    GovipxAccountVerificationComponent,
    SalaryComponent,
    MultisysFavoriteComponent,
    NetbankComponent,
    NetbankTransactionComponent,
    SpxRiderRemittanceComponent,
    BayadComponent,
    BayadTransactionComponent,
    BayadTransactionsComponent,
    SpxRemittanceTransactionComponent,
    SpxRemittanceReportComponent,
    SpxRemittanceTransactionsComponent,
    SpxRemittanceSettlementComponent,
    SpxRemittanceSettlementEditComponent,
    GvcSpxSettlementEditComponent,
    ProductEventLeaderboardComponent,
    NetbankTransactionsComponent,
    StoreHoursSettingsComponent,
    SpxRiderListComponent,
    MerchantVerificationComponent,
    VipsDashboardComponent,
    VipsProfileComponent,
    VipsUsersComponent,
    VipsMerchantComponent,
    VipsRidersComponent,
    VipsSendComponent,
    VipsTopupComponent,
    WidgetBasicProfileComponent,
    VipsTutorialsComponent,
    VipsRiderVerificationComponent,
    VipsAdminComponent,
    VipsMerchantStoreApplicationComponent,
    VipsStoreProductsComponent,
    FoodOrderHistoryComponent,
    VipsMerchantStoreHistoryComponent,
    SearchPipe,
    VipsRiderApplicationHistoryComponent,
    ScrollableDirective,
    LoadingSpinnerComponent,
    MerchantLocationComponent,
    VipsRiderCreditComponent,
    NewsletterBuilderComponent,
    StandardInsuranceTransactionsComponent,
    VipcoTransactionComponent,
    VipLootComponent,
    VipsUsersListComponent,
    ManagerWalletComponent,
    StockItemListComponent,
    ItemSettingsListComponent,
    StockItemComponent,
    StockItemCreateComponent,
    GovipStoreComponent,
    PurchaseOrderComponent,
    InventoryComponent,
    AddToCartComponent,
    CheckOutComponent,
    PurchaseOrderViewComponent,
    CartComponent,
    PurchaseOrderAdminComponent,
    PurchaseOrderAdminViewComponent,
    ReceiptComponent,
    XpressHistoryComponent,
    UgiComponent,
    UgiTransactionComponent,
    UgiTransactionsComponent,
    VipsLevel2VerificationComponent,
    IglooComponent,
    IglooTransactionComponent,
    IglooCtplTransactionComponent,
    AccountReactivationComponent,
    IglooTransactionsComponent,
    IglooCtplTransactionsComponent,
    CebuanaComponent,
    CebuanaTransactionComponent,
    CebuanaTransactionsComponent,
    CebuanaPayoutTransactionComponent,
    CebuanaPayoutTransactionsComponent,
    SalesActivationPaymentComponent,
    JntComponent,
    JntTransactionComponent,
    VipsCustomListComponent,
    PaygramComponent,
    PinTextboxComponent,
    PaygramTransactionComponent,
    PaygramTransactionsComponent,
    PaygramLedgerComponent,
    PaygramFundComponent,
    WaybillComponent,
    JntTransactionsComponent,
    VipsCommunityComponent,
    VipsCommunityListComponent,
    AutoResizeDirective,
    IglooAdminTransactionsComponent,
    PhpayComponent,
    PhpayTransactionComponent,
    PhpayTransactionsComponent,
    VipsHotelVerificationComponent,
    SettlementStatusDataComponent,
    PaymentCollectionComponent,
    PaymentCollectionTransactionComponent,
    PaymentCollectionTransactionsComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
    NoopAnimationsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    CarouselModule.forRoot(),
    NgxPrintModule,
    CodeInputModule,
    NgSelect2Module,
    NgSelectModule,
    NgxPaginationModule,
    AutocompleteLibModule,
    NgxImageZoomModule,
    NgxQRCodeModule,
    AngularEditorModule,
    ChartsModule,
    TooltipModule,
    NgSelectModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxExtendedPdfViewerModule,
    NgxSimpleCalendarModule,
    TreeDiagramModule,
    NgxDropzoneModule,
    CloudinaryModule.forRoot(Cloudinary, { cloud_name: "govipcenter-com", secure: true, upload_preset: "my_preset" }),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDV4oOlVMhBn_xcuEUAA7WJSH6Ku6aHaxU",
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    ColorPickerModule,
    ImageCropperModule,
    NgxBarcodeModule
  ],

  exports: [
    ClickOutsideDirective,
    NumberOnlyDirective,
    UpperCaseDirective,
    NoSpecialCharDirective,
    RemoveComponentTagDirective,
    AnimatedHideDirective,
    BlockCopyCutPasteDirective,
    PasswordComponent,
    DebounceClickDirective,
    RestrictCharactersDirective,
    AutoResizeDirective
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    CryptoService,
    ErrorContentPipe,
    ErrorStatusPipe,
    ConvertToApiDatetimePipe,
    BsModalRef,
    DatePipe,
    CurrencyPipe,
    TransactionService,
    EmitterService,
    PrintService,
    DecimalPipe,
    ChatService,
    ArraySortPipe,
    ArraySortDescPipe,
    OrdinalDatePipe,
    SecondsToHhmmss,
    TruncatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
