import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { InvoiceComponent } from 'src/app/elements/invoice/invoice.component';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { PrintService } from 'src/data/services/web/print.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { TransactionService } from 'src/data/services/web/transaction.service';
import { StandardInsuranceService } from 'src/data/services/web/standard-insurance.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-standard-insurance-transaction',
  templateUrl: './standard-insurance-transaction.component.html',
  styleUrls: ['./standard-insurance-transaction.component.less']
})
export class StandardInsuranceTransactionComponent implements OnInit {

  @ViewChild(InvoiceComponent ) child: InvoiceComponent ; 
  @ViewChild('vipLogo') vipLogo: ElementRef;

  modalServe: NgbModal;
  previewModal: NgbModalRef;
  
  @Input() requestId: string;
  public data :  any;
  public loader : boolean = false;
  public receipt: any;
  public isA4 = new FormControl();
  roleLevel:any;
  public isTicketActive : any = false;

  public transactionService: TransactionService;
  public dti: any;
  public tin: any;
  public address: any;
  
  constructor(private standardInsurance : StandardInsuranceService, 
    private router: Router,
    private localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    public datepipe: DatePipe,
    public printService: PrintService,
    private webService: WebSettingsService, 
    transactionService: TransactionService,
    modalServe: NgbModal) {
    this.transactionService = transactionService;
    this.standardInsurance = standardInsurance;
    PNotifyButtons;
    this.modalServe = modalServe;
  }

  
  @Output("closeModal") closeModal = new EventEmitter();


  ngOnInit(): void {
    this.loader = true;
    this.getTransaction();
    this.initReceipt();
    this.roleLevel = this.localStorageCacheService.getStorage("role_level");
  }

  openTicket(){

    this.isTicketActive = true;
  }

  initReceipt() {
    this.webService.getWebSettingsByIdentifier("INVOICE_RECEIPT").subscribe((result) => {
      this.receipt = result.data;
    });
  }
  

  response: any;
  isIndividual: any;
  getTransaction() {
    this.standardInsurance.getTransaction(this.requestId).subscribe((billers) => {
      debugger
       this.data = billers;

      if (billers.clientType == "C"){
        this.isIndividual = false;
      } else if(billers.clientType == "I") {
        this.isIndividual = true;
      } else {
        this.isIndividual = true;
      }

       this.loader = false;
       this.response = JSON.parse(billers.responseJson);
       this.initReceiptDetails(billers.transactByUser.cashierUser.managerUserId);
    }, error => {
      this.loader = false;
    });
  }

  initReceiptDetails(managerUserId: any){
    this.transactionService.getReceiptDetails(managerUserId).subscribe((result) => {
      this.dti = result.dti;
      this.tin = result.tin != null ? result.tin : "***-***-***-***";
      this.address = result.address;
    });
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }



  rejectTransaction(){
  //   this.loader = true;
  //   this.standardInsurance.rejectTransaction(this.requestId).subscribe((billers) => {
  //     PNotify.success({
  //       title: "Falied Status",
  //       text: "Transaction successfully rejected.",
  //     });
  //     this.loader = false;
  //     this.closeModal.emit();
  //  }, error => {
  //   PNotify.error({
  //     title: "Failed",
  //     text: "There's a problem occured. Please contant ACM support.",
  //   });
  //    this.loader = false;
  //  });

  }

  async completeTransaction(issi){
    this.loader = true;
    debugger
    await this.standardInsurance.completeTransaction(issi, this.data.transactionId).subscribe((result) => {
      if(result == "Success"){
        PNotify.success({
          title: "Information",
          text: "Transaction successfully completed.",
        });
      } else {
        PNotify.notice({
          title: "Information",
          text: result,
        });
      }
      this.loader = false;
      this.closeModal.emit();
   }, error => {
    PNotify.error({
      title: "Failed",
      text: "There's a problem occured. Please contant JuanPay support.",
    });
     this.loader = false;
   });
  }

  async completeCOCViewTransaction(issi){
    this.loader = true;
    debugger
    await this.standardInsurance.completeTransaction(issi, this.data.transactionId).subscribe((result) => {
      if(result == "Success"){
        PNotify.success({
          title: "Information",
          text: "Transaction successfully completed.",
        });
      } else {
        // PNotify.notice({
        //   title: "Information",
        //   text: result,
        // });
      }
      this.loader = false;
      this.closeModal.emit();
   }, error => {
    // PNotify.error({
    //   title: "Failed",
    //   text: "There's a problem occured. Please contant GO VIP support.",
    // });
     this.loader = false;
   });
  }

  reProcessPayment(){
    // this.loader = true;
    // this.standardInsurance.reProcessPayment(
    //   this.data.requestJsonData,
    //   this.data.billerName,
    //   this.data.billerCategory,
    //   this.requestId)
    //   .subscribe((biller) => {
    //     PNotify.success({
    //       title: "Payment Success",
    //       text: biller.reason,
    //     });
    //     this.loader = false;
    //     this.closeModal.emit();
      
    // }, error => {

    //   if(error.error.status == 490){
    //     PNotify.error({
    //       title: "Payment Failed",
    //       text: error.error.reason,
    //     });
    //     this.loader = false;

    //   }else{
    //     PNotify.error({
    //       title: "Payment Failed",
    //       text: error.error.reason,
    //     });
    //     this.loader = false;
    //     this.closeModal.emit();

    //   }

     



    // });
}

  buildUserPhoto(user: any): any {
    if(user){
      return user.photoFormat + "," + user.photo;
    }
  }

  imagePreview: any;
  viewCOC(content,issi){
    this.loader = true;
    if(issi){
      this.standardInsurance.getCOC(issi).subscribe((result) => {
        if(result.transaction == "valid"){
          if(result.data?.coC_PDF == null || result.data?.coC_PDF == ''){
            PNotify.notice({
              title: "COC INQUIRY",
              text: "Failed to issue COC File. \nPlease contact JuanPay Support for assistance. ",
            });
            this.loader = false;
          } else {
            this.imagePreview =  result.data.coC_PDF;
            if(this.data.statusId == 3){
              this.completeCOCViewTransaction(issi);
            }
  
            this.previewModal = this.modalServe.open(content, { centered: true,size:'xl', backdrop: 'static'});
            this.loader = false;
          }
        } else if(result.transaction == "processing"){
          PNotify.notice({
            title: "COC INQUIRY",
            text: "COC File still on process. Please wait for a moment.\nIf COC file is not yet ready within 10 minutes, please contact JuanPay Support.",
          });
          this.loader = false;
        } else {
          this.loader = false;
          PNotify.error({
            title: "COC INQUIRY",
            text: "Invalid transaction details. Please contact JuanPay Support for assistance.",
          });
        }
      });
    } else {
      PNotify.error({
        title: "COC INQUIRY",
        text: "Invalid transaction details. Please contact GoVIPCenter Support for assistance.",
      });
    }
  }

  onBeforePrint(){
    this.previewModal.close();
    //this.closeModal.emit();
  }

  printReceipt(){
    debugger
    var resJson = JSON.parse(this.data.responseJson);
    var completedDate = this.data?.completedDate ? this.datepipe.transform(this.data?.completedDate, 'MMM-dd-yyyy') : this.datepipe.transform(this.data?.createdDate, 'MMM-dd-yyyy');
    var transTime = this.data?.completedDate ? this.datepipe.transform(this.data?.completedDate, 'hh:mm:ss a') : this.datepipe.transform(this.data?.createdDate, 'MMM-dd-yyyy');
    var reqId = this.data.requestId;
    var recValNo = this.data?.transactionId ? this.data?.transactionId : null;
    var cashier = this.data.transactByUser.cashierUser.firstName + ' ' + this.data.transactByUser.cashierUser.lastName;
    var billerName = this.data.productCode;
    var refNo = resJson.issirefno[0] ? resJson.issirefno[0] : null ;
    var can = this.data?.plateNo;
    var peyeeContact = this.data.payeeContactNumber ? this.data.payeeContactNumber : null;
    var billAmount = this.data?.amountToBePaidByCustomer; 
    var amountPaid =  this.data.amount;
    var fee = 0;
    var transNo = this.data.transactionId ? this.data.transactionId : null;
    var AccountNo = this.data.plateNo;
    var billerCode = this.data.productType;
    var vipLogo = this.vipLogo.nativeElement.innerHTML;

    if(this.isA4.value){
      // if(this.data[i].statusId == 2){

      // }
      var Newstr = (this.receipt[1].value).replace("{{Date}}", completedDate);
      Newstr = (Newstr).replace("{{Time}}", transTime);
      Newstr = (Newstr).replace("{{Cashier}}", cashier);

      Newstr = (Newstr).replace("{{RefNo}}", refNo);
      Newstr = (Newstr).replace("{{RecValNo}}", recValNo);
      Newstr = (Newstr).replace("{{TransactionNo}}", transNo);
      Newstr = (Newstr).replace("{{PayeeContact}}", peyeeContact);
      
      Newstr = (Newstr).replace("{{AccountNo}}", AccountNo);
      Newstr = (Newstr).replace("{{BillerCode}}", billerCode);
      Newstr = (Newstr).replace("{{BillerName}}", billerName);
      Newstr = (Newstr).replace("{{BillAmount}}", parseFloat(billAmount).toFixed(2));
      Newstr = (Newstr).replace("{{AmountPaid}}", parseFloat(amountPaid).toFixed(2));
      Newstr = Newstr.replace("{{receiptLogo}}", vipLogo);
      Newstr = Newstr.replace("{{dti}}", this.dti);
      Newstr = Newstr.replace("{{tinNo}}", this.tin);
      Newstr = Newstr.replace("{{address}}", this.address);
      
      this.PrintReceipt(Newstr);
      } else {
  
        var Newstr = (this.receipt[0].value).replace("{{Date}}", completedDate);
        Newstr = (Newstr).replace("{{Time}}", transTime);
        Newstr = (Newstr).replace("{{RecID}}", reqId);
        Newstr = (Newstr).replace("{{ReferenceName}}", "Rec Val No.");
        Newstr = (Newstr).replace("{{ServiceRef}}", recValNo);
        Newstr = (Newstr).replace("{{Cashier}}", cashier);
        Newstr = (Newstr).replace("{{BillerName}}", billerName);
        Newstr = (Newstr).replace("{{RefNo}}", refNo);
        Newstr = (Newstr).replace("{{CAN}}", can);
        Newstr = (Newstr).replace("{{PayeeContact}}", peyeeContact);
        Newstr = (Newstr).replace("{{BillAmount}}", parseFloat(billAmount).toFixed(2));
        Newstr = (Newstr).replace("{{billerFee}}", fee);
        Newstr = (Newstr).replace("{{AmountPaid}}", parseFloat(amountPaid).toFixed(2));
        Newstr = Newstr.replace("{{receiptLogo}}", vipLogo);
        Newstr = Newstr.replace("{{dti}}", this.dti);
        Newstr = Newstr.replace("{{tinNo}}", this.tin);
        Newstr = Newstr.replace("{{address}}", this.address);
 
     this.PrintReceipt(Newstr);
   }
  }

  PrintReceipt(Newstr: any)
  {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write(Newstr);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    return true;
  }
}
