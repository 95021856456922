
import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { PrintService } from "src/data/services/web/print.service";
 import { NgZone } from "@angular/core";
import { NgSelectConfig } from "@ng-select/ng-select";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.less"],
})
export class AppComponent implements OnInit {
  public currentRoute: string;

  constructor(private router: Router, public printService: PrintService,
    private _ngZone: NgZone,
    private config: NgSelectConfig
      ) {

      this.config.notFoundText = 'Custom not found';
      this.config.appendTo = 'body';
      // set the bindValue to global config when you use the same 
      // bindValue in most of the place. 
      // You can also override bindValue for the specified template 
      // by defining `bindValue` as property
      // Eg : <ng-select bindValue="some-new-value"></ng-select>
      this.config.bindValue = 'value';


    // Subscribe to route changes
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        // The route changed
        this.currentRoute = window.location.pathname.split("?")[0];
      }
    });
  }

  ngOnInit(): void {

    // Get the current route path
    this.currentRoute = window.location.pathname.split("?")[0];
  }
}
