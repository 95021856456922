import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PrintService } from "src/data/services/web/print.service";
import { TransactionService } from "src/data/services/web/transaction.service";

@Component({
  selector: "app-a4-invoice",
  templateUrl: "./a4-invoice.component.html",
  styleUrls: ["./a4-invoice.component.less"],
})
export class A4InvoiceComponent implements OnInit {
  invoiceIds: string[];
  invoiceDetails: Promise<any>[];
  bill;
  trans;

  invoiceId: any;

  constructor(route: ActivatedRoute, private printService: PrintService, service: TransactionService) {
    this.invoiceId = route.snapshot.params["invoiceData"];
    this.bill = service.getTransById(1);
    this.trans = service.getTrans();
  }

  ngOnInit() {
    this.printService.onDataReady()
  }

  getInvoiceDetails(invoiceId) {
    const amount = Math.floor(Math.random() * 100);
    return new Promise((resolve) => setTimeout(() => resolve({ amount }), 1000));
  }
}
