<app-loader *ngIf="loader"></app-loader>
<div class="modal-body" *ngIf="activeTab != 3">
  <form id=" form" novalidate="">
    <div id="rootwizard">
      <ul class="nav mb-3">
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 1 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >1</span
            >
            <div class="mt-2">
              <div class="text-muted">Vips Topup</div>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 2 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >2</span
            >
            <div class="mt-2">
              <div class="text-muted">Confirmation</div>
            </div>
          </a>
        </li>
        <li  class="nav-item" style="margin-left: auto">
          <a class="nav-link text-center" [ngClass]="activeTab == 3 ? 'active' : ''">
            <img [src]="application.value.thumbnail.logo" height="50" class="img-responsive" style="object-fit: contain" />
          </a>
        </li>
      </ul>

      <hr class="bg-dark" />

      <div class="tab-content p-3">
        <div class="tab-pane" [ngClass]="activeTab == 1 ? 'active' : ''" id="selectBillerTab">
          <div class="row" [formGroup]="govipxTopupFormGroup">
           
            <div class="col-sm-12 col-md-12" style="float:left">
                <h4>Vips Topup Details</h4>
                <hr/>                

                <app-text-all-caps
                  [parent-form-group]="govipxTopupFormGroup"
                  tab-index-start="0"
                  control-name="barcode"
                  [error]="govipxTopupFormGroup?.controls.barcode.errors"
                  read-only="false"
                  no-special-char="false"
                  label="Reference Code"
                  placeholder="Reference Code"
                  max-length="6"
                >
                </app-text-all-caps>

              </div>
            
            <div class="col-sm-12 col-md-12"  style="float:left">
              
            </div>
          </div>
        </div>

        <div class="tab-pane" [ngClass]="activeTab == 2 ? 'active' : ''" id="newTransTab">
          <div class="form-group">
            <p>
              <strong>Vips Topup Confirmation</strong><br />
              <small>Please make sure that the all details is correct.</small>
            </p>
            <div class="card">
              <div class="list list-row row">

                <div class="col-sm-6 col-md-6">

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Customer name</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ userInfo?.user_info?.name }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Mobile No</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ userInfo?.user_info?.mobile_no }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Email Address</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ userInfo?.user_info?.email }}
                      </div>
                    </div>
                  </div>
    
                </div>

                <div class="col-sm-6 col-md-6">

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Reference</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ govipxTopupFormGroup?.controls.barcode.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Amount</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ userInfo?.transaction_details?.amount }}
                      </div>
                    </div>
                  </div>

                </div>

                <div class="col-sm-12 col-md-12">
                  <hr />
                  <div class="col-sm-12 col-md-6" style="float: none; margin: auto">
                    <div class="item-feed h-2x text-center">ENTER PINCODE</div>
                    <code-input
                      [isCodeHidden]="true"
                      [codeLength]="6"
                      (codeChanged)="onCodeChanged($event)"
                      (codeCompleted)="onCodeCompleted($event)"
                      [code]="code"
                    >
                    </code-input>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>

        <!-- previous next -->
        <div class="row py-3">
          <div class="col-6">
            <a class="btn btn-white button-previous" (click)="setActiveTabPrev()" *ngIf="activeTab != 1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-arrow-left"
              >
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
              &nbsp; Previous
            </a>
          </div>
          <div class="col-6">
            <div class="d-flex justify-content-end">
              <a *ngIf="activeTab != 2" class="btn btn-white button-next" (click)="setActiveTab()">
                Next &nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-arrow-right"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </a>
              <a
                *ngIf="activeTab == 2 && codeStatus"
                appDebounceClick 
                [debounceTime]="500" 
                (debounceClick)="processPayment()"
                class="btn btn-info button-next"
                style="color: white"
              >
                Process Vips Topup &nbsp;
                <span class="fa fa-hand-pointer"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
