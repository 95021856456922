import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { RolesService } from "src/data/services/web/roles.service";
import { AllocateFundService } from "src/data/services/web/allocate-fund.service";
import { environment } from "src/environments/environment";
import { NumericDictionaryIteratee } from "lodash";
import { ManagerUsersService } from "src/data/services/web/manager-users.service";
import { UserService } from "src/data/services/web/user.service";
import { CashierUsersService } from "src/data/services/web/cashier-users.service";

// PNotify
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { WalletService } from "src/data/services/web/wallet.service";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; 

@Component({
  selector: "app-allocate-fund-create",
  templateUrl: "./allocate-fund-create.component.html",
  styleUrls: ["./allocate-fund-create.component.less"],
})
export class AllocateFundCreateComponent implements OnInit {
  public allocateFundFormGroup: FormGroup;
  public allocateFundValuesFormGroup: FormGroup;
  public searchByUsernameFormGroup: FormGroup;

  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public allocateFundService: AllocateFundService;
  public emptyString = "";
  public bsModalRefConfirmation: BsModalRef;
  public modalService: BsModalService;
  public user: any;
  public userService: UserService;
  public walletService: WalletService;

  public managerService: ManagerUsersService;
  public cashierService: CashierUsersService;

  public accessToken: string;
  public newAllocateFund: boolean;
  public AllocateFund: any;
  public loadFlag = false;
  public AllocateFundId: any;
  public base64Photo: string | ArrayBuffer;
  public roleLevelId: any;
  public cashiers: any;
  processCreate = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    walletService: WalletService,
    rolesService: RolesService,
    allocateFundService: AllocateFundService,
    modalService: BsModalService,
    managerService: ManagerUsersService,
    userService: UserService,
    cashierService: CashierUsersService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.allocateFundService = allocateFundService;
    this.modalService = modalService;
    this.managerService = managerService;
    this.walletService = walletService;
    this.userService = userService;
    this.cashierService = cashierService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
  }

  ngOnInit(): void {
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.initAllocateFundFormGroup();
    this.initSearchByUsernameFormGroup();
    this.getWallet();
    if (this.roleLevelId == 2) {
      this.getCashiersByManager();
      this.getManagerUser(this.localStorageCacheService.getStorage("id"));
    } else if (this.roleLevelId == 3) {
      this.getManagerByCashier();
      this.getCashierUser(this.localStorageCacheService.getStorage("id"));
    }
  }

  initSearchByUsernameFormGroup(): void {
    this.searchByUsernameFormGroup = new FormGroup({
      username: new FormControl(0),
    });
  }

  wallet: any;
  getWallet(): void {
    this.walletService.getWallet().subscribe((result) => {
      this.wallet = result.wallet;
    });
  }

  getCashiersByManager() {
    this.userService.getCashiersByManager().subscribe((result) => {
      this.cashiers = result;
    });
  }

  getManagerByCashier() {
    this.userService.getManagerByCashier().subscribe((result) => {
      this.cashiers = result;
    });
  }

  initAllocateFundFormGroup(): void {
    this.allocateFundFormGroup = new FormGroup({
      amount: new FormControl("0.00"),
      destinationUserId: new FormControl(this.emptyString),
    });
    //this.setDefaultAmount(this.allocateFundFormGroup.controls.amount);
  }

  setDefaultAmount(control: AbstractControl) {
    control.valueChanges.subscribe((element) => {
   
      if (!element) {
        control.setValue("0.00");
      }
    });
  }

  getManagerUser(id: any): void {
    this.managerService.getManagerUser(id).subscribe((result) => {
      this.user = result.data;
    });
  }

  getCashierUser(id: any): void {
    this.cashierService.getCashierUser(id).subscribe((result) => {
      this.user = result.data;
    });
  }

  public actionEventCreateAllocateFund(): void {
    this.processCreate = true;
    debugger
    this.allocateFundService.createAllocateFund(this.allocateFundFormGroup.value).subscribe(
      (result) => {
        PNotify.success({
          title: "Success",
          text: "Fund Allocation successful.",
        });
        this.processCreate = false;
        this.backToAllocateFundList();
      },
      (error) => {
        if (error.status === 400) {
          const obj = error.error.errors;
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              let str: string = key;
              str = str[0].toLowerCase() + str.slice(1);
              this.allocateFundFormGroup.controls[str].setErrors({
                required: Validators.required,
                apiMessage: obj[key],
              });
            }
          }

          PNotify.error({
            title: "Something went wrong",
            text: error.error,
          });
          console.log(error);
          this.processCreate = false;
        } else {
          // Other error
          console.log(error);
          PNotify.error({
            title: "Something went wrong",
            text: error.error,
          });
          this.processCreate = false;
        }
      }
    );
  }

  backToAllocateFundList(): void {
    this.router.navigate([this.roleLevelId == 2 ? "manager" : "cashier", "allocate-fund-list"]);
  }

  public fileName: any = "Click here to choose file...";

  public onPhotoChange(event: any) {
    const reader = new FileReader();
  
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.fileName = file.name;
      reader.onload = () => {
        this.base64Photo = reader.result;

        this.allocateFundFormGroup.patchValue({
          officialReceiptPhoto: reader.result,
        });

        this.changeDetectorRef.markForCheck();
      };
    }
  }
}
