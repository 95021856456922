<app-loader *ngIf="loader"></app-loader>
<div class="card">
  <table
    id="table"
    class="table table-theme table-row v-middle dataTable no-footer table-hover"
    data-plugin="bootstrapTable"
    data-toolbar="#toolbar"
    data-search="true"
    data-search-align="left"
    data-show-export="true"
    data-show-columns="true"
    data-detail-view="false"
    data-mobile-responsive="true"
    data-pagination="true"
    data-page-list="[10, 25, 50, 100, ALL]"
  >
    <thead>
      <tr>
        <th data-sortable="true" data-field="id">Details</th>
        <th data-sortable="true" data-field="owner">Identity</th>
        <th data-sortable="true" data-field="project">Amount</th>
        <th data-field="task"><span class="d-none d-sm-block">Balance Before</span></th>
        <th data-field="finish"><span class="d-none d-sm-block">Balance After</span></th>
        <th data-field="finish"><span class="d-none d-sm-block">Status</span></th>
      </tr>
    </thead>

    <tbody>
      <tr
        class=" "
        *ngFor="
          let transaction of transactions?.data
            | paginate: { itemsPerPage: 10, currentPage: p, totalItems: transactions.totalRecords }
        "
        class="odd"
        role="row" 
        (click)="setSelectedTransaction(transaction)"
      >
        <td class="flex">
          <div class="item-except text-sm h-1x">
            <span class="text-secondary" style="font-weight: bold">Req. ID :</span> {{ transaction?.requestId }}
          </div>
          <div class="item-except text-sm h-1x">
            <span class="text-secondary" style="font-weight: bold">Amount :</span> {{ makeMoney(transaction?.amount) }}
          </div>
          <div class="item-except text-sm h-1x">
            <span class="text-secondary" style="font-weight: bold">Biller Account No. :</span>
            {{ transaction?.cellphoneNumber }}
          </div>
          <div class="item-except text-sm h-1x">
            <span class="text-secondary" style="font-weight: bold">Biller Fee :</span>
            {{ makeMoney(transaction?.billerFee) }}
          </div>
          <div class="item-except text-sm h-1x">
            <span class="text-secondary" style="font-weight: bold">Biller Name :</span> {{ transaction?.extensionTag }}
          </div>
          <div class="item-except text-sm h-1x">
            <span class="text-secondary" style="font-weight: bold">Category :</span> {{ transaction?.telco }}
          </div>
          <div class="item-except text-sm h-1x">
            <span class="text-secondary" style="font-weight: bold">Biller Partner Ref No. </span>:
            {{ transaction?.billerPartnerRefNo }}
          </div>
        </td>

        <td class="flex">
          <div class="item-except text-sm h-1x">
            <span class="text-secondary" style="font-weight: bold">Transact by :</span>
            {{ transaction?.transactByUser.username }}
          </div>
          <div class="item-except text-sm h-1x">
            <span class="text-secondary" style="font-weight: bold">Created date :</span>
            {{ transaction?.createdDate | date: "MMM dd, yyyy hh:mm:ss a" }}
          </div>
          <div class="item-except text-sm h-1x">
            <span class="text-secondary" style="font-weight: bold">Completed date :</span>
            {{ transaction?.completedDate | date: "MMM dd, yyyy hh:mm:ss a" }}
            <!-- {{transaction?.completedByUsername}} -->
          </div>
        </td>
        <td>
          <div class="text-center">
            <!-- <strong>{{makeMoney(transaction?.amount)}} </strong> -->
            <strong style="color: red">{{ makeMoney(transaction?.amount * -1) }} </strong>
          </div>
        </td>
        <td>
          <div class="text-center">
            <strong *ngIf="transaction?.statusId == 2">{{ makeMoney(transaction?.transactByOpeningBalance) }}</strong>
            <i class="" *ngIf="transaction?.statusId != 2">N/A</i>
          </div>
        </td>
        <td>
          <div class="text-center">
            <strong *ngIf="transaction?.statusId == 2">{{
              makeMoney(transaction?.transactByOpeningBalance - transaction?.amount)
            }}</strong>
            <i class="" *ngIf="transaction?.statusId != 2">N/A</i>
          </div>
        </td>
        <td>
          <app-status-table-data [status]="transaction?.statusId"></app-status-table-data>
        </td>
        <td>
          <div class="item-action dropdown">
            <!-- <a href="#" data-toggle="dropdown" class="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-more-vertical"
              >
                <circle cx="12" cy="12" r="1"></circle>
                <circle cx="12" cy="5" r="1"></circle>
                <circle cx="12" cy="19" r="1"></circle>
              </svg>
            </a> -->
            <div class="dropdown-menu dropdown-menu-right bg-black" role="menu">
              <!-- <a *ngIf="roleLevelId == 1" data-toggle="modal" (click)="setSelectedTransaction(transaction)"
                data-target="#modal-approve" data-toggle-class-target=".animate" class="dropdown-item trash">
                Approve
              </a>
              <div *ngIf="roleLevelId == 1" class="dropdown-divider"></div>
              <a *ngIf="roleLevelId == 1" data-toggle="modal" (click)="setSelectedTransaction(transaction)"
                data-target="#modal-reject" data-toggle-class-target=".animate" class="dropdown-item trash">
                Reject
              </a>
              <div *ngIf="roleLevelId == 1" class="dropdown-divider"></div> -->
              <!-- <a
                data-toggle="modal"
                (click)="setSelectedTransaction(transaction)"
                data-target="#modal-receipt"
                data-toggle-class-target=".animate"
                class="dropdown-item trash"
              >
                See Details
              </a> -->
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="row">
  <div class="col-sm-12 col-md-5">
    <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
      <div class="col-sm-12 col-md-12">
        <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
          Showing {{ (transactions?.pageNumber - 1) * transactions?.pageSize + 1 }} to
          {{ (transactions?.pageNumber - 1) * transactions?.pageSize + transactions?.data?.length }} of
          {{ transactions?.data?.length }} entries
        </div>
      </div>
    </div>
  </div>

  <div class="col-sm-12 col-md-7" style="float: right">
    <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
      <ul class="pagination" style="float: right; margin-right: 36px">
        <pagination-controls (pageChange)="getTransaction(buildPageUrl($event, 10))"></pagination-controls>
      </ul>
    </div>
  </div>
</div>

<ng-template #viewEctopup let-modal>
  <div class="modal-header">
    <h4 class="modal-title">[EC Topup] Transaction Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-ectopup-transaction (closeModal)="closeModal()" [requestId]="requestId"></app-ectopup-transaction>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>
