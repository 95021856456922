import { Component, OnInit } from "@angular/core";
import { TransactionService } from "src/data/services/web/transaction.service";

@Component({
  selector: "app-pos-transaction-details",
  templateUrl: "./pos-transaction-details.component.html",
  styleUrls: ["./pos-transaction-details.component.less"],
})
export class PosTransactionDetailsComponent implements OnInit {
  public transID: any;
  items;

  constructor(service: TransactionService) {
    this.items = service.getItemID(this.transID);
  }

  ngOnInit(): void {}

  getTransactionDetails(id: any): void {
   
    this.transID = id;
  }
}
