<nav id="topbar--main" class="navbar navbar-expand-sm navbar-light bg-light">
  <a href="{{ roleLevelName }}" class="nav-bar">
    <img
      src="https://juanpaycdn.azureedge.net/juanpayassets/LOGO_juanpay12x5.png"
      alt="company logo"
      class="vip--logo"
    />
  </a>
  <div class="navbar-collapse container-fluid">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item d-none d-md-block pl-4">
        <div class="user--withWallet">
          (<span id="roleName--header" class="text-dark font-weight-bold">{{ roleName }}</span
          >)
        </div>
      </li>
      <li class="nav-item d-none d-md-block pl-4">
        <div class="user--withWallet">
          <!-- <span *ngIf="roleLevelId == 2 && !isPartner && wallet != 'N/A'" class="text-dark font-weight-bold user--balance">GoVIPCenter Account No: </span>
          &nbsp;
          <span *ngIf="roleLevelId == 2 && !isPartner && wallet != 'N/A'" class="text-highlight font-weight-bold user--values">{{accountPrefix[0].value + userID.padStart(7,0)}}</span> -->

          &nbsp;&nbsp;&nbsp;
          <span *ngIf="!isPartner && wallet != 'N/A'" class="text-dark font-weight-bold user--balance">Balance: </span>
          &nbsp;
          <span *ngIf="!isPartner && wallet != 'N/A'" class="text-highlight font-weight-bold user--values">{{
            wallet | number: "1.2-2"
          }}</span>

          &nbsp;&nbsp;&nbsp;
          <span *ngIf="roleLevelId == 2 && !isPartner" class="text-dark font-weight-bold user--balance"
            >Rider Credit Wallet:
          </span>
          &nbsp;
          <span *ngIf="roleLevelId == 2 && !isPartner" class="text-highlight font-weight-bold user--values">{{
            riderCreditWallet | number: "1.2-2"
          }}</span>

          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span
            *ngIf="(roleLevelId == 2 || roleLevelId == 4) && roleId != 21 && isSalesUser"
            class="text-dark font-weight-bold user--balance"
            >Rewards:
          </span>
          &nbsp;
          <span
            *ngIf="(roleLevelId == 2 || roleLevelId == 4) && roleId != 21 && isSalesUser"
            class="text-highlight font-weight-bold user--values"
            >{{ _decimalPipe.transform(salesWallet?.vipcoBalance, "1.2-2") }}</span
          >

          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span
            *ngIf="(roleLevelId == 2 || roleLevelId == 4) && roleId != 21 && isSalesUser"
            class="text-dark font-weight-bold user--balance"
            >SP:
          </span>
          &nbsp;
          <span
            *ngIf="(roleLevelId == 2 || roleLevelId == 4) && roleId != 21 && isSalesUser"
            class="text-highlight font-weight-bold user--values"
            >{{ _decimalPipe.transform(salesWallet?.specialPointBalance, "1.2-2") }}</span
          >
        </div>
      </li>
    </ul>
    <ul class="navbar-nav">
      <li *ngIf="!isPartner" class="nav-item d-none d-md-block mt-2">
        <span class="font-weight-bold text-dark csr--code">CSR Code: </span>
        &nbsp;
        <span class="font-weight-bold csr--values">{{ csrCode }}</span>
      </li>
      <li *ngIf="!isPartner" class="nav-item d-none d-md-block">
        <a class="nav-link px-2" data-toggle="fullscreen" data-plugin="fullscreen">
          <i data-feather="maximize"></i>
        </a>
      </li>
      <li *ngIf="!isPartner" class="nav-item" id="user--announcement">
        <a class="nav-link px-2" (click)="displayAnnouncenment(content)">
          <i class="fa fa-envelope fa-2x"></i>
          <span class="badge badge-pill badge-up bg-info msg--alert">!</span>
        </a>
      </li>
      <li class="nav-item">
        <a href="#" data-toggle="dropdown">
          <span class="avatar w-35">
            <img
              class="img-side"
              src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file64407579-6a37-49ab-b083-b882c28eea4e.png"
              alt="..."
            />
          </span>
        </a>
        <!-- <div class="dropdown-menu dropdown-menu-right w mt-2 animate fadeIn signOut--toggle--menu pl-4">
          <div>
            <h1>QR</h1>
          </div>
          <hr>
          <a class="dropdown-item" (click)="logOut()">
            <i class="fas fa-sign-out-alt"> Logout</i>
          </a>
        </div> -->

        <div class="dropdown-menu dropdown-menu-right w mt-3 animate fadeIn p-4">
          <a
            class="dropdown-item"
            routerLink="{{ roleLevelName }}/{{ roleLevelName == 'acm' ? 'company' : roleLevelName }}-user-view"
            data-pjax-state=""
            ><span class="font-weight-bold">{{ fullname | uppercase }}</span></a
          >
          <p class="dropdown-item pb-2">
            (<span id="roleName--header" class="text-dark font-weight-bold">{{ roleName }}</span
            >)
          </p>

          <div class="dropdown-divider"></div>

          <a class="d-flex justify-content-center" (click)="openQR(myQRTemplateModal)" *ngIf="roleLevelId == 2">
            <ngx-qrcode
              [elementType]="elementType"
              [errorCorrectionLevel]="correctionLevel"
              [value]="value"
              cssClass="bshadow"
              [width]="180"
            ></ngx-qrcode>
          </a>

          <div class="dropdown-divider" *ngIf="roleLevelId == 2"></div>

          <a class="dropdown-item mt-2" (click)="logOut()" data-pjax-state="">Logout</a>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link animate" (click)="toggleSideNav()">
          <!-- <i data-feather="menu"></i> -->
          <i class="fa fa-bars fa-2x burger--button"></i>
        </a>
      </li>
    </ul>
  </div>
</nav>

<div class="container--sideNav container-fluid sidenav" [ngClass]="isToggled == true ? '' : 'hidden'">
  <div class="container p-3" style="margin-bottom: -80px">
    (<span id="roleName--header" class="text-dark font-weight-bold">{{ roleName }}</span
    >) <br />

    <span *ngIf="!isPartner && wallet != 'N/A'" class="text-highlight font-weight-bold user--values">{{
      wallet | number: "1.2-2"
    }}</span>
    <br />

    <span *ngIf="(roleLevelId == 2 || roleLevelId == 4) && isSalesUser" class="text-dark font-weight-bold user--balance"
      >Rewards:
    </span>

    <span
      *ngIf="(roleLevelId == 2 || roleLevelId == 4) && isSalesUser"
      class="text-highlight font-weight-bold user--values"
    >
      {{ _decimalPipe.transform(salesWallet?.vipcoBalance, "1.2-2") }}</span
    >
    <br />

    <span *ngIf="(roleLevelId == 2 || roleLevelId == 4) && isSalesUser" class="text-dark font-weight-bold user--balance"
      >SP:
    </span>

    <span
      *ngIf="(roleLevelId == 2 || roleLevelId == 4) && isSalesUser"
      class="text-highlight font-weight-bold user--values"
      >{{ _decimalPipe.transform(salesWallet?.specialPointBalance, "1.2-2") }}</span
    >
  </div>
  <app-side-nav></app-side-nav>
</div>

<!-- 
  (<span id="roleName--header" class="text-dark font-weight-bold">{{roleName}}</span>) 

  &nbsp;&nbsp;&nbsp;
  <span *ngIf="!isPartner" class="text-dark font-weight-bold user--balance">Balance: </span>
  &nbsp;
  <span *ngIf="!isPartner" class="text-highlight font-weight-bold user--values">{{wallet}}</span>

  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <span *ngIf="(roleLevelId == 2 || roleLevelId == 4) && isSalesUser" class="text-dark font-weight-bold user--balance">VIPCO: </span>
  &nbsp;
  <span *ngIf="(roleLevelId == 2 || roleLevelId == 4) && isSalesUser" class="text-highlight font-weight-bold user--values">{{ _decimalPipe.transform(salesWallet?.vipcoBalance, "1.2-2") }}</span>

  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <span *ngIf="(roleLevelId == 2 || roleLevelId == 4) && isSalesUser" class="text-dark font-weight-bold user--balance">SP: </span>
  &nbsp;
  <span *ngIf="(roleLevelId == 2 || roleLevelId == 4) && isSalesUser" class="text-highlight font-weight-bold user--values">{{ _decimalPipe.transform(salesWallet?.specialPointBalance, "1.2-2") }}</span>
  
-->

<!-- <ng-template #content let-modal let-c="close" let-d="dismiss">
  <div class="container--box">
    <div class="modal-header mb-0 pb-0">
      <h4 class="modal-title custom--modal--title" id="modal-basic-title">↫GoVIPCenter Announcement↬</h4>
    </div>
    <div class="modal-body" *ngIf="announcementString">
      <div [innerHtml]="announcementString" class="custom--modal--body"></div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline modal--button" (click)="('Close click')">Close</button>
    </div>
  </div>
</ng-template> -->

<ng-template #content let-modal>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true"></span>
  </button>
  <div class="container--box">
    <div class="modal-header d-flex justify-content-center">
      <h4 class="modal-title custom--modal--title" id="modal-basic-title">JuanPay Announcement</h4>
      <h6></h6>
    </div>
    <!-- <div class="modal-body mt-0 pt-0" *ngIf="announcementString">
    <div [innerHtml]="announcementString"></div>
  </div>
 -->
    <div class="modal-body" *ngIf="announcementString">
      <div [innerHtml]="announcementString" class="custom--modal--body pl-0 pr-0"></div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline modal--button" (click)="modal.dismiss('Cross click')">Close</button>
    </div>
  </div>
</ng-template>

<div #govipxQR hidden>
  <ngx-qrcode
    [elementType]="elementType"
    [errorCorrectionLevel]="correctionLevel"
    [value]="value"
    cssClass="bshadow"
    [width]="300"
    style="margin-left: 21px; margin-top: 290px; border-radius: 2%"
  ></ngx-qrcode>
</div>

<div #vipLogo hidden>
  <img
    src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file9828bf15-729f-4650-97a6-476c13f02ce0.png"
    alt="Go-VIP-Logo-Logo-Small"
  />
</div>

<ng-template #myQRTemplateModal let-modal>
  <!-- background: url(../../../assets/images/png\ QR.png) !important 
  linear-gradient(#044660, #02554c)-->

  <button class="btn btn-dark btn-lg" (click)="getQR(myQRTemplate)">Print &nbsp; <i class="fa fa-print"></i></button>
  <div class="d-flex justify-content-center">
    <div #myQRTemplate>
      <div
        style="
          background: url(../../../assets/images/Vips\ QR.png);
          background-repeat: no-repeat !important;
          background-size: 210mm 297mm !important;
          width: 210mm !important;
          height: 297mm !important;
        "
      >
        <div style="display: flex; justify-content: center">
          <ngx-qrcode
            [elementType]="elementType"
            [errorCorrectionLevel]="correctionLevel"
            [value]="value"
            cssClass="bshadow"
            [width]="300"
            style="margin-left: 10px; margin-top: 320px; border-radius: 2%"
          ></ngx-qrcode>
        </div>
        <div style="display: flex; justify-content: center">
          <div style="width: 180px; height: 35px; display: flex; justify-content: center; align-items: center">
            <h1 style="font-weight: bolder; font-family: Consolas" class="text-primary">
              {{ "V" + userID.padStart(5, 0) + username.substring(0, 2).toUpperCase() }}
            </h1>
          </div>
        </div>
        <div style="margin: 45px; display: flex; justify-content: center">
          <div style="width: 470px; height: 55px; display: flex; justify-content: center; align-items: center">
            <h3
              style="
                font-family: 'Segoe UI', Arial, sans-serif;
                max-width: 470px;
                text-align: center;
                font-weight: 700;
                color: #000;
              "
            >
              {{ dti }}
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
