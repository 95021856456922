<app-loader *ngIf="!managerUserFormGroup"></app-loader>
<app-loader *ngIf="processCreate"></app-loader>
<div *ngIf="managerUserFormGroup" id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">Create Manager</h2>
          <small class="text-highlight font-weight-normal font-italic">Make sure all the information are correct</small>
        </div>
        <div class="flex"></div>

        <div>
          <div
            data-toggle="modal"
            data-target="#modal-left"
            data-toggle-class="fade-left"
            data-toggle-class-target=".animate"
            class="btn btn-md btn-info"
          >
            <span class="d-none d-sm-inline mx-1">Save</span>
            <i data-feather="arrow-right"></i>
          </div>
        </div>

        <!-- (click)="saveManagerUser()"  -->
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="padding">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <strong>Account Information</strong>
              </div>
              <div class="card-body">
                <form>
                  <div class="form-group">
                    <div class="p-4">
                      <div class="d-flex">
                        <a href="#">
                          <span class="avatar w-64">
                            <img
                              *ngIf="managerUserFormGroup?.controls.photo.value"
                              [src]="managerUserFormGroup?.controls.photo.value | authImage | async"
                              alt="Juan"
                              onerror="this.src='https://via.placeholder.com/64x64?text=Juan';"
                            />
                            <img
                              *ngIf="!managerUserFormGroup?.controls.photo.value"
                              src="https://via.placeholder.com/64x64?text=Juan"
                              alt="Juan"
                            />
                            <i class="on"></i>
                          </span>
                        </a>
                        <div class="mx-3">
                          <h5 class="mt-2">
                            {{ managerUserFormGroup?.controls.firstName.value }}
                            {{ managerUserFormGroup?.controls.lastName.value }}
                          </h5>
                          <div class="text-fade text-sm">
                            <span class="m-r"></span>
                            <small> <input id="file-upload" type="file" (change)="onPhotoChange($event)" /></small>
                          </div>
                          <span *ngIf="managerUserFormGroup?.controls.photo.errors | estatus" class="error-msg"
                            ><small>{{ managerUserFormGroup?.controls.photo.errors | econtent }}</small></span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <app-textbox
                    [parent-form-group]="managerUserFormGroup"
                    tab-index-start="0"
                    control-name="username"
                    [error]="managerUserFormGroup?.controls.username.errors | estatus"
                    [error-message]="managerUserFormGroup?.controls.username.errors | econtent"
                    read-only="false"
                    label="Username"
                    placeholder="Username"
                    (keyup.enter)="checkMemberDetails()"
                  >
                  </app-textbox>
                  <!-- <button class="btn btn-primary" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span class="sr-only">Loading...</span>
                  </button> -->

                  <app-textbox
                    [parent-form-group]="managerUserFormGroup"
                    tab-index-start="0"
                    control-name="email"
                    [error]="managerUserFormGroup?.controls.email.errors | estatus"
                    [error-message]="managerUserFormGroup?.controls.email.errors | econtent"
                    read-only="false"
                    label="Email Address"
                    placeholder="Email Address"
                  >
                  </app-textbox>

                  <app-dropdown
                    place-holder="Role"
                    [parent-form-group]="managerUserFormGroup"
                    control-name="roleId"
                    label="Role"
                    [data]="roles"
                    [error]="managerUserFormGroup?.controls.roleId.errors | estatus"
                    [error-message]="managerUserFormGroup?.controls.roleId.errors | econtent"
                  >
                  </app-dropdown>

                  <!-- <app-textbox
                    [parent-form-group]="managerUserFormGroup"
                    tab-index-start="0"
                    control-name="roleId"
                    value="Manager"
                    [error]="managerUserFormGroup?.controls.roleId.errors | estatus"
                    [error-message]="managerUserFormGroup?.controls.roleId.errors | econtent"
                    read-only="false"
                    label="Role"
                    placeholder="Role"
                  >
                  </app-textbox> -->

                  <!-- <app-dropdown
                    place-holder="Role"
                    [parent-form-group]="managerUserFormGroup"
                    control-name="roleId"
                    label="Role"
                    [data]="roles"
                    [error]="managerUserFormGroup?.controls.roleId.errors | estatus"
                    [error-message]="managerUserFormGroup?.controls.roleId.errors | econtent"
                  >
                  </app-dropdown> -->

                  <div style="margin-bottom: 58px">
                    <app-boolslider
                      *ngIf="true"
                      [parentFormGroup]="managerUserFormGroup"
                      controlName="status"
                      label="Account"
                    >
                    </app-boolslider>
                  </div>
                </form>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <strong>Personal Information</strong>
              </div>
              <div class="card-body">
                <form>
                  <app-textbox
                    [parent-form-group]="managerUserFormGroup"
                    tab-index-start="0"
                    control-name="firstName"
                    [error]="managerUserFormGroup?.controls.firstName.errors | estatus"
                    [error-message]="managerUserFormGroup?.controls.firstName.errors | econtent"
                    read-only="false"
                    no-special-char="false"
                    label="First name"
                    placeholder="First name"
                  >
                  </app-textbox>

                  <app-textbox
                    [parent-form-group]="managerUserFormGroup"
                    tab-index-start="0"
                    control-name="lastName"
                    [error]="managerUserFormGroup?.controls.lastName.errors | estatus"
                    [error-message]="managerUserFormGroup?.controls.lastName.errors | econtent"
                    read-only="false"
                    no-special-char="false"
                    label="Last name"
                    placeholder="Last name"
                  >
                  </app-textbox>

                  <app-textbox
                    [parent-form-group]="managerUserFormGroup"
                    tab-index-start="0"
                    control-name="contactNumber"
                    [error]="managerUserFormGroup?.controls.contactNumber.errors | estatus"
                    [error-message]="managerUserFormGroup?.controls.contactNumber.errors | econtent"
                    read-only="false"
                    label="Contact No."
                    placeholder="Contact No."
                  >
                  </app-textbox>

                  <app-textarea
                    [parent-form-group]="managerUserFormGroup"
                    tab-index-start="0"
                    control-name="address"
                    [error]="managerUserFormGroup?.controls.address.errors | estatus"
                    [error-message]="managerUserFormGroup?.controls.address.errors | econtent"
                    read-only="false"
                    label="Address"
                    placeholder="Address"
                  >
                  </app-textarea>

                  <!-- <app-datepicker
                    [parent-form-group]="managerUserFormGroup"
                    tab-index-start="0"
                    control-name="birthDate"
                    [error]="managerUserFormGroup?.controls.birthDate.errors | estatus"
                    [error-message]="managerUserFormGroup?.controls.birthDate.errors | econtent"
                    no-special-char="false"
                    label="Birth Date "
                    placeholder="Birth Date "
                  >
                  </app-datepicker> -->

                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <input type="file" (change)="onDTIUpload($event)" class="custom-file-input" id="inputFile" />
                      <label class="custom-file-label" for="inputFile">{{ fileName }}</label>
                    </div>
                    <span *ngIf="managerUserFormGroup?.controls.dtiForm.errors | estatus" class="text-error"
                      ><small>{{ managerUserFormGroup?.controls.dtiForm.errors | econtent }}</small></span
                    >
                  </div>

                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <input
                        type="file"
                        (change)="onApplicationFormUpload($event)"
                        class="custom-file-input"
                        id="inputFile1"
                      />
                      <label class="custom-file-label" for="inputFile1">{{ appfileName }}</label>
                    </div>
                    <span *ngIf="managerUserFormGroup?.controls.applicationForm.errors | estatus" class="text-error"
                      ><small>{{ managerUserFormGroup?.controls.applicationForm.errors | econtent }}</small></span
                    >
                  </div>

                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <input
                        type="file"
                        (change)="onValidID1Upload($event)"
                        class="custom-file-input"
                        id="inputFile2"
                      />
                      <label class="custom-file-label" for="inputFile2">{{ valid1FileName }}</label>
                    </div>
                    <span *ngIf="managerUserFormGroup?.controls.validID1.errors | estatus" class="text-error"
                      ><small>{{ managerUserFormGroup?.controls.validID1.errors | econtent }}</small></span
                    >
                  </div>

                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <input
                        type="file"
                        (change)="onValidID2Upload($event)"
                        class="custom-file-input"
                        id="inputFile3"
                      />
                      <label class="custom-file-label" for="inputFile3">{{ valid2FileName }}</label>
                    </div>
                    <span *ngIf="managerUserFormGroup?.controls.validID2.errors | estatus" class="text-error"
                      ><small>{{ managerUserFormGroup?.controls.validID2.errors | econtent }}</small></span
                    >
                  </div>

                  <!-- <div
                    *ngIf="!managerUserFormGroup?.controls.officialReceiptPhoto.value"
                    class="dropzone white b-a b-3x b-dashed b-primary p-a rounded p-5 text-center"
                  >
                    <br />
                    <br />
                    <br />
                    <h4 class="my-4">Upload DTI Permit.</h4>
                    <br />
                    <br />
                    <br />
                  </div>
                  <img
                    *ngIf="managerUserFormGroup?.controls.officialReceiptPhoto.value"
                    [src]="managerUserFormGroup?.controls.officialReceiptPhoto.value | authImage | async"
                    alt="VIP"
                    onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
                    style="width: 100%"
                  />

                  <div
                    *ngIf="!managerUserFormGroup?.controls.officialReceiptPhoto.value"
                    class="dropzone white b-a b-3x b-dashed b-primary p-a rounded p-5 text-center"
                  >
                    <br />
                    <br />
                    <br />
                    <h4 class="my-4">Upload Application Form.</h4>
                    <br />
                    <br />
                    <br />
                  </div>
                  <img
                    *ngIf="managerUserFormGroup?.controls.officialReceiptPhoto.value"
                    [src]="managerUserFormGroup?.controls.officialReceiptPhoto.value | authImage | async"
                    alt="VIP"
                    onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
                    style="width: 100%"
                  /> -->

                  <div class="row">
                    <div class="col-sm-6 mb-2">
                      <div
                        *ngIf="!managerUserFormGroup?.controls.dtiForm.value"
                        class="dropzone white b-a b-3x b-dashed b-primary p-a rounded p-5 text-center"
                      >
                        <br />
                        <h4 class="my-4">Upload DTI Permit.</h4>
                        <br />
                      </div>
                      <img
                        *ngIf="managerUserFormGroup?.controls.dtiForm.value"
                        [src]="managerUserFormGroup?.controls.dtiForm.value | authImage | async"
                        alt="VIP"
                        onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
                        style="width: 100%"
                      />
                    </div>
                    <div class="col-sm-6 mb-2">
                      <div
                        *ngIf="!managerUserFormGroup?.controls.applicationForm.value"
                        class="dropzone white b-a b-3x b-dashed b-primary p-a rounded p-5 text-center"
                      >
                        <br />
                        <h4 class="my-4">Upload Application.</h4>
                        <br />
                      </div>
                      <img
                        *ngIf="managerUserFormGroup?.controls.applicationForm.value"
                        [src]="managerUserFormGroup?.controls.applicationForm.value | authImage | async"
                        alt="VIP"
                        onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
                        style="width: 100%"
                      />
                    </div>
                    <div class="col-sm-6">
                      <div
                        *ngIf="!managerUserFormGroup?.controls.validID1.value"
                        class="dropzone white b-a b-3x b-dashed b-primary p-a rounded p-5 text-center"
                      >
                        <br />
                        <h4 class="my-4">Upload 2 Valid ID.</h4>
                        <br />
                      </div>
                      <img
                        *ngIf="managerUserFormGroup?.controls.validID1.value"
                        [src]="managerUserFormGroup?.controls.validID1.value | authImage | async"
                        alt="VIP"
                        onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
                        style="width: 100%"
                      />
                    </div>
                    <div class="col-sm-6">
                      <div
                        *ngIf="!managerUserFormGroup?.controls.validID2.value"
                        class="dropzone white b-a b-3x b-dashed b-primary p-a rounded p-5 text-center"
                      >
                        <br />
                        <h4 class="my-4">Upload 2 Valid ID.</h4>
                        <br />
                      </div>
                      <img
                        *ngIf="managerUserFormGroup?.controls.validID2.value"
                        [src]="managerUserFormGroup?.controls.validID2.value | authImage | async"
                        alt="VIP"
                        onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
                        style="width: 100%"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <strong>Store Activation Details</strong>
              </div>
              <div class="card-body">
                <form>
                  <!-- <app-datepicker
                    [parent-form-group]="storeActivationFormGroup"
                    tab-index-start="0"
                    control-name="mainActivationDate"
                    [error]="storeActivationFormGroup?.controls.mainActivationDate.errors | estatus"
                    [error-message]="storeActivationFormGroup?.controls.mainActivationDate.errors | econtent"
                    label="Activation Date "
                    [read-only]="roleLevelId == 2"
                    placeholder="Activation Date "
                  >
                  </app-datepicker>

                  <app-datepicker
                    [parent-form-group]="storeActivationFormGroup"
                    tab-index-start="0"
                    control-name="enterpriseActivationDate"
                    [error]="storeActivationFormGroup?.controls.enterpriseActivationDate.errors | estatus"
                    [error-message]="storeActivationFormGroup?.controls.enterpriseActivationDate.errors | econtent"
                    label="Enterprise Activation Date "
                    [read-only]="roleLevelId == 2"
                    placeholder="Enterprise Activation Date "
                  >
                  </app-datepicker> -->

                  <app-textbox
                    [parent-form-group]="managerUserFormGroup"
                    tab-index-start="0"
                    control-name="mainActivationDate"
                    [error]="managerUserFormGroup?.controls.mainActivationDate?.errors | estatus"
                    [error-message]="managerUserFormGroup?.controls.mainActivationDate?.errors | econtent"
                    [read-only]="true"
                    label="Activation Date"
                    placeholder="Activation Date"
                  >
                  </app-textbox>

                  <app-textbox
                    [parent-form-group]="managerUserFormGroup"
                    tab-index-start="0"
                    control-name="enterpriseActivationDate"
                    [error]="managerUserFormGroup?.controls.enterpriseActivationDate?.errors | estatus"
                    [error-message]="managerUserFormGroup?.controls.enterpriseActivationDate?.errors | econtent"
                    [read-only]="true"
                    label="Enterprise Activation Date"
                    placeholder="Enterprise Activation Date"
                  >
                  </app-textbox>

                  <app-textbox
                    [parent-form-group]="managerUserFormGroup"
                    tab-index-start="0"
                    control-name="sponsor"
                    [error]="managerUserFormGroup?.controls.sponsor?.errors | estatus"
                    [error-message]="managerUserFormGroup?.controls.sponsor?.errors | econtent"
                    [read-only]="true"
                    label="Sponsor"
                    placeholder="Sponsor"
                  >
                  </app-textbox>

                  <app-textbox
                    [parent-form-group]="managerUserFormGroup"
                    tab-index-start="0"
                    control-name="upline"
                    [error]="managerUserFormGroup?.controls.upline?.errors | estatus"
                    [error-message]="managerUserFormGroup?.controls.upline?.errors | econtent"
                    [read-only]="true"
                    label="Upline"
                    placeholder="Upline"
                  >
                  </app-textbox>

                  <app-textbox
                    [parent-form-group]="managerUserFormGroup"
                    tab-index-start="0"
                    control-name="placement"
                    [error]="managerUserFormGroup?.controls.placement?.errors | estatus"
                    [error-message]="managerUserFormGroup?.controls.placement?.errors | econtent"
                    [read-only]="true"
                    label="Placement"
                    placeholder="Placement"
                  >
                  </app-textbox>

                  <app-textbox
                    [parent-form-group]="managerUserFormGroup"
                    tab-index-start="0"
                    control-name="accountType"
                    [error]="managerUserFormGroup?.controls.accountType?.errors | estatus"
                    [error-message]="managerUserFormGroup?.controls.accountType?.errors | econtent"
                    [read-only]="true"
                    label="Account Type"
                    placeholder="Account Type"
                  >
                  </app-textbox>

                  <!-- <app-textbox
                    [parent-form-group]="managerUserFormGroup"
                    tab-index-start="0"
                    control-name="storeType"
                    [error]="managerUserFormGroup?.controls.storeType?.errors | estatus"
                    [error-message]="managerUserFormGroup?.controls.storeType?.errors | econtent"
                    [read-only]="true"
                    label="Store Type"
                    placeholder="Store Type"
                  >
                  </app-textbox> -->

                  <app-dropdown-identifier
                    place-holder="Store Type"
                    [parent-form-group]="managerUserFormGroup"
                    control-name="storeType"
                    label="Store Type :"
                    identifier="STORE_TYPE"
                  >
                  </app-dropdown-identifier>

                  <!-- <app-dropdown
                    place-holder="0"
                    [parent-form-group]="managerUserFormGroup"
                    control-name="storeType"
                    label="Store Type"
                    [data]="storeTypeOption"
                    [error]="managerUserFormGroup?.controls.storeType.errors | estatus"
                    [error-message]="managerUserFormGroup?.controls.storeType.errors | econtent"
                  >
                  </app-dropdown> -->
                </form>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <strong>Store Settings</strong>
              </div>
              <div class="card-body">
                <app-textbox
                  [parent-form-group]="managerUserFormGroup"
                  tab-index-start="0"
                  control-name="name"
                  [error]="managerUserFormGroup?.controls.name?.errors | estatus"
                  [error-message]="managerUserFormGroup?.controls.name?.errors | econtent"
                  [read-only]="roleLevelId == 2"
                  label="Store Name"
                  placeholder="Store Name"
                >
                </app-textbox>

                <app-dropdown
                  *ngIf="roleId == 7 || roleId == 1 || roleId == 2"
                  place-holder="0"
                  [parent-form-group]="managerUserFormGroup"
                  control-name="maxCashiers"
                  label="Max Cashiers"
                  [data]="maxCashiersArray"
                  [error]="managerUserFormGroup?.controls.maxCashiers.errors | estatus"
                  [error-message]="managerUserFormGroup?.controls.maxCashiers.errors | econtent"
                >
                </app-dropdown>

                <app-dropdown-identifier
                  place-holder="Remarks"
                  [parent-form-group]="managerUserFormGroup"
                  control-name="accountRemarks"
                  label="Remarks :"
                  identifier="ACCOUNT_REMARKS"
                >
                </app-dropdown-identifier>

                <!-- <app-textbox
                  *ngIf="roleId == 3"
                  [parent-form-group]="managerUserFormGroup"
                  tab-index-start="0"
                  control-name="maxCashiers"
                  [error]="managerUserFormGroup?.controls.maxCashiers?.errors | estatus"
                  [error-message]="managerUserFormGroup?.controls.maxCashiers?.errors | econtent"
                  [read-only]="roleId == 3"
                  no-special-char="false"
                  label="Max Cashiers"
                  placeholder="Max Cashiers"
                >
                </app-textbox> -->
              </div>

              <div class="card-header">
                <strong>Maximum amount limit per day</strong>
              </div>
              <div class="card-body">
                <app-textbox-currency
                  [parent-form-group]="managerUserFormGroup"
                  tab-index-start="0"
                  control-name="maxBillsAmountPerDay"
                  [read-only]="roleLevelId == 2"
                  [error]="managerUserFormGroup?.controls.maxBillsAmountPerDay?.errors | estatus"
                  [error-message]="managerUserFormGroup?.controls.maxBillsAmountPerDay?.errors | econtent"
                  read-only="false"
                  no-special-char="false"
                  label="Bills"
                  placeholder="0.00"
                  max-length="20"
                >
                </app-textbox-currency>

                <app-textbox-currency
                  [parent-form-group]="managerUserFormGroup"
                  tab-index-start="0"
                  control-name="maxRemittanceAmountPerDay"
                  [read-only]="roleLevelId == 2"
                  [error]="managerUserFormGroup?.controls.maxRemittanceAmountPerDay?.errors | estatus"
                  [error-message]="managerUserFormGroup?.controls.maxRemittanceAmountPerDay?.errors | econtent"
                  read-only="false"
                  no-special-char="false"
                  label="Remittance"
                  placeholder="0.00"
                  max-length="20"
                >
                </app-textbox-currency>

                <app-textbox-currency
                  [parent-form-group]="managerUserFormGroup"
                  tab-index-start="0"
                  control-name="maxEpinsAmountPerDay"
                  [read-only]="roleLevelId == 2"
                  [error]="managerUserFormGroup?.controls.maxEpinsAmountPerDay?.errors | estatus"
                  [error-message]="managerUserFormGroup?.controls.maxEpinsAmountPerDay?.errors | econtent"
                  read-only="false"
                  no-special-char="false"
                  label="EPins"
                  placeholder="0.00"
                  max-length="20"
                >
                </app-textbox-currency>

                <app-textbox-currency
                  [parent-form-group]="managerUserFormGroup"
                  tab-index-start="0"
                  control-name="maxEloadAmountPerDay"
                  [read-only]="roleLevelId == 2"
                  [error]="managerUserFormGroup?.controls.maxEloadAmountPerDay?.errors | estatus"
                  [error-message]="managerUserFormGroup?.controls.maxEloadAmountPerDay?.errors | econtent"
                  read-only="false"
                  no-special-char="false"
                  label="ELoad"
                  placeholder="0.00"
                  max-length="20"
                >
                </app-textbox-currency>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <strong>Store Address</strong>
              </div>
              <div class="card-body">
                <app-textbox
                  [parent-form-group]="managerUserFormGroup"
                  tab-index-start="0"
                  control-name="number"
                  [error]="managerUserFormGroup?.controls.number?.errors | estatus"
                  [error-message]="managerUserFormGroup?.controls.number?.errors | econtent"
                  [read-only]="roleLevelId == 2"
                  label="House Number"
                  placeholder="House Number"
                >
                </app-textbox>

                <app-textbox
                  [parent-form-group]="managerUserFormGroup"
                  tab-index-start="0"
                  control-name="street"
                  [error]="managerUserFormGroup?.controls.street?.errors | estatus"
                  [error-message]="managerUserFormGroup?.controls.street?.errors | econtent"
                  [read-only]="roleLevelId == 2"
                  label="Street"
                  placeholder="Street"
                >
                </app-textbox>

                <app-textbox
                  [parent-form-group]="managerUserFormGroup"
                  tab-index-start="0"
                  control-name="subdivision"
                  [error]="managerUserFormGroup?.controls.subdivision?.errors | estatus"
                  [error-message]="managerUserFormGroup?.controls.subdivision?.errors | econtent"
                  [read-only]="roleLevelId == 2"
                  label="Subdivision"
                  placeholder="Subdivision"
                >
                </app-textbox>

                <app-textbox
                  [parent-form-group]="managerUserFormGroup"
                  tab-index-start="0"
                  control-name="barangay"
                  [error]="managerUserFormGroup?.controls.barangay?.errors | estatus"
                  [error-message]="managerUserFormGroup?.controls.barangay?.errors | econtent"
                  [read-only]="roleLevelId == 2"
                  label="Barangay"
                  placeholder="Barangay"
                >
                </app-textbox>

                <app-textbox
                  [parent-form-group]="managerUserFormGroup"
                  tab-index-start="0"
                  control-name="city"
                  [error]="managerUserFormGroup?.controls.city?.errors | estatus"
                  [error-message]="managerUserFormGroup?.controls.city?.errors | econtent"
                  [read-only]="roleLevelId == 2"
                  label="Municipality"
                  placeholder="Municipality"
                >
                </app-textbox>

                <app-textbox
                  [parent-form-group]="managerUserFormGroup"
                  tab-index-start="0"
                  control-name="municipality"
                  [error]="managerUserFormGroup?.controls.municipality?.errors | estatus"
                  [error-message]="managerUserFormGroup?.controls.municipality?.errors | econtent"
                  [read-only]="roleLevelId == 2"
                  label="City"
                  placeholder="City"
                >
                </app-textbox>

                <app-textbox
                  [parent-form-group]="managerUserFormGroup"
                  tab-index-start="0"
                  control-name="region"
                  [error]="managerUserFormGroup?.controls.region?.errors | estatus"
                  [error-message]="managerUserFormGroup?.controls.region?.errors | econtent"
                  [read-only]="roleLevelId == 2"
                  label="Region"
                  placeholder="Region"
                >
                </app-textbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<div id="modal-left" class="modal fade" data-backdrop="true">
  <div class="modal-dialog animate">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title text-md">Updating of User</div>
        <button class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="p-4 text-center">
          <p>Are you sure you want continue?</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
        <button type="button" (click)="actionEventCreateManagerUser()" class="btn btn-primary" data-dismiss="modal">
          Save Changes
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
</div>
