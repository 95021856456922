<app-loader *ngIf="loader"></app-loader>
<div class="modal-body" *ngIf="activeTab != 3">
  <form id=" form" novalidate="">
    <div id="rootwizard">
      <ul class="nav mb-3">
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 1 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >1</span
            >
            <div class="mt-2">
              <div class="text-muted">SPX Remittance</div>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 2 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >2</span
            >
            <div class="mt-2">
              <div class="text-muted">Confirmation</div>
            </div>
          </a>
        </li>
        <li  class="nav-item" style="margin-left: auto">
          <a class="nav-link text-center" [ngClass]="activeTab == 3 ? 'active' : ''">
            <img [src]="application.value.thumbnail.logo" height="50" class="img-responsive" style="object-fit: contain" />
          </a>
        </li>
      </ul>

      <hr class="bg-dark" />

      <div class="tab-content p-3">
        <div class="tab-pane" [ngClass]="activeTab == 1 ? 'active' : ''" id="selectBillerTab">
          <div class="row">
            <!-- <div class="col-sm-12 col-md-2 d-flex justify-content-center align-items-center">
              <img
                [src]="application.value.thumbnail.logo"
                height="100"
                width="100"
                class="img-responsive"
                style="object-fit: cover"
              />
            </div> -->
            <div class="col-sm-12 col-md-1"></div>
            <div class="col-sm-12 col-md-4 d-flex flex-wrap">
              <h4>{{ application.value.thumbnail.title }}</h4>
              <h6>
                {{ application.value.acquired.description }}
              </h6>
            </div>
            <div class="col-sm-12 col-md-1"></div>
            <div class="col-sm-12 col-md-5">

              <app-textbox
                [parent-form-group]="spxRemittanceFormGroup"
                tab-index-start="1"
                control-name="lifetimeID"
                [error]="spxRemittanceFormGroup?.controls.lifetimeID.errors"
                read-only="false"
                [no-special-char]="true"
                label="SPX Rider ID No"
                placeholder="SPX Rider ID No"
                max-length="8"
              >
              </app-textbox>

              <app-textbox
                [parent-form-group]="spxRemittanceFormGroup"
                tab-index-start="2"
                control-name="fmsDriverName"
                [error]="spxRemittanceFormGroup?.controls.fmsDriverName.errors"
                read-only="true"
                label="FMS Driver Name"
                placeholder="FMS Driver Name"
                max-length="50"
              >
              </app-textbox>

              <app-textbox
                [parent-form-group]="spxRemittanceFormGroup"
                tab-index-start="3"
                control-name="hubLocation"
                [error]="spxRemittanceFormGroup?.controls.hubLocation.errors"
                read-only="true"
                label="Hub Location"
                placeholder="Hub Location"
                max-length="50"
              >
              </app-textbox>

              <app-textbox-number-only 
                [parent-form-group]="spxRemittanceFormGroup" 
                tab-index-start="6"
                control-name="mobileNo"
                [error]="spxRemittanceFormGroup?.controls.mobileNo.errors"
                read-only=false
                label="Mobile No"
                placeholder="Mobile No (Optional)"
                max-length="12">
              </app-textbox-number-only>

              <hr>

              <app-textbox-currency
                  [parent-form-group]="spxRemittanceFormGroup" 
                  tab-index-start="8"
                  control-name="amount"
                  [read-only]="false"
                  [error]="spxRemittanceFormGroup?.controls.amount.errors"
                  number-only="false"
                  read-only="false"
                  label="Amount"
                  placeholder="0.00"
                  max-length="10"
                >
              </app-textbox-currency>

              <!-- <app-textbox
                [parent-form-group]="spxRemittanceFormGroup"
                tab-index-start="4"
                control-name="sPXRiderID"
                [error]="spxRemittanceFormGroup?.controls.sPXRiderID.errors"
                read-only="false"
                label="SPX Rider ID No"
                placeholder="SPX Rider ID No"
                max-length="20"
                >
              </app-textbox>

              <app-textbox-number-only 
                [parent-form-group]="spxRemittanceFormGroup" 
                tab-index-start="5"
                control-name="age" 
                [error]="spxRemittanceFormGroup?.controls.age.errors"
                read-only=false
                label="Age"
                placeholder="Age"
                max-length="3">
              </app-textbox-number-only>

              <app-textarea
                [parent-form-group]="spxRemittanceFormGroup"
                tab-index-start="7"
                control-name="address"
                [error]="spxRemittanceFormGroup?.controls.address.errors | estatus"
                read-only="false"
                label="Address"
                placeholder="Address"
              >
              </app-textarea> -->

            </div>
            <!-- <div class="col-sm-12 col-md-4">
                
              <ngx-dropzone [multiple]="false" (change)="onSelect($event)" style="height:200px">
                <ngx-dropzone-label>Click to upload or drop the image here!</ngx-dropzone-label>
              
                <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" 
                [removable]="true" (removed)="onRemove(f)">
                  <ngx-dropzone-label></ngx-dropzone-label>
                </ngx-dropzone-image-preview>
              </ngx-dropzone>

            </div> -->
            <div class="col-sm-12 col-md-1"></div>
          </div>
        </div>

        <div class="tab-pane" [ngClass]="activeTab == 2 ? 'active' : ''" id="newTransTab">
          <div class="form-group">
            <p>
              <strong>SPX Rider Remittance Confirmation</strong><br />
              <small>Please make sure that all details are correct.</small>
            </p>
            <div class="card">
              <div class="list list-row">
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">SPX Rider ID No</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ spxRemittanceFormGroup.controls.lifetimeID.value }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">FMS Driver Name</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ spxRemittanceFormGroup.controls.fmsDriverName.value }}
                    </div>
                  </div>
                </div>

                
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Hub Location</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ spxRemittanceFormGroup.controls.hubLocation.value }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Mobile Number</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ spxRemittanceFormGroup.controls.mobileNo.value }}
                    </div>
                  </div>
                </div>

                <hr />
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Total Amount</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ spxRemittanceFormGroup.controls.amount.value }}
                    </div>
                  </div>
                </div>
                <hr />
                <div class="col-sm-12 col-md-12">
                  <div class="col-sm-12 col-md-6" style="float: none; margin: auto">
                    <div class="item-feed h-2x text-center">ENTER PINCODE</div>
                    <code-input
                      [isCodeHidden]="true"
                      [codeLength]="6"
                      (codeChanged)="onCodeChanged($event)"
                      (codeCompleted)="onCodeCompleted($event)"
                      [code]="code"
                    >
                    </code-input>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>

        <!-- previous next -->
        <div class="row py-3">
          <div class="col-6">
            <a class="btn btn-white button-previous" *ngIf="activeTab != 1" (click)="setActiveTabPrev()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-arrow-left"
              >
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
              &nbsp; Previous
            </a>
          </div>
          <div class="col-6">
            <div class="d-flex justify-content-end">
              <a *ngIf="activeTab != 2 && spxRemittanceFormGroup.status == 'VALID'" class="btn btn-white button-next" (click)="setActiveTab()">
                Next &nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-arrow-right"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </a>
              <a
                *ngIf="activeTab == 2 && codeStatus"
                appDebounceClick 
                [debounceTime]="500" 
                (debounceClick)="processPayment()"
                class="btn btn-info button-next"
                style="color: white"
              >
                Process SPX Remittance &nbsp;
                <span class="fa fa-hand-pointer"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
