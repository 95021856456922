import { Component, OnInit, ɵCodegenComponentFactoryResolver } from '@angular/core';
import { SettlementsService } from 'src/data/services/web/settlements.service';
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5
import { Select2OptionData } from "ng-select2";
import { CryptoService } from 'src/data/crypto/crypto.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SunlifeGrepaExportExcelService } from 'src/data/services/web/sunlife-grepa-export-excel.service';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import * as XLSX from 'ts-xlsx';
import * as FileSaver from 'file-saver';
import { SpxRemittanceService } from 'src/data/services/web/spx-remittance.service';
import { FileUploadService } from 'src/data/services/web/file-upload.service';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-spx-remittance-settlement',
  templateUrl: './spx-remittance-settlement.component.html',
  styleUrls: ['./spx-remittance-settlement.component.less']
})
export class SpxRemittanceSettlementComponent implements OnInit {

  dpConfig: Partial<BsDatepickerConfig>;
  public uploadModal = null;
  public fileUploadFormGroup: FormGroup;
  emptyString = '';
  p: number = 1;
  pgvc: number = 1;
  public pageRows: any = 10;
  public pageRowsGVC: any = 10;
  
  constructor(private settlementService : SettlementsService,
    private spxService : SpxRemittanceService,
    private websettings : WebSettingsService,
    private modalServe: NgbModal,
    private router: Router,
    private localStorageCacheService: LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    formBuilder : FormBuilder,
    private fileUpload: FileUploadService,
    public ete: SunlifeGrepaExportExcelService,
    private route: ActivatedRoute, 
    private cryptoService: CryptoService,
    public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.roleId = this.localStorageCacheService.getStorage("roleId");

    this.initFileUploadFormGroup();
    PNotifyButtons;
    this.initSearchFormGroup();
    this.getTransaction();
    this.getGVCTransaction();
    this.getShowRows();
    this.getStatus();

    this.dpConfig = new BsDatepickerConfig();
    this.dpConfig.containerClass = "theme-dark-blue";
    this.dpConfig.dateInputFormat = "YYYY-MM-DD";
    this.dpConfig.isAnimated = true;
  }

  roleId: any;
  loader = false;
  canCreate: boolean = false;
  totalAmount: any = 0;
  totalGVCAmount: any = 0;
  selectedDateRangeTransaction: any = [];
  selectedGVCDateRangeTransaction: any = [];
  initFileUploadFormGroup(): void {
    this.fileUploadFormGroup = new FormGroup({
      daterange: new FormControl(this.emptyString),
      deyt: new FormControl(this.emptyString)
    });

    this.fileUploadFormGroup.controls.daterange.valueChanges.subscribe((value) => {
      var deyt = `${this.datepipe.transform(value[0], 'yyyy-MM-dd')},${this.datepipe.transform(value[1], 'yyyy-MM-dd')}`;

      if(deyt != null || deyt != ''){
        this.settlementService.getTransactionByDateRange(deyt).subscribe((result) => {
          if(result.length == 0){
            this.canCreate = false;
          } else {
            this.canCreate = true;
          }
  
          this.selectedDateRangeTransaction = result;
          this.totalAmount = 0;
          result.forEach((element) => {
            this.totalAmount += element.amount;
          });
  
        });
      }
    });

    this.fileUploadFormGroup.controls.deyt.valueChanges.subscribe((value) => {
      var deyt = `${this.datepipe.transform(value, 'yyyy-MM-dd')}`;

      //alert(deyt);
      if(deyt != null || deyt != ''){
        this.settlementService.getGVCTransactionByDateRange(deyt).subscribe((result) => {
          if(result.length == 0){
            this.canCreate = false;
          } else {
            this.canCreate = true;
          }
  
          this.selectedGVCDateRangeTransaction = result;
          this.totalGVCAmount = 0;
          result.forEach((element) => {
            this.totalGVCAmount += element.amount;
          });
  
        });
      }
    });
  }

  onDateChange(date: any){
    alert(date);
  }


  openFileUpload(content: any) : void {
    this.listItems = [];
    this.uploadModal = this.modalServe.open(content, { centered: true, size: "lg" });
  }

  public selectedRowsId: any = 0;
  selectedRows(e){
    this.selectedRowsId = e;
    this.pageRows = e;
  }
  
  public selectedRowsIdgvc: any = 0;
  selectedRowsGVC(e){
    this.selectedRowsIdgvc = e;
    this.pageRowsGVC = e;
  }

  settlementBanksArray = [];
  getSettlementBank(id : any) {

    if(id){
    var settlementBanksArray = [
      { id: "0001", name: "UNION BANK" },
      { id: "0102", name: "ASIA UNITED BANK" },
      { id: "0053", name: "BANCO DE ORO" },
      { id: "0004", name: "BANK OF THE PHILIPPINE ISLANDS" },
      { id: "0030", name: "BDO NETWORK BANK" },
      { id: "0010", name: "CHINABANK" },
      { id: "0035", name: "LANDBANK OF THE PHILS" },
      { id: "0026", name: "METROBANK" },
      { id: "0008", name: "PHILIPPINE NATIONAL BANK" },
      { id: "0670", name: "QUEZON CAPITAL RURAL BANK" },
      { id: "0014", name: "SECURITY BANK" },
    ];

   return settlementBanksArray.filter(x=>x.id == id)[0].name;
  }else{
    return this.emptyString;
  }
  }

 listItems = [];
  
  public fileName: any = "Click here to upload Remittance Receipt";
  public onFileChange(event: any) {
    this.loader = true;
    const target: DataTransfer = <DataTransfer>(event.target);
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.fileName = file.name;
      reader.readAsBinaryString(target.files[0]);
      
      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.IWorkBook = XLSX.read(binarystr, { type: 'binary' });
  
        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.IWorkSheet = wb.Sheets[wsname];
  
        /* save data */
        var listItems = [];
        const dataXLS = XLSX.utils.sheet_to_json(ws, { header:1 }); // to get 2d array pass 2nd parameter as object {header: 1}
        for (let index = 2; index < dataXLS.length; index++) {
          const element : any = dataXLS[index];

          var date = this.datepipe.transform(element[6], 'yyyy-MM-dd');
          var storeName = element[10].replace('SPX Service Point - ','');
          var terminalId = element[8];
          var transactionCount = parseInt(element[4]);
          var amount = (parseInt(element[4])*3.0);
          
          if(this.listItems.filter(x=>x.transactionDate == date && x.storeName == storeName).length == 0){
            var data = {
              storeName : storeName,
              terminalId : terminalId,
              transactionCountFirst: transactionCount,
              amountFirst: amount,
              transactionCountSecond: transactionCount,
              amountSecond:  amount,
              amountThird: amount,
              fee: 2.0,
              transactionCountThird: transactionCount,
              amountForth:  amount,
              netPayable: amount,
              transactionDate: date,
            }
            this.listItems.push(data);
          }
        }
       
    this.loader = false;
      };
    }
  }

  files: File[] = [];

  onSelect(event) {
    this.files = [];
    this.files.push(...event.addedFiles);
  }
  
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  uploadSettlement(totalItem: any, totalGVCAmount: any){
    if(this.canCreate){
      this.loader = true;
      var value = this.fileUploadFormGroup.controls.deyt.value;
      var deyt = `${this.datepipe.transform(value, 'yyyy-MM-dd')}`
      this.settlementService.postSPXSettlementHeader(deyt, totalItem, totalGVCAmount).subscribe((result) => {
        PNotify.success({
          title: "Success",
          text: "Settlement has been created successfully!",
        });

        this.getGVCTransaction();
        this.fileUploadFormGroup.controls.deyt.patchValue(this.emptyString);
        this.createSettlementModal.close();
        this.loader = false;
      });
    } else {
      this.fileUploadFormGroup.controls.deyt.patchValue(this.emptyString);
      PNotify.error({
        title: "Invalid",
        text: "No Transaction Found.",
      });
    }
  }

  uploadBilling(totalItem: any, totalAmount: any){
    if(this.canCreate){
      this.loader = true;
      var value = this.fileUploadFormGroup.controls.daterange.value;
      var deyt = `${this.datepipe.transform(value[0], 'yyyy-MM-dd')},${this.datepipe.transform(value[1], 'yyyy-MM-dd')}`
      this.settlementService.postSPXBillingHeader(deyt, totalItem, totalAmount).subscribe((result) => {
        PNotify.success({
          title: "Success",
          text: "Billing has been created successfully!",
        });

        this.getTransaction();
        this.fileUploadFormGroup.controls.daterange.patchValue(this.emptyString);
        this.createSettlementModal.close();
        this.loader = false;
      });
    } else {
      this.fileUploadFormGroup.controls.daterange.patchValue(this.emptyString);
      PNotify.error({
        title: "Invalid",
        text: "No Transaction Found.",
      });
    }
  }

  searchByFormGroup: FormGroup;
  searchGVCByFormGroup: FormGroup;
  public showRowsOption: Array<Select2OptionData>;
  public transactions: any = [];
  public transaction: any;
  public totals : any;
  
  public gvctransactions: any = [];
  public gvctransaction: any;
  public gvctotals : any;

  initSearchFormGroup(): void {
    this.searchByFormGroup = new FormGroup({
      showRows: new FormControl(null),
      dateRange: new FormControl(this.emptyString),
      userId: new FormControl(this.emptyString),
      storeName: new FormControl(null),
      status: new FormControl(null),
      requestId: new FormControl(null),
    });


    this.searchByFormGroup.controls.showRows.valueChanges.subscribe(e => {
      this.selectedRows(e);
    });
    
    
    this.searchGVCByFormGroup = new FormGroup({
      showRows: new FormControl(null),
      dateRange: new FormControl(this.emptyString)
    });


    this.searchGVCByFormGroup.controls.showRows.valueChanges.subscribe(e => {
      this.selectedRowsGVC(e);
    });
  }

  public statusIdFilterOptions: Array<Select2OptionData>;
  getStatus() {
    this.statusIdFilterOptions = [
      { id: "3", text: "PENDING" },
      { id: "2", text: "COMPLETED" }
      // { id: "1", text: "DECLINED" }
    ];
  }


  getShowRows() {
    this.websettings.getWebSettingsByIdentifier("SHOW_ROWS").subscribe((result) => {
     
      this.showRowsOption = result.data.map((status) => {
        return {
          id: status.parameter,
          text: status.value,
        };
      });
       
    });
  }


  getTransaction(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader =true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    
    const userId : string = this.searchByFormGroup.controls.userId.value;
    const storeName : string = this.searchByFormGroup.controls.storeName.value;
    const showRows : string = this.searchByFormGroup.controls.showRows.value;
    const dateRange : string = this.searchByFormGroup.controls.dateRange.value;
    const status : string = this.searchByFormGroup.controls.status.value;
    const requestId : string = this.searchByFormGroup.controls.requestId.value;
    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_userId: string = userId ? `userId=${userId}&` : "";
    const queryString_storeName: string = storeName ? `storeName=${storeName}&` : "";
    const queryString_requestId: string = requestId ? `requestId=${requestId}&` : "";
    const queryString_status: string = status ? `status=${status}&` : "";
    const queryString_showRows: string = showRows ? `showRows=${showRows}&` : "showRows=10&";
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    const queryString = queryString_orderby + queryString_order +  queryString_userId+ queryString_storeName + queryString_requestId +queryString_status+ queryString_dateRange + queryString_showRows;
 
    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/SPXRemittance/Billings?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/SPXRemittance/Billings?${queryString}`;
      }
    }

    this.settlementService.getSettlements(endPointUrl.slice(0, -1)).subscribe((result) => {
      this.loader =false;
      if(result){
        if (result.data.length == 0 && result.pageNumber != 1) {
          this.getTransaction(result.previousPage, _sortBy, _sort);
        } else {
          this.transactions = result;
          this.totals = result.totals;
        }
      }
     
    });
  }


  getGVCTransaction(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader =true;
    if (fromSearch) {
      this.createdDateGVC = this.emptyString;
    }
 
    const showRows : string = this.searchGVCByFormGroup.controls.showRows.value;
    const dateRange : string = this.searchGVCByFormGroup.controls.dateRange.value;
    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_status: string = status ? `status=${status}&` : "";
    const queryString_showRows: string = showRows ? `showRows=${showRows}&` : "showRows=10&";
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    const queryString = queryString_orderby + queryString_order +  queryString_status+ queryString_dateRange + queryString_showRows;

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/SPXRemittance/Settlements?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/SPXRemittance/Settlements?${queryString}`;
      }
    }

    this.settlementService.getSettlements(endPointUrl.slice(0, -1)).subscribe((result) => {
      this.loader =false;
      if(result){
        if (result.data.length == 0 && result.pageNumber != 1) {
          this.getGVCTransaction(result.previousPage, _sortBy, _sort);
        } else {
          this.gvctransactions = result;
          this.gvctotals = result.totals;
        }
      }
     
    });
  }

 

  searchData(){
    this.pageRows = 1;
    this.getTransaction(this.buildPageUrl(1,
      this.searchByFormGroup.controls.showRows.value
      ?this.searchByFormGroup.controls.showRows.value : 10),null,null,true);
  }
  
  searchGVCData(){
    this.pageRowsGVC = 1;
    this.getGVCTransaction(this.buildGVCPageUrl(1,
      this.searchGVCByFormGroup.controls.showRows.value
      ?this.searchGVCByFormGroup.controls.showRows.value : 10),null,null,true);
  }

  createdDate = "";
  searchString = "";
  
  private _sortBy: any;
  private _sort: any;
  
  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.createdDate = field == "createdDate" ? "th-inner sortable both " + sortBy : "";
   
    this.getTransaction(
      `${environment.API_URL}/api/SPXRemittance/Billings?pageNumber=${
        page ? page : this.transactions.pageNumber
      }`,
      this._sortBy,
      this._sort
    );
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.transactions?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }

  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p = pageNumber;
    return `${environment.API_URL}/api/SPXRemittance/Billings?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }

  createdDateGVC = "";
  searchStringGVC = "";
  
  private _sortByGVC: any;
  private _sortGVC: any;


  setGVCPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortByGVC == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sortGVC = sort;
    this._sortByGVC = sortBy;

    this.createdDateGVC = field == "createdDate" ? "th-inner sortable both " + sortBy : "";
   
    this.getGVCTransaction(
      `${environment.API_URL}/api/SPXRemittance/Settlements?pageNumber=${
        page ? page : this.gvctransactions.pageNumber
      }`,
      this._sortByGVC,
      this._sortGVC
    );
  }

  getGVCPageNumberClass(pageNumber: any) {
    if (pageNumber == this.gvctransactions?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }

  buildGVCPageUrl(pageNumber: any, pageSize: any): any {
    this.pgvc = pageNumber;
    return `${environment.API_URL}/api/SPXRemittance/Settlements?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  totalGVCPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }




  

  statusOfAmount(money: any): boolean {
    return money < 0 ? false : true;
  }

  makeMoney(money: any) {
    if(money){
      return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
    }else{
      return "N/A";
    }
   
  }


  
  public editModal = null;

  // openEditView(content: any,transaction : any) : void {
  //   this.getSettlementbyId(content,transaction,true);
  // }

  // getSettlementbyId(content: any,transaction : any, openModal : any){
  //   this.settlementService.getSettlementbyIdShopee(transaction.id).subscribe((result) => {
  //     this.loader =false;
  //     if(result){
  //       this.transaction = result;
  //       if(openModal){
  //         this.editModal = this.modalServe.open(content, { centered: true, size: "xl" });
  //         transaction = result;
  //       }else{
  //         this.searchData();
  //       }
  //     }
  //   });
  // }

 closeModal(){
  //this.getSettlementbyId(null,this.transaction,false);
  this.modalServe.dismissAll();
 }

  exportToExcel(){

  }

  createSettlementModal: NgbModalRef;
  createModal(content: any){
    this.createSettlementModal = this.modalServe.open(content, { centered: true, size: "xl" });
  }

  selectedtransaction: any = [];
  selectedRemittances: any = [];
  editSettlementModal: NgbModalRef;
  selectSettlement(content: any, transaction: any){
    this.loader = true;
    this.selectedtransaction = transaction;
    this.spxService.getSPXSettlementById(transaction.id).subscribe((result) => {
      this.selectedRemittances = result;

      
      this.editSettlementModal = this.modalServe.open(content, { centered: true, size: "xl" });
      this.loader = false;
      //alert(result);
    });
  }
  
  selectedGVCtransaction: any = [];
  selectedGVCRemittances: any = [];
  editGVCSettlementModal: NgbModalRef;
  selectGVCSettlement(content: any, transaction: any){
    this.loader = true;
    this.selectedGVCtransaction = transaction;
    this.spxService.getGVCSettlementById(transaction.id).subscribe((result) => {
      this.selectedGVCRemittances = result;
      
      this.editGVCSettlementModal = this.modalServe.open(content, { centered: true, size: "xl" });
      this.loader = false;
      //alert(result);
    });
  }



  /////// ##################################### Shopee to GoVIP #######################################

  getDate(deyt: Date){
    var temp = new Date(deyt);
    temp.setDate(temp.getDate()-1);

    console.log(temp);
    return temp;
  }

  public fileNameSPXBilling: any = "Click here to choose file...";
  public base64Photo: string | ArrayBuffer;

  triggerFile(inputFile){
    inputFile.click();
  }

  public onUploadFile(event: any) {
    
    const reader = new FileReader();
   
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.fileNameSPXBilling = file.name;
      reader.onload = async () => {
        this.base64Photo = reader.result;
        if(this.calculateImageSize(this.base64Photo)){
          this.spxService.uploadSPXReceipt(this.transaction.id, this.base64Photo, "data:image/png;base64", 'false').subscribe((result) => {
            this.loader = false;
            PNotify.success({
              title: "Success",
              text: "Data Saved Successfully!",
            });
            // this.getTransaction.emit();
            // this.closeModal.emit();
          }, error=>{
            this.loader = false;
            PNotify.error({
              title: "Saving Failed",
              text: "All fields are required.",
            });
          });
        }else{

          PNotify.error({
                title: "Files",
                text: "File is too big. Must be less than or equal to 1mb.",
              });
        }
      };
    }
  }

  calculateImageSize(base64String) : boolean {
    let padding;
    let inBytes;
    let base64StringLength;
    if (base64String.endsWith('==')) { padding = 2; }
    else if (base64String.endsWith('=')) { padding = 1; }
    else { padding = 0; }

    base64StringLength = base64String.length;
    //console.log(base64StringLength);
    inBytes = (base64StringLength / 4) * 3 - padding;
    return inBytes < 1000000;
  }

  public imagePreview: any;
  public photoFormat: any;
  public photo: any;
  public photoFileName: any;
  fileType : any = "IMAGE";
  public uploadModalSPXBilling: NgbModalRef;
  async preview(content: any, url: any){

    this.fileUpload.getBase64ImageFromURL(url).subscribe((result) => {
      this.imagePreview = result;
      this.uploadModal = this.modalServe.open(content, { centered: true,size:'xl', backdrop: 'static'});
    });
    //debugger
  }

  /////// ##################################### Shopee to GoVIP #######################################

}
