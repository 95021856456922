<app-loader *ngIf="loader"></app-loader>
<div class="modal-body" *ngIf="activeTab!=4" >
  <form id=" form" novalidate="">
    <div id="rootwizard">
      <ul class="nav mb-3">
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="(activeTab==1)?'active':''">
            <span
              class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary">1</span>
            <div class="mt-2">
              <div class="text-muted">Select Product</div>
            </div>
          </a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="(activeTab==2)?'active':''">
            <span
              class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary">2</span>
            <div class="mt-2">
              <div class="text-muted">New Transaction</div>
            </div>
          </a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="(activeTab==2)?'active':''">
            <span
              class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary">2</span>
            <div class="mt-2">
              <div class="text-muted">Confirmation</div>
            </div>
          </a>
        </li>

        <li *ngIf="biller && activeTab!=1" class="nav-item" style="margin-left: auto;">
          <a class="nav-link text-center" [ngClass]="(activeTab==3)?'active':''">
            <img [src]="biller?.logo" height="50" class="img-responsive" style="object-fit: contain;">
          </a>
        </li>
      </ul>

      <hr class="bg-dark" />

      <div class="tab-content p-3">
        <div class="tab-pane" [ngClass]="(activeTab==1)?'active':''" id="selectBillerTab">
          <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="d-flex align-items-center">
                    <h4 class="ml-3">{{application.value.thumbnail.title}}</h4>
                    <div class="flex"></div>
                    <img [src]="application.value.thumbnail.logo" height="100" width="100" class="img-responsive" style="object-fit: contain;">
                </div>
                <p>
                    {{application.value.acquired.description}}
                </p>
            </div>
            <div class="col-sm-12 col-md-6" [formGroup]="billerFormGroup">

              <div class="form-group">
                <label>Product Type: </label>
                <select (change)="getBillersByBillerCategory($event.target.value)"
                  class="form-control " tabindex="-1" aria-hidden="true">
                  <option value="">Choose...</option>
                  <option *ngFor="let billerCategory of billerCategories | sortdata" [value]="billerCategory" >
                    {{billerCategory}}</option>
                </select>
              </div>

              <div class="form-group">
                <label>Product Name: </label>
                <select (change)="getBillersByBillerType($event.target.value)" formControlName="billerCode"
                  class="form-control " tabindex="-1" aria-hidden="true">
                  <option value="">Choose...</option>
                  <option *ngFor="let billerType of billerTypes | sortdata" [value]="billerType" >
                    {{billerType}}</option>
                </select>
              </div>

              <!-- <div class="form-group">
                <label>Product Name:</label>
                <select (change)="setBillerCode($event.target.value)" formControlName="billerCode"
                  class="form-control " tabindex="-1" aria-hidden="true">
                  <option value="">Choose...</option>
                  <option *ngFor="let biller of billersByBillerTypes" [value]="biller.code">
                    {{biller.name}}</option>
                </select>
              </div> -->

              <div class="form-group">
                <label>Description: </label>
                <input type="text" class="form-control " tabindex="-1" aria-hidden="true" value="{{detail}}" readonly="read-only"/>
              </div>

              <div class="form-group">
                <label>Wallet Fee: </label>
                <input type="text" class="form-control " tabindex="-1" aria-hidden="true" value="{{walletFee}}" readonly="read-only"/>
              </div>

                <app-textbox-currency *ngIf="detail == 'AMAX'"
                [parent-form-group]="billerFormGroup" tab-index-start="0" control-name="amount"
                [error]="billerFormGroup?.controls['amount'].errors | estatus"
                [error-message]="billerFormGroup.controls['amount'].errors?.required" read-only=false
                number-only="false" label="Amount" placeholder="0.00" max-length="10">
                </app-textbox-currency>

                <app-textbox-currency  *ngIf="detail != 'AMAX'"
                [parent-form-group]="billerFormGroup" tab-index-start="0" control-name="amount"
                [error]="billerFormGroup?.controls['amount'].errors | estatus"
                [error-message]="billerFormGroup.controls['amount'].errors?.required" read-only=true
                no-special-char="false" label="Amount" placeholder="0.00" max-length="10">
                </app-textbox-currency>

                <app-textbox 
                [parent-form-group]="billerFormGroup" tab-index-start="0" control-name="mobileNumber"
                [error]="billerFormGroup?.controls['mobileNumber'].errors | estatus"
                [error-message]="billerFormGroup.controls['mobileNumber'].errors?.required" read-only=false
                label="Mobile Number" placeholder="09123456789" max-length="30">
                </app-textbox>

            </div>
          </div>
        </div>

        <div class="tab-pane" [ngClass]="(activeTab==2)?'active':''" id="confirmationTab">
          <div class="form-group">
            <p><strong>Purchase Confirmation</strong><br /> <small>Please make sure that the all details is
                correct.</small>
            </p>
            <div class="card">
              <div class="list list-row">
                <!-- <div class="list-item " data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">
                      Category
                    </div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">{{biller?.category}}</div>
                  </div>
                </div> -->
                <div class="list-item " data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">
                      Product Type
                    </div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">{{selectedProduct?.productType}}</div>
                  </div>
                </div>
                <div class="list-item " data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">
                      Product Name
                    </div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">{{selectedProduct?.productName}}</div>
                  </div>
                </div>
                <div class="list-item " data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">
                      Mobile Number / Account Number
                    </div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">{{billerFormGroup?.controls.mobileNumber.value}}</div>
                  </div>
                </div>
                <div class="list-item " data-id="19">
                    <div class="flex">
                      <div class="item-date text-muted text-sm d-none d-md-block">
                        Description
                      </div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">{{detail}}</div>
                    </div>
                  </div>

                  <div class="list-item " data-id="19">
                    <div class="flex">
                      <div class="item-date text-muted text-sm d-none d-md-block">
                        Wallet Fee
                      </div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">{{walletFee}}</div>
                    </div>
                  </div>
                <!-- <div class="list-item " data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">
                      Biler Fee
                    </div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">{{biller?.fee}}</div>
                  </div>
                </div> -->
                <div class="list-item " data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">
                      Amount
                    </div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">{{billerFormGroup?.controls.amount.value}}</div>
                  </div>
                </div>
               <hr/>
               <div class="list-item " data-id="19">
                <div class="flex">
                  <div class="item-date text-muted text-sm d-none d-md-block">
                    Total Amount
                  </div>
                </div>
                <div class="no-wrap">
                  <div class="item-feed h-2x">{{billerFormGroup?.controls.amount.value}}</div>
                </div>
              </div>
                <hr />
                <div class="col-sm-12 col-md-12">
                  <div class="col-sm-12 col-md-6" style="float:none;margin:auto;">
                    <div class="item-feed h-2x text-center">
                      ENTER PINCODE
                    </div>
                    <code-input [isCodeHidden]="true" [codeLength]="6" (codeChanged)="onCodeChanged($event)"
                      (codeCompleted)="onCodeCompleted($event)" [code]="code">
                    </code-input>
                  </div>
                </div>
                <br />


              </div>
            </div>
          </div>
        </div>

        <!-- previous next -->
        <div class="row py-3">
          <div class="col-6">
            <a class="btn btn-white button-previous" (click)="setActiveTabPrev()">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-arrow-left">
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
              &nbsp; Previous
            </a>
          </div>
          <div class="col-6">
            <div class="d-flex justify-content-end">
              <a *ngIf="activeTab != 2" class="btn btn-white button-next" (click)="setActiveTab()">
                Next &nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-arrow-right">
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </a>
              <a *ngIf="activeTab == 2 &&  codeStatus" appDebounceClick [debounceTime]="500" (debounceClick)="processPayment()" class="btn btn-info button-next"
                style="color: white;" >
                Process Purchase &nbsp;
                <span class="fa fa-hand-pointer"></span>
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </form>
</div>
