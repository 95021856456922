import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-print-layout",
  templateUrl: "./print-layout.component.html",
  styleUrls: ["./print-layout.component.less"],
})
export class PrintLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
