<app-loader *ngIf="!videoTutorials"></app-loader>

<div  *ngIf="videoTutorials" id="content" class="flex"><div>
    <div >
    <div class="page-hero page-container " id="page-hero" >
        <div class="padding d-flex">
            <div class="page-title">
                <h2 class="text-md text-highlight">Video Collections</h2>
            </div>
            <div class="flex"></div>
        </div>
    </div>
    
    <div class="page-content page-container" id="page-content" style="display: block;" *ngFor="let video of videoTutorials"> 

        <div class="col-12">
            <div class="alert bg-dark-overlay mb-4 py-4" role="alert">
                <div class="d-flex">
                     <div class="px-3">
                        <h5 class="alert-heading">{{video.parameter}}</h5>
                    </div>
                </div>
            </div>
        </div>


            <div class="row">
                <div class="col-sm-12 col-lg-12">
                    <div class="col-sm-12 col-lg-12">
                    </div>
                    <div class="tab-content">
                        <div class="col-lg-6" id="tab_1" style="float: left;" >
                            <div *ngFor="let meta of video.value; let index = index" >
                            <div *ngIf="0 == index % 2" class="card col-lg-12" id="feed-1"  >
                                <div class="card-header d-flex align-items-center">
                                    <a >
                                        <img src="https://i.ibb.co/JCjTYCx/youtube.png" class="avatar w-40 img-responsive">
                                    </a>
                                    <div class="mx-3 d-flex align-items-center">
                                        <a class="font-weight-bold" style="font-size: 16px;">{{meta.title}}</a>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="card-text mb-3">
                                        <p>{{meta.description}} </p>
                                    </div>
                                    <div class="row row-xs">
                                        <div class="col-12">
                                            <div class="media media-2x1 r ">
                                                <iframe class="videos"  [src]="meta.url" width="560" height="315" frameborder="0" webkitallowfullscreen mozallowfullscreen
                                                allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                      
                        <div class="col-lg-6" id="tab_1" style="float: left;" >
                            <div *ngFor="let meta of video.value; let index = index" >
                            <div *ngIf="0 != index % 2" class="card col-lg-12" id="feed-1"  >
                                <div class="card-header d-flex align-items-center">
                                    <a >
                                        <img src="https://i.ibb.co/JCjTYCx/youtube.png" class="avatar w-40 img-responsive">
                                    </a>
                                    <div class="mx-3  d-flex align-items-center">
                                        <a  class="font-weight-bold" style="font-size: 16px;">{{meta.title}}</a>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="card-text mb-3">
                                        <p>{{meta.description}}</p>
                                    </div>
                                    <div class="row row-xs">
                                        <div class="col-12">
                                            <div class="media media-2x1 r ">
                                                <iframe class="videos"  [src]="meta.url" width="560" height="315" frameborder="0" webkitallowfullscreen mozallowfullscreen
                                                allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                      
                    </div>
                </div>
             
            </div>
       
    </div>
        
    </div>
</div></div>