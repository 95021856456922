<div [formGroup]="parentFormGroup" class="form-group row" [class.error]="error">
    <label class="col-sm-3 col-form-label">{{ label }} </label>
    <div class="col-sm-9">
      <div class="box-input-container">
        <code-input
            class=""
            [isCodeHidden]="false"
            [codeLength]="maxLength"
            [isCharsCode]="alphaNumeric"
            (codeChanged)="onCodeChanged($event)"
            (codeCompleted)="onCodeCompleted($event)"
            [code]="code"
            
            [formControlName]="controlName"
            [id]="controlName"
            [placeholder]="placeholder"
            [no-special-char]="noSpecialChar"
            [tabindex]="tabIndexStart"
            [readonly]="readOnly()"
            >
        </code-input>
      </div>
      <span *ngIf="error" class="error-msg">
        <small><div [innerHTML]="errorMessage"></div></small>
      </span>
    </div>
  </div>
  