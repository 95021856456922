import { CurrencyPipe, DatePipe } from "@angular/common";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { TransactionService } from "src/data/services/web/transaction.service";
import { RolesService } from "src/data/services/web/roles.service";
import { environment } from "src/environments/environment";
import { UserService } from "src/data/services/web/user.service";
import { Select2OptionData } from "ng-select2";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WebSettingsService } from "src/data/services/web/web-settings.service";
import { ExportExcelService } from "src/data/services/web/export-excel.service";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { debug } from "console";

@Component({
  selector: "app-transaction-list-all",
  templateUrl: "./transaction-list-all.component.html",
  styleUrls: ["./transaction-list-all.component.less"],
})


export class TransactionListAllComponent implements OnInit {

  @ViewChild('viewMultisys') viewMultisys: TemplateRef<any>;
  @ViewChild('viewEctopup') viewEctopup: TemplateRef<any>;
  @ViewChild('viewEccash') viewEccash: TemplateRef<any>;
  @ViewChild('viewEcpay') viewEcpay: TemplateRef<any>;
  @ViewChild('viewCti') viewCti: TemplateRef<any>;
  @ViewChild('viewIBayadBankTransfer') viewIBayadBankTransfer: TemplateRef<any>;
  @ViewChild('viewVIPProtect') viewVIPProtect: TemplateRef<any>;
  @ViewChild('viewPHPay') viewPHPay: TemplateRef<any>;


  public transactionFilterFormGroup: FormGroup;
  public searchByUsernameFormGroup: FormGroup;
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public transactionService: TransactionService;
  public websettings: WebSettingsService;
  public userService: UserService;
  public emptyString = "";
  public statuses: any;
  public status: any;
  public modalService: BsModalService;

  public accessToken: string;
  public newTransaction: boolean;
  public transactions: any;
  public transaction: any;
  public transactionsExport: any;
  public trans: any = [];
  public pageCount: any;
  public roleLevelId: any;

  public cashiersAndManagers: any;

  public processUpdate: any = false;

  public bsModalRefConfirmation: BsModalRef;
  public selectedUserId: any = 0;
  
  public userIdFilterOptions: Array<Select2OptionData>;
  public userIdFilter: any = [];
  public showRowsOption: Array<Select2OptionData>;
  public showRowsFilter: any = [];
  
  public pageRows: any = 10;
  public selectedRowsId: any = 0;
  public loader = true;
  
  public statusIdFilterOptions: Array<Select2OptionData>;
  public statusIdFilter: any = [];
  dataForExcel = [];

  p: number = 1;
  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    private currencyPipe: CurrencyPipe,
    public datepipe: DatePipe,
    private modalBService: NgbModal,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    websettings: WebSettingsService,
    transactionService: TransactionService,
    modalService: BsModalService,
    bsModalRefConfirmation: BsModalRef,
    userService: UserService,
    public ete: ExportExcelService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.transactionService = transactionService;
    this.modalService = modalService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
    this.bsModalRefConfirmation = bsModalRefConfirmation;
    this.userService = userService;
    this.websettings = websettings;
  }
  userId;
  ngOnInit(): void {
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.userId = this.roleLevelId == 1 ? 0 : this.localStorageCacheService.getStorage("userId");
    
    this.initSearchByUsernameFormGroup();
    this.getStatus();
    this.initMultipleObservable();

  }


  initMultipleObservable(){
   
   var multipleSubscription = forkJoin(
    this.userService.getCashiersAndManagers(),
    this.websettings.getWebSettingsByIdentifier("SHOW_ROWS")
    );
    multipleSubscription.subscribe((results) => {

      var getCashiersAndManagersResponse = results[0];
      this.cashiersAndManagers = getCashiersAndManagersResponse;
      // Set the username options from the API data
      this.userIdFilterOptions = getCashiersAndManagersResponse.map((user) => {
        return {
          id: user.id,
          text: user.name,
        };
      });
      this.searchByUsernameFormGroup.controls.username.setValue(0, {onlySelf: true});

      var getWebSettingsByIdentifierResponse = results[1];
      this.showRowsOption = getWebSettingsByIdentifierResponse.data.map((status) => {
        return {
          id: status.parameter,
          text: status.value,
        };
      });
     this.searchByUsernameFormGroup.controls.showRows.setValue(10, {onlySelf: true});
     this.searchByUsernameFormGroup.controls.status.setValue(3, {onlySelf: true});
      
      this.getTransaction();
      
    });

  }



  initSearchByUsernameFormGroup(): void {
    this.searchByUsernameFormGroup = new FormGroup({
      status: new FormControl(3),
      username: new FormControl(0),
      dateRange : new FormControl(""),
      requestId : new FormControl(""),
      showRows: new FormControl(10)
    });

    this.searchByUsernameFormGroup.controls.username.valueChanges.subscribe(e =>{
      this.selectedUser(e);
      this.searchByUsernameFormGroup.controls.dateRange.setValue("");
      this.searchByUsernameFormGroup.controls.requestId.setValue("");
    });
    this.searchByUsernameFormGroup.controls.status.valueChanges.subscribe(e =>{
      this.selectedStatus(e);
      this.searchByUsernameFormGroup.controls.dateRange.setValue("");
      this.searchByUsernameFormGroup.controls.requestId.setValue("");
    });
    this.searchByUsernameFormGroup.controls.showRows.valueChanges.subscribe(e => {
      this.selectedRows(e);
    });
  }

  private _sortBy: any;
  private _sort: any;

  selectedStatus(e){
    this.statusIdFilter = e;
  //  this.getTransaction();
  }

  selectedUser(e) {
    this.selectedUserId = e;
  //  this.getTransaction();
  }

  selectedRows(e){
    this.selectedRowsId = e;
    this.pageRows = e;
   // this.getTransaction();
 
  }
  
  getStatus() {
      this.statusIdFilterOptions = [
        { id: "2", text: "Completed"},
        { id: "3", text: "Pending"},
        { id: "1", text: "Failed"},
        { id: "4", text: "Refund"}
      ];
  }

  statusOfAmount(money: any): boolean {
    return money < 0 ? false : true;
  }

  getTransaction(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader = true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    
    }
    
    const requestId : string = this.searchByUsernameFormGroup.controls.requestId.value;
    const dateRange : string = this.searchByUsernameFormGroup.controls.dateRange.value;
    const statusId : string = this.searchByUsernameFormGroup.controls.status.value;
    const showRows : string = this.searchByUsernameFormGroup.controls.showRows.value;

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_requestId: string = requestId ? `requestId=${requestId}&` : "";
    const queryString_statusId: string = statusId ? `statusId=${statusId}&` : "";
    const queryString_showRows: string = showRows ? `showRows=${showRows}&` : "";
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    var queryString = queryString_orderby + queryString_order + queryString_requestId + queryString_statusId + queryString_dateRange + queryString_showRows;
    if (fromSearch) {
      this.p = 1;
      queryString =`pageNumber=${1}&pageSize=${10}&${queryString}`;
    }

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/transactions/admin/${this.selectedUserId ?this.selectedUserId : 0 }?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/transactions/admin/${this.selectedUserId ?this.selectedUserId : 0}?${queryString}`;
      }
    }
    this.transactionService.getTransaction(endPointUrl.slice(0, -1)).subscribe((result) => {
      if(result){
        if (result.data.length == 0 && result.pageNumber != 1 && fromSearch == false) {
          console.log("iterate");
          this.getTransaction(result.previousPage, _sortBy, _sort);
        } else {
          this.transactions = result;
         
        }
        this.loader = false;
      }
     
    });
  }

  getTransactionForExport(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader = true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    
    }
    
    const requestId : string = this.searchByUsernameFormGroup.controls.requestId.value;
    const dateRange : string = this.searchByUsernameFormGroup.controls.dateRange.value;
    const statusId : string = this.searchByUsernameFormGroup.controls.status.value;
    const showRows : string = this.searchByUsernameFormGroup.controls.showRows.value;

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_requestId: string = requestId ? `requestId=${requestId}&` : "";
    const queryString_statusId: string = statusId ? `statusId=${statusId}&` : "";
    const queryString_showRows: string = showRows ? `showRows=${showRows}&` : "";
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    var queryString = queryString_orderby + queryString_order + queryString_requestId + queryString_statusId + queryString_dateRange + queryString_showRows;
    if (fromSearch) {
      this.p = 1;
      queryString =`pageNumber=${1}&pageSize=${10}&${queryString}`;
    }

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/transactions/adminHistory/${this.selectedUserId ?this.selectedUserId : 0 }?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/transactions/adminHistory/${this.selectedUserId ?this.selectedUserId : 0}?${queryString}`;
      }
    }
    this.transactionService.getTransaction(endPointUrl.slice(0, -1)).subscribe((result) => {
      if(result){
        if (result.data.length == 0 && result.pageNumber != 1 && fromSearch == false) {
          console.log("iterate");
          this.getTransaction(result.previousPage, _sortBy, _sort);
        } else {
          this.transactionsExport = result;
         this.generateExcel(result, "Transaction History");
        }
        this.loader = false;
      }
     
    });
  }

  createdDate = "";
  searchString = "";

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.createdDate = field == "createdDate" ? "th-inner sortable both " + sortBy : "";
  
    this.getTransaction(
      `${environment.API_URL}/api/transactions/admin/${this.selectedUserId ?this.selectedUserId : 0 }?pageNumber=${
        page ? page : this.transactions.pageNumber
      }`,
      this._sortBy,
      this._sort
    );
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.transactions?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }

  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p=pageNumber;
    return `${environment.API_URL}/api/transactions/admin/${this.selectedUserId ?this.selectedUserId : 0 }?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  requestId;
  setSelectedTransaction(transaction: any) {
    this.transaction = transaction;
    const transactionType = this.transaction.transactionType;
    this.requestId = this.transaction.requestId;
 
    switch (transactionType){
      case "BILLS_PAYMENT_MULTISYS" : this.openTransDetail(this.viewMultisys); break;
      case "BILLS_PAYMENT_ECPAY" : this.openTransDetail(this.viewEcpay); break;
      case "BILLS_PAYMENT_CTI" : this.openTransDetail(this.viewCti); break;
      case "CASH_ECPAY" : this.openTransDetail(this.viewEccash); break;
      case "TOP_UP_ECPAY" : this.openTransDetail(this.viewEctopup); break;
      case "IBAYAD_TRANSFER" : this.openTransDetail(this.viewIBayadBankTransfer); break;
      case "VIP_PROTECT" : this.openTransDetail(this.viewVIPProtect); break;
    }
  }
  openTransDetail(viewModal) {
    this.modalBService.open(viewModal, { centered: true, size: "xl" });
  }

  exportToExcel() {
    this.trans = [];
    this.dataForExcel = [];
    
    this.getTransactionForExport();
  }

  getValues(provider: any, i: any, providerName: any, status: any) {
  
    let meta = [];
    switch (providerName) {
      case "MULTISYS": 
          meta = [
            {
              "Biller Name": providerName,
              "Request ID": provider.data[i].requestId,
              "Account Number": provider.data[i].multisys.billerAccountNo,
              "Biller/Product Name": provider.data[i].multisys.billerName,
              "Category": provider.data[i].multisys.billerCategory,
              "Biller Partners Reference Number": provider.data[i].multisys.billerPartnerRefNo,
              "Transact By": provider.data[i].user.username,
              "Completed Date": provider.data[i].completedDate,
              "Amount": provider.data[i].amount,
              "Biller Fee": provider.data[i].multisys.billerFee,
              "Balance Before": this.makeMoney(provider.data[i].openingBalance),
              "Balance After": this.makeMoney(provider.data[i].openingBalance + provider.data[i].amount),
              "Status":	status
            }
          ]
        break;
      case "ECCASH":
        meta = [
          {
            "Biller Name": providerName,
            "Request ID": provider.data[i].requestId,
            "Account Number": provider.data[i].ecCash.billerAccountNo,
            "Biller/Product Name": provider.data[i].ecCash.billerName,
            "Category": provider.data[i].ecCash.billerCategory,
            "Biller Partners Reference Number": provider.data[i].ecCash.billerPartnerRefNo,
            "Transact By": provider.data[i].user.username,
            "Completed Date": provider.data[i].completedDate,
            "Amount": provider.data[i].amount,
            "Biller Fee": provider.data[i].ecCash.billerFee,
            "Balance Before": this.makeMoney(provider.data[i].openingBalance),
            "Balance After": this.makeMoney(provider.data[i].openingBalance + provider.data[i].amount),
            "Status":	status
          }
        ]
        break;
      case "CTI":
        meta = [
          {
            "Biller Name": providerName,
            "Request ID": provider.data[i].requestId,
            "Account Number": provider.data[i].cti.mobileNumber,
            "Biller/Product Name": provider.data[i].cti.productName,
            "Category": provider.data[i].cti.productCategory,
            "Biller Partners Reference Number": provider.data[i].cti.billerPartnerRefNo,
            "Transact By": provider.data[i].user.username,
            "Completed Date": provider.data[i].completedDate,
            "Amount": provider.data[i].amount,
            "Biller Fee": "N/A",
            "Balance Before": this.makeMoney(provider.data[i].openingBalance),
            "Balance After": this.makeMoney(provider.data[i].openingBalance + provider.data[i].amount),
            "Status":	status
          }
        ]
        break;
      case "ECPAY":
        meta = [
          {
            "Biller Name": providerName,
            "Request ID": provider.data[i].requestId,
            "Account Number": provider.data[i].ecPay.billerAccountNo,
            "Biller/Product Name": provider.data[i].ecPay.billerName,
            "Category": provider.data[i].ecPay.billerCategory,
            "Biller Partners Reference Number": provider.data[i].ecPay.billerPartnerRefNo,
            "Transact By": provider.data[i].user.username,
            "Completed Date": provider.data[i].completedDate,
            "Amount": provider.data[i].amount,
            "Biller Fee": provider.data[i].ecPay.billerFee,
            "Balance Before": this.makeMoney(provider.data[i].openingBalance),
            "Balance After": this.makeMoney(provider.data[i].openingBalance + provider.data[i].amount),
            "Status":	status
          }
        ]
        break;
      case "ECTOPUP":
        meta = [
          {
            "Biller Name": providerName,
            "Request ID": provider.data[i].requestId,
            "Account Number": provider.data[i].ecTopUp.cellphoneNumber,
            "Biller/Product Name": provider.data[i].ecTopUp.extensionTag,
            "Category": provider.data[i].ecTopUp.telco,
            "Biller Partners Reference Number": provider.data[i].ecTopUp.billerPartnerRefNo,
            "Transact By": provider.data[i].user.username,
            "Completed Date": provider.data[i].completedDate,
            "Amount": provider.data[i].amount,
            "Biller Fee": provider.data[i].ecTopUp.billerFee,
            "Balance Before": this.makeMoney(provider.data[i].openingBalance),
            "Balance After": this.makeMoney(provider.data[i].openingBalance + provider.data[i].amount),
            "Status":	status
          }
        ]
        break;
      default :
        meta = [
          {
            "Biller Name": provider.data[i].transactionType,
            "Request ID": provider.data[i].requestId,
            "Account Number": "N/A",
            "Biller/Product Name": "N/A",
            "Category": "N/A",
            "Biller Partners Reference Number": "N/A",
            "Transact By": provider.data[i].user.username,
            "Completed Date": provider.data[i].completedDate,
            "Amount": provider.data[i].amount,
            "Biller Fee": "N/A",
            "Balance Before": this.makeMoney(provider.data[i].openingBalance),
            "Balance After": this.makeMoney(provider.data[i].openingBalance + provider.data[i].amount),
            "Status":	status
          }
        ]
        break;
    }

    return meta;
  }

  generateExcel(provider: any, name: any): void {
 
    debugger

      for(var i = 0; i < provider.data.length; i++){
        
        let status = "";
  
        if(provider.data[i].statusId == "1"){
          status = "Failed"
        }  else if (provider.data[i].statusId == "2"){
          status = "Completed"
        } else if (provider.data[i].statusId == "3"){
          status = "Pending"
        }

        var providerName = "";
        if(provider.data[i].multisys != null){
          providerName = "MULTISYS";
        } else if (provider.data[i].cti != null){
          providerName = "CTI";
        } else if (provider.data[i].ecCash != null){
          providerName = "ECCASH";
        } else if (provider.data[i].ecPay != null){
          providerName = "ECPAY";
        } else if (provider.data[i].ecTopUp != null){
          providerName = "ECTOPUP";
        } else {
          providerName = provider.data[i].transactionType;
        }


        let meta = this.getValues(provider, i, providerName, status);
        this.trans.push(meta[0]);
      }
 
  
    for(var x = 0; x < this.trans.length; x++){
      this.dataForExcel.push(Object.values(this.trans[x]));
    }
  
    let reportData = {
      title: name + " Report ",
      data: this.dataForExcel,
      headers: Object.keys(this.trans[0])
    }
    
    this.ete.exportExcel(reportData, name);
  }
  public padIntegerLeftWithZeros(rawInteger: number, numberOfDigits: number): any {
    let paddedInteger: string = rawInteger + '';
    while (paddedInteger.length < numberOfDigits) {
      paddedInteger = '0' + paddedInteger;
    }
    return paddedInteger;
  }
}
