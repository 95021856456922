import { typeWithParameters } from '@angular/compiler/src/render3/util';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { first, map } from 'rxjs/operators';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { CashierUsersService } from 'src/data/services/web/cashier-users.service';
import { WebSettingsService } from "src/data/services/web/web-settings.service";

@Component({
  selector: 'app-product-event-leaderboard',
  templateUrl: './product-event-leaderboard.component.html',
  styleUrls: ['./product-event-leaderboard.component.less']
})
export class ProductEventLeaderboardComponent implements OnInit {

  constructor(
    private store: AngularFirestore,
    private webService: WebSettingsService,
    private  localStorageCacheService: LocalStorageCacheService,
    private cashierUsersService : CashierUsersService,) { }


    public topTenResult = [];
    public rewardList = ["3000","2000","1000","0","0","0","0","0","0","0"];
    public ownRank;
    public totalScores = 0;
    public myRankNumber = 0;
    public store_id;
    public seconds = 0;
    public secondsCounter = 0;
    public gameActive = false;

    public isManagerOrCashier = false;

  ngOnInit(): void {

    var role_id = this.localStorageCacheService.getStorage("roleId");
    if (role_id == '4'){
      var cashierUserId =  this.localStorageCacheService.getStorage("id");
      this.cashierUsersService.getCashierUser(cashierUserId).subscribe((result) => {
        this.store_id = result.data.cashierUser.managerUserId;
this.isManagerOrCashier = true;
        this.webService.getWebSettingsByIdentifier("A_GOVIPCENTER_PRODUCT_EVENT").subscribe((result) => {
  
          var _serverDate = new Date(result.serverDate)
          var startDate = new Date(result.data[1].value);
          var endDate = new Date(result.data[2].value);
          var userTimezoneOffset = startDate.getTimezoneOffset() * 60000;
          var _startDate = new Date(startDate.getTime() + userTimezoneOffset);
          var _endDate = new Date(endDate.getTime() + userTimezoneOffset);
          this.seconds = (_startDate.getTime() - _serverDate.getTime()) / 1000;
          this.secondsCounter = (_endDate.getTime() - _serverDate.getTime()) / 1000;
     
        debugger
        if(this.seconds > 0){
          this.startTimer();
          this.gameActive = false;
        }else{
          this.startTimerCounter();
          this.productEventTop10();
          this.gameActive = true;
        }
       
         
          });
      });
    } else if (role_id == '3'){

      this.isManagerOrCashier = true;
      this.store_id =this.localStorageCacheService.getStorage("id");
      this.webService.getWebSettingsByIdentifier("A_GOVIPCENTER_PRODUCT_EVENT").subscribe((result) => {
  
        var _serverDate = new Date(result.serverDate)
          var startDate = new Date(result.data[1].value);
          var endDate = new Date(result.data[2].value);
          var userTimezoneOffset = startDate.getTimezoneOffset() * 60000;
          var _startDate = new Date(startDate.getTime() + userTimezoneOffset);
          var _endDate = new Date(endDate.getTime() + userTimezoneOffset);
          this.seconds = (_startDate.getTime() - _serverDate.getTime()) / 1000;
          this.secondsCounter = (_endDate.getTime() - _serverDate.getTime()) / 1000;
     
        debugger
        if(this.seconds > 0){
          this.startTimer();
          this.gameActive = false;
        }else{
          this.startTimerCounter();
          this.productEventTop10();
          this.gameActive = true;
        }
       
        });
    }else{
      
        this.isManagerOrCashier = false;

        this.webService.getWebSettingsByIdentifier("A_GOVIPCENTER_PRODUCT_EVENT").subscribe((result) => {
  
          var _serverDate = new Date(result.serverDate)
          var startDate = new Date(result.data[1].value);
          var endDate = new Date(result.data[2].value);
          var userTimezoneOffset = startDate.getTimezoneOffset() * 60000;
          var _startDate = new Date(startDate.getTime() + userTimezoneOffset);
          var _endDate = new Date(endDate.getTime() + userTimezoneOffset);
          this.seconds = (_startDate.getTime() - _serverDate.getTime()) / 1000;
          this.secondsCounter = (_endDate.getTime() - _serverDate.getTime()) / 1000;
       debugger
          if(this.seconds > 0){
            this.startTimer();
            this.gameActive = false;
          }else{
            this.startTimerCounter();
            this.productEventTop10();
            this.gameActive = true;
          }
         
          });
    }

  


   
  }

  interval;
  startTimer() {
    this.interval = setInterval(() => {
      this.seconds = this.seconds-1;

      if(this.seconds < 0){
        clearInterval(this.interval);
        this.productEventTop10();
        this.gameActive = true;
      }


    },1000);
  }


  intervalCounter;
  startTimerCounter() {
    this.intervalCounter = setInterval(() => {
      this.secondsCounter = this.secondsCounter-1;

      if(this.secondsCounter < 0){
        clearInterval(this.intervalCounter);
      }


    },1000);
  }



  getPercentage(score: any) {
     this.totalScores = 0;
    this.topTenResult.forEach(data => {
      this.totalScores = this.totalScores + data.transaction_count;
    });
    return (score / this.totalScores) * 100;
  }

  productEventTop10(): void {
    this.webService.getWebSettingsByIdentifier("A_GOVIPCENTER_PRODUCT_EVENT").subscribe((result) => {
      var productEventId = result.data[0].value.replace(result.data[0].parameter+':',"");
     
      this.store.collection('GoVIPCenterProductEventTopTen').doc(productEventId.toString()).valueChanges().subscribe((val: any) => {
        this.topTenResult = val.topTen;
     
       if(this.isManagerOrCashier) this.getCurrentRank();
      });
    });
  }

  getCurrentRank():void{
    //this.store.collection('GoVIPCenterProductEvent').doc(_productEventId).get().subscribe((val: any) => {
      this.ownRank = this.topTenResult.find(x=>x.store_id == this.store_id);
      if(this.ownRank){
        let i = 0;
        var outlet_stores = this.topTenResult;
  
       outlet_stores.forEach(element => {
        if(element.store_id ==  this.store_id){
             this.myRankNumber= i;
           }
           i++;
       });
      }
     
    
   // });
  }


   
}
