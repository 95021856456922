import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { ECPayService } from "src/data/services/web/ecpay.service";

import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { CurrencyPipe, DecimalPipe } from "@angular/common";
import { PrintService } from "src/data/services/web/print.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WebSettingsService } from "src/data/services/web/web-settings.service";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5
@Component({
  selector: "app-eccash",
  templateUrl: "./eccash.component.html",
  styleUrls: ["./eccash.component.less"],
})
export class EccashComponent implements OnInit {
  @Input("application") application: any;
  @Input("shortcutCode") shortcutCode: any;
  @Input("shortcutAccumulator") shortcutAccumulator: any;
  @Output("closeOnly") closeOnly = new EventEmitter();
  @Output("getShortcutApps") getShortcutApps = new EventEmitter();

  @ViewChild("pinModal") pinModal: TemplateRef<any>;
  billerFormGroup: FormGroup;
  formBuilder: FormBuilder;
  ecPayService: ECPayService;
  public emptyString = "";
  public billers: any = [];
  public biller: any;
  public billerRate: any;
  public billerCode: any;
  public billerTypes: any = [];
  public billersByBillerTypes: any = [];
  public selectedBillerCode: any;
  public type : any;
  public activeTab = 1;
  public loader = true;
  public fee: any;

  @Output("closeModal") closeModal = new EventEmitter();

  constructor(
    ecPayService: ECPayService,
    private router: Router,
    localStorageCacheService: LocalStorageCacheService,
    private currencyPipe: CurrencyPipe,
    formBuilder: FormBuilder,
    private modalBService: NgbModal,
    private webSettingsService : WebSettingsService,
    private _decimalPipe: DecimalPipe
  ) {
    this.ecPayService = ecPayService;
    this.formBuilder = formBuilder;
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.initBillerTypeFormGroup();
    this.getBillers();

    if(this.shortcutCode){
      setTimeout(() => {
        this.initShortcut();
      }, 1000);
    }
  }

  initShortcut(): void {
    this.billerFormGroup.controls.billerType.patchValue(this.shortcutCode.category);
    this.getBillersByBillerType(this.shortcutCode.category);
    this.billerFormGroup.controls.billerCode.patchValue(this.shortcutCode.itemCode);
    this.getBillerRates();
    this.billerCode = this.shortcutCode.itemCode;
    this.getBiller();
  }

  initBillerTypeFormGroup(): void {
    this.billerFormGroup = new FormGroup({
      billerType: new FormControl(this.emptyString, [Validators.required]),
      billerCode: new FormControl(this.emptyString, [Validators.required]),
      params: this.formBuilder.group({}),
      billerFee: new FormControl(this.emptyString,[]),
      amountToPaid: new FormControl(this.emptyString, [])
    });
  }

  reInitBillerTypeFormGroup(): void {
    this.billerFormGroup = new FormGroup({
      billerType: new FormControl(this.billerFormGroup.controls.billerType.value, [Validators.required]),
      billerCode: new FormControl(this.billerFormGroup.controls.billerCode.value, [Validators.required]),
      params: this.formBuilder.group({}),
      billerFee: new FormControl(this.emptyString,[]),
      amountToPaid: new FormControl(this.emptyString, [])
    });
  }

  getBillers() {
    this.ecPayService.getCashes().subscribe((billers) => {
      this.billers = billers;
      this.getBillerTypes(billers);
    });
  }


  getBillerTypes(billers: any) {
    const lookup = {};
    const items = billers;

    for (var item, i = 0; (item = items[i++]); ) {
      const type = item.type;
      if (!(type in lookup)) {
        lookup[type] = 1;
        this.billerTypes.push(type);
      }
    }
    this.loader = false;
  }

  isShortcutExisting: any = false;
  addShortcut(){
    this.ecPayService.addFavoriteCash(this.billerCode, this.billerType).subscribe((result) => {
      if(result){
        PNotify.success({
          title: "Success",
          text: "Biller successfully added to your favorites.",
        });

        this.getShortcutApps.emit();
        this.isShortcutExisting = true;
      } else {
        PNotify.error({
          title: "Duplicate",
          text: "Biller is already exist.",
        });
        this.getShortcutApps.emit();
        this.isShortcutExisting = true;
      }
    }, error => {
      PNotify.error({
        title: "Error",
        text: "Something went wrong. Please contact support for assistance.",
      });
    });
  }

  removeShortcut(){
    this.ecPayService.removeFavoriteCash(this.billerCode, this.billerType).subscribe((result) => {
      PNotify.success({
        title: "Success",
        text: "Biller successfully removed to your favorites.",
      });
      this.getShortcutApps.emit();
      this.isShortcutExisting = false;
    }, error => {
      PNotify.error({
        title: "Error",
        text: "Something went wrong. Please cntact support for assistance.",
      });
    });
  }

  billerType: any;
  getBillersByBillerType(billerType: any) {
    this.billerType = billerType;
    this.billersByBillerTypes = this.billers.filter((x) => x.type == billerType);
  }

  setBillerCode(billerCode: any) {
    this.billerCode = billerCode;
    this.checkExisting(billerCode);
  }

  checkExisting(billerCode: any){
    this.isShortcutExisting = this.shortcutAccumulator.some(x => x.itemCode == billerCode);
    debugger
    return this.shortcutAccumulator.some(x => x.itemCode == billerCode);
  }


  getBiller() {
    this.activeTab++;
    this.biller = this.billers.filter((x) => x.code == this.billerCode)[0];
    //console.log(this.biller);
    this.addBillerParam(this.biller.meta);
    this.fee = this.biller.fee;
    // this.loader = true;
    // this.eccashService.getBiller(this.billerCode).subscribe((biller) => {
    //   if(biller.meta.length == 0){
    //     PNotify.error({
    //       title: "Error",
    //       text: "Biller is unavailable.",
    //     });
    //     this.loader = false;
    //   }else{
    //     this.activeTab++;
    //     this.addBillerParam(biller.meta);
    //     this.fee = biller.fee;
    //     this.biller = biller;
    //     this.loader = false;
    //   }
    // });
  }

  inquireBiller() {

    var amountFloat = parseFloat(this.paramsFormGroup()?.controls["amount"].value.replace(/,/g, ''));
    console.log(amountFloat,this.billerRate.rules);
    var rule : any = this.billerRate.rules.filter(x=>x.min <= amountFloat && x.max >= amountFloat);
    if(rule.length > 0){
     
      this.loader = true;
    //  this.eccashService.inquireBiller(this.paramsFormGroup().value,this.billerCode, this.fee).subscribe((biller) => {

    PNotify.info({
      title: "Validation will be execute after submitting",
      text: "Accepted",
    });
    this.loader = false;
    this.code = "";
    this.activeTab = 6;
    this.codeStatus = false;

    //  }, error => {
    //    PNotify.error({
    //      title: "Payment Validation",
    //      text: error.error.reason,
    //   });
    //   this.loader = false;

    //   });
    }else{
   
      PNotify.error({
        title: "Invalid Amount",
        text: `Amount should be between ${this.makeMoney(this.billerRate.rules[0].min)} to ${this.makeMoney(this.billerRate.rules[0].max)}.`,
      });
    }



   
  } //

  paramsFormGroup(): FormGroup {
    return this.billerFormGroup.get("params") as FormGroup;
  }

 

  addBillerParam(meta: any) {
    if (this.selectedBillerCode != this.billerFormGroup.controls.billerCode.value) {
      this.reInitBillerTypeFormGroup();

      meta.forEach((param) => {
        (this.billerFormGroup.get("params") as FormGroup).addControl(param.field, new FormControl(""));
      });
    }
    this.selectedBillerCode = this.billerFormGroup.controls.billerCode.value;
  }

  setActiveTab() {
    this.isTransactionStatusLegit = false;
    if (this.activeTab == 1) {
      if (this.billerFormGroup.status == "INVALID") {
        PNotify.error({
          title: "Error",
          text: "All fields are required to proceed to next step.",
        });
      } else {
        this.getBiller();
        this.getBillerRates();
      }
    } else if (this.activeTab == 2) {
      let hasError = false;
      this.biller.meta.forEach((param) => {
        if (!this.paramsFormGroup().controls[param.field].value && param.is_required) {
          this.paramsFormGroup().controls[param.field].setErrors({ required: param.label + " is required." });
          hasError = true;
        }
      });

      if (hasError) {
        PNotify.error({
          title: "Request denied",
          text: "Please fill up all the required fields.",
        });
      } 
      else {
        this.activeTab++;
      }
    } else if (this.activeTab == 3) {
      this.activeTab++;
    }else if (this.activeTab == 4) {
      this.activeTab++;
    }else if (this.activeTab == 5) {
      this.inquireBiller();
    }
  }


  isTransactionStatusLegit : any = false;

  transactionStatusLegit(val){
    this.isTransactionStatusLegit = val;
    this.activeTab++;
  }

  setActiveTabPrev() {
    
    this.isTransactionStatusLegit = false;
    if (this.activeTab == 1) {
      PNotify.error({
        title: "Error",
        text: "Action Denied.",
      });
    } else {
      this.activeTab--;
    }
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {}
  onCodeChangedOtp(otp: string) {}


  // this called only if user entered full code
  otpStatus = false;
  codeStatus = false;
  code: any;
  otp:any;
  onCodeCompleted(code: string) {
    this.code = code;
    this.codeStatus = code.length == 6;
  }

  onCodeCompletedOtp(otp: string) {
    this.otp = otp;
    this.otpStatus = otp.length == 6;
    // if(this.otpStatus){
    //   this.processPayment(this.otp);
    // }
  }


 otpModal;
  openPin() {
   this.otpModal = this.modalBService.open(this.pinModal, { centered: true, size: "md", backdrop: "static", keyboard: false });
  }

  closeTransaction() {
    this.modalBService.dismissAll();
  }

  requestOtp() {
    this.loader = true;
    this.ecPayService
      .sendOTP(
        this.paramsFormGroup().value,
        this.billerCode,
        this.fee,
        this.biller.name,
        this.biller.type,
        this.code
       // ,this.makeMoney(this.billerFormGroup.controls.amountToPaid.value.replace(/,/g, ''))
      )
      .subscribe(
        (biller) => {
          PNotify.success({
            title: "PIN Validate",
            text: "Please input OTP sent to your registered number.",
          });
          this.openPin();
          this.loader = false;
     //     this.code = "";
           this.codeStatus = false;
          // this.closeModal.emit();
        },
        (error) => {
          PNotify.error({
            title: "Invalid PIN",
            text: error.error.reason,
          });
          this.loader = false;
         this.code = "";
          this.codeStatus = false;
        }
      );
  }
  processPayment(otp) {
    if(this.loader == false){
      this.loader = true;
      this.otpModal.close();
      this.ecPayService
        .processCash(
          this.paramsFormGroup().value,
          this.billerCode,
          this.fee,
          this.biller.name,
          this.biller.type,
          this.code,
          otp
          //,this.makeMoney(this.billerFormGroup.controls.amountToPaid.value.replace(/,/g, ''))
        )
        .subscribe(
          (biller) => {
            this.closeModal.emit();
            PNotify.success({
              title: "Payment Success",
              text: biller.reason,
            });
            this.closeTransaction();
            this.loader = false;
            this.otp = "";
            this.otpStatus = false;
          },
          (error) => {
            PNotify.error({
              title: "Payment Failed",
              text: error.error.reason,
            });
            this.loader = false;
            this.otp = "";
            this.otpStatus = false;
            this.openPin();
          }
        );
    }
  }



  getBillerRates(){
   
    this.webSettingsService.getWebSettingsByIdentifier("ECCASH_BILLERRATE").subscribe((result) => {
     
      var billerRates : any = result.data.filter(x=>x.parameter == "BILL_RATE")[0].value;
      this.billerRate = billerRates.filter(x=>x.billerCode == this.billerCode)[0];
      console.log(this.billerRate);

     });
  }
  
  computeBillerRate(amount){
    debugger
    var amountFloat = parseFloat(amount.replace(/,/g, ''));
    //console.log(this.billerRate.rules);
    var rule : any = this.billerRate.rules.filter(x=>x.min <= amountFloat && x.max >= amountFloat);
    if(rule.length > 0){
   
      var data = rule[0];
      var fee = data.fee; 
      var type = data.type; 
      this.type = type =="percentage" ? '%' : '+' ;
      if(type =="percentage" ){
        var amountToPaidPerce = amountFloat + (amountFloat * fee);
        this.billerFormGroup.controls.billerFee.setValue(this.makeMoney((amountFloat * fee)));
        this.billerFormGroup.controls.amountToPaid.setValue(this.makeMoney(amountToPaidPerce));
      }else{
        var amountToPaidAddOn = amountFloat + fee;
        this.billerFormGroup.controls.billerFee.setValue(this.makeMoney(fee));
        this.billerFormGroup.controls.amountToPaid.setValue(this.makeMoney(amountToPaidAddOn));
        console.log(this.billerFormGroup);
      }
     
    }else{
      // PNotify.error({
      //   title: "No Biller Rate Setup",
      //   text: `Please contact ACM Support.`,
      // });
     
      PNotify.error({
        title: "Invalid Amount",
        text: `Amount should be between ${this.makeMoney(this.billerRate.rules[0].min)} to ${this.makeMoney(this.billerRate.rules[0].max)}.`,
      });
    }
  }

  
  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  totalAmount(fee: any, amount: any) {
    try {
      return this.makeMoney(Number(fee) + Number(amount));
    } catch (e) {
      return "0.00";
    }
  }

  optionValue(param) {
    return param.options.filter((x) => x.value == this.paramsFormGroup().controls[param.field].value)[0].key;
  }
  
}
