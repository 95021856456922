<app-loader *ngIf="loader"></app-loader>

<div class="row">
  <div class="col-lg-8" >
    <span style="font-weight: bold !important; color: #EE4D2D; font-size: 14px">List of Transactions</span>
    <div class="card mb-5" style="height: 500px; max-height: 500px; overflow-y: scroll;">
      <table
        id="table"
        class="table table-striped table-hover"
        style="width: 100%;"
      >

        <tbody class="w-100">
          <tr class=" " *ngFor="let remit of remittance" class="odd" role="row">
            <td class="flex">
              <div class="item-except text-sm h-1x">
                <strong>Transaction Name : {{ remit?.fmsDriverName }}</strong>
              </div>
              <div class="item-except text-muted text-sm h-1x">SPX Rider ID No. : {{ remit?.lifetimeID }}</div>
              <div class="item-except text-muted text-sm h-1x">Request ID : {{ remit?.requestId }}</div>
            </td>
            <td class="flex">
              <div class="item-except text-muted text-sm h-1x">Transact by : {{ remit?.transactByUser.username }} </div> 
              <div class="item-except text-muted text-sm h-1x">Created date : {{ remit?.createdDate }}</div>
              <div class="item-except text-muted text-sm h-1x">Completed date : {{ remit?.completedDate }}</div>
            </td>
            <td style="width: 120px;">
              <div class="text-center">
                <strong style="color: green"
                  >{{ remit?.amount | number : "1.2-2" }}
                </strong>
              </div>
            </td>
            <td style="width: 100px;">
              <app-status-table-data [status]="remit?.statusId"></app-status-table-data>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
    
      
  </div>
  
  <div class="col-lg-4">
    <span style="font-weight: bold !important; color: #EE4D2D; font-size: 14px">Billing Details</span>
      <div class="card-body">
          
          <table class="table table-hover" >   
              <tr>
                  <th  style="width: 180px;">Settlement Start Date</th>
                  <td style="text-align: right;">{{ transaction?.dateStart | date: "MMM dd, yyyy" }} </td>
              </tr>
              <tr>
                  <th  style="width: 180px;">Settlement End Date</th>
                  <td style="text-align: right;">{{ getDate(transaction?.dateEnd) | date: "MMM dd, yyyy" }} </td>
              </tr>
              <tr>
                  <th  style="width: 180px;">Transactions Count</th>
                  <td style="text-align: right;">{{ transaction?.totalItem}} </td>
              </tr>
              <tr>
                  <th  style="width: 180px;">Transactions Fee</th>
                  <td style="text-align: right;">{{  amount | number : "1.2-2" }} </td>
              </tr>
              <tr>
                  <th  style="width: 180px;">Transactions Amount</th>
                  <td style="text-align: right;">{{  (transaction?.totalAmount - (0 * transaction?.totalItem)) | number : "1.2-2" }} </td>
              </tr>
              
              <tr>
                  <th>Total Service Fee</th>
                  <td style="text-align: right; font-size: 20px; font-weight: bolder;">{{ (amount * transaction?.totalItem) | number : "1.2-2"}}</td>
              </tr>
          </table>
          <table  class="table table-hover" >     
              <tr>
                  <th>Billing Status</th>
                  <td style="text-align: right;">
                    <div *ngIf="transaction.statusId == 4">
                      <span class="badge badge-warning">For Sending Billing Invoice</span>
                    </div>
                    <div *ngIf="transaction.statusId == 3">
                      <span class="badge badge-warning">Waiting for Shopee Settlement</span>
                    </div>
                    <div *ngIf="transaction.statusId == 2">
                      <span class="badge badge-success">Completed</span>
                    </div>
                     <!-- <app-status-table-data [status]="transaction?.statusId"></app-status-table-data>  -->
                  </td>
              </tr>
              <tr>
                  <th>Created Date</th>
                  <td style="text-align: right;">{{ transaction?.createdDate | date: "MMM dd, yyyy" }} </td>
              </tr>
          </table>
  
          <hr>

          <div class="list list-row block">
            <div class="list-item " data-id="20">
                <div class="pointer" (click)="triggerInvoice(fileUploadInvoice)" >
                    <span class="w-40 avatar gd-info">
                        <i class="fa fa-upload" aria-hidden="true"></i>
                        <input #fileUploadInvoice type="file" style="display: none;"
                        accept="image/jpeg,image/png,application/pdf"  (change)="onUploadInvoice($event)" /> 
                    </span>
                </div>
                <div class="flex">
                    <a  class="item-author text-color">Billing Invoice</a>
                    <div class="item-except text-muted text-sm h-1x">
                        <label *ngIf="!transaction.spxInvoiceURL">Click the button to upload File...</label>
                        <label *ngIf="transaction.spxInvoiceURL"  style="color:blue; cursor: pointer;"
                        (click)="preview(viewImageModal, transaction.spxInvoiceURL, transaction.spxInvoiceFormat)">
                        <span class="fa fa-eye">&nbsp;</span>View File
                        </label>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="list list-row block" *ngIf="transaction.statusId == 4">
          <div class="d-flex justify-content-end">
            <button class="btn btn-sm btn-primary button--search" (click)="sendEmailBilling()"  *ngIf="transaction.statusId == 4 && (roleId == 1 || roleId == 2 || roleId == 7 || roleId != 19)" >
              Email Billing Invoice  &nbsp; <i class="fa fa-check"></i>
            </button>
          </div>
        </div>

        <div class="list list-row block" *ngIf="transaction.statusId == 3">
          <div class="d-flex justify-content-end">
          <button class="btn btn-sm btn-primary button--search" (click)="sendEmailBilling()"  *ngIf="transaction.statusId == 3 && (roleId == 1 || roleId == 2 || roleId == 7 || roleId != 19)" >
            Resend Invoice  &nbsp; <i class="fa fa-check"></i>
          </button>
        </div>
        </div>
  
          <div class="list list-row block">
              <div class="list-item " data-id="20">
                  <div class="pointer" (click)="triggerFile(fileUpload)" >
                      <span class="w-40 avatar gd-info">
                          <i class="fa fa-upload" aria-hidden="true"></i>
                          <input #fileUpload type="file" style="display: none;"
                          accept="image/jpeg,image/png,application/pdf"  (change)="onUploadFile($event)" /> 
                      </span>
                  </div>
                  <div class="flex">
                      <a  class="item-author text-color">SPX Settlement Receipt</a>
                      <div class="item-except text-muted text-sm h-1x">
                          <label *ngIf="!transaction.spxReceiptURL">Click the button to upload File...</label>
                          <label *ngIf="transaction.spxReceiptURL"  style="color:blue; cursor: pointer;"
                          (click)="preview(viewImageModal, transaction.spxReceiptURL, transaction.spxReceiptURLFormat)">
                          <span class="fa fa-eye">&nbsp;</span>View File
                          </label>
                      </div>
                  </div>
              </div>
          </div>
          
          <div class="list list-row block">
              <div class="d-flex justify-content-end">
                <button class="btn btn-sm btn-primary button--search" (click)="saveAndCompleteSettlement()"  *ngIf="transaction.statusId == 3 && (roleId == 1 || roleId == 2 || roleId == 7 || roleId != 19)" >
                  Settle Billing  &nbsp; <i class="fa fa-check"></i>
                </button>
              </div>
          </div>
      </div>
      
  </div>
  
</div>


<ng-template #viewImageModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">File Viewer</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" >
      <div class="d-flex justify-content-end mr-2">
       
        <!-- <button class="btn btn-sm btn-success mr-2 mb-1" (click)="downloadImage(photo, photoFormat, photoFileName)">
          <i class="fa fa-download"></i> &nbsp; Download
        </button> -->
  
      </div>
      <div>
        <img 
            [src]="imagePreview"
            onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
            style="width: 100%"
          />
      </div>
    </div>
  </ng-template>
 