<app-loader *ngIf="!requestFunds || loader"></app-loader>
<app-loader *ngIf="processUpdate"></app-loader>

<div id="content" class="flex" *ngIf="requestFunds">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">List of Request Funds</h2>
          <small class="font-italic" style="font-weight: bold">JuanPay list of request funds</small>
        </div>
        <div class="flex"></div>
        <div class="mr-2">
          <div>
            <button class="btn btn-md btn-success w-100" (click)="exportToExcel()">
              <i class="fa fa-file-excel"></i> &nbsp; Export to Excel
            </button>
          </div>
        </div>

        <div>
          <div *ngIf="roleLevelId == 2" (click)="createRequestFund()" class="btn btn-md btn-info">
            <span class="d-none d-sm-inline mx-1">Create New</span>
            <i data-feather="arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="">
        <div class="card p-1" style="background-color: #182038">
          <div class="row">
            <!-- <div class="col-sm-12 col-md-4 col-lg-4"></div>
            <div class="col-sm-12 col-md-4 col-lg-4">

            </div> -->
            <div class="col-sm-12 col-md-12 col-lg-12 pl-0">
              <form class="form-inline" [formGroup]="listFilterForm">
                <div class="col-sm-12 col-md-6 col-lg-2">
                  <ng-select
                    [items]="showRowsOption"
                    [placeholder]="' &nbsp;&nbsp;&nbsp;&nbsp; No of Rows &nbsp;&nbsp;&nbsp;&nbsp;'"
                    [allowClear]="true"
                    bindLabel="text"
                    bindValue="id"
                    formControlName="showRows"
                  ></ng-select>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-2">
                  <div *ngIf="roleId != 3">
                    <ng-select
                      [(ngModel)]="userIdFilter"
                      [items]="managerUsers"
                      [placeholder]="'Select Username'"
                      [allowClear]="true"
                      bindLabel="text"
                      bindValue="id"
                      formControlName="userId"
                      style="background-color: rgb(255, 255, 255); color: black"
                    ></ng-select>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-2">
                  <ng-select
                    [items]="statusOPtion"
                    [placeholder]="'Select Status'"
                    [allowClear]="true"
                    bindLabel="text"
                    bindValue="id"
                    formControlName="status"
                  ></ng-select>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-2 pr-1 pl-0">
                  <input
                    type="text"
                    placeholder="Date Range"
                    class="form-control w-100"
                    bsDaterangepicker
                    formControlName="dateRange"
                    style="background-color: rgb(255, 255, 255); color: black"
                  />
                </div>

                <div class="col-sm-12 col-md-6 col-lg-2 pr-1 pl-0">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control search"
                      placeholder="Search..."
                      formControlName="searchString"
                      style="background-color: rgb(255, 255, 255); color: black"
                    />
                    <!-- <span class="input-group-append">
                      
                    </span> -->
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-2 pl-0 pr-0 d-flex justify-content-end align-items-center">
                  <button class="btn btn-sm btn-primary w-100" (click)="getRequestFund(null, null, null, true)">
                    <i class="fa fa-search"></i> &nbsp; Search
                  </button>
                </div>
              </form>
            </div>
          </div>

          <!-- Search Button -->
        </div>

        <div class="card mt-2">
          <table
            id="table"
            class="table table-striped table-hover"
            data-plugin="bootstrapTable"
            data-toolbar="#toolbar"
            data-search="true"
            data-search-align="left"
            data-show-export="true"
            data-show-columns="true"
            data-detail-view="false"
            data-mobile-responsive="true"
            data-pagination="true"
            data-page-list="[10, 25, 50, 100, ALL]"
          >
            <thead>
              <tr>
                <th data-sortable="true" data-field="id">No</th>
                <th data-sortable="true" data-field="id">Details</th>
                <th data-sortable="true" data-field="owner">Identity</th>
                <th data-sortable="true" data-field="project">Amount</th>
                <th data-field="task"><span class="d-none d-sm-block">Balance Before</span></th>
                <th data-field="finish"><span class="d-none d-sm-block">Balance After</span></th>
                <th data-field="finish"><span class="d-none d-sm-block">Status</span></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class=" "
                *ngFor="
                  let requestFund of requestFunds?.data
                    | paginate: { itemsPerPage: pageRows, currentPage: p, totalItems: requestFunds.totalRecords };
                  let i = index
                "
                (click)="viewDetails(requestFundModal, requestFund)"
                class="odd"
                role="row"
              >
                <td class="flex">
                  <div class="item-except text-sm text-dark h-1x">
                    {{ i + 1 }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm text-dark h-1x">
                    <span style="font-weight: bold" class="text-secondary">Request ID :</span>
                    {{ requestFund?.requestId }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span style="font-weight: bold" class="text-secondary">Reference Number :</span>
                    {{ requestFund?.referenceNumber }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span style="font-weight: bold" class="text-secondary">Fund Origin :</span>
                    {{ requestFund?.origin }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span style="font-weight: bold" class="text-secondary">Fund Destination :</span>
                    {{ requestFund?.destination }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    <span style="font-weight: bold" class="text-secondary">Requested by :</span>
                    {{ requestFund?.createdByUsername }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span style="font-weight: bold" class="text-secondary">Created date :</span>
                    {{ requestFund?.createdDate | date: "MMMM dd, yyyy hh:mm:ss a" }}
                  </div>
                  <div class="item-except text-sm h-1x" *ngIf="requestFund?.destination != 'GOVIPCENTER'">
                    <span style="font-weight: bold" class="text-secondary">Completed by :</span>
                    {{ requestFund?.completedByUsername }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span style="font-weight: bold" class="text-secondary">Completed date :</span>
                    {{ requestFund?.completedDate | date: "MMMM dd, yyyy hh:mm:ss a" }}
                  </div>
                </td>
                <td>
                  <div class="text-center">
                    <strong style="color: green">{{ makeMoney(requestFund?.amount) }} </strong>
                  </div>
                </td>
                <td>
                  <div class="text-center">
                    <strong *ngIf="requestFund?.statusId == 2">{{ makeMoney(requestFund?.openingBalance) }}</strong>
                    <i class="" *ngIf="requestFund?.statusId != 2">N/A</i>
                  </div>
                </td>
                <td>
                  <div class="text-center">
                    <strong *ngIf="requestFund?.statusId == 2">{{
                      makeMoney(requestFund?.openingBalance + requestFund?.amount)
                    }}</strong>
                    <i class="" *ngIf="requestFund?.statusId != 2">N/A</i>
                  </div>
                </td>
                <td>
                  <app-status-table-data [status]="requestFund?.statusId"></app-status-table-data>
                </td>
                <!-- <td>
                  <div class="item-action dropdown">
                    <a href="#" data-toggle="dropdown" class="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-more-vertical"
                      >
                        <circle cx="12" cy="12" r="1"></circle>
                        <circle cx="12" cy="5" r="1"></circle>
                        <circle cx="12" cy="19" r="1"></circle>
                      </svg>
                    </a>

                    <div class="dropdown-menu dropdown-menu-right bg-black" role="menu">
                      <a
                        *ngIf="roleLevelId == 1 && requestFund.statusId == 3"
                        data-toggle="modal"
                        (click)="setSelectedRequestFund(requestFund)"
                        data-target="#modal-approve"
                        data-toggle-class-target=".animate"
                        class="dropdown-item trash"
                      >
                        Approve
                      </a>
                      <div *ngIf="roleLevelId == 1 && requestFund.statusId == 3" class="dropdown-divider"></div>
                      <a
                        *ngIf="roleLevelId == 1 && requestFund.statusId == 3"
                        data-toggle="modal"
                        (click)="setSelectedRequestFund(requestFund)"
                        data-target="#modal-reject"
                        data-toggle-class-target=".animate"
                        class="dropdown-item trash"
                      >
                        Reject
                      </a>
                      <div *ngIf="roleLevelId == 1 && requestFund.statusId == 3" class="dropdown-divider"></div>
                      <a
                        data-toggle="modal"
                        (click)="setSelectedRequestFund(requestFund)"
                        data-target="#modal-receipt"
                        data-toggle-class-target=".animate"
                        class="dropdown-item trash"
                      >
                        See Official Recipt
                      </a>
                    </div>
                  </div>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
              <div class="col-sm-12 col-md-12">
                <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                  <span style="font-weight: bold">
                    Showing {{ (requestFunds?.pageNumber - 1) * requestFunds?.pageSize + 1 }} to
                    {{ (requestFunds?.pageNumber - 1) * requestFunds?.pageSize + requestFunds?.data?.length }} of
                    {{ requestFunds?.data?.length }} entries
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-7" style="float: right">
            <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
              <ul class="pagination" style="float: right; margin-right: 36px">
                <pagination-controls (pageChange)="getRequestFund(buildPageUrl($event, 10))"></pagination-controls>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<div id="modal-approve" class="modal fade" data-backdrop="true">
  <div class="modal-dialog animate">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title text-md text-info">Approving of Request Fund</div>
        <button class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="p-4 text-center">
          <p>Are you sure you want to approve the request fund?</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
        <button type="button" (click)="processRequestFund(true)" class="btn btn-primary" data-dismiss="modal">
          Continue
        </button>
        <!--  -->
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
</div>

<div id="modal-reject" class="modal fade" data-backdrop="true">
  <div class="modal-dialog animate">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title text-md text-danger">Rejecting of Request Fund</div>
        <button class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="p-4 text-center">
          <p>Are you sure you want to reject the request fund?</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
        <button type="button" (click)="processRequestFund(false)" class="btn btn-danger" data-dismiss="modal">
          Continue
        </button>
        <!--  -->
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
</div>

<!-- <div id="modal-receipt" class="modal fade" data-backdrop="true">
  <div class="modal-dialog animate">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title text-md">Original Official Reciept</div>
        <button class="close" data-dismiss="modal">&times;</button>
      </div>

      <div class="modal-body">
        <div class="p-4 text-center">
          <p>Make sure that this receipt was validated</p>
          <img
            *ngIf="requestFund?.officialReceiptPhoto"
            [src]="buildUserPhoto(requestFund) | authImage | async"
            alt="VIP"
            onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
            style="width: 100%"
          />
          <img *ngIf="!requestFund?.officialReceiptPhoto" src="https://via.placeholder.com/64x64?text=VIP" alt="VIP" />
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div> -->

<ng-template #requestFundModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Request Fund Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-sm-6">
        <div class="card bg-dark mb-2 p-1 d-flex align-items-center justify-content-center">
          <p class="font-weight-bold mb-0">Transaction Details</p>
        </div>
        <form action="" [formGroup]="requestFundRemarkFormGroup">
          <table class="table table-striped bg-white">
            <tbody>
              <tr>
                <td colspan="5">Amount</td>
                <th class="text-nowrap" scope="row">{{ makeMoney(requestFund?.amount) }}</th>
              </tr>
              <tr>
                <td colspan="5">Reference number</td>
                <th class="text-nowrap" scope="row">{{ requestFund?.referenceNumber }}</th>
              </tr>
              <tr>
                <td colspan="5">Request origin</td>
                <th class="text-nowrap" scope="row">{{ requestFund?.origin }}</th>
              </tr>
              <tr>
                <td colspan="5">Request destination</td>
                <th class="text-nowrap" scope="row">{{ requestFund?.destination }}</th>
              </tr>
              <tr>
                <td colspan="5">Requested by</td>
                <th class="text-nowrap" scope="row">{{ requestFund?.createdByUsername }}</th>
              </tr>
              <tr>
                <td colspan="5">Created date</td>
                <th class="text-nowrap" scope="row">{{ requestFund?.createdDate }}</th>
              </tr>
              <tr>
                <td colspan="5">Notes</td>
                <th style="max-width: 40em; max-width: 100ch" scope="row">{{ requestFund?.managerRemarks }}</th>
              </tr>
              <tr>
                <td colspan="5" class="pb-0">Remarks</td>
                <th style="max-width: 40em; max-width: 100ch" scope="row">
                  <span *ngIf="requestFund?.statusId != 3 || (roleId != 7 && roleId != 22)">{{
                    requestFund?.financeRemarks
                  }}</span>
                  <textarea
                    formControlName="remarks"
                    placeholder="Remarks..."
                    class="form-control w-100"
                    *ngIf="requestFund?.statusId == 3 && (roleId == 7 || roleId == 1 || roleId == 2 || roleId == 22)"
                  ></textarea>
                  <!-- <app-textarea placeholder="Remarks..." label="Remarks" [formControl]="remarks" read-only="false">
                  </app-textarea> -->
                </th>
              </tr>
              <tr *ngIf="requestFund?.statusId == 6">
                <td colspan="5" class="pb-0">Void Remarks</td>
                <th style="max-width: 40em; max-width: 100ch" scope="row">
                  <span>{{ requestFund?.voidRemarks }}</span>
                  <!-- <app-textarea placeholder="Remarks..." label="Remarks" [formControl]="remarks" read-only="false">
                  </app-textarea> -->
                </th>
              </tr>
              <tr>
                <td colspan="6" class="pt-0 pb-0">
                  <button
                    class="btn btn-primary mt-2"
                    style="float: right; width: 100px"
                    [disabled]="isRemark"
                    *ngIf="requestFund?.statusId == 3 && (roleId == 7 || roleId == 1 || roleId == 2 || roleId == 22)"
                    (click)="approveRequest(approvedFund)"
                  >
                    Approve
                  </button>

                  <button
                    class="btn btn-danger mr-2 mt-2"
                    style="float: right; width: 100px"
                    [disabled]="isRemark"
                    *ngIf="requestFund?.statusId == 3 && (roleId == 7 || roleId == 1 || roleId == 2 || roleId == 22)"
                    (click)="rejectRequest(rejectFund)"
                  >
                    Reject
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>

        <div *ngIf="!isTicketActive">
          <br />

          <div class="w-100 d-flex justify-content-end">
            <button
              *ngIf="requestFund?.statusId == 2 && roleLevelId == 1"
              class="btn btn-sm btn-danger"
              style="width: 120px"
              (click)="void()"
            >
              Void &nbsp; <i class="fa fa-step-backward"></i>
            </button>
            &nbsp;&nbsp;
            <button class="btn btn-sm btn-warning" style="width: 120px" (click)="openTicket()">
              Open Ticket &nbsp; <i class="fa fa-tag"></i>
            </button>
          </div>
          <br />
        </div>

        <!-- <div class=" bg-dark mb-2 p-1 d-flex align-items-center justify-content-center">
          <p class="font-weight-bold mb-0">Note</p>
        </div> -->
      </div>

      <div class="col-sm-6">
        <div class="card bg-dark mb-2 p-1 d-flex align-items-center justify-content-center">
          <p class="font-weight-bold mb-0">Attachment: Official Receipt</p>
        </div>
        <div class="text-center" style="max-height: 410px; overflow-y: scroll">
          <img
            *ngIf="currentImage?.officialReceiptPhoto"
            [src]="buildUserPhoto(currentImage) | authImage | async"
            alt="VIP"
            onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
            style="width: 100%"
          />
          <img
            *ngIf="requestFund.officialReceiptPhotoUrl"
            [src]="requestFund.officialReceiptPhotoUrl"
            alt="VIP"
            onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
            style="width: 100%"
          />
          <img
            *ngIf="!currentImage?.officialReceiptPhoto && !requestFund.officialReceiptPhotoUrl"
            src="https://via.placeholder.com/64x64?text=VIP"
            alt="VIP"
            style="width: 100%"
          />
        </div>
      </div>

      <div class="col-sm-12">
        <app-ticket-create-transaction
          *ngIf="isTicketActive"
          [requestId]="requestFund.requestId"
          transactionType="REQUEST_FUND"
          [username]="requestFund?.createdByUsername"
        >
        </app-ticket-create-transaction>
      </div>

      <div
        class="col-sm-12 mt-2"
        *ngIf="requestFund?.statusId == 3 && (roleId == 7 || roleId == 1 || roleId == 2 || roleId == 22)"
      >
        <div class="card bg-dark mb-2 p-1 d-flex align-items-center justify-content-center">
          <p class="font-weight-bold mb-0">Transaction History</p>
        </div>

        <div style="max-height: 300px; overflow-y: scroll">
          <table
            id="table"
            class="table table-striped table-hover"
            data-plugin="bootstrapTable"
            data-toolbar="#toolbar"
            data-search="true"
            data-search-align="left"
            data-show-export="true"
            data-show-columns="true"
            data-detail-view="false"
            data-mobile-responsive="true"
            data-pagination="true"
            data-page-list="[10, 25, 50, 100, ALL]"
          >
            <thead>
              <tr>
                <th data-sortable="true" data-field="id">Details</th>
                <th data-sortable="true" data-field="owner">Identity</th>
                <th data-sortable="true" data-field="project">Amount</th>
                <th data-field="task"><span class="d-none d-sm-block">Balance Before</span></th>
                <th data-field="finish"><span class="d-none d-sm-block">Balance After</span></th>
                <th data-field="finish"><span class="d-none d-sm-block">Status</span></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class=" "
                *ngFor="let lastTenFund of lastTenFunds"
                (click)="viewOfficialReceipt(officialReceiptModal, lastTenFund)"
                class="odd"
                role="row"
              >
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    <span style="font-weight: bold" class="text-secondary">Reference Number :</span>
                    {{ lastTenFund?.referenceNumber }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span style="font-weight: bold" class="text-secondary">Fund Origin :</span>
                    {{ lastTenFund?.origin }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span style="font-weight: bold" class="text-secondary">Fund Destination :</span>
                    {{ lastTenFund?.destination }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    <span style="font-weight: bold" class="text-secondary">Requested by :</span>
                    {{ lastTenFund?.createdByUsername }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span style="font-weight: bold" class="text-secondary">Created date :</span>
                    {{ lastTenFund?.createdDate | date: "MMMM dd, yyyy hh:mm:ss a" }}
                  </div>
                </td>
                <td>
                  <div class="text-center">
                    <strong style="color: green">{{ makeMoney(lastTenFund?.amount) }} </strong>
                  </div>
                </td>
                <td>
                  <div class="text-center">
                    <strong *ngIf="lastTenFund?.statusId == 2">{{ makeMoney(lastTenFund?.openingBalance) }}</strong>
                    <i class="" *ngIf="lastTenFund?.statusId != 2">N/A</i>
                  </div>
                </td>
                <td>
                  <div class="text-center">
                    <strong *ngIf="lastTenFund?.statusId == 2">{{
                      makeMoney(lastTenFund?.openingBalance + lastTenFund?.amount)
                    }}</strong>
                    <i class="" *ngIf="lastTenFund?.statusId != 2">N/A</i>
                  </div>
                </td>
                <td>
                  <app-status-table-data [status]="lastTenFund?.statusId"></app-status-table-data>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #requestFundGOVIPCENTERModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Request Fund Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        <div class="card bg-dark mb-2 p-1 d-flex align-items-center justify-content-center">
          <p class="font-weight-bold mb-0">Transaction Details</p>
        </div>
        <form action="" [formGroup]="requestFundRemarkFormGroup">
          <table class="table table-striped bg-white">
            <tbody>
              <tr>
                <td colspan="5">Amount</td>
                <th class="text-nowrap" scope="row">{{ makeMoney(requestFund?.amount) }}</th>
              </tr>
              <tr>
                <td colspan="5">Reference number</td>
                <th class="text-nowrap" scope="row">{{ requestFund?.referenceNumber }}</th>
              </tr>
              <tr>
                <td colspan="5">Request origin</td>
                <th class="text-nowrap" scope="row">{{ requestFund?.origin }}</th>
              </tr>
              <tr>
                <td colspan="5">Request destination</td>
                <th class="text-nowrap" scope="row">{{ requestFund?.destination }}</th>
              </tr>
              <tr>
                <td colspan="5">Requested by</td>
                <th class="text-nowrap" scope="row">{{ requestFund?.createdByUsername }}</th>
              </tr>
              <tr>
                <td colspan="5">Created date</td>
                <th class="text-nowrap" scope="row">{{ requestFund?.createdDate }}</th>
              </tr>
              <tr>
                <td colspan="5" class="pb-0">Remarks</td>
                <th scope="row">
                  <span *ngIf="roleId != 7 && roleId != 22 && roleId != 1">{{ govipcenterRemarks }}</span>
                </th>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #officialReceiptModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Compare Receipt</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-sm-6">
        <div class="card bg-dark mb-2 p-1 d-flex align-items-center justify-content-center">
          <p class="font-weight-bold mb-0">Attachment: Official Receipt</p>
        </div>
        <div class="text-center" style="max-height: 410px; overflow-y: scroll">
          <img
            *ngIf="currentImage?.officialReceiptPhoto"
            [src]="buildUserPhoto(currentImage) | authImage | async"
            alt="VIP"
            onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
            style="width: 100%"
          />
          <img
            *ngIf="requestFund.officialReceiptPhotoUrl"
            [src]="requestFund.officialReceiptPhotoUrl"
            alt="VIP"
            onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
            style="width: 100%"
          />
          <img
            *ngIf="!currentImage?.officialReceiptPhoto && !requestFund.officialReceiptPhotoUrl"
            src="https://via.placeholder.com/64x64?text=VIP"
            alt="VIP"
            style="width: 100%"
          />
        </div>
      </div>

      <div class="col-sm-6">
        <div class="card bg-dark mb-2 p-1 d-flex align-items-center justify-content-center">
          <p class="font-weight-bold mb-0">History: Official Receipt</p>
        </div>
        <div class="text-center" style="max-height: 410px; overflow-y: scroll">
          <img
            *ngIf="currentImageinLastTen?.officialReceiptPhoto"
            [src]="buildUserHPhoto(currentImageinLastTen) | authImage | async"
            alt="VIP"
            onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
            style="width: 100%"
          />
          <img
            *ngIf="officialReceipt.officialReceiptPhotoUrl"
            [src]="officialReceipt.officialReceiptPhotoUrl"
            alt="VIP"
            onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
            style="width: 100%"
          />

          <img
            *ngIf="!currentImageinLastTen?.officialReceiptPhoto && !officialReceipt.officialReceiptPhotoUrl"
            src="https://via.placeholder.com/64x64?text=VIP"
            alt="VIP"
            style="width: 100%"
          />
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #approvedFund let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Are you sure you want to approve request fund?</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <button class="btn btn-sm btn-primary float-right" (click)="processRequestFund(true)">Proceed</button>
    <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #rejectFund let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Are you sure you want to reject request fund?</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <button class="btn btn-sm btn-danger float-right" (click)="processRequestFund(false)">Proceed</button>
    <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #pinModal let-modal>
  <div class="bg-dark">
    <div class="modal-header justify-content-center">
      <img src="https://i.ibb.co/vsQZ3MC/Go-VIP-Logo.png" height="29.12" width="59.68" alt="Go-VIP-Logo" />

      <h4 class="modal-title ml-5">ENTER MOBILE OTP</h4>
      <!-- <img src="https://i.ibb.co/vsQZ3MC/Go-VIP-Logo.png" height="101.92" width="208.88" alt="Go-VIP-Logo" /> -->
      <button type="button" class="close" aria-label="Close" (click)="closeTransaction()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <!-- <app-pin (doLoginPin)="processPayment($event)" [code]="otp"></app-pin> -->
  <div class="modal-body">
    <div class="col-sm-12 col-md-12 p-3">
      <div class="col-sm-12 col-md-12" style="float: none; margin: auto">
        <label>Void Remarks</label>
        <form action="" [formGroup]="requestFundRemarkFormGroup">
          <table class="table table-striped bg-white">
            <tbody>
              <tr>
                <th style="max-width: 40em; max-width: 100ch" scope="row">
                  <textarea
                    formControlName="remarksVoid"
                    placeholder="Remarks..."
                    class="form-control w-100"
                    rows="10"
                  ></textarea>
                </th>
              </tr>
            </tbody>
          </table>
        </form>

        <!-- <div class="text-center">
          <h3>ENTER SECURITY PASSCODE</h3>
        </div> -->
        <code-input
          [isCodeHidden]="false"
          [codeLength]="6"
          (codeChanged)="onCodeChangedOtp($event)"
          (codeCompleted)="onCodeCompletedOtp($event)"
          [code]="otp"
          isCharsCode="true"
        >
        </code-input>
        <br />

        <a
          *ngIf="otpStatus"
          (click)="processVoid(otp)"
          class="btn btn-info button-next"
          style="color: white; float: right"
        >
          Continue &nbsp;
          <span class="fa fa-hand-pointer"></span>
        </a>
      </div>
    </div>
  </div>
</ng-template>
