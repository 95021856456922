import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  constructor(private http: HttpClient) {}

  getExcelData(): Promise<any[]> {
    const excelFilePath = '../assets/file/address.xlsx';

    return this.http
      .get(excelFilePath, { responseType: 'blob' })
      .toPromise()
      .then((blob) => {
        return this.readExcelData(blob);
      });
  }

  private readExcelData(blob: Blob): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

        resolve(jsonData as any[]);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsBinaryString(blob);
    });
  }
}
