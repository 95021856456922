<app-loader *ngIf="!user || processStoreSave"></app-loader>

<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex" *ngIf="user">
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">Sales User's Profile</h2>
          <p class="text-highlight font-weight-normal font-italic">Your personal information</p>
        </div>
        <div class="flex"></div>
        <div
          *ngIf="
            (user.salesUser?.mobileActivationDate == null || user.salesUser?.emailActivationDate == null) &&
            (roleLevelId == 1 || roleLevelId == 2)
          "
        >
          <div (click)="resendEmailVerification(userId)" class="btn btn-md btn-secondary">
            <span class="d-none d-sm-inline mx-1">Resend Account Activation</span>
            <i data-feather="arrow-right"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="page-content page-container" id="page-content">
      <div class="padding sr">
        <div class="card">
          <div
            class="card-header bg-dark bg-img p-0 no-border"
            data-stellar-background-ratio="0.1"
            style="background-image: url(../../../assets/img/b3.jpg)"
            data-plugin="stellar"
          >
            <div class="bg-dark-overlay r-2x no-r-b">
              <div class="d-md-flex">
                <div class="p-4">
                  <div class="d-flex">
                    <a>
                      <span class="avatar w-64">
                        <img
                          class="w-48 avatar"
                          *ngIf="user.salesUser?.photo"
                          [src]="buildUserPhoto(user) | authImage | async"
                          alt="https://i.ibb.co/cczMjzp/male.jpg"
                          onerror="this.src='https://i.ibb.co/cczMjzp/male.jpg';"
                        />
                        <img
                          class="w-48 avatar"
                          *ngIf="!user.salesUser?.photo"
                          src="https://i.ibb.co/cczMjzp/male.jpg"
                          alt="https://i.ibb.co/cczMjzp/male.jpg"
                        />
                        <i class="on"></i>
                      </span>
                    </a>
                    <div class="mx-3">
                      <h5 class="mt-2">{{ user.salesUser?.firstName }} {{ user.salesUser?.lastName }}</h5>
                      <div class="text-fade text-sm">
                        <span class="m-r">{{ role }}</span>
                        <small>
                          <span
                            class="vip-input-text__activated"
                            [ngClass]="{ active: user.salesUser?.email_verified_at }"
                          >
                            {{ user.salesUser?.status ? "Active" : "Deactivated" }}
                          </span></small
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-3">
            <div class="d-flex">
              <ul class="nav nav-pills">
                <li class="nav-item">
                  <a class="nav-link active" data-toggle="tab" data-target="#tab_4">Profile</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" data-target="#tab_activation">Activation Details</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" data-target="#tab_bankDatails">Bank Details</a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" data-target="#tab_files">Files</a>
                </li> -->
              </ul>
            </div>
          </div>
        </div>
        
        <div class="row">
          <div class="col-sm-12 col-lg-12">
            <div class="tab-content">


              <div class="tab-pane fade show active" id="tab_4">
                <div class="card">
                  <div class="card-header">
                    <strong>Sales User's Profile</strong>
                    <div *ngIf="roleLevelId == 2" (click)="editCashierUserProfile()" style="float: right">
                      <button class="btn btn-sm btn-primary mb-2 text-white">
                        Update &nbsp; <span class="fa fa-edit"></span>
                      </button>
                    </div>
                  </div>
                  <div class="px-4 py-4">
                    <div class="row mb-2">
                      <div class="col-6">
                        <small class="text-muted">Username</small>
                        <div class="my-2">{{ user?.username }}</div>
                      </div>
                      <div class="col-6">
                        <small class="text-muted">Employee Number</small>
                        <div class="my-2">{{ user.salesUser?.employeeId }}</div>
                      </div>
                      
                    </div>
                    <div class="row mb-2">
                      <div class="col-6">
                        <small class="text-muted">Gender</small>
                        <div class="my-2">{{ user.salesUser?.gender }}</div>
                      </div>
                      
                      <div class="col-6">
                        <small class="text-muted">Email Address</small>
                        <div class="my-2">{{ user?.email }}</div>
                      </div>

                    </div>
                    <div class="row mb-2">
                      <div class="col-6">
                        <small class="text-muted">Birth Date</small>
                        <div class="my-2">{{ user.salesUser?.birthDate | date: "MMMM dd, yyyy" }}</div>
                      </div>

                      <div class="col-6">
                        <small class="text-muted">Contact Number</small>
                        <div class="my-2">{{ user.salesUser?.contactNumber }}</div>
                      </div>
                      
                    </div>
                    <div>
                      <div>
                        <small class="text-muted">Address</small>
                        <div class="my-2">{{ user.salesUser?.address }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="tab-pane fade show" id="tab_activation">

                <div class="card">
                  <div class="card-header">
                    <strong>VIP Activation Details</strong>
                  </div>
                  <div class="card-body">

                    <div class="px-4 py-4">
                      <div class="row mb-2">
                        <div class="col-6">
                          <small class="text-muted">Sponsor</small>
                          <div class="my-2">{{ user.salesUser?.sponsorId  }}</div>
                        </div>

                        <div class="col-6">
                          <small class="text-muted">Activation Date</small>
                          <div class="my-2">{{ user.salesUser?.vipCreatedDate | date: "MMMM dd, yyyy"  }}</div>
                        </div>

                      </div>

                      <div class="row mb-2">
                        <div class="col-6">
                          <small class="text-muted">Members</small>
                          <div class="my-2">{{ user.salesUser?.membersId }}</div>
                        </div>
                        <div class="col-6">
                          <small class="text-muted">Hiring Date</small>
                          <div class="my-2">{{ user.salesUser?.hiringDate | date: "MMMM dd, yyyy" }}</div>
                        </div>
                      </div>

                      <div class="row mb-2">
                        <div class="col-6">
                          <small class="text-muted">Upline</small>
                          <div class="my-2">{{ user.salesUser?.uplineId }}</div>
                        </div>
                        <div class="col-6">
                          <small class="text-muted">Placement</small>
                          <div class="my-2">{{ user.salesUser?.placement }}</div>
                        </div>
                      </div>
                      

                    </div>

                  </div>
                </div>
              </div>

              <div class="tab-pane fade show" id="tab_bankDatails">

                <div class="card">
                  <div class="card-header">
                    <strong>Bank Details</strong>

                  </div>
                  <div class="card-body">


                    <div class="px-4 py-4">
                      <div class="row mb-2">
                        <div class="col-6">
                          <small class="text-muted">Bank</small>
                          <div class="my-2">{{ user.salesUser?.bank  }}</div>
                        </div>

                        <div class="col-6">
                          <small class="text-muted">Bank Account</small>
                          <div class="my-2">{{ user.salesUser?.bankAccount  }}</div>
                        </div>

                      </div>

                      <div class="row mb-2">
                        <div class="col-6">
                          <small class="text-muted">Bank Type</small>
                          <div class="my-2">{{ user.salesUser?.bankType }}</div>
                        </div>
                      </div>
                      

                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade show" id="tab_files">

                <div class="card">
                  <div class="card-header">
                    <strong>Bank Details</strong>

                  </div>
                  <div class="card-body">


                    <div class="px-4 py-4">
                      <div class="row mb-2">
                        <div class="col-6">
                          <small class="text-muted">Bank</small>
                          <div class="my-2">{{ user.salesUser?.bank  }}</div>
                        </div>

                        <div class="col-6">
                          <small class="text-muted">Bank Account</small>
                          <div class="my-2">{{ user.salesUser?.bankAccount  }}</div>
                        </div>

                      </div>

                      <div class="row mb-2">
                        <div class="col-6">
                          <small class="text-muted">Bank Type</small>
                          <div class="my-2">{{ user.salesUser?.bankType }}</div>
                        </div>
                      </div>
                      

                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div id="modal-left-settings" class="modal fade" data-backdrop="true">
  <div class="modal-dialog animate">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title text-md">Updating Store Settings</div>
        <button class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="p-4 text-center">
          <p>Are you sure you want continue?</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
        <button  type="button" class="btn btn-primary" data-dismiss="modal">
          Save Changes
        </button>
        <!--  -->
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
</div>