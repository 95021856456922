<!-- <div id="mainPage-admin" class="container-fluid">

<header class="fixed-top"> 
    <app-header [wallet]="wallet"></app-header>
</header>

<section>
  <div class="container-fluid">
    <div class="row">
      <div id="sidebar" class="sidenav col-2 d-none d-lg-flex fixed-left">
        <div id="side">
          <app-side-nav></app-side-nav>
        </div>
      </div>

      <div class="ContentBox col">
        <main id="mainContent" class="my-auto"> 
          <router-outlet (activate)="changeOfRoutes()"></router-outlet>
        </main>
      </div>
    </div>
  </div>
</section>

<footer class="container-fluid" style="height: 20vh;"> 
  <app-footer></app-footer>
</footer>

</div> -->

<div id="mainPage-admin">
  <header class="fixed-top">
    <app-header [wallet]="wallet"></app-header>
  </header>
  
  <div class="container-fluid">
    <div class="row">
      <div class="col-2 pr-0 sidenav d-none d-lg-flex fixed-left">
        <app-side-nav></app-side-nav>
      </div>
      <div class="ContentBox col pr-0 pl-0">
        <main id="mainContent" class="my-auto"> 
          <router-outlet (activate)="changeOfRoutes()"></router-outlet>
        </main>
      </div>
    </div>
  </div>
  
  <app-footer></app-footer>
</div>




  <!-- <div class="fixed-top">
    <app-header [wallet]="wallet"></app-header>
  </div>

  <div class="container-fluid">
    <div id="sidebar" class="sidenav">
      <app-side-nav></app-side-nav>
    </div>
  </div>

  <div class="ContentBox">
    <main id="mainContent" class="my-auto">
      <router-outlet (activate)="changeOfRoutes()">
      </router-outlet>
    </main>
  </div>
  
  <div class="container-fluid mt-1">
    <app-footer></app-footer>
  </div> -->