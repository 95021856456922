<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">VIP Protect Inventory</h2>
          <small class="text-highlight font-weight-normal font-italic">JuanPay list of VIP Protect Items</small>
        </div>
        <div class="flex"></div>
        <div>
          <div (click)="exportToExcel()" class="btn btn-sm btn-success mr-2">
            <span class="d-none d-sm-inline mx-1">Export to Excel</span>
            <i data-feather="arrow-right"></i>
          </div>

          <div (click)="viewUploadCOCModal()" class="btn btn-sm btn-secondary">
            <span class="d-none d-sm-inline mx-1">Upload COC's</span>
            <i data-feather="arrow-right"></i>
          </div>
        </div>
        <!-- <div>
            <div *ngIf="isDataNotNull">
              <button class="btn btn-md btn-success w-100" (click)="exportToExcel()">
                <i class="fa fa-file-excel"></i> &nbsp; Export to Excel
              </button>
            </div>
          </div> -->
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="padding">
        <div class="toolbar">
          <!-- Filters -->

          <div class="card p-2 mb-2 w-100" style="background-color: #182038">
            <div class="row" [formGroup]="searchFormGroup">
              <div class="col-sm-4">
                <ng-select2
                  [data]="statusOPtion"
                  [placeholder]="
                    '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Select Status &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
                  "
                  [allowClear]="true"
                  formControlName="status"
                ></ng-select2>
              </div>

              <div class="col-sm-3 pr-0">
                <input
                  type="text"
                  placeholder="Date Range"
                  class="form-control form-control-sm"
                  bsDaterangepicker
                  class="form-control"
                  formControlName="dateRange"
                  style="background-color: white; color: black"
                  [bsConfig]="{
                    rangeInputFormat: 'YYYY-MM-DD',
                    dateInputFormat: 'YYYY-MM-DD',
                    showWeekNumbers: false
                  }"
                />
              </div>

              <div class="col-sm-3 pr-0">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control form-control-sm search"
                    placeholder="Request ID"
                    class="form-control"
                    formControlName="requestId"
                    style="background-color: white; color: black"
                  />
                </div>
              </div>

              <div class="col-2">
                <button class="btn btn-sm btn-primary w-100" (click)="getTransaction()">
                  <i class="fa fa-search"></i> &nbsp; Search
                </button>
              </div>
            </div>
          </div>

          <!-- List Toolbar Form -->
          <form class="form-inline"></form>

          <!-- Search Button -->
        </div>

        <div class="card">
          <table
            id="table"
            class="table table-striped table-hover"
            data-plugin="bootstrapTable"
            data-toolbar="#toolbar"
            data-search="true"
            data-search-align="left"
            data-show-export="true"
            data-show-columns="true"
            data-detail-view="false"
            data-mobile-responsive="true"
            data-pagination="true"
            data-page-list="[10, 25, 50, 100, ALL]"
          >
            <thead>
              <tr>
                <th data-sortable="true" data-field="id">COC Number</th>
                <th data-sortable="true" data-field="owner">Created By</th>
                <th data-sortable="true" data-field="project">Completed Date</th>
                <th data-field="task"><span class="d-none d-sm-block">Request Id</span></th>
                <th data-field="finish"><span class="d-none d-sm-block">Receipt</span></th>
                <th data-field="finish"><span class="d-none d-sm-block">Status</span></th>
              </tr>
            </thead>

            <tbody>
              <tr
                class="pt-1 pb-1"
                *ngFor="
                  let transaction of transactions?.data
                    | paginate: { itemsPerPage: 10, currentPage: p, totalItems: transactions.totalRecords }
                "
                class="odd"
                role="row"
              >
                <!-- (click)="setSelectedTransaction(transaction)" -->
                <td class="pt-1 pb-1">
                  <div class="item-except text-sm h-1x">
                    {{ padIntegerLeftWithZeros(transaction?.cocNumber, 5) }}
                  </div>
                </td>

                <td class="pt-1 pb-1">
                  <div class="item-except text-sm h-1x">
                    {{ transaction.createdBy }}
                  </div>
                </td>

                <td class="pt-1 pb-1">
                  <div class="item-except text-sm h-1x">
                    {{ transaction.vipProtect?.completedDate | date: "MMM dd, yyyy hh:mm:ss a" }}
                  </div>
                </td>

                <td class="pt-1 pb-1">
                  <div class="item-except text-sm h-1x">
                    {{ transaction.requestId }}
                  </div>
                </td>

                <td class="pt-1 pb-1">
                  <a (click)="preview(viewImageModal, transaction.vipProtect)">
                    <span class="w-32 avatar gd-warning">
                      <img
                        *ngIf="transaction.vipProtect?.photo"
                        class="w-32 avatar"
                        [src]="buildUserPhoto(transaction.vipProtect)"
                      />
                      <img
                        *ngIf="!transaction.vipProtect?.photo"
                        src="https://via.placeholder.com/64x64?text=VIP"
                        alt="VIP"
                      />
                    </span>
                  </a>
                </td>

                <td class="pt-1 pb-1">
                  <app-status-table-data-v2 [status]="transaction?.statusId"></app-status-table-data-v2>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
              <div class="col-sm-12 col-md-12">
                <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                  Showing {{ (transactions?.pageNumber - 1) * transactions?.pageSize + 1 }} to
                  {{ (transactions?.pageNumber - 1) * transactions?.pageSize + transactions?.data?.length }} of
                  {{ transactions?.data?.length }} entries
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-7" style="float: right">
            <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
              <ul class="pagination" style="float: right; margin-right: 36px">
                <pagination-controls (pageChange)="getTransaction(buildPageUrl($event, 10))"></pagination-controls>
              </ul>
            </div>
          </div>
        </div>

        <ng-template #viewUploadCOCs let-modal>
          <div class="modal-header">
            <h4 class="modal-title">VIP Protect COC's Batch Upload</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header">
                  <strong>Batch Upload Details</strong>
                </div>
                <div class="card-body">
                  <form>
                    <app-textbox-number
                      [parent-form-group]="batchUploadCOCsFormGroup"
                      tab-index-start="0"
                      control-name="start"
                      [error]="batchUploadCOCsFormGroup?.controls.start.errors | estatus"
                      [error-message]="batchUploadCOCsFormGroup?.controls.start.errors | econtent"
                      read-only="false"
                      label="Starting Number"
                      placeholder="0"
                    >
                    </app-textbox-number>

                    <app-textbox-number
                      [parent-form-group]="batchUploadCOCsFormGroup"
                      tab-index-start="0"
                      control-name="end"
                      [error]="batchUploadCOCsFormGroup?.controls.end.errors | estatus"
                      [error-message]="batchUploadCOCsFormGroup?.controls.end.errors | econtent"
                      read-only="false"
                      label="Ending Number"
                      placeholder="0"
                    >
                    </app-textbox-number>
                  </form>

                  <h2>Total Count of COC's will be added : {{ cOCCount() }}</h2>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="addCOCsToInventory()">
              Add COC's to VIP Protect Inventory
            </button>
            <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Close</button>
          </div>
        </ng-template>
      </div>
    </div>

    <ng-template #viewImageModal let-modal>
      <div class="modal-header">
        <h4 class="modal-title">Image Viewer</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <img
            *ngIf="vipProtect.photo"
            [src]="buildUserPhoto(vipProtect) | authImage | async"
            alt="VIP"
            onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
            style="width: 100%"
          />
        </div>
      </div>
    </ng-template>
  </div>
</div>
