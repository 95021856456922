<!-- <app-loader *ngIf="!accounts || loader"></app-loader> -->
<!-- <app-loader *ngIf="processUpdate"></app-loader> -->

<!-- *ngIf="requestFunds" -->
<div id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="--spam">Vips Merchant Verification</h2>
          <small class="--desc" style="font-weight: bold">JuanPay list of Vips Merchants for verification</small>
        </div>
        <div class="flex"></div>
        <div class="mr-2"></div>

        <div>
          <!-- <div (click)="revertChanges()" class="btn btn-md btn-info">
            <span class="d-none d-sm-inline mx-1">Revert</span>
            <i data-feather="arrow-right"></i>
          </div> -->
        </div>
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="">
        <div class="card mt-2">
          <table
            id="table"
            class="table table-striped table-hover"
            data-plugin="bootstrapTable"
            data-toolbar="#toolbar"
            data-search="true"
            data-search-align="left"
            data-show-export="true"
            data-show-columns="true"
            data-detail-view="false"
            data-mobile-responsive="true"
            data-pagination="true"
            data-page-list="[10, 25, 50, 100, ALL]"
          >
            <thead>
              <tr>
                <th data-sortable="true" data-field="id">No</th>
                <th data-sortable="true" data-field="id">Business Name</th>
                <th data-sortable="true" data-field="owner">Business Food Type</th>
                <th data-sortable="true" data-field="owner">Created Date</th>
              </tr>
            </thead>
            <tbody *ngIf="merchantList">
              <!-- *ngFor="
                let requestFund of requestFunds?.data
                  | paginate: { itemsPerPage: pageRows, currentPage: p, totalItems: requestFunds.totalRecords }; let i = index
              " account
              (click)="viewDetails(requestFundModal, requestFund)" -->
              <tr
                class=" "
                class="odd"
                role="row"
                (click)="viewDetails(verificationDetails, merchant)"
                *ngFor="let merchant of merchantList; let i = index"
              >
                <td class="flex">
                  <div class="item-except text-sm text-dark h-1x">
                    {{ i + 1 }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm text-dark h-1x">
                    {{ merchant.business_name }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm text-dark h-1x">
                    {{ merchant.business_food_type }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ merchant?.timestamp?.toDate() | date: "yyyy/MM/dd h:mm:ss a" }}
                    <!-- {{ merchant.timestamp | date: "MMMM dd, yyyy hh:mm:ss a" }} -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<!-- <ng-template #officialReceiptModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Compare Receipt</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</ng-template>-->

<ng-template #verifyApplicationModal let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Are you sure you want to approve this account?</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <button class="btn btn-sm btn-primary float-right" (click)="processVerification(true)">Proceed</button>
    <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #rejectApplicationModal let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Are you sure you want to reject this account?</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <textarea type="text" class="form-control" id="remm" [(ngModel)]="remarksStr"></textarea>
  </div>

  <div class="modal-body">
    <button class="btn btn-sm btn-danger float-right" (click)="processVerification(false)">Proceed</button>
    <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #verificationDetails let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Merchant Verification</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body pl-5 pr-5">
    <div class="row">
      <div class="col-sm-6">
        <h5 class="text-center">Business Details</h5>
        <hr />
        <div class="row">
          <div class="col-3 text-sm mb-1">Business Name</div>
          <div class="col-9">
            <div class="text-sm mb-1 font-weight-bold">
              {{ selectedMechant.business_name }}
            </div>
          </div>

          <div class="col-3 text-sm mb-1">Business Address</div>
          <div class="col-9">
            <div
              class="text-sm mb-1 font-weight-bold"
              style="max-width: 400px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
            >
              {{
                selectedMechant?.business_address_address +
                  " " +
                  selectedMechant?.business_address_barangay +
                  " " +
                  selectedMechant?.business_address_country +
                  " " +
                  selectedMechant?.business_address_municipalty +
                  " " +
                  selectedMechant?.business_address_province +
                  " " +
                  selectedMechant?.business_address_region
              }}
            </div>
          </div>

          <div class="col-3 text-sm mb-1">ZIP Code</div>
          <div class="col-9">
            <div class="text-sm mb-1 font-weight-bold">
              {{ selectedMechant.business_address_zipcode }}
            </div>
          </div>

          <div class="col-3 text-sm mb-1">Latitude</div>
          <div class="col-9">
            <div class="text-sm mb-1 font-weight-bold">
              {{ selectedMechant.business_latitude }}
            </div>
          </div>

          <div class="col-3 text-sm mb-1">Longitude</div>
          <div class="col-9">
            <div class="text-sm mb-1 font-weight-bold">
              {{ selectedMechant.business_longitude }}
            </div>
          </div>

          <div class="col-3 text-sm mb-1">Tags</div>
          <div class="col-9">
            <div class="text-sm mb-1 font-weight-bold">
              <span
                class="badge badge-secondary mr-1"
                *ngFor="let tag of selectedMechant.business_food_tags; let i = index"
                >&nbsp; {{ tag }} &nbsp;</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <h5 class="text-center">Applicant Details</h5>
        <hr />
        <div class="row">
          <div class="col-3 text-sm mb-1">Full name</div>
          <div class="col-9">
            <div class="text-sm mb-1 font-weight-bold">
              {{
                selectedMechant?.first_name +
                  " " +
                  selectedMechant?.middle_name +
                  " " +
                  selectedMechant?.last_name +
                  " " +
                  selectedMechant?.suffix_name
              }}
            </div>
          </div>

          <div class="col-3 text-sm mb-1">Applicant Address</div>
          <div class="col-9">
            <div
              class="text-sm mb-1 font-weight-bold"
              style="max-width: 400px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap"
            >
              {{
                selectedMechant?.address_address +
                  " " +
                  selectedMechant?.address_barangay +
                  " " +
                  selectedMechant?.address_country +
                  " " +
                  selectedMechant?.address_municipalty +
                  " " +
                  selectedMechant?.address_province +
                  " " +
                  selectedMechant?.address_region
              }}
            </div>
          </div>

          <div class="col-3 text-sm mb-1">ZIP Code</div>
          <div class="col-9">
            <div class="text-sm mb-1 font-weight-bold">
              {{ selectedMechant.address_zipcode }}
            </div>
          </div>

          <div class="col-3 text-sm mb-1">Email Address</div>
          <div class="col-9">
            <div class="text-sm mb-1 font-weight-bold">
              {{ selectedMechant.email_address }}
            </div>
          </div>

          <div class="col-3 text-sm mb-1">Gender</div>
          <div class="col-9">
            <div class="text-sm mb-1 font-weight-bold">
              {{ selectedMechant.gender }}
            </div>
          </div>

          <div class="col-3 text-sm mb-1">Birthday</div>
          <div class="col-9">
            <div class="text-sm mb-1 font-weight-bold">
              <!-- {{ selectedMechant.birth_date | date:'MMM dd, yyyy' }} -->
              {{ selectedMechant.birth_date }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-4">
        <hr />
        <h5 class="text-center">ID Picture: ( {{ selectedMechant.identification_type }} )</h5>
        <hr />

        <div class="list list-row block">
          <div class="list-item" data-id="20">
            <div class="pointer">
              <span class="w-40 avatar gd-info">
                <i class="fa fa-upload" aria-hidden="true"></i>
                <input #fileUpload type="file" style="display: none" accept="image/jpeg,image/png,application/pdf" />
              </span>
            </div>
            <div class="flex">
              <a class="item-author text-color">ID: Front</a>
              <div class="item-except text-muted text-sm h-1x">
                <label
                  *ngIf="selectedMechant.identification_front_url"
                  style="color: blue; cursor: pointer"
                  (click)="preview(viewImageModal, selectedMechant.identification_front_url, false)"
                >
                  <span class="fa fa-eye">&nbsp;</span>View File
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="list list-row block">
          <div class="list-item" data-id="20">
            <div class="pointer">
              <span class="w-40 avatar gd-info">
                <i class="fa fa-upload" aria-hidden="true"></i>
                <input #fileUpload type="file" style="display: none" accept="image/jpeg,image/png,application/pdf" />
              </span>
            </div>
            <div class="flex">
              <a class="item-author text-color">ID: Back</a>
              <div class="item-except text-muted text-sm h-1x">
                <label
                  *ngIf="selectedMechant.identification_back_url"
                  style="color: blue; cursor: pointer"
                  (click)="preview(viewImageModal, selectedMechant.identification_back_url, false)"
                >
                  <span class="fa fa-eye">&nbsp;</span>View File
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-4">
        <hr />
        <h5 class="text-center">Selfies</h5>
        <hr />

        <div class="list list-row block">
          <div class="list-item" data-id="20">
            <div class="pointer">
              <span class="w-40 avatar gd-info">
                <i class="fa fa-upload" aria-hidden="true"></i>
                <input #fileUpload type="file" style="display: none" accept="image/jpeg,image/png,application/pdf" />
              </span>
            </div>
            <div class="flex">
              <a class="item-author text-color">Selfie</a>
              <div class="item-except text-muted text-sm h-1x">
                <label
                  *ngIf="selectedMechant.identification_selfie"
                  style="color: blue; cursor: pointer"
                  (click)="preview(viewImageModal, selectedMechant.identification_selfie, false)"
                >
                  <span class="fa fa-eye">&nbsp;</span>View File
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="list list-row block">
          <div class="list-item" data-id="20">
            <div class="pointer">
              <span class="w-40 avatar gd-info">
                <i class="fa fa-upload" aria-hidden="true"></i>
                <input #fileUpload type="file" style="display: none" accept="image/jpeg,image/png,application/pdf" />
              </span>
            </div>
            <div class="flex">
              <a class="item-author text-color">Selfie with ID</a>
              <div class="item-except text-muted text-sm h-1x">
                <label
                  *ngIf="selectedMechant.identification_selfie_with_id"
                  style="color: blue; cursor: pointer"
                  (click)="preview(viewImageModal, selectedMechant.identification_selfie_with_id, false)"
                >
                  <span class="fa fa-eye">&nbsp;</span>View File
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 col-md-6 col-lg-4">
        <hr />
        <h5 class="text-center">Store Files</h5>
        <hr />

        <div class="list list-row block">
          <div class="list-item" data-id="20">
            <div class="pointer">
              <span class="w-40 avatar gd-info">
                <i class="fa fa-upload" aria-hidden="true"></i>
                <input #fileUpload type="file" style="display: none" accept="image/jpeg,image/png,application/pdf" />
              </span>
            </div>
            <div class="flex">
              <a class="item-author text-color">Store Facade</a>
              <div class="item-except text-muted text-sm h-1x">
                <label
                  *ngIf="selectedMechant.business_store_facade"
                  style="color: blue; cursor: pointer"
                  (click)="preview(viewImageModal, selectedMechant.business_store_facade, false)"
                >
                  <span class="fa fa-eye">&nbsp;</span>View File
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="list list-row block">
          <div class="list-item" data-id="20">
            <div class="pointer">
              <span class="w-40 avatar gd-info">
                <i class="fa fa-upload" aria-hidden="true"></i>
                <input #fileUpload type="file" style="display: none" accept="image/jpeg,image/png,application/pdf" />
              </span>
            </div>
            <div class="flex">
              <a class="item-author text-color">Store Logo</a>
              <div class="item-except text-muted text-sm h-1x">
                <label
                  *ngIf="selectedMechant.business_store_logo"
                  style="color: blue; cursor: pointer"
                  (click)="preview(viewImageModal, selectedMechant.business_store_logo, false)"
                >
                  <span class="fa fa-eye">&nbsp;</span>View File
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 mt-2">
        <div>
          <button class="btn btn-sm btn-primary float-right mr-3" (click)="openVerify(verifyApplicationModal)">
            Approve
          </button>
        </div>
        <div>
          <button class="btn btn-sm btn-danger float-right mr-2" (click)="openReject(rejectApplicationModal)">
            Reject
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #viewImageModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">File Viewer</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="d-flex justify-content-end mr-2">
      <!-- <button class="btn btn-sm btn-success mr-2 mb-1" (click)="downloadImage(photo, photoFormat, photoFileName)">
        <i class="fa fa-download"></i> &nbsp; Download
      </button> -->
    </div>
    <div>
      <ngx-extended-pdf-viewer
        *ngIf="ispdf"
        [base64Src]="imagePreview"
        backgroundColor="#ffffff"
        height="100%"
        [useBrowserLocale]="true"
        [handTool]="false"
        [showHandToolButton]="true"
      >
      </ngx-extended-pdf-viewer>
      <img
        *ngIf="!ispdf"
        [src]="imagePreview"
        onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
        style="width: 100%"
      />
    </div>
  </div>
</ng-template>
