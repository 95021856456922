<app-loader *ngIf="!user || processStoreSave"></app-loader>
<app-loader *ngIf="loader"></app-loader>

<div
  id="content"
  class="flex"
  *ngIf="user && storeSettingFormGroup && storeAddressFormGroup && storeActivationFormGroup"
>
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">Profile</h2>
          <small class="text-highlight font-weight-normal font-italic">Your personal information</small>
        </div>
        <div class="flex"></div>
        <div
          *ngIf="
            ((user.managerUser?.mobileActivationDate == null || user.managerUser?.emailActivationDate == null) &&
              isExpired &&
              roleLevelId == 1) ||
            roleId == 1
          "
        >
          <div (click)="resendEmailVerification(userId)" class="btn btn-md btn-secondary">
            <span class="d-none d-sm-inline mx-1">Resend Account Activation</span>
            <i data-feather="arrow-right"></i>
          </div>
        </div>

        <div *ngIf="roleLevelId == 2">
          <div (click)="resendImageSecurity()" class="btn btn-md btn-secondary">
            <span class="d-none d-sm-inline mx-1">Generate New Security Image</span>
            <i data-feather="arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="padding sr">
        <div class="card">
          <div
            class="card-header bg-dark bg-img p-0 no-border"
            data-stellar-background-ratio="0.1"
            style="background-image: url(../../../assets/img/b3.jpg)"
            data-plugin="stellar"
          >
            <div class="bg-dark-overlay r-2x no-r-b">
              <div class="d-md-flex">
                <div class="p-4">
                  <div class="d-flex">
                    <a>
                      <span class="avatar w-64">
                        <img
                          class="w-48 avatar"
                          *ngIf="user.managerUser?.photo"
                          [src]="buildUserPhoto(user) | authImage | async"
                          alt="Juan"
                          onerror="this.src='https://govipcenterstorage.blob.core.windows.net/govipcenterassets/filea7a2449f-a14a-4909-85f3-cf35a4b8b68b.png';"
                        />
                        <img
                          class="w-48 avatar"
                          *ngIf="!user.managerUser?.photo"
                          src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/filea7a2449f-a14a-4909-85f3-cf35a4b8b68b.png"
                          alt="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/filea7a2449f-a14a-4909-85f3-cf35a4b8b68b.png"
                        />
                        <i class="on"></i>
                      </span>
                    </a>
                    <div class="mx-3">
                      <h5 class="mt-2">{{ user.managerUser?.firstName }} {{ user.managerUser?.lastName }}</h5>
                      <div class="text-fade text-sm">
                        <span class="m-r">{{ user.role.name }}</span>
                        <small>
                          <span class="vip-input-text__activated" [ngClass]="{ active: user.managerUser?.status }">
                            {{ user.managerUser?.status ? " - Active" : " - Deactivated" }}
                          </span></small
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-3">
            <div class="d-flex">
              <ul class="nav nav-pills">
                <li class="nav-item">
                  <a class="nav-link active" data-toggle="tab" data-target="#tab_4">Profile</a>
                </li>
                <li class="nav-item" *ngIf="roleLevelId == 1 || roleLevelId == 2">
                  <a class="nav-link" data-toggle="tab" data-target="#tab_11">Cashiers Role Assignment</a>
                </li>
                <li class="nav-item" *ngIf="roleLevelId == 1 || roleLevelId == 2">
                  <a class="nav-link" data-toggle="tab" data-target="#tab_12">Bank for ATM Withdrawal Settlement</a>
                </li>
                <li class="nav-item" *ngIf="roleLevelId == 1 || roleLevelId == 2">
                  <a class="nav-link" data-toggle="tab" data-target="#tab_5">AML Settings</a>
                </li>
                <li class="nav-item" *ngIf="roleLevelId == 1 || roleLevelId == 2">
                  <a class="nav-link" data-toggle="tab" data-target="#tab_6">Store Address</a>
                </li>
                <li class="nav-item" *ngIf="roleLevelId == 1 || roleLevelId == 2">
                  <a class="nav-link" data-toggle="tab" data-target="#tab_7">Store Activation Details</a>
                </li>
                <li *ngIf="roleLevelId == 1" class="nav-item">
                  <a class="nav-link" data-toggle="tab" data-target="#tab_8" (click)="refreshMap()">Store Longlat</a>
                </li>
                <li class="nav-item" *ngIf="roleLevelId == 1 || roleLevelId == 2">
                  <a class="nav-link" data-toggle="tab" data-target="#tab_9">SPM</a>
                </li>
                <li class="nav-item" *ngIf="roleLevelId == 1 || roleLevelId == 2">
                  <a class="nav-link" data-toggle="tab" data-target="#tab_10">Files</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-lg-12">
            <div class="tab-content">
              <div class="tab-pane fade show active" id="tab_4">
                <div class="card">
                  <div class="card-header">
                    <strong>Manager's Profile</strong>
                    <div
                      *ngIf="roleId == 1 || roleId == 2 || roleId == 23"
                      (click)="editManagerUserProfile()"
                      style="float: right"
                    >
                      <button class="btn btn-sm btn-wave mb-2 blue text-white">
                        <span class="fa fa-edit"></span> &nbsp; Update
                      </button>
                    </div>
                  </div>
                  <div class="px-4 py-4">
                    <div class="row mb-2">
                      <div class="col-6">
                        <small class="text-muted">Username</small>
                        <div class="my-2">{{ user?.username }}</div>
                      </div>
                      <div class="col-6">
                        <small class="text-muted">Email Address</small>
                        <div class="my-2">{{ user?.email }}</div>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-6">
                        <small class="text-muted">Manager ID</small>
                        <div class="my-2">{{ user.managerUser?.managerId }}</div>
                      </div>
                      <div class="col-6">
                        <small class="text-muted">Contact Number</small>
                        <div class="my-2">{{ user.managerUser?.contactNumber }}</div>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-6">
                        <small class="text-muted">Birth Date</small>
                        <div class="my-2">{{ user.managerUser?.birthDate | date: "MMMM dd, yyyy" }}</div>
                      </div>
                      <div class="col-6" *ngIf="roleId == 1 || roleId == 2 || roleId == 5">
                        <small class="text-muted">Account Status</small>
                        <div class="my-2">
                          <app-boolslider
                            *ngIf="roleId != 3"
                            [parentFormGroup]="userFormGroup"
                            controlName="isDeactivated"
                            label="Deactivated"
                          >
                          </app-boolslider>
                        </div>
                      </div>
                    </div>
                    <div>
                      <small class="text-muted">Address</small>
                      <div class="my-2">{{ user.managerUser?.address }}</div>
                    </div>
                  </div>
                  <div class="card-header">
                    <strong>Wallets Debit/Credit</strong>
                    <button
                      class="btn btn-sm btn-danger float-right mr-2"
                      *ngIf="roleId == 1"
                      (click)="openAdjustWallet(openAdjustWalletModal, 'false')"
                    >
                      <i class="fa fa-minus"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-success float-right mr-2"
                      *ngIf="roleId == 1"
                      (click)="openAdjustWallet(openAdjustWalletModal, 'true')"
                    >
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                  <div class="px-4 py-4">
                    <div class="card-header">
                      <strong>Manager's Wallet</strong>
                    </div>
                    <!--DEBIT CREDIT-->

                    <table
                      id="datatable"
                      class="table table-striped table-hover"
                      role="grid"
                      aria-describedby="datatable_info"
                    >
                      <thead>
                        <tr>
                          <th data-sortable="true" data-field="id">
                            <div [ngClass]="managerId" (click)="setPageSorting('managerId')">Transaction Type</div>
                          </th>
                          <th data-sortable="true" data-field="owner">
                            <div [ngClass]="username" (click)="setPageSorting('username')">Transaction Name</div>
                          </th>
                          <th data-sortable="true" data-field="owner">
                            <div [ngClass]="email" (click)="setPageSorting('email')">Total Count</div>
                          </th>
                          <th data-sortable="true" data-field="project">
                            <div [ngClass]="firstName" (click)="setPageSorting('firstName')">Total Amount</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="let transaction of debitCredit?.manager"
                          style="cursor: pointer"
                          class="odd"
                          role="row"
                        >
                          <td>
                            <small class="">{{ transaction.transactionType }}</small>
                          </td>
                          <td>
                            <small class="">{{ transaction.transactionName }}</small>
                          </td>
                          <td style="text-align: center">
                            <small class="">{{ transaction.totalCount }}</small>
                          </td>
                          <td style="text-align: right; color: red" *ngIf="transaction.totalAmount < 0">
                            <strong>{{ transaction.totalAmount | number: "1.2-2" }}</strong>
                          </td>
                          <td style="text-align: right; color: darkgreen" *ngIf="transaction.totalAmount > 0">
                            <strong>{{ transaction.totalAmount | number: "1.2-2" }}</strong>
                          </td>
                          <td style="text-align: right" *ngIf="transaction.totalAmount == 0">
                            <strong>{{ transaction.totalAmount | number: "1.2-2" }}</strong>
                          </td>
                        </tr>

                        <tr style="cursor: pointer" class="odd" role="row">
                          <td>
                            <small class=""></small>
                          </td>
                          <td>
                            <small class=""></small>
                          </td>
                          <td style="text-align: center">
                            <strong class="">DEBIT</strong>
                          </td>
                          <td style="text-align: right">
                            <strong style="color: darkgreen; font-size: larger">{{
                              debitCredit?.debit | number: "1.2-2"
                            }}</strong>
                          </td>
                        </tr>
                        <tr style="cursor: pointer" class="odd" role="row">
                          <td>
                            <small class=""></small>
                          </td>
                          <td>
                            <small class=""></small>
                          </td>
                          <td style="text-align: center">
                            <strong class="">CREDIT</strong>
                          </td>
                          <td style="text-align: right">
                            <strong style="color: red; font-size: larger">{{
                              debitCredit?.credit | number: "1.2-2"
                            }}</strong>
                          </td>
                        </tr>
                        <tr style="cursor: pointer" class="odd" role="row">
                          <td>
                            <small class=""></small>
                          </td>
                          <td>
                            <small class=""></small>
                          </td>
                          <td style="text-align: center">
                            <strong class="">WALLET BALANCE</strong>
                          </td>
                          <td style="text-align: right">
                            <strong style="color: darkgreen; font-size: larger">{{
                              debitCredit?.wallet | number: "1.2-2"
                            }}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <!--DEBIT CREDIT-->

                    <!--DEBIT CREDIT CASHIERS-->

                    <div *ngFor="let cashier of debitCredit?.cashiers">
                      <div class="card-header">
                        <strong>Cashier's Wallet - {{ cashier.username }}</strong>
                      </div>
                      <table
                        id="datatable"
                        class="table table-striped table-hover"
                        role="grid"
                        aria-describedby="datatable_info"
                      >
                        <thead>
                          <tr>
                            <th data-sortable="true" data-field="id">
                              <div [ngClass]="managerId" (click)="setPageSorting('managerId')">Transaction Type</div>
                            </th>
                            <th data-sortable="true" data-field="owner">
                              <div [ngClass]="username" (click)="setPageSorting('username')">Transaction Name</div>
                            </th>
                            <th data-sortable="true" data-field="owner">
                              <div [ngClass]="email" (click)="setPageSorting('email')">Total Count</div>
                            </th>
                            <th data-sortable="true" data-field="project">
                              <div [ngClass]="firstName" (click)="setPageSorting('firstName')">Total Amount</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let transaction of cashier?.cashierDebitCredit"
                            style="cursor: pointer"
                            class="odd"
                            role="row"
                          >
                            <td>
                              <small class="">{{ transaction.transactionType }}</small>
                            </td>
                            <td>
                              <small class="">{{ transaction.transactionName }}</small>
                            </td>
                            <td style="text-align: center">
                              <small class="">{{ transaction.totalCount }}</small>
                            </td>
                            <td style="text-align: right; color: red" *ngIf="transaction.totalAmount < 0">
                              <strong>{{ transaction.totalAmount | number: "1.2-2" }}</strong>
                            </td>
                            <td style="text-align: right; color: darkgreen" *ngIf="transaction.totalAmount > 0">
                              <strong>{{ transaction.totalAmount | number: "1.2-2" }}</strong>
                            </td>
                            <td style="text-align: right" *ngIf="transaction.totalAmount == 0">
                              <strong>{{ transaction.totalAmount | number: "1.2-2" }}</strong>
                            </td>
                          </tr>

                          <tr style="cursor: pointer" class="odd" role="row">
                            <td>
                              <small class=""></small>
                            </td>
                            <td>
                              <small class=""></small>
                            </td>
                            <td style="text-align: center">
                              <strong class="">DEBIT</strong>
                            </td>
                            <td style="text-align: right">
                              <strong style="color: darkgreen; font-size: larger">{{
                                cashier?.debit | number: "1.2-2"
                              }}</strong>
                            </td>
                          </tr>
                          <tr style="cursor: pointer" class="odd" role="row">
                            <td>
                              <small class=""></small>
                            </td>
                            <td>
                              <small class=""></small>
                            </td>
                            <td style="text-align: center">
                              <strong class="">CREDIT</strong>
                            </td>
                            <td style="text-align: right">
                              <strong style="color: red; font-size: larger">{{
                                cashier?.credit | number: "1.2-2"
                              }}</strong>
                            </td>
                          </tr>

                          <tr style="cursor: pointer" class="odd" role="row">
                            <td>
                              <small class=""></small>
                            </td>
                            <td>
                              <small class=""></small>
                            </td>
                            <td style="text-align: center">
                              <strong class="">WALLET BALANCE</strong>
                            </td>
                            <td style="text-align: right">
                              <strong style="color: darkgreen; font-size: larger">{{
                                cashier?.wallet | number: "1.2-2"
                              }}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <!--DEBIT CREDIT-->

                    <!--GRAND TOTAL WALLET-->
                    <table
                      id="datatable"
                      class="table table-striped table-hover"
                      role="grid"
                      aria-describedby="datatable_info"
                    >
                      <thead>
                        <tr>
                          <th data-sortable="true" data-field="id">
                            <div [ngClass]="managerId" (click)="setPageSorting('managerId')" style="color: white">
                              Transaction Type
                            </div>
                          </th>
                          <th data-sortable="true" data-field="owner">
                            <div [ngClass]="username" (click)="setPageSorting('username')" style="color: white">
                              Transaction Name
                            </div>
                          </th>
                          <th data-sortable="true" data-field="owner">
                            <div [ngClass]="email" (click)="setPageSorting('email')" style="color: white">
                              Total Count
                            </div>
                          </th>
                          <th data-sortable="true" data-field="project">
                            <div [ngClass]="firstName" (click)="setPageSorting('firstName')" style="color: white">
                              Total Amount
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style="cursor: pointer" class="odd" role="row">
                          <td>
                            <small class=""></small>
                          </td>
                          <td>
                            <small class=""></small>
                          </td>
                          <td style="text-align: center">
                            <strong class="">WALLET GRAND TOTAL</strong>
                          </td>
                          <td style="text-align: right">
                            <strong style="color: darkgreen; font-size: larger">{{
                              debitCredit?.totalwallet | number: "1.2-2"
                            }}</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <!--GRAND TOTAL WALLET-->
                  </div>
                </div>
                <!-- <div class="card">
                  <div class="px-4 py-4">
                    <div class="row mb-2">
                      <div class="col-6">
                        <small class="text-muted">Emergency Contact Number</small>
                        <div class="my-2">{{ user.managerUser?.emergency_contact_number }}</div>
                      </div>
                      <div class="col-6">
                        <small class="text-muted">Emergency Contact Name</small>
                        <div class="my-2">{{ user.managerUser?.emergency_contact_name }}</div>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-6">
                        <small class="text-muted">Emergency Contact Relationship</small>
                        <div class="my-2">{{ user.managerUser?.emergency_contact_relationship }}</div>
                      </div>
                      <div class="col-6">
                        <small class="text-muted">Contact Number</small>
                        <div class="my-2">{{ user.managerUser?.contact_number }}</div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
              <div class="tab-pane fade show" id="tab_12">
                <div class="card">
                  <div class="card-header">
                    <strong>Bank for ATM Withdrawal Settlement</strong>
                    <div
                      *ngIf="roleId == 1 || roleId == 2 || roleId == 8 || roleId == 6 || roleId == 23"
                      data-toggle="modal"
                      data-target="#modal-bank-settlements-settings"
                      data-toggle-class="fade-left"
                      data-toggle-class-target=".animate"
                      style="float: right"
                    >
                      <button class="btn btn-sm btn-wave mb-2 blue text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-save"
                        >
                          <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                          <polyline points="17 21 17 13 7 13 7 21"></polyline>
                          <polyline points="7 3 7 8 15 8"></polyline>
                        </svg>
                        &nbsp; Save Changes
                      </button>
                    </div>
                  </div>
                  <div class="card-body">
                    <!-- this.managerBankSettlementFormGroup = new FormGroup({
                        bankSettlementName: new FormControl(managerUser.bankSettlementName),
                        bankAccountNumber: new FormControl(managerUser.bankAccountNumber),
                        bankAccountName: new FormControl(managerUser.bankAccountName)
                      }); -->

                    <app-dropdown
                      place-holder="Bank Name"
                      [parent-form-group]="managerBankSettlementFormGroup"
                      control-name="bankSettlementName"
                      label="Bank Name"
                      [data]="settlementBanksArray"
                      [read-only]="
                        roleId != 1 && roleId != 2 && roleId != 8 && roleId != 6 && roleId != 7 && roleId != 23
                      "
                      [error]="managerBankSettlementFormGroup?.controls.bankSettlementName.errors | estatus"
                      [error-message]="managerBankSettlementFormGroup?.controls.bankSettlementName.errors | econtent"
                    >
                    </app-dropdown>

                    <app-textbox
                      [parent-form-group]="managerBankSettlementFormGroup"
                      tab-index-start="0"
                      control-name="bankAccountNumber"
                      [error]="managerBankSettlementFormGroup?.controls.bankAccountNumber?.errors | estatus"
                      [error-message]="managerBankSettlementFormGroup?.controls.bankAccountNumber?.errors | econtent"
                      [read-only]="
                        roleId != 1 && roleId != 2 && roleId != 8 && roleId != 6 && roleId != 7 && roleId != 23
                      "
                      label="Bank Account Number"
                      placeholder="Bank Account Number"
                    >
                    </app-textbox>

                    <app-textbox
                      [parent-form-group]="managerBankSettlementFormGroup"
                      tab-index-start="0"
                      control-name="bankAccountName"
                      [error]="managerBankSettlementFormGroup?.controls.bankAccountName?.errors | estatus"
                      [error-message]="managerBankSettlementFormGroup?.controls.bankAccountName?.errors | econtent"
                      [read-only]="
                        roleId != 1 && roleId != 2 && roleId != 8 && roleId != 6 && roleId != 7 && roleId != 23
                      "
                      label="Bank Account Name"
                      placeholder="Bank Account Name"
                    >
                    </app-textbox>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade show" id="tab_11">
                <div class="card">
                  <div class="card-header">
                    <strong>Cashiers Role Assignment</strong>

                    <div style="float: right" *ngIf="roleLevelId == '1'">
                      <label>Simultaneous Access :</label>
                      <app-boolslider [parentFormGroup]="multipleCashiersFormGroup" controlName="status" label="">
                      </app-boolslider>
                    </div>
                  </div>
                  <table
                    id="datatable"
                    class="table table-striped table-hover"
                    role="grid"
                    aria-describedby="datatable_info"
                  >
                    <thead>
                      <tr>
                        <th data-sortable="true" data-field="id">
                          <div>Username</div>
                        </th>
                        <th data-sortable="true" data-field="owner" style="text-align: center">
                          <div>Bills Payment</div>
                        </th>
                        <th data-sortable="true" data-field="owner" style="text-align: center">
                          <div>Airtime</div>
                        </th>
                        <th data-sortable="true" data-field="project" style="text-align: center">
                          <div>Remittance</div>
                        </th>
                        <th data-sortable="true" data-field="project" style="text-align: center">
                          <div>Insurance</div>
                        </th>
                        <th data-sortable="true" data-field="project" style="text-align: center">
                          <div>JuanPay Services</div>
                        </th>
                        <th data-sortable="true" data-field="project" style="text-align: center">
                          <div>Others</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let cashier of cashierUsersWithRolesFormGroup?.controls.cashiers?.controls"
                        style="cursor: pointer"
                        class="odd"
                        role="row"
                      >
                        <td>
                          <small class="">{{ cashier.controls.username.value }}</small>
                        </td>
                        <td>
                          <app-boolslider [parentFormGroup]="cashier" controlName="bpn" label=""> </app-boolslider>
                        </td>
                        <td>
                          <app-boolslider [parentFormGroup]="cashier" controlName="aln" label=""> </app-boolslider>
                        </td>
                        <td>
                          <app-boolslider [parentFormGroup]="cashier" controlName="rn" label=""> </app-boolslider>
                        </td>
                        <td>
                          <app-boolslider [parentFormGroup]="cashier" controlName="in" label=""> </app-boolslider>
                        </td>
                        <td>
                          <app-boolslider [parentFormGroup]="cashier" controlName="gs" label=""> </app-boolslider>
                        </td>
                        <td>
                          <app-boolslider [parentFormGroup]="cashier" controlName="on" label=""> </app-boolslider>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="tab-pane fade show" id="tab_5">
                <div *ngIf="!isStoreSettingCreated && !isReadyForSettingCreation && roleLevelId == 1" class="card">
                  <div (click)="isReadyForSettingCreation = true" class="btn btn-md btn-info">
                    <span class="d-none d-sm-inline mx-1">Setup Managers Store</span>
                    <i data-feather="arrow-right"></i>
                  </div>
                </div>

                <div *ngIf="!isStoreSettingCreated && !isReadyForSettingCreation && roleLevelId == 2" class="card">
                  <div class="card-header">
                    <strong>Store is not yet setup. Please contact ACM Support.</strong>
                  </div>
                </div>

                <div *ngIf="isStoreSettingCreated || isReadyForSettingCreation" class="card">
                  <div class="card-header">
                    <div
                      *ngIf="roleId == 1 || roleId == 2 || roleId == 7 || roleId == 8 || roleId == 6 || roleId == 23"
                      data-toggle="modal"
                      data-target="#modal-left-settings"
                      data-toggle-class="fade-left"
                      data-toggle-class-target=".animate"
                      style="float: right"
                    >
                      <button class="btn btn-sm btn-wave mb-2 blue text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-save"
                        >
                          <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                          <polyline points="17 21 17 13 7 13 7 21"></polyline>
                          <polyline points="7 3 7 8 15 8"></polyline>
                        </svg>
                        &nbsp; Save Changes
                      </button>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-6">
                        <strong>Store</strong>

                        <hr />

                        <app-textbox
                          [parent-form-group]="storeSettingFormGroup"
                          tab-index-start="0"
                          control-name="name"
                          [error]="storeSettingFormGroup?.controls.name?.errors | estatus"
                          [error-message]="storeSettingFormGroup?.controls.name?.errors | econtent"
                          [read-only]="roleId != 1 && roleId != 6 && roleId != 23"
                          label="Store Name"
                          placeholder="Store Name"
                        >
                        </app-textbox>

                        <app-textbox
                          [parent-form-group]="storeSettingFormGroup"
                          tab-index-start="0"
                          control-name="tin"
                          [error]="storeSettingFormGroup?.controls.tin?.errors | estatus"
                          [error-message]="storeSettingFormGroup?.controls.tin?.errors | econtent"
                          [read-only]="roleId != 1 && roleId != 8 && roleId != 6 && roleId != 23"
                          label="Business TIN No"
                          placeholder="Business TIN No"
                        >
                        </app-textbox>

                        <app-dropdown
                          *ngIf="roleId == 1 || roleId == 2"
                          place-holder="0"
                          [parent-form-group]="storeSettingFormGroup"
                          control-name="maxCashiers"
                          label="Max Cashiers"
                          [data]="maxCashiersArray"
                          [error]="storeSettingFormGroup?.controls.maxCashiers.errors | estatus"
                          [error-message]="storeSettingFormGroup?.controls.maxCashiers.errors | econtent"
                        >
                        </app-dropdown>

                        <app-textbox
                          *ngIf="roleId != 1 && roleId != 2 && roleId != 23"
                          [parent-form-group]="storeSettingFormGroup"
                          tab-index-start="0"
                          control-name="maxCashiers"
                          [error]="storeSettingFormGroup?.controls.maxCashiers?.errors | estatus"
                          [error-message]="storeSettingFormGroup?.controls.maxCashiers?.errors | econtent"
                          [read-only]="true"
                          no-special-char="false"
                          label="Max Cashiers"
                          placeholder="Max Cashiers"
                        >
                        </app-textbox>

                        <app-textbox
                          *ngIf="roleId != 1 && roleId != 2"
                          [parent-form-group]="storeSettingFormGroup"
                          tab-index-start="0"
                          control-name="maxCashiers"
                          [error]="storeSettingFormGroup?.controls.maxCashiers?.errors | estatus"
                          [error-message]="storeSettingFormGroup?.controls.maxCashiers?.errors | econtent"
                          [read-only]="true"
                          no-special-char="false"
                          label="Max Cashiers"
                          placeholder="Max Cashiers"
                        >
                        </app-textbox>

                        <app-textbox
                          *ngIf="roleId == 1 || roleId == 2 || roleId == 5 || roleId == 8 || roleId == 23"
                          [parent-form-group]="storeSettingFormGroup"
                          tab-index-start="0"
                          control-name="ctplAuthkey"
                          [error]="storeSettingFormGroup?.controls.ctplAuthkey?.errors | estatus"
                          [error-message]="storeSettingFormGroup?.controls.ctplAuthkey?.errors | econtent"
                          [read-only]="false"
                          label="CTPL Auth key"
                          placeholder="CTPL Auth key"
                        >
                        </app-textbox>

                        <app-textbox
                          *ngIf="roleId == 1 || roleId == 2 || roleId == 5 || roleId == 8 || roleId == 23"
                          [parent-form-group]="storeSettingFormGroup"
                          tab-index-start="0"
                          control-name="agentCode"
                          [error]="storeSettingFormGroup?.controls.agentCode?.errors | estatus"
                          [error-message]="storeSettingFormGroup?.controls.agentCode?.errors | econtent"
                          [read-only]="false"
                          label="Cebuana AgentCode"
                          placeholder="Cebuana AgentCode"
                        >
                        </app-textbox>

                        <app-boolslider
                          *ngIf="roleId != 3"
                          [parentFormGroup]="storeSettingFormGroup"
                          controlName="isLazadaEligible"
                          label="Lazada Eligible"
                        >
                        </app-boolslider>

                        <app-boolslider
                          *ngIf="roleId != 3"
                          [parentFormGroup]="storeSettingFormGroup"
                          controlName="isSalesModuleEnable"
                          label="Enable Sales Module"
                        >
                        </app-boolslider>

                        <app-boolslider
                          *ngIf="roleId != 3"
                          [parentFormGroup]="storeSettingFormGroup"
                          controlName="isAccountLock"
                          label="Account Lock"
                        >
                        </app-boolslider>
                      </div>
                      <div class="col-6">
                        <strong>Maximum amount limit per day</strong>
                        <hr />

                        <app-textbox-currency
                          [parent-form-group]="storeSettingFormGroup"
                          tab-index-start="0"
                          control-name="maxBillsAmountPerDay"
                          [read-only]="roleLevelId == 2"
                          [error]="storeSettingFormGroup?.controls.maxBillsAmountPerDay?.errors | estatus"
                          [error-message]="storeSettingFormGroup?.controls.maxBillsAmountPerDay?.errors | econtent"
                          read-only="roleId != 1 || roleId != 2 || roleId != 7"
                          no-special-char="false"
                          label="Bills"
                          placeholder="0.00"
                          max-length="20"
                        >
                        </app-textbox-currency>

                        <app-textbox-currency
                          [parent-form-group]="storeSettingFormGroup"
                          tab-index-start="0"
                          control-name="maxRemittanceAmountPerDay"
                          [read-only]="roleLevelId == 2"
                          [error]="storeSettingFormGroup?.controls.maxRemittanceAmountPerDay?.errors | estatus"
                          [error-message]="storeSettingFormGroup?.controls.maxRemittanceAmountPerDay?.errors | econtent"
                          read-only="roleId != 1 || roleId != 2 || roleId != 7"
                          no-special-char="false"
                          label="Remittance"
                          placeholder="0.00"
                          max-length="20"
                        >
                        </app-textbox-currency>

                        <app-textbox-currency
                          [parent-form-group]="storeSettingFormGroup"
                          tab-index-start="0"
                          control-name="maxEpinsAmountPerDay"
                          [read-only]="roleLevelId == 2"
                          [error]="storeSettingFormGroup?.controls.maxEpinsAmountPerDay?.errors | estatus"
                          [error-message]="storeSettingFormGroup?.controls.maxEpinsAmountPerDay?.errors | econtent"
                          read-only="roleId != 1 || roleId != 2 || roleId != 7"
                          no-special-char="false"
                          label="EPins"
                          placeholder="0.00"
                          max-length="20"
                        >
                        </app-textbox-currency>

                        <app-textbox-currency
                          [parent-form-group]="storeSettingFormGroup"
                          tab-index-start="0"
                          control-name="maxEloadAmountPerDay"
                          [read-only]="roleLevelId == 2"
                          [error]="storeSettingFormGroup?.controls.maxEloadAmountPerDay?.errors | estatus"
                          [error-message]="storeSettingFormGroup?.controls.maxEloadAmountPerDay?.errors | econtent"
                          read-only="roleId != 1 || roleId != 2 || roleId != 7"
                          no-special-char="false"
                          label="ELoad"
                          placeholder="0.00"
                          max-length="20"
                        >
                        </app-textbox-currency>
                      </div>

                      <div class="col-12">
                        <hr />
                        <app-store-hours-settings [managerId]="userId"></app-store-hours-settings>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="card-header">
                    
                  </div>
                  <div class="card-body">
                    
                  </div> -->
                </div>
              </div>

              <div class="tab-pane fade show" id="tab_6">
                <div *ngIf="!isStoreAddressCreated && !isReadyForAddressCreation && roleLevelId == 1" class="card">
                  <div (click)="isReadyForAddressCreation = true" class="btn btn-md btn-info">
                    <span class="d-none d-sm-inline mx-1">Setup Store Address</span>
                    <i data-feather="arrow-right"></i>
                  </div>
                </div>

                <div *ngIf="!isStoreAddressCreated && !isReadyForAddressCreation && roleLevelId == 2" class="card">
                  <div class="card-header">
                    <strong>Store address is not yet setup. Please contact ACM Support.</strong>
                  </div>
                </div>

                <div *ngIf="isStoreAddressCreated || isReadyForAddressCreation" class="card">
                  <div class="card-header">
                    <strong>Store Address</strong>
                    <div
                      *ngIf="roleId == 7 || roleId == 1 || roleId == 2 || roleId == 6 || roleId == 23"
                      data-toggle="modal"
                      data-target="#modal-left-address"
                      data-toggle-class="fade-left"
                      data-toggle-class-target=".animate"
                      style="float: right"
                    >
                      <button class="btn btn-sm btn-wave mb-2 blue text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-save"
                        >
                          <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                          <polyline points="17 21 17 13 7 13 7 21"></polyline>
                          <polyline points="7 3 7 8 15 8"></polyline>
                        </svg>
                        &nbsp; Save Changes
                      </button>
                    </div>
                  </div>
                  <div class="card-body">
                    <app-textbox
                      [parent-form-group]="storeAddressFormGroup"
                      tab-index-start="0"
                      control-name="number"
                      [error]="storeAddressFormGroup?.controls.number?.errors | estatus"
                      [error-message]="storeAddressFormGroup?.controls.number?.errors | econtent"
                      [read-only]="roleLevelId == 2"
                      label="House Number"
                      placeholder="House Number"
                    >
                    </app-textbox>

                    <app-textbox
                      [parent-form-group]="storeAddressFormGroup"
                      tab-index-start="0"
                      control-name="street"
                      [error]="storeAddressFormGroup?.controls.street?.errors | estatus"
                      [error-message]="storeAddressFormGroup?.controls.street?.errors | econtent"
                      [read-only]="roleLevelId == 2"
                      label="Street"
                      placeholder="Street"
                    >
                    </app-textbox>

                    <app-textbox
                      [parent-form-group]="storeAddressFormGroup"
                      tab-index-start="0"
                      control-name="subdivision"
                      [error]="storeAddressFormGroup?.controls.subdivision?.errors | estatus"
                      [error-message]="storeAddressFormGroup?.controls.subdivision?.errors | econtent"
                      [read-only]="roleLevelId == 2"
                      label="Subdivision"
                      placeholder="Subdivision"
                    >
                    </app-textbox>

                    <app-textbox
                      [parent-form-group]="storeAddressFormGroup"
                      tab-index-start="0"
                      control-name="barangay"
                      [error]="storeAddressFormGroup?.controls.barangay?.errors | estatus"
                      [error-message]="storeAddressFormGroup?.controls.barangay?.errors | econtent"
                      [read-only]="roleLevelId == 2"
                      label="Barangay"
                      placeholder="Barangay"
                    >
                    </app-textbox>

                    <app-textbox
                      [parent-form-group]="storeAddressFormGroup"
                      tab-index-start="0"
                      control-name="city"
                      [error]="storeAddressFormGroup?.controls.city?.errors | estatus"
                      [error-message]="storeAddressFormGroup?.controls.city?.errors | econtent"
                      [read-only]="roleLevelId == 2"
                      label="Municipality"
                      placeholder="Municipality"
                    >
                    </app-textbox>

                    <app-textbox
                      [parent-form-group]="storeAddressFormGroup"
                      tab-index-start="0"
                      control-name="municipality"
                      [error]="storeAddressFormGroup?.controls.municipality?.errors | estatus"
                      [error-message]="storeAddressFormGroup?.controls.municipality?.errors | econtent"
                      [read-only]="roleLevelId == 2"
                      label="City"
                      placeholder="City"
                    >
                    </app-textbox>

                    <app-textbox
                      [parent-form-group]="storeAddressFormGroup"
                      tab-index-start="0"
                      control-name="region"
                      [error]="storeAddressFormGroup?.controls.region?.errors | estatus"
                      [error-message]="storeAddressFormGroup?.controls.region?.errors | econtent"
                      [read-only]="roleLevelId == 2"
                      label="Region"
                      placeholder="Region"
                    >
                    </app-textbox>

                    <!-- <app-dropdown place-holder="0" [parent-form-group]="storeSettingFormGroup"
                      control-name="max_cashiers" label="Max Cashiers" [data]="maxCashiersArray"
                      [error]="storeSettingFormGroup?.controls.max_cashiers.errors | estatus"
                      [error-message]="storeSettingFormGroup?.controls.max_cashiers.errors | econtent">
                    </app-dropdown> -->
                  </div>
                </div>
              </div>
              <div class="tab-pane fade show" id="tab_7">
                <div
                  *ngIf="
                    !isStoreActivationCreated &&
                    !isReadyForActivationCreation &&
                    (roleId == 7 || roleId == 1 || roleId == 2 || roleId == 3 || roleId == 23)
                  "
                  class="card"
                >
                  <div (click)="isReadyForActivationCreation = true" class="btn btn-md btn-info">
                    <span class="d-none d-sm-inline mx-1">Setup Store</span>
                    <i data-feather="arrow-right"></i>
                  </div>
                </div>

                <div
                  *ngIf="!isStoreActivationCreated && !isReadyForActivationCreation && roleLevelId == 2"
                  class="card"
                >
                  <div class="card-header">
                    <strong>Store activation is not yet setup. Please contact ACM Support.</strong>
                  </div>
                </div>

                <div *ngIf="isStoreActivationCreated || isReadyForActivationCreation" class="card">
                  <div class="card-header">
                    <strong>Store Activation Details</strong>
                    <div
                      *ngIf="roleId == 7 || roleId == 1 || roleId == 2"
                      data-toggle="modal"
                      data-target="#modal-left-activation"
                      data-toggle-class="fade-left"
                      data-toggle-class-target=".animate"
                      style="float: right"
                    >
                      &nbsp;

                      <button class="btn btn-sm btn-wave mb-2 blue text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-save"
                        >
                          <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                          <polyline points="17 21 17 13 7 13 7 21"></polyline>
                          <polyline points="7 3 7 8 15 8"></polyline>
                        </svg>
                        &nbsp; Save
                      </button>
                    </div>
                    <!-- <div
                      *ngIf="roleId == 7 || roleId == 1 || roleId == 2"
                      (click)="checkMemberDetails()"
                      style="float: right"
                    >
                      <button class="btn btn-sm btn-wave mb-2 green text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-download-cloud mx-2"
                        >
                          <polyline points="8 17 12 21 16 17"></polyline>
                          <line x1="12" y1="12" x2="12" y2="21"></line>
                          <path d="M20.88 18.09A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.29"></path>
                        </svg>
                        Import Member
                      </button>
                    </div> -->
                  </div>
                  <div class="card-body">
                    <!-- <app-datepicker
                      *ngIf="roleLevelId == 1"
                      [parent-form-group]="storeActivationFormGroup"
                      tab-index-start="0"
                      control-name="mainActivationDate"
                      [error]="storeActivationFormGroup?.controls.mainActivationDate.errors | estatus"
                      [error-message]="storeActivationFormGroup?.controls.mainActivationDate.errors | econtent"
                      label="Activation Date "
                      placeholder="Activation Date "
                    >
                    </app-datepicker>

                    <app-datepicker
                      *ngIf="roleLevelId == 1"
                      [parent-form-group]="storeActivationFormGroup"
                      tab-index-start="0"
                      control-name="enterpriseActivationDate"
                      [error]="storeActivationFormGroup?.controls.enterpriseActivationDate.errors | estatus"
                      [error-message]="storeActivationFormGroup?.controls.enterpriseActivationDate.errors | econtent"
                      label="Enterprise Activation Date "
                      placeholder="Enterprise Activation Date "
                    >
                    </app-datepicker> -->

                    <app-textbox
                      [parent-form-group]="storeActivationFormGroup"
                      tab-index-start="0"
                      control-name="mainActivationDate"
                      [error]="storeActivationFormGroup?.controls.mainActivationDate?.errors | estatus"
                      [error-message]="storeActivationFormGroup?.controls.mainActivationDate?.errors | econtent"
                      [read-only]="true"
                      label="Activation Date"
                      placeholder="Activation Date"
                    >
                    </app-textbox>

                    <app-textbox
                      [parent-form-group]="storeActivationFormGroup"
                      tab-index-start="0"
                      control-name="enterpriseActivationDate"
                      [error]="storeActivationFormGroup?.controls.enterpriseActivationDate?.errors | estatus"
                      [error-message]="storeActivationFormGroup?.controls.enterpriseActivationDate?.errors | econtent"
                      [read-only]="true"
                      label="Enterprise Activation Date"
                      placeholder="Enterprise Activation Date"
                    >
                    </app-textbox>

                    <app-textbox
                      [parent-form-group]="storeActivationFormGroup"
                      tab-index-start="0"
                      control-name="sponsor"
                      [error]="storeActivationFormGroup?.controls.sponsor?.errors | estatus"
                      [error-message]="storeActivationFormGroup?.controls.sponsor?.errors | econtent"
                      [read-only]="true"
                      label="Sponsor"
                      placeholder="Sponsor"
                    >
                    </app-textbox>

                    <app-textbox
                      [parent-form-group]="storeActivationFormGroup"
                      tab-index-start="0"
                      control-name="upline"
                      [error]="storeActivationFormGroup?.controls.upline?.errors | estatus"
                      [error-message]="storeActivationFormGroup?.controls.upline?.errors | econtent"
                      [read-only]="true"
                      label="Connected to"
                      placeholder="COnnected to"
                    >
                    </app-textbox>

                    <app-textbox
                      [parent-form-group]="storeActivationFormGroup"
                      tab-index-start="0"
                      control-name="placement"
                      [error]="storeActivationFormGroup?.controls.placement?.errors | estatus"
                      [error-message]="storeActivationFormGroup?.controls.placement?.errors | econtent"
                      [read-only]="true"
                      label="Placement"
                      placeholder="Placement"
                    >
                    </app-textbox>

                    <app-textbox
                      [parent-form-group]="storeActivationFormGroup"
                      tab-index-start="0"
                      control-name="accountType"
                      [error]="storeActivationFormGroup?.controls.accountType?.errors | estatus"
                      [error-message]="storeActivationFormGroup?.controls.accountType?.errors | econtent"
                      [read-only]="true"
                      label="Account Type"
                      placeholder="Account Type"
                    >
                    </app-textbox>

                    <app-textbox
                      [parent-form-group]="storeActivationFormGroup"
                      *ngIf="storeActivationFormGroup?.controls.storeType.value != ''"
                      tab-index-start="0"
                      control-name="storeType"
                      [error]="storeActivationFormGroup?.controls.storeType?.errors | estatus"
                      [error-message]="storeActivationFormGroup?.controls.storeType?.errors | econtent"
                      [read-only]="true"
                      label="Store Type"
                      placeholder="Store Type"
                    >
                    </app-textbox>

                    <app-dropdown-identifier
                      *ngIf="roleId == 1 && roleId == 2 && storeActivationFormGroup?.controls.storeType.value == ''"
                      place-holder="Store Type"
                      [parent-form-group]="storeActivationFormGroup"
                      control-name="storeType"
                      label="Store Type :"
                      identifier="STORE_TYPE"
                    >
                    </app-dropdown-identifier>

                    <app-textbox
                      *ngIf="roleLevelId == 2"
                      [parent-form-group]="storeActivationFormGroup"
                      tab-index-start="0"
                      control-name="MSFPromoId"
                      [error]="storeActivationFormGroup?.controls.MSFPromoId?.errors | estatus"
                      [error-message]="storeActivationFormGroup?.controls.MSFPromoId?.errors | econtent"
                      [read-only]="true"
                      label="MSF Promo Code"
                      placeholder="MSF Promo Code"
                    >
                    </app-textbox>

                    <app-dropdown
                      *ngIf="roleLevelId == 1"
                      place-holder="MSF Code"
                      [parent-form-group]="storeActivationFormGroup"
                      control-name="MSFPromoId"
                      label="MSF Code"
                      [data]="msfPromos"
                      [error]="storeActivationFormGroup?.controls.MSFPromoId.errors | estatus"
                      [error-message]="storeActivationFormGroup?.controls.MSFPromoId.errors | econtent"
                    >
                    </app-dropdown>
                  </div>
                </div>
              </div>
              <div *ngIf="roleLevelId == 1" class="tab-pane fade show" id="tab_8">
                <div class="card">
                  <div class="card-header">
                    <strong>Store Longtitude and Latitude</strong>
                    <div
                      data-toggle="modal"
                      data-target="#modal-longlat-address"
                      data-toggle-class="fade-left"
                      data-toggle-class-target=".animate"
                      style="float: right"
                    >
                      <button class="btn btn-sm btn-wave mb-2 blue text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-save"
                        >
                          <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                          <polyline points="17 21 17 13 7 13 7 21"></polyline>
                          <polyline points="7 3 7 8 15 8"></polyline>
                        </svg>
                        &nbsp; Save Changes
                      </button>
                    </div>
                  </div>
                  <div class="card-body">
                    <app-textbox
                      [parent-form-group]="storeAddressFormGroup"
                      tab-index-start="0"
                      control-name="latitude"
                      [error]="storeAddressFormGroup?.controls.latitude?.errors | estatus"
                      [error-message]="storeAddressFormGroup?.controls.latitude?.errors | econtent"
                      [read-only]="roleLevelId != 1"
                      label="Latitude"
                      placeholder="Latitude"
                    ></app-textbox>

                    <app-textbox
                      [parent-form-group]="storeAddressFormGroup"
                      tab-index-start="0"
                      control-name="longtitude"
                      [error]="storeAddressFormGroup?.controls.longtitude?.errors | estatus"
                      [error-message]="storeAddressFormGroup?.controls.longtitude?.errors | econtent"
                      [read-only]="roleLevelId != 1"
                      label="Longtitude"
                      placeholder="Longtitude"
                    ></app-textbox>

                    <app-agm-map-single
                      *ngIf="location"
                      [location]="location"
                      [dragPin]="dragPin"
                      (updateCoordinates)="updateCoordinates($event)"
                    ></app-agm-map-single>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade show" id="tab_9">
                <div class="card">
                  <div class="card-header">
                    <strong>SPM</strong>
                    <div
                      *ngIf="roleId == 1 || roleId == 2 || roleId == 5 || roleId == 9 || roleId == 6 || roleId == 23"
                      data-toggle="modal"
                      data-target="#modal-left-address"
                      data-toggle-class="fade-left"
                      data-toggle-class-target=".animate"
                      style="float: right"
                    >
                      <button class="btn btn-sm btn-wave mb-2 blue text-white">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-save"
                        >
                          <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                          <polyline points="17 21 17 13 7 13 7 21"></polyline>
                          <polyline points="7 3 7 8 15 8"></polyline>
                        </svg>
                        &nbsp; Save Changes
                      </button>
                    </div>
                  </div>
                  <div class="card-body">
                    <app-textarea
                      [parent-form-group]="storeAddressFormGroup"
                      tab-index-start="0"
                      control-name="spm"
                      [error]="storeAddressFormGroup?.controls.spm?.errors | estatus"
                      [error-message]="storeAddressFormGroup?.controls.spm?.errors | econtent"
                      [read-only]="roleLevelId != 1"
                      label="SPM"
                      placeholder="Input SPM here..."
                    >
                    </app-textarea>

                    <!-- <textarea 
                      name="spm" 
                      id="" 
                      style="width: 100%;" 
                      rows="20" 
                      [parent-form-group]="storeAddressFormGroup"
                      tab-index-start="0"
                      control-name="spm"
                      placeholder="Input SPM here..."
                      ></textarea> -->
                  </div>
                </div>
              </div>
              <div class="tab-pane fade show" id="tab_10">
                <div class="card">
                  <div class="card-header">
                    <strong>Files</strong>
                    <!-- <div
                      *ngIf="roleId == 1 || roleId == 2 || roleId == 3 || roleId == 5 || roleId == 8"
                      style="float: right"
                    >
                      <div  style="float: right">
                        <button class="btn btn-sm btn-primary w-100" (click)="openFileUpload(uploadFilemodal)">
                          <i class="fa fa-upload"></i> &nbsp; Upload File
                        </button>
                      </div>
                    </div> -->
                    <div class="card-body">
                      <!-- #region Files -->

                      <div class="card">
                        <div class="card-body">
                          <div class="row row-sm">
                            <div class="col-sm-4" *ngFor="let file of fileManager">
                              <div class="list list-row block">
                                <div class="list-item" data-id="20">
                                  <div class="pointer" (click)="triggerFile(fileUpload)">
                                    <span class="w-40 avatar gd-info">
                                      <i class="fa fa-upload" aria-hidden="true"></i>
                                      <input
                                        #fileUpload
                                        type="file"
                                        style="display: none"
                                        accept="image/jpeg,image/png,application/pdf"
                                        (change)="onUploadFile($event, user?.username, file.value)"
                                      />
                                    </span>
                                  </div>
                                  <div class="flex">
                                    <a class="item-author text-color">{{ file.value }}</a>
                                    <div class="item-except text-muted text-sm h-1x">
                                      <label *ngIf="!file.value">Click the button to upload File...</label>
                                      <label
                                        *ngIf="checkAvailability(managerFiles?.data, user?.username, file.value)"
                                        style="color: blue; cursor: pointer"
                                        (click)="preview(viewImageModal, file.value)"
                                      >
                                        <span class="fa fa-eye">&nbsp;</span>View File
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- #endregion -->

                      <!-- <table
                        id="datatable"
                        class="table table-striped table-hover"
                        role="grid"
                        aria-describedby="datatable_info"
                        style="max-height: 600px"
                      >
                        <thead>
                          <tr>
                            <th data-sortable="true" data-field="name">File Name</th>
                            <th data-field="finish"><span class="d-none d-sm-block">Date Upload</span></th>
                            <th data-sortable="true" data-field="name">Purpose</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            style="cursor: pointer"
                            class="odd"
                            role="row"
                            *ngFor="let managerFile of managerFiles?.data"
                            (click)="viewContent(contentModal, managerFile)"
                          >
                            <td>
                              <small class="">{{ managerFile?.fileName }}</small>
                            </td>

                            <td class="flex">
                              <div class="item-except text-sm h-1x">
                                {{ managerFile?.createdDate | date: "dd-MMM-yyyy hh:mm:ss a" }}
                              </div>
                            </td>
                            <td class="flex">
                              <div class="item-except text-sm h-1x">{{ managerFile?.purpose }}</div>
                            </td>
                          </tr>
                        </tbody>
                      </table> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-sm-5 col-lg-4">
            <div class="card sticky" style="z-index: 1">
              <div class="card-header">
                <strong>Your List of Cashier's</strong>
              </div>
              <div class="list list-row">
                <div class="list-item " data-id="8">
                  <div>
                    <a >
                      <span class="w-40 avatar gd-success">
                        <span class="avatar-status on b-white avatar-right"></span>
                        <img src="../../../assets/img/a8.jpg" alt=".">
                      </span>
                    </a>
                  </div>
                  <div class="flex">
                    <a  class="item-author text-color ">Lawrence Cole</a>
                    <a  class="item-manager text-muted h-1x">
                      Jet
                    </a>
                  </div>
                  <div>
                    <div class="item-action dropdown">
                      <a  data-toggle="dropdown" class="text-muted">
                        <i data-feather="more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right bg-black" role="menu">
                        <a class="dropdown-item" >
                          See detail
                        </a>
                        <a class="dropdown-item download">
                          Download
                        </a>
                        <a class="dropdown-item edit">
                          Edit
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item trash">
                          Delete item
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-item " data-id="17">
                  <div>
                    <a >
                      <span class="w-40 avatar gd-warning">
                        <span class="avatar-status on b-white avatar-right"></span> H
                      </span>
                    </a>
                  </div>
                  <div class="flex">
                    <a  class="item-author text-color ">Helen Valdez</a>
                    <a  class="item-manager text-muted h-1x">
                      AI
                    </a>
                  </div>
                  <div>
                    <div class="item-action dropdown">
                      <a  data-toggle="dropdown" class="text-muted">
                        <i data-feather="more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right bg-black" role="menu">
                        <a class="dropdown-item" >
                          See detail
                        </a>
                        <a class="dropdown-item download">
                          Download
                        </a>
                        <a class="dropdown-item edit">
                          Edit
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item trash">
                          Delete item
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-item " data-id="3">
                  <div>
                    <a >
                      <span class="w-40 avatar gd-primary">
                        <span class="avatar-status away b-white avatar-right"></span>
                        <img src="../../../assets/img/a3.jpg" alt=".">
                      </span>
                    </a>
                  </div>
                  <div class="flex">
                    <a  class="item-author text-color ">Jordan Stephens</a>
                    <a  class="item-manager text-muted h-1x">
                      Wealth corp
                    </a>
                  </div>
                  <div>
                    <div class="item-action dropdown">
                      <a  data-toggle="dropdown" class="text-muted">
                        <i data-feather="more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right bg-black" role="menu">
                        <a class="dropdown-item" >
                          See detail
                        </a>
                        <a class="dropdown-item download">
                          Download
                        </a>
                        <a class="dropdown-item edit">
                          Edit
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item trash">
                          Delete item
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-item " data-id="12">
                  <div>
                    <a >
                      <span class="w-40 avatar gd-info">
                        <span class="avatar-status off b-white avatar-right"></span> A
                      </span>
                    </a>
                  </div>
                  <div class="flex">
                    <a  class="item-author text-color ">Alan Richards</a>
                    <a  class="item-manager text-muted h-1x">
                      Drafty
                    </a>
                  </div>
                  <div>
                    <div class="item-action dropdown">
                      <a  data-toggle="dropdown" class="text-muted">
                        <i data-feather="more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right bg-black" role="menu">
                        <a class="dropdown-item" >
                          See detail
                        </a>
                        <a class="dropdown-item download">
                          Download
                        </a>
                        <a class="dropdown-item edit">
                          Edit
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item trash">
                          Delete item
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-item " data-id="9">
                  <div>
                    <a >
                      <span class="w-40 avatar gd-info">
                        <span class="avatar-status on b-white avatar-right"></span>
                        <img src="../../../assets/img/a9.jpg" alt=".">
                      </span>
                    </a>
                  </div>
                  <div class="flex">
                    <a  class="item-author text-color ">Steven Cruz</a>
                    <a  class="item-manager text-muted h-1x">
                      HHH inc
                    </a>
                  </div>
                  <div>
                    <div class="item-action dropdown">
                      <a  data-toggle="dropdown" class="text-muted">
                        <i data-feather="more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right bg-black" role="menu">
                        <a class="dropdown-item" >
                          See detail
                        </a>
                        <a class="dropdown-item download">
                          Download
                        </a>
                        <a class="dropdown-item edit">
                          Edit
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item trash">
                          Delete item
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-item " data-id="14">
                  <div>
                    <a >
                      <span class="w-40 avatar gd-warning">
                        <span class="avatar-status off b-white avatar-right"></span> B
                      </span>
                    </a>
                  </div>
                  <div class="flex">
                    <a  class="item-author text-color ">Benjamin Fields</a>
                    <a  class="item-manager text-muted h-1x">
                      Luckman corp
                    </a>
                  </div>
                  <div>
                    <div class="item-action dropdown">
                      <a  data-toggle="dropdown" class="text-muted">
                        <i data-feather="more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right bg-black" role="menu">
                        <a class="dropdown-item" >
                          See detail
                        </a>
                        <a class="dropdown-item download">
                          Download
                        </a>
                        <a class="dropdown-item edit">
                          Edit
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item trash">
                          Delete item
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-item " data-id="10">
                  <div>
                    <a >
                      <span class="w-40 avatar gd-danger">
                        <span class="avatar-status on b-white avatar-right"></span>
                        <img src="../../../assets/img/a10.jpg" alt=".">
                      </span>
                    </a>
                  </div>
                  <div class="flex">
                    <a  class="item-author text-color ">Edward Clark</a>
                    <a  class="item-manager text-muted h-1x">
                      Goldage
                    </a>
                  </div>
                  <div>
                    <div class="item-action dropdown">
                      <a  data-toggle="dropdown" class="text-muted">
                        <i data-feather="more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right bg-black" role="menu">
                        <a class="dropdown-item" >
                          See detail
                        </a>
                        <a class="dropdown-item download">
                          Download
                        </a>
                        <a class="dropdown-item edit">
                          Edit
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item trash">
                          Delete item
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-item " data-id="5">
                  <div>
                    <a >
                      <span class="w-40 avatar gd-warning">
                        <span class="avatar-status on b-white avatar-right"></span>
                        <img src="../../../assets/img/a5.jpg" alt=".">
                      </span>
                    </a>
                  </div>
                  <div class="flex">
                    <a  class="item-author text-color ">Sara George</a>
                    <a  class="item-manager text-muted h-1x">
                      Sun
                    </a>
                  </div>
                  <div>
                    <div class="item-action dropdown">
                      <a  data-toggle="dropdown" class="text-muted">
                        <i data-feather="more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right bg-black" role="menu">
                        <a class="dropdown-item" >
                          See detail
                        </a>
                        <a class="dropdown-item download">
                          Download
                        </a>
                        <a class="dropdown-item edit">
                          Edit
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item trash">
                          Delete item
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="modal-bank-settlements-settings" class="modal fade" data-backdrop="true">
    <div class="modal-dialog animate">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title text-md">Updating Bank Settlements</div>
          <button class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          <div class="p-4 text-center">
            <p>Please make sure that the bank details is correct. Are you sure you want continue?</p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
          <button
            (click)="actionEventUpdateManagerBankSettlement()"
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
          >
            Save Changes
          </button>
          <!--   -->
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
  </div>

  <div id="modal-left-settings" class="modal fade" data-backdrop="true">
    <div class="modal-dialog animate">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title text-md">Updating Store Settings</div>
          <button class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          <div class="p-4 text-center">
            <p>Are you sure you want continue?</p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
          <button
            (click)="actionEventCreateManagerStoreSetting()"
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
          >
            Save Changes
          </button>
          <!--   -->
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
  </div>

  <div id="modal-left-address" class="modal fade" data-backdrop="true">
    <div class="modal-dialog animate">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title text-md">Updating Store Address</div>
          <button class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          <div class="p-4 text-center">
            <p>Are you sure you want continue?</p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
          <button
            type="button"
            (click)="actionEventCreateManagerStoreAddress()"
            class="btn btn-primary"
            data-dismiss="modal"
          >
            Save Changes
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
  </div>

  <div id="modal-longlat-address" class="modal fade" data-backdrop="true">
    <div class="modal-dialog animate">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title text-md">Updating Store Address Longtitude/Latitude</div>
          <button class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          <div class="p-4 text-center">
            <p>Are you sure you want continue?</p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
          <button
            type="button"
            (click)="actionEventUpdateManagerStoreAddressLongLat()"
            class="btn btn-primary"
            data-dismiss="modal"
          >
            Save Changes
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
  </div>

  <div id="modal-left-activation" class="modal fade" data-backdrop="true">
    <div class="modal-dialog animate">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title text-md">Updating Store Activation</div>
          <button class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          <div class="p-4 text-center">
            <p>Are you sure you want continue?</p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
          <button
            (click)="actionEventCreateManagerStoreActivation()"
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
          >
            Save Changes
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
  </div>

  <ng-template #uploadmodal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Upload Files</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div>
        <div class="page-content page-container" id="page-content">
          <div class="padding">
            <p class="text-muted">
              DropzoneJS is an open source library that provides drag’n’drop file uploads with image previews.
            </p>
            <form
              action="api/dropzone"
              class="dropzone white b-a b-3x b-dashed b-primary p-a rounded p-5 text-center"
              data-plugin="dropzone"
              data-option="{url: '../assets/api/dropzone'}"
            >
              <div class="dz-message">
                <h4 class="my-4">Drop files here or click to upload.</h4>
                <span class="text-muted d-block mb-4"
                  >(This is just a demo dropzone. Selected files are <strong>not</strong> actually uploaded.)</span
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #contentModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">{{ managerFile.fileName }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div class="p-4 text-center">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-6">
            <p class="text-secondary text-left font-weight-bold">
              Manager User:
              <span class="font-weight-bold text-dark"> {{ managerFile.managerUsername }}</span>
            </p>
            <p class="text-secondary text-left font-weight-bold">
              Purpose:
              <span class="font-weight-bold text-dark"> {{ managerFile.purpose }}</span>
            </p>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6">
            <p class="text-secondary text-left font-weight-bold">
              Date Uploaded:
              <span class="font-weight-bold text-dark">
                {{ managerFile.createdDate | date: "dd-MMM-yyyy hh:mm:ss a" }}
              </span>
            </p>
          </div>
        </div>

        <img
          *ngIf="managerFile?.photo"
          [src]="buildFilePhoto(managerFile) | authImage | async"
          alt="VIP"
          onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
          style="width: 100%"
        />
        <img *ngIf="!managerFile?.photo" src="https://via.placeholder.com/64x64?text=VIP" alt="VIP" />
      </div>

      <div class="d-flex justify-content-end mr-2">
        <button
          class="btn btn-sm btn-danger mr-2"
          *ngIf="roleId == 1 || roleId == 2 || roleId == 3 || roleId == 5 || roleId == 8"
          (click)="confirmRemove(confirmFileRemove)"
        >
          <i class="fa fa-trash"></i> &nbsp; Remove
        </button>

        <button
          class="btn btn-sm btn-success mr-2"
          (click)="downloadImage(managerFile.photo, managerFile.photoFormat, managerFile.fileName)"
        >
          <i class="fa fa-download"></i> &nbsp; Download
        </button>

        <button class="btn btn-sm btn-secondary mr-2" (click)="modal.dismiss('Cross click')">Close</button>
      </div>
    </div>
  </ng-template>

  <ng-template #confirmFileRemove let-modal>
    <div class="modal-header">
      <h6 class="modal-title">Are you sure you want to remove the file?</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <button class="btn btn-sm btn-primary float-right" (click)="removeFile()">Proceed</button>
      <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
        Cancel
      </button>
    </div>
  </ng-template>

  <ng-template #openAdjustWalletModal let-modal>
    <div class="modal-header" [ngClass]="isAdd == 'true' ? ' bg-success ' : ' bg-danger '">
      <h4 class="modal-title">Wallet Adjustment</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" *ngIf="adjustWalletFormGroup">
      <app-textbox
        [parent-form-group]="adjustWalletFormGroup"
        tab-index-start="0"
        control-name="remarks"
        [error]="adjustWalletFormGroup?.controls.remarks?.errors | estatus"
        [error-message]="adjustWalletFormGroup?.controls.remarks?.errors | econtent"
        [read-only]="false"
        label="Remarks"
        placeholder="Remarks"
      ></app-textbox>

      <app-textbox
        [parent-form-group]="adjustWalletFormGroup"
        tab-index-start="0"
        control-name="adjustmentAmount"
        [error]="adjustWalletFormGroup?.controls.adjustmentAmount?.errors | estatus"
        [error-message]="adjustWalletFormGroup?.controls.adjustmentAmount?.errors | econtent"
        [read-only]="false"
        label="Amount"
        placeholder="Amount"
      ></app-textbox>

      <button class="btn btn-sm btn-primary float-right" (click)="proceedAdjustment()">Proceed</button>
      <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
        Cancel
      </button>
    </div>
  </ng-template>

  <ng-template #uploadFilemodal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Upload Files</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <div>
        <div class="page-content page-container" id="page-content">
          <div class="padding" *ngIf="fileUploadFormGroup">
            <!-- <div>
              <form (ngSubmit)="onSubmit()">
                <div class="form-group">
                  <input type="file" name="image" />
                </div>
                <div class="form-group">
                  <button class="btn btn-primary">Submit</button>
                </div>
              </form>
            </div> -->

            <div>
              <app-dropdown-identifier
                place-holder="Purpose"
                [parent-form-group]="fileUploadFormGroup"
                control-name="purpose"
                label="Purpose :"
                identifier="FILE_UPLOAD_PURPOSE"
              >
              </app-dropdown-identifier>
            </div>
            <div>
              <app-dropdown-identifier
                place-holder="Purpose"
                [parent-form-group]="fileUploadFormGroup"
                control-name="meta"
                label="Type:"
                identifier="APPLICATION_META"
              >
              </app-dropdown-identifier>
            </div>
            <div class="input-group mb-3">
              <div class="input-group-prepend">
                <input type="file" (change)="onPhotoChange($event)" class="custom-file-input" id="inputFile" />
                <label class="custom-file-label" for="inputFile">{{ fileName }}</label>
              </div>
              <span *ngIf="fileUploadFormGroup?.controls.photoFile.errors | estatus" class="text-error"
                ><small>{{ fileUploadFormGroup?.controls.photoFile.errors | econtent }}</small>
              </span>
            </div>
            <div
              *ngIf="!fileUploadFormGroup?.controls.photoFile.value"
              class="dropzone white b-a b-3x b-dashed b-primary p-a rounded p-5 text-center"
            >
              <br />
              <br />
              <br />
              <h4 class="my-4">Upload Photo Here.</h4>
              <span class="text-muted d-block mb-4"
                >(Allowed photo format: <strong>.png </strong> <strong>.jpeg </strong> <strong>.jpg</strong> )</span
              >
              <br />
              <br />
              <br />
            </div>
            <img
              *ngIf="fileUploadFormGroup?.controls.photoFile.value"
              [src]="fileUploadFormGroup?.controls.photoFile.value | authImage | async"
              alt="VIP"
              onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
              style="width: 100%"
            />
            <button class="btn btn-sm btn-primary mt-2 float-right" (click)="submitFile()">
              <i class="fa fa-upload"></i> &nbsp; Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<ng-template #viewImageModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">File Viewer</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="d-flex justify-content-end mr-2">
      <button class="btn btn-sm btn-success mr-2 mb-1" (click)="downloadImage(photo, photoFormat, photoFileName)">
        <i class="fa fa-download"></i> &nbsp; Download
      </button>
    </div>
    <div>
      <ngx-extended-pdf-viewer
        *ngIf="fileType == 'PDF'"
        [base64Src]="imagePreview"
        backgroundColor="#ffffff"
        height="100%"
        [useBrowserLocale]="true"
        [handTool]="false"
        [showHandToolButton]="true"
      >
      </ngx-extended-pdf-viewer>
      <img
        *ngIf="fileType == 'IMAGE'"
        [src]="imagePreview"
        onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
        style="width: 100%"
      />
    </div>
  </div>
</ng-template>

<ng-template #confirmSalesAccountActivation let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Are you sure you want to activate Sales Module for {{ user?.username }}?</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <button class="btn btn-sm btn-primary float-right" (click)="activateSalesModule()">Proceed</button>
    <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #lockAccountModal let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Are you sure you want to Lock Manager User of {{ user?.username }}?</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <button class="btn btn-sm btn-primary float-right" (click)="putAccountLock()">Proceed</button>
    <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
      Cancel
    </button>
  </div>
</ng-template>
