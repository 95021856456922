import { FormControl, FormGroup } from "@angular/forms";
import { Input } from "@angular/core";
import { Component } from "@angular/core";
import { CurrencyPipe, DecimalPipe } from "@angular/common";

@Component({
  selector: "app-textbox-currency",
  templateUrl: "./textbox-currency.component.html",
  styleUrls: ["./textbox-currency.component.less"],
})
export class TextboxCurrencyComponent {
  @Input("parent-form-group") parentFormGroup: FormGroup;
  @Input("control-name") controlName: string;
  @Input("label") label: string;
  @Input("placeholder") placeholder: string;
  @Input("upper-case") upperCase: boolean;
  @Input("number-only") numberOnly: boolean;
  @Input("max-length") maxLength: number;
  @Input("tab-index-start") tabIndexStart: number;
  @Input("read-only") isReadonly: string;

  @Input("error") error: boolean;
  @Input("error-message") errorMessage: string;

  constructor(private currencyPipe: CurrencyPipe, private decimalPipe: DecimalPipe) {}

  ngOnInit(): void {}

  readOnly(): any {
    return this.isReadonly.toString() == "true" ? "readonly" : null;
  }

  transformAmount(): void {
    try {
      this.parentFormGroup.controls[this.controlName].setValue(
        this.currencyPipe
          .transform(this.parentFormGroup.controls[this.controlName].value.replace(/,/g, ""), "PHP")
          .replace("PHP", "")
      );
    } catch (Error) {
      this.parentFormGroup.controls[this.controlName].setValue("");
    }
  }
}
