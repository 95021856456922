import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadService } from 'src/data/services/web/file-upload.service';
import PNotify from "pnotify/dist/es/PNotify";
import { RolesService } from 'src/data/services/web/roles.service';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
@Component({
  selector: 'app-manager-file-list',
  templateUrl: './manager-file-list.component.html',
  styleUrls: ['./manager-file-list.component.less']
})
export class ManagerFileListComponent implements OnInit {
  
  public loader = true;
  public base64Photo: string | ArrayBuffer;
  public emptyString = "";

  public rolesService: RolesService;
  public fileUploadService: FileUploadService;
  public localStorageCacheService: LocalStorageCacheService;

  public fileUploadFormGroup: FormGroup;
  public roleLevelId: any;
  public userID: any;
  public username: any;

  public uploadModal = null;

  public managerFiles: any;
  public managerFile: any;
  fileData: File = null;

  @Output("closeModal") closeModal = new EventEmitter();

  constructor(private changeDetectorRef: ChangeDetectorRef, 
    rolesService: RolesService,
    private modalService: NgbModal, 
    localStorageCacheService: LocalStorageCacheService,
    fileUploadService: FileUploadService,
    private http: HttpClient) { 

    this.fileUploadService = fileUploadService;
    this.rolesService = rolesService;
    this.localStorageCacheService = localStorageCacheService;
  }

  ngOnInit(): void {
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.userID = this.localStorageCacheService.getStorage("userId");
    this.username = this.localStorageCacheService.getStorage("username");
    this.initFileUploadFormGroup();
    this.getManagerFiles();
  }

  initFileUploadFormGroup(): void {
    this.fileUploadFormGroup = new FormGroup({
      photoFile: new FormControl(this.emptyString),
      purpose: new FormControl(this.emptyString),
      meta: new FormControl(this.emptyString)
    });
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

  onSubmit() {
    const formData = new FormData();
    formData.append('file', this.fileData);

    
  }
  
  getManagerFiles(){
    this.fileUploadService.getManagerFiles(this.userID).subscribe((result) => {
      this.managerFiles = result;
    });

    
  }

  getCompanyUsers(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
  
  }

  openFileUpload(content: any) : void {
    this.uploadModal = this.modalService.open(content, { centered: true, size: "lg" });
  }

  viewContent(content: any, managerFile) : void {
    this.managerFile = managerFile;
    this.modalService.open(content, { centered: true, size: "lg" });
  }


  public fileName: any = "Click here to choose file...";

  public onPhotoChange(event: any) {
    const reader = new FileReader();
    
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.fileName = file.name;
      reader.onload = () => {
        this.base64Photo = reader.result;

        this.fileUploadFormGroup.patchValue({
          photoFile: reader.result,
        });

        this.changeDetectorRef.markForCheck();
      };
    }
  }

  submitFile() : void {
    this.loader = true;

   
    this.fileUploadService.PostFileUpload(this.fileUploadFormGroup.value, this.userID, this.username + "'s " + this.fileUploadFormGroup.controls.meta.value)
    .subscribe((result) => {
      PNotify.success({
        title: "Success",
        text: "File uploaded successfully!",
      });
      this.fileUploadFormGroup.patchValue({
        photoFile: "",
      })
      this.loader = false;
      this.getManagerFiles();
      this.uploadModal.close();
    
    }, error => {
      PNotify.error({
        title: "Failed",
        text: "Error uploading file!",
      });
      this.loader = false;
    });
  }

  buildUserPhoto(managerFile: any): any {
    return managerFile?.photoFormat + "," + managerFile?.photo;
  }
}
