<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css">
<body>
  <div id="brd"></div>
  <div class="main-game">
    <h3>REVOLUTION</h3>
    <span>Bang! Bang! Bang!</span>
    <h1>Are you ready to earn points?</h1>
    <h2 >GO START AND COLLECT YOUR REWARDS!</h2>
    <button class="start-btn game-button" id="strt-gme">Start</button>
</div>




<audio preload="auto" id="sound1" src="../../../assets/vips/gunbird.wav"></audio>
<audio preload="auto" id="sound2" src="../../../assets/vips/coin-upaif-14631.mp3"></audio>
<audio preload="auto" id="sound3" src="../../../assets/vips/audiomass-output.wav"></audio>
<audio id="bg-music" loop src="../../../assets/vips/Komiku_-_12_-_Bicycle.mp3" [volume]=".5"></audio>
</body>

<!-- <div class="top-btn">
  <a id="muteBtn" class="muteBtn"><i id="iconmute" class="iconmute bi bi-volume-up-fill"></i></a>
  <a style="padding-bottom: 5px;"><i class="bi bi-gear"></i></a>
</div> -->