import { Pipe, PipeTransform } from "@angular/core";
import { FormControl } from "@angular/forms";

@Pipe({
  name: "econtent",
})
export class ErrorContentPipe implements PipeTransform {
  transform(errors: any): string {
    return errors !== null ? errors.apiMessage[0] : "";
  }
}
