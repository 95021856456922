<app-loader *ngIf="!transactions"></app-loader>
<app-loader *ngIf="transferVipco"></app-loader>

<!-- <app-loader *ngIf="processUpdate"></app-loader> -->

<div id="content" class="flex" *ngIf="transactions">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">List of Rewards Transactions</h2>
          <small class="text-highlight font-weight-normal font-italic">JuanPay list of transaction Items</small>
        </div>
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="card mt-2">
        <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
          <thead>
            <tr>
              <th data-field="Reference" *ngIf="!transferVipco">
                <div>Actions</div>
              </th>
              <th data-field="Reference">
                <div>Transaction Details</div>
              </th>

              <th data-field="Amount">
                <div>Amount</div>
              </th>
              <th data-field="Before">
                <div>Balance Before</div>
              </th>
              <th data-field="After">
                <div>Balance After</div>
              </th>
              <th data-field="status"><span class="d-none d-sm-block">Status</span></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let item of transactions?.data
                  | paginate: { itemsPerPage: 10, currentPage: p, totalItems: transactions.totalRecords }
              "
              style="cursor: pointer"
              class="odd"
              role="row"
              (click)="viewItem(item.id)"
            >
              <!-- withdrawableDate-->
              <td class="flex text-center" *ngIf="!transferVipco">
                <button
                  (click)="actionTransferVIPCoToAppData(item?.id)"
                  *ngIf="
                    item?.isWithdrawable &&
                    item?.isTransferred != true &&
                    (item?.transactionType == 'MIGRATED_REWARDS' ||
                      item?.transactionType == 'PAIRING_COMMISION' ||
                      item?.transactionType == 'SPONSOR_COMMISSION')
                  "
                  type="button"
                  class="btn btn-primary"
                  data-dismiss="modal"
                >
                  Transfer Rewards
                </button>
                <div
                  *ngIf="
                    !item?.isWithdrawable &&
                    (item?.transactionType == 'MIGRATED_REWARDS' ||
                      item?.transactionType == 'PAIRING_COMMISION' ||
                      item?.transactionType == 'SPONSOR_COMMISSION')
                  "
                  class="item-except text-sm h-1x text-highlight"
                  style="color: orange"
                >
                  <strong> Transferable on {{ item?.withdrawableDate | date: "dd-MMM-yyyy hh:mm:ss a" }}</strong>
                </div>

                <div
                  class="text-center"
                  *ngIf="
                    item?.isTransferred == true &&
                    (item?.transactionType == 'MIGRATED_REWARDS' ||
                      item?.transactionType == 'PAIRING_COMMISION' ||
                      item?.transactionType == 'SPONSOR_COMMISSION')
                  "
                >
                  <strong style="color: green"> Successfully Transferred </strong>
                </div>
              </td>
              <td class="flex">
                <div class="item-except text-sm h-1x">
                  <strong>Transaction Name : {{ item?.transactionName }}</strong>
                </div>
                <div class="item-except text-muted text-sm h-1x">Request ID : {{ item?.requestId }}</div>
                <div class="item-except text-muted text-sm h-1x">Reference Number : {{ item?.referenceNumber }}</div>

                <div class="item-except text-muted text-sm h-1x">
                  Created date : {{ item?.createdDate | date: "dd-MMM-yyyy hh:mm:ss a" }}
                </div>
                <div class="item-except text-muted text-sm h-1x">
                  Completed date : {{ item?.completedDate | date: "dd-MMM-yyyy hh:mm:ss a" }}
                </div>
              </td>

              <td>
                <div class="text-center">
                  <strong *ngIf="statusOfAmount(item?.amount)" style="color: green"
                    >{{ makeMoney(item?.amount) }}
                  </strong>
                  <strong *ngIf="!statusOfAmount(item?.amount)" style="color: red"
                    >{{ makeMoney(item?.amount) }}
                  </strong>
                </div>
              </td>

              <td>
                <div class="text-center">
                  <strong *ngIf="item?.statusId == 2">{{ makeMoney(item?.openingBalance) }}</strong>
                  <i class="text-muted" *ngIf="item?.statusId != 2">N/A</i>
                </div>
              </td>

              <td>
                <div class="text-center">
                  <strong *ngIf="item?.statusId == 2">{{ makeMoney(item?.openingBalance + item?.amount) }}</strong>
                  <i class="text-muted" *ngIf="item?.statusId != 2">N/A</i>
                </div>
              </td>

              <td class="flex" style="max-width: 75px">
                <app-status-table-data [status]="item?.statusId"></app-status-table-data>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-5">
          <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
            <div class="col-sm-12 col-md-12">
              <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                Showing {{ (transactions?.pageNumber - 1) * transactions?.pageSize + 1 }} to
                {{ (transactions?.pageNumber - 1) * transactions?.pageSize + transactions?.data?.length }} of
                {{ transactions?.data?.length }} entries
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-7" style="float: right">
          <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
            <ul class="pagination" style="float: right">
              <pagination-controls (pageChange)="getVIPCOTransactions(buildPageUrl($event, 10))"></pagination-controls>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<ng-template #viewMultisys let-modal>
  <div class="modal-header">
    <h4 class="modal-title">[Store] Transaction Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- body here -->
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>
