<div class="page-hero page-container" id="page-hero">
    <div class="padding d-flex">
      <div class="page-title">
        <h2 class="text-md text-highlight">Your VIP Community</h2>
        <small class="text-highlight font-weight-normal font-italic"></small>
      </div>
      <div class="flex"></div>

     

      <!-- (click)="saveRequest FundUser()"  -->
    </div>
  </div>
<tree-diagram [data]="tree"></tree-diagram>


