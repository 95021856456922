import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';

import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { CurrencyPipe, DatePipe } from '@angular/common';
import { PrintService } from 'src/data/services/web/print.service';
import { VipProtectService } from 'src/data/services/web/vip-protect.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { SunlifeGrepaService } from 'src/data/services/web/sunlife-grepa.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5";

@Component({
  selector: 'app-sunlife-grepa',
  templateUrl: './sunlife-grepa.component.html',
  styleUrls: ['./sunlife-grepa.component.less']
})
export class SunlifeGrepaComponent implements OnInit {

  @Input("application") application: any;
  vipProtectFormGroup : FormGroup;
  formBuilder : FormBuilder;
  public emptyString = "";
  public billers : any = [];
  public biller : any;
  public billerCode : any;
  public billerTypes : any = [];
  public billersByBillerTypes : any = [];
  public selectedBillerCode : any;

  public activeTab = 1;
  public loader = true;
  public fee : any;
  public amount : any;

  @Output("closeModal") closeModal = new EventEmitter();
  
  constructor(
    private sunlifeGrepaService : SunlifeGrepaService,
    private router: Router,
    localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    formBuilder : FormBuilder,
    private webService: WebSettingsService,
    private datepipe: DatePipe) {
    this.formBuilder = formBuilder;
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.initVIPProtectFormGroup();
    this.initSunLifeGrepaProducts();
    this.loader = false;
  }

  initVIPProtectFormGroup(): void {
    this.vipProtectFormGroup = new FormGroup({
      productCode: new FormControl(this.emptyString, [Validators.required]),
      customerName: new FormControl(this.emptyString, [Validators.required]),
      birthDate: new FormControl(this.emptyString, [Validators.required]),
      // beneficiary: new FormControl(this.emptyString, [Validators.required]),
      // relationship: new FormControl(this.emptyString, [Validators.required]),
      // residence: new FormControl(this.emptyString, [Validators.required]),
      // contactNumber: new FormControl(this.emptyString, [Validators.required]),
      amountToBePaidByCustomer: new FormControl(this.emptyString, [Validators.required]),
      amountToBePaidByOutlet: new FormControl(this.emptyString, [Validators.required]),
      outletShare: new FormControl(this.emptyString, [Validators.required]),
      amountOfInsurance: new FormControl(this.emptyString, [Validators.required])
    });
  }


  

  productDetails : any;
  productDetail : any;

  initSunLifeGrepaProducts() {
    this.webService.getWebSettingsByIdentifier("SUNLIFE_GREPA_PRODUCTS").subscribe((result) => {
      this.productDetails = result.data.filter(x=>x.value.active == true);
    });
  }

  getProductName(productCode){
    if(productCode){
      return this.productDetails.filter(x=>x.parameter == productCode)[0].value.plan;
    }
  
  }
 
  setSelectedProduct(){
    this.productDetail = this.productDetails.filter(x=>x.value.active == true && x.parameter == this.vipProtectFormGroup.controls.productCode.value)[0].value;
    this.vipProtectFormGroup.controls.amountToBePaidByCustomer.setValue(this.productDetail.amountToBePaidByCustomer);
    this.vipProtectFormGroup.controls.amountToBePaidByOutlet.setValue(this.productDetail.amountToBePaidByOutlet);
    this.vipProtectFormGroup.controls.outletShare.setValue(this.productDetail.outletShare);
    this.vipProtectFormGroup.controls.amountOfInsurance.setValue(this.productDetail.amountOfInsurance);

  }


  setActiveTab(){

    if(this.activeTab == 1){
       if(this.vipProtectFormGroup.status == "INVALID"){
        PNotify.error({
          title: "Error",
          text: "All fields are required to proceed to next step.",
        });
      }else{
        this.activeTab++;
        this.loader = false;
      }
    }else if(this.activeTab == 2){

      var hasError : boolean  = false;
      if(hasError){
        PNotify.error({
          title: "Request denied",
          text: "Please fill up all the required fields.",
        });
      }else{

      }

     
    }
  }

  setActiveTabPrev(){
    if(this.activeTab == 1){
      PNotify.error({
        title: "Error",
        text: "Action Denied.",
      });
    }else{
      this.activeTab--;
    }
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  codeStatus : boolean = false;
  code : any;
  
  onCodeCompleted(code: string) {
    this.code = code;
    this.codeStatus = code.length == 6;
  }

  
  processPayment(){
    this.loader = true;
    this.vipProtectFormGroup.controls.birthDate.setValue(this.datepipe.transform(this.vipProtectFormGroup.controls.birthDate.value, 'MMM-dd-yyyy'));
    this.sunlifeGrepaService.processSunlifeGrepa(this.vipProtectFormGroup.value,this.code)
      .subscribe((result) => {
        PNotify.success({
          title: "Success",
          text: result.reason,
        });
        this.loader = false;
        this.code = "";
        this.codeStatus=false;
        this.closeModal.emit();
      
    }, error => {
      PNotify.error({
        title: "Failed",
        text: error.error.reason,
      });
      this.loader = false;
      this.code = "";
      this.codeStatus = false;
    });
}

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  totalAmount(fee:any,amount:any){
    try  {
    return this.makeMoney(Number(fee) + Number(amount));
    } catch(e){
      return "0.00";
    }
  }

  
}

