import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { ManagerUsersService } from 'src/data/services/web/manager-users.service';
import { VipcoTransactionService } from 'src/data/services/web/vipco-transaction.service';
import { WalletService } from 'src/data/services/web/wallet.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';

@Component({
  selector: 'app-widget-basic-profile',
  templateUrl: './widget-basic-profile.component.html',
  styleUrls: ['./widget-basic-profile.component.less']
})
export class WidgetBasicProfileComponent implements OnInit {

  // @Input() userCount: any;
  // @Input() managerCount: any;
  // @Input() riderCount: any;

  public localStorageCacheService: LocalStorageCacheService;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value:any = "";
  emptyString: any = "";

  searchByFormGroup: FormGroup;
  
  constructor(localStorageCacheService: LocalStorageCacheService,
    private modalServe: NgbModal,
    private usersService: ManagerUsersService,
    private walletService: WalletService,
    private vipcoTransactionService: VipcoTransactionService,
    public webSettingsService: WebSettingsService) { 
   
    this.localStorageCacheService = localStorageCacheService;
  }

  userID: any;
  username: any;
  ngOnInit(): void {
    this.value = this.localStorageCacheService.getStorage("forQR");

    var managerId = this.localStorageCacheService.getStorage("id");
    this.getManagerUser(managerId);
    this.userID = this.localStorageCacheService.getStorage("userId");
    this.username = this.localStorageCacheService.getStorage("username");
    this.getWallet();
    this.getVipsUsers();
    this.initReceipt();
    this.getRewards(managerId);
  }

  getWallet(): void {
    this.walletService.getWallet().subscribe((result) => {
      this.wallet = result.wallet;
    });
  }

  wallet: any;
  user: any;
  getManagerUser(id: any): void {
    this.usersService.getManagerUser(id).subscribe((result) => {
      this.user = result.data;
    });
  }

  userCount: any;
  managerCount: any;
  riderCount: any;
  users: any = [];
  getVipsUsers(){
    this.vipcoTransactionService.getVipsLinkedUsers().subscribe((result) => {
      this.users = (result.response_data);
      this.userCount = (result.response_data).filter(x=> x.Usertype != "LEVEL_6" && x.Usertype != "LEVEL_7" && x.Usertype != "LEVEL_8" && x.Usertype != "LEVEL_9").length;
      this.managerCount = (result.response_data).filter(x=> x.Usertype == "LEVEL_6" || x.Usertype == "LEVEL_7").length;
      this.riderCount = (result.response_data).filter(x=> x.Usertype == "LEVEL_8" || x.Usertype == "LEVEL_9").length;
     
      // if (result.data.length == 0 && result.pageNumber != 1) {
      //   this.getVIPCOTransactions(result.previousPage, _sortBy, _sort);
      // } else {
      //   this.transactions = result;
      // }
    });
  }

  searchData(){

  }

  initSearchFormGroup(): void {
    this.searchByFormGroup = new FormGroup({
      showRows: new FormControl(null),
      dateRange: new FormControl(this.emptyString),
      searchString: new FormControl(null),
    });
  }

  viewUser(id: any){

  }

  receipt: any;
  initReceipt() {
    this.webSettingsService.getWebSettingsByIdentifier("GoVIPX_QR_TEMPLATE").subscribe((result) => {
      this.receipt = result.data;
    });
  }

  openQR(content: any){
    this.modalServe.open(content, { centered: true, size: "lg" });
  }

  getQR(myQRTemplate: any){
    var qrtemplate = myQRTemplate.innerHTML;

    var Newstr = (this.receipt[0].value)
      .replace("{{QR}}", qrtemplate);
    
    this.PrintReceipt(Newstr);
  }

  PrintReceipt(Newstr: any)
  {
    var mywindow = window.open('', 'PRINT', 'height=700,width=1000');
    mywindow.document.write(Newstr);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    this.modalServe.dismissAll();

    return true;
  }

  dailyReward: any;
  weeklyReward: any;
  monthlyReward: any;
  totalReward: any;
  getRewards(managerId: any){
    this.walletService.getRewardSummary(managerId).subscribe((result) => {
      this.dailyReward = result.daily;
      this.weeklyReward = result.weekly;
      this.monthlyReward = result.monthly;
      this.totalReward = result.total;
    });
  }
}
