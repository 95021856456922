<app-loader *ngIf="loader"></app-loader>

<!-- <div class="col-sm-12 col-md-12">
  <div class="modal-body" *ngIf="step == 1">
    <div class="col-sm-12 col-md-12" style="float: none; margin: auto">
      <div class="item-feed h-2x text-center">
        <h3>Please enter 6 digits pin.</h3>
      </div>
      <code-input
        [isCodeHidden]="true"
        [codeLength]="6"
        (codeChanged)="onCodeChanged($event)"
        (codeCompleted)="onCodeCompleted($event)"
        [code]="code"
      >
      </code-input>
    </div>
  </div>
  <div class="modal-footer" *ngIf="step == 1">
    <button *ngIf="code.length == 6" type="button" class="btn btn-primary" (click)="proceedToNextStep()">Next</button>
  </div>

  <div class="modal-body" *ngIf="step == 2">
    <div class="col-sm-12 col-md-12" style="float: none; margin: auto">
      <div class="item-feed h-2x text-center">
        <h3>Please re-enter your 6 digits pin.</h3>
      </div>
      <code-input
        [isCodeHidden]="true"
        [codeLength]="6"
        (codeChanged)="onCodeChanged($event)"
        (codeCompleted)="onReEnterCodeCompleted($event)"
        [code]="reEnterCode"
      >
      </code-input>
    </div>
  </div>
  <div class="modal-footer" *ngIf="step == 2">
    <button *ngIf="code.length == 6" type="button" class="btn btn-default" (click)="goBack()">Back</button>
    <button type="button" *ngIf="reEnterCode.length == 6" class="btn btn-primary" (click)="enrolPin()">Enroll</button>
  </div>

  <div class="modal-body" *ngIf="step == 3">
    <div class="col-sm-12 col-md-12" style="float: none; margin: auto">
      <div class="item-feed h-2x text-center"></div>
    </div>
    <h3>{{ apiMessage }}</h3>
  </div>
  <div class="modal-footer" *ngIf="step == 3">
    <button type="button" class="btn btn-primary" (click)="close()">Login</button>
  </div>
</div> -->

<!-- <div class="modal-body rounded-circle">
  <div class="container-fluid">

    <div class="item-feed h-2x text-center" *ngIf="step == 1">
      <h6>Please enter 6 digits pin.</h6>
    </div>
    <div class="row" *ngIf="step == 1">
      <div class="col-2 guard--icon">
        <img src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file12eec62c-b174-4ed1-87aa-1f5cc2cc5a67.png" alt="...">
      </div>
      <div class="col p-0">
        <code-input
          class="rounded-circle"
          [isCodeHidden]="true"
          [codeLength]="6"
          (codeChanged)="onCodeChanged($event)"
          (codeCompleted)="onCodeCompleted($event)"
          [code]="code">
        </code-input>
      </div>
    </div>
    <div class="modal-footer mt-2" *ngIf="step == 1">
      <button *ngIf="code.length == 6" type="button" class="btn btn-primary" (click)="proceedToNextStep()">Next</button>
    </div>

    <div class="row" *ngIf="step == 2">
      <div class="col p-0">
        <code-input
          class="rounded-circle"
          [isCodeHidden]="true"
          [codeLength]="6"
          (codeChanged)="onCodeChanged($event)"
          (codeCompleted)="onReEnterCodeCompleted($event)"
          [code]="reEnterCode"
        >
        </code-input>
      </div>
    </div>
    <div class="modal-footer mt-2" *ngIf="step == 2">
      <button *ngIf="code.length == 6" type="button" class="btn btn-default" (click)="goBack()">Back</button>
      <button type="button" *ngIf="reEnterCode.length == 6" class="btn btn-primary" (click)="enrolPin()">Enroll</button>
    </div>

    <div class="row" *ngIf="step == 3">
      <div class="modal-body" *ngIf="step == 3">
        <div class="col-sm-12 col-md-12" style="float: none; margin: auto">
          <div class="item-feed h-2x text-center"></div>
        </div>
        <h6>{{ apiMessage }}</h6>
      </div>
      <div class="modal-footer" *ngIf="step == 3">
        <button type="button" class="btn btn-primary" (click)="close()">Login</button>
      </div>
    </div>
 

  </div>
</div>
 -->

<div class="modal-body" style="position: absolute; top: -100px">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <img src="../../../assets/vips/Security On-bro.png" alt="" style="padding: 0; width: 70px; margin-top: 20px" />
      <h2 style="text-align: center; font-size: 20px; margin: 0; padding-top: 10px; margin-bottom: 20px">
        Enroll Security PIN
      </h2>

      <div
        class="col p-0"
        style="box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; border-radius: 8px; height: 75px; background-color: #f3f3fa"
      >
        <div class="row" *ngIf="step == 1">
          <div class="col p-0">
            <code-input
              class="rounded-circle"
              [isCodeHidden]="true"
              [codeLength]="6"
              (codeChanged)="onCodeChanged($event)"
              (codeCompleted)="onCodeCompleted($event)"
              [code]="code"
            >
            </code-input>
          </div>
        </div>
        <div class="modal-footer mt-5" *ngIf="step == 1">
          <button *ngIf="code.length == 6" type="button" class="btn btn-primary" (click)="proceedToNextStep()">
            Next
          </button>
        </div>

        <div class="row" *ngIf="step == 2">
          <div class="col p-0">
            <code-input
              class="rounded-circle"
              [isCodeHidden]="true"
              [codeLength]="6"
              (codeChanged)="onCodeChanged($event)"
              (codeCompleted)="onReEnterCodeCompleted($event)"
              [code]="reEnterCode"
            >
            </code-input>
          </div>
        </div>
        <div class="modal-footer mt-5" *ngIf="step == 2">
          <button *ngIf="code.length == 6" type="button" class="btn btn-default" (click)="goBack()">Back</button>
          <button type="button" *ngIf="reEnterCode.length == 6" class="btn btn-primary" (click)="enrolPin()">
            Enroll
          </button>
        </div>
      </div>
      <p style="color: grey; font-size: 14px; text-align: center; margin-top: 10px" *ngIf="step == 1">
        Please enter 6 digit PIN code
      </p>
      <p style="color: grey; font-size: 14px; text-align: center; margin-top: 10px" *ngIf="step == 2">
        Please re-enter 6 digit PIN code
      </p>
    </div>
  </div>
</div>
