

<ng-template #viewLoginModal let-modal>       
    <div class="modal-body p-5">
        <center><img src="../../../assets/images/logo/GoVIP Logo Size  230.16 x 72 Pixels.png" alt="..." width="200"  /><br/>
            <h5 class="modal-title">Sales Agent Migration Login</h5><br/></center>
        <div class="" [formGroup]="loginUserFormGroup" >
            <div class="form-group">
                <label>Username</label>
                <input type="text" class="form-control" placeholder="Enter username" formControlName="username">
            </div>
            <div class="form-group">
                <label>Password</label>
                <input type="password" class="form-control" placeholder="Password" formControlName="password">
            </div>

            <button type="button" (click)="loadData()" class="btn btn-primary mb-4" style="float:right;">Sign in to Migration</button>
          
        </div>
    </div>
</ng-template>


<ng-template #verifyModal let-modal>
    <div class="modal-body p-5">
        <center><img src="../../../assets/images/logo/GoVIP Logo Size  230.16 x 72 Pixels.png" alt="..." width="200"  /><br/>
        <h5 class="modal-title">Sales Agent Migration</h5><br/></center>

        <div class="pt-4 pb-4 pl-0 pr-0 text-center">
            <h6>Kindly verify your email to proceed with OTP.</h6>
            <h6>We have already sent an email to ({{ emailStr }}).</h6>
        </div>
    </div>
</ng-template>


<ng-template #enterOTPModal let-modal>
    <div class="modal-body pt-5 pb-5">
        <center><img src="../../../assets/images/logo/GoVIP Logo Size  230.16 x 72 Pixels.png" alt="..." width="200"  /><br/>
        <h5 class="modal-title">Sales Agent Migration</h5><br/></center>

        <div class="pt-4 pb-4 pl-0 pr-0 text-center">
            <h6>Email succesfully validated.</h6>
            <h6 *ngIf="hasNumber">To proceed to the next step.</h6><br>
            <h6 *ngIf="hasNumber">Please enter OTP sent to (+63XXXXXX{{ numberStr.substring(11, 7) }})</h6>
            <h6 *ngIf="isDuplicateContact">Contact number already exist.</h6>
            <h6 *ngIf="!hasNumber">Please contact GoVIPCenter Support.</h6>
            
            <code-input
                *ngIf="hasNumber"
                [isCodeHidden]="false"
                [codeLength]="6"
                [isCharsCode]="true"
                [inputType]="text"
                [autoCapitalize]
                (codeChanged)="onCodeChangedOtp($event)"
                (codeCompleted)="onCodeCompletedOtp($event)"
                [code]="otp"
            >
            </code-input>
        </div>
    </div>
</ng-template>



<app-loader *ngIf="loader || processCreate"> </app-loader>

<div *ngIf="validAccount" id="content" class="flex" >

  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">

        <div style="float: left;"><img src="../../../assets/images/logo/GoVIP Logo Size  230.16 x 72 Pixels.png" alt="..." width="200"  /></div>
        <div class="page-title">
         
            
          <h2 class="text-md text-highlight">Migration Form</h2>
          <small class="text-highlight font-weight-normal font-italic">Make sure all the information are correct</small>
        </div>
        <div class="flex"></div>

        <div>
            
        </div>

      </div>
    </div>
    <div class="page-content page-container" id="page-content">
    <div class="padding">

        <div class="card" [formGroup]="managerUserFormGroup">
            <div class="card-header">
                <strong>Account Information</strong>

                <div 
                    *ngIf="!username"
                    (click)="logout()"
                    class="btn btn-sm btn-info float-right mr-2"
                >
                    <span >Logout</span>
                    <i data-feather="arrow-right"></i>
                </div>
                <div 
                    *ngIf="username"
                    class="btn btn-sm btn-info float-right mr-2"
                    (click)="createAccount()"
                    >
                    <span >Create Account</span>
                    <i data-feather="arrow-right"></i>
                </div>
                &nbsp;
                
                <div
                (click)="submitChanges()"
                    class="btn btn-sm btn-primary float-right mr-2"
                >
                    <span >Save</span>
                    <i data-feather="arrow-right"></i>
                </div>
            </div>
            <div class="card-body">
                <div class="row row-sm">
                    <div class="col-sm-4">
                        <div class="md-form-group">
                            <input class="md-input input-readonly" readonly formControlName="username">
                            <label>Username</label>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="md-form-group">
                            <input class="md-input input-readonly" readonly formControlName="email">
                            <label>Email Address</label>
                        </div>
                    </div>
                    <!-- <div class="col-sm-4">
                        <div class="md-form-group">
                            <input class="md-input input-readonly" readonly formControlName="roleId">
                            <label>Role</label>
                        </div>
                    </div> -->

                    <div class="col-sm-4">
                        <div class="md-form-group float-label">
                            <select class="md-input" value="" onkeyup="this.setAttribute('value', this.value);" required="" formControlName="roleId" [ngClass]="{ 'is-invalid': submitted && f.roleId.errors }">
                                <option>Executive Director</option>
                                <option>Sales Director</option>
                                <option>Sales Manager</option>
                                <option>Sales Agent</option>
                            </select>
                            <label> <span class="text-danger font-weight-bold">*</span> &nbsp; Role</label>
                        </div>
                        <div *ngIf="submitted && f.roleId.errors" class="invalid-feedback">
                            <div *ngIf="f.roleId.errors">Role is required</div>
                        </div>
                    </div>


                </div>
            </div>
            <div class="card-header">
            <strong>Personal Information</strong>
            </div>
            <div class="card-body">
                <div class="row row-sm">
        
            <div class="col-sm-4">
                <div class="md-form-group float-label">
                    <input class="md-input input-readonly" readonly formControlName="firstName">
                    <label>First Name</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="md-form-group float-label">
                    <input class="md-input" formControlName="middleName">
                    <label>Middle Name/Initial</label>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="md-form-group float-label">
                    <input class="md-input input-readonly" readonly formControlName="lastName">
                    <label>Last Name</label>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="md-form-group float-label">
                    <input class="md-input" formControlName="address">
                    <label> <span class="text-danger font-weight-bold">*</span> &nbsp; Address</label>
                </div>
            </div>
            <div class="col-sm-4">
                    <div class="md-form-group">
                        <input class="md-input input-readonly" readonly formControlName="contactNumber">
                        <label>Contact Number</label>
                        <span class="md-input-msg right"></span>
                    </div>
                
            </div>

            <div class="col-sm-4">
                <div class="md-form-group float-label">
                    <select class="md-input" value="" onkeyup="this.setAttribute('value', this.value);" required="" formControlName="gender" [ngClass]="{ 'is-invalid': submitted && f.gender.errors }">
                        <option>Male</option>
                        <option>Female</option>
                    </select>
                    <label> <span class="text-danger font-weight-bold">*</span> &nbsp; Gender</label>
                </div>
                <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
                    <div *ngIf="f.gender.errors">Gender is required</div>
                </div>
            </div>

            <div class="col-sm-4">
                <div class="md-form-group">
                    <app-datepicker-wo-label
                        [parent-form-group]="managerUserFormGroup"
                        tab-index-start="0"
                        control-name="birthdate"
                        [error]="managerUserFormGroup?.controls.birthdate.errors | estatus"
                        [error-message]="managerUserFormGroup?.controls.birthdate.errors | econtent"
                        no-special-char="false"
                        label="Birthdate"
                        placeholder="Birthdate"
                    >
                  </app-datepicker-wo-label>
                  
                </div>
            </div>
        
            </div>
            </div>
            <div class="card-header">
                <strong>Activation Details</strong>
            </div>
            <div class="card-body">
                <div class="row row-sm">
        
                    <div class="col-sm-3">
                        <div class="md-form-group">
                            <input class="md-input  input-readonly" readonly formControlName="enterpriseActivationDate">
                            <label> <span class="text-danger font-weight-bold">*</span> &nbsp; Enterprise Activation Date</label>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="md-form-group">
                            <input class="md-input input-readonly" readonly formControlName="sponsor">
                            <label> <span class="text-danger font-weight-bold">*</span> &nbsp; Sponsor</label>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="md-form-group">
                            <input class="md-input input-readonly" readonly formControlName="upline">
                            <label> <span class="text-danger font-weight-bold">*</span> &nbsp; Upline</label>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="md-form-group">
                            <input class="md-input input-readonly" readonly formControlName="placement">
                            <label> <span class="text-danger font-weight-bold">*</span> &nbsp; Placement</label>
                        </div>
                    </div>

                </div>
            </div>


            <div class="card-header">
                <strong>Bank Details</strong>
            </div>
            <div class="card-body">
                <div class="row row-sm">

                    <div class="col-sm-4">
                        <div class="md-form-group float-label">
                            <input class="md-input" value="" onkeyup="this.setAttribute('value', this.value);" required=""
                            formControlName="bank" [ngClass]="{ 'is-invalid': submitted && f.bank.errors }">
                            <label><span class="text-danger font-weight-bold">*</span> &nbsp; Bank</label>
                        </div>
                        <div *ngIf="submitted && f.bankAccount.errors" class="invalid-feedback">
                            <div *ngIf="f.bank.errors">Bank is required</div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="md-form-group float-label">
                            <input class="md-input" value="" onkeyup="this.setAttribute('value', this.value);" required=""
                            formControlName="bankAccount" [ngClass]="{ 'is-invalid': submitted && f.bankAccount.errors }">
                            <label><span class="text-danger font-weight-bold">*</span> &nbsp; Bank Account</label>
                        </div>
                        <div *ngIf="submitted && f.bankAccount.errors" class="invalid-feedback">
                            <div *ngIf="f.bankAccount.errors">Bank Account is required</div>
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <div class="md-form-group float-label">
                            <input class="md-input" value="" onkeyup="this.setAttribute('value', this.value);" required=""
                            formControlName="bankType" [ngClass]="{ 'is-invalid': submitted && f.bankType.errors }">
                            <label><span class="text-danger font-weight-bold">*</span> &nbsp; Bank Type</label>
                        </div>
                        <div *ngIf="submitted && f.bankType.errors" class="invalid-feedback">
                            <div *ngIf="f.bankType.errors">Bank Type is required</div>
                        </div>
                    </div>
                </div>
            </div>   


            <div class="card-header">
                <strong>Credits</strong>
            </div>
            <div class="card-body">
                <div class="row row-sm">

                    <div class="col-sm-4">
                        <div class="md-form-group float-label">
                            <input class="md-input input-readonly" readonly [value]="enterpriseData.old.rewards_Credits">
                            <label>Rewards (VIPCO)</label>
                        </div>
                    </div>
                    
                    <div class="col-sm-4">
                        <div class="md-form-group float-label">
                            <input class="md-input input-readonly" readonly [value]="enterpriseData.old.specialPoints">
                            <label>Special Points (SP)</label>
                        </div>
                       
                    </div>

                </div>
            </div>  

    </div>
    
    
    <div class="card" [formGroup]="fileFormGroup">
        <div class="card-header" >
            <strong>File Manager</strong>
        </div>
        <div class="card-body">
            <div class="row row-sm" >
                <div class="col-sm-4">
                    <div class="list list-row block">


                        <div class="list-item " data-id="20">
                            <div class="pointer" (click)="triggerFile(validID1)">
                                <span class="w-40 avatar gd-info">
                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                    <input #validID1  type="file" (change)="onUploadFile($event,'validID1')" style="display: none;"
                                    accept="image/jpeg,image/png,application/pdf" />
                                </span>
                            </div>
                            <div class="flex">
                                <a  class="item-author text-color"><span class="text-danger font-weight-bold">*</span> &nbsp; [1] Valid ID</a>
                                <div class="item-except text-muted text-sm h-1x" [ngClass]="{ 'is-invalid': submitted && fi.validID1Id.errors }">
                                    <label *ngIf="!managerUserFormGroup.controls.validID1Id.value && !fileFormGroup.controls.validID1.value">Click the button to upload File...</label>
                                    <label *ngIf="managerUserFormGroup.controls.validID1Id.value && !fileFormGroup.controls.validID1.value" 
                                        (click)="preview(viewImageModal, managerUserFormGroup.controls.validID1Id.value)" 
                                        style="color:blue; cursor: pointer;"
                                        >
                                    <span class="fa fa-eye">&nbsp;</span>View File
                                    </label>
                                    <label *ngIf="fileFormGroup.controls.validID1.value" style="color:blue; cursor: pointer;">
                                        <span class="fa fa-tag">&nbsp;</span>Save to preview file
                                    </label>
                                </div>
                                <div *ngIf="submitted && fi.validID1Id.errors" class="invalid-feedback">
                                    <div *ngIf="fi.validID1Id.errors">Valid ID is required</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="list list-row block">

                        <div class="list-item " data-id="20">
                            <div class="pointer" (click)="triggerFile(validID2)">
                                <span class="w-40 avatar gd-info">
                                    <i class="fa fa-upload" aria-hidden="true"></i>
                                    <input #validID2  type="file" (change)="onUploadFile($event,'validID2')" style="display: none;"
                                    accept="image/jpeg,image/png,application/pdf" />
                                </span>
                            </div>
                            <div class="flex">
                                <a  class="item-author text-color"> <span class="text-danger font-weight-bold">*</span> &nbsp; [2] Valid ID</a>
                                <div class="item-except text-muted text-sm h-1x" [ngClass]="{ 'is-invalid': submitted && fi.validID2Id.errors }">
                                    <label *ngIf="!managerUserFormGroup.controls.validID2Id.value && !fileFormGroup.controls.validID2.value">Click the button to upload File...</label>
                                    <label *ngIf="managerUserFormGroup.controls.validID2Id.value && !fileFormGroup.controls.validID2.value" 
                                        (click)="preview(viewImageModal, managerUserFormGroup.controls.validID2Id.value)" 
                                        style="color:blue; cursor: pointer;"
                                        >
                                    <span class="fa fa-eye">&nbsp;</span>View File
                                    </label>
                                    <label *ngIf="fileFormGroup.controls.validID2.value" style="color:blue; cursor: pointer;">
                                        <span class="fa fa-tag">&nbsp;</span>Save to preview file
                                    </label>
                                </div>
                                <div *ngIf="submitted && fi.validID2Id.errors" class="invalid-feedback">
                                    <div *ngIf="fi.validID2Id.errors">Valid ID 2 is required</div>
                                </div> 
                            </div>
                        </div>
                    
                    </div>
                </div>
 
            </div> 
        </div>
    </div>

    <div class="card" *ngIf="username">
        <div class="card-header" >
            <strong>Comment Section</strong>
        </div>

        <div class="card-body">
            <div *ngFor="let comment in managerUserFormGroup.controls.comment?.value; let i = index">
                <div formGroup="commentFormGroup" class="form-group row">
              
                    <div class="col-sm-12">
                    <textarea 
                      type="text"
                      value="comment"
                      class="form-control"
                      readonly="true"
                    ></textarea>
                  </div>
                </div>
            </div>
            <div>
                <app-textarea
                    [parent-form-group]="commentFormGroup"
                    tab-index-start="0"
                    control-name="comment"
                    [error]="commentFormGroup?.controls.comment?.errors | estatus"
                    [error-message]="commentFormGroup?.controls.comment?.errors | econtent"
                    [read-only]="roleLevelId == 3"
                    label="Comment"
                    placeholder="Enter your comment here..."> 
                </app-textarea>
            </div>
            <div class="d-flex justify-content-end">
                <button class="btn btn-xs btn-primary" (click)="saveComment()">
                    Send
                </button>
            </div>


            <div class="col-sm-12">
             
                <div class="list list-row block">
                    <div class="list-item " *ngFor="let comment of comments">
                        <div>
                            <a >
                                <span class="w-48 avatar gd-primary">
                                    <span class="fa fa-tag"></span>
                                </span>
                            </a>
                        </div>
                        <div class="flex" comments>
                            <a href="#" class="item-author text-color ">{{comment.comment}}</a>
                            <div class="item-except text-muted text-sm h-1x">
                                {{comment.username}}
                            </div>
                        </div>
                        <div class="no-wrap">
                            <div class="item-date text-muted text-sm d-none d-md-block">{{comment.createdDate | date: "dd-MMM-yyyy hh:mm:ss a"}}</div>
                        </div>
                    </div>
                   
            </div>
        </div>
    </div>


    </div>

    
    </div>
</div>

</div>

<div id="modal-left" class="modal fade" data-backdrop="true">
  <div class="modal-dialog animate">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title text-md">Migration Form</div>
        <button class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="p-4 text-center">
          <p>Are you sure you want continue?</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
        <button type="button" (click)="submitChanges()" class="btn btn-primary" data-dismiss="modal">
          Submit Changes
        </button>
      </div>
    </div>
  </div>
  
<div id="chatButton"></div>
</div>

<ng-template #viewImageModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">File Viewer</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" >
      <div>
        <ngx-extended-pdf-viewer
            [base64Src]="imagePreview" *ngIf="fileType=='PDF'"
            backgroundColor="#ffffff"
            height="100%"
            [useBrowserLocale]="true"
            [handTool]="false"
            [showHandToolButton]="true" 
        >
        </ngx-extended-pdf-viewer>
        <img *ngIf="fileType=='IMAGE'"
            [src]="imagePreview"
            onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
            style="width: 100%"
          />
      </div>
    </div>
  </ng-template>






    