import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { environment } from "src/environments/environment";
import { FormGroup } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class WalletService {
  private localStorageCacheService: LocalStorageCacheService;

  constructor(
    private httpClient: HttpClient,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
  }
  private httpClientNoInterceptor: HttpClient;

  private baseAPIUrl = environment.API_URL;

  public getWallet(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/GetWallet`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getWalletByAdmin(id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/GetWalletByAdmin`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        managerId : id
      };

    return this.httpClient.post(`${endPointUrl}`, json ,{ headers: httpHeaders });
  }

  public getRewardSummary(id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/VIPCOTransactions/GetRewardSummary`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        id : id
      };

    return this.httpClient.post(`${endPointUrl}`, json ,{ headers: httpHeaders });
  }

  public getSMSBalance(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Multisys/SMSBalance`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
    .append("Authorization", "Bearer " + accessToken)
    .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }
  
  public getUGIBalance(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Multisys/UGIBalance`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
    .append("Authorization", "Bearer " + accessToken)
    .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }


  
  
  public getReportsTransactions(days : any, apiRoute : any,isEnterprise : boolean, provider : string): Observable<any> {
    const endPointUrl = isEnterprise ? `${this.baseAPIUrl}/api/Account/${apiRoute}/${days}/${provider}` : `${this.baseAPIUrl}/api/Account/${apiRoute}/${days}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getReportsTransactionsBillers(date : any, apiRoute : any,isEnterprise : boolean, provider : string): Observable<any> {
    const endPointUrl = isEnterprise ?  `${this.baseAPIUrl}/api/Account/${apiRoute}/Billers/${date}/${provider}`:  `${this.baseAPIUrl}/api/Account/${apiRoute}/Billers/${date}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }


  

  public getECPayBalance(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/getECPayBalance`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getCTIBalance(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/GetCTIBalance`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getCTIPinsBalance(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/GetCTIPinBalance`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getIBayadBalance(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/GetIBayadBalance`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getCSRCode(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/GetCSRCode`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  // tslint:disable-next-line: typedef
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  // tslint:disable-next-line: typedef
  private log(message: string) {
    //
  }
}
