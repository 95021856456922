import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';


import { CurrencyPipe, DatePipe } from '@angular/common';
import { PrintService } from 'src/data/services/web/print.service';
import { VipProtectService } from 'src/data/services/web/vip-protect.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { SunlifeGrepaService } from 'src/data/services/web/sunlife-grepa.service';
import { IglooService } from 'src/data/services/web/igloo.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UploadService } from 'src/data/services/web/upload.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5";

@Component({
  selector: 'app-igloo',
  templateUrl: './igloo.component.html',
  styleUrls: ['./igloo.component.less']
})
export class IglooComponent implements OnInit {

  @Input("application") application: any;
  iglooPolicyFormGroup : FormGroup;
  iglooCTPLPolicyFormGroup : FormGroup;

  holderFormGroup: FormGroup;
  insuredFormGroup: FormGroup;
  beneficiaryFormGroup: FormGroup;

  ctplHolderFormGroup: FormGroup;
  insuredVehicleFormGroup: FormGroup;

  formBuilder : FormBuilder;
  public emptyString = "";
  public billers : any = [];
  public biller : any;
  public billerCode : any;
  public billerTypes : any = [];
  public billersByBillerTypes : any = [];
  public selectedBillerCode : any;

  public activeTab = 1;
  public loader = true;
  public fee : any;
  public amount : any;

  @Output("closeModal") closeModal = new EventEmitter();
  
  constructor(
    private iglooService : IglooService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private uploadService: UploadService,
    localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    private modalService: NgbModal,
    formBuilder : FormBuilder,
    private webService: WebSettingsService,
    private datepipe: DatePipe) {
    this.formBuilder = formBuilder;
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.initIglooPolicyFormGroup();
    this.initIglooCTPLPolicyFormGroup();
    this.initIglooProducts();
    this.getInsurancePlan();
    this.initFilesFormGroup();
    this.loader = false;

    this.holderFormGroup = this.iglooPolicyFormGroup.controls.policyHolder as FormGroup;
    this.insuredFormGroup = this.iglooPolicyFormGroup.controls.insured as FormGroup;
    this.beneficiaryFormGroup = this.iglooPolicyFormGroup.controls.beneficiary as FormGroup;

    this.ctplHolderFormGroup = this.iglooCTPLPolicyFormGroup.controls.policyHolder as FormGroup;
    this.insuredVehicleFormGroup = this.iglooCTPLPolicyFormGroup.controls.insuredVehicle as FormGroup;
  }

  initIglooPolicyFormGroup(): void {
    const emptyString = this.emptyString;
  
    this.iglooPolicyFormGroup = new FormGroup({
      productType: new FormControl(emptyString, [Validators.required]),
      productKey: new FormControl(emptyString, [Validators.required]),
      planKey: new FormControl(emptyString, [Validators.required]),
      coveragePeriod: new FormControl(emptyString),
      partnerPolicyId: new FormControl(emptyString),
      amount: new FormControl(0.00, [Validators.required]),
      outletShare: new FormControl(0.00, [Validators.required]),
      // policyHolder: this.populateHolderFormGroup(),
      insured: this.populateInsuredFormGroup(),
      // beneficiary: this.populateBeneficiaryFormGroup(),
      // relationToInsured: new FormControl(emptyString, [Validators.required]),
      // extension: new FormControl(emptyString),
      pinCode: new FormControl(emptyString),
    });
  }
  
  populateHolderFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(0),
      fullName: new FormControl(this.emptyString, [Validators.required]),
      gender: new FormControl(this.emptyString),
      dateOfBirth: new FormControl(this.emptyString, [Validators.required]),
      occupation: new FormControl(this.emptyString),
      phoneAreaCode: new FormControl("+63"),
      phoneNumber: new FormControl(this.emptyString),
      email: new FormControl(this.emptyString, [Validators.required]),
      addressLine1: new FormControl(this.emptyString),
      addressLine2: new FormControl(this.emptyString),
      province: new FormControl(this.emptyString),
      city: new FormControl(this.emptyString),
      postcode: new FormControl(this.emptyString),
    });
  }

  populateInsuredFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(0),
      fullName: new FormControl(this.emptyString, [Validators.required]),
      gender: new FormControl(this.emptyString),
      dateOfBirth: new FormControl(this.emptyString, [Validators.required]),
      occupation: new FormControl(this.emptyString),
      // phoneAreaCode: new FormControl("+63"),
      // phoneNumber: new FormControl(this.emptyString),
      email: new FormControl(this.emptyString, [Validators.required]),
      // addressLine1: new FormControl(this.emptyString),
      // addressLine2: new FormControl(this.emptyString),
      // province: new FormControl(this.emptyString),
      // city: new FormControl(this.emptyString),
      // postcode: new FormControl(this.emptyString),
    });
  }

  populateBeneficiaryFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(0),
      fullName: new FormControl(this.emptyString, [Validators.required]),
      gender: new FormControl(this.emptyString),
      dateOfBirth: new FormControl(this.emptyString, [Validators.required]),
      occupation: new FormControl(this.emptyString),
      phoneAreaCode: new FormControl("+63"),
      phoneNumber: new FormControl(this.emptyString),
      email: new FormControl(this.emptyString, [Validators.required]),
      addressLine1: new FormControl(this.emptyString),
      addressLine2: new FormControl(this.emptyString),
      province: new FormControl(this.emptyString),
      city: new FormControl(this.emptyString),
      postcode: new FormControl(this.emptyString),
    });
  }

  initIglooCTPLPolicyFormGroup(): void {
    this.iglooCTPLPolicyFormGroup = new FormGroup({
      productType: new FormControl('CTPL', [Validators.required]),
      partnerPolicyId: new FormControl(this.emptyString),
      productKey: new FormControl('Compulsory Third Party Liability', [Validators.required]),
      planKey: new FormControl('Plan A', [Validators.required]),
      coveragePeriod: new FormControl('One year'),
      amount: new FormControl(0.00, [Validators.required]),
      outletShare: new FormControl(0.00, [Validators.required]),
      policyHolder: this.populatePolicyHolderFormGroup(),
      insuredVehicle: this.populateInsuredVehicleFormGroup(),
      extension: new FormControl(this.emptyString),
      pinCode: new FormControl(this.emptyString)
    });
  }

  populatePolicyHolderFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(0),
      // deliverHardCopy: new FormControl(true),
      // idNumber: new FormControl(this.emptyString),
      // idType: new FormControl(this.emptyString),
      fullname: new FormControl(this.emptyString, [Validators.required]),
      // firstName: new FormControl(this.emptyString, [Validators.required]),
      // lastName: new FormControl(this.emptyString, [Validators.required]),
      // middleName: new FormControl(this.emptyString),
      // gender: new FormControl(this.emptyString),
      // dateOfBirth: new FormControl(this.emptyString, [Validators.required]),
      // occupation: new FormControl(this.emptyString),
      // phoneAreaCode: new FormControl("+63"),
      // phoneNumber: new FormControl(this.emptyString),
      email: new FormControl(this.emptyString, [Validators.required]),
      addressLine1: new FormControl(this.emptyString),
      // addressLine2: new FormControl(this.emptyString),
      // province: new FormControl(this.emptyString),
      //city: new FormControl(this.emptyString),
      //postcode: new FormControl(this.emptyString),
    });
  }

  populateInsuredVehicleFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(0),
      bodyType: new FormControl(this.emptyString, [Validators.required]),
      certificateOfRegistration: new FormControl(this.emptyString),
      chassisNumber: new FormControl(this.emptyString, [Validators.required]),
      // color: new FormControl(this.emptyString, [Validators.required]),
      engineNumber: new FormControl(this.emptyString, [Validators.required]),
      make: new FormControl(this.emptyString, [Validators.required]),
      mvFileNumber: new FormControl(this.emptyString, [Validators.required]),
      officialReceipt: new FormControl(this.emptyString),
      plateNumber: new FormControl(this.emptyString, [Validators.required]),
      // series: new FormControl(this.emptyString, [Validators.required]),
      vehicleType: new FormControl(this.emptyString, [Validators.required]),
      yearModel: new FormControl(this.emptyString, [Validators.required])
    });
  }

  public fileFormGroup: FormGroup;

  initFilesFormGroup(): void {
    this.fileFormGroup = new FormGroup({
      certificateOfRegistration: new FormControl(this.emptyString, [Validators.required]),
      officialReceipt: new FormControl(this.emptyString , [Validators.required])
    });
  }

  productDetails: any[];  // Your JSON data
  productDetail: any[];

  insurancePlan: any;
  selectedProduct: string;
  selectedProductTypes: string[];
  selectedProductType: string;
  selectedProductTypePlans: any;

  insuranceAmount: any;
  walletDeduction: any = 0.00;

  genders: any[];
  relations: any[];
  idTypes: any[];
  vehicleTypes: any[];
  years: any[];
  initIglooProducts() {
    this.productDetails = [
      { id : "PA", value: "Personal Accident"},
      { id : "CTPL", value: "Compulsory Third Party Liability"}
    ]

    this.genders = [
      { id: "Male", value : "Male"},
      { id: "Female", value : "Female"}
    ]

    this.relations = [
      { id: "Spouse", value : "Spouse"},
      { id: "Child", value : "Child"},
      { id: "Parent", value : "Parent"},
      { id: "Sibling", value : "Sibling"}
    ]
    
    this.years = [];
    const currentYear = new Date().getFullYear(); // Get the current year
    const endYear = 2030; // Change this to the desired end year

    for (let year = 1990; year <= endYear; year++) {
      this.years.push({ id: year.toString(), value: year.toString() });
    }
    
    this.idTypes = [
      { id: "Passport", value : "Passport"},
      { id: "Driving License", value : "Driving License"},
      { id: "Social Security System (SSS)", value : "Social Security System (SSS)"},
      { id: "National ID", value : "National ID"},
      { id: "Postal ID", value : "Postal ID"},
      { id: "GSIS", value : "GSIS"},
      { id: "PhilHealth", value : "PhilHealth"}
    ]

    this.vehicleTypes = [
      { id: "Motorcycle / Tricycle", value : "Motorcycle / Tricycle"},
      { id: "Private Cars (Sedan, Pick-up, SUV, Van)", value : "Private Cars (Sedan, Pick-up, SUV, Van)"},
      { id: "Light and Medium Trucks", value : "Light and Medium Trucks"},
      { id: "Heavy Trucks", value : "Heavy Trucks"}
    ]
  }

  getInsurancePlan(){
    this.webService.getWebSettingsByIdentifier("Igloo_Plan").subscribe((result) => {
      this.insurancePlan = result.data;
    });
  }

  setSelectedProduct() {
    this.selectedProduct = this.iglooPolicyFormGroup.value.productType;
    this.selectedProductTypes = this.getProductTypes(this.selectedProduct);
    this.iglooPolicyFormGroup.patchValue({
      productKey: null,
      planKey: null
    });
  }

  setSelectedProductType() {
    this.selectedProductType = this.iglooPolicyFormGroup.value.productKey;
    this.selectedProductTypePlans = this.getProductTypePlans(this.selectedProductType);
    this.iglooPolicyFormGroup.patchValue({
      planKey: ''
    });
  }

  getProductTypes(productCode: string): string[] {
    if (productCode === 'PA') {
      this.selectedProductTypes = this.insurancePlan.slice(0, 3).map(item => item.parameter);
    } else {
      this.selectedProductTypes = [this.insurancePlan[3].parameter];
    }
    return this.selectedProductTypes;
  }

  getProductTypePlans(productType: string): string[] {
    let selectedType = this.insurancePlan.find(x => x.parameter === productType);
    if (selectedType) {
      this.selectedProductTypePlans = selectedType.value;
    } else {
      this.selectedProductTypePlans = [];
    }
    
    return this.selectedProductTypePlans;
  }

  setSelectedPlan(e) {
    const selectedPlanName = e.target.value;
    const selectedPlan = this.selectedProductTypePlans.find(plan => plan.plan === selectedPlanName);

    if (selectedPlan) {
      let percent = 0;
      if (this.selectedProduct !== "CTPL") {
        percent = 10;
      } else {
        percent = 15;
      }
      
      let acmShare = selectedPlan.amount * (percent / 100);
      let outletShare = acmShare * 0.7;
      let walletDeduction = selectedPlan.amount - outletShare;
      
      // Round up to 2 decimal places
      walletDeduction = Math.ceil(walletDeduction * 100) / 100;
      
      this.walletDeduction = walletDeduction;

      this.iglooPolicyFormGroup.controls.outletShare.setValue(outletShare);
      this.iglooPolicyFormGroup.controls.amount.setValue(selectedPlan.amount);
      this.iglooPolicyFormGroup.controls.coveragePeriod.setValue(selectedPlan.coveragePeriod);

      this.iglooCTPLPolicyFormGroup.controls.outletShare.setValue(outletShare);
      this.iglooCTPLPolicyFormGroup.controls.amount.setValue(selectedPlan.amount);
      this.iglooCTPLPolicyFormGroup.controls.coveragePeriod.setValue(selectedPlan.coveragePeriod);
    }
  }

  setActiveTab(){
    if(this.activeTab == 1){
       if(this.validateForm(this.iglooPolicyFormGroup.controls.productType.value) && this.validateForm(this.iglooPolicyFormGroup.controls.productKey.value) && this.validateForm(this.iglooPolicyFormGroup.controls.planKey.value)){
        if(this.iglooPolicyFormGroup.controls.productType.value == "CTPL"){
          this.activeTab = 4;
          this.loader = false;
        } else {
          this.activeTab++;
          this.loader = false;
        }
      } else {
        PNotify.error({
          title: "Error",
          text: "All fields are required to proceed to next step.",
        });
      }
    } else if(this.activeTab == 2){
      this.loader = true;
      if(this.iglooPolicyFormGroup.valid){
        this.iglooService.validatePayment(this.iglooPolicyFormGroup.value).subscribe((result) => {
          if(result){
            this.insuredFormGroup.controls.id.patchValue(result.item3[0].id);
            // this.beneficiaryFormGroup.controls.id.patchValue(result.item3[1].id);
            // this.holderFormGroup.controls.id.patchValue(result.item3[2].id);
            this.activeTab++;
            this.loader = false;
          } else {
            PNotify.error({
              title: "Request denied",
              text: result.item2,
            });
          }
        });
      } else {
        PNotify.error({
          title: "Request denied",
          text: "Please fill up all the required fields.",
        });
        this.loader = false;
      }
    } else if(this.activeTab == 4){
      // console.log(this.iglooCTPLPolicyFormGroup);
      // console.log(this.fileFormGroup);
      this.loader = true;
      if(this.iglooCTPLPolicyFormGroup.valid && this.fileFormGroup.valid){
        this.iglooService.validateCTPLPayment(this.iglooCTPLPolicyFormGroup.value, this.fileFormGroup.value).subscribe((result) => {
          if(result.item1){
            this.ctplHolderFormGroup.controls.id.patchValue(result.item3.id);
            this.insuredVehicleFormGroup.controls.id.patchValue(result.item4.id);
            this.insuredVehicleFormGroup.controls.certificateOfRegistration.patchValue(result.item4.certificateOfRegistration);
            this.insuredVehicleFormGroup.controls.officialReceipt.patchValue(result.item4.officialReceipt);

            this.activeTab++;
            this.loader = false;
          } else {
            PNotify.error({
              title: "Request denied",
              text: result.item2,
            });
          }
        });
      } else {
        this.loader = false;
        PNotify.error({
          title: "Request denied",
          text: "Please fill up all the required fields.",
        });
      }
    }
  }

  validateForm(formControl){
    if(formControl != null && formControl != ""){
      return true 
    } else {
      return false;
    }
  }

  setActiveTabPrev(){
    if(this.activeTab == 1){
      PNotify.error({
        title: "Error",
        text: "Action Denied.",
      });
    } else if(this.activeTab == 4){
      this.activeTab = 1;
    } else {
      this.activeTab--;
    }
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  codeStatus : boolean = false;
  code : any;
  
  onCodeCompleted(code: string) {
    this.code = code;
    this.codeStatus = code.length == 6;
  }

  
  processPayment(){
    this.loader = true;
    this.iglooPolicyFormGroup.controls.pinCode.patchValue(this.code);

    this.iglooService.processPayment(this.iglooPolicyFormGroup.value)
      .subscribe((result) => {
        PNotify.success({
          title: "Success",
          text: result.reason,
        });
        this.loader = false;
        this.code = "";
        this.codeStatus=false;
        this.closeModal.emit();
    }, error => {
      PNotify.error({
        title: "Failed",
        text: error.error.reason,
      });
      this.loader = false;
      this.code = "";
      this.codeStatus = false;
    });
  }
  
  processCTPLPayment(){
    this.loader = true;
    this.iglooCTPLPolicyFormGroup.controls.pinCode.patchValue(this.code);

    this.iglooService.processCTPLPayment(this.iglooCTPLPolicyFormGroup.value)
      .subscribe((result) => {
        PNotify.success({
          title: "Success",
          text: result.reason,
        });
        this.loader = false;
        this.code = "";
        this.codeStatus=false;
        this.closeModal.emit();
    }, error => {
      PNotify.error({
        title: "Failed",
        text: error.error.reason,
      });
      this.loader = false;
      this.code = "";
      this.codeStatus = false;
    });
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  totalAmount(fee:any,amount:any){
    try  {
    return this.makeMoney(Number(fee) + Number(amount));
    } catch(e){
      return "0.00";
    }
  }

  submitted = false;
  get f() { return this.insuredVehicleFormGroup.controls; }
  get fi() { return this.fileFormGroup.controls; }
  public fileName: any = "Click here to choose...";
  public base64Photo: string | ArrayBuffer;

  triggerFile(inputFile){
    inputFile.click();
  }

  public onUploadFile(event: any, formControlName : any) {
    const reader = new FileReader();
   
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.fileName = file.name;
      reader.onload = () => {
        
        this.base64Photo = reader.result as string;
        if(this.calculateImageSize(this.base64Photo)){
          this.fileFormGroup.controls[formControlName].patchValue(this.base64Photo);
          this.fileFormGroup.controls[formControlName].markAsTouched();
          this.changeDetectorRef.markForCheck();
          PNotify.info({
            title: "Certificate of Registration",
            text: "You need to next before the image reflects on view file.",
          });
        }else{
          PNotify.error({
                title: "Certificate of Registration",
                text: "File is too big. Must be less than or equal to 1mb.",
            });
        }
      };
    }
  }

  calculateImageSize(base64String) : boolean {
    let padding;
    let inBytes;
    let base64StringLength;
    if (base64String.endsWith('==')) { padding = 2; }
    else if (base64String.endsWith('=')) { padding = 1; }
    else { padding = 0; }

    base64StringLength = base64String.length;
    console.log(base64StringLength);
    inBytes = (base64StringLength / 4) * 3 - padding;
    return inBytes < 1000000;
  }

  viewModalRef: NgbModalRef;
  imagePreview: any;
  preview(content, image){
    this.imagePreview = image;
    this.viewModalRef = this.modalService.open(content, { centered: true,size:'xl', backdrop: 'static'});
  }
}

