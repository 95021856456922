import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { result } from 'lodash';
import { StoreItemService } from 'src/data/services/web/store-item.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.less']
})
export class AddToCartComponent implements OnInit {
  @Input() stockItem: any;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();  

  images = [];
  imageWidth = 64; // set the width of each image
  imageHeight = 68; // set the width of each image
  visibleImages = 3; // set the number of visible images
  currentIndex = 0; // set the initial index of the first visible image
  
  scroll(direction: number): void {
    const wrapper = document.querySelector('.slider-wrapper') as HTMLElement;
    const itemWidth = this.imageWidth + 4; // include margin-right
    const wrapperWidth = wrapper.offsetWidth;
    const maxIndex = this.images.length - this.visibleImages;
    let nextIndex = this.currentIndex + direction;
    if (nextIndex < 0) {
      nextIndex = 0;
    } else if (nextIndex > maxIndex) {
      nextIndex = maxIndex;
    }
    this.currentIndex = nextIndex;
    const transform = `translateX(-${this.currentIndex * itemWidth}px)`;
    wrapper.style.transform = transform;
  }

  isToUpdate: boolean = false;
  

  constructor(private router: Router, 
    private storeItemService: StoreItemService,
    private currencyPipe: CurrencyPipe
    ) { }

  ngOnInit(): void {
    this.initFormGroup();
    this.getCategories();
    this.getUoM();
    this.getConversion();

    this.cardImage = this.stockItem.storeItemImages[0].path;
    this.images = this.stockItem.storeItemImages;
  }

  stockItemFormGroup: FormGroup;
  initFormGroup(){
    this.stockItemFormGroup = new FormGroup({
      itemId: new FormControl(this.stockItem.id, [Validators.required]),
      quantity: new FormControl(1, [Validators.required])
    });
  }

  quantityChanged() {
    this.stockItemFormGroup.controls.quantity.patchValue(this.count);
  }

  count = 1;
  increment() {
    this.count++;
  }

  decrement() {
    if (this.count > 1) {
      this.count--;
    }
  }

  onInput(event: any) {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value)) {
      this.count = value;
    }
  }

  categories: any;
  getCategories(){
    this.storeItemService.getCategories().subscribe((result) => {
      //this.categories = result;
      this.categories = result.map((status) => {
        return {
          id: status.id,
          text: status.category,
        };
      });
    });
  }

  uom: any;
  getUoM(){
    this.storeItemService.getUoM().subscribe((result) => {
      this.uom = result.map((status) => {
        return {
          id: status.id,
          text: status.abrUnit,
        };
      });
    });
  }

  itemConversion: any;
  getConversion(){
    this.storeItemService.getConversion(this.stockItem.id).subscribe((result) => {
      this.itemConversion = result;
    });
  }

  setoUpdate(){
    this.isToUpdate = true;
  }

  saveChange(){
    this.isToUpdate = false;
  }

  cardImage: any;
  changeBigImage(image: any){
    this.cardImage = image;
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  addToCart(){
    //console.log(this.stockItemFormGroup.value);
    this.storeItemService.addToCartPost(this.stockItemFormGroup.value).subscribe((result) => {
      if(result){
        PNotify.success({
          title: "Add to Cart",
          text: "Item Successfully Added",
        });
        this.closeModal.emit();
      } else {
        PNotify.error({
          title: "Add to Cart",
          text: "Something went wrong!",
        });
      }
    });
  }

  buyNow(){
    var json = [{ ItemID: this.stockItemFormGroup.controls.itemId.value, Quantity: this.stockItemFormGroup.controls.quantity.value }];

    this.storeItemService.checkOut(json).subscribe((result) => {
      if(result != null){
        this.closeModal.emit();
        this.router.navigate(["manager", "checkout"]);
      }
    });
  }
}
