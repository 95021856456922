import { EventEmitter, Inject, Injectable } from "@angular/core";

@Injectable()
export class EmitterService {
  public reloadWalletEmitter: EventEmitter<boolean> = new EventEmitter();

  //method to get question
  reloadWallet(status: boolean) {
    this.reloadWalletEmitter.emit();
  }
}
