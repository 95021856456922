import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  private localStorageCacheService: LocalStorageCacheService;
  httpClientNoInterceptor: HttpClient;

  constructor(
    private httpClient: HttpClient,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
  }

  private baseAPIUrl = environment.API_URL;


  public async FileUploadOutSingle(fileUpload:any) : Promise<any>{
    const endPointUrl = `${this.baseAPIUrl}/api/FileUpload/Out/Single`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.post(endPointUrl, fileUpload , { headers: httpHeaders })
    .toPromise();
  }

  public async UploadFileSingle(fileUpload:any) : Promise<any>{
    const endPointUrl = `${this.baseAPIUrl}/api/FileUpload/Single`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.post(endPointUrl, fileUpload , { headers: httpHeaders })
    .toPromise();
  }

  

  public async GetFileOutSingle(id:any) : Promise<any>{
    const endPointUrl = `${this.baseAPIUrl}/api/FileUpload/Out/GetFile/${id}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders })
    .toPromise();
  }

  
  public async GetFileRequirements(username:any, filename:any) : Promise<any>{
    const endPointUrl = `${this.baseAPIUrl}/api/FileUpload/GetFileRequirements`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      var json = {
          username : username,
          filename : filename
      }

      return this.httpClient.post(endPointUrl, json , { headers: httpHeaders })
    .toPromise();
  }


  
  

  public PostFileUpload(fileUpload:any, userID: any, fileName: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/FileUpload/UploadFile`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      fileUpload.managerUserId = userID; 
      fileUpload.fileName = fileName;

    return this.httpClient.post(endPointUrl, fileUpload , { headers: httpHeaders });
  }

  public PostFileUploadManager(fileUpload:any, userID: any, filename: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/FileUpload/UploadFileByApplication`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      fileUpload.managerUserId = userID;
      fileUpload.filename = filename;
      fileUpload.purpose = "For Requirements";

    return this.httpClient.post(endPointUrl, fileUpload , { headers: httpHeaders });
  }

  public getSingleFile(url: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/FileUpload/SingleFile/${url}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getBase64ImageFromURL(fileName: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/FileUpload/Base64ImageFromURL`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    var json = { url : fileName };

    return this.httpClient.post(`${endPointUrl}`, json ,{ headers: httpHeaders });
  }
  
  public getBase64AsImageFromURL(fileName: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/FileUpload/Base64ImageAsFromURL`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    var json = { url : fileName };

    return this.httpClient.post(`${endPointUrl}`, json ,{ headers: httpHeaders });
  }

  public uploadCustomImage(file: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/FileUpload/UploadCustomImage`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    var json = { file : file , folder_name : "govipstore"};

    return this.httpClient.post(`${endPointUrl}`, json ,{ headers: httpHeaders });
  }

  public getManagerFiles(managerUserId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/FileUpload/ManagerFiles/${managerUserId}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public removeManagerFile(fileID: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/FileUpload/RemoveManagerFile/${fileID}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.delete(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getAllManagerFiles(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/FileUpload/ManagerAllFiles`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  // tslint:disable-next-line: typedef
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  // tslint:disable-next-line: typedef
  private log(message: string) {
    //
  }

}
