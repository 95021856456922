<app-loader *ngIf="loader"></app-loader>
<div class="container-fluid --home">
    <div class="row">
        <div class="col-12">
            <div class="header mt-3">
                <div class="text">
                    <img src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file260fa82a-0150-4c07-a413-e289dffd98fb.png" width="65px" height="65px" alt=""> 
                    LIST OF VIPS RIDERS
                </div>
            </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 pl-5 pr-4 mb-3" style="max-height: 290px;">
          <div class="box p-0 m-0">
            <app-widget-basic-profile></app-widget-basic-profile>
          </div>
        </div>
        <div class="col-lg-7 col-md-12 col-sm-12 pl-5 pr-5">
            
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 pl-5 pr-5">
            <div class="row box mt-3">
                <div class="col-12 pt-2">
                    <h5>Table for Vips User - Total Linked Riders (Registered) : {{users.length}}</h5>
                    <div class="row mt-2 p-2">
                        <div class="col-lg-5 col-md-5 col-sm-12"></div>
                        <div class="col-lg-7 col-md-7 col-sm-12 pr-0">
                            <div class="input-group">
                                <input type="search" class="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                                <button type="button" class="btn btn-outline-primary">Search</button>
                            </div>
                        </div>
        
                        <div class="col-12 mt-3"  style="max-height: calc(100vh - 500px); overflow-y: scroll;">
                            <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
                                <thead>
                                  <tr>
                                    <th data-sortable="true" data-field="name">
                                      <div>Date</div>
                                    </th>
                                    <th data-sortable="true" data-field="value">
                                      <div>User Type</div>
                                    </th>
                                    <th data-sortable="true" data-field="description">
                                      <div>Mobile No</div>
                                    </th>
                                    <th data-sortable="true" data-field="description">
                                        <div>Full Name</div>
                                    </th>
                                    <th data-sortable="true" data-field="description">
                                      <div>Rider Balance</div>
                                    </th>
                                    <th data-field="finish">
                                        <span class="d-none d-sm-block">Rider Enable</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    *ngFor="let user of users | sortDesc:'Createdate'" 
                                    (click)="viewUser(user?.id)"
                                    style="cursor: pointer"
                                    class="odd"
                                    role="row"
                                  >
                                    <td class="flex">
                                      <div class="item-except text-sm h-1x">
                                        {{ user?.Createdate | date: "dd-MMM-yyyy"}}
                                      </div>
                                    </td>
                                    <td class="flex">
                                      <div class="item-except text-sm h-1x">
                                        {{ getUserType(user?.Usertype) }}
                                      </div>
                                    </td>
                                    <td class="flex">
                                      <div class="item-except text-sm h-1x">
                                        {{ user?.MobileNo }}
                                      </div>
                                    </td>
                                    <td class="flex">
                                        <div class="item-except text-sm h-1x">
                                          {{ user?.FullName }}
                                        </div>
                                      </td>
                                      <td class="flex">
                                        <div class="item-except text-sm h-1x">
                                          {{ getVipcoWallet(user?.WalletAccounts) }}
                                        </div>
                                      </td>
                                    <!-- <td class="flex">
                                        <div class="item-except text-sm h-1x">
                                          {{ user?.creditEnable }}
                                      </div>
                                    </td> -->
                                    <td class="flex" style="max-width: 75px" *ngIf="user?.application_status == true">
                                        <label *ngIf="user?.creditEnable == true" class="ui-switch ui-switch-sm mt-1 mr-2">
                                          <input type="checkbox" (change)="onChange(user?.MobileNo, $event)" checked />
                                          <i></i>
                                        </label>
                    
                                        <label *ngIf="user?.creditEnable != true" class="ui-switch ui-switch-sm mt-1 mr-2">
                                          <input type="checkbox" (change)="onChange(user?.MobileNo, $event)"  />
                                          <i></i>
                                        </label>
                                    </td>

                                    <td class="flex" style="max-width: 75px" *ngIf="user?.application_status != true">
                                      <div class="item-except text-sm h-1x">
                                        Not Verified
                                      </div>
                                    </td>
                    
                                    <!-- <td class="flex" style="max-width: 75px">
                                      <div class="item-except">
                                        <label *ngIf="user?.status == true">
                                       Active
                                        </label>
                    
                                        <label *ngIf="user?.status != true">
                                         
                                          Inactive
                                        </label>
                                      </div>
                                    </td> -->
                                  </tr>
                                </tbody>
                              </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div #govipxQR hidden>
    <ngx-qrcode
      [elementType]="elementType"
      [errorCorrectionLevel]="correctionLevel"
      [value]="value"
      cssClass="bshadow"
      [width]="300"
      style='margin-left:21px; margin-top:290px; border-radius: 2%'
    ></ngx-qrcode>
  </div>
  
  <div #vipLogo hidden>
    <img src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file9828bf15-729f-4650-97a6-476c13f02ce0.png" alt="Go-VIP-Logo-Logo-Small" />
  </div>
  
  <ng-template #myQRTemplateModal let-modal>
    <!-- background: url(../../../assets/images/png\ QR.png) !important 
    linear-gradient(#044660, #02554c)-->
    
    <button class="btn btn-dark btn-lg" (click)="getQR(myQRTemplate)">Print &nbsp; <i class="fa fa-print"></i></button>
    <div class="d-flex justify-content-center">
      <div #myQRTemplate >
          <div style="background: url(../../../assets/images/png\ QR.png);
          background-repeat: no-repeat !important;
          background-size: 210mm 297mm !important;
          width: 210mm !important;
          height: 297mm !important;" 
          >
            <div style='display:flex; justify-content: center'>
              <ngx-qrcode
                [elementType]="elementType"
                [errorCorrectionLevel]="correctionLevel"
                [value]="value"
                cssClass="bshadow"
                [width]="300"
                style='margin-left:21px; margin-top:290px; border-radius: 2%'
              ></ngx-qrcode>
            </div>
            <div style='display:flex; justify-content: center; padding-top: 45px'>
                <h3 
                style='margin-left:27px; font-family: "Segoe UI", Arial, sans-serif; max-width: 360px; text-align:center; font-weight: 700'
                >
                    {{dti}}
                </h3>
            </div>
          </div>
        </div>
    </div>
  </ng-template>