import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { AuthService } from 'src/data/services/authentication/auth.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-pin-enroll',
  templateUrl: './pin-enroll.component.html',
  styleUrls: ['./pin-enroll.component.less']
})

export class PinEnrollComponent implements OnInit {

  @Input("email") email: any;
  @Input("password") password: any;
  @Output("showLoginPin") showLoginPin = new EventEmitter();
 public step = 1;

  constructor(private authService: AuthService) { 
    PNotifyButtons;
  }

  ngOnInit(): void {
  }

  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  codeStatus : boolean = false;
  code : any = "";
  reEnterCode : any = "";
  onCodeCompleted(code: string) {
    this.code = code;
  }

  onReEnterCodeCompleted(reEnterCode: string) {
    this.reEnterCode = reEnterCode;
  }

  proceedToNextStep(){
    PNotify.info({
      title: "PIN Nomination",
      text: "Please re-enter your PIN Code.",
    });
    this.step = 2;
  }
public apiMessage;
  public loader = false;
  enrolPin(){

      if(this.code == this.reEnterCode){
   
          this.loader = true;
      
          this.authService.setPinCode(this.email, this.password,this.code).subscribe(
            (token: any) => {
              if(token.result.status == 8){
                this.loader = false;
                PNotify.success({
                  title: "PIN Code Enrollment",
                  text: token.result.message,
                });
                this.showLoginPin.emit();
                this.apiMessage = token.result.message;
                this.step = 3;
              }
              else {
                this.apiMessage = token.result.message;
                this.step = 3;
                this.loader = false;
              }
            },
            (error) => {
              this.loader = false;
              if (error.status === 401 || error.status === 403 || error.status === 400) {
                PNotify.notice({
                  title: "PIN Code Enrollment",
                  text: error.error.result.message,
                });
              }
            }
          );

      }else{

        PNotify.notice({
          title: "PIN Re-Enter",
          text: "Pin code did not match. Please try again.",
        });
        this.reEnterCode = "";
      }
     
  }

  goBack(){
    this.code = "";
    this.reEnterCode = "";
    this.step =1;
  }
  close(){
    this.showLoginPin.emit();
  }

}
