<app-loader *ngIf="loader"></app-loader>
<div class="modal-body" *ngIf="activeTab != 4">
  <form id=" form" novalidate="">
    <div id="rootwizard">
      <ul class="nav mb-3">
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 1 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >1</span
            >
            <div class="mt-2">
              <div class="text-muted">New Transfer</div>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 2 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >2</span
            >
            <div class="mt-2">
              <div class="text-muted">Confirmation</div>
            </div>
          </a>
        </li>

        <li  class="nav-item" style="margin-left: auto">
          <a class="nav-link text-center" [ngClass]="activeTab == 3 ? 'active' : ''">
            <img [src]="application.value.thumbnail.logo" height="50" class="img-responsive" style="object-fit: contain" />
          </a>
        </li>
      </ul>

      <hr class="bg-dark" />

      <div class="tab-content p-3">
        <div class="tab-pane" [ngClass]="activeTab == 1 ? 'active' : ''" id="selectBillerTab">
          <div class="row">
            <!-- <div class="col-sm-12 col-md-2 d-flex justify-content-center align-items-center">
              <img
                [src]="application.value.thumbnail.logo"
                height="100"
                width="100"
                class="img-responsive"
                style="object-fit: cover"
              />
            </div> -->
            <div class="col-sm-12 col-md-12 d-flex flex-wrap">
              <h4>{{ application.value.thumbnail.title }}</h4>
              <p>
                {{ application.value.acquired.description }}
              </p>
              
            <hr/>
            </div>
            <div class="col-sm-12 col-md-2"></div>
            <div class="col-sm-12 col-md-8" [formGroup]="billerFormGroup">
              <div class="form-group">
                <label>Select Bank </label>
                <select
                  formControlName="billerCode"
                  class="form-control"
                  tabindex="-1"
                  aria-hidden="true"
                >
                  <option value="">Choose...</option>
                  <option *ngFor="let biller of billers" [value]="biller.code">
                    {{ biller.name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <app-textbox
                  [parent-form-group]="billerFormGroup"
                  tab-index-start="0"
                  control-name="senderMobileNumber"
                  [read-only]="false"
                  [error]="billerFormGroup?.controls.senderMobileNumber.errors | estatus"
                  read-only="false"
                  alpha-numeric="false"
                  label="Sender's Mobile No."
                  placeholder="639XXXXXXXXX"
                  max-length="100"
                >
                </app-textbox>
              </div>

              <div class="form-group">
                <div  class="form-group row">
                <div class="col-sm-3">
                <label>Biller Fee: </label>
            </div>
                <div class="col-sm-9">
                <input type="text" class="form-control " tabindex="-1" aria-hidden="true" value="{{billerFormGroup?.controls.billerFee.value | number: '1.2-2'}}" readonly="read-only"/>
              </div></div>
            </div>


              <div class="form-group">
                <app-textbox-currency
                [parent-form-group]="billerFormGroup" 
                tab-index-start="0"
                control-name="amount"
                [read-only]="false"
                [error]="billerFormGroup?.controls.amount.errors | estatus"
                number-only="false"
                read-only="false"
                label="Amount"
                placeholder="0.00"
                max-length="10"
              >
              </app-textbox-currency>
              </div>
            

              <div class="form-group">
                <app-textbox
                  [parent-form-group]="billerFormGroup"
                  tab-index-start="0"
                  control-name="accountNumber"
                  [read-only]="false"
                  [error]="billerFormGroup?.controls.accountNumber.errors | estatus"
                  read-only="false"
                  alpha-numeric="false"
                  label="Account Number"
                  placeholder="Account Number"
                  max-length="20"
                >
                </app-textbox>
              </div>

              <div class="form-group">
                <app-textbox
                  [parent-form-group]="billerFormGroup"
                  tab-index-start="0"
                  control-name="accountName"
                  [read-only]="false"
                  [error]="billerFormGroup?.controls.accountName.errors | estatus"
                  read-only="false"
                  label="Account Name"
                  placeholder="Account Name"
                  max-length="100"
                >
                </app-textbox>
              </div>

              <div class="form-group">
                <app-textbox
                  [parent-form-group]="billerFormGroup"
                  tab-index-start="0"
                  control-name="recipientMobileNumber"
                  [read-only]="false"
                  [error]="billerFormGroup?.controls.recipientMobileNumber.errors | estatus"
                  read-only="false"
                  alpha-numeric="false"
                  label="Recipient's Mobile No."
                  placeholder="639XXXXXXXXX"
                  max-length="100"
                >
                </app-textbox>
              </div>


            
              

            </div>
            <div class="col-sm-12 col-md-2"></div>

            <hr/>
           


          </div>
        </div>

        <div class="tab-pane" [ngClass]="activeTab == 2 ? 'active' : ''" id="confirmationTab">
          <div class="form-group">
            <p>
              <strong>Transfer Confirmation</strong><br />
              <small>Please make sure that the all details is correct.</small>
            </p>
            <div class="card">
              <div class="list list-row">
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Bank</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">{{ billerFormGroup?.controls.billerCode.value }}</div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date text-muted text-sm d-none d-md-block">Sender's Mobile No.</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">{{ billerFormGroup?.controls.senderMobileNumber.value }}</div>
                    </div>
                  </div>
                  
                <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date text-muted text-sm d-none d-md-block">Transfer Fee</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">{{ billerFormGroup?.controls.billerFee.value  | number: '1.2-2'  }}</div>
                    </div>
                  </div>

                <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date text-muted text-sm d-none d-md-block">Amount</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">{{ billerFormGroup?.controls.amount.value  | number: '1.2-2'}}</div>
                    </div>
                  </div>
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Account Number</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">{{ billerFormGroup?.controls.accountNumber.value }}</div>
                  </div>
                </div>
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Account Name</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">{{ billerFormGroup?.controls.accountName.value }}</div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date text-muted text-sm d-none d-md-block">Recipient's Mobile No.</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">{{ billerFormGroup?.controls.recipientMobileNumber.value }}</div>
                    </div>
                  </div>

                
               
                <hr />
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Total Amount</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ totalAmount(billerFormGroup?.controls.billerFee.value, billerFormGroup?.controls.amount.value) }}
                    </div>
                  </div>
                </div>
                <hr />

                <div class="list-item" data-id="19">
                  <div class="flex">
                   
                    <label for="inputFile" class="custom-file-upload btn btn-primary">
                      Click Here to Upload Security Image
                    </label>
                    <input type="file" (change)="onPhotoChange($event)" class="custom-file-input" id="inputFile" />


                  </div>
                  <div class="no-wrap">
                  
                     
                      <img
                      *ngIf="fileUploadFormGroup?.controls.photoFile.value"
                      [src]="fileUploadFormGroup?.controls.photoFile.value | authImage | async"
                      alt="VIP"
                      onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
                      style="width: 50px"
                    />
                    
                  </div>
                </div>

            


                <div class="col-sm-12 col-md-12">
                  <div class="col-sm-12 col-md-6" style="float: none; margin: auto">
                    <div class="item-feed h-2x text-center">ENTER PINCODE</div>
                    <code-input
                      [isCodeHidden]="true"
                      [codeLength]="6"
                      (codeChanged)="onCodeChanged($event)"
                      (codeCompleted)="onCodeCompleted($event)"
                      [code]="code"
                    >
                    </code-input>
                  </div>
                </div>
               
              
                <br />
              </div>
            </div>
          </div>
        </div>

        <!-- previous next -->
        <div class="row py-3">
          <div class="col-6">
            <a class="btn btn-white button-previous" (click)="setActiveTabPrev()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-arrow-left"
              >
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
              &nbsp; Previous
            </a>
          </div>
          <div class="col-6">
            <div class="d-flex justify-content-end">
              <a *ngIf="activeTab != 2" class="btn btn-white button-next" (click)="setActiveTab()">
                Next &nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-arrow-right"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </a>
              <!-- <a
                *ngIf="activeTab == 2 && codeStatus"
                (click)="processBankTransfer()"
                class="btn btn-info button-next"
                style="color: white"
              >
                Process Transfer &nbsp;
                <span class="fa fa-hand-pointer"></span>
              </a> -->


              <a *ngIf="activeTab == 2 &&  codeStatus && base64Photo" (click)="requestOtp()" class="btn btn-info button-next"
                style="color: white;" >
                Request OTP &nbsp;
                <span class="fa fa-hand-pointer"></span>
              </a>



            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>


<ng-template #pinModal let-modal >
  <div class="bg-dark">
    <div class="modal-header justify-content-center">
      <img src="https://i.ibb.co/vsQZ3MC/Go-VIP-Logo.png" height="29.12" width="59.68" alt="Go-VIP-Logo" />
      <h4 class="modal-title ml-5">ENTER MOBILE OTP</h4>
      <!-- <img src="https://i.ibb.co/vsQZ3MC/Go-VIP-Logo.png" height="101.92" width="208.88" alt="Go-VIP-Logo" /> -->
      <button type="button" class="close" aria-label="Close" (click)="closeTransaction()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <!-- <app-pin (doLoginPin)="processPayment($event)" [code]="otp"></app-pin> -->
  <div class="modal-body">
    <div class="col-sm-12 col-md-12 p-3">
      <div class="col-sm-12 col-md-12" style="float: none; margin: auto">
        <!-- <div class="text-center">
          <h3>ENTER SECURITY PASSCODE</h3>
        </div> -->
        <code-input
          [isCodeHidden]="false"
          [codeLength]="6"
          (codeChanged)="onCodeChangedOtp($event)"
          (codeCompleted)="onCodeCompletedOtp($event)"
          [code]="otp"
          isCharsCode="true"
        >
        </code-input>
        <br/>
      
        <a *ngIf="activeTab == 2 &&  otpStatus" appDebounceClick [debounceTime]="500" (debounceClick)="processBankTransfer(otp)" class="btn btn-info button-next"
        style="color: white;float: right;" >
        Process Transfer &nbsp;
        <span class="fa fa-hand-pointer"></span>
      </a>
      </div>
    </div>
  </div>
</ng-template>