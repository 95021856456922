<app-loader *ngIf="!webSettings"></app-loader>

<div id="content" class="flex" *ngIf="webSettings">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">List of Web Settings</h2>
          <small class="text-highlight font-weight-normal font-italic">JuanPay list of web settings</small>
        </div>
        <div class="flex"></div>
        <div>
          <div (click)="createWebSettings()" class="btn btn-md btn-info">
            <span class="d-none d-sm-inline mx-1">Create New</span>
            <i data-feather="arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="padding" style="padding-right: 0px">
        <div class="card mb-2 p-1" style="background-color: #182038">
          <div class="row">
            <div class="col-sm-12 col-md-7"></div>
            <div class="col-sm-12 col-md-5" style="padding-left: 0px">
              <div class="flex">
                <div class="input-group">
                  <input
                    type="text"
                    [(ngModel)]="searchString"
                    class="form-control form-control-theme form-control-sm search"
                    placeholder="Search"
                    style="background-color: white; color: black"
                  />
                  <span class="input-group-append">
                    <button
                      (click)="getWebSettings(null, null, null, true)"
                      class="btn btn-white no-border btn-sm"
                      type="button"
                      style="background-color: gainsboro"
                    >
                      <span class="d-flex text-muted"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-search"
                        >
                          <circle cx="11" cy="11" r="8"></circle>
                          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                        </svg>
                      </span>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card mt-2">
          <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
            <thead>
              <tr>
                <th data-sortable="true" data-field="name">
                  <div [ngClass]="name" (click)="setPageSorting('name')">Name</div>
                </th>
                <th data-sortable="true" data-field="value">
                  <div [ngClass]="value" (click)="setPageSorting('value')">Identifier</div>
                </th>
                <th data-sortable="true" data-field="description">
                  <div [ngClass]="description" (click)="setPageSorting('description')">Description</div>
                </th>

                <th data-field="finish"><span class="d-none d-sm-block">Status</span></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let webSetting of webSettings?.data"
                (click)="editWebSettingsProfile(webSetting?.id)"
                style="cursor: pointer"
                class="odd"
                role="row"
              >
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ webSetting?.name }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ webSetting?.value }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ webSetting?.description }}
                  </div>
                </td>

                <td class="flex" style="max-width: 75px">
                  <div class="item-except text-sm h-1x">
                    <label *ngIf="webSetting?.status == true" class="ui-switch ui-switch-sm dark mt-1 mr-2">
                      <input type="checkbox" checked disabled />
                      <i></i>
                    </label>

                    <label *ngIf="webSetting?.status != true" class="ui-switch ui-switch-sm dark mt-1 mr-2">
                      <input type="checkbox" disabled />
                      <i></i>
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
              <div class="col-sm-12 col-md-12">
                <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                  Showing {{ (webSettings?.pageNumber - 1) * webSettings?.pageSize + 1 }} to
                  {{ (webSettings?.pageNumber - 1) * webSettings?.pageSize + webSettings?.data?.length }} of
                  {{ webSettings?.data?.length }} entries
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-7" style="float: right">
            <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
              <ul class="pagination" style="float: right; margin-right: 36px">
                <!-- <li (click)="getWebSettings(managerUsers?.next_page_url)" class="paginate_button page-item previous disabled" id="datatable_previous"><a 
                    aria-controls="datatable" data-dt-idx="0" tabindex="0" class="page-link">Previous</a></li> -->

                <li (click)="getWebSettings(webSettings?.previousPage)" class="paginate_button page-item">
                  <a class="page-link">&laquo; Previous</a>
                </li>

                <!--   -->
                <li
                  *ngFor="let page of totalPages(webSettings?.totalPages)"
                  (click)="getWebSettings(buildPageUrl(page + 1, webSettings.pageSize))"
                  [ngClass]="getPageNumberClass(page + 1)"
                >
                  <a class="page-link" [innerHTML]="page + 1"></a>
                </li>
                <!-- active -->

                <li (click)="getWebSettings(webSettings?.nextPage)" class="paginate_button page-item">
                  <a class="page-link">Next &raquo;</a>
                </li>

                <!-- <li (click)="getWebSettings(managerUsers?.prev_page_url)" class="paginate_button page-item next" id="datatable_next"><a  aria-controls="datatable"
                    data-dt-idx="3" tabindex="0" class="page-link">Next</a></li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>
