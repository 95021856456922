import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Select2OptionData } from "ng-select2";
import { BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { ExportExcelService } from "src/data/services/web/export-excel.service";
import { ManagerUsersService } from "src/data/services/web/manager-users.service";
import { RolesService } from "src/data/services/web/roles.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-manager-user-list",
  templateUrl: "./manager-user-list.component.html",
  styleUrls: ["./manager-user-list.component.less"],
})
export class ManagerUserListComponent implements OnInit {
  public managerUserFilterFormGroup: FormGroup;
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public managerUsersService: ManagerUsersService;
  public emptyString = "";
  public roles: any;
  public role: any;
  public statuses: any;
  public status: any;
  public modalService: BsModalService;

  public accessToken: string;
  public newManagerUser: boolean;
  public users: any;
  public usersExcel: any = [];
  public pageCount: any;
  public roleId: any;
  dataForExcel = [];
  // Filters
  public listFilterForm = new FormGroup({
    searchString: new FormControl(""),
    searchStringField: new FormControl(""),
  });
  public filterByOptions: Array<Select2OptionData>;
  public filterBy: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    managerUsersService: ManagerUsersService,
    modalService: BsModalService,
    private modService: NgbModal,
    public ete: ExportExcelService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.managerUsersService = managerUsersService;
    this.modalService = modalService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
  }

  ngOnInit(): void {
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    this.rolesService.getRoles(2).then((roles) => {
      this.roles = roles;
      this.getManagerUsers();
    });

    this.filterByOptions = [
      {
        id: "",
        disabled: false,
        text: "",
      },
      {
        id: "employeeID",
        text: "Employee ID",
      },
      {
        id: "username",
        text: "Username",
      },
      {
        id: "email",
        text: "Email",
      },
      {
        id: "firstName",
        text: "First Name",
      },
      {
        id: "lastName",
        text: "Last Name",
      },
      {
        id: "roleName",
        text: "Role Name",
      },
    ];
  }

  private _sortBy: any;
  private _sort: any;

  getManagerUsers(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    if (fromSearch) {
      this.managerId = this.emptyString;
      this.username = this.emptyString;
      this.email = this.emptyString;
      this.firstName = this.emptyString;
      this.lastName = this.emptyString;
      this.roleName = this.emptyString;
    }

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_searchString: string = this.listFilterForm.get("searchString").value
      ? `searchString=${this.listFilterForm.get("searchString").value}&`
      : "";
    const queryString_filterBy: string = this.filterBy ? `filterBy=${this.filterBy}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_searchString + queryString_filterBy;

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/managerusers?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/managerusers${queryString}`;
      }
    }

    this.managerUsersService.getManagerUsers(endPointUrl).subscribe((result) => {
      if (result.data.length == 0 && result.pageNumber != 1) {
        this.getManagerUsers(result.previousPage, _sortBy, _sort);
      } else {
        this.users = result;
        
      }
    });
  }

  loader: any = false;
  getManagerUsersForExcel(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader = true;
    if (fromSearch) {
      this.managerId = this.emptyString;
      this.username = this.emptyString;
      this.email = this.emptyString;
      this.firstName = this.emptyString;
      this.lastName = this.emptyString;
      this.roleName = this.emptyString;
    }

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_searchString: string = this.listFilterForm.get("searchString").value
      ? `searchString=${this.listFilterForm.get("searchString").value}&`
      : "";
    const queryString_filterBy: string = this.filterBy ? `filterBy=${this.filterBy}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_searchString + queryString_filterBy;

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/managerusers/Excel?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/managerusers/Excel${queryString}`;
      }
    }

    this.managerUsersService.getManagerUsers(endPointUrl).subscribe((result) => {
      if (result.data.length == 0 && result.pageNumber != 1) {
        this.getManagerUsersForExcel(result.previousPage, _sortBy, _sort);
      } else {
        //debugger
        this.generateExcel(result);
      }
    });
  }

  generateExcel(user: any): void {
    // this.usersExcel = [];
    // debugger
    //   for(var i = 0; i < user.length; i++){
    //     let meta = [
    //       {
    //           "Owner's Full Name": user[i].managerUser?.firstName + ' ' + user[i].managerUser?.lastName,
    //           "Email Address": user[i].email,
    //           "Contact Number":	user[i].managerUser.contactNumber,
    //           "Username": user[i].username,
    //           "VIP Activation Date": user[i].managerUser?.storeActivation?.enterpriseActivationDate,
    //           "Migration / Creation Date": user[i].createdDate,
    //           "Sponsor Name": user[i].managerUser?.storeActivation?.sponsor,
    //           "DTI Name": user[i].managerUser?.storeSetting?.name,
    //           "Complete Address":	user[i].managerUser?.address,
    //           "City":	user[i].managerUser?.storeAddress?.city,
    //           "Municipality":	user[i].managerUser?.storeAddress?.municipality,
    //           "Region":	user[i].managerUser?.storeAddress?.region,
    //           "Store Type":	user[i].managerUser?.storeActivation?.storeType,
    //           "Latitude":	user[i].managerUser?.storeAddress?.latitude,
    //           "Longitude":	user[i].managerUser?.storeAddress?.longtitude
    //       }
    //     ]

    //     this.usersExcel.push(meta);
    //   }

    for(var x = 0; x < user.totalRecords; x++){
      // this.dataForExcel.push(Object.values(this.usersExcel[x][0]));
      this.dataForExcel.push(Object.values(user.data[x]));
    }

    let reportData = {
      title: 'Manager Data',
      data: this.dataForExcel,
      headers: Object.keys(user.data[0])
    }

    this.loader = false;
    this.ete.exportExcel(reportData, "Manager Data");
  }

  managerId = "";
  username = "";
  email = "";
  firstName = "";
  lastName = "";
  roleName = "";
  searchString = "";

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.managerId = field == "managerId" ? "th-inner sortable both " + sortBy : "";
    this.username = field == "username" ? "th-inner sortable both " + sortBy : "";
    this.email = field == "email" ? "th-inner sortable both " + sortBy : "";
    this.firstName = field == "firstName" ? "th-inner sortable both " + sortBy : "";
    this.lastName = field == "lastName" ? "th-inner sortable both " + sortBy : "";
    this.roleName = field == "roleName" ? "th-inner sortable both " + sortBy : "";
   
    this.getManagerUsers(
      `${environment.API_URL}/api/managerusers?pageNumber=${page ? page : this.users.pageNumber}`,
      this._sortBy,
      this._sort
    );
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.users?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }

  // tslint:disable-next-line: variable-name
  getRole(role_id: any): string {
    if (role_id) {
      return this.roles.filter((x) => x.id === role_id)[0].name;
    } else {
      return "No Role";
    }
  }
p:Number =1;
  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p = pageNumber;
    return `${environment.API_URL}/api/managerusers?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  buildUserPhoto(user: any): any {
    return user.managerUser.photoFormat + "," + user.managerUser.photo;
  }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }

  viewManagerUserProfile(id: any): void {
  
    const encId = this.cryptoService.set(this.data.authentication.privateKey, id);
    this.router.navigate(["acm", "manager-user-view", { id: encId }]);
  }

  editManagerUserProfile(id: any): void {
  
    const encId = this.cryptoService.set(this.data.authentication.privateKey, id);
    this.router.navigate(["acm", "manager-user-edit", { id: encId }]);
  }

  createManagerUser(): void {
    this.router.navigate(["acm", "manager-user-create"]);
  }

  modalSmsTransaction;
  mobileNo;
  openSendSms(smsModal, mobileNo) {
    this.mobileNo = mobileNo;
    this.modalSmsTransaction = this.modService.open(smsModal, {
      centered: true,
      size: "xl",
      backdrop: "static",
      keyboard: false,
    });
  }

  closeModal(){
    this.modalSmsTransaction.close();
  }

  exportManagerData(){

  }
}
