import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { StoreItemService } from 'src/data/services/web/store-item.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { UploadService } from 'src/data/services/web/upload.service';
import { Router } from '@angular/router';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-purchase-order-admin-view',
  templateUrl: './purchase-order-admin-view.component.html',
  styleUrls: ['./purchase-order-admin-view.component.less']
})
export class PurchaseOrderAdminViewComponent implements OnInit {

  @Input() purchaseOrder: any;
  @Input() Inventory: any;
  @Output() closeModalEmitter: EventEmitter<any> = new EventEmitter(); 

  @Output() getPO: EventEmitter<any> = new EventEmitter(); 
  @Output() getToShip: EventEmitter<any> = new EventEmitter(); 
  @Output() getToReceive: EventEmitter<any> = new EventEmitter(); 
  @Output() getCompleted: EventEmitter<any> = new EventEmitter(); 
  @Output() getCancelled: EventEmitter<any> = new EventEmitter(); 
  @Output() getReturn: EventEmitter<any> = new EventEmitter(); 

  imageWidth = 64; // set the width of each image
  imageHeight = 68; // set the width of each image
  visibleImages = 3; // set the number of visible images
  currentIndex = 0; // set the initial index of the first visible image

  constructor(
    private modalServe: NgbModal,
    private webService: WebSettingsService,
    private uploadService: UploadService,
    private router: Router,
    private currencyPipe: CurrencyPipe,
    public datepipe: DatePipe,
    public storeItemService: StoreItemService,
    private localStorageCacheService: LocalStorageCacheService
  ) { }

  ngOnInit(): void {
    //this.getInventory();
    this.getProofOfDelivery();
  }

  getProofOfDelivery(){
    this.storeItemService.getPoD(this.purchaseOrder.id).subscribe((result) => {
      this.images = result;
      debugger
    });
  }

  getAvailable(itemId){
    return this.Inventory.find(x => x.storeItem.id == itemId)?.available;
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  closeModal(){
    this.modalServe.dismissAll();
  }

  drModalRef: NgbModalRef;
  confirmDRModal(content){
    this.drModalRef = this.modalServe.open(content, { centered: true, size: "sm" });
  }

  pickOrder(){
    this.storeItemService.pickPO(this.purchaseOrder.id).subscribe((result) => {
      this.getPO.emit()
      this.getToShip.emit();
      this.closeModalEmitter.emit();
    });
  }
  
  inTransitOrder(){
    this.storeItemService.drPO(this.purchaseOrder.id).subscribe((result) => {
      this.getToShip.emit();
      this.getToReceive.emit();
      this.closeModalEmitter.emit();
    });
  }
  
  completeOrder(){
    this.storeItemService.receivePO(this.purchaseOrder.id).subscribe((result) => {
      this.getToReceive.emit();
      this.getCompleted.emit();
      this.closeModalEmitter.emit();
    });
  }

  cancelOrder(){
    this.storeItemService.cancelPO(this.purchaseOrder.id).subscribe((result) => {
      if (this.purchaseOrder.status == 2) this.getPO.emit();
      if (this.purchaseOrder.status == 3) this.getToShip.emit();
      if (this.purchaseOrder.status == 4) this.getToReceive.emit();
      this.getCancelled.emit();
      this.closeModalEmitter.emit();
    });
  }

  returnOrder(){
    this.storeItemService.returnPO(this.purchaseOrder.id).subscribe((result) => {
      this.getToReceive.emit();
      this.getReturn.emit();
      this.closeModalEmitter.emit();
    });
  }

  images:any = [];
  files: File[] = [];

  onSelect(event) {
    console.log(event);
    this.files = [];
    this.files.push(...event.addedFiles);
  }
  
  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  addImageModalRef: NgbModalRef;
  addImage(content: any){
    this.addImageModalRef = this.modalServe.open(content, {
      centered: true,
      size: "sm",
      backdrop: "static",
      keyboard: false,
    });
  }

  uploadImage(){
    if(!this.files[0]){
      PNotify.error({
        title: "Invalid",
        text: "Please attach a valid image.",
      });
      return;
    }
    
    const file_data = this.files[0];

    //var filedata = '';
    const reader = new FileReader();
    reader.readAsDataURL(file_data);
    reader.onload = () => {
      const filedata = reader.result.toString().substring(reader.result.toString().indexOf(',') + 1);

      this.uploadService.uploadProofOfDelivery(filedata,this.purchaseOrder.id).subscribe(response => {
        if(response){
          const json = JSON.parse(response);
          let path = { path: json.response_data };
          //this.images.push(path);
          this.images = [];
          this.files = [];
          this.getProofOfDelivery();
          //console.log(this.images);
        }
      });
    }
    
    this.addImageModalRef.close();
  }

  displayImagePoD: any;
  displayImageModalRef: NgbModalRef;
  selectedImage: any;
  showImage(content, image){
    this.displayImagePoD = image.path;
    this.selectedImage = image;
    debugger
    this.displayImageModalRef = this.modalServe.open(content, {
      centered: true,
      size: "md",
    });
  }

  closeDisplay(){
    this.displayImageModalRef.close();
  }

  printReceipt(){
    let data = this.purchaseOrder.pono;
    const url = this.router.serializeUrl(this.router.createUrlTree(['receipt', data]));
    window.open(url, '_blank');
  }

  removeImage(){
    this.uploadService.removeProofOfDelivery(this.selectedImage.id).subscribe((result) => {
      if(result){
        PNotify.success({
          title: "Success",
          text: "Remove Image.",
        });
        this.getProofOfDelivery();
        this.closeDisplay();
      } else {
        PNotify.error({
          title: "Invalid",
          text: "Something went wrong!",
        });
      }
    });
  }
}
