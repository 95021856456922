<app-loader *ngIf="loader"></app-loader>

<div *ngIf="!loader" [class.isPrinting]="printService.isPrinting" class="col-lg-12 col-md-12 col-sm-12">
  <div class="col-lg-12 col-md-12 col-sm-12">
    <div class="card">
      <div class="list list-row">
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-md d-none d-md-block color-red">
              <span class="text-dark">Request ID: </span> <strong>{{ requestId }}</strong>
            </div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <a style="color: blue" (click)="printReceipt()">Print Receipt <span class="fa fa-print"></span></a> <br />
              <!-- <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" [formControl]="isA4" />
                <label class="form-check-label text-muted" for="flexCheckDefault" style="font-size: 12px">
                  Print via A4
                </label>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <div class="form-group card">
        <p><strong>Client Details</strong><br /></p>
        <div class="card">
          <div class="list list-row">
            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Firstname</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">{{ data.clientFirstName }}</div>
              </div>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Lastname</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ data.clientLastName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group card">
        <p><strong>Beneficiary Details</strong><br /></p>
        <div class="card">
          <div class="list list-row">
            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Firstname</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">{{ data.beneficiaryFirstName }}</div>
              </div>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Lastname</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ data.beneficiaryLastName }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6 card">
      <div class="form-group card">
        <p><strong>Transaction Details</strong><br /></p>
        <div class="card">
          <div class="list list-row">
            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Remittance Status</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">{{ data?.remittanceStatus }}</div>
              </div>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Partner Reference Number</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">{{ data?.billerPartnerRefNo }}</div>
              </div>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Control Number</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ data.controlNumber }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group card">
        <p><strong>Amount</strong><br /></p>
        <div class="card">
          <div class="list list-row">
            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Remittance Amount</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ makeMoney(data.amount) }}
                </div>
              </div>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Wallet Amount</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  <span style="color: red">{{ makeMoney(getWalletAmount(data)) }}</span> &nbsp;
                  <i class="fa fa-exclamation-circle" [tooltip]="tooltipText" (mouseover)="updateTooltip(data)"></i>
                </div>
              </div>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Service Fee</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">{{ makeMoney(data.serviceFee) }}</div>
              </div>
            </div>

            <hr />

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Total</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ makeMoney(getTotalAmount(data)) }}
                </div>
              </div>
            </div>

            <hr *ngIf="data.amendFee != '0.00' || data.cancelFee != '0.00'" />
            <p *ngIf="data.amendFee != '0.00' || data.cancelFee != '0.00'"><strong>Other Charges</strong><br /></p>

            <div class="list-item" data-id="19" *ngIf="data.amendFee != 0">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Ammend Fee</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ makeMoney(data.amendFee) }}
                </div>
              </div>
            </div>

            <div class="list-item" data-id="19" *ngIf="data.cancelFee != 0">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Cancellation Fee</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ makeMoney(data.cancelFee) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div #vipLogo hidden>
  <img
    src="https://juanpaycdn.azureedge.net/juanpayassets/LOGO_logowithtagline-removebg-preview.png"
    alt="Go-VIP-Logo-Logo-Small"
    height="80px"
  />
</div>
