import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { environment } from "src/environments/environment";
import { FormGroup } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class PosService {
  private localStorageCacheService: LocalStorageCacheService;

  constructor(
    private httpClient: HttpClient,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
  }
  private httpClientNoInterceptor: HttpClient;

  private baseAPIUrl = environment.API_URL;

  public getTransactionItems(posID): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/POSTransaction/Items/${posID}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getActiveTransaction(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/POSTransaction/Active`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getOldTransaction(transactionId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/POSTransaction/Old/${transactionId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getTrackerTransaction(transactionId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/POSTransaction/Tracker/${transactionId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

   const httpHeaders: HttpHeaders = new HttpHeaders();
    //   .append("Authorization", "Bearer " + accessToken)
    //   .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }
  
  

  public gePreviousTransaction(transactionId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/POSTransaction/Previous/${transactionId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public geNextTransaction(transactionId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/POSTransaction/Next/${transactionId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getCashierTransactions(url: any): Observable<any> {
    let endPointUrl: any;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    endPointUrl = `${url}`;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  } //CashierHistory

  public getCashierTransactionHistory(url: any): Observable<any> {
    let endPointUrl: any;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    endPointUrl = `${url}`;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  } 
  

  public completeTransaction(cashTendered : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/POSTransaction/Complete`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    var payload = {
      cashTendered : cashTendered
    }

    return this.httpClient.post(`${endPointUrl}`, payload,{ headers: httpHeaders });
  }
  

  // tslint:disable-next-line: typedef
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  // tslint:disable-next-line: typedef
  private log(message: string) {
    //
  }
}
