import { CurrencyPipe, DatePipe } from "@angular/common";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { sortedIndexOf } from "lodash";
import { Select2OptionData } from "ng-select2";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { ExportExcelService } from "src/data/services/web/export-excel.service";
import { RequestFundService } from "src/data/services/web/request-fund.service";
import { RolesService } from "src/data/services/web/roles.service";
import { UserService } from "src/data/services/web/user.service";
import { WebSettingsService } from "src/data/services/web/web-settings.service";
import { environment } from "src/environments/environment";
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { GovipxService } from "src/data/services/web/govipx.service";
import { AngularFirestore } from '@angular/fire/firestore';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploadService } from "src/data/services/web/file-upload.service";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-merchant-verification',
  templateUrl: './merchant-verification.component.html',
  styleUrls: ['./merchant-verification.component.less']
})
export class MerchantVerificationComponent implements OnInit {
  public requestFundFilterFormGroup: FormGroup;
  public requestFundRemarkFormGroup: FormGroup;
  //remarks = new FormControl('');


  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public govipxService: GovipxService;
  public websettings: WebSettingsService;
  
  public userService: UserService;
  public statusOPtion: Array<Select2OptionData>;
 
  public emptyString = "";
  public statuses: any;
  public status: any;
  public modalService: BsModalService;

  public accessToken: string;
  public newRequestFund: boolean;
  public accounts: any;
  public requestFundsExport: any;
  public requestFund: any;

  public pageRows: any = 10;

  public lastTenFunds: any;
  public lastTenFund: any;

  public officialReceipt: any;
  public pageCount: any;
  public roleLevelId: any;
  public roleId: any;
  public processUpdate: any = false;
  public userID: any;
  public username: any;

  public bsModalRefConfirmation: BsModalRef;

  // Filters
  public listFilterForm: FormGroup;
  public selectedUserId: any = 0;
  public selectedStatusId: any = 0;
  public selectedRowsId: any = 0;

  public showRowsOption: Array<Select2OptionData>
  public userIdFilterOptions: Array<Select2OptionData>;
  public managerUsers: Array<Select2OptionData>;
  public userIdFilter: any;
  public managers: string[] = [];

  public Transactions = [];
  public isDataNotNull: boolean = false;
  dataForExcel = [];
  public isTicketActive : any = false;
  
  @ViewChild("pinModal") pinModal: TemplateRef<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    private currencyPipe: CurrencyPipe,
    private _sanitizer: DomSanitizer,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    govipxService: GovipxService,
    modalService: BsModalService,
    bsModalRefConfirmation: BsModalRef,
    websettings: WebSettingsService,
    userService: UserService,
    public datepipe: DatePipe,
    private modalServe: NgbModal,
    public ete: ExportExcelService,
    private store: AngularFirestore,
    public _fileUploadService: FileUploadService
  ) {
    PNotifyButtons;
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.govipxService = govipxService;
    this.modalService = modalService;
    this.websettings = websettings;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
    this.bsModalRefConfirmation = bsModalRefConfirmation;
    this.userService = userService;
  }

  public merchantList = [];

  ngOnInit(): void {
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    this.userID = this.localStorageCacheService.getStorage("userId");
    this.username = this.localStorageCacheService.getStorage("username");

    //this.getAccountValidationList();

    this.store.collection('business_registration').valueChanges().subscribe((val: any) => {
      this.merchantList = val.filter(x => x.application_status == false && x.application_rejected == false  && x?.business_type != 1);
      //this.merchantList = val;
    });
  }

  loader = false;

  selectedMechant: any;
  viewDetails(content: any, merchant: any){
    this.selectedMechant = merchant;
    this.modalServe.open(content, { centered: true, size: "xl" });
    this.loader = false;
  }

  async approveMerchant(merchant_id){
    var merchantId;
    await this.store.firestore.collection("business_registration").where("merchant_id", "==", merchant_id)
    .get()
    .then(function(querySnapshot) {
      //console.log(querySnapshot);
        querySnapshot.forEach(function(doc) {
          //console.log(doc.id, " => ", doc.data());
          merchantId = doc.id;
        });
    })
    .catch(function(error) {
        //console.log("Error getting documents: ", error);
    });

    this.approveStore(merchantId);
    this.remarksStr = "";
    this.verifyModal.close();
    this.modalServe.dismissAll();
  }

  remarksStr: any;

  async rejectMerchant(merchant_id){
    var merchantId;
    await this.store.firestore.collection("business_registration").where("merchant_id", "==", merchant_id)
    .get()
    .then(function(querySnapshot) {
      console.log(querySnapshot);
        querySnapshot.forEach(function(doc) {
          console.log(doc.id, " => ", doc.data());
          merchantId = doc.id;
        });
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });

    this.rejectStore(merchantId);
    this.remarksStr = "";
    this.rejectModal.close();
    this.modalServe.dismissAll();
  }

  rejectStore(id: any){
    debugger
    this.store
      .firestore
      .collection('business_registration')
      .doc(id)
      .set({
        application_status: false,
        application_rejected: true,
        remarks: this.remarksStr,
        processBy: this.userID,
        processByUserName: this.username,
        processAction: "Reject",
        processDate: Date.now()
      }, {
        merge: true,
    });
    // let myGuid = uuidv4();
    // this.store
    //   .firestore
    //   .collection('message_centre')
    //   .doc(id)
    //   .collection('messages')
    //   .doc(myGuid)
    //   .set({
    //     timestamp: new Date(),
    //     recipient_list: [],
    //     recipient: '09462374088',
    //     subject: 'Test Subject',
    //     message: this.remarksStr,
    //     status: 1,
    //     criteia: 'sendToAll'
    //   }, {
    //     merge: true,
    // });
  }

  approveStore(id: any){
    debugger
    this.store
      .firestore
      .collection('business_registration')
      .doc(id)
      .set({
        application_status: true,
        application_rejected: false,
        processBy: this.userID,
        processByUserName: this.username,
        processAction: "Approve",
        processDate: Date.now()
      }, {
        merge: true,
    });
  }

  // bank_account_holder_name: "NIKKI JUENNE CRUZ",
  // bank_account_number: "447845551232",
  // bank_identification_code: "225634",
  // bank_name: "BPI",
  // business_address: "UNIT 888 4TH FLOOR LEMON SQUARE BUILDING, MUNOZ EDSA, QUEON CITY",
  // business_name: "NIKKI'S LUGAWAN",
  // dti_permit_or_bir_2303: "https://firebasestorage.googleapis.com/v0/b/vipcomegawallet.appspot.com/o/merchantFiles%2Fe67de69d-a92e-410a-8cd4-1dc8fcc50856?alt=media&token=73ba8693-300d-4378-b6a8-77da40159f53",
  // latitude: "14.6653259",
  // longitude: "12.88648798",
  // merchant_id: "ff6a7c7b-ce56-48a8-8d99-fbe79a64d699",
  // proof_of_banking: "https://firebasestorage.googleapis.com/v0/b/vipcomegawallet.appspot.com/o/merchantFiles%2F2db38592-e3ce-488f-8c63-e72db3991a69?alt=media&token=2d8516fb-b404-4388-8b09-9eef98c386db",
  // proof_of_banking_attachment: "https://firebasestorage.googleapis.com/v0/b/vipcomegawallet.appspot.com/o/merchantFiles%2F8983657c-3aa8-4dce-bcef-2b1ed2f8213c?alt=media&token=91fe12cd-9461-41aa-8e8c-541854b07dc9",
  // store_facade: "https://firebasestorage.googleapis.com/v0/b/vipcomegawallet.appspot.com/o/merchantFiles%2F00f777bd-00a2-42d1-8d7c-2465be26ae2d?alt=media&token=afc4732d-3ba2-45c0-ac56-8bece01f1a94",
  // store_menu: "https://firebasestorage.googleapis.com/v0/b/vipcomegawallet.appspot.com/o/merchantFiles%2F52063639-336f-447c-ae92-a531c02e92d6?alt=media&token=90f52559-14e6-457c-b7d2-ac49f888fa7a",
  // tax_identification_number: "442-810-368-0000",
  // tax_information: "VAT Included",
  // timestamp: "October 12, 2022 at 5:18:07 PM UTC+8"

  processVerification(status: any){
    if(status){
      this.approveMerchant(this.selectedMechant.merchant_id);
    } else {
      this.rejectMerchant(this.selectedMechant.merchant_id);
    }
  }

  revertChanges(){
    this.store
      .firestore
      .collection('business_registration')
      .doc("09999999996")
      .set({
        application_status: false,
        application_rejected: false,
        dti_permit_or_bir_2303: "https://firebasestorage.googleapis.com/v0/b/vipcomegawallet.appspot.com/o/merchantFiles%2F67f0024f-b8fd-4f90-a8b1-b9101dbd75bc?alt=media&token=43da8647-e282-4fce-8381-5ba776c50a0a"
      }, {
        merge: true,
    });
  }

  public imagePreview: any;
  ispdf: boolean;
  public photoFormat: any;
  public photo: any;
  public photoFileName: any;
  fileType : any = "IMAGE";
  public uploadModal: NgbModalRef;
  async preview(content: any, url: any, isPDF: any){
    this.loader = true;
    this.ispdf = isPDF;
    this._fileUploadService.getBase64ImageFromURL(url).subscribe((result) => {
      debugger
      this.imagePreview = isPDF ? result : this._sanitizer.bypassSecurityTrustResourceUrl('data:image/*;base64,' + result);
      this.uploadModal = this.modalServe.open(content, { centered: true,size:'xl', backdrop: 'static'});
      this.loader = false;
    });
  }

  verifyModal: NgbModalRef;
  openVerify(content){
    this.verifyModal = this.modalServe.open(content, { centered: true, size: "md" });
  }

  rejectModal: NgbModalRef;
  openReject(content){
    this.rejectModal = this.modalServe.open(content, { centered: true, size: "md" });
  }
}
