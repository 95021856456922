<app-loader *ngIf="!user || processStoreSave"></app-loader>

<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex" *ngIf="user">
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">Cashier's Profile</h2>
          <p class="text-highlight font-weight-normal font-italic">Your personal information</p>
        </div>
        <div class="flex"></div>
        <div
          *ngIf="
            (user.cashierUser?.mobileActivationDate == null || user.cashierUser?.emailActivationDate == null) && isExpired &&
            (roleLevelId == 1 || roleLevelId == 2)
          "
        >
          <div (click)="resendEmailVerification(userId)" class="btn btn-md btn-secondary">
            <span class="d-none d-sm-inline mx-1">Resend Account Activation</span>
            <i data-feather="arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="padding sr">
        <div class="card">
          <div
            class="card-header bg-dark bg-img p-0 no-border"
            data-stellar-background-ratio="0.1"
            style="background-image: url(../../../assets/img/b3.jpg)"
            data-plugin="stellar"
          >
            <div class="bg-dark-overlay r-2x no-r-b">
              <div class="d-md-flex">
                <div class="p-4">
                  <div class="d-flex">
                    <a>
                      <span class="avatar w-64">
                        <img
                          class="w-48 avatar"
                          *ngIf="user.cashierUser?.photo"
                          [src]="buildUserPhoto(user) | authImage | async"
                          alt="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/filea7a2449f-a14a-4909-85f3-cf35a4b8b68b.png"
                          onerror="this.src='https://govipcenterstorage.blob.core.windows.net/govipcenterassets/filea7a2449f-a14a-4909-85f3-cf35a4b8b68b.png';"
                        />
                        <img
                          class="w-48 avatar"
                          *ngIf="!user.cashierUser?.photo"
                          src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/filea7a2449f-a14a-4909-85f3-cf35a4b8b68b.png"
                          alt="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/filea7a2449f-a14a-4909-85f3-cf35a4b8b68b.png"
                        />
                        <i class="on"></i>
                      </span>
                    </a>
                    <div class="mx-3">
                      <h5 class="mt-2">{{ user.cashierUser?.firstName }} {{ user.cashierUser?.lastName }}</h5>
                      <div class="text-fade text-sm">
                        <span class="m-r">{{ role }}</span>
                        <small>
                          <span
                            class="vip-input-text__activated"
                            [ngClass]="{ active: user.cashierUser?.email_verified_at }"
                          >
                            {{ user.cashierUser?.status ? "Active" : "Deactivated" }}
                          </span></small
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <span class="flex"></span>
                <div  *ngIf="isScheduleCreated || isReadyForScheduleCreation" class="align-items-center d-flex p-4">
                    <div class="toolbar">
                        <a  class="btn btn-sm bg-dark-overlay btn-rounded text-white bg-success active" >
                          <div data-toggle="modal" data-target="#modal-left" data-toggle-class="fade-left" data-toggle-class-target=".animate" >
                            <span class="d-none d-sm-inline mx-1">Save</span>
                            <i data-feather="arrow-right"></i>
                        </div>
                        </a>
                    </div>
                </div> -->
              </div>
            </div>
          </div>

          <div class="p-3">
            <div class="d-flex">
              <ul class="nav nav-pills">
                <li class="nav-item">
                  <a class="nav-link active" data-toggle="tab" data-target="#tab_4">Profile</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" data-toggle="tab" data-target="#tab_5">Cashier Schedule</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-lg-12">
            <div class="tab-content">
              <div class="tab-pane fade show active" id="tab_4">
                <div class="card">
                  <div class="card-header">
                    <strong>Cashier's Profile</strong>
                    <div *ngIf="roleLevelId == 2 || roleId == 1 || roleId == 2" (click)="editCashierUserProfile()" style="float: right">
                      <button class="btn btn-sm btn-primary mb-2 text-white">
                        Update &nbsp; <span class="fa fa-edit"></span>
                      </button>
                    </div>
                  </div>
                  <div class="px-4 py-4">
                    <div class="row mb-2">
                      <div class="col-6">
                        <small class="text-muted">Username</small>
                        <div class="my-2">{{ user?.username }}</div>
                      </div>
                      <div class="col-6">
                        <small class="text-muted">Email Address</small>
                        <div class="my-2">{{ user?.email }}</div>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-6">
                        <small class="text-muted">Employee Number</small>
                        <div class="my-2">{{ user.cashierUser?.cashierId }}</div>
                      </div>
                      <div class="col-6">
                        <small class="text-muted">Contact Number</small>
                        <div class="my-2">{{ user.cashierUser?.contactNumber }}</div>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-6">
                        <small class="text-muted">Birth Date</small>
                        <div class="my-2">{{ user.cashierUser?.birthDate | date: "MMMM dd, yyyy" }}</div>
                      </div>
                    </div>
                    <div>
                      <small class="text-muted">Address</small>
                      <div class="my-2">{{ user.cashierUser?.address }}</div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="px-4 py-4">
                    <div class="row mb-2">
                      <div class="col-6">
                        <small class="text-muted">Emergency Contact Number</small>
                        <div class="my-2">{{ user.cashierUser?.emergencyContactNumber }}</div>
                      </div>
                      <div class="col-6">
                        <small class="text-muted">Emergency Contact Name</small>
                        <div class="my-2">{{ user.cashierUser?.emergencyContactName }}</div>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-6">
                        <small class="text-muted">Emergency Contact Relationship</small>
                        <div class="my-2">{{ user.cashierUser?.emergencyContactRelationship }}</div>
                      </div>
                      <div class="col-6">
                        <small class="text-muted">Contact Number</small>
                        <div class="my-2">{{ user.cashierUser?.contactNumber }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade show" id="tab_5">
                <div class="card col-sm-12 col-lg-12" style="background-color: whitesmoke;">
                  <div class="card-header">
                    <strong>Schedule Mapping</strong>
                    <div
                      *ngIf="roleLevelId == 2"
                      data-toggle="modal"
                      data-target="#modal-left-settings"
                      data-toggle-class="fade-left"
                      data-toggle-class-target=".animate"
                      style="float: right"
                    >
                      <button class="btn btn-sm btn-primary mb-2 blue text-white">
                        Save changes &nbsp; <span class="fa fa-save"></span>
                      </button>
                    </div>
                  </div>
                  <div class="card-body">

                    <!-- <div class="row" [formGroup]="customScheduleFormGroup">
                      <div class="col-sm-4">
                        <select class="form-select" formControlName="customDays" aria-label="Default select example">
                          <option *ngFor="let day of daysSelection">{{day}}</option>
                        </select>
                      </div>
                      <div class="col-sm-4">
                        <select class="form-select" formControlName="customDays" aria-label="Default select example">
                          <option *ngFor="let time of timeSelection">{{time}}</option>
                        </select>
                      </div>
                      <div class="col-sm-4"></div>
                    </div>

                    <hr> -->

                    <table class="table col-12 table-striped">
                      <thead>
                        <th>DAY & TIME</th>
                        <th>M</th>
                        <th>T</th>
                        <th>W</th>
                        <th>TH</th>
                        <th>F</th>
                        <th>SA</th>
                        <th>SU</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>00:00 AM</td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.monday"
                              control-name="t24"
                            >
                            </app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.tuesday"
                              control-name="t24"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.wednesday"
                              control-name="t24"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.thursday"
                              control-name="t24"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.friday"
                              control-name="t24"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.saturday"
                              control-name="t24"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.sunday"
                              control-name="t24"
                            ></app-schedule-ticker>
                          </td>
                        </tr>
                        <tr>
                          <td>01:00 AM</td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.monday"
                              control-name="t1"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.tuesday"
                              control-name="t1"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.wednesday"
                              control-name="t1"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.thursday"
                              control-name="t1"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.friday"
                              control-name="t1"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.saturday"
                              control-name="t1"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.sunday"
                              control-name="t1"
                            ></app-schedule-ticker>
                          </td>
                        </tr>
                        <tr>
                          <td>02:00 AM</td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.monday"
                              control-name="t2"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.tuesday"
                              control-name="t2"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.wednesday"
                              control-name="t2"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.thursday"
                              control-name="t2"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.friday"
                              control-name="t2"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.saturday"
                              control-name="t2"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.sunday"
                              control-name="t2"
                            ></app-schedule-ticker>
                          </td>
                        </tr>
                        <tr>
                          <td>03:00 AM</td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.monday"
                              control-name="t3"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.tuesday"
                              control-name="t3"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.wednesday"
                              control-name="t3"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.thursday"
                              control-name="t3"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.friday"
                              control-name="t3"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.saturday"
                              control-name="t3"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.sunday"
                              control-name="t3"
                            ></app-schedule-ticker>
                          </td>
                        </tr>
                        <tr>
                          <td>04:00 AM</td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.monday"
                              control-name="t4"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.tuesday"
                              control-name="t4"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.wednesday"
                              control-name="t4"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.thursday"
                              control-name="t4"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.friday"
                              control-name="t4"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.saturday"
                              control-name="t4"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.sunday"
                              control-name="t4"
                            ></app-schedule-ticker>
                          </td>
                        </tr>

                        <tr></tr>
                        <tr>
                          <td>05:00 AM</td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.monday"
                              control-name="t5"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.tuesday"
                              control-name="t5"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.wednesday"
                              control-name="t5"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.thursday"
                              control-name="t5"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.friday"
                              control-name="t5"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.saturday"
                              control-name="t5"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.sunday"
                              control-name="t5"
                            ></app-schedule-ticker>
                          </td>
                        </tr>
                        <tr>
                          <td>06:00 AM</td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.monday"
                              control-name="t6"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.tuesday"
                              control-name="t6"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.wednesday"
                              control-name="t6"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.thursday"
                              control-name="t6"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.friday"
                              control-name="t6"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.saturday"
                              control-name="t6"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.sunday"
                              control-name="t6"
                            ></app-schedule-ticker>
                          </td>
                        </tr>
                        <tr>
                          <td>07:00 AM</td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.monday"
                              control-name="t7"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.tuesday"
                              control-name="t7"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.wednesday"
                              control-name="t7"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.thursday"
                              control-name="t7"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.friday"
                              control-name="t7"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.saturday"
                              control-name="t7"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.sunday"
                              control-name="t7"
                            ></app-schedule-ticker>
                          </td>
                        </tr>
                        <tr>
                          <td>08:00 AM</td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.monday"
                              control-name="t8"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.tuesday"
                              control-name="t8"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.wednesday"
                              control-name="t8"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.thursday"
                              control-name="t8"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.friday"
                              control-name="t8"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.saturday"
                              control-name="t8"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.sunday"
                              control-name="t8"
                            ></app-schedule-ticker>
                          </td>
                        </tr>
                        <tr>
                          <td>09:00 AM</td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.monday"
                              control-name="t9"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.tuesday"
                              control-name="t9"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.wednesday"
                              control-name="t9"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.thursday"
                              control-name="t9"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.friday"
                              control-name="t9"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.saturday"
                              control-name="t9"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.sunday"
                              control-name="t9"
                            ></app-schedule-ticker>
                          </td>
                        </tr>
                        <tr>
                          <td>10:00 AM</td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.monday"
                              control-name="t10"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.tuesday"
                              control-name="t10"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.wednesday"
                              control-name="t10"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.thursday"
                              control-name="t10"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.friday"
                              control-name="t10"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.saturday"
                              control-name="t10"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.sunday"
                              control-name="t10"
                            ></app-schedule-ticker>
                          </td>
                        </tr>
                        <tr>
                          <td>11:00 AM</td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.monday"
                              control-name="t11"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.tuesday"
                              control-name="t11"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.wednesday"
                              control-name="t11"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.thursday"
                              control-name="t11"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.friday"
                              control-name="t11"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.saturday"
                              control-name="t11"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.sunday"
                              control-name="t11"
                            ></app-schedule-ticker>
                          </td>
                        </tr>
                        <tr>
                          <td>12:00 PM</td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.monday"
                              control-name="t12"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.tuesday"
                              control-name="t12"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.wednesday"
                              control-name="t12"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.thursday"
                              control-name="t12"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.friday"
                              control-name="t12"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.saturday"
                              control-name="t12"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.sunday"
                              control-name="t12"
                            ></app-schedule-ticker>
                          </td>
                        </tr>
                        <tr>
                          <td>01:00 PM</td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.monday"
                              control-name="t13"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.tuesday"
                              control-name="t13"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.wednesday"
                              control-name="t13"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.thursday"
                              control-name="t13"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.friday"
                              control-name="t13"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.saturday"
                              control-name="t13"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.sunday"
                              control-name="t13"
                            ></app-schedule-ticker>
                          </td>
                        </tr>
                        <tr>
                          <td>02:00 PM</td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.monday"
                              control-name="t14"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.tuesday"
                              control-name="t14"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.wednesday"
                              control-name="t14"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.thursday"
                              control-name="t14"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.friday"
                              control-name="t14"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.saturday"
                              control-name="t14"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.sunday"
                              control-name="t14"
                            ></app-schedule-ticker>
                          </td>
                        </tr>
                        <tr>
                          <td>03:00 PM</td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.monday"
                              control-name="t15"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.tuesday"
                              control-name="t15"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.wednesday"
                              control-name="t15"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.thursday"
                              control-name="t15"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.friday"
                              control-name="t15"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.saturday"
                              control-name="t15"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.sunday"
                              control-name="t15"
                            ></app-schedule-ticker>
                          </td>
                        </tr>
                        <tr>
                          <td>04:00 PM</td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.monday"
                              control-name="t16"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.tuesday"
                              control-name="t16"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.wednesday"
                              control-name="t16"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.thursday"
                              control-name="t16"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.friday"
                              control-name="t16"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.saturday"
                              control-name="t16"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.sunday"
                              control-name="t16"
                            ></app-schedule-ticker>
                          </td>
                        </tr>
                        <tr>
                          <td>05:00 PM</td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.monday"
                              control-name="t17"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.tuesday"
                              control-name="t17"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.wednesday"
                              control-name="t17"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.thursday"
                              control-name="t17"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.friday"
                              control-name="t17"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.saturday"
                              control-name="t17"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.sunday"
                              control-name="t17"
                            ></app-schedule-ticker>
                          </td>
                        </tr>
                        <tr>
                          <td>06:00 PM</td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.monday"
                              control-name="t18"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.tuesday"
                              control-name="t18"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.wednesday"
                              control-name="t18"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.thursday"
                              control-name="t18"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.friday"
                              control-name="t18"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.saturday"
                              control-name="t18"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.sunday"
                              control-name="t18"
                            ></app-schedule-ticker>
                          </td>
                        </tr>
                        <tr>
                          <td>07:00 PM</td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.monday"
                              control-name="t19"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.tuesday"
                              control-name="t19"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.wednesday"
                              control-name="t19"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.thursday"
                              control-name="t19"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.friday"
                              control-name="t19"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.saturday"
                              control-name="t19"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.sunday"
                              control-name="t19"
                            ></app-schedule-ticker>
                          </td>
                        </tr>
                        <tr>
                          <td>08:00 PM</td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.monday"
                              control-name="t20"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.tuesday"
                              control-name="t20"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.wednesday"
                              control-name="t20"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.thursday"
                              control-name="t20"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.friday"
                              control-name="t20"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.saturday"
                              control-name="t20"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.sunday"
                              control-name="t20"
                            ></app-schedule-ticker>
                          </td>
                        </tr>
                        <tr>
                          <td>09:00 PM</td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.monday"
                              control-name="t21"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.tuesday"
                              control-name="t21"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.wednesday"
                              control-name="t21"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.thursday"
                              control-name="t21"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.friday"
                              control-name="t21"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.saturday"
                              control-name="t21"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.sunday"
                              control-name="t21"
                            ></app-schedule-ticker>
                          </td>
                        </tr>
                        <tr>
                          <td>10:00 PM</td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.monday"
                              control-name="t22"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.tuesday"
                              control-name="t22"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.wednesday"
                              control-name="t22"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.thursday"
                              control-name="t22"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.friday"
                              control-name="t22"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.saturday"
                              control-name="t22"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.sunday"
                              control-name="t22"
                            ></app-schedule-ticker>
                          </td>
                        </tr>
                        <tr>
                          <td>11:00 PM</td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.monday"
                              control-name="t23"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.tuesday"
                              control-name="t23"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.wednesday"
                              control-name="t23"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.thursday"
                              control-name="t23"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.friday"
                              control-name="t23"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.saturday"
                              control-name="t23"
                            ></app-schedule-ticker>
                          </td>
                          <td>
                            <app-schedule-ticker
                              [parent-form-group]="cashierScheduleDaysFormGroup.controls.sunday"
                              control-name="t23"
                            ></app-schedule-ticker>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-sm-5 col-lg-4">
            <div class="card sticky" style="z-index: 1">
              <div class="card-header">
                <strong>Your List of Cashier's</strong>
              </div>
              <div class="list list-row">
                <div class="list-item " data-id="8">
                  <div>
                    <a>
                      <span class="w-40 avatar gd-success">
                        <span class="avatar-status on b-white avatar-right"></span>
                        <img src="../../../assets/img/a8.jpg" alt=".">
                      </span>
                    </a>
                  </div>
                  <div class="flex">
                    <a class="item-author text-color ">Lawrence Cole</a>
                    <a class="item-cashier text-muted h-1x">
                      Jet
                    </a>
                  </div>
                  <div>
                    <div class="item-action dropdown">
                      <a data-toggle="dropdown" class="text-muted">
                        <i data-feather="more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right bg-black" role="menu">
                        <a class="dropdown-item">
                          See detail
                        </a>
                        <a class="dropdown-item download">
                          Download
                        </a>
                        <a class="dropdown-item edit">
                          Edit
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item trash">
                          Delete item
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-item " data-id="17">
                  <div>
                    <a>
                      <span class="w-40 avatar gd-warning">
                        <span class="avatar-status on b-white avatar-right"></span> H
                      </span>
                    </a>
                  </div>
                  <div class="flex">
                    <a class="item-author text-color ">Helen Valdez</a>
                    <a class="item-cashier text-muted h-1x">
                      AI
                    </a>
                  </div>
                  <div>
                    <div class="item-action dropdown">
                      <a data-toggle="dropdown" class="text-muted">
                        <i data-feather="more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right bg-black" role="menu">
                        <a class="dropdown-item">
                          See detail
                        </a>
                        <a class="dropdown-item download">
                          Download
                        </a>
                        <a class="dropdown-item edit">
                          Edit
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item trash">
                          Delete item
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-item " data-id="3">
                  <div>
                    <a>
                      <span class="w-40 avatar gd-primary">
                        <span class="avatar-status away b-white avatar-right"></span>
                        <img src="../../../assets/img/a3.jpg" alt=".">
                      </span>
                    </a>
                  </div>
                  <div class="flex">
                    <a class="item-author text-color ">Jordan Stephens</a>
                    <a class="item-cashier text-muted h-1x">
                      Wealth corp
                    </a>
                  </div>
                  <div>
                    <div class="item-action dropdown">
                      <a data-toggle="dropdown" class="text-muted">
                        <i data-feather="more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right bg-black" role="menu">
                        <a class="dropdown-item">
                          See detail
                        </a>
                        <a class="dropdown-item download">
                          Download
                        </a>
                        <a class="dropdown-item edit">
                          Edit
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item trash">
                          Delete item
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-item " data-id="12">
                  <div>
                    <a>
                      <span class="w-40 avatar gd-info">
                        <span class="avatar-status off b-white avatar-right"></span> A
                      </span>
                    </a>
                  </div>
                  <div class="flex">
                    <a class="item-author text-color ">Alan Richards</a>
                    <a class="item-cashier text-muted h-1x">
                      Drafty
                    </a>
                  </div>
                  <div>
                    <div class="item-action dropdown">
                      <a data-toggle="dropdown" class="text-muted">
                        <i data-feather="more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right bg-black" role="menu">
                        <a class="dropdown-item">
                          See detail
                        </a>
                        <a class="dropdown-item download">
                          Download
                        </a>
                        <a class="dropdown-item edit">
                          Edit
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item trash">
                          Delete item
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-item " data-id="9">
                  <div>
                    <a>
                      <span class="w-40 avatar gd-info">
                        <span class="avatar-status on b-white avatar-right"></span>
                        <img src="../../../assets/img/a9.jpg" alt=".">
                      </span>
                    </a>
                  </div>
                  <div class="flex">
                    <a class="item-author text-color ">Steven Cruz</a>
                    <a class="item-cashier text-muted h-1x">
                      HHH inc
                    </a>
                  </div>
                  <div>
                    <div class="item-action dropdown">
                      <a data-toggle="dropdown" class="text-muted">
                        <i data-feather="more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right bg-black" role="menu">
                        <a class="dropdown-item">
                          See detail
                        </a>
                        <a class="dropdown-item download">
                          Download
                        </a>
                        <a class="dropdown-item edit">
                          Edit
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item trash">
                          Delete item
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-item " data-id="14">
                  <div>
                    <a>
                      <span class="w-40 avatar gd-warning">
                        <span class="avatar-status off b-white avatar-right"></span> B
                      </span>
                    </a>
                  </div>
                  <div class="flex">
                    <a class="item-author text-color ">Benjamin Fields</a>
                    <a class="item-cashier text-muted h-1x">
                      Luckman corp
                    </a>
                  </div>
                  <div>
                    <div class="item-action dropdown">
                      <a data-toggle="dropdown" class="text-muted">
                        <i data-feather="more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right bg-black" role="menu">
                        <a class="dropdown-item">
                          See detail
                        </a>
                        <a class="dropdown-item download">
                          Download
                        </a>
                        <a class="dropdown-item edit">
                          Edit
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item trash">
                          Delete item
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-item " data-id="10">
                  <div>
                    <a>
                      <span class="w-40 avatar gd-danger">
                        <span class="avatar-status on b-white avatar-right"></span>
                        <img src="../../../assets/img/a10.jpg" alt=".">
                      </span>
                    </a>
                  </div>
                  <div class="flex">
                    <a class="item-author text-color ">Edward Clark</a>
                    <a class="item-cashier text-muted h-1x">
                      Goldage
                    </a>
                  </div>
                  <div>
                    <div class="item-action dropdown">
                      <a data-toggle="dropdown" class="text-muted">
                        <i data-feather="more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right bg-black" role="menu">
                        <a class="dropdown-item">
                          See detail
                        </a>
                        <a class="dropdown-item download">
                          Download
                        </a>
                        <a class="dropdown-item edit">
                          Edit
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item trash">
                          Delete item
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="list-item " data-id="5">
                  <div>
                    <a>
                      <span class="w-40 avatar gd-warning">
                        <span class="avatar-status on b-white avatar-right"></span>
                        <img src="../../../assets/img/a5.jpg" alt=".">
                      </span>
                    </a>
                  </div>
                  <div class="flex">
                    <a class="item-author text-color ">Sara George</a>
                    <a class="item-cashier text-muted h-1x">
                      Sun
                    </a>
                  </div>
                  <div>
                    <div class="item-action dropdown">
                      <a data-toggle="dropdown" class="text-muted">
                        <i data-feather="more-vertical"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right bg-black" role="menu">
                        <a class="dropdown-item">
                          See detail
                        </a>
                        <a class="dropdown-item download">
                          Download
                        </a>
                        <a class="dropdown-item edit">
                          Edit
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item trash">
                          Delete item
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>

<div id="modal-left-settings" class="modal fade" data-backdrop="true">
  <div class="modal-dialog animate">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title text-md">Updating Store Settings</div>
        <button class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="p-4 text-center">
          <p>Are you sure you want continue?</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
        <button (click)="actionEvenUpdateManagerStore()" type="button" class="btn btn-primary" data-dismiss="modal">
          Save Changes
        </button>
        <!--  -->
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
</div>

<!-- <div id="modal-left-address" class="modal fade" data-backdrop="true">
  <div class="modal-dialog animate">
      <div class="modal-content ">
          <div class="modal-header ">
              <div class="modal-title text-md">Updating Store Address</div>
              <button class="close" data-dismiss="modal">&times;</button>
          </div>
          <div class="modal-body">
              <div class="p-4 text-center">
                  <p>Are you sure you want continue?</p>
              </div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
              <button type="button"  (click)="actionEventCreateCashierStoreAddress()" class="btn btn-primary" data-dismiss="modal">Save Changes</button>
          </div>
      </div>
     
  </div>
</div>

<div id="modal-left-activation" class="modal fade" data-backdrop="true">
  <div class="modal-dialog animate">
      <div class="modal-content ">
          <div class="modal-header ">
              <div class="modal-title text-md">Updating Store Activation</div>
              <button class="close" data-dismiss="modal">&times;</button>
          </div>
          <div class="modal-body">
              <div class="p-4 text-center">
                  <p>Are you sure you want continue?</p>
              </div>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
              <button type="button"  (click)="actionEventCreateCashierStoreActivation()" class="btn btn-primary" data-dismiss="modal">Save Changes</button>
          </div>
      </div>
    
  </div>
</div> -->
