import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Select2OptionData } from 'ng-select2';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { SettlementsService } from 'src/data/services/web/settlements.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'ts-xlsx';
import * as FileSaver from 'file-saver';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SpxRemittanceService } from 'src/data/services/web/spx-remittance.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { result } from 'lodash';
import { ExportExcelService } from 'src/data/services/web/export-excel.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { AngularFirestore } from '@angular/fire/firestore';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-vips-custom-list',
  templateUrl: './vips-custom-list.component.html',
  styleUrls: ['./vips-custom-list.component.less']
})
export class VipsCustomListComponent implements OnInit {

  loader = false;
  searchByFormGroup: FormGroup;
  emptyString = '';
  roleId: any;
  
  createdDate = "";
  searchString = "";

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

  public showRowsOption: Array<Select2OptionData>;
  public hubOption: Array<Select2OptionData>;
  public users: any = [];
  public usersExcel: any = [];
  public transactionsExcel: any = [];
  public transaction: any;
  public totals : any;
  
  constructor(
    private localStorageCacheService: LocalStorageCacheService,
    private websettings : WebSettingsService,
    private modalServe: NgbModal,
    public datepipe: DatePipe,
    public ete: ExportExcelService,
    private store: AngularFirestore
  ) { }

  ngOnInit(): void {
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    this.initSearchFormGroup();
    this.initCustomFormGroup();
    this.initData();
  }

  initSearchFormGroup(){
    this.searchByFormGroup = new FormGroup({
      searchString : new FormControl,
      dateRange : new FormControl,
      showRows: new FormControl
    });

    this.searchByFormGroup.controls.searchString.valueChanges.subscribe((val) => {
      this.searchData();
    });
  }

  customFormGroup: FormGroup;
  initCustomFormGroup(){
    this.customFormGroup = new FormGroup({
      extras : new FormControl
    });

    this.customFormGroup.controls.extras.valueChanges.subscribe((val) => {
      this.updateExtras(val);
    });
  }

  customList: any = [];
  customApprovedList: any = [];
  accumulator: any = [];
  accumulatorApproved: any = [];
  initData(){
    this.store.collection("CustomVipsApplication").valueChanges().subscribe((val: any) => {
      this.accumulator = val;
      this.customList = val.filter(x => x.status == "Applied");
    });

    this.store.collection("CustomVips").valueChanges().subscribe((val: any) => {
      this.accumulatorApproved = val;
      this.customApprovedList = val;
    });
  }

  updateExtras(val){
    this.store.collection("CustomVipsApplication").doc(this.selectedItem.id).set({
      municipality_features: val,
      }, {
      merge: true,
    });
    
    this.store.collection("CustomVips").doc(this.selectedItem.id).set({
      municipality_features: val
    }, {
      merge: true
    })
    .then(() => {
      PNotify.success({
        title: "Success",
        text: "Extras Successfully Updated",
      });

      this.loader = false;
    })
    .catch((error) => {
      console.error("Error updating document: ", error);
      PNotify.error({
        title: "Error",
        text: "Failed to update data. Please try again.",
      });
      this.loader = false;
    });
  }

  searchData(){
    var search = this.searchByFormGroup.controls.searchString.value;
    if(search){
      this.customList = this.accumulator.filter(x => x.status == "Applied" 
        && (x.customAppName?.toLowerCase().includes(search)
        || x.username?.toLowerCase().includes(search)));
      this.customApprovedList = this.accumulatorApproved.filter(x => (x.customAppName?.toLowerCase().includes(search)
        || x.username?.toLowerCase().includes(search)));
    } else {
      this.customList = this.accumulator;
      this.customApprovedList = this.accumulatorApproved;
    }
  }

  // approve(data){
  //   this.loader = true;
  //   this.store.collection("CustomVips")
  //     .doc(data.id).set({
  //       backgroundCustomColor: data.backgroundCustomColor,
  //       buttonCustomColor: data.buttonCustomColor,
  //       buttonCustomFontColor: data.buttonCustomFontColor,
  //       customImageLandscapeUrl: data.customImageLandscapeUrl,
  //       customImageUrl: data.customImageUrl,
  //       primaryCustomColor: data.primaryCustomColor,
  //       primaryCustomFontColor: data.primaryCustomFontColor,
  //       primaryCustomWalletFontColor: data.primaryCustomWalletFontColor,
  //       status: "Approved",
  //       username:data.username,
  //       id: data.id,
  //       qr: data.qr,
  //       appliedDate: data.appliedDate,
  //       mode: data.mode,
  //       customAppName: data.customAppName,
  //       municipality_features: data.municipality_features
  //     }, {
  //       merge: true,
  //     })
  //     .then(() => {
  //       this.store.collection("CustomVipsApplication").doc(data.id).set({
  //           status: "Approved",
  //         }, {
  //         merge: true,
  //       });

  //       PNotify.success({
  //         title: "Success",
  //         text: "Application Approved",
  //       });

  //     this.loader = false;
  //     this.modalServe.dismissAll();
  //   })
  //   .catch((error) => {
  //     console.error("Error updating document: ", error);
  //     PNotify.error({
  //       title: "Error",
  //       text: "Failed to update data. Please try again.",
  //     });
  //     this.loader = false;
  //     this.modalServe.dismissAll();
  //   });
  // }

  approve(data) {
    this.loader = true;
    const sourceDocRef = this.store.collection("CustomVipsApplication").doc(data.id);
    const destDocRef = this.store.collection("CustomVips").doc(data.id);
  
    // Transfer data from "CustomVipsApplication" to "CustomVips"
    const transferDataPromise = destDocRef.set({
      backgroundCustomColor: data.backgroundCustomColor,
      buttonCustomColor: data.buttonCustomColor,
      buttonCustomFontColor: data.buttonCustomFontColor,
      customImageLandscapeUrl: data.customImageLandscapeUrl,
      customImageUrl: data.customImageUrl,
      primaryCustomColor: data.primaryCustomColor,
      primaryCustomFontColor: data.primaryCustomFontColor,
      primaryCustomWalletFontColor: data.primaryCustomWalletFontColor,
      status: "Approved",
      username: data.username,
      id: data.id,
      qr: data.qr,
      appliedDate: data.appliedDate,
      mode: data.mode,
      customAppName: data.customAppName,
      municipality_features: data.municipality_features
    }, { merge: true });
  
    // Transfer data from "Banners" collection in source document to "Banners" collection in destination document
    const transferBannersPromise = sourceDocRef.collection("Banners").get().toPromise().then(querySnapshot => {
      const batch = this.store.firestore.batch();
      querySnapshot.forEach(doc => {
        const bannerData = doc.data();
        const bannerDocRef = destDocRef.collection("Banners").doc(doc.id);
        batch.set(bannerDocRef.ref, bannerData);
      });
      return batch.commit();
    });
  
    Promise.all([transferDataPromise, transferBannersPromise])
      .then(() => {

        sourceDocRef.set({
            status: "Approved",
          }, {
          merge: true,
        });

        PNotify.success({
          title: "Success",
          text: "Application Approved",
        });
        this.loader = false;
        this.modalServe.dismissAll();
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        PNotify.error({
          title: "Error",
          text: "Failed to update data. Please try again.",
        });
        this.loader = false;
        this.modalServe.dismissAll();
      });
  }
  
  

  selectedUser: any;
  openConfirmation(content, data){
    this.selectedUser = data;
    
    this.modalServe.open(content, { centered: true, size: "sm" });
  }

  async getBanners(id){
    const bannersSnapshot = await this.store.collection("CustomVipsApplication").doc(id).collection("Banners").get().toPromise();
        
      // Initialize an array to store the images
      this.images = [];
      this.cardImage = '../../../../assets/vips2/empty2.png';
      var i = 0;

      bannersSnapshot.forEach((doc) => {
        // Push each banner data into the images array
        
        const bannerUrl = doc.data().bannerUrl;
        const index = doc.id; // Use document ID as index
        this.images.push({ path: bannerUrl, valid: true });

        if(i == 0){
          this.cardImage = bannerUrl;
          i += 1;
        }
      });
  }

  selectedItem: any;
  selectItemModalRef: NgbModalRef;
  openQR(content, item){
    this.selectedItem = item;
    this.selectItemModalRef = this.modalServe.open(content, { centered: true, size: "lg" });
  }

  openCustomRef: NgbModalRef;
  openCustom(content, item){
    this.selectedItem = item;
    this.getBanners(item?.id);

    console.log(item);
    this.selectItemModalRef = this.modalServe.open(content, { centered: true, size: "xl" });
  }

  // approve(data) {
  //   this.loader = true;
  
  //   // Move data to CustomVips collection
  //   this.store.collection("CustomVips").doc(data.id).set(data)
  //     .then(() => {
  //       // Data moved to CustomVips, now delete it from CustomVipsApplication
  //       //return this.store.collection("CustomVipsApplication").doc(data.id).delete();
  //     })
  //     .then(() => {
  //       // Approval success
  //       PNotify.success({
  //         title: "Success",
  //         text: "Application Approved",
  //       });
  //     })
  //     .catch((error) => {
  //       console.error("Error updating document: ", error);
  //       PNotify.error({
  //         title: "Error",
  //         text: "Failed to update data. Please try again.",
  //       });
  //     })
  //     .finally(() => {
  //       this.loader = false;
  //       this.modalServe.dismissAll();
  //     });
  // }

  remarksStr: any;
  reject(data){
    this.store.collection("CustomVipsApplication")
        .doc(data.id).set({
            status: "Rejected",
            remarks: this.remarksStr
          }, {
          merge: true,
      });

      this.modalServe.dismissAll();
      PNotify.success({
        title: "Success",
        text: "Application Rejected",
      });
  }

  cardImage: any = '../../../../assets/vips2/empty2.png';
  changeBigImage(image: any){
    this.cardImage = image;
  }

  images:any = [];

  imageWidth = 64; // set the width of each image
  imageHeight = 68; // set the width of each image
  visibleImages = 3; // set the number of visible images
  currentIndex = 0; // set the initial index of the first visible image
  
  scroll(direction: number): void {
    const wrapper = document.querySelector('.slider2-wrapper') as HTMLElement;
    const itemWidth = this.imageWidth + 4; // include margin-right
    const wrapperWidth = wrapper.offsetWidth;
    const maxIndex = this.images.length - this.visibleImages;
    let nextIndex = this.currentIndex + direction;
    if (nextIndex < 0) {
      nextIndex = 0;
    } else if (nextIndex > maxIndex) {
      nextIndex = maxIndex;
    }
    this.currentIndex = nextIndex;
    const transform = `translateX(-${this.currentIndex * itemWidth}px)`;
    wrapper.style.transform = transform;
  }
}
