import { CommonModule } from "@angular/common";
import { ApplicationInitStatus, ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule, Routes } from "@angular/router";

import { AlwaysAuthChildrenGuard } from "./guards/always-auth-children.guard";
import { AlwaysAuthGuard } from "./guards/always-auth.guard";
import { OnlyLoggedInCompanyUsersGuard } from "./guards/only-logged-in-company-users.guard";
import { OnlyLoggedInManagerUsersGuard } from "./guards/only-logged-in-manager-users.guard";
import { OnlyLoggedInCashierUsersGuard } from "./guards/only-logged-in-cashier-users.guard";
import { OnlyLoggedInSalesUsersGuard } from "./guards/only-logged-in-sales-users.guard";
import { UserService } from "./guards/user.service";
import { BankFromListComponent } from "./pages/bank-from-list/bank-from-list.component";
import { BankToListComponent } from "./pages/bank-to-list/bank-to-list.component";
import { CashierUserCreateComponent } from "./pages/cashier-user-create/cashier-user-create.component";
import { CashierUserEditComponent } from "./pages/cashier-user-edit/cashier-user-edit.component";
import { CashierUserListComponent } from "./pages/cashier-user-list/cashier-user-list.component";
import { CashierUserScheduleEditComponent } from "./pages/cashier-user-schedule-edit/cashier-user-schedule-edit.component";
import { CashierUserScheduleComponent } from "./pages/cashier-user-schedule/cashier-user-schedule.component";
import { CashierUserViewComponent } from "./pages/cashier-user-view/cashier-user-view.component";
import { CompanyUserCreateComponent } from "./pages/company-user-create/company-user-create.component";
import { CompanyUserEditComponent } from "./pages/company-user-edit/company-user-edit.component";
import { CompanyUserListComponent } from "./pages/company-user-list/company-user-list.component";
import { CompanyUserViewComponent } from "./pages/company-user-view/company-user-view.component";
import { HomeComponent } from "./pages/home/home.component";
// import { HomeHolidaysComponent } from "./pages/home-holidays/home-holidays.component";
import { LoginComponent } from "./pages/login/login.component";
import { ManagerUserCreateComponent } from "./pages/manager-user-create/manager-user-create.component";
import { ManagerUserEditComponent } from "./pages/manager-user-edit/manager-user-edit.component";
import { ManagerUserListComponent } from "./pages/manager-user-list/manager-user-list.component";
import { ManagerUserViewComponent } from "./pages/manager-user-view/manager-user-view.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { RoleListComponent } from "./pages/role-list/role-list.component";
import { StoreTransactionListComponent } from "./pages/store-transaction-list/store-transaction-list.component";
import { TicketDiscussionComponent } from "./pages/ticket-discussion/ticket-discussion.component";
import { TopUpListComponent } from "./pages/top-up-list/top-up-list.component";
import { WebSettingsListComponent } from "./pages/web-settings-list/web-settings-list.component";
import { WebSettingsEditComponent } from "./pages/web-settings-edit/web-settings-edit.component";
import { WebSettingsCreateComponent } from "./pages/web-settings-create/web-settings-create.component";
import { RequestFundListComponent } from "./pages/request-fund-list/request-fund-list.component";
import { RequestFundCreateComponent } from "./pages/request-fund-create/request-fund-create.component";
import { TransactionListComponent } from "./pages/transaction-list/transaction-list.component";
import { TransactionListAcmComponent } from "./pages/transaction-list-acm/transaction-list-acm.component";
import { PosTransactionComponent } from "./pages/pos-transaction/pos-transaction.component";
import { PosTransactionDetailsComponent } from "./pages/pos-transaction-details/pos-transaction-details.component";
import { MarketPlaceComponent } from "./pages/market-place/market-place.component";
import { AccountVerificationComponent } from "./pages/account-verification/account-verification.component";
import { BlankComponent } from "./pages/blank/blank.component";
import { AllocateFundListComponent } from "./pages/allocate-fund-list/allocate-fund-list.component";
import { AllocateFundCreateComponent } from "./pages/allocate-fund-create/allocate-fund-create.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { ApplicationListComponent } from "./pages/application-list/application-list.component";
import { PrintLayoutComponent } from "./elements/print-layout/print-layout.component";
import { InvoiceComponent } from "./elements/invoice/invoice.component";
import { AccountResetPasswordComponent } from "./pages/account-reset-password/account-reset-password.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { AccountVerifyComponent } from "./pages/account-verify/account-verify.component";
import { A4InvoiceComponent } from "./elements/a4-invoice/a4-invoice.component";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
import { ExamplesComponent } from "./pages/examples/examples.component";
import { PosTransactionListComponent } from "./pages/pos-transaction-list/pos-transaction-list.component";
import { TransactionListAllComponent } from "./pages/transaction-list-all/transaction-list-all.component";
import { OnlyLoggedInFinanceUsersGuard } from "./guards/only-logged-in-finance-users.guard";
import { TransactionHistoryComponent } from "./pages/transaction-history/transaction-history.component";
import { ManagerFileListComponent } from "./pages/manager-file-list/manager-file-list.component";
import { AllFilesListComponent } from "./pages/all-files-list/all-files-list.component";
import { PosTransactionPublicViewComponent } from "./pages/pos-transaction-public-view/pos-transaction-public-view.component";
import { ApplicationViewComponent } from "./pages/application-view/application-view.component";
import { MessagesComponent } from "./pages/messages/messages.component";
import { VideoCollectionsComponent } from "./pages/video-collections/video-collections.component";
import { AnnouncementAreaComponent } from "./pages/announcement-area/announcement-area.component";
import { AnnouncementBuilderComponent } from "./pages/announcement-builder/announcement-builder.component";
import { VipProtectListComponent } from "./pages/vip-protect-list/vip-protect-list.component";
import { VipProtectTermsAndConditionComponent } from "./pages/vip-protect-terms-and-condition/vip-protect-terms-and-condition.component";
import { GovipPrivacyPolicyComponent } from "./pages/govip-privacy-policy/govip-privacy-policy.component";
import { AuditWalletComponent } from "./pages/audit-wallet/audit-wallet.component";
import { ServiceChargeComponent } from "./billers/service-charge/service-charge.component";
import { ServiceChargeHistoryComponent } from "./pages/service-charge-history/service-charge-history.component";
import { ServiceChargeTransactionsComponent } from "./billersTransactions/service-charge-transactions/service-charge-transactions.component";
import { RealtimeMessageComponent } from "./pages/realtime-message/realtime-message.component";
import { LocationTrackerComponent } from "./pages/location-tracker/location-tracker.component";
import { MigrationFormComponent } from "./pages/migration-form/migration-form.component";
import { MigrationListComponent } from "./pages/migration-list/migration-list.component";
import { EnterpriseDashboardComponent } from "./pages/enterprise-dashboard/enterprise-dashboard.component";
import { AccountResetPincodeComponent } from "./pages/account-reset-pincode/account-reset-pincode.component";
import { GvaclComponent } from "./billers/sunlife-grepa/sunlife-grepa-tnc/gvacl/gvacl.component";
import { GvfacComponent } from "./billers/sunlife-grepa/sunlife-grepa-tnc/gvfac/gvfac.component";
import { GvacComponent } from "./billers/sunlife-grepa/sunlife-grepa-tnc/gvac/gvac.component";
import { GvlplComponent } from "./billers/sunlife-grepa/sunlife-grepa-tnc/gvlpl/gvlpl.component";
import { GvacppComponent } from "./billers/sunlife-grepa/sunlife-grepa-tnc/gvacpp/gvacpp.component";
import { SunlifeGrepaReportsComponent } from "./billers/sunlife-grepa/sunlife-grepa-reports/sunlife-grepa-reports.component";
import { VipProtectReportsComponent } from "./billers/vip-protect/vip-protect-reports/vip-protect-reports.component";
import { Da5CertificateComponent } from "./pages/da5-certificate/da5-certificate.component";
import { NationlinkSettlementComponent } from "./billers/settlements/nationlink-settlement/nationlink-settlement.component";
import { EasydebitSettlementComponent } from "./billers/settlements/easydebit-settlement/easydebit-settlement.component";
import { EncashSettlementComponent } from "./billers/settlements/encash-settlement/encash-settlement.component";
import { ShopeeSettlementComponent } from "./billers/settlements/shopee-settlement/shopee-settlement.component";
import { ApplicationDa5ViewComponent } from "./pages/application-da5-view/application-da5-view.component";
import { ApplicationSendahViewComponent } from "./pages/application-sendah-view/application-sendah-view.component";
import { HolidayComponent } from "./page-components/holiday/holiday.component";
import { TimekeepingReportComponent } from "./pages/timekeeping-report/timekeeping-report.component";
import { MinifiedVersionLoginComponent } from "./pages/rpi/minified-version-login/minified-version-login.component";
import { MigrationSalesListComponent } from "./pages/migration-sales-list/migration-sales-list.component";
import { MigrationSalesFormComponent } from "./pages/migration-sales-form/migration-sales-form.component";
import { StoreItemListComponent } from "./pages/VIPCO/STORE_MODULE/store-item-list/store-item-list.component";
import { StoreItemCreateComponent } from "./pages/VIPCO/STORE_MODULE/store-item-create/store-item-create.component";
import { StoreComponent } from "./pages/VIPCO/STORE_MODULE/store/store.component";
import { StoreItemComponent } from "./pages/VIPCO/STORE_MODULE/store-item/store-item.component";
import { GeneologyComponent } from "./pages/geneology/geneology.component";
import { SalesUserCreateComponent } from "./pages/VIPCO/SALES_USER_MODULE/sales-user-create/sales-user-create.component";
import { SalesAgentUserListComponent } from "./pages/VIPCO/SALES_USER_MODULE/sales-agent-user-list/sales-agent-user-list.component";
import { SalesUserViewComponent } from "./pages/VIPCO/SALES_USER_MODULE/sales-user-view/sales-user-view.component";
import { ExecutiveDirectorUserListComponent } from "./pages/VIPCO/SALES_USER_MODULE/executive-director-user-list/executive-director-user-list.component";
import { SalesDirectorUserListComponent } from "./pages/VIPCO/SALES_USER_MODULE/sales-director-user-list/sales-director-user-list.component";
import { SalesManagerUserListComponent } from "./pages/VIPCO/SALES_USER_MODULE/sales-manager-user-list/sales-manager-user-list.component";
import { VipcoTransactionListComponent } from "./pages/VIPCO/VIP_TRANSACTION/vipco-transaction-list/vipco-transaction-list.component";
import { SendVipcoComponent } from "./pages/VIPCO/VIP_TRANSACTION/send-vipco/send-vipco.component";
import { SalesActivationListComponent } from "./pages/VIPCO/SALES_USER_MODULE/sales-activation-list/sales-activation-list.component";
import { GovipExpressComponent } from "./pages/govip-express/govip-express.component";
import { BranchLocatorComponent } from "./pages/branch-locator/branch-locator.component";
import { PairingComponent } from "./pages/VIPCO/SALES_USER_MODULE/pairing/pairing.component";
import { VipCloudComponent } from "./pages/vip-cloud/vip-cloud.component";
import { CompanySalaryComponent } from "./pages/company-salary/company-salary.component";
import { GovipxAccountVerificationComponent } from "./pages/GoVIPX/govipx-account-verification/govipx-account-verification.component";
import { SalaryComponent } from "./pages/GoVIPX/salary/salary.component";
import { SpxRemittanceReportComponent } from "./billers/spx-rider-remittance/spx-remittance-report/spx-remittance-report.component";
import { SpxRemittanceSettlementComponent } from "./billers/settlements/spx-remittance-settlement/spx-remittance-settlement.component";
import { ProductEventLeaderboardComponent } from "./pages/product-event-leaderboard/product-event-leaderboard.component";
import { SpxRiderListComponent } from "./billers/spx-rider-remittance/spx-rider-list/spx-rider-list.component";
import { MerchantVerificationComponent } from "./pages/GoVIPX/merchant-verification/merchant-verification.component";
import { VipsDashboardComponent } from "./pages/VIPS/vips-dashboard/vips-dashboard.component";
import { VipsProfileComponent } from "./pages/VIPS/vips-profile/vips-profile.component";
import { VipsTopupComponent } from "./pages/VIPS/vips-topup/vips-topup.component";
import { VipsSendComponent } from "./pages/VIPS/vips-send/vips-send.component";
import { VipsTutorialsComponent } from "./pages/VIPS/vips-tutorials/vips-tutorials.component";
import { VipsRiderVerificationComponent } from "./pages/GoVIPX/vips-rider-verification/vips-rider-verification.component";
import { VipsUsersComponent } from "./pages/VIPS/vips-users/vips-users.component";
import { VipsMerchantComponent } from "./pages/VIPS/vips-merchant/vips-merchant.component";
import { VipsRidersComponent } from "./pages/VIPS/vips-riders/vips-riders.component";
import { VipsAdminComponent } from "./pages/VIPS/admin/vips-admin/vips-admin.component";
import { VipsMerchantStoreApplicationComponent } from "./pages/VIPS/MERCHANT/vips-merchant-store-application/vips-merchant-store-application.component";
import { VipsStoreProductsComponent } from "./pages/VIPS/MERCHANT/vips-store-products/vips-store-products.component";
import { FoodOrderHistoryComponent } from "./pages/VIPS/REPORTS/food-order-history/food-order-history.component";
import { VipsMerchantStoreHistoryComponent } from "./pages/VIPS/REPORTS/vips-merchant-store-history/vips-merchant-store-history.component";
import { VipsRiderApplicationHistoryComponent } from "./pages/VIPS/REPORTS/vips-rider-application-history/vips-rider-application-history.component";
import { MerchantLocationComponent } from './pages/VIPS/MERCHANT/merchant-location/merchant-location.component';
import { NewsletterBuilderComponent } from './pages/newsletter-builder/newsletter-builder.component';
import { VipsRiderCreditComponent } from "./pages/VIPS/vips-rider-credit/vips-rider-credit.component";
import { VipsLiveAgentComponent } from "./pages/VIPS/vips-live-agent/vips-live-agent.component";
import { VipcoTransactionComponent } from "./pages/vipco-transaction/vipco-transaction.component";
import { VipLootComponent } from "./pages/vip-loot/vip-loot.component";
import { VipsUsersListComponent } from "./pages/VIPS/vips-users-list/vips-users-list.component";
import { ManagerWalletComponent } from "./pages/manager-wallet/manager-wallet.component";
import { ItemSettingsListComponent } from "./pages/GOVIP-STORE/SETTINGS/item-settings-list/item-settings-list.component";
import { StockItemListComponent } from "./pages/GOVIP-STORE/SETTINGS/stock-item-list/stock-item-list.component";
import { InventoryComponent } from "./pages/GOVIP-STORE/ADMIN/inventory/inventory.component";
import { GovipStoreComponent } from "./pages/GOVIP-STORE/OUTLET/govip-store/govip-store.component";
import { PurchaseOrderComponent } from "./pages/GOVIP-STORE/OUTLET/purchase-order/purchase-order.component";
import { CartComponent } from "./pages/GOVIP-STORE/OUTLET/cart/cart.component";
import { CheckOutComponent } from "./pages/GOVIP-STORE/OUTLET/check-out/check-out.component";
import { PurchaseOrderAdminComponent } from "./pages/GOVIP-STORE/ADMIN/purchase-order-admin/purchase-order-admin.component";
import { ReceiptComponent } from "./pages/GOVIP-STORE/ADMIN/receipt/receipt.component";
import { XpressHistoryComponent } from "./pages/VIPS/REPORTS/xpress-history/xpress-history.component";
import { VipsLevel2VerificationComponent } from "./pages/GoVIPX/vips-level2-verification/vips-level2-verification.component";
import { AccountReactivationComponent } from "./pages/GoVIPX/account-reactivation/account-reactivation.component";
import { SalesActivationPaymentComponent } from "./pages/VIPCO/SALES_USER_MODULE/sales-activation-payment/sales-activation-payment.component";
import { VipsCustomListComponent } from "./pages/VIPS/vips-custom-list/vips-custom-list.component";
import { PaygramLedgerComponent } from "./pages/paygram-ledger/paygram-ledger.component";
import { PaygramFundComponent } from "./billers/paygram/paygram-fund/paygram-fund.component";
import { WaybillComponent } from "./billers/jnt/waybill/waybill.component";
import { VipsCommunityComponent } from "./pages/VIPS/vips-community/vips-community.component";
import { VipsCommunityListComponent } from "./pages/VIPS/vips-community-list/vips-community-list.component";
import { IglooAdminTransactionsComponent } from "./billersTransactions/igloo-admin-transactions/igloo-admin-transactions.component";
import { VipsHotelVerificationComponent } from "./pages/GoVIPX/vips-hotel-verification/vips-hotel-verification.component";

const routes: Routes = [
  // Anonynmous routes
  { path: "vipco", component: GovipExpressComponent },
  { path: "", component: LoginComponent, pathMatch: "full" },
  //{ path: "home", component: LoginComponent },
  { path: "login", component: LoginComponent },
  { path: "login-minify", component: MinifiedVersionLoginComponent },
  { path: "holiday", component: HolidayComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "tracker/:id", component: PosTransactionPublicViewComponent },
  { path: "vip-protect-terms-and-condition", component: VipProtectTermsAndConditionComponent },
  { path: "govip-privacy-policy", component: GovipPrivacyPolicyComponent },
  { path: "gvacl/:id", component: GvaclComponent },
  { path: "gvfac/:id", component: GvfacComponent },
  { path: "gvac/:id", component: GvacComponent },
  { path: "gvlpl/:id", component: GvlplComponent },
  { path: "gvacpp/:id", component: GvacppComponent },
  { path: "migration-sp-form", component: MigrationSalesFormComponent },
  { path: "migration-form", component: MigrationFormComponent },
  { path: "branch-locator", component: BranchLocatorComponent },
  { path: "vips-live-agent", component: VipsLiveAgentComponent },
  { path: "receipt/:data", component: ReceiptComponent },
  { path: "outlet-payment-details/:data", component: SalesActivationPaymentComponent },
  
  {
    path: "account/verification",
    component: AccountVerifyComponent,
  },
  {
    path: "account/resetpassword",
    component: AccountResetPasswordComponent,
  },
  {
    path: "account/resetpincode",
    component: AccountResetPincodeComponent,
  },
  // ACM routes
  {
    path: "partner-sunlife-grepa",
    // component: AppComponent,
    canActivate: [OnlyLoggedInCompanyUsersGuard, AlwaysAuthGuard],
    canActivateChild: [AlwaysAuthChildrenGuard],
    children: [{ path: "reports", component: SunlifeGrepaReportsComponent }],
  },
  // ACM routes
  {
    path: "partner-vip-protect",
    // component: AppComponent,
    canActivate: [OnlyLoggedInCompanyUsersGuard, AlwaysAuthGuard],
    canActivateChild: [AlwaysAuthChildrenGuard],
    children: [{ path: "reports", component: VipProtectReportsComponent }],
  },
  {
    path: "spx-finance",
    // component: AppComponent,
    canActivate: [OnlyLoggedInCompanyUsersGuard, AlwaysAuthGuard],
    canActivateChild: [AlwaysAuthChildrenGuard],
    children: [{ path: "spx-rider-pay-settlement", component: SpxRemittanceSettlementComponent }],
  },
  {
    path: "acm",
    // component: AppComponent,
    canActivate: [OnlyLoggedInCompanyUsersGuard, AlwaysAuthGuard],
    canActivateChild: [AlwaysAuthChildrenGuard],
    children: [
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      { path: "all-files-list", component: AllFilesListComponent },
      { path: "home", redirectTo: "dashboard", pathMatch: "full" },
      { path: "dashboard", component: DashboardComponent },
      { path: "web-settings-list", component: WebSettingsListComponent },
      { path: "web-settings-create", component: WebSettingsCreateComponent },
      { path: "web-settings-edit", component: WebSettingsEditComponent },
      { path: "announcement-builder", component: AnnouncementBuilderComponent },
      { path: "announcement-area", component: AnnouncementAreaComponent },
      { path: "bank-to-list", component: BankToListComponent },
      { path: "bank-from-list", component: BankFromListComponent },
      { path: "role-list", component: RoleListComponent },

      { path: "cashier-user-list", component: CashierUserListComponent },

      { path: "company-user-list", component: CompanyUserListComponent },
      { path: "company-user-create", component: CompanyUserCreateComponent },
      { path: "company-user-edit", component: CompanyUserEditComponent },
      { path: "company-user-view", component: CompanyUserViewComponent },

      { path: "manager-user-list", component: ManagerUserListComponent },
      { path: "manager-user-create", component: ManagerUserCreateComponent },
      { path: "manager-user-edit", component: ManagerUserEditComponent },
      { path: "manager-user-view", component: ManagerUserViewComponent },
      { path: "manager-wallet", component: ManagerWalletComponent },
      { path: "paygram-fund", component: PaygramFundComponent },

      { path: "request-fund-list", component: RequestFundListComponent },
      { path: "transaction-list-acm", component: TransactionListAcmComponent },
      { path: "transaction-list-all", component: TransactionListAllComponent },
      { path: "transaction-history", component: TransactionHistoryComponent },
      { path: "vipco-transaction-history", component: VipcoTransactionComponent },
      { path: "service-charge-history", component: ServiceChargeHistoryComponent },
      { path: "service-charge-transactions", component: ServiceChargeTransactionsComponent },
      { path: "transaction-list", component: TransactionListComponent },
      { path: "cashier-user-list", component: CashierUserListComponent },
      { path: "cashier-user-view", component: CashierUserViewComponent },
      { path: "cashier-user-edit", component: CashierUserEditComponent },
      { path: "pos-transaction-list", component: PosTransactionListComponent },
      { path: "store-transaction-list", component: StoreTransactionListComponent },
      { path: "top-up-list", component: TopUpListComponent },
      { path: "ticket-discussion", component: TicketDiscussionComponent },

      { path: "market-place", component: MarketPlaceComponent },
      { path: "application-list", component: ApplicationListComponent },
      { path: "application-view", component: ApplicationViewComponent },
      { path: "application-da5-view", component: ApplicationDa5ViewComponent },
      { path: "application-sendah-view", component: ApplicationSendahViewComponent },
      { path: "purchase-order", component: PurchaseOrderAdminComponent },

      { path: "messages", component: MessagesComponent },
      { path: "examples", component: ExamplesComponent }, // Examples page for developers
      { path: "video-collections", component: VideoCollectionsComponent }, // Examples page for developers
      { path: "vip-protect", component: VipProtectListComponent }, // Examples page for developers
      { path: "audit-wallet", component: AuditWalletComponent }, // Examples page for developers
      { path: "realtime-message", component: RealtimeMessageComponent }, // Examples page for developers
      { path: "location-tracker", component: LocationTrackerComponent }, // Examples page for developers
      { path: "migration-list", component: MigrationListComponent },
      { path: "migration-sales-list", component: MigrationSalesListComponent },
      { path: "enterprise-dashboard-restricted-access", component: EnterpriseDashboardComponent },
      { path: "sun-life-grepa-reports", component: SunlifeGrepaReportsComponent },
      { path: "vip-protect-reports", component: VipProtectReportsComponent },
      { path: "da5-certificate", component: Da5CertificateComponent },
      { path: "vip-protect-reports", component: VipProtectReportsComponent },
      { path: "nationlink-settlement", component: NationlinkSettlementComponent },
      { path: "easydebit-settlement", component: EasydebitSettlementComponent },
      { path: "encash-settlement", component: EncashSettlementComponent },
      { path: "shopee-settlement", component: ShopeeSettlementComponent },
      { path: "timekeeping-report", component: TimekeepingReportComponent },

      { path: "store-item", component: StoreItemComponent },
      { path: "store-item-list", component: StoreItemListComponent },
      { path: "store-item-create", component: StoreItemCreateComponent },
      { path: "store", component: StoreComponent },

      { path: "sales-agent-user-list", component: SalesAgentUserListComponent },
      { path: "executive-director-user-list", component: ExecutiveDirectorUserListComponent },
      { path: "sales-director-user-list", component: SalesDirectorUserListComponent },
      { path: "sales-manager-user-list", component: SalesManagerUserListComponent },
      { path: "sales-user-view", component: SalesUserViewComponent },
      { path: "sales-user-edit", component: StoreComponent },

      { path: "sales-activation-list", component: SalesActivationListComponent },
      { path: "company-salary", component: CompanySalaryComponent },

      { path: "govipx-account-verification", component: GovipxAccountVerificationComponent },
      { path: "vips-level2-verification", component: VipsLevel2VerificationComponent },
      { path: "merchant-verification", component: MerchantVerificationComponent },
      { path: "hotel-verification", component: VipsHotelVerificationComponent },
      { path: "rider-verification", component: VipsRiderVerificationComponent },
      { path: "food-order-history", component: FoodOrderHistoryComponent },
      { path: "vips-xpress-history", component: XpressHistoryComponent },
      { path: "account-reactivation", component: AccountReactivationComponent },
      { path: "vips-custom-list", component: VipsCustomListComponent },
      { path: "vips-community", component: VipsCommunityListComponent },

      { path: "salary", component: SalaryComponent },
      { path: "spx-remittance-reports", component: SpxRemittanceReportComponent },
      { path: "spx-rider-pay-settlement", component: SpxRemittanceSettlementComponent },
      { path: "spx-rider-list", component: SpxRiderListComponent },
      { path: "govipcenter-bonanza", component: ProductEventLeaderboardComponent },
      { path: "vips-admin", component: VipsAdminComponent },
      { path: "vips-merchant-store-application", component: VipsMerchantStoreApplicationComponent },
      { path: "vips-store-products", component: VipsStoreProductsComponent },
      { path: "foodOrderHistory", component: FoodOrderHistoryComponent },
      { path: "merchant-application-history", component: VipsMerchantStoreHistoryComponent },
      { path: "rider-application-history", component: VipsRiderApplicationHistoryComponent },
      { path: "merchant-location", component: MerchantLocationComponent },
      { path: "newsletter", component: NewsletterBuilderComponent },
      { path: "vips-user-list", component: VipsUsersListComponent },
      { path: "store-settings", component: ItemSettingsListComponent },
      { path: "stock-item-list", component: StockItemListComponent },
      { path: "inventory", component: InventoryComponent },
      { path: "paygram-ledger", component: PaygramLedgerComponent },
      { path: "igloo-admin-transactions", component: IglooAdminTransactionsComponent },
    ],
  },

  // ACM Finance routes
  {
    path: "acm",
    // component: AppComponent,
    canActivate: [OnlyLoggedInFinanceUsersGuard, AlwaysAuthGuard],
    canActivateChild: [AlwaysAuthChildrenGuard],
    children: [
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      { path: "home", redirectTo: "dashboard", pathMatch: "full" },
      { path: "dashboard", component: DashboardComponent },
      // { path: "web-settings-list", component: WebSettingsListComponent },
      // { path: "web-settings-create", component: WebSettingsCreateComponent },
      // { path: "web-settings-edit", component: WebSettingsEditComponent },
      // { path: "bank-to-list", component: BankToListComponent },
      // { path: "bank-from-list", component: BankFromListComponent },
      // { path: "role-list", component: RoleListComponent },
      // { path: "cashier-user-list", component: CashierUserListComponent },
      // { path: "company-user-list", component: CompanyUserListComponent },
      // { path: "company-user-create", component: CompanyUserCreateComponent },
      // { path: "company-user-edit", component: CompanyUserEditComponent },
      // { path: "company-user-view", component: CompanyUserViewComponent },
      { path: "manager-user-list", component: ManagerUserListComponent },
      { path: "manager-user-create", component: ManagerUserCreateComponent },
      { path: "manager-user-edit", component: ManagerUserEditComponent },
      { path: "manager-user-view", component: ManagerUserViewComponent },
      { path: "request-fund-list", component: RequestFundListComponent },
      { path: "service-charge-history", component: ServiceChargeHistoryComponent },
      { path: "service-charge-transactions", component: ServiceChargeTransactionsComponent },
      // { path: "transaction-list-acm", component: TransactionListAcmComponent },
      // { path: "transaction-list-all", component: TransactionListAllComponent },
      // { path: "transaction-list", component: TransactionListComponent },
      // { path: "cashier-user-list", component: CashierUserListComponent },
      // { path: "cashier-user-view", component: CashierUserViewComponent },
      // { path: "cashier-user-edit", component: CashierUserEditComponent },
      // { path: "pos-transaction-list", component: PosTransactionListComponent },
      {
        path: "store-transaction-list",
        component: StoreTransactionListComponent,
      },
      { path: "top-up-list", component: TopUpListComponent },
      { path: "ticket-discussion", component: TicketDiscussionComponent },
      // { path: "market-place", component: MarketPlaceComponent },
      // { path: "application-list", component: ApplicationListComponent },
      { path: "examples", component: ExamplesComponent }, // Examples page for developers
      { path: "video-collections", component: VideoCollectionsComponent },
      { path: "govipcenter-bonanza", component: ProductEventLeaderboardComponent }, // Examples page for developers
      { path: "paygram-ledger", component: PaygramLedgerComponent },
    ],
  },
  // Manager routes
  {
    path: "manager",
    //component: DashboardComponent,
    canActivate: [OnlyLoggedInManagerUsersGuard, AlwaysAuthGuard],
    canActivateChild: [AlwaysAuthChildrenGuard],
    children: [
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      { path: "home", redirectTo: "dashboard", pathMatch: "full" },
      { path: "manager-file-list", component: ManagerFileListComponent },
      { path: "dashboard", component: DashboardComponent },
      { path: "announcement-area", component: AnnouncementAreaComponent },
      { path: "cashier-user-list", component: CashierUserListComponent },
      { path: "cashier-user-create", component: CashierUserCreateComponent },
      { path: "cashier-user-view", component: CashierUserViewComponent },
      { path: "cashier-user-edit", component: CashierUserEditComponent },
      { path: "cashier-users/:id/schedule", component: CashierUserScheduleComponent },
      { path: "cashier-users/:id/schedule/edit", component: CashierUserScheduleEditComponent },
      { path: "manager-user-view", component: ManagerUserViewComponent },
      { path: "request-fund-list", component: RequestFundListComponent },

      { path: "request-fund-create", component: RequestFundCreateComponent },
      { path: "transaction-list", component: TransactionListComponent },
      { path: "transaction-history", component: TransactionHistoryComponent },
      { path: "service-charge-history", component: ServiceChargeHistoryComponent },
      { path: "service-charge-transactions", component: ServiceChargeTransactionsComponent },
      { path: "transaction-list-acm", component: TransactionListAcmComponent },
      { path: "allocate-fund-list", component: AllocateFundListComponent },
      { path: "allocate-fund-create", component: AllocateFundCreateComponent },


      { path: "market-place", component: MarketPlaceComponent },
      { path: "govip-store", component: GovipStoreComponent },
      { path: "purchase-order", component: PurchaseOrderComponent },
      { path: "my-cart", component: CartComponent },
      { path: "checkout", component: CheckOutComponent },

      { path: "application-view", component: ApplicationViewComponent },
      { path: "application-da5-view", component: ApplicationDa5ViewComponent },
      { path: "application-sendah-view", component: ApplicationSendahViewComponent },
      { path: "pos-transaction-list", component: PosTransactionListComponent },
      { path: "video-collections", component: VideoCollectionsComponent }, // Examples page for developers

      { path: "community", component: GeneologyComponent },
      { path: "sales-activation-list", component: SalesActivationListComponent },
      { path: "activate", component: SalesUserCreateComponent },
      { path: "sales-user-view", component: SalesUserViewComponent },
      { path: "sales-user-edit", component: StoreComponent },
      { path: "store", component: StoreComponent },
      { path: "vipco-transaction-list", component: VipcoTransactionListComponent },
      { path: "send-vipco", component: SendVipcoComponent },
      { path: "pairing", component: PairingComponent },
      { path: "govipcenter-bonanza", component: ProductEventLeaderboardComponent },

      { path: "vips-dashboard", component: VipsDashboardComponent },
      { path: "vips-profile", component: VipsProfileComponent },
      { path: "vips-topup", component: VipsTopupComponent },
      { path: "vips-send", component: VipsSendComponent },
      { path: "vips-tutorials", component: VipsTutorialsComponent },
      { path: "vips-users", component: VipsUsersComponent },
      { path: "vips-merchants", component: VipsMerchantComponent },
      { path: "vips-riders", component: VipsRidersComponent },
      { path: "vips-rider-credit", component: VipsRiderCreditComponent },
      { path: "vips-community", component: VipsCommunityComponent },

    ],
  },
  // Cashier routes
  {
    path: "cashier",
    //  component: DashboardComponent,
    canActivate: [OnlyLoggedInCashierUsersGuard, AlwaysAuthGuard],
    canActivateChild: [AlwaysAuthChildrenGuard],
    children: [
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      { path: "home", redirectTo: "dashboard", pathMatch: "full" },
      { path: "dashboard", component: DashboardComponent },

      { path: "announcement-area", component: AnnouncementAreaComponent },

      { path: "cashier-user-list", component: CashierUserListComponent },
      { path: "cashier-user-create", component: CashierUserCreateComponent },
      { path: "cashier-user-view", component: CashierUserViewComponent },
      { path: "cashier-user-edit", component: CashierUserEditComponent },
      { path: "cashier-users/:id/schedule", component: CashierUserScheduleComponent },
      { path: "cashier-users/:id/schedule/edit", component: CashierUserScheduleEditComponent },
      { path: "manager-user-view", component: ManagerUserViewComponent },

      { path: "request-fund-list", component: RequestFundListComponent },
      { path: "request-fund-create", component: RequestFundCreateComponent },

      { path: "transaction-list", component: TransactionListComponent },
      { path: "transaction-list-acm", component: TransactionListAcmComponent },
      { path: "transaction-history", component: TransactionHistoryComponent },
      { path: "service-charge-history", component: ServiceChargeHistoryComponent },
      { path: "service-charge-transactions", component: ServiceChargeTransactionsComponent },

      { path: "allocate-fund-list", component: AllocateFundListComponent },
      { path: "allocate-fund-create", component: AllocateFundCreateComponent },

      { path: "pos-transaction-details", component: PosTransactionDetailsComponent },
      { path: "pos-transaction-list", component: PosTransactionListComponent },
      { path: "video-collections", component: VideoCollectionsComponent }, // Examples page for developers
      // { path: "vip-cloud", component: VipCloudComponent },
      // { path: "vip-loot", component: VipLootComponent },
      { path: "govipcenter-bonanza", component: ProductEventLeaderboardComponent },
    ],
  },
  // Sales routes
  {
    path: "sales",
    //  component: DashboardComponent,
    canActivate: [OnlyLoggedInSalesUsersGuard, AlwaysAuthGuard],
    canActivateChild: [AlwaysAuthChildrenGuard],
    children: [
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      { path: "home", redirectTo: "dashboard", pathMatch: "full" },
      { path: "dashboard", component: DashboardComponent },
      { path: "community", component: GeneologyComponent },
      { path: "sales-activation-list", component: SalesActivationListComponent },
      { path: "activate", component: SalesUserCreateComponent },
      { path: "sales-user-view", component: SalesUserViewComponent },
      { path: "sales-user-edit", component: StoreComponent },
      { path: "store", component: StoreComponent },
      { path: "vipco-transaction-list", component: VipcoTransactionListComponent },
      { path: "send-vipco", component: SendVipcoComponent },
      { path: "pairing", component: PairingComponent },
      { path: "govipcenter-bonanza", component: ProductEventLeaderboardComponent },
    ],
  },
  { path: "blank", component: BlankComponent }, // Sample blank page
  { path: "pos-transaction", component: PosTransactionComponent },
  { path: "waybill", component: WaybillComponent },
  {
    path: "invoicePrint",
    outlet: "print",
    component: PrintLayoutComponent,
    children: [
      { path: "invoice/:invoiceData", component: InvoiceComponent },
      { path: "a4-invoice/:invoiceData", component: A4InvoiceComponent },
    ],
  },
  { path: "**", component: PageNotFoundComponent }, // Wildcard route for a 404 page
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
  ],
  exports: [RouterModule],
  providers: [
    AlwaysAuthGuard,
    OnlyLoggedInCompanyUsersGuard,
    OnlyLoggedInManagerUsersGuard,
    OnlyLoggedInCashierUsersGuard,
    OnlyLoggedInSalesUsersGuard,
    AlwaysAuthChildrenGuard,
    UserService,
  ],
})
export class AppRoutingModule {}
