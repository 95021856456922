import { FormGroup } from "@angular/forms";
import { Input } from "@angular/core";
import { Component } from "@angular/core";

@Component({
  selector: 'app-text-alpha',
  templateUrl: './text-alpha.component.html',
  styleUrls: ['./text-alpha.component.less']
})
export class TextAlphaComponent {
  @Input("parent-form-group") parentFormGroup: FormGroup;
  @Input("control-name") controlName: string;
  @Input("label") label: string;
  @Input("placeholder") placeholder: string;
  @Input("upper-case") upperCase: boolean;
  @Input("no-special-char") noSpecialChar: boolean;
  @Input("alpha-numeric") alphaNumeric: boolean;
  @Input("max-length") maxLength: number;
  @Input("tab-index-start") tabIndexStart: number;
  @Input("read-only") isReadonly: string;

  @Input("error") error: boolean;
  @Input("error-message") errorMessage: string;

  constructor() {}

  ngOnInit(): void {}

  readOnly(): any {
    return this.isReadonly.toString() == "true" ? "readonly" : null;
  }
}
