import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { Select2OptionData } from "ng-select2";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { WalletService } from "src/data/services/web/wallet.service";
import { WebSettingsService } from "src/data/services/web/web-settings.service";
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ApplicationService } from "src/data/services/web/application.service";
import { PaygramService } from "src/data/services/web/paygram.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.less"],
})
export class DashboardComponent implements OnInit {
  
  @ViewChild('viewSplashScreeModal') viewSplashScreeModal: TemplateRef<any>;
  @ViewChild('viewStoreHoursSettingsModal') viewStoreHoursSettingsModal: TemplateRef<any>;
  // @ViewChild('previewProductEventLeaderboard') previewProductEventLeaderboard: TemplateRef<any>;
  
  public dashboard: any;
  public roleLevelId: any;
  public roleId: any;
  public localStorageCacheService: LocalStorageCacheService;
  public username: any;

  skip_user: any;

  constructor(
    private webService: WebSettingsService,
    localStorageCacheService: LocalStorageCacheService,
    private sanitizer: DomSanitizer,
    private walletService : WalletService,
    private applicationService : ApplicationService,
    private paygramService : PaygramService,
    private modalService: NgbModal,
    private modalServiceLeaderBoard: NgbModal,
  ) 
  {
    PNotifyButtons;
    this.localStorageCacheService = localStorageCacheService;
  }

  ngOnInit(): void {
    this.username = this.localStorageCacheService.getStorage("username");
    this.resetCache();
  }

  hasStoreHours: any = false;
  ngAfterViewInit() {
    var storeHours = this.localStorageCacheService.getStorage("storeHours");
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.roleId = this.localStorageCacheService.getStorage("roleId");

    //debugger
    if(this.roleLevelId == 2 && storeHours != null){
     
      this.hasStoreHours = true;
    }

    //this.openPreviewProductEventLeaderboard(this.previewProductEventLeaderboard);
    this.closePreviewProductEventLeaderboard();
    

    
    this.initDashboardUI();

    if(this.roleLevelId == "1" && (this.roleId != 10 && this.roleId != 12 && this.roleId != 19)){
      this.getCTIBalance();
      this.getCTIPinsBalance();
      this.getECPayBalance();
      this.getIBayadBalance();
      this.getUGIBalance();
      this.getSMSBalance();
      this.getPaygramBalance();
    }
    if(this.roleLevelId == "2"){
      this.getApplicationCount();
    }
    
  }
  
  resetCache(){
    this.localStorageCacheService.removeStorage("bayadBiller");
    this.localStorageCacheService.removeStorage("ecTopupBiller");
    this.localStorageCacheService.removeStorage("ecCashBiller");
    this.localStorageCacheService.removeStorage("ecpayBiller");
    this.localStorageCacheService.removeStorage("multisysBiller");
  }
  
 
  applicationData ; any;
  getApplicationCount(){
    this.applicationService.getApplicationCount().subscribe(
      (result) => {
          this.applicationData = result;
        
      });
  }

 

  ctiPinsBalance : any;
  ctiBalance : any;
  ecPayBalance : any;
  smsBalance : any;
  iBayadBalance : any;
  ugiBalance : any;
  paygramBalance : any;

  getCTIBalance(){
    this.walletService.getCTIBalance().subscribe(
      (result) => {
          this.ctiBalance = (JSON.parse(result.data.result)).balance;
      });
  }

  getCTIPinsBalance(){
    this.walletService.getCTIPinsBalance().subscribe(
      (result) => {
        this.ctiPinsBalance = (JSON.parse(result.data.result)).balance;
      });
  }

  getECPayBalance(){
    this.walletService.getECPayBalance().subscribe(
      (result) => {
        this.ecPayBalance = result.data.result;
      });
  }

  getSMSBalance(){
    this.walletService.getSMSBalance().subscribe(
      (result) => {
        this.smsBalance = result;
      });
  }

  getUGIBalance(){
    this.walletService.getUGIBalance().subscribe(
      (result) => {
        this.ugiBalance = result.balance;
      });
  }


  getIBayadBalance(){
    this.walletService.getIBayadBalance().subscribe(
      (result) => {
        this.iBayadBalance = (JSON.parse(result.data.result).pockets[0]['available-balance']).replace(/,/g, '');
      });
  }

  getPaygramBalance(){
    this.paygramService.getPaygramBalance().subscribe(
      (result) => {
        this.paygramBalance = result;
      });

  }

  initDashboardUI(){
    
    var roleIndex = 0;

    if(this.roleLevelId == 3){
      roleIndex = 1;
    } else if(this.roleId == 10 || this.roleId == 12 || this.roleId == 19){
      roleIndex = 3;
    }

    this.webService.getWebSettingsByIdentifier("DASHBOARD").subscribe((result) => {
      var htmlData = (result.data[roleIndex].value).replace("{{USERNAME}}", this.username);

      this.dashboard = this.sanitizer.bypassSecurityTrustHtml(htmlData);
    });
  }


  private modalCreateTransaction: any;

  openCreateTransaction(modal) {
      this.modalCreateTransaction = this.modalService.open(modal, {
        centered: true,
        size: "xl",
        backdrop: "static",
        keyboard: false,
      });
  }

  openStoreHoursSettings(modal) {
    this.storeSettingsModal = this.modalService.open(modal, {
      centered: true,
      size: "xl",
      backdrop: "static",
      keyboard: false,
    });
}



  closeTransaction() {
    if (confirm("Are you sure you want to cancel your transaction?")) {
      PNotify.error({
        title: "Information",
        text: "Transaction Cancelled.",
      });
      this.modalCreateTransaction.close();
    }
  }

  closeModal() {
    this.modalCreateTransaction.close();
    if(this.roleLevelId != 2 || this.hasStoreHours){
      this.localStorageCacheService.removeStorage("from_login");
    }
    
    if(this.roleLevelId == 2 && !this.hasStoreHours){
      //debugger
      this.openStoreHoursSettings(this.viewStoreHoursSettingsModal);
    }
  }
  
  storeSettingsModal: NgbModalRef;
  closeSettingsModal() {
    this.storeSettingsModal.close();
  }


  openPreviewProductEventLeaderboard(previewProductEventLeaderboardModal) {
    this.modalServiceLeaderBoard.open(previewProductEventLeaderboardModal, {
      centered: true,
      size:  <any>'xl',
      backdrop: "static",
      keyboard: false,
    });
  }

  closePreviewProductEventLeaderboard() {
    this.modalServiceLeaderBoard.dismissAll();

    var fromLogin = this.localStorageCacheService.getStorage("from_login");
    this.webService.getWebSettingsByIdentifier("SKIP_SPLASHSCREEN").subscribe((result) => {
      if(fromLogin){
        if(this.roleLevelId != 1 && this.username != result.data[0].value){
          this.openCreateTransaction(this.viewSplashScreeModal);
        }
      }
    });
  }

}
