import { CurrencyPipe } from "@angular/common";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Select2OptionData } from "ng-select2";
import { BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { ExportExcelService } from "src/data/services/web/export-excel.service";
import { ManagerUsersService } from "src/data/services/web/manager-users.service";
import { RolesService } from "src/data/services/web/roles.service";
import { environment } from "src/environments/environment";
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { PaygramService } from "src/data/services/web/paygram.service";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5";

@Component({
  selector: 'app-paygram-fund',
  templateUrl: './paygram-fund.component.html',
  styleUrls: ['./paygram-fund.component.less']
})
export class PaygramFundComponent implements OnInit {
  public managerUserFilterFormGroup: FormGroup;
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public managerUsersService: ManagerUsersService;
  public emptyString = "";
  public roles: any;
  public role: any;
  public statuses: any;
  public status: any;
  public modalService: BsModalService;

  public accessToken: string;
  public newManagerUser: boolean;
  public users: any;
  public usersExcel: any = [];
  public pageCount: any;
  public roleId: any;
  dataForExcel = [];
  // Filters
  public listFilterForm = new FormGroup({
    searchString: new FormControl(""),
    searchStringField: new FormControl(""),
  });
  public filterByOptions: Array<Select2OptionData>;
  public filterBy: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    private currencyPipe: CurrencyPipe,
    private changeDetectorRef: ChangeDetectorRef,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    managerUsersService: ManagerUsersService,
    modalService: BsModalService,
    private modService: NgbModal,
    private paygramService: PaygramService,
    public ete: ExportExcelService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.managerUsersService = managerUsersService;
    this.modalService = modalService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
  }

  isRequire = true;
  ngOnInit(): void {
    this.getFundTransaction();
    this.initFundTransactionForm();
    this.getbalance();
    this.filterByOptions = [
      {
        id: "WITHDRAW",
        text: "WITHDRAW",
      },
      {
        id: "DEPOSIT",
        text: "DEPOSIT",
      }
    ];
  }

  fundTransactionForm: FormGroup;
  initFundTransactionForm(): void {
    this.fundTransactionForm = new FormGroup({
      transactionName: new FormControl(null, [Validators.required]),
      refNo: new FormControl(null, [Validators.required]),
      imageUrl: new FormControl(null, [Validators.required]),
      amount: new FormControl(null, [Validators.required]),
    });
    
    this.fundTransactionForm.controls.transactionName.valueChanges.subscribe((val) => {
      const imgControl = this.fundTransactionForm.controls.imageUrl;
    
      if (val === "WITHDRAW") {
        this.isRequire = false;
        imgControl.clearValidators();
      } else {
        this.isRequire = true;
        imgControl.setValidators([Validators.required]);
      }
    
      imgControl.updateValueAndValidity();
    });
  }

  balance: any;
  getbalance(){
    this.loader = true;
    this.paygramService.getPaygramBalance().subscribe((result) => {
      this.balance = result;
      this.loader = false;
    });
  }

  private _sortBy: any;
  private _sort: any;

  getFundTransaction(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    if (fromSearch) {
      this.managerId = this.emptyString;
      this.username = this.emptyString;
      this.email = this.emptyString;
      this.firstName = this.emptyString;
      this.lastName = this.emptyString;
      this.roleName = this.emptyString;
    }

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_searchString: string = this.listFilterForm.get("searchString").value
      ? `searchString=${this.listFilterForm.get("searchString").value}&`
      : "";
    const queryString_filterBy: string = this.filterBy ? `filterBy=${this.filterBy}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_searchString + queryString_filterBy;

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/Paygram/FundTransactions?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/Paygram/FundTransactions${queryString}`;
      }
    }

    this.managerUsersService.getManagerUsers(endPointUrl).subscribe((result) => {
      if (result.data.length == 0 && result.pageNumber != 1) {
        this.getFundTransaction(result.previousPage, _sortBy, _sort);
      } else {
        this.users = result;
        
      }
    });
  }

  loader: any = false;
  getManagerUsersForExcel(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader = true;
    if (fromSearch) {
      this.managerId = this.emptyString;
      this.username = this.emptyString;
      this.email = this.emptyString;
      this.firstName = this.emptyString;
      this.lastName = this.emptyString;
      this.roleName = this.emptyString;
    }

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_searchString: string = this.listFilterForm.get("searchString").value
      ? `searchString=${this.listFilterForm.get("searchString").value}&`
      : "";
    const queryString_filterBy: string = this.filterBy ? `filterBy=${this.filterBy}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_searchString + queryString_filterBy;

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/Paygram/FundTransactions?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/Paygram/FundTransactions${queryString}`;
      }
    }

    this.managerUsersService.getManagerUsers(endPointUrl).subscribe((result) => {
      if (result.data.length == 0 && result.pageNumber != 1) {
        this.getManagerUsersForExcel(result.previousPage, _sortBy, _sort);
      } else {
        //debugger
        this.generateExcel(result);
      }
    });
  }

  generateExcel(user: any): void {
    let usersExcel = [];
    
      for(var i = 0; i < user.totalRecords; i++){
        let meta = [
          {
              "Username": user.data[i].username,
              "Owner's Full Name": user.data[i].fullname,
              "Store Name": user.data[i].storeName,
              "Current Balance": user.data[i].balance,
              "Allocated Fund":	user.data[i].totalCashierBalance,
              "Total Running Balance":	user.data[i].totalRunningBalance,
              "No of Cashier": user.data[i].cashierList.length
          }
        ]

        usersExcel.push(meta);
      }

      debugger
    for(var x = 0; x < usersExcel.length; x++){
      this.dataForExcel.push(Object.values(usersExcel[x][0]));
    }

    let reportData = {
      title: 'Manager Running Wallet',
      data: this.dataForExcel,
      headers: Object.keys(usersExcel[0][0])
    }

    this.loader = false;
    this.ete.exportExcel(reportData, "Manager Running Wallet");
  }

  managerId = "";
  username = "";
  email = "";
  firstName = "";
  lastName = "";
  roleName = "";
  searchString = "";

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.managerId = field == "managerId" ? "th-inner sortable both " + sortBy : "";
    this.username = field == "username" ? "th-inner sortable both " + sortBy : "";
    this.email = field == "email" ? "th-inner sortable both " + sortBy : "";
    this.firstName = field == "firstName" ? "th-inner sortable both " + sortBy : "";
    this.lastName = field == "lastName" ? "th-inner sortable both " + sortBy : "";
    this.roleName = field == "roleName" ? "th-inner sortable both " + sortBy : "";
   
    this.getFundTransaction(
      `${environment.API_URL}/api/Paygram/FundTransactions?pageNumber=${page ? page : this.users.pageNumber}`,
      this._sortBy,
      this._sort
    );
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.users?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }

  // tslint:disable-next-line: variable-name
  getRole(role_id: any): string {
    if (role_id) {
      return this.roles.filter((x) => x.id === role_id)[0].name;
    } else {
      return "No Role";
    }
  }
p:Number =1;
  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p = pageNumber;
    return `${environment.API_URL}/api/Paygram/FundTransactions?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  buildUserPhoto(user: any): any {
    return user.managerUser.photoFormat + "," + user.managerUser.photo;
  }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }

  viewManagerUserProfile(id: any): void {
  
    const encId = this.cryptoService.set(this.data.authentication.privateKey, id);
    this.router.navigate(["acm", "manager-user-view", { id: encId }]);
  }

  editManagerUserProfile(id: any): void {
  
    const encId = this.cryptoService.set(this.data.authentication.privateKey, id);
    this.router.navigate(["acm", "manager-user-edit", { id: encId }]);
  }

  createManagerUser(): void {
    this.router.navigate(["acm", "manager-user-create"]);
  }

  modalSmsTransaction;
  mobileNo;
  openSendSms(smsModal, mobileNo) {
    this.mobileNo = mobileNo;
    this.modalSmsTransaction = this.modService.open(smsModal, {
      centered: true,
      size: "xl",
      backdrop: "static",
      keyboard: false,
    });
  }

  closeModal(){
    this.modalSmsTransaction.close();
  }

  exportManagerData(){

  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  selectedManager: any;
  selectManager(walletModal: any, user: any){
    this.selectedManager = user;
    this.modalSmsTransaction = this.modService.open(walletModal, {
      centered: true,
      size: "xl"
    });
  }
  
  createModalRef: NgbModalRef;
  openCreateModal(content: any){
    this.fileView = "";
    this.createModalRef = this.modService.open(content, {
      centered: true,
      size: "xl",
      backdrop: "static",
      keyboard: false,
    });
  }

  processTransaction(){
    if(this.fundTransactionForm.valid){
      this.loader = true;
      this.paygramService.processFundTransaction(this.fundTransactionForm.value).subscribe((result) =>{
        if(result.item1 == true){
          PNotify.success({
            title: "Paygram Fund Transaction",
            text: "Success",
          });

          this.getFundTransaction();
          this.getbalance();
          this.fundTransactionForm.reset();
          this.fileView = "";
          this.createModalRef.close();

          this.loader = false;
        } else {
          PNotify.error({
            title: "Paygram Fund Transaction",
            text: result.item2,
          });
          this.loader = false;
        }
      }, (error) => {
        this.loader = false;
        PNotify.error({
          title: "Invalid",
          text: error.error.item2,
        });
      });
    } else {
      this.loader = false;
      PNotify.error({
        title: "Action denied",
        text: "Please fill all fields",
      });
    }
  }

  uploadReceipt(fund){
    //console.log(fund);
    if(this.fileView){
      this.loader = true;
      this.paygramService.uploadFundReceipt(fund.id, this.fileView).subscribe((result) => {
        if(result.item1 == true){
          PNotify.success({
            title: "Paygram Fund Transaction",
            text: "Receipt successfully added",
          });
          this.getFundTransaction();
          
          this.loader = false;
        } else {
          PNotify.error({
            title: "Paygram Fund Transaction",
            text: result.item2,
          });
          this.loader = false;
        }
      }, (error) => {
        this.loader = false;
        PNotify.error({
          title: "Invalid",
          text: error.error.item2,
        });
      });
    } else {
      this.loader = false;
      PNotify.error({
        title: "Action denied",
        text: "Please upload the receipt",
      });
    }
  }

  public fileName: any = "Click here to choose...";
  public base64Photo: string | ArrayBuffer;
  fileView: any;

  triggerFile(inputFile){
    inputFile.click();
  }

  public onUploadFile(event: any) {
    const reader = new FileReader();
   
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.fileName = file.name;
      reader.onload = () => {
        
        this.base64Photo = reader.result as string;
        if(this.calculateImageSize(this.base64Photo)){
          this.fundTransactionForm.controls.imageUrl.patchValue(this.base64Photo);
          this.fundTransactionForm.controls.imageUrl.markAsTouched();
          this.fileView = this.base64Photo;
          this.changeDetectorRef.markForCheck();
        }else{
          PNotify.error({
              title: "Receipt",
              text: "File is too big. Must be less than or equal to 1mb.",
          });
        }
      };
    }
  }

  calculateImageSize(base64String) : boolean {
    let padding;
    let inBytes;
    let base64StringLength;
    if (base64String.endsWith('==')) { padding = 2; }
    else if (base64String.endsWith('=')) { padding = 1; }
    else { padding = 0; }

    base64StringLength = base64String.length;
    //console.log(base64StringLength);
    inBytes = (base64StringLength / 4) * 3 - padding;
    return inBytes < 1000000;
  }
}
