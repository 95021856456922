import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.less"],
})
export class LoaderComponent implements OnInit {
  @Input("message") message: any;

  constructor() {}
  ngOnInit(): void {}
}
