import { FormGroup } from "@angular/forms";
import { Input } from "@angular/core";
import { Component } from "@angular/core";

@Component({
  selector: 'app-pin-textbox',
  templateUrl: './pin-textbox.component.html',
  styleUrls: ['./pin-textbox.component.less']
})
export class PinTextboxComponent {
  @Input("parent-form-group") parentFormGroup: FormGroup;
  @Input("control-name") controlName: string;
  @Input("label") label: string;
  @Input("placeholder") placeholder: string;
  @Input("upper-case") upperCase: boolean;
  @Input("no-special-char") noSpecialChar: boolean;
  @Input("alpha-numeric") alphaNumeric: boolean;
  @Input("max-length") maxLength: number;
  @Input("tab-index-start") tabIndexStart: number;
  @Input("read-only") isReadonly: string;

  @Input("error") error: boolean;
  @Input("error-message") errorMessage: string;

  constructor() {}

  ngOnInit(): void {}

  readOnly(): any {
    return this.isReadonly.toString() == "true" ? "readonly" : null;
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
    this.code = this.upperCase ? code.toUpperCase() : code;
    const codeControl = this.parentFormGroup.get(this.controlName);
    if (codeControl) {
      codeControl.setValue(this.upperCase ? code.toUpperCase() : code);
    }
  }

  // this called only if user entered full code
  codeStatus : boolean = false;
  code : any = "";
  onCodeCompleted(code: string) {
    this.code = code;
  }

  doLoginPinAction(){
  }

  
}
