<div class="card flex flex-column">
    <div class="row" [formGroup]="stockItemFormGroup">
        <div class="col-4" style="border-radius: 15px;">
            <div>
                <img class="card-image" [src]="cardImage" alt="Card image cap">
            </div>
            <div class="mt-3">
                <div class="slider">
                    <div class="slider-wrapper pt-1 pb-2" [style.width.px]="imageWidth * images.length + (images.length * 5)">
                        <div class="slider-item" *ngFor="let image of images">
                            <img class="item-img" (mouseover)="changeBigImage(image.path)" [src]="image.path" [style.width.px]="imageWidth">
                        </div>
                    </div>
                    <button class="slider-arrow left" *ngIf="images.length >= 6" (click)="scroll(-1)">&lt;</button>
                    <button class="slider-arrow right" *ngIf="images.length >= 6" (click)="scroll(1)">&gt;</button>
                </div>
                <!-- <div class="mt-2">
                    <button class="item-img btn btn-sm btn-primary text-dark"><i class="fa fa-plus"></i></button>
                </div> -->
            </div>
        </div>
        <div class="col-8 p-3">
            <!-- <h4> <span class="color-blue font-weight-bolder">{{ stockItem.name }}</span> &nbsp; <span class="color-blue font-weight">({{ stockItem.itemCode }})</span> </h4>
            <button
                class="btn btn-sm mt-2 btn-secondary button--search btn-hover font-weight-bold" style="float:right">
                <i class="fa fa-edit"></i>
            </button>

            <p class="card-text mt-2 mb-2">{{ stockItem.description }}</p> -->
            <div class="row">
                <div class="col-md-11">
                    <h4 *ngIf="!isToUpdate" >
                        <span class="color-blue font-weight-bolder">
                            {{ stockItem.name }}
                        </span>
                        
                        &nbsp;
                        <span class="color-blue font-weight" >
                            (
                                {{ stockItem.itemCode }}
                            )
                        </span>
                    </h4>
                    <span *ngIf="isToUpdate">
                        <app-textbox
                            [parent-form-group]="stockItemFormGroup"
                            tab-index-start="0"
                            control-name="name"
                            [error]="stockItemFormGroup?.controls.name?.errors | estatus"
                            [error-message]="stockItemFormGroup?.controls.name?.errors | econtent"
                            [read-only]="false"
                            label="Item Name"
                            placeholder="Item Name"
                        ></app-textbox>

                        <app-textbox
                            [parent-form-group]="stockItemFormGroup"
                            tab-index-start="0"
                            control-name="itemCode"
                            [error]="stockItemFormGroup?.controls.itemCode?.errors | estatus"
                            [error-message]="stockItemFormGroup?.controls.itemCode?.errors | econtent"
                            [read-only]="false"
                            label="Item Code"
                            placeholder="Item Code"
                        ></app-textbox>
                    </span>

                    <p *ngIf="!isToUpdate" class="card-text mt-2 mb-2">{{ stockItem.description }}</p>
                    <span *ngIf="isToUpdate">
                        <app-textarea
                            [parent-form-group]="stockItemFormGroup"
                            tab-index-start="4"
                            control-name="description"
                            [error]="stockItemFormGroup?.controls.description.errors | estatus"
                            read-only="false"
                            label="Description"
                            placeholder="Description"
                            max-length="250"
                        >
                        </app-textarea>
                    </span>
                </div>
                <div class="col-md-1 text-right">
                  <!-- <button 
                    *ngIf="!isToUpdate" 
                    class="btn btn-sm btn-secondary button--search btn-hover font-weight-bold"
                    (click)="setoUpdate()" 
                    tooltip="Update Item">
                    <i class="fa fa-edit"></i>
                  </button>

                  <button 
                    *ngIf="isToUpdate" 
                    class="btn btn-sm btn-danger button--search btn-hover font-weight-bold" 
                    tooltip="Save Changes"
                    (click)="saveChange()">
                    <i class="fa fa-save"></i>
                  </button> -->
                </div>
            </div>

            <div class="w-100 d-flex justify-content-end p-2" style="background-color: rgb(239, 239, 239);">
                <h3 class="color-red font-weight-bolder mr-3 mb-0" *ngIf="!isToUpdate">₱ {{ makeMoney(stockItem.price) }}</h3>
                <app-textbox
                    *ngIf="isToUpdate"
                    [parent-form-group]="stockItemFormGroup"
                    tab-index-start="0"
                    control-name="price"
                    [error]="stockItemFormGroup?.controls.price?.errors | estatus"
                    [error-message]="stockItemFormGroup?.controls.price?.errors | econtent"
                    [read-only]="false"
                    label="Price"
                    placeholder="Price"
                ></app-textbox>
            </div>

            <div class="card mt-3">
                <!-- <div class="d-flex align-items-center p-2 b-t">
                    <div class="color-blue">Shipping to:</div>
                    <div class="flex"></div>
                    <div>
                        05 Kabilugan St., Gulod, Novaliches, Quezon City
                    </div>
                </div> -->

                <div class="d-flex align-items-center pt-2 pl-2 pr-2 pb-0 b-t" style="border:0">
                    <div class="color-blue">Shipping from: </div>
                    <div class="flex"></div>
                    <div class="address">
                        Lemon Square Building, Muñoz, EDSA, Quezon City
                    </div>
                </div>
                <div class="d-flex align-items-center pl-2 pr-2 pb-2 pt-0 b-t" style="border:0">
                    <div class="color-blue"></div>
                    <div class="flex"></div>
                    <div>
                       <!-- <button class="btn button-blue btn-sm">Change Address</button> -->
                    </div>
                </div>

                <div class="d-flex align-items-center p-2 b-t" style="border:0">
                    <div class="color-blue"></div>
                    <div class="flex"></div>
                    <div>
                       &nbsp;
                    </div>
                </div>

                <div class="d-flex align-items-center p-2 b-t" style="border:0">
                    <div class="color-blue"></div>
                    <div class="flex"></div>
                    <div>
                       &nbsp;
                    </div>
                </div>

                <div class="d-flex align-items-center p-2 b-t" style="border:0">
                    <div class="color-blue"></div>
                    <div class="flex"></div>
                    <div>
                       &nbsp;
                    </div>
                </div>

                <div class="d-flex align-items-center p-2 b-t" style="border:0">
                    <div class="color-blue"></div>
                    <div class="flex"></div>
                    <div>
                       &nbsp;
                    </div>
                </div>

                <div class="d-flex align-items-center p-2 b-t" style="border:0" [formGroup]="stockItemFormGroup">
                    <div class="color-blue">Quantity</div>
                    <div class="quantity">
                      <button class="minus-btn" type="button" (click)="decrement(); quantityChanged()">-</button>
                      <input type="number" class="count" name="quantity" [min]="1" [formControlName]="'quantity'" style="width:100px" (input)="onInput($event); quantityChanged()" restrict-characters>
                      <button class="plus-btn" type="button" (click)="increment(); quantityChanged()">+</button>
                    </div>
                </div>

                <div class="d-flex align-items-center p-2 b-t">
                    <div class="color-blue"></div>
                    <div class="flex"></div>
                    <div>
                        <!-- 13 pieces available  -->
                        <button class="btn button-blue btn-sm ml-2 mr-2"
                            (click)="addToCart()"
                        >
                            <i class="fa fa-cart-plus"></i> &nbsp; Add to Cart
                        </button> 
                        <button class="btn button-red btn-sm" (click)="buyNow()">Buy Now</button>
                    </div>
                </div>

 
            </div>

            <!-- soemeting here -->

        </div>
    </div>

</div>
