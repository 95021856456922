<app-loader *ngIf="loader"></app-loader>
<div class="modal-body" *ngIf="activeTab != 3">
  <form id=" form" novalidate="">
    <div id="rootwizard">
      <ul class="nav mb-3">
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 1 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >1</span
            >
            <div class="mt-2">
              <div class="text-muted">Payment Collection</div>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 2 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >2</span
            >
            <div class="mt-2">
              <div class="text-muted">Confirmation</div>
            </div>
          </a>
        </li>
      </ul>

      <hr class="bg-dark" />

      <div class="tab-content p-3">
        <div class="tab-pane" [ngClass]="activeTab == 1 ? 'active' : ''" id="selectBillerTab">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <p class="mb-0"><strong>Biller Details</strong></p>
              <hr style="margin-top: 25px" />

              <app-textbox
                [parent-form-group]="paymentCollectionFormGroup"
                tab-index-start="3"
                control-name="billerName"
                read-only="false"
                [no-special-char]="false"
                label="Biller Name"
                placeholder="Biller Name"
                max-length="50"
              >
              </app-textbox>

              <app-textbox
                [parent-form-group]="paymentCollectionFormGroup"
                tab-index-start="3"
                control-name="accountName"
                read-only="false"
                [no-special-char]="false"
                label="Account Name"
                placeholder="Account Name"
                max-length="50"
              >
              </app-textbox>

              <app-textbox
                [parent-form-group]="paymentCollectionFormGroup"
                tab-index-start="3"
                control-name="accountNumber"
                read-only="false"
                [no-special-char]="false"
                label="Account Number"
                placeholder="Account Number"
                max-length="50"
              >
              </app-textbox>

              <app-datepicker
                [parent-form-group]="paymentCollectionFormGroup"
                tab-index-start="1"
                control-name="dueDate"
                read-only="false"
                label="Due Date"
                placeholder="Due Date"
              >
              </app-datepicker>

              <app-textbox-currency
                [parent-form-group]="paymentCollectionFormGroup"
                tab-index-start="0"
                control-name="amount"
                read-only="false"
                number-only="false"
                label="Amount"
                placeholder="0.00"
                max-length="20"
              >
              </app-textbox-currency>

              <app-textbox-currency
                [parent-form-group]="paymentCollectionFormGroup"
                tab-index-start="0"
                control-name="serviceCharge"
                read-only="false"
                number-only="false"
                label="Service Charge"
                placeholder="0.00"
                max-length="20"
              >
              </app-textbox-currency>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="d-flex justify-content-between align-items-center">
                <p class="mb-0"><strong>Other Details</strong></p>
                <button type="button" class="btn btn-primary btn-sm" (click)="openAddFieldModal(addFieldModal)">
                  Add Field
                </button>
              </div>
              <hr />
              <!-- Display dynamically added fields with a remove button -->
              <div *ngFor="let field of dynamicFields; let i = index" class="form-group row mb-0">
                <div class="col-md-10">
                  <app-textbox
                    [parent-form-group]="newFieldsFormGroup"
                    tab-index-start="0"
                    [control-name]="field.formControlName"
                    [label]="field.label"
                    read-only="false"
                    [read-only]="false"
                    placeholder="Enter {{ field.label }}"
                    max-length="50"
                  ></app-textbox>
                </div>
                <div class="col-md-2 d-flex justify-content-end">
                  <div><button class="btn btn-danger btn-sm" (click)="removeField(i)">Remove</button></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane" [ngClass]="activeTab == 2 ? 'active' : ''" id="newTransTab">
          <div class="row">
            <!-- Title Section (col-12) -->
            <div class="col-12">
              <p class="mb-0"><strong>Payment Collection Confirmation</strong></p>
              <small>Please make sure that all details are correct.</small>
              <hr style="margin-top: 25px" />
            </div>

            <!-- Biller Details Section (col-6) -->
            <div class="col-6">
              <p class="mb-0"><strong>Biller Details</strong></p>
              <hr />

              <div class="list list-row">
                <!-- Biller Name -->
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Biller Name</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ paymentCollectionFormGroup.controls.billerName.value }}
                    </div>
                  </div>
                </div>

                <!-- Account Name -->
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Account Name</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ paymentCollectionFormGroup.controls.accountName.value }}
                    </div>
                  </div>
                </div>

                <!-- Account Number -->
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Account Number</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ paymentCollectionFormGroup.controls.accountNumber.value }}
                    </div>
                  </div>
                </div>

                <!-- Due Date -->
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Due Date</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ paymentCollectionFormGroup.controls.dueDate.value | date: "MMM dd,yyyy" }}
                    </div>
                  </div>
                </div>

                <!-- Amount -->
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Amount</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ paymentCollectionFormGroup.controls.amount.value }}
                    </div>
                  </div>
                </div>

                <!-- Service Charge -->
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Service Charge</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ paymentCollectionFormGroup.controls.serviceCharge.value }}
                    </div>
                  </div>
                </div>

                <!-- Total Amount -->
                <hr />
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Total Amount</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{
                        totalAmount(
                          paymentCollectionFormGroup.controls.serviceCharge.value,
                          paymentCollectionFormGroup.controls.amount.value
                        )
                      }}
                    </div>
                  </div>
                </div>
                <hr />
              </div>
            </div>

            <!-- Other Details Section (col-6) -->
            <div class="col-6">
              <p class="mb-0"><strong>Other Details</strong></p>
              <hr />

              <div class="list list-row">
                <div *ngFor="let field of dynamicFields; let i = index" class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">
                      {{ field.label }}
                    </div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ newFieldsFormGroup.get(field.formControlName)?.value }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Enter PINCODE Section (col-12) -->
            <div class="col-12 mt-3">
              <div class="col-sm-12 col-md-6" style="float: none; margin: auto">
                <div class="item-feed h-2x text-center">
                  <strong>Enter PINCODE</strong>
                </div>
                <code-input
                  [isCodeHidden]="true"
                  [codeLength]="6"
                  (codeChanged)="onCodeChanged($event)"
                  (codeCompleted)="onCodeCompleted($event)"
                  [code]="code"
                >
                </code-input>
              </div>
            </div>
          </div>
        </div>

        <!-- previous next -->
        <div class="row py-3">
          <div class="col-6">
            <a class="btn btn-white button-previous" (click)="setActiveTabPrev()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-arrow-left"
              >
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
              &nbsp; Previous
            </a>
          </div>
          <div class="col-6">
            <div class="d-flex justify-content-end">
              <a *ngIf="activeTab != 2" class="btn btn-white button-next" (click)="setActiveTab()">
                Next &nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-arrow-right"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </a>
              <a
                *ngIf="activeTab == 2 && codeStatus"
                appDebounceClick
                [debounceTime]="500"
                (debounceClick)="processPayment()"
                class="btn btn-info button-next"
                style="color: white"
              >
                Process Payment Collection &nbsp;
                <span class="fa fa-hand-pointer"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Template for the Add New Field Modal -->
<ng-template #addFieldModal let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Add New Field</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <label for="newFieldTitle">Field Title</label>
    <input id="newFieldTitle" type="text" class="form-control" [(ngModel)]="newFieldTitle" />
    <div class="mt-3">
      <button class="btn btn-sm btn-success float-right" (click)="addNewField(); modal.close('Save click')">
        Add Field
      </button>
      <button class="btn btn-sm btn-dark float-right mr-2" (click)="modal.dismiss('Cancel click')">Cancel</button>
    </div>
  </div>
</ng-template>
