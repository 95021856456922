import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { environment } from "src/environments/environment";
import { FormGroup } from "@angular/forms";
import { identity } from "lodash";
import { DatePipe } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private localStorageCacheService: LocalStorageCacheService;

  constructor(
    private httpClient: HttpClient,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService,
    private datepipe : DatePipe
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
  }
  private httpClientNoInterceptor: HttpClient;

  private baseAPIUrl = environment.API_URL;

  public uploadPhotoToImgbb(base64Photo : any): Observable<any> {
    const endPointUrl = `https://api.imgbb.com/1/upload`;
      const formData = new FormData();
      formData.append('key', '8af52e56fd4e5bcc5dfd5b36aa351d78');
      formData.append('image', base64Photo);
    console.log(base64Photo);
    return this.httpClient.post(endPointUrl, formData);
  }

  public getSystemSettings(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SystemSettings`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClientNoInterceptor.get<any>(endPointUrl, { headers: httpHeaders });
  }

  public getCashiersAndManagers(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/CashiersAndManagers`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.get(endPointUrl, { headers: httpHeaders });
  }

  public getManagersUsername(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/ManagersUsername`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.get(endPointUrl, { headers: httpHeaders });
  }

  public GetCashierUsernames(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/POS/Cashiers`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.get(endPointUrl, { headers: httpHeaders });
  }

  

  public getCashiersByManager(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/Cashiers`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.get(endPointUrl, { headers: httpHeaders });
  }

  public getManagerByCashier(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/Manager`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.get(endPointUrl, { headers: httpHeaders });
  }

  public resendEmailVerification(id : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/ReSendEmailVerification`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      var json = {
        id : id,
        redirect : environment.EMAIL_VERIFICATION_REDIRECT
      }

    return this.httpClient.post(endPointUrl,json, { headers: httpHeaders });
  }

  public resetPINCode(email: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/resetPINCode`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      var json = {
        email : email,
        baseUrl : environment.EMAIL_RESET_PINCODE_REDIRECT
      }
    return this.httpClient.post(endPointUrl, json, { headers: httpHeaders });
  }


  public PostContact(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/PostContact`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  
  public GetSalesUserWallet(id): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/SalesUserWallet/${id}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public createTicket(ticketDetails : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/CreateTicket`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.post(endPointUrl,ticketDetails, { headers: httpHeaders });
  }

  
  public getTransactionTickets(ticketDetails : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/GetTransactionTickets`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.post(endPointUrl,ticketDetails, { headers: httpHeaders });
  }

  public reOpenTicket(ticketId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/ReOpenTicket/${ticketId}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.put(endPointUrl, {} ,{ headers: httpHeaders });
  }
  
  
  public sendSecurityPIN(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/Send/SecurityPIN`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.put(`${endPointUrl}`, {} , { headers: httpHeaders });
  }

  public sendOTPRequestFund(email:any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/SendOTP/RequestFund/${email}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.put(`${endPointUrl}`, {} , { headers: httpHeaders });
  }

  public sendSMSPrivate(smsDetail : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/SendSMSPrivate`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.post(endPointUrl,smsDetail, { headers: httpHeaders });
  }

  public realtimeMessage(formValue : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/RealTimeMessage`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      formValue.startDate = this.datepipe.transform(formValue.startDate, 'yyyy-MM-dd');
      formValue.endDate = this.datepipe.transform(formValue.endDate, 'yyyy-MM-dd');

      

    return this.httpClient.post(endPointUrl,formValue, { headers: httpHeaders });
  }
  

  public getRealtimeMessage(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/RealTimeMessage/LastTenRealtimeMessage`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }
  

  public getRealtimeMessageSingle(id): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/RealTimeMessage/${id}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }
  
  public getSalesUserActivationList(username: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/UserActivationList/${username}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.get(endPointUrl, { headers: httpHeaders });
  }


  public getSalesUserActivation(id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/UserActivation/${id}`;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Accept", "application/json");

    return this.httpClient.get(endPointUrl, { headers: httpHeaders });
  }

  
  public getBankCheckOutOption(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/BankCheckOutOption`;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Accept", "application/json");

    return this.httpClient.get(endPointUrl, { headers: httpHeaders });
  }

  public postGenerateCheckout(formValue : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/GenerateCheckout`;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Accept", "application/json");

    return this.httpClient.post(endPointUrl,formValue, { headers: httpHeaders });
  }
  


  // tslint:disable-next-line: typedef
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  // tslint:disable-next-line: typedef
  private log(message: string) {
    //
  }
}
