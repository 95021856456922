import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { ManagerUsersService } from 'src/data/services/web/manager-users.service';
import { VipcoTransactionService } from 'src/data/services/web/vipco-transaction.service';
import { WalletService } from 'src/data/services/web/wallet.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { AngularFirestore } from '@angular/fire/firestore';
@Component({
  selector: 'app-vips-merchant',
  templateUrl: './vips-merchant.component.html',
  styleUrls: ['./vips-merchant.component.less']
})
export class VipsMerchantComponent implements OnInit {

  public localStorageCacheService: LocalStorageCacheService;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value:any = "";
  emptyString: any = "";
  message: boolean = false;
  kms_input: boolean= false;
  kmslimit: boolean = true;

  searchByFormGroup: FormGroup;

  users: any = [];
  // [
  //   { "id": 1, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 2, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 3, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 4, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 5, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 6, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 7, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true }
  // ];

  transactions: any = [];
  // [
  //   { "id": 1, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10001, "rewards": 50, "status": 2 },
  //   { "id": 2, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10002, "rewards": 50, "status": 2 },
  //   { "id": 3, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10003, "rewards": 50, "status": 2 },
  //   { "id": 4, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10004, "rewards": 50, "status": 2 },
  //   { "id": 5, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10005, "rewards": 50, "status": 2 },
  //   { "id": 6, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10006, "rewards": 50, "status": 2 },
  //   { "id": 7, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10007, "rewards": 50, "status": 2 }
  // ];

  constructor(localStorageCacheService: LocalStorageCacheService,
    private modalServe: NgbModal,
    private usersService: ManagerUsersService,
    private walletService: WalletService,
    private vipcoTransactionService: VipcoTransactionService,
    private store: AngularFirestore,
    public webSettingsService: WebSettingsService) {

    this.localStorageCacheService = localStorageCacheService;
  }

  ngOnInit(): void {
    this.value = this.localStorageCacheService.getStorage("forQR");
    this.getManagerUser(this.localStorageCacheService.getStorage("id"));
    // this.getManagerUser(106);
    this.initReceipt();
    this.getWallet();
    this.getVipsUsers();
  }


  userCount: any;
  managerCount: any;
  riderCount: any;

getVipsUsers(){
  this.vipcoTransactionService.getVipsLinkedUsers().subscribe((result) => {
    this.users = (result.response_data).filter(x=>x.Usertype == "LEVEL_6" || x.Usertype == "LEVEL_7");
    this.userCount = (result.response_data).filter(x=>x.Usertype != "LEVEL_6" && x.Usertype != "LEVEL_7" && x.Usertype != "LEVEL_8" && x.Usertype != "LEVEL_9").length;
    this.managerCount = (result.response_data).filter(x=>x.Usertype == "LEVEL_6" || x.Usertype == "LEVEL_7").length;
    this.riderCount = (result.response_data).filter(x=>x.Usertype == "LEVEL_8" || x.Usertype == "LEVEL_9").length;

    // if (result.data.length == 0 && result.pageNumber != 1) {
    //   this.getVIPCOTransactions(result.previousPage, _sortBy, _sort);
    // } else {
    //   this.transactions = result;
    // }
  });
}

getUserType(userType){
  switch (userType) {
    case "LEVEL_0":
    case "LEVEL_1":
    case "LEVEL_2":
    case "LEVEL_3":
    case "LEVEL_4":
    case "LEVEL_5":
      return "User";
    case "LEVEL_6":
    case "LEVEL_7":
      return "Merchant";
    case "LEVEL_8":
    case "LEVEL_9":
      return "Rider";

    default:
      break;
  }

}

  getWallet(): void {
    this.walletService.getWallet().subscribe((result) => {
      this.wallet = result.wallet;
    });
  }

  wallet: any;
  user: any;
  getManagerUser(id: any): void {
    this.usersService.getManagerUser(id).subscribe((result) => {
      this.user = result.data;
    });
  }


  searchData(){

  }

  initSearchFormGroup(): void {
    this.searchByFormGroup = new FormGroup({
      showRows: new FormControl(null),
      dateRange: new FormControl(this.emptyString),
      searchString: new FormControl(null),
    });
  }

  // @ViewChild('riderViewModal') riderViewModal: TemplateRef<any>;
  @ViewChild('merchantViewModal') merchantViewModal: TemplateRef<any>;
  // detailRiderModal: NgbModalRef;
  detailMerchantModal: NgbModalRef;
  // selectedRider:any;
  selectedMerchant:any;
  selectedNumber: any;
  async viewUser(user: any){
    this.selectedNumber = user?.MobileNo;
    let userNumber = user?.MobileNo;
    // if(this.getUserType(user?.Usertype) == "Rider"){
    //   await this.store.collection("rider_registration").doc(userNumber).valueChanges().subscribe((val) => {
    //     this.selectedRider = val;
    //   });
    //   debugger
    //   this.detailRiderModal = this.modalServe.open(this.riderViewModal, { centered: true, size: "md" });
    // }
    // else
     if(this.getUserType(user?.Usertype) == "Merchant"){
      await this.store.collection("business_registration").doc(userNumber).valueChanges().subscribe((val) => {
        this.selectedMerchant = val;
        console.log(this.selectedMerchant);
      });
      this.detailMerchantModal = this.modalServe.open(this.merchantViewModal, { centered: true, size: "md" });
    }
  }
 save:boolean = false;
  edit(){
    this.kms_input = true;
    this.kmslimit = false;
    this.save =true;
  }
  kmsNmbr:any;
  submitKms(id:any){
    debugger
    this.store.firestore
    .collection("business_registration")
    .doc(id)
    .set({
      kms_limit: this.kmsNmbr
    },
    {
      merge:true,
    });
    console.log(this.kmsNmbr);
    this.kms_input = false;
    this.kmslimit = true;
    this.save = false;
  }
  close(){
    this.kmslimit = true;
    this.save = false;
    this.kms_input = false;
  }

  imgSrc:string;
  onClick(event){
    const imgElem = event.target;
    var target = event.target || event.srcElement || event.currentTarget;
    var srcAttr = target.attributes.src;
    this.imgSrc = srcAttr.nodeValue;
  }

  receipt: any;
  initReceipt() {
    this.webSettingsService.getWebSettingsByIdentifier("GoVIPX_QR_TEMPLATE").subscribe((result) => {
      this.receipt = result.data;
    });
  }

  openQR(content: any){
    this.modalServe.open(content, { centered: true, size: "lg" });
  }

  getQR(myQRTemplate: any){
    var qrtemplate = myQRTemplate.innerHTML;

    var Newstr = (this.receipt[0].value)
      .replace("{{QR}}", qrtemplate);

    this.PrintReceipt(Newstr);
  }

  PrintReceipt(Newstr: any)
  {
    var mywindow = window.open('', 'PRINT', 'height=700,width=1000');
    mywindow.document.write(Newstr);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    this.modalServe.dismissAll();

    return true;
  }
//   selectedUser:any;
//   remarkModal: NgbModalRef;
// openRemark(content, user){
//   debugger
//   this.selectedUser = user;
//   console.log(this.selectedUser);

//   // if merchant is not yet active
//   if(this.selectedUser === undefined){
//     this.message = true;
//     setTimeout(() => {
//       this.message = false;
//    }, 4000);
//   }
//   else{
//     this.remarkModal = this.modalServe.open(content, { centered: true, size: "md" });
//   }
// }
// close(){
//   this.message = false;
// }
// selectedUser:any;
// remarkModal: NgbModalRef;
// openRemark(content, user){
//   debugger
//   this.selectedUser = user;
//   console.log(user);
//   this.remarkModal = this.modalServe.open(content, { centered: true, size: "md" });
// }


}
