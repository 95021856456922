import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { MultisysService } from 'src/data/services/web/multisys.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { CryptoService } from 'src/data/crypto/crypto.service';
import { AuthService } from 'src/data/services/authentication/auth.service';
import { environment } from 'src/environments/environment';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { VipProtectService } from 'src/data/services/web/vip-protect.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { Select2OptionData } from "ng-select2";
import { ExportExcelService } from 'src/data/services/web/export-excel.service';
import { SunlifeGrepaService } from 'src/data/services/web/sunlife-grepa.service';
import { SunlifeGrepaExportExcelService } from 'src/data/services/web/sunlife-grepa-export-excel.service';

@Component({
  selector: 'app-sunlife-grepa-reports',
  templateUrl: './sunlife-grepa-reports.component.html',
  styleUrls: ['./sunlife-grepa-reports.component.less']
})
export class SunlifeGrepaReportsComponent implements OnInit {
  billerFormGroup : FormGroup;
  searchByFormGroup : FormGroup;
  formBuilder : FormBuilder;
  loader : boolean = false;
  emptyString : any = "";
  public transactions: any = [];
  public totals: any;
  public transactionsForExcel: any = [];
  public transaction: any;
  p: number = 1;
  public showRowsOption: Array<Select2OptionData>;
  public productsOption: Array<Select2OptionData>;
  
  constructor(private sunlifeGrepaService : SunlifeGrepaService, 
    private router: Router,
    private currencyPipe : CurrencyPipe,
    formBuilder : FormBuilder,
    public ete: SunlifeGrepaExportExcelService,
    private route: ActivatedRoute, 
    private cryptoService: CryptoService,
    public datepipe: DatePipe,
    localStorageCacheService: LocalStorageCacheService,
    private modalBService: NgbModal,
    private websettings : WebSettingsService) {
    this.formBuilder = formBuilder;
    PNotifyButtons;
  }


  ngOnInit(): void {
    this.initSearchFormGroup();
    this.getTransaction();
    this.getShowRows();
    this.getProducts();
  }


  initSearchFormGroup(): void {
    this.searchByFormGroup = new FormGroup({
      showRows: new FormControl(null),
      dateRange: new FormControl(this.emptyString),
      groupPolicyNumber: new FormControl(this.emptyString),
      customerName: new FormControl(this.emptyString),
      productCode: new FormControl(null)
    });
  }

  
  getShowRows() {
    this.websettings.getWebSettingsByIdentifier("SHOW_ROWS").subscribe((result) => {
     
      this.showRowsOption = result.data.map((status) => {
        return {
          id: status.parameter,
          text: status.value,
        };
      });
       
    });
  }

  getProducts() {
    this.websettings.getWebSettingsByIdentifier("SUNLIFE_GREPA_PRODUCTS").subscribe((result) => {
     
      this.productsOption = result.data.map((status) => {
        return {
          id: status.parameter,
          text: status.value.plan,
        };
      });
       
    });
  }

  getTransaction(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader =true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    
    const productCode : string = this.searchByFormGroup.controls.productCode.value;
    const customerName : string = this.searchByFormGroup.controls.customerName.value;
    const showRows : string = this.searchByFormGroup.controls.showRows.value;
    const groupPolicyNumber : string = this.searchByFormGroup.controls.groupPolicyNumber.value;
    const dateRange : string = this.searchByFormGroup.controls.dateRange.value;
    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_productCode: string = productCode ? `productCode=${productCode}&` : "";
    const queryString_customerName: string = customerName ? `customerName=${customerName}&` : "";
    const queryString_groupPolicyNumber: string = groupPolicyNumber ? `groupPolicyNumber=${groupPolicyNumber}&` : "";
    const queryString_showRows: string = showRows ? `showRows=${showRows}&` : "showRows=10&";
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    const queryString = queryString_orderby + queryString_order +  queryString_productCode+ queryString_customerName + 
    queryString_groupPolicyNumber + queryString_dateRange + queryString_showRows;
 
    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/SunlifeGrepa/Partner/Transactions?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/SunlifeGrepa/Partner/Transactions?${queryString}`;
      }
    }

    this.sunlifeGrepaService.getTransactions(endPointUrl.slice(0, -1)).subscribe((result) => {
      this.loader =false;
      if(result){
        if (result.data.length == 0 && result.pageNumber != 1) {
          this.getTransaction(result.previousPage, _sortBy, _sort);
        } else {
          this.transactions = result.data;
          this.totals = result.total;
          console.log(this.totals);
        }
      }
     
    });
  }

 

  searchData(){
    this.getTransaction(null,null,null,true);

  }

  createdDate = "";
  searchString = "";
  
  private _sortBy: any;
  private _sort: any;

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.createdDate = field == "createdDate" ? "th-inner sortable both " + sortBy : "";
   
    this.getTransaction(
      `${environment.API_URL}/api/SunlifeGrepa/Partner/Transactions?pageNumber=${
        page ? page : this.transactions.pageNumber
      }`,
      this._sortBy,
      this._sort
    );
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.transactions?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }

  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p = pageNumber;
    return `${environment.API_URL}/api/SunlifeGrepa/Partner/Transactions?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }

  statusOfAmount(money: any): boolean {
    return money < 0 ? false : true;
  }

  makeMoney(money: any) {
    if(money){
      return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
    }else{
      return "N/A";
    }
   
  }

  requestId;
  setSelectedTransaction(transaction: any) {
   
    this.transaction = transaction;
    this.requestId = this.transaction.requestId;
   
  }
  

  
  dataForExcel = [];
  public Transactions: any;

  exportToExcel() {
    this.Transactions = [];
    this.dataForExcel = [];
    this.getTransactionForExcel(null,null,null,true);
  }


  getTransactionForExcel(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader =true;
   
    const productCode : string = this.searchByFormGroup.controls.productCode.value;
    const customerName : string = this.searchByFormGroup.controls.customerName.value;
    const showRows : string = '1000000';
    const groupPolicyNumber : string = this.searchByFormGroup.controls.groupPolicyNumber.value;
    const dateRange : string = this.searchByFormGroup.controls.dateRange.value;
    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_productCode: string = productCode ? `productCode=${productCode}&` : "";
    const queryString_customerName: string = customerName ? `customerName=${customerName}&` : "";
    const queryString_groupPolicyNumber: string = groupPolicyNumber ? `groupPolicyNumber=${groupPolicyNumber}&` : "";
    const queryString_showRows: string = showRows ? `showRows=${showRows}&` : "showRows=1000000&";
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    const queryString = queryString_orderby + queryString_order +  queryString_productCode+ queryString_customerName + 
    queryString_groupPolicyNumber + queryString_dateRange + queryString_showRows;
 
    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/SunlifeGrepa/Partner/Transactions?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/SunlifeGrepa/Partner/Transactions?${queryString}`;
      }
    }

    this.sunlifeGrepaService.getTransactions(endPointUrl.slice(0, -1)).subscribe((result) => {
      this.loader =false;
      if(result){
        var datStr =  this.datepipe.transform(new Date(),"MMddyyyyhhmmss")
        this.generateExcel(result.data.data,result.total,`SUNLIFE-GREPA-${datStr}`)
      }
     
    });
  }

  generateExcel(transactions: any,totals : any, name: any): void {
    this.Transactions = [];

    transactions.forEach(transaction => {
      let meta = [
        {
            "Seq":	transaction.id,
            "Transaction Date": this.datepipe.transform(transaction.createdDate,"MM/dd/yyyy"),
            "Effectivity Date":	this.datepipe.transform(transaction.effectivityDate,"MM/dd/yyyy"),
            "POC Number":	transaction.pocNumber,
            "Customer Name":	transaction.customerName,
            "Customer Bday":	 this.datepipe.transform(transaction.birthDate,"MM/dd/yyyy"),
            "Address":	transaction.address,
            "Contact Number":	transaction.contactNumber,
            "Beneficiary Name":	transaction.beneficiaryName,
            "Relationship": transaction.relationship,
            "Gross Premium":	this.makeMoney(transaction.grossPremium),       
            "Service Fee(45% of gross premium)":	this.makeMoney(transaction.serviceFee),       
            "Witholding Tax(Service Fee x 2%)":this.makeMoney(transaction.withHoldingTax),    
            "Net Premium to SLGFI((Gross Premium - Service Fee) + Witholding Tax)":this.makeMoney(transaction.netPremiumToSLGFI),
            "Trasact by username": transaction.transactBy,
            "Manager username": transaction.managerUsername
        }
      ]
      this.Transactions.push(meta);
    });
    
    for(var x = 0; x < this.Transactions.length; x++){
      this.dataForExcel.push(Object.values(this.Transactions[x][0]));
    }

    let reportData = {
      title: name + ' Report',
      data: this.dataForExcel,
      headers: Object.keys(this.Transactions[0][0]),
      dateStart  :this.searchByFormGroup.controls.dateRange.value ? this.datepipe.transform(this.searchByFormGroup.controls.dateRange.value[0],"MM/dd/yyyy"): "ALL" ,
      dateEnd  :this.searchByFormGroup.controls.dateRange.value ?  this.datepipe.transform(this.searchByFormGroup.controls.dateRange.value[1],"MM/dd/yyyy"): "ALL" ,
      productCode : this.searchByFormGroup.controls.productCode.value?this.searchByFormGroup.controls.productCode.value : "ALL",
      totals : totals
    }

    this.ete.exportExcel(reportData, name);
  }
}
