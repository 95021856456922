import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-partners-button",
  templateUrl: "./partners-button.component.html",
  styleUrls: ["./partners-button.component.less"],
})
export class PartnersButtonComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
