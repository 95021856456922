import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-store-item-create',
  templateUrl: './store-item-create.component.html',
  styleUrls: ['./store-item-create.component.less']
})
export class StoreItemCreateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
