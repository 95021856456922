import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';

import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { CurrencyPipe } from '@angular/common';
import { PrintService } from 'src/data/services/web/print.service';
import { BayadService } from 'src/data/services/web/bayad.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5
import { DatePipe } from '@angular/common'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-bayad',
  templateUrl: './bayad.component.html',
  styleUrls: ['./bayad.component.less']
})
export class BayadComponent implements OnInit {

  @Input("application") application: any;
  @Input("shortcutAccumulator") shortcutAccumulator: any;
  @Input("shortcutCode") shortcutCode: any;

  @ViewChild('verifyTransactionModal') verifyTransactionModal: TemplateRef<any>;

  formBuilder : FormBuilder;
  billerFormGroup : FormGroup;
  bayadService : BayadService;
  public emptyString = "";
  public billers : any = [];
  public biller : any;
  public billerCode : any;
  public billerValidationNumber : any;
  public billerTypes : any = [];
  public billersByBillerTypes : any = [];
  public selectedBillerCode : any;
  ;

  public activeTab = 1;
  public loader = true;
  public fee : any;
  
  @Output("closeModal") closeModal = new EventEmitter();
  @Output("closeOnly") closeOnly = new EventEmitter();
  @Output("getShortcutApps") getShortcutApps = new EventEmitter();
  
  constructor(bayadService : BayadService, 
    private router: Router,
    localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    formBuilder : FormBuilder,
    private modalService: NgbModal,
    public datepipe: DatePipe) {
    this.bayadService = bayadService;
    this.formBuilder = formBuilder;
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.initBillerTypeFormGroup();
    this.getBillers();

    if(this.shortcutCode){
      setTimeout(() => {
        this.initShortcut();
      }, 1000);
    }
    // if(this.shortcutCode){
    //   this.initShortcut();
    // }
  }

  // initShortcut(): void {
  //   this.billerCode = this.shortcutCode.itemCode;
  //   this.getBiller();
  // }

  initShortcut(): void {
    this.billerFormGroup.controls.billerType.patchValue(this.shortcutCode.category);
    this.getBillersByBillerType(this.shortcutCode.category);
    this.billerFormGroup.controls.billerCode.patchValue(this.shortcutCode.itemCode);
    this.billerCode = this.shortcutCode.itemCode;
    this.getBiller();
    debugger
  }

  async initBillerTypeFormGroup() {
    this.billerFormGroup = await new FormGroup({
      billerType: new FormControl(this.emptyString, [Validators.required]),
      billerCode: new FormControl(this.emptyString, [Validators.required]),
      validationNumber: new FormControl(this.emptyString),
      params: this.formBuilder.group({})
    });
  }

  reInitBillerTypeFormGroup(): void {
    this.billerFormGroup = new FormGroup({
      billerType: new FormControl(this.billerFormGroup.controls.billerType.value, [Validators.required]),
      billerCode: new FormControl(this.billerFormGroup.controls.billerCode.value, [Validators.required]),
      params: this.formBuilder.group({})
    });
  }

  isShortcutExisting: any = false;
  checkExisting(billerCode: any){
    this.isShortcutExisting = this.shortcutAccumulator.some(x => x.itemCode == billerCode);
    debugger
    return this.shortcutAccumulator.some(x => x.itemCode == billerCode);
  }

  getBillers() {
    this.bayadService.getBillers().subscribe((billers) => {
      this.billers = billers;
      this.getBillerTypes(billers);
    }, error => {
      PNotify.error({
        title: "Service Unavailable",
        text: "[Error 331] Partner services unreachable at the moment. Please retry after 30 minutes or check our announcement for more details. Thank you for your usual support and cooperation.",
      });
      this.loader = false;
      this.closeOnly.emit();
    });
  }

  getBillerTypes(billers : any){
    var lookup = {};
    var items = billers;
    
    for (var item, i = 0; item = items[i++];) {
      var type = item.type;
    
      if (!(type in lookup)) {
        lookup[type] = 1;
        this.billerTypes.push(type);
      }
      
    }
    this.loader = false;
  }

  billerType: any;
  getBillersByBillerType(billerType : any){
    this.billerType = billerType;
    this.billersByBillerTypes = this.billers.filter(x=>x.type == billerType);
  }

  setBillerCode(billerCode : any){
    this.billerCode = billerCode;
    this.checkExisting(billerCode);
  }

  addShortcut(){
    this.bayadService.addFavorite(this.billerCode, this.billerType).subscribe((result) => {
      if(result){
        PNotify.success({
          title: "Success",
          text: "Biller successfully added to your favorites.",
        });

        this.getShortcutApps.emit();
        this.isShortcutExisting = true;
      } else {
        PNotify.error({
          title: "Duplicate",
          text: "Biller is already exist.",
        });
        this.getShortcutApps.emit();
        this.isShortcutExisting = true;
      }
    }, error => {
      PNotify.error({
        title: "Error",
        text: "Something went wrong. Please cntact support for assistance.",
      });
    });
  }
  
  removeShortcut(){
    this.bayadService.removeFavorite(this.billerCode, this.billerType).subscribe((result) => {
      PNotify.success({
        title: "Success",
        text: "Biller successfully removed to your favorites.",
      });
      this.getShortcutApps.emit();
      this.isShortcutExisting = false;
    }, error => {
      PNotify.error({
        title: "Error",
        text: "Something went wrong. Please cntact support for assistance.",
      });
    });
  }

  getBiller() {
    this.loader = true;
    this.bayadService.getBiller(this.billerCode).subscribe((biller) => {
      if(biller.meta.length == 0){
        PNotify.error({
          title: "Error",
          text: "Biller is unavailable.",
        });
        this.loader = false;
      }else{
        debugger
        this.activeTab++;
        this.addBillerParam(biller.meta);
        this.fee = biller.fee;
        this.biller = biller;
        this.loader = false;
      }
    });
  }

  filterErrorMessages(errorMessages) {
    return errorMessages.filter(x=>x.rule != 'required');
  }



  confirmationMessage: any;
  validationNumber: any;
  inquireBiller() {
    this.loader = true;
    debugger

    var jsonParams = {};

    this.biller.meta.forEach(param => {
        if(param.type == 'Calendar'){
          let stringDate =this.datepipe.transform(this.paramsFormGroup().controls[param.field].value, param.date_format.replaceAll("D","d").replaceAll("Y","y"));
          jsonParams[param.field] = stringDate;
        }else{
          jsonParams[param.field] = this.paramsFormGroup().controls[param.field].value;
        }
    });


    this.bayadService.inquireBiller(jsonParams,this.billerCode, this.fee ?? "0.00").subscribe((biller) => {
        if(biller.code == 0){
          PNotify.info({
            title: "Account Validation",
            text: "Accepted",
          });

          this.billerValidationNumber = biller.validationNumber;
          this.loader = false;
          this.code = "";
          this.activeTab = 3;
          this.codeStatus=false;
        } else if(biller.code == 1) {
          this.confirmationMessage = biller.message;
          this.validationNumber = biller.validationNumber;
          this.openViewApplication(this.verifyTransactionModal);
        }
    }, error => {

      debugger
      if(error?.status == 400){
        PNotify.error({
          title: "Validation Failed",
          text: error?.error?.details?.message ? error?.error?.details?.message : error?.error?.message
        });
        this.loader = false;
        this.code = "";
        this.codeStatus = false;
      } else if(error?.error?.status == 490){
        PNotify.error({
          title: "Validation Failed",
          text: error?.error?.reason == "[183] Wallet balance is below threshold" ? "[183] Partner is currently not available": error?.error?.reason,
        });
        this.loader = false;
        this.code = "";
        this.codeStatus = false;

      } else if(error?.error?.status == 500){
        PNotify.error({
          title: "Validation Failed",
          text:"Connection time out between JuanPay and BayadCenter",
        });
        this.loader = false;
        this.code = "";
        this.codeStatus = false;
      } else{
        PNotify.error({
          title: "Validation Failed",
          text: "Connection time out between JuanPay and BayadCenter",
        });
        this.loader = false;
        this.code = "";
        this.codeStatus = false;
      }

     
      


    });
  }

  transactionRef: NgbModalRef;
  openViewApplication(modal) {
    this.transactionRef = this.modalService.open(modal, {
      centered: true,
      size: "md",
      backdrop: "static",
      keyboard: false,
    });
  }

  processVerification(){
    PNotify.info({
      title: "Account Validation",
      text: "Accepted",
    });

    this.transactionRef.close();
    this.billerValidationNumber = this.validationNumber;
    this.loader = false;
    this.code = "";
    this.activeTab = 3;
    this.codeStatus=false;
  }

  transformParamValue(param: any){
    if(param.type == 'Calendar'){
      let stringDate =this.datepipe.transform(this.paramsFormGroup().controls[param.field].value, param.date_format.replaceAll("D","d").replaceAll("Y","y"));
      return stringDate;
    }
    else if(param.type == 'Dropdown'){
      return (param.options.filter(x=>x.key ==this.paramsFormGroup().controls[param.field].value )[0]).value;
    }
    else{
      return this.paramsFormGroup().controls[param.field].value;
    }
  }

  paramsFormGroup() : FormGroup{
    return this.billerFormGroup.get('params') as FormGroup;
  } 


  addBillerParam(meta: any){
    if (this.selectedBillerCode != this.billerFormGroup.controls.billerCode.value){
        this.reInitBillerTypeFormGroup();
        
        meta.forEach(param => {
          (this.billerFormGroup.get("params") as FormGroup).addControl(
            param.field,
            new FormControl("")
          );
        });
   }
    this.selectedBillerCode = this.billerFormGroup.controls.billerCode.value;
  }


maxChar(max_char){
  return max_char != null ? parseInt(max_char) : 50;
}


isAlphaDash(str)
{
 var regexp = /^[a-z0-9_\-]+$/i;
  debugger
        if (regexp.test(str))
          {
            if(str.toString().includes("-")){

              return true;
            }else{
              return false;
            }
          }
        else
          {
            return false;
          }
}



  // checkErrorStatus(meta,  control : FormControl){

  //   if(meta.required == "true" && control.value == ""){
  //     return false;
  //   }else{
  //     return true;
  //   }
  // }
  //   contructErrorMessage(meta,  control : FormControl){
  //     debugger
  //     var error_message;
  //     if(meta.required == "true" && control.value == ""){
  //       error_message = error_message + (meta.error_messages.filter(x=>x.rule != 'required')[0]).message;
  //     }
  //     return error_message;
  //   }

  setActiveTab(){
    
    if(this.activeTab == 1){
      debugger
      if(this.billerFormGroup.controls.billerType.value == "" || this.billerFormGroup.controls.billerCode.value == ""){
        PNotify.error({
          title: "Error",
          text: "All fields are required to proceed to next step.",
        });
      }
      else{
        this.getBiller();
      }
    } else if(this.activeTab == 2){

      var hasError : boolean  = false;
      this.biller.meta.forEach(param => {
        var errorMessage :String = "";
        //required
        if(!this.paramsFormGroup().controls[param.field].value && JSON.parse(param.is_required)){
          errorMessage =  errorMessage + '<br/>' + param.error_messages.filter(x=>x.rule == 'required')[0].message;
          hasError = true;
        }
      //alpha_dash
      if(param.type == "Text" && param.error_messages.filter(x=>x.rule == 'alpha_dash').length > 0){
        if(!this.isAlphaDash(this.paramsFormGroup().controls[param.field].value)){
          errorMessage =  errorMessage + '<br/>' + param.error_messages.filter(x=>x.rule == 'alpha_dash')[0].message;
          hasError = true;
        }
      }

      //size,min,max _required
      if((param.type == "Text" || param.type == "Digits") && JSON.parse(param.is_required)){
        
        if(param.error_messages.filter(x=>x.rule == 'digits').length > 0){
          if(parseFloat(param.min_char) != parseFloat(this.paramsFormGroup().controls[param.field].value.length)){
            errorMessage = errorMessage + '<br/>' + param.error_messages.filter(x=>x.rule == 'digits')[0].message;
            hasError = true;
          }
        }
         else if(param.error_messages.filter(x=>x.rule == 'digits_between').length > 0){
            if(!(parseFloat(param.min_char) <= parseFloat(this.paramsFormGroup().controls[param.field].value.length)
            && parseFloat(param.max_char) >= parseFloat(this.paramsFormGroup().controls[param.field].value.length))
            ){
              errorMessage = errorMessage + '<br/>' + param.error_messages.filter(x=>x.rule == 'digits_between')[0].message;
              hasError = true;

            }
          }
          else if(param.error_messages.filter(x=>x.rule == 'size').length > 0){
            if(parseFloat(param.min_char) != parseFloat(this.paramsFormGroup().controls[param.field].value.length)){
              errorMessage = errorMessage + '<br/>' + param.error_messages.filter(x=>x.rule == 'size')[0].message;
              hasError = true;
            }
          }
          else{
            if(parseFloat(param.min_char) > parseFloat(this.paramsFormGroup().controls[param.field].value.length)){
              errorMessage = errorMessage + '<br/>' + param.error_messages.filter(x=>x.rule == 'min')[0].message;
              hasError = true;
            }
              if(parseFloat(param.max_char) < parseFloat(this.paramsFormGroup().controls[param.field].value.length)){
              errorMessage = errorMessage + '<br/>' + param.error_messages.filter(x=>x.rule == 'max')[0].message;
              hasError = true;
            }
          }
      }

      //size,min,max not_required
      if((param.type == "Text" || param.type == "Digits") && !JSON.parse(param.is_required) && this.paramsFormGroup().controls[param.field].value.toString() != ""){
       
        if(param.error_messages.filter(x=>x.rule == 'digits').length > 0){
          if(parseFloat(param.min_char) != parseFloat(this.paramsFormGroup().controls[param.field].value.length)){
            errorMessage = errorMessage + '<br/>' + param.error_messages.filter(x=>x.rule == 'digits')[0].message;
            hasError = true;
          }
        }
        else if(param.error_messages.filter(x=>x.rule == 'digits_between').length > 0){
          if(!(parseFloat(param.min_char) <= parseFloat(this.paramsFormGroup().controls[param.field].value.length)
          && parseFloat(param.max_char) >= parseFloat(this.paramsFormGroup().controls[param.field].value.length))
          ){
            errorMessage = errorMessage + '<br/>' + param.error_messages.filter(x=>x.rule == 'digits_between')[0].message;
            hasError = true;

          }
        }
         else if(param.error_messages.filter(x=>x.rule == 'size').length > 0){
          if(parseFloat(param.min_char) != parseFloat(this.paramsFormGroup().controls[param.field].value.length)){
            errorMessage = errorMessage + '<br/>' + param.error_messages.filter(x=>x.rule == 'size')[0].message;
            hasError = true;
          }
        }else{
          if(parseFloat(param.min_char) > parseFloat(this.paramsFormGroup().controls[param.field].value.length)){
            errorMessage = errorMessage + '<br/>' + param.error_messages.filter(x=>x.rule == 'min')[0].message;
            hasError = true;
          }
            if(parseFloat(param.max_char) < parseFloat(this.paramsFormGroup().controls[param.field].value.length)){
            errorMessage = errorMessage + '<br/>' + param.error_messages.filter(x=>x.rule == 'max')[0].message;
            hasError = true;
          }
        }
      }


        //digits_between,min,max _required
        if(param.type == "Number" && JSON.parse(param.is_required)){
        
         
            if(parseFloat(param.min_value) > parseFloat(this.paramsFormGroup().controls[param.field].value.replaceAll(',',''))){
              errorMessage = errorMessage + '<br/>' + param.error_messages.filter(x=>x.rule == 'min')[0].message;
              hasError = true;
            }
              if(parseFloat(param.max_value) < parseFloat(this.paramsFormGroup().controls[param.field].value.replaceAll(',',''))){
              errorMessage = errorMessage + '<br/>' + param.error_messages.filter(x=>x.rule == 'max')[0].message;
              hasError = true;
            }
              
        }

        //digits_between,min,max not_required
        if(param.type == "Number" && !JSON.parse(param.is_required) && this.paramsFormGroup().controls[param.field].value.toString() != ""){
         
          
            if(parseFloat(param.min_value) > parseFloat(this.paramsFormGroup().controls[param.field].value.replaceAll(',',''))){
              errorMessage = errorMessage + '<br/>' + param.error_messages.filter(x=>x.rule == 'min')[0].message;
              hasError = true;
            }
              if(parseFloat(param.max_value) < parseFloat(this.paramsFormGroup().controls[param.field].value.replaceAll(',',''))){
              errorMessage = errorMessage + '<br/>' + param.error_messages.filter(x=>x.rule == 'max')[0].message;
              hasError = true;
            }
              
        }
        
        this.paramsFormGroup().controls[param.field].setErrors({'required': errorMessage.substring(5,errorMessage.length)});
      });
  

      

      

      if(hasError){
        PNotify.error({
          title: "Request denied",
          text: "Please fill up all the required fields.",
        });
      } else{
        this.inquireBiller();
      }

     
    }
  }

  setActiveTabPrev(){
    if(this.activeTab == 1){
      PNotify.error({
        title: "Error",
        text: "Action Denied.",
      });
    }else{
      this.activeTab--;
    }
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  codeStatus : boolean = false;
  code : any;
  onCodeCompleted(code: string) {
    this.code = code;
    this.codeStatus = code.length == 6;
  }


  processPayment(){
      this.loader = true;
      this.bayadService.processPayment(
        this.paramsFormGroup().value,
        this.billerCode, 
        this.fee, 
        this.biller.name, 
        this.billerFormGroup.controls.billerType.value,
        this.code,
        this.billerValidationNumber)
        .subscribe((biller) => {
          PNotify.success({
            title: "Payment Success",
            text: biller.reason,
          });
          this.loader = false;
          this.code = "";
          this.codeStatus=false;
          this.closeModal.emit();
      }, error => {
        if(error.error.status == 490){
          PNotify.error({
            title: "Payment Failed",
            text: error.error.reason == "[183] Wallet balance is below threshold" ? "[183] Partner is currently not available": error?.error?.reason,
          });
          this.loader = false;
          this.code = "";
          this.codeStatus = false;

        } else if (error.error.status == 400){
          PNotify.error({
            title: "Payment Failed",
            text: error?.error?.details?.message ? error?.error?.details?.message : error?.error?.message
          });
          this.loader = false;
          this.code = "";
          this.codeStatus = false;
          
        } else if (error.error.status == 500){
          PNotify.error({
            title: "Payment Failed",
            text: "Transaction Error: Your request could not be processed. Please check your internet connection, consider using a different browser, or update your current browser, and try again. If the problem persists, please try again later or contact our customer service support for assistance."
          });
          this.loader = false;
          this.code = "";
          this.codeStatus = false;
        } else {
          PNotify.error({
            title: "Payment Failed",
            text: error.error?.reason ? error.error?.reason : "Transaction Error: Your request could not be processed. Please check your internet connection, consider using a different browser, or update your current browser, and try again. If the problem persists, please try again later or contact our customer service support for assistance.",
          });
          this.loader = false;
          this.code = "";
          this.codeStatus = false;
        }
      });
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  totalAmount(fee:any,amount:any){
    try  {
    return this.makeMoney(Number(fee) + Number(amount.replaceAll(",","")));
    } catch(e){
      return "0.00";
    }
  }

}
