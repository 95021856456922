import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { MultisysService } from 'src/data/services/web/multisys.service';

import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { CurrencyPipe } from '@angular/common';
import { PrintService } from 'src/data/services/web/print.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-multisys',
  templateUrl: './multisys.component.html',
  styleUrls: ['./multisys.component.less']
})
export class MultisysComponent implements OnInit {

  @Input("application") application: any;
  @Input("shortcutAccumulator") shortcutAccumulator: any;
  @Input("shortcutCode") shortcutCode: any;

  formBuilder : FormBuilder;
  billerFormGroup : FormGroup;
  multisysService : MultisysService;
  public emptyString = "";
  public billers : any = [];
  public biller : any;
  public billerCode : any;
  public billerTypes : any = [];
  public billersByBillerTypes : any = [];
  public selectedBillerCode : any;

  public activeTab = 1;
  public loader = true;
  public fee : any;
  
  @Output("closeModal") closeModal = new EventEmitter();
  @Output("closeOnly") closeOnly = new EventEmitter();
  @Output("getShortcutApps") getShortcutApps = new EventEmitter();
  
  constructor(multisysService : MultisysService, 
    private router: Router,
    localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    formBuilder : FormBuilder) {
    this.multisysService = multisysService;
    this.formBuilder = formBuilder;
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.initBillerTypeFormGroup();
    this.getBillers();

    if(this.shortcutCode){
      setTimeout(() => {
        this.initShortcut();
      }, 1000);
    }
    // if(this.shortcutCode){
    //   this.initShortcut();
    // }
  }

  // initShortcut(): void {
  //   this.billerCode = this.shortcutCode.itemCode;
  //   this.getBiller();
  // }

  initShortcut(): void {
    this.billerFormGroup.controls.billerType.patchValue(this.shortcutCode.category);
    this.getBillersByBillerType(this.shortcutCode.category);
    this.billerFormGroup.controls.billerCode.patchValue(this.shortcutCode.itemCode);
    this.billerCode = this.shortcutCode.itemCode;
    this.getBiller();
    debugger
  }

  async initBillerTypeFormGroup() {
    this.billerFormGroup = await new FormGroup({
      billerType: new FormControl(this.emptyString, [Validators.required]),
      billerCode: new FormControl(this.emptyString, [Validators.required]),
      params: this.formBuilder.group({})
    });
  }

  reInitBillerTypeFormGroup(): void {
    this.billerFormGroup = new FormGroup({
      billerType: new FormControl(this.billerFormGroup.controls.billerType.value, [Validators.required]),
      billerCode: new FormControl(this.billerFormGroup.controls.billerCode.value, [Validators.required]),
      params: this.formBuilder.group({})
    });
  }

  isShortcutExisting: any = false;
  checkExisting(billerCode: any){
    this.isShortcutExisting = this.shortcutAccumulator.some(x => x.itemCode == billerCode);
    debugger
    return this.shortcutAccumulator.some(x => x.itemCode == billerCode);
  }

  getBillers() {
    this.multisysService.getBillers().subscribe((billers) => {
      this.billers = billers;
      this.getBillerTypes(billers);
    }, error => {
      PNotify.error({
        title: "Service Unavailable",
        text: "[Error 331] Partner services unreachable at the moment. Please retry after 30 minutes or check our announcement for more details. Thank you for your usual support and cooperation.",
      });
      this.loader = false;
      this.closeOnly.emit();
    });
  }

  getBillerTypes(billers : any){
    var lookup = {};
    var items = billers;
    
    for (var item, i = 0; item = items[i++];) {
      var type = item.type;
    
      if (!(type in lookup)) {
        lookup[type] = 1;
        this.billerTypes.push(type);
      }
      
    }
    this.loader = false;
  }

  billerType: any;
  getBillersByBillerType(billerType : any){
    this.billerType = billerType;
    this.billersByBillerTypes = this.billers.filter(x=>x.type == billerType);
  }

  setBillerCode(billerCode : any){
    this.billerCode = billerCode;
    this.checkExisting(billerCode);
  }

  addShortcut(){
    this.multisysService.addFavorite(this.billerCode, this.billerType).subscribe((result) => {
      if(result){
        PNotify.success({
          title: "Success",
          text: "Biller successfully added to your favorites.",
        });

        this.getShortcutApps.emit();
        this.isShortcutExisting = true;
      } else {
        PNotify.error({
          title: "Duplicate",
          text: "Biller is already exist.",
        });
        this.getShortcutApps.emit();
        this.isShortcutExisting = true;
      }
    }, error => {
      PNotify.error({
        title: "Error",
        text: "Something went wrong. Please cntact support for assistance.",
      });
    });
  }
  
  removeShortcut(){
    this.multisysService.removeFavorite(this.billerCode, this.billerType).subscribe((result) => {
      PNotify.success({
        title: "Success",
        text: "Biller successfully removed to your favorites.",
      });
      this.getShortcutApps.emit();
      this.isShortcutExisting = false;
    }, error => {
      PNotify.error({
        title: "Error",
        text: "Something went wrong. Please cntact support for assistance.",
      });
    });
  }

  getBiller() {
    this.loader = true;
    this.multisysService.getBiller(this.billerCode).subscribe((biller) => {
      if(biller.meta.length == 0){
        PNotify.error({
          title: "Error",
          text: "Biller is unavailable.",
        });
        this.loader = false;
      }else{
        this.activeTab++;
        this.addBillerParam(biller.meta);
        this.fee = biller.fee;
        this.biller = biller;
        this.loader = false;
      }
    });
  }

  inquireBiller() {
    this.loader = true;
    this.multisysService.inquireBiller(this.paramsFormGroup().value,this.billerCode, this.fee).subscribe((biller) => {
      
        PNotify.info({
          title: "Account Validation",
          text: "Accepted",
        });
        this.loader = false;
        this.code = "";
        this.activeTab = 3;
        this.codeStatus=false;
      
    }, error => {

      if(error.error.status == 490){
        PNotify.error({
          title: "Payment Failed",
          text: error.error.reason == "[183] Wallet balance is below threshold" ? "[183] Partner is currently not available": error.error.reason,
        });
        this.loader = false;
        this.code = "";
        this.codeStatus = false;

      }else{
        PNotify.error({
          title: "Payment Failed",
          text: error.error.reason == "[183] Wallet balance is below threshold" ? "[183] Partner is currently not available": error.error.reason,
        });
        this.loader = false;
        this.code = "";
        this.codeStatus = false;

      }

     



    });
  }

  paramsFormGroup() : FormGroup{
    return this.billerFormGroup.get('params') as FormGroup;
  } 


  addBillerParam(meta: any){
    if (this.selectedBillerCode != this.billerFormGroup.controls.billerCode.value){
        this.reInitBillerTypeFormGroup();
        
        meta.forEach(param => {
          (this.billerFormGroup.get("params") as FormGroup).addControl(
            param.field,
            new FormControl("")
          );
        });
   }
    this.selectedBillerCode = this.billerFormGroup.controls.billerCode.value;
  }

  setActiveTab(){

    if(this.activeTab == 1){
      if(this.billerFormGroup.status == "INVALID"){
        PNotify.error({
          title: "Error",
          text: "All fields are required to proceed to next step.",
        });
      }
      else{
        this.getBiller();
      }
    } else if(this.activeTab == 2){

      var hasError : boolean  = false;
      this.biller.meta.forEach(param => {
        if(!this.paramsFormGroup().controls[param.field].value && param.is_required){
          this.paramsFormGroup().controls[param.field].setErrors({'required': param.label + " is required."});
          hasError = true;
        }
      });

      

      if(hasError){
        PNotify.error({
          title: "Request denied",
          text: "Please fill up all the required fields.",
        });
      }else{
        this.inquireBiller();
      }

     
    }
  }

  setActiveTabPrev(){
    if(this.activeTab == 1){
      PNotify.error({
        title: "Error",
        text: "Action Denied.",
      });
    }else{
      this.activeTab--;
    }
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  codeStatus : boolean = false;
  code : any;
  onCodeCompleted(code: string) {
    this.code = code;
    this.codeStatus = code.length == 6;
  }


  processPayment(){
      this.loader = true;
      this.multisysService.processPayment(
        this.paramsFormGroup().value,
        this.billerCode, 
        this.fee, 
        this.biller.name, 
        this.biller.type,
        this.code)
        .subscribe((biller) => {
          PNotify.success({
            title: "Payment Success",
            text: biller.reason,
          });
          this.loader = false;
          this.code = "";
          this.codeStatus=false;
          this.closeModal.emit();
        
      }, error => {

        if(error.error.status == 490){
          PNotify.error({
            title: "Payment Failed",
            text: error.error.reason == "[183] Wallet balance is below threshold" ? "[183] Partner is currently not available": error.error.reason,
          });
          this.loader = false;
          this.code = "";
          this.codeStatus = false;

        }else{
          PNotify.error({
            title: "Payment Failed",
            text: error.error.reason == "[183] Wallet balance is below threshold" ? "[183] Partner is currently not available": error.error.reason,
          });
          this.loader = false;
          this.code = "";
          this.codeStatus = false;
          this.closeModal.emit();

        }

       



      });
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  totalAmount(fee:any,amount:any){
    try  {
    return this.makeMoney(Number(fee) + Number(amount));
    } catch(e){
      return "0.00";
    }
    }

}
