import { CurrencyPipe, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { InvoiceComponent } from 'src/app/elements/invoice/invoice.component';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { PaymentCollectionService } from 'src/data/services/web/payment-collection.service';
import { PrintService } from 'src/data/services/web/print.service';
import { TransactionService } from 'src/data/services/web/transaction.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5";

@Component({
  selector: 'app-payment-collection-transaction',
  templateUrl: './payment-collection-transaction.component.html',
  styleUrls: ['./payment-collection-transaction.component.less']
})
export class PaymentCollectionTransactionComponent implements OnInit {

  @ViewChild(InvoiceComponent ) child: InvoiceComponent ; 
  @ViewChild('vipLogo') vipLogo: ElementRef;
  @Output("closeModal") closeModal = new EventEmitter();

  public isA4 = new FormControl();
  public receipt: any;

  @Input() requestId: string;
  public data :  any;
  public loader : boolean = false;

  public transactionService: TransactionService;
  public dti: any;
  public tin: any;
  public address: any;
  roleLevelId: any;

  constructor(private paymentCollectionService : PaymentCollectionService, 
    private router: Router,
    public datepipe: DatePipe,
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: NgbModal,
    private webService: WebSettingsService,
    private localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    public printService: PrintService, transactionService: TransactionService) {
      this.transactionService = transactionService;
  }


  ngOnInit(): void {
    this.loader = true;
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.initReceiptFormGroup();
    this.initFilesFormGroup();
    this.getTransaction();
    this.initReceipt();
  }

  receiptFormGroup: FormGroup;
  initReceiptFormGroup(){
    this.receiptFormGroup = new FormGroup({
      referenceNo: new FormControl(null, [Validators.required]),
      transactionReceipt: new FormControl(null, [Validators.required]),
      requestId: new FormControl(this.requestId)
    });
  }

  initReceipt() {
    this.webService.getWebSettingsByIdentifier("INVOICE_RECEIPT").subscribe((result) => {
      this.receipt = result.data;
    });
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  totalAmount(fee:any,amount:any) {
    try  {
    return this.makeMoney(Number(fee) + Number(amount));
    } catch(e){
      return "0.00";
    }
  }

  public fileFormGroup: FormGroup;
  emptyString: any = "";

  initFilesFormGroup(): void {
    this.fileFormGroup = new FormGroup({
      transactionReceipt: new FormControl(this.emptyString, [Validators.required])
    });
  }

  submitted = false;
  get f() { return this.receiptFormGroup.controls; }
  get fi() { return this.fileFormGroup.controls; }
  public fileName: any = "Click here to choose...";
  public base64Photo: string | ArrayBuffer;

  triggerFile(inputFile){
    inputFile.click();
  }

  public onUploadFile(event: any, formControlName : any) {
    const reader = new FileReader();
   
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.fileName = file.name;
      reader.onload = () => {
        
        this.base64Photo = reader.result as string;
        if(this.calculateImageSize(this.base64Photo)){
          this.fileFormGroup.controls[formControlName].patchValue(this.base64Photo);
          this.fileFormGroup.controls[formControlName].markAsTouched();
          this.changeDetectorRef.markForCheck();
          PNotify.info({
            title: "Certificate of Registration",
            text: "You need to next before the image reflects on view file.",
          });
        }else{
          PNotify.error({
                title: "Certificate of Registration",
                text: "File is too big. Must be less than or equal to 1mb.",
            });
        }
      };
    }
  }

  ProcessComplete(){
    this.loader = true;
    if(this.fileFormGroup.valid){
      this.receiptFormGroup.controls.transactionReceipt.patchValue(this.fileFormGroup.controls.transactionReceipt.value);
      if(this.receiptFormGroup.valid){
        this.paymentCollectionService.completeTransaction(this.receiptFormGroup.value).subscribe((result) => {
          if(result.item1){
            this.getTransaction();
            this.closeModal.emit();
            PNotify.success({
              title: "Transaction",
              text: "Payment Collection Successfully Completed",
            });
          } else {
            PNotify.error({
              title: "Something went wrong",
              text: "Please try again in a moment.",
            });
          }
          this.loader = false;
          this.completeModalRef.close();
        });
      } else {
        PNotify.error({
          title: "Invalid",
          text: "Please fill Reference No",
        });
        this.loader = false;
      }
    } else {
      PNotify.error({
        title: "Invalid",
        text: "Please upload Official Receipt",
      });
      this.loader = false;
    }
  }

  ProcessCancel(){
    this.loader = true;
    if(this.receiptFormGroup.controls.requestId != this.emptyString){
      this.paymentCollectionService.cancelTransaction(this.receiptFormGroup.value).subscribe((result) => {
        if(result.item1){
          this.getTransaction();
          this.closeModal.emit();
          PNotify.success({
            title: "Transaction",
            text: "Payment Collection Successfully Completed",
          });
        } else {
          PNotify.error({
            title: "Something went wrong",
            text: "Please try again in a moment.",
          });
        }

        this.loader = false;
        this.completeModalRef.close();
      });
    } else {
      PNotify.error({
        title: "Invalid",
        text: "Please fill Reference No",
      });
      this.loader = false;
    }
  }

  calculateImageSize(base64String) : boolean {
    let padding;
    let inBytes;
    let base64StringLength;
    if (base64String.endsWith('==')) { padding = 2; }
    else if (base64String.endsWith('=')) { padding = 1; }
    else { padding = 0; }

    base64StringLength = base64String.length;
    console.log(base64StringLength);
    inBytes = (base64StringLength / 4) * 3 - padding;
    return inBytes < 1000000;
  }

  // Parsed Other Details
  parsedOtherDetails: any;
  getTransaction() {
    this.paymentCollectionService.getTransaction(this.requestId).subscribe((serviceCharge) => {
       this.data = serviceCharge;

       if (this.data.otherDetails) {
        this.parsedOtherDetails = JSON.parse(this.data.otherDetails);
        }

       this.loader = false;
       this.initReceiptDetails(serviceCharge.transactByUser.cashierUser.managerUserId);
    }, error => {
      this.loader = false;
    });
  }

  initReceiptDetails(managerUserId: any){
    this.transactionService.getReceiptDetails(managerUserId).subscribe((result) => {
      this.dti = result.dti;
      this.tin = result.tin != null ? result.tin : "***-***-***-***";
      this.address = result.address;
    });
  }

  buildUserPhoto(user: any): any {
    if(user){
    return user.photoFormat + "," + user.photo;
    }
  }

  printReceipt(){
    var completedDate = this.datepipe.transform(this.data.completedDate, 'MMM-dd-yyyy');
    var transTime = this.datepipe.transform(this.data.completedDate, 'hh:mm:ss a');

    var reqId = this.data.requestId;

    var recValNo = "N/A";
    var cashier = this.data.transactByUser.cashierUser.firstName + ' ' + this.data.transactByUser.cashierUser.lastName;

    var billerName = "Payment Collection";
    var refNo = this.data.accountName;
    var can = this.data.accountNumber;
    var peyeeContact = "N/A";
    var billAmount = this.data.amount; 
    var fee = this.data.serviceCharge;
    var amountPaid =  Number(this.data.amount) + Number(this.data.serviceCharge);

    var transNo = "0000000000";
    var AccountNo = "N/A";
    var billerCode = "PC";
    var vipLogo = this.vipLogo.nativeElement.innerHTML;

    if(this.isA4.value){
      var Newstr = (this.receipt[1].value).replace("{{Date}}", completedDate);
      Newstr = (Newstr).replace("{{Time}}", transTime);
      Newstr = (Newstr).replace("{{Cashier}}", cashier);

      Newstr = (Newstr).replace("{{RefNo}}", refNo);
      Newstr = (Newstr).replace("{{RecValNo}}", recValNo);
      Newstr = (Newstr).replace("{{TransactionNo}}", transNo);
      Newstr = (Newstr).replace("{{PayeeContact}}", peyeeContact);
      
      Newstr = (Newstr).replace("{{AccountNo}}", AccountNo);
      Newstr = (Newstr).replace("{{BillerCode}}", billerCode);
      Newstr = (Newstr).replace("{{BillerName}}", billerName);
      Newstr = (Newstr).replace("{{BillAmount}}", parseFloat(billAmount).toFixed(2));
      Newstr = (Newstr).replace("{{AmountPaid}}", parseFloat(amountPaid.toString()).toFixed(2));
 
      Newstr = Newstr.replace("{{receiptLogo}}", vipLogo);
      Newstr = Newstr.replace("{{dti}}", this.dti);
      Newstr = Newstr.replace("{{tinNo}}", this.tin);
      Newstr = Newstr.replace("{{address}}", this.address);
      
      this.PrintReceipt(Newstr);
      } else {
  
        var Newstr = (this.receipt[0].value).replace("{{Date}}", completedDate);
        Newstr = (Newstr).replace("{{Time}}", transTime);
        Newstr = (Newstr).replace("{{RecID}}", reqId);
        Newstr = (Newstr).replace("{{ReferenceName}}", "Rec Val No.");
        Newstr = (Newstr).replace("{{ServiceRef}}", recValNo);

        Newstr = (Newstr).replace("{{Cashier}}", cashier);
        Newstr = (Newstr).replace("{{BillerName}}", billerName);
        Newstr = (Newstr).replace("{{RefNo}}", refNo);
        Newstr = (Newstr).replace("{{CAN}}", can);
        Newstr = (Newstr).replace("{{PayeeContact}}", peyeeContact);
        Newstr = (Newstr).replace("{{BillAmount}}", parseFloat(billAmount).toFixed(2));
        Newstr = (Newstr).replace("{{billerFee}}", fee);
        Newstr = (Newstr).replace("{{AmountPaid}}", parseFloat(amountPaid.toString()).toFixed(2));
        Newstr = Newstr.replace("{{receiptLogo}}", vipLogo);
        Newstr = Newstr.replace("{{dti}}", this.dti);
        Newstr = Newstr.replace("{{tinNo}}", this.tin);
        Newstr = Newstr.replace("{{address}}", this.address);
        Newstr = Newstr.replace("Partner Ref No", "Account Name");
 
     this.PrintReceipt(Newstr);
   }
  }

  PrintReceipt(Newstr: any)
  {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write(Newstr);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    return true;
  }

  viewModalRef: NgbModalRef;
  imagePreview: any;
  preview(content, image){
    this.imagePreview = image;
    this.viewModalRef = this.modalService.open(content, { centered: true,size:'xl', backdrop: 'static'});
  }
  
  completeModalRef: NgbModalRef;
  openCompleteModal(content){
    this.completeModalRef = this.modalService.open(content, { centered: true, size:'md', backdrop: 'static'});
  }

  cancelModalRef: NgbModalRef;
  openCancelModal(content){
    this.cancelModalRef = this.modalService.open(content, { centered: true, size:'md', backdrop: 'static'});
  }
}
