import { CurrencyPipe, DatePipe } from "@angular/common";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { sortedIndexOf } from "lodash";
import { Select2OptionData } from "ng-select2";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { ExportExcelService } from "src/data/services/web/export-excel.service";
import { RequestFundService } from "src/data/services/web/request-fund.service";
import { RolesService } from "src/data/services/web/roles.service";
import { UserService } from "src/data/services/web/user.service";
import { WebSettingsService } from "src/data/services/web/web-settings.service";
import { environment } from "src/environments/environment";
import { ModalConfirmationComponent } from "../modal-confirmation/modal-confirmation.component";
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: "app-request-fund-list",
  templateUrl: "./request-fund-list.component.html",
  styleUrls: ["./request-fund-list.component.less"],
})
export class RequestFundListComponent implements OnInit {
  public requestFundFilterFormGroup: FormGroup;
  public requestFundRemarkFormGroup: FormGroup;
  //remarks = new FormControl('');


  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public requestFundService: RequestFundService;
  public websettings: WebSettingsService;
  
  public userService: UserService;
  public statusOPtion: Array<Select2OptionData>;
 
  public emptyString = "";
  public statuses: any;
  public status: any;
  public modalService: BsModalService;

  public accessToken: string;
  public newRequestFund: boolean;
  public requestFunds: any;
  public requestFundsExport: any;
  public requestFund: any;

  public pageRows: any = 10;

  public lastTenFunds: any;
  public lastTenFund: any;

  public officialReceipt: any;
  public pageCount: any;
  public roleLevelId: any;
  public roleId: any;
  public processUpdate: any = false;
  public userID: any;

  public bsModalRefConfirmation: BsModalRef;

  // Filters
  public listFilterForm: FormGroup;
  public selectedUserId: any = 0;
  public selectedStatusId: any = 0;
  public selectedRowsId: any = 0;

  public showRowsOption: Array<Select2OptionData>
  public userIdFilterOptions: Array<Select2OptionData>;
  public managerUsers: Array<Select2OptionData>;
  public userIdFilter: any;
  public managers: string[] = [];

  public Transactions = [];
  public isDataNotNull: boolean = false;
  dataForExcel = [];
  public isTicketActive : any = false;
  
  @ViewChild("pinModal") pinModal: TemplateRef<any>;
  @ViewChild("requestFundGOVIPCENTERModal") requestFundGOVIPCENTERModal: TemplateRef<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    private currencyPipe: CurrencyPipe,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    requestFundService: RequestFundService,
    modalService: BsModalService,
    bsModalRefConfirmation: BsModalRef,
    websettings: WebSettingsService,
    userService: UserService,
    public datepipe: DatePipe,
    private modalServe: NgbModal,
    public ete: ExportExcelService
  ) {
    PNotifyButtons;
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.requestFundService = requestFundService;
    this.modalService = modalService;
    this.websettings = websettings;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
    this.bsModalRefConfirmation = bsModalRefConfirmation;
    this.userService = userService;
  }

  ngOnInit(): void {
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    this.userID = this.localStorageCacheService.getStorage("userId");
    this.initSearchByFormGroup();
    this.getRequestFund();
    this.getManagers();
    this.initRemarksFormGroup();
    this.getStatus();
    this.getShowRows();
  }

  openTicket(){

    this.isTicketActive = true;
  }
  
  public isRemark: boolean = true;

  initRemarksFormGroup(): void {
    this.requestFundRemarkFormGroup = new FormGroup({
      remarks: new FormControl(this.emptyString),
      remarksVoid: new FormControl(this.emptyString)
    });

    this.requestFundRemarkFormGroup.controls.remarks.valueChanges.subscribe(e => {
      if(e){
        this.isRemark = false;
      } else {
        this.isRemark = true;
      }
    });
  }

  initSearchByFormGroup(): void {
    this.listFilterForm = new FormGroup({
      searchString: new FormControl(this.emptyString),
      userId: new FormControl(this.emptyString),
      dateRange: new FormControl(this.emptyString),
      status: new FormControl(this.emptyString),
      showRows: new FormControl(this.emptyString)
    });

    this.listFilterForm.controls.userId.valueChanges.subscribe(e =>{
      this.selectedUser(e);
    });

    this.listFilterForm.controls.status.valueChanges.subscribe(e =>{
      this.selectedStatus(e);
    });

    this.listFilterForm.controls.showRows.valueChanges.subscribe(e => {
      this.selectedRows(e);
    });
  }

  getShowRows() {
    this.websettings.getWebSettingsByIdentifier("SHOW_ROWS").subscribe((result) => {
      
      this.showRowsOption = result.data.map((status) => {
        return {
          id: status.parameter,
          text: status.value,
        };
      });
      
    });
  }

  getStatus() {
    this.websettings.getWebSettingsByIdentifier("STATUS_SELECTION").subscribe((result) => {
       
      this.statusOPtion = result.data.map((status) => {
        return {
          id: status.parameter,
          text: status.value,
        };
      });
      
    });
  }

  selectedUser(e){
    this.selectedUserId = e;
   
  }

  selectedRows(e){
    this.selectedRowsId = e;
    this.pageRows = e;
   
  }

  selectedStatus(e){
    this.selectedStatusId = e;
    //this.getRequestFund();
  }

  getManagers() {
    this.userService.getManagersUsername().subscribe((result) => {
     
      this.managerUsers = result.map((user) => {
        return {
          id: user.id,
          text: user.name,
        };
      });
    });
   
  }
    
  selectEvent(item) {
    // do something with selected item
  }

  onChangeSearch(search: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something
  }

  private _sortBy: any;
  private _sort: any;
  loader = false;

  getRequestFund(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader = true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    var userId : string;
    const statusId : string = this.listFilterForm.controls.status.value;
    const searchString : string = this.listFilterForm.controls.searchString.value;
    const dateRange : string = this.listFilterForm.controls.dateRange.value;
    const showRows : string = this.listFilterForm.controls.showRows.value;

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";

    if(this.roleId == 3){
      userId = this.userID;
    } else {
      userId = this.listFilterForm.controls.userId.value;
    }

   
    const queryString_userId: string = userId ? `userId=${userId}&` : "";
    const queryString_statusId: string = statusId ? `statusId=${statusId}&` : "";
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    const queryString_searchString: string = searchString ? `searchString=${searchString}&` : "";
    const queryString_showRows: string = showRows ? `showRows=${showRows}&` : "";
    const queryString = queryString_orderby + queryString_order +   queryString_searchString + queryString_userId + queryString_statusId + queryString_dateRange + queryString_showRows;

    let endPointUrl: string;
    if (url) {  
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/requestfunds?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/requestfunds${queryString}`;
      }
    }

    this.requestFundService.getRequestFund(endPointUrl).subscribe((result) => {
      if (result.data.length == 0 && result.pageNumber != 1) {
        this.getRequestFund(result.previousPage, _sortBy, _sort);
      } else {
        this.requestFunds = result;
         this.loader = false;
      }
    });
  }

  getRequestFundForExport(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader = true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    var userId : string;
    const statusId : string = this.listFilterForm.controls.status.value;
    const searchString : string = this.listFilterForm.controls.searchString.value;
    const dateRange : string = this.listFilterForm.controls.dateRange.value;
    const showRows : string = this.listFilterForm.controls.showRows.value;

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";

    if(this.roleId == 3){
      userId = this.userID;
    } else {
      userId = this.listFilterForm.controls.userId.value;
    }

   
    const queryString_userId: string = userId ? `userId=${userId}&` : "";
    const queryString_statusId: string = statusId ? `statusId=${statusId}&` : "";
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    const queryString_searchString: string = searchString ? `searchString=${searchString}&` : "";
    const queryString = queryString_orderby + queryString_order +   queryString_searchString + queryString_userId + queryString_statusId + queryString_dateRange;

    let endPointUrl: string;
    if (url) {  
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/requestFundsExport?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/requestFundsExport${queryString}`;
      }
    }

    this.requestFundService.getRequestFund(endPointUrl).subscribe((result) => {
      if (result.data.length == 0 && result.pageNumber != 1) {
        this.getRequestFund(result.previousPage, _sortBy, _sort);
      } else {
        this.requestFundsExport = result;
        this.generateExcel(this.requestFundsExport.data, "Request Fund");
         this.loader = false;
      }
    });
  }

  // getLastTen(createdByUsername): void {
  //   this.requestFundService.getLastTen(createdByUsername).subscribe((result) => {
  //       this.lastTenFunds = result;
  //   });
  // }

  currentImage;

  viewDetails(content: any, requestFund: any): void {
    if(requestFund.destination == "GOVIPCENTER"){
      this.viewGoVIPTopup(requestFund);
    } else {
      this.viewNonGoVIPTopup(content, requestFund);
    }
  }

  govipcentermodalref: NgbModalRef;
  govipcenterRemarks: any;
  viewGoVIPTopup(requestFund: any){
    this.requestFund = requestFund;
    var jsonRemarks = JSON.parse(requestFund.financeRemarks);
    this.govipcenterRemarks = "Transaction status: (" + jsonRemarks.externalTransferStatus + ") with \nreference number: " + jsonRemarks.referenceNumber
    this.govipcentermodalref = this.modalServe.open(this.requestFundGOVIPCENTERModal, {
      centered: true,
      size: "md",
      backdrop: "static",
      keyboard: false,
    });
  }

  viewNonGoVIPTopup(content: any, requestFund: any){
    this.currentImage ={};
    this.lastTenFunds = [];
    this.loader = true;
    if(requestFund.officialReceiptPhotoUrl){
          this.requestFund = requestFund;
          this.requestFundRemarkFormGroup.controls.remarks.setValue("");
          this.isTicketActive = false;

          if(requestFund?.statusId == 3 && (this.roleId == 7 || this.roleId == 1 || this.roleId == 2 || this.roleId == 22)){
          this.requestFundService.getLastTen(requestFund.createdByUsername, requestFund.id).subscribe((result) => {
            this.lastTenFunds = result;
            this.modalServe.open(content, { centered: true, size: "xl" });
            this.loader = false;
          });
          }else{
          this.modalServe.open(content, { centered: true, size: "xl" });
          this.loader = false;
          }
    }else{
          this.requestFundService.getImageFund(requestFund.id).subscribe((currentImage) => {
            this.currentImage = currentImage;
            this.requestFund = requestFund;
            this.requestFundRemarkFormGroup.controls.remarks.setValue("");
            this.isTicketActive = false;

            if(requestFund?.statusId == 3 && (this.roleId == 7 || this.roleId == 1 || this.roleId == 2 || this.roleId == 22)){
            this.requestFundService.getLastTen(requestFund.createdByUsername, requestFund.id).subscribe((result) => {
              this.lastTenFunds = result;
              this.modalServe.open(content, { centered: true, size: "xl" });
              this.loader = false;
            });
            }else{
            this.modalServe.open(content, { centered: true, size: "xl" });
            this.loader = false;
            }
        });
    }
  }
  
  currentImageinLastTen : any;
  viewOfficialReceipt(content: any, lastTenFund: any): void {
    this.currentImageinLastTen = {};
    this.loader = true;
    this.officialReceipt = lastTenFund;
    if(lastTenFund.officialReceiptPhotoUrl){
      this.modalServe.open(content, { centered: true, size: "xl" });
      this.loader = false;
    }else{
      this.requestFundService.getImageFund(lastTenFund.id).subscribe((currentImage) => {
        this.currentImageinLastTen= currentImage;
        this.modalServe.open(content, { centered: true, size: "xl" });
        this.loader = false;
      });
    }
   
    
  }

  createdDate = "";
  searchString = "";

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.createdDate = field == "createdDate" ? "th-inner sortable both " + sortBy : "";
   
    this.getRequestFund(
      `${environment.API_URL}/api/requestfunds?pageNumber=${page ? page : this.requestFunds.pageNumber}`,
      this._sortBy,
      this._sort
    );
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.requestFunds?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }
  p:Number =1;
  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p = pageNumber;
    return `${environment.API_URL}/api/requestfunds?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }

  editRequestFundProfile(id: any): void {
   
    const encId = this.cryptoService.set(this.data.authentication.privateKey, id);
    this.router.navigate([this.roleLevelId == 1 ? "acm" : "manager", "request-fund-edit", { id: encId }]);
  }

  createRequestFund(): void {
    this.router.navigate(["manager", "request-fund-create"]);
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  setSelectedRequestFund(requestFund: any) {
    this.requestFund = requestFund;
  }

  processRequestFund(status: any) {
    this.processUpdate = true;
    
    var remarks : string = this.requestFundRemarkFormGroup.controls.remarks.value;
    
    this.requestFundService.updateRequestFund(this.requestFund.id, status, remarks).subscribe((result) => {
      this.searchString = this.emptyString;
      this.getRequestFund();

      this.modalServe.dismissAll();
      this.messageDialog();
    });
  }

 

  
  messageDialog(): void {
    // const initialState = {
    //   meta: {
    //     title: "Request Fund",
    //     message: "Processing of request fund was completed.",
    //     btnTitle: "Ok",
    //     showCancel: false,
    //   },
    // };

    PNotify.success({
      title: "Request Fund",
      text: "Processing of request fund was completed.",
    });

    this.processUpdate = false;

    // this.bsModalRefConfirmation = this.modalService.show(ModalConfirmationComponent, {
    //   initialState,
    // });

    // this.bsModalRefConfirmation.content.clickevent.subscribe((response) => {
    //   if (response.result) {
    //   }
    // });
  }

  approveRequest(content: any){
    this.modalServe.open(content, { centered: true, size: "sm" });
  }

  rejectRequest(content: any){
    this.modalServe.open(content, { centered: true, size: "sm" });
  }


  buildUserPhoto(requestFund: any): any {
    return requestFund?.officialReceiptPhotoFormat + "," + requestFund?.officialReceiptPhoto;
  }

  buildUserHPhoto(officialReceipt: any): any {
    return officialReceipt?.officialReceiptPhotoFormat + "," + officialReceipt?.officialReceiptPhoto;
  }

  exportToExcel() {
    this.requestFundsExport = [];
    this.getRequestFundForExport();

    //this.generateExcel(this.requestFundsExport.data, "Request Fund");
  }

  generateExcel(requestfund: any, name: any): void {
    this.Transactions = [];

      for(var i = 0; i < requestfund.length; i++){
        
        let status = "";
        let requestfundName = "";

        if(requestfund[i].statusId == "1"){
          status = "Rejected"
        }  else if (requestfund[i].statusId == "2"){
          status = "Completed"
        } else {
          status = "Pending"
        }

        let meta = [
          {
              "Request ID": requestfund[i].requestId,
              "Reference Number": requestfund[i].referenceNumber,
              "Fund Origin":	requestfund[i].origin,
              "Fund Destination": requestfund[i].destination,
              "Requested By": requestfund[i].createdByUsername,
              "Completed by": requestfund[i].completedByUsername,
              "Created Date": requestfund[i].createdDate,
              "Completed date":	requestfund[i].completedDate,
              "Amount":	requestfund[i].amount,
              "Balance Before": this.makeMoney(requestfund[i].openingBalance),
              "Balance After": this.makeMoney(requestfund[i].openingBalance + requestfund[i].amount),
              "Status":	status
          }
        ]

        this.Transactions.push(meta);
      }

   

    for(var x = 0; x < this.Transactions.length; x++){
      this.dataForExcel.push(Object.values(this.Transactions[x][0]));
    }

    

    let reportData = {
      title: name + ' Transactions',
      data: this.dataForExcel,
      headers: Object.keys(this.Transactions[0][0])
    }

    this.ete.exportExcel(reportData, name);
  }

  emailFinanceSuperVisor : any;

  void(){

    if(confirm(`Are you sure you want to void the topup? Note: Finance supervisor otp is required.`)) {
      this.processUpdate = true;
      this.websettings.getWebSettingsByIdentifier('OTP_SPECIAL').subscribe((result) => {
          var email = result.data.filter(x=>x.parameter == 'VOID_REQUEST_FUND')[0].value;
          this.emailFinanceSuperVisor=email;
          this.userService.sendOTPRequestFund(email).subscribe((result) => {
            PNotify.success({
              title: "Information",
              text: "OTP successfully sent to finance supervisor.",
            });
            this.openPin();
          }, error =>{
            PNotify.error({
              title: "Error",
              text: "Unable to send OTP, please try again later.",
            });
          });
      });

      
     
    }
  }

  voidRequestFund() {
    this.processUpdate = true;
    
    var remarks : string = this.requestFundRemarkFormGroup.controls.remarksVoid.value;
    
    this.requestFundService.voidRequestFund(this.requestFund.id, this.otp, this.emailFinanceSuperVisor, remarks).subscribe((result) => {
      this.searchString = this.emptyString;
      this.getRequestFund();

      this.modalServe.dismissAll();
      this.messageDialogVoid();
    }, error => {
      this.otp = "";
      this.otpStatus = false;
      PNotify.error({
        title: "Error",
        text: "Invalid OTP. Please try again.",
      });
      this.processUpdate = false;
    });
  }


  messageDialogVoid(): void {
    const initialState = {
      meta: {
        title: "Request Fund - Void",
        message: "Processing of request fund was successfully voided.",
        btnTitle: "Ok",
        showCancel: false,
      },
    };

    this.processUpdate = false;

    this.bsModalRefConfirmation = this.modalService.show(ModalConfirmationComponent, {
      initialState,
    });

    this.bsModalRefConfirmation.content.clickevent.subscribe((response) => {
      if (response.result) {
      }
    });
  }

  otpModal;
  openPin() {
   this.otpModal = this.modalServe.open(this.pinModal, { centered: true, size: "md", backdrop: "static", keyboard: false });
   this.processUpdate = false;
  }

  closeTransaction() {
    this.otpModal.close();
  }

  otpStatus = false;
  otp: any;

  onCodeChangedOtp(otp: string) {}
  
  onCodeCompletedOtp(otp: string) {
    this.otp = otp;
    this.otpStatus = otp.length == 6;
  }

  processVoid(otp){

    this.voidRequestFund();
  
  }



}
