import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { RolesService } from "src/data/services/web/roles.service";
import { RequestFundService } from "src/data/services/web/request-fund.service";
import { environment } from "src/environments/environment";
import { NumericDictionaryIteratee } from "lodash";
import { ManagerUsersService } from "src/data/services/web/manager-users.service";

// PNotify
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { UserService } from "src/data/services/web/user.service";
import { UploadService } from "src/data/services/web/upload.service";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: "app-request-fund-create",
  templateUrl: "./request-fund-create.component.html",
  styleUrls: ["./request-fund-create.component.less"],
  providers: [UploadService]
})
export class RequestFundCreateComponent implements OnInit {
  public requestFundFormGroup: FormGroup;
  public requestFundValuesFormGroup: FormGroup;
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public requestFundService: RequestFundService;
  public emptyString = "";
  public bsModalRefConfirmation: BsModalRef;
  public modalService: BsModalService;
  public user: any;

  public usersService: ManagerUsersService;

  public accessToken: string;
  public newRequestFund: boolean;
  public RequestFund: any;
  public loadFlag = false;
  public RequestFundId: any;
  public base64Photo: string | ArrayBuffer;
  public roleLevelId: any;
  processCreate = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    requestFundService: RequestFundService,
    modalService: BsModalService,
    usersService: ManagerUsersService,
    private userService: UserService,
    private uploadService : UploadService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.requestFundService = requestFundService;
    this.modalService = modalService;
    this.usersService = usersService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");

    PNotifyButtons; // Initiate PNotify buttons. Important!
  }


  files: File[] = [];

  onSelect(event) {
    console.log(event);
    this.files = [];
    this.files.push(...event.addedFiles);
  }
  
  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  onUpload(){
    if(!this.files[0]){

      const file_data = this.files[0];
      const data = new FormData();
      data.append('file',file_data);
      data.append('upload_preset','govipcenter_requestfund');
      data.append('cloud_name','govipcenter-com');
  
  
      this.uploadService.uploadImage(data).subscribe(response => {
          if(response){
              console.log(response.url);
          }
      });
    }

  }

  ngOnInit(): void {
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.initRequestFundFormGroup();
    this.getManagerUser(this.localStorageCacheService.getStorage("id"));
  }

  initRequestFundFormGroup(): void {
    this.requestFundFormGroup = new FormGroup({
      id: new FormControl(0),
      amount: new FormControl("0.00", Validators.required),
      referenceNumber: new FormControl(this.emptyString, Validators.required),
      officialReceiptPhoto: new FormControl(this.emptyString),
      officialReceiptPhotoUrl: new FormControl(this.emptyString),
      origin: new FormControl(this.emptyString, Validators.required),
      destination: new FormControl(this.emptyString, Validators.required),
      managerRemarks: new FormControl(this.emptyString)
    });
    
  }

  setDefaultAmount(control: AbstractControl) {
    control.valueChanges.subscribe((element) => {
     
      if (!element) {
        control.setValue("0.00");
      }
    });
  }

  getManagerUser(id: any): void {
    this.usersService.getManagerUser(id).subscribe((result) => {
      this.user = result.data;
    });
  }

  public actionEventCreateRequestFund(): void {


    if(!this.files[0]){

      PNotify.error({
        title: "Request Fund Invalid",
        text: "Error in saving. Please fill all required fields.",
      });
        return;

    }

    if (this.requestFundFormGroup.invalid) {
      //debugger

      PNotify.error({
        title: "Request Fund Invalid",
        text: "Error in saving. Please fill all required fields.",
      });
        return;
    }

    const file_data = this.files[0];
    const data = new FormData();
    data.append('file',file_data);
    data.append('upload_preset','govipcenter_requestfund');
    data.append('cloud_name','govipcenter-com');


    //var filedata = '';
    const reader = new FileReader();
    reader.readAsDataURL(file_data);
    reader.onload = () => {
      // const filedata = reader.result.toString().substring(reader.result.toString().indexOf(',') + 1)
      // console.log("FILE:     ",filedata.split(",")[1].toString());
      // console.log("FILE:     ", reader.result.toString().substring(reader.result.toString().indexOf(',') + 1));
      const filedata = reader.result.toString().substring(reader.result.toString().indexOf(',') + 1);

      this.processCreate = true;
      this.uploadService.uploadImageAzureBlob(filedata).subscribe(result => {
        
        const json = JSON.parse(result);
        //console.log("FILE:     ",json);

          this.requestFundFormGroup.controls.officialReceiptPhotoUrl.setValue(json.response_data);
          this.requestFundService.createRequestFund(this.requestFundFormGroup.value).subscribe(
            (result) => {
              PNotify.success({
                title: "Success",
                text: "The Request Fund has been submitted.",
              });
      
              this.processCreate = false;
              this.backToRequestFundList();
            },
            (error) => {
              if (error.status === 400) {
      
                const obj = error.error.errors;
                for (const key in obj) {
                  if (obj.hasOwnProperty(key)) {
                    let str: string = key;
                    str = str[0].toLowerCase() + str.slice(1);
                    this.requestFundFormGroup.controls[str].setErrors({
                      required: Validators.required,
                      apiMessage: obj[key],
                    });
                  }
                }
      
                this.processCreate = false;
            
                if(error.error.data == 98){
                  PNotify.notice({
                    title: "Duplicate Reference Number",
                    text: "Please check reference number.",
                  });
                } else {
                  PNotify.notice({
                    title: "Invalid",
                    text: error.error.title,
                  });
                }
              } else {
                // Other error
                this.processCreate = false;
      
                PNotify.error({
                  title: "Error",
                  text: "Something went wrong.",
                });
              }
            }
          );

      });
    };


    //debugger
    
  }

  backToRequestFundList(): void {
    this.router.navigate(["manager", "request-fund-list"]);
  }

  public fileName: any = "Click here to choose file...";

  public onPhotoChange(event: any) {
    const reader = new FileReader();
    
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.fileName = file.name;
      reader.onload = () => {
        var data : any = reader.result;
        this.base64Photo = data.split(',')[1];
        this.requestFundFormGroup.patchValue({
          officialReceiptPhoto: reader.result,
        });

        this.changeDetectorRef.markForCheck();
      };
    }
  }
}
