import { Injectable } from "@angular/core";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private authService: AuthService;
  constructor(authService: AuthService, private localStorageCacheService: LocalStorageCacheService) {
    this.authService = authService;
  }

  async isLoggedInCompanyUser(): Promise<boolean> {
    return await this.authService.validateAccessToken("1");
  }

  async isLoggedInManagerUser(): Promise<boolean> {
    return await this.authService.validateAccessToken("2");
  }
  async isLoggedInSalesUser(): Promise<boolean> {
    return await this.authService.validateAccessToken("4");
  }
  async isLoggedInCashierUser(): Promise<boolean> {
    return await this.authService.validateAccessToken("3");
  }
  async isLoggedInFinanceUser(): Promise<boolean> {
    return await this.authService.validateAccessToken("1") && this.localStorageCacheService.getStorage("roleId");
  }

  // this.localStorageCacheService.setStorage("roleId", token.details.roleId, token.details.expiresIn);
}
