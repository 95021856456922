import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Select2OptionData } from 'ng-select2';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { SettlementsService } from 'src/data/services/web/settlements.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'ts-xlsx';
import * as FileSaver from 'file-saver';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SpxRemittanceService } from 'src/data/services/web/spx-remittance.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { result } from 'lodash';
import { ExportExcelService } from 'src/data/services/web/export-excel.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-spx-rider-list',
  templateUrl: './spx-rider-list.component.html',
  styleUrls: ['./spx-rider-list.component.less']
})
export class SpxRiderListComponent implements OnInit {

  loader = false;
  searchByFormGroup: FormGroup;
  public fileUploadFormGroup: FormGroup;
  public deacFileUploadFormGroup: FormGroup;
  public riderFormGroup: FormGroup;
  p: number = 1;
  public pageRows: any = 10;
  emptyString = '';
  roleId: any;
  
  createdDate = "";
  searchString = "";

  public showRowsOption: Array<Select2OptionData>;
  public hubOption: Array<Select2OptionData>;
  public transactions: any = [];
  public transactionsExcel: any = [];
  public transaction: any;
  public totals : any;

  
  private _sortBy: any;
  private _sort: any;
  
  constructor(
    private settlementService : SettlementsService,
    private spxRemittanceService : SpxRemittanceService,
    private localStorageCacheService: LocalStorageCacheService,
    private websettings : WebSettingsService,
    private modalServe: NgbModal,
    public datepipe: DatePipe,
    public ete: ExportExcelService
  ) { }

  ngOnInit(): void {
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    this.initSearchFormGroup();

    this.getShowRows();
    this.getTransaction();
    this.initFileUploadFormGroup();
    this.initDeacFileUploadFormGroup();
    this.initRiderFormGroup();
    this.initHubs();
  }

  hubList: any = [];
  initHubs(){
    this.websettings.getWebSettingsByIdentifier("SPX_HUBS").subscribe((result) => {
      debugger
      this.hubOption = result.data[0].value.map((status) => {
        return {
          id: status["HUB NAME"],
          text: status["HUB NAME"],
        };
      });
    });
  }

  initFileUploadFormGroup(): void {
    this.fileUploadFormGroup = new FormGroup({
      reportFile: new FormControl(this.emptyString)
    });
  }

  initDeacFileUploadFormGroup(): void {
    this.deacFileUploadFormGroup = new FormGroup({
      reportFile: new FormControl(this.emptyString)
    });
  }

  initSearchFormGroup(){
    this.searchByFormGroup = new FormGroup({
      searchString : new FormControl,
      showRows: new FormControl
    });
  }

  initRiderFormGroup(): void {
    this.riderFormGroup= new FormGroup({
      fmsDriverName: new FormControl(this.emptyString),
      hubLocation: new FormControl(this.emptyString),
      region: new FormControl(this.emptyString),
      subRegion: new FormControl(this.emptyString),
      lifetimeID: new FormControl(this.emptyString)
    });
  }

  searchData(){
    this.pageRows = 1;
    this.getTransaction(this.buildPageUrl(1,
      this.searchByFormGroup.controls.showRows.value
      ?this.searchByFormGroup.controls.showRows.value : 10),null,null,true);
  }

  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p = pageNumber;
    return `${environment.API_URL}/api/SPXRemittance/Rider?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  getTransaction(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader =true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    const searchString : string = this.searchByFormGroup.controls.searchString.value;
    const showRows : string = this.searchByFormGroup.controls.showRows.value;
    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_showRows: string = showRows ? `showRows=${showRows}&` : "showRows=10&";
    const queryString_searchString: string = searchString ? `searchString=${searchString}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_showRows + queryString_searchString;
 
    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/SPXRemittance/Rider?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/SPXRemittance/Rider?${queryString}`;
      }
    }

    this.settlementService.getSettlements(endPointUrl.slice(0, -1)).subscribe((result) => {
      this.loader =false;
      if(result){
        if (result.data.length == 0 && result.pageNumber != 1) {
          this.getTransaction(result.previousPage, _sortBy, _sort);
        } else {
          this.transactions = result;
          this.totals = result.totals;
        }
      }
    });
  }

  getTransactionExcel(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader =true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    const searchString : string = this.searchByFormGroup.controls.searchString.value;
    const showRows : string = this.searchByFormGroup.controls.showRows.value;
    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_showRows: string = showRows ? `showRows=1000000&` : "showRows=1000000&";
    const queryString_searchString: string = searchString ? `searchString=${searchString}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_showRows + queryString_searchString;
 
    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/SPXRemittance/Rider?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/SPXRemittance/Rider?${queryString}`;
      }
    }

    this.settlementService.getSettlements(endPointUrl.slice(0, -1)).subscribe((result) => {
      this.loader =false;
      if(result){
        if (result.data.length == 0 && result.pageNumber != 1) {
          this.getTransactionExcel(result.previousPage, _sortBy, _sort);
        } else {
          this.transactions = result;
          this.totals = result.totals;

          this.transactionsExcel = result;
          this.generateRiderExcel(this.transactionsExcel.data, "Rider List");
           this.loader = false;
        }
      }
    });
  }

  getShowRows() {
    this.websettings.getWebSettingsByIdentifier("SHOW_ROWS").subscribe((result) => {
     
      this.showRowsOption = result.data.map((status) => {
        return {
          id: status.parameter,
          text: status.value,
        };
      });
       
    });
  }

  listItems = [];
  
  public fileName: any = "Click here to choose file...";
  public onFileChange(event: any) {
    const target: DataTransfer = <DataTransfer>(event.target);
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      reader.readAsBinaryString(target.files[0]);
      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.IWorkBook = XLSX.read(binarystr, { type: 'binary' });
  
        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.IWorkSheet = wb.Sheets[wsname];
  
        /* save data */
        var listItems = [];
        const dataXLS = XLSX.utils.sheet_to_json(ws, { header:1 }); // to get 2d array pass 2nd parameter as object {header: 1}
        for (let index = 1; index < dataXLS.length; index++) {
          const element : any = dataXLS[index];
          //if(element.length < 14){
            var data = {
              fMSDriverName : element[1],
              hubLocation :  element[2],
              region: element[3],
              subRegion: element[4],
              lifetimeID: element[5]
            }
            this.listItems.push(data);

          //}
        }
        //console.log(listItems); // Data will be logged in array format containing objects
      };
    }
  }

  public deacFileName: any = "Click here to choose file...";
  public onDeacFileChange(event: any) {
    const target: DataTransfer = <DataTransfer>(event.target);
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      reader.readAsBinaryString(target.files[0]);
      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.IWorkBook = XLSX.read(binarystr, { type: 'binary' });
  
        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.IWorkSheet = wb.Sheets[wsname];
  
        /* save data */
        const dataXLS = XLSX.utils.sheet_to_json(ws, { header:1 }); // to get 2d array pass 2nd parameter as object {header: 1}
        for (let index = 1; index < dataXLS.length; index++) {
          const element : any = dataXLS[index];
          //if(element.length < 14){
            var data = {
              fMSDriverName : element[1],
              hubLocation :  element[2],
              region: element[3],
              subRegion: element[4],
              lifetimeID: element[5]
            }
            this.deactListItems.push(data);

          //}
        }
        //console.log(listItems); // Data will be logged in array format containing objects
      };
    }
  }

  public uploadModal: NgbModalRef;
  openFileUpload(content: any) : void {
    this.listItems = [];
    this.uploadModal = this.modalServe.open(content, { centered: true, size: "lg" });
  }
 
  deactListItems: any = [];
  public deacModal: NgbModalRef;
  openBatchDeacModal(content: any) : void {
    this.deactListItems = [];
    this.deacModal = this.modalServe.open(content, { centered: true, size: "lg" });
  }
  
  submitRiderList(){
    this.loader = true;
    this.spxRemittanceService.postRiderList(this.listItems).subscribe((result) => {
      debugger
      if(result.length == 0){
        PNotify.success({
          title: "Success",
          text: "Rider Successfully created",
        });

        this.getTransaction();
        this.uploadModal.close();
        this.loader = false;
      } else {
        PNotify.error({
          title: "Create Success with Duplicate Data",
          text: "Please see below the list of existing riders.",
        });

        this.getTransaction();
        this.listItems = result.map((status) => {
          return {
            fMSDriverName: status.fmsDriverName,
            hubLocation: status.hubLocation,
            region: status.region,
            subRegion: status.subRegion,
            lifetimeID: status.lifetimeID,
          };
        });;
        this.loader = false;
      }
    }, error =>{
      PNotify.error({
        title: "Something went wrong",
        text: "Please double check the data and try again.",
      });
      this.loader = false;
    });
  }
  
  submitDeactivationList(){
    this.loader = true;
    this.spxRemittanceService.postDeactivationList(this.deactListItems).subscribe((result) => {
      debugger
      PNotify.success({
        title: "Deactivation",
        text: "Riders Successfully deactivated",
      });

      this.getTransaction();
      this.uploadModal.close();
      this.loader = false;
    }, error =>{
      PNotify.error({
        title: "Something went wrong",
        text: "Please double check the data and try again.",
      });
      this.loader = false;
    });
  }

  public createRiderModal: NgbModalRef;
  createModal(content: any) : void {
    this.createRiderModal = this.modalServe.open(content, { centered: true, size: "md" });
  }

  processAddManual(){
    this.spxRemittanceService.postRider(this.riderFormGroup.value).subscribe((result) => {
      if(result){
        PNotify.success({
          title: "Success",
          text: "Rider Successfully created",
        });

        this.getTransaction();

        this.riderFormGroup.controls.fmsDriverName.patchValue(this.emptyString);
        this.riderFormGroup.controls.hubLocation.patchValue(this.emptyString);
        this.riderFormGroup.controls.region.patchValue(this.emptyString);
        this.riderFormGroup.controls.subRegion.patchValue(this.emptyString);
        this.riderFormGroup.controls.lifetimeID.patchValue(this.emptyString);
        
        this.createRiderModal.close();
      } else {
        PNotify.error({
          title: "Duplicate Data",
          text: "Rider is already exist.",
        });
      }
    }, error =>{
      PNotify.error({
        title: "Something went wrong",
        text: "Please double check the data and try again.",
      });
    });
  }

  changeStatus(id: any, event: any){
    // debugger
    // alert(id + " :: " +  event.target.checked);
    this.loader = true;
    this.spxRemittanceService.putRider(id, event.target.checked).subscribe((result) => {
      debugger
      if(result){
        PNotify.success({
          title: "Status Update",
          text: "Rides status updated successfully",
        });
      } else {
        PNotify.error({
          title: "Invalid Action",
          text: "An active rider with same data already exist.",
        });
      }
      this.loader = false;
    }, error =>{
      PNotify.error({
        title: "Something went wrong",
        text: "Please double check the data and try again.",
      });
    });
  }

  exportReturnData(){
    this.generateReturnedRiderExcel(this.listItems, "Returned Rider List");
  }

  Transactions: any = [];
  dataForExcel = [];
  generateReturnedRiderExcel(riderReturnedList: any, name: any): void {
    this.Transactions = [];

      for(var i = 0; i < riderReturnedList.length; i++){
        
        let status = "";

        if(riderReturnedList[i].statusId == "1"){
          status = "Rejected"
        }  else if (riderReturnedList[i].statusId == "2"){
          status = "Completed"
        } else {
          status = "Pending"
        }

        let meta = [
          {
              "Item": i + 1,
              "FMS Driver Name": riderReturnedList[i].fMSDriverName,
              "Hub Location": riderReturnedList[i].hubLocation,
              "Region":	riderReturnedList[i].region,
              "Sub Region": riderReturnedList[i].subRegion,
              "LifetimeID": riderReturnedList[i].lifetimeID
          }
        ]

        this.Transactions.push(meta);
      }

    for(var x = 0; x < this.Transactions.length; x++){
      this.dataForExcel.push(Object.values(this.Transactions[x][0]));
    }

    let reportData = {
      title: name,
      data: this.dataForExcel,
      headers: Object.keys(this.Transactions[0][0])
    }

    this.ete.exportExcel(reportData, name);
  }

  RiderExport: any = [];
  riderDataForExcel = [];
  generateRiderExcel(riderlist: any, name: any): void {
    this.RiderExport = [];

      for(var i = 0; i < riderlist.length; i++){
        
        let status = "";

        if(riderlist[i].statusId == "1"){
          status = "Rejected"
        }  else if (riderlist[i].statusId == "2"){
          status = "Completed"
        } else {
          status = "Pending"
        }

        let meta = [
          {
              "Item": i + 1,
              "FMS Driver Name": riderlist[i].fmsDriverName,
              "Hub Location": riderlist[i].hubLocation,
              "Region":	riderlist[i].region,
              "Sub Region": riderlist[i].subRegion,
              "LifetimeID": riderlist[i].lifetimeID,
              "Active": riderlist[i].active == 2 ? "Active" : "Deactivated"
          }
        ]

        this.RiderExport.push(meta);
      }

    for(var x = 0; x < this.RiderExport.length; x++){
      this.riderDataForExcel.push(Object.values(this.RiderExport[x][0]));
    }

    let reportData = {
      title: name,
      data: this.riderDataForExcel,
      headers: Object.keys(this.RiderExport[0][0])
    }

    this.ete.exportExcel(reportData, name);
  }
}
