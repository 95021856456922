import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';

import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { CurrencyPipe, DatePipe } from '@angular/common';
import { PrintService } from 'src/data/services/web/print.service';
import { VipProtectService } from 'src/data/services/web/vip-protect.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { SpxRemittanceService } from 'src/data/services/web/spx-remittance.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: 'app-spx-rider-remittance',
  templateUrl: './spx-rider-remittance.component.html',
  styleUrls: ['./spx-rider-remittance.component.less']
})
export class SpxRiderRemittanceComponent implements OnInit {

  @Input("application") application: any;
  spxRemittanceFormGroup : FormGroup;
  formBuilder : FormBuilder;
  public emptyString = "";
  public billers : any = [];
  public biller : any;
  public billerCode : any;
  public billerTypes : any = [];
  public billersByBillerTypes : any = [];
  public selectedBillerCode : any;

  public activeTab = 1;
  public loader = true;
  public fee : any;
  public amount : any;

  @Output("closeModal") closeModal = new EventEmitter();
  
  constructor(
    private spxRemittanceService : SpxRemittanceService,
    private router: Router,
    localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    formBuilder : FormBuilder,
    private webService: WebSettingsService,
    private datepipe: DatePipe) {
    this.formBuilder = formBuilder;
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.initspxRemittanceFormGroup();
    this.initAmount();
    this.loader = false;
  }

  initspxRemittanceFormGroup(): void {
    this.spxRemittanceFormGroup = new FormGroup({
      fmsDriverName: new FormControl(this.emptyString, [Validators.required]),
      amount: new FormControl(this.emptyString, [Validators.required]),
      lifetimeID: new FormControl(this.emptyString, [Validators.required]),
      hubLocation: new FormControl(this.emptyString, [Validators.required]),
      mobileNo: new FormControl(this.emptyString),
      region: new FormControl(this.emptyString)
    });

    this,this.spxRemittanceFormGroup.controls.amount.valueChanges.subscribe((value) => {
      this.spxRemittanceFormGroup.controls.amount.updateValueAndValidity({emitEvent:false, onlySelf:true});
      this.remittanceAmount = value;
    });

    this.spxRemittanceFormGroup.controls.lifetimeID.valueChanges.pipe(debounceTime(800)).subscribe((value) => {
      if(value.length == 8){
        this.spxRemittanceService.getRiderDetailsByLifetimeID(value).subscribe((result) => {
          debugger
          if(result.data){
            if(result.isActive){
              this.spxRemittanceFormGroup.controls.fmsDriverName.patchValue(result.data.fmsDriverName);
              this.spxRemittanceFormGroup.controls.hubLocation.patchValue(result.data.hubLocation);
              this.spxRemittanceFormGroup.controls.mobileNo.patchValue(result.data.mobileNo);
              this.spxRemittanceFormGroup.controls.region.patchValue(result.data.region);
            } else {
              PNotify.error({
                title: "Rider Deactived",
                text: "Sorry this rider is deactivated. Please adivce the rider to contact Shopee support for assistance.",
              });
            }
          } else {
            this.spxRemittanceFormGroup.controls.fmsDriverName.patchValue(this.emptyString);
            this.spxRemittanceFormGroup.controls.hubLocation.patchValue(this.emptyString);
            this.spxRemittanceFormGroup.controls.mobileNo.patchValue(this.emptyString);
            this.spxRemittanceFormGroup.controls.region.patchValue(this.emptyString);
            PNotify.error({
              title: "Rider Not Found",
              text: "This data doesn't match any SPX Rider.",
            });
          }
        });
      } else {
        this.spxRemittanceFormGroup.controls.fmsDriverName.patchValue(this.emptyString);
        this.spxRemittanceFormGroup.controls.hubLocation.patchValue(this.emptyString);
        this.spxRemittanceFormGroup.controls.mobileNo.patchValue(this.emptyString);
        this.spxRemittanceFormGroup.controls.region.patchValue(this.emptyString);
      }
    });
  }

  initAmount() {
    this.webService.getWebSettingsByIdentifier("SPX_RIDER_PAY").subscribe((result) => {
      this.amount = result.data[0].value;
    });
  }

  replaceComma(num) {
    return num.replace(/,/g, '');
  };

  remittanceAmount: number;
  setActiveTab(){
    
    if(this.activeTab == 1){
       if(this.spxRemittanceFormGroup.status == "INVALID"){
        PNotify.error({
          title: "Error",
          text: "All fields are required to proceed to next step.",
        });
      }else{
        //this.spxRemittanceFormGroup.controls.amount.updateValueAndValidity({emitEvent:false, onlySelf:true});
        debugger
        var amount = Number(this.replaceComma(this.remittanceAmount));
        if(amount > 50000 || amount < 100){
          PNotify.error({
            title: "Error",
            text: "Remittance Amount\nMaximum: P50,000\nMinimum: P100",
          });
        } else {
          this.activeTab++;
          this.loader = false;  
        }
      }
    }else if(this.activeTab == 2){

      var hasError : boolean  = false;
      if(hasError){
        PNotify.error({
          title: "Request denied",
          text: "Please fill up all the required fields.",
        });
      }else{

      }

     
    }
  }

  setActiveTabPrev(){
    if(this.activeTab == 1){
      PNotify.error({
        title: "Error",
        text: "Action Denied.",
      });
    }else{
      this.activeTab--;
    }
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  codeStatus : boolean = false;
  code : any;
  
  onCodeCompleted(code: string) {
    this.code = code;
    this.codeStatus = code.length == 6;
  }

  
  processPayment(){
    this.loader = true;
    
    this.spxRemittanceService.processSPXRemittance(this.spxRemittanceFormGroup.value, this.code)
      .subscribe((result) => {
        PNotify.success({
          title: "Success",
          text: result.reason,
        });
        this.loader = false;
        this.code = "";
        this.codeStatus=false;
        this.closeModal.emit();
      
    }, error => {
      PNotify.error({
        title: "Failed",
        text: error.error == null ? "Something went wrong." : error.error.reason,
      });
      this.loader = false;
      this.code = "";
      this.codeStatus = false;
    });
}

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  totalAmount(fee:any,amount:any){
    try  {
    return this.makeMoney(Number(fee) + Number(amount));
    } catch(e){
      return "0.00";
    }
  }

  files: File[] = [];
  onSelect(event) {
    //console.log("event 1: ",event);
    this.files = [];
    this.files.push(...event.addedFiles);

    const file_data = this.files[0];
    const data = new FormData();
    data.append('file',file_data);
    data.append('upload_preset','govipcenter_requestfund');
    data.append('cloud_name','govipcenter-com');

    //var filedata = '';
    const reader = new FileReader();
    reader.readAsDataURL(file_data);
    reader.onload = () => {
      // const filedata = reader.result.toString().substring(reader.result.toString().indexOf(',') + 1)
      // console.log("FILE:     ",filedata.split(",")[1].toString());
      // console.log("FILE:     ", reader.result.toString().substring(reader.result.toString().indexOf(',') + 1));
      const filedata = reader.result.toString().substring(reader.result.toString().indexOf(',') + 1);

      this.spxRemittanceFormGroup.controls.riderIDPhoto.patchValue(filedata);
    };
  }
  
  onRemove(event) {
    //console.log("event 2: ",event);
    this.files.splice(this.files.indexOf(event), 1);

    this.spxRemittanceFormGroup.controls.riderIDPhoto.patchValue(this.emptyString);
  }
  
}
