
<agm-map 
[latitude]="location.latitude" 
[longitude]="location.longitude" 
[zoom]="location.zoom"
(mapReady)="mapReadyHandler($event)">
<agm-marker *ngFor="let marker of location.markers; let i = index" 
            [latitude]="marker.lat" 
            [longitude]="marker.lng"
            [iconUrl]="marker.iconValue == 1 ? marker.iconGreen : marker.iconRed"
            (mouseOver)="onMouseOver(infoWindow,gm)"
            (markerRightClick)="onDoubleClick(i)">

            <agm-circle *ngIf="marker.showRadius" [latitude]="marker.lat" [longitude]="marker.lng" 
            [radius]="350"
            [fillColor]="'red'"
            (centerChange)="onCenterChanged($event)"
            (radiusChange)="onRadiusChanged($event)">
             </agm-circle>

            <agm-info-window [disableAutoPan]="false" #infoWindow>

                <div>
                    {{marker.name}}
                </div>
    
            </agm-info-window>
</agm-marker>
</agm-map>



<table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
    <thead>
      <tr>
        <th data-sortable="true" data-field="owner" colspan="4">  <strong>  Origin Store Address : {{dragPin ? 'N/A'  :storeAddress}}</strong></th>
        <th data-sortable="true" data-field="owner" > 

        

          <button *ngIf="dragPin"
          (click)="showDistance()"
          type="button"
          class="btn btn-primary"
        >
          Fetch Distance Matrix
        </button>
        </th>
        
      </tr>
      <tr>
        <th data-sortable="true" data-field="owner">Store Name</th>
        <th data-sortable="true" data-field="id">
          <div>Distance in Radius [Meters]</div>
        </th>
        <th data-sortable="true" data-field="id">
            <div>Distance in Road [Meters]</div>
          </th>
          <th data-sortable="true" data-field="id">
            <div>Distance in Road [Kilometers]</div>
          </th>
          <th data-sortable="true" data-field="id">
            <div>Destination Store Address</div>
          </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let marker of locationDisplay | sort : 'meterDistanceInRadius'"
        style="cursor: pointer"
        class="odd"
        role="row"
        
      >
        <td>
            <small class="">{{ marker?.name }}</small>
        </td>
        <td>
          <small class="">{{ marker?.meterDistanceInRadius | number : '1.0-0' }} meters</small>
        </td>
        <td>
            <small class="">{{ dragPin ? 'N/A'  : marker?.meterDistanceInRoad == -1 ? 'N/A': marker?.meterDistanceInRoad}}</small>
          </td>
          <td>
            <small class="">{{ dragPin ? 'N/A'  :marker?.kmDistanceInRoad }}</small>
          </td>
          <td>
            <small class="">{{dragPin ? 'N/A'  : marker?.destinationAddresses }}</small>
          </td>
      </tr>
    </tbody>
  </table>

