import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { SalesUserService } from 'src/data/services/web/sales-user.service';

@Component({
  selector: 'app-geneology',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './geneology.component.html',
  styleUrls: ['./geneology.component.less']
})
export class GeneologyComponent implements OnInit {

  
  userId ;
  constructor(private localStorageCacheService : LocalStorageCacheService,
    private salesUserService : SalesUserService) { 

    this.userId = this.localStorageCacheService.getStorage("userId");

  }
     
      public treeConfig = {
        nodeWidth: 150,
        nodeHeight: 100
    };

    public tree: any;


    public ngOnInit() {
      
      debugger
      this.salesUserService.getDetails(this.userId).subscribe(
        (data: any) => {

          var json = [
            {
              "guid": data.membersId,
              "displayName": data.username,
              "regType" : data.regType,
              "children": [
                
              ]
            }
          ];
            this.tree = {
                json,
                config: this.treeConfig
            };

        });
    }


   

}
