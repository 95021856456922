<app-loader *ngIf="loader"></app-loader>
<div class="modal-body" *ngIf="activeTab != 10">
  <form id=" form" novalidate="">
    <div id="rootwizard">
      <ul class="nav mb-3">
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 1 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >1</span
            >
            <div class="mt-2">
              <div class="text-muted">Cebuana Padala</div>
            </div>
          </a>
        </li>
        <li class="nav-item">
            <a class="nav-link text-center" [ngClass]="activeTab == 2 || activeTab == 4 || activeTab == 6 || activeTab == 8 ? 'active' : ''">
              <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
                >2</span
              >
              <div class="mt-2">
                <div class="text-muted">Transaction Details</div>
              </div>
            </a>
          </li>
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 3 || activeTab == 5 || activeTab == 7 || activeTab == 9 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >3</span
            >
            <div class="mt-2">
              <div class="text-muted">Confirmation</div>
            </div>
          </a>
        </li>
        <li  class="nav-item" style="margin-left: auto">
          <a class="nav-link text-center" [ngClass]="activeTab == 3 || activeTab == 5 || activeTab == 7 || activeTab == 9 ? 'active' : ''">
            <img [src]="application.value.thumbnail.logo" height="100" class="img-responsive" style="object-fit: contain" />
          </a>
        </li>
      </ul>

      <hr class="bg-dark" />

      <div class="tab-content p-3">
        <div class="tab-pane" [ngClass]="activeTab == 1 ? 'active' : ''" id="selectBillerTab">
          <div class="row">
           
            <div class="col-sm-6 col-md-6" style="float:left">
                <div class="d-flex justify-content-center align-items-center pl-3 pr-3">
                    <h5>
                        Cebuana Lhuillier’s Pera Padala service is an easy, quick, and safe way to send and receive money within and outside the Philippines. 
                        You may claim the money as soon as the sender completes the remittance process in the branch! You may visit over 3,000 Cebuana Lhuillier 
                        branches nationwide and its wide network of accredited international partners.
                    </h5>
                </div>
            </div>
           
            <div class="col-sm-6 col-md-6" style="float:left" >

                <h4>Transaction</h4>
                <hr/>

                <div class="form-group">
                    <div class="form-group row">
                      <div class="col-sm-4">
                        <label class="text-muted">Select Type: </label>
                      </div>
                      <div class="col-sm-8" >
                        <select [(ngModel)]="selectedTransactionType" [ngModelOptions]="{standalone: true}"
                                class="form-control" [ngClass]="{'error': isError}" tabindex="-1" aria-hidden="true">
                          <option value="">Choose...</option>
                          <option *ngFor="let type of transactionType" [value]="type.id">
                            {{ type.value }}
                          </option>
                        </select>
                      </div>
                    </div>
                </div>

            </div>
            
          </div>
        </div>

        <div class="tab-pane" [ngClass]="activeTab == 2 ? 'active' : ''" id="sendDomestic">
            <div class="row">
                <div class="col-sm-4 col-md-4" [formGroup]="addClientFormGroup">
                    <h4>Client Details</h4>
                    <hr>

                    <div class="card">
                        <button class="btn btn-sm btn-primary" (click)="openFindClientModal(findClientModal)">Find Client</button>
                    </div>

                    <div class="list list-row row" *ngIf="selectedClient">
                      <div class="col-sm-12 col-md-12">
                        <div class="list-item" data-id="19">
                          <div class="flex">
                            <div class="item-date font-weight-bold text-sm d-none d-md-block">Firstname</div>
                          </div>
                          <div class="no-wrap">
                            <div class="item-feed h-2x">
                              {{ selectedClient?.firstName }}
                            </div>
                          </div>
                        </div>

                        <div class="list-item" data-id="19">
                          <div class="flex">
                            <div class="item-date font-weight-bold text-sm d-none d-md-block">Middlename</div>
                          </div>
                          <div class="no-wrap">
                            <div class="item-feed h-2x">
                              {{ selectedClient?.middleName }}
                            </div>
                          </div>
                        </div>
                        
                        <div class="list-item" data-id="19">
                          <div class="flex">
                            <div class="item-date font-weight-bold text-sm d-none d-md-block">Last Name</div>
                          </div>
                          <div class="no-wrap">
                            <div class="item-feed h-2x">
                              {{ selectedClient?.lastName }}
                            </div>
                          </div>
                        </div>
                        
                        <!-- <div class="list-item" data-id="19">
                          <div class="flex">
                            <div class="item-date font-weight-bold text-sm d-none d-md-block">Cellphone Number</div>
                          </div>
                          <div class="no-wrap">
                            <div class="item-feed h-2x">
                              {{ selectedClient?.cellphoneNumber }}
                            </div>
                          </div>
                        </div> -->

                        <div class="list-item" data-id="19">
                          <div class="flex">
                            <div class="item-date font-weight-bold text-sm d-none d-md-block">Birthdate</div>
                          </div>
                          <div class="no-wrap">
                            <div class="item-feed h-2x">
                              {{ selectedClient?.birthDate | date: "MMM dd, yyyy" }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>

                <div class="col-sm-4 col-md-4">
                    <h4>Beneficiary Details</h4>
                    <hr/>

                    <div class="card">
                      <button class="btn btn-sm btn-primary" (click)="openFindBeneficiaryModal(findBeneficiaryModal)">Find Beneficiary</button>
                    </div>

                    <div class="list list-row row" *ngIf="selectedBeneficiary">
                      <div class="col-sm-12 col-md-12">
                        <div class="list-item" data-id="19">
                          <div class="flex">
                            <div class="item-date font-weight-bold text-sm d-none d-md-block">Firstname</div>
                          </div>
                          <div class="no-wrap">
                            <div class="item-feed h-2x">
                              {{ selectedBeneficiary?.firstName }}
                            </div>
                          </div>
                        </div>

                        <div class="list-item" data-id="19">
                          <div class="flex">
                            <div class="item-date font-weight-bold text-sm d-none d-md-block">Middlename</div>
                          </div>
                          <div class="no-wrap">
                            <div class="item-feed h-2x">
                              {{ selectedBeneficiary?.middleName }}
                            </div>
                          </div>
                        </div>
                        
                        <div class="list-item" data-id="19">
                          <div class="flex">
                            <div class="item-date font-weight-bold text-sm d-none d-md-block">Last Name</div>
                          </div>
                          <div class="no-wrap">
                            <div class="item-feed h-2x">
                              {{ selectedBeneficiary?.lastName }}
                            </div>
                          </div>
                        </div>
                        
                        <!-- <div class="list-item" data-id="19">
                          <div class="flex">
                            <div class="item-date font-weight-bold text-sm d-none d-md-block">Cellphone Number</div>
                          </div>
                          <div class="no-wrap">
                            <div class="item-feed h-2x">
                              {{ selectedBeneficiary?.cellphoneNumber }}
                            </div>
                          </div>
                        </div> -->

                        <div class="list-item" data-id="19">
                          <div class="flex">
                            <div class="item-date font-weight-bold text-sm d-none d-md-block">Birthdate</div>
                          </div>
                          <div class="no-wrap">
                            <div class="item-feed h-2x">
                              {{ selectedBeneficiary?.birthDate | date: "MMM dd, yyyy" }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>

                <div class="col-sm-4 col-md-4">
                    <h4>Transaction Details</h4>
                    <hr/>

                    <div class="card" [formGroup]="sendDomesticFormGroup">
                        <div class="form-group mb-0">
                            <div class="form-group row">
                              <div class="col-sm-3">
                                <label class="text-muted">Currency: </label>
                              </div>
                              <div class="col-sm-9">
                                <select formControlName="sendCurrencyID"
                                  class="form-control " tabindex="-1" aria-hidden="true">
                                  <option *ngFor="let currency of currencyCollection" [value]="currency.currencyID">
                                    {{ currency.code }} - {{ currency.description }}
                                  </option>
                                </select>
                              </div>
                            </div>
                        </div>
    
                        <app-textbox-currency
                            [parent-form-group]="sendDomesticFormGroup" 
                            tab-index-start="0"
                            control-name="principalAmount"
                            [read-only]="false"
                            number-only="false"
                            read-only="false"
                            label="Amount *"
                            placeholder="0.00"
                            max-length="10"
                            [error]="getStatus('principalAmount', sendDomesticFormGroup)"
                            [error-message]="getErrorMessage('principalAmount', sendDomesticFormGroup)"
                            >
                        </app-textbox-currency>                        
    
                        <!-- <app-textbox-currency
                            [parent-form-group]="sendDomesticFormGroup" 
                            tab-index-start="0"
                            control-name="serviceFee"
                            [read-only]="true"
                            number-only="false"
                            read-only="false"
                            label="Service Fee"
                            placeholder="0.00"
                            max-length="10"
                            >
                        </app-textbox-currency>
    
                        <button class="btn btn-sm btn-danger ml-auto" (click)="getServiceFee()">Get Service Fee &nbsp; <i class="fa fa-search"></i></button> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="tab-pane" [ngClass]="activeTab == 4 ? 'active' : ''" id="payoutDomestic">
          <div class="row">
            <div class="col-5 card p-3">
              <app-textbox
                  [parent-form-group]="payoutFormGroup"
                  tab-index-start="0"
                  control-name="controlNumber"
                  read-only="false"
                  label="Control Number *"
                  placeholder="Control Number"
                  max-length="50"
                  [error]="getStatus('controlNumber', payoutFormGroup)"
                  [error-message]="getErrorMessage('controlNumber', payoutFormGroup)"
              >
              </app-textbox>
              <div class="card">
                  <button class="btn btn-sm btn-primary" (click)="pullPayoutTransaction(payoutFormGroup.controls.controlNumber.value)">Pull Transaction</button>
              </div>
            </div>
            <div class="col-3"></div>
            <div class="col-3"></div>
              <div class="col-sm-4 col-md-4" [formGroup]="payoutFormGroup">
                  <h4>Client Details</h4>
                  <hr>
                  <div class="list list-row row" *ngIf="payoutTransaction">
                    <div class="col-sm-12 col-md-12">
                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date font-weight-bold text-sm d-none d-md-block">Firstname</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ payoutTransaction?.senderFirstName }}
                          </div>
                        </div>
                      </div>

                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date font-weight-bold text-sm d-none d-md-block">Middlename</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ payoutTransaction?.senderMiddleName }}
                          </div>
                        </div>
                      </div>
                      
                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date font-weight-bold text-sm d-none d-md-block">Last Name</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ payoutTransaction?.senderLastName }}
                          </div>
                        </div>
                      </div>
                      
                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date font-weight-bold text-sm d-none d-md-block">Birthday</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ payoutTransaction?.senderBirthDate | date: "MMM dd, yyyy" }}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
              </div>

              <div class="col-sm-4 col-md-4">
                  <h4>Beneficiary Details</h4>
                  <hr/>

                  <div class="list list-row row" *ngIf="payoutTransaction">
                    <div class="col-sm-12 col-md-12">
                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date font-weight-bold text-sm d-none d-md-block">Firstname</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ payoutTransaction?.beneficiaryFirstName }}
                          </div>
                        </div>
                      </div>

                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date font-weight-bold text-sm d-none d-md-block">Middlename</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ payoutTransaction?.beneficiaryMiddleName }}
                          </div>
                        </div>
                      </div>
                      
                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date font-weight-bold text-sm d-none d-md-block">Last Name</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ payoutTransaction?.beneficiaryLastName }}
                          </div>
                        </div>
                      </div>
                      
                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date font-weight-bold text-sm d-none d-md-block">Birthday</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ payoutTransaction?.beneficiaryBirthDate | date: "MMM dd, yyyy" }}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
              </div>

              <div class="col-sm-4 col-md-4">
                  <h4>Transaction Details</h4>
                  <hr/>

                  <div [formGroup]="payoutFormGroup">

                      <app-textbox-currency
                          [parent-form-group]="payoutFormGroup" 
                          tab-index-start="0"
                          control-name="principalAmount"
                          [read-only]="true"
                          number-only="false"
                          read-only="false"
                          label="Amount"
                          placeholder="0.00"
                          max-length="10"
                          >
                      </app-textbox-currency>
  
                      <app-textbox-currency
                          [parent-form-group]="payoutFormGroup" 
                          tab-index-start="0"
                          control-name="serviceFee"
                          [read-only]="true"
                          number-only="false"
                          read-only="false"
                          label="Service Fee"
                          placeholder="0.00"
                          max-length="10"
                          >
                      </app-textbox-currency>
  
                      <div class="form-group mb-0">
                        <div class="form-group row">
                          <div class="col-sm-3">
                            <label class="text-muted">ID Type *</label>
                          </div>
                          <div class="col-sm-9">
                            <select formControlName="identificationTypeID"
                              class="form-control " tabindex="-1" aria-hidden="true"
                              [ngClass]="{'error': isError}">
                              <option value="">Choose...</option>
                              <option *ngFor="let id of idCollection" [value]="id.identificationTypeID">
                                {{ id.description }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <app-textbox
                          [parent-form-group]="payoutFormGroup"
                          tab-index-start="0"
                          control-name="identificationNumber"
                          read-only="false"
                          label="ID Number *"
                          placeholder="ID Number"
                          max-length="50"
                          [error]="getStatus('identificationNumber', payoutFormGroup)"
                          [error-message]="getErrorMessage('identificationNumber', payoutFormGroup)"
                      >
                      </app-textbox>

                  </div>
              </div>
          </div>
        </div>

        <div class="tab-pane" [ngClass]="activeTab == 6 ? 'active' : ''" id="amendDomestic">
          <div class="row">
              <div class="col-sm-6 col-md-6" [formGroup]="amendDomesticFormGroup">
                <div class="card">
                    <app-textbox
                      [parent-form-group]="amendDomesticFormGroup"
                      tab-index-start="0"
                      control-name="controlNumber"
                      read-only="false"
                      label="Control Number *"
                      placeholder="Control Number"
                      max-length="50"
                      [error]="getStatus('controlNumber', amendDomesticFormGroup)"
                      [error-message]="getErrorMessage('controlNumber', amendDomesticFormGroup)"
                  >
                  </app-textbox>
                  <div class="card">
                      <button class="btn btn-sm btn-primary" (click)="pullPayoutTransaction(amendDomesticFormGroup.controls.controlNumber.value)">Pull Transaction</button>
                  </div>
                </div>

                  <h4>Client Details</h4>
                  <hr>
                  <div class="list list-row row" *ngIf="payoutTransaction">
                    <div class="col-sm-12 col-md-12">
                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date font-weight-bold text-sm d-none d-md-block">Firstname</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ payoutTransaction?.senderFirstName }}
                          </div>
                        </div>
                      </div>

                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date font-weight-bold text-sm d-none d-md-block">Middlename</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ payoutTransaction?.senderMiddleName }}
                          </div>
                        </div>
                      </div>
                      
                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date font-weight-bold text-sm d-none d-md-block">Last Name</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ payoutTransaction?.senderLastName }}
                          </div>
                        </div>
                      </div>
                      
                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date font-weight-bold text-sm d-none d-md-block">Birthday</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ payoutTransaction?.senderBirthDate | date: "MMM dd, yyyy" }}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <h4>Beneficiary Details</h4>
                  <hr/>

                  <div class="list list-row row" *ngIf="payoutTransaction">
                    <div class="col-sm-12 col-md-12">
                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date font-weight-bold text-sm d-none d-md-block">Firstname</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ payoutTransaction?.beneficiaryFirstName }}
                          </div>
                        </div>
                      </div>

                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date font-weight-bold text-sm d-none d-md-block">Middlename</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ payoutTransaction?.beneficiaryMiddleName }}
                          </div>
                        </div>
                      </div>
                      
                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date font-weight-bold text-sm d-none d-md-block">Last Name</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ payoutTransaction?.beneficiaryLastName }}
                          </div>
                        </div>
                      </div>
                      
                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date font-weight-bold text-sm d-none d-md-block">Birthday</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ payoutTransaction?.beneficiaryBirthDate | date: "MMM dd, yyyy" }}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

              </div>


              <div class="col-sm-6 col-md-6">
                  <h4>New Beneficiary Details</h4>
                    <hr class="mt-0"/>

                    <div class="card">
                      <button class="btn btn-sm btn-primary" (click)="openFindNewBeneficiaryModal(findBeneficiaryModal)">Find New Beneficiary</button>
                    </div>

                    <div class="list list-row row" *ngIf="selectedBeneficiary">
                      <div class="col-sm-12 col-md-12">
                        <div class="list-item" data-id="19">
                          <div class="flex">
                            <div class="item-date font-weight-bold text-sm d-none d-md-block">Firstname</div>
                          </div>
                          <div class="no-wrap">
                            <div class="item-feed h-2x">
                              {{ selectedBeneficiary?.firstName }}
                            </div>
                          </div>
                        </div>

                        <div class="list-item" data-id="19">
                          <div class="flex">
                            <div class="item-date font-weight-bold text-sm d-none d-md-block">Middlename</div>
                          </div>
                          <div class="no-wrap">
                            <div class="item-feed h-2x">
                              {{ selectedBeneficiary?.middleName }}
                            </div>
                          </div>
                        </div>
                        
                        <div class="list-item" data-id="19">
                          <div class="flex">
                            <div class="item-date font-weight-bold text-sm d-none d-md-block">Last Name</div>
                          </div>
                          <div class="no-wrap">
                            <div class="item-feed h-2x">
                              {{ selectedBeneficiary?.lastName }}
                            </div>
                          </div>
                        </div>
                        
                        <!-- <div class="list-item" data-id="19">
                          <div class="flex">
                            <div class="item-date font-weight-bold text-sm d-none d-md-block">Cellphone Number</div>
                          </div>
                          <div class="no-wrap">
                            <div class="item-feed h-2x">
                              {{ selectedBeneficiary?.cellphoneNumber }}
                            </div>
                          </div>
                        </div> -->

                        <div class="list-item" data-id="19">
                          <div class="flex">
                            <div class="item-date font-weight-bold text-sm d-none d-md-block">Birthdate</div>
                          </div>
                          <div class="no-wrap">
                            <div class="item-feed h-2x">
                              {{ selectedBeneficiary?.birthDate | date: "MMM dd, yyyy" }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h4>Transaction Details</h4>
                    <hr/>

                    <div [formGroup]="amendDomesticFormGroup">
                        <app-textbox-currency
                            [parent-form-group]="amendDomesticFormGroup" 
                            tab-index-start="0"
                            control-name="amendmentFee"
                            [read-only]="true"
                            number-only="false"
                            read-only="false"
                            label="Amendment Fee"
                            placeholder="0.00"
                            max-length="10"
                            >
                        </app-textbox-currency>
                    </div>
              </div>
          </div>
        </div>

        <div class="tab-pane" [ngClass]="activeTab == 8 ? 'active' : ''" id="cancelDomestic">
          <div class="row">
            <div class="col-5 card p-3">
              <app-textbox
                  [parent-form-group]="cancelDomesticFormGroup"
                  tab-index-start="0"
                  control-name="controlNumber"
                  read-only="false"
                  label="Control Number *"
                  placeholder="Control Number"
                  max-length="50"
                  [error]="getStatus('controlNumber', cancelDomesticFormGroup)"
                  [error-message]="getErrorMessage('controlNumber', cancelDomesticFormGroup)"
              >
              </app-textbox>
              <div class="card">
                  <button class="btn btn-sm btn-primary" (click)="pullPayoutTransaction(cancelDomesticFormGroup.controls.controlNumber.value)">Pull Transaction</button>
              </div>
            </div>
            <div class="col-3"></div>
            <div class="col-3"></div>
              <div class="col-sm-4 col-md-4" [formGroup]="payoutFormGroup">
                  <h4>Client Details</h4>
                  <hr>
                  <div class="list list-row row" *ngIf="payoutTransaction">
                    <div class="col-sm-12 col-md-12">
                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date font-weight-bold text-sm d-none d-md-block">Firstname</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ payoutTransaction?.senderFirstName }}
                          </div>
                        </div>
                      </div>

                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date font-weight-bold text-sm d-none d-md-block">Middlename</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ payoutTransaction?.senderMiddleName }}
                          </div>
                        </div>
                      </div>
                      
                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date font-weight-bold text-sm d-none d-md-block">Last Name</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ payoutTransaction?.senderLastName }}
                          </div>
                        </div>
                      </div>
                      
                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date font-weight-bold text-sm d-none d-md-block">Birthday</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ payoutTransaction?.senderBirthDate | date: "MMM dd, yyyy" }}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
              </div>

              <div class="col-sm-4 col-md-4">
                  <h4>Beneficiary Details</h4>
                  <hr/>

                  <div class="list list-row row" *ngIf="payoutTransaction">
                    <div class="col-sm-12 col-md-12">
                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date font-weight-bold text-sm d-none d-md-block">Firstname</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ payoutTransaction?.beneficiaryFirstName }}
                          </div>
                        </div>
                      </div>

                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date font-weight-bold text-sm d-none d-md-block">Middlename</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ payoutTransaction?.beneficiaryMiddleName }}
                          </div>
                        </div>
                      </div>
                      
                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date font-weight-bold text-sm d-none d-md-block">Last Name</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ payoutTransaction?.beneficiaryLastName }}
                          </div>
                        </div>
                      </div>
                      
                      <div class="list-item" data-id="19">
                        <div class="flex">
                          <div class="item-date font-weight-bold text-sm d-none d-md-block">Birthday</div>
                        </div>
                        <div class="no-wrap">
                          <div class="item-feed h-2x">
                            {{ payoutTransaction?.beneficiaryBirthDate | date: "MMM dd, yyyy" }}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
              </div>

              <div class="col-sm-4 col-md-4">
                  <h4>Transaction Details</h4>
                  <hr/>

                  <div [formGroup]="cancelDomesticFormGroup">

                      <app-textbox-currency
                          [parent-form-group]="cancelDomesticFormGroup" 
                          tab-index-start="0"
                          control-name="cancelFee"
                          [read-only]="true"
                          number-only="false"
                          read-only="false"
                          label="Cancellation Fee"
                          placeholder="0.00"
                          max-length="10"
                          >
                      </app-textbox-currency>

                  </div>
              </div>
          </div>
        </div>

        <div class="tab-pane" [ngClass]="activeTab == 3 ? 'active' : ''" id="confirmSend">
          <div class="form-group" *ngIf="activeTab == 3">
            <p>
              <strong>Cebuana Confirmation</strong><br />
              <small>Please make sure that the all details is correct.</small>
            </p>
            <div class="card">
              <div class="list list-row row">

                <div class="col-sm-4 col-md-4">
                  <h4>Client Details</h4>
                  <hr>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Firstname</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ sendDomesticFormGroup.controls.clientFirstName.value }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Middlename</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ selectedClient?.middleName }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Lastname</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ sendDomesticFormGroup.controls.clientLastName.value }}
                      </div>
                    </div>
                  </div>
                  
                  <!-- <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Contact Number</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        +63 {{ selectedClient?.contactNumber }}
                      </div>
                    </div>
                  </div> -->
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Birthdate</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ selectedClient?.birthDate | date: "MMM dd, yyyy" }}
                      </div>
                    </div>
                  </div>

                </div>

                <div class="col-sm-4 col-md-4">
                  <h4>Beneficiary Details</h4>
                  <hr>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Firstname</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ sendDomesticFormGroup.controls.beneficiaryFirstName.value }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Middlename</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ selectedBeneficiary?.middleName }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Lastname</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ sendDomesticFormGroup.controls.beneficiaryLastName.value }}
                      </div>
                    </div>
                  </div>
                  
                  <!-- <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Contact Number</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        +63 {{ selectedBeneficiary?.contactNumber }}
                      </div>
                    </div>
                  </div> -->
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Birthdate</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ selectedBeneficiary?.birthDate | date: "MMM dd, yyyy" }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 col-md-4">
                  <h4>Transaction Details</h4>
                  <hr>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Principal Amount</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ makeMoney(replaceComma(sendDomesticFormGroup.controls.principalAmount.value)) }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Service Fee</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ makeMoney(sendDomesticFormGroup.controls.serviceFee.value) }}
                      </div>
                    </div>
                  </div>

                  <hr>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Total Amount</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ makeMoney(getTotal()) }}
                      </div>
                    </div>
                  </div>
                  
                </div>

                <div class="col-sm-12 col-md-12">
                  <hr />
                  <div class="col-sm-12 col-md-6" style="float: none; margin: auto">
                    <div class="item-feed h-2x text-center">ENTER PINCODE</div>
                    <code-input
                      [isCodeHidden]="true"
                      [codeLength]="6"
                      (codeChanged)="onCodeChanged($event)"
                      (codeCompleted)="onCodeCompleted($event)"
                      [code]="code"
                    >
                    </code-input>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane" [ngClass]="activeTab == 5 ? 'active' : ''" id="confirmPayout">
          <div class="form-group" *ngIf="activeTab == 5">
            <p>
              <strong>Cebuana Confirmation</strong><br />
              <small>Please make sure that the all details is correct.</small>
            </p>
            <div class="card">
              <div class="list list-row row">

                <div class="col-sm-4 col-md-4">
                  <h4>Client Details</h4>
                  <hr>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Firstname</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutTransaction?.senderFirstName }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Middlename</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutTransaction?.senderMiddleName }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Lastname</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutTransaction?.senderLastName }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Birthdate</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutTransaction?.senderBirthDate | date: "MMM dd, yyyy"}}
                      </div>
                    </div>
                  </div>

                </div>

                <div class="col-sm-4 col-md-4">
                  <h4>Beneficiary Details</h4>
                  <hr>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Firstname</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutTransaction?.beneficiaryFirstName }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Middlename</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutTransaction?.beneficiaryMiddleName }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Lastname</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutTransaction.beneficiaryLastName }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Birthdate</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutTransaction?.beneficiaryBirthDate | date: "MMM dd, yyyy"}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 col-md-4">
                  <h4>Transaction Details</h4>
                  <hr>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">ID Type</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ getIDDetails(payoutFormGroup.controls.identificationTypeID.value) }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">ID Number</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutFormGroup.controls.identificationNumber.value }}
                      </div>
                    </div>
                  </div>

                  <hr>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Principal Amount</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ makeMoney(payoutTransaction.principalAmount) }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Service Fee</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ makeMoney(payoutTransaction.serviceFee) }}
                      </div>
                    </div>
                  </div>

                  <hr>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Total Amount</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ makeMoney(getTotalPayout(payoutTransaction)) }}
                      </div>
                    </div>
                  </div>
                  
                </div>

                <div class="col-sm-12 col-md-12">
                  <hr />
                  <div class="col-sm-12 col-md-6" style="float: none; margin: auto">
                    <div class="item-feed h-2x text-center">ENTER PINCODE</div>
                    <code-input
                      [isCodeHidden]="true"
                      [codeLength]="6"
                      (codeChanged)="onCodeChanged($event)"
                      (codeCompleted)="onCodeCompleted($event)"
                      [code]="code"
                    >
                    </code-input>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane" [ngClass]="activeTab == 7 ? 'active' : ''" id="confirmAmend">
          <div class="form-group" *ngIf="activeTab == 7">
            <p>
              <strong>Cebuana Confirmation</strong><br />
              <small>Please make sure that the all details is correct.</small>
            </p>
            <div class="card">
              <div class="list list-row row">

                <div class="col-sm-4 col-md-4">
                  <h4>Client Details</h4>
                  <hr>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Firstname</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutTransaction?.senderFirstName }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Middlename</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutTransaction?.senderMiddleName }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Lastname</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutTransaction?.senderLastName }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Birthdate</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutTransaction?.senderBirthDate | date: "MMM dd, yyyy"}}
                      </div>
                    </div>
                  </div>

                </div>

                <div class="col-sm-4 col-md-4">
                  <h4>Beneficiary Details</h4>
                  <hr>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Firstname</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutTransaction?.beneficiaryFirstName }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Middlename</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutTransaction?.beneficiaryMiddleName }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Lastname</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutTransaction.beneficiaryLastName }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Birthdate</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutTransaction?.beneficiaryBirthDate | date: "MMM dd, yyyy"}}
                      </div>
                    </div>
                  </div>

                  <h4>New Beneficiary Details</h4>
                  <hr>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Firstname</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ selectedBeneficiary?.firstName }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Middlename</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ selectedBeneficiary?.middleName }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Lastname</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ selectedBeneficiary.lastName }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Birthdate</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ selectedBeneficiary?.birthDate | date: "MMM dd, yyyy"}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 col-md-4">
                  <h4>Transaction Details</h4>
                  <hr>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Amendment Fee</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ makeMoney(amendDomesticFormGroup.controls.amendmentFee.value) }}
                      </div>
                    </div>
                  </div>
                  
                </div>

                <div class="col-sm-12 col-md-12">
                  <hr />
                  <div class="col-sm-12 col-md-6" style="float: none; margin: auto">
                    <div class="item-feed h-2x text-center">ENTER PINCODE</div>
                    <code-input
                      [isCodeHidden]="true"
                      [codeLength]="6"
                      (codeChanged)="onCodeChanged($event)"
                      (codeCompleted)="onCodeCompleted($event)"
                      [code]="code"
                    >
                    </code-input>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane" [ngClass]="activeTab == 9 ? 'active' : ''" id="confirmCancel">
          <div class="form-group" *ngIf="activeTab == 9">
            <p>
              <strong>Cebuana Confirmation</strong><br />
              <small>Please make sure that the all details is correct.</small>
            </p>
            <div class="card">
              <div class="list list-row row">

                <div class="col-sm-4 col-md-4">
                  <h4>Client Details</h4>
                  <hr>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Firstname</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutTransaction?.senderFirstName }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Middlename</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutTransaction?.senderMiddleName }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Lastname</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutTransaction?.senderLastName }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Birthdate</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutTransaction?.senderBirthDate | date: "MMM dd, yyyy"}}
                      </div>
                    </div>
                  </div>

                </div>

                <div class="col-sm-4 col-md-4">
                  <h4>Beneficiary Details</h4>
                  <hr>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Firstname</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutTransaction?.beneficiaryFirstName }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Middlename</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutTransaction?.beneficiaryMiddleName }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Lastname</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutTransaction.beneficiaryLastName }}
                      </div>
                    </div>
                  </div>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Birthdate</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ payoutTransaction?.beneficiaryBirthDate | date: "MMM dd, yyyy"}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 col-md-4">
                  <h4>Transaction Details</h4>
                  <hr>
                  
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Principal Amount</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ makeMoney(payoutTransaction.principalAmount) }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Cancel Fee</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ makeMoney(cancelDomesticFormGroup.controls.cancelFee.value) }}
                      </div>
                    </div>
                  </div>

                  <hr>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Total Amount</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ makeMoney(getTotalCancel(payoutTransaction)) }}
                      </div>
                    </div>
                  </div>
                  
                </div>

                <div class="col-sm-12 col-md-12">
                  <hr />
                  <div class="col-sm-12 col-md-6" style="float: none; margin: auto">
                    <div class="item-feed h-2x text-center">ENTER PINCODE</div>
                    <code-input
                      [isCodeHidden]="true"
                      [codeLength]="6"
                      (codeChanged)="onCodeChanged($event)"
                      (codeCompleted)="onCodeCompleted($event)"
                      [code]="code"
                    >
                    </code-input>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>

        <!-- previous next -->
        <div class="row py-3">
          <div class="col-6">
            <a class="btn btn-white button-previous" (click)="setActiveTabPrev()" *ngIf="activeTab != 1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-arrow-left"
              >
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
              &nbsp; Previous
            </a>
          </div>
          <div class="col-6">
            <div class="d-flex justify-content-end">
              <a *ngIf="activeTab != 3 && activeTab != 5 && activeTab != 7 && activeTab != 9" class="btn btn-white button-next" (click)="setActiveTab()">
                Next &nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-arrow-right"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </a>
              <a
                *ngIf="activeTab == 3 && codeStatus"
                appDebounceClick 
                [debounceTime]="500" 
                (debounceClick)="processSendPayment()"
                class="btn btn-info button-next"
                style="color: white"
              >
                Process Send Domestic &nbsp;
                <span class="fa fa-hand-pointer"></span>
              </a>
              <a
                *ngIf="activeTab == 5 && codeStatus"
                appDebounceClick 
                [debounceTime]="500" 
                (debounceClick)="processPayoutPayment()"
                class="btn btn-info button-next"
                style="color: white"
              >
                Process Payout Domestic &nbsp;
                <span class="fa fa-hand-pointer"></span>
              </a>
              <a
                *ngIf="activeTab == 7 && codeStatus"
                appDebounceClick 
                [debounceTime]="500" 
                (debounceClick)="processAmendPayment()"
                class="btn btn-info button-next"
                style="color: white"
              >
                Process Amend Domestic &nbsp;
                <span class="fa fa-hand-pointer"></span>
              </a>
              <a
                *ngIf="activeTab == 9 && codeStatus"
                appDebounceClick 
                [debounceTime]="500" 
                (debounceClick)="processCancelPayment()"
                class="btn btn-info button-next"
                style="color: white"
              >
                Process Cancel Domestic &nbsp;
                <span class="fa fa-hand-pointer"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #findClientModal let-modal>
  <app-loader *ngIf="loader"></app-loader>
    <div class="modal-header">
        <h4 class="modal-title">Client Details</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeFindClient()">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="p-3">
        <div class="card p-3" [formGroup]="findClientFormGroup">
            <app-textbox
                [parent-form-group]="findClientFormGroup"
                tab-index-start="0"
                control-name="firstname"
                read-only="false"
                label="Firstname *"
                placeholder="Firstname"
                max-length="50"
                [error]="getStatus('firstname', findClientFormGroup)"
                [error-message]="getErrorMessage('firstname', findClientFormGroup)"
            >
            </app-textbox>

            <app-textbox
                [parent-form-group]="findClientFormGroup"
                tab-index-start="0"
                control-name="lastname"
                read-only="false"
                label="Lastname *"
                placeholder="Lastname"
                max-length="50"
                [error]="getStatus('lastname', findClientFormGroup)"
                [error-message]="getErrorMessage('lastname', findClientFormGroup)"
            >
            </app-textbox>

            <div class="row">
                <div class="col-3"></div>
                <div class="col-9">
                    <!-- <button class="btn btn-sm btn-danger" (click)="openAddClientModal(addClientModal)">Can't find the Client? Add Beneficiary &nbsp; <i class="fa fa-plus"></i></button> -->
                    <span class="link-button" (click)="openAddClientModal(addClientModal)">
                        If the client is not found, <u>click here to add a new</u>
                    </span>
                    <button class="btn btn-sm btn-primary float-right" (click)="findClient()">Find Client &nbsp; <i class="fa fa-search"></i></button>
                </div>
            </div>
            
        </div>

        <hr>

        <div class="card mt-2" *ngIf="clientCollection">
            <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
              <thead>
                <tr>
                  <th data-sortable="true" data-field="id">
                    <div class="color-blue">Client Details</div>
                  </th>
                  
                  <th class="color-blue">Other Details</th>

                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let item of clientCollection"
                    (click)="selectClient(item)"
                    style="cursor: pointer"
                    class="odd"
                    role="row"
                  >
                    <td class="flex">
                        <div class="item-except text-sm h-1x">
                            Name: <span>{{item?.firstName}} {{item?.middleName}} {{item?.lastName}}</span> <br>
                        </div>
                        <div class="item-except text-sm h-1x">
                            Province Address: <span>{{item?.provinceAddress}}</span>
                        </div>
                    </td>
  
                    <td class="flex">
                        <div class="item-except text-sm h-1x">
                            Birthday: <span style="color: rgb(60, 62, 62);">{{ item?.birthDate | date: "MMM dd, yyyy" }}</span>
                        </div>
                        <div class="item-except text-sm h-1x">
                            Contact Details: <span>{{item?.cellphoneNumber}}</span>
                        </div>
                    </td>
                  
                </tr>
              </tbody>
            </table>
        </div>


    </div>
</ng-template>

<ng-template #addClientModal let-modal>
  <app-loader *ngIf="loader"></app-loader>
    <div class="modal-header">
        <h4 class="modal-title">Add Client</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeAddClientModal()">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="card pl-4 pr-4 mb-0 pb-0" [formGroup]="addClientFormGroup">
        <app-textbox
            [parent-form-group]="addClientFormGroup"
            tab-index-start="0"
            control-name="firstName"
            read-only="false"
            label="Firstname *"
            placeholder="Firstname"
            max-length="50"
            [error]="getStatus('firstName', addClientFormGroup)"
            [error-message]="getErrorMessage('firstName', addClientFormGroup)"
        >
        </app-textbox>

        <app-textbox
            [parent-form-group]="addClientFormGroup"
            tab-index-start="0"
            control-name="middleName"
            read-only="false"
            label="Middlename"
            placeholder="Middlename"
            max-length="50"
        >
        </app-textbox>

        <app-textbox
            [parent-form-group]="addClientFormGroup"
            tab-index-start="0"
            control-name="lastName"
            read-only="false"
            label="Lastname *"
            placeholder="Lastname"
            max-length="50"
            [error]="getStatus('lastName', addClientFormGroup)"
            [error-message]="getErrorMessage('lastName', addClientFormGroup)"
        >
        </app-textbox>

        <app-datepicker
            [parent-form-group]="addClientFormGroup"
            tab-index-start="0"
            control-name="birthDate"
            read-only="false"
            label="Birthday *"
            placeholder="Birthday"
            [error]="getStatus('birthDate', addClientFormGroup)"
            [error-message]="getErrorMessage('birthDate', addClientFormGroup)"
            >
        </app-datepicker>

        <app-textbox
            tab-index-start="0"
            read-only="true"
            label="Country Code"
            placeholder="+63"
            max-length="50"
        >
        </app-textbox>

        <app-textbox-number-only
            [parent-form-group]="addClientFormGroup"
            tab-index-start="0"
            control-name="cellphoneNumber"
            read-only="false"
            no-special-char="false"
            number-only="true"
            label="Contact  *"
            placeholder="ex. 9123456789"
            max-length="10"
            [error]="getStatus('cellphoneNumber', addClientFormGroup)"
            [error-message]="getErrorMessage('cellphoneNumber', addClientFormGroup)"
        >
        </app-textbox-number-only>

        <app-textbox
            [parent-form-group]="addClientFormGroup"
            tab-index-start="0"
            control-name="provinceAddress"
            read-only="false"
            label="Province"
            placeholder="Province Address"
            max-length="50"
        >
        </app-textbox>
        
        <app-textarea
            [parent-form-group]="addClientFormGroup"
            tab-index-start="0"
            control-name="address"
            read-only="false"
            allowed-dash="true"
            label="Address"
            placeholder="Address"
            max-length="100"
            upper-case="true"
        > 
        </app-textarea>

        <div class="form-group row">
            <label class="col-sm-3 col-form-label">Source of Fund: </label>
            <div class="col-sm-9">
                <select
                formControlName="clientSourceOfFundID"
                class="form-control"
                tabindex="-1"
                aria-hidden="true"
                >
                <option *ngFor="let source of sourceCollection" [value]="source.sourceOfFundID">
                    {{ source.sourceOfFund }}
                </option>
                </select>
            </div>
        </div>

        <app-textbox
            [parent-form-group]="addClientFormGroup"
            tab-index-start="0"
            control-name="tin"
            read-only="false"
            label="TIN"
            placeholder="000-000-000-000"
            max-length="50"
        >
        </app-textbox>

    </div>

    <div class="card mt-0 pt-0 pr-3">
        <div class="btn btn-sm btn-primary ml-auto" (click)="addClient()">Add Client &nbsp; <i class="fa fa-plus"></i></div>
    </div>

</ng-template>

<ng-template #findBeneficiaryModal let-modal>
  <app-loader *ngIf="loader"></app-loader>
  <div class="modal-header">
      <h4 class="modal-title">Beneficiary Details</h4>
      <button type="button" class="close" aria-label="Close" (click)="closeFindBeneficiary()">
        <span aria-hidden="true">&times;</span>
      </button>
  </div>

  <div class="p-3">
      <div class="card p-3">
          <div class="row">
              <div class="col-3"></div>
              <div class="col-9">
                  <!-- <button class="btn btn-sm btn-danger" (click)="openAddClientModal(addClientModal)">Can't find the Client? Add Beneficiary &nbsp; <i class="fa fa-plus"></i></button> -->
                  <!-- <span class="link-button" (click)="openAddBeneficiaryModal(addBeneficiaryModal)">
                      If the Beneficiary is not found, <u>click here to add a new</u>
                  </span> -->
                  <button class="btn btn-sm btn-primary float-right" (click)="openAddBeneficiaryModal(addBeneficiaryModal)">Add Beneficiary &nbsp; <i class="fa fa-search"></i></button>
              </div>
          </div>
          
      </div>

      <div class="card mt-2" *ngIf="beneficiaryCollection">
          <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
            <thead>
              <tr>
                <th data-sortable="true" data-field="id">
                  <div class="color-blue">Beneficiary Details</div>
                </th>
                
                <th class="color-blue">Other Details</th>

              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let item of beneficiaryCollection"
                  (click)="selectbeneficiary(item)"
                  style="cursor: pointer"
                  class="odd"
                  role="row"
                >
                  <td class="flex">
                      <div class="item-except text-sm h-1x">
                          Name: <span>{{item?.firstName}} {{item?.middleName}} {{item?.lastName}}</span> <br>
                      </div>
                      <div class="item-except text-sm h-1x">
                          Province Address: <span>{{item?.provinceAddress}}</span>
                      </div>
                  </td>

                  <td class="flex">
                      <div class="item-except text-sm h-1x">
                          Birthday: <span style="color: rgb(60, 62, 62);">{{ item?.birthDate | date: "MMM dd, yyyy" }}</span>
                      </div>
                      <div class="item-except text-sm h-1x">
                          Contact Details: <span>{{item?.cellphoneNumber}}</span>
                      </div>
                  </td>
                
              </tr>
            </tbody>
          </table>
      </div>


  </div>
</ng-template>

<ng-template #addBeneficiaryModal let-modal>
  <app-loader *ngIf="loader"></app-loader>
    <div class="modal-header">
        <h4 class="modal-title">Add Beneficiary</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeAddBeneficiaryModal()">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="card pl-4 pr-4 mb-0 pb-0" [formGroup]="addBeneficiaryFormGroup">
        <app-textbox
            [parent-form-group]="addBeneficiaryFormGroup"
            tab-index-start="0"
            control-name="firstName"
            read-only="false"
            label="Firstname *"
            placeholder="Firstname"
            max-length="50"
            [error]="getStatus('firstName', addBeneficiaryFormGroup)"
            [error-message]="getErrorMessage('firstName', addBeneficiaryFormGroup)"
        >
        </app-textbox>

        <app-textbox
            [parent-form-group]="addBeneficiaryFormGroup"
            tab-index-start="0"
            control-name="middleName"
            read-only="false"
            label="Middlename"
            placeholder="Middlename"
            max-length="50"
        >
        </app-textbox>

        <app-textbox
            [parent-form-group]="addBeneficiaryFormGroup"
            tab-index-start="0"
            control-name="lastName"
            read-only="false"
            label="Lastname *"
            placeholder="Lastname"
            max-length="50"
            [error]="getStatus('lastName', addBeneficiaryFormGroup)"
            [error-message]="getErrorMessage('lastName', addBeneficiaryFormGroup)"
        >
        </app-textbox>

        <app-datepicker
            [parent-form-group]="addBeneficiaryFormGroup"
            tab-index-start="0"
            control-name="birthDate"
            read-only="false"
            label="Birthday *"
            placeholder="Birthday"
            [error]="getStatus('birthDate', addBeneficiaryFormGroup)"
            [error-message]="getErrorMessage('birthDate', addBeneficiaryFormGroup)"
            >
        </app-datepicker>

        <app-textbox
            tab-index-start="0"
            read-only="true"
            label="Country Code"
            placeholder="+63"
            max-length="50"
        >
        </app-textbox>

        <app-textbox-number-only
            [parent-form-group]="addBeneficiaryFormGroup"
            tab-index-start="0"
            control-name="cellphoneNumber"
            read-only="false"
            no-special-char="false"
            number-only="true"
            label="Contact  *"
            placeholder="ex. 9123456789"
            max-length="10"
            [error]="getStatus('cellphoneNumber', addBeneficiaryFormGroup)"
            [error-message]="getErrorMessage('cellphoneNumber', addBeneficiaryFormGroup)"
        >
        </app-textbox-number-only>

        <app-textbox
            [parent-form-group]="addBeneficiaryFormGroup"
            tab-index-start="0"
            control-name="provinceAddress"
            read-only="false"
            label="Province"
            placeholder="Province Address"
            max-length="50"
        >
        </app-textbox>
        
        <app-textarea
            [parent-form-group]="addBeneficiaryFormGroup"
            tab-index-start="0"
            control-name="address"
            read-only="false"
            allowed-dash="true"
            label="Address"
            placeholder="Address"
            max-length="100"
            upper-case="true"
        > 
        </app-textarea>

        <app-textbox
            [parent-form-group]="addBeneficiaryFormGroup"
            tab-index-start="0"
            control-name="zipCode"
            read-only="false"
            label="Zip Code"
            placeholder="Zip Code"
            max-length="50"
        >
        </app-textbox>

        <div class="form-group row">
            <label class="col-sm-3 col-form-label">Occupation: </label>
            <div class="col-sm-9">
                <select
                formControlName="occupationID"
                class="form-control"
                tabindex="-1"
                aria-hidden="true"
                >
                <option *ngFor="let source of occupationCollection" [value]="source.occupationID">
                    {{ source.occupationDescription }}
                </option>
                </select>
            </div>
        </div>

        <app-textbox
            [parent-form-group]="addBeneficiaryFormGroup"
            tab-index-start="0"
            control-name="tin"
            read-only="false"
            label="TIN"
            placeholder="000-000-000-000"
            max-length="50"
        >
        </app-textbox>

    </div>

    <div class="card mt-0 pt-0 pr-3">
        <div class="btn btn-sm btn-primary ml-auto" (click)="addBeneficiary()">Add Beneficiary &nbsp; <i class="fa fa-plus"></i></div>
    </div>

</ng-template>
