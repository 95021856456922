import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { environment } from "src/environments/environment";
import { FormGroup } from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class NetbankService {
  private localStorageCacheService: LocalStorageCacheService;

  constructor(
    private httpClient: HttpClient,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
  }
  private httpClientNoInterceptor: HttpClient;

  private baseAPIUrl = environment.API_URL;

  public getBanks(product : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/NetBank/Banks/${product}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getBillers(product : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/IBayad/Billers/${product}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getTransaction(requestId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/NetBank/Transaction/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }


  public rejectTransaction(requestId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/NetBank/BankTransfer/Failed/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.put(`${endPointUrl}`,"",{ headers: httpHeaders });
  }

  public completeTransaction(requestId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/NetBank/BankTransfer/Complete/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.put(`${endPointUrl}`,"",{ headers: httpHeaders });
  }


  public inquireBiller(biller : any, billerCode: any, fee : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Multisys/Billers/Inquiry`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    
      biller.biller = billerCode;
      //biller.channel = "A4EZ";
       biller.channel = billerCode == "BC_MECOA" ? "A9ZK" : "A4EZ";
      //biller.channel = billerCode == "BC_MECOA" ? "1A85" : "1A85";
      biller.fee = fee;

      var json = 
      {biller};

    return this.httpClient.post(`${endPointUrl}`, json,{ headers: httpHeaders });
  }

  public getTransactions(url: string): Observable<any> {
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${url}`, { headers: httpHeaders });
  }

  public processBankTransfer(json : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/NetBank/BankTransfer/Process`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");
    return this.httpClient.post(`${endPointUrl}`, json,{ headers: httpHeaders });
  }


  public reProcessPayment(biller : any, billerName : any,billerCategory: any ,  requestId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Multisys/Billers/Reprocess/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

   

      var json = 
      { 
        biller : biller,
        billerName : billerName,
        billerCategory : billerCategory
      };

    return this.httpClient.put(`${endPointUrl}`, json,{ headers: httpHeaders });
  }


  // tslint:disable-next-line: typedef
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  // tslint:disable-next-line: typedef
  private log(message: string) {
    //
  }

  public sendOTP(
    biller: any,
    billerCode: any,
    fee: any,
    billerName: any,
    billerCategory: any,
    code: any,
    securityCode: any
  ): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/IBayad/SendOTP/BankTransfer`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");
    
    const json = {
      biller,
      billerName: billerName,
      billerCategory: billerCategory,
      pinCode: code,
      securityCode : securityCode
    };

    console.log("ecpay-json", json);

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }
}
