<div class="row p-0 m-0">
    <div class="col-12 pt-2 pl-0">
        <h5>Vips Franchise Details</h5>
        <table class="table table-striped --tablee">
            <tbody>
                <tr>
                  <td style="width:100px">Name</td>
                  <th style="max-width: 100px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; text-align: right;" class="text-nowrap" scope="row">{{ user?.managerUser?.firstName + ' ' + user?.managerUser?.lastName }}</th>
    
                  <td style="width:100px">V-Wallet</td>
                  <th style="text-align: right;" class="text-nowrap" scope="row">{{ wallet | number : "1.2-2"}}</th>
                </tr>
                <tr>
                  <td style="width:100px">Mobile Number</td>
                  <th style="text-align: right;" class="text-nowrap" scope="row">{{ user?.managerUser?.contactNumber }}</th>
    
                  <td style="width:100px">Rewards</td>
                  <th style="text-align: right;" class="text-nowrap" scope="row">{{ totalReward }}</th>
                </tr>
                <tr>
                  <td style="width:100px">Activation Date</td>
                  <th style="text-align: right;" class="text-nowrap" scope="row">{{ user?.managerUser?.emailActivationDate | date:"MMM dd, yyyy"}}</th>
    
                  <td style="width:100px">Ref Code</td>
                  <th style="text-align: right;" class="text-nowrap" scope="row">{{ "V" + userID.padStart(5,0) + username.substring(0,2).toUpperCase() }}</th>
                </tr>
              </tbody>
          </table>
    </div>
    <div class="col-12 mt-2 mb-2 pl-0">
      <h5>Summary of Rewards and Vips Users</h5>
      <table class="table table-striped --tablee">
          <tbody>
            <tr>
              <td style="width:100px">Daily</td>
              <th style="text-align: right;" class="text-nowrap" scope="row">{{ dailyReward }}</th>

              <td style="width:100px">Users</td>
              <th style="text-align: right;" class="text-nowrap" scope="row">{{userCount}}</th>
            </tr>
            <tr>
              <td style="width:100px">Weekly</td>
              <th style="text-align: right;" class="text-nowrap" scope="row">{{ weeklyReward }}</th>

              <td style="width:100px">Merchants</td>
              <th style="text-align: right;" class="text-nowrap" scope="row">{{managerCount}}</th>
            </tr>
            <tr>
              <td style="width:100px">Monthly</td>
              <th style="text-align: right;" class="text-nowrap" scope="row">{{ monthlyReward }}</th>

              <td style="width:100px">Riders</td>
              <th style="text-align: right;" class="text-nowrap" scope="row">{{riderCount}}</th>
            </tr>
          </tbody>
      </table>
    </div>
</div>

<div #govipxQR hidden>
    <ngx-qrcode
      [elementType]="elementType"
      [errorCorrectionLevel]="correctionLevel"
      [value]="value"
      cssClass="bshadow"
      [width]="300"
      style='margin-left:21px; margin-top:290px; border-radius: 2%'
    ></ngx-qrcode>
  </div>
  
  <div #vipLogo hidden>
    <img src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file9828bf15-729f-4650-97a6-476c13f02ce0.png" alt="Go-VIP-Logo-Logo-Small" />
  </div>
  
  <ng-template #myQRTemplateModal let-modal>
    <!-- background: url(../../../assets/images/png\ QR.png) !important 
    linear-gradient(#044660, #02554c)-->
    
    <button class="btn btn-dark btn-lg" (click)="getQR(myQRTemplate)">Print &nbsp; <i class="fa fa-print"></i></button>
    <div class="d-flex justify-content-center">
      <div #myQRTemplate >
          <div style="background: url(../../../assets/images/Vips\ QR.png);
          background-repeat: no-repeat !important;
          background-size: 210mm 297mm !important;
          width: 210mm !important;
          height: 297mm !important;" 
          >
            <div style='display:flex; justify-content: center'>
              <ngx-qrcode
                [elementType]="elementType"
                [errorCorrectionLevel]="correctionLevel"
                [value]="value"
                cssClass="bshadow"
                [width]="300"
                style='margin-left:10px; margin-top:320px; border-radius: 2%'
              ></ngx-qrcode>
            </div>
            <div style='display:flex; justify-content: center; padding-top: 85px'>
                <h2 
                style='font-family: "Segoe UI", Arial, sans-serif; max-width: 360px; text-align:center; font-weight: 700; color: #000'
                >
                    {{dti}}
                </h2>
            </div>
          </div>
        </div>
    </div>
  </ng-template>