import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[alphaOnly]'
})
export class AlphaOnlyDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initialValue = event.target.value;
    event.target.value = initialValue.replace(/[^a-zA-Z\s]/g,'');
    if (initialValue !== event.target.value) {
      event.stopPropagation();
    }
  }
}
