<div class="modal-body" style="position: absolute; top: -100px;">
  <div class="container-fluid">
      <div class="row justify-content-center">
        <img src="../../../assets/vips/Security On-bro.png" alt="" style="padding: 0;
        width: 70px;
    margin-top: 20px;">
        <h2 style="text-align: center;
        font-size: 20px;
        margin: 0;
        padding-top: 10px;margin-bottom: 20px;">Enter your PIN code</h2>
        <!-- <div class="col-2 guard--icon">
          <img src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file12eec62c-b174-4ed1-87aa-1f5cc2cc5a67.png" alt="...">
        </div> -->
        <div class="col p-0" style="box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        border-radius: 8px;
        height: 75px; background-color: #f3f3fa;">
          <code-input
            class=""
            [isCodeHidden]="true"
            [codeLength]="6"
            (codeChanged)="onCodeChanged($event)"
            (codeCompleted)="onCodeCompleted($event)"
            [code]="code">
          </code-input>
        </div>
        <p style="color: grey;
        font-size: 14px;
        text-align: center;
        margin-top: 10px;">If you forgot your PIN code, please click the link below.</p>
      </div>
  </div>
</div>
