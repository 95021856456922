<!-- <app-loader *ngIf="loader"></app-loader> -->
<div id="content" class="flex bg-dark">
  <div class="page-hero page-container" id="page-hero">
    <div class="padding pr-0">
      <div class="card p-4">
        <h1 style="text-align: center" class="text-white">ACM Business Solutions Inc.</h1>
        <h2 style="text-align: center" class="text-warning">Privacy Policy</h2>
      </div>

      <div>
        <p class="pl-4"><b>Effective: </b> &nbsp; <span class="text-danger">[01 July 2021]</span></p>
      </div>

      <div class="card p-4">
        <p>
          <span class="font-weight-bold text-white">ACM Business Solution Inc. </span> (“ACM”) ensures continued
          protection of your personal information with our organizational, physical, and technical measures for data
          protection, including policies for evaluation, monitoring, and review of operations and security risks. This
          Privacy Policy governs the manner in which ACM collects, uses, maintains and discloses information collected
          from users (each, a “User”) of https://www.govipcenter.com/ (“Site”). This privacy policy applies to the Site
          and all products and services offered by ACM.
        </p>
        <p>
          This Privacy Policy informs you of updates in our corporate policies regarding the collection, use, storage,
          disclosure, and disposal of personal information we receive and collect from customers, payees, users, and any
          individual who communicate, raise inquiries and concerns, as well as transact with us or through our
          authorized representatives, official websites and platforms, and web-based applications and channels. This
          Privacy Policy shall be subject to changes in our policies as well as legal and regulatory developments. We
          shall notify you of the changes by posting in our website and other means of communication for your
          information and reference.
        </p>
        <h5 class="text-white">Personal Identification Information</h5>
        <p>
          We may collect personal identification information from Users in a variety of ways, including, but not limited
          to, when Users visit our site, register on the site, and in connection with other activities, services,
          features or resources we make available on our Site. Users may be asked for, as appropriate, name, email
          address, mailing address, phone number. Except for minimum Know Your Client (KYC) information which are listed
          below, we will collect personal identification information from Users only if they voluntarily submit such
          information to us. Users can always refuse to supply personally identification information, except that it may
          prevent them from engaging in certain Site related activities.
        </p>
        <p class="text-white">
          When using the ACM and other associated financial service, we may collect and use the following data about
          you:
        </p>
        <ol>
          <li>Complete name</li>
          <li>Address, including country of residence</li>
          <li>Email address</li>
          <li>Mobile phone number or other contact information</li>
          <li>Date and Place of birth</li>
          <li>Proof of identity (e.g., driver’s license, passport, or government-issued identity card)</li>
          <li>Official Identification issued by a government; and</li>
          <li>
            Other Personal Information or commercial and/or identification information that ACM, in its sole discretion,
            deems necessary to comply with its legal obligations under various Anti-Money Laundering (AML) obligations,
            such as those under the Anti-Money Laundering Act of 2001 (as amended), European Union’s 4th AML Directive
            and the U.S. Bank Secrecy Act (BSA).
          </li>
        </ol>
        <h5 class="text-white">How We Use Collected Information</h5>
        <p class="text-white">ACM may collect and use Users personal information for the following purposes:</p>
        <ol>
          <li>
            <p class="font-weight-bold text-white">To Process Account Verification</p>
            <p>
              We may use the information Users provide about themselves for verification purposes for the Users account.
              We do not share this information with outside parties except to the extent necessary to provide the
              service.
            </p>
          </li>
          <li>
            <p class="font-weight-bold text-white">To Send Periodic Emails</p>
            <p>
              We may use the email address to respond to their inquiries, questions, and/or other requests as well as to
              send periodic emails that will inform users on the latest updates on the services and features of ACM.
            </p>
            <p>
              When you create a ACM Account, you are agreeing to opt-in to all communications from us. We will provide
              you with an opportunity to opt-out of newsletter communications. We will never provide your Personal
              Information to third parties for direct marketing or other unrelated purposes without your written
              consent. Unfortunately, we are unable to allow you to opt-out of the emails we send you for certain legal,
              compliance or administrative purposes.
            </p>
          </li>
          <li>
            <p class="font-weight-bold text-white">Profiling and Automated Decision Making</p>
            <p>
              We may use Automated Decision Making (ADM) to improve your experience or help fight financial crime. For
              example, so that we can provide you with a fast and efficient service, we may use ADM to verify your
              identity documents, or to confirm the accuracy of the information you have provided to us. None of our ADM
              processes will adversely affect the privacy of your personal information.
            </p>
          </li>
          <li>
            <p class="font-weight-bold text-white">Data Retention</p>
            <p>
              As ACM is subject to various legal, compliance and reporting obligations, including AML requirements, we
              are required by law to store some of your personal and transactional data beyond the closure of your
              account with us. Your data is only accessed internally on a need to know basis, and it will only be
              accessed or processed if absolutely necessary. We will delete data that is no longer required by any
              relevant law or jurisdiction in which we operate.
            </p>
          </li>
        </ol>
        <h5 class="text-white">How We Protect Your Information</h5>
        <p>
          We adopt appropriate data collection, storage and processing practices and security measures to protect
          against unauthorized access, alteration, disclosure or destruction of your personal information, username,
          password, transaction information and data stored on our Site.
        </p>
        <p>
          We restrict access of your Personal Information only to ACM employees, affiliates, and subcontractors who have
          a legitimate business need for accessing such information. We continuously educate and train our employees
          about the importance of confidentiality and privacy of customer information. We maintain physical, electronic,
          and procedural safeguards that comply with the relevant laws and regulations to protect your Personal
          Information from unauthorized access.
        </p>
        <p>
          We employ security products, such as firewall and data encryption, and other internal controls which are meant
          to safeguard all our servers and information systems, including the data stored in these systems. Sensitive
          and private data exchange between the Site and its Users are held safely and securely over a minimum of
          128-bit SSL encryption.
        </p>
        <p>
          Where we have a legal obligation to do so, we have appointed a Data Protection Officer (DPO) to be responsible
          for ACM’s privacy program. If you have any questions about how we protect or use your Personal Information,
          you can contact our DPOs by email at support@govipcenter.com.
        </p>
        <h5 class="text-white">Sharing Your Personal Information</h5>
        <p>
          We do not sell, trade, or rent Users personal identification information to others. We never collect or
          maintain information at our Site from those we actually know are under 18, and no part of our website is
          structured to attract anyone under 18.
        </p>
        <h5 class="text-white">Data Storage</h5>
        <p>
          It is ACM’s policy that all systems and storage medium or repositories that store customer data shall have
          completed the appropriate information security, risk, legal compliance, and privacy impact assessments. Your
          personal information shall only be stored in the environment that we can manage. Physical copies of documents
          containing your personal information shall be stored in physical vaults in a sealed and secure manner.
        </p>

        <h5 class="text-white">Personal Data Breach Management</h5>
        <p>
          Personal Data Breach refers to a breach of security leading to the accidental or unlawful destruction, loss,
          alteration, unauthorized disclosure of, or access to, personal data transmitted, stored, or otherwise
          processed. A Personal Data Breach shall be subject to notification requirements under the following
          conditions:
        </p>

        <ol>
          <li>Compromised data involves personal data that may be used to enable identity fraud</li>
          <li>There is reason to believe that the information may have been acquired by an unauthorized person; and</li>
          <li>
            The unauthorized acquisition is likely to give rise to a real risk of serious harm to any affected data
            subject.
          </li>
        </ol>
        <p>
          ACM shall notify the National Privacy Commission and affected customers in case of breach within 72 hours upon
          knowledge of or reasonable belief by the Bank or our third party processor that a personal data breach has
          occurred. If such event occurs, we shall notify you, through a secure means of communication, of the nature of
          the breach, your personal information possibly compromised, measures taken to address the breach and reduce
          negative consequences, contact details of government authorities concerned and our Data Protection Officer who
          can assist you in mitigating the possible ramifications that can compromise you and your right to privacy.
        </p>

        <h5 class="text-white">Cookies</h5>
        <p>
          When you use ACM’s Services, we observe standard practice of placing tiny data files called cookies, flash
          cookies, pixel tags, or other tracking tools (herein, “Cookies”) on your computer or other devices used to
          visit ACM Services. We use Cookies to (i) help us recognize you as a customer, collect information about your
          use of ACM Services to better customize our services and content for you, and to collect information about
          your computer or other access devices to ensure our compliance with our Anti-Money Laundering and Combatting
          of Terrorist Financing obligations and (ii) ensure that your account security has not been compromised by
          detecting irregular or suspicious account activities.
        </p>
        <p>
          We use both session and persistent Cookies. Session Cookies expire when you log out of your account or close
          your browser. Persistent Cookies remain on your computer or mobile device until deleted or otherwise expire.
          Most web browsers are set to accept Cookies by default. You are free to decline most of our session Cookies if
          your browser or browser add-on permits, but choosing to remove or disable our Cookies may interfere with your
          use and functionality of the ACM Services.
        </p>

        <h5 class="text-white">Right of Rectification</h5>
        <p>
          You have the right to correct any personal information We hold on you that is inaccurate, incorrect, or out of
          date.
        </p>

        <h5 class="text-white">Right of Erasure</h5>
        <p>
          You have the right to ask us to delete your data when it is no longer necessary, or no longer subject to a
          legal obligation to which ACM is subject to.
        </p>

        <h5 class="text-white">Portability</h5>
        <p>
          You may have the right to transfer certain of your personal data between data controllers, for example, to
          move your account details from one online platform to another.
        </p>

        <h5 class="text-white">Location Information</h5>
        <p>
          You may be able to stop the collection of location information through your device settings or by following
          the standard uninstall process to remove our applications from your device; however, because such data is used
          by us to optimize ACM’s Services, meet legal requirements, as well as for ongoing fraud and risk monitoring
          purposes, choosing to remove or disable location services may interfere with your use and functionality of the
          ACM Services.
        </p>

        <h5 class="text-white">Access to Information</h5>
        <p>
          Subject to applicable laws, you may have the right to access information we hold about you. Your right of
          access can be exercised in accordance with the relevant data protection legislation. For further information,
          please contact support@govipcenter.com.
        </p>

        <h5 class="text-white">Changes to this Privacy Policy</h5>
        <p>
          ACM has the discretion to update this privacy policy at any time. When we do so, we will revise the updated
          date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay
          informed about how we are helping to protect the personal information we collect. You acknowledge and agree
          that it is your responsibility to review this Privacy Policy periodically and become aware of modifications.
        </p>

        <h5 class="text-white">Your Acceptance of these Terms</h5>
        <p>
          By using this Site, you signify your acceptance of this policy and terms of service. If you do not agree to
          this policy, please do not use our Site. Your continued use of the Site following the posting of changes to
          this policy will be deemed your acceptance of those changes.
        </p>

        <h5 class="text-white">Contacting Us</h5>
        <p>If you have any questions about this Privacy Policy, please contact us at: help@govipcenter.com.</p>
        <div class="d-flex">
          <div>
            <p class="ml-3">
              <span class="font-weight-bold">LANDLINE: </span><br />
              028-5228761 <br />
              02-70056248
            </p>
          </div>
          <div>
            <p class="ml-5">
              <span class="font-weight-bold">MOBILE NUMBER:</span><br />
              0967-135-9003
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- end of transaction items -->

  <!-- Modal for Creating Transaction -->
</div>
