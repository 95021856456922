<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w==" crossorigin="anonymous" referrerpolicy="no-referrer" />
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css">
<div class="container-fluid --home">
    <div class="row">
        <div class="col-12">
            <div class="header mt-3">
                <div class="text">
                    <img src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file260fa82a-0150-4c07-a413-e289dffd98fb.png" width="65px" height="65px" alt="">
                    LIST OF VIPS MERCHANTS
                </div>
            </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 pl-5 pr-4 mb-3" style="max-height: 290px;">
          <div class="box p-0 m-0">
            <app-widget-basic-profile ></app-widget-basic-profile>
          </div>
        </div>
        <div class="col-lg-7 col-md-12 col-sm-12 pl-5 pr-5">

        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 pl-5 pr-5">
            <div class="row box mt-3">
                <div class="col-12 pt-2">
                    <h5>Table for Vips User - Total Linked Users : {{users.length}}</h5>
                    <div class="row mt-2 p-2">
                        <div class="col-lg-5 col-md-5 col-sm-12"></div>
                        <div class="col-lg-7 col-md-7 col-sm-12 pr-0">
                            <div class="input-group">
                                <input type="search" class="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                                <button type="button" class="btn btn-outline-primary">Search</button>
                            </div>
                        </div>

                        <div class="col-12 mt-3"  style="max-height: 230px; overflow-y: scroll;">
                            <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
                                <thead>
                                  <tr>
                                    <th data-sortable="true" data-field="name">
                                      <div>Date</div>
                                    </th>
                                    <th data-sortable="true" data-field="value">
                                      <div>User Type</div>
                                    </th>
                                    <th data-sortable="true" data-field="description">
                                      <div>Mobile No</div>
                                    </th>
                                    <th data-sortable="true" data-field="description">
                                        <div>Full Name</div>
                                    </th>
                                    <th data-sortable="true" data-field="description">
                                      <div>Store Name</div>
                                  </th>
                                    <!-- <th data-field="finish">
                                        <span class="d-none d-sm-block">Status</span>
                                    </th> -->
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    *ngFor="let user of users | sortDesc:'Createdate'"
                                    (click)="viewUser(user)"
                                    style="cursor: pointer"
                                    class="odd"
                                    role="row"
                                  >
                                    <td class="flex">
                                      <div class="item-except text-sm h-1x">
                                        {{ user?.Createdate | date: "dd-MMM-yyyy"}}
                                      </div>
                                    </td>
                                    <td class="flex">
                                      <div class="item-except text-sm h-1x">
                                        {{ getUserType(user?.Usertype) }}
                                      </div>
                                    </td>
                                    <td class="flex">
                                      <div class="item-except text-sm h-1x">
                                        {{ user?.MobileNo }}
                                      </div>
                                    </td>
                                    <td class="flex">
                                        <div class="item-except text-sm h-1x">
                                          {{ user?.FullName }}
                                        </div>
                                      </td>
                                    <td class="flex">
                                      <div class="item-except text-sm h-1x">
                                        {{ user?.business_name }}
                                      </div>
                                    </td>

                                    <!-- <td class="flex" style="max-width: 75px">
                                      <div class="item-except">
                                        <label *ngIf="user?.status == true">
                                       Active
                                        </label>

                                        <label *ngIf="user?.status != true">

                                          Inactive
                                        </label>
                                      </div>
                                    </td> -->
                                  </tr>
                                </tbody>
                              </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div #govipxQR hidden>
    <ngx-qrcode
      [elementType]="elementType"
      [errorCorrectionLevel]="correctionLevel"
      [value]="value"
      cssClass="bshadow"
      [width]="300"
      style='margin-left:21px; margin-top:290px; border-radius: 2%'
    ></ngx-qrcode>
  </div>

  <div #vipLogo hidden>
    <img src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file9828bf15-729f-4650-97a6-476c13f02ce0.png" alt="Go-VIP-Logo-Logo-Small" />
  </div>

  <ng-template #myQRTemplateModal let-modal>
    <!-- background: url(../../../assets/images/png\ QR.png) !important
    linear-gradient(#044660, #02554c)-->

    <button class="btn btn-dark btn-lg" (click)="getQR(myQRTemplate)">Print &nbsp; <i class="fa fa-print"></i></button>
    <div class="d-flex justify-content-center">
      <div #myQRTemplate >
          <div style="background: url(../../../assets/images/png\ QR.png);
          background-repeat: no-repeat !important;
          background-size: 210mm 297mm !important;
          width: 210mm !important;
          height: 297mm !important;"
          >
            <div style='display:flex; justify-content: center'>
              <ngx-qrcode
                [elementType]="elementType"
                [errorCorrectionLevel]="correctionLevel"
                [value]="value"
                cssClass="bshadow"
                [width]="300"
                style='margin-left:21px; margin-top:290px; border-radius: 2%'
              ></ngx-qrcode>
            </div>
            <div style='display:flex; justify-content: center; padding-top: 45px'>
                <h3
                style='margin-left:27px; font-family: "Segoe UI", Arial, sans-serif; max-width: 360px; text-align:center; font-weight: 700'
                >
                    {{dti}}
                </h3>
            </div>
          </div>
        </div>
    </div>
  </ng-template>
  <!-- <div *ngIf="selectedMerchant === undefined; else userIsDefined" class="alert alert-info" role="alert" style="    position: absolute;
  top: 3%;
  right: 1%;">
    This user or merchant is not active.
  </div> -->
  <div *ngIf="message" class="alert alert-success alert-dismissible fade show animate__animated animate__fadeInDown" role="alert" style="position: absolute;
  top: 3%;
  right: 1%;">
    <strong>Sorry</strong> but this user or merchant is not yet active.
    <i
    (click)="close()"
    class="bi bi-x"
    style="
    position: absolute;
    top: 0;
    right: 5px;"
    ></i>
  </div>
  <ng-template #merchantViewModal let-modal>
    <div class="card">
      <div class="rounded-top text-white d-flex flex-row" style="height:200px; padding-inline: 20px; background-size: cover;">
        <img class="rounded-top" [src]="selectedMerchant?.banner"
        style="
        height: 200px;
        width: 100%;
        position: absolute;
        background-size: cover;
        left: 0;" *ngIf="selectedMerchant?.banner">
        <img src="../../../../../assets/vips/No BANNER.png" class="img-fluid" style="
        height: 200px;
        width: 100%;
        position: absolute;
        background-size: cover;
        left: 0;" *ngIf="!selectedMerchant?.banner">
        <div class="card_0" *ngIf="selectedMerchant?.remarks">
          <div class="align">
              <span class="red"></span>
              <h1 style="margin: 0;
              padding: 0;
              font-size: 12px;
              color: white;">Remarks</h1>
          </div>
          <p class="mt-4 text-left" style="font-size: 11px;">
            {{selectedMerchant?.remarks}}
          </p>
        </div>
        <div class="ms-4 mt-5 d-flex flex-column" style="width: 150px;">
          <img [src]="selectedMerchant?.business_store_logo"
            *ngIf="selectedMerchant?.business_store_logo"
            alt="Generic placeholder image" class="img-fluid img-thumbnail mt-4 mb-2"
            style="width: 150px; z-index: 1; height: 155px;">

          <img src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file3068edc4-eb2a-47f4-b9aa-86d6536a0d43.png"
            *ngIf="!selectedMerchant?.business_store_logo"
            alt="Generic placeholder image" class="img-fluid img-thumbnail mt-4 mb-2"
            style="width: 150px; z-index: 1; height: 155px;">
            <span class="badge badge-pill badge-danger" *ngIf="selectedMerchant?.application_rejected && !selectedMerchant?.application_status">Rejected</span>
            <span class="badge badge-pill badge-success" *ngIf="!selectedMerchant?.application_rejected && selectedMerchant?.application_status">Approved</span>
            <span style="width: 90px;" class="badge badge-pill badge-success" *ngIf="!selectedMerchant?.application_rejected && !selectedMerchant?.application_status">Not Registered</span>
        </div>
      </div>
      <div class="p-4 text-black" style="background-color: #f8f9fa; padding-top: 2rem !important;">
        <div class="d-flex justify-content-between text-center py-1">
          <div class="ms-2 w-50" style="z-index: 5;">
            <h3 class="text-left text-dark h5 fw-600">{{ selectedMerchant?.business_name }}</h3>
            <p class="text-left h6 text-muted"> {{selectedMerchant?.business_address_address}} </p>
          </div>
          <div class="">
            <!-- <p class="mb-1 h5">{{selectedMerchant?.store_rating_count}}</p> -->
            <div class="d-flex justify-content-center">
              <p *ngIf="kmslimit" class="mb-1 h5">{{selectedMerchant?.kms_limit}}</p>
              <input style="width: 35px;
              border: none;
              box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;" *ngIf="kms_input" type="number" value="5" [(ngModel)]="kmsNmbr">
              <i *ngIf="!save" (click)="edit()" class="bi bi-pencil-square"></i>&nbsp;
              <i *ngIf="save" (click)="submitKms(selectedNumber)" class="bi text-success bi-check-circle"></i>&nbsp;
              <i *ngIf="save" (click)="close()" class="bi text-danger bi-x-circle"></i>
            </div>
            <p class="small text-muted mb-0">Kms Limit</p>
          </div>
          <div>
            <span class="badge badge-pill badge-primary mb-0" *ngIf="selectedMerchant?.store_status == true">Open</span>
            <span class="badge badge-pill badge-secondary mb-0" *ngIf="!selectedMerchant?.store_status">Closed</span>
            <p class="small text-muted mb-0">Status</p>
          </div>
          <div class="">
            <p class="mb-1 h5" *ngIf="selectedMerchant?.store_rating_count">{{selectedMerchant?.store_rating_count}}</p>
            <p class="mb-1 h5" *ngIf="!selectedMerchant?.store_rating_count">0</p>
            <p class="small text-muted mb-0">Reviews</p>
          </div>
          <div style="text-align: center;
          align-items: center;
          display: flex;
          flex-direction: column;">
            <p class="mb-1 h5">{{selectedMerchant?.store_rating | slice:0:3}}
              <span class = "fa fa-star checked" *ngIf="selectedMerchant?.store_rating>5 || selectedMerchant?.store_rating==5"></span>
              <span class = "fa fa-star zero" *ngIf="selectedMerchant?.store_rating<0 || selectedMerchant?.store_rating==0"></span>
              <span *ngIf="!selectedMerchant?.store_rating">--</span>
            </p>
            <p class="small text-muted mb-0">Rating</p>
          </div>
        </div>
      </div>
      <div class="card-body p-4 text-black" style="padding-top: 0 !important;">
        <div class="mb-2">
          <p class="lead fw-normal mb-1 h5 text-muted">Owner Details</p>
          <div class="p-4" style="background-color: #f8f9fa;padding-top: 5px !important; display: flex; gap: 20px;">
            <div class="dets">
              <p class="mb-1 h6">Name:</p>
              <p class="mb-1 h6">Phone:</p>
              <p class="mb-1 h6">E-mail:</p>
              <p class="mb-1 h6">Birthday:</p>
              <p class="mb-1 h6">Gender:</p>
              <p class="mb-1 h6">LongLat:</p>
            </div>
            <div class="inf">
              <p class="mb-1 h6">{{selectedMerchant?.first_name}} {{selectedMerchant?.last_name}} &nbsp;</p>
              <p class="mb-1 h6">{{selectedNumber}}</p>
              <p class="mb-1 h6">{{selectedMerchant?.email_address}}</p>
              <p class="mb-1 h6">{{selectedMerchant?.birth_date}}</p>
              <p class="mb-1 h6">{{selectedMerchant?.gender}}</p>
              <p class="mb-1 h6">{{selectedMerchant?.business_latitude}},{{selectedMerchant?.business_longitude}} </p>
            </div>

          </div>
        </div>

        <div class="img_container mb-3">
          <p class="lead fw-normal mb-1 text-muted h5">Identifications</p>
          <img style="max-width:100%;cursor:pointer"
          *ngIf="selectedMerchant?.identification_front_url"
          (click)="onClick($event)"
          class="modal-hover-opacity imgs"
          data-toggle="modal" data-target="#myModal" [src]="selectedMerchant?.identification_front_url" alt="Snow">

          <img style="max-width:100%;cursor:pointer"
          *ngIf="!selectedMerchant?.identification_front_url"
          (click)="onClick($event)"
          class="modal-hover-opacity imgs"
          data-toggle="modal" data-target="#myModal" src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file3068edc4-eb2a-47f4-b9aa-86d6536a0d43.png" alt="Snow">

          <img style="max-width:100%;cursor:pointer"
          *ngIf="selectedMerchant?.identification_back_url"
          (click)="onClick($event)"
          class="modal-hover-opacity imgs"
          data-toggle="modal" data-target="#myModal" [src]="selectedMerchant?.identification_back_url" alt="Snow">

          <img style="max-width:100%;cursor:pointer"
          *ngIf="!selectedMerchant?.identification_back_url"
          (click)="onClick($event)"
          class="modal-hover-opacity imgs"
          data-toggle="modal" data-target="#myModal" src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file3068edc4-eb2a-47f4-b9aa-86d6536a0d43.png" alt="Snow">

          <img style="max-width:100%;cursor:pointer"
          *ngIf="selectedMerchant?.identification_selfie"
          (click)="onClick($event)"
          class="modal-hover-opacity imgs"
          data-toggle="modal" data-target="#myModal" [src]="selectedMerchant?.identification_selfie" alt="Snow">

          <img style="max-width:100%;cursor:pointer"
          *ngIf="!selectedMerchant?.identification_selfie"
          (click)="onClick($event)"
          class="modal-hover-opacity imgs"
          data-toggle="modal" data-target="#myModal" src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file3068edc4-eb2a-47f4-b9aa-86d6536a0d43.png" alt="Snow">

          <img style="max-width:100%;cursor:pointer"
          *ngIf="selectedMerchant?.identification_selfie_with_id"
          (click)="onClick($event)"
          class="modal-hover-opacity imgs"
          data-toggle="modal" data-target="#myModal" [src]="selectedMerchant?.identification_selfie_with_id" alt="Snow">

          <img style="max-width:100%;cursor:pointer"
          *ngIf="!selectedMerchant?.identification_selfie_with_id"
          (click)="onClick($event)"
          class="modal-hover-opacity imgs"
          data-toggle="modal" data-target="#myModal" src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file3068edc4-eb2a-47f4-b9aa-86d6536a0d43.png" alt="Snow">

          <img style="max-width:100%;cursor:pointer"
          *ngIf="selectedMerchant?.business_store_facade"
          (click)="onClick($event)"
          class="modal-hover-opacity imgs"
          data-toggle="modal" data-target="#myModal" [src]="selectedMerchant?.business_store_facade" alt="Snow">

          <img style="max-width:100%;cursor:pointer"
          *ngIf="!selectedMerchant?.business_store_facade"
          (click)="onClick($event)"
          class="modal-hover-opacity imgs"
          data-toggle="modal" data-target="#myModal" src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file3068edc4-eb2a-47f4-b9aa-86d6536a0d43.png" alt="Snow">
        </div>

        <!-- <div class="img_container">
          <p class="lead fw-normal mb-1">Vehicle Details</p>
          <img style="max-width:100%;cursor:pointer"
          (click)="onClick($event)"
          class="modal-hover-opacity imgs"
          data-toggle="modal" data-target="#myModal" [src]="selectedMerchant?.vehicle_cr" alt="Snow">
          <img style="max-width:100%;cursor:pointer"
          (click)="onClick($event)"
          class="modal-hover-opacity imgs"
          data-toggle="modal" data-target="#myModal" [src]="selectedMerchant?.vehicle_or" alt="Snow">
          <img style="max-width:100%;cursor:pointer"
          (click)="onClick($event)"
          class="modal-hover-opacity imgs"
          data-toggle="modal" data-target="#myModal" [src]="selectedMerchant?.vehicle_photo" alt="Snow">
        </div> -->

      </div>
    </div>

    <div class="modal fade" id="myModal" role="dialog" style="backdrop-filter: blur(8px);">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title text-white">Image Viewer</h4>
            <button type="button" #closebutton class="close" data-dismiss="modal">&times;</button>
          </div>
          <div class="modal-body">
          <img
          src="{{imgSrc}}"
          style="max-width:100%;cursor:pointer; max-height: 590px;"
          class="modal-hover-opacity"
        />
          </div>
        </div>
      </div>
      </div>
    </ng-template>
