<div class="row">
    <div class="col-sm-12 col-md-2 d-flex justify-content-center align-items-center">
      <img
        [src]="application.value.thumbnail.logo"
        height="100"
        width="100"
        class="img-responsive"
        style="object-fit: contain"
      />
    </div>
    <div class="col-sm-12 col-md-5 d-flex flex-wrap">
      <h4>{{ application.value.thumbnail.title }}</h4>
      <p>
        {{ application.value.acquired.description }}
      </p>
    </div>
    <div class="col-sm-12 col-md-5" [formGroup]="billerFormGroup">
      <div class="form-group">
        <label>Select Category: </label>
        <select
          (change)="getBillersByBillerType($event.target.value)"
          formControlName="billerType"
          class="form-control"
          tabindex="-1"
          aria-hidden="true"
        >
          <option value="">Choose...</option>
          <option *ngFor="let billerType of billerTypes | sortdata" [value]="billerType">
            {{ billerType }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label>Select Biller:</label>
        <select
          (change)="setBillerCode($event.target.value)"
          formControlName="billerCode"
          class="form-control"
          tabindex="-1"
          aria-hidden="true"
        >
          <option value="">Choose...</option>
          <option *ngFor="let biller of billersByBillerTypes | sort:'name'" [value]="biller.code">
            {{ biller.name }}
          </option>
        </select>
      </div>
    </div>
  </div>