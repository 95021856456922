<app-loader *ngIf="loader"></app-loader>

<body class="layout-row">
  <div class="d-flex flex-column flex">
    <div class="row no-gutters h-100">
      <div
        class="col-md-6 bg-primary"
        style="
          background-image: url('https://juanpaycdn.azureedge.net/juanpayassets/JuanPay-Portrait1.jpg');
          background-size: cover;
          background-position: center;
        "
      >
        <div class="p-3 p-md-5 d-flex flex-column h-100">
          <!-- <h4 class="mb-3 text-white">GO VIP Center</h4>
          <div class="text-fade">The Business of the Current</div> -->
          <div class="d-flex flex align-items-center justify-content-center"></div>

          <div class="d-flex text-sm text-fade">
            <a routerLink="/about" class="text-white">About</a>
            <span class="flex"></span>
            <a routerLink="/terms-and-policies" class="text-white mx-1">Terms and Policies</a>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div id="content-body">
          <div class="p-3 p-md-5">
            <h5>Reset Password</h5>
            <p *ngIf="userDetail">
              <strong class="text-muted"
                >Hello {{ userDetail?.details.details.firstName + " " + userDetail?.details.details.lastName }},</strong
              >
            </p>

            <div id="verifyEmail" *ngIf="step == 1">
              <p>
                <small class="text-muted">Password reset will take 3 steps.</small>
              </p>
              <div class="">
                <div name="form" action="dashboard.html">
                  <div *ngIf="verifyEmailStatus == true" class="alert alert-success" role="alert">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-check"
                    >
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                    <span class="mx-2"
                      >Email succesfully validated. To proceed to the next step. Verify your mobile number
                      <strong>(+63XXXXXX{{ userDetail?.details.details.contactNumber.substring(11, 7) }})</strong></span
                    >
                  </div>

                  <div *ngIf="verifyEmailStatus == false" class="alert alert-danger" role="alert">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-alert-circle"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="12" y1="8" x2="12" y2="12"></line>
                      <line x1="12" y1="16" x2="12" y2="16"></line>
                    </svg>
                    <span class="mx-2">Token is invalid. Please contact JuanPay Support.</span>
                  </div>

                  <!-- <div *ngIf="sendSmsStatus == false" class="alert alert-danger" role="alert">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-alert-circle">
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="12" y1="8" x2="12" y2="12"></line>
                      <line x1="12" y1="16" x2="12" y2="16"></line>
                    </svg>
                    <span class="mx-2">Unable to send OTP at this time. Please try again later</span>
                  </div> -->

                  <button
                    *ngIf="verifyEmailStatus == true"
                    (click)="sendSMSOTP()"
                    type="button"
                    class="btn btn-primary mb-4"
                  >
                    Send OTP
                  </button>
                </div>
              </div>
            </div>

            <div id="verifyOTP" *ngIf="step == 2">
              <p>
                <small class="text-muted">Verify your mobile OTP</small>
              </p>
              <div class="">
                <form name="form" action="dashboard.html">
                  <div class="alert alert-success" role="alert">
                    We have send an (OTP) One time password to
                    <strong>(+63XXXXXX{{ userDetail?.details.details.contactNumber.substring(11, 7) }})</strong>.
                  </div>

                  <!-- <div *ngIf="verifySMSStatus == false" class="alert alert-danger" role="alert">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-alert-circle">
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="12" y1="8" x2="12" y2="12"></line>
                      <line x1="12" y1="16" x2="12" y2="16"></line>
                    </svg>
                    <span class="mx-2">Invalid (OTP) One time password. Please try again.</span>
                  </div> -->

                  <div [formGroup]="otpFormGroup" class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="XXXXXX"
                      required
                      autofocus
                      formControlName="otp"
                    />
                  </div>

                  <div>
                    <label *ngIf="!resendSmsStatus">Request a new OTP in ({{ timeLeft }}) seconds.</label>
                    <label
                      ><a *ngIf="resendSmsStatus" (click)="sendSMSOTP()" class="text-primary _600"
                        >Request for a new OTP</a
                      ></label
                    >
                  </div>

                  <div *ngIf="resendSmsSuccessStatus" class="alert alert-success" role="alert">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-check"
                    >
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                    <span class="mx-2">OTP resend succesfully.</span>
                  </div>

                  <button type="button" (click)="verifySMS()" class="btn btn-primary mb-4">Submit</button>
                </form>
              </div>
            </div>

            <div id="createPassword" *ngIf="step == 3">
              <p>
                <small class="text-muted">Reset password will take 3 steps.</small>
              </p>
              <div class="">
                <div [formGroup]="passwordFormGroup" name="form" action="dashboard.html">
                  <div *ngIf="verifySMSStatus == true" class="alert alert-success" role="alert">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-check"
                    >
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                    <span class="mx-2">Mobile OTP successfully validated! Reset your password now.</span>
                  </div>

                  <div>
                    <h4 class="mt-3">Password must be</h4>
                    <ul>
                      <li>between 8 and 15 characters</li>
                      <li>
                        an alphanumeric
                        <span class="text-muted">(ABC123)</span>
                      </li>
                      <li>
                        a mix of capital and small letters
                        <span class="text-muted">(ABCde)</span>
                      </li>
                      <li>
                        a special character
                        <span class="text-muted">(ex. _ ^ % $ # @ , .)</span>
                      </li>
                    </ul>
                  </div>

                  <div class="form-group">
                    <input
                      type="password"
                      class="form-control"
                      maxlength="15"
                      id="password"
                      formControlName="password"
                      placeholder="Password"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="password"
                      class="form-control"
                      maxlength="15"
                      id="passwordConfirm"
                      formControlName="passwordConfirm"
                      placeholder="Re-type Password"
                    />
                  </div>

                  <!-- <div *ngIf="resetPasswordStatus == false" class="alert alert-danger" role="alert">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-alert-circle">
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="12" y1="8" x2="12" y2="12"></line>
                      <line x1="12" y1="16" x2="12" y2="16"></line>
                    </svg>
                    <span class="mx-2">{{resetPasswordErrorMessage}}</span>
                  </div> -->

                  <button type="button" (click)="resetPassword()" class="btn btn-primary mb-4">Submit</button>
                </div>
              </div>
            </div>

            <div id="createPassword" *ngIf="step == 4">
              <div class="">
                <div name="form" action="dashboard.html">
                  <div class="alert alert-success" role="alert">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-check"
                    >
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                    <span class="mx-2">Congratulations, your password was successfully reset!</span>
                  </div>
                </div>
              </div>
            </div>

            <div>
              Already have an account?
              <a href="login" class="text-primary _600">Sign in</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- build:js ../assets/js/site.min.js -->
  <!-- jQuery -->
  <script src="../libs/jquery/dist/jquery.min.js"></script>
  <!-- Bootstrap -->
  <script src="../libs/popper.js/dist/umd/popper.min.js"></script>
  <script src="../libs/bootstrap/dist/js/bootstrap.min.js"></script>
  <!-- ajax page -->
  <script src="../libs/pjax/pjax.min.js"></script>
  <script src="../assets/js/ajax.js"></script>
  <!-- lazyload plugin -->
  <script src="../assets/js/lazyload.config.js"></script>
  <script src="../assets/js/lazyload.js"></script>
  <script src="../assets/js/plugin.js"></script>
  <!-- scrollreveal -->
  <script src="../libs/scrollreveal/dist/scrollreveal.min.js"></script>
  <!-- feathericon -->
  <script src="../libs/feather-icons/dist/feather.min.js"></script>
  <script src="../assets/js/plugins/feathericon.js"></script>
  <!-- theme -->
  <script src="../assets/js/theme.js"></script>
  <script src="../assets/js/utils.js"></script>
  <!-- endbuild -->
</body>
