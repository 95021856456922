<div [formGroup]="parentFormGroup" class="form-group row">

  <label class="col-sm-3 col-form-label">{{ label }}</label>
  <div class="col-sm-9">
    <!-- <input type="hidden" [formControlName]="controlName" /> -->
    <select [formControlName]="controlName" class="form-control" style="border-color: #294896;">
      <option class="text-muted">Choose...</option>
      <option *ngFor="let option of data">{{ option.value }}</option>
    </select>
    <span *ngIf="error" class="error-msg"
      ><small><div [innerHTML]="errorMessage"></div></small></span
    >
  </div>
</div>
