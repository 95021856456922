import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-cashier-user-schedule",
  templateUrl: "./cashier-user-schedule.component.html",
  styleUrls: ["./cashier-user-schedule.component.less"],
})
export class CashierUserScheduleComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
