<div class="vip">
  <div class="row">
    <div class="vip__banner col-sm-12">
      <div class="vip__banner-desktop">
        <img class="img-responsive" src="../../../assets/images/banner/img-holidays-desktop.jpg" />
      </div>
      <div class="vip__banner-mobile">
        <img class="img-responsive" src="../../../assets/images/banner/img-holidays-mobile.jpg" />
      </div>
      <div class="text-container">
        <h1 class="title">Happy Holidays</h1>
        <p class="content">from</p>
        <p class="content custom-text">Go VIP Center Family</p>
        <p class="content">will be back soon</p>
      </div>
    </div>
  </div>
</div>