import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { InvoiceComponent } from 'src/app/elements/invoice/invoice.component';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { PrintService } from 'src/data/services/web/print.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { TransactionService } from 'src/data/services/web/transaction.service';
import { SpxRemittanceService } from 'src/data/services/web/spx-remittance.service';
import { PhpayService } from 'src/data/services/web/phpay.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-phpay-transaction',
  templateUrl: './phpay-transaction.component.html',
  styleUrls: ['./phpay-transaction.component.less']
})
export class PhpayTransactionComponent implements OnInit {

  @ViewChild(InvoiceComponent ) child: InvoiceComponent ;
  @ViewChild('vipLogo') vipLogo: ElementRef;
  
  @Input() requestId: string;
  public data :  any;
  public loader : boolean = false;
  public receipt: any;
  public isA4 = new FormControl();
  roleLevel:any;
  public isTicketActive : any = false;

  public transactionService: TransactionService;
  public dti: any;
  public tin: any;
  public address: any;
  
  constructor(private phpayService : PhpayService, 
    private router: Router,
    private localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    public datepipe: DatePipe,
    public printService: PrintService,
    private webService: WebSettingsService, 
    transactionService: TransactionService) {
    this.transactionService = transactionService;
    this.phpayService = phpayService;
    PNotifyButtons;
  }

  @Output("closeModal") closeModal = new EventEmitter();


  ngOnInit(): void {
    this.loader = true;
    this.getTransaction();
    this.initReceipt();
    this.roleLevel = this.localStorageCacheService.getStorage("role_level");
  }

  openTicket(){

    this.isTicketActive = true;
  }

  initReceipt() {
    this.webService.getWebSettingsByIdentifier("INVOICE_RECEIPT").subscribe((result) => {
      this.receipt = result.data;
    });
  }

  getTransaction() {
    this.phpayService.getTransaction(this.requestId).subscribe((billers) => {
       this.data = billers;
       this.loader = false;
       this.initReceiptDetails(billers.transactByUser.cashierUser.managerUserId);
    }, error => {
      this.loader = false;
    });
  }

  initReceiptDetails(managerUserId: any){
    this.transactionService.getReceiptDetails(managerUserId).subscribe((result) => {
      this.dti = result.dti;
      this.tin = result.tin != null ? result.tin : "***-***-***-***";
      this.address = result.address;
    });
  }

  getPayoutAmount(): number {
    // Remove commas from the amount and service fee, then convert to numbers
    const amountString = String(this.data.amount).replace(/,/g, '');
    const serviceFeeString = String(this.data.serviceFee).replace(/,/g, '');

    const amount = Number(amountString) || 0;
    const serviceFee = Number(serviceFeeString) || 0;

    // Compute and return the difference
    return amount - serviceFee;
  }

  buildUserPhoto(user: any): any {
    if(user){
    return user.photoFormat + "," + user.photo;
    }
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  printReceipt(){
    var jsonResponse = JSON.parse(this.data?.responseJson);
    var completedDate = this.data.completedDate ? this.datepipe.transform(this.data.completedDate, 'MMM-dd-yyyy') : null;
    var transTime = this.data.completedDate ? this.datepipe.transform(this.data.completedDate, 'hh:mm:ss a') : null;
    var reqId = this.data.requestId;
    var cashier = this.data.transactByUser.cashierUser.firstName + ' ' + this.data.transactByUser.cashierUser.lastName;
    var refNo = this.data.referenceNo;
    var PartnerRefNo = jsonResponse ? jsonResponse.response_id : null;
    var peyeeContact = this.data.mobileNo ? this.data.mobileNo : null;
    var billAmount = (parseFloat(this.data.amount)).toString(); 
    var amountPaid =  this.data.amount - this.data.serviceFee;
    var fee = this.data.serviceFee;
    var transNo = this.data.responseJsonData ? this.data.responseJsonData.data.meta.TransactionNo : null;
    var AccountNo = this.data.referenceNo;
    var vipLogo = this.vipLogo.nativeElement.innerHTML;

    if(this.isA4.value){
      // var Newstr = (this.receipt[1].value).replace("{{Date}}", completedDate);
      // Newstr = (Newstr).replace("{{Time}}", transTime);
      // Newstr = (Newstr).replace("{{Cashier}}", cashier);

      // Newstr = (Newstr).replace("{{RefNo}}", refNo);
      // Newstr = (Newstr).replace("{{RecValNo}}", "");
      // Newstr = (Newstr).replace("{{TransactionNo}}", transNo);
      // Newstr = (Newstr).replace("{{PayeeContact}}", peyeeContact);
      
      // Newstr = (Newstr).replace("{{AccountNo}}", AccountNo);
      // Newstr = (Newstr).replace("{{BillerCode}}", "");
      // Newstr = (Newstr).replace("{{BillerName}}", "Cardless Withdrawal");
      // Newstr = (Newstr).replace("{{BillAmount}}", parseFloat(billAmount).toFixed(2));
      // Newstr = (Newstr).replace("{{AmountPaid}}", parseFloat(amountPaid.toString()).toFixed(2));
      // Newstr = Newstr.replace("{{receiptLogo}}", vipLogo);
      // Newstr = Newstr.replace("{{dti}}", this.dti);
      // Newstr = Newstr.replace("{{tinNo}}", this.tin);
      // Newstr = Newstr.replace("{{address}}", this.address);
      
      // this.PrintReceipt(Newstr);
      } else {
  
        var Newstr = (this.receipt[11].value).replace("{{Date}}", completedDate);
        Newstr = (Newstr).replace("{{Time}}", transTime);
        Newstr = (Newstr).replace("{{RecID}}", reqId);
        Newstr = (Newstr).replace("{{Cashier}}", cashier);
        Newstr = (Newstr).replace("{{RefNo}}", refNo);
        Newstr = (Newstr).replace("{{PartnerRefNo}}", PartnerRefNo);
        Newstr = (Newstr).replace("{{TotalAmount}}", this.makeMoney(billAmount));
        Newstr = (Newstr).replace("{{ServiceFee}}", fee);
        Newstr = (Newstr).replace("{{PayoutAmount}}", this.makeMoney(amountPaid));
        Newstr = (Newstr).replace("{{receiptLogo}}", vipLogo);
        Newstr = (Newstr).replace("{{dti}}", this.dti);
        Newstr = (Newstr).replace("{{tinNo}}", this.tin);
        Newstr = (Newstr).replace("{{address}}", this.address);
     this.PrintReceipt(Newstr);
   }
  }

  PrintReceipt(Newstr: any)
  {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write(Newstr);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    return true;
  }
}
