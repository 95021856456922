import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class PrintService {
  isPrinting = false;

  constructor(private router: Router) {}

  printDocument(documentName: string, documentData: any) {
    this.isPrinting = true;
    this.router.navigate([
      "/",
      {
        outlets: {
          "print": ["invoicePrint", documentName, documentData],
        },
      },
    ]);
  }

  onDataReady() {
    this.isPrinting = true;
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      //this.router.navigate([{ outlets: { print: null } }]);
    },2000);
  }
}
