<app-loader *ngIf="loader"></app-loader>

<div *ngIf="!loader" [class.isPrinting]="printService.isPrinting" class="col-lg-12 col-md-12 col-sm-12">
  <div class="col-lg-12 col-md-12 col-sm-12">
    <div class="card">
      <div class="list list-row">
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-md d-none d-md-block color-red">
              <span class="text-dark">Request ID: </span> <strong>{{ requestId }}</strong>
            </div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <a style="color: blue" (click)="printReceipt()">Print Receipt <span class="fa fa-print"></span></a> <br />
              <!-- <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" [formControl]="isA4" />
                <label class="form-check-label text-muted" for="flexCheckDefault" style="font-size: 12px">
                  Print via A4
                </label>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-6 col-md-6 col-sm-12 pull-right">
    <div class="card">
      <div class="list list-row">
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">
              <!-- <strong
                >{{ data?.billerCategory }} <br />
                {{ data?.billerName }} ({{ data?.billerData.code }})</strong
              > -->
            </div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <img
                src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file90ed8163-f9f8-49e2-9043-12f600317cc1.png"
                height="50"
                class="img-responsive"
                style="object-fit: cover"
              />
            </div>
          </div>
        </div>

        <hr />

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Client Type</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.clientType == "C" ? "Client" : "Individual" }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19" *ngIf="isIndividual">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Full Name</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.title + " " + data.firstName + " " + data.middleName + " " + data.lastName }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19" *ngIf="!isIndividual">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Company Name</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.companyName.toUpperCase() }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19" *ngIf="!isIndividual">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Industry Type</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.industryType }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Contact No</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.contactNo }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Email Address</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.emailAddress.toUpperCase() }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Address 1</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.address1.toUpperCase() }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Address 2</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.address2.toUpperCase() }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Province</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.province }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Postal Code</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.postalCode }}
            </div>
          </div>
        </div>

        <hr />

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Transaction No</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.transactionId }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Created Date</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.createdDate | date: "dd-MMM-yyyy hh:mm:ss a" }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Completed Date</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.completedDate | date: "dd-MMM-yyyy hh:mm:ss a" }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Status</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x"><app-status-table-data [status]="data?.statusId"></app-status-table-data></div>
          </div>
        </div>

        <div *ngIf="data?.statusId == 3 && roleLevel == '1'" class="list-item" data-id="19">
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <button class="btn btn-sm btn-success w-100" (click)="completeTransaction(response?.issirefno[0])">
                <i class="fa fa-check"></i> &nbsp; Complete
              </button>
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Outlet Commission</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data?.outletShare | number: "1.2-2" }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Amount</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.amountToBePaidByCustomer | number: "1.2-2" }}</div>
          </div>
        </div>
        <hr />

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">ISSI Ref no.</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <a style="color: blue" (click)="viewCOC(viewImageModal, response.issirefno[0])">
                <span style="font-weight: bold">{{ response?.issirefno[0] }}</span> &nbsp;
                <span class="fa fa-eye"></span>
              </a>
            </div>
          </div>
        </div>

        <hr />
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Total Amount</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ makeMoney(data?.amountToBePaidByCustomer) | number: "1.2-2" }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-6 col-md-6 col-sm-12 pull-left">
    <div class="card">
      <div class="list list-row">
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-sm d-none d-md-block">
              <strong
                >{{ data?.transactByUser.cashierUser.firstName + " " + data?.transactByUser.cashierUser.lastName }}
                <br />
                {{ data?.transactByUser.email }}</strong
              >
            </div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <img
                src="../../../../assets/images/banner/dbg.jpg"
                height="70"
                class="avatar w-40 img-responsive"
                style="object-fit: cover"
              />
            </div>
          </div>
        </div>

        <hr />

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Inception Date</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.inceptionDt | date: "dd-MMM-yyyy hh:mm:ss a" }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Expiry Date</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.expiryDt | date: "dd-MMM-yyyy hh:mm:ss a" }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Branch Code</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.branchCode }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Product Type</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.productCode }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Plate No</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.plateNo.toUpperCase() }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Year No</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.yearModel }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Manufacturer</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.manufacturer }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Make Model</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.makeModel }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Body Type</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.bodyType }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Sub-Body Type</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.bodySubType }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Vehicle Type</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.vehicleType.toUpperCase() }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Engine Type</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.engineType }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Seat</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.seat }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Wheel</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.wheels }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Engine No</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.engineNo.toUpperCase() }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Chassis No</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.chassisNo.toUpperCase() }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">MV File No</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.mvFileNo }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Vehicle Color</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.vehicleColor }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Registration Type</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.registrationType == "N" ? "New" : "Renewal" }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">MV Type</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.mvType }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Tax Type</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.taxType }}
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date font-weight-bold text-sm d-none d-md-block">Premium Type</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ data.mvPremiumType }}
            </div>
          </div>
        </div>
        <!-- <div *ngIf="!isTicketActive">
          <br/>

          <div class="w-100 d-flex justify-content-end">
            <button class="btn btn-sm btn-warning" style="width: 120px;" (click)="openTicket()" > Open Ticket &nbsp; <i class="fa fa-tag"></i> </button>
        </div>
        <br/> 
        </div> -->
      </div>
    </div>
  </div>

  <!-- <app-ticket-create-transaction 
  *ngIf="isTicketActive"
  [requestId]="requestId"
  transactionType="STANDARD_INSURANCE"
  [username]="data.transactByUser.username">
</app-ticket-create-transaction> -->
</div>

<div #vipLogo hidden>
  <img
    src="https://juanpaycdn.azureedge.net/juanpayassets/LOGO_logowithtagline-removebg-preview.png"
    alt="Go-VIP-Logo-Logo-Small"
    height="80px"
  />
</div>

<ng-template #viewImageModal let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Image Viewer</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <ngx-extended-pdf-viewer
      [base64Src]="imagePreview"
      backgroundColor="#ffffff"
      height="100%"
      [useBrowserLocale]="true"
      [handTool]="true"
      [showHandToolButton]="false"
      [showSidebarButton]="false"
      [showFindButton]="false"
      [showPagingButtons]="true"
      [showZoomButtons]="true"
      [showPresentationModeButton]="false"
      [showOpenFileButton]="false"
      [showPrintButton]="false"
      [showDownloadButton]="true"
      [filenameForDownload]="false"
      [showBookmarkButton]="false"
      [showSecondaryToolbarButton]="false"
      [showRotateButton]="false"
      [showHandToolButton]="false"
      [showScrollingButton]="false"
      [showSpreadButton]="false"
      [showPropertiesButton]="false"
    >
    </ngx-extended-pdf-viewer>
  </div>
</ng-template>
