<app-loader *ngIf="loader"></app-loader>

<div *ngIf="!loader" [class.isPrinting]="printService.isPrinting" class="col-lg-12 col-md-12 col-sm-12">
  <div class="col-lg-12 col-md-12 col-sm-12">
    <div class="card">
      <div class="list list-row">
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-md d-none d-md-block color-red">
              <span class="text-dark">Request ID: </span> <strong>{{ requestId }}</strong>
            </div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <a style="color: blue" (click)="printReceipt()">Print Receipt <span class="fa fa-print"></span></a> <br />
              <!-- <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" [formControl]="isA4" />
                <label class="form-check-label text-muted" for="flexCheckDefault" style="font-size: 12px">
                  Print via A4
                </label>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group">
    <div class="row">
      <div class="col-4">
        <p><strong>Insurance Details</strong><br /></p>
        <div class="card">
          <div class="list list-row">
            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Insurance Plan</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ data.productType == "PA" ? "Personal Accident" : "Compulsory Third Party Liability" }}
                </div>
              </div>
            </div>
            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Product Type</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ data.productKey }}
                </div>
              </div>
            </div>
            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Product Plan</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  <!-- {{ vipProtectFormGroup.controls.birthDate | date: "yyyy-MM-dd"  }} -->
                  {{ data.planKey }}
                </div>
              </div>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Coverage Period</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ data.coveragePeriod }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card">
          <p><strong>More Details</strong><br /></p>

          <div class="list list-row">
            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Partner Policy Id</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ data.partnerPolicyId }}
                </div>
              </div>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Policy Id</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ data.policyId }}
                </div>
              </div>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Outlet Share</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ data.outletShare | number: "1.2-2" }}
                </div>
              </div>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Total Amount</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ data.amount | number: "1.2-2" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-4">
        <div class="card">
          <p><strong>Insured Details</strong><br /></p>

          <div class="list list-row">
            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Full Name</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">{{ data.insured.fullName }}</div>
              </div>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Birthdate</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ data.insured.dateOfBirth | date: "MMM dd, yyyy" }}
                </div>
              </div>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Email Address</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ data.insured.email }}
                </div>
              </div>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date text-muted text-sm d-none d-md-block">Occupation</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ data.insured.occupation }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="row">
        <div class="col-4">
          <div class="form-group">
            <p>
              <strong>Policy Holder Details</strong><br />
            </p>
            <div class="card">
              <div class="list list-row">

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Full Name</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">{{ data.policyHolder.fullName }}</div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Gender</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.policyHolder.gender }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Birthdate</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.policyHolder.dateOfBirth | date: "MMM dd, yyyy"  }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Occupation</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.policyHolder.occupation }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Contact Number</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.policyHolder.phoneAreaCode }} {{ data.policyHolder.phoneNumber }} 
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Email Address</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.policyHolder.email }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Address Line 1</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.policyHolder.addressLine1 }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Address Line 2</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.policyHolder.addressLine2 }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Province</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.policyHolder.province }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">City</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.policyHolder.city }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Postal Code</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.policyHolder.postcode }}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="col-4">
          <div class="form-group">
            <p>
              <strong>Insured Details</strong><br />
            </p>
            <div class="card">
              <div class="list list-row">

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Full Name</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">{{ data.insured.fullName }}</div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Gender</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.insured.gender }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Birthdate</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.insured.dateOfBirth | date: "MMM dd, yyyy"  }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Occupation</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.insured.occupation }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Contact Number</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.insured.phoneAreaCode }} {{ data.insured.phoneNumber }} 
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Email Address</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.insured.email }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Address Line 1</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.insured.addressLine1 }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Address Line 2</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.insured.addressLine2 }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Province</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.insured.province }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">City</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.insured.city }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Postal Code</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.insured.postcode }}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="col-4">
          <div class="form-group">
            <p>
              <strong>Beneficiary Details</strong><br />
            </p>
            <div class="card">
              <div class="list list-row">

                <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date text-muted text-sm d-none d-md-block">Relation to Beneficiary</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ data.relationToBeneficiary }}
                      </div>
                    </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Full Name</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">{{ data.beneficiary.fullName }}</div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Gender</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.beneficiary.gender }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Birthdate</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.beneficiary.dateOfBirth | date: "MMM dd, yyyy"  }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Occupation</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.beneficiary.occupation }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Contact Number</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.beneficiary.phoneAreaCode }} {{ data.beneficiary.phoneNumber }} 
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Email Address</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.beneficiary.email }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Address Line 1</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.beneficiary.addressLine1 }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Address Line 2</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.beneficiary.addressLine2 }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Province</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.beneficiary.province }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">City</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.beneficiary.city }}
                    </div>
                  </div>
                </div>

                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Postal Code</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ data.beneficiary.postcode }}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div> -->
</div>

<div #vipLogo hidden>
  <img
    src="https://juanpaycdn.azureedge.net/juanpayassets/LOGO_logowithtagline-removebg-preview.png"
    alt="Go-VIP-Logo-Logo-Small"
    height="50px"
  />
</div>

<ng-template #viewImageModal let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Image Viewer</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <ngx-extended-pdf-viewer
      [base64Src]="imagePreview"
      backgroundColor="#ffffff"
      height="100%"
      [useBrowserLocale]="true"
      [handTool]="true"
      [showHandToolButton]="false"
      [showSidebarButton]="false"
      [showFindButton]="false"
      [showPagingButtons]="true"
      [showZoomButtons]="true"
      [showPresentationModeButton]="false"
      [showOpenFileButton]="false"
      [showPrintButton]="false"
      [showDownloadButton]="true"
      [filenameForDownload]="false"
      [showBookmarkButton]="false"
      [showSecondaryToolbarButton]="false"
      [showRotateButton]="false"
      [showHandToolButton]="false"
      [showScrollingButton]="false"
      [showSpreadButton]="false"
      [showPropertiesButton]="false"
    >
    </ngx-extended-pdf-viewer>
  </div>
</ng-template>
