import { Message } from "@angular/compiler/src/i18n/i18n_ast";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { AuthService } from "src/data/services/authentication/auth.service";
import { CashierUsersService } from "src/data/services/web/cashier-users.service";
import { RolesService } from "src/data/services/web/roles.service";
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { ModalConfirmationComponent } from "../modal-confirmation/modal-confirmation.component";
import { environment } from "src/environments/environment";
import { ChatService } from "src/app/chat.service";

@Component({
  selector: "app-cashier-user-edit",
  templateUrl: "./cashier-user-edit.component.html",
  styleUrls: ["./cashier-user-edit.component.less"],
})
export class CashierUserEditComponent implements OnInit {
  public cashierUserFormGroup: FormGroup;
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public cashierUsersService: CashierUsersService;
  public emptyString = "";
  public roles: any;
  public role: any;
  public bsModalRefConfirmation: BsModalRef;
  public modalService: BsModalService;

  public accessToken: string;
  public newCashierUser: boolean | string;
  public user: any;
  public loadFlag = false;
  public userId: any;
  public base64Photo: string | ArrayBuffer;
  public roleLevelId: any;
  public roleId: any;

  public chatService: any;
  public currentEmail: any;
  public currentContact: any;

  processCreate = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    authService: AuthService,
    chatService: ChatService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    cashierUsersService: CashierUsersService,
    modalService: BsModalService
  ) {
    PNotifyButtons;
    this.authService = authService;
    this.chatService = chatService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.cashierUsersService = cashierUsersService;
    this.modalService = modalService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
  }

  ngOnInit(): void {
    
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    this.route.params.subscribe((params) => {
      const id = decodeURI(params.id || "");
      const status = decodeURI(params.status || "");
      this.newCashierUser = this.cryptoService.get(this.data.authentication.privateKey, status);
      this.rolesService.getRoles(3).then((roles) => {
        this.roles = roles;
       
        if (id) {
          this.userId = this.cryptoService.get(this.data.authentication.privateKey, id);
          this.getCashierUser(this.userId);
        } else {
          this.authService.validateAccessToken(this.roleLevelId).then((res) => {
            const idDefault = this.localStorageCacheService.getStorage("id");
             
            this.getCashierUser(idDefault);
          });
        }
      });
    });
  }

  initCashierUserFormGroup(user: any): void {
    
    this.currentEmail = user.email;
    this.currentContact = user.cashierUser.contactNumber;

    this.cashierUserFormGroup = new FormGroup({
      email: new FormControl(user.email ? user.email : this.emptyString),
      username: new FormControl(user.username ? user.username : this.emptyString),
      firstName: new FormControl(user.cashierUser.firstName ? user.cashierUser.firstName : this.emptyString),
      lastName: new FormControl(user.cashierUser.lastName ? user.cashierUser.lastName : this.emptyString),
      address: new FormControl(user.cashierUser.address ? user.cashierUser.address : this.emptyString),
      contactNumber: new FormControl(
        user.cashierUser.contactNumber ? user.cashierUser.contactNumber : this.emptyString
      ),
      birthDate: new FormControl(user.cashierUser.birthDate ? user.cashierUser.birthDate : this.emptyString),
      roleId: new FormControl(user.roleId ? user.roleId : this.emptyString),
      cashierId: new FormControl(user.cashierUser.cashierId ? user.cashierUser.cashierId : this.emptyString),
      emergencyContactName: new FormControl(
        user.cashierUser.emergencyContactName ? user.cashierUser.emergencyContactName : this.emptyString
      ),
      emergencyContactNumber: new FormControl(
        user.cashierUser.emergencyContactNumber ? user.cashierUser.emergencyContactNumber : this.emptyString
      ),
      emergencyContactRelationship: new FormControl(
        user.cashierUser.emergencyContactRelationship ? user.cashierUser.emergencyContactRelationship : this.emptyString
      ),
      photo: new FormControl(
        user.cashierUser.photoFormat ? `${user.cashierUser.photoFormat},${user.cashierUser.photo}` : this.emptyString
      ),
      status: new FormControl(user.cashierUser.status ? true : false),
      redirect: new FormControl(environment.EMAIL_VERIFICATION_REDIRECT)
    });
    this.loadFlag = true;

    this.cashierUserFormGroup.valueChanges.subscribe((value) => {
     
    });
  }

  getCashierUser(id: any): void {
    this.cashierUsersService.getCashierUser(id).subscribe((result) => {
      this.initCashierUserFormGroup(result.data);
    });
  }

  public actionEventEditCashierUser(): void {
   
    this.processCreate = true;

    this.cashierUsersService.editCashierUser(this.cashierUserFormGroup.value, this.userId).subscribe(
      (result) => {
        const encId = this.cryptoService.set(this.data.authentication.privateKey, result.data.id);
        const encNewData = this.cryptoService.set(this.data.authentication.privateKey, "true");
 
       if(this.currentEmail != result.data.email || this.currentContact != result.data.cashierUser.contactNumber){
        this.resetCashier(result.data.id);
       } else {
        PNotify.success({
          title: "Success",
          text: "Account updated successfully.",
        });
       }
       if(this.roleId == 1){
        this.router.navigate(["acm", "cashier-user-view", { id: encId }]);
       } else if(this.roleLevelId == 2){
        this.router.navigate(["manager", "cashier-user-view", { id: encId }]);
       }
      
      },
      (error) => {
        if (error.status === 400) {
          
          const obj = error.error.errors;
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              let str: string = key;
              str = str[0].toLowerCase() + str.slice(1);
              this.cashierUserFormGroup.controls[str].setErrors({
                required: Validators.required,
                apiMessage: obj[key],
              });
            }
          }

          this.processCreate = false;
        } else {
          // Other error
          this.processCreate = false;
        }
      }
    );
  }

  resetCashier(userId){
    this.cashierUsersService.resetCashierUser(this.cashierUserFormGroup.value, userId).subscribe((result) => {
      PNotify.success({
        title: "Email/Contact no. updated Successfully",
        text: "Account activation has been sent to your cashier's registered email.",
      });

      this.chatService.resetUserSocket(this.currentEmail);
    });
  }

  public onPhotoChange(event: any): void {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.base64Photo = reader.result;

        this.cashierUserFormGroup.patchValue({
          photo: reader.result,
        });

        // need to run CD since file load runs outside of zone
        this.changeDetectorRef.markForCheck();
      };
    }
  }

  backToCashierUsersList(): void {
    if(this.roleId == 1){
      this.router.navigate(["acm", "cashier-user-list"]);
    } else if(this.roleLevelId == 2){
      this.router.navigate(["manager", "cashier-user-list"]);
    }
    
  }
}
