import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Location,Marker } from 'src/data/classes/location-interface';     
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { combineLatest } from "rxjs";
import { AgmMap } from '@agm/core';
import { ArraySortPipe } from 'src/app/pipes/array-sort.pipe';


@Component({
  selector: 'app-agm-map-single',
  templateUrl: './agm-map-single.component.html',
  styleUrls: ['./agm-map-single.component.less']
})
export class AgmMapSingleComponent implements OnInit {
  @Output('updateCoordinates') updateCoordinates = new EventEmitter<{lat: any, lng: any}>();
  @Input("location") location: Location;
  @Input("dragPin") dragPin: boolean;
  @ViewChild(AgmMap)
  public agmMap: AgmMap

 


loader = false;
  constructor() { }

  ngOnInit(): void {
    this.loadDistances();
  }

  ngAfterViewInit(){
    this.agmMap.triggerResize();
  }

locationDisplay;

  loadDistances(){
    
    this.dragPin  =true;
     let currentMarker : Marker = this.location.markers.filter(x=>x.meterDistanceInRadius == -1)[0];
     let locationMarkers : Array<Marker> = this.location.markers.filter(x=>x.meterDistanceInRadius != -1);

     locationMarkers.forEach(marker => {
      marker.meterDistanceInRadius = this.getDistance(currentMarker.lat,currentMarker.lng,marker.lat,marker.lng);
    });


    this.location.markers.forEach(marker => {
      marker.meterDistanceInRadius = Math.round(marker.meterDistanceInRadius);
    });

    this.locationDisplay = this.location.markers.filter(x=>x.meterDistanceInRadius != -1);

    

    
  }


  
  storeAddress = 'N/A';

  showDistance(){

    var origin = {
      lat : this.location.markers[0].lat ,
      lng : this.location.markers[0].lng
    }


    this.getDistanceByRoad(origin).then(data => {
      this.dragPin  =false;

   debugger
      let locationMarkers : Array<Marker> = this.location.markers.filter(x=>x.meterDistanceInRadius != -1);
   
      const arraySortPipe = new ArraySortPipe();
      const arraySortPipeRef = arraySortPipe.transform(locationMarkers,'meterDistanceInRadius');
      
     for (let i = 0; i < 25; i++) {
      arraySortPipeRef[i].meterDistanceInRoad = data.rows[0].elements[i].distance.value + ' meters';
      arraySortPipeRef[i].kmDistanceInRoad = data.rows[0].elements[i].distance.text;
      arraySortPipeRef[i].originAddresses = data.originAddresses[0];
      arraySortPipeRef[i].destinationAddresses = data.destinationAddresses[i];
      this.storeAddress = data.originAddresses[0];
      //data.rows[0].elements[0].distance.text = KMS
      //data.rows[0].elements[0].duration.text = mins
      //data.destinationAddresses[0]
      //data.originAddresses[0]
     }

    });
  }

  getDistance(x1: number, y1: number, x2: number, y2: number) {
    var result = 0;
    const RADIANS: number = 180 / 3.14159265;
    const METRES_IN_MILE: number = 1609.34;
    
    if (x1 == x2 && y1 == y2) {
      result = 0;
    
    } else {
      // Calculating Distance between Points
      var lt1 = x1 / RADIANS;
      var lg1 = y1 / RADIANS;
      var lt2 = x2 / RADIANS;
      var lg2 = y2 / RADIANS;
    
      // radius of earth in miles (3,958.8) * metres in a mile * position on surface of sphere...
      result = (3958.8 * METRES_IN_MILE) * Math.acos(Math.sin(lt1) * Math.sin(lt2) + Math.cos(lt1) * Math.cos(lt2) * Math.cos(lg2 - lg1));
    }
    return result; }



    public getDistanceByRoad(origin: any) : any {

      var destinations : google.maps.LatLng[] = [];
      let locationMarkers : Array<Marker> = this.location.markers.filter(x=>x.meterDistanceInRadius != -1);
   
      const arraySortPipe = new ArraySortPipe();
      const arraySortPipeRef = arraySortPipe.transform(locationMarkers,'meterDistanceInRadius');


    for (var i = 0; i < 25; i++) {
      destinations.push(new google.maps.LatLng(arraySortPipeRef[i].lat,arraySortPipeRef[i].lng));
    }



      const matrix = new google.maps.DistanceMatrixService();
      return new Promise((resolve, reject)=>{
        matrix.getDistanceMatrix({
        origins: [new google.maps.LatLng(origin.lat,origin.lng)],
        destinations:  destinations,
        travelMode: google.maps.TravelMode.DRIVING,
        }, function(response, status) {
          if(status === 'OK'){
            resolve(response)
          }else{
            reject(response);
          }
        });
      })
    }

  map: google.maps.Map;
  mapClickListener : any;
  zone : any;
  public mapReadyHandler(map: google.maps.Map): void {

    this.map = map;
    this.mapClickListener = this.map.addListener('click', (e: google.maps.MouseEvent) => {
        
        this.location.markers[0].lat = e.latLng.lat();
        this.location.markers[0].lng =  e.latLng.lng();
        this.updateCoordinates.emit({lat: e.latLng.lat(), lng: e.latLng.lng()});
        this.loadDistances();
    });
  }
  
  public ngOnDestroy(): void {
    if (this.mapClickListener) {
      this.mapClickListener.remove();
    }
  }

  onMouseOver(infoWindow, gm) {


try {
  if (gm.lastOpen != null) {
    gm.lastOpen.close();
}
gm.lastOpen = infoWindow;
infoWindow.open();
}catch(e){

}
    
}

  onDoubleClick(i){
    this.location.markers[i].showRadius = !this.location.markers[i].showRadius;
    
  }

private onCenterChanged(center:google.maps.Circle):void {

}

private onRadiusChanged(radius:number):void {

}

}
