import { EventEmitter, Injectable, Output } from '@angular/core';  
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';  
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { environment } from 'src/environments/environment';
import { Message } from './models/message';
  
@Injectable()  
export class ChatService {  
  @Output() messageReceived = new EventEmitter<Message>();  
  @Output() onlineUsersReceived = new EventEmitter<any>();  
  @Output() logoutUserEmail = new EventEmitter<any>();  
  @Output() logsReceived = new EventEmitter<any>();  
  connectionEstablished = new EventEmitter<Boolean>();  
  
  private connectionIsEstablished = false;  
  private _hubConnection: HubConnection;  
  
  constructor(private localStorageCacheService: LocalStorageCacheService, private webSettingService : WebSettingsService) {  

    // const footprint = this.localStorageCacheService.getStorage("footprint");
    // const email = this.localStorageCacheService.getStorage("email_address");
    //     if(email){
    //       this.webSettingService.getGeolocation().subscribe((geolocation) => {
    //       //  this.createConnection(email,footprint,JSON.stringify(geolocation));  
    //      ///   this.registerOnServerEvents();  
    //      ///   this.OnlineUsersOnServerEvents();
    //      //   this.LogoutReceivedOnServerEvents();
    //     ///    this.ResetUserReceivedOnServerEvents();
    //      //   this.LogsReceivedOnServerEvents();
    //      // this.startConnection();  
    //       });
    //     }
  }  
  
  sendMessage(message: any) {  
   // this._hubConnection.invoke('NewMessage', message);  
  }  
  
  logoutSocket(email: any) {  
  //  this._hubConnection.invoke('Logout', email);  
  }  

  resetUserSocket(email: any) {  
    //this._hubConnection.invoke('ResetUser', email);  
  }  
  

  private createConnection(email:any,footprint : any,geolocation : any) {  
     
      this._hubConnection = new HubConnectionBuilder()  
      .withUrl(`${environment.API_URL}/MessageHub?uname=${email}&footprint=${footprint}&geolocation=${geolocation}`) 
      .build();  

  }  
  
  private startConnection(): void {  
   
    this._hubConnection  
      .start()  
      .then(() => {  
        this.connectionIsEstablished = true;  
        this.connectionEstablished.emit(true);  
      })  
      .catch(err => {  
        console.log('Error while establishing connection, retrying...');  
        setTimeout(function () { this.startConnection(); }, 5000);  
      });  
  }  
  
  private registerOnServerEvents(): void {  
    // this._hubConnection.on('MessageReceived', (data: any) => {  
    //   this.messageReceived.emit(data);  
    // });  
  }  

  private OnlineUsersOnServerEvents(): void {  
    // this._hubConnection.on('OnlineUsers', (data: any) => {  
    //   //debugger
    // var onlineUsers = JSON.parse(data);
    // this.onlineUsersReceived.emit(onlineUsers);
    // const email = this.localStorageCacheService.getStorage("email_address");
    // if(onlineUsers.find(x=>x.name == email))
    //         var user = onlineUsers.filter(x=>x.name == email)[0];
    //         const footprint = this.localStorageCacheService.getStorage("footprint");
    //         if(user.footprint != footprint){
    //             this.localStorageCacheService.removeStorage("email_address");
    //             this.localStorageCacheService.removeStorage("access_token");
    //             this.localStorageCacheService.removeStorage("refresh_token");
    //             window.location.href = `login`;
    //         }
    // });  
  }  

  private LogoutReceivedOnServerEvents(): void {  
    // this._hubConnection.on('LogoutReceived', (data: any) => {  
    // const email = this.localStorageCacheService.getStorage("email_address");
    // this.logoutUserEmail.emit(data);
    //     if(email == null){
    //         location.reload();
    //     }
    // });  
  }  

  private LogsReceivedOnServerEvents(): void {  
    // this._hubConnection.on('LogsReceived', (data: any) => {  
    // this.logsReceived.emit(data);
    // });  
  }  

  

  private ResetUserReceivedOnServerEvents(): void {  
    // this._hubConnection.on('ResetUserReceived', (data: any) => {  
    // this.localStorageCacheService.removeStorage("email_address");
    // this.localStorageCacheService.removeStorage("access_token");
    // this.localStorageCacheService.removeStorage("refresh_token");
    // window.location.href = `login`;
    // });  
  }  

  
}    