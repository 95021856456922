import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { ManagerUsersService } from 'src/data/services/web/manager-users.service';
import { WalletService } from 'src/data/services/web/wallet.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { CurrencyPipe, DatePipe } from '@angular/common';
import { VipcoTransactionService } from 'src/data/services/web/vipco-transaction.service';
import { result } from 'lodash';
import { environment } from 'src/environments/environment';
import { TransactionService } from 'src/data/services/web/transaction.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-vips-rider-credit',
  templateUrl: './vips-rider-credit.component.html',
  styleUrls: ['./vips-rider-credit.component.less']
})
export class VipsRiderCreditComponent implements OnInit {

  public localStorageCacheService: LocalStorageCacheService;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value:any = "";
  emptyString: any = "";

  searchByFormGroup: FormGroup;
  sendFormGroup: FormGroup;

  users: any = [];
  
  transactions: any = [];
  
  constructor(localStorageCacheService: LocalStorageCacheService,
    private modalServe: NgbModal,
    private usersService: ManagerUsersService,
    private walletService: WalletService,
    private store: AngularFirestore,
    private datepipe: DatePipe,
    private transactionService: TransactionService,
    private vipcoService: VipcoTransactionService,
    private currencyPipe: CurrencyPipe,
    public webSettingsService: WebSettingsService) { 
   
    this.localStorageCacheService = localStorageCacheService;
  }

  ngOnInit(): void {
    this.value = this.localStorageCacheService.getStorage("forQR");

    this.initSendFormGroup();
    this.initSearchTransactionFormGroup();
    this.getTransaction();
    this.getManagerUser(this.localStorageCacheService.getStorage("id"));
    this.getWallet();
    this.initReceipt();
   
  }

  initSendFormGroup(): void {
    this.sendFormGroup = new FormGroup({
      amount: new FormControl(0),
      creditamount: new FormControl(0),
      accountNumber: new FormControl(this.emptyString),
      accountName: new FormControl(this.emptyString),
      bank: new FormControl(this.emptyString),
      pinCode: new FormControl(this.emptyString),
      otp: new FormControl(this.emptyString)
    });
  }

  riderCreditWallet: any;
  public searchTranscationFormGroup: FormGroup;

  initSearchTransactionFormGroup() {
    this.searchTranscationFormGroup = new FormGroup({
      status: new FormControl(0),
      username: new FormControl(null),
      dateRange : new FormControl(null),
      requestId : new FormControl(null),
      showRows: new FormControl(null),
      searchString: new FormControl(this.emptyString)
    });
  }

  getWallet(): void {
    this.walletService.getWallet().subscribe((result) => {
      this.wallet = result.wallet;
    });
    
    let userId = this.localStorageCacheService.getStorage("id");
    this.store.collection("RiderCreditWallet").doc(userId).valueChanges().subscribe((result: any) => {
      this.riderCreditWallet = result.balance;
    });
  }

  wallet: any;
  user: any;
  getManagerUser(id: any): void {
    this.usersService.getManagerUser(id).subscribe((result) => {
      this.user = result.data;
    });
  }

  searchData(){

  }

  initSearchFormGroup(): void {
    this.searchByFormGroup = new FormGroup({
      showRows: new FormControl(null),
      dateRange: new FormControl(this.emptyString),
      searchString: new FormControl(null),
    });
  }

  viewUser(id: any){

  }

  receipt: any;
  initReceipt() {
    this.webSettingsService.getWebSettingsByIdentifier("GoVIPX_QR_TEMPLATE").subscribe((result) => {
      this.receipt = result.data;
    });
  }

  openQR(content: any){
    this.modalServe.open(content, { centered: true, size: "lg" });
  }

  getQR(myQRTemplate: any){
    var qrtemplate = myQRTemplate.innerHTML;

    var Newstr = (this.receipt[0].value)
      .replace("{{QR}}", qrtemplate);
    
    this.PrintReceipt(Newstr);
  }

  PrintReceipt(Newstr: any)
  {
    var mywindow = window.open('', 'PRINT', 'height=700,width=1000');
    mywindow.document.write(Newstr);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    this.modalServe.dismissAll();

    return true;
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  async transferCredit(){
    const id = this.localStorageCacheService.getStorage("id");
    debugger
    let amount = Number(this.replaceComma(this.sendFormGroup.controls.creditamount.value));

    debugger
    if(this.wallet >= amount && amount > 0){
      await this.vipcoService.transferRiderCreditWallet(amount, true).subscribe((result) => {
        if(result){
          this.sendFormGroup.controls.creditamount.patchValue('');
      
          PNotify.success({
            title: "Transfer VWallet",
            text: "Rider Successfully Credited.",
          });

          //this.getWallet();
          //this.getTransaction();
          this.verifyRiderModal.close();
          this.verifyRiderModal.dismiss();
          window.location.reload();
           
        } else {
          PNotify.error({
            title: "Transfer VWallet",
            text: "Opps! Insufficient Balance.",
          });

          this.sendFormGroup.controls.creditamount.patchValue('');
          this.verifyRiderModal.close();
          this.verifyRiderModal.dismiss();
        }
      }, (error) => {
        PNotify.error({
          title: "Something Went Wrong",
          text: "Please try again in a moment.",
        });
      });

    } else if (amount == 0) {
      PNotify.error({
        title: "Invalid Amount",
        text: "Please input valid amount.",
      });
    } else {
      PNotify.error({
        title: "Transfer VWallet",
        text: "Opps! Insufficient Balance.",
      });
    }
  }

  replaceComma(num) {
    return num.replace(/,/g, '');
  };

  async transferToVWallet(){
    this.getWallet();
    const id = this.localStorageCacheService.getStorage("id");
    let amount = Number(this.replaceComma(this.sendFormGroup.controls.amount.value));
debugger
    if(this.riderCreditWallet >= amount && amount > 0){
      await this.vipcoService.transferRiderCreditWallet(amount, false).subscribe((result) => {
        if(result){
          this.sendFormGroup.controls.amount.patchValue('');
      
          PNotify.success({
            title: "Transfer VWallet",
            text: "Rider Successfully Credited.",
          });

          //this.getWallet();
          //  this.getTransaction();
          this.verifyModal.close();
          this.verifyModal.dismiss();
          window.location.reload();
            
        } else {
          PNotify.error({
            title: "Transfer VWallet",
            text: "Opps! Insufficient Balance.",
          });

          this.sendFormGroup.controls.amount.patchValue('');
          this.verifyModal.close();
          this.verifyModal.dismiss();
        }
      }, (error) => {
        PNotify.error({
          title: "Something Went Wrong",
          text: "Please try again in a moment.",
        });
      });
    } else if (amount == 0) {
      PNotify.error({
        title: "Invalid Amount",
        text: "Please input valid amount.",
      });
    } else {
      PNotify.error({
        title: "Transfer VWallet",
        text: "Opps! Insufficient Balance.",
      });
    }
  }

  public loader = false;
  p: number = 1;
  public pageRows: any = 10;

  getTransaction(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader = true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }
    //debugger
    this.loader = true;
    //const requestId : string = this.searchTranscationFormGroup.controls.requestId.value;
    const dateRange : string = this.searchTranscationFormGroup.controls.dateRange.value;
    //const statusId : string = this.searchTranscationFormGroup.controls.status.value;
    const searchString : string = this.searchTranscationFormGroup.controls.searchString.value;
    const showRows : string = this.searchTranscationFormGroup.controls.showRows.value;
    const userId : string = this.localStorageCacheService.getStorage("id");

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    //const queryString_requestId: string = requestId ? `requestId=${requestId}&` : "";
    //const queryString_statusId: string = statusId ? `statusId=${statusId}&` : "";
    //const queryString_transactFrom: string = transactFrom ? `transactFrom=${transactFrom}&` : "";
    const queryString_userId: string = userId ? `userId=${userId}&` : "";
    const queryString_searchString: string = searchString ? `requestId=${searchString}&` : "";
    const queryString_showRows: string = showRows ? `showRows=${showRows}&` : `showRows=10&`;
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    var queryString = queryString_orderby + queryString_order + queryString_userId + queryString_dateRange + queryString_showRows + queryString_searchString;
    if (fromSearch) {
      this.p = 1;
      queryString =`pageNumber=${1}&pageSize=${10}&${queryString}`;
    }

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/VIPCOTransactions/RiderCreditTransactions?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/VIPCOTransactions/RiderCreditTransactions?${queryString}`;
      }
    }

    this.transactionService.getTransaction(endPointUrl.slice(0, -1)).subscribe((result) => {
      if(result){
        if (result.data.length == 0 && result.pageNumber != 1  && fromSearch == false) {
          this.getTransaction(result.previousPage, _sortBy, _sort);
        } else {
          this.transactions = result;
        }
        this.loader = false;
      }
    },
    (error) => {
      if (error.status === 400) {
        PNotify.notice({
          title: "Warning",
          text: "Username is required.",
        });
      } else {

        PNotify.error({
          title: "Error",
          text: "Server unavailable. PLease try again later.",
        });
      }
      this.loader = false;
    });
  }

  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p=pageNumber;
    return `${environment.API_URL}/api/VIPCOTransactions/RiderCreditTransactions?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  createdDate = "";
  searchString = "";

  private _sortBy: any;
  private _sort: any;

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.createdDate = field == "createdDate" ? "th-inner sortable both " + sortBy : "";
    
    this.getTransaction(
      `${environment.API_URL}/api/VIPCOTransactions/VipsTransactions?pageNumber=${
        page ? page : this.transactions.pageNumber
      }`,
      this._sortBy,
      this._sort
    );
  }

  verifyModal: NgbModalRef;
  confirmModal(content){
    let amount = Number(this.sendFormGroup.controls.amount.value);

    if(amount == 0){
      PNotify.error({
        title: "Invalid Amount",
        text: "Please input valid amount.",
      });
    } else {
      this.verifyModal = this.modalServe.open(content, { centered: true, size: "md" });
    }
  }
  
  verifyRiderModal: NgbModalRef;
  confirmRiderModal(content){
    let amount = Number(this.sendFormGroup.controls.creditamount.value);

    if(amount == 0){
      PNotify.error({
        title: "Invalid Amount",
        text: "Please input valid amount.",
      });
    } else {
      this.verifyRiderModal = this.modalServe.open(content, { centered: true, size: "md" });
    }
  }
}
