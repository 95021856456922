import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StandardInsuranceService {

  private localStorageCacheService: LocalStorageCacheService;

  constructor(
    private httpClient: HttpClient,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
  }
  private httpClientNoInterceptor: HttpClient;

  private baseAPIUrl = environment.API_URL;

  public getAuthenticated(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StandardInsurance/Authenticate`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getIndustryType(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StandardInsurance/IndustryType`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getProductType(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StandardInsurance/ProductType`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getPremiumType(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StandardInsurance/PremiumType`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getMVType(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StandardInsurance/MVType`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getTitle(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StandardInsurance/Title`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }
  
  public getRegistrationType(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StandardInsurance/RegistrationType`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }
  
  public getTaxType(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StandardInsurance/TaxType`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getManufacturer(productType: any, year: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StandardInsurance/Manufacturer`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        productType: productType,
        yearModel: year
      }

    return this.httpClient.post(`${endPointUrl}`, json , { headers: httpHeaders });
  }

  public getMakeModel(productType: any, year: any, manufacturer: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StandardInsurance/Model`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        productType: productType,
        yearModel: year,
        manufacturer: manufacturer
      }

    return this.httpClient.post(`${endPointUrl}`, json , { headers: httpHeaders });
  }

  public getfmvDetails(yearModel: any, manufacturer: any, model: any, productType: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StandardInsurance/FMVDetails`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        yearModel: yearModel,
        manufacturer: manufacturer,
        model: model,
        productCode: productType
      }

    return this.httpClient.post(`${endPointUrl}`, json , { headers: httpHeaders });
  }

  public getBodyType(productType: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StandardInsurance/BodyType`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        productType: productType
      }

    return this.httpClient.post(`${endPointUrl}`, json , { headers: httpHeaders });
  }

  public getSubBodyType(bodyType: any, productType: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StandardInsurance/SubBodyType`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        bodyType: bodyType,
        productType: productType
      }

    return this.httpClient.post(`${endPointUrl}`, json , { headers: httpHeaders });
  }
  
  public getVehicleType(productType: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StandardInsurance/VehicleType`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        productType: productType
      }

    return this.httpClient.post(`${endPointUrl}`, json , { headers: httpHeaders });
  }

  public processStandardInsurance(standardInsurance:any ,code:any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StandardInsurance/Process`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");
      standardInsurance.PinCode  = code;

    return this.httpClient.post(`${endPointUrl}`, standardInsurance ,{ headers: httpHeaders });
  }

  public getTransaction(requestId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StandardInsurance/Transaction/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getTransactions(url: string): Observable<any> {
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${url}`, { headers: httpHeaders });
  }

  public getCOC(issi_ref_no : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StandardInsurance/COC/${issi_ref_no}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }


  public rejectTransaction(requestId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StandardInsurance/Billers/Failed/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.put(`${endPointUrl}`,"",{ headers: httpHeaders });
  }

  public completeTransaction(issi : any, transID): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StandardInsurance/CompleteTransaction`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = 
      { 
        ISSI_REF_NO : issi,
        TRANSACTION_ID : transID
      };

    return this.httpClient.post(`${endPointUrl}`,json,{ headers: httpHeaders });
  }

  public reProcessPayment(biller : any, billerName : any,billerCategory: any ,  requestId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Multisys/Billers/Reprocess/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = 
      { 
        biller : biller,
        billerName : billerName,
        billerCategory : billerCategory
      };

    return this.httpClient.put(`${endPointUrl}`, json,{ headers: httpHeaders });
  }
}
