import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { FormGroup } from "@angular/forms";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class WebSettingsService {
  private localStorageCacheService: LocalStorageCacheService;

  constructor(
    private httpClient: HttpClient,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
  }
  private httpClientNoInterceptor: HttpClient;

  private baseAPIUrl = environment.API_URL;

 

  public getGeolocation(): Observable<any> {
    const endPointUrl = "https://geolocation-db.com/json/";
    return this.httpClient.get(`${endPointUrl}`);
  }

  public getWebSettings(url: any): Observable<any> {
    const endPointUrl = url;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getWebSettingsSingle(id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/websettings/${id}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public updateWebSettings(webSettingsFormGroup: any, id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/websettings/${id}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    webSettingsFormGroup.values = JSON.stringify(webSettingsFormGroup.values);

    return this.httpClient.put(endPointUrl, webSettingsFormGroup, { headers: httpHeaders });
  }

  public createWebSettings(webSettingsFormGroup: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/websettings`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    webSettingsFormGroup.values = JSON.stringify(webSettingsFormGroup.values);

    return this.httpClient.post(endPointUrl, webSettingsFormGroup, { headers: httpHeaders });
  }

  public getWebSettingsByIdentifier(identifier: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/WebSettings/Identifier`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    const json: any = {
      value: identifier,
    };

    return this.httpClient.post(endPointUrl, json, { headers: httpHeaders });
  }

  public getWebSettingsByIdentifierNonAdmin(identifier: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/WebSettings/IdentifierNonAdmin`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    const json: any = {
      value: identifier,
    };

    return this.httpClient.post(endPointUrl, json, { headers: httpHeaders });
  }

  // tslint:disable-next-line: typedef
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  // tslint:disable-next-line: typedef
  private log(message: string) {
    //
  }
}
