<!-- Load the appropriate layout components -->

<!-- Basik Admin Layout -->
<ng-container
  *ngIf="currentRoute.includes('acm') || currentRoute.includes('manager') || currentRoute.includes('cashier') || currentRoute.includes('partner-sunlife-grepa')
  || currentRoute.includes('sales') || currentRoute.includes('partner-vip-protect') || currentRoute.includes('spx-finance')"
>
  <div>
    <app-basik-admin></app-basik-admin>
  </div>
</ng-container>

<!-- Basik Non-Admin Layout -->
<ng-container
  *ngIf="!currentRoute.includes('acm') && !currentRoute.includes('manager') && !currentRoute.includes('cashier') && !currentRoute.includes('partner-sunlife-grepa')
  && !currentRoute.includes('sales')  && !currentRoute.includes('partner-vip-protect') && !currentRoute.includes('spx-finance')"
>
  <div>
    <app-basik-non-admin></app-basik-non-admin>
    <router-outlet name="print"></router-outlet>
  </div>
</ng-container>


                    
           

<!-- Old static layout -->
<!-- <ng-container *ngIf="false">
  <router-outlet></router-outlet>
</ng-container> -->

