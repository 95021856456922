import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-waybill',
  templateUrl: './waybill.component.html',
  styleUrls: ['./waybill.component.less']
})
export class WaybillComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  waybill(myQRTemplate: any){
    var qrtemplate = myQRTemplate.innerHTML;

    // var Newstr = (this.receipt[0].value)
    //   .replace("{{QR}}", qrtemplate);
    
    this.PrintReceipt(qrtemplate);
  }

  PrintReceipt(Newstr: any)
  {
    var mywindow = window.open('', 'PRINT', 'height=700,width=1000');
    mywindow.document.write(Newstr);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    //this.modalServe.dismissAll();

    return true;
  }
}
