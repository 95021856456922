import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChatService } from 'src/app/chat.service';
import { ManagerUsersService } from 'src/data/services/web/manager-users.service';
import { UserService } from 'src/data/services/web/user.service';

@Component({
  selector: 'app-migration-list',
  templateUrl: './migration-list.component.html',
  styleUrls: ['./migration-list.component.less']
})
export class MigrationListComponent implements OnInit {

  
  messageFormGroup : FormGroup;
  emptyString : any = '';
  migrationList : any;
  migrationListNeed : any;
  loader : any = true;
  constructor(  private usersService: ManagerUsersService,
    private modService: NgbModal) { }

  ngOnInit(): void {
 
    this.getMigrationList();
    this.getMigrationListNeed();

  }

  
  getMigrationList() {
    this.usersService.getMigrationList().subscribe((result) => {
      this.migrationList = result;
      this.loader = false;
    });
  }

  getMigrationListNeed() {
    this.usersService.getNeedMigrationList().subscribe((result) => {
      this.migrationListNeed = result;
      console.log(this.migrationListNeed);
      this.loader = false;
    });
  }

  


  modalMessageTransaction;
  mobileNo;
  username;
  openSendMessage(messageModal,username) {
    this.username = username;
    this.modalMessageTransaction = this.modService.open(messageModal, {
      centered: true,
      size: "xl",
      backdrop: "static",
      keyboard: false,
    });
  }

  closeModal(){
    this.modalMessageTransaction.close();
    
  }
}

