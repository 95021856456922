import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { environment } from "src/environments/environment";
import { FormGroup } from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class CebuanaService {

  private localStorageCacheService: LocalStorageCacheService;

  constructor(
    private httpClient: HttpClient,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
  }
  private httpClientNoInterceptor: HttpClient;

  private baseAPIUrl = environment.API_URL;

  public getTransactions(url: string): Observable<any> {
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${url}`, { headers: httpHeaders });
  }

  public getTransaction(requestId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Cebuana/Transaction/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getPayoutTransactionByRequestID(requestId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Cebuana/PayoutTransaction/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getCurrencyCollection(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Cebuana/GetCurrencyCollection`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getIDCollection(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Cebuana/GetID`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }
  
  public getServiceFee(amount: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Cebuana/GetServiceFees`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        amount : amount
      };

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public getAmendFee(amount: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Cebuana/GetAmendFees`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        amount : amount
      };

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public getSourceOfFundCollection(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Cebuana/GetFundSource`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getOccupationCollection(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Cebuana/GetOccupation`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public findClient(findClientFormGroup: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Cebuana/FindClient`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(`${endPointUrl}`, findClientFormGroup, { headers: httpHeaders });
  }

  public getBeneficiaryBySender(clientID: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Cebuana/GetBeneficiaryBySender`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    var json = {
      senderClientID: clientID
    }

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public addClient(addClientFormGroup: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Cebuana/AddClient`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(`${endPointUrl}`, addClientFormGroup, { headers: httpHeaders });
  }

  public addBeneficiary(addBeneficiaryFormGroup: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Cebuana/addBeneficiary`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(`${endPointUrl}`, addBeneficiaryFormGroup, { headers: httpHeaders });
  }

  public processSendRemittance(remittanceFormGroup: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Cebuana/Billers/ProcessSend`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(`${endPointUrl}`, remittanceFormGroup, { headers: httpHeaders });
  }

  public getPayoutTransaction(controlNumber: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Cebuana/FindDomesticRemittance`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        controlNumber : controlNumber
      };

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public processPayoutRemittance(remittanceFormGroup: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Cebuana/Billers/ProcessPayout`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(`${endPointUrl}`, remittanceFormGroup, { headers: httpHeaders });
  }
  
  public processAmendRemittance(remittanceFormGroup: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Cebuana/Billers/ProcessAmend`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(`${endPointUrl}`, remittanceFormGroup, { headers: httpHeaders });
  }
  
  public processCancelRemittance(remittanceFormGroup: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Cebuana/Billers/CancelDomestic`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(`${endPointUrl}`, remittanceFormGroup, { headers: httpHeaders });
  }
}
