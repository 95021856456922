import { CurrencyPipe, DatePipe } from "@angular/common";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { TransactionService } from "src/data/services/web/transaction.service";
import { RolesService } from "src/data/services/web/roles.service";
import { environment } from "src/environments/environment";
import { UserService } from "src/data/services/web/user.service";
import { Select2OptionData } from "ng-select2";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WebSettingsService } from "src/data/services/web/web-settings.service";
import { ExportExcelService } from "src/data/services/web/export-excel.service";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { VipcoTransactionService } from "src/data/services/web/vipco-transaction.service";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-vipco-transaction',
  templateUrl: './vipco-transaction.component.html',
  styleUrls: ['./vipco-transaction.component.less']
})
export class VipcoTransactionComponent implements OnInit {

  @ViewChild('viewMultisys') viewMultisys: TemplateRef<any>;
  @ViewChild('viewEctopup') viewEctopup: TemplateRef<any>;
  @ViewChild('viewEccash') viewEccash: TemplateRef<any>;
  @ViewChild('viewEcpay') viewEcpay: TemplateRef<any>;
  @ViewChild('viewCti') viewCti: TemplateRef<any>;
  @ViewChild('viewIBayadBankTransfer') viewIBayadBankTransfer: TemplateRef<any>;
  @ViewChild('viewVIPProtect') viewVIPProtect: TemplateRef<any>;
  @ViewChild('viewIbayadMeralco') viewIbayadMeralco: TemplateRef<any>;
  @ViewChild('viewGovipxCashout') viewGovipxCashout: TemplateRef<any>;
  @ViewChild('viewGovipxTopup') viewGovipxTopup: TemplateRef<any>;
  @ViewChild('viewNetBank') viewNetBank: TemplateRef<any>;
  @ViewChild('viewBayad') viewBayad: TemplateRef<any>;
  @ViewChild('viewStandardInsurance') viewStandardInsurance: TemplateRef<any>;
  

  public transactionFilterFormGroup: FormGroup;
  public searchByUsernameFormGroup: FormGroup;
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public vipcoTransactionService: VipcoTransactionService;
  public websettings: WebSettingsService;
  public userService: UserService;
  public emptyString = "";
  public statuses: any;
  public status: any;
  public modalService: BsModalService;

  public accessToken: string;
  public newTransaction: boolean;
  public transactions: any;
  public transactionsExcel: any;
  public transactionsExport: any;
  public transaction: any;
  public trans: any = [];
  public pageCount: any;
  public roleLevelId: any;

  public cashiersAndManagers: any;

  public processUpdate: any = false;

  public bsModalRefConfirmation: BsModalRef;
  public selectedUserId: any = 0;
  
  public userIdFilterOptions: Array<Select2OptionData>;
  public userIdFilter: any = [];
  public showRowsOption: Array<Select2OptionData>;
  public transactFromOption: Array<Select2OptionData>;
  public showRowsFilter: any = [];
  
  public pageRows: any = 10;
  public selectedRowsId: any = 0;
  public loader = true;
  
  public statusIdFilterOptions: Array<Select2OptionData>;
  public statusIdFilter: any = [];
  dataForExcel = [];

  p: number = 1;
  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    private currencyPipe: CurrencyPipe,
    public datepipe: DatePipe,
    private modalBService: NgbModal,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    websettings: WebSettingsService,
    vipcoTransactionService: VipcoTransactionService,
    modalService: BsModalService,
    bsModalRefConfirmation: BsModalRef,
    userService: UserService,
    public ete: ExportExcelService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.vipcoTransactionService = vipcoTransactionService;
    this.modalService = modalService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
    this.bsModalRefConfirmation = bsModalRefConfirmation;
    this.userService = userService;
    this.websettings = websettings;
    PNotifyButtons; // Initiate PNotify buttons. Important!
  }
  userId;
  ngOnInit(): void {
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.userId = this.roleLevelId == 1 ? 0 : this.localStorageCacheService.getStorage("userId");
    
    this.initSearchByUsernameFormGroup();
    this.getStatus();
    this.transactFrom();
    this.initMultipleObservable();

  }


  initMultipleObservable(){
   
   var multipleSubscription = forkJoin(
    this.userService.getCashiersAndManagers(),
    this.websettings.getWebSettingsByIdentifier("SHOW_ROWS")
    );
    multipleSubscription.subscribe((results) => {

      var getCashiersAndManagersResponse = results[0];
      this.cashiersAndManagers = getCashiersAndManagersResponse;
      // Set the username options from the API data
      this.userIdFilterOptions = getCashiersAndManagersResponse.map((user) => {
        return {
          id: user.id,
          text: user.name,
        };
      });
      this.searchByUsernameFormGroup.controls.username.setValue(this.userId, {onlySelf: true});

      var getWebSettingsByIdentifierResponse = results[1];
      this.showRowsOption = getWebSettingsByIdentifierResponse.data.map((status) => {
        return {
          id: status.parameter,
          text: status.value,
        };
      });
     this.searchByUsernameFormGroup.controls.showRows.setValue(10, {onlySelf: true});
     this.searchByUsernameFormGroup.controls.status.setValue(0, {onlySelf: true});
      
      this.getVIPCOTransactions();
      
    });

  }



  initSearchByUsernameFormGroup() {
    this.searchByUsernameFormGroup = new FormGroup({
      status: new FormControl(0),
      username: new FormControl(null),
      dateRange : new FormControl(null),
      requestId : new FormControl(null),
      showRows: new FormControl(null),
      transactFrom: new FormControl(1)
    });

    this.searchByUsernameFormGroup.controls.username.valueChanges.subscribe(e =>{
      this.selectedUser(e);
      this.searchByUsernameFormGroup.controls.dateRange.setValue("");
      this.searchByUsernameFormGroup.controls.requestId.setValue("");
    });
    this.searchByUsernameFormGroup.controls.status.valueChanges.subscribe(e =>{
     // this.getTransaction();
      this.searchByUsernameFormGroup.controls.dateRange.setValue("");
      this.searchByUsernameFormGroup.controls.requestId.setValue("");
    });
    this.searchByUsernameFormGroup.controls.showRows.valueChanges.subscribe(e => {
      this.selectedRows(e);
    });
  }

  private _sortBy: any;
  private _sort: any;

  selectedUser(e) {
    this.selectedUserId = e;
  }

  selectedRows(e){
    this.selectedRowsId = e;
    this.pageRows = e;
    

   
  }
  
  getStatus() {
      this.statusIdFilterOptions = [
        { id: "2", text: "Completed"},
        { id: "3", text: "Pending"},
        { id: "1", text: "Failed"},
        { id: "4", text: "Refund"}
      ];
  }
  
  transactFrom() {
      this.transactFromOption = [
        { id: "1", text: "GoVIP"},
        { id: "2", text: "VIP Cloud"},
        { id: "3", text: "Vips Commission"}
      ];
  }

  statusOfAmount(money: any): boolean {
    return money < 0 ? false : true;
  }

  getVIPCOTransactions(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_userId: string = this.userId ? `userId=${this.userId}&` : "";
    const queryString_searchString: string = this.searchString ? `searchString=${this.searchString}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_userId + queryString_searchString;

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/VIPCOTransactions/Transactions?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/VIPCOTransactions/Transactions${queryString}`;
      }
    }

    this.vipcoTransactionService.getVIPCOTransactions(endPointUrl).subscribe((result) => {
      if (result.data.length == 0 && result.pageNumber != 1) {
        this.getVIPCOTransactions(result.previousPage, _sortBy, _sort);
      } else {
        this.transactions = result;
        this.loader = false;
      }
    });
  }

  getTransactionForExcel(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_userId: string = this.userId ? `userId=${this.userId}&` : "";
    const queryString_searchString: string = this.searchString ? `searchString=${this.searchString}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_userId + queryString_searchString;
                                                                                                                                                                            
    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/VIPCOTransactions/TransactionForExport?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/VIPCOTransactions/TransactionForExport${queryString}`;
      }
    }

    this.vipcoTransactionService.getVIPCOTransactions(endPointUrl).subscribe((result) => {
      if (result.data.length == 0 && result.pageNumber != 1) {
        this.getTransactionForExcel(result.previousPage, _sortBy, _sort);
      } else {
        this.transactionsExcel = result;
        this.generateExcel(result, "VIPCO Transaction");
      }
    });
  }

  createdDate = "";
  searchString = "";

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.createdDate = field == "createdDate" ? "th-inner sortable both " + sortBy : "";
  
    this.getVIPCOTransactions(
      `${environment.API_URL}/api/VIPCOTransactions/Transactions?pageNumber=${
        page ? page : this.transactions.pageNumber
      }`,
      this._sortBy,
      this._sort
    );
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.transactions?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }

  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p = pageNumber;
    return `${environment.API_URL}/api/VIPCOTransactions/Transactions?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  openTransDetail(viewModal) {
    this.modalBService.open(viewModal, { centered: true, size: "xl" });
  }

  exportToExcel() {
    this.trans = [];
    this.dataForExcel = [];
    this.transactionsExport = [];

    this.getTransactionForExcel();
  }

  generateExcel(provider: any, name: any): void {  
      for(var i = 0; i < provider.data.length; i++){
        
        let status = "";
  
        if(provider.data[i].statusId == "1"){
          status = "Failed"
        }  else if (provider.data[i].statusId == "2"){
          status = "Completed"
        } else if (provider.data[i].statusId == "3"){
          status = "Pending"
        }
  
        let meta = [
          {
            "Biller Name": provider.data[i].itemName,
            "Request ID": provider.data[i].requestId,
            "Reference No": provider.data[i].referenceNumber,
            "Transaction Type": provider.data[i].transactionName,
            "Transact By": provider.data[i].user.username,
            "Completed Date": provider.data[i].completedDate,
            "Amount": provider.data[i].amount,
            "Balance Before": this.makeMoney(provider.data[i].openingBalance),
            "Balance After": this.makeMoney(provider.data[i].openingBalance + provider.data[i].amount),
            "Status":	status
          }
        ] 
        
        this.trans.push(meta[0]);
      }

    for(var x = 0; x < this.trans.length; x++){
      this.dataForExcel.push(Object.values(this.trans[x]));
    }
  
    let reportData = {
      title: name + " Report ",
      data: this.dataForExcel,
      headers: Object.keys(this.trans[0])
    }
    
    this.ete.exportExcel(reportData, name);
  }

  public padIntegerLeftWithZeros(rawInteger: number, numberOfDigits: number): any {
    let paddedInteger: string = rawInteger + '';
    while (paddedInteger.length < numberOfDigits) {
      paddedInteger = '0' + paddedInteger;
    }
    return paddedInteger;
  }
}
