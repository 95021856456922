import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "convertToApiDatetime",
})
export class ConvertToApiDatetimePipe implements PipeTransform {
  transform(value: string): any {
    let apiDateTime = "";
    if (value) {
      const splitValue = value.split(" ");
      const date = splitValue[0];
      const hourAndMinutes = splitValue[1];
      apiDateTime = `${date}`;
    }
    return apiDateTime;
  }
}
