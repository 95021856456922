<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w==" crossorigin="anonymous" referrerpolicy="no-referrer" />
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css">
<app-loader *ngIf="loader"></app-loader>
<div class="container-fluid" style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
  <div class="container-fluid" style="padding-inline:10px; width: 95%;display: flex;justify-content: space-between; align-items: center;gap: 20px; margin-block: 5px; padding-right: 25px;">
    <div class="d-flex align-items-center header" style="gap: 15px;">
      <img src="../../../../../assets/vips/Vips_Merchants-Horizontal_01_PNG (1).png" width="170px" alt="">
      <h1 style="font-size: 24px; font-weight: 600;color: #5e676f;">APPLICATION HISTORY</h1>
    </div>
    <div class="search">
      <button type="button" class="btn btn-success p-1" (click)="exportexcel()"><i class="bi bi-file-earmark-spreadsheet"></i>Excel</button>
      <b style="white-space: nowrap;">Total Merchants: {{page.totalOf}} </b>
      <div class="search_">
        <input (click)="showAllData()" type="text" class="form-control search__input" id="myInput" placeholder="Search here" [(ngModel)]="searchText">
        <!-- <label for="StartDate">Start Date</label>
        <input id="EndDate" type="date" [(ngModel)]="startDate">
        <label for="EndDate">End Date</label>
        <input type="date" [(ngModel)]="endDate"> -->
      </div>
  </div>
  </div>
  <!-- <div class="container mt-1" style="display: flex;
  justify-content: flex-end;
  gap: 20px;">
    <div class="startDate" style="display: flex;
    flex-direction: row;
    gap: 10px;">
      <label for="StartDate">Start Date:</label>
        <input id="EndDate" type="date" [(ngModel)]="startDate">
    </div>
    <div class="endDate" style="display: flex;
    flex-direction: row;
    gap: 10px;">
      <label for="EndDate">End Date:</label>
        <input id="EndDate" type="date" [(ngModel)]="endDate">
    </div>
  </div> -->
  <div class="table" style="margin-top: 53px;width: 95%; max-height: 65vh !important;overflow-y: scroll;" scrollable (scrollPosition)="scrollHandler($event)">
    <table id="myTable" class="table table-striped">
        <thead class="w-100">
            <tr style="position: fixed;
            width: 77%;
            background: white;
            z-index: 9;
            top: 162px;
            padding: 0;">
                <th class="fw-600" style="width: 340.59px;" >Business Name</th>
                <th class="fw-600" style="width: 262.05px;" >Merchant Name & Email</th>
                <th class="fw-600" style="width: 265.8px;" >Business Address</th>
                <th class="fw-600" style="width: 106.23px;" >Food Type</th>
                <th class="fw-600" style="width: 101.35px;" >Date</th>
                <th class="fw-600" style="width: 110.03px;" >Status</th>
                <th> </th>
            </tr>
        </thead>
          <tbody *ngFor="let merchant of page.data | async | search: searchText;let i = index;">
              <tr *ngIf="merchant?.application_status || merchant?.application_rejected" style="cursor: pointer;" (click)="openRemark(Remark,merchant,product)" class="align-middle alert" role="alert">
                <td>
                  <div class="d-flex align-items-center" style="gap: 10px;">
                    <div class="img-container">
                        <img [src]="merchant?.business_store_logo"
                            alt="">
                    </div>
                    <div class="ps-3">
                        <div class="fw-400 pb-1">{{ merchant?.business_name }}</div>
                    </div>
                </div>
                </td>
                  <td>
                      <div class="d-flex align-items-center" style="gap: 10px;">
                          <div class="img-container">
                              <img [src]="merchant?.identification_selfie"
                                  alt="">
                          </div>
                          <div class="ps-3">
                              <div class="fw-400 pb-1">{{ merchant?.first_name + " " + merchant?.last_name }}</div>
                              <p class="m-0 text-grey fs-09">{{ merchant?.email_address }}</p>
                          </div>
                      </div>
                  </td>
                  <td>
                    <div class="fw-400">{{ merchant?.business_address_address + " " +
                      merchant?.business_address_barangay + ", " +
                      merchant?.business_address_municipalty + " " +
                      merchant?.business_address_province
                    }}</div>
                  </td>
                  <td>
                    <div class="fw-400">{{ merchant?.business_food_type }}</div>
                  </td>
                  <td>
                    <div class="fw-400 text-muted" style="font-size: 12px;">{{ merchant?.timestamp?.toDate() | date:'MMM dd, yyyy h:mm a' }}</div>
                  </td>
                  <td>
                      <div class="d-inline-flex align-items-center active" *ngIf="!merchant.application_rejected">
                          <div class="circle"></div>
                          <div class="ps-2">Approved</div>
                      </div>
                      <div class="d-inline-flex align-items-center waiting" *ngIf="merchant?.application_rejected">
                        <div class="circle"></div>
                        <div class="ps-2 myDIV">Rejected</div>
                    </div>
                  </td>
                  <td>
                    <!-- <div class="fw-400" *ngIf="merchant.application_rejected">
                      <h5 class="text-center mb-2">Remark:</h5>
                    </div> -->
                  </td>
              </tr>
          </tbody>
      </table>
      <loading-spinner *ngIf="page.loading | async"></loading-spinner>
      <p class="text-center text-muted" *ngIf="page.done | async">No more data to show...</p>
      <!-- <div class="pagin d-flex justify-content-center mt-2" style="padding-left: -40px;">
        <pagination-controls
        previousLabel="Prev"
        nextLabel="Next"
        (pageChange)="p = $event">
      </pagination-controls>
      </div> -->
  </div>
</div>
<ng-template #Remark let-modal>
  <div class="card">
    <div class="rounded-top text-white d-flex flex-row" style="height:200px; padding-inline: 20px; background-size: cover;">
      <img class="rounded-top" [src]="selectedMerchant?.banner"
      style="
      height: 200px;
      width: 100%;
      position: absolute;
      background-size: cover;
      left: 0;" *ngIf="selectedMerchant.banner">
      <img src="../../../../../assets/vips/No BANNER.png" class="img-fluid" style="
      height: 200px;
      width: 100%;
      position: absolute;
      background-size: cover;
      left: 0;" *ngIf="!selectedMerchant.banner">
      <div class="card_0" *ngIf="selectedMerchant.remarks">
        <div class="align">
            <span class="red"></span>
            <h1 style="margin: 0;
            padding: 0;
            font-size: 12px;
            color: white;">Remarks</h1>
        </div>
        <p class="mt-4 text-left" style="font-size: 11px;">
          {{selectedMerchant?.remarks}}
        </p>
      </div>
      <div class="ms-4 mt-5 d-flex flex-column" style="width: 150px;">
        <img [src]="selectedMerchant?.business_store_logo"
          alt="Generic placeholder image" class="img-fluid img-thumbnail mt-4 mb-2"
          style="width: 150px; z-index: 1; height: 155px;">
          <span class="badge badge-pill badge-danger" *ngIf="selectedMerchant?.application_rejected">R</span>
          <span class="badge badge-pill badge-success" *ngIf="!selectedMerchant?.application_rejected">A</span>
      </div>
    </div>
    <div class="p-4 text-black" style="background-color: #f8f9fa; padding-top: 2rem !important;">
      <div class="d-flex justify-content-between text-center py-1">
        <div class="ms-2" style="z-index: 5;">
          <h3 class="text-left text-dark h5 fw-600">{{ selectedMerchant?.business_name }}</h3>
          <p class="text-left h6 text-muted"> {{selectedMerchant?.business_address_address}} </p>
        </div>
        <div>
          <span class="badge badge-pill badge-primary mb-0" *ngIf="selectedMerchant?.store_status">Open</span>
          <span class="badge badge-pill badge-secondary mb-0" *ngIf="!selectedMerchant?.store_status">Close</span>
          <p class="small text-muted mb-0">Status</p>
        </div>
        <div class="">
          <p class="mb-1 h5" *ngIf="selectedMerchant?.store_rating_count">{{selectedMerchant?.store_rating_count}}</p>
          <p class="mb-1 h5" *ngIf="!selectedMerchant?.store_rating_count">0</p>
          <p class="small text-muted mb-0">Reviews</p>
        </div>
        <div style="text-align: center;
        align-items: center;
        display: flex;
        flex-direction: column;">
          <p class="mb-1 h5">{{selectedMerchant?.store_rating | slice:0:3}}
            <span class = "fa fa-star checked" *ngIf="selectedMerchant?.store_rating>5 || selectedMerchant?.store_rating==5"></span>
            <span class = "fa fa-star zero" *ngIf="selectedMerchant?.store_rating<0 || selectedMerchant?.store_rating==0"></span>
            <span *ngIf="!selectedMerchant?.store_rating">--</span>
          </p>
          <p class="small text-muted mb-0">Rating</p>
        </div>
      </div>
    </div>
    <div class="card-body p-4 text-black" style="padding-top: 0 !important;">
      <div class="mb-2">
        <p class="lead fw-normal mb-1 h5 text-muted">Owner Details</p>
        <div class="p-4" style="background-color: #f8f9fa;padding-top: 5px !important; display: flex; gap: 20px;">
          <div class="dets">
            <p class="mb-1 h6">Name:</p>
            <p class="mb-1 h6">Phone:</p>
            <p class="mb-1 h6">E-mail:</p>
            <p class="mb-1 h6">Birthday:</p>
            <p class="mb-1 h6">Gender:</p>
          </div>
          <div class="inf">
            <p class="mb-1 h6">{{selectedMerchant?.first_name + " " + selectedMerchant?.last_name}}</p>
            <p class="mb-1 h6">{{selectedMerchant?.doc.id}}</p>
            <p class="mb-1 h6">{{selectedMerchant?.email_address}}</p>
            <p class="mb-1 h6">{{selectedMerchant?.birth_date}}</p>
            <p class="mb-1 h6">{{selectedMerchant?.gender}}</p>
          </div>

        </div>
      </div>

      <div class="img_container mb-3">
        <p class="lead fw-normal mb-1 text-muted h5">Identifications</p>
        <img style="max-width:100%;cursor:pointer"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" [src]="selectedMerchant?.identification_front_url" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" [src]="selectedMerchant?.identification_back_url" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" [src]="selectedMerchant?.identification_selfie" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" [src]="selectedMerchant?.identification_selfie_with_id" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" [src]="selectedMerchant?.business_store_facade" alt="Snow">
      </div>

      <!-- <div class="img_container">
        <p class="lead fw-normal mb-1">Vehicle Details</p>
        <img style="max-width:100%;cursor:pointer"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" [src]="selectedMerchant?.vehicle_cr" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" [src]="selectedMerchant?.vehicle_or" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" [src]="selectedMerchant?.vehicle_photo" alt="Snow">
      </div> -->

    </div>
  </div>

  <div class="modal fade" id="myModal" role="dialog" style="backdrop-filter: blur(8px);">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text-white">Image Viewer</h4>
          <button type="button" #closebutton class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
        <img
        src="{{imgSrc}}"
        style="max-width:100%;cursor:pointer; max-height: 590px;"
        class="modal-hover-opacity"
      />
        </div>
      </div>
    </div>
    </div>
  <!-- <div class="container d-flex justify-content-center align-items-center mt-0">
    <div class="card">
     <div class="upper">
       <img [src]="selectedMerchant?.banner" class="img-fluid" style="width: 100%; height: 100%;" *ngIf="selectedMerchant.banner">
       <img src="../../../../../assets/vips/No BANNER.png" class="img-fluid" style="width: 100%; height: 100%;" *ngIf="!selectedMerchant.banner">
     </div>
     <div class="user text-center">
       <div class="profile">
         <img [src]="selectedMerchant?.business_store_logo" class="rounded-circle" width="80">
       </div>
     </div>
     <div class="mt-5 text-center">
       <h4 class="mb-0">{{selectedMerchant?.business_name}}</h4>
       <span class="text-muted d-block">{{selectedMerchant?.business_address_address}}</span>
       <span class="text-muted d-block mb-2 owner">{{ "Business Owner: " + selectedMerchant?.first_name + " " + selectedMerchant?.last_name}}</span>
       <span class="btn btn-success btn-sm follow" *ngIf="!selectedMerchant.application_rejected">Approved</span>
       <span class="btn btn-danger btn-sm follow" *ngIf="selectedMerchant.application_rejected">Rejected</span>
       <div class="card_0" *ngIf="selectedMerchant.remarks">
        <div class="align">
            <span class="red"></span>
            <h1 style="margin: 0;
            padding: 0;
            font-size: 12px;
            color: white;">Remarks</h1>
        </div>
        <p class="mt-4 text-left" style="font-size: 11px;">
          {{selectedMerchant?.remarks}}
        </p>
      </div>
       <div class="d-flex justify-content-between align-items-center mt-4 px-4">
         <div class="stats">
           <h6 class="mb-0">Food Type</h6>
           <span>{{selectedMerchant?.business_food_type}}</span>
         </div>
         <div class="stats">
           <h6 class="mb-0">Products</h6>
           <span>939</span>
         </div>
         <div class="stats">
           <h6 class="mb-0">Rating</h6>
           <span *ngIf="selectedMerchant.store_rating_count">{{selectedMerchant?.store_rating_count}}&nbsp;<i style="color: yellow;" class="fa fa-star" aria-hidden="true"></i></span>
           <span *ngIf="!selectedMerchant.store_rating_count">0&nbsp;<i style="color: yellow;" class="fa fa-star" aria-hidden="true"></i></span>
         </div>
       </div>
     </div>
    </div>
  </div>
  <div class="mb-2">
    <p class="lead fw-normal mb-1">About</p>
    <div class="p-4" style="background-color: #f8f9fa;padding-top: 5px !important;">
      <p class="mb-1">E-mail:&nbsp;{{selectedRider?.email_address}}</p>
      <p class="mb-1">Birthday:&nbsp;{{selectedRider?.birth_date}}</p>
      <p class="mb-1">Gender:&nbsp;{{selectedRider?.gender}}</p>
      <p class="mb-0">Plate Number:&nbsp;{{selectedRider?.vehicle_plate_no}}</p>
    </div>
  </div> -->
</ng-template>
