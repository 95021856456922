<app-loader *ngIf="processCreate"> </app-loader>

<div class="container-fluid">
  <div class="page-content page-container mt-3">
    <div class="shadow-lg mb-3 p-4 bg-white rounded">
      <div class="row">
        <div class="col">
          <h5 class="card-title text-uppercase font-weight-bold mb-0" style="color: #0e1b82">Activation Center</h5>
          <span class="h5 mb-0">Make sure all the information are correct</span>
        </div>
        <div class="col-auto">
          <div class="icon icon-shape text-dark rounded-circle shadow" style="background-color: #9dcb5f">
            <i class="fas fa-toggle-on"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="card p-3 bg-light">
      <div class="row pl-3 pr-3" *ngIf="migrationSalesList">
        <div class="col-sm-12 col-md-12 col-lg-12 p-2">
          <div class="shadow-lg mb-3 bg-white rounded">
            <div class="card-header">
              <strong style="color: #0e1b82"
                >Sales User ({{ migrationSalesList.length }} accounts for Activation)</strong
              >
              <button
                class="btn btn-primary btn-xs float-right mb-1"
                *ngIf="roleLevelId == 4 || roleLevelId == 2"
                (click)="createActivation()"
              >
                Create &nbsp; <i class="fa fa-save"></i>
              </button>
              <!-- <button class="btn btn-primary btn-xs float-right mb-1" (click)="openPaymentModal(paymentModal)">Open Payment &nbsp; <i class="fa fa-save"></i></button> -->
            </div>
            <div class="card-body pt-1">
              <table
                id="datatable"
                class="table table-striped table-hover"
                role="grid"
                aria-describedby="datatable_info"
              >
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Username</th>
                    <th>Created Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let migration of migrationSalesList; let i = index"
                    style="cursor: pointer"
                    class="odd"
                    role="row"
                    (click)="
                      roleLevelId == 1
                        ? openActivationAdmin(activationFormAdmin, migration)
                        : openActivationSales(migration.id)
                    "
                  >
                    <td>
                      {{ i + 1 }}
                    </td>
                    <td>
                      <strong> {{ migration.username }}</strong>
                    </td>
                    <td>
                      <strong> {{ migration.createdDate | date: "dd-MMM-yyyy hh:mm:ss a" }}</strong>
                    </td>
                    <td>
                      <span class="badge badge-success" *ngIf="migration.isActivated == 2">Activated</span>
                      <span class="badge badge-dark" *ngIf="migration.isActivated == 1">Enlisted</span>
                      <span class="badge badge-danger" *ngIf="migration.isActivated == 3">Rejected</span>
                      <span class="badge badge-warning" *ngIf="migration.isActivated == 4">Submitted</span>
                      <span class="badge badge-info" *ngIf="migration.isActivated == 5">Reserved</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #activationFormAdmin let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Activation Form</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <!--  -->

    <div id="rootwizard">
      <ul class="nav mb-3">
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 1 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >1</span
            >
            <div class="mt-2">
              <div class="text-muted">Sales Account</div>
            </div>
          </a>
        </li>

        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 2 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >2</span
            >
            <div class="mt-2">
              <div class="text-muted">Manager Account</div>
            </div>
          </a>
        </li>
      </ul>

      <hr class="bg-dark" />

      <div class="tab-content p-3">
        <div class="tab-pane" [ngClass]="activeTab == 1 ? 'active' : ''" id="selectBillerTab">
          <div class="row">
            <div class="col-12">
              <div class="shadow-lg mb-3 bg-white rounded">
                <div class="card-header">
                  <strong style="color: #0e1b82">Payment Information</strong>
                  <a class="btn btn-success text-white" *ngIf="activeTab == 1" (click)="confirmReject(rejectModal)">
                    &nbsp; Add Payment Request
                  </a>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="shadow-lg mb-3 bg-white rounded">
                <div class="card-header">
                  <strong style="color: #0e1b82">Account Information</strong>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-3">
                      <strong class="title-form">Username </strong>
                    </div>
                    <div class="col-9">
                      <small class="font-weight-bold body-form">{{ migrationDetail.Username }}</small>
                    </div>

                    <div class="col-3">
                      <strong class="title-form">Email Address </strong>
                    </div>
                    <div class="col-9">
                      <small class="font-weight-bold body-form">{{ migrationDetail.Email }}</small>
                    </div>

                    <div class="col-3">
                      <strong class="title-form">Role </strong>
                    </div>
                    <div class="col-9">
                      <small class="font-weight-bold body-form">{{ roleDetails }}</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="shadow-lg mb-3 bg-white rounded">
                <div class="card-header">
                  <strong style="color: #0e1b82">Personal Information</strong>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-3">
                      <strong class="title-form">First Name </strong>
                    </div>
                    <div class="col-9">
                      <small class="font-weight-bold body-form">{{ migrationDetail.FirstName }}</small>
                    </div>

                    <div class="col-3">
                      <strong class="title-form">Last Name </strong>
                    </div>
                    <div class="col-9">
                      <small class="font-weight-bold body-form">{{ migrationDetail.LastName }}</small>
                    </div>

                    <div class="col-3">
                      <strong class="title-form">Birthdate </strong>
                    </div>
                    <div class="col-9">
                      <small class="font-weight-bold body-form">{{
                        migrationDetail.BirthDate | date: "MMM-dd-yyyy"
                      }}</small>
                    </div>

                    <div class="col-3">
                      <strong class="title-form">Gender </strong>
                    </div>
                    <div class="col-9">
                      <small class="font-weight-bold body-form">{{ migrationDetail.Gender }}</small>
                    </div>

                    <div class="col-3">
                      <strong class="title-form">Contact No </strong>
                    </div>
                    <div class="col-9">
                      <small class="font-weight-bold body-form">{{ migrationDetail.ContactNumber }}</small>
                    </div>

                    <div class="col-3">
                      <strong class="title-form">Address </strong>
                    </div>
                    <div class="col-9">
                      <small class="font-weight-bold body-form">{{ migrationDetail.Address }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="shadow-lg mb-3 bg-white rounded">
                <div class="card-header">
                  <strong style="color: #0e1b82">Bank Account Details</strong>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-3">
                      <strong class="title-form">Bank Name </strong>
                    </div>
                    <div class="col-9">
                      <small class="font-weight-bold body-form">{{ migrationDetail.BankName }}</small>
                    </div>

                    <div class="col-3">
                      <strong class="title-form">Account Number </strong>
                    </div>
                    <div class="col-9">
                      <small class="font-weight-bold body-form">{{ migrationDetail.BankAccountNumber }}</small>
                    </div>

                    <div class="col-3">
                      <strong class="title-form">Account Name </strong>
                    </div>
                    <div class="col-9">
                      <small class="font-weight-bold body-form">{{ migrationDetail.BankAccountName }}</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="shadow-lg mb-3 bg-white rounded">
                <div class="card-header">
                  <strong style="color: #0e1b82">Account Details</strong>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-3">
                      <strong class="title-form">Sponsor </strong>
                    </div>
                    <div class="col-9">
                      <small class="font-weight-bold body-form">{{ sponsorUsername.username }}</small>
                    </div>

                    <div class="col-3">
                      <strong class="title-form">Upline </strong>
                    </div>
                    <div class="col-9">
                      <small class="font-weight-bold body-form">{{ uplineUsername.username }}</small>
                    </div>

                    <div class="col-3">
                      <strong class="title-form">Placement </strong>
                    </div>
                    <div class="col-9">
                      <small class="font-weight-bold body-form">{{ migrationDetail.Placement }}</small>
                    </div>

                    <div class="col-3">
                      <strong class="title-form">Registration Type </strong>
                    </div>
                    <div class="col-9">
                      <small class="font-weight-bold body-form">{{ migrationDetail.RegistrationType }}</small>
                    </div>

                    <!-- <div class="col-3">
                                      <strong class="title-form">Members Id </strong>
                                  </div>
                                  <div class="col-9">
                                      <small class="font-weight-bold body-form">{{ migrationDetail.MembersId }}</small>
                                  </div> -->

                    <div class="col-3">
                      <strong class="title-form">Proof of Payment </strong>
                    </div>
                    <div class="col-9">
                      <div class="flex">
                        <div class="item-except text-muted text-sm h-1x">
                          <label
                            *ngIf="migrationDetail.Pop"
                            (click)="preview(viewImageModal, migrationDetail.Pop)"
                            style="color: blue; cursor: pointer"
                          >
                            <span class="fa fa-eye">&nbsp;</span> [ &nbsp; View File &nbsp; ]
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <button style="color: #0e1b82;" class="btn btn-sm shadow btn-light float-right">Activate &nbsp; <i class="fa fa-toggle-on"></i></button> -->
            </div>
          </div>
        </div>

        <div class="tab-pane" [ngClass]="activeTab == 2 ? 'active' : ''" id="confirmationTab">
          <div class="card" [formGroup]="managerUserFormGroup">
            <div class="card-header">
              <strong>Account Information</strong>
            </div>
            <div class="card-body">
              <div class="row row-sm">
                <div class="col-sm-4">
                  <div class="md-form-group">
                    <input class="md-input input-readonly" readonly formControlName="username" />
                    <label>Username</label>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="md-form-group">
                    <input class="md-input input-readonly" readonly formControlName="email" />
                    <label>Email Address</label>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="md-form-group">
                    <input class="md-input input-readonly" readonly formControlName="roleId" />
                    <label>Role</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-header">
              <strong>Personal Information</strong>
            </div>
            <div class="card-body">
              <div class="row row-sm">
                <div class="col-sm-4">
                  <div class="md-form-group float-label">
                    <input class="md-input input-readonly" readonly formControlName="firstName" />
                    <label>First Name</label>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="md-form-group float-label">
                    <input class="md-input input-readonly" readonly formControlName="lastName" />
                    <label>Last Name</label>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="md-form-group float-label">
                    <input class="md-input input-readonly" readonly formControlName="middleName" />
                    <label>Middle Name/Initial</label>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="md-form-group float-label">
                    <input class="md-input input-readonly" readonly formControlName="address" />
                    <label>Address</label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="md-form-group">
                    <input class="md-input input-readonly" readonly formControlName="contactNumber" />
                    <label>Contact Number</label>
                    <span class="md-input-msg right"></span>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="md-form-group float-label">
                    <input
                      class="md-input"
                      value=""
                      onkeyup="this.setAttribute('value', this.value);"
                      formControlName="latitude"
                    />
                    <label>Latitude</label>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="md-form-group float-label">
                    <input
                      class="md-input"
                      value=""
                      onkeyup="this.setAttribute('value', this.value);"
                      formControlName="longitude"
                    />
                    <label>Longitude</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-header">
              <strong>Store Settings and Activation Details</strong>
            </div>
            <div class="card-body">
              <div class="row row-sm">
                <div class="col-sm-4">
                  <div class="md-form-group float-label">
                    <input
                      class="md-input"
                      value=""
                      onkeyup="this.setAttribute('value', this.value);"
                      required=""
                      formControlName="name"
                      [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
                    />
                    <label><span class="text-danger font-weight-bold">*</span> &nbsp; Store Name</label>
                  </div>
                  <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors">Store Name is required</div>
                  </div>
                </div>
                <!-- <div class="col-sm-4">
                      <div class="md-form-group">
                          <input bsDatepicker class="md-input" value="" onkeyup="this.setAttribute('value', this.value);" required=""
                                formControlName="mainActivationDate" autocomplete="off" container="app-root" 
                                [bsConfig]="dpConfig" readonly placeholder="mm/dd/yyyy" [ngClass]="{ 'is-invalid': submitted && f.mainActivationDate.errors }">
                          <label>Activation Date</label>
                      </div>
                  </div>
                  <div class="col-sm-4">
                      <div class="md-form-group">
                          <input bsDatepicker class="md-input" value="" onkeyup="this.setAttribute('value', this.value);" required=""
                                formControlName="enterpriseActivationDate" autocomplete="off" container="app-root" 
                                [bsConfig]="dpConfig" readonly placeholder="mm/dd/yyyy" [ngClass]="{ 'is-invalid': submitted && f.enterpriseActivationDate.errors }">
                          <label>Enterprise Activation Date</label>
                      </div>
                  </div> -->
                <div class="col-sm-4">
                  <div class="md-form-group">
                    <input class="md-input input-readonly" readonly formControlName="sponsor" />
                    <label>Sponsor</label>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="md-form-group">
                    <input class="md-input input-readonly" readonly formControlName="upline" />
                    <label>Upline</label>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="md-form-group">
                    <input class="md-input input-readonly" readonly formControlName="placement" />
                    <label>Placement</label>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="md-form-group">
                    <input class="md-input input-readonly" readonly formControlName="accountType" />
                    <label>Account Type</label>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="md-form-group float-label">
                    <select
                      class="md-input"
                      value=""
                      onkeyup="this.setAttribute('value', this.value);"
                      required=""
                      formControlName="storeType"
                      [ngClass]="{ 'is-invalid': submitted && f.storeType.errors }"
                    >
                      <option>Add Ons</option>
                      <option>Home Based</option>
                      <option>Stand Alone</option>
                    </select>
                    <label> <span class="text-danger font-weight-bold">*</span> &nbsp; Store Type</label>
                  </div>
                  <div *ngIf="submitted && f.storeType.errors" class="invalid-feedback">
                    <div *ngIf="f.storeType.errors">Store Type is required</div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="md-form-group float-label">
                    <select
                      class="md-input"
                      [value]=""
                      (keyup)="this.setAttribute('value', this.value)"
                      required=""
                      formControlName="msfPromo"
                      [ngClass]="{ 'is-invalid': submitted && f.msfPromo.errors }"
                    >
                      <option value="" disabled selected>MSF Code</option>
                      <option *ngFor="let promo of msfPromos" [value]="promo.id">
                        {{ promo.promoCode }} - {{ promo.msfCode }}
                      </option>
                    </select>
                    <label> <span class="text-danger font-weight-bold">*</span> &nbsp; MSF Code </label>
                  </div>
                  <div *ngIf="submitted && f.msfPromo.errors" class="invalid-feedback">
                    <div *ngIf="f.msfPromo.errors">MSF Code is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-header">
              <strong>Store Address</strong>
            </div>
            <div class="card-body">
              <div class="row row-sm">
                <div class="col-sm-4">
                  <div class="md-form-group float-label">
                    <input class="md-input" formControlName="number" maxlength="49" />
                    <label>House Number</label>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="md-form-group float-label">
                    <input class="md-input" formControlName="street" />
                    <label>Street</label>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="md-form-group float-label">
                    <input class="md-input" formControlName="subdivision" />
                    <label>Subdivision</label>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="md-form-group float-label">
                    <input
                      class="md-input"
                      value=""
                      onkeyup="this.setAttribute('value', this.value);"
                      required=""
                      formControlName="barangay"
                      [ngClass]="{ 'is-invalid': submitted && f.barangay.errors }"
                    />
                    <label><span class="text-danger font-weight-bold">*</span> &nbsp; Barangay</label>
                  </div>
                  <div *ngIf="submitted && f.barangay.errors" class="invalid-feedback">
                    <div *ngIf="f.barangay.errors">Barangay is required</div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="md-form-group float-label">
                    <input class="md-input" formControlName="municipality" />
                    <label>Municipality</label>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="md-form-group float-label">
                    <input class="md-input" formControlName="city" />
                    <label>City</label>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="md-form-group float-label">
                    <input class="md-input" formControlName="region" />
                    <label>Region</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-header">
              <strong>Bank for ATM Withdrawal Settlement</strong>
            </div>
            <div class="card-body">
              <div class="row row-sm">
                <div class="col-sm-4">
                  <div class="md-form-group float-label">
                    <input
                      class="md-input"
                      value=""
                      onkeyup="this.setAttribute('value', this.value);"
                      required=""
                      formControlName="bankName"
                      [ngClass]="{ 'is-invalid': submitted && f.bankName.errors }"
                    />
                    <label><span class="text-danger font-weight-bold">*</span> &nbsp; Bank Name</label>
                  </div>
                  <div *ngIf="submitted && f.bankName.errors" class="invalid-feedback">
                    <div *ngIf="f.bankName.errors">Bank Name is required</div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="md-form-group float-label">
                    <input
                      class="md-input"
                      value=""
                      onkeyup="this.setAttribute('value', this.value);"
                      required=""
                      formControlName="bankAccountNumber"
                      [ngClass]="{ 'is-invalid': submitted && f.bankAccountNumber.errors }"
                    />
                    <label><span class="text-danger font-weight-bold">*</span> &nbsp; Bank Account Number</label>
                  </div>
                  <div *ngIf="submitted && f.bankAccountNumber.errors" class="invalid-feedback">
                    <div *ngIf="f.bankAccountNumber.errors">Bank Account Number is required</div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="md-form-group float-label">
                    <input
                      class="md-input"
                      value=""
                      onkeyup="this.setAttribute('value', this.value);"
                      required=""
                      formControlName="bankAccountName"
                      [ngClass]="{ 'is-invalid': submitted && f.bankAccountName.errors }"
                    />
                    <label><span class="text-danger font-weight-bold">*</span> &nbsp; Bank Account Name</label>
                  </div>
                  <div *ngIf="submitted && f.bankAccountName.errors" class="invalid-feedback">
                    <div *ngIf="f.bankAccountName.errors">Bank Account Name is required</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-header">
              <strong>Requirements</strong>
            </div>
            <div class="card-body">
              <div class="row row-sm">
                <div class="col-sm-4">
                  <div class="md-form-group float-label">
                    <input
                      class="md-input"
                      value=""
                      onkeyup="this.setAttribute('value', this.value);"
                      required=""
                      formControlName="dtiRegistrationName"
                      [ngClass]="{ 'is-invalid': submitted && f.dtiRegistrationName.errors }"
                    />
                    <label><span class="text-danger font-weight-bold">*</span> &nbsp; DTI Registration Name</label>
                  </div>
                  <div *ngIf="submitted && f.dtiRegistrationName.errors" class="invalid-feedback">
                    <div *ngIf="f.dtiRegistrationName.errors">DTI Registration Name is required</div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="md-form-group">
                    <input
                      bsDatepicker
                      class="md-input"
                      value=""
                      onkeyup="this.setAttribute('value', this.value);"
                      required=""
                      formControlName="dtiRegistrationDate"
                      autocomplete="off"
                      container="app-root"
                      [bsConfig]="dpConfig"
                      readonly
                      placeholder="mm/dd/yyyy"
                      [ngClass]="{ 'is-invalid': submitted && f.dtiRegistrationDate.errors }"
                    />

                    <label><span class="text-danger font-weight-bold">*</span> &nbsp; DTI Registration Date</label>
                  </div>
                  <div *ngIf="submitted && f.dtiRegistrationDate.errors" class="invalid-feedback">
                    <div *ngIf="f.dtiRegistrationDate.errors">DTI Registration Date is required</div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="md-form-group float-label">
                    <select
                      class="md-input"
                      value=""
                      onkeyup="this.setAttribute('value', this.value);"
                      required=""
                      formControlName="atmWithdrawalMachine"
                      [ngClass]="{ 'is-invalid': submitted && f.atmWithdrawalMachine.errors }"
                    >
                      <option>Nationlink</option>
                      <option>EasyDebit</option>
                      <option>Encash</option>
                      <option>Others</option>
                      <option>Not Applicable</option>
                    </select>
                    <label
                      ><span class="text-danger font-weight-bold">*</span> &nbsp; Current ATM Withdrawal Machine</label
                    >
                  </div>
                  <div *ngIf="submitted && f.atmWithdrawalMachine.errors" class="invalid-feedback">
                    <div *ngIf="f.atmWithdrawalMachine.errors">ATM Withdrawal Machine is required</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card" [formGroup]="fileFormGroup">
            <div class="card-header">
              <strong>File Manager</strong>
            </div>
            <div class="card-body">
              <div class="row row-sm">
                <div class="col-sm-4">
                  <div class="list list-row block">
                    <div class="list-item" data-id="20">
                      <div class="pointer" (click)="triggerFile(dtiForm)">
                        <span class="w-40 avatar gd-info">
                          <i class="fa fa-upload" aria-hidden="true"></i>
                          <input
                            #dtiForm
                            type="file"
                            (change)="onUploadFile($event, 'dtiForm')"
                            style="display: none"
                            accept="image/jpeg,image/png,application/pdf"
                          />
                        </span>
                      </div>
                      <div class="flex">
                        <a class="item-author text-color"
                          ><span class="text-danger font-weight-bold">*</span> &nbsp; DTI Registration</a
                        >
                        <div
                          class="item-except text-muted text-sm h-1x"
                          [ngClass]="{ 'is-invalid': submitted && fi.dtiFormId.errors }"
                        >
                          <label
                            *ngIf="
                              !managerUserFormGroup.controls.dtiFormId.value && !fileFormGroup.controls.dtiForm.value
                            "
                            >Click the button to upload File...</label
                          >
                          <label
                            *ngIf="
                              managerUserFormGroup.controls.dtiFormId.value && !fileFormGroup.controls.dtiForm.value
                            "
                            (click)="preview(viewImageModal, managerUserFormGroup.controls.dtiFormId.value)"
                            style="color: blue; cursor: pointer"
                          >
                            <span class="fa fa-eye">&nbsp;</span>View File
                          </label>
                          <label *ngIf="fileFormGroup.controls.dtiForm.value" style="color: blue; cursor: pointer">
                            <span class="fa fa-tag">&nbsp;</span>Save to preview file
                          </label>
                        </div>
                        <div *ngIf="submitted && fi.dtiFormId.errors" class="invalid-feedback">
                          <div *ngIf="fi.dtiFormId.errors">DTI Form is required</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="list list-row block">
                    <div class="list-item" data-id="20">
                      <div class="pointer" (click)="triggerFile(validID1)">
                        <span class="w-40 avatar gd-info">
                          <i class="fa fa-upload" aria-hidden="true"></i>
                          <input
                            #validID1
                            type="file"
                            (change)="onUploadFile($event, 'validID1')"
                            style="display: none"
                            accept="image/jpeg,image/png,application/pdf"
                          />
                        </span>
                      </div>
                      <div class="flex">
                        <a class="item-author text-color"
                          ><span class="text-danger font-weight-bold">*</span> &nbsp; [1] Valid ID</a
                        >
                        <div
                          class="item-except text-muted text-sm h-1x"
                          [ngClass]="{ 'is-invalid': submitted && fi.validID1Id.errors }"
                        >
                          <label
                            *ngIf="
                              !managerUserFormGroup.controls.validID1Id.value && !fileFormGroup.controls.validID1.value
                            "
                            >Click the button to upload File...</label
                          >
                          <label
                            *ngIf="
                              managerUserFormGroup.controls.validID1Id.value && !fileFormGroup.controls.validID1.value
                            "
                            (click)="preview(viewImageModal, managerUserFormGroup.controls.validID1Id.value)"
                            style="color: blue; cursor: pointer"
                          >
                            <span class="fa fa-eye">&nbsp;</span>View File
                          </label>
                          <label *ngIf="fileFormGroup.controls.validID1.value" style="color: blue; cursor: pointer">
                            <span class="fa fa-tag">&nbsp;</span>Save to preview file
                          </label>
                        </div>
                        <div *ngIf="submitted && fi.validID1Id.errors" class="invalid-feedback">
                          <div *ngIf="fi.validID1Id.errors">Valid ID is required</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="list list-row block">
                    <div class="list-item" data-id="20">
                      <div class="pointer" (click)="triggerFile(validID2)">
                        <span class="w-40 avatar gd-info">
                          <i class="fa fa-upload" aria-hidden="true"></i>
                          <input
                            #validID2
                            type="file"
                            (change)="onUploadFile($event, 'validID2')"
                            style="display: none"
                            accept="image/jpeg,image/png,application/pdf"
                          />
                        </span>
                      </div>
                      <div class="flex">
                        <a class="item-author text-color">
                          <span class="text-danger font-weight-bold">*</span> &nbsp; [2] Valid ID</a
                        >
                        <div
                          class="item-except text-muted text-sm h-1x"
                          [ngClass]="{ 'is-invalid': submitted && fi.validID2Id.errors }"
                        >
                          <label
                            *ngIf="
                              !managerUserFormGroup.controls.validID2Id.value && !fileFormGroup.controls.validID2.value
                            "
                            >Click the button to upload File...</label
                          >
                          <label
                            *ngIf="
                              managerUserFormGroup.controls.validID2Id.value && !fileFormGroup.controls.validID2.value
                            "
                            (click)="preview(viewImageModal, managerUserFormGroup.controls.validID2Id.value)"
                            style="color: blue; cursor: pointer"
                          >
                            <span class="fa fa-eye">&nbsp;</span>View File
                          </label>
                          <label *ngIf="fileFormGroup.controls.validID2.value" style="color: blue; cursor: pointer">
                            <span class="fa fa-tag">&nbsp;</span>Save to preview file
                          </label>
                        </div>
                        <div *ngIf="submitted && fi.validID2Id.errors" class="invalid-feedback">
                          <div *ngIf="fi.validID2Id.errors">Valid ID 2 is required</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- previous next -->
        <div class="row py-3">
          <div class="col-6">
            <a class="btn btn-white button-previous" *ngIf="activeTab != 1" (click)="setActiveTabPrev()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-arrow-left"
              >
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
              &nbsp; Previous
            </a>
            <a class="btn btn-danger text-white" *ngIf="activeTab == 1" (click)="confirmReject(rejectModal)">
              &nbsp; Reject
            </a>
          </div>
          <div class="col-6">
            <div class="d-flex justify-content-end">
              <a
                *ngIf="activeTab != 2 && migrationDetail.RoleId != 18"
                class="btn btn-white button-next"
                (click)="setActiveTab()"
              >
                Next &nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-arrow-right"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </a>
              <a
                *ngIf="activeTab == 1 && migrationDetail.RoleId == 18"
                class="btn btn-info button-next"
                (click)="processAgentActivation()"
                style="color: white"
              >
                Process Activation &nbsp;
                <span class="fa fa-hand-pointer"></span>
              </a>
              <a
                *ngIf="activeTab == 2"
                class="btn btn-info button-next"
                (click)="processActivation()"
                style="color: white"
              >
                Process Activation &nbsp;
                <span class="fa fa-hand-pointer"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #viewImageModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">File Viewer</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <ngx-extended-pdf-viewer
        [base64Src]="imagePreview"
        *ngIf="fileType == 'PDF'"
        backgroundColor="#ffffff"
        height="100%"
        [useBrowserLocale]="true"
        [handTool]="false"
        [showHandToolButton]="true"
      >
      </ngx-extended-pdf-viewer>
      <img
        *ngIf="fileType == 'IMAGE'"
        [src]="imagePreview"
        onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
        style="width: 100%"
      />
    </div>
  </div>
</ng-template>

<div id="rejectModal" class="modal fade" data-backdrop="true">
  <div class="modal-dialog animate">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title text-md">Rejecting of User</div>
        <button class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="p-4 text-center">
          <p>Are you sure you want to reject sales activation?</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
        <button type="button" (click)="rejectActivation()" class="btn btn-primary" data-dismiss="modal">Proceed</button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
</div>

<ng-template #paymentModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Payment</h4>
  </div>
  <div class="modal-body">
    <app-payment-form></app-payment-form>
  </div>
</ng-template>
