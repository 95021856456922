import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/data/services/web/user.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { CurrencyPipe } from '@angular/common';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5
@Component({
  selector: 'app-sales-activation-payment',
  templateUrl: './sales-activation-payment.component.html',
  styleUrls: ['./sales-activation-payment.component.less']
})
export class SalesActivationPaymentComponent implements OnInit {

  selectedPaymentMethod: string = 'onlineBanking'; // Default to Online Banking
  selectedBankCode: string = '';

  receivedData: string;
  migrationDetail: any;
  public activeTab = 1;
  paymentTransaction: NgbModalRef;
  form: FormGroup;
  banks:any;
  constructor(
    private route: ActivatedRoute,
    private usersService: UserService,
    private modService: NgbModal,
    private currencyPipe: CurrencyPipe,
    private formBuilder: FormBuilder) { 
      this.form = this.formBuilder.group({
        fname: ['', Validators.required],
        lname: ['', Validators.required],
        mname: [''],
        email: ['', [Validators.required, Validators.email]],
        mobile: ['', [Validators.required, Validators.pattern(/^\9\d{9}$/)]],
        address_line1: ['', Validators.required],
        address_line2: [''],
        address_city: ['', Validators.required],
        address_province: ['', Validators.required],
        address_zip_code: ['', Validators.required],
        address_country: ['', Validators.required],
        bank_code: ['', Validators.required],
        amount: ['', [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/), this.validateAmountPositive]]
      });

    }

    payments: any = [
      { 
        id: 1,
        bankCode: "BPI_PERSONAL",
        createdDate: "2023-11-23",
        amount: 1000.00,
        merchantReferenceId: "GBO026L47LKU1O"
      },
      { 
        id: 2,
        bankCode: "BPI_PERSONAL",
        createdDate: "2023-11-23",
        amount: 100.00,
        merchantReferenceId: "GBO02V3IV967JT"
      }
    ];

     // Custom validator to check if the amount is greater than zero
  validateAmountPositive(control: AbstractControl): {[key: string]: any} | null {
    const value = parseFloat(control.value);
    return value > 0 ? null : { 'amountPositive': { value: control.value } };
  }
  

  ngOnInit(): void {
    debugger
    this.receivedData = this.route.snapshot.paramMap.get('data');
    this.initSalesUserActivation(this.receivedData);
  }

  initSalesUserActivation(id: any) {
    this.usersService.getSalesUserActivation(this.receivedData).subscribe((result) => {
      this.migrationDetail = JSON.parse(result.data.meta);
    });

    this.usersService.getBankCheckOutOption().subscribe((result) => {
      this.banks = result.data;
     console.log(result.data);
    });

   
    
  }

  async openPaymentModal(paymentModal) {
    this.paymentTransaction = this.modService.open(paymentModal, {
      centered: false,
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });
  }


  selectPaymentMethod(method: string) {
    this.selectedPaymentMethod = method;
  }
  
  selectBank(bankCode: string) {
    this.selectedBankCode = bankCode;
    this.form.controls['bank_code'].setValue(bankCode);
  }

  private baseUrl = environment.ROOT_URL;

  onSubmit() {
    if (this.form.valid) {
      const formData = this.form.value;

      const transformedData = {
        model: {
          customer: {
            fname: formData.fname,
            lname: formData.lname,
            mname: formData.mname,
            email: formData.email,
            mobile: "+63" + formData.mobile,
            address: {
              line1: formData.address_line1,
              line2: formData.address_line2,
              city: formData.address_city,
              province: formData.address_province,
              zip_code: formData.address_zip_code,
              country: formData.address_country
            }
          },
          amount: {
            num: this.transformAmount(formData.amount).toString()

          },
          client: {

            return_url : this.baseUrl +"/" +this.receivedData ,
            fail_url : this.baseUrl +"/" +this.receivedData
          },
          from: {
            bank_code: formData.bank_code
          }
        },
        id: this.receivedData // Assuming 'id' is set elsewhere or is not required for submission
      };

      this.usersService.postGenerateCheckout(transformedData).subscribe((result) => {
       window.location.href = result.data.redirect_uri;
      });
  // Or send this data to your backend
    } else {
      PNotify.error({
        title: "Action Denied",
        text: "Please fill all required fields",
      });
    }
  }

   transformAmount(amount: string): number {
    // Convert the amount to a decimal number and fix to 2 decimal places
    const decimalAmount = parseFloat(amount).toFixed(2);
  
    // Multiply the decimal number by 100 and round it to remove any fractional part
    return Math.round(parseFloat(decimalAmount) * 100);
  }
  
  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }
  
}
