import { Message } from "@angular/compiler/src/i18n/i18n_ast";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { AuthService } from "src/data/services/authentication/auth.service";
import { ManagerUsersService } from "src/data/services/web/manager-users.service";
import { RolesService } from "src/data/services/web/roles.service";
import { environment } from "src/environments/environment";
import { ModalConfirmationComponent } from "../modal-confirmation/modal-confirmation.component";

// PNotify
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { WebSettingsService } from "src/data/services/web/web-settings.service";
import { Select2OptionData } from "ng-select2";
import { DATE } from "ngx-bootstrap/chronos/units/constants";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: "app-manager-user-create",
  templateUrl: "./manager-user-create.component.html",
  styleUrls: ["./manager-user-create.component.less"],
})
export class ManagerUserCreateComponent implements OnInit {
  public storeActivationFormGroup: FormGroup;
  public isStoreActivationCreated: boolean;
  public user: any;

  public storeSettingFormGroup: FormGroup;
  public storeAddressFormGroup: FormGroup;

  public managerUserFormGroup: FormGroup;
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public managerUsersService: ManagerUsersService;
  public emptyString = "";
  public roles: any;
  public bsModalRefConfirmation: BsModalRef;
  public modalService: BsModalService;
  public websettings: WebSettingsService;

  public accessToken: string;

  public base64Photo: string | ArrayBuffer;
  public base64Photo1: string | ArrayBuffer;
  public base64Photo2: string | ArrayBuffer;
  public base64Photo3: string | ArrayBuffer;

  public roleLevelId: any;
  public roleId: any;

  public maxCashiersArray: any;
  public storeTypeOption: any;

  processCreate = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    managerUsersService: ManagerUsersService,
    websettings: WebSettingsService,
    modalService: BsModalService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.managerUsersService = managerUsersService;
    this.websettings = websettings;
    this.modalService = modalService;
    this.accessToken = this.localStorageCacheService.getStorage("access-token");

    PNotifyButtons; // Initiate PNotify buttons. Important!
  }

  ngOnInit(): void {
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.roleId = this.localStorageCacheService.getStorage("roleId");

    this.initManagerUserFormGroup();
    // this.initStoreActivationFormGroup();
    // this.initStoreSettingFormGroup();
    // this.initStoreAddressFormGroup();
    this.setMaxCashiers();
    //this.getProviders();
    this.initRoles();
  }

  initManagerUserFormGroup(): void {
    this.managerUserFormGroup = new FormGroup({
      username: new FormControl(this.emptyString),
      email: new FormControl(this.emptyString),
      firstName: new FormControl(this.emptyString),
      lastName: new FormControl(this.emptyString),
      address: new FormControl(this.emptyString),
      contactNumber: new FormControl(this.emptyString),
      //birthDate: new FormControl(this.emptyString),
      roleId: new FormControl(this.emptyString),
      employeeId: new FormControl(this.emptyString),
      // emergencyContactName: new FormControl(this.emptyString),
      // emergencyContactNumber: new FormControl(this.emptyString),
      // emergencyContactRelationship: new FormControl(this.emptyString),
      photo: new FormControl(this.emptyString),
      status: new FormControl(true),
      redirect: new FormControl(environment.EMAIL_VERIFICATION_REDIRECT),
      dtiForm: new FormControl(this.emptyString),
      applicationForm: new FormControl(this.emptyString),
      validID1: new FormControl(this.emptyString),
      validID2: new FormControl(this.emptyString),

      mainActivationDate: new FormControl("05/05/2022"),
      enterpriseActivationDate: new FormControl("05/05/2022"),
      sponsor: new FormControl("TO FOLLOW"),
      upline: new FormControl("TO FOLLOW"),
      placement: new FormControl("TO FOLLOW"),
      accountType: new FormControl("ENTERPRISE"),
      storeType: new FormControl(this.emptyString),

      name: new FormControl(this.emptyString),
      maxCashiers: new FormControl(this.emptyString),
      accountRemarks: new FormControl(this.emptyString),
      maxBillsAmountPerDay: new FormControl(this.emptyString),
      maxRemittanceAmountPerDay: new FormControl(this.emptyString),
      maxEpinsAmountPerDay: new FormControl(this.emptyString),
      maxEloadAmountPerDay: new FormControl(this.emptyString),

      number: new FormControl(this.emptyString),
      street: new FormControl(this.emptyString),
      subdivision: new FormControl(this.emptyString),
      barangay: new FormControl(this.emptyString),
      city: new FormControl(this.emptyString),
      municipality: new FormControl(this.emptyString),
      region: new FormControl(this.emptyString),
    });

    // this.managerUserFormGroup.valueChanges.subscribe((value) => {
    // });
  }

  initRoles(): any {
    this.rolesService.getRoles(2).then((roles) => {
      this.roles = roles;
    });
  } 

  setMaxCashiers() {
    this.maxCashiersArray = [
      { id: 1, name: "1" },
      { id: 2, name: "2" },
      { id: 3, name: "3" },
      { id: 4, name: "4" },
      { id: 5, name: "5" },
      { id: 6, name: "6" },
      { id: 7, name: "7" },
      { id: 8, name: "8" },
      { id: 9, name: "9" },
      { id: 10, name: "10" },
    ];

   
  }

  actionEventCreateManagerUser(): void {
    this.processCreate = true;

    

    this.managerUsersService.createManagerUser(this.managerUserFormGroup.value).subscribe(
      (result) => {
        const encId = this.cryptoService.set(this.data.authentication.privateKey, result.data.id);
        const encNewData = this.cryptoService.set(this.data.authentication.privateKey, "true");
        this.router.navigate(["acm", "manager-user-view", { id: encId, status: encNewData }]);

        PNotify.success({
          title: "Success",
          text: "The account has been stored.",
        });
      },
      (error) => {

        if (error.status === 400) {

          const obj = error.error.errors;
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              let str: string = key;
              str = str[0].toLowerCase() + str.slice(1);
              this.managerUserFormGroup.controls[str].setErrors({
                required: Validators.required,
                apiMessage: obj[key],
              });
            }
          }
          this.processCreate = false;

          PNotify.notice({
            title: "Warning",
            text: "Could not store account with errors.",
          });
        } else {
          // Other error
          this.processCreate = false;

          PNotify.error({
            title: "Error",
            text: "Something went wrong.",
          });
        }
      }
    );
  }

  checkMemberDetails() {
    this.managerUsersService.getManagerVIPDetails(this.managerUserFormGroup.controls.username.value).subscribe(
      (result) => {
        if (result.Username) {
          this.managerUserFormGroup.controls.username.setValue(result.Username);
          this.managerUserFormGroup.controls.email.setValue(result.EmailAddress);
          this.managerUserFormGroup.controls.contactNumber.setValue(result.MobileNo1);
          this.managerUserFormGroup.controls.address.setValue(result.Address1);
          this.managerUserFormGroup.controls.firstName.setValue(result.FirstName);
          this.managerUserFormGroup.controls.lastName.setValue(result.LastName);

          this.managerUserFormGroup.controls.mainActivationDate.setValue(result.Created_Dt);
          this.managerUserFormGroup.controls.enterpriseActivationDate.setValue(result.Created_Dt);
          this.managerUserFormGroup.controls.sponsor.setValue(result.Sponsored_Id);
          this.managerUserFormGroup.controls.upline.setValue(result.Upline_Id);
          this.managerUserFormGroup.controls.placement.setValue(result.Placement);
          this.managerUserFormGroup.controls.accountType.setValue(result.Reg_Type);
          //this.managerUserFormGroup.controls.storeType.setValue(result.LastName);
        } else {
          PNotify.error({
            title: "Connecting to VIP",
            text: "username does not exist.",
          });
        }
      },
      (error) => {
        PNotify.error({
          title: "Connecting to VIP",
          text: "username does not exist.",
        });
      }
    );
  }

  public onPhotoChange(event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.base64Photo = reader.result;

        this.managerUserFormGroup.patchValue({
          photo: reader.result,
        });

        this.changeDetectorRef.markForCheck();
      };
    }
  }

  public fileName: any = "Click here to choose DTI...";
  public appfileName: any = "Click here to choose Application Form...";
  public valid1FileName: any = "Click here to choose Valid ID...";
  public valid2FileName: any = "Click here to choose Valid ID...";

  public onDTIUpload(event: any) {
    const reader = new FileReader();
   
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.fileName = file.name;
      reader.onload = () => {
        this.base64Photo = reader.result;

        this.managerUserFormGroup.patchValue({
          dtiForm: reader.result,
        });

        this.changeDetectorRef.markForCheck();
      };
    }
  }

  public onApplicationFormUpload(event: any) {
    const reader = new FileReader();
  
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.appfileName = file.name;
      reader.onload = () => {
        this.base64Photo1 = reader.result;

        this.managerUserFormGroup.patchValue({
          applicationForm: reader.result,
        });

        this.changeDetectorRef.markForCheck();
      };
    }
  }

  public onValidID1Upload(event: any) {
    const reader = new FileReader();
   
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.valid1FileName = file.name;
      reader.onload = () => {
        this.base64Photo2 = reader.result;

        this.managerUserFormGroup.patchValue({
          validID1: reader.result,
        });

        this.changeDetectorRef.markForCheck();
      };
    }
  }

  public onValidID2Upload(event: any) {
    const reader = new FileReader();
   
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.valid2FileName = file.name;
      reader.onload = () => {
        this.base64Photo3 = reader.result;

        this.managerUserFormGroup.patchValue({
          validID2: reader.result,
        });

        this.changeDetectorRef.markForCheck();
      };
    }
  }

  backToManagerUsersList(): void {
    this.router.navigate(["acm", "manager-user-list"]);
  }
}
