<app-loader *ngIf="!storeItems"></app-loader>
<!-- <app-loader *ngIf="processUpdate"></app-loader> -->

<div id="content" class="flex" *ngIf="storeItems">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">List of Store Item</h2>
          <small class="text-highlight font-weight-normal font-italic">Go VIP Center list of Store Item</small>
        </div>
      </div>
    </div>
    <div class="page-content page-container" id="page-content">

      <div class="card mt-2">
        <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
          <thead>
            <tr>
           
              <th data-field="itemCode">
                <div>Item Code</div>
              </th>
              <th data-field="itemName">
                <div>Item Name</div>
              </th>
              <th data-field="itemDescription">
                <div>Item Description</div>
              </th>
              <th data-field="price">
                <div>Price</div>
              </th>
              <th data-field="status"><span class="d-none d-sm-block">Status</span></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let item of storeItems?.data | paginate: { itemsPerPage: 10, currentPage: p, totalItems: storeItems.totalRecords }"
              style="cursor: pointer"
              class="odd"
              role="row"
              (click)="viewItem(item.id)"
            >
              <td class="flex">
                <div class="item-except text-sm h-1x">
                  {{ item?.itemCode }}
                </div>
              </td>

              <td class="flex">
                <div class="item-except text-sm h-1x">
                  {{ item?.name }}
                </div>
              </td>

              <td class="flex">
                <div class="item-except text-sm h-1x">
                  {{ item?.description }}
                </div>
              </td>

              <td class="flex">
                <div class="item-except text-sm h-1x">
                  {{ item?.price }}
                </div>
              </td>
              <td class="flex" style="max-width: 75px">
                <div class="item-except text-sm h-1x">
                  <label *ngIf="item?.active == true" class="ui-switch ui-switch-sm dark mt-1 mr-2">
                    <input type="checkbox" checked disabled />
                    <i></i>
                  </label>

                  <label *ngIf="item?.active != true" class="ui-switch ui-switch-sm dark mt-1 mr-2">
                    <input type="checkbox" disabled />
                    <i></i>
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row">
        <div class="col-sm-12 col-md-5">
          <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
            <div class="col-sm-12 col-md-12">
              <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                Showing {{ (storeItems?.pageNumber - 1) * storeItems?.pageSize + 1 }} to
                {{ (storeItems?.pageNumber - 1) * storeItems?.pageSize + storeItems?.data?.length }} of
                {{ storeItems?.data?.length }} entries
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-7" style="float: right">
          <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
            <ul class="pagination" style="float: right">
              <pagination-controls (pageChange)="getStoreItem(buildPageUrl($event, 10))"></pagination-controls>
            </ul>
          </div>
        </div>
      </div>


    </div>
  </div>
  <!-- ############ Main END-->
</div>

<ng-template #viewMultisys let-modal>
  <div class="modal-header">
    <h4 class="modal-title">[Store] Transaction Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <!-- body here -->

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>
