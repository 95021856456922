<app-loader *ngIf="!roles"></app-loader>

<div id="content" class="flex" *ngIf="roles">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">List of Roles</h2>
          <small class="text-muted">JuanPay roles</small>
        </div>
        <div class="flex"></div>
        <!-- <div>
          <div (click)="createRole()" class="btn btn-md btn-info">
              <span class="d-none d-sm-inline mx-1">Create New</span>
              <i data-feather="arrow-right"></i>
          </div>
      </div> -->
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="padding" style="padding-right: 0px">
        <!-- <div class="col-sm-12 col-md-5" style="padding-left:0px;">
        <div class="flex">
          <div class="input-group">
            <input type="text" [(ngModel)]="searchString" class="form-control form-control-theme form-control-sm search" placeholder="Search">
            <span class="input-group-append">
              <button (click)="setPageSorting(_order_by)" class="btn btn-white no-border btn-sm" type="button">
                <span class="d-flex text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round" class="feather feather-search">
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg></span>
              </button>
            </span>
          </div>
        </div>
      </div> -->

        <div class="card mt-2">
          <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
            <thead>
              <tr>
                <th data-sortable="true" data-field="id">Id</th>
                <th data-sortable="true" data-field="name">
                  <div [ngClass]="name" (click)="setPageSorting('name')">Role</div>
                </th>
                <th data-field="finish"><span class="d-none d-sm-block">Description</span></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let role of roles?.data" style="cursor: pointer" class="odd" role="row">
                <td>
                  <small class="">{{ role?.id }}</small>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ role?.name }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ role?.description }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
              <div class="col-sm-12 col-md-12">
                <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                  Showing {{ roles?.from }} to {{ roles?.to }} of {{ roles?.data?.length }} entries
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-7" style="float: right">
            <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
              <ul class="pagination" style="float: right; margin-right: 36px">
                <li
                  *ngFor="let page of roles?.links"
                  (click)="getRoles(page.url)"
                  [ngClass]="getPageNumberClass(page.label)"
                >
                  <a class="page-link" [innerHTML]="page.label"></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="vip__page-table--pagination">
          <!-- Pagination from ADMS Project -->
          <pagination-template
            *ngIf="flightTransactionList?.length > 10"
            #p="paginationApi"
            [id]="paginationConfig.id"
            [maxSize]="10"
            (pageChange)="paginationConfig.currentPage = $event"
          >
            <div class="pagination-container text-center margin-top-15">
              <div class="adms-flight-transaction-list__pagination">
                <div class="adms-flight-transaction-list__pagination--pagination-previous">
                  <button type="button" [disabled]="p.isFirstPage()" (click)="p.previous()" class="btn btn-link">
                    <span class="cebcons-md cebcons-arrow-down rotate-left"></span>
                  </button>
                </div>
                <div *ngFor="let page of p.pages" class="adms-flight-transaction-list__pagination--pagination-item">
                  <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">{{ page.label }}</a>
                  <span *ngIf="p.getCurrent() === page.value">{{ page.label }}</span>
                </div>
                <div class="adms-flight-transaction-list__pagination--pagination-next">
                  <button type="button" [disabled]="p.isLastPage()" (click)="p.next()" class="btn btn-link">
                    <span class="cebcons-md cebcons-arrow-down rotate-right"></span>
                  </button>
                </div>
              </div>
            </div>
          </pagination-template>
          <!-- Pagination from ADMS Project -->
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>
