import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { GovipxService } from 'src/data/services/web/govipx.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { toInteger } from 'lodash';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5";

@Component({
  selector: 'app-govipx-topup',
  templateUrl: './govipx-topup.component.html',
  styleUrls: ['./govipx-topup.component.less']
})
export class GovipxTopupComponent implements OnInit {

  @Input("application") application: any;
  govipxTopupFormGroup : FormGroup;
  formBuilder : FormBuilder;
  public emptyString = "";
  public billers : any = [];
  public biller : any;
  public billerCode : any;
  public selectedBillerCode : any;

  public activeTab = 1;
  public loader = true;
  public fee : any;
  public amount : any;

  @Output("closeModal") closeModal = new EventEmitter();

  constructor(
    private govipxTopupService : GovipxService,
    private router: Router,
    localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    formBuilder : FormBuilder,
    private webService: WebSettingsService,
    private datepipe: DatePipe) {
    this.formBuilder = formBuilder;
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.initGovipxTopupFormGroup();
    this.initAPI();
  }

  initGovipxTopupFormGroup(): void {
    this.govipxTopupFormGroup = new FormGroup({
      barcode: new FormControl(this.emptyString, [Validators.required]),
      amount: new FormControl(this.emptyString),
      mobileNo: new FormControl(this.emptyString)
    });
  }

  initAPI(){
    this.loader = false;
    this.govipxTopupService.getAuthenticated().subscribe((result) => {
      if(result){

      } else {
        PNotify.error({
          title: "Error",
          text: "Something went wrong. Please try again.",
        });
      }
    });
  }

  userInfo: any;
  setActiveTab(){
    // this.govipxTopupFormGroup.controls.inceptionDate.setValue(this.datepipe.transform(this.govipxTopupFormGroup.controls.inceptionDate.value, 'yyyy-MM-dd'));
    // this.govipxTopupFormGroup.controls.expiryDate.setValue(this.datepipe.transform(this.govipxTopupFormGroup.controls.expiryDate.value, 'yyyy-MM-dd'));
    //console.log(this.govipxTopupFormGroup.value);


    if(this.activeTab == 1){
       if(this.govipxTopupFormGroup.status == "INVALID"){
        PNotify.error({
          title: "Error",
          text: "All fields are required to proceed to next step.",
        });
      }else{
        this.loader = true;
        this.govipxTopupFormGroup.controls.barcode.patchValue(this.govipxTopupFormGroup.controls.barcode.value.toUpperCase());
        this.govipxTopupService.validateReference(this.govipxTopupFormGroup.controls.barcode.value).subscribe((result) => {
          this.userInfo = result;
          this.govipxTopupFormGroup.controls.amount.patchValue(parseFloat(result?.transaction_details?.amount));
          this.govipxTopupFormGroup.controls.mobileNo.patchValue(result?.user_info?.mobile_no);
          this.activeTab++;
          this.loader = false;
        }, error => {
          debugger
          PNotify.error({
            title: "Failed",
            text: error.error.response_data,
          });
          this.loader = false;
        });
       
      }
    }else if(this.activeTab == 2){

      var hasError : boolean  = false;
      if(hasError){
        PNotify.error({
          title: "Request denied",
          text: "Please fill up all the required fields.",
        });
      }else{

      }

     
    }
  }

  setActiveTabPrev(){
    if(this.activeTab == 1){
      PNotify.error({
        title: "Error",
        text: "Action Denied.",
      });
    }else{
      this.activeTab--;
    }
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  codeStatus : boolean = false;
  code : any;
  
  onCodeCompleted(code: string) {
    this.code = code;
    this.codeStatus = code.length == 6;
  }

  
  processPayment(){
    this.loader = true;
    this.govipxTopupFormGroup.controls.barcode.patchValue(this.govipxTopupFormGroup.controls.barcode.value.toUpperCase());
    this.govipxTopupService.processGoVIPXTopup(this.govipxTopupFormGroup.value, this.code)
      .subscribe((result) => {
        PNotify.success({
          title: "Successful!",
          text: "Transaction completed.",
        });
        this.loader = false;
        this.code = "";
        this.codeStatus=false;
        this.closeModal.emit();
      
    }, error => {
      PNotify.error({
        title: "Failed",
        text: error.error.reason,
      });
      this.loader = false;
      this.code = "";
      this.codeStatus = false;
    });
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  totalAmount(fee:any,amount:any){
    try  {
    return this.makeMoney(Number(fee) + Number(amount));
    } catch(e){
      return "0.00";
    }
  }
}
