import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CryptoService } from 'src/data/crypto/crypto.service';
import { Data } from 'src/data/data';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { AuthService } from 'src/data/services/authentication/auth.service';
import { ExportExcelService } from 'src/data/services/web/export-excel.service';
import { RolesService } from 'src/data/services/web/roles.service';
import { StoreItemService } from 'src/data/services/web/store-item.service';
import { UserService } from 'src/data/services/web/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-store-item-list',
  templateUrl: './store-item-list.component.html',
  styleUrls: ['./store-item-list.component.less']
})
export class StoreItemListComponent implements OnInit {
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public storeItemService: StoreItemService;
  public modalService: BsModalService;
  public accessToken: string;
  public bsModalRefConfirmation: BsModalRef;
  public userService: UserService;

  public roleLevelId: any;
  public username: any;
  public pageCount: any;

  public storeItems: any;
  public emptyString = "";
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    private currencyPipe: CurrencyPipe,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    storeItemService: StoreItemService,
    modalService: BsModalService,
    bsModalRefConfirmation: BsModalRef,
    userService: UserService,
    private modalBService: NgbModal,
    public ete: ExportExcelService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.storeItemService = storeItemService;
    this.modalService = modalService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
    this.bsModalRefConfirmation = bsModalRefConfirmation;
    this.userService = userService;
  }

  ngOnInit(): void {
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.username = this.localStorageCacheService.getStorage("username");
    this.getStoreItem();
  }

  //#region ***** Get List *****

  private _sortBy: any;
  private _sort: any;

  getStoreItem(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_searchString: string = this.searchString ? `searchString=${this.searchString}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_searchString;

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/StoreItem/StoreItems?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/StoreItem/StoreItems${queryString}`;
      }
    }

    this.storeItemService.getStoreItems(endPointUrl).subscribe((result) => {
      if (result.data.length == 0 && result.pageNumber != 1) {
        this.getStoreItem(result.previousPage, _sortBy, _sort);
      } else {
        this.storeItems = result;
      }
    });
  }

  createdDate = "";
  searchString = "";

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.createdDate = field == "createdDate" ? "th-inner sortable both " + sortBy : "";

    this.getStoreItem(
      `${environment.API_URL}/api/StoreItem/StoreItems?pageNumber=${page ? page : this.storeItems.pageNumber}`,
      this._sortBy,
      this._sort
    );
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.storeItems?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }

  p:Number =1;
  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p = pageNumber;
    return `${environment.API_URL}/api/StoreItem/StoreItems?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }

  //#endregion
  

  viewItem(id: any){
    const encId = this.cryptoService.set(this.data.authentication.privateKey, id);
    this.router.navigate(["acm", "store-item", { id: encId }]);
  }

}
