import { getLocaleDateTimeFormat } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { OrdinalDatePipe } from 'src/app/pipes/ordinal-date.pipe';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';

@Component({
  selector: 'app-da5-certificate',
  templateUrl: './da5-certificate.component.html',
  styleUrls: ['./da5-certificate.component.less']
})
export class Da5CertificateComponent implements OnInit {

  public woSign: any;
  public wtihSign: any;

  public certificateFormGroup: FormGroup;

  public issueDate = new Date();
  public deyt: any;
  public name: any = "";
  public dti: any = "";
  public address: any = "";

  public emptyString = "";

  loader = false;

  constructor(private webService: WebSettingsService,private sanitizer: DomSanitizer, private ordinalDate: OrdinalDatePipe) { }

  ngOnInit(): void {
    this.initReceipt();
  }

  initReceipt() {
    this.loader = true;
    this.initCertificateFormGroup();
    this.deyt = this.ordinalDate.transform(this.issueDate);
    this.webService.getWebSettingsByIdentifier("DA5_CERTIFICATION").subscribe((result) => {
      this.woSign = result.data[0].value;
      this.wtihSign = result.data[1].value;

      
      this.loader = false;
    });
  }

  initCertificateFormGroup(): void {
    this.certificateFormGroup = new FormGroup({
      name : new FormControl(this.emptyString),
      dti : new FormControl(this.emptyString),
      address: new FormControl(this.emptyString)
    });
  }


  printWithoutSign(elementId){
    this.name = this.certificateFormGroup.controls.name.value;
    this.dti = this.certificateFormGroup.controls.dti.value;
    this.address = this.certificateFormGroup.controls.address.value;

    var printContent = document.getElementById(elementId);
    var newStr = printContent.innerHTML.replace("%%DTI%%", this.certificateFormGroup.controls.dti.value);
    newStr = newStr.replace("%%NAME%%", this.certificateFormGroup.controls.name.value);
    newStr = newStr.replace("%%DTI%%", this.certificateFormGroup.controls.dti.value);
    newStr = newStr.replace("%%ADDRESS%%", this.certificateFormGroup.controls.address.value);

    var windowUrl = 'about:blank';
    var uniqueName = new Date();
    var windowName = 'Print' + uniqueName.getTime();
    var printWindow = window.open(windowUrl, windowName, 'left=50000,top=50000,width=0,height=0');

    printWindow.document.write(newStr);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();

    this.certificateFormGroup.controls.name.setValue("");
    this.certificateFormGroup.controls.dti.setValue("");
    this.certificateFormGroup.controls.address.setValue("");
    // var Newstr = this.woSign;

    //  this.PrintReceipt(Newstr);
  }

  PrintReceipt(Newstr: any)
  {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write(Newstr);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    return true;
  }
}
