
<div *ngIf="!(!loader && !loaderTabs && hideBillers)">
    <center>
      
    <img src="../../../assets/loader/custom-spinner-final.gif" style="width: 50px;"/>
  </center>
</div>




<div *ngIf="!loader && !loaderTabs && hideBillers" >



<div class="col-lg-5" style="float:left">


<div class="col-lg-12" style="float: left;" >
  <div>
    <div>
      <div *ngIf="barChartData" style="display: block">
        <canvas baseChart
          [datasets]="barChartData"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          
          [legend]="barChartLegend"
          [chartType]="barChartType">
        </canvas>
      </div>
    </div>
  </div>
  
  <!-- [plugins]="barChartPlugins" -->
</div>



<button class="btn btn-raised btn-wave btn-icon btn-rounded mb-2 text-black" (click)="reportChart(apiRoute);" style="float: right;">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-refresh-ccw mx-2"><polyline points="1 4 1 10 7 10"></polyline><polyline points="23 20 23 14 17 14"></polyline><path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15"></path></svg>
</button>




<div>
  
  <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
    <thead>
      <tr>
        <th data-sortable="true" data-field="id" style="width: 22%">
          <div>Date</div>
        </th>
        
        <th data-sortable="true" data-field="owner">
          <div class="pull-end" >Completed</div>
        </th>
        <th data-sortable="true" data-field="owner">
          <div class="pull-end" >Pending</div>
        </th>
        <th data-sortable="true" data-field="project">
          <div class="pull-end">Failed</div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let perDayCost of perDayCosts"
        style="cursor: pointer"
        class="odd"
        role="row"
        (click)="getReportsTransactionsBillers(perDayCost.date)"
      >

        <td>
           <small class="" >{{ perDayCost.date | date : 'yyyy-MM-dd' }}</small>
        </td>
        
       <td style="text-align: end;">
        <small class="" ><strong>({{perDayCost.successCount}})</strong> {{ perDayCost.successCost | number: "1.2-2" }}</small>
     </td>
        <td style="text-align: end;">
          <small class="" ><strong>({{perDayCost.pendingCount}})</strong> {{ perDayCost.pendingCost | number: "1.2-2" }}</small>
       </td>
     <td style="text-align: end;">
      <small class="" ><strong>({{perDayCost.failedCount}})</strong> {{ perDayCost.failedCost  | number: "1.2-2"}}</small>
    </td>
      </tr>
   
    </tbody>
  </table>
  
</div>

</div>



<div class="col-lg-7" style="float:right; height: 565px;">


 

  <div class="pull-end" style="width: 75%;">

    
  <div class="btn-group btn-group-toggle mb-3 pull-end" data-toggle="buttons" style="position: absolute;">
    <label (click)="sorting('successCount');" class="btn active">
        <input  type="radio" name="options" id="option1" checked=""> Transaction
    </label>
    <label (click)="sorting('successCost');" class="btn">
        <input  type="radio" name="options" id="option2"> Amount
    </label>
</div>
</div>

  <div>
    <div class="b-b">

   

        <div class="nav-active-border b-primary bottom">
            <ul class="nav" id="myTab" role="tablist">
                <li class="nav-item" *ngIf="hideBillers === 'false'">
                    <a class="nav-link active" [id]="providerHashA" data-toggle="tab" [href]="providerHashA" role="tab" aria-controls="home" aria-selected="true">Billers</a>
                </li>
                <li class="nav-item">
                    <a [ngClass]="hideBillers === 'false' ? 'nav-link' : 'nav-link active'" [id]="providerHashB" data-toggle="tab" [href]="providerHashB" role="tab" aria-controls="profile" aria-selected="false">
                      {{role == 1 ? 'Outlets' : 'Cashiers' }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="tab-content p-3">
        <div *ngIf="hideBillers === 'false'" class="tab-pane fade show active" [id]="providerA" role="tabpanel" [aria-labelledby]="providerA" >
          <div *ngIf="!loaderTabs && transactionPerDay?.billersBreakdowns?.length != 0" style="overflow: auto;  height: 565px;">
          <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
            <thead>
              <tr>
                <th data-sortable="true" data-field="id" style="width: 40%">
                  <div>Biller : {{sDate}}</div>
                </th>
                
                <th data-sortable="true" data-field="owner" style="width: 30%">
                  <div class="pull-end" >Completed</div>
                </th>
                <th data-sortable="true" data-field="owner" style="width: 15%" >
                  <div class="pull-end" >Pending</div>
                </th>
                <th data-sortable="true" data-field="project" style="width: 15%">
                  <div class="pull-end">Failed</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let transaction of transactionPerDay.billersBreakdowns"
                style="cursor: pointer"
                class="odd"
                role="row"
                (click)="getReportsTransactionsBillers(perDayCost.date)"
              >
        
                <td>
                   <small class="" >{{ transaction.billerName }}</small>
                </td>
                <td style="text-align: end;">
                  <small class="tooltip-350" [tooltip]="HtmlContents" placement="left" contentType="template" ><strong>({{transaction.successCount}})</strong> {{ transaction.successCost | number: "1.2-2" }}</small>
                  <ng-template #HtmlContents>
                    <span  *ngFor="let transaction of transaction.successBillers">
                      [{{transaction.transactionCount}}] {{transaction.username}} : {{transaction.transactionCost | number: "1.2-2"}} <br/>
                    </span>
                  </ng-template>   
                </td>
                <td  style="text-align: end;">
                  <small class="tooltip-350" [tooltip]="HtmlContentp" placement="left" contentType="template"><strong>({{transaction.pendingCount}})</strong> {{ transaction.pendingCost | number: "1.2-2" }}</small>
                  <ng-template #HtmlContentp>
                    <span  *ngFor="let transaction of transaction?.pendingBillers">
                      [{{transaction.transactionCount}}] {{transaction.username}} : {{transaction.transactionCost | number: "1.2-2"}} <br/>
                    </span>
                    <span *ngIf="transaction?.pendingCount == 0">
                      No Transaction
                  </span>
                  </ng-template>   
               </td>
            
             <td   style="text-align: end;">
              <small  class="tooltip-350" [tooltip]="HtmlContentf" placement="left" contentType="template"><strong>({{transaction.failedCount}})</strong> {{ transaction.failedCost  | number: "1.2-2"}}</small>
                <ng-template #HtmlContentf  >
                  <span   *ngFor="let transaction of transaction?.failedBillers">
                    [{{transaction.transactionCount}}] {{transaction.username}} : {{transaction.transactionCost | number: "1.2-2"}} <br/>
                  </span>
                  <span *ngIf="transaction.failedCount == 0">
                      No Transaction
                  </span>
                </ng-template>   
            </td>
              </tr>
           
            </tbody>
          </table>
       
      
       
        </div>
        <div *ngIf="transactionPerDay.billersBreakdowns?.length == 0" style="height: auto;text-align: center;"> -- No Transaction -- </div>
        </div>
        <div [ngClass]="hideBillers === 'false' ? 'tab-pane fade' : 'tab-pane fade show active'"  [id]="providerB" role="tabpanel" [aria-labelledby]="providerB">
          <div *ngIf="!loaderTabs && transactionPerDay.managersBreakdowns?.length != 0" style="overflow: auto;  height: 510px;">
            <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
              <thead>
                <tr>
                  <th data-sortable="true" data-field="id" style="width: 30%">
                    <div>Username : {{sDate}}</div>
                  </th>
                  <th data-sortable="true" data-field="owner" style="width: 30%">
                    <div class="pull-end" >Completed</div>
                  </th>
                  <th data-sortable="true" data-field="owner" style="width: 20%" >
                    <div class="pull-end" >Pending</div>
                  </th>
                  
                  <th data-sortable="true" data-field="project" style="width: 20%">
                    <div class="pull-end">Failed</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let transaction of transactionPerDay.managersBreakdowns"
                  style="cursor: pointer"
                  class="odd"
                  role="row"
                >
                  
                  <td>
                     <small class="" >{{ transaction.username }}</small>
                  </td>
                  <td   style="text-align: end;">
                    <small class="tooltip-350" [tooltip]="HtmlContents" placement="left" contentType="template" ><strong>({{transaction.successCount}})</strong> {{ transaction.successCost | number: "1.2-2" }}</small>
                    <ng-template #HtmlContents>
                      <span  *ngFor="let transaction of transaction.successBillers">
                        [{{transaction.transactionCount}}] {{transaction.billerName}} : {{transaction.transactionCost | number: "1.2-2"}} <br/>
                      </span>
                    </ng-template>   
                  </td>
                  <td   style="text-align: end;">
                    <small class="tooltip-350" [tooltip]="HtmlContentp" placement="left" contentType="template"><strong>({{transaction.pendingCount}})</strong> {{ transaction.pendingCost | number: "1.2-2" }}</small>
                    <ng-template #HtmlContentp>
                      <span  *ngFor="let transaction of transaction?.pendingBillers">
                        [{{transaction.transactionCount}}] {{transaction.billerName}} : {{transaction.transactionCost | number: "1.2-2"}} <br/>
                      </span>
                      <span *ngIf="transaction?.pendingCount == 0">
                        No Transaction
                    </span>
                    </ng-template>   
                 </td>
              
               <td   style="text-align: end;">
                <small  class="tooltip-350" [tooltip]="HtmlContentf" placement="left" contentType="template"><strong>({{transaction.failedCount}})</strong> {{ transaction.failedCost  | number: "1.2-2"}}</small>
                  <ng-template #HtmlContentf  >
                    <span   *ngFor="let transaction of transaction?.failedBillers">
                      [{{transaction.transactionCount}}] {{transaction.billerName}} : {{transaction.transactionCost | number: "1.2-2"}} <br/>
                    </span>
                    <span *ngIf="transaction.failedCount == 0">
                        No Transaction
                    </span>
                  </ng-template>   
              </td>
                </tr>
             
              </tbody>
            </table>
          </div>
        <div *ngIf="transactionPerDay.managersBreakdowns?.length == 0" style="height: auto;text-align: center;"> -- No Transaction -- </div>
          
        </div>
      
    </div>
</div>
</div>


</div>



