import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ModalConfirmationComponent } from "src/app/pages/modal-confirmation/modal-confirmation.component";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { WalletService } from "src/data/services/web/wallet.service";
import { WebSettingsService } from "src/data/services/web/web-settings.service";

@Component({
  selector: "app-side-nav",
  templateUrl: "./side-nav.component.html",
  styleUrls: ["./side-nav.component.less"],
})
export class SideNavComponent implements OnInit {
  encapsulation: ViewEncapsulation.None;

  public authService: AuthService;
  public bsModalRefConfirmation: BsModalRef;
  public localStorageCacheService: LocalStorageCacheService;
  public modalService: BsModalService;
  public roleLevelId: any;
  public roleLevelName: any;
  public roleId: any;

  public soaModule = false;
  public individualSoaModule = false;
  public allSoaModule = false;
  public transactionHistoryModule = false;
  public serviceChargeHistoryModule = false;

  public settingsModule = false;
  public webSettingsModule = false;
  public passwordResetModule = false;
  public logsModule = false;
  public auditCheckerModule = false;
  public announcenmentModule = false;
  public vipMarketPlaceModule = false;
  public marketPlaceModule = false;
  public applicationModule = false;
  public accessManagementModule = false;
  public roleModule = false;
  public manageRoleModule = false;
  public companyModule = false;
  public companyUserModule = false;
  public managerUserModule = false;
  public cashierUserModule = false;
  public storeVerificationModule = false;

  public salesUserModule = false;
  public executiveDirectorModule = false;
  public salesDirectorModule = false;
  public salesManagerModule = false;
  public salesAgentModule = false;

  public salesModule = false;

  public storeModule = false;
  public posModule = false;
  public cashierHistoryModule = false;
  public fundsModule = false;
  public requestFundsModule = false;
  public paygramLedgerModule = false;
  public allocateFundsModule = false;
  public supportModule = false;
  public ticketModule = false;
  public discussionModule = false;
  public libraryModule = false;
  public downloadAreModule = false;
  public uploadAreModule = false;
  public videoTutorialModule = false;
  //
  public AllFilesModule = false;
  public managerFilesModule = false;
  public vipProtectModule = false;
  public da5CertificateModule = false;
  public productModule = false;
  public partnerModule = false;

  public realtimeMessageModule = false;
  public salesMigrationModule = false;
  public migrationModule = false;
  public locationTrackerModule = false;

  public isPartner = false;
  public isPartnerSunlife = false;
  public isPartnerVIPProtect = false;
  public settlementModule = false;
  public settlementTitleModule = false;
  public timeKeepingModule = false;
  public salesActivationModule = false;
  public vipCloudModule = false;
  public vipLootModule = false;
  public govipxAccountVerification = false;
  public merchantVerification = false;
  public ridertVerification = false;
  public salaryModule = false;
  public spxModule = false;
  public vipsModule = false;
  public vipsHistory = false;
  public vipsRequestFundModule = false;
  public foodOrderHistoryModule = false;
  public riderCreditModule = false;
  public vipsRiderCreditModule = false;
  public vipcoRewardsTransactionHistory = false;
  public userVipsModule = false;
  public managerUserFinanceModule = false;
  public storeSettings = false;
  public inventoryModule = false;
  public govipStoreModule = false;
  public adminPOModule = false;
  public accountReactivationModule = false;
  public vipsCustomizationListModule = false;
  public vipsCommunityListModule = false;
  public paygramFundModule = false;
  public iglooTransactionHistoryModule = false;

  public isSalesModuleEnable: any;
  username: any;

  public walletService: WalletService;

  constructor(
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    private router: Router,
    private route: ActivatedRoute,
    walletService: WalletService,
    private webService: WebSettingsService,
    modalService: BsModalService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.modalService = modalService;
    this.walletService = walletService;
  }

  ngOnInit(): void {
    this.roleLevelName = this.authService.getRoleLevelName(this.localStorageCacheService.getStorage("role_level"));
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    this.userId = this.localStorageCacheService.getStorage("userId");
    this.username = this.localStorageCacheService.getStorage("username");
    this.isSalesModuleEnable = this.localStorageCacheService.getStorage("isSalesModuleEnable");
    this.isPartner = this.roleId == 10 || this.roleId == 12 || this.roleId == 19 || this.roleId == 21 ? true : false;
    this.isPartnerSunlife = this.roleId == 10 ? true : false;
    this.isPartnerVIPProtect = this.roleId == 12 ? true : false;
    this.loadSideNav();
    this.validateAccess();
    this.initAuthorizedUsername();

    if (this.roleId == "3") {
      const userId = this.localStorageCacheService.getStorage("userId");
      this.getStoreSetting(userId);
    }
    if (this.roleId == "4") {
      this.getWallet();
    }
  }

  userId: any;
  getStoreSetting(userId: any) {}

  validateAccess(): void {
    switch (this.roleId) {
      case "1": //super user
        this.govipxAccountVerification = true;
        this.merchantVerification = true;
        this.ridertVerification = true;
        this.accountReactivationModule = true;
        this.paygramFundModule = true;
      case "2": //admin
        this.partnerModule = true;
        this.realtimeMessageModule = true;
        this.locationTrackerModule = true;

        this.soaModule = true;
        this.individualSoaModule = true;
        this.allSoaModule = true;
        this.serviceChargeHistoryModule = true;
        this.vipcoRewardsTransactionHistory = true;

        this.transactionHistoryModule = true;
        this.settingsModule = true;
        this.webSettingsModule = true;
        this.passwordResetModule = true;
        this.logsModule = true;
        this.auditCheckerModule = true;
        this.announcenmentModule = true;

        this.vipMarketPlaceModule = true;
        this.marketPlaceModule = true;
        this.applicationModule = true;
        this.adminPOModule = true;

        this.accessManagementModule = true;
        this.roleModule = true;
        this.manageRoleModule = true;
        this.companyModule = true;
        this.companyUserModule = true;
        //this.AllFilesModule = true;
        this.cashierUserModule = true;

        this.salesMigrationModule = true;
        this.salesUserModule = true;
        this.executiveDirectorModule = true;
        this.salesDirectorModule = true;
        this.salesManagerModule = true;
        this.salesAgentModule = true;

        this.cashierHistoryModule = true;
        this.fundsModule = true;
        this.requestFundsModule = true;
        this.paygramLedgerModule = true;
        //this.allocateFundsModule = true;
        this.supportModule = true;
        this.ticketModule = true;
        this.discussionModule = true;
        this.libraryModule = true;
        this.downloadAreModule = true;
        this.uploadAreModule = true;
        this.videoTutorialModule = true;
        this.vipProtectModule = true;
        this.da5CertificateModule = true;
        this.productModule = true;

        this.settlementModule = true;
        this.salesActivationModule = true;
        this.vipsHistory = true;
        this.salaryModule = true;
        this.settlementTitleModule = true;
        this.spxModule = true;
        this.storeVerificationModule = true;
        this.foodOrderHistoryModule = true;
        this.userVipsModule = true;
        this.managerUserFinanceModule = true;
        this.vipsCustomizationListModule = true;
        this.vipsCommunityListModule = true;

        this.storeSettings = true;
        this.inventoryModule = true;
        break;
      case "3": //manager
        this.managerFilesModule = true;
        this.soaModule = true;
        this.individualSoaModule = true;
        this.serviceChargeHistoryModule = true;
        this.transactionHistoryModule = true;

        this.vipMarketPlaceModule = true;
        this.marketPlaceModule = true;
        this.govipStoreModule = true;

        this.companyModule = true;
        this.cashierUserModule = true;

        this.cashierHistoryModule = true;
        this.fundsModule = true;
        this.requestFundsModule = true;
        this.allocateFundsModule = true;

        this.supportModule = true;
        // this.ticketModule = true;
        // this.discussionModule = true;
        this.libraryModule = true;
        this.downloadAreModule = true;
        // this.uploadAreModule = true;
        this.videoTutorialModule = true;
        this.vipsModule = true;
        this.riderCreditModule = true;

        if (this.isSalesModuleEnable == "true") {
          this.salesModule = true;
        } else {
          this.salesModule = false;
        }
        break;
      case "4": //cashier
        this.soaModule = true;
        this.individualSoaModule = true;
        this.serviceChargeHistoryModule = true;
        this.transactionHistoryModule = true;

        this.storeModule = true;
        this.posModule = true;
        this.vipCloudModule = true;
        this.vipLootModule = true;
        this.cashierHistoryModule = true;

        this.fundsModule = true;
        this.allocateFundsModule = true;

        this.supportModule = true;
        // this.ticketModule = true;
        // this.discussionModule = true;
        this.libraryModule = true;
        this.downloadAreModule = true;
        // this.uploadAreModule = true;
        this.videoTutorialModule = true;
        break;
      case "5": //techinical supervisor
        this.realtimeMessageModule = true;
        this.locationTrackerModule = true;
        this.soaModule = true;
        this.individualSoaModule = true;
        this.allSoaModule = true;
        this.transactionHistoryModule = true;
        this.serviceChargeHistoryModule = true;
        this.vipcoRewardsTransactionHistory = true;

        this.settingsModule = true;
        this.passwordResetModule = true;
        this.logsModule = true;
        this.auditCheckerModule = true;
        this.announcenmentModule = true;
        this.paygramLedgerModule = true;

        this.vipMarketPlaceModule = true;
        this.marketPlaceModule = true;
        this.applicationModule = true;

        this.companyModule = true;
        // this.managerUserModule = true;
        this.managerUserFinanceModule = true;
        this.cashierUserModule = true;

        this.salesUserModule = true;
        this.executiveDirectorModule = true;
        this.salesDirectorModule = true;
        this.salesManagerModule = true;
        this.salesAgentModule = true;

        this.cashierHistoryModule = true;

        this.fundsModule = true;
        this.requestFundsModule = true;

        this.productModule = true;
        this.vipProtectModule = true;
        this.da5CertificateModule = true;

        this.supportModule = true;
        this.ticketModule = true;
        this.discussionModule = true;
        this.libraryModule = true;
        this.downloadAreModule = true;
        this.uploadAreModule = true;
        this.videoTutorialModule = true;

        this.govipxAccountVerification = true;
        this.merchantVerification = true;
        this.vipsHistory = true;
        this.salaryModule = true;
        this.userVipsModule = true;
        this.vipsCustomizationListModule = true;
        this.vipsCommunityListModule = true;

        this.settlementModule = true;
        this.settlementTitleModule = true;
        this.spxModule = true;
        this.foodOrderHistoryModule = true;
        this.accountReactivationModule = true;
        break;
      case "6": //csr
        this.settingsModule = true;
        this.realtimeMessageModule = true;
        this.locationTrackerModule = true;
        this.soaModule = true;
        this.individualSoaModule = true;
        this.allSoaModule = true;
        this.transactionHistoryModule = true;
        this.serviceChargeHistoryModule = true;
        this.vipcoRewardsTransactionHistory = true;

        this.settingsModule = true;
        this.announcenmentModule = true;

        this.companyModule = true;
        //this.managerUserFinanceModule = true;
        this.managerUserModule = true;
        this.cashierUserModule = true;

        this.salesUserModule = true;
        this.executiveDirectorModule = true;
        this.salesDirectorModule = true;
        this.salesManagerModule = true;
        this.salesAgentModule = true;
        this.paygramLedgerModule = true;

        this.cashierHistoryModule = true;

        this.vipMarketPlaceModule = true;
        this.marketPlaceModule = true;
        this.applicationModule = true;

        this.fundsModule = true;
        this.requestFundsModule = true;

        this.productModule = true;
        this.vipProtectModule = true;
        this.da5CertificateModule = true;

        this.supportModule = true;
        this.ticketModule = true;
        this.discussionModule = true;
        this.libraryModule = true;
        this.downloadAreModule = true;
        this.uploadAreModule = true;
        this.videoTutorialModule = true;

        this.govipxAccountVerification = true;
        this.merchantVerification = true;
        this.vipsHistory = true;
        this.ridertVerification = true;
        this.foodOrderHistoryModule = true;
        this.userVipsModule = true;
        this.accountReactivationModule = true;
        this.vipsCustomizationListModule = true;
        this.vipsCommunityListModule = true;
        break;
      case "7": //finance
        this.partnerModule = true;
        this.settingsModule = true;
        this.realtimeMessageModule = true;
        this.locationTrackerModule = true;
        this.companyModule = true;
        // this.managerUserModule = true;
        this.managerUserFinanceModule = true;
        this.cashierUserModule = true;
        this.vipcoRewardsTransactionHistory = true;

        this.salesUserModule = true;
        this.executiveDirectorModule = true;
        this.salesDirectorModule = true;
        this.salesManagerModule = true;
        this.salesAgentModule = true;
        this.paygramLedgerModule = true;

        this.fundsModule = true;
        this.requestFundsModule = true;

        this.supportModule = true;
        this.discussionModule = true;
        this.settlementModule = true;
        this.salesActivationModule = true;
        this.salaryModule = true;

        this.settlementTitleModule = true;
        this.spxModule = true;

        break;
      case "8": //compliance
        this.partnerModule = true;
        this.settingsModule = true;
        this.storeSettings = true;

        this.soaModule = true;
        this.individualSoaModule = true;
        this.allSoaModule = true;
        this.serviceChargeHistoryModule = true;
        this.transactionHistoryModule = true;
        this.vipcoRewardsTransactionHistory = true;

        this.realtimeMessageModule = true;
        this.locationTrackerModule = true;
        this.vipMarketPlaceModule = true;
        this.marketPlaceModule = true;
        this.applicationModule = true;

        this.productModule = true;
        this.vipProtectModule = true;
        this.da5CertificateModule = true;

        this.companyModule = true;
        this.managerUserModule = true;
        this.settlementModule = true;

        this.salesUserModule = true;
        this.executiveDirectorModule = true;
        this.salesDirectorModule = true;
        this.salesManagerModule = true;
        this.salesAgentModule = true;
        this.paygramLedgerModule = true;
        //this.govipxAccountVerification = true;
        //this.merchantVerification = true;
        this.vipsHistory = true;
        //this.ridertVerification = true;
        this.userVipsModule = true;

        break;
      case "9": //corporate
        this.settingsModule = true;
        this.realtimeMessageModule = true;
        this.locationTrackerModule = true;
        this.companyModule = true;
        this.managerUserModule = true;

        this.adminPOModule = true;
        this.storeSettings = true;
        this.inventoryModule = true;
        break;
      case "13": //Human Resource
        //this.settingsModule = true;
        //this.realtimeMessageModule = true;
        this.companyModule = true;
        this.companyUserModule = true;
        break;
      case "14": //executive director
      case "16": //sales director
      case "17": //sales executive
      case "18": //sales agent
        this.salesModule = true;
        break;
      case "19":
        this.settingsModule = true;
        this.settlementTitleModule = true;
        this.spxModule = true;
        break;
      //Vips User
      case "21":
        this.vipsModule = true;
        this.vipsRequestFundModule = true;
        this.libraryModule = true;
        this.downloadAreModule = true;
        this.vipsRiderCreditModule = true;
        break;
      //Finance Manager:
      case "22":
        this.partnerModule = true;
        this.realtimeMessageModule = true;
        this.paygramLedgerModule = true;

        //this.salesMigrationModule = true;
        //this.migrationModule = true;
        this.locationTrackerModule = true;

        this.soaModule = true;
        this.individualSoaModule = true;
        this.allSoaModule = true;
        this.serviceChargeHistoryModule = true;
        this.vipcoRewardsTransactionHistory = true;

        this.transactionHistoryModule = true;
        this.settingsModule = true;
        //this.webSettingsModule = true;
        //this.passwordResetModule = true;
        //this.logsModule = true;
        //this.auditCheckerModule = true;
        this.announcenmentModule = true;

        this.vipMarketPlaceModule = true;
        this.marketPlaceModule = true;
        this.applicationModule = true;
        this.adminPOModule = true;

        //this.accessManagementModule = true;
        //this.roleModule = true;
        //this.manageRoleModule = true;
        this.companyModule = true;
        this.companyUserModule = true;
        //this.AllFilesModule = true;
        this.cashierUserModule = true;

        this.salesUserModule = true;
        this.executiveDirectorModule = true;
        this.salesDirectorModule = true;
        this.salesManagerModule = true;
        this.salesAgentModule = true;

        this.cashierHistoryModule = true;
        this.fundsModule = true;
        this.requestFundsModule = true;
        this.paygramFundModule = true;
        //this.allocateFundsModule = true;
        this.supportModule = true;
        //this.ticketModule = true;
        this.discussionModule = true;
        this.libraryModule = true;
        this.downloadAreModule = true;
        //this.uploadAreModule = true;
        this.videoTutorialModule = true;
        this.vipProtectModule = true;
        //this.da5CertificateModule = true;
        this.productModule = true;

        this.settlementModule = true;
        this.salesActivationModule = true;
        this.vipsHistory = true;
        this.salaryModule = true;
        this.settlementTitleModule = true;
        this.spxModule = true;
        this.storeVerificationModule = true;
        this.foodOrderHistoryModule = true;
        this.userVipsModule = true;
        this.managerUserFinanceModule = true;
        this.accountReactivationModule = true;
        //Team Leader:
      case "23":
        this.partnerModule = true;
        this.realtimeMessageModule = true;

        this.salesMigrationModule = true;
        //this.migrationModule = true;
        this.locationTrackerModule = true;

        this.soaModule = true;
        this.individualSoaModule = true;
        this.allSoaModule = true;
        this.serviceChargeHistoryModule = true;
        this.vipcoRewardsTransactionHistory = true;

        this.transactionHistoryModule = true;
        this.settingsModule = true;
        //this.webSettingsModule = true;
        //this.passwordResetModule = true;
        //this.logsModule = true;
        //this.auditCheckerModule = true;
        this.announcenmentModule = true;
        this.paygramLedgerModule = true;

        this.vipMarketPlaceModule = true;
        this.marketPlaceModule = true;
        this.applicationModule = true;
        this.adminPOModule = true;

        //this.accessManagementModule = true;
        //this.roleModule = true;
        //this.manageRoleModule = true;
        this.companyModule = true;
        this.companyUserModule = true;
        //this.AllFilesModule = true;
        this.cashierUserModule = true;

        this.salesUserModule = true;
        this.executiveDirectorModule = true;
        this.salesDirectorModule = true;
        this.salesManagerModule = true;
        this.salesAgentModule = true;

        this.cashierHistoryModule = true;
        this.fundsModule = true;
        this.requestFundsModule = true;
        //this.allocateFundsModule = true;
        this.supportModule = true;
        //this.ticketModule = true;
        this.discussionModule = true;
        this.libraryModule = true;
        this.downloadAreModule = true;
        //this.uploadAreModule = true;
        this.videoTutorialModule = true;
        this.vipProtectModule = true;
        //this.da5CertificateModule = true;
        this.productModule = true;

        //this.settlementModule = true;
        this.salesActivationModule = true;
        this.vipsHistory = true;
        //this.salaryModule = true;
        this.settlementTitleModule = true;
        this.spxModule = true;
        this.storeVerificationModule = true;
        this.foodOrderHistoryModule = true;
        this.userVipsModule = true;
        this.managerUserFinanceModule = true;
        this.accountReactivationModule = true;
        this.vipsCommunityListModule = true;
      //Igloo Admin
      case "26":
        this.iglooTransactionHistoryModule = true;
    }
  }

  wallet: number;
  getWallet(): void {
    this.walletService.getWallet().subscribe((result) => {
      this.wallet = result.wallet;
    });
  }

  authorizedUsername: any;
  initAuthorizedUsername() {
    this.webService.getWebSettingsByIdentifier("AUTHORIZE_SALARY").subscribe((result) => {
      this.authorizedUsername = result.data;
    });
  }

  hideSideNav(): void {
    document.getElementById("side-nav").classList.remove("active");
    document.getElementById("nav-bg-wrap").classList.remove("active");
  }

  loadSideNav(): void {
    const dropdown = document.getElementsByClassName("dropdown-btn");
    let i;
    for (i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener("click", function () {
        this.classList.toggle("active");
        const dropdownContent = this.nextElementSibling;
        if (dropdownContent.style.display === "block") {
          dropdownContent.style.display = "none";
        } else {
          dropdownContent.style.display = "block";
        }
      });
    }
  }

  messageDialog(title, message): void {
    const initialState = {
      meta: {
        title: title,
        message: message,
        btnTitle: "Ok",
        showCancel: false,
      },
    };

    this.bsModalRefConfirmation = this.modalService.show(ModalConfirmationComponent, {
      initialState,
    });

    this.bsModalRefConfirmation.content.clickevent.subscribe((response) => {
      if (response.result) {
        //
      }
    });
  }

  angleClass(event: any) {}

  getAuthorize(){
    if(this.authorizedUsername.some(x => x.value == this.username)){
      return true;
    } else {
      return false;
    }
  }
}
