<div class="vip--main">

    <nav class="vip--nav navbar navbar-expand-lg navbar-light bg-light">
      <a class="navbar-brand" href="#">Home</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Services
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="#services">EMI Products</a>
              <a class="dropdown-item" href="#services">Remittance</a>
              <a class="dropdown-item" href="#services">Government Payment</a>
              <a class="dropdown-item" href="#services">Bills Payment</a>
              <a class="dropdown-item" href="#services">Airtime Loading</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="vipco">VIPCO</a>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#about">About Us</a>
          </li>
        </ul>
        <form class="form-inline my-2 my-lg-0">

          <a class="nav-link my-2 my-sm-0" href="login">Login <span class="sr-only">(current)</span></a>
        </form>
      </div>
    </nav>

  <div id="goVIPBanner" class="carousel slide carousel-fade" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#goVIPBanner" data-slide-to="0" class="active"></li>
      <li data-target="#goVIPBanner" data-slide-to="1"></li>
      <li data-target="#goVIPBanner" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active" data-interval="10000">
        <img src="../../../assets/images/banner/banner_1.jpg" class="d-block w-100" alt="goVP Banner1">
      </div>
      <div class="carousel-item" data-interval="2000">
        <img src="../../../assets/images/banner/banner_2.jpg" class="d-block w-100" alt="goVP Banner2">
      </div>
      <div class="carousel-item" data-interval="2000">
        <img src="../../../assets/images/banner/banner_3.jpg" class="d-block w-100" alt="goVP Banner2">
      </div>
    </div>
  </div>

  <div class="vip--files container-fluid">
    <div class="row">
      <div class="col-6 whitepaper--container file--container">
        <a href="../../../assets/images/ACM_BSP_RTC_Presentation_LegRev_2_ytgmji.pdf" target="_blank">
        <img src="../../../assets/hoempage/Icon - JL_2022-07-28_18_15/Whitepaper_tkryyf.png" alt="whitepaper icon">&nbsp;<span>Whitepaper</span>
        </a>
      </div>
      <div class="col eventCalendar--container file--container">
        <a href="">
        <img src="../../../assets/hoempage/Icon - JL_2022-07-28_18_15/Event_Calendar_ovxson.png" alt="calendar event icon">&nbsp;<span class="text-muted">Events(coming soon)</span>
        </a>
      </div>
    </div>
  </div>


  <div id="services" class="vip--services vip--section light--section">
    <h2 class="section--title--light">OUR SERVICES</h2>
    <div class="container-fluid">
      <div class="vip--underline--dark"><img src="../../../assets/hoempage/Icon - JL_2022-07-28_18_15/1_uqaxwk.png" alt=""></div>
    </div>

    <div class="container-fluid services--body">
      <div class="row">
        <div class="col-4 col-sm-3">
          <img src="../../../assets/hoempage/Icon - JL_2022-07-28_18_15/EMI_wsemuw.png"
            class="services--icon d-block float-right"
            alt="EMI icon">
        </div>
        <div class="col-8 col-sm-9">
          <h4>EMI Products</h4>
          <p>
            (Electronic Money Issuance) VIP PAYMENT CENTER is equipped with various products that features “Cashier - Level” transactions such as Cash In, Cash Out Transactions. With our partners' backup, our branches can deliver financial services within our community. VIP is focused on serving the unserved and providing financial service throughout our network.
          </p>
        </div>
        <div class="col-4 col-sm-3">
          <img src="../../../assets/hoempage/Icon - JL_2022-07-28_18_15/Remittance_buqjk8.png"
          class="services--icon d-block float-right"
          alt="remittance icon">
        </div>
        <div class="col-8 col-sm-9">
          <h4>Remittance</h4>
          <p>
            Essential, secured, and easy Local and International Remittance services are offered inside our VIP Payment Center branches, with low cost fees and fast service. Availability is our priority.
          </p>
        </div>
        <div class="col-4 col-sm-3">
          <img src="../../../assets/hoempage/Icon - JL_2022-07-28_18_15/Gov_ulsgmq.png" class="d-block float-right services--icon" alt="govpayment icon">
        </div>
        <div class="col-8 col-sm-9">
          <h4>Government Payment</h4>
          <p>
            With over 400 billers to serve, our Bills Payment Facility is a state-of-the-art service that features almost all the billers / merchants nationwide. Our system in Bills Payment started in 2018 and has served more than a million transactions. Airline Payments are also offered within our Payment Network.
          </p>
        </div>
        <div class="col-4 col-sm-3">
          <img src="../../../assets/hoempage/Icon - JL_2022-07-28_18_15/Bills_kv05r8.png" class="d-block float-right services--icon" alt="bills payment icon">
        </div>
        <div class="col-8 col-sm-9">
          <h4>Bills Payment</h4>
          <p>
            From different Telecommunication Companies local and abroad Airtime Loading is an essential service to complete our ALL IN ONE SHOP community service. Get the best and faster service with our VIP Payment Center Branches.
          </p>
        </div>
        <div class="col-4 col-sm-3">
          <img src="../../../assets/hoempage/Icon - JL_2022-07-28_18_15/Airtime_Loading_d6icen.png" class="d-block float-right services--icon" alt="airtime loading icon">
        </div>
        <div class="col-8 col-sm-9">
          <h4>Airtime Loading</h4>
          <p>
            Our system is built to connect to various government and service providers to provide Government Bills Payment Transaction, ensuring the transaction credibility our team and partners are more than willing to update and give the best possible service at the time being. VIP Payment Center services are truly essential and excellent in community service.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div id="about" class="vip--aboutUs  vip--section light--section">
    <h2 class="section--title--light">ABOUT US</h2>
    <div class="container-fluid">
      <div class="vip--underline--dark">
        <img src="../../../assets/hoempage/Icon - JL_2022-07-28_18_15/1_uqaxwk.png" alt="">
      </div>

    </div>
    <div class="container-fluid vip--aboutUs--container">
      <div class="row">
        <div class="col about--us--content">
          <p>
            <strong>GO VIP CENTER</strong> is the first Philippine premiere website creating innovative web-based business solutions. Providing the underserved and unserved population access to Financial Services.
          </p>
          <p>
            <strong>Our objective</strong> is to develop technology-based systems where legitimate traditional products and new age services combine for a seamless eco system for everyone in our community.
          </p>
          <p>
            <strong>Our vision,</strong> to develop new entrepreneurs and different products and business system through our companies development program.
          </p>
          <p>
            <strong>Our mission,</strong> to create a stable and future proof community that benefits together by helping one another.
          </p>
        </div>
        <div class="col">
          <img src="../../../assets/hoempage/Icon - JL_2022-07-28_18_15/acm_outlet_xhcke5.png" class="mx-auto d-block store--outlet" alt="store outlet image">
        </div>
      </div>
    </div>
  </div>

  <div class="vip--videos vip--section dark--section">
    <h2 class="section--title--dark">VIDEOS</h2>
    <div class="vip--underline--light">
      <img src="../../../assets/hoempage/Icon - JL_2022-07-28_18_15/2_ihjqkf.png" alt="">
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="card" style="width: 18rem;">
            <iframe src="https://drive.google.com/file/d/1Rg2dr09R8OTqpl3CKrLxR1jHp4Pi_uOi/preview" class="card-img-top" alt="..."></iframe>
            <div class="card-body">
              <p class="card-text">VIP CONTRACT SIGNING with MONEYBEES</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card" style="width: 18rem;">
            <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fcreativestudio1234%2Fvideos%2F846979236053137%2F&show_text=false&width=560" class="card-img-top" alt="..."></iframe>
            <div class="card-body">
              <p class="card-text">VIP CONTRACT SIGNING with AYANNAH</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card" style="width: 18rem;">
            <iframe src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Fcreativestudio1234%2Fvideos%2F694245731472349%2F&show_text=false&width=560" class="card-img-top" alt="..."></iframe>
            <div class="card-body">
              <p class="card-text">VIP CONTRACT SIGNING with NATIONLINK</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card" style="width: 18rem;">
            <iframe src="https://www.youtube.com/embed/FJyAVS3WgKU" class="card-img-top" alt="..."></iframe>
            <div class="card-body">
              <p class="card-text">VIP X DA5 (Western Union)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="vip--articles vip--section light--section">
    <h2 class="section--title--light">ARTICLES</h2>
    <div class="container-fluid align-middle">
      <div class="vip--underline--dark">
        <img src="../../../assets/hoempage/Icon - JL_2022-07-28_18_15/1_uqaxwk.png" alt="">
      </div>
    </div>
    <div class="container-fluid">
    <div class="row row-cols-1 row-cols-md-3">
      <div class="col mb-4">
        <div class="card h-100">
          <img src="../../../assets/hoempage/Article Photos_2022-07-28_12_40/article_banner_4_nylcqb.png" class="card-img-top" alt="...">
          <div class="card-body">
            <h5 class="card-title">NATIONLINK PARTNERSHIP</h5>
              <p class="card-text">
                Quezon City, Philippines - Nationlink joins VIP PAyment Center to reach the unbanked and under-banked.
              </p>
            <div id="article--button1" class="collapse">
              <p class="card-text">
                Nationlink offers full banking capability, allowing VIP Payment Center outlets to offer financial services to its community through Nationlink's POS Solution.
              </p>
            </div>
              <button
                class="btn btn-primary"
                data-toggle="collapse"
                data-target="#article--button1">
                  More Details
              </button>
          </div>
          <div class="card-footer">
          </div>
        </div>
      </div>
      <div class="col mb-4">
        <div class="card h-100">
          <img src="../../../assets/hoempage/Article Photos_2022-07-28_12_40/article_banner_1_nupiyj.png" class="card-img-top" alt="...">
          <div class="card-body">
            <h5 class="card-title">AYANNAH BUSINESS SOLUTIONS PARTNERSHIP</h5>
              <p class="card-text">
                Quezon City, Philippines - Ayannah Business Solutions partners with VIP Payment Center to empower its remittance and cash-in / cash-out facility.
              </p>
            <div id="article--button2" class="collapse">
              <p class="card-text">
                Ayannah Business Solutions offer a wide range of products, VIP Payment Center in particular consumes the Sendah Direct capabilities and opens its service across all VIP Payment Center. Services like Smart Padala Sending and Encashments are done in the Sendah Direct Integration with VIP Payment Center. Looking forward to more services from them, we are in the move to bring more profitable services for our franchise partners.
              </p>
            </div>
              <button
                class="btn btn-primary"
                data-toggle="collapse"
                data-target="#article--button2">
                  More Details
              </button>
          </div>
          <div class="card-footer">
          </div>
        </div>
      </div>
      <div class="col mb-4">
        <div class="card h-100">
          <img src="../../../assets/hoempage/Article Photos_2022-07-28_12_40/article_banner_2_oeqvw0.png" class="card-img-top" alt="...">
          <div class="card-body">
            <h5 class="card-title">BRB - BINANGONAN RURAL BANK PARTNERSHIP</h5>
              <p class="card-text">
                Quezon City, Philippines - Binangonan Rural Bank collaborates with VIP Payment Center.
              </p>
            <div id="article--button3" class="collapse">
              <p class="card-text">
                With the help of BRB (Binangonan Rural Bank) , VIP Payment Centers can now provide a reloadable, multi-purpose electronic value cash card which will allow card holders access to services like cash withdrawal and fund transfers. VIP Payment Center outlet owners gain additional earnings per released cash card and per card transactions.
              </p>
            </div>
              <button
                class="btn btn-primary"
                data-toggle="collapse"
                data-target="#article--button3">
                  More Details
              </button>
          </div>
          <div class="card-footer">
          </div>
        </div>
      </div>
      <div class="col mb-4">
        <div class="card h-100">
          <img src="../../../assets/hoempage/Article Photos_2022-07-28_12_40/article_banner_3_pyty0i.png" class="card-img-top" alt="...">
          <div class="card-body">
            <h5 class="card-title">DA5 - WESTERN UNION PARTNERSHIP</h5>
              <p class="card-text">
                Quezon City, Philippines - DA5, an authorized agent of Western Union in the Philippines, announces collaboration with VIP Payment Center.
              </p>
            <div id="article--button4" class="collapse">
              <p class="card-text">
                VIP Payment Center continues to make big leaps with its business platform by augmenting more features, products and services. The partnership with DA5 will now include remittance services, allowing VIP Payment Center business partners to serve as an avenue in sending out or receiving money internationally or domestically via Western Union. VIP Payment Center Franchise partners earn from every remittance whether it is a sending out or a receiving a transaction. The more transactions processed, the higher the profit.
              </p>
            </div>
              <button
                class="btn btn-primary"
                data-toggle="collapse"
                data-target="#article--button4">
                  More Details
              </button>
          </div>
          <div class="card-footer">
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>

  <div class="vip--gallery vip--section dark--section">
    <h2 class="section--title--dark">GALLERY</h2>
    <div class="vip--underline--light">
      <img src="https://res.cloudinary.com/govipcenter-com/image/upload/v1641358840/Icon%20-%20JL/2_ihjqkf.png" alt="">
    </div>

    <div class="container-fluid">
        <div class="row photo--container">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/1.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/2.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/3.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/4.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/5.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/6.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/7.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/8.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/9.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/10.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/11.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/12.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/13.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/14.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/15.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/16.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/17.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/18.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/19.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/20.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/21.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/22.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/23.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/24.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/25.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/26.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/27.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/28.jpg" alt="">
            <img src="../../../assets/hoempage/Homepage Gallery_2022-07-28_13_36/29.jpg" alt="">
        </div>
    </div>
  </div>

  <div class="vip--partners vip--section light--section">
    <h2 class="section--title--light">OUR PARTNERS</h2>
    <div class="container-fluid">
      <div class="vip--underline--dark">
        <img src="https://res.cloudinary.com/govipcenter-com/image/upload/v1641358840/Icon%20-%20JL/1_uqaxwk.png" alt="">
      </div>
    </div>

    <div class="container-fluid">
      <div class="row justify-center vip--partners--container">
        <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/Multisys_zvispi.png" />
        <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/ECPay_dkizot.png" />
        <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/Senda_yywig1.png" />
        <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/Netplay_s5gvw8.png" />
        <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/UB_kt9fv4.png" />
        <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/NationLink_dg6f9k.png" />
        <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/Western_Union_uuwspp.png" />
        <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/iBayad_yts7uf.png" />
        <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/Fexco_wwddtd.png" />
        <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/Easydebit_jvyooh.png" />
        <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/Banko_BPI_hxa806.png" />
        <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/B_Rural_Bank_qti30v.png" />
        <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/moneybees_100x100.jpeg" />
        <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/CTI_mgsdx1.png" />
        <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/Ayannah_id69zc.png" />
        <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/BAYAD-LOGO (1).png" />
      </div>
    </div>
  </div>

  <div class="vip--footer container-fluid">
    <div class="footer--icon--container">
      <a href="https://www.facebook.com/Govipcenter-212236824125698" target="_blank">
        <img src="../../../assets/hoempage/Icon - JL_2022-07-28_18_15/icons8_Facebook_100px_1_bmzuhc.png" alt="" class="footer--icon facebook--icon">
      </a>

      <a href="https://www.youtube.com/channel/UCY4aJgRRBEwnhhZ60pMgp4A" target="_blank">
        <img src="../../../assets/hoempage/Icon - JL_2022-07-28_18_15/icons8_YouTube_2_100px_wx6hud.png" alt="" class="footer--icon">
      </a>
    </div>

    <p class="footer--content"><a href="/govip-privacy-policy" target="_blank">Terms and Conditions</a></p>

    <p class="footer--content"><a href="/govip-privacy-policy" target="_blank">Privacy and Policy</a></p>

    <p class="aboutUs--container"><a href="#about">ABOUT US</a></p>

    <div class="copyright--container">
      <p>
        Copyright © ACM Business Solution Inc. is a Payment operator licensed by the Bangko Sentral ng Pilipinas.
      </p>
    </div>
  </div>

</div>
























<!-- <link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap" rel="stylesheet">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w==" crossorigin="anonymous" referrerpolicy="no-referrer" />
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css">
<div class="container-fluid p-0" id="header" style="background: transparent;
position: fixed;
top: 0;
z-index: 99999;">
  <nav class="vip--nav navbar navbar-expand-lg navbar-light" data-aos="zoom-out-down">
    <img src="../../../assets/images/logo/GoVIP Logo.png" alt="" width="10%" class="gologo" id="goviplogo">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
      <ul class="navbar-nav mr-2">
        <li class="nav-item">
          <a id="links1" class="nav-link mt-2" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Services
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="background-color: #0e2a76;">
            <a class="dropdown-item text-light" href="#services">EMI Products</a>
            <a class="dropdown-item text-light" href="#services">Remittance</a>
            <a class="dropdown-item text-light" href="#services">Government Payment</a>
            <a class="dropdown-item text-light" href="#services">Bills Payment</a>
            <a class="dropdown-item text-light" href="#services">Airtime Loading</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item text-light" href="https://vips.com.ph/">Vips</a>
          </div>
        </li>
        <li class="nav-item">
          <a id="links2" class="nav-link mt-2" href="#about">About Us</a>
        </li>
        <li class="nav-item">
          <a id="links3" class="nav-link mt-2" href="#footer">Contact Us</a>
        </li>
        <li class="nav-item">
          <a id="links4" class="nav-link mt-2" href="#videos">Videos</a>
        </li>
        <li class="nav-item">
          <a id="links5" class="nav-link mt-2" href="#articles">Articles</a>
        </li>
        <li class="nav-item ml-5">
          <a id="links6" class="nav-link" href="login"><i class="bi bi-person-circle" style="font-size: 20px;"></i> &nbsp; Login</a>
        </li>
      </ul>
    </div>
  </nav>
</div> -->

  <!-- <nav class="navbar navbar-hide-on-scroll navbar-expand-lg" style="    display: flex;
  flex-direction: column;">
    <a class="navbar-brand" href="#"><img src="../../../assets/images/logo/GoVIP Logo.png" alt=""></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav">
        <a class="nav-item nav-link" href="#">Home <span class="sr-only">(current)</span></a>
        <a class="nav-item nav-link" href="#">About Us</a>
        <a class="nav-item nav-link" href="#">Contact Us</a>
        <a class="nav-item nav-link" href="#">Services</a>
      </div>
    </div>
  </nav> -->
<!-- <section class="d-table w-100" id="mainhero"> -->
  <!-- Subscribe Notification Message -->
  <!-- <div style="z-index: 999999999;" *ngIf="message" class="alert alert-success alert-dismissible fade show animate__animated animate__fadeInDown" role="alert">
    <strong>Thank You!</strong> You have been subscribed
    <button (click)="close()" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div> -->
  <!-- <div class="container" style="position: absolute;
  left: 20px;
  z-index: 999;
  top: 50%; width: 50px;">
    <div class="social-links" style="display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 20px;">
      <a class="soc-links text-muted" style="font-size: 15px;">
        <i class="bi bi-align-bottom"></i>
      </a>
      <a href="https://www.facebook.com/Govipcenter-Go-VIP-Business-Center-212236824125698/" class="soc-links text-muted">
        <i class="bi bi-facebook"></i>
      </a>
      <a href="https://www.youtube.com/channel/UCY4aJgRRBEwnhhZ60pMgp4A" class="soc-links text-muted">
        <i class="bi bi-youtube"></i>
      </a>

      <a class="soc-links text-muted" style="font-size: 15px;">
        <i class="bi bi-align-top"></i>
      </a>
    </div>
  </div>
  <div id="goVIPBanner" class="carousel slide carousel-fade" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#goVIPBanner" data-slide-to="0" class="active"></li>
      <li data-target="#goVIPBanner" data-slide-to="1"></li>
      <li data-target="#goVIPBanner" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item active" data-interval="10000">
        <img src="../../../assets/vips/THE BUSINESS OF THE CURRENT.png" class="d-block w-100" alt="goVP Banner1">
      </div>
      <div class="carousel-item" data-interval="2000">
        <img src="../../../assets/vips/THE BUSINESS OF THE CURRENT (1).png" class="d-block w-100" alt="goVP Banner2">
      </div>
      <div class="carousel-item" data-interval="2000">
        <img src="../../../assets/vips/THE BUSINESS OF THE CURRENT (2).png" class="d-block w-100" alt="goVP Banner2">
      </div>
    </div>
  </div>

  <div class="container container__header">
    <a href="../../../assets/images/ACM_BSP_RTC_Presentation_LegRev_2_ytgmji.pdf" target="_blank" class="header__links">
      <div class="whitepaper d-flex align-items-center" style="gap: 10px;">
        <i class="bi bi-newspaper text-white" style="font-size: 50px;"></i>
        <h4 style="color: white; align-self: flex-end;">Whitepaper</h4>
      </div>
    </a>

    <div style="border: 1px solid white; height: 60%; border-radius: 20px;"></div>

    <a href="#" class="header__links">
      <div class="events d-flex align-items-center" style="gap: 10px;">
        <i class="bi bi-calendar-event text-white" style="font-size: 50px;"></i>
        <h4 style="color: white; align-self: flex-end;">Events (coming soon)</h4>
      </div>
    </a>

  </div> -->
  <!-- Email Subscription -->
  <!-- <form [formGroup]="FormData" (ngSubmit)="onSubmit(FormData.value)" class="subscribe">
    <p class="m-0">SUBSCRIBE</p>
    <h6 class="text-center text-light">Subscribe now and get the latest updates straight to your inbox.</h6>
    <input name="email" id="email" type="email" class="subscribe-input ps-5" placeholder="Your email :" formControlName="email">
    <br>
    <input [disabled]="!FormData.valid" type="submit" id="submit" name="send" class="submit-btn" value="Subscribe">
  </form> -->

  <!-- <div class="custom-shape-divider-top-1675836590">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
    </svg>
</div> -->
  <!-- <div class="container-fluid" style="height: 100vh;">
    <div class="row align-items-start justify-content-end w-100">
      <div class="mt-5 col-lg-5 col-md-11 col-sm-2 p-sm-0 p-md-2" style="z-index: 5;">
        <div class="title-heading" data-aos="fade-right" data-aos-duration="1000">
          <h1 style="line-height: 2; font-size: 40px;
          font-weight: 500;
          background: linear-gradient(245deg, rgb(0 12 37) 30%, #050a18 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;" class="heading mb-3 mt-2"><span style="font-size: 50px;
            font-weight: 700;
            background: linear-gradient(327deg, rgba(18,52,148,1) 30%, rgba(153,198,93,1) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;">INNOVATING</span> THE NATION, THE BUSINESS OF THE CURRENT,
            ONE VIP AT A TIME.</h1>
          <div class="d-flex mt-3 text-center gap-5" data-aos="fade-up" data-aos-duration="1500">
            <a href="../../../assets/images/ACM_BSP_RTC_Presentation_LegRev_2_ytgmji.pdf" target="_blank" class="cta">
              <span class="hover-underline-animation"> Learn More </span>
              <svg viewBox="0 0 46 16" height="10" width="30" xmlns="http://www.w3.org/2000/svg" id="arrow-horizontal">
                  <path transform="translate(30)" d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z" data-name="Path 10" id="Path_10"></path>
              </svg>
          </a>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-10 col-sm-3 t-4 pt-2 mt-sm-0 pt-sm-0" style="z-index: 5;">
        <div class="text-md-end text-right ms-lg-4 mt-3" data-aos="fade-left" data-aos-duration="1000">
          <img src="../../../assets/vips/Investment data-amico.svg" class="imghero" width="85%" alt="">
        </div>
      </div>
    </div>
  </div>
  <img src="../../../assets/vips/govipimg.avif" alt="" style="position: absolute;
  bottom: -65px;
  opacity: .4;
  width: 100%;
  height: 100%;"> -->
<!-- </section> -->

  <!-- <div class="container-fluid" style="padding-bottom: 5rem; padding-top: 2rem; background: #0a1d52;">
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-12 col-md-10 col-sm-8 ms-lg-4">
        <div class="title-heading" data-aos="fade-right" data-aos-duration="1000">
          <h2 class="mb-2 text-center" style="color: #ffffff;
          font-weight: 700;"><span>Our Partners</span></h2>
          <p class="mb-2 text-center" style="color: #ffffff;"><span>Trusted by hundreds of corporate teams and home offices.</span></p>
          <div class="slider">
            <div class="slider-track">
              <div class="slide">
                <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/Ayannah_id69zc.png" height="100" width="100" data-aos="zoom-in-down" />
              </div>

              <div class="slide">
                <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/B_Rural_Bank_qti30v.png" height="100" width="100" data-aos="zoom-in-down" />
              </div>

              <div class="slide">
                <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/Banko_BPI_hxa806.png" height="100" width="100" data-aos="zoom-in-down" />
              </div>

              <div class="slide">
                <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/Bayad_Center_rurtzo.png" height="100" width="100" data-aos="zoom-in-down" />
              </div>

              <div class="slide">
                <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/BRB_jhnps1.png" height="100" width="100" data-aos="zoom-in-down" />
              </div>

              <div class="slide">
                <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/CTI_mgsdx1.png" height="100" width="100" data-aos="zoom-in-down" />
              </div>

              <div class="slide">
                <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/ECPay_dkizot.png" height="100" width="100" data-aos="zoom-in-down" />
              </div>

              <div class="slide">
                <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/Fexco_wwddtd.png" height="100" width="100" data-aos="zoom-in-down" />
              </div>

              <div class="slide">
                <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/iBayad_yts7uf.png" height="100" width="100" data-aos="zoom-in-down" />
              </div>

              <div class="slide">
                <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/Multisys_zvispi.png" height="100" width="100" data-aos="zoom-in-down" />
              </div>

              <div class="slide">
                <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/NationLink_dg6f9k.png" height="100" width="100" data-aos="zoom-in-down" />
              </div>

              <div class="slide">
                <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/Netplay_s5gvw8.png" height="100" width="100" data-aos="zoom-in-down" />
              </div>

              <div class="slide">
                <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/Senda_yywig1.png" height="100" width="100" data-aos="zoom-in-down" />
              </div>

              <div class="slide">
                <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/UB_kt9fv4.png" height="100" width="100" data-aos="zoom-in-down" />
              </div>

              <div class="slide">
                <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/Velcro_gf86l2.png" height="100" width="100" data-aos="zoom-in-down" />
              </div>

              <div class="slide">
                <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/Western_Union_uuwspp.png" height="100" width="100" data-aos="zoom-in-down" />
              </div>

              <div class="slide">
                <img src="../../../assets/hoempage/Partners Logo_2022-07-28_18_48/Easydebit_jvyooh.png" height="100" width="100" data-aos="zoom-in-down" />
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


<section id="services" class="d-table w-100 pb-2">
  <div class="container">
    <div class="row mt-5 align-items-center justify-content-center">
      <div class="col-lg-12 col-md-10 ms-lg-4">
        <div class="title-heading mt-5" data-aos="fade-right" data-aos-duration="1000">
          <h2 class="mb-2 text-center" style="color: #0a1d52;
          font-weight: 700;"><span>Our passion is your success</span></h2>
          <p class="mb-2 text-center" style="color: #23335f;"><span>Check out our premium services</span></p>
          <div class="row justify-content-center" style="gap: 20px;
          margin-top: 3rem;">

            <div class="card_feat">
              <div class="header_feat">
                  <div class="img-box_feat">
                    <img src="../../../assets/vips/emi3.png" alt="" class="w-100">
                  </div>
                  <h1 class="title_feat">Electronic Money Issuance</h1>
              </div>

              <div class="content_feat">
                  <p class="text-muted">
                    (Electronic Money Issuance) VIP PAYMENT CENTER is equipped with various products that features “Cashier - Level” transactions such as Cash In, Cash Out Transactions. With our partners' backup, our branches can deliver financial services within our community. VIP is focused on serving the unserved and providing financial service throughout our network.
                  </p>

                  <a class="btn-link_feat">Read More...</a>
              </div>
          </div>

        <div class="card_feat">
          <div class="header_feat">
              <div class="img-box_feat">
                <img src="../../../assets/vips/emi2.png" alt="" class="w-100">
              </div>
              <h1 class="title_feat">Government Payment</h1>
          </div>

          <div class="content_feat">
              <p class="text-muted">
                With over 400 billers to serve, our Bills Payment Facility is a state-of-the-art service that features almost all the billers / merchants nationwide. Our system in Bills Payment started in 2018 and has served more than a million transactions. Airline Payments are also offered within our Payment Network.
              </p>

              <a class="btn-link_feat">Read More...</a>
          </div>
      </div>

      <div class="card_feat">
        <div class="header_feat">
            <div class="img-box_feat">
              <img src="../../../assets/vips/emi.png" alt="" class="w-100">
            </div>
            <h1 class="title_feat">Airtime Loading</h1>
        </div>

        <div class="content_feat">
            <p class="text-muted">
              Our system is built to connect to various government and service providers to provide Government Bills Payment Transaction, ensuring the transaction credibility our team and partners are more than willing to update and give the best possible service at the time being. VIP Payment Center services are truly essential and excellent in community service.
            </p>

            <a class="btn-link_feat">Read More...</a>
        </div>
    </div>

      <div class="card_feat">
        <div class="header_feat">
            <div class="img-box_feat">
              <img src="../../../assets/vips/emi1.png" alt="" class="w-100">
            </div>
            <h1 class="title_feat">Bills Payment</h1>
        </div>

        <div class="content_feat">
            <p class="text-muted">
              From different Telecommunication Companies local and abroad Airtime Loading is an essential service to complete our ALL IN ONE SHOP community service. Get the best and faster service with our VIP Payment Center Branches.
            </p>

            <a class="btn-link_feat">Read More...</a>
        </div>
    </div>

    <div class="card_feat">
      <div class="header_feat">
          <div class="img-box_feat">
            <img src="../../../assets/vips/emi4.png" alt="" class="w-100">
          </div>
          <h1 class="title_feat">Remittance</h1>
      </div>

      <div class="content_feat">
          <p class="text-muted">
            Essential, secured, and easy Local and International Remittance services are offered inside our VIP Payment Center branches, with low cost fees and fast service. Availability is our priority.
          </p>

          <a class="btn-link_feat">Read More...</a>
      </div>
  </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section id="about" class="pt-5 pb-5 d-table w-100">
  <img src="../../../assets/vips/wavesOpacity.svg" alt="" style="width: 100%;
  height: 30px;
  margin-top: -103px
;
  opacity: .5;">
  <img src="../../../assets/vips/wavesOpacity (1).svg" alt="" style="    transform: rotate(180deg);
  height: 20px;
  width: 100%;
  margin-top: -193px;
  opacity: .5;">
  <div class="container">
    <div class="row mt-5 align-items-center justify-content-center">
      <div class="col-lg-12 col-md-10 ms-lg-4">
        <div class="title-heading" data-aos="fade-right" data-aos-duration="1000">
          <h2 class="mb-2 text-center" style="color: #0a1d52;
          font-weight: 700;"><span>About Us</span></h2>
          <p class="mb-2 text-center" style="color: #23335f;"><span>If you want to learn more about us, you're in the right place.</span></p>
          <div class="row justify-content-center">

            <div class=".col-xs-6 .col-md-10 col-lg-10 mt-4 pt-2 mb-4" data-aos="fade-left" data-aos-duration="1000">
              <div
                class="card crd rounded p-4 text-center position-relative overflow-hidden border-0">
                <div class="card-body p-0 content">
                  <h4 class="text-white" style="letter-spacing: 1px; line-height: 2; z-index: 2;"><span style="font-weight: 700;">GoVIPCenter</span> is the first Philippine premiere website creating innovative web-based business solutions. Providing the underserved and unserved population access to Financial Services.</h4>
                </div>
              </div>
            </div>

            <div class=".col-xs-6 .col-md-10 col-lg-4 mt-4 pt-2 rela" data-aos="fade-right" data-aos-duration="1000" style="position: absolute;
            left: 0;
            top: 75%;">
              <img class="rela1" src="../../../assets/vips/business-3d-businesswoman-planning-on-reaching-the-goal 1.png" alt="" style="position: absolute;
              top: -112px;
              width: 130px;
              z-index: 2;" data-aos="fade-up" data-aos-duration="2000">
              <div
                class="card rounded abt p-4 text-center position-relative overflow-hidden border-0">
                <div class="card-body p-0 content">

                  <h5 style="color: #0a1d52;">Our objective</h5>
                  <h5 class="text-muted h6 text-justify">is to develop technology-based systems where legitimate traditional products and new age services combine for a seamless eco system for everyone in our community.</h5><br><br>
                </div>
              </div>
            </div>

            <div style="position: absolute;
            top: 125%;" class=".col-xs-6 .col-md-10 col-lg-4 mt-4 pt-2 rela" data-aos="fade-up" data-aos-duration="1000" >
              <img class="rela1" style="position: absolute;
              z-index: 2;
              top: -85px;
              left: 10%;
              width: 117px;" src="../../../assets/vips/business-3d-businessman-with-laptop-finished-all-tasks 1.png" alt="" data-aos="fade-up" data-aos-duration="2000">
              <div
                class="card rounded abt p-4 text-center position-relative overflow-hidden border-0">
                <div class="card-body p-0 content">

                  <h5 style="color: #0a1d52;">Our mission</h5>
                  <h5 class="text-muted h6 text-justify">
                    to create a stable and future proof community that benefits together by helping one another.</h5>
                </div>
              </div>
            </div>

            <div class=".col-xs-6 .col-md-10 col-lg-4 mt-4 pt-2 rela" data-aos="fade-left" data-aos-duration="1000" style="position: absolute;
            top: 75%; right: 0;" >
              <img class="rela1" src="../../../assets/vips/business-3d-businessman-in-blue-suit-looking-into-the-distance 1.png" alt="" style="position: absolute;
              z-index: 2;
              right: 72px;
              top: -92px;width: 70px;" data-aos="fade-up" data-aos-duration="2000">
              <div
                class="card rounded abt p-4 text-center position-relative overflow-hidden border-0">
                <div class="card-body p-0 content">
                  <h5 style="color: #0a1d52;">Our vision</h5>
                  <h5 class="text-muted h6 text-justify">
                    to develop new entrepreneurs and different products and business system through our companies development program.</h5>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="pt-5 pb-5 d-table w-100" id="articles">
  <div class="container">
    <div class="row mt-2 align-items-center justify-content-center pb-4">
      <div class="col-lg-12 col-md-12 ms-lg-4">
        <div class="title-heading" data-aos="fade-right" data-aos-duration="1000">
          <div class="container d-flex justify-content-between">
            <h2 class="mb-3 text-left" style="color: #0a1d52;
          font-weight: 700;"><span>Latest Stories</span></h2>
          <h6><a href="">View all articles</a></h6>
          </div> -->

          <!-- <p class="mb-2 text-center" style="color: #23335f;"><span>If you want to learn more about us, you're in the right place.</span></p> -->
          <!-- <div class="row justify-content-center">

            <div class="col-md-4 col-lg-10 d-flex align-items-stretch mb-4 mb-lg-0 mt-2" data-aos="fade-up-right" data-aos-delay="300">
            <img src="../../../assets/hoempage/Article Photos_2022-07-28_12_40/article_banner_4_nylcqb.png" class="card-img-top" alt="..." width="500px" height="300px">
              <div class="article_desc">
                <span class="badge badge-info">Partnership</span>
                <h2 class="fw-700">Nationlink Partnership</h2>
                <h5>Quezon City, Philippines - Nationlink joins VIP Payment Center to reach the unbanked and under-banked.</h5>
                <p>Nationlink offers full banking capability, allowing VIP Payment Center outlets to offer financial services to its community through Nationlink's POS Solution.</p>
              </div>
            </div>



              <div class="col-md-4 col-lg-10 d-flex align-items-stretch mb-4 mb-lg-0 mt-2" data-aos="fade-up-right" data-aos-delay="300">
                <div class="article_desc">
                  <span class="badge badge-info">Partnership</span>
                  <h2 class="fw-700">Ayannah Business Solution Partnership</h2>
                  <h5>Quezon City, Philippines - Ayannah Business Solutions partners with VIP Payment Center to empower its remittance and cash-in / cash-out facility.</h5>
                  <p>Ayannah Business Solutions offer a wide range of products, VIP Payment Center in particular consumes the Sendah Direct capabilities and opens its service across all VIP Payment Center. Services like Smart Padala Sending and Encashments are done in the Sendah Direct Integration with VIP Payment Center. Looking forward to more services from them, we are in the move to bring more profitable services for our franchise partners.</p>
                </div>
                <img src="../../../assets/hoempage/Article Photos_2022-07-28_12_40/article_banner_2_oeqvw0.png" class="card-img-top" alt="..." width="500px" height="300px">
              </div>

              <div class="col-md-4 col-lg-10 d-flex align-items-stretch mb-4 mb-lg-0 mt-2" data-aos="fade-up-left" data-aos-delay="400">

                <img src="../../../assets/hoempage/Article Photos_2022-07-28_12_40/article_banner_3_pyty0i.png" class="card-img-top" alt="..." width="500px" height="300px">
                <div class="article_desc">
                  <span class="badge badge-info">Partnership</span>
                  <h2 class="fw-700">Binangonan Rural Bank Partnership</h2>
                  <h5>Quezon City, Philippines - Binangonan Rural Bank collaborates with VIP Payment Center.</h5>
                  <p>With the help of BRB (Binangonan Rural Bank) , VIP Payment Centers can now provide a reloadable, multi-purpose electronic value cash card which will allow card holders access to services like cash withdrawal and fund transfers. VIP Payment Center outlet owners gain additional earnings per released cash card and per card transactions.</p>
                </div>
              </div>

              <div class="col-md-4 col-lg-10 d-flex align-items-stretch mb-4 mb-lg-0 mt-2" data-aos="fade-up-left" data-aos-delay="300">
                <div class="article_desc">
                  <span class="badge badge-info">Partnership</span>
                  <h2 class="fw-700">DA5 - Western Union Partnership</h2>
                  <h5>Quezon City, Philippines - DA5, an authorized agent of Western Union in the Philippines, announces collaboration with VIP Payment Center.</h5>
                  <p>VIP Payment Center continues to make big leaps with its business platform by augmenting more features, products and services. The partnership with DA5 will now include remittance services, allowing VIP Payment Center business partners to serve as an avenue in sending out or receiving money internationally or domestically via Western Union. VIP Payment Center Franchise partners earn from every remittance whether it is a sending out or a receiving a transaction. The more transactions processed, the higher the profit.</p>
                </div>
                <img src="../../../assets/hoempage/Article Photos_2022-07-28_12_40/article_banner_1_nupiyj.png" class="card-img-top" alt="..." width="500px" height="300px">
              </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="pb-5 d-table w-100" id="videos"> -->
  <!-- <div class="custom-shape-divider-top-1675845240">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z" class="shape-fill"></path>
    </svg>
</div> -->
  <!-- <div class="container">
    <div class="row mt-2 align-items-center justify-content-center">
      <div class="col-lg-12 col-md-12 ms-lg-4">
        <div class="title-heading" data-aos="fade-right" data-aos-duration="1000">
          <h2 class="mb-2 text-center" style="color: #0a1d52;
          font-weight: 700;"><span>Videos</span></h2>
          <p class="mb-2 text-center" style="color: #0a1d52;"><span>Feel free to watch our videos, and be with us in our success.</span></p>
          <div class="row justify-content-center pb-5 mb-5">

            <div class="col-md-4 col-lg-4 d-flex align-items-stretch mb-4 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
              <div class="icon-box justify-content-center d-block">
                <iframe width="100%" class="rounded mb-3" height="200" src="https://www.youtube.com/embed/p-QrtRUnBN8" title="Contract signing Go VIP Center iBayad" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <h5 class="title text000">GoVIPCenter Contract signing with iBayad</h5>
              </div>
            </div>

              <div class="col-md-4 col-lg-4 d-flex align-items-stretch mb-4 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                <div class="icon-box justify-content-center d-block">
                  <iframe width="100%" class="rounded mb-3" height="200" src="https://www.youtube.com/embed/OnFMUsTjGRg" title="GO VIP CENTER x MULTISYS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  <h5 class="title text000">GoVIPCenter Contract signing with Multisys</h5>
                </div>
                </div>

              <div class="col-md-4 col-lg-4 d-flex align-items-stretch mb-4 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                <div class="icon-box justify-content-center d-block">
                  <iframe  width="100%" class="rounded mb-3" height="200" src="https://www.youtube.com/embed/CgerfP9SxJM" title="VIP CONTRACT SIGNING with BRB" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  <h5 class="title text000">GoVIPCenter Contract signing with BRB</h5>
                </div>
                </div>

              <div class="col-md-4 col-lg-4 d-flex align-items-stretch mb-4 mb-lg-0 mt-4" data-aos="zoom-in" data-aos-delay="400">
                <div class="icon-box justify-content-center d-block">
                  <iframe width="100%" class="rounded mb-3" height="200" src="https://www.youtube.com/embed/Efc4A9rvY_g" title="VIP CONTRACT SIGNING WITH AYANNAH 2020" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  <h5 class="title text000">GoVIPCenter Contract signing with Ayannah</h5>
                </div>
              </div>

              <div class="col-md-4 col-lg-4 d-flex align-items-stretch mb-4 mb-lg-0 mt-4" data-aos="zoom-in" data-aos-delay="300">
                <div class="icon-box justify-content-center d-block">
                  <iframe width="100%" class="rounded mb-3" height="200" src="https://www.youtube.com/embed/IpaN5Fgvb8k" title="VIP CONTRACT SIGNING WITH NATIONLINK" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  <h5 class="title text000">GoVIPCenter Contract signing with Nationlink</h5>
                </div>
              </div>


              <div class="col-md-4 col-lg-4 d-flex align-items-stretch mb-4 mb-lg-0 mt-4" data-aos="zoom-in" data-aos-delay="200">
                  <div class="icon-box justify-content-center d-block">
                    <iframe width="100%" class="rounded mb-3" height="200" src="https://www.youtube.com/embed/FJyAVS3WgKU" class="card-img-top" alt="..."></iframe>
                    <h5 class="title text000">GoVIPCenter Contract signing with DA5 & Western Union</h5>
                  </div>
                </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</section> -->

<!-- Footer -->
<!-- <img src="../../../assets/vips/wavesOpacity (2).svg" alt="" style="width: 100%;
margin-top: -120px;
transform: rotate(180deg);">
<footer class="text-center text-lg-start bg-light text-muted" id="footer"> -->

  <!-- Section: Links  -->
    <!-- <div class="container text-center text-md-start mt-2"> -->
      <!-- Grid row -->
      <!-- <div class="row mt-3"> -->
        <!-- Grid column -->
        <!-- <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4"> -->
          <!-- Content -->
          <!-- <h6 class="fw-bold mb-4 text-left text-white">
            GoVIPCenter -->
            <!-- <img src="../../../assets/images/logo/GoVIP Logo.png" width="50px" alt=""> -->
          <!-- </h6>
          <p class="text-left text-light h6">Unit 888, 4th Level Lemon Square Bldg. Muñoz EDSA Quezon City</p>
          <p class="text-justify text-light h6">
            Tel. No: (02) 8 294 2312
          </p>
        </div> -->
        <!-- Grid column -->

        <!-- Grid column -->
        <!-- <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4"> -->
          <!-- Links -->
          <!-- <h6 class="fw-bold mb-4 text-white text-left h4">
            Apply Now!
          </h6>
          <p class="text-left text-light h6">
            <a href="https://vips.com.ph/merchant-application" class="text-reset">Be a Vips Merchant</a>
          </p>
          <p class="text-left text-light h6">
            <a href="https://vips.com.ph/rider-application" class="text-reset">Be a Vips Rider</a>
          </p>
          <p>
        </div> -->
        <!-- Grid column -->

        <!-- Grid column -->
        <!-- <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4"> -->
          <!-- Links -->
          <!-- <h6 class="fw-bold mb-4 text-white text-left h4">
            Useful links
          </h6>
          <p class="text-left text-light h6">
            <a href="/govip-privacy-policy" target="_blank" class="text-reset">Terms and Conditions</a>
          </p>
          <p class="text-left text-light h6">
            <a href="/govip-privacy-policy" target="_blank" class="text-reset">Privacy and Policy</a>
          </p>
          <p class="text-left text-light h6">
            <a href="#about" class="text-reset">About Us</a>
          </p>
        </div> -->
        <!-- Grid column -->

        <!-- Grid column -->
        <!-- <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4"> -->
          <!-- Links -->
          <!-- <h6 class="fw-bold mb-4 text-white text-left h4">Socials</h6>
          <p class="text-left text-light h6"><a class="text-light h6" href="https://www.facebook.com/Govipcenter-Go-VIP-Business-Center-212236824125698/">
            <i class="fab fa-facebook-square me-3 text-light h6"></i> &nbsp;GoVIPCenter /Go VIP Business Center</a>
          </p>
          <p class="text-left text-light h6"><a class="text-light h6" href="https://www.youtube.com/channel/UCY4aJgRRBEwnhhZ60pMgp4A">
            <i class="fab fa-youtube me-3 text-light h6"></i> &nbsp;VIP Business Franchise</a>
          </p> -->
        <!-- </div> -->
        <!-- Grid column -->
      <!-- </div> -->
      <!-- Grid row -->
    <!-- </div> -->
  <!-- Section: Links  -->

  <!-- Copyright -->
  <!-- <div class="text-center p-4 text-light h6 mb-0" style="background-color: rgba(0, 0, 0, 0.05);">
    Copyright © ACM Business Solution Inc. is a Payment operator licensed by the Bangko Sentral ng Pilipinas
  </div> -->
  <!-- Copyright -->
<!-- </footer> -->
<!-- Footer -->

