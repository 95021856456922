import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { AccountServiceService } from 'src/app/services/account-service.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-account-reset-pincode',
  templateUrl: './account-reset-pincode.component.html',
  styleUrls: ['./account-reset-pincode.component.less']
})
export class AccountResetPincodeComponent implements OnInit {
  public loader: any = false;

  private token: string;

  constructor(private route: ActivatedRoute,
    private accountService: AccountServiceService) 
  { 
    PNotifyButtons;
   }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.token = params["token"];
      this.loader = true;
      this.verifyEmail();
    });
  }

  public verifyEmail(): void {
    this.accountService.verifyEmailResetPIN(this.token).subscribe(
      (response: any) => {
        this.loader = false;

        PNotify.success({
          title: "Reset Successfull",
          text: "PIN Code successfully reset. \nPlease proceed to login and create new PIN.",
        });
        
        window.location.href = `login`;
      }
    );
  }

}
