import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { environment } from "src/environments/environment";
import { FormGroup } from "@angular/forms";
import { AnyCnameRecord } from "dns";

@Injectable({
  providedIn: "root",
})
export class TransactionService {
  private localStorageCacheService: LocalStorageCacheService;

  constructor(
    private httpClient: HttpClient,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
  }
  private httpClientNoInterceptor: HttpClient;

  private baseAPIUrl = environment.API_URL;

  public getTransaction(url: string): Observable<any> {
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${url}`, { headers: httpHeaders });
  }

  public getDebitCredit(id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Transactions/DebitCredit/${id}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getReceiptDetails(id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Transactions/ReceiptDetails/${id}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }
  

  public getDebitCreditAll(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Transactions/DebitCredit/All`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public adjustWallet(username : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Transactions/AdjustWallet/${username}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.put(`${endPointUrl}`,{}, { headers: httpHeaders });
  }

  public adjustWalletBalance(walletAjustment : FormGroup): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Transactions/AdjustWalletBalance`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(`${endPointUrl}`, walletAjustment, { headers: httpHeaders });
  }

  // tslint:disable-next-line: typedef
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  // tslint:disable-next-line: typedef
  private log(message: string) {
    //
  }

  trans = [
    {
      id: 1,
      requestID: "31WA52SDAWAD54",
      biller: "Manila Water Company",
      billerCode: "BC_MWCOM",
      accountno: "2778546",
      contactno: "09123456789",
      paidBillAmount: "565.00",
      quantity: "1",
      dateCreated: "2021-03-01 6:00 PM",
      transactBy: "Phineas",
      via: "MULTISYS",
      status: "Verified",
      isGlobal: true,
    },
    {
      id: 2,
      requestID: "5ADWSAK3JHW",
      biller: "IPhone X",
      billerCode: "AppleIPX",
      accountno: "N/A",
      contactno: "09123456789",
      paidBillAmount: "99,000.00",
      quantity: "1",
      dateCreated: "2021-03-01 6:00 PM",
      transactBy: "Ferb",
      via: "LOCAL",
      status: "Verified",
      isGlobal: false,
    },
    {
      id: 3,
      requestID: "LWW64WD5DWWA",
      biller: "P1000 Meralco KLOAD",
      billerCode: "BC_MECOPS",
      accountno: "N/A",
      contactno: "09123456789",
      paidBillAmount: "1100.00",
      quantity: "1",
      dateCreated: "2021-03-01 6:00 PM",
      transactBy: "Candice",
      via: "MULTISYS",
      status: "Verified",
      isGlobal: true,
    },
  ];

  categories = [
    {
      categoryId: 1,
      name: "cat 1",
    },
    {
      categoryId: 2,
      name: "cat 2",
    },
    {
      categoryId: 3,
      name: "cat 3",
    },
    {
      categoryId: 4,
      name: "cat 4",
    },
  ];

  rateToApply = [
    {
      rateID: 1,
      name: "PHP 5.00 ConvenienceFee",
    },
    {
      rateID: 2,
      name: "PHP 10.00 ConvenienceFee",
    },
    {
      rateID: 3,
      name: "PHP 15.00 ConvenienceFee",
    },
    {
      rateID: 4,
      name: "PHP 20.00 ConvenienceFee",
    },
    {
      rateID: 5,
      name: "PHP 25.00 ConvenienceFee",
    },
    {
      rateID: 6,
      name: "PHP 30.00 ConvenienceFee",
    },
  ];

  biller = [
    {
      billerId: 1,
      name: "BAYAD CENTER",
      parameter: "BAYAD_CENTER",
      value: {
        thumbnail: {
          title: "Bayad Center",
          logo: "../../../assets/images/partner/Bayad Partner.jpg",
          price: 0,
          details: {},
        },
        acquired: {
          title: "Bayad Center",
          logo: "../../../assets/images/partner/Bayad Partner.jpg",
          description:
            "Bayad Center is a leader in the Philippines' Outsourced Payment Collection Industry. It continues to innovate by providing a reliable bills collection solution for its corporate partners, and a convenient bills payment option for every Filipino.",
          purchase_progress: [
            {
              title: "Order Processed",
              icon: "list.svg",
              status: false,
            },
            {
              title: "Store Preparation",
              icon: "store.svg",
              status: false,
            },
            {
              title: "Order Shipped",
              icon: "box.svg",
              status: false,
            },
            {
              title: "Settting Up Inventory",
              icon: "inventory.svg",
              status: false,
            },
            {
              title: "Order Completed",
              icon: "house.svg",
              status: false,
            },
          ],
        },
        active: true,
      },
    },
    {
      billerId: 2,
      name: "EC PAY",
      parameter: "EC_PAY",
      value: {
        thumbnail: {
          title: "EC PAY",
          logo: "../../../assets/images/partner/ECPay.jpg",
          price: 0,
          details: {},
        },
        acquired: {
          title: "EC PAY",
          logo: "../../../assets/images/partner/ECPay.jpg",
          description:
            "EC PAY is a leader in the Philippines' Outsourced Payment Collection Industry. It continues to innovate by providing a reliable bills collection solution for its corporate partners, and a convenient bills payment option for every Filipino.",
          purchase_progress: [
            {
              title: "Order Processed",
              icon: "list.svg",
              status: false,
            },
            {
              title: "Store Preparation",
              icon: "store.svg",
              status: false,
            },
            {
              title: "Order Shipped",
              icon: "box.svg",
              status: false,
            },
          ],
        },
        active: true,
      },
    },
    {
      billerId: 3,
      name: "PAY MAYA",
      parameter: "PAY MAYA",
      value: {
        thumbnail: {
          title: "PAY MAYA",
          logo: "../../../assets/images/partner/Pay Maya.jpg",
          price: 0,
          details: {},
        },
        acquired: {
          title: "PAY MAYA",
          logo: "../../../assets/images/partner/Pay Maya.jpg",
          description:
            "PAY MAYA is a leader in the Philippines' Outsourced Payment Collection Industry. It continues to innovate by providing a reliable bills collection solution for its corporate partners, and a convenient bills payment option for every Filipino.",
          purchase_progress: [
            {
              title: "Order Processed",
              icon: "list.svg",
              status: false,
            },
            {
              title: "Store Preparation",
              icon: "store.svg",
              status: false,
            },
            {
              title: "Order Shipped",
              icon: "box.svg",
              status: false,
            },
            {
              title: "Settting Up Inventory",
              icon: "inventory.svg",
              status: false,
            },
            {
              title: "Order Completed",
              icon: "house.svg",
              status: false,
            },
          ],
        },
        active: true,
      },
    },
    {
      billerId: 4,
      name: "BANKO",
      parameter: "BANKO",
      value: {
        thumbnail: {
          title: "BanKo",
          logo: "../../../assets/images/partners/partners_14.png",
          price: 0,
          details: {},
        },
        acquired: {
          title: "BanKo",
          logo: "../../../assets/images/partner/Banko BPI.jpg",
          description:
            "BanKo is a leader in the Philippines' Outsourced Payment Collection Industry. It continues to innovate by providing a reliable bills collection solution for its corporate partners, and a convenient bills payment option for every Filipino.",
          purchase_progress: [
            {
              title: "Order Processed",
              icon: "list.svg",
              status: false,
            },
            {
              title: "Store Preparation",
              icon: "store.svg",
              status: false,
            },
            {
              title: "Order Shipped",
              icon: "box.svg",
              status: false,
            },
            {
              title: "Settting Up Inventory",
              icon: "inventory.svg",
              status: false,
            },
            {
              title: "Order Completed",
              icon: "house.svg",
              status: false,
            },
          ],
        },
        active: true,
      },
    },
    {
      billerId: 5,
      name: "NATIONLINK",
      parameter: "NATIONLINK",
      value: {
        thumbnail: {
          title: "NationLink",
          logo: "../../../assets/images/partner/Nation Link.jpg",
          price: 0,
          details: {},
        },
        acquired: {
          title: "NationLink",
          logo: "../../../assets/images/partner/Nation Link.jpg",
          description:
            "NationLink is a leader in the Philippines' Outsourced Payment Collection Industry. It continues to innovate by providing a reliable bills collection solution for its corporate partners, and a convenient bills payment option for every Filipino.",
          purchase_progress: [
            {
              title: "Order<br />Processed",
              icon: "list.svg",
              status: false,
            },
            {
              title: "Store<br />Preparation",
              icon: "store.svg",
              status: false,
            },
            {
              title: "Order<br />Shipped",
              icon: "box.svg",
              status: false,
            },
            {
              title: "Settting Up<br />Inventory",
              icon: "inventory.svg",
              status: false,
            },
            {
              title: "Order<br />Completed",
              icon: "house.svg",
              status: false,
            },
          ],
        },
        active: true,
      },
    },
    {
      billerId: 6,
      name: "CHERRY PREPPAID",
      parameter: "CHERRY PREPAID",
      value: {
        thumbnail: {
          title: "Cherry Prepaid",
          logo: "../../../assets/images/partner/Cherry Prepaid.jpg",
          price: 0,
          details: {},
        },
        acquired: {
          title: "Cherry Prepaid",
          logo: "../../../assets/images/partner/Cherry Prepaid.jpg",
          description:
            "Cherry Prepaid is a leader in the Philippines' Outsourced Payment Collection Industry. It continues to innovate by providing a reliable bills collection solution for its corporate partners, and a convenient bills payment option for every Filipino.",
          purchase_progress: [
            {
              title: "Order<br />Processed",
              icon: "list.svg",
              status: false,
            },
            {
              title: "Store<br />Preparation",
              icon: "store.svg",
              status: false,
            },
            {
              title: "Order<br />Shipped",
              icon: "box.svg",
              status: false,
            },
            {
              title: "Settting Up<br />Inventory",
              icon: "inventory.svg",
              status: false,
            },
            {
              title: "Order<br />Completed",
              icon: "house.svg",
              status: false,
            },
          ],
        },
        active: true,
      },
    },
    {
      billerId: 7,
      name: "CTI",
      parameter: "CTI",
      value: {
        thumbnail: {
          title: "cti",
          logo: "../../../assets/images/partner/CTI.jpg",
          price: 0,
          details: {},
        },
        acquired: {
          title: "NationLink",
          logo: "../../../assets/images/partner/CTI.jpg",
          description:
            "CTI is a leader in the Philippines' Outsourced Payment Collection Industry. It continues to innovate by providing a reliable bills collection solution for its corporate partners, and a convenient bills payment option for every Filipino.",
          purchase_progress: [
            {
              title: "Order<br />Processed",
              icon: "list.svg",
              status: false,
            },
            {
              title: "Store<br />Preparation",
              icon: "store.svg",
              status: false,
            },
            {
              title: "Order<br />Shipped",
              icon: "box.svg",
              status: false,
            },
            {
              title: "Settting Up<br />Inventory",
              icon: "inventory.svg",
              status: false,
            },
            {
              title: "Order<br />Completed",
              icon: "house.svg",
              status: false,
            },
          ],
        },
        active: true,
      },
    },
    {
      billerId: 8,
      name: "EASY DEBIT",
      parameter: "EASY DEBIT",
      value: {
        thumbnail: {
          title: "Easy Debit",
          logo: "../../../assets/images/partner/Easy Debit.jpg",
          price: 0,
          details: {},
        },
        acquired: {
          title: "Easy Debit",
          logo: "../../../assets/images/partner/Easy Debit.jpg",
          description:
            "Easy Debit is a leader in the Philippines' Outsourced Payment Collection Industry. It continues to innovate by providing a reliable bills collection solution for its corporate partners, and a convenient bills payment option for every Filipino.",
          purchase_progress: [
            {
              title: "Order<br />Processed",
              icon: "list.svg",
              status: false,
            },
            {
              title: "Store<br />Preparation",
              icon: "store.svg",
              status: false,
            },
            {
              title: "Order<br />Shipped",
              icon: "box.svg",
              status: false,
            },
            {
              title: "Settting Up<br />Inventory",
              icon: "inventory.svg",
              status: false,
            },
            {
              title: "Order<br />Completed",
              icon: "house.svg",
              status: false,
            },
          ],
        },
        active: true,
      },
    },
    {
      billerId: 9,
      name: "GCASH",
      parameter: "GCASH",
      value: {
        thumbnail: {
          title: "Gcash",
          logo: "../../../assets/images/partner/Gcash.jpg",
          price: 0,
          details: {},
        },
        acquired: {
          title: "Gcash",
          logo: "../../../assets/images/partner/Gcash.jpg",
          description:
            "Gcash is a leader in the Philippines' Outsourced Payment Collection Industry. It continues to innovate by providing a reliable bills collection solution for its corporate partners, and a convenient bills payment option for every Filipino.",
          purchase_progress: [
            {
              title: "Order<br />Processed",
              icon: "list.svg",
              status: false,
            },
            {
              title: "Store<br />Preparation",
              icon: "store.svg",
              status: false,
            },
            {
              title: "Order<br />Shipped",
              icon: "box.svg",
              status: false,
            },
            {
              title: "Settting Up<br />Inventory",
              icon: "inventory.svg",
              status: false,
            },
            {
              title: "Order<br />Completed",
              icon: "house.svg",
              status: false,
            },
          ],
        },
        active: true,
      },
    },
    {
      billerId: 10,
      name: "IBAYAD",
      parameter: "IBAYAD",
      value: {
        thumbnail: {
          title: "IBayad",
          logo: "../../../assets/images/partner/iBayad.jpg",
          price: 0,
          details: {},
        },
        acquired: {
          title: "IBayad",
          logo: "../../../assets/images/partner/iBayad.jpg",
          description:
            "IBayad is a leader in the Philippines' Outsourced Payment Collection Industry. It continues to innovate by providing a reliable bills collection solution for its corporate partners, and a convenient bills payment option for every Filipino.",
          purchase_progress: [
            {
              title: "Order<br />Processed",
              icon: "list.svg",
              status: false,
            },
            {
              title: "Store<br />Preparation",
              icon: "store.svg",
              status: false,
            },
            {
              title: "Order<br />Shipped",
              icon: "box.svg",
              status: false,
            },
            {
              title: "Settting Up<br />Inventory",
              icon: "inventory.svg",
              status: false,
            },
            {
              title: "Order<br />Completed",
              icon: "house.svg",
              status: false,
            },
          ],
        },
        active: true,
      },
    },
    {
      billerId: 11,
      name: "MULTISYS",
      parameter: "MULTISYS",
      value: {
        thumbnail: {
          title: "MultiSys",
          logo: "../../../assets/images/partner/Multisys.jpg",
          price: 0,
          details: {},
        },
        acquired: {
          title: "MultiSys",
          logo: "../../../assets/images/partner/Multisys.jpg",
          description:
            "MultiSys is a leader in the Philippines' Outsourced Payment Collection Industry. It continues to innovate by providing a reliable bills collection solution for its corporate partners, and a convenient bills payment option for every Filipino.",
          purchase_progress: [
            {
              title: "Order<br />Processed",
              icon: "list.svg",
              status: false,
            },
            {
              title: "Store<br />Preparation",
              icon: "store.svg",
              status: false,
            },
            {
              title: "Order<br />Shipped",
              icon: "box.svg",
              status: false,
            },
            {
              title: "Settting Up<br />Inventory",
              icon: "inventory.svg",
              status: false,
            },
            {
              title: "Order<br />Completed",
              icon: "house.svg",
              status: false,
            },
          ],
        },
        active: true,
      },
    },
  ];

  items = [
    {
      id: 1,
      itemCode: "ABCDE",
      itemDescription: "AB CDE",
      price: "26.00",
    },
    {
      id: 2,
      itemCode: "ABCDE",
      itemDescription: "AB CDE",
      price: "26.00",
    },
    {
      id: 3,
      itemCode: "ABCDE",
      itemDescription: "AB CDE",
      price: "26.00",
    },
    {
      id: 4,
      itemCode: "ABCDE",
      itemDescription: "AB CDE",
      price: "26.00",
    },
    {
      id: 5,
      itemCode: "ABCDE",
      itemDescription: "AB CDE",
      price: "26.00",
    },
    {
      id: 6,
      itemCode: "ABCDE",
      itemDescription: "AB CDE",
      price: "26.00",
    },
    {
      id: 7,
      itemCode: "ABCDE",
      itemDescription: "AB CDE",
      price: "26.00",
    },
    {
      id: 8,
      itemCode: "ABCDE",
      itemDescription: "AB CDE",
      price: "26.00",
    },
    {
      id: 9,
      itemCode: "ABCDE",
      itemDescription: "AB CDE",
      price: "26.00",
    },
    {
      id: 10,
      itemCode: "ABCDE",
      itemDescription: "AB CDE",
      price: "26.00",
    },
    {
      id: 11,
      itemCode: "ABCDE",
      itemDescription: "AB CDE",
      price: "26.00",
    },
    {
      id: 12,
      itemCode: "ABCDE",
      itemDescription: "AB CDE",
      price: "26.00",
    },
    {
      id: 13,
      itemCode: "ABCDE",
      itemDescription: "AB CDE",
      price: "26.00",
    },
  ];

  getRates() {
    return this.rateToApply;
  }

  getItem() {
    return this.items;
  }

  getTrans() {
    return this.trans;
  }

  getItemID(ID: any) {
    const result = this.trans.find(ID);
    return result;
  }

  getCategories() {
    return this.categories;
  }

  getBiller() {
    return this.biller;
  }

  getBillerById(ID: any) {
    const result = this.biller.find((x) => x.billerId == ID);
    return result;
  }

  getTransById(ID: any) {
    const result = this.trans.find((x) => x.id == ID);
    return result;
  }
}
