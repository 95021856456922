import { Message } from "@angular/compiler/src/i18n/i18n_ast";
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { AuthService } from "src/data/services/authentication/auth.service";
import { CompanyUsersService } from "src/data/services/web/company-users.service";
import { RolesService } from "src/data/services/web/roles.service";
import { environment } from "src/environments/environment";
import { ModalConfirmationComponent } from "../../../modal-confirmation/modal-confirmation.component";

// PNotify
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { SalesUserService } from "src/data/services/web/sales-user.service";
import { Select2OptionData } from "ng-select2";
import { HttpClient } from "@angular/common/http";
import { FileUploadService } from "src/data/services/web/file-upload.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-sales-user-create',
  templateUrl: './sales-user-create.component.html',
  styleUrls: ['./sales-user-create.component.less']
})
export class SalesUserCreateComponent implements OnInit {

  @Output("closeModal") closeModal = new EventEmitter();
  @Input("username") username: any;

  public salesUserFormGroup: FormGroup;
  public fileFormGroup: FormGroup;
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public companyUsersService: CompanyUsersService;
  public salesUserService: SalesUserService;
  public emptyString = "";
  public bsModalRefConfirmation: BsModalRef;
  public modalService: BsModalService;
  public loaderMessage : any;

  public accessToken: string;

  public base64Photo: string | ArrayBuffer;
  public roleId: any;
  public roleLevelId: any;
  public userId: any;
  public user: any;
  public role: any;
  public meta: any;

  processCreate = false;

  constructor(
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    companyUsersService: CompanyUsersService,
    salesUserService: SalesUserService,
    modalService: BsModalService,
    private http: HttpClient,
    private modalServe: NgbModal,
    private fileUploadService : FileUploadService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.companyUsersService = companyUsersService;
    this.modalService = modalService;
    this.salesUserService = salesUserService;
    this.accessToken = this.localStorageCacheService.getStorage("access-token");

    PNotifyButtons; // Initiate PNotify buttons. Important!
  }

  photos = [];
  photosBuffer = [];
  bufferSize = 50;
  numberOfItemsFromEndBeforeFetchingMore = 10;
  loading = false;

  private fetchMore() {
      const len = this.photosBuffer.length;
      const more = this.photos.slice(len, this.bufferSize + len);
      this.loading = true;
      // using timeout here to simulate backend API delay
      setTimeout(() => {
          this.loading = false;
          this.photosBuffer = this.photosBuffer.concat(more);
      }, 200)
  }

  roles: any;
  ngOnInit(): void {
    this.initRoles();
    this.initFilesFormGroup();
    this.initGender();
    this.initRegsType();
    this.initPlacement();
    //this.initSalesUsers(this.pageNumber, this.pageSize);  

    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    this.route.params.subscribe((params) => {
      const id = decodeURI(params.id || "");
      this.rolesService.getRoles(4).then((roles) => {
        this.roles = roles;
        if (id) {
          this.userId = this.cryptoService.get(this.data.authentication.privateKey, id);
          this.getUser(this.userId);
        } else {
          var meta = [];
          this.initCompanyUserFormGroup(meta, 0);
        }
      });
    });
  }

  initFilesFormGroup(): void {
    this.fileFormGroup = new FormGroup({
      pop: new FormControl(this.emptyString)
    });
  }

  isActivated: any = 0;
  getUser(id: any): void {
    this.salesUserService.getSalesUserActivation(id).subscribe((result) => {
      this.user = result.data;
      this.hasMeta = true;
      const meta = JSON.parse(result.data.meta);
      this.isActivated = result.data.isActivated;
      this.getSponsorById(meta.SponsorId);
      this.getUplineById(meta.UplineId);
      setTimeout(() => {
        this.initCompanyUserFormGroup(meta, result.data.id);
      }, 700);
    });
  }


  initCompanyUserFormGroup(meta: any, id: any): void {
    console.log(meta);
    this.salesUserFormGroup = new FormGroup({
      id: new FormControl(id ? id : 0),
      username: new FormControl(meta ? meta.Username : this.emptyString, [Validators.required]),
      email: new FormControl(meta ? meta.Email : this.emptyString, [Validators.required]),
      roleId: new FormControl(3),
      firstName: new FormControl(meta ? meta.FirstName : this.emptyString, [Validators.required]),
      lastName: new FormControl(meta ? meta.LastName : this.emptyString),
      middleName: new FormControl(meta ? meta.MiddleName : this.emptyString, [Validators.required]),
      address: new FormControl(meta ? meta.Address : this.emptyString, [Validators.required]),
      contactNumber: new FormControl(meta ? meta.ContactNumber : this.emptyString, [Validators.required]),
      birthDate: new FormControl(meta ? meta.BirthDate : this.emptyString, [Validators.required]),
      gender: new FormControl(meta ? meta.Gender : this.emptyString, [Validators.required]),
      membersId: new FormControl(meta ? meta.MembersId : this.emptyString),
      sponsorId: new FormControl(meta ? meta.SponsorId : null, [Validators.required]),
      uplineId: new FormControl(meta ? meta.UplineId : null, [Validators.required]),
      placement: new FormControl(meta ? meta.Placement : this.emptyString, [Validators.required]),
      registrationType: new FormControl(meta ? meta.RegistrationType : this.emptyString, [Validators.required]),
      employeeId: new FormControl(meta ? meta.EmployeeId : this.emptyString),
      bankName: new FormControl(meta ? meta.BankName : this.emptyString, [Validators.required]),
      bankAccountNumber: new FormControl(meta ? meta.BankAccountNumber : this.emptyString, [Validators.required]),
      bankAccountName: new FormControl(meta ? meta.BankAccountName : this.emptyString, [Validators.required]),
      pop: new FormControl(meta ? meta.Pop : this.emptyString),
      status: new FormControl(true),
      redirect: new FormControl(environment.EMAIL_VERIFICATION_REDIRECT)
    });

    // if(meta){
    //   this.hasSponsor = true;
    //   this.hasUpline = true;
    // }
  }

  async getSponsorById(id: any){
    await this.salesUserService.getSalesUserById(id).subscribe((result) => { 
      this.sponsor = result.data;
    });
  }

  async getUplineById(id: any){
    await this.salesUserService.getSalesUserById(id).subscribe((result) => { 
      this.upline = result.data;
    });
  }

  backTolist(){
    if(this.roleLevelId == 4){
      this.router.navigate(["sales", "sales-activation-list"]);
    } else {
      this.router.navigate(["manager", "sales-activation-list"]);
    }
    
  }

  editActivation(){
    this.hasMeta = false;
  }

  actionEventDraftCompanyUser() {
    debugger
    this.processCreate = true;

    if (this.salesUserFormGroup.invalid || this.salesUserFormGroup.controls.birthDate.value == ""
        || this.salesUserFormGroup.controls.address.value == "" || this.salesUserFormGroup.controls.contactNumber.value == ""
        || this.salesUserFormGroup.controls.gender.value == "") {

      PNotify.error({
        title: "Activation Form",
        text: "Error in saving. Please fill all fields.",
      });
      
      this.processCreate = false;
      return;
    }

    if(this.salesUserFormGroup.controls.bankName.value == "" || this.salesUserFormGroup.controls.bankAccountNumber.value == "" || this.salesUserFormGroup.controls.bankAccountName.value == "" ){
      PNotify.error({
        title: "Activation Form",
        text: "Bank Details is requied. Please fill all fields.",
      });
      
      this.processCreate = false;
      return;
    }

    if(this.salesUserFormGroup.controls.sponsorId.value == ""){
      PNotify.error({
        title: "Activation Form",
        text: "Sponsor is requied. Please fill all fields.",
      });
      
      this.processCreate = false;
      return;
    }

    if(this.salesUserFormGroup.controls.uplineId.value == ""){
      PNotify.error({
        title: "Activation Form",
        text: "Upline is requied. Please fill all fields.",
      });
      
      this.processCreate = false;
      return;
    }

    if(this.salesUserFormGroup.controls.placement.value == ""){
      PNotify.error({
        title: "Activation Form",
        text: "Placement is requied. Please fill all fields.",
      });
      
      this.processCreate = false;
      return;
    }


    this.salesUserService.createDraftSalesUserInitial(this.salesUserFormGroup.value).subscribe(
      (result) => {
        if(this.roleLevelId == 4){
          this.router.navigate(["sales", "sales-activation-list"]);
        } else {
          this.router.navigate(["manager", "sales-activation-list"]);
        }

        PNotify.success({
          title: "Success",
          text: "The account has been stored.",
        });
        this.processCreate = false;
      },
      (error) => {
       
        if (error.status === 400) {
         
          const obj = error.error.errors;
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              let str: string = key;
              str = str[0].toLowerCase() + str.slice(1);
              this.salesUserFormGroup.controls[str].setErrors({
                required: Validators.required,
                apiMessage: obj[key],
              });
            }
          }
          this.processCreate = false;

          // PNotify.notice({
          //   title: "Warning",
          //   text: "Could not store account with errors.",
          // });
        } else {
          // Other error
          this.processCreate = false;

          PNotify.error({
            title: "Error",
            text: "Something went wrong.",
          });
        }
      }
    );
  }

  async submitChanges(){
    var fileCount = this.fileFormGroup.controls.length;
    var fileCounter = 1;
    for (const field in this.fileFormGroup.controls) { 
      debugger
      if(this.fileFormGroup.controls[field].value){
        var file = {
          fileName : field,
          managerUserId : 0,
          photoFile : this.fileFormGroup.controls[field].value,
          purpose : "File Manager",
          username : this.username
        }
        await this.fileUploadService.FileUploadOutSingle(file).then(data =>{ 
          debugger
          this.salesUserFormGroup.controls.pop.setValue(data);
          this.loaderMessage = `Uploading ${fileCounter} of ${fileCount}. Please wait.`;
            fileCounter++;
        });
      }
    }
  }

  actionEventCreateCompanyUser() {
    debugger
    this.processCreate = true;

    if (this.salesUserFormGroup.invalid || this.salesUserFormGroup.controls.birthDate.value == ""
        || this.salesUserFormGroup.controls.address.value == "" || this.salesUserFormGroup.controls.contactNumber.value == ""
        || this.salesUserFormGroup.controls.gender.value == "") {

      PNotify.error({
        title: "Activation Form",
        text: "Error in saving. Please fill all fields.",
      });
      
      this.processCreate = false;
      return;
    }

    if(this.salesUserFormGroup.controls.bankName.value == "" || this.salesUserFormGroup.controls.bankAccountNumber.value == "" || this.salesUserFormGroup.controls.bankAccountName.value == "" ){
      PNotify.error({
        title: "Activation Form",
        text: "Bank Details is requied. Please fill all fields.",
      });
      
      this.processCreate = false;
      return;
    }

    if(this.salesUserFormGroup.controls.sponsorId.value == ""){
      PNotify.error({
        title: "Activation Form",
        text: "Sponsor is requied. Please fill all fields.",
      });
      
      this.processCreate = false;
      return;
    }

    if(this.salesUserFormGroup.controls.uplineId.value == ""){
      PNotify.error({
        title: "Activation Form",
        text: "Upline is requied. Please fill all fields.",
      });
      
      this.processCreate = false;
      return;
    }

    if(this.salesUserFormGroup.controls.placement.value == ""){
      PNotify.error({
        title: "Activation Form",
        text: "Placement is requied. Please fill all fields.",
      });
      
      this.processCreate = false;
      return;
    }

    this.salesUserService.createSalesUserInitial(this.salesUserFormGroup.value).subscribe(
      (result) => {

        if(this.roleLevelId == 4){
          this.router.navigate(["sales", "sales-activation-list"]);
        } else {
          this.router.navigate(["manager", "sales-activation-list"]);
        }
        

        PNotify.success({
          title: "Success",
          text: "The account has been stored.",
        });
        this.processCreate = false;
      },
      (error) => {
       
        if (error.status === 400) {
         
          const obj = error.error.errors;
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              let str: string = key;
              str = str[0].toLowerCase() + str.slice(1);
              this.salesUserFormGroup.controls[str].setErrors({
                required: Validators.required,
                apiMessage: obj[key],
              });
            }
          }
          this.processCreate = false;

          PNotify.notice({
            title: "Warning",
            text: "Could not store account with errors.",
          });
        } else {
          // Other error
          this.processCreate = false;

          PNotify.error({
            title: "Error",
            text: "Something went wrong.",
          });
        }
      }
    );
  }



  public bankNames: any;
  initRoles(): any {
    this.bankNames = [
      {
        id: "UnionBank", name: "UnionBank"
      },
      {
        id: "Others", name: "Others"
      }
    ];
  }


  public genders: any;
  public regsTypes: any;
  public placements: any;
  public salesUser: Array<Select2OptionData>;
  public uplineUser: Array<Select2OptionData>;
  public hasSponsor: boolean = false;
  public hasUpline: boolean = false;
  public hasLeft: boolean = false;
  public hasRight: boolean = false;

  public hasMeta: boolean = false;
  public sponsor: any;
  public upline: any;
  public placement: any;

  initGender(): any {
    this.genders = [
      {
        id: "Male", name: "Male"
      },
      {
        id: "Female", name: "Female"
      }
    ];
  }

  initRegsType(): any {
    this.regsTypes = [
      {
        id: "ENTERPRISE", name: "ENTERPRISE"
      }
    ];
  }

  initPlacement(): any {
    this.placements = [
      {
        id: "L", name: "Left"
      },
      {
        id: "R", name: "Right"
      }
    ];
  }

  pageNumber = 1;
  pageSize = 10;
  searchString: any = null;

  initSalesUsers(pageNumber: any, pageSize: any): any {
    this.salesUserService.getSalesUserAll(pageNumber, pageSize, this.searchString).subscribe((result) => {
      this.salesUser = result.data.map((user) => {
        return {
          id: user.userId,
          text: user.username,
        };
      });
    });
  }

  onOpen($event){
    this.initSalesUsers(this.pageNumber, this.pageSize);
  }

  onScrollToEnd() {
    this.pageNumber = this.pageNumber + 1;

    this.salesUserService.getSalesUserAll(this.pageNumber, this.pageSize, this.searchString).subscribe((result) => {
      this.salesUser = this.salesUser.concat(result.data.map((user) => {
        return {
          id: user.userId,
          text: user.username,
        };
      }));
    });
  }

  onSearchText: any = "";
  onSearch($event) {
    this.salesUser = [];
    this.onSearchText = $event.term;
    console.log($event);
  }

  onEnter(){
    this.salesUserService.getSalesUserAll(this.pageNumber, this.pageSize, this.onSearchText).subscribe((result) => {
      this.salesUser = result.data.map((user) => {
        return {
          id: user.userId,
          text: user.username,
        };
      });
    });
  }


  onSearchTextUpline: any = "";
  searchStringUpline: any = null;
  onSearchUpline($event) {
    this.uplineUser = [];
    this.onSearchTextUpline = $event.term;
  }

  events: Event[] = [];
  getValues($event) {
    this.salesUserFormGroup.controls.sponsorId.patchValue($event.id);
    this.hasSponsor = true;
    // this.salesUserService.getSalesUserAll(1, this.pageSize, null).subscribe((result) => {
    //   this.uplineUser = result.data.map((user) => {
    //     return {
    //       id: user.userId,
    //       text: user.username
    //     }
    //   });
    //   this.hasSponsor = true;
    // });
  }

  pageNumberUpline: any = 1;
  onScrollToEndUpline(){
    this.pageNumberUpline = this.pageNumberUpline + 1;

    this.salesUserService.getSalesUserAll(this.pageNumberUpline, this.pageSize, this.searchStringUpline).subscribe((result) => {
      this.uplineUser = this.uplineUser.concat(result.data.map((user) => {
        return {
          id: user.userId,
          text: user.username,
        };
      }));
    });
  }

  onEnterUpline(){
    this.salesUserService.getSalesUserAll(this.pageNumberUpline, this.pageSize, this.onSearchTextUpline).subscribe((result) => {
      this.uplineUser = result.data.map((user) => {
        return {
          id: user.userId,
          text: user.username,
        };
      });
    });
  }

  onRemoveSponsor($event) {
    this.hasSponsor = false;
    this.hasUpline = false;
    this.onSearchText = "";
    this.pageNumber = 1;
    this.pageSize = 10;
    this.searchString = null;
  }

  onRemoveUpline($event) {
    this.hasUpline = false;
    this.hasLeft = false;
    this.hasRight = false;

    this.onSearchTextUpline = "";
    this.pageNumberUpline = 1;
    this.searchStringUpline = null;
  }

  getPlacements($event){
    this.salesUserFormGroup.controls.uplineId.patchValue($event.id);
    this.hasLeft = false;
    this.hasRight = false;

    this.salesUserService.getSalesUserLeft($event.id).subscribe((result) => {
      if(result.data != null){
        this.hasLeft = true;
      }
      this.hasUpline = true;
    });

    this.salesUserService.getSalesUserRight($event.id).subscribe((result) => {
      if(result.data != null){
        this.hasRight = true;
      }
      this.hasUpline = true;
    });
  }

  placementChange(placement: any){
    this.salesUserFormGroup.controls.placement.patchValue(placement);
  }

  // saveForm(){
  //   console.log(this.salesUserFormGroup); 
  // }

  public onPhotoChange(event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.base64Photo = reader.result;

        this.salesUserFormGroup.patchValue({
          photo: reader.result,
        });

        this.changeDetectorRef.markForCheck();
        this.submitChanges();
      };
    }
  }

  backToCompanyUsersList(): void {
    this.router.navigate(["acm", "company-user-list"]);
  }

  triggerFile(inputFile){
    inputFile.click();
  }

  public fileName: any = "Click here to choose...";

  public onUploadFile(event: any, formControlName : any) {
    const reader = new FileReader();
   
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.fileName = file.name;
      reader.onload = () => {
        this.base64Photo = reader.result;
        if(this.calculateImageSize(this.base64Photo)){
          this.fileFormGroup.controls[formControlName].patchValue(reader.result);
          this.fileFormGroup.controls[formControlName].markAsTouched();
          this.changeDetectorRef.markForCheck();
          this.submitChanges();
          PNotify.info({
            title: "Migration Form",
            text: "You need to save the changes before the image reflects on view file.",
          });
        }else{
          PNotify.error({
            title: "Migration Form",
            text: "File is too big. Must be less than or equal to 2mb.",
          });
        }
      };
    }
  }

  calculateImageSize(base64String) : boolean {
    let padding;
    let inBytes;
    let base64StringLength;
    if (base64String.endsWith('==')) { padding = 2; }
    else if (base64String.endsWith('=')) { padding = 1; }
    else { padding = 0; }

    base64StringLength = base64String.length;
    console.log(base64StringLength);
    inBytes = (base64StringLength / 4) * 3 - padding;
    return inBytes < 2000000;
  }

  public imagePreview: any;
  public uploadModal = null;
  fileType : any = "IMAGE";

  async preview(content: any,id : any){
    await this.fileUploadService.GetFileOutSingle(id).then(data =>{
        if(data.photoFormat == 'data:application/pdf;base64'){
          this.imagePreview = data.photo;
          this.fileType = "PDF";
        }else{
          this.imagePreview = `${data.photoFormat},${data.photo}`;
          this.fileType = "IMAGE";
        }
      this.uploadModal = this.modalServe.open(content, { centered: true,size:'xl', backdrop: 'static'});
    });

  }


  closePdf(){
    this.imagePreview = null;
  }


  getFileType(data){
    var array = data.split(',');
    return array[0].replace('data:','').replace(';base64','');
  }

  getBase64Type(data){
    var array = data.split(',');
    return array[1];
  }
}
