import { Directive, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[no-special-char]",
})
export class NoSpecialCharDirective {
  @Input("no-special-char") activateNoSpecialChar: boolean;

  private regex: RegExp;

  constructor() {
    this.regex = new RegExp(/[~`!*\"\'@#$%^&()._={}[\]:;,<>+\/?-]/g);
  }

  @HostListener("paste", ["$event"])
  public onPaste(clipboardEvent: ClipboardEvent): ClipboardEvent {
    if (!this.activateNoSpecialChar) {
      return clipboardEvent;
    }

    if (clipboardEvent.clipboardData.getData("text").match(this.regex) == null) {
      return clipboardEvent;
    }

    clipboardEvent.preventDefault();
  }

  @HostListener("keydown", ["$event"])
  public onKeyDown(keyboardEvent: KeyboardEvent): KeyboardEvent {
    if (!this.activateNoSpecialChar) {
      return keyboardEvent;
    }

    if (keyboardEvent.ctrlKey || keyboardEvent.metaKey) {
      if (new Array<string>("a", "c", "v").includes(keyboardEvent.key)) {
        return keyboardEvent; // Allow Ctrl+A, Ctrl+C, Ctrl+V
      }
    }

    if (keyboardEvent.shiftKey || keyboardEvent.metaKey) {
      if (new Array<string>("Home", "End").includes(keyboardEvent.key)) {
        return keyboardEvent; // Allow Shft+Home, Shft+End
      }
    }

    const keyWhiteList: Array<string> = new Array<string>(
      "Tab",
      "Home",
      "End",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight"
    );

    if (keyWhiteList.includes(keyboardEvent.key)) {
      return keyboardEvent;
    }

    if (keyboardEvent.key.match(this.regex) == null) {
      return keyboardEvent;
    }

    keyboardEvent.preventDefault();
  }
}
