<div
  style="background-color: #ffffffff"
  class="pr-5 pl-5 mt-2 mb-0 pl-0 w-100 d-flex justify-content-center align-items-center"
>
  <!-- <img width="130px" src="../../../../assets/vips2/8.png" alt="Go-VIP-Logo-Logo-Small" /> -->
</div>
<div
  style="background-color: #ffffffff"
  class="pr-5 pl-5 mt-0 pt-0 w-100 d-flex justify-content-center align-items-center"
>
  <img width="250px" src="../../../../assets/icons/Contact us-pana (1).png" alt="Go-VIP-Logo-Logo-Small" />
</div>
<div style="background-color: #ffffffff" class="pl-5 pr-5 w-100 d-flex justify-content-center align-items-center">
  <h4 style="max-width: 340px">Need assistance? Talk to our Live Agents!</h4>
</div>
<div style="background-color: #ffffffff" class="pl-5 pr-5 pt-4 w-100 d-flex justify-content-center align-items-center">
  <ol style="font-size: 12px; font-weight: bold">
    <li>Tap the Live Agent bubble to raise your concern</li>
    <li>Click End Chat button after concern has been resolved</li>
    <li>Leave a rating and comment</li>
  </ol>
</div>

<div id="chatButton"></div>
