<table
  id="datatable"
  class="table table-theme table-row v-middle dataTable no-footer table-hover"
  role="grid"
  aria-describedby="datatable_info"
>
  <thead>
    <tr>
      <th data-sortable="true" data-field="Product" style="width: 20px">Date</th>
      <th data-sortable="true" data-field="Quantity">
        <div>Biller Details</div>
      </th>
      <th data-sortable="true" data-field="Price">
        <div>Paid Bill Amount</div>
      </th>
      <th data-sortable="true" data-field="Subtotal">
        <div>Transact By</div>
      </th>
      <th data-sortable="true" data-field="Status">
        <div>Via</div>
      </th>
      <th data-sortable="true" data-field="Status">
        <div>Status</div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let ii of items" style="cursor: pointer" class="odd" role="row">
      <td style="width: 20px; padding-bottom: 2px">
        <p class="text-muted mb-0" style="font-size: 8px">Mar</p>
        <p class="mb-0 mt-0 font-weight-bold" style="font-size: 12px">01</p>
        <p class="text-muted mt-0" style="font-size: 8px">2021</p>
      </td>
      <td style="padding-bottom: 2px">
        <!-- <small>{{ ii.product }}</small> -->
        <p class="mb-0" style="font-size: 12px">
          <span class="font-weight-bold"> {{ ii.biller }} </span>
          ( <span class="font-highlighted"> {{ ii.billerCode }} </span> )
        </p>
        <p class="text-muted mt-0 mb-0" style="font-size: 10px">
          Request ID:<span class="font-highlighted"> {{ ii.requestID }} </span>
        </p>
        <p class="text-muted mt-0" style="font-size: 10px">
          Account No:<span class="font-highlighted"> {{ ii.accountno }} </span>
        </p>
      </td>

      <td style="padding-bottom: 2px">
        <p class="text-muted mt-0" style="font-size: 12px">
          <span class="font-highlighted"> {{ ii.paidBillAmount }} </span>
        </p>
      </td>

      <td style="padding-bottom: 2px">
        <p class="text-muted mt-0" style="font-size: 12px">
          <span class="font-highlighted"> {{ ii.transactBy }} </span>
        </p>
      </td>

      <td style="padding-bottom: 2px">
        <p class="text-muted mt-0" style="font-size: 12px">
          <span class="font-highlighted"> {{ ii.via }} </span>
        </p>
      </td>

      <td style="padding-bottom: 2px">
        <p class="text-muted mt-0" style="font-size: 12px">
          <a href="#"
            ><span class="badge badge-success text-uppercase"><i class="fa fa-check"></i></span
          ></a>
        </p>
      </td>
    </tr>
  </tbody>
</table>
