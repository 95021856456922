import { Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TransactionService } from "../../../data/services/web/transaction.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DatePipe, DecimalPipe, PlatformLocation } from "@angular/common";
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { PosService } from "src/data/services/web/pos.service";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-pos-transaction-public-view',
  templateUrl: './pos-transaction-public-view.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./pos-transaction-public-view.component.less']
})
export class PosTransactionPublicViewComponent implements OnInit {
  public SelectBillerFormGroup: FormGroup;
  public posTransactionFormGroup: FormGroup;
  public oldPosTransactionFormGroup: FormGroup;
  public NewTransactionFormGroup: FormGroup;
  @ViewChild('containerQRDiv') containerQRDiv: ElementRef;

  public activeBillsApplications: any = [];
  public activeAirtimeApplications: any = [];
  public activeRemitanceApplications: any = [];
  public activeOtherApplications: any = [];

  public emptyString = "";
  wallet : any;
  currentDate : any;
  selectedApplication : any;
  transactionStatus : boolean = false;
  loader : boolean = false;
  pos : any;
  isOldTransaction : boolean = false;
  public username : any;
  public receipt: any;
  public isprinting = true;
  public isA4 = new FormControl();
  public photo: any;

  trans;
  categories;
  billers;
  items;
  fees;
  bill;

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = 'https://www.techiediaries.com/';

  
  constructor(service: TransactionService, 
    private posService: PosService,
    private modalService: NgbModal, 
    private platformLocation: PlatformLocation ,
    private router: Router,
    private route: ActivatedRoute,
    public datepipe: DatePipe,
    private _decimalPipe: DecimalPipe,
    ) {
    this.items = service.getItem();
    this.categories = service.getCategories();
    this.billers = service.getBiller();
    this.trans = service.getTrans();
    this.fees = service.getRates();
    this.bill = service.getTransById(1);
    PNotifyButtons;
  }
  elem:any;
  id: any;
  ngOnInit(): void {
  
    this.id = this.route.snapshot.params.id;
    this.elem = document.documentElement;
    this.loader = true;
    this.viewTrackerTransaction();
  }

  
 //#region API Integrated
 openFullscreen() {
  if (this.elem.requestFullscreen) {
    this.elem.requestFullscreen();
  } else if (this.elem.mozRequestFullScreen) {
    /* Firefox */
    this.elem.mozRequestFullScreen();
  } else if (this.elem.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    this.elem.webkitRequestFullscreen();
  } else if (this.elem.msRequestFullscreen) {
    /* IE/Edge */
    this.elem.msRequestFullscreen();
  }`]`
}

 


viewTrackerTransaction(){
  this.loader = true;
  this.posService.getTrackerTransaction(this.id).subscribe((pos) => {
    this.pos = pos;
    this.value = `${environment.WEB_URL}${this.pos.id}`;
    this.isOldTransaction = true;
    this.loader = false;
    this.modalService.dismissAll();
  },
  (error) => {
   
    if(error.status === 404){
      PNotify.notice({
        title: "Warning",
        text: "You've already reach the last record.",
      });
      this.modalService.dismissAll();
      this.loader = false;
    }else {
      // Other error
    this.loader = false;

      PNotify.error({
        title: "Error",
        text: "Something went wrong.",
      });
    }
  });
}

 //#endregion
 


  checkProgressStatus(application: any) {
    let status = true;
    application.value.acquired.purchase_progress.forEach((item) => {
      if (item.status == false) {
        status = false;
      }
    });
    return status;
  }

  getTotalByQuantity(amount:any, quantity: any){
    return amount* quantity;
  }

  pad(num:number, size:number): string {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}
}