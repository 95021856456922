<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex">
  <!-- ############ Main START-->
  <div class="pl-3 pr-3">
    <div id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="--spam">JuanPay | <span class="color-blue fonr-weight-bold">Store</span></h2>
          <!-- <small class="--desc">GoVIPCenter list of Stock Item</small> -->
        </div>
        <div class="flex"></div>
      </div>
    </div>
    <div id="page-content">
      <div class="card p-2">
        <!-- <div class="card mb-2 p-1" style="background-color: #182038"> -->
        <!-- Filters -->

        <div
          class="search-container"
          style="display: flex; justify-content: space-between; align-items: center; padding-right: 10px"
        >
          <div style="display: flex; align-items: center">
            <input
              type="text"
              placeholder="Search..."
              style="margin-right: 5px; margin-left: 5px"
              [(ngModel)]="searchText"
              (input)="filterItems()"
            />
            <button type="submit" (click)="filterItems()">Search</button>
          </div>
          <div class="ml-2">
            <!-- <ng-select class="my-select" [items]="categories" bindLabel="text" bindValue="id" placeholder="Select Category" formControlName="categoryId"></ng-select> -->
          </div>
          <div class="flex"></div>
          <div class="mr-2">
            <button type="button" class="btn btn-md button-blue" tooltip="Go to My Cart" (click)="goToCart()">
              <i class="fa fa-cart-arrow-down fa-lg"></i>
            </button>
          </div>
        </div>

        <hr />
        <div class="mt-2">
          <div class="flex-container">
            <div
              class="flex-item mb-5"
              *ngFor="
                let item of filteredItems
                  | paginate: { itemsPerPage: 10, currentPage: p, totalItems: stockItem.totalRecords }
              "
              (click)="selectItem(itemModal, item)"
            >
              <div class="item-container">
                <img class="card-image" [src]="item.storeItemImages[0].path" alt="Image 1" />
                <p class="item-title mb-1 pb-0">{{ item.name }}</p>
                <p class="item-price mt-0 mb-0 pt-0 pb-0">₱ {{ item.price }}</p>
              </div>
            </div>
          </div>
          <ng-template [ngTemplateOutlet]="noOrdertemplate" *ngIf="filteredItems.length == 0"></ng-template>
        </div>

        <div class="row">
          <!-- <div class="col-sm-12 col-md-5">
            <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
              <div class="col-sm-12 col-md-12">
                <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                  Showing {{ (users?.pageNumber - 1) * users?.pageSize + 1 }} to
                  {{ (users?.pageNumber - 1) * users?.pageSize + users?.data?.length }} of
                  {{ users?.data?.length }} entries
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-7" style="float: right">
            <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
              <ul class="pagination" style="float: right">
                <pagination-controls (pageChange)="getManagerUsers(buildPageUrl($event, 10))"></pagination-controls>
              </ul>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<ng-template #itemModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Stock Item Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body pt-0 pb-0">
    <app-add-to-cart [stockItem]="selectedItem" (closeModal)="closeModal()"></app-add-to-cart>
  </div>
</ng-template>

<ng-template #createItemModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Create Stock Item</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body pt-0 pb-0">
    <!-- <app-stock-item-create [stockItem]="selectedItem"></app-stock-item-create> -->
  </div>
</ng-template>

<ng-template #noOrdertemplate>
  <div class="card p-2 container-card d-flex justify-content-center align-items-center" style="height: 475px">
    <div class="text-center">
      <img src="../../../../../assets/vips2/no-orders_2x.png" style="height: 120px; width: 160px" />
      <h5>No Item Found</h5>
    </div>
  </div>
</ng-template>
