
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { MultisysService } from 'src/data/services/web/multisys.service';

import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { CurrencyPipe } from '@angular/common';
import { PrintService } from 'src/data/services/web/print.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { xorBy } from 'lodash';
import { IbayadService } from 'src/data/services/web/ibayad.service';
import { ECPayService } from 'src/data/services/web/ecpay.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";


@Component({
  selector: 'app-instapay',
  templateUrl: './instapay.component.html',
  styleUrls: ['./instapay.component.less']
})
export class InstapayComponent implements OnInit {

  @ViewChild("pinModal") pinModal: TemplateRef<any>;
  @Input("application") application: any;
  billerFormGroup : FormGroup;
  formBuilder : FormBuilder;
  fileUploadFormGroup : FormGroup;
  ibayadService : IbayadService;
  public emptyString = "";
  public billers : any = [];
  public biller : any;
  public billerCode : any;
  public billerTypes : any = [];
  public billersByBillerTypes : any = [];
  public selectedBillerCode : any;

  
  public base64Photo: string | ArrayBuffer;

  public activeTab = 1;
  public loader = true;
  public fee : any;

  
  @Output("closeModal") closeModal = new EventEmitter();
  @Input("provider") provider : any;
  

  constructor( ibayadService: IbayadService, 
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    formBuilder : FormBuilder,
    private webService : WebSettingsService,
    private ecPayService : ECPayService,
    private modalBService: NgbModal,) {
    this.ibayadService = ibayadService;
    this.formBuilder = formBuilder;
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.initBillerTypeFormGroup();
    this.getBanks();
    this.initBillerFee();
    this.initFileUploadFormGroup();

  }

  initFileUploadFormGroup(): void {
    this.fileUploadFormGroup = new FormGroup({
      photoFile: new FormControl(this.emptyString),
      purpose: new FormControl(this.emptyString),
      meta: new FormControl(this.emptyString)
    });
  }

  initBillerFee() {
    this.webService.getWebSettingsByIdentifier("IBAYAD_SEND_MONEY").subscribe((result) => {
      const billerFee = result.data.filter(x=>x.parameter == this.provider)[0].value;
      this.billerFormGroup.controls.billerFee.setValue(billerFee);
    });
  }

  initBillerTypeFormGroup(): void {
    this.billerFormGroup = new FormGroup({
      billerCode: new FormControl(this.emptyString, [Validators.required]),
      billerFee: new FormControl(this.emptyString, [Validators.required]),
      amount: new FormControl(this.emptyString, [Validators.required]),
      accountNumber: new FormControl(this.emptyString, [Validators.required]),
      accountName: new FormControl(this.emptyString, [Validators.required]),
      recipientMobileNumber: new FormControl(this.emptyString, [Validators.required]),
      senderMobileNumber: new FormControl(this.emptyString, [Validators.required]),
      product: new FormControl(this.provider),
      bank: new FormControl(this.emptyString),
      pinCode: new FormControl(this.emptyString),
      otp: new FormControl(this.emptyString)
    });
  }


  getBanks() {
    this.ibayadService.getBanks(this.provider).subscribe((billers) => {
       this.billers = billers;
       this.loader = false;
    });
  }

  setBillerCode(billerCode : any){
    this.billerCode = billerCode;
  }
  
  paramsFormGroup() : FormGroup{
    return this.billerFormGroup.get('params') as FormGroup;
  } 


  addBillerParam(meta: any){
    if (this.selectedBillerCode != this.billerFormGroup.controls.billerCode.value){
        
        meta.forEach(param => {
          (this.billerFormGroup.get("params") as FormGroup).addControl(
            param.field,
            new FormControl("")
          );
        });
   }
    this.selectedBillerCode = this.billerFormGroup.controls.billerCode.value;
  }

  setActiveTab(){

    if(this.activeTab == 1){
      if(this.billerFormGroup.status == "INVALID"){
        PNotify.error({
          title: "Error",
          text: "All fields are required to proceed to next step.",
        });
      }
      else{
        PNotify.info({
          title: "Validation will be execute after submitting",
          text: "Accepted",
        });
        this.loader = false;
        this.code = "";
        this.activeTab = 2;
        this.codeStatus=false;
      }
    } 
  }

  setActiveTabPrev(){
    if(this.activeTab == 1){
      PNotify.error({
        title: "Error",
        text: "Action Denied.",
      });
    }else{
      this.activeTab--;
    }
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  codeStatus : boolean = false;
  code : any;
  onCodeCompleted(code: string) {
    this.code = code;
    this.codeStatus = code.length == 6;
  }

  

  processBankTransfer(otp){
    this.otpModal.close();
    this.loader = true;
      this.billerFormGroup.controls.bank.setValue(this.billers.filter(x=>x.code ==this.billerFormGroup.controls.billerCode.value)[0].name);
      this.billerFormGroup.controls.otp.setValue(otp);

      console.log(JSON.stringify(this.billerFormGroup.value));
      this.ibayadService.processBankTransfer(
        this.billerFormGroup.value)
        .subscribe((biller) => {
          PNotify.success({
            title: "Transfer Success",
            text: biller.reason,
          });
          this.closeTransaction();
          this.loader = false;
          this.otp = "";
          this.otpStatus = false;
          this.closeModal.emit();
        
      }, error => {

        if(error.error.status == 490){
          PNotify.error({
            title: "Payment Failed",
            text: error.error.reason,
          });
          this.loader = false;
          this.otp = "";
          this.otpStatus = false;
          this.openPin();
        }else{
          PNotify.error({
            title: "Payment Failed",
            text: error.error.reason,
          });
          this.loader = false;
          this.code = "";
          this.codeStatus = false;
          this.closeModal.emit();
        }

      });
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  totalAmount(fee:any,amount:any){
    try  {
    return this.makeMoney(Number(fee) + Number(amount));
    } catch(e){
      return "0.00";
    }
    }

    otpModal;
    openPin() {
     this.otpModal = this.modalBService.open(this.pinModal, { centered: true, size: "md", backdrop: "static", keyboard: false });
    }
  
    closeTransaction() {
      this.modalBService.dismissAll();
    }

    requestOtp() {
      this.loader = true;
      this.ibayadService
        .sendOTP(
          this.billerFormGroup.controls.value,
          this.billerFormGroup.controls.billerCode.value,
          this.billerFormGroup.controls.billerFee.value,
          this.provider,
          this.provider,
          this.code,
          this.base64Photo
         // ,this.makeMoney(this.billerFormGroup.controls.amountToPaid.value.replace(/,/g, ''))
        )
        .subscribe(
          (biller) => {
            PNotify.success({
              title: "PIN Validate",
              text: "Please input OTP sent to your registered number.",
            });
            this.openPin();
            this.loader = false;
       //     this.code = "";
             this.codeStatus = false;
            // this.closeModal.emit();
          },
          (error) => {
            PNotify.error({
              title: "Invalid PIN",
              text: error.error.reason,
            });
            this.loader = false;
           this.code = "";
            this.codeStatus = false;
          }
        );
    }


    otpStatus = false;
    otp:any;
    onCodeChangedOtp(otp: string) {}

    onCodeCompletedOtp(otp: string) {
      this.otp = otp;
      this.otpStatus = otp.length == 6;
      // if(this.otpStatus){
      //   this.processPayment(this.otp);
      // }
    }


    public fileName: any = "Click here..";

    public onPhotoChange(event: any) {
      const reader = new FileReader();
      
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);
        this.fileName = file.name;
        reader.onload = () => {
          this.base64Photo = reader.result;
  
          this.fileUploadFormGroup.patchValue({
            photoFile: reader.result,
          });
  
          this.changeDetectorRef.markForCheck();
        };
      }
    }

}
