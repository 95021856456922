import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import * as firebase from 'firebase';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { ManagerUsersService } from 'src/data/services/web/manager-users.service';
import { VipcoTransactionService } from 'src/data/services/web/vipco-transaction.service';
import { WalletService } from 'src/data/services/web/wallet.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5
@Component({
  selector: 'app-vips-riders',
  templateUrl: './vips-riders.component.html',
  styleUrls: ['./vips-riders.component.less']
})
export class VipsRidersComponent implements OnInit {

  public localStorageCacheService: LocalStorageCacheService;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value:any = "";
  emptyString: any = "";

  searchByFormGroup: FormGroup;

  users: any = [];
  // [
  //   { "id": 1, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 2, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 3, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 4, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 5, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 6, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 7, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true }
  // ];
  
  transactions: any = [];
  // [
  //   { "id": 1, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10001, "rewards": 50, "status": 2 },
  //   { "id": 2, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10002, "rewards": 50, "status": 2 },
  //   { "id": 3, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10003, "rewards": 50, "status": 2 },
  //   { "id": 4, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10004, "rewards": 50, "status": 2 },
  //   { "id": 5, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10005, "rewards": 50, "status": 2 },
  //   { "id": 6, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10006, "rewards": 50, "status": 2 },
  //   { "id": 7, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10007, "rewards": 50, "status": 2 }
  // ];

  constructor(localStorageCacheService: LocalStorageCacheService,
    private modalServe: NgbModal,
    private usersService: ManagerUsersService,
    private walletService: WalletService,
    private vipcoTransactionService: VipcoTransactionService,
    public webSettingsService: WebSettingsService,
    private store: AngularFirestore) { 
   
    this.localStorageCacheService = localStorageCacheService;
  }

  ngOnInit(): void {
    this.value = this.localStorageCacheService.getStorage("forQR");
    this.getManagerUser(this.localStorageCacheService.getStorage("id"));
    this.initReceipt();
    this.getWallet();
    this.getVipsUsers();
  }


  loader: boolean = false;
  userCount: any;
  managerCount: any;
  riderCount: any;

async getVipsUsers(){
  this.loader = true;
  this.vipcoTransactionService.getVipsLinkedUsers().subscribe((result) => {
    let users = (result.response_data).filter(x=>x.Usertype == "LEVEL_8" || x.Usertype == "LEVEL_9");
    
    this.getRiderCreditEnable(users);

    //this.users = users;
    this.userCount = (result.response_data).filter(x=>x.Usertype != "LEVEL_6" && x.Usertype != "LEVEL_7" && x.Usertype != "LEVEL_8" && x.Usertype != "LEVEL_9").length;
    this.managerCount = (result.response_data).filter(x=>x.Usertype == "LEVEL_6" || x.Usertype == "LEVEL_7").length;
    this.riderCount = (result.response_data).filter(x=>x.Usertype == "LEVEL_8" || x.Usertype == "LEVEL_9").length;
   
    // if (result.data.length == 0 && result.pageNumber != 1) {
    //   this.getVIPCOTransactions(result.previousPage, _sortBy, _sort);
    // } else {
    //   this.transactions = result;
    // }
  });
}

riders;
async getRiderCreditEnable(users){
  let acum = [];
  for(var x = 0; x < users.length; x+=10){
    let arr = [];
    for(var y = x; y < Math.min(x + 10, users.length); y++){
      if(y < users.length){
        //console.log(y);
        z = y;
        arr.push(users[y].MobileNo);
      }

      if(users.length == y){
        //console.log("end 1");
        x = y;
        break;
      }
    }

    if(users.length == x){
      //console.log("end 2");
      break;
    }

    var riderID = [];
      await this.store.firestore.collection("rider_registration")
      .where(firebase.default.firestore.FieldPath.documentId(), "in", arr)
      .get()
      .then(function(querySnapshot) {
        //console.log(querySnapshot);
          querySnapshot.forEach(function(doc) {
            //console.log(doc.id, " => ", doc.data());
            riderID.push({ docId : doc.id, docData: doc.data() });
          });
      })
      .catch(function(error) {
          console.log("Error getting documents: ", error);
      });
      console.log(riderID);
      // await riderID.forEach((element) => {
      //   let cred = users.find(x => x.MobileNo == element.docId);
      //   debugger
      //   if(element.docData?.application_status == true){
      //     cred.creditEnable = element.docData?.rider_credit ? element.docData?.rider_credit : false;
      //     cred.application_status = true;
      //     acum.push(cred);
      //   } else {
      //     cred.creditEnable = false;
      //     cred.application_status = false;
      //     acum.push(cred);
      //   }
      // });

      //await users.forEach((element) => {
      for(var z = x; z < Math.min(x + 10, users.length); z++){
        if(riderID.some(x => x.docId == users[z].MobileNo)){
          let cred = riderID.find(x => x.docId == users[z].MobileNo);
          if(cred.docData?.application_status == true){
            users[z].creditEnable = cred.docData?.rider_credit ? cred.docData?.rider_credit : false;
            users[z].application_status = true;
          } else {
            users[z].creditEnable = false;
            users[z].application_status = false;
          }
        } else {
          users[z].creditEnable = false;
          users[z].application_status = false;
        }
        acum.push(users[z]);
      };
  }
    console.log(acum);
    this.users = acum;
    this.loader = false;
}

getRiderCredit(mobileno){
  let cred = this.riders.filter(x => x.docId == mobileno).docData?.rider_credit;
  return cred;
}

getVipcoWallet(wallet){
  let vipcowallet = wallet.filter(x => x.ProductId == "VIPCO");
  return vipcowallet[0]?.eAmount;
}

onChange(mobileNo, e){
  //alert(mobileNo + "\n" + e.target.checked);
  this.store
    .firestore
    .collection('rider_registration')
    .doc(mobileNo)
    .set({
      rider_credit: e.target.checked
    }, {
      merge: true,
  });

  if(e.target.checked){
    PNotify.success({
      title: "Update Sucess",
      text: "Rider Credit Enabled",
    });
  } else {
    PNotify.error({
      title: "Update Sucess",
      text: "Rider Credit Disabled",
    });
  }
}

getUserType(userType){
  switch (userType) {
    case "LEVEL_0":
    case "LEVEL_1":
    case "LEVEL_2":
    case "LEVEL_3":
    case "LEVEL_4":
    case "LEVEL_5":
      return "User";
    case "LEVEL_6":
    case "LEVEL_7":
      return "Merchant";
    case "LEVEL_8":
    case "LEVEL_9":
      return "Rider";
    default:
      break;
  }

}

  getWallet(): void {
    this.walletService.getWallet().subscribe((result) => {
      this.wallet = result.wallet;
    });
  }

  wallet: any;
  user: any;
  getManagerUser(id: any): void {
    this.usersService.getManagerUser(id).subscribe((result) => {
      this.user = result.data;
    });
  }


  searchData(){

  }

  initSearchFormGroup(): void {
    this.searchByFormGroup = new FormGroup({
      showRows: new FormControl(null),
      dateRange: new FormControl(this.emptyString),
      searchString: new FormControl(null),
    });
  }

  viewUser(id: any){

  }

  receipt: any;
  initReceipt() {
    this.webSettingsService.getWebSettingsByIdentifier("GoVIPX_QR_TEMPLATE").subscribe((result) => {
      this.receipt = result.data;
    });
  }

  openQR(content: any){
    this.modalServe.open(content, { centered: true, size: "lg" });
  }

  getQR(myQRTemplate: any){
    var qrtemplate = myQRTemplate.innerHTML;

    var Newstr = (this.receipt[0].value)
      .replace("{{QR}}", qrtemplate);
    
    this.PrintReceipt(Newstr);
  }

  PrintReceipt(Newstr: any)
  {
    var mywindow = window.open('', 'PRINT', 'height=700,width=1000');
    mywindow.document.write(Newstr);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    this.modalServe.dismissAll();

    return true;
  }
}
