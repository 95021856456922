<app-loader *ngIf="loader"></app-loader>

<div *ngIf="!loader" [class.isPrinting]="printService.isPrinting" class="col-lg-12 col-md-12 col-sm-12">
  <div class="col-lg-12 col-md-12 col-sm-12">
    <div class="card">
      <div class="list list-row">
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-md d-none d-md-block color-red">
              <span class="text-dark">Request ID: </span> <strong>{{ requestId }}</strong>
            </div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <a style="color: blue" (click)="printReceipt()">Print Receipt <span class="fa fa-print"></span></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-6 col-md-6 col-sm-12 pull-right">
    <div class="card">
      <div class="list list-row">
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">
              <strong
                >{{ data?.transactByUser.cashierUser.firstName + " " + data?.transactByUser.cashierUser.lastName }}
                <br />
                {{ data?.transactByUser.email }}</strong
              >
            </div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <img
                [src]="buildUserPhoto(data?.transactByUser?.cashierUser) | authImage | async"
                height="50"
                class="avatar w-40 img-responsive"
                style="object-fit: cover"
              />
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Created Date</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.createdDate | date: "MMM dd, yyyy hh:mm:ss a" }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Completed Date</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.completedDate | date: "MMM dd, yyyy hh:mm:ss a" }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Status</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x"><app-status-table-data [status]="data?.statusId"></app-status-table-data></div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Amount</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.amountToBePaidByCustomer | number: "1.2-2" }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex" *ngIf="roleLevelId == 3">
            <br />
            <br />
            <label for="inputFile" class="custom-file-upload btn btn-primary">
              Click Here to Upload Receipt with Signature
            </label>
            <input type="file" (change)="onPhotoChange($event)" class="custom-file-input" id="inputFile" />
          </div>
          <div class="no-wrap">
            <img
              *ngIf="fileUploadFormGroup?.controls.photoFile.value"
              [src]="fileUploadFormGroup?.controls.photoFile.value | authImage | async"
              alt="VIP"
              onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
              style="width: 50px; cursor: zoom-in"
              (click)="preview(viewImageModal)"
            />
          </div>
        </div>
        <div class="list-item" data-id="19">
          <div class="flex">
            <p style="color: red">
              Important Note: Failure to upload the signed receipt nullifies the insurance purchased by the customer.
            </p>
          </div>
        </div>
        <hr class="mt-0" />
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Total Amount</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.amountToBePaidByCustomer | number: "1.2-2" }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-6 col-md-6 col-sm-12 pull-left">
    <div class="card">
      <div class="list list-row">
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">
              <strong
                >Sunlife Grepa <br />
                {{ productDetail?.value.plan }} ({{ data?.productCode }})</strong
              >
            </div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <img
                src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file29815894-3e00-428c-87da-5a55069de2bf.png"
                height="50"
                class="img-responsive"
                style="object-fit: cover"
              />
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Amount of Insurance</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.amountOfInsurance }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Policy Holder Name</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">ACM Business Solutions, Inc.</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="form-group row">
            <div class="col-sm-12">
              <label>Insurance Plan Details:</label>
            </div>
            <div class="col-sm-12">
              <div *ngFor="let meta of productDetail?.value.details">
                <strong>* {{ meta.value }}</strong>
              </div>
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Member’s Name</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.customerName }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Group Policy Number</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.groupPolicyNumber }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Birthdate</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.birthDate | date: "MMMM dd, yyyy" }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Beneficiary</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.beneficiary }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Relationship</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.relationship }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Contact Number</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.contactNumber }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Residence</div>
          </div>
          <div class="wrap">
            <div class="item-feed h-2x">{{ data?.residence }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #viewImageModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Image Viewer</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <img
        *ngIf="fileUploadFormGroup?.controls.photoFile.value"
        [src]="fileUploadFormGroup?.controls.photoFile.value | authImage | async"
        alt="VIP"
        onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
        style="width: 100%"
      />
    </div>
  </div>
</ng-template>

<div #vipLogo hidden>
  <img
    src="https://juanpaycdn.azureedge.net/juanpayassets/LOGO_logowithtagline-removebg-preview.png"
    alt="Go-VIP-Logo-Logo-Small"
    height="80px"
  />
</div>

<div #termsAndConditionQR hidden>
  <ngx-qrcode
    [elementType]="elementType"
    [errorCorrectionLevel]="correctionLevel"
    [value]="qrCodeUrl"
    cssClass="bshadow"
    [width]="120"
  ></ngx-qrcode>
</div>
