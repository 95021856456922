<div class="pl-3 pr-3 pt-1" style="background-color: rgb(247, 247, 247);">
    <div class="card card-timeline px-2 border-none">
        <ul class="bs4-order-tracking">
          <li class="step" [ngClass]="{'active': purchaseOrder.status >= 2}" [tooltip]="purchaseOrder.placedDate | date: 'MMMM dd, yyyy hh:mm:ss a'">
            <div >
              <i class="fas fa-user"></i>
            </div> Order Placed
          </li>
          <li class="step" [ngClass]="{'active': purchaseOrder.status >= 3}" [tooltip]="purchaseOrder.pickDate | date: 'MMMM dd, yyyy hh:mm:ss a'">
            <div >
              <i class="fas fa-bread-slice"></i>
            </div> In transit
          </li>
          <li class="step" [ngClass]="{'active': purchaseOrder.status >= 4}" [tooltip]="purchaseOrder.drDate | date: 'MMMM dd, yyyy hh:mm:ss a'">
            <div >
              <i class="fas fa-truck"></i>
            </div> Out for delivery
          </li>
          <li class="step" [ngClass]="{'active': purchaseOrder.status >= 5}" [tooltip]="purchaseOrder.receivedDate | date: 'MMMM dd, yyyy hh:mm:ss a'">
            <div>
              <i class="fas fa-birthday-cake"></i>
            </div> Delivered
          </li>
        </ul>
        <!-- <h5 class="text-center">
          <b>In transit</b>. The order has been shipped!
        </h5> -->
    </div>

    <div>
        <div class="card p-2 container-card">
            <div class="d-flex" >
                <div class="div-item ml-4 mr-4">
                    <h6 class="mb-0 pb-0">Image</h6>
                </div>
                <div class="div-item ml-3">
                    <div>
                        <h6 class="mb-0 pb-0">Item Description</h6>
                    </div>
                </div>
                <div class="flex"></div>

                <div class="div-item mr-5">
                    <div class="quantity">
                        <h6 class="mb-0 pb-0">Quantity</h6>
                    </div>
                </div>

                <div class="div-item ml-2 mr-5">
                    <div class="quantity">
                        <h6 class="mb-0 pb-0">Available</h6>
                    </div>
                </div>

                <div class="div-item ml-4 mr-3">
                    <h6 class="mb-0 pb-0">Amount</h6>
                </div>

            </div>
        </div>
    </div>
    
    <div>
        <div class="card p-2 container-card" *ngFor="let item of purchaseOrder.purchaseOrderItems">
            <div class="d-flex">
                <div>
                    <img class="item-img-s" [src]="item.storeItem.storeItemImages[0].path">
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <div>
                        <h5>{{ item.storeItem.name }}</h5>
                        <p class="text-muted description m-0 p-0">{{ item.storeItem.description }}</p>
                        <!-- <p class="text-muted">Quantity: <span class="color-blue font-weight-bolder">{{ item.orderQuantity }}</span></p> -->
                    </div>
                </div>
                <div class="flex"></div>
                <div class="d-flex justify-content-center align-items-center mr-5">
                    <div>
                        <p class="color-blue font-weight-bolder m-0 p-0">{{ item.orderQuantity }}</p>
                    </div>
                </div>
                <div class="d-flex justify-content-center align-items-center ml-5 mr-5">
                    <div>
                        <p class="color-blue font-weight-bolder m-0 p-0">{{ getAvailable(item.storeItem.id) }}</p>
                    </div>
                </div>
                <div class="d-flex justify-content-center align-items-center ml-5 mr-2">
                    <span class="color-red font-weight-bolder">₱ {{ makeMoney(item.storeItem.price) }}</span>
                </div>
            </div>
        </div>

    </div>
    
    <div class="row">
        <div class="col-6 pr-0">
            <div class="card mt-0 card-container p-2">
                <div class="d-flex">
                    <div>
                        <h5 class="text-muted font-weight-bolder">Delivery Address</h5>
                    </div>
                </div>
                <div>
                    <h6 class="mb-0 font-weight-bolder">{{ purchaseOrder.name }}</h6>
                    <p class="mb-0">{{ purchaseOrder.contact }}</p>
                    <p class="text-muted">{{ purchaseOrder.address }}</p>
                </div>
            </div>
        </div>

        <div class="col-6">
            <div class="card mt-0 card-container p-2">
                <h5 class="text-muted font-weight-bolder">Total Summary</h5>
                <div class="d-flex align-items-center">
                    <div class="font-weight-bold">Payment Method</div>
                    <div class="flex">

                    </div>
                    <span class="mr-2">
                        <span class="text-muted font-weight-bold color-red">
                            {{ purchaseOrder.paymentMethod == 1 ? "V-Wallet" : "Vips"}}
                        </span>
                    </span>
                </div>
                <div class="d-flex align-items-center">
                    <div class="font-weight-bold">Shipping Fee</div>
                    <div class="flex">

                    </div>
                    <span class="mr-2">
                        <span class="text-muted font-weight-bold color-red">(Not Included)</span>
                    </span>
                </div>
                <hr class="mt-0">
                <div class="d-flex align-items-center">
                    <div class="font-weight-bold">Total Payment</div>
                    <div class="flex">

                    </div>
                    <span class="font-weight-bold mr-2">
                        <span class="color-red">₱ {{ makeMoney(purchaseOrder.totalAmount) }}</span>
                    </span>
                </div>
            </div>
            
        </div>
    </div>

    <div class="card p-2 mt-0 mb-2" *ngIf="purchaseOrder.status == 3 || purchaseOrder.status == 4 || purchaseOrder.status == 5 || purchaseOrder.status == 7">
        <div class="mt-0">
            <div class="d-flex">
                <div>
                    <h5 class="text-muted font-weight-bolder p-0 m-0">Proof of Delivery</h5>
                </div>
            </div>
            <div class="p-2">
                <!-- <div class="pt-1 pb-2 mr-2" >
                    <div class="d-flex" *ngFor="let image of images">
                        <img class="item-img" [src]="image.path" [style.width.px]="imageWidth">
                    </div>
                    <div>
                        <button class="item-img btn btn-sm btn-primary text-dark" (click)="addImage(addImageModal)"><i class="fa fa-plus"></i></button>
                    </div>
                </div> -->

                <div class="pt-1 pb-2 mr-2 d-flex align-items-center" style="flex-wrap: nowrap;" [style.width.px]="imageWidth * images.length + (images.length * 5)">
                    <div *ngFor="let image of images" class="mr-2">
                        <img class="item-img" [src]="image.path" [style.width.px]="imageWidth" (click)="showImage(displayPoDTemplate, image)">
                    </div>
                    <div *ngIf="purchaseOrder.status == 3">
                        <button class="item-img btn btn-sm btn-primary text-dark" (click)="addImage(addImageModal)"><i class="fa fa-plus"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card p-2 mt-0 mb-2">
        <div class="d-flex">
            <div class="d-flex align-items-center">
                <label class="font-weight-bold mb-0">Note: <span class="text-muted">{{ purchaseOrder.note ? purchaseOrder.note : "No message attached." }}</span></label>
            </div>
            <div class="flex"></div>
            <div class="d-flex justify-content-center align-items-center">
                
                <button class="btn btn-sm btn-secondary mr-2" 
                    style="width:200px" 
                    (click)="confirmDRModal(cancelPOTemplate)" *ngIf="purchaseOrder.status != 4 && purchaseOrder.status != 5 && purchaseOrder.status != 6 && purchaseOrder.status != 7">
                    Cancel Order
                </button>

                <button class="btn btn-sm btn-dark mr-2" style="width:200px" (click)="confirmDRModal(returnPOTemplate)" *ngIf="purchaseOrder.status == 4">Return Order</button>
                
                <button class="btn btn-danger btn-sm mr-2" style="width: 200px;" *ngIf="purchaseOrder.status == 3" (click)="printReceipt()">Print Receipt</button>
                <button class="btn btn-danger btn-sm mr-2" style="width: 200px;" *ngIf="purchaseOrder.status == 4 || purchaseOrder.status == 5 || purchaseOrder.status == 7" (click)="printReceipt()">RePrint Receipt</button>

                <button class="btn btn-sm button-blue mr-2" style="width:200px" (click)="confirmDRModal(confirmDRTemplate)" *ngIf="purchaseOrder.status == 2">Proceed to Deliver</button>
                <button class="btn btn-sm button-blue mr-2" style="width:200px" (click)="confirmDRModal(confirmDRTemplate)" *ngIf="purchaseOrder.status == 3">Proceed to InTransit</button>
                
                <button class="btn btn-sm button-blue mr-2" style="width:200px" (click)="confirmDRModal(confirmDRTemplate)" *ngIf="purchaseOrder.status == 4">Complete Order</button>
            </div>
        </div>
    </div>
</div>

<ng-template #confirmDRTemplate let-modal>
    <div class="modal-header">
      <h6 class="modal-title">Confirmation</h6>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    
    <div class="modal-body pt-0 pb-0">
        <p class="mb-0" *ngIf="purchaseOrder.status == 2">Are you sure you want to <br>Proceed Order to Deliver?</p>
        <p class="mb-0" *ngIf="purchaseOrder.status == 3">Are you sure you want to <br>Proceed Order to In Transit?</p>
        <p class="mb-0" *ngIf="purchaseOrder.status == 4">Are you sure you want to <br>Complete the Order?</p>
    </div>

    <div class="modal-body">
      <button class="btn btn-sm button-blue float-right" *ngIf="purchaseOrder.status == 2" (click)="pickOrder()">Proceed</button>
      <button class="btn btn-sm button-blue float-right" *ngIf="purchaseOrder.status == 3" (click)="inTransitOrder()">Proceed</button>
      <button class="btn btn-sm button-blue float-right" *ngIf="purchaseOrder.status == 4" (click)="completeOrder()">Proceed</button>
      <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="closeModal()">
        Cancel
      </button>
    </div>
</ng-template>

<ng-template #cancelPOTemplate let-modal>
    <div class="modal-header">
      <h6 class="modal-title">Confirmation</h6>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    
    <div class="modal-body pt-0 pb-0">
        <p class="mb-0">Are you sure you want to Cancel Order?</p>
    </div>

    <div class="modal-body">
      <button class="btn btn-sm button-blue float-right" (click)="cancelOrder()">Proceed</button>
      <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="closeModal()">
        Cancel
      </button>
    </div>
</ng-template>

<ng-template #returnPOTemplate let-modal>
    <div class="modal-header">
      <h6 class="modal-title">Confirmation</h6>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    
    <div class="modal-body pt-0 pb-0">
        <p class="mb-0" *ngIf="purchaseOrder.status == 4">Are you sure you want to Return Order?</p>
    </div>

    <div class="modal-body">
      <button class="btn btn-sm button-blue float-right" (click)="returnOrder()">Proceed</button>
      <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="closeModal()">
        Cancel
      </button>
    </div>
</ng-template>

<ng-template #addImageModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Add Image</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
        <ngx-dropzone [multiple]="false" (change)="onSelect($event)" style="height:250px">
            <ngx-dropzone-label>Click to upload or drop the image here!</ngx-dropzone-label>
          
            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" 
            [removable]="true" (removed)="onRemove(f)">
              <ngx-dropzone-label></ngx-dropzone-label>
            </ngx-dropzone-image-preview>
        </ngx-dropzone>
      <!-- <app-stock-item [stockItem]="selectedItem"></app-stock-item> -->
      <hr>

        <div>
          <button class="btn btn-sm btn-primary float-right" (click)="uploadImage()">Upload Image</button>
          <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
            Cancel
          </button>
        </div>
    </div>
  </ng-template>

  <ng-template #displayPoDTemplate let-modal>
    <div class="modal-header">
      <h6 class="modal-title">Proof of Delivery</h6>
      <button type="button" class="close" aria-label="Close" (click)="closeDisplay()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body pt-0 pb-0 d-flex justify-content-center align-items-center">
        <img [src]="displayImagePoD" alt="" style="max-width: 100%; height: auto;">
    </div>

    <div class="modal-body" *ngIf="purchaseOrder.status == 3">
        <button class="btn btn-sm btn-danger float-right" (click)="removeImage()">Remove Image</button>
        <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
          Close
        </button>
    </div>
</ng-template>