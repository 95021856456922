import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-boolslider",
  templateUrl: "./boolslider.component.html",
  styleUrls: ["./boolslider.component.less"],
})
export class BoolsliderComponent implements OnInit {
  @Input() parentFormGroup: FormGroup;
  @Input() controlName: string;
  @Input() label: string;
  @Input() readonly: boolean;

  constructor() {}

  ngOnInit(): void {}
}
