import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'ts-xlsx';
import * as FileSaver from 'file-saver';
import { GovipxService } from 'src/data/services/web/govipx.service';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { Select2OptionData } from 'ng-select2';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { environment } from 'src/environments/environment';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-salary',
  templateUrl: './salary.component.html',
  styleUrls: ['./salary.component.less']
})
export class SalaryComponent implements OnInit {

  loader: any = false;
  cutoffs: any = [];
  emptyString: any = "";
  govipxService: GovipxService;

  username: any;
  public cutoffDateFormGroup: FormGroup;
  public fileUploadFormGroup: FormGroup;
  public employeeFormGroup: FormGroup;

  public localStorageCacheService: LocalStorageCacheService;
  public userIdFilterOptions: Array<Select2OptionData>;

  constructor(
    private modalServe: NgbModal,
    private webService: WebSettingsService,
    public datepipe: DatePipe,
    govipxService: GovipxService,
    localStorageCacheService: LocalStorageCacheService
  ) { 
    this.govipxService = govipxService;
    this.localStorageCacheService = localStorageCacheService;
  }

  ngOnInit(): void {
    this.getCutOffDates();
    this.initCutoffDateFormGroup();
    this.initFileUploadFormGroup();
    this.initAuthorizedUsername();
    this.initEmployeeFormGroup();
    this.getCreditingCategories();

    this.username = this.localStorageCacheService.getStorage("username");
  }

  getCreditingCategories() {
      this.userIdFilterOptions =  [ 
        { 
          id: "SALARY",
          text: "SALARY",
        },
        { 
          id: "WALLET_ADJUSTMENT",
          text: "WALLET ADJUSTMENT",
        }
        ,
        { 
          id: "PAYMENT",
          text: "PAYMENT",
        }
      ];
  }

  authorizedUsername: any;
  initAuthorizedUsername() {
    this.webService.getWebSettingsByIdentifier("AUTHORIZE_SALARY").subscribe((result) => {
      this.authorizedUsername = result.data[0].value;
    });
  }

  initFileUploadFormGroup(): void {
    this.fileUploadFormGroup = new FormGroup({
      reportFile: new FormControl(this.emptyString)
    });
  }
  
  initCutoffDateFormGroup(): void {
    this.cutoffDateFormGroup = new FormGroup({
      description: new FormControl(this.emptyString),
      startDate: new FormControl(this.emptyString),
      endDate: new FormControl(this.emptyString),
      creditingCategory: new FormControl(null),
      processingDate: new FormControl(this.emptyString)
    });
  }
  
  initEmployeeFormGroup(): void {
    this.employeeFormGroup= new FormGroup({
      employeeName: new FormControl(this.emptyString),
      companyCode: new FormControl(this.emptyString),
      govipxAccountNo: new FormControl(this.emptyString),
      amount: new FormControl(this.emptyString)
    });
  }

  getCutOffDates(){
    this.govipxService.getCutoff().subscribe((result) => {
      this.cutoffs = result;
    });
  }

  private _sortBy: any;
  private _sort: any;

  getCutOffDatesV2(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader =true;

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";

    const queryString_showRows: string = `showRows=10&`;
    const queryString = queryString_orderby + queryString_order + queryString_showRows;

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/GoVIPX/Cutoff?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/GoVIPX/Cutoff?${queryString}`;
      }
    }

    this.govipxService.getCutoffV2(endPointUrl.slice(0, -1)).subscribe((result) => {
      this.loader =false;
      if(result){
        if (result.data.length == 0 && result.pageNumber != 1) {
          this.getCutOffDatesV2(result.previousPage, _sortBy, _sort);
        } else {
          this.cutoffs = result;
          console.log(result);
        }
      }
     
    });
  }

  p:Number =1;
  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p = pageNumber;
    return `${environment.API_URL}/api/GoVIPX/Cutoff?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.cutoffs?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }

 employeeModalRef: NgbModalRef;
  addManual(content: any){
    this.employeeModalRef = this.modalServe.open(content, { centered: true, size: "md" });
  }

  proceedAddManual(){
    var listItems = [];
    var data = {
      employeeName : this.employeeFormGroup.controls.employeeName.value,
      govipxAccountNo :  this.employeeFormGroup.controls.govipxAccountNo.value,
      amount: this.employeeFormGroup.controls.amount.value,
      companyCode: this.employeeFormGroup.controls.companyCode.value
    }
    listItems.push(data);

    this.loader = true;
    this.govipxService.postSalaryEmployeeList(listItems, this.selectedCutoff.id).subscribe((result) => {
      this.selecteEmployeeList = result;
      PNotify.success({
        title: "Upload Employee",
        text: "Completed Successfully",
      });

      this.employeeFormGroup.controls.employeeName.patchValue(this.emptyString),
      this.employeeFormGroup.controls.govipxAccountNo.patchValue(this.emptyString),
      this.employeeFormGroup.controls.amount.patchValue(this.emptyString),
      this.employeeFormGroup.controls.companyCode.patchValue(this.emptyString)

      this.loader = false;
      this.employeeModalRef.close();
      
    });
  }

  selectedToRemoveCutoff: any;
  confirmRemoveCutoffModal: NgbModalRef;
  removeCutoff(content: any, cutoff: any){
    this.selectedToRemoveCutoff = cutoff;

    this.confirmRemoveCutoffModal = this.modalServe.open(content, { centered: true, size: "sm" });
  }

  processRemoveCutoff(id:any){
    this.loader = true;
    this.govipxService.putRemoveCutoff(id).subscribe((result) => {
      if(result){
        PNotify.success({
          title: "Success",
          text: "Cutoff Successfully Removed",
        });
      } else {
        PNotify.error({
          title: "Error",
          text: "Something went wrong.",
        });
      }

      this.getCutOffDates();
      this.confirmRemoveCutoffModal.close();
      this.employeeModal.close();
      this.loader = false;

    });
  }

  selectedToRemoveEmployee: any;
  confirmRemoveEmployeeModal: NgbModalRef;
  removeEmployee(content: any, employee: any){
    this.selectedToRemoveEmployee = employee;

    this.confirmRemoveEmployeeModal = this.modalServe.open(content, { centered: true, size: "sm" });
  }

  processRemoveEmployee(id:any){
    this.loader = true;
    this.govipxService.putEmployee(id, this.selectedCutoff.id).subscribe((result) => {
      if(result){
        this.selecteEmployeeList = result;

        PNotify.success({
          title: "Update Record",
          text: "Employee Successfully Removed",
        });
      } else {
        PNotify.error({
          title: "Update Record",
          text: "Something went wrong.",
        });
      }

      this.confirmRemoveEmployeeModal.close();
      this.loader = false;

    });
  }

  selectedCutoff: any;
  selecteEmployeeList: any;
  employeeModal: NgbModalRef;
  viewDetails(content: any, cutoff:any){
    this.selectedCutoff = cutoff;

    this.getSelectedEmployeeList(cutoff.id);

    this.employeeModal = this.modalServe.open(content, { centered: true, size: "xl" });
    this.loader = false;
  }

  async getSelectedEmployeeList(id){
    await this.govipxService.getSelectedEmployeeList(id).subscribe((result) => {
      this.selecteEmployeeList = result;
    });
  }

  submitSalary(){
    this.loader = true;
    //alert(this.selecteEmployeeList.filter(x => x.statusType == 'LEVEL_0' || x.statusType == '' || x.statusType == null).length);
    if(this.selecteEmployeeList.filter(x => x.statusType == 'LEVEL_0' || x.statusType == '' || x.statusType == null ).length > 0){
      PNotify.error({
        title: "Account Validation",
        text: "Not all accounts are validated.",
      });
      this.loader = false;
    } else {
          this.govipxService.putCutoff(this.selectedCutoff.id).subscribe((result) => {
        if(result){
          PNotify.success({
            title: "Upload Batch",
            text: "Completed Successfully",
          });
        } else {
          PNotify.error({
            title: "Upload Batch",
            text: "Something went wrong.",
          });
        }

        this.getCutOffDates();
        this.employeeModal.close();
        this.loader = false;
      });
    }
  }

  ProcessSalary(){
    this.loader = true;
    //alert(this.selecteEmployeeList.filter(x => x.statusType == 'LEVEL_0' || x.statusType == '' || x.statusType == null).length);
    if(this.selecteEmployeeList.filter(x => x.statusType == 'LEVEL_0' || x.statusType == '' || x.statusType == null ).length > 0){
      PNotify.error({
        title: "Account Validation",
        text: "Not all accounts are validated.",
      });
      this.loader = false;
    } else {


      this.govipxService.ProcessPayroll(this.selectedCutoff).subscribe({
        next: (result) => {
          if(result){
            this.getSelectedEmployeeList(this.selectedCutoff.id);
  
            PNotify.success({
              title: "Payroll",
              text: "Completed Successfully",
            });
          } else {
            PNotify.error({
              title: "Payroll",
              text: "Something went wrong.",
            });
          }
  
          this.getCutOffDates();
          this.loader = false;
          this.employeeModal.close();
        },
        error: (error) => {
          let errorMessage = "An error occurred during validation.";
    
          if (error.status === 403) {
            errorMessage = "Access forbidden: " + error.error;
          } else if (error.status === 400) {
            errorMessage = error.error.message;
          }else if (error.status === 404) {
            errorMessage = "Validation service not found.";
          } else if (error.status === 500) {
            errorMessage = "Internal server error.";
          }
          
    
          PNotify.error({
            title: "Account Validation",
            text: errorMessage,
          });
          this.loader = false;
        },
        complete: () => {
          this.loader = false;
        }
      });
    }
  }

  cutoff: NgbModalRef;
  openAddCutoff(content: any){
    this.cutoff = this.modalServe.open(content, { centered: true, size: "md" });
  }

  processAddCutoff(){
    this.loader = true;
    this.govipxService.postCutoff(this.cutoffDateFormGroup.value).subscribe((result) => {
      this.getCutOffDates();

      PNotify.success({
        title: "Cut Off",
        text: "Created Successfully",
      });

      this.loader = false;
      this.cutoff.close();
      
    });
  }

  listItems = [];
  
  public fileName: any = "Click here to choose file...";
  public onFileChange(event: any) {
    const target: DataTransfer = <DataTransfer>(event.target);
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      reader.readAsBinaryString(target.files[0]);
      reader.onload = (e: any) => {
        /* create workbook */
        const binarystr: string = e.target.result;
        const wb: XLSX.IWorkBook = XLSX.read(binarystr, { type: 'binary' });
  
        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.IWorkSheet = wb.Sheets[wsname];
  
        /* save data */
        var listItems = [];
        const dataXLS = XLSX.utils.sheet_to_json(ws, { header:1 }); // to get 2d array pass 2nd parameter as object {header: 1}
        for (let index = 1; index < dataXLS.length; index++) {
          const element : any = dataXLS[index];
          //if(element.length < 14){
            var data = {
              employeeName : element[0],
              govipxAccountNo :  element[1],
              amount: element[2],
              companyCode: element[3]
            }
            this.listItems.push(data);

          //}
        }
        //console.log(listItems); // Data will be logged in array format containing objects
      };
    }
  }

  public uploadModal: NgbModalRef;
  openFileUpload(content: any) : void {
    this.listItems = [];
    this.uploadModal = this.modalServe.open(content, { centered: true, size: "lg" });
  }


  submitEmployeeList(){
    this.loader = true;
    this.govipxService.postSalaryEmployeeList(this.listItems, this.selectedCutoff.id).subscribe((result) => {
      this.selecteEmployeeList = result;
      PNotify.success({
        title: "Upload Batch",
        text: "Completed Successfully",
      });

      this.loader = false;
      this.uploadModal.close();
      
    });
  }

  validateEmployeeList() {
    this.loader = true;
    this.govipxService.validateEmployee(this.selecteEmployeeList,this.selectedCutoff.id).subscribe({
      next: (result) => {
        this.selecteEmployeeList = result;
        PNotify.success({
          title: "Account Validation",
          text: "Completed Successfully",
        });
        this.loader = false;
        this.uploadModal.close();
      },
      error: (error) => {
        let errorMessage = "An error occurred during validation.";
  
        if (error.status === 403) {
          errorMessage = "Access forbidden: " + error.error;
        } else if (error.status === 400) {
          console.log(error.error);
          errorMessage = error.error.message;
          this.selecteEmployeeList = error.error.employees;
        }else if (error.status === 404) {
          errorMessage = "Validation service not found.";
        } else if (error.status === 500) {
          errorMessage = "Internal server error.";
        }
        
  
        PNotify.error({
          title: "Account Validation",
          text: errorMessage,
        });
        this.loader = false;
      },
      complete: () => {
        this.loader = false;
      }
    });
  }
  

}
