import { CurrencyPipe } from "@angular/common";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { TransactionService } from "src/data/services/web/transaction.service";
import { RolesService } from "src/data/services/web/roles.service";
import { environment } from "src/environments/environment";
import { ModalConfirmationComponent } from "../modal-confirmation/modal-confirmation.component";
import { UserService } from "src/data/services/web/user.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ExportExcelService } from "src/data/services/web/export-excel.service";


@Component({
  selector: "app-transaction-list",
  templateUrl: "./transaction-list.component.html",
  styleUrls: ["./transaction-list.component.less"],
})
export class TransactionListComponent implements OnInit {
  public transactionFilterFormGroup: FormGroup;
  public searchByUsernameFormGroup: FormGroup;
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public transactionService: TransactionService;
  public userService: UserService;
  public emptyString = "";
  public statuses: any;
  public status: any;
  public modalService: BsModalService;

  public accessToken: string;
  public newTransaction: boolean;
  public transactions: any;
  public transaction: any;
  public pageCount: any;
  public roleLevelId: any;

  public cashiersAndManagers: any;
  p: number = 1;
  public userId: any;

  public processUpdate: any = false;

  public bsModalRefConfirmation: BsModalRef;

  public isDataNotNull: boolean = false;
  dataForExcel = [];

  
  @ViewChild('viewMultisys') viewMultisys: TemplateRef<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    private currencyPipe: CurrencyPipe,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    transactionService: TransactionService,
    modalService: BsModalService,
    bsModalRefConfirmation: BsModalRef,
    userService: UserService,
    private modalBService: NgbModal,
    public ete: ExportExcelService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.transactionService = transactionService;
    this.modalService = modalService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
    this.bsModalRefConfirmation = bsModalRefConfirmation;
    this.userService = userService;
  }

  ngOnInit(): void {
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.userId = this.localStorageCacheService.getStorage("userId");
    this.getTransaction();
    this.initSearchByUsernameFormGroup();

  }

  initSearchByUsernameFormGroup(): void {
    this.searchByUsernameFormGroup = new FormGroup({
      username: new FormControl(this.emptyString),
    });
  }

  private _sortBy: any;
  private _sort: any;

  getTransaction(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_searchString: string = this.searchString ? `searchString=${this.searchString}&` : "";
    var queryString = queryString_orderby + queryString_order + queryString_searchString;

    let endPointUrl: string;
    if (url) {
    
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
       
        endPointUrl = `${environment.API_URL}/api/transactions/${this.userId ?this.userId : 0}?${queryString}`;
      } else {
        
        endPointUrl = `${environment.API_URL}/api/transactions/${this.userId ?this.userId : 0}?${queryString}`;
      }
    }

    this.transactionService.getTransaction(endPointUrl.slice(0, -1)).subscribe((result) => {
     
      if (result.data.length == 0 && result.pageNumber != 1) {
        this.getTransaction(result.previousPage, _sortBy, _sort);
      } else {
        this.transactions = result;
      }
    });
  }

  createdDate = "";
  searchString = "";

  // setPageSorting(field: any, page: any = null) {
  //   let sortBy: any;
  //   let sort: any;

  //   if (this._sort == field) {
  //     sort = field;
  //     sortBy = this._sortBy == "desc" ? "asc" : "desc";
  //   } else {
  //     sort = field;
  //     sortBy = "asc";
  //   }
  //   this._sort = sort;
  //   this._sortBy = sortBy;

  //   this.createdDate = field == "createdDate" ? "th-inner sortable both " + sortBy : "";
    
  //   this.getTransaction(
  //     `${environment.API_URL}/api/transactions/${this.userId ?this.userId : 0}?pageNumber=${page ? page : this.transactions.pageNumber}`,
  //     this._sortBy,
  //     this._sort
  //   );
  // }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.transactions?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }

  buildPageUrl(pageNumber: any, pageSize: any): any {
    
    this.p = pageNumber;
    return `${environment.API_URL}/api/transactions/${this.userId ?this.userId : 0}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }
  
  // buildPageUrl(pageNumber: any, pageSize: any): any {
  //   this.p=pageNumber;
  //   return `${environment.API_URL}/api/transactions/${this.selectedUserId ?this.selectedUserId : 0}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  // }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  statusOfAmount(money: any): boolean {
    return money < 0 ? false : true;
  }

  requestId;
  setSelectedTransaction(transaction: any) {
    this.transaction = transaction;
    const transactionType = this.transaction.transactionType;
    this.requestId = this.transaction.requestId;
    switch (transactionType){
      case "BILLS_PAYMENT_MULTISYS" : this.openTransDetail(this.viewMultisys)
    }
  }
  openTransDetail(viewModal) {
    this.modalBService.open(viewModal, { centered: true, size: "xl" });
  }

  
}
