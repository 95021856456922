<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css" />
<div class="container-fluid --home">
  <div class="row">
    <div class="col-12">
      <div class="header mt-3">
        <div class="text">
          <img
            src="../../../../../assets/vips/1.png"
            width="65px"
            height="55px"
            alt=""
          />
          Merchant Products
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 pl-5 pr-5 mt-3">
      <div class="row box mt-3">
        <div class="col-12 pt-2">
          <!-- <h5>Table for Vips Transactions</h5> -->
          <div class="row p-2">
            <div class="col-lg-5 col-md-5 col-sm-12"></div>
            <div class="col-lg-7 col-md-7 col-sm-12 pr-0">
              <div class="input-group">
                <input
                  type="search"
                  class="form-control rounded"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="search-addon"
                />
                <button type="button" class="btn btn-outline-primary">Search</button>
              </div>
            </div>
            <div class="col-12 mt-3" style="max-height: calc(100vh - 300px); overflow-y: scroll">
              <table class="table table-stripped table-hover pb-3">
                <thead style="position: sticky; top: 0; background-color: white">
                  <tr>
                    <th scope="col" class="text-center">Business Name</th>
                    <th scope="col" class="text-center">Business Address</th>
                    <th scope="col" class="text-center">Category</th>
                    <th scope="col" class="text-center">Date</th>
                    <!-- <th scope="col" class="text-center">24 Hours?</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let merchant of merchantList let i = index"
                    (click)="getDocID(merchant.merchant_id)">
                    <td class="text-left text-primary text-uppercase">{{ merchant.business_name }}</td>
                    <td class="text-center" style="white-space: nowrap;">{{ merchant.business_address_address }}</td>
                    <td class="text-center">{{ merchant.business_food_type }}</td>
                    <!-- <td class="text-muted text-center">{{ merchant?.timestamp?.toDate() | date:'MMM dd, yyyy h:mm:ss a' }}</td> -->
                    <td style="width: 20px;">
                      <!-- <p class="text-muted mb-0 text-center" style="font-size: 12px">{{ merchant?.timestamp?.toDate() | date: "MMM" }}</p>
                      <p class="mb-0 mt-0 text-highlight font-weight-bold text-center" style="font-size: 14px">
                        {{ merchant?.timestamp?.toDate() | date: "dd" }}
                      </p>
                      <p class="text-muted mt-0 m-0 text-center" style="font-size: 12px">{{ merchant?.timestamp?.toDate() | date: "yyyy" }}</p> -->
                      <p class="text-muted mt-0 m-0 text-center" style="font-size: 12px">{{ merchant?.timestamp?.toDate() | date: "h:mm:ss a" }}</p>
                    </td>
                    <!-- <td class="text-center">Yes</td> -->

                    <!-- <div class="dropdown">
                      <button
                        class="dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span id="boot-icon" class="bi bi-three-dots-vertical" style="font-size: 20px"></span>
                      </button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item text-center" href="#">View</a>
                        <a class="dropdown-item text-center" href="#">Approve</a>
                        <a class="dropdown-item text-center" href="#">Decline</a>
                      </div>
                    </div> -->
                  </tr>
                </tbody>
                <tfoot></tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
