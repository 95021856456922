<app-loader *ngIf="loader"></app-loader>
<div class="modal-body" *ngIf="activeTab!=3" >
  <form id=" form" novalidate="">
    <div id="rootwizard">
      <ul class="nav mb-3">
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="(activeTab==1)?'active':''">
            <span
              class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary">1</span>
            <div class="mt-2">
              <div class="text-muted">Paygram</div>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="(activeTab==2)?'active':''">
            <span
              class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary">2</span>
            <div class="mt-2">
              <div class="text-muted">Confirmation</div>
            </div>
          </a>
        </li>
      </ul>

      <hr class="bg-dark" />

      <div class="tab-content p-3">
        <div class="tab-pane" [ngClass]="(activeTab==1)?'active':''" id="selectBillerTab">
          <div class="row">
            <div class="col-sm-12 col-md-2 d-flex justify-content-center align-items-center">
              <img [src]="application.value.thumbnail.logo" height="100" width="100" class="img-responsive"
                style="object-fit: contain;">
            </div>
            <div class="col-sm-12 col-md-5 d-flex flex-wrap">
              <h4>{{application.value.thumbnail.title}}</h4>
              <p>
                {{application.value.acquired.description}}
              </p>
            </div>
            <div class="col-sm-12 col-md-5" >
            
            <div class="form-group" [formGroup]="serviceChargeFormGroup">
                <div class="form-group row">
                    <div class="col-sm-3">
                    <label>Select Type: </label>
                    </div>
                    <div class="col-sm-9">
                    <select class="form-control " tabindex="-1" aria-hidden="true"
                        formControlName="type">
                        <option value="">Choose...</option>
                        <option *ngFor="let type of transactionType" [value]="type.id">
                        {{ type.value }}
                        </option>
                    </select>
                    </div>
                </div>
            </div>

            <app-pin-textbox
                [parent-form-group]="serviceChargeFormGroup"
                tab-index-start="0"
                control-name="code"
                [max-length]="12"
                [upper-case]="true"
                [alpha-numeric]="true"
                [no-special-char]="true"
                read-only="false"
                label="Paygram Code"
                >
            </app-pin-textbox>
            
            <app-textbox-currency
                [parent-form-group]="serviceChargeFormGroup"
                tab-index-start="0"
                control-name="amount"
                [read-only]="roleLevelId == 2"
                read-only="false"
                number-only="false"
                label="Amount"
                placeholder="0.00"
                max-length="20"
            >
            </app-textbox-currency>

            </div>
          </div>
        </div>

        <div class="tab-pane"  [ngClass]="(activeTab==2)?'active':''" id="newTransTab">
          <div class="form-group">
            <p><strong>Paygram Confirmation</strong><br /> <small>Please make sure that the all details is
                correct.</small>
            </p>
            <div class="card">
              <div class="list list-row">
                <div class="list-item " data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">
                      Biller Name
                    </div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                        Paygram
                    </div>
                  </div>
                </div>

                <div class="list-item " data-id="19">
                    <div class="flex">
                      <div class="item-date text-muted text-sm d-none d-md-block">
                        Transaction Type
                      </div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{serviceChargeFormGroup.controls?.type?.value}}  
                      </div>
                    </div>
                </div>

                <div class="list-item " data-id="19">
                    <div class="flex">
                      <div class="item-date text-muted text-sm d-none d-md-block">
                        Paygram code
                      </div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{serviceChargeFormGroup.controls?.code?.value.toUpperCase() }}  
                      </div>
                    </div>
                </div>

                <div class="list-item " data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">
                      Transaction Amount
                    </div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{serviceChargeFormGroup.controls?.amount?.value | number: "1.2-2"}}  
                    </div>
                  </div>
                </div>

                <div *ngIf="transactionInfo" class="list-item " data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">
                      Service Charge (2%)
                    </div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{transactionInfo.fee | number: "1.2-2" }}  
                    </div>
                  </div>
                </div>
              
               <hr/>
               <div *ngIf="transactionInfo && serviceChargeFormGroup.controls.type.value == 'Redeem'" class="list-item " data-id="19">
                <div class="flex">
                  <div class="item-date text-muted text-sm d-none d-md-block">
                    Customer Payout
                  </div>
                </div>
                <div class="no-wrap">
                  <div class="item-feed h-2x">
                    {{transactionInfo.customerPayout | number: "1.2-2" }}  
                    </div>
                </div>
              </div>
              <div *ngIf="transactionInfo && serviceChargeFormGroup.controls.type.value == 'Buy'" class="list-item " data-id="19">
                <div class="flex">
                  <div class="item-date text-muted text-sm d-none d-md-block">
                    Customer Payment
                  </div>
                </div>
                <div class="no-wrap">
                  <div class="item-feed h-2x">
                    {{(transactionInfo.amount + transactionInfo.fee) | number: "1.2-2" }}  
                    </div>
                </div>
              </div>
              <div *ngIf="transactionInfo" class="list-item " data-id="19">
                <div class="flex">
                  <div class="item-date text-muted text-sm d-none d-md-block">
                    Commission
                  </div>
                </div>
                <div class="no-wrap">
                  <div class="item-feed h-2x">
                    {{transactionInfo.commission | number: "1.2-2" }}  
                    </div>
                </div>
              </div>
                <hr />
                <div class="col-sm-12 col-md-12">
                  <div class="col-sm-12 col-md-6" style="float:none;margin:auto;">
                    <div class="item-feed h-2x text-center">
                      ENTER PINCODE
                    </div>
                    <code-input [isCodeHidden]="true" [codeLength]="6" (codeChanged)="onCodeChanged($event)"
                      (codeCompleted)="onCodeCompleted($event)" [code]="code">
                    </code-input>
                  </div>
                </div>
                <br />


              </div>
            </div>
          </div>
        </div>

        <!-- previous next -->
        <div class="row py-3">
          <div class="col-6">
            <a class="btn btn-white button-previous" (click)="setActiveTabPrev()">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-arrow-left">
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
              &nbsp; Previous
            </a>
          </div>
          <div class="col-6">
            <div class="d-flex justify-content-end">
              <a *ngIf="activeTab != 2" class="btn btn-white button-next" (click)="setActiveTab()">
                Next &nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-arrow-right">
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </a>
              <a *ngIf="activeTab == 2 &&  codeStatus" appDebounceClick [debounceTime]="500" (debounceClick)="processPayment()" class="btn btn-info button-next"
                style="color: white;" >
                Process {{this.serviceChargeFormGroup.controls.type.value}} &nbsp;
                <span class="fa fa-hand-pointer"></span>
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </form>
</div>
