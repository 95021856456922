import { CurrencyPipe } from "@angular/common";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { ApplicationService } from "src/data/services/web/application.service";
import { EmitterService } from "src/data/services/web/emitter.service";
import { ManagerUsersService } from "src/data/services/web/manager-users.service";
import { RolesService } from "src/data/services/web/roles.service";
import { StoreService } from "src/data/services/web/store.service";
import { WebSettingsService } from "src/data/services/web/web-settings.service";
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { MsfService } from "src/data/services/web/msf.service";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: "app-market-place",
  templateUrl: "./market-place.component.html",
  styleUrls: ["./market-place.component.less"],
})
export class MarketPlaceComponent implements OnInit {
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public webSettingsService: WebSettingsService;
  public applicationService: ApplicationService;

  public storeService: StoreService;
  public emptyString = "";
  public roles: any;
  public role: any;
  public app: any;

  public accessToken: string;
  public newManagerUser: boolean;
  public user: any;
  public userId: any;
  public roleLevelId: any;
  public applications: any;
  public activeApplications: any = [];
  public AMCLCount: any = 0;
  public declinedApplications: any = [];
  public selectedApp: any;
  public selectedApplication: any;
  public selectedApplicationPurchased: any;
  purchaseApplicationProcessing = false;
  public maxCashiersArray: any;
  public usersService: ManagerUsersService;
  public isLazada: any;
  public msf: any;
  public msfList: any;
  storeActivationId: any;
  loader: boolean = false;

  
  @ViewChild('viewStoreHoursSettingsModal') viewStoreHoursSettingsModal: TemplateRef<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    private currencyPipe: CurrencyPipe,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    webSettingsService: WebSettingsService,
    applicationService: ApplicationService,
    private msfService: MsfService,
    private modalService: NgbModal,
    private emitter: EmitterService,
    usersService: ManagerUsersService,
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.webSettingsService = webSettingsService;
    this.applicationService = applicationService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
    PNotifyButtons;
    this.usersService = usersService;
  }
  ngOnInit(): void {
    this.initOnePager();
    this.resetApplication();
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.userId = this.localStorageCacheService.getStorage("userId");
    if(this.roleLevelId == 2){
      this.initStoreSettings(this.userId);
    }    
    this.initActiveNetworks();
  }

  hasStoreHours:any;
  ngAfterViewInit(): void {
    var storeHours = this.localStorageCacheService.getStorage("storeHours");

    if(this.roleLevelId == 2 && storeHours != null){
      this.hasStoreHours = true;
    } else if(this.roleLevelId != 2) {

    } else {
      this.openStoreHoursSettings(this.viewStoreHoursSettingsModal);
    }
  }

  storeSettingsModal: NgbModalRef;
  openStoreHoursSettings(modal) {
    this.storeSettingsModal = this.modalService.open(modal, {
      centered: true,
      size: "xl",
      backdrop: "static",
      keyboard: false,
    });
  }

  activeNetworks: any = [];
  initActiveNetworks(){
    if(this.roleLevelId == 2){
      this.msfService.getActiveNetwork().subscribe((result) => {
        if(result){
          this.activeNetworks = result;
        } else {
          this.activeNetworks = [];
        }
        //console.log(this.activeNetworks);
      });
    }
  }

  checkAddOnCredit(network: any) {
    if(this.msf?.parameter == 'C3'){
      return false;
    } else {
      if(this.activeNetworks.includes(network)){
        return false;
      } else {
        if(this.msf?.value.addons <= this.activeNetworks.length){
          return false;
        } else {
          return true;
        }
      }
    }
  }
  
  async initStoreSettings(id: any) {
    this.loader = true;
    await this.usersService.getManagerUser(id).subscribe((result) => {
      this.isLazada = result.data.managerUser.storeSetting.isLazadaEligible;
      this.storeActivationId = result.data.managerUser.storeActivation.id;
      //var xxx = this.msfList;
      this.msfList.forEach(promo => {
        if(promo.parameter == result.data.managerUser.storeActivation.msfPromo.msfCode){
          this.msf = promo;
          this.loader = false;
        }
      });
    });
  }

  resetApplication() {
    this.activeApplications = [];
    this.initMarketPlace();
  }

  activeOnepager: any;
  initOnePager() {
    this.webSettingsService.getWebSettingsByIdentifier("ONE_PAGER").subscribe((result) => {
      this.activeOnepager = result.data;
    });

    
    this.webSettingsService.getWebSettingsByIdentifier("MSF").subscribe((result) => {
      this.msfList = result.data;
    });
  }

  initMarketPlace() {
    this.webSettingsService.getWebSettingsByIdentifier("VIP_MARKETPLACE").subscribe((result) => {
      this.applications = result.data;
      this.getActiveApplication();
      this.getDeclinedApplication();
    });
  }

  generateMarketClass() {
    if (this.roleLevelId == 1) {
      return "col-sm-12 col-md-12";
    } else {
      return "col-sm-12 col-md-8";
    }
  }

  interval;
  getActiveApplication() {
    if (this.roleLevelId == 2) {
      this.applicationService.getApplications().subscribe((result) => {
        result.forEach((application) => {
          var _application =JSON.parse(application.meta);
          _application.applicationId = application.id;
          this.activeApplications.push(_application);
          if(_application.parameter == "Anti-Money Laundering Council"){
            this.AMCLCount++;
          }
        });

        this.modalService.dismissAll();
        this.emitter.reloadWallet(true);
        this.interval = setInterval(() => {
          this.purchaseApplicationProcessing = false;
          clearInterval(this.interval);
        }, 2000);
      });
    }
  }

  currentPurchasedNetworks: any = [];  
  getPurchasedNetworks(apps: any){
    
  }

  getDeclinedApplication() {
    if (this.roleLevelId == 2) {
      this.applicationService.getDeclinedApplications().subscribe((result) => {
        result.forEach((application) => {
          var _application =JSON.parse(application.meta);
          _application.applicationId = application.id;
          this.declinedApplications.push(_application);
          if(_application.parameter == "Anti-Money Laundering Council"){
            this.AMCLCount++;
          }
        });
      });
    }
  }

  checkIfPurchased(application: any, network: any) {
    if(this.activeApplications.some((x) => x.parameter == application.parameter)){
      return true;
    } else {
      if(this.activeNetworks.includes(network) || application.value?.isFree || this.msf?.parameter == '3C'){
        return false;
      } else {
        return true;
      }
    }
  }

  checkIfPurchasedV2(application: any) {
    if(this.activeApplications.some((x) => x.parameter == application)){
      return true;
    } else {
      return false;
    }
  }

  checkIfAppIsAvailable(app: any): { success: boolean; message: string } {
    let isAvailable = false;
    let message = 'App not found in the network.';
    
    // Example logic to check if the app is available in the network
    this.msf?.value.network.forEach(element => {
      if (element === app.value.network) {
        isAvailable = true;
        message = '';
      }
    });
  
    // Return both values as an object
    return { success: isAvailable, message: message };
  }

  purchaseApplication() {
    this.purchaseApplicationProcessing = true;

    this.applicationService.createApplication(this.selectedApplication).subscribe((result) => {
        const encId = this.cryptoService.set(this.data.authentication.privateKey, result.id);

        if(this.selectedApplication.parameter == "DA5 Western Union"){
          this.router.navigate(["manager", "application-da5-view", { id: encId }]);
        } else if(this.selectedApplication.parameter == "Sendah Remit" || this.selectedApplication.parameter == "Sendah Direct"){
          this.router.navigate(["manager", "application-sendah-view", { id: encId }]);
        } else {
          this.router.navigate(["manager", "application-view", { id: encId }]);
        }

        //this.router.navigate(["manager", "application-view", { id: encId }]);
        this.modalService.dismissAll();
        PNotify.success({
          title: "Success",
          text: "Purchase completed! Please wait for the JuanPay Support admin to process your order.",
        });

        this.resetApplication();
        
    }, (error)=>{
      if (error.status === 400) {
        PNotify.error({
          title: "Error",
          text: "Insufficient balance. Please add funds to purchase this service.",
        });
        this.resetApplication();
      }else{
        PNotify.error({
          title: "Error",
          text: "Internal server error. Please contact JuanPay Center Support.",
        });
        this.resetApplication();
      }
    
    });
  }

  checkProgressStatus(application: any) {
    let status = true;
    application.value.acquired.purchase_progress.forEach((item) => {
      if (item.status == false) {
        status = false;
      }
    });
    return status
      ? "<span class='badge badge-success text-uppercase'>Active</span>"
      : "<span class='badge badge-warning text-uppercase'>On Processing</span>";
  }

  checkProgressClass(status: any) {
    return status ? "step0 active" : "step0";
  }

  selectedOnepager:any;
  openContentUponPurchased(content: any, application: any): void {
    this.purchaseApplicationProcessing = false;
    clearInterval(this.interval);
    this.selectedApplication = application;
    this.selectedOnepager = this.activeOnepager.filter(x => x.parameter == application.parameter);
    this.openProgress(content);
  }
  
  openContentDonePurchased(app: any): void {
     
    this.applicationService.getApplicationByParameter(this.userId, app.parameter,app.applicationId).subscribe((result) => {
      this.app = result;

      const encId = this.cryptoService.set(this.data.authentication.privateKey, this.app.id);
      if(app.parameter == "DA5 Western Union" && app.applicationId > 8087){
        this.router.navigate(["manager", "application-da5-view", { id: encId }]);
      } else if((app.parameter == "Sendah Remit" && app.applicationId > 8514) || (app.parameter == "Sendah Direct" && app.applicationId > 8514)){
        this.router.navigate(["manager", "application-sendah-view", { id: encId }]);
      } else {
        this.router.navigate(["manager", "application-view", { id: encId }]);
      }
      
    });
    
    //content: any, application: any
    // this.selectedApplicationPurchased = application;
    // this.openProgress(content);
  }

  openProgress(content: any): void {
    this.modalService.open(content, { centered: true, size: "lg" });
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  setStyleApplication() {
    return "margin-bottom: 1px;";
  }

  adminNetworks: string[] = ['BILLS', 'AIRTIME', 'REMITTANCE', 'JUANPAY SERVICES', 'INSURANCE', 'OTHER'];
  networks: string[] = ['FREE', 'REMITTANCE', 'INSURANCE', 'OTHER'];
  // networks: any = 
  // [
  //   { "network": "FREE", "isActive": false },
  //   { "network": "REMITTANCE", "isActive": false },
  //   { "network": "INSURANCE", "isActive": false },
  //   { "network": "OTHER", "isActive": false }
  // ];

  getApplicationsByAdminNetwork(network: string) {
    return this.applications.filter(application => {
      return application.value.network === network;
    });
  }

  // Function to get applications by network and isFree condition
  getApplicationsByNetwork(network: string) {
    return this.applications.filter(application => {
      if (network === 'FREE') {
        return application.value.isFree;
      }
      return !application.value.isFree && application.value.network === network;
    });
  }

  activateNetwork(network){
    this.loader = true;
    if(network){
      var json = { storeActivationId: this.storeActivationId, network: network };
      this.msfService.updateActivatedNetwork(json).subscribe((result) => {
        debugger
        if(result.item1){
          this.initActiveNetworks();
          this.resetApplication();

          PNotify.success({
            title: "Network Activated",
            text: "Congratulation! You can now purchase all of apps under this network.",
          });

          this.confirmPurchaseModal.close();
        } else {
          PNotify.error({
            title: "Error",
            text: "Internal server error. Please contact JuanPay Center Support.",
          });

          this.confirmPurchaseModal.close();
        }
        this.loader = false;
      });
    }
  }

  confirmPurchaseModal: NgbModalRef;
  selectedNetwork: any;
  async confirmPurchaseOfNetwork(content, network){
    this.selectedNetwork = network;

    this.confirmPurchaseModal = this.modalService.open(content, {
      centered: true,
      size: "lg",
      backdrop: "static",
      keyboard: false,
    });
  }
}
