import { Message } from "@angular/compiler/src/i18n/i18n_ast";
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { AuthService } from "src/data/services/authentication/auth.service";
import { ManagerUsersService } from "src/data/services/web/manager-users.service";
import { RolesService } from "src/data/services/web/roles.service";
import { environment } from "src/environments/environment";
import { ModalConfirmationComponent } from "../modal-confirmation/modal-confirmation.component";

// PNotify
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { WebSettingsService } from "src/data/services/web/web-settings.service";
import { Select2OptionData } from "ng-select2";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";
import { FileUploadService } from "src/data/services/web/file-upload.service";
import { SalesUserService } from "src/data/services/web/sales-user.service";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

declare let LiveAgent: any;
@Component({
  selector: 'app-migration-sales-form',
  templateUrl: './migration-sales-form.component.html',
  styleUrls: ['./migration-sales-form.component.less']
})
export class MigrationSalesFormComponent implements OnInit {

    
  @Output("closeModal") closeModal = new EventEmitter();
  @ViewChild('viewLoginModal') viewLoginModal: ElementRef;
  @ViewChild('verifyModal') verifyModal: ElementRef;
  @ViewChild('enterOTPModal') enterOTPModal: ElementRef;
  @Input("username") username: any;
  
  public formBuilder: FormBuilder;
  public loaderMessage : any;
  public storeActivationFormGroup: FormGroup;
  public isStoreActivationCreated: boolean;
  public user: any;

  public loginUserFormGroup: FormGroup;
  public signOffFormGroup: FormGroup;
  public storeSettingFormGroup: FormGroup;
  public storeAddressFormGroup: FormGroup;

  public commentFormGroup: FormGroup;
  public managerUserFormGroup: FormGroup;
  public fileFormGroup: FormGroup;
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public managerUsersService: ManagerUsersService;
  public salesUsersService: SalesUserService;
  public emptyString = "";
  public roles: any;
  public bsModalRefConfirmation: BsModalRef;
  public modalService: BsModalService;
  public websettings: WebSettingsService;

  public corporateServicesSignOff:any = [];
  public financeSignOff: any = [];
  public supportSignOff: any = [];

  public comments: any = [];

  public accessToken: string;

  public base64Photo: string | ArrayBuffer;
  public base64Photo1: string | ArrayBuffer;
  public base64Photo2: string | ArrayBuffer;
  public base64Photo3: string | ArrayBuffer;

  public roleLevelId: any;
  public roleId: any;
  public acmUsername: any;

  public maxCashiersArray: any;
  public genderArray: any;
  public storeTypeOption: any;

  processCreate = false;
  loader : any = true;
  validAccount : any = false;
  public fileName: any = "Click here to choose...";

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    managerUsersService: ManagerUsersService,
    salesUsersService: SalesUserService,    
    websettings: WebSettingsService,
    modalService: BsModalService,
    private route: ActivatedRoute,
    private modalServe: NgbModal,
    formBuilder: FormBuilder,
    private datepipe : DatePipe,
    private fileUploadService : FileUploadService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.managerUsersService = managerUsersService;
    this.websettings = websettings;
    this.modalService = modalService;
    this.formBuilder = formBuilder;
    this.salesUsersService = salesUsersService;
    this.accessToken = this.localStorageCacheService.getStorage("access-token");
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    this.acmUsername = this.localStorageCacheService.getStorage("username");
    PNotifyButtons; // Initiate PNotify buttons. Important!     
  }

  viewLoginModalRef : any;
  verifyModalModalRef : any;
  enterOTPModalRef : any;
  otpReference: any;
  emailUsername: any;
  token: any;
  isDuplicateContact: any = false;
  
  ngOnInit(): void {
    //console.log("this.username",this.username);
    this.initLoginFormGroup();
    this.route.queryParams.subscribe((params) => {
      this.token = params["token"];
    });

    if(!this.username && this.token == null){
      this.loader = false;
    } else if (this.token != null) {
      this.loader = true;      
      this.salesUsersService.verifySalesUserNumber(this.token).subscribe((result) => {
        debugger
        this.numberStr = result.number;
        this.hasNumber = result.hasNumber;
        this.otpReference = result.otp;
        this.loginUserFormGroup.controls.username.patchValue(result.username);
        this.isDuplicateContact = false;
        this.loader = false;
      },
      (error) => {
        this.isDuplicateContact = true;
        PNotify.error({
          title: "Token Expired.",
          text: "Please contact JuanPay Support.",
        });
        this.loader = false;
      });
    }

  }

  ngAfterViewInit(){
    if(this.username){
      this.loadData();
      this.initCommentFormGroup();
      //this.initSignOffFormGroup();
    } else if(this.token != null){
      this.enterOTPModalRef = this.modalServe.open(this.enterOTPModal, { centered: true, size:'sm', backdrop: 'static'});
    } else {
      this.viewLoginModalRef = this.modalServe.open(this.viewLoginModal, { centered: true,size:'sm', backdrop: 'static'});
    }
  }

    // this called every time when user changed the code
    onCodeChangedOtp(otp: string) {}
  
    // this called only if user entered full code
    otpStatus = false;
    otp:any;
  
    onCodeCompletedOtp(otp: string) {
      this.otp = otp;
      this.otpStatus = otp.length == 6;

      debugger 
      if(otp == this.otpReference){
        this.loader = true;
        this.initSalesUserFormGroup();
        this.initFilesFormGroup();
        this.setGender();
      } else {
        PNotify.error({
          title: "Invalid PIN",
          text: "Please enter the correct OTP.",
        });
      }
    }



  loadData() {
    this.loader = true;
    this.initManagerUserFormGroup();
    this.initFilesFormGroup();
    this.setGender();
  }

  initCommentFormGroup(): void {
    this.commentFormGroup = new FormGroup({
      comment: new FormControl(this.emptyString)
    });
  }

  initLoginFormGroup(): void {
    this.loginUserFormGroup = new FormGroup({
      username: new FormControl(this.emptyString),
      password: new FormControl(this.emptyString)
    });
  }

  initSignOffFormGroup(): void {
    this.signOffFormGroup = new FormGroup({
      corpServButton: new FormControl(this.emptyString),
      financeSignOff: new FormControl(this.emptyString),
      supportSignOff: new FormControl(this.emptyString)
    });
  }

  triggerFile(inputFile){
    inputFile.click();
  }

  
  saveComment() {
    if(confirm(`Are you sure you want to continue?`)) {
      var data = {
        username : this.acmUsername,
        comment : this.commentFormGroup.controls.comment.value
      }
      this.managerUsersService.postSalesComment(this.username, "COMMENTS",JSON.stringify(data)).subscribe(data =>{
        this.comments = data;
        this.commentFormGroup.controls.comment.setValue(this.emptyString);
        PNotify.success({
          title: "Migration Form",
          text: "Comment posted successfully.",
        });
    });
    }
  }

  public onUploadFile(event: any, formControlName : any) {
    const reader = new FileReader();
   
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.fileName = file.name;
      reader.onload = () => {
        this.base64Photo = reader.result;
        if(this.calculateImageSize(this.base64Photo)){
          this.fileFormGroup.controls[formControlName].patchValue(reader.result);
          this.fileFormGroup.controls[formControlName].markAsTouched();
          this.changeDetectorRef.markForCheck();
          PNotify.info({
            title: "Migration Form",
            text: "You need to save the changes before the image reflects on view file.",
          });
        }else{
          PNotify.error({
                title: "Migration Form",
                text: "File is too big. Must be less than or equal to 2mb.",
              });
        }
      };
    }
  }

  calculateImageSize(base64String) : boolean {
    let padding;
    let inBytes;
    let base64StringLength;
    if (base64String.endsWith('==')) { padding = 2; }
    else if (base64String.endsWith('=')) { padding = 1; }
    else { padding = 0; }

    base64StringLength = base64String.length;
    console.log(base64StringLength);
    inBytes = (base64StringLength / 4) * 3 - padding;
    return inBytes < 2000000;
  }

  public imagePreview: any;
  public uploadModal = null;
  fileType : any = "IMAGE";

  async preview(content: any,id : any){
    await this.fileUploadService.GetFileOutSingle(id).then(data =>{
        if(data.photoFormat == 'data:application/pdf;base64'){
          this.imagePreview = data.photo;
          this.fileType = "PDF";
        }else{
          this.imagePreview = `${data.photoFormat},${data.photo}`;
          this.fileType = "IMAGE";
        }
      this.uploadModal = this.modalServe.open(content, { centered: true,size:'xl', backdrop: 'static'});
    });

  }


  closePdf(){
    this.imagePreview = null;
  }


  getFileType(data){
    var array = data.split(',');
    return array[0].replace('data:','').replace(';base64','');
  }

  getBase64Type(data){
    var array = data.split(',');
    return array[1];
  }

  submitted = false;
  get f() { return this.managerUserFormGroup.controls; }
  get fi() { return this.fileFormGroup.controls; }

  initManagerUserFormGroup(): void {
    this.managerUserFormGroup = new FormGroup({
      username: new FormControl(this.emptyString),
      email: new FormControl(this.emptyString),
      firstName: new FormControl(this.emptyString),
      lastName: new FormControl(this.emptyString),
      middleName: new FormControl(this.emptyString),
      address: new FormControl(this.emptyString,Validators.required),
      contactNumber: new FormControl(this.emptyString),
      roleId: new FormControl("Sales Director"),
      gender: new FormControl(this.emptyString,Validators.required),
      birthdate: new FormControl(this.emptyString,Validators.required),
      status: new FormControl(true),

      sponsor: new FormControl(this.emptyString),
      upline: new FormControl(this.emptyString),
      placement: new FormControl(this.emptyString),
      enterpriseActivationDate: new FormControl(this.emptyString),

      membersId : new FormControl(this.emptyString,Validators.required),
      photo: new FormControl(this.emptyString),
      employeeId: new FormControl(this.emptyString),
      redirect: new FormControl(environment.EMAIL_VERIFICATION_REDIRECT),

      bank: new FormControl(this.emptyString,Validators.required),
      bankAccount: new FormControl(this.emptyString,Validators.required),
      bankType: new FormControl(this.emptyString,Validators.required),

      validID1Id: new FormControl(this.emptyString,Validators.required),
      validID2Id: new FormControl(this.emptyString,Validators.required),

      rewards: new FormControl(this.emptyString),
      specialPoints: new FormControl(this.emptyString),
    });

    if(this.username){
      this.checkMemberDetailsACM();
    }else{
      this.checkMemberDetails();
    }
  }

  initSalesUserFormGroup(): void {
    this.managerUserFormGroup = new FormGroup({
      username: new FormControl(this.emptyString),
      email: new FormControl(this.emptyString),
      firstName: new FormControl(this.emptyString),
      lastName: new FormControl(this.emptyString),
      middleName: new FormControl(this.emptyString),
      address: new FormControl(this.emptyString),
      contactNumber: new FormControl(this.emptyString),
      roleId: new FormControl("Sales Director"),
      gender: new FormControl(this.emptyString),
      birthdate: new FormControl(this.emptyString),
      status: new FormControl(true),

      sponsor: new FormControl(this.emptyString),
      upline: new FormControl(this.emptyString),
      placement: new FormControl(this.emptyString),
      enterpriseActivationDate: new FormControl(this.emptyString),

      membersId : new FormControl(this.emptyString),
      photo: new FormControl(this.emptyString),
      employeeId: new FormControl(this.emptyString),
      redirect: new FormControl(environment.EMAIL_VERIFICATION_REDIRECT),

      bank: new FormControl(this.emptyString),
      bankAccount: new FormControl(this.emptyString),
      bankType: new FormControl(this.emptyString),

      validID1Id: new FormControl(this.emptyString),
      validID2Id: new FormControl(this.emptyString),

      rewards: new FormControl(this.emptyString),
      specialPoints: new FormControl(this.emptyString),
    });

    this.checkSalesMemberDetailsACM();
  }

  initFilesFormGroup(): void {
    this.fileFormGroup = new FormGroup({
      validID1: new FormControl(this.emptyString, Validators.required),
      validID2: new FormControl(this.emptyString , Validators.required)
    });
  }

  loadLaDesk(email:any,firstName:any,lastName:any,username:any,contactNumber:any): void {
  
    const scriptUrl = "https://juanpay.ladesk.com/scripts/track.js";
    const node = document.createElement("script");
    node.src = scriptUrl;
    node.id = "la_x2s6df8d";
    node.type = "text/javascript";
    node.async = true;
    node.charset = "utf-8";
    node.onload = function (e) {
    LiveAgent.clearAllUserDetails();
    LiveAgent.setUserDetails(email, firstName, lastName);
    LiveAgent.addContactField('username', username);
    LiveAgent.addContactField('contactnumber', contactNumber);
    LiveAgent.addContactField('email', email);
    LiveAgent.addContactField('name', firstName +  ' ' + lastName);
    LiveAgent.addContactField('csrcode','FROM MIGRATION');
    LiveAgent.createButton("01n1ydwf", document.getElementById("chatButton"));
  
  document.getElementsByTagName("head")[0].appendChild(node);
}
}
  
  async submitChanges(){
    this.submitted = true;

    debugger
    // stop here if form is invalid
    if (this.managerUserFormGroup.invalid && !this.username) {
      debugger

      PNotify.error({
        title: "Migration Form",
        text: "Error in saving. Please fill all required fields.",
      });
        return;
    }

    // dtiFormId: new FormControl(this.emptyString),
    // applicationFormId: new FormControl(this.emptyString),
    // validID1Id: new FormControl(this.emptyString),
    // validID2Id: new FormControl(this.emptyString),

    if((this.managerUserFormGroup.controls.validID1Id.value == "" || this.managerUserFormGroup.controls.validID2Id.value == "") && !this.username){
      if (this.fileFormGroup.invalid) {
        debugger
  
        PNotify.error({
          title: "Migration Form",
          text: "Error in saving. Please upload all required files.",
        });
          return;
      }
    }


    await this.managerUsersService.checkExistingSalesMigration(this.managerUserFormGroup.value).subscribe(
      (result) => {
        this.saveMigrationData();
      },
      (error) => {
       
        if (error.status === 400) {
         
          const obj = error.error.errors;
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              let str: string = key;
              str = str[0].toLowerCase() + str.slice(1);
              this.managerUserFormGroup.controls[str].setErrors({
                required: Validators.required,
                apiMessage: obj[key],
              });
            }
          }
          this.processCreate = false;

          PNotify.notice({
            title: "Warning",
            text: "Could not store account with errors.",
          });
        } else {
          // Other error
          this.processCreate = false;

          PNotify.error({
            title: "Error",
            text: "Something went wrong.",
          });
        }

        this.loaderMessage = "";
        this.loader = false;

        return;
      });
      
      
      // error => {
      //   PNotify.error({
      //     title: "Migration Form",
      //     text: "Email/Contact No is already exist. Please update your email. Go to https://vipexclusiveclub.net",
      //   });
      //   this.loaderMessage = "";
      //   this.loader = false;

      //   return;
      // });
    
  }

  async saveMigrationData(){
    this.loader = true;
    var fileCount = this.fileFormGroup.controls.length;
    var fileCounter = 1;
    for (const field in this.fileFormGroup.controls) { 
      debugger
      if(this.fileFormGroup.controls[field].value){
        var file = {
          fileName : field,
          managerUserId : 0,
          photoFile : this.fileFormGroup.controls[field].value,
          purpose : "File Manager",
          username : this.username ? this.username : this.loginUserFormGroup.controls.username.value
        }
        await this.fileUploadService.FileUploadOutSingle(file).then(data =>{ 
          this.managerUserFormGroup.controls[field + 'Id'].setValue(data);
          this.loaderMessage = `Uploading ${fileCounter} of ${fileCount}. Please wait.`;
            fileCounter++;
        });
      }
    }

    this.loader = true;
    this.managerUsersService.postSalesUserMigration(this.managerUserFormGroup.value).subscribe(
    (result) => {
      PNotify.success({
        title: "Migration Form",
        text: "Successfully Saved.",
      });
      this.initFilesFormGroup();
      this.loaderMessage = "";
      this.loader = false;
    }, error => {
      PNotify.error({
        title: "Migration Form",
        text: "Error in saving. Please contact ACM support.",
      });
      this.loaderMessage = "";
      this.loader = false;
    });
  }

  setGender(){
    this.genderArray = [
      {
        id: 1, name: "Male"
      },
      {
        id: 2, name: "Female"
      }
    ];
  }

  alreadyExist : any = false;
  enterpriseData : any;
  checkMemberDetails() {
    this.managerUsersService.getManagerVIPExclusiveClubDetailsMigration(this.loginUserFormGroup.value).subscribe(
      (result) => {
          if(result.isExist){
            this.loadMemberData(result);
            debugger
          } else {
            this.verifySalesEmail(result.old.emailAddress);
            debugger
          }
      },
      (error) => {
        PNotify.error({
          title: "Connecting to VIP",
          text: "Invalid old enterprise username or password.",
        });
        this.initLoginFormGroup();
        this.loader = false;
      }
    );
  }

  hasNumber: any;
  numberStr: any;
  emailStr: any;
  verifySalesEmail(email){
    this.loader = true;
    this.salesUsersService.verifySalesUserEmail(this.loginUserFormGroup.value, email).subscribe((result) => {
      this.emailStr = this.censorEmail(email);
      this.viewLoginModalRef.close();
      this.loader = false;
      this.verifyModalModalRef = this.modalServe.open(this.verifyModal, { centered: true,size:'sm', backdrop: 'static'});
    });
  }

  censorWord(str) {
   return str[0] + "*".repeat(str.length - 2) + str.slice(-1);
  }

  censorEmail(email) {
     var arr = email.split("@");
     return this.censorWord(arr[0]) + "@" + this.censorWord(arr[1]);
  }

  checkMemberDetailsACM() {
    this.managerUsersService.getManagerVIPExclusiveClubDetailsMigrationACM(this.username).subscribe(
      (result) => {
        //console.log(result);
        this.loadMemberData(result);
      },
      (error) => {
        PNotify.error({
          title: "Connecting to VIP Exclusive Club",
          text: "Invalid VIP username or password.",
        });
        this.initLoginFormGroup();
        this.loader = false;
      }
    );
  }
  
  checkSalesMemberDetailsACM() {
    this.managerUsersService.getManagerVIPExclusiveClubDetailsMigrationACM(this.loginUserFormGroup.controls.username.value).subscribe(
      (result) => {
        //console.log(result);
        this.enterOTPModalRef.close();

        this.loadMemberData(result);
      },
      (error) => {
        PNotify.error({
          title: "Connecting to VIP Exclusive Club",
          text: "Invalid VIP username or password.",
        });
        this.initLoginFormGroup();
        this.loader = false;
      }
    );
  }

  loadMemberData(result){
    this.enterpriseData = result;
    this.loadLaDesk(result.old.emailAddress,result.old.firstName,result.old.lastName,result.old.username,result.old.mobileNo1);
    this.comments = result.comments ? result.comments :  [];
    debugger
    
    if(!result.isExist){
      if (result.old.username) {

       
        this.managerUserFormGroup.controls.username.setValue(result.old.username);
        this.managerUserFormGroup.controls.email.setValue(result.old.emailAddress);
        this.managerUserFormGroup.controls.contactNumber.setValue(result.old.mobileNo1 ? result.old.mobileNo1 : 'Go to https://vipexclusiveclub.net and update your mobile no.');
        
        this.managerUserFormGroup.controls.address.setValue(result.old.address1);
        this.managerUserFormGroup.controls.firstName.setValue(result.old.firstName);
        this.managerUserFormGroup.controls.lastName.setValue(result.old.lastName);
        this.managerUserFormGroup.controls.middleName.setValue(result.old.middleName);
        this.managerUserFormGroup.controls.birthdate.setValue(result.old.birthDate);

        this.managerUserFormGroup.controls.enterpriseActivationDate.setValue(result.old.created_Dt);
        this.managerUserFormGroup.controls.sponsor.setValue(result.old.sponsored_Id);
        this.managerUserFormGroup.controls.upline.setValue(result.old.upline_Id);
        this.managerUserFormGroup.controls.placement.setValue(result.old.placement);
        
        this.managerUserFormGroup.controls.rewards.setValue(result.old.rewards_Credits);
        this.managerUserFormGroup.controls.specialPoints.setValue(result.old.specialPoints);

        this.managerUserFormGroup.controls.roleId.setValue("Sales Director");

        this.managerUserFormGroup.controls.bank.setValue(result.old.bank1);
        this.managerUserFormGroup.controls.bankAccount.setValue(result.old.bankAccount1);
        this.managerUserFormGroup.controls.bankType.setValue(result.old.bankType1);
        
        this.loader = false;
        this.validAccount = true;
         this.viewLoginModalRef.close();
         this.enterOTPModalRef.close();
      } else {
        PNotify.error({
          title: "Connecting to VIP",
          text: "Invalid old enterprise username or password.",
        });
        this.initLoginFormGroup();
        this.loader = false;
      }
    }else{
      
      if (result.old.username) {

        //console.log("a",result.meta);

        this.managerUserFormGroup.controls.username.setValue(result.old.username);
        this.managerUserFormGroup.controls.email.setValue(result.old.emailAddress);
        this.managerUserFormGroup.controls.contactNumber.setValue(result.old.mobileNo1 ? result.old.mobileNo1 : 'Go to https://vipexclusiveclub.net and update your mobile no.');
        
        this.managerUserFormGroup.controls.address.setValue(result.meta.address);
        this.managerUserFormGroup.controls.firstName.setValue(result.old.firstName);
        this.managerUserFormGroup.controls.lastName.setValue(result.old.lastName);
        this.managerUserFormGroup.controls.middleName.setValue(result.meta.MiddleName);
        this.managerUserFormGroup.controls.address.setValue(result.meta.Address);
        this.managerUserFormGroup.controls.birthdate.setValue(result.meta.BirthDate);

        this.managerUserFormGroup.controls.roleId.setValue(result.meta.RoleId);
        this.managerUserFormGroup.controls.gender.setValue(result.meta.Gender);

        this.managerUserFormGroup.controls.enterpriseActivationDate.setValue(result.old.created_Dt);
        this.managerUserFormGroup.controls.sponsor.setValue(result.old.sponsored_Id);
        this.managerUserFormGroup.controls.upline.setValue(result.old.upline_Id);
        this.managerUserFormGroup.controls.placement.setValue(result.old.placement);

        this.managerUserFormGroup.controls.bank.setValue(result.meta.Bank);
        this.managerUserFormGroup.controls.bankAccount.setValue(result.meta.BankAccount);
        this.managerUserFormGroup.controls.bankType.setValue(result.meta.BankType);
        
        this.managerUserFormGroup.controls.validID1Id.patchValue(result.meta.ValidID1Id);
        this.managerUserFormGroup.controls.validID2Id.patchValue(result.meta.ValidID2Id);

        
        this.managerUserFormGroup.controls.rewards.setValue(result.old.rewards_Credits);
        this.managerUserFormGroup.controls.specialPoints.setValue(result.old.specialPoints);

        //this.managerUserFormGroup.controls.comment.setValue(result.meta.Comment ? this.populateCommentDataFormArray(result.meta.Comment) : []);
      
        this.loader = false;
        this.validAccount = true;
        this.viewLoginModalRef.close();
        this.enterOTPModalRef.close();
       if(!this.username) 
       this.viewLoginModalRef.close();
       
      } else {
        PNotify.error({
          title: "Connecting to VIP",
          text: "Invalid old enterprise username or password.",
        });
        this.initLoginFormGroup();
        this.loader = false;
      }
    }
  }

  populateCommentDataFormArray(comments: any): void {
    //console.log("comment: ", comments);

    comments.forEach((step) => {
      const commentFormGroup = this.formBuilder.group({
        username: new FormControl(step.username),
        createdDate: new FormControl(step.createdDate),
        comment: new FormControl(step.comment)
      });

      (this.managerUserFormGroup.controls.comment as FormArray).push(commentFormGroup);
    }); 
  }

  sendComment(){
    console.log(this.commentFormGroup);
    console.log(this.managerUserFormGroup);
  }

  logout(){
    this.validAccount = false;
    this.token = "";
    this.loginUserFormGroup.controls.username.patchValue("");
    this.loginUserFormGroup.controls.password.patchValue("");
    this.viewLoginModalRef = this.modalServe.open(this.viewLoginModal, { centered: true, size:'sm', backdrop: 'static'});
  }

  public onPhotoChange(event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.base64Photo = reader.result;

        this.managerUserFormGroup.patchValue({
          photo: reader.result,
        });

        this.changeDetectorRef.markForCheck();
      };
    }
  }

  backToManagerUsersList(): void {
    this.router.navigate(["acm", "manager-user-list"]);
  }



  createAccount(){
    if(this.roleId != "7" && this.roleId != "1"){
      PNotify.error({
        title: "Account Creation",
        text: "Finance Account is Required.",
      });
    } else{
      if(confirm(`Are you sure you want to migrate the account of ${this.managerUserFormGroup.controls.username.value} to govipcenter?`)) {
        this.actionEventActivateSalesUser(this.managerUserFormGroup.value);
  
      }

        
    }
  }

  actionEventActivateSalesUser(createAccountPayload : any): void {
    this.processCreate = true;

    this.managerUsersService.createSalesUser(createAccountPayload).subscribe(
      (result) => {
        // const encId = this.cryptoService.set(this.data.authentication.privateKey, result.data.id);
        // const encNewData = this.cryptoService.set(this.data.authentication.privateKey, "true");
        // this.router.navigate(["acm", "manager-user-view", { id: encId, status: encNewData }]);

        PNotify.success({
          title: "Success",
          text: "The account has been successfully created.",
        });
        this.processCreate = false;
        this.closeModal.emit();
      },
      (error) => {

        if (error.status === 400) {

          this.processCreate = false;
          PNotify.notice({
            title: "Warning",
            text: "Could not store account with errors. " + JSON.stringify(error.error.errors)  ,
          });
        } else {
          // Other error
          this.processCreate = false;

          PNotify.error({
            title: "Error",
            text: "Something went wrong.",
          });
        }
      }
    );
  }

}

