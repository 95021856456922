import { Component, OnInit } from "@angular/core";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { CashierUsersService } from "src/data/services/web/cashier-users.service";
import { ManagerUsersService } from "src/data/services/web/manager-users.service";

declare let LiveAgent: any;

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.less"],
})
export class FooterComponent implements OnInit {
  constructor(private localStorageCacheService : LocalStorageCacheService,
     private managerUsersService : ManagerUsersService,
     private cashierUsersService : CashierUsersService) {
    //
  }


  ngOnInit(): void {

    const idDefault = this.localStorageCacheService.getStorage("id");
    const role_level = this.localStorageCacheService.getStorage("role_level");
    
    if(role_level == 2){
      this.getManagerUserDetails(idDefault);
    }
    if(role_level == 3){
      this.getCashierUserDetails(idDefault);
      console.log("footer");
    }

    
    
    //
  }

  getManagerUserDetails(id: any): void {
    this.managerUsersService.getManagerUserV2(id).subscribe((result) => {
    var user = result.data;
      const scriptUrl = "https://juanpay.ladesk.com/scripts/track.js";
      const node = document.createElement("script");
      node.src = scriptUrl;
      node.id = "la_x2s6df8d";
      node.type = "text/javascript";
      node.async = true;
      node.charset = "utf-8";
      node.onload = function (e) {
      LiveAgent.clearAllUserDetails();
      LiveAgent.setUserDetails(result.data.email, result.data.firstName, result.data.lastName);
      LiveAgent.addContactField('username', result.data.username);
      LiveAgent.addContactField('contactnumber', result.data.contactNumber);
      LiveAgent.addContactField('email', result.data.email);
      LiveAgent.addContactField('name', result.data.firstName +  ' ' + result.data.lastName);
      LiveAgent.addContactField('csrcode',result.data.csrCode);
      LiveAgent.createButton("01n1ydwf", document.getElementById("chatButton"));
    };
    document.getElementsByTagName("head")[0].appendChild(node);
    
    });
  }

  getCashierUserDetails(id: any): void {
    this.cashierUsersService.getCashierUserv2(id).subscribe((result) => {
    var user = result.data;
   
     
      const scriptUrl = "https://juanpay.ladesk.com/scripts/track.js";
      const node = document.createElement("script");
      node.src = scriptUrl;
      node.id = "la_x2s6df8d";
      node.type = "text/javascript";
      node.async = true;
      node.charset = "utf-8";
      node.onload = function (e) {
      LiveAgent.clearAllUserDetails();
      LiveAgent.setUserDetails(result.data.email, result.data.firstName, result.data.lastName);
      LiveAgent.addContactField('username', result.data.username);
      LiveAgent.addContactField('contactnumber', result.data.contactNumber);
      LiveAgent.addContactField('email', result.data.email);
      LiveAgent.addContactField('name', result.data.firstName +  ' ' + result.data.lastName);
      LiveAgent.addContactField('csrcode',result.data.csrCode);
      LiveAgent.createButton("01n1ydwf", document.getElementById("chatButton"));
    };
    document.getElementsByTagName("head")[0].appendChild(node);
    
    });
  }

}

//  <script type="text/javascript">
//   (function(d, src, c) { var t=d.scripts[d.scripts.length - 1],s=d.createElement('script');s.id='la_x2s6df8d';s.async=true;s.src=src;s.onload=s.onreadystatechange=function(){var rs=this.readyState;if(rs&&(rs!='complete')&&(rs!='loaded')){return;}c(this);};t.parentElement.insertBefore(s,t.nextSibling);})(document,
//   'https://govipcenter.ladesk.com/scripts/track.js',
//   function(e){
//   LiveAgent.clearAllUserDetails();
//   LiveAgent.setUserDetails('delnortebryan@yahoo.com', 'Bryan Asuncion', 'del Norte');
//   LiveAgent.addContactField('contactnumber', '09998483938');

//   LiveAgent.createButton('01n1ydwf', e);
//  });
