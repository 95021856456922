<div [formGroup]="parentFormGroup" class="form-group row">
    <label  class="col-sm-3 col-form-label font-weight-bold">{{ label }}</label>
    <div class="col-sm-9">
        <label  class="ui-switch ui-switch-lg dark mt-1 mr-2">
            <input 
              [formControlName]="controlName" 
              type="checkbox" 
              [attr.disabled]="readonly ? true : null"
              style="border-color: #294896;"
              >
            <i></i>
        </label>
    </div>
</div>

<!-- <div [formGroup]="parentFormGroup" class="row">
    <div class="col-md-2">
      <div class="generic-checkbox {{ controlName }}">
        <input
          type="checkbox"
          [formControlName]="controlName"
          [id]="controlName"
        />
        <label *ngIf="label" for="{{ controlName }}"></label>
      </div>
    </div>
    <div class="checkbox-label">
      <span>{{ label }}</span>
    </div>
  </div> -->
  