<app-loader *ngIf="loader"></app-loader>

<div *ngIf="!loader"  id="content" class="flex" >
    <!-- <div>
      <div class="page-hero page-container" id="page-hero">
        <div class="padding d-flex">
          <div class="page-title">
            <h2 class="text-md text-highlight">VIP Store</h2>
            <small class="text-highlight font-weight-normal font-italic">Go VIP Center list of VIP Store Items</small>
          </div>
        </div>
      </div>
    </div> -->

    <div class="card w-90 m-5 bg-white">
      <div class="p-2" style="background-color: rgb(22, 28, 45);">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-4"></div>
          <div class="col-sm-12 col-md-6 col-lg-4"></div>
          <div class="col-sm-12 col-md-6 col-lg-4">
            <form class="flex" [formGroup]="listFilterForm">
              <div class="input-group rounded">
                <input
                  type="text"
                  class="form-control rounded"
                  placeholder="Search..."
                  formControlName="searchString"
                  (keyup.enter)="getStoreItem(null, null, null, true)"
                />

                <span class="input-group-text border-0" id="search-addon" (click)="getStoreItem(null, null, null, true)">
                  <i class="fas fa-search text-white"></i>
                </span>

              </div>
            </form>

          </div>
        </div>
      </div>


      <div class="card bg-light mb-0">
        <div class="card-body">
          <h4>VIPCO STORE</h4>
          <hr>

          <div class="row">
            <div class="col-sm-12 col-md-4 p-3 d-flex justify-content-around" *ngFor="let item of storeItems; let i = index" [ngClass]="(innerWidth > 1450) ? 'col-lg-2' : 'col-lg-3'">
              <div class="polaroid p-1">
                <img src="{{ item.storeItemImages[0].path }}" alt="5 Terre" style="width:100%; border-radius: 10px 10px 0 0;">
                <div class="container">
                  <div class="d-block">
                    <div>
                      <h4>{{ item.name }}</h4>
                    </div>
                    <div>
                      <h6>{{ item.description }}</h6>
                    </div>
                    <div>
                      <br>
                      <h4>₱ {{ makeMoney(item.price) }}</h4>
                    </div>
                    <div class="d-flex justify-content-center align-items-end">
                      <div>
                        <h6></h6>
                      </div>
                      <div>
                        <button class="btn btn-sm btn-secondary m-1" style="width: 120px;" (click)="viewDetails(detailModal, item)">View Details</button>
                        <button class="btn btn-sm btn-primary m-1" style="width: 120px;" (click)="confirmPurchase(confirmModal, item)">Purchase</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>

        </div>
      </div>


    </div>
</div>

<ng-template #detailModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Product Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-1">

    <div class="container-fluid">
      <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item" *ngFor="let itemImage of storeItem.storeItemImages; let i=index" [ngClass]="(i === 0) ? 'active' : ''">
            <img src="{{ itemImage.path }}" class="d-block w-100" alt="{{ itemImage.path }}" style="width:100%; border-radius: 10px 10px 0 0;">
          </div>
        </div>
        <a *ngIf="storeImagesCount != 1" class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a *ngIf="storeImagesCount != 1" class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>

    <div class="container-fluid">
      <div class="d-flex justify-content-around">

        <div>
          <div class="container">
            <div class="d-block">
              <div>
                <h4>{{ storeItem.name }}</h4>
              </div>
              <div>
                <h6>{{ storeItem.description }}</h6>
              </div>
              <div>
                <br>
                <h4>₱ {{ makeMoney(storeItem.price) }}</h4>
              </div>
              <div>
                <button class="btn btn-sm btn-primary m-1" style="width: 120px;" (click)="confirmPurchase(confirmModal, item)">Purchase</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    
  </div>
</ng-template>

<ng-template #confirmModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Confirm Purchase</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h4>Are you sure you want to purchase {{ storeItem.name }} ?</h4>
    <hr>
    <div>
      <button class="btn btn-sm btn-secondary float-right" aria-label="Close" (click)="modal.dismiss('Cross click')">Cancel</button>
      <button class="btn btn-sm btn-primary float-right mr-2" (click)="proceedPurchase(storeItem)">Proceed</button>
    </div>
  </div>
</ng-template>