import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
// import * as logo from '../../../assets/images/logo';

@Injectable({
  providedIn: 'root'
})
export class VIPProtectExportExcelService {

  
  constructor(private currencyPipe : CurrencyPipe) { }

  exportExcel(excelData, name) {

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    //Title, Header & Data
    const title = excelData.title;
    const header = excelData.headers
    const data = excelData.data;
    const dateStart = excelData.dateStart;
    const dateEnd = excelData.dateEnd;
    const productCode = excelData.productCode;
    const totals = excelData.totals;

    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Sales Data');


    //Add Row and formatting
    worksheet.mergeCells('C1', 'K4');
    let titleRow = worksheet.getCell('C1');
    titleRow.value = title
    titleRow.font = {
      name: 'Calibri',
      size: 16,
      underline: 'single',
      bold: true,
      color: { argb: '0085A3' }
    }
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

    // Date
    worksheet.mergeCells('L1:M4');
    let d = new Date();
    let date =  monthNames[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear();
    let dateCell = worksheet.getCell('L1');
    dateCell.value = date;
    dateCell.font = {
      name: 'Calibri',
      size: 12,
      bold: true
    }
    dateCell.alignment = { vertical: 'middle', horizontal: 'center' }

    // Start Date
    let startDateCell = worksheet.getCell('C6');
    startDateCell.value = 'Start Date';
    startDateCell.font = {
      name: 'Calibri',
      size: 12,
      bold: false
    }
    startDateCell.alignment = { vertical: 'middle', horizontal: 'left' }

    worksheet.mergeCells('D6:E6');
    let startDateCellValue = worksheet.getCell('D6');
    startDateCellValue.value =dateStart;
    startDateCellValue.font = {
      name: 'Calibri',
      size: 12,
      bold: true
    }
    startDateCellValue.alignment = { vertical: 'middle', horizontal: 'center' }

    // endDate
    let endDateCell = worksheet.getCell('C7');
    endDateCell.value = 'End Date';
    endDateCell.font = {
      name: 'Calibri',
      size: 12,
      bold: false
    }
    endDateCell.alignment = { vertical: 'middle', horizontal: 'left' }

    worksheet.mergeCells('D7:E7');
    let endDateCellValue = worksheet.getCell('D7');
    endDateCellValue.value = dateEnd;
    endDateCellValue.font = {
      name: 'Calibri',
      size: 12,
      bold: true
    }
    endDateCellValue.alignment = { vertical: 'middle', horizontal: 'center' }

    // Product Code
    let productCodeCell = worksheet.getCell('C8');
    productCodeCell.value = 'Product Code';
    productCodeCell.font = {
      name: 'Calibri',
      size: 12,
      bold: false
    }
    productCodeCell.alignment = { vertical: 'middle', horizontal: 'left' }

    worksheet.mergeCells('D8:E8');
    let productCodeCellValue = worksheet.getCell('D8');
    productCodeCellValue.value = productCode;
    productCodeCellValue.font = {
      name: 'Calibri',
      size: 12,
      bold: true
    }
    productCodeCellValue.alignment = { vertical: 'middle', horizontal: 'center' }


    // Product Code
    worksheet.mergeCells('G6:I6');
    let sum1Cell = worksheet.getCell('G6');
    sum1Cell.value = 'Total Enrollment';
    sum1Cell.font = {
      name: 'Calibri',
      size: 12,
      bold: false
    }
    sum1Cell.alignment = { vertical: 'middle', horizontal: 'left' }

     // Product Code
     worksheet.mergeCells('G7:I7');
     let sum2Cell = worksheet.getCell('G7');
     sum2Cell.value = 'Total Premium';
     sum2Cell.font = {
       name: 'Calibri',
       size: 12,
       bold: false
     }
     sum2Cell.alignment = { vertical: 'middle', horizontal: 'left' }

      // Product Code
    worksheet.mergeCells('G8:I8');
    let sum3Cell = worksheet.getCell('G8');
    sum3Cell.value = 'Total Service Fees';
    sum3Cell.font = {
      name: 'Calibri',
      size: 12,
      bold: false
    }
    sum3Cell.alignment = { vertical: 'middle', horizontal: 'left' }

     // Product Code
     worksheet.mergeCells('G9:I9');
     let sum4Cell = worksheet.getCell('G9');
     sum4Cell.value = 'Total Withholding Tax';
     sum4Cell.font = {
       name: 'Calibri',
       size: 12,
       bold: false
     }
     sum4Cell.alignment = { vertical: 'middle', horizontal: 'left' }

      // Product Code
    worksheet.mergeCells('G10:I10');
    let sum5Cell = worksheet.getCell('G10');
    sum5Cell.value = 'Total Net Premium to VIP Protect';
    sum5Cell.font = {
      name: 'Calibri',
      size: 12,
      bold: false
    }
    sum5Cell.alignment = { vertical: 'middle', horizontal: 'left' }




     // Product Code
     worksheet.mergeCells('J6:M6');
     let sum1CellValue = worksheet.getCell('J6');
     sum1CellValue .value = this.makeMoney(totals.totalEnrollment);
     sum1CellValue .font = {
       name: 'Calibri',
       size: 12,
       bold: true
     }
     sum1CellValue .alignment = { vertical: 'middle', horizontal: 'right' }
 
      // Product Code
      worksheet.mergeCells('J7:M7');
      let sum2CellValue  = worksheet.getCell('J7');
      sum2CellValue.value = this.makeMoney(totals.grossPremium);
      sum2CellValue.font = {
        name: 'Calibri',
        size: 12,
        bold: true
      }
      sum2CellValue.alignment = { vertical: 'middle', horizontal: 'right' }
 
       // Product Code
     worksheet.mergeCells('J8:M8');
     let sum3CellValue = worksheet.getCell('J8');
     sum3CellValue.value = this.makeMoney(totals.serviceFee);
     sum3CellValue.font = {
       name: 'Calibri',
       size: 12,
       bold: true
     }
     sum3CellValue.alignment = { vertical: 'middle', horizontal: 'right' }
 
      // Product Code
      worksheet.mergeCells('J9:M9');
      let sum4CellValue = worksheet.getCell('J9');
      sum4CellValue.value = this.makeMoney(totals.withHoldingTax);
      sum4CellValue.font = {
        name: 'Calibri',
        size: 12,
        bold: true
      }
      sum4CellValue.alignment = { vertical: 'middle', horizontal: 'right' }
 
       // Product Code
     worksheet.mergeCells('J10:M10');
     let sum5CellValue = worksheet.getCell('J10');
     sum5CellValue.value = this.makeMoney(totals.netPremiumToVIPProtect);
     sum5CellValue.font = {
       name: 'Calibri',
       size: 12,
       bold: true
     }
     sum5CellValue.alignment = { vertical: 'middle', horizontal: 'right' }

    //Add Image
    // let myLogoImage = workbook.addImage({
    //   base64: logo.imgBase64,
    //   extension: 'png',
    // });
    // worksheet.mergeCells('A1:B4');
    // worksheet.addImage(myLogoImage, 'A1:B4');

    //Blank Row 
    worksheet.addRow([]);

    //Adding Header Row
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    })

    // Adding Data with Conditional Formatting
    data.forEach(d => {
      let row = worksheet.addRow(d);

      // let sales = row.getCell(13);
      // let color = 'FF99FF99';
      // if (+sales.value < 200000) {
      //   color = 'FF99FF99'
      // }

      // sales.fill = {
      //   type: 'pattern',
      //   pattern: 'solid',
      //   fgColor: { argb: color }
      // }
    }
    );

    worksheet.getColumn(3).width = 20;
    worksheet.addRow([]);

    //Footer Row
    let footerRow = worksheet.addRow([name + ' Report Generated from GoVIPCenter at ' + date]);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFB050' }
    };

    //Merge Cells
    worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, title + '.xlsx');
    })

  }

  
  makeMoney(money: any) {
    if(money){
      return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
    }else{
      return "0.00";
    }
   
  } 
}