import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CryptoService } from 'src/data/crypto/crypto.service';
import { Data } from 'src/data/data';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { AuthService } from 'src/data/services/authentication/auth.service';
import { ExportExcelService } from 'src/data/services/web/export-excel.service';
import { RolesService } from 'src/data/services/web/roles.service';
import { StoreItemService } from 'src/data/services/web/store-item.service';
import { UserService } from 'src/data/services/web/user.service';
import { VipcoTransactionService } from 'src/data/services/web/vipco-transaction.service';
import { environment } from 'src/environments/environment';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-vipco-transaction-list',
  templateUrl: './vipco-transaction-list.component.html',
  styleUrls: ['./vipco-transaction-list.component.less']
})
export class VipcoTransactionListComponent implements OnInit {

  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public vipcoTransactionService: VipcoTransactionService;
  public modalService: BsModalService;
  public accessToken: string;
  public userService: UserService;

  public roleLevelId: any;
  public userId: any;
  public username: any;
  public pageCount: any;

  public transactions: any;
  public transferVipco: any;
  public emptyString = "";
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    private currencyPipe: CurrencyPipe,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    vipcoTransactionService: VipcoTransactionService,
    modalService: BsModalService,
    userService: UserService,
    private modalBService: NgbModal,
    public ete: ExportExcelService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.vipcoTransactionService = vipcoTransactionService;
    this.modalService = modalService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
    this.userService = userService;
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.username = this.localStorageCacheService.getStorage("username");
    this.userId = this.localStorageCacheService.getStorage("userId");
    this.getVIPCOTransactions();
  }

  private _sortBy: any;
  private _sort: any;

  getVIPCOTransactions(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_userId: string = this.userId ? `userId=${this.userId}&` : "";
    const queryString_searchString: string = this.searchString ? `searchString=${this.searchString}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_userId + queryString_searchString;

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/VIPCOTransactions/Transactions?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/VIPCOTransactions/Transactions${queryString}`;
      }
    }

    this.vipcoTransactionService.getVIPCOTransactions(endPointUrl).subscribe((result) => {
      if (result.data.length == 0 && result.pageNumber != 1) {
        this.getVIPCOTransactions(result.previousPage, _sortBy, _sort);
      } else {
        this.transactions = result;
      }
    });
  }

  createdDate = "";
  searchString = "";

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.createdDate = field == "createdDate" ? "th-inner sortable both " + sortBy : "";

    this.getVIPCOTransactions(
      `${environment.API_URL}/api/VIPCOTransactions/Transactions?pageNumber=${page ? page : this.transactions.pageNumber}`,
      this._sortBy,
      this._sort
    );
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.transactions?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }

  p:Number =1;
  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p = pageNumber;
    return `${environment.API_URL}/api/VIPCOTransactions/Transactions?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  statusOfAmount(money: any): boolean {
    return money < 0 ? false : true;
  }


  public actionTransferVIPCoToAppData(id: any): void {

    this.transferVipco = true;
        this.vipcoTransactionService.transferVIPCoToAppData(id).subscribe(
          (result) => {
            this.getVIPCOTransactions();
            this.transferVipco = false;
            var message = "";
            switch (result) {
              case 1: message = "Rewards Transferred Successfully"; break;
              case 2:message = "Unauthorized account. This request will be recorded"; break;
              case 3:message = "Mobile number not yet registered in Vips mobile app"; break;
              case 4:message = "There's a problem with the connection. Please try again later"; break;
              case 5:message = "This transaction has been transferred to Vips mobile app already"; break;
              case 6:message = "Transaction datetime is not matured yet"; break;
              case 7:message = "Invalid transaction. This request will be recorded"; break;
              case 8:message = "Unexpected error. Please try again later"; break;
              default:
                break;
            }

            if(result == 1){
              PNotify.success({
                title: "Success",
                text: message,
              });
            }else{
              PNotify.error({
                title: "Something went wrong",
              text: message,
              });
            }
           
          },
          (error) => {
            PNotify.error({
              title: "Something went wrong",
              text: "Please try again later.",
            });
          }
        );
  }

}
