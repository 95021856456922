import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreItemService } from 'src/data/services/web/store-item.service';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.less']
})
export class ReceiptComponent implements OnInit {
  receivedData: string;
  @ViewChild('myDiv', { static: false }) myDiv: ElementRef;

  @HostListener('document:keydown.control.p', ['$event'])
  onPrintShortcut(event: KeyboardEvent): void {
    event.preventDefault(); // Prevent the default print action
    this.printDiv();
  }
  
  constructor(
    private currencyPipe: CurrencyPipe,
    public datepipe: DatePipe,
    public storeItemService: StoreItemService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100vh';
    document.body.style.width = '100vw';
    document.body.style.position = 'fixed';
    document.body.style.top = '0';
    document.body.style.left = '0';
    
    this.receivedData = this.route.snapshot.paramMap.get('data');
    this.getPO();
  }

  loader: boolean = false;
  purchaseOrder: any;
  getPO(){
    this.loader = true;
    this.storeItemService.GetPOByPONO(this.receivedData).subscribe((result) => {
      if(result){
        this.purchaseOrder = result;
        this.loader = false;
      }
    });
  }

  getTotal(){
    
  }

  printDiv(): void {
    const printContents = this.myDiv.nativeElement.innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }
}
