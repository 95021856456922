import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Select2OptionData } from 'ng-select2';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { SettlementsService } from 'src/data/services/web/settlements.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'ts-xlsx';
import * as FileSaver from 'file-saver';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SpxRemittanceService } from 'src/data/services/web/spx-remittance.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { result } from 'lodash';
import { ExportExcelService } from 'src/data/services/web/export-excel.service';
import { VipcoTransactionService } from 'src/data/services/web/vipco-transaction.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-vips-users-list',
  templateUrl: './vips-users-list.component.html',
  styleUrls: ['./vips-users-list.component.less']
})
export class VipsUsersListComponent implements OnInit {

  loader = false;
  searchByFormGroup: FormGroup;
  public fileUploadFormGroup: FormGroup;
  public deacFileUploadFormGroup: FormGroup;
  public riderFormGroup: FormGroup;
  p: number = 1;
  public pageRows: any = 10;
  emptyString = '';
  roleId: any;
  
  createdDate = "";
  searchString = "";

  public showRowsOption: Array<Select2OptionData>;
  public hubOption: Array<Select2OptionData>;
  public users: any = [];
  public usersExcel: any = [];
  public transactionsExcel: any = [];
  public transaction: any;
  public totals : any;

  
  private _sortBy: any;
  private _sort: any;
  
  constructor(
    private settlementService : SettlementsService,
    private vipsService : VipcoTransactionService,
    private localStorageCacheService: LocalStorageCacheService,
    private websettings : WebSettingsService,
    private modalServe: NgbModal,
    public datepipe: DatePipe,
    public ete: ExportExcelService
  ) { }

  ngOnInit(): void {
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    this.initSearchFormGroup();

    this.getShowRows();
    this.getVipsUser();
    // this.initFileUploadFormGroup();
    // this.initDeacFileUploadFormGroup();
    // this.initRiderFormGroup();
    // this.initHubs();
  }

  hubList: any = [];
  initHubs(){
    this.websettings.getWebSettingsByIdentifier("SPX_HUBS").subscribe((result) => {
      //debugger
      this.hubOption = result.data[0].value.map((status) => {
        return {
          id: status["HUB NAME"],
          text: status["HUB NAME"],
        };
      });
    });
  }

  initFileUploadFormGroup(): void {
    this.fileUploadFormGroup = new FormGroup({
      reportFile: new FormControl(this.emptyString)
    });
  }

  initDeacFileUploadFormGroup(): void {
    this.deacFileUploadFormGroup = new FormGroup({
      reportFile: new FormControl(this.emptyString)
    });
  }

  initSearchFormGroup(){
    this.searchByFormGroup = new FormGroup({
      searchString : new FormControl,
      dateRange : new FormControl,
      showRows: new FormControl
    });
  }

  initRiderFormGroup(): void {
    this.riderFormGroup= new FormGroup({
      fmsDriverName: new FormControl(this.emptyString),
      hubLocation: new FormControl(this.emptyString),
      region: new FormControl(this.emptyString),
      subRegion: new FormControl(this.emptyString),
      lifetimeID: new FormControl(this.emptyString)
    });
  }

  getFullName(user: any): string {
    //const middleName = user.userAux.find(x => x.auxDersciption == "MiddleName")?.auxValue || '';
    //const suffixName = user.userAux.find(x => x.auxDersciption == "SuffixName")?.auxValue || '';
    // if(suffixName != '' && middleName != '' && suffixName.toUpperCase() != 'N/A' && middleName.toUpperCase() != 'N/A'){
    //   return `${user.user.userFname} ${middleName} ${user.user.userLname} ${suffixName}`;
    // } else if(suffixName != '' && middleName == '' && suffixName.toUpperCase() != 'N/A'){
    //   return `${user.user.userFname} ${user.user.userLname} ${suffixName}`;
    // } else if(suffixName == '' && middleName != '' && middleName.toUpperCase() != 'N/A'){
    //   return `${user.user.userFname} ${middleName} ${user.user.userLname}`;
    // } else {
      
    // }.
    ////debugger
    const userFname = user?.user?.userFname;
    const userLname = user?.user?.userLname;

    const firstName = userFname ? userFname.toUpperCase() : '';
    const lastName = userLname ? userLname.toUpperCase() : '';

    return `${firstName} ${lastName}`;
    //return `${user?.user?.userFname?.toUpperCase()} ${user?.user?.userLname?.toUpperCase()}`;
  }

  getBday(user: any){
    if(user?.userAux?.some(x => x.auxDersciption == "Bday")){
      const bday = user?.userAux.find(x => x.auxDersciption == "Bday")?.auxValue || '';
      return `${bday}`;
    } else {
      return '';
    }
  }
  
  getGender(user: any){
    if(user?.userAux?.some(x => x.auxDersciption == "Gender")){
      const gender = user?.userAux.find(x => x.auxDersciption == "Gender")?.auxValue || '';
      return `${gender}`;
    } else {
      return '';
    }
    
  }
  
  getMunicipality(user: any){
    if(user?.userAux?.some(x => x.auxDersciption == "Municipality")){
      const municipality = user?.userAux.find(x => x.auxDersciption == "Municipality")?.auxValue || '';
      return `${municipality}`;
    } else {
      return '';
    }
  }

  getAddress(user: any){
    if(user?.userAux?.some(x => x.auxDersciption == "Brgy")){
      const brgy = user?.userAux.find(x => x.auxDersciption == "Brgy")?.auxValue || '';
      const province = user?.userAux.find(x => x.auxDersciption == "Province")?.auxValue || '';
      return `${brgy} (${province})`;
    } else {
      return '';
    }
    
  }

  getRegion(user: any){
    if(user?.userAux?.some(x => x.auxDersciption == "Region")){
      const region = user?.userAux.find(x => x.auxDersciption == "Region")?.auxValue || '';
      return `${region}`;
    } else {
      return '';
    }
  }

  searchData(){
    this.pageRows = 1;
    this.getVipsUser(this.buildPageUrl(1,
      this.searchByFormGroup.controls.showRows.value
      ?this.searchByFormGroup.controls.showRows.value : 10),null,null,true);
  }

  buildPageUrl(pageNumber: any, pageSize: any): any {
    //debugger
    this.p = pageNumber;
    return `${environment.API_URL}/api/VIPCOTransactions/GetAllUsers?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  getVipsUser(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader =true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    const searchString : string = this.searchByFormGroup.controls.searchString.value;
    const showRows : string = this.searchByFormGroup.controls.showRows.value;
    const dateRange : string = this.searchByFormGroup.controls.dateRange.value;

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_showRows: string = showRows ? `showRows=${showRows}&` : "showRows=10&";
    const queryString_searchString: string = searchString ? `searchString=${searchString}&` : "";
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_showRows + queryString_searchString + queryString_dateRange;
 
    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/VIPCOTransactions/GetAllUsers?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/VIPCOTransactions/GetAllUsers?${queryString}`;
      }
    }

    this.vipsService.getVipsUsers(endPointUrl.slice(0, -1)).subscribe((result) => {
      this.loader =false;
      // if(result){
      //   if (result.data.length == 0 && result.pageNumber != 1) {cred
      //     this.getVipsUser(result.previousPage, _sortBy, _sort);
      //   } else {
      //     this.users = result;
      //     this.totals = result.totals;
      //   }
      // }

      if (result.data.length == 0 && result.pageNumber != 1) {
        this.getVipsUser(result.previousPage, _sortBy, _sort);
      } else {
        this.users = result;
      }
    });
    
  }

  getTransactionExcel(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader =true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    const searchString : string = this.searchByFormGroup.controls.searchString.value;
    const showRows : string = this.searchByFormGroup.controls.showRows.value;
    const dateRange : string = this.searchByFormGroup.controls.dateRange.value;

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_searchString: string = searchString ? `searchString=${searchString}&` : "";
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_searchString + queryString_dateRange;
 
    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/VIPCOTransactions/GetAllUsersExcel?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/VIPCOTransactions/GetAllUsersExcel?${queryString}`;
      }
    }

    this.settlementService.getSettlements(endPointUrl.slice(0, -1)).subscribe((result) => {
      this.loader =false;
      if(result){
        console.log(result);
        if (result.data.length == 0 && result.pageNumber != 1) {
          this.getTransactionExcel(result.previousPage, _sortBy, _sort);
        } else {
          this.generateRiderExcel(result.data, "Vips User");
           this.loader = false;
        }
      }
    });
  }

  dataForExcel = [];
  async generateRiderExcel(userList: any, name: any) {
    let UserList = [];
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    ////debugger

      for(var i = 0; i < userList.length; i++){
        
        var fullname = this.getFullName(userList[i]);
        var bday = this.getBday(userList[i]);
        var address = this.getAddress(userList[i]);
        var region = this.getRegion(userList[i]);
        var municipality = this.getMunicipality(userList[i]);
        var gender = this.getGender(userList[i]);

        let meta = [
          {
              "Fullname": fullname,
              "Mobile No": userList[i].user.mobileNo,
              "Email Address": userList[i].user.userEmail,
              "Birthday": bday != '' ? new Date(bday).toLocaleDateString("en-US", { month: "short", day: "2-digit", year: "numeric" }) : "",
              "Gender":	gender,
              "Region":	region,
              "Address": address,
              "Municipality": municipality,
              "User Level": userList[i].user.usertype,
              "Active": userList[i].user.status,
              "Created Date": new Date(userList[i].user.createdate).toLocaleDateString("en-US", { month: "short", day: "2-digit", year: "numeric" }),
              "Linked Store": userList[i]?.linkedStore,
              "Linked User": userList[i]?.linkedUser,
              "Linked Type": userList[i]?.userType,
          }
        ];

        UserList.push(meta);
      }


    console.log(UserList);
    for(var x = 0; x < userList.length; x++){
      this.dataForExcel.push(Object.values(UserList[x][0]));
    }

    let reportData = {
      title: name + ' Report',
      data: this.dataForExcel,
      headers: Object.keys(UserList[0][0])
    }

    // for(var x = 0; x < UserList.length; x++){
    //   UserList.push(Object.values(UserList[x][0]));
    // }

    // let reportData = {
    //   title: name,
    //   data: UserList,
    //   headers: Object.keys(UserList[0][0])
    // }

    this.ete.exportExcel(reportData, name);
  }

  getShowRows() {
    this.websettings.getWebSettingsByIdentifier("SHOW_ROWS").subscribe((result) => {
     
      this.showRowsOption = result.data.map((status) => {
        return {
          id: status.parameter,
          text: status.value,
        };
      });
       
    });
  }

}
