import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PrintService } from "src/data/services/web/print.service";
import { TransactionService } from "src/data/services/web/transaction.service";

@Component({
  selector: "app-invoice",
  templateUrl: "./invoice.component.html",
  styleUrls: ["./invoice.component.less"],
})
export class InvoiceComponent implements OnInit {
  bill;
  trans;

  invoiceId: any;

  constructor(route: ActivatedRoute, private printService: PrintService, service: TransactionService) {
    this.invoiceId = route.snapshot.params["invoiceData"];
    //this.bill = service.getTransById(1);
    //this.trans = service.getTrans();
  }

  ngOnInit() {
    this.printService.onDataReady()
  }

  print(data : any) {
    alert(data);
     this.printService.onDataReady()
   }
}
