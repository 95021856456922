<!-- <app-loader *ngIf="!accounts || loader"></app-loader> -->
<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">JuanPay Store Settings</h2>
          <small class="font-italic" style="font-weight: bold">JuanPay Store List of Settings</small>
        </div>
        <div class="flex"></div>

        <div></div>
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="">
        <div class="card">
          <div class="b-b">
            <div class="nav-active-border b-primary bottom">
              <ul class="nav" id="myTab" role="tablist">
                <li class="nav-item pt-3 pr-3 pl-3">
                  <a
                    class="nav-link active"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home3"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    >Categories</a
                  >
                </li>
                <li class="nav-item pt-3 pr-3 pl-3">
                  <a
                    class="nav-link"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile3"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    >Unit of Measurements</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="tab-content p-3">
            <div class="tab-pane fade active show" id="home3" role="tabpanel" aria-labelledby="home-tab">
              <div (click)="AddCategory(addCateogryModal)" class="btn btn-sm btn-primary mb-2 float-right">
                <i class="fa fa-plus"></i> <span class="d-none d-sm-inline mx-1">Add Category</span>
              </div>
              <table
                id="table"
                class="table table-striped table-hover"
                data-plugin="bootstrapTable"
                data-toolbar="#toolbar"
                data-search="true"
                data-search-align="left"
                data-show-export="true"
                data-show-columns="true"
                data-detail-view="false"
                data-mobile-responsive="true"
                data-pagination="true"
                data-page-list="[10, 25, 50, 100, ALL]"
              >
                <thead>
                  <tr class="bg-dark">
                    <th data-sortable="true" data-field="owner">Category</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="odd" role="row" *ngFor="let category of categories; let i = index">
                    <td class="flex">
                      <div>
                        {{ category.category }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="tab-pane fade" id="profile3" role="tabpanel" aria-labelledby="profile-tab">
              <div (click)="AddUoM(addUoMModal)" class="btn btn-sm btn-primary mb-2 float-right">
                <i class="fa fa-plus"></i> <span class="d-none d-sm-inline mx-1">Add Unit of Measure</span>
              </div>
              <table
                id="table"
                class="table table-striped table-hover"
                data-plugin="bootstrapTable"
                data-toolbar="#toolbar"
                data-search="true"
                data-search-align="left"
                data-show-export="true"
                data-show-columns="true"
                data-detail-view="false"
                data-mobile-responsive="true"
                data-pagination="true"
                data-page-list="[10, 25, 50, 100, ALL]"
              >
                <thead>
                  <tr class="bg-dark">
                    <th data-sortable="true" data-field="owner">Unit</th>
                    <th data-sortable="true" data-field="owner">Short Unit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="odd" role="row" *ngFor="let unit of uom; let i = index">
                    <td class="flex">
                      <div>
                        {{ unit.unit }}
                      </div>
                    </td>
                    <td class="flex">
                      <div>
                        {{ unit.abrUnit }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<ng-template #addCateogryModal let-modal>
  <div *ngIf="categoryFormGroup">
    <div class="modal-header">
      <h6 class="modal-title">Add Category</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <app-textbox
        [parent-form-group]="categoryFormGroup"
        tab-index-start="0"
        control-name="category"
        [read-only]="false"
        label="Category"
        placeholder="Category"
      ></app-textbox>

      <hr />

      <div>
        <button class="btn btn-sm btn-primary float-right mb-2" (click)="submitCategory()">Submit</button>
        <button
          class="btn btn-sm btn-dark float-right mr-2 mb-2"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addUoMModal let-modal>
  <div *ngIf="uomFormGroup">
    <div class="modal-header">
      <h6 class="modal-title">Add Unit of Measure</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <app-textbox
        [parent-form-group]="uomFormGroup"
        tab-index-start="0"
        control-name="unit"
        [read-only]="false"
        label="Unit"
        placeholder="Unit"
      ></app-textbox>

      <app-textbox
        [parent-form-group]="uomFormGroup"
        tab-index-start="0"
        control-name="abrUnit"
        [read-only]="false"
        label="Short Unit"
        placeholder="Short Unit"
      ></app-textbox>
      <hr />

      <div>
        <button class="btn btn-sm btn-primary float-right mb-2" (click)="submitUom()">Submit</button>
        <button
          class="btn btn-sm btn-dark float-right mr-2 mb-2"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</ng-template>
