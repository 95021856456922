<!-- <app-loader *ngIf="!(users && roles)"></app-loader> -->

<!-- *ngIf="users && roles"w -->
<div id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">Pairing</h2>
          <small class="text-highlight font-weight-normal font-italic">JuanPay list of paired transaction</small>
        </div>
        <div class="flex"></div>
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="" style="padding-right: 0px">
        <!-- <div class="card mb-2 p-1" style="background-color: #182038">
            
            <div class="row">
              <div class="col-sm-12 col-md-7"></div>
              <div class="col-sm-12 col-md-5">
                <form class="flex" [formGroup]="listFilterForm">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control form-control-theme form-control-sm search"
                      placeholder="Search..."
                      formControlName="searchString"
                      
                      style="background-color: white; color: black"
                    />
                    
                    <span class="input-group-append">
                      <button
                        class="btn btn-white no-border btn-sm"
                        type="button"
                        style="background-color: gainsboro"
                      >
                        <i class="fa fa-search"></i>
                      </button>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div> -->

        <div class="card mt-2">
          <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
            <thead>
              <tr>
                <th data-sortable="true" data-field="owner">
                  <div>Date</div>
                </th>

                <th data-sortable="true" data-field="owner">
                  <div>Username</div>
                </th>
                <th data-sortable="true" data-field="project">
                  <div>Points Left</div>
                </th>
                <th data-sortable="true" data-field="project">
                  <div>Points Right</div>
                </th>
                <th data-sortable="true" data-field="project">
                  <div>Points Earned</div>
                </th>
                <th data-sortable="true" data-field="project">
                  <div>Special Points Earned</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <!-- | paginate: { itemsPerPage: 10, currentPage: p, totalItems: users?.totalRecords } -->
              <tr *ngFor="let user of users?.data" style="cursor: pointer" class="odd" role="row">
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ user?.dateTime | date: "MMM-dd-yyyy" }}
                  </div>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ user?.username }}
                  </div>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ user?.points_left }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ user?.points_right }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ user?.points_earned }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ user?.special_points_earned }}
                  </div>
                </td>

                <!-- <td >
                    <button type="button" class="btn btn-success" (click)="viewSalesUserProfile(user?.id)">
                      <span aria-hidden="true">Edit</span>
                    </button>
                    &nbsp;
                    <button *ngIf="roleLevel == 1" type="button" class="btn btn-primary"  (click)="openSendSms(sendSmsModal,user?.contactNumber)">
                      <span aria-hidden="true">Write Message</span>
                    </button>
                  </td> -->
              </tr>
            </tbody>
          </table>
        </div>

        <!-- <div class="row">
            <div class="col-sm-12 col-md-5">
              <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                <div class="col-sm-12 col-md-12">
                  <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                    Showing {{ (users?.pageNumber - 1) * users?.pageSize + 1 }} to
                    {{ (users?.pageNumber - 1) * users?.pageSize + users?.data?.length }} of
                    {{ users?.data?.length }} entries
                  </div>
                </div>
              </div>
            </div>
  
            <div class="col-sm-12 col-md-7" style="float: right">
              <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                <ul class="pagination" style="float: right">
                  <pagination-controls (pageChange)="getSalesAgentUsers(buildPageUrl($event, 10))"></pagination-controls>
                </ul>
              </div>
            </div>
          </div> -->
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>
