import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UploadService } from 'src/data/services/web/upload.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { PhpayService } from 'src/data/services/web/phpay.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5";

@Component({
  selector: 'app-phpay',
  templateUrl: './phpay.component.html',
  styleUrls: ['./phpay.component.less']
})
export class PhpayComponent implements OnInit {

  @Input("application") application: any;
  phpayFormGroup : FormGroup;

  formBuilder : FormBuilder;
  public emptyString = "";
  public billers : any = [];
  public biller : any;
  public billerCode : any;
  public billerTypes : any = [];
  public billersByBillerTypes : any = [];
  public selectedBillerCode : any;

  public activeTab = 1;
  public loader = false;
  public fee : any;
  public amount : any;
  public serviceFees: any;

  @Output("closeModal") closeModal = new EventEmitter();
  
  constructor(
    private phpayService : PhpayService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private uploadService: UploadService,
    localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    private modalService: NgbModal,
    formBuilder : FormBuilder,
    private webService: WebSettingsService,
    private datepipe: DatePipe) {
    this.formBuilder = formBuilder;
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.initPhpayFormGroup();
    this.initServiceFee();
  }

  initPhpayFormGroup(): void {
    const emptyString = this.emptyString;
  
    this.phpayFormGroup = new FormGroup({
      fullname: new FormControl(emptyString, [Validators.required]),
      contactNo: new FormControl(emptyString, [Validators.required]),
      email: new FormControl(emptyString),
      referenceNo: new FormControl(emptyString, [Validators.required]),
      amount: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(50000)]),
      pinCode: new FormControl(emptyString),
    });
  }

  initServiceFee(){
    this.webService.getWebSettingsByIdentifier("PHPay").subscribe((result) => {
      this.serviceFees = result.data[0].value[0];
    });
  }

  setActiveTab(){
    if(this.activeTab == 1){
      this.loader = true;
      if(this.phpayFormGroup.valid){
        this.loader = false;
        this.activeTab++;
        // this.phpayService.validateQR(this.phpayFormGroup.value).subscribe((result) => {
        //   if (result){
        //     this.activeTab++;
        //     this.loader = false;
        //   } else {
        //     PNotify.error({
        //       title: "Failed",
        //       text: result.item2,
        //     });
        //     this.loader = false;
        //   }
        // },
        // (error) => {
        //   // Handle error
        //   console.error("Error occurred:", error);
        //   // Here you can display an error notification or take other appropriate actions
        //   PNotify.error({
        //     title: "Invalid",
        //     text: error.error.item2,
        //   });
        //   this.loader = false;
        // });
      } else {
        PNotify.error({
          title: "Invalid",
          text: "Please fill all fields",
        });
        this.loader = false;
      }
    } else if(this.activeTab == 2){

    }
  }

  setActiveTabPrev(){
    if(this.activeTab == 1){
      PNotify.error({
        title: "Error",
        text: "Action Denied.",
      });
    } else {
      this.activeTab--;
    }
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  codeStatus : boolean = false;
  code : any;
  
  onCodeCompleted(code: string) {
    this.code = code;
    this.codeStatus = code.length == 6;
  }

  
  processPayment(){
    this.loader = true;
    this.phpayFormGroup.controls.pinCode.patchValue(this.code);

    this.phpayService.processPayment(this.phpayFormGroup.value)
      .subscribe((result) => {
        PNotify.success({
          title: "Success",
          text: result?.reason,
        });
        this.loader = false;
        this.code = "";
        this.codeStatus=false;
        this.closeModal.emit();
    }, error => {
      PNotify.error({
        title: "Failed",
        text: error.error.reason,
      });
      this.loader = false;
      this.code = "";
      this.codeStatus = false;
    });
  }

  getPayoutAmount(): any {
    // Get the amount value as a string and remove commas
    const amountString = String(this.phpayFormGroup.controls.amount.value).replace(/,/g, '');
    // Convert the cleaned string to a number
    const amount = Number(amountString) || 0;
    // Ensure the service fee is a number
    const serviceFee = Number(this.serviceFees?.ServiceFee) || 0;

    // Compute the payout amount
    const payoutAmount = amount - (amount * serviceFee);

    return this.makeMoney(payoutAmount);
  }

  getServiceFee(): any {
    // Get the amount value as a string and remove commas
    const amountString = String(this.phpayFormGroup.controls.amount.value).replace(/,/g, '');
    // Convert the cleaned string to a number
    const amount = Number(amountString) || 0;
    // Ensure the service fee is a number
    const serviceFee = (Number(this.serviceFees?.ServiceFee) || 0) * amount;

    return this.makeMoney(serviceFee);
  }

  getCommission(){
    // Get the amount value as a string and remove commas
    const amountString = String(this.phpayFormGroup.controls.amount.value).replace(/,/g, '');
    // Convert the cleaned string to a number
    const amount = Number(amountString) || 0;
    // Ensure the service fee is a number
    const serviceFee = (Number(this.serviceFees?.ServiceFee) || 0) * amount;

    const commission = serviceFee * (Number(this.serviceFees?.outletShare) || 0);

    return this.makeMoney(commission);
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  totalAmount(fee:any,amount:any){
    try  {
    return this.makeMoney(Number(fee) + Number(amount));
    } catch(e){
      return "0.00";
    }
  }

  getStatus(controlName: string, parentFormGroup): boolean {
    const control = parentFormGroup.get(controlName);
    return control ? control.touched && control.invalid : false;
  }

  getErrorMessage(controlName: string, parentFormGroup): string {
    const control = parentFormGroup.get(controlName);
    if (control && control.errors) {
      // You may need to adjust this logic based on the structure of your error messages
      if (control.errors.required) {
        return "This field is required.";
      } else if (control.errors.maxlength) {
        return `Maximum length exceeded (${control.errors.maxlength.requiredLength} characters allowed).`;
      } else if (control.errors.min) {
        return `Amount must be greater than ${control.errors.min.min}.`;
      } else if (control.errors.max) {
        return `Amount must be less than ${control.errors.max.max}.`;
      } // Add more error message handling logic as needed
    }
    return "";
  }
}

