import { DatePipe } from "@angular/common";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Select2OptionData } from "ng-select2";
import { BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { PosService } from "src/data/services/web/pos.service";
import { RolesService } from "src/data/services/web/roles.service";
import { UserService } from "src/data/services/web/user.service";
import { WebSettingsService } from "src/data/services/web/web-settings.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-pos-transaction-list",
  templateUrl: "./pos-transaction-list.component.html",
  styleUrls: ["./pos-transaction-list.component.less"],
})
export class PosTransactionListComponent implements OnInit {
  public posTransactionFilterFormGroup: FormGroup;
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public posTransactionService: PosService;
  public websettings: WebSettingsService;
  
  public emptyString = "";
  public roles: any;
  public role: any;
  public statuses: any;
  public status: any;
  public modalService: BsModalService;

  public accessToken: string;
  public newPosTransaction: boolean;
  public posTransactions: any;
  public pageCount: any;

  // Filters

  
  public searchByUsernameFormGroup : FormGroup;

  public showRowsOption: Array<Select2OptionData>;
  public applicationNameOptions: Array<Select2OptionData>;
  public userIdFilterOptions: Array<Select2OptionData>;
  public userIdFilter: any = [];
  public selectedPos : any;

  public roleLevelId;
  public roleId: any;
  public userId;
  public selectedUserId = 0;
  public cashiersAndManagers;
  public cashierID: any;
  
  @ViewChild('viewPosTransaction') viewPosTransaction: TemplateRef<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    private modalBService : NgbModal,
    private userService : UserService,
    private datepipe : DatePipe,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    posTransactionsService: PosService,
    modalService: BsModalService,
    websettings: WebSettingsService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.websettings = websettings;
    this.posTransactionService = posTransactionsService;
    this.modalService = modalService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
  }

  ngOnInit(): void {
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    this.userId = this.roleLevelId == 1 || this.roleLevelId == 2 ? 0 : this.localStorageCacheService.getStorage("userId");
    this.cashierID = this.localStorageCacheService.getStorage("userId");
    this.initSearchByUsernameFormGroup();
    this.getCashierUsernames();
    this.getShowRows();
  }

  initSearchByUsernameFormGroup(): void {
    this.searchByUsernameFormGroup = new FormGroup({
      showRows: new FormControl(this.emptyString),
      username: new FormControl(0),
      dateRange : new FormControl(this.emptyString),
      posTransactionNumber : new FormControl(this.emptyString),
    });

    this.getPosTransactions();
    this.searchByUsernameFormGroup.controls.username.valueChanges.subscribe(e =>{
      this.selectedUser(e);
    });
  }

  selectedUser(e) {
    this.selectedUserId = e;
  }

  getCashierUsernames() {
    this.userService.GetCashierUsernames().subscribe((result) => {
      this.cashiersAndManagers = result;
      // Set the username options from the API data
      this.userIdFilterOptions = result.map((user) => {
        return {
          id: user.id,
          text: user.name,
        };
      });

      if(this.roleLevelId == 3){
        this.searchByUsernameFormGroup.controls.username.setValue(this.userId, {onlySelf: true});
      }
     
    });
  }

  getShowRows() {
    this.websettings.getWebSettingsByIdentifier("SHOW_ROWS").subscribe((result) => {
     
      this.showRowsOption = result.data.map((status) => {
        return {
          id: status.parameter,
          text: status.value,
        };
      });
       
    });
  }

  private _sortBy: any;
  private _sort: any;

  getPosTransactions(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    const showRows : string = this.searchByUsernameFormGroup.controls.showRows.value;
    const posTransactionNumber : string = this.searchByUsernameFormGroup.controls.posTransactionNumber.value;
    const dateRange : string = this.searchByUsernameFormGroup.controls.dateRange.value;

    var userID : string;

    if(this.roleLevelId == 3){
      userID = this.cashierID;
    } else {
      userID = this.selectedUserId.toString();
    }



    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_showRows: string = showRows ? `showRows=${showRows}&` : "";
    const queryString_requestId: string = posTransactionNumber ? `transactionNumber=${posTransactionNumber}&` : "";
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_requestId +  queryString_dateRange + queryString_showRows;

      let endPointUrl: string;
      if (url) {
        endPointUrl = `${url}&${queryString}`;
      } else {
        if(this.roleLevelId == 3){
          if (queryString) {
            endPointUrl = `${environment.API_URL}/api/POSTransaction/CashierHistory/List/${this.cashierID}?${queryString}`;
          } else {
            endPointUrl = `${environment.API_URL}/api/POSTransaction/CashierHistory/List/${this.cashierID}${queryString}`;
          }
        }
        else {
          if (queryString) {
            endPointUrl = `${environment.API_URL}/api/POSTransaction/CashierHistory/List/${userID}?${queryString}`;
          } else {
            endPointUrl = `${environment.API_URL}/api/POSTransaction/CashierHistory/List/${this.selectedUserId ?this.selectedUserId : 0}${queryString}`;
          }
        }
      }
  
      this.posTransactionService.getCashierTransactions(endPointUrl).subscribe((result) => {
        if (result.data.length == 0 && result.pageNumber != 1) {
          this.getPosTransactions(result.previousPage, _sortBy, _sort);
        } else {
          this.posTransactions = result;
        }
      });
  }

  searchString = "";

  searchData(){
    // const showRows : string = this.searchByUsernameFormGroup.controls.showRows.value;
    // const cashierID : string = this.searchByUsernameFormGroup.controls.username.value;
    // const posTransactionNumber : string = this.searchByUsernameFormGroup.controls.posTransactionNumber.value;
    // const dateRange : string = this.searchByUsernameFormGroup.controls.dateRange.value;

    // console.log("a: ", showRows);
    // console.log("b: ", cashierID);
    // console.log("c: ", posTransactionNumber);
    // console.log("d: ", dateRange);

    this.getPosTransactions();
  };

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    
     
    this.getPosTransactions(
      `${environment.API_URL}/api/POSTransaction/CashierHistory/List/${this.selectedUserId ?this.selectedUserId : 0}?pageNumber=${
        page ? page : this.posTransactions.pageNumber}`,
      this._sortBy,
      this._sort
    );
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.posTransactions?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }

  // tslint:disable-next-line: variable-name
  getRole(role_id: any): string {
    if (role_id) {
      return this.roles.filter((x) => x.id === role_id)[0].name;
    } else {
      return "No Role";
    }
  }

  p:Number =1;
  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p = pageNumber;
    var userID : string;

    if(this.roleLevelId == 3){
      userID = this.cashierID;
    } else {
      userID = this.selectedUserId.toString();
    }



    return `${environment.API_URL}/api/POSTransaction/CashierHistory/List/${userID}?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  buildUserPhoto(user: any): any {
    return user.posTransaction.photoFormat + "," + user.posTransaction.photo;
  }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }

  viewPosTransactionProfile(id: any): void {
    
    const encId = this.cryptoService.set(this.data.authentication.privateKey, id);
    this.router.navigate(["acm", "pos-transaction-view", { id: encId }]);
  }

  editPosTransactionProfile(id: any): void {
     
    const encId = this.cryptoService.set(this.data.authentication.privateKey, id);
    this.router.navigate(["acm", "pos-transaction-edit", { id: encId }]);
  }

  createPosTransaction(): void {
    this.router.navigate(["acm", "pos-transaction-create"]);
  }

  pad(num:number, size:number): string {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}

  setSelectedPos(posTransaction){
    this.selectedPos = posTransaction;
    this.viewSelectedPos();
  }

  
  viewSelectedPos() {
    this.modalBService.open(this.viewPosTransaction, { centered: true, size: "xl" });
  }

}

interface ListFilter {
  searchString: string; // The string to search
  searchStringField: string; // The field to search
}
