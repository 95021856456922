import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { FormGroup } from "@angular/forms";
import { ConvertToApiDatetimePipe } from "src/app/pipes/convert-to-api-datetime.pipe";
import { DatePipe } from "@angular/common";
import { CryptoService } from "src/data/crypto/crypto.service";
import { environment } from "src/environments/environment";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class CompanyUsersService {
  private localStorageCacheService: LocalStorageCacheService;
  private convertToApiDatetimePipe: ConvertToApiDatetimePipe;

  constructor(
    private httpClient: HttpClient,
    private datePipe: DatePipe,
    private cryptoService: CryptoService,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService,
    convertToApiDatetimePipe: ConvertToApiDatetimePipe,
    private domSanitizer: DomSanitizer
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
    this.convertToApiDatetimePipe = convertToApiDatetimePipe;
  }
  private httpClientNoInterceptor: HttpClient;

  private baseAPIUrl = environment.API_URL;

  public createCompanyUser(companyUserMeta: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/CompanyUsers`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");
    const httpHeaders: HttpHeaders = new HttpHeaders().append("Authorization", "Bearer " + accessToken);
    return this.httpClient.post(endPointUrl, companyUserMeta, { headers: httpHeaders });
  }

  public editCompanyUser(companyUserMeta: FormGroup, id: any): Observable<any> {
  
    const endPointUrl = `${this.baseAPIUrl}/api/companyusers/${id}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.put(endPointUrl, companyUserMeta, { headers: httpHeaders });
  }

  public getCompanyUser(companyUserId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/companyusers/${companyUserId}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getCompanyUsers(url: any): Observable<any> {
    let endPointUrl: any;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    endPointUrl = `${url}`;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getUserLogs(monthYear: any, userId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/EmployeeTimekeepingLogs/Time`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    var json ={
      monthYear : monthYear,
      userId : userId
    };
    
    return this.httpClient.post(endPointUrl, json, { headers: httpHeaders });
  }

  // tslint:disable-next-line: typedef
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  // tslint:disable-next-line: typedef
  private log(message: string) {
    //
  }
}
