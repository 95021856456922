<app-loader *ngIf="!user"></app-loader>
<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex" *ngIf="user">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">Profile</h2>
          <small class="text-highlight font-weight-normal font-italic">Your personal information</small>
        </div>
        <div class="flex"></div>
        <div
          *ngIf="
            (user.companyUser?.mobileActivationDate == null || user.companyUser?.emailActivationDate == null) &&
            isExpired &&
            roleLevelId == 1
          "
        >
          <div (click)="resendEmailVerification(userId)" class="btn btn-md btn-secondary">
            <span class="d-none d-sm-inline mx-1">Resend Account Activation</span>
            <i data-feather="arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="padding sr">
        <div class="card">
          <div
            class="card-header bg-dark bg-img p-0 no-border"
            data-stellar-background-ratio="0.1"
            style="background-image: url(../../../assets/img/b3.jpg)"
            data-plugin="stellar"
          >
            <div class="bg-dark-overlay r-2x no-r-b">
              <div class="d-md-flex">
                <div class="p-4">
                  <div class="d-flex">
                    <a>
                      <span class="avatar w-64">
                        <img
                          class="w-48 avatar"
                          *ngIf="user.companyUser?.photo"
                          [src]="buildUserPhoto(user) | authImage | async"
                          alt="VIP"
                          onerror="this.src='https://govipcenterstorage.blob.core.windows.net/govipcenterassets/fileebd27018-2029-4596-8c77-0ecd48f3c7e3.png';"
                        />
                        <img
                          class="w-48 avatar"
                          *ngIf="!user.companyUser?.photo"
                          src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/fileebd27018-2029-4596-8c77-0ecd48f3c7e3.png"
                          alt="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/fileebd27018-2029-4596-8c77-0ecd48f3c7e3.png"
                        />
                        <i class="on"></i>
                      </span>
                    </a>
                    <div class="mx-3">
                      <h5 class="mt-2">{{ user.companyUser?.firstName }} {{ user.companyUser?.lastName }}</h5>
                      <div class="text-fade text-sm">
                        <span class="m-r">{{ role }}</span>
                        <small>
                          <span class="vip-input-text__activated" [ngClass]="{ active: user.companyUser?.status }">
                            {{ user.companyUser?.status ? " - Active" : " - Deactivated" }}
                          </span></small
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <span class="flex"></span>
              </div>
            </div>
          </div>
          <div class="p-3">
            <div class="d-flex">
              <ul class="nav nav-pills">
                <li class="nav-item">
                  <a class="nav-link active" href="#" data-toggle="tab" data-target="#tab_4">Profile</a>
                </li>
                <!-- <li class="nav-item" *ngIf="roleId != 19 && roleId != 10 && roleId != 12">
                  <a class="nav-link" href="#" data-toggle="tab" data-target="#tab_5">Timekeeping Logs</a>
                </li> -->
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-lg-12">
            <div class="tab-content">
              <div class="tab-pane fade show active" id="tab_4">
                <div class="card">
                  <div class="card-header">
                    <strong>Staff's Profile</strong>
                    <div *ngIf="roleId == 1" (click)="editCompanyUserProfile()" style="float: right">
                      <button class="btn btn-md btn-raised btn-wave btn-icon btn-rounded mb-2 blue text-white">
                        <span class="fa fa-edit"></span>
                      </button>
                    </div>
                  </div>
                  <div class="px-4 py-4">
                    <div class="row mb-2">
                      <div class="col-6">
                        <small class="text-muted">Username</small>
                        <div class="my-2">{{ user?.username }}</div>
                      </div>
                      <div class="col-6">
                        <small class="text-muted">Email Address</small>
                        <div class="my-2">{{ user?.email }}</div>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-6">
                        <small class="text-muted">Employee Number</small>
                        <div class="my-2">{{ user.companyUser?.employeeId }}</div>
                      </div>
                      <div class="col-6">
                        <small class="text-muted">Contact Number</small>
                        <div class="my-2">{{ user.companyUser?.contactNumber }}</div>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-6">
                        <small class="text-muted">Birth Date</small>
                        <div class="my-2">{{ user.companyUser?.birthDate | date: "MMMM dd, yyyy" }}</div>
                      </div>
                      <div class="col-6">
                        <small class="text-muted">Hiring Date</small>
                        <div class="my-2">{{ user.companyUser?.hiringDate | date: "MMMM dd, yyyy" }}</div>
                      </div>
                    </div>
                    <div>
                      <small class="text-muted">Address</small>
                      <div class="my-2">{{ user.companyUser?.address }}</div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="px-4 py-4">
                    <div class="row mb-2">
                      <div class="col-6">
                        <small class="text-muted">Emergency Contact Number</small>
                        <div class="my-2">{{ user.companyUser?.emergencyContactNumber }}</div>
                      </div>
                      <div class="col-6">
                        <small class="text-muted">Emergency Contact Name</small>
                        <div class="my-2">{{ user.companyUser?.emergencyContactName }}</div>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-6">
                        <small class="text-muted">Emergency Contact Relationship</small>
                        <div class="my-2">{{ user.companyUser?.emergencyContactRelationship }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade show" id="tab_5">
                <div class="card">
                  <div class="card-header">
                    <strong>Logs</strong>
                  </div>
                  <app-timekeeping-logs [userId]="userId"></app-timekeeping-logs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<div id="content" class="container-fluid" *ngIf="user">
  <!--  -->
</div>
