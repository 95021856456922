import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { Select2OptionData } from 'ng-select2';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { ManagerUsersService } from 'src/data/services/web/manager-users.service';
import { UserService } from 'src/data/services/web/user.service';
import { VipcoTransactionService } from 'src/data/services/web/vipco-transaction.service';
import { WalletService } from 'src/data/services/web/wallet.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { TransactionService } from 'src/data/services/web/transaction.service';
import { CurrencyPipe, DatePipe } from '@angular/common';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5
import { ExportExcelService } from 'src/data/services/web/export-excel.service';
import { AngularFirestore } from '@angular/fire/firestore';


@Component({
  selector: 'app-vips-admin',
  templateUrl: './vips-admin.component.html',
  styleUrls: ['./vips-admin.component.less']
})
export class VipsAdminComponent implements OnInit {

  public localStorageCacheService: LocalStorageCacheService;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value:any = "";
  emptyString: any = "";

  searchByFormGroup: FormGroup;

  users: any = [];
  // [
  //   { "id": 1, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 2, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 3, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 4, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 5, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 6, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 7, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true }
  // ];
  
  transactions: any = [];
  transactionsExcel: any = [];
  // [
  //   { "id": 1, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10001, "rewards": 50, "status": 2 },
  //   { "id": 2, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10002, "rewards": 50, "status": 2 },
  //   { "id": 3, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10003, "rewards": 50, "status": 2 },
  //   { "id": 4, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10004, "rewards": 50, "status": 2 },
  //   { "id": 5, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10005, "rewards": 50, "status": 2 },
  //   { "id": 6, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10006, "rewards": 50, "status": 2 },
  //   { "id": 7, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10007, "rewards": 50, "status": 2 }
  // ];

  constructor(localStorageCacheService: LocalStorageCacheService,
    private modalServe: NgbModal,
    private usersService: ManagerUsersService,
    private walletService: WalletService,
    private transactionService: TransactionService,
    private vipcoTransactionService: VipcoTransactionService,
    private userService: UserService,
    private datepipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private websettings: WebSettingsService,
    public webSettingsService: WebSettingsService,
    private store: AngularFirestore,
    public ete: ExportExcelService) { 
   
    this.localStorageCacheService = localStorageCacheService;
  }

  ngOnInit(): void {
    this.initSearchByUsernameFormGroup();
    this.initFilterUserFormGroup();
    this.initSearchTransactionFormGroup();
    this.getUserTypes();
    this.value = this.localStorageCacheService.getStorage("forQR");
    
    if(this.selectedManager != 0){
      this.getManagerUser(this.selectedManager);
      this.getVipsUsers();
    }

    this.getManagers();
    this.initReceipt();
    //this.getTransaction();
    //this.getWallet();
  }

  public userIdFilterOptions: Array<Select2OptionData>;
  public searchByUsernameFormGroup: FormGroup;
  public searchTranscationFormGroup: FormGroup;
  public filterUserFormGroup: FormGroup;
  selectedManager: any = 0;
  userCount: any= 0;
  managerCount: any= 0;
  riderCount: any= 0;
  dataForExcel = [];

  userID: any;
  username: any;

getVipsUsers(){
  this.vipcoTransactionService.getVipsLinkedUsersByAdmin(this.selectedManager).subscribe((result) => {
    this.users = (result.response_data) != null ? result.response_data : 0;
    this.userAccumulator = result.response_data != null ? result.response_data : [];
    if (result.response_data.length != 0){
      this.userCount = (result.response_data).filter(x=> x.Usertype != "LEVEL_6" && x.Usertype != "LEVEL_7" && x.Usertype != "LEVEL_8" && x.Usertype != "LEVEL_9").length;
      this.managerCount = (result.response_data).filter(x=> x.Usertype == "LEVEL_6" || x.Usertype == "LEVEL_7").length;
      this.riderCount = (result.response_data).filter(x=> x.Usertype == "LEVEL_8" || x.Usertype == "LEVEL_9").length;
    }
   
    // if (result.data.length == 0 && result.pageNumber != 1) {
    //   this.getVIPCOTransactions(result.previousPage, _sortBy, _sort);
    // } else {
    //   this.transactions = result;
    // }
  });
}

initSearchByUsernameFormGroup() {
  this.searchByUsernameFormGroup = new FormGroup({
    status: new FormControl(0),
    username: new FormControl(null),
    dateRange : new FormControl(null),
    requestId : new FormControl(null),
    showRows: new FormControl(null),
    transactFrom: new FormControl(1)
  });

  this.searchByUsernameFormGroup.controls.username.valueChanges.subscribe(e =>{
    this.selectedUser(e);
    this.getManagerUser(this.selectedManager);
    this.getVipsUsers();
    this.getWallet();
    this.clearValue();
    this.filterUserFormGroup.reset();
  });
}

initSearchTransactionFormGroup() {
  this.searchTranscationFormGroup = new FormGroup({
    status: new FormControl(0),
    username: new FormControl(null),
    dateRange : new FormControl(null),
    requestId : new FormControl(null),
    showRows: new FormControl(null),
    searchString: new FormControl(this.emptyString)
  });
}

userOptions: Array<Select2OptionData>;;
getUserTypes() {
  this.websettings.getWebSettingsByIdentifier("VIPS_USER_TYPES").subscribe((result) => {
    this.userOptions = result.data.map((status) => {
      return {
        id: status.parameter,
        text: status.value,
      };
    });
  });
}

initFilterUserFormGroup() {
  this.filterUserFormGroup = new FormGroup({
    searchString: new FormControl(null),
    userType: new FormControl(null)
  });

  this.filterUserFormGroup.controls.searchString.valueChanges.subscribe(e =>{
    if(e){
      this.users = this.userAccumulator.filter(x => x.FullName.toUpperCase().includes(e.toUpperCase()) || x.users?.MobileNo.includes(e));
    } else {
      this.users = this.userAccumulator;
    }
  });

  this.filterUserFormGroup.controls.userType.valueChanges.subscribe((e) => {
    this.selectedUserType(e);
  })
}


clearValue(){
  this.filterUserFormGroup.controls.searchString.patchValue('');
  this.users = this.userAccumulator;
}

selectedUserType(e) {
  switch(e){
    case "User":
      this.users = this.userAccumulator
        .filter(x => x.Usertype == "LEVEL_0" || x.Usertype == "LEVEL_1" || x.Usertype == "LEVEL_2" || x.Usertype == "LEVEL_3" || x.Usertype == "LEVEL_4" || x.Usertype == "LEVEL_5")
      break;
    case "Merchant":
      this.users = this.userAccumulator.filter(x => x.Usertype == "LEVEL_6" || x.Usertype == "LEVEL_7")
      break;
    case "Rider":
      this.users = this.userAccumulator.filter(x => x.Usertype == "LEVEL_8" || x.Usertype == "LEVEL_9")
      break;
    default: 
      this.users = this.userAccumulator;
  }
}

selectedUser(e) {
  this.selectedManager = e != null ? e : 0;

  if(e){
    this.getTransaction();
    this.getRewards(e);
  } else {
    this.transactions = [];
  }
  //alert(this.selectedManager);
}

getManagers() {
  this.userService.getManagersUsername().subscribe((result) => {
  
    this.userIdFilterOptions = result.map((user) => {
      return {
        id: user.id,
        text: user.name,
      };
    });

  });
}

isRider: boolean = false;
getUserType(userType){
  switch (userType) {
    case "LEVEL_0":
    case "LEVEL_1":
    case "LEVEL_2":
    case "LEVEL_3":
    case "LEVEL_4":
    case "LEVEL_5":
      this.isRider = false;
      return "User";
    case "LEVEL_6":
    case "LEVEL_7":
      this.isRider = false;
      return "Merchant";
    case "LEVEL_8":
    case "LEVEL_9":
      this.isRider = true;
      return "Rider";
    default:
      break;
  }
}

  getWallet(): void {
    if(this.selectedManager){
      this.walletService.getWalletByAdmin(this.selectedManager).subscribe((result) => {
        this.wallet = result.wallet;
        this.username = result.username;
      });
    }
  }

  getVipcoWallet(wallet){
    let vipcowallet = wallet.filter(x => x.ProductId == "VIPCO");
    return vipcowallet[0]?.eAmount;
  }

  wallet: any;
  user: any = [];
  userAccumulator:any = [];
  getManagerUser(id: any): void {
    this.usersService.getManagerUser(id).subscribe((result) => {
      if(result){
        this.user = result.data;
      }
    });
  }


  searchData(){

  }

  initSearchFormGroup(): void {
    this.searchByFormGroup = new FormGroup({
      showRows: new FormControl(null),
      dateRange: new FormControl(this.emptyString),
      searchString: new FormControl(null),
    });
  }

  @ViewChild('riderViewModal') riderViewModal: TemplateRef<any>;
  @ViewChild('merchantViewModal') merchantViewModal: TemplateRef<any>;
  detailRiderModal: NgbModalRef;
  detailMerchantModal: NgbModalRef;
  selectedRider:any;
  selectedMerchant:any;
  selectedNumber: any;
  async viewUser(user: any){
    this.selectedNumber = user?.MobileNo;
    let userNumber = user?.MobileNo;
    if(this.getUserType(user?.Usertype) == "Rider"){
      await this.store.collection("rider_registration").doc(userNumber).valueChanges().subscribe((val) => {
        this.selectedRider = val;
      });
      debugger
      this.detailRiderModal = this.modalServe.open(this.riderViewModal, { centered: true, size: "md" });
    } else if(this.getUserType(user?.Usertype) == "Merchant"){
      await this.store.collection("business_registration").doc(userNumber).valueChanges().subscribe((val) => {
        this.selectedMerchant = val;
      });
      this.detailMerchantModal = this.modalServe.open(this.merchantViewModal, { centered: true, size: "md" });
    }
  }

  imgSrc:string;
  onClick(event){
    const imgElem = event.target;
    var target = event.target || event.srcElement || event.currentTarget;
    var srcAttr = target.attributes.src;
    this.imgSrc = srcAttr.nodeValue;
  }

  receipt: any;
  initReceipt() {
    this.webSettingsService.getWebSettingsByIdentifier("GoVIPX_QR_TEMPLATE").subscribe((result) => {
      this.receipt = result.data;
    });
  }

  openQR(content: any){
    this.modalServe.open(content, { centered: true, size: "lg" });
  }

  getQR(myQRTemplate: any){
    var qrtemplate = myQRTemplate.innerHTML;

    var Newstr = (this.receipt[0].value)
      .replace("{{QR}}", qrtemplate);
    
    this.PrintReceipt(Newstr);
  }

  PrintReceipt(Newstr: any)
  {
    var mywindow = window.open('', 'PRINT', 'height=700,width=1000');
    mywindow.document.write(Newstr);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    this.modalServe.dismissAll();

    return true;
  }

  public loader = false;
  p: number = 1;
  public pageRows: any = 10;

  getTransaction(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader = true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }
    //debugger
    this.loader = true;
    //const requestId : string = this.searchTranscationFormGroup.controls.requestId.value;
    const dateRange : string = this.searchTranscationFormGroup.controls.dateRange.value;
    //const statusId : string = this.searchTranscationFormGroup.controls.status.value;
    const searchString : string = this.searchTranscationFormGroup.controls.searchString.value;
    const showRows : string = this.searchTranscationFormGroup.controls.showRows.value;
    const userId : string = this.selectedManager ? this.selectedManager : 0;

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    //const queryString_requestId: string = requestId ? `requestId=${requestId}&` : "";
    //const queryString_statusId: string = statusId ? `statusId=${statusId}&` : "";
    //const queryString_transactFrom: string = transactFrom ? `transactFrom=${transactFrom}&` : "";
    const queryString_userId: string = userId ? `userId=${userId}&` : "";
    const queryString_searchString: string = searchString ? `requestId=${searchString}&` : "";
    const queryString_showRows: string = showRows ? `showRows=${showRows}&` : `showRows=10&`;
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    var queryString = queryString_orderby + queryString_order + queryString_userId + queryString_dateRange + queryString_showRows + queryString_searchString;
    if (fromSearch) {
      this.p = 1;
      queryString =`pageNumber=${1}&pageSize=${10}&${queryString}`;
    }

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/VIPCOTransactions/VipsTransactions?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/VIPCOTransactions/VipsTransactions?${queryString}`;
      }
    }

    this.transactionService.getTransaction(endPointUrl.slice(0, -1)).subscribe((result) => {
      if(result){
        if (result.data.length == 0 && result.pageNumber != 1  && fromSearch == false) {
          this.getTransaction(result.previousPage, _sortBy, _sort);
        } else {
          this.transactions = result;
        }
        this.loader = false;
      }
    },
    (error) => {
      if (error.status === 400) {
        PNotify.notice({
          title: "Warning",
          text: "Username is required.",
        });
      } else {

        PNotify.error({
          title: "Error",
          text: "Server unavailable. PLease try again later.",
        });
      }
      this.loader = false;
    });
  }

  getTransactionExcel(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader = true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }
    //debugger
    this.loader = true;

    const dateRange : string = this.searchTranscationFormGroup.controls.dateRange.value;
    //const statusId : string = this.searchTranscationFormGroup.controls.status.value;
    const searchString : string = this.searchTranscationFormGroup.controls.searchString.value;
    const showRows : string = this.searchTranscationFormGroup.controls.showRows.value;
    const userId : string = this.selectedManager ? this.selectedManager : 0;

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    //const queryString_requestId: string = requestId ? `requestId=${requestId}&` : "";
    //const queryString_statusId: string = statusId ? `statusId=${statusId}&` : "";
    //const queryString_transactFrom: string = transactFrom ? `transactFrom=${transactFrom}&` : "";
    const queryString_userId: string = userId ? `userId=${userId}&` : "";
    const queryString_searchString: string = searchString ? `requestId=${searchString}&` : "";
    //const queryString_showRows: string = showRows ? `showRows=${showRows}&` : `showRows=10&`;
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    var queryString = queryString_orderby + queryString_order + queryString_userId + queryString_dateRange + queryString_searchString;
    if (fromSearch) {
      this.p = 1;
      queryString =`pageNumber=${1}&pageSize=${10}&${queryString}`;
    }

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/VIPCOTransactions/VipsTransactionsExcel?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/VIPCOTransactions/VipsTransactionsExcel?${queryString}`;
      }
    }

    this.transactionService.getTransaction(endPointUrl.slice(0, -1)).subscribe((result) => {
      if(result){
        if (result.data.length == 0 && result.pageNumber != 1  && fromSearch == false) {
          this.getTransactionExcel(result.previousPage, _sortBy, _sort);
        } else {
          this.transactionsExcel = result;
          this.generateExcel("Vips Transactions", result?.data);
        }
        this.loader = false;
      }
    },
    (error) => {
      if (error.status === 400) {
        PNotify.notice({
          title: "Warning",
          text: "Username is required.",
        });
      } else {
        PNotify.error({
          title: "Error",
          text: "Server unavailable. PLease try again later.",
        });
      }
      this.loader = false;
    });
  }

  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p=pageNumber;
    return `${environment.API_URL}/api/VIPCOTransactions/VipsTransactions?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  createdDate = "";
  searchString = "";

  private _sortBy: any;
  private _sort: any;

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.createdDate = field == "createdDate" ? "th-inner sortable both " + sortBy : "";
    
    this.getTransaction(
      `${environment.API_URL}/api/VIPCOTransactions/VipsTransactions?pageNumber=${
        page ? page : this.transactions.pageNumber
      }`,
      this._sortBy,
      this._sort
    );
  }

  dailyReward: any;
  weeklyReward: any;
  monthlyReward: any;
  totalReward: any;
  getRewards(managerId: any){
    this.walletService.getRewardSummary(managerId).subscribe((result) => {
      this.dailyReward = result.daily;
      this.weeklyReward = result.weekly;
      this.monthlyReward = result.monthly;
      this.totalReward = result.total;
    });
  }

  public Transactions = [];
  generateExcel(name: any, trans: any): void {  
    //for(var i = 0; i < trans.length; i++){
    this.Transactions = [];
    //this.dataForExcel = [];
    
    for(var x = 0; x < trans.length; x++){

      let status = "";

      if(trans[x]?.statusId == 1){
        status = "Failed"
      }  else if (trans[x]?.statusId == 2){
        status = "Completed"
      } else if (trans[x]?.statusId == 3){
        status = "Pending"
      }
      
      let meta =
        [{
            "User Type": trans[x].transactionName.split(" - ")[1],
            "Reference Number": trans[x].requestId,
            "Completed by": trans[x].user.username,
            "Created Date": trans[x].createdDate,
            "Completed date":	trans[x].completedDate,
            "Amount":	trans[x].amount,
            "Fund Origin":	trans[x].openingBalance,
            "Fund Destination":  this.makeMoney(trans[x].openingBalance + trans[x].amount),
            "Status":	status
        }];

      this.Transactions.push(meta);
      //this.dataForExcel.push(Object.values(this.Transactions[x][0]));
    }

    for(var x = 0; x < this.Transactions.length; x++){
      this.dataForExcel.push(Object.values(this.Transactions[x][0]));
    }

    let reportData = {
      title: name + ' Report',
      data: this.dataForExcel,
      headers: Object.keys(this.Transactions[0][0])
    }
    debugger
    
    this.ete.exportExcel(reportData, name);
    //}
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }
}


// {
//   this.TransactionsExcel = [];
//     //let TransactionsExcel = [];
//     for(var x = 0; x < trans.length; x++){
      
//       let status = "";

//       if (trans?.statusId == "1"){
//         status = "Failed"
//       }  else if (trans?.statusId == "2"){
//         status = "Completed"
//       } else if (trans?.statusId == "3"){
//         status = "Pending"
//       }

   
//       let meta = [
//         {
//             "User Type": trans[x].transactionName.split(" - ")[1],
//             "Reference Number": trans[x].requestId,
//             "Completed by": trans[x].user.username,
//             "Created Date": trans[x].createdDate,
//             "Completed date":	trans[x].completedDate,
//             "Amount":	trans[x].amount,
//             "Fund Origin":	trans[x].openingBalance,
//             "Fund Destination":  this.makeMoney(trans[x].openingBalance + trans[x].amount),
//             "Status":	status
//         }
//       ]

//       this.TransactionsExcel.push(meta[0]);
//     }
//     debugger
//     for(var x = 0; x < this.TransactionsExcel.length; x++){
//       this.dataForExcel.push(Object.values(this.TransactionsExcel[x]));
//     }

//     debugger
//     let reportData = {
//       title: name + " Report ",
//       data: this.dataForExcel,
//       headers: Object.keys(this.TransactionsExcel[0])
//     }
    
//     this.ete.exportExcel(reportData, name);
// }