import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-status-table-data-v2",
  templateUrl: "./status-table-data-v2.component.html",
  styleUrls: ["./status-table-data-v2.component.less"],
})
export class StatusTableDataV2Component implements OnInit {
  @Input("status") status: number;

  constructor() {}

  ngOnInit(): void {}
}
