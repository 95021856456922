


import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { InvoiceComponent } from 'src/app/elements/invoice/invoice.component';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { MultisysService } from 'src/data/services/web/multisys.service';
import { PrintService } from 'src/data/services/web/print.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { IbayadService } from 'src/data/services/web/ibayad.service';
import { TransactionService } from 'src/data/services/web/transaction.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-instapay-transaction',
  templateUrl: './instapay-transaction.component.html',
  styleUrls: ['./instapay-transaction.component.less']
})
export class InstapayTransactionComponent implements OnInit {

  @ViewChild(InvoiceComponent ) child: InvoiceComponent ; 

  @Input() requestId: string;
  public data :  any;
  public loader : boolean = false;
  public receipt: any;
  public isA4 = new FormControl();
  roleLevel:any;
  public isTicketActive : any = false;

  public transactionService: TransactionService;
  public dti: any;
  public tin: any;
  public address: any;
  
  constructor(private ibayadService : IbayadService, 
    private router: Router,
    private localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    public datepipe: DatePipe,
    public printService: PrintService,
    private webService: WebSettingsService, 
    transactionService: TransactionService) {
    this.transactionService = transactionService;
    PNotifyButtons;
  }

  
  @Output("closeModal") closeModal = new EventEmitter();
  @ViewChild('vipLogo') vipLogo: ElementRef;

  ngOnInit(): void {
    this.loader = true;
    this.getTransaction();
     this.initReceipt();
    this.roleLevel = this.localStorageCacheService.getStorage("role_level");
  }

  openTicket(){

    this.isTicketActive = true;
  }

  initReceipt() {
    this.webService.getWebSettingsByIdentifier("INVOICE_RECEIPT").subscribe((result) => {
      this.receipt = result.data;
    });
  }

  responseJson : any;
  getTransaction() {
    this.ibayadService.getTransaction(this.requestId).subscribe((billers) => {
       this.data = billers;
       this.responseJson = JSON.parse(this.data.responseJson);
       this.loader = false;
       this.initReceiptDetails(billers.transactByUser.cashierUser.managerUserId);
    }, error => {
      this.loader = false;
    });
  }

  initReceiptDetails(managerUserId: any){
    this.transactionService.getReceiptDetails(managerUserId).subscribe((result) => {
      this.dti = result.dti;
      this.tin = result.tin != null ? result.tin : "***-***-***-***";
      this.address = result.address;
    });
  }

  rejectTransaction(){
    this.loader = true;
    this.ibayadService.rejectTransaction(this.requestId).subscribe((billers) => {
      PNotify.success({
        title: "Failed Status",
        text: "Transaction successfully rejected.",
      });
      this.loader = false;
      this.closeModal.emit();
   }, error => {
    PNotify.error({
      title: "Failed",
      text: "There's a problem occured. Please contant ACM support.",
    });
     this.loader = false;
   });

  }

  completeTransaction(){
    this.loader = true;
    this.ibayadService.completeTransaction(this.requestId).subscribe((billers) => {
      PNotify.success({
        title: "Information",
        text: "Transaction successfully completed.",
      });
      this.loader = false;
      this.closeModal.emit();
   }, error => {
    PNotify.error({
      title: "Failed",
      text: "There's a problem occured. Please contant GO VIP support.",
    });
     this.loader = false;
   });

  
  }

  reProcessPayment(){
    this.loader = true;
    this.ibayadService.reProcessPayment(
      this.data.requestJsonData,
      this.data.billerName,
      this.data.billerCategory,
      this.requestId)
      .subscribe((biller) => {
        PNotify.success({
          title: "Payment Success",
          text: biller.reason,
        });
        this.loader = false;
        this.closeModal.emit();
      
    }, error => {

      if(error.error.status == 490){
        PNotify.error({
          title: "Payment Failed",
          text: error.error.reason,
        });
        this.loader = false;

      }else{
        PNotify.error({
          title: "Payment Failed",
          text: error.error.reason,
        });
        this.loader = false;
        this.closeModal.emit();

      }

     



    });
}

  buildUserPhoto(user: any): any {
    if(user){
    return user.photoFormat + "," + user.photo;
    }
  }
  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  printReceipt(){

    var createdDate = this.datepipe.transform(this.data.createdDate, 'MMM-dd-yyyy');
    var transTime = this.datepipe.transform(this.data.createdDate, 'hh:mm:ss a');
    var reqId = this.data.requestId;
    var cashier = this.data.transactByUser.cashierUser.firstName + ' ' + this.data.transactByUser.cashierUser.lastName;
    
    var billerName = this.data.billerName;
    var refNo = this.data.billerPartnerRefNo;
    var peyeeContact = this.data.senderContactNumber;
    var billAmount = (parseFloat(this.data.amount) - parseFloat(this.data.billerFee)).toString(); 
    var amountPaid =  this.data.amount;
    var fee = this.makeMoney(this.data.billerFee);
    var billerAccountName = this.data.billerBank;
    var billerAccountNo = this.data.billerAccountNo;
    var vipLogo = this.vipLogo.nativeElement.innerHTML;

    if(this.isA4.value){
      var Newstr = ((this.receipt.filter(x => x.parameter == "IN58mm_InstaPeso")[0]).value).replace("{{Date}}", createdDate);
      Newstr = (Newstr).replace("{{Time}}", transTime);
      Newstr = (Newstr).replace("{{Cashier}}", cashier);

      Newstr = (Newstr).replace("{{RefNo}}", refNo);
      Newstr = (Newstr).replace("{{PayeeContact}}", peyeeContact);
      
      Newstr = (Newstr).replace("{{BankName}}", billerAccountName);
      Newstr = (Newstr).replace("{{BankAccountNumber}}", billerAccountNo);
      Newstr = (Newstr).replace("{{BillerName}}", billerName);
      Newstr = (Newstr).replace("{{BillAmount}}", parseFloat(billAmount).toFixed(2));
      Newstr = (Newstr).replace("{{AmountPaid}}", parseFloat(amountPaid).toFixed(2));
      Newstr = Newstr.replace("{{receiptLogo}}", vipLogo);
      Newstr = Newstr.replace("{{dti}}", this.dti);
        Newstr = Newstr.replace("{{tinNo}}", this.tin);
      Newstr = Newstr.replace("{{address}}", this.address);
      
      this.PrintReceipt(Newstr);
      } else {
  
        var Newstr = ((this.receipt.filter(x => x.parameter == "IN58mm_InstaPeso")[0]).value).replace("{{Date}}", createdDate);
        Newstr = (Newstr).replace("{{Time}}", transTime);
        Newstr = (Newstr).replace("{{RecID}}", reqId);
        Newstr = (Newstr).replace("{{Cashier}}", cashier);
        Newstr = (Newstr).replace("{{BillerName}}", billerName);
        Newstr = (Newstr).replace("{{RefNo}}", refNo);
        Newstr = (Newstr).replace("{{BankName}}", billerAccountName);
        Newstr = (Newstr).replace("{{BankAccountNumber}}", billerAccountNo);
        Newstr = (Newstr).replace("{{PayeeContact}}", peyeeContact);
        Newstr = (Newstr).replace("{{BillAmount}}", parseFloat(billAmount).toFixed(2));
        Newstr = (Newstr).replace("{{billerFee}}", fee);
        Newstr = (Newstr).replace("{{AmountPaid}}", parseFloat(amountPaid).toFixed(2));
        Newstr = Newstr.replace("{{receiptLogo}}", vipLogo);
        Newstr = Newstr.replace("{{dti}}", this.dti);
        Newstr = Newstr.replace("{{tinNo}}", this.tin);
        Newstr = Newstr.replace("{{address}}", this.address);

     this.PrintReceipt(Newstr);
   }
  }

  PrintReceipt(Newstr: any)
  {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write(Newstr);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    return true;
  }
}
