import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { UserService } from "./user.service";

@Injectable()
export class OnlyLoggedInSalesUsersGuard implements CanActivate {
  constructor(private userService: UserService) {}

  async canActivate(): Promise<boolean> {
    return await this.userService.isLoggedInSalesUser();
  }
}
