<app-loader *ngIf="loader"></app-loader>

<div *ngIf="!loader" [class.isPrinting]="printService.isPrinting" class="col-lg-12 col-md-12 col-sm-12 row">
  <div class="col-lg-12 col-md-12 col-sm-12">
    <div class="card">
      <div class="list list-row">
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-md d-none d-md-block color-red">
              <span class="text-dark">Request ID: </span> <strong>{{ requestId }}</strong>
            </div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <a style="color: blue" (click)="printReceipt()">Print Receipt <span class="fa fa-print"></span></a> <br />
              <!-- <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" [formControl]="isA4" />
                <label class="form-check-label text-muted" for="flexCheckDefault" style="font-size: 12px">
                  Print via A4
                </label>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-6 col-md-6 col-sm-6">
    <div class="card">
      <div class="list list-row">
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">
              <strong>Partner</strong>
            </div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <img
                src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/fileada7b1c3-ccd6-4361-8a37-a774f8a2ae8f.png"
                height="41"
                class="img-responsive"
                style="object-fit: cover"
              />
            </div>
          </div>
        </div>

        <hr />

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Created Date</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.createdDate | date: "MMM dd, yyyy hh:mm:ss a" }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Completed Date</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.completedDate | date: "MMM dd, yyyy hh:mm:ss a" }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Fullname</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.fullname }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Contact No</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.contactNo }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Email</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.email }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-6 col-md-6 col-sm-6">
    <div class="card">
      <div class="list list-row">
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">
              <strong
                >{{ data?.transactByUser.cashierUser.firstName + " " + data?.transactByUser.cashierUser.lastName }}
                <br />
                {{ data?.transactByUser.email }}</strong
              >
            </div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <img
                src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/filea7a2449f-a14a-4909-85f3-cf35a4b8b68b.png"
                height="50"
                class="avatar w-40 img-responsive"
                style="object-fit: cover"
              />
            </div>
          </div>
        </div>

        <hr />

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Transaction Status</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x"><app-status-table-data [status]="data?.statusId"></app-status-table-data></div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Settlement Status</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <app-settlement-status-data [status]="data?.settlementStatus"> </app-settlement-status-data>
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Reference No</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.referenceNo }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Payout Amount</div>
          </div>
          <div class="no-wrap">
            <div>
              {{ getPayoutAmount() | number: "1.2-2" }}
              <i
                class="fa fa-exclamation-circle"
                tooltip="The payout amount is the total amount less the service fee. This is the amount of cash to be given to the client."
              ></i>
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Service Fee</div>
          </div>
          <div class="no-wrap">
            {{ data?.serviceFee | number: "1.2-2" }}
            <i class="fa fa-exclamation-circle" tooltip="The service fee is 2% of the total amount."></i>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Commission</div>
          </div>
          <div class="no-wrap">
            <div>
              {{ data?.outletShare | number: "1.2-2" }}
              <i class="fa fa-exclamation-circle" tooltip="The commission is 50% of the service fee."></i>
            </div>
          </div>
        </div>

        <hr />

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Total Amount</div>
          </div>
          <div class="no-wrap">
            <div>
              {{ data?.amount | number: "1.2-2" }}
              <i class="fa fa-exclamation-circle" tooltip="This is the withdrawal amount."></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div #vipLogo hidden>
  <img
    src="https://juanpaycdn.azureedge.net/juanpayassets/LOGO_logowithtagline-removebg-preview.png"
    alt="Go-VIP-Logo-Logo-Small"
    height="80px"
  />
</div>
