import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { environment } from "src/environments/environment";
import { FormGroup } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class ApplicationService {
  private localStorageCacheService: LocalStorageCacheService;

  constructor(
    private httpClient: HttpClient,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
  }
  private httpClientNoInterceptor: HttpClient;

  private baseAPIUrl = environment.API_URL;

  public getApplications(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Applications`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getApplicationCount(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Applications/GetApplicationCount`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getFavorites(userId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Applications/Favorites/${userId}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.get(endPointUrl, { headers: httpHeaders });
  }

  

  public getDeclinedApplications(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Applications/Declined`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getApplicationByParameter(id:any, param: any,applicationId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Applications/${id}/${param}/${applicationId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getApplicationById(id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Applications/${id}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getApplicationsList(url: any): Observable<any> {
    const endPointUrl = url;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getAllocateFundSingle(id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/websettings/${id}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public updateApplication(id: any, json: any, isProceed: any, i: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Applications/${id}/${isProceed}/${i}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.put(endPointUrl, json, { headers: httpHeaders });
  } //submitRequirements

  public submitRequirements(id: any, json: any, isProceed: any, i: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Applications/SendahRequirements/${id}/${isProceed}/${i}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.put(endPointUrl, json, { headers: httpHeaders });
  } 

  public saveAndEmailRemarks(id: any, appJson: any, isProceed: any, i: any, remarks: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Applications/EmailRemarks`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      var json = {
        id: id,
        isProceed: isProceed,
        i: i,
        remarks: remarks,
        application: appJson
      };

    return this.httpClient.post(endPointUrl, json, { headers: httpHeaders });
  }

  public saveFTCCNotify(id: any, json: any, i: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Applications/NotifyFTCC/${id}/${i}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.put(endPointUrl, json, { headers: httpHeaders });
  }

  public updateDA5Files(id: any, json: any, i: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Applications/DA5Files/${id}/${i}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.put(endPointUrl, json, { headers: httpHeaders });
  }

  public updateDA5Collateral(id: any, json: any, i: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Applications/DA5Collateral/${id}/${i}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.put(endPointUrl, json, { headers: httpHeaders });
  }

  public updateDA5S1(id: any, json: any, i: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Applications/DA5Application/${id}/${i}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.put(endPointUrl, json, { headers: httpHeaders });
  }

  public updateApplicationDeclined(id: any, json: any, isProceed: any, i: any, remarks: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Applications/Declined/${id}/${isProceed}/${i}/${remarks}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.put(endPointUrl, json, { headers: httpHeaders });
  }

  public updateApplicationRefund(id: any, json: any, i: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Applications/Refund/${id}/${i}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.put(endPointUrl, json, { headers: httpHeaders });
  }

  public createApplication(application: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Applications`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    const json = {
      id: 0,
      meta: JSON.stringify(application),
    };

    return this.httpClient.post(endPointUrl, json, { headers: httpHeaders });
  }

  public getAPIHookInternal(apiRoute: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/${apiRoute}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }


  public getAPIHookExternal(apiRoute: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/${apiRoute}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  // tslint:disable-next-line: typedef
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  // tslint:disable-next-line: typedef
  private log(message: string) {
    //
  }
}
