import { Message } from "@angular/compiler/src/i18n/i18n_ast";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { AuthService } from "src/data/services/authentication/auth.service";
import { CashierUsersService } from "src/data/services/web/cashier-users.service";
import { ManagerUsersService } from "src/data/services/web/manager-users.service";
import { RolesService } from "src/data/services/web/roles.service";
import { environment } from "src/environments/environment";
import { ModalConfirmationComponent } from "../modal-confirmation/modal-confirmation.component";

@Component({
  selector: "app-cashier-user-create",
  templateUrl: "./cashier-user-create.component.html",
  styleUrls: ["./cashier-user-create.component.less"],
})
export class CashierUserCreateComponent implements OnInit {
  public cashierUserFormGroup: FormGroup;
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public cashierUsersService: CashierUsersService;
  public managerUsersService: ManagerUsersService;
  public emptyString = "";
  public roles: any;
  public bsModalRefConfirmation: BsModalRef;
  public modalService: BsModalService;

  public accessToken: string;

  public base64Photo: string | ArrayBuffer;

  processCreate = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    cashierUsersService: CashierUsersService,
    modalService: BsModalService,
    managerUsersService: ManagerUsersService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.cashierUsersService = cashierUsersService;
    this.modalService = modalService;
    this.managerUsersService = managerUsersService;
    this.accessToken = this.localStorageCacheService.getStorage("access-token");
  }

  ngOnInit(): void {
    this.isAllowedToAddCashier();
  }

  isAllowedToAddCashier() {
    this.managerUsersService.isAllowedToAddCashier().subscribe(
      (result) => {
        this.initCashierUserFormGroup();
        this.initRoles();
      },
      (error) => {
        this.router.navigate(["manager", "cashier-user-list"]);
      }
    );
  }

  initCashierUserFormGroup(): void {
    this.cashierUserFormGroup = new FormGroup({
      username: new FormControl(this.emptyString),
      email: new FormControl(this.emptyString),
      firstName: new FormControl(this.emptyString),
      lastName: new FormControl(this.emptyString),
      address: new FormControl(this.emptyString),
      contactNumber: new FormControl(this.emptyString),
      birthDate: new FormControl(this.emptyString),
      roleId: new FormControl(this.emptyString),
      employeeId: new FormControl(this.emptyString),
      emergencyContactName: new FormControl(this.emptyString),
      emergencyContactNumber: new FormControl(this.emptyString),
      emergencyContactRelationship: new FormControl(this.emptyString),
      photo: new FormControl(this.emptyString),
      status: new FormControl(true),
      managerUserId: new FormControl(0),
      redirect: new FormControl(environment.EMAIL_VERIFICATION_REDIRECT),
    });

    this.cashierUserFormGroup.valueChanges.subscribe((value) => {
    });
  }

  initRoles(): any {
    this.rolesService.getRoles(3).then((roles) => {
      this.roles = roles;
    });
  }

  actionEventCreateCashierUser(): void {
    this.processCreate = true;

    this.cashierUsersService.createCashierUser(this.cashierUserFormGroup.value).subscribe(
      (result) => {
        const encId = this.cryptoService.set(this.data.authentication.privateKey, result.data.id);
        const encNewData = this.cryptoService.set(this.data.authentication.privateKey, "true");
        this.router.navigate(["manager", "cashier-user-view", { id: encId, status: encNewData }]);
      },
      (error) => {
       
        if (error.status === 400) {

          const obj = error.error.errors;
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              let str: string = key;
              str = str[0].toLowerCase() + str.slice(1);
              this.cashierUserFormGroup.controls[str].setErrors({
                required: Validators.required,
                apiMessage: obj[key],
              });
            }
          }
          this.processCreate = false;
        } else {
          // Other error
          this.processCreate = false;
        }
      }
    );
  }

  public onPhotoChange(event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.base64Photo = reader.result;

        this.cashierUserFormGroup.patchValue({
          photo: reader.result,
        });

        this.changeDetectorRef.markForCheck();
      };
    }
  }

  backToCashierUsersList(): void {
    this.router.navigate(["manager", "cashier-user-list"]);
  }
}
