import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SettlementsService } from 'src/data/services/web/settlements.service';
import { Select2OptionData } from "ng-select2";
import { FormControl, FormGroup } from '@angular/forms';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { ManagerUsersService } from 'src/data/services/web/manager-users.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-easydebit-settlement-edit',
  templateUrl: './easydebit-settlement-edit.component.html',
  styleUrls: ['./easydebit-settlement-edit.component.less']
})
export class EasydebitSettlementEditComponent implements OnInit {


  @Input('transaction') transaction : any;
  @Output("closeModal") closeModal = new EventEmitter();

  public storesOption: Array<Select2OptionData>;
  public settlementTypesOption: Array<Select2OptionData>;
  generalFormGroup : FormGroup;
  loader = true;

  constructor(private settlementsService : SettlementsService, 
    private websettings : WebSettingsService,
    private managerUsersService : ManagerUsersService) {
      PNotifyButtons;
     }

  ngOnInit(): void {
    this.getSettlementTypes();
      this.getManagerStores();
      this.initGeneralFormGroup();
      this.setSettlementBanks();
  }
  initGeneralFormGroup(): void {
    console.log(this.transaction);
    this.generalFormGroup = new FormGroup({
      storeName: new FormControl(this.transaction.storeName),
      userSystemOrigin: new FormControl(this.transaction.userSystemOrigin),
      managerContactNumber: new FormControl(this.transaction.managerContactNumber),
      managerEmailAddress: new FormControl(this.transaction.managerEmailAddress),
      managerName: new FormControl(this.transaction.managerName),
      managerUsername: new FormControl(this.transaction.managerUsername),
      depositReferenceNumber: new FormControl(this.transaction.depositReferenceNumber),
      settlementId: new FormControl(this.transaction.settlementId),
      remarks: new FormControl(this.transaction.remarks),
      bankSettlementName: new FormControl(this.transaction.bankSettlementName),
      bankAccountNumber: new FormControl(this.transaction.bankAccountNumber),
      bankAccountName: new FormControl(this.transaction.bankAccountName)
    });

    this.generalFormGroup.controls.userSystemOrigin.valueChanges.subscribe(x=>{
      if(this.generalFormGroup.controls.userSystemOrigin.value == '[OLD] ENTERPRISE'){
        this.generalFormGroup.controls.settlementId.setValue('VIA_BANK_TRANSFER');
      }
    });

    this.generalFormGroup.controls.storeName.valueChanges.subscribe(x=>{
      if(!this.generalFormGroup.controls.storeName.value){
        this.transaction.email = "";
        this.transaction.transactBy = "";
        this.transaction.contactNumber = "";
        this.transaction.name = "";
      }
    });
  }

  getSettlementBank(id : any) {

    if(id){
    var settlementBanksArray = [
      { id: "0001", name: "UNION BANK" },
      { id: "0102", name: "ASIA UNITED BANK" },
      { id: "0053", name: "BANCO DE ORO" },
      { id: "0004", name: "BANK OF THE PHILIPPINE ISLANDS" },
      { id: "0030", name: "BDO NETWORK BANK" },
      { id: "0010", name: "CHINABANK" },
      { id: "0035", name: "LANDBANK OF THE PHILS" },
      { id: "0026", name: "METROBANK" },
      { id: "0008", name: "PHILIPPINE NATIONAL BANK" },
      { id: "0670", name: "QUEZON CAPITAL RURAL BANK" },
      { id: "0014", name: "SECURITY BANK" },
    ];

   return settlementBanksArray.filter(x=>x.id == id)[0].name;
  }else{
    return "";
  }
  }

  getLatestBankDetails(){
    
    this.loader = true;
    if (confirm("Are you sure you want to continue? [FETCH LATEST BANK DETAILS]")) {
    this.settlementsService.updateLatestBankSettlement(this.transaction.id, "Easydebit").subscribe((result) => {
      if(result){
        this.loader = false;
        this.transaction.bankSettlementName = result.bankSettlementName;
          this.transaction.bankAccountNumber =  result.bankAccountNumber;
          this.transaction.bankAccountName =  result.bankAccountName;
        PNotify.success({
          title: "Success",
          text: "Fetch Latest Bank Details Successfully!",
        });
      } else {
        this.loader = false;
        PNotify.error({
          title: "Bank Details not found",
          text: "Please update manager bank details.",
        });
      }

    });
  }else{
    this.loader = false;
  }
  }
  settlementBanksArray = [];
  setSettlementBanks() {
    this.settlementBanksArray = [
      { id: "0001", name: "UNION BANK" },
      { id: "0102", name: "ASIA UNITED BANK" },
      { id: "0053", name: "BANCO DE ORO" },
      { id: "0004", name: "BANK OF THE PHILIPPINE ISLANDS" },
      { id: "0030", name: "BDO NETWORK BANK" },
      { id: "0010", name: "CHINABANK" },
      { id: "0035", name: "LANDBANK OF THE PHILS" },
      { id: "0026", name: "METROBANK" },
      { id: "0008", name: "PHILIPPINE NATIONAL BANK" },
      { id: "0670", name: "QUEZON CAPITAL RURAL BANK" },
      { id: "0014", name: "SECURITY BANK" },
    ];
  }


  saveSettlement(){
    this.loader = true;
    if (confirm("Are you sure you want to continue? [SAVE]")) {
    this.settlementsService.updateEasydebitData(this.generalFormGroup.value,this.transaction.id).subscribe((result) => {
      this.loader = false;
      PNotify.success({
        title: "Success",
        text: "Data Saved Successfully!",
      });
    });
  }else{
    this.loader = false;
  }
  }

  saveAndCompleteSettlement(){
    this.loader = true;
    if (confirm("Are you sure you want to continue? [SAVE AND COMPLETE]")) {
    this.settlementsService.updateAndCompleteEasydebitData(this.generalFormGroup.value,this.transaction.id).subscribe((result) => {
      this.loader = false;
      PNotify.success({
        title: "Success",
        text: "Data Saved Successfully!",
      });
      this.closeModal.emit();
    }, error=>{
      this.loader = false;
      PNotify.error({
        title: "Saving Failed",
        text: "All fields are required.",
      });

    });
  }else{
    this.loader = false;
  }
  }



  

  
  getSettlementTypes() {
    this.websettings.getWebSettingsByIdentifier("SETTLEMENT_STATUS").subscribe((result) => {
     
      this.settlementTypesOption = result.data.map((status) => {
        return {
          id: status.parameter,
          text: status.value,
        };
      });
       
    });
  }

  getManagerStores() {
    this.settlementsService.getManagerStores().subscribe((result) => {
     
      this.storesOption = result.map((data) => {
        return {
          id: data.storeName,
          text: data.storeName,
        };
      });
      this.loader = false;
    });
  }

  getSettlementbyStoreName() {

    this.loader = true;
    this.settlementsService.getSettlementbyStoreName(this.generalFormGroup.controls.storeName.value).subscribe((result) => {
      this.transaction.email = result.email;
      this.transaction.transactBy = result.transactBy;
      this.transaction.contactNumber = result.contactNumber;
      this.transaction.name = result.name;
      this.loader = false;
    }, error=>{
      this.loader = false;
    });
  }

  
  checkMemberDetails() {
    this.loader = true;
    this.managerUsersService.getManagerVIPDetails(this.generalFormGroup.controls.managerUsername.value).subscribe(
      (result) => {
        if (result.Username) {
          console.log(result);
          this.generalFormGroup.controls.managerUsername.setValue(result.Username);
          this.generalFormGroup.controls.managerEmailAddress.setValue(result.EmailAddress);
          this.generalFormGroup.controls.managerContactNumber.setValue(result.MobileNo1);
          this.generalFormGroup.controls.managerName.setValue(result.FirstName + ' ' + result.LastName );
          this.loader = false;
        } else {
          this.generalFormGroup.controls.managerUsername.setValue("");
          this.generalFormGroup.controls.managerEmailAddress.setValue("");
          this.generalFormGroup.controls.managerContactNumber.setValue("");
          this.generalFormGroup.controls.managerName.setValue("" );
          this.loader = false;
          PNotify.error({
            title: "Connecting to VIP",
            text: "username does not exist.",
          });
        }
      },
      (error) => {
        PNotify.error({
          title: "Connecting to VIP",
          text: "username does not exist.",
        });
      }
    );
  }

}
