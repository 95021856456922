<!-- https://valor-software.com/ngx-bootstrap/#/datepicker -->

<div [formGroup]="parentFormGroup" class="form-group row" [class.error]="error">
  <label class="col-sm-3 col-form-label">{{ label }}</label>
  <div class="col-sm-9">
    <!-- <input
      bsDatepicker
      autocomplete="off"
      container="app-root"
      class="form-control vip-form-control"
      [bsConfig]="dpConfig"
      [formControlName]="controlName"
      [id]="controlName"
      [placeholder]="placeholder"
      [upper-case]="upperCase"
      [no-special-char]="noSpecialChar"
      [maxlength]="maxLength"
      [tabindex]="tabIndexStart"
      readonly
    /> -->
    <app-custom-date-picker
    mode="YEAR"
    label="Choose a year"
    [formControlName]="controlName"
    [id]="controlName"
    [upper-case]="upperCase"
    [no-special-char]="noSpecialChar"
    [maxlength]="maxLength"
    [tabindex]="tabIndexStart"
  ></app-custom-date-picker>

    <span *ngIf="error" class="error-msg"
    ><small><div [innerHTML]="errorMessage"></div></small></span
  >
  </div>
</div>
