<div class="line-to"></div>
<div
  id="tree-node-{{ node.guid }}"
  [ngClass]="{
    'tree-element-container': !0,
    'tree-element-has-children': node.hasChildren()
  }"
  [style.width.px]="node.width"
  [style.height.px]="node.height"
>
  <div
    [ngClass]="{
      'tree-element-main': !0,
      dragover: node.isDragover,
      expanded: node.isExpanded,
      dragging: node.isDragging,
      'tree-new-node': node.isMaker
    }"
    [attr.draggable]="node.isMaker ? null : 'true'"
    [style.width.px]="node.width"
    [style.height.px]="node.height"
    
  >

  <!-- (drop)="node.drop($event)"
  (dragenter)="node.dragenter($event)"
  (dragstart)="node.dragstart($event)"
  (dragover)="node.dragover($event)"
  (dragend)="node.dragend()"
  (dragleave)="node.dragleave($event)" -->

    <div
      class="rect"
      [style.width.px]="node.width"
      [style.height.px]="node.height"
      style ="border-radius: 30px !important"
    >
      <div class="buttons">
        <!-- <div class="delete" (click)="node.destroy()"></div> -->
        <div *ngIf="node.displayName != 'Available [L]' && node.displayName != 'Available [R]'" class="toggler" (click)="node.addChild()"></div>
        <!-- <div class="add" (click)="node.addChild()"></div> -->
      </div>
      <div class="tree-text tree-text-non-editable">
        <span>
          {{ node.displayName }}
        </span>
      </div>
      <div class="tree-text tree-text-editable">

       
        <span class="badge badge-warning text-uppercase" style="background-color: gold;" *ngIf="node.regType.toUpperCase() == 'GOLD'"
          (blur)="onNodeBlur($event, node.guid)"
        >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
        <br/>
        GOLD <br/>
        {{ node.displayName }}<br/>
        {{ node.guid }}
      </span>

      <span class="badge badge-warning text-uppercase" style="background-color: goldenrod;" *ngIf="node.regType.toUpperCase() == 'GOLDPLUS'"
      (blur)="onNodeBlur($event, node.guid)"
    >
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
    <br/>
    GOLDPLUS <br/>
    {{ node.displayName }}<br/>
    {{ node.guid }}
  </span>

  <span class="badge badge-warning text-uppercase"  *ngIf="node.regType.toUpperCase() == 'ENTERPRISE'"
      (blur)="onNodeBlur($event, node.guid)"
    >
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
    <br/>
    ENTERPRISE <br/>
    {{ node.displayName }}<br/>
    {{ node.guid }}
  </span>


  <span class="badge badge-warning text-uppercase"  *ngIf="node.regType.toUpperCase() == 'BRONZE'" style="background-color: brown; color: white;"
      (blur)="onNodeBlur($event, node.guid)"
    >
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
    <br/>
    BRONZE <br/>
    {{ node.displayName }}<br/>
    {{ node.guid }}
  </span>


  <span class="badge badge-warning text-uppercase"  *ngIf="node.regType.toUpperCase() == 'SILVER'" style="background-color: silver;"
      (blur)="onNodeBlur($event, node.guid)"
    >
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
    <br/>
    SILVER <br/>
    {{ node.displayName }}<br/>
    {{ node.guid }}
  </span>


  <span class="badge badge-warning text-uppercase"  *ngIf="node.regType.toUpperCase() == 'STARTUP'" style="background-color: green;"
  (blur)="onNodeBlur($event, node.guid)"
>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
<br/>
STARTUP <br/>
{{ node.displayName }}<br/>
{{ node.guid }}
</span>

   


 


        <!-- <span class="children-count"> ({{ node.childrenCount() }})</span> -->
      </div>
    </div>
  </div>
  <div
    class="tree-children"
    [style.transform]="childrenTransform"
    *ngIf="node.isExpanded"
  >
    <div class="tree-elements-group">
      <tree-diagram-node
        *ngFor="let child of node.children"
        [nodeId]="child"
        class="tree-node tree-child"
      ></tree-diagram-node>
    </div>
  </div>
</div>
