import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { MarketPlaceComponent } from "src/app/pages/market-place/market-place.component";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { WalletService } from "src/data/services/web/wallet.service";

@Component({
  selector: "app-basik-admin",
  templateUrl: "./basik-admin.component.html",
  styleUrls: ["./basik-admin.component.less"],
})
export class BasikAdminComponent implements OnInit {
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public modalService: BsModalService;
  public roleLevelName: any;
  public walletService: WalletService;
  public wallet: any;
  constructor(
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    private router: Router,
    modalService: BsModalService,
    private route: ActivatedRoute,
    walletService: WalletService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.modalService = modalService;
    this.walletService = walletService;
  }

  ngOnInit(): void {
    this.roleLevelName = this.authService.getRoleLevelName(this.localStorageCacheService.getStorage("role_level"));
    const roleId = this.localStorageCacheService.getStorage("roleId");
    const roleLevelName = window.location.pathname.split("?")[0].split("/")[1].toLocaleLowerCase();
    if (roleLevelName === "dashboard") {
      if(roleId == 21){
        this.router.navigate([`${this.roleLevelName}/vips-dashboard`]);
      } else {
        this.router.navigate([`${this.roleLevelName}/dashboard`]);
      }
    }
  }

  getWallet(): void {
    this.walletService.getWallet().subscribe((result) => {
      this.wallet = result.wallet;
    });
  }

  changeOfRoutes() {
    this.getWallet();
  }

  hideSideNav(): void {
    document.getElementById("side-nav").classList.remove("active");
    document.getElementById("nav-bg-wrap").classList.remove("active");
  }
}
