
<div id="content-wrapper" style="height: calc(100vh - 110px); overflow-y: auto;" scrollable (scrollPosition)="scrollHandler($event)">
  <app-loader *ngIf="loader"></app-loader>
    <div class="">
      <div class="page-content page-container" id="page-content">
        <div class="padding sr">
          <div class="card box-2" data-sr-id="100" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
            <div class="card-header bg-dark bg-img p-0 no-border" data-stellar-background-ratio="0.1" style="background-image: url(../../../assets/vips2/1.png); background-size: auto 100%; background-position: right top; background-repeat: no-repeat;">
              <div class="bg-dark-overlay r-2x no-r-b">
                <div class="d-md-flex">
                  <div class="p-4">
                    <div class="d-flex">
                      <a >
                        <span class="avatar w-64">
                          <img class="bg-white" src="../../../assets/vips2/1.png" alt="logo">
                          <i class="on"></i>
                        </span>
                      </a>
                      <div class="mx-3">
                        <h5 class="mt-2" style="font-weight: bolder; text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);">Vips Community</h5>
                        <div class=" text-sm" style="font-weight: bolder; text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);">
                            <small>
                                <i class="fa fa-map-marker mr-2"></i>#42 Scout Bayoran St. Barangay South Triangle Diliman, Quezon City
                            </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span class="flex"></span>
                  <div class="align-items-center d-flex p-4">
                  </div>
                </div>
              </div>
            </div>
            <div class="p-3">
              <div class="d-flex">
                <ul class="nav nav-pills">
                  <li class="nav-item">
                    <a class="nav-link active" (click)="activeTabIndex = 0" data-toggle="tab" data-target="#tab_1">My Community</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" (click)="activeTabIndex = 1" data-toggle="tab" data-target="#tab_2">Pending Post</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-7 col-lg-8">
              <div class="tab-content">
                <div class="tab-pane fade show active bg-wraning" id="tab_1">
                  <div class="card box-2" *ngFor="let post of posts" id="feed-2" data-sr-id="102" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                    <div *ngIf="post.data.contentType == 'images'">
                        <div *ngIf="post.count == 0">
                          <ng-container *ngTemplateOutlet="messagePost; context: { $implicit: post }"></ng-container>
                        </div>
                        <div *ngIf="post.count == 1">
                            <ng-container *ngTemplateOutlet="singleImagePost; context: { $implicit: post }"></ng-container>
                        </div>
                        <div *ngIf="post.count == 2">
                            <ng-container *ngTemplateOutlet="twoImagePost; context: { $implicit: post }"></ng-container>
                        </div>
                        <div *ngIf="post.count == 3">
                            <ng-container *ngTemplateOutlet="threeImagePost; context: { $implicit: post }"></ng-container>
                        </div>
                        <div *ngIf="post.count == 5">
                            <ng-container *ngTemplateOutlet="fiveImagePost; context: { $implicit: post }"></ng-container>
                        </div>
                        <div *ngIf="post.count > 5">
                            <ng-container *ngTemplateOutlet="multipleImagePost; context: { $implicit: post }"></ng-container>
                        </div>
                    </div>
                    <div *ngIf="post.data.contentType == 'video'">
                        <ng-container *ngTemplateOutlet="videoPost; context: { $implicit: post }"></ng-container>
                    </div>
                    <div *ngIf="post.data.contentType == 'post'">
                        <ng-container *ngTemplateOutlet="messagePost; context: { $implicit: post }"></ng-container>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center" *ngIf="posts.length == 0">
                    <p class="text-muted">-------- There are no active posts --------</p>
                  </div>
                </div>
                <div class="tab-pane fade" id="tab_2" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                  <div class="card box-2" *ngFor="let post of postsPending" id="feed-2" data-sr-id="102" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                    <div *ngIf="post.data.contentType == 'images'">
                        <div *ngIf="post.count == 0">
                          <ng-container *ngTemplateOutlet="messagePost; context: { $implicit: post }"></ng-container>
                        </div>
                        <div *ngIf="post.count == 1">
                            <ng-container *ngTemplateOutlet="singleImagePost; context: { $implicit: post }"></ng-container>
                        </div>
                        <div *ngIf="post.count == 2">
                            <ng-container *ngTemplateOutlet="twoImagePost; context: { $implicit: post }"></ng-container>
                        </div>
                        <div *ngIf="post.count == 3">
                            <ng-container *ngTemplateOutlet="threeImagePost; context: { $implicit: post }"></ng-container>
                        </div>
                        <div *ngIf="post.count == 4">
                            <ng-container *ngTemplateOutlet="fourImagePost; context: { $implicit: post }"></ng-container>
                        </div>
                        <div *ngIf="post.count == 5">
                            <ng-container *ngTemplateOutlet="fiveImagePost; context: { $implicit: post }"></ng-container>
                        </div>
                        <div *ngIf="post.count > 5">
                            <ng-container *ngTemplateOutlet="multipleImagePost; context: { $implicit: post }"></ng-container>
                        </div>
                    </div>
                    <div *ngIf="post.data.contentType == 'video'">
                        <ng-container *ngTemplateOutlet="videoPost; context: { $implicit: post }"></ng-container>
                    </div>
                    <div *ngIf="post.data.contentType == 'post'">
                        <ng-container *ngTemplateOutlet="messagePost; context: { $implicit: post }"></ng-container>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center" *ngIf="postsPending.length == 0">
                    <p class="text-muted">-------- There are no pending posts --------</p>
                  </div>
                </div>
            </div>
            </div>
            <div class="col-sm-5 col-lg-4">
              <div class="card sticky box-2" style="z-index: 1; visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;" data-sr-id="120">
                <div class="card-header">
                  <strong class="font-weight-bold">Custom App Banners</strong>
                </div>
                <div class="list list-row">
                  <div class="list-item pt-0" *ngFor="let image of images" data-id="8" data-sr-id="121" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                    <div class="pl-2 pr-2 pt-0 pb-1">
                        <img class="box-2" [src]="image?.path" alt="banner" style="max-width: 100%; width: 100%;">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <input type="file" id="file" accept="image/*" (change)="handleFileInput($event)" hidden>
  </div>

  <ng-template #threeImagePost let-data>
      <div class="card-header d-flex">
        <a>
          <img [src]="data.data?.logo || altData" alt="{{ altData }}" class="avatar w-40">
        </a>
        <div class="mx-3">
          <a class="app-name" [title]="data.data?.username">{{ data.data?.customAppName }}</a>
          <div class="text-muted text-sm">{{ computeTimeAgo(data.data?.timestamp) }}</div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class="card-text mb-3">
          <h6>{{ data.data.title }}</h6>
          <!-- <div *ngIf="data.data.message"><p [innerHTML]="transformtext(data.data?.message)"></p></div> -->
          <div *ngIf="data.data.message"><p [innerHTML]="transformtext(data.data?.message)"></p></div>
        </div>
        <div class="row row-xs">
          <div class="col-4">
            <div class="media media-4x3 r">
              <a class="media-content" (click)="openImageViewer(imageViewer, data, 0)" [ngStyle]="{'background-image': 'url(' + data.images[0].url + ')'}"></a>
            </div>
          </div>
          <div class="col-4">
            <div class="media media-4x3 r">
              <a class="media-content" (click)="openImageViewer(imageViewer, data, 1)" [ngStyle]="{'background-image': 'url(' + data.images[1].url + ')'}"></a>
            </div>
          </div>
          <div class="col-4">
            <div class="media media-4x3 r">
              <a class="media-content" (click)="openImageViewer(imageViewer, data, 2)" [ngStyle]="{'background-image': 'url(' + data.images[2].url + ')'}"></a>
            </div>
          </div>
        </div>
      </div>
      <div class="pr-3 pb-3 pl-3 pt-0 b-t" id="feed-form-1" *ngIf="data.data.status == 'pending'">
        <form>
          <div class="d-flex">
            <span class="flex"></span>
            <button class="button-confirm mr-2" (click)="openConfirmation(rejectApplicationModal, data)">Reject</button>
            <button class="button-confirm" (click)="openConfirmation(verifyApplicationModal, data)">Approve</button>
          </div>
        </form>
      </div>
  </ng-template>

  <ng-template #twoImagePost let-data>
    <div class="card-header d-flex">
        <a >
          <img [src]="data.data?.logo || altData" alt="{{ altData }}" class="avatar w-40">
        </a>
        <div class="mx-3">
          <a class="app-name" [title]="data.data?.username">{{ data.data?.customAppName }}</a>
          <div class="text-muted text-sm">{{ computeTimeAgo(data.data.timestamp) }}</div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class="card-text mb-3">
          <h6>{{ data.data.title }}</h6>
          <div *ngIf="data.data.message"><p [innerHTML]="transformtext(data.data?.message)"></p></div>
        </div>
        <div class="row row-xs">
          <div class="col-6">
            <div class="media media-16x9 r">
              <a class="media-content media-16x9" (click)="openImageViewer(multipleImageViewer, data, 0)" [ngStyle]="{'background-image': 'url(' + data.images[0].url + ')'}"></a>
            </div>
          </div>
          <div class="col-6">
            <div class="media media-16x9 r">
              <a class="media-content media-16x9" (click)="openImageViewer(multipleImageViewer, data, 1)" [ngStyle]="{'background-image': 'url(' + data.images[1].url + ')'}"></a>
            </div>
          </div>
        </div>
      </div>
      <div class="pr-3 pb-3 pl-3 pt-0 b-t" id="feed-form-1" *ngIf="data.data.status == 'pending'">
        <form>
          <div class="d-flex">
            <span class="flex"></span>
            <button class="button-confirm mr-2" (click)="openConfirmation(rejectApplicationModal, data)">Reject</button>
            <button class="button-confirm" (click)="openConfirmation(verifyApplicationModal, data)">Approve</button>
          </div>
        </form>
      </div>
  </ng-template>

  <ng-template #videoPost let-data>
    <div class="card-header d-flex">
        <a >
          <img [src]="data.data?.logo || altData" alt="{{ altData }}" class="avatar w-40">
        </a>
        <div class="mx-3">
          <a class="app-name" [title]="data.data?.username">{{ data.data?.customAppName }}</a>
          <div class="text-muted text-sm">{{ computeTimeAgo(data.data.timestamp) }}</div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class="card-text mb-3">
            <h6>{{ data.data.title }}</h6>
            <div *ngIf="data.data.message"><p [innerHTML]="transformtext(data.data?.message)"></p></div>
        </div>
        <div class="row row-xs">
          <div class="col-12">
            <div class="media media-2x1 r">
              <a class="media-content" (click)="openVideoViewer(videoViewer, data)" [ngStyle]="{'background-image': 'url(' + data.data.thumbnailUrl + ')'}"></a>
              <div class="media-action active">
                <a (click)="openVideoViewer(videoViewer, data)" class="btn btn-md btn-icon btn-white btn-rounded">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-play">
                    <polygon points="5 3 19 12 5 21 5 3"></polygon>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pr-3 pb-3 pl-3 pt-0 b-t" id="feed-form-1" *ngIf="data.data.status == 'pending'">
        <form>
          <div class="d-flex">
            <span class="flex"></span>
            <button class="button-confirm mr-2" (click)="openConfirmation(rejectApplicationModal, data)">Reject</button>
            <button class="button-confirm" (click)="openConfirmation(verifyApplicationModal, data)">Approve</button>
          </div>
        </form>
      </div>
  </ng-template>

  <ng-template #singleImagePost let-data>
    <div class="card-header d-flex">
        <a >
          <img [src]="data.data?.logo || altData" alt="{{ altData }}" class="avatar w-40">
        </a>
        <div class="mx-3">
          <a class="app-name" [title]="data.data?.username">{{ data.data?.customAppName }}</a>
          <div class="text-muted text-sm">{{ computeTimeAgo(data.data?.timestamp) }}</div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class="card-text mb-3">
            <h6>{{ data.data?.title }}</h6>
            <div *ngIf="data.data.message"><p [innerHTML]="transformtext(data.data?.message)"></p></div>
        </div>
        <div class="row row-xs">
          <div class="col-12">
            <div class="media media-2x1 r">
              <a class="media-content" (click)="openImageViewer(imageViewer, data, 0)" [ngStyle]="{'background-image': 'url(' + data?.images[0].url + ')'}"></a>
            </div>
          </div>
        </div>
      </div>
      <div class="pr-3 pb-3 pl-3 pt-0 b-t" id="feed-form-1" *ngIf="data.data.status == 'pending'">
        <form>
          <div class="d-flex">
            <span class="flex"></span>
            <button class="button-confirm mr-2" (click)="openConfirmation(rejectApplicationModal, data)">Reject</button>
            <button class="button-confirm" (click)="openConfirmation(verifyApplicationModal, data)">Approve</button>
          </div>
        </form>
      </div>
  </ng-template>

  <ng-template #multipleImagePost let-data>
    <div class="card-header d-flex">
        <a >
          <img [src]="data.data?.logo || altData" alt="{{ altData }}" class="avatar w-40">
        </a>
        <div class="mx-3">
          <a class="app-name" [title]="data.data?.username">{{ data.data?.customAppName }}</a>
          <div class="text-muted text-sm">{{ computeTimeAgo(data.data.timestamp) }}</div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class="card-text mb-3">
          <h6>{{ data.data.title }}</h6>
          <div *ngIf="data.data.message"><p [innerHTML]="transformtext(data.data?.message)"></p></div>
        </div>
        <div class="row row-xs">
          <div class="col-6 d-flex">
            <div class="media flex r">
              <a class="media-content" (click)="openImageViewer(multipleImageViewer, data, 0)" [ngStyle]="{'background-image': 'url(' + data.images[0].url + ')'}"></a>
            </div>
          </div>
          <div class="col-6">
            <div class="media media-2x1 mb-2 r">
              <a class="media-content" (click)="openImageViewer(multipleImageViewer, data, 1)" [ngStyle]="{'background-image': 'url(' + data.images[1].url + ')'}"></a>
            </div>
            <div class="row row-xs">
              <div class="col-6">
                <div class="media r">
                  <a class="media-content" (click)="openImageViewer(multipleImageViewer, data, 2)" [ngStyle]="{'background-image': 'url(' + data.images[2].url + ')'}"></a>
                </div>
              </div>
              <div class="col-6">
                <div class="media r">
                  <a class="media-content" (click)="openImageViewer(multipleImageViewer, data, 3)" [ngStyle]="{'background-image': 'url(' + data.images[3].url + ')'}"></a>
                  <a (click)="openImageViewer(multipleImageViewer, data, 3)" class="media-overlay active bg-dark-overlay">
                    <small class="text-white mx-auto">{{ data.count - 4 }}+</small>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pr-3 pb-3 pl-3 pt-0 b-t" id="feed-form-1" *ngIf="data.data.status == 'pending'">
        <form>
          <div class="d-flex">
            <span class="flex"></span>
            <button class="button-confirm mr-2" (click)="openConfirmation(rejectApplicationModal, data)">Reject</button>
            <button class="button-confirm" (click)="openConfirmation(verifyApplicationModal, data)">Approve</button>
          </div>
        </form>
      </div>
  </ng-template>

  <ng-template #fiveImagePost let-data>
    <div class="card-header d-flex">
        <a >
          <img [src]="data.data?.logo || altData" alt="{{ altData }}" class="avatar w-40">
        </a>
        <div class="mx-3">
          <a class="app-name" [title]="data.data?.username">{{ data.data?.customAppName }}</a>
          <div class="text-muted text-sm">{{ computeTimeAgo(data.data.timestamp) }}</div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class="card-text mb-3">
            <h6>{{ data.data.title }}</h6>
            <div *ngIf="data.data.message"><p [innerHTML]="transformtext(data.data?.message)"></p></div>
        </div>
        <div class="row row-xs">
          <div class="col-6 mb-2">
            <div class="media media-2x1 r">
              <a class="media-content" (click)="openImageViewer(multipleImageViewer, data, 0)" [ngStyle]="{'background-image': 'url(' + data.images[0].url + ')'}"></a>
            </div>
          </div>
          <div class="col-6 mb-2">
            <div class="media media-2x1 r">
              <a class="media-content" (click)="openImageViewer(multipleImageViewer, data, 1)" [ngStyle]="{'background-image': 'url(' + data.images[1].url + ')'}"></a>
            </div>
          </div>
          <div class="col-4">
            <div class="media media-4x3 r">
              <a class="media-content" (click)="openImageViewer(multipleImageViewer, data, 2)" [ngStyle]="{'background-image': 'url(' + data.images[2].url + ')'}"></a>
            </div>
          </div>
          <div class="col-4">
            <div class="media media-4x3 r">
              <a class="media-content" (click)="openImageViewer(multipleImageViewer, data, 3)" [ngStyle]="{'background-image': 'url(' + data.images[3].url + ')'}"></a>
            </div>
          </div>
          <div class="col-4">
            <div class="media media-4x3 r">
              <a class="media-content" (click)="openImageViewer(multipleImageViewer, data, 4)" [ngStyle]="{'background-image': 'url(' + data.images[4].url + ')'}"></a>
            </div>
          </div>
        </div>
      </div>
      <div class="pr-3 pb-3 pl-3 pt-0 b-t" id="feed-form-1" *ngIf="data.data.status == 'pending'">
        <form>
          <div class="d-flex">
            <span class="flex"></span>
            <button class="button-confirm mr-2" (click)="openConfirmation(rejectApplicationModal, data)">Reject</button>
            <button class="button-confirm" (click)="openConfirmation(verifyApplicationModal, data)">Approve</button>
          </div>
        </form>
      </div>
  </ng-template>

  <ng-template #fourImagePost let-data>
    <div class="card-header d-flex">
        <a >
          <img [src]="data.data?.logo || altData" alt="{{ altData }}" class="avatar w-40">
        </a>
        <div class="mx-3">
          <a class="app-name" [title]="data.data?.username">{{ data.data?.customAppName }}</a>
          <div class="text-muted text-sm">{{ computeTimeAgo(data.data.timestamp) }}</div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class="card-text mb-3">
          <h6>{{ data.data.title }}</h6>
            <div *ngIf="data.data.message"><p [innerHTML]="transformtext(data.data?.message)"></p></div>
        </div>
        <div class="row row-xs">
          <div class="col-6 mb-2">
            <div class="media media-2x1 r">
              <a class="media-content" (click)="openImageViewer(multipleImageViewer, data, 0)" [ngStyle]="{'background-image': 'url(' + data.images[0].url + ')'}"></a>
            </div>
          </div>
          <div class="col-6 mb-2">
            <div class="media media-2x1 r">
              <a class="media-content" (click)="openImageViewer(multipleImageViewer, data, 1)" [ngStyle]="{'background-image': 'url(' + data.images[1].url + ')'}"></a>
            </div>
          </div>
          <div class="col-6">
            <div class="media media-2x1 r">
              <a class="media-content" (click)="openImageViewer(multipleImageViewer, data, 2)" [ngStyle]="{'background-image': 'url(' + data.images[2].url + ')'}"></a>
            </div>
          </div>
          <div class="col-6">
            <div class="media media-2x1 r">
              <a class="media-content" (click)="openImageViewer(multipleImageViewer, data, 3)" [ngStyle]="{'background-image': 'url(' + data.images[3].url + ')'}"></a>
            </div>
          </div>
        </div>
      </div>
      <div class="pr-3 pb-3 pl-3 pt-0 b-t" id="feed-form-1" *ngIf="data.data.status == 'pending'">
        <form>
          <div class="d-flex">
            <span class="flex"></span>
            <button class="button-confirm mr-2" (click)="openConfirmation(rejectApplicationModal, data)">Reject</button>
            <button class="button-confirm" (click)="openConfirmation(verifyApplicationModal, data)">Approve</button>
          </div>
        </form>
      </div>
  </ng-template>

  <ng-template #threeLandscapeImagePost let-data>
    <div class="card-header d-flex">
        <a >
          <img [src]="data.data?.logo || altData" alt="{{ altData }}" class="avatar w-40">
        </a>
        <div class="mx-3">
          <a class="app-name" [title]="data.data?.username">{{ data.data?.customAppName }}</a>
          <div class="text-muted text-sm">{{ computeTimeAgo(data.data.timestamp) }}</div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class="card-text mb-3">
          <h6>{{ data.data.title }}</h6>
            <div *ngIf="data.data.message"><p [innerHTML]="transformtext(data.data?.message)"></p></div>
        </div>
        <div class="row row-xs">
          <div class="col-12 mb-2">
            <div class="media media-3x1 r">
              <a class="media-content" (click)="openImageViewer(multipleImageViewer, data, 0)" [ngStyle]="{'background-image': 'url(' + data.images[0].url + ')'}"></a>
            </div>
          </div>
          <div class="col-6">
            <div class="media media-2x1 r">
              <a class="media-content" (click)="openImageViewer(multipleImageViewer, data, 1)" [ngStyle]="{'background-image': 'url(' + data.images[1].url + ')'}"></a>
            </div>
          </div>
          <div class="col-6">
            <div class="media media-2x1 r">
              <a class="media-content" (click)="openImageViewer(multipleImageViewer, data, 2)" [ngStyle]="{'background-image': 'url(' + data.images[2].url + ')'}"></a>
            </div>
          </div>
        </div>
      </div>
      <div class="pr-3 pb-3 pl-3 pt-0 b-t" id="feed-form-1" *ngIf="data.data.status == 'pending'">
        <form>
          <div class="d-flex">
            <span class="flex"></span>
            <button class="button-confirm mr-2" (click)="openConfirmation(rejectApplicationModal, data)">Reject</button>
            <button class="button-confirm" (click)="openConfirmation(verifyApplicationModal, data)">Approve</button>
          </div>
        </form>
      </div>
  </ng-template>

  <ng-template #messagePost let-data>
    <div class="card-header d-flex">
        <a>
          <img [src]="data.data?.logo || altData" alt="{{ altData }}" class="avatar w-40">
        </a>
        <div class="mx-3">
          <a class="app-name" [title]="data.data?.username">{{ data.data?.customAppName }}</a>
          <div class="text-muted text-sm">{{ computeTimeAgo(data.data?.timestamp) }}</div>
        </div>
      </div>
      <div class="card-body pt-0 pb-0">
        <div class="card-text mb-3">
            <h6>{{ data.data?.title }}</h6>
            <div *ngIf="data.data.message"><p [innerHTML]="transformtext(data.data?.message)"></p></div>
        </div>
      </div>
      <div class="pr-3 pb-3 pl-3 pt-0 b-t" id="feed-form-1" *ngIf="data.data.status == 'pending'">
        <form>
          <div class="d-flex">
            <span class="flex"></span>
            <button class="button-confirm mr-2" (click)="openConfirmation(rejectApplicationModal, data)">Reject</button>
            <button class="button-confirm" (click)="openConfirmation(verifyApplicationModal, data)">Approve</button>
          </div>
        </form>
      </div>
  </ng-template>

  <ng-template #imageViewer let-modal>
    <div class="modal-body p-0 m-0 row">
      <div class="col-9 bg-dark p-0 m-0 d-flex align-items-center justify-content-center" style="min-height: 600px;">
        <img [src]="selectedPost.images[0].url" alt="" style="max-height: 600px; max-width: 100%;">
      </div>
      <div class="col-3 pr-0 position-relative">
        <div class="card-header d-flex pl-0 pr-0">
          <a>
            <img [src]="selectedPost.data?.logo || altData" alt="{{ altData }}" class="avatar w-40">
          </a>
          <div class="mx-3">
            <a class="app-name" [title]="selectedPost.data?.username">{{ selectedPost.data?.customAppName }}</a>
            <div class="text-muted text-sm">{{ computeTimeAgo(selectedPost.data.timestamp) }}</div>
          </div>
        </div>
        <div class="card-body pt-0 pl-0 pr-2" style="min-height: 535px; max-height: 535px; overflow-y: scroll;">
          <div class="card-text mb-3">
            <h6>{{ selectedPost.data.title }}</h6>
            <!-- <div [innerHtml]="selectedPost.data.message"></div> -->
            <div *ngIf="selectedPost.data.message"><p [innerHTML]="transformtext(selectedPost.data?.message)"></p></div>
          </div>
        </div>
        <!-- Close button -->
        <button type="button" class="close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </ng-template>

  <ng-template #multipleImageViewer let-modal>
    <div class="modal-body p-0 m-0 row">
        <div class="col-9 bg-dark p-0 m-0 position-relative d-flex justify-content-center align-items-center" style="min-height: 600px; max-height: 600px;">
          <img [src]="selectedPost.images[currentImageIndex]?.url" alt="" style="max-height: 600px; max-width: 100%;">
          <!-- Navigation buttons -->
          <button class="slide-btn prev" (click)="prevImage()" *ngIf="selectedPost.images.length > 1">❮</button>
          <button class="slide-btn next" (click)="nextImage()" *ngIf="selectedPost.images.length > 1">❯</button>
        </div>
        <div class="col-3 pr-0">
          <div class="card-header d-flex pl-0 pr-0">
            <a>
              <img [src]="selectedPost.data?.logo || altData" alt="{{ altData }}" class="avatar w-40">
            </a>
            <div class="mx-3">
              <a class="app-name" [title]="selectedPost.data?.username">{{ selectedPost.data?.customAppName }}</a>
              <div class="text-muted text-sm">{{ computeTimeAgo(selectedPost.data.timestamp) }}</div>
            </div>
          </div>
          <div class="card-body pt-0 pl-0 pr-2" style="min-height: 535px; max-height: 535px; overflow-y: scroll;">
            <div class="card-text mb-3">
              <h6>{{ selectedPost.data.title }}</h6>
              <!-- <div [innerHtml]="selectedPost.data.message"></div> -->
              <div *ngIf="selectedPost.data.message"><p [innerHTML]="transformtext(selectedPost.data?.message)"></p></div>
            </div>
          </div>
          <!-- Close button -->
          <button type="button" class="close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
  </ng-template>

  <ng-template #videoViewer let-modal>
    <div class="modal-body p-0 m-0 row">
        <div class="col-9 bg-dark p-0 m-0 position-relative d-flex justify-content-center align-items-center" style="min-height: 600px; max-height: 900px;">
          <!-- Video Player here selectedPost.data.videoUrl -->
          <video #videoPlayer autoplay controls [src]="selectedVideoPost.data.videoUrl" style="max-height: 900px; max-width: 100%;"></video>
       </div>
        <div class="col-3 pr-0">
          <div class="card-header d-flex pl-0 pr-0">
            <a>
              <img [src]="selectedVideoPost.data?.logo || altData" alt="{{ altData }}" class="avatar w-40">
            </a>
            <div class="mx-3">
              <a class="app-name" [title]="selectedVideoPost.data?.username">{{ selectedVideoPost.data?.customAppName }}</a>
              <div class="text-muted text-sm">{{ computeTimeAgo(selectedVideoPost.data.timestamp) }}</div>
            </div>
          </div>
          <div class="card-body pt-0 pl-0 pr-2" style="min-height: 535px; max-height: 535px; overflow-y: scroll;">
            <div class="card-text mb-3">
              <h6>{{ selectedVideoPost.data.title }}</h6>
              <!-- <div [innerHtml]="selectedVideoPost.data.message"></div> -->
              <div *ngIf="selectedVideoPost.data.message"><p [innerHTML]="transformtext(selectedVideoPost.data?.message)"></p></div>
            </div>
          </div>
          <!-- Close button -->
          <button type="button" class="close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
  </ng-template>

  <ng-template #createImagePost let-modal>
    <div>
      <div class="modal-header d-flex justify-content-center">
        <div class="flex"></div>
        <h4 class="modal-title font-weight-bolder">CREATE POST</h4>
        <div class="flex"></div>
        <button type="button" class="close p-0 m-0" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>           
      
      <div class="modal-body pt-0">
        <div class="card-header p-0 d-flex">
          <a>
            <img [src]="data.data?.logo || altData" alt="{{ altData }}" class="avatar w-40">
          </a>
          <div class="mx-3">
            <a class="app-name" [title]="data.data?.username">{{ data.data?.customAppName }}</a>
            <div class="text-muted text-sm">Connect with others by sharing...</div>
          </div>
        </div>
      </div>
  
      <div class="modal-body pt-0 pb-1"> <!-- Apply bg-dark and text-light to the parent div -->
        <div [formGroup]="postFormGroup">
          <input 
            type="text" 
            formControlName="title" 
            class="form-control border border-dark" 
            placeholder="Title" 
            rows="3"
            maxlength="100"
          >
          <textarea 
            #textareaRef
            class="form-control border border-dark mt-1 expandable-textarea" 
            formControlName="message" 
            maxlength="2000" 
            placeholder="Your thoughts, ideas, or announcements." 
            rows="1"
            (input)="resizeTextarea($event.target)"
          ></textarea>
        </div>
      </div>

      <div class="modal-body pt-0 pb-1">
        <div *ngIf="!backgroundImageUrl">
          <label class="custum-file-upload" for="file">
            <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" fill="" viewBox="0 0 24 24"><g stroke-width="0" id="SVGRepo_bgCarrier"></g><g stroke-linejoin="round" stroke-linecap="round" id="SVGRepo_tracerCarrier"></g><g id="SVGRepo_iconCarrier"> <path fill="" d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z" clip-rule="evenodd" fill-rule="evenodd"></path> </g></svg>
            </div>
            <div class="text">
               <span>Click to upload image</span>
               </div>
               <input type="file" id="file" accept="image/*" (change)="handleFileInput($event)">
            </label>
        </div>
        <div class="w-100" *ngIf="backgroundImageUrl">
          <div class="mmedia mmedia-2x1 r" style="position: relative; display: flex; justify-content: center; align-items: center; height: 500px; width: 100%;">
            <button 
                class="btn btn-light mt-1 ml-5" 
                style="position: absolute; top: 0; left: 0; z-index: 1;"
                (click)="triggerFileInput()"
                [disabled]="backgroundImages.length >= 10"
            >
                <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    fill="" 
                    viewBox="0 0 24 24" 
                    width="16" 
                    height="16"
                >
                    <g stroke-width="0" id="SVGRepo_bgCarrier"></g>
                    <g stroke-linejoin="round" stroke-linecap="round" id="SVGRepo_tracerCarrier"></g>
                    <g id="SVGRepo_iconCarrier">
                        <path 
                            fill="" 
                            d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z" 
                            clip-rule="evenodd" 
                            fill-rule="evenodd"
                        ></path> 
                    </g>
                </svg>
                &nbsp; Add Image
            </button>
            <button class="btn btn-light mt-1 ml-0" style="position: absolute; top: 0; left: 0; z-index: 1;" disabled>
              {{backgroundImages.length}}/10
            </button>
        
            <button 
                class="btn btn-light mt-1 mr-1" 
                style="position: absolute; top: 0; right: 0; z-index: 1; border-radius: 50%; font-weight: bolder; background-color: rgba(255, 255, 255, 0.3); transition: background-color 0.3s, box-shadow 0.3s;"
                (click)="removeImage()"
            >
                <i class="fas fa-times"></i> <!-- Font Awesome X icon -->
            </button>
        
            <img [src]="backgroundImages[currentBGIndex]?.url" alt="" style="max-width: 100%; max-height: 500px;">
            <!-- Navigation buttons -->
            <button class="slide-btn prev" (click)="prevBGImage()" *ngIf="backgroundImages.length > 1">❮</button>
            <button class="slide-btn next" (click)="nextBGImage()" *ngIf="backgroundImages.length > 1">❯</button>
          </div>
        </div>
      </div>
      
      <div class="modal-body card pt-0 mb-0">
        <button class="btn btn-md btn-primary font-weight-bolder" (click)="processImagesPost()" [disabled]="!isValid">Post</button>
      </div>
    </div>
  </ng-template>

  <ng-template #createVideoPost let-modal>
    <div>
      <div class="modal-header d-flex justify-content-center">
        <div class="flex"></div>
        <h4 class="modal-title font-weight-bolder">CREATE POST</h4>
        <div class="flex"></div>
        <button type="button" class="close p-0 m-0" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>           
      
      <div class="modal-body pt-0">
        <div class="card-header p-0 d-flex">
          <a>
            <img [src]="data.data?.logo || altData" alt="{{ altData }}" class="avatar w-40">
          </a>
          <div class="mx-3">
            <a class="app-name" [title]="data.data?.username">{{ data.data?.customAppName }}</a>
            <div class="text-muted text-sm">Connect with others by sharing...</div>
          </div>
        </div>
      </div>
  
      <div class="modal-body pt-0 pb-1"> <!-- Apply bg-dark and text-light to the parent div -->
        <div [formGroup]="postFormGroup">
          <input 
            type="text" 
            formControlName="title" 
            class="form-control border border-dark" 
            placeholder="Title" 
            rows="3"
            maxlength="100"
          >
          <textarea 
            #textareaRef
            class="form-control border border-dark mt-1 expandable-textarea" 
            formControlName="message" 
            maxlength="2000" 
            placeholder="Your thoughts, ideas, or announcements." 
            rows="1"
            (input)="resizeTextarea($event.target)"
          ></textarea>
        </div>
      </div>

      <div class="modal-body pt-0 pb-1"> <!-- Apply bg-dark and text-light to the parent div -->
        <div>
          <label class="custum-file-upload" for="file">
            <div class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" fill="" viewBox="0 0 24 24">
                <path d="M10 6H14V10H17L12 15L7 10H10V6ZM19 4H5C3.9 4 3 4.9 3 6V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V6C21 4.9 20.1 4 19 4ZM17 16H7V14H17V16Z"/>
              </svg>
              
            </div>                     
            <div class="text">
               <span>Click to upload video</span>
               </div>
               <input type="file" id="file" accept="video/*">
            </label>
        </div>        
      </div>
      
      <div class="modal-body card pt-0 mb-0">
        <button class="btn btn-md btn-primary font-weight-bolder" (click)="processPost()">Post</button>
      </div>
    </div>
  </ng-template>

  <ng-template #verifyApplicationModal let-modal>
    <div class="modal-header">
      <h6 class="modal-title">Are you sure you want to approve this post?</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
      <button class="btn btn-sm btn-primary float-right" (click)="approve(selectedConfirmPost)">Proceed</button>
      <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
        Cancel
      </button>
    </div>
  </ng-template>
  
  <ng-template #rejectApplicationModal let-modal>
    <div class="modal-header">
      <h6 class="modal-title">Are you sure you want to reject this post?</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body pb-1 pt-0">
      <textarea 
          type="text"
          class="form-control"
          id="remm"
          [(ngModel)] = "remarksStr"
        ></textarea>
    </div>
  
    <div class="modal-body pt-0">
      <button class="btn btn-sm btn-danger float-right" (click)="reject(selectedConfirmPost)">Proceed</button>
      <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
        Cancel
      </button>
    </div>
  </ng-template>