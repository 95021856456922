import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorService {

  color_primary: string = '#YourPrimaryColorHexCode'; // Replace with your primary color hex code

  colorShade50(hexColor?: string): string {
    if (!hexColor) {
      return this.color_primary;
    } else {
      return this.lighten(hexColor, 90);
    }
  }

  colorShade100(hexColor?: string): string {
    if (!hexColor) {
      return this.color_primary;
    } else {
      return this.lighten(hexColor, 80);
    }
  }

  colorShade200(hexColor?: string): string {
    if (!hexColor) {
      return this.color_primary;
    } else {
      return this.lighten(hexColor, 60);
    }
  }

  colorShade300(hexColor?: string): string {
    if (!hexColor) {
      return this.color_primary;
    } else {
      return this.lighten(hexColor, 40);
    }
  }

  colorShade400(hexColor?: string): string {
    if (!hexColor) {
      return this.color_primary;
    } else {
      return this.lighten(hexColor, 20);
    }
  }

  colorShade500(hexColor?: string): string {
    return hexColor || this.color_primary;
  }

  colorShade600(hexColor?: string): string {
    if (!hexColor) {
      return this.color_primary;
    } else {
      return this.darken(hexColor, 20);
    }
  }

  colorShade700(hexColor?: string): string {
    if (!hexColor) {
      return this.color_primary;
    } else {
      return this.darken(hexColor, 40);
    }
  }

  colorShade800(hexColor?: string): string {
    if (!hexColor) {
      return this.color_primary;
    } else {
      return this.darken(hexColor, 60);
    }
  }

  colorShade900(hexColor?: string): string {
    if (!hexColor) {
      return this.color_primary;
    } else {
      return this.darken(hexColor, 80);
    }
  }

  darken(hexColor: string, percent: number = 10): string {
    const color = this.hexToColor(hexColor);
    const f = 1 - percent / 100;
    const darkenedColor = this.rgbToHex(
      Math.round(color.r * f),
      Math.round(color.g * f),
      Math.round(color.b * f)
    );
    return darkenedColor;
  }

  lighten(hexColor: string, percent: number = 10): string {
    const color = this.hexToColor(hexColor);
    const p = percent / 100;
    const lightenedColor = this.rgbToHex(
      color.r + ((255 - color.r) * p),
      color.g + ((255 - color.g) * p),
      color.b + ((255 - color.b) * p)
    );
    return lightenedColor;
  }

  hexToColor(code: string): { r: number, g: number, b: number } {
    const hex = code.replace(/^#/, '');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  }

  rgbToHex(r: number, g: number, b: number): string {
    return `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1)}`;
  }
}
