<app-loader *ngIf="loader"></app-loader>
<div class="container-fluid --home">
    <div class="row">
        <div class="col-12">
            <div class="header mt-3">
                <div class="text">
                    <img src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file260fa82a-0150-4c07-a413-e289dffd98fb.png" width="65px" height="65px" alt="">
                    VIPS ADMIN
                </div>
            </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 pl-5 pr-4 mb-3" style="max-height: 290px;">
          <div class="box p-0 m-0">
            <div class="row mb-2 p-2">
              <div class="col-lg-6 col-md-3 col-sm-12">

              </div>
              <div class="col-lg-6 col-md-9 col-sm-12" [formGroup]="searchByUsernameFormGroup">
                <ng-select
                  [items]="userIdFilterOptions"
                  placeholder="Select Username"
                  bindLabel="text"
                  bindValue="id"
                  formControlName="username">
                </ng-select>
              </div>
            </div>

            <div class="row p-0 m-0">
              <div class="col-12 pt-2 pl-0">
                  <h5>Vips Franchise Details</h5>
                  <table class="table table-striped --tablee">
                      <tbody>
                          <tr>
                            <td style="width:100px">Name</td>
                            <th style="max-width: 100px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; text-align: right;" class="text-nowrap" scope="row">{{ selectedManager == 0 ? "---" : user?.managerUser?.firstName + ' ' + user?.managerUser?.lastName }}</th>

                            <td style="width:100px">V-Wallet</td>
                            <th style="text-align: right;" class="text-nowrap" scope="row">{{ selectedManager == 0 ? "---" : wallet | number : "1.2-2"}}</th>
                          </tr>
                          <tr>
                            <td style="width:100px">Mobile Number</td>
                            <th style="text-align: right;" class="text-nowrap" scope="row">{{ selectedManager == 0 ? "---" : user?.managerUser?.contactNumber }}</th>

                            <td style="width:100px">Rewards</td>
                            <th style="text-align: right;" class="text-nowrap" scope="row">{{ selectedManager == 0 ? "---" : totalReward }}</th>
                          </tr>
                          <tr>
                            <td style="width:100px">Activation Date</td>
                            <th style="text-align: right;" class="text-nowrap" scope="row">{{ selectedManager == 0 ? "---" : user?.managerUser?.emailActivationDate | date:"MMM dd, yyyy"}}</th>

                            <td style="width:100px">Ref Code</td>
                            <th style="text-align: right;" class="text-nowrap" scope="row">{{ selectedManager == 0 ? "---" : ("V" + (selectedManager?.toString()).padStart(5,0) + username.substring(0,2).toUpperCase()) }}</th>
                          </tr>
                        </tbody>
                    </table>
              </div>
              <div class="col-12 mt-2 mb-2 pl-0">
                <h5>Summary of Rewards and Vips Users</h5>
                <table class="table table-striped --tablee">
                    <tbody>
                      <tr>
                        <td style="width:100px">Daily</td>
                        <th style="text-align: right;" class="text-nowrap" scope="row">{{ selectedManager == 0 ? "---" : dailyReward }}</th>

                        <td style="width:100px">Users</td>
                        <th style="text-align: right;" class="text-nowrap" scope="row">{{ selectedManager == 0 ? "---" : userCount}}</th>
                      </tr>
                      <tr>
                        <td style="width:100px">Weekly</td>
                        <th style="text-align: right;" class="text-nowrap" scope="row">{{ selectedManager == 0 ? "---" : weeklyReward }}</th>

                        <td style="width:100px">Merchants</td>
                        <th style="text-align: right;" class="text-nowrap" scope="row">{{ selectedManager == 0 ? "---" : managerCount}}</th>
                      </tr>
                      <tr>
                        <td style="width:100px">Monthly</td>
                        <th style="text-align: right;" class="text-nowrap" scope="row">{{ selectedManager == 0 ? "---" : monthlyReward }}</th>

                        <td style="width:100px">Riders</td>
                        <th style="text-align: right;" class="text-nowrap" scope="row">{{ selectedManager == 0 ? "---" : riderCount}}</th>
                      </tr>
                    </tbody>
                </table>
              </div>
          </div>

          </div>
        </div>
        <div class="col-lg-7 col-md-12 col-sm-12 pl-5 pr-5">
            <div class="row" style="height: 290px;">
                <div class="col-12 pt-2">
                    <h5>Table for Vips User - Total Linked Users : {{selectedManager != 0 ? users?.length : 0}}</h5>
                    <div class="d-flex mt-2 p-2" [formGroup]="filterUserFormGroup">
                        <div class="d-flex justify-content-center align-items-center">
                          <ng-select [items]="userOptions" 
                            placeholder="Select User Type"
                            bindLabel="text" 
                            bindValue="id" 
                            formControlName="userType">
                          </ng-select>
                        </div>
                        <div class="flex"></div>
                        <div class="d-flex justify-content-center align-items-center">
                            <div class="input-group">
                                <input
                                  type="search"
                                  class="form-control rounded"
                                  placeholder="Search"
                                  formControlName="searchString"
                                  appDebounceClick
                                  [debounceTime]="500"
                                  aria-label="Search"
                                  (click)="clearValue()"
                                  aria-describedby="search-addon" />
                                <button type="button" class="btn btn-outline-primary">Search</button>
                            </div>
                        </div>
                        <!-- <div class="col-6" [formGroup]="searchByFormGroup">
                            <input
                            type="text"
                            placeholder="Date Range"
                            class="form-control"
                            bsDaterangepicker
                            formControlName="dateRange"
                            style="background-color: white; color: black; height: 37px;"
                            [bsConfig]="{
                            rangeInputFormat: 'YYYY-MM-DD',
                            dateInputFormat: 'YYYY-MM-DD',
                            showWeekNumbers: false
                            }"
                        />
                        </div> -->
                      </div>
                        <div class="col-12 mt-3"  style="max-height: 210px; overflow-y: scroll;">
                            <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
                                <thead>
                                  <tr>
                                    <th data-sortable="true" data-field="name">
                                      <div>Date</div>
                                    </th>
                                    <th data-sortable="true" data-field="value">
                                      <div>User Type</div>
                                    </th>
                                    <th data-sortable="true" data-field="description">
                                      <div>User Details</div>
                                    </th>
                                    <th data-sortable="true" data-field="description">
                                        <div>Rider Balance</div>
                                    </th>
                                    <!-- <th data-field="finish">
                                        <span class="d-none d-sm-block">Status</span>
                                    </th> -->
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    *ngFor="let user of users | sortDesc:'Createdate'"
                                    (click)="viewUser(user)"
                                    style="cursor: pointer"
                                    class="odd"
                                    role="row"
                                  >
                                    <td class="flex">
                                      <!-- <div class="item-except text-sm h-1x">
                                        {{ user?.Createdate | date: "dd-MMM-yyyy"}}
                                      </div> -->
                                      <div>
                                        <p class="text-muted mb-0" style="font-size: 10px">{{ user.Createdate | date: "MMM" }}</p>
                                        <p class="mb-0 mt-0 text-highlight font-weight-bold" style="font-size: 14px">
                                          {{ user.Createdate | date: "dd" }}
                                        </p>
                                        <p class="text-muted mt-0" style="font-size: 10px">{{ user.Createdate | date: "yyyy" }}</p>
                                      </div>
                                    </td>
                                    <td class="flex">
                                      <div class="item-except text-sm h-1x">
                                        {{ getUserType(user?.Usertype) }}
                                      </div>
                                    </td>
                                    <td class="flex">
                                      <div class="item-except text-sm h-1x">
                                        <p style="font-size: 14px">Name: <span class="font-weight-bold">{{ user?.FullName }}</span></p>
                                      </div>
                                      <div class="item-except text-sm h-1x">
                                        <p style="font-size: 14px">Mobile No: <span class="font-weight-bold">{{ user?.MobileNo }} </span></p>
                                      </div>
                                    </td>
                                    <td class="flex" >
                                        <div *ngIf="getUserType(user?.Usertype) != 'Rider'" class="item-except text-sm h-1x">
                                          N/A
                                        </div>
                                        <div *ngIf="getUserType(user?.Usertype) == 'Rider'" class="item-except text-sm h-1x">
                                          {{ getVipcoWallet(user?.WalletAccounts) }}
                                        </div>
                                    </td>

                                    <!-- <td class="flex" style="max-width: 75px">
                                      <div class="item-except">
                                        <label *ngIf="user?.status == true">
                                       Active
                                        </label>

                                        <label *ngIf="user?.status != true">

                                          Inactive
                                        </label>
                                      </div>
                                    </td> -->
                                  </tr>
                                </tbody>
                              </table>
                        </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 pl-5 pr-5">
            <div class="row box mt-3">
                <div class="col-12 pt-2">
                    <h5>Table for Vips Transactions</h5>
                    <div class="row mt-2 p-2" [formGroup]="searchTranscationFormGroup">
                        <div class="col-lg-2 col-md-6 col-sm-12">
                          <button class="btn btn-success btn-sm" *ngIf="selectedManager != 0" (click)="getTransactionExcel()"><i class="fa fa-file-excel"></i> &nbsp; Export</button>
                        </div>

                        <div class="col-lg-3 col-md-6 col-sm-12">
                          <input
                            type="text"
                            placeholder="Date Range"
                            class="form-control"
                            bsDaterangepicker
                            formControlName="dateRange"
                            style="background-color: white; color: black;"
                            [bsConfig]="{
                              rangeInputFormat: 'YYYY-MM-DD',
                              dateInputFormat: 'YYYY-MM-DD',
                              showWeekNumbers: false
                            }"
                          />
                        </div>

                        <div class="col-lg-7 col-md-7 col-sm-12 pr-0">
                            <div class="input-group">
                                <input type="search" class="form-control rounded" formControlName="searchString" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                                <button type="button" (click)="getTransaction()" class="btn btn-outline-primary mr-2">Search</button>
                            </div>
                        </div>

                        <div class="col-12 mt-3"  style="max-height: calc(100vh - 665px); overflow-y: scroll;">
                            <table id="datatable" class="table table-striped table-hover " role="grid" aria-describedby="datatable_info">
                                <thead>
                                  <tr>
                                    <th data-sortable="true" data-field="name">
                                      <div>Date</div>
                                    </th>
                                    <th data-sortable="true" data-field="value">
                                      <div>Transaction Type</div>
                                    </th>
                                    <th data-sortable="true" data-field="description">
                                      <div>User Type</div>
                                    </th>
                                    <th data-sortable="true" data-field="description">
                                        <div>Reference</div>
                                    </th>
                                    <th data-sortable="true" data-field="description">
                                        <div>Rewards</div>
                                    </th>
                                    <th data-field="finish">
                                        <span class="d-none d-sm-block">Status</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    *ngFor="let transaction of transactions?.data | paginate: { itemsPerPage: pageRows, currentPage: p, totalItems: transactions?.totalRecords }"
                                    
                                    style="cursor: pointer"
                                    class="odd"
                                    role="row"
                                  >
                                    <td class="flex">
                                      <div class="item-except text-sm h-1x">
                                        {{ transaction?.createdDate | date: "dd-MMM-yyyy" }}
                                      </div>
                                    </td>
                                    <td class="flex">
                                      <div class="item-except text-sm h-1x">
                                        {{ transaction?.transactionName.split(" - ")[0] }}
                                      </div>
                                    </td>
                                  <td class="flex">
                                    <div class="item-except text-sm h-1x">
                                      {{ transaction?.transactionName.split(" - ")[1] }}
                                    </div>
                                  </td>
                                    <td class="flex">
                                      <div class="item-except text-sm h-1x">
                                        {{ transaction?.requestId }}
                                      </div>
                                    </td>
                                    <td class="flex">
                                        <div class="item-except text-sm h-1x">
                                          {{ transaction?.amount | number : "1.2-2"}}
                                        </div>
                                      </td>

                                    <td class="flex" style="max-width: 75px">
                                        <app-status-table-data [status]="transaction?.statusId"></app-status-table-data>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                        </div>
                        <div class="col-12">
                          <div class="row mt-5" *ngIf="selectedManager != 0">
                            <div class="col-sm-12 col-md-5">
                              <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                                <div class="col-sm-12 col-md-12">
                                  <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                                    <span class="font-weight-bold"
                                      >Showing {{ (transactions?.pageNumber - 1) * transactions?.pageSize + 1 }} to
                                      {{ (transactions?.pageNumber - 1) * transactions?.pageSize + transactions?.data?.length }} of
                                      {{ transactions?.data?.length }} entries</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-7" style="float: right">
                              <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                                <ul class="pagination" style="float: right; margin-right: 36px">
                                  <pagination-controls (pageChange)="getTransaction(buildPageUrl($event, 10))"></pagination-controls>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #riderViewModal let-modal>
  <div class="card">
    <div class="rounded-top text-white d-flex flex-row" style="background-image: url(../../../../../assets/vips/rider___\ s.png); height:200px; padding-inline: 20px; background-size: cover;">
      <div class="card_0" *ngIf="selectedRider?.remarks">
        <div class="align">
            <span class="red"></span>
            <h1 style="margin: 0;
            padding: 0;
            font-size: 12px;
            color: white;">Remarks</h1>
        </div>
        <p class="mt-4 text-left" style="font-size: 11px;">
          {{selectedRider?.remarks}}
        </p>
      </div>
      <div class="ms-4 mt-5 d-flex flex-column" style="width: 150px;">
        <img [src]="selectedRider?.profile_pic"
          *ngIf="selectedRider?.profile_pic"
          alt="Generic placeholder image" class="img-fluid img-thumbnail mt-4 mb-2"
          style="width: 150px; z-index: 1; height: 155px;">

        <img src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file3068edc4-eb2a-47f4-b9aa-86d6536a0d43.png"
          *ngIf="!selectedRider?.profile_pic"
          alt="Generic placeholder image" class="img-fluid img-thumbnail mt-4 mb-2"
          style="width: 150px; z-index: 1; height: 155px;">
          <span class="badge badge-pill badge-danger" *ngIf="selectedRider?.application_rejected">Rejected</span>
          <span class="badge badge-pill badge-success" *ngIf="!selectedRider?.application_rejected">Approved</span>
      </div>
      <div class="ms-2" style="margin-top: 130px;">
        <h3 class="ml-2 text-white">{{ selectedRider?.first_name }} {{ selectedRider?.last_name }}</h3>
        <p class="ml-2"> {{selectedRider?.address_address}} </p>
      </div>
    </div>
    <div class="p-4 text-black" style="background-color: #f8f9fa;padding-bottom: 10px !important;">
      <div class="d-flex justify-content-end text-center py-1">
        <div>
          <span class="badge badge-pill badge-primary mb-0" *ngIf="selectedRider?.is_active == true">Active</span>
          <!-- <span class="badge badge-pill badge-primary mb-0" *ngIf="selectedRider?.is_active == false">Inactive</span> -->
          <span class="badge badge-pill badge-secondary mb-0" *ngIf="!selectedRider?.is_active">Inactive</span>
          <p class="small text-muted mb-0">Status</p>
        </div>
        <div class="px-3">
          <p class="mb-1 h5" *ngIf="selectedRider?.rider_rating_count">{{selectedRider?.rider_rating_count}}</p>
          <p class="mb-1 h5" *ngIf="!selectedRider?.rider_rating_count">0</p>
          <p class="small text-muted mb-0">Reviews</p>
        </div>
        <div style="text-align: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;">
          <p class="mb-1 h5">{{selectedRider?.rider_rating | slice:0:3}}
            <span class = "fa fa-star checked" *ngIf="selectedRider?.rider_rating>5 || selectedRider?.rider_rating==5"></span>
            <span class = "fa fa-star zero" *ngIf="selectedRider?.rider_rating<0 || selectedRider?.rider_rating==0"></span>
            <span *ngIf="!selectedRider?.rider_rating">--</span>
          </p>
          <p class="small text-muted mb-0">Rating</p>
        </div>
      </div>
    </div>
    <div class="card-body p-4 text-black" style="padding-top: 0 !important;">
      <div class="mb-2">
        <p class="lead fw-normal mb-1">About</p>
        <div class="p-4" style="background-color: #f8f9fa;padding-top: 5px !important; display: flex; gap: 20px;">
          <div class="dets">
            <p class="mb-1 h6">E-mail:</p>
            <p class="mb-1 h6">Birthday:</p>
            <p class="mb-1 h6">Gender:</p>
            <p class="mb-0 h6">Plate Number:</p>
          </div>
          <div class="inf">
            <p class="mb-1 h6">{{selectedRider?.email_address}}</p>
            <p class="mb-1 h6">{{selectedRider?.birth_date}}</p>
            <p class="mb-1 h6">{{selectedRider?.gender}}</p>
            <p class="mb-1 h6">{{selectedRider?.vehicle_plate_no}}</p>
          </div>

        </div>
        <!-- <div class="p-4" style="background-color: #f8f9fa;padding-top: 5px !important;">
          <p class="mb-1">E-mail:&nbsp;{{selectedRider?.email_address}}</p>
          <p class="mb-1">Birthday:&nbsp;{{selectedRider?.birth_date}}</p>
          <p class="mb-1">Gender:&nbsp;{{selectedRider?.gender}}</p>
          <p class="mb-0">Plate Number:&nbsp;{{selectedRider?.vehicle_plate_no}}</p>
        </div> -->
      </div>

      <div class="img_container mb-3">
        <p class="lead fw-normal mb-1">Identifications</p>
        <img style="max-width:100%;cursor:pointer"
        *ngIf="selectedRider?.identification_front_url"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" [src]="selectedRider?.identification_front_url" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        *ngIf="!selectedRider?.identification_front_url"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file3068edc4-eb2a-47f4-b9aa-86d6536a0d43.png" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        *ngIf="selectedRider?.identification_back_url"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" [src]="selectedRider?.identification_back_url" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        *ngIf="!selectedRider?.identification_back_url"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file3068edc4-eb2a-47f4-b9aa-86d6536a0d43.png" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        *ngIf="selectedRider?.identification_selfie"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" [src]="selectedRider?.identification_selfie" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        *ngIf="!selectedRider?.identification_selfie"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file3068edc4-eb2a-47f4-b9aa-86d6536a0d43.png" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        *ngIf="selectedRider?.identification_selfie_with_id"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" [src]="selectedRider?.identification_selfie_with_id" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        *ngIf="!selectedRider?.identification_selfie_with_id"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file3068edc4-eb2a-47f4-b9aa-86d6536a0d43.png" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        *ngIf="selectedRider?.vaccination_card"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" [src]="selectedRider?.vaccination_card" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        *ngIf="!selectedRider?.vaccination_card"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file3068edc4-eb2a-47f4-b9aa-86d6536a0d43.png" alt="Snow">
      </div>

      <div class="img_container">
        <p class="lead fw-normal mb-1">Vehicle Details</p>
        <img style="max-width:100%;cursor:pointer"
        *ngIf="selectedRider?.vehicle_cr"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" [src]="selectedRider?.vehicle_cr" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        *ngIf="!selectedRider?.vehicle_cr"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file3068edc4-eb2a-47f4-b9aa-86d6536a0d43.png" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        *ngIf="selectedRider?.vehicle_or"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" [src]="selectedRider?.vehicle_or" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        *ngIf="!selectedRider?.vehicle_or"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file3068edc4-eb2a-47f4-b9aa-86d6536a0d43.png" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        *ngIf="selectedRider?.vehicle_photo"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" [src]="selectedRider?.vehicle_photo" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        *ngIf="!selectedRider?.vehicle_photo"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file3068edc4-eb2a-47f4-b9aa-86d6536a0d43.png" alt="Snow">
      </div>

    </div>
  </div>

  <div class="modal fade" id="myModal" role="dialog" style="backdrop-filter: blur(8px);">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text-white">Image Viewer</h4>
          <button type="button" #closebutton class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
        <img
        src="{{imgSrc}}"
        style="max-width:100%;cursor:pointer; max-height: 590px;"
        class="modal-hover-opacity"
      />
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #merchantViewModal let-modal>
  <div class="card">
    <div class="rounded-top text-white d-flex flex-row" style="height:200px; padding-inline: 20px; background-size: cover;">
      <img class="rounded-top" [src]="selectedMerchant?.banner"
      style="
      height: 200px;
      width: 100%;
      position: absolute;
      background-size: cover;
      left: 0;" *ngIf="selectedMerchant?.banner">
      <img src="../../../../../assets/vips/No BANNER.png" class="img-fluid" style="
      height: 200px;
      width: 100%;
      position: absolute;
      background-size: cover;
      left: 0;" *ngIf="!selectedMerchant?.banner">
      <div class="card_0" *ngIf="selectedMerchant?.remarks">
        <div class="align">
            <span class="red"></span>
            <h1 style="margin: 0;
            padding: 0;
            font-size: 12px;
            color: white;">Remarks</h1>
        </div>
        <p class="mt-4 text-left" style="font-size: 11px;">
          {{selectedMerchant?.remarks}}
        </p>
      </div>
      <div class="ms-4 mt-5 d-flex flex-column" style="width: 150px;">
        <img [src]="selectedMerchant?.business_store_logo"
          *ngIf="selectedMerchant?.business_store_logo"
          alt="Generic placeholder image" class="img-fluid img-thumbnail mt-4 mb-2"
          style="width: 150px; z-index: 1; height: 155px;">

        <img src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file3068edc4-eb2a-47f4-b9aa-86d6536a0d43.png"
          *ngIf="!selectedMerchant?.business_store_logo"
          alt="Generic placeholder image" class="img-fluid img-thumbnail mt-4 mb-2"
          style="width: 150px; z-index: 1; height: 155px;">
          <span class="badge badge-pill badge-danger" *ngIf="selectedMerchant?.application_rejected && !selectedMerchant?.application_status">Rejected</span>
          <span class="badge badge-pill badge-success" *ngIf="!selectedMerchant?.application_rejected && selectedMerchant?.application_status">Approved</span>
          <span class="badge badge-pill badge-success" *ngIf="!selectedMerchant?.application_rejected && !selectedMerchant?.application_status">Not Registered</span>
      </div>
    </div>
    <div class="p-4 text-black" style="background-color: #f8f9fa; padding-top: 2rem !important;">
      <div class="d-flex justify-content-between text-center py-1">
        <div class="ms-2" style="z-index: 5;">
          <h3 class="text-left text-dark h5 fw-600">{{ selectedMerchant?.business_name }}</h3>
          <p class="text-left h6 text-muted"> {{selectedMerchant?.business_address_address}} </p>
        </div>
        <div>
          <span class="badge badge-pill badge-primary mb-0" *ngIf="selectedMerchant?.is_active == true">Active</span>
          <span class="badge badge-pill badge-secondary mb-0" *ngIf="!selectedMerchant?.is_active">Inactive</span>
          <p class="small text-muted mb-0">Status</p>
        </div>
        <div class="">
          <p class="mb-1 h5" *ngIf="selectedMerchant?.store_rating_count">{{selectedMerchant?.store_rating_count}}</p>
          <p class="mb-1 h5" *ngIf="!selectedMerchant?.store_rating_count">0</p>
          <p class="small text-muted mb-0">Reviews</p>
        </div>
        <div style="text-align: center;
        align-items: center;
        display: flex;
        flex-direction: column;">
          <p class="mb-1 h5">{{selectedMerchant?.store_rating | slice:0:3}}
            <span class = "fa fa-star checked" *ngIf="selectedMerchant?.store_rating>5 || selectedMerchant?.store_rating==5"></span>
            <span class = "fa fa-star zero" *ngIf="selectedMerchant?.store_rating<0 || selectedMerchant?.store_rating==0"></span>
            <span *ngIf="!selectedMerchant?.store_rating">--</span>
          </p>
          <p class="small text-muted mb-0">Rating</p>
        </div>
      </div>
    </div>
    <div class="card-body p-4 text-black" style="padding-top: 0 !important;">
      <div class="mb-2">
        <p class="lead fw-normal mb-1 h5 text-muted">Owner Details</p>
        <div class="p-4" style="background-color: #f8f9fa;padding-top: 5px !important; display: flex; gap: 20px;">
          <div class="dets">
            <p class="mb-1 h6">Name:</p>
            <p class="mb-1 h6">Phone:</p>
            <p class="mb-1 h6">E-mail:</p>
            <p class="mb-1 h6">Birthday:</p>
            <p class="mb-1 h6">Gender:</p>
          </div>
          <div class="inf">
            <p class="mb-1 h6">{{selectedMerchant?.first_name}} {{selectedMerchant?.last_name}} &nbsp;</p>
            <p class="mb-1 h6">{{selectedNumber}}</p>
            <p class="mb-1 h6">{{selectedMerchant?.email_address}}</p>
            <p class="mb-1 h6">{{selectedMerchant?.birth_date}}</p>
            <p class="mb-1 h6">{{selectedMerchant?.gender}}</p>
          </div>

        </div>
      </div>

      <div class="img_container mb-3">
        <p class="lead fw-normal mb-1 text-muted h5">Identifications</p>
        <img style="max-width:100%;cursor:pointer"
        *ngIf="selectedMerchant?.identification_front_url"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" [src]="selectedMerchant?.identification_front_url" alt="Snow">
        
        <img style="max-width:100%;cursor:pointer"
        *ngIf="!selectedMerchant?.identification_front_url"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file3068edc4-eb2a-47f4-b9aa-86d6536a0d43.png" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        *ngIf="selectedMerchant?.identification_back_url"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" [src]="selectedMerchant?.identification_back_url" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        *ngIf="!selectedMerchant?.identification_back_url"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file3068edc4-eb2a-47f4-b9aa-86d6536a0d43.png" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        *ngIf="selectedMerchant?.identification_selfie"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" [src]="selectedMerchant?.identification_selfie" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        *ngIf="!selectedMerchant?.identification_selfie"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file3068edc4-eb2a-47f4-b9aa-86d6536a0d43.png" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        *ngIf="selectedMerchant?.identification_selfie_with_id"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" [src]="selectedMerchant?.identification_selfie_with_id" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        *ngIf="!selectedMerchant?.identification_selfie_with_id"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file3068edc4-eb2a-47f4-b9aa-86d6536a0d43.png" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        *ngIf="selectedMerchant?.business_store_facade"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" [src]="selectedMerchant?.business_store_facade" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        *ngIf="!selectedMerchant?.business_store_facade"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file3068edc4-eb2a-47f4-b9aa-86d6536a0d43.png" alt="Snow">
      </div>

      <!-- <div class="img_container">
        <p class="lead fw-normal mb-1">Vehicle Details</p>
        <img style="max-width:100%;cursor:pointer"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" [src]="selectedMerchant?.vehicle_cr" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" [src]="selectedMerchant?.vehicle_or" alt="Snow">

        <img style="max-width:100%;cursor:pointer"
        (click)="onClick($event)"
        class="modal-hover-opacity imgs"
        data-toggle="modal" data-target="#myModal" [src]="selectedMerchant?.vehicle_photo" alt="Snow">
      </div> -->

    </div>
  </div>

  <div class="modal fade" id="myModal" role="dialog" style="backdrop-filter: blur(8px);">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text-white">Image Viewer</h4>
          <button type="button" #closebutton class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
        <img
        src="{{imgSrc}}"
        style="max-width:100%;cursor:pointer; max-height: 590px;"
        class="modal-hover-opacity"
      />
        </div>
      </div>
    </div>
    </div>
</ng-template>
