import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UploadService {
  private localStorageCacheService: LocalStorageCacheService;
  private baseAPIUrl = environment.API_URL;

  constructor(
    private httpClient: HttpClient,
    localStorageCacheService: LocalStorageCacheService,
    httpBackend: HttpBackend
  ) {
    this.localStorageCacheService = localStorageCacheService;
  }
  private httpClientNoInterceptor: HttpClient;

  public uploadImage(vals): Observable<any> {
    var data = vals;
    const endPointUrl = `https://api.cloudinary.com/v1_1/govipcenter-com/image/upload`;
    // const accessToken = this.localStorageCacheService.getStorage("access_token");

    // const httpHeaders: HttpHeaders = new HttpHeaders()
    //   .append("Authorization", "Bearer " + accessToken)
    //   .append("Accept", "application/json");

      return this.httpClient.post(`${endPointUrl}`, data);
  }

  // public uploadImageAzureBlob(vals: any): Observable<any> {
  //   const endPointUrl = `https://govipxprd.azurewebsites.net/api/azure_storage?code=h2TyhfCaBYZwIRWkyHc1sP4DpVGs2XOuEisCbs8H5-5UAzFu_Afq_Q==`;
  //   const accessToken = this.localStorageCacheService.getStorage("access_token");
  //   debugger
  //   const httpHeaders: HttpHeaders = new HttpHeaders()
  //     .append("Authorization", "Bearer " + accessToken)
  //     .append("Accept", "application/json");

  //     var json = {
  //       folder_name : "topupreceipts",
  //       file : vals
  //     };

  //     return this.httpClient.put(`${endPointUrl}`, JSON.stringify(json) , { headers: httpHeaders });
  // }

  public uploadImageAzureBlob(vals: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/RequestFunds/ReceiptImage`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      var json = {
        folder_name : "topupreceipts",
        file : vals
      };

    return this.httpClient.post(endPointUrl, json ,{ headers: httpHeaders });
  }
  

  public uploadImageGovipStore(vals: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/RequestFunds/ReceiptImage`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      var json = {
        folder_name : "govipstore",
        file : vals
      };

    return this.httpClient.post(endPointUrl, json ,{ headers: httpHeaders });
  }

  public uploadIglooCTPL(vals: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/RequestFunds/ReceiptImage`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      var json = {
        folder_name : "govipstore",
        file : vals
      };

    return this.httpClient.post(endPointUrl, json ,{ headers: httpHeaders });
  }

  public uploadProofOfDelivery(vals: any, poId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/ProofOfDelivery`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      var json = {
        folder_name : "govipstore",
        file : vals,
        poId: poId
      };

    return this.httpClient.post(endPointUrl, json ,{ headers: httpHeaders });
  }

  public removeProofOfDelivery(imageId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/ProofOfDelivery`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      var json = {
        imageId: imageId
      };

    return this.httpClient.put(endPointUrl, json ,{ headers: httpHeaders });
  }
  
  public uploadImageCreatedItem(vals: any, stockItemId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/UploadImageStoreItem`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      var json = {
        folder_name : "govipstore",
        file : vals,
        StockItemId: stockItemId
      };

    return this.httpClient.post(endPointUrl, json ,{ headers: httpHeaders });
  }

  // tslint:disable-next-line: typedef
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  // tslint:disable-next-line: typedef
  private log(message: string) {
    //
  }
}
