import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SettlementsService } from 'src/data/services/web/settlements.service';
import { Select2OptionData } from "ng-select2";
import { FormControl, FormGroup } from '@angular/forms';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { ManagerUsersService } from 'src/data/services/web/manager-users.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadService } from 'src/data/services/web/file-upload.service';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-spx-remittance-settlement-edit',
  templateUrl: './spx-remittance-settlement-edit.component.html',
  styleUrls: ['./spx-remittance-settlement-edit.component.less']
})
export class SpxRemittanceSettlementEditComponent implements OnInit {


  @Input('transaction') transaction : any;
  @Input('remittance') remittance : any;
  @Output("closeModal") closeModal = new EventEmitter();
  @Output("getTransaction") getTransaction = new EventEmitter();

  public storesOption: Array<Select2OptionData>;
  public settlementTypesOption: Array<Select2OptionData>;
  generalFormGroup : FormGroup;
  public _fileUploadService: FileUploadService;
  loader = false;

  constructor(private settlementsService : SettlementsService, 
    private websettings : WebSettingsService,
    private fileUpload: FileUploadService,
    private localStorageCacheService: LocalStorageCacheService,
    private modalServe: NgbModal,
    private managerUsersService : ManagerUsersService) {
      PNotifyButtons;
      this._fileUploadService = fileUpload;
     }

     roleId: any;
  ngOnInit(): void {
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    this.initAmount();
  }

  amount: any;
  initAmount() {
    this.websettings.getWebSettingsByIdentifier("SPX_RIDER_PAY").subscribe((result) => {
      this.amount = result.data[0].value;
    });
  }


  saveSettlement(){
    // this.loader = true;
    //   if (confirm("Are you sure you want to continue? [SAVE]")) {
    //   this.settlementsService.updateShopeeData(this.generalFormGroup.value,this.transaction.id).subscribe((result) => {
    //     this.loader = false;
    //     PNotify.success({
    //       title: "Success",
    //       text: "Data Saved Successfully!",
    //     });
    //   });
    // }else{
    //   this.loader = false;
    // }
  }

  sendEmailBilling(){
    this.loader = true;
    if(this.transaction.spxInvoiceURL){
      if (confirm("Are you sure you want to send Email for Billing Invoice?")) {
        this.settlementsService.sendInvoiceSPX(this.transaction.id).subscribe((result) => {
          this.loader = false;
          PNotify.success({
            title: "Success",
            text: "Email has been sent!",
          });
          this.getTransaction.emit();
          this.closeModal.emit();
        }, error=>{
          this.loader = false;
          PNotify.error({
            title: "Sending Failed",
            text: "All fields are required.",
          });
        });
      }else{
        this.loader = false;
      }
    } else {
      this.loader = false;
      PNotify.error({
        title: "Invalid",
        text: "Please Upload Billing Invoice.",
      });
    }
  }
  
  saveAndCompleteSettlement(){
    this.loader = true;
    if(this.transaction.spxReceiptURL){
      if (confirm("Are you sure you want to process SPX Billing?")) {
        this.settlementsService.updateAndCompleteSPXData(this.transaction.id).subscribe((result) => {
          this.loader = false;
          PNotify.success({
            title: "Success",
            text: "Data Saved Successfully!",
          });
          this.getTransaction.emit();
          this.closeModal.emit();
        }, error=>{
          this.loader = false;
          PNotify.error({
            title: "Saving Failed",
            text: "All fields are required.",
          });
        });
      }else{
        this.loader = false;
      }
    } else {
      this.loader = false;
      PNotify.error({
        title: "Invalid",
        text: "Please Upload Billing Receipt.",
      });
    }
  }

  getManagerStores() {
    this.settlementsService.getManagerStores().subscribe((result) => {
     
      this.storesOption = result.map((data) => {
        return {
          id: data.storeName,
          text: data.storeName,
        };
      });
      this.loader = false;
    });
  }

  getSettlementbyStoreName() {

    this.loader = true;
    this.settlementsService.getSettlementbyStoreName(this.generalFormGroup.controls.storeName.value).subscribe((result) => {
      this.transaction.email = result.email;
      this.transaction.transactBy = result.transactBy;
      this.transaction.contactNumber = result.contactNumber;
      this.transaction.name = result.name;
      this.loader = false;
    }, error=>{
      this.loader = false;
    });
  }

  
  checkMemberDetails() {
    this.loader = true;
    this.managerUsersService.getManagerVIPDetails(this.generalFormGroup.controls.managerUsername.value).subscribe(
      (result) => {
        if (result.Username) {
          this.generalFormGroup.controls.managerUsername.setValue(result.Username);
          this.generalFormGroup.controls.managerEmailAddress.setValue(result.EmailAddress);
          this.generalFormGroup.controls.managerContactNumber.setValue(result.MobileNo1);
          this.generalFormGroup.controls.managerName.setValue(result.FirstName + ' ' + result.LastName );
          this.loader = false;
        } else {
          this.generalFormGroup.controls.managerUsername.setValue("");
          this.generalFormGroup.controls.managerEmailAddress.setValue("");
          this.generalFormGroup.controls.managerContactNumber.setValue("");
          this.generalFormGroup.controls.managerName.setValue("" );
          this.loader = false;
          PNotify.error({
            title: "Connecting to VIP",
            text: "username does not exist.",
          });
        }
      },
      (error) => {
        PNotify.error({
          title: "Connecting to VIP",
          text: "username does not exist.",
        });
      }
    );
  }


  triggerFile(inputFile){
    if(this.transaction.statusId != 2){
      inputFile.click();
    }
  }

  triggerInvoice(inputFile){
    if(this.transaction.statusId != 2 && this.transaction.statusId != 3){
      inputFile.click();
    }
  }

  public fileName: any = "Click here to choose file...";
  public base64Photo: string | ArrayBuffer;

  public onUploadFile(event: any) {
    
    const reader = new FileReader();
   
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.fileName = file.name;
      reader.onload = async () => {
        this.base64Photo = reader.result;
        if(this.calculateImageSize(this.base64Photo)){
        debugger
          this.settlementsService.uploadSPXReceipt(this.transaction.id, this.base64Photo,'false').subscribe((result) => {
            this.loader = false;
            PNotify.success({
              title: "Success",
              text: "Data Saved Successfully!",
            });
            this.getTransaction.emit();
            this.closeModal.emit();
          }, error=>{
            this.loader = false;
            PNotify.error({
              title: "Saving Failed",
              text: "All fields are required.",
            });
    
          });
        }else{

          PNotify.error({
                title: "Files",
                text: "File is too big. Must be less than or equal to 1mb.",
              });
        }
      };
    }
  }

  public onUploadInvoice(event: any) {
    
    const reader = new FileReader();
   
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.fileName = file.name;
      reader.onload = async () => {
        this.base64Photo = reader.result;
        if(this.calculateImageSize(this.base64Photo)){
        debugger
          this.settlementsService.uploadSPXInvoice(this.transaction.id, this.base64Photo,'false').subscribe((result) => {
            this.loader = false;
            PNotify.success({
              title: "Success",
              text: "Data Saved Successfully!",
            });
            this.getTransaction.emit();
            this.closeModal.emit();
          }, error=>{
            this.loader = false;
            PNotify.error({
              title: "Saving Failed",
              text: "All fields are required.",
            });
    
          });
        }else{

          PNotify.error({
                title: "Files",
                text: "File is too big. Must be less than or equal to 1mb.",
              });
        }
      };
    }
  }

  public imagePreview: any;
  public photoFormat: any;
  public photo: any;
  public photoFileName: any;
  fileType : any = "IMAGE";
  public uploadModal: NgbModalRef;
  async preview(content: any, url: any, format: any){
    this.loader = true;
    this._fileUploadService.getBase64ImageFromURL(url).subscribe((result) => {
      this.imagePreview = format + "," + result;
      this.uploadModal = this.modalServe.open(content, { centered: true,size:'xl', backdrop: 'static'});
      this.loader = false;
    });
  }

  downloadImage(photo: any, photoFormat: any, trasaction): void {
    const dataURI = photoFormat + "," + photo;
      saveAs(dataURI, "GoVIPCenter Recepit - " + trasaction.spxRiderID);
  }

  calculateImageSize(base64String) : boolean {
    let padding;
    let inBytes;
    let base64StringLength;
    if (base64String.endsWith('==')) { padding = 2; }
    else if (base64String.endsWith('=')) { padding = 1; }
    else { padding = 0; }

    base64StringLength = base64String.length;
    console.log(base64StringLength);
    inBytes = (base64StringLength / 4) * 3 - padding;
    return inBytes < 1000000;
  }

  getDate(deyt: Date){
    var temp = new Date(deyt);
    temp.setDate(temp.getDate()-1);

    console.log(temp);
    return temp;
  }

}
