<div class="card flex flex-column">
    <div class="row" [formGroup]="stockItemCreateFormGroup">
        <div class="col-4" style="border-radius: 15px;">
            <div>
                <img class="card-image" [src]="cardImage" alt="Card image cap">
            </div>
            <div class="mt-3">
                <div class="slider" *ngIf="images.length > 0">
                    <div class="slider-wrapper pt-1 pb-2" [style.width.px]="imageWidth * images.length + (images.length * 5)">
                        <div class="slider-item" *ngFor="let image of images">
                            <img class="item-img" (mouseover)="changeBigImage(image)" [src]="image" [style.width.px]="imageWidth">
                        </div>
                    </div>
                    <button class="slider-arrow left" (click)="scroll(-1)">&lt;</button>
                    <button class="slider-arrow right" (click)="scroll(1)">&gt;</button>
                </div>
                <div class="mt-2">
                    <button class="item-img btn btn-sm btn-primary text-dark" (click)="addImage(addImageModal)"><i class="fa fa-plus"></i></button>
                </div>
            </div>
        </div>
        <div class="col-8 p-3" *ngIf="stockItemCreateFormGroup">
            <!-- <h4> <span class="color-blue font-weight-bolder">{{ stockItem.name }}</span> &nbsp; <span class="color-blue font-weight">({{ stockItem.itemCode }})</span> </h4>
            <button
                class="btn btn-sm mt-2 btn-secondary button--search btn-hover font-weight-bold" style="float:right">
                <i class="fa fa-edit"></i>
            </button>

            <p class="card-text mt-2 mb-2">{{ stockItem.description }}</p> -->
            <div class="row">
                <div class="col-md-11">
                    <!-- <h4 *ngIf="!isToUpdate" >
                        <span class="color-blue font-weight-bolder">
                            {{ stockItem?.name }}
                        </span>
                        
                        &nbsp;
                        <span class="color-blue font-weight" >
                            (
                                {{ stockItem?.itemCode }}
                            )
                        </span>
                    </h4> -->
                    <span>
                        <app-textbox
                            [parent-form-group]="stockItemCreateFormGroup"
                            tab-index-start="0"
                            control-name="name"
                            [read-only]="false"
                            label="Item Name"
                            placeholder="Item Name"
                        ></app-textbox>

                        <app-text-all-caps
                            [parent-form-group]="stockItemCreateFormGroup"
                            tab-index-start="3"
                            control-name="itemCode"
                            read-only="false"
                            no-special-char="false"
                            label="Item Code"
                            placeholder="Item Code"
                            max-length="20"
                        >
                        </app-text-all-caps>

                        <!-- <app-textbox
                            [parent-form-group]="stockItemCreateFormGroup"
                            tab-index-start="0"
                            control-name="itemCode"
                            [read-only]="false"
                            label="Item Code"
                            placeholder="Item Code"
                        ></app-textbox> -->
                    </span>

                    <!-- <p *ngIf="!isToUpdate" class="card-text mt-2 mb-2">{{ stockItem?.description }}</p> -->
                    <span>
                        <app-textarea
                            [parent-form-group]="stockItemCreateFormGroup"
                            tab-index-start="4"
                            control-name="description"
                            read-only="false"
                            label="Description"
                            placeholder="Description"
                            max-length="250"
                        >
                        </app-textarea>
                    </span>
                </div>
                <div class="col-md-1 text-right">
                  <!-- <button 
                    *ngIf="!isToUpdate" 
                    class="btn btn-sm btn-secondary button--search btn-hover font-weight-bold"
                    (click)="setoUpdate()" 
                    tooltip="Update Item">
                    <i class="fa fa-edit"></i>
                  </button> -->

                  <button 
                    class="btn btn-sm btn-danger button--search btn-hover font-weight-bold" 
                    tooltip="Save Item"
                    (click)="saveChange()">
                    <i class="fa fa-save"></i>
                  </button>
                </div>
            </div>

            <div class="w-100 d-flex justify-content-end p-2" style="background-color: rgb(239, 239, 239);">
                <!-- <h3 class="color-red font-weight-bolder mr-3 mb-0" *ngIf="!isToUpdate">₱ {{ makeMoney(stockItem.price) }}</h3> -->
                <!-- <app-textbox
                    [parent-form-group]="stockItemCreateFormGroup"
                    tab-index-start="0"
                    control-name="price"
                    [read-only]="false"
                    label="Price"
                    placeholder="Price"
                ></app-textbox> -->

                <app-textbox-number-only
                  [parent-form-group]="stockItemCreateFormGroup"
                  tab-index-start="0"
                  control-name="price"
                  read-only="false"
                  number-only="true"    
                  label="Price"
                  placeholder="Price"
                >
                </app-textbox-number-only>
            </div>

            <div class="card">
                <div class="d-flex align-items-center px-4 py-3 b-t">
                    <div class="color-blue font-weight-bold">Category</div>
                    <div class="flex">

                    </div>
                    <span>
                        <!-- <label *ngIf="!isToUpdate" class="mr-2">
                            {{ stockItem?.category.category }}
                        </label> -->
                        <ng-select 
                            [items]="categories"
                            bindLabel="text" 
                            bindValue="id"
                            placeholder="Select Category"
                            formControlName="categoryId">
                        </ng-select>
                    </span>
                </div>

                <div class="d-flex align-items-center px-4 py-3 b-t">
                    <div class="color-blue font-weight-bold">Unit of Measurement</div>
                    <div class="flex">

                    </div>
                    <span>
                        <!-- <label *ngIf="!isToUpdate" class="mr-2">
                            {{ stockItem?.unit.abrUnit }}
                        </label> -->
                        <ng-select 
                            [items]="uom"
                            bindLabel="text" 
                            bindValue="id"
                            placeholder="Select Unit of Measurement"
                            formControlName="uomId">
                        </ng-select>
                    </span>
                </div>

            </div>
        </div>
    </div>

</div>

<ng-template #addImageModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Add Image</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
        <ngx-dropzone [multiple]="false" (change)="onSelect($event)" style="height:250px">
            <ngx-dropzone-label>Click to upload or drop the image here!</ngx-dropzone-label>
          
            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" 
            [removable]="true" (removed)="onRemove(f)">
              <ngx-dropzone-label></ngx-dropzone-label>
            </ngx-dropzone-image-preview>
        </ngx-dropzone>
      <!-- <app-stock-item [stockItem]="selectedItem"></app-stock-item> -->
      <hr>

        <div>
          <button class="btn btn-sm btn-primary float-right" (click)="uploadImage()">Upload Image</button>
          <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
            Cancel
          </button>
        </div>
    </div>
  </ng-template>