import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, from } from 'rxjs';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { windowWhen } from 'rxjs/operators';
import { transformAll } from '@angular/compiler/src/render3/r3_ast';
import { VipcoTransactionService } from 'src/data/services/web/vipco-transaction.service';

@Component({
  selector: 'app-vip-loot',
  templateUrl: './vip-loot.component.html',
  styleUrls: ['./vip-loot.component.less']
})
export class VipLootComponent implements OnInit {

  tutorials: Observable<any[]>;
  userId: any;

  constructor(private store: AngularFirestore,
    private elementRef: ElementRef,
    private vipcoService: VipcoTransactionService,
    private localStorageCacheService: LocalStorageCacheService) {
      this.userId = this.localStorageCacheService.getStorage("id");
  }

  public cloudTransactionList = [];
  public loader = false;

  ngOnInit(): void {
    this.loader = true;
    this.initVIPLoot();

    // this.store.collection('VIPCoCloudTransaction').valueChanges().subscribe((val: any) => {
    //   debugger
    //   this.cloudTransactionList = val.filter(x => x.vip_cloud_body.processed_by == "");
    //   console.log(this.cloudTransactionList);
    // });
  }

  ngAfterViewInit() {
    this.transactionBird.forEach(element => {
      this.createReward(element, null, this.userId, this.vipcoService);
    });
  }

  counter: any = 0;
  async initVIPLoot(){
    await this.store.collection('VIPLOOT', ref => ref.where("userId","==",null)).valueChanges().subscribe((val: any) => {
      this.counter++;
      debugger
      if(this.counter == 1 || this.transaction.length == 0){
        this.transaction = val;
        val.forEach((element) => {
          this.createReward(element, this.store, this.userId, this.vipcoService);
        });
      } else {
        val.forEach((element) => {
          //debugger
          var matchingElement = this.transaction.find(x => x.requestId == element.requestId);
  
          if (matchingElement) {
            // An element with the same requestId already exists in the transaction array
            // You can access its length property or perform other operations
            //var transLength = matchingElement.length;
          } else {
            // An element with the specified requestId does not exist in the transaction array
            // You can create a new reward or perform other operations
            this.createReward(element, this.store, this.userId, this.vipcoService);
            this.transaction.push(element);
          }
        });
      }
      
      this.loader = false;
    });
  }

  computeTotalAmount(firstValue, secondValue){
      var totalAmount = Number(firstValue.replaceAll(",",""))  + Number(secondValue.replaceAll(",",""));
      return totalAmount;
  }

  processTransaction(id){
    debugger
    this.store
      .firestore
      .collection('VIPCoCloudTransaction')
      .doc(id)
      .set({
        vip_cloud_body:
        {
          processed_by: this.userId
        }
      }, {
        merge: true,
    });
  }

  convertToDynamicJson(jsonString){
      return JSON.parse(jsonString);
  }
  //Vip Cloud Game
  transaction: any = [];
  transactionBird = [
    {requestId: 'bird1', name: "bird1"},  
    {requestId: 'bird2', name: "bird2"},
    {requestId: 'bird3', name: "bird3"},
    {requestId: 'bird4', name: "bird4"},
    {requestId: 'bird5', name: "bird5"},
    {requestId: 'bird6', name: "bird6"},
    {requestId: 'bird7', name: "bird7"},
    {requestId: 'bird8', name: "bird8"},
    {requestId: 'bird9', name: "bird9"},
    {requestId: 'bird0', name: "bird10"}
  ];
  //Create an array containing the multi animation
  // anim: any = ['birdfly','birdfly1','birdfly2','birdfly3','birdfly4','birdfly5'];
  anim = 'birdfly';
  currentBird = 20;
  add = {id: 'bird', name: "ab-normal"};
  addB(){
    this.transaction.push(this.add);
    console.log(this.transaction);
  }

  createReward(transaction, store, userID, vipcoService){
    debugger
    let start = document.querySelector('.start-btn');
    // let rand = Math.floor(Math.random() * this.anim.length);
    let rand = Math.floor(Math.random() * 1);
    //let mainpage = document.querySelector('.main-game');
    let mainpage = document.querySelector<HTMLElement>('.main-game');
    const bird = document.createElement('img');

    //Creating the Bird design and animation
    if(transaction.requestId.length == 26) {
      bird.src = '../../../assets/vips/xt.gif';
    } else {
      bird.src = '../../../assets/vips/birdy.gif';
    }
    bird.width = 70;
    bird.height = 70;
    bird.style.animation = this.anim + " linear 8s infinite";
    bird.style.animationDelay = Math.floor(Math.random() * 10) + 's';
    bird.style.position = "absolute";
    bird.style.zIndex = '5';
    bird.style.left = "-300px";
    bird.style.bottom = Math.floor(Math.random() * 600) + 'px';

    const div = this.elementRef.nativeElement.querySelector('#brd');
    bird.className = 'reward';
    bird.setAttribute('id', transaction.requestId);
    div.dataset.number = this.currentBird.toString();
    // this.currentBird++
    div.appendChild(bird);

    //start button
    start.addEventListener('click', function(){
      let bgsnd = document.getElementById("bg-music") as HTMLVideoElement | null;
      mainpage.style.opacity = '0';
      mainpage.style.transition = '.5s';
      mainpage.style.transformOrigin = '60%';
      mainpage.style.transform = 'scale(0)';
      mainpage.style.zIndex = '-5';
      //generateReward();
      bgsnd.play();


      // Music Mute/Unmute Button
      // let mute = this.elementRef.nativeElement.querySelector('#mute');
      // let icon = this.elementRef.nativeElement.querySelector('#iconmute');
      // let iconMute = "bi-volume-up-fill";
      // let iconUnmute = "bi-volume-mute-fill";

      // mute.addEventListener('click', function(e){
      //   bgsnd.muted = true;
      //   if ((event.target as HTMLElement).classList.toString().contains(iconMute)) {
      //     icon.classList.replace(iconMute, iconUnmute);
      //   } else {
      //     icon.classList.replace(iconUnmute, iconMute);
      //     bgsnd.muted = false;
      //   }
      // })

    });
    //kill the bird
    function deleteBird(elem){
      let success = document.getElementById("sound2") as HTMLVideoElement | null;

      elem.remove();
      if (Number(div.dataset.number) !== 20) {
        bird.style.bottom = Math.floor(Math.random() * 600) + 'px';
        if(elem.id.length < 26){
          div.append(bird);
        }
      }
      
      if(elem.id.length == 26){
        success.play();
        updateLoot(elem.id);
      }
    };

    div.addEventListener('click', function handleClick($event) {
      let gun = document.getElementById("sound1") as HTMLVideoElement | null;
      
      if ($event.srcElement.id == transaction.requestId) {
        gun.play();

        deleteBird($event.target);
        const currentNumber = parseInt(div.dataset.number);
        if (!isNaN(currentNumber)) {
          div.dataset.number = (currentNumber - 1).toString();
        }
        //console.log(transaction);
      }
    
    });

    async function updateLoot(requestId){
      var loot = await store.firestore.collection("VIPLOOT")
      .doc(requestId)
      .get(); 

      if (loot.exists) {
        var docData = loot.data();
        console.log(docData);

        if(!docData.userId){
          store
            .firestore
            .collection('VIPLOOT')
            .doc(requestId)
            .set({
              userId: userID
            }, {
              merge: true,
          });
         
          vipcoService.creditVIPLoot(requestId).subscribe((result) => {
            
          });
        } else {

        }
      } else {

      }
    }
  }
}






