<app-loader *ngIf="loader"></app-loader>
<div class="modal-body" *ngIf="activeTab != 4">
  <form id=" form" novalidate="">
    <div id="rootwizard">
      <ul class="nav mb-3">
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 1 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >1</span
            >
            <div class="mt-2">
              <div class="text-muted">Select Biller</div>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 2 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >2</span
            >
            <div class="mt-2">
              <div class="text-muted">New Transaction</div>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 3 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >3</span
            >
            <div class="mt-2">
              <div class="text-muted">Confirmation</div>
            </div>
          </a>
        </li>

        <li *ngIf="biller && activeTab != 1" class="nav-item" style="margin-left: auto">
          <a class="nav-link text-center" [ngClass]="activeTab == 3 ? 'active' : ''">
            <img [src]="biller?.logo" height="50" class="img-responsive" style="object-fit: contain" />
          </a>
        </li>
      </ul>

      <hr class="bg-dark" />

      <div class="tab-content p-3">
        <div class="tab-pane" [ngClass]="activeTab == 1 ? 'active' : ''" id="selectBillerTab">
          <div class="row">
            <div class="col-sm-12 col-md-2 d-flex justify-content-center align-items-center">
              <img
                [src]="application.value.thumbnail.logo"
                height="100"
                width="100"
                class="img-responsive"
                style="object-fit: contain"
              />
            </div>
            <div class="col-sm-12 col-md-5 d-flex flex-wrap">
              <h4>{{ application.value.thumbnail.title }}</h4>
              <p>
                {{ application.value.acquired.description }}
              </p>
            </div>
            <div class="col-sm-12 col-md-5" [formGroup]="billerFormGroup">
              <div class="form-group">
                <label>Select Category: </label>
                <select
                  (change)="getBillersByBillerType($event.target.value)"
                  formControlName="billerType"
                  class="form-control"
                  tabindex="-1"
                  aria-hidden="true"
                >
                  <option value="">Choose...</option>
                  <option *ngFor="let billerType of billerTypes | sortdata" [value]="billerType">
                    {{ billerType }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label>Select Biller:</label>
                <select
                  (change)="setBillerCode($event.target.value)"
                  formControlName="billerCode"
                  class="form-control"
                  tabindex="-1"
                  aria-hidden="true"
                >
                  <option value="">Choose...</option>
                  <option *ngFor="let biller of billersByBillerTypes | sort:'name'" [value]="biller.code">
                    {{ biller.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane" [ngClass]="activeTab == 2 ? 'active' : ''" id="newTransTab">
          <div class="row">
            <div class="col-sm-12 col-md-2"></div>
            <div class="col-sm-12 col-md-8">
              <span class="text-highlight font-weight-bold"> Payee Details : {{ biller?.name }} </span>
              <br />
              <span class="text-highlight font-weight-bold"> Biller Fee : {{ biller?.fee }} </span>

              <hr />

              <div *ngFor="let param of biller?.meta">
                <app-textbox-currency
                  *ngIf="param?.type == 'Number' && (param?.field == 'amount' || param?.field == 'water_due' || param?.field == 'association_fee' || param?.field == 'arrear')"
                  [parent-form-group]="paramsFormGroup()" 
                  tab-index-start="0"
                  [control-name]="param?.field"
                  [read-only]="false"
                  [error]="paramsFormGroup()?.controls[param?.field].errors | estatus"
                  [error-message]="paramsFormGroup().controls[param?.field].errors?.required"
                  number-only="false"
                  read-only="false"
                  [label]="param?.label"
                  placeholder="0.00"
                  max-length="10"
                >
                </app-textbox-currency>

                <app-textbox-number
                  *ngIf="param?.type == 'Number' && param?.field != 'amount' && param?.field != 'account_number' && param?.field != 'phone_number' && param?.field != 'water_due' && param?.field != 'association_fee' && param?.field != 'arrear'"
                  [parent-form-group]="paramsFormGroup()"
                  tab-index-start="0"
                  [control-name]="param?.field"
                  [read-only]="false"
                  [error]="paramsFormGroup()?.controls[param?.field].errors | estatus"
                  [error-message]="paramsFormGroup().controls[param?.field].errors?.required"
                  read-only="false"
                  [label]="param?.label"
                  [placeholder]="param?.label"
                  max-length="50"
                >
                </app-textbox-number>

                <app-textbox
                  *ngIf="param?.type == 'mobilenumber' || (param?.type == 'Number' && param?.field == 'account_number') || (param?.type == 'Number' && param?.field == 'phone_number')"
                  [parent-form-group]="paramsFormGroup()"
                  tab-index-start="0"
                  [control-name]="param?.field"
                  [read-only]="false"
                  [error]="paramsFormGroup()?.controls[param?.field].errors | estatus"
                  [error-message]="paramsFormGroup().controls[param?.field].errors?.required"
                  read-only="false"
                  alpha-numeric="false"
                  [label]="param?.label"
                  [placeholder]="param?.label"
                  max-length="50"
                >
                </app-textbox>

                <app-textbox
                  *ngIf="param?.type == 'Text'"
                  [parent-form-group]="paramsFormGroup()"
                  tab-index-start="0"
                  [control-name]="param?.field"
                  [read-only]="false"
                  [error]="paramsFormGroup()?.controls[param?.field].errors | estatus"
                  [error-message]="paramsFormGroup().controls[param?.field].errors?.required"
                  read-only="false"
                  [label]="param?.label"
                  [placeholder]="param?.label"
                  max-length="50"
                >
                </app-textbox>
                

                <app-datepicker
                  *ngIf="param?.type == 'Calendar'"
                  [parent-form-group]="paramsFormGroup()"
                  tab-index-start="0"
                  [control-name]="param?.field"
                  [read-only]="false"
                  [error]="paramsFormGroup()?.controls[param?.field].errors | estatus"
                  [error-message]="paramsFormGroup().controls[param?.field].errors?.required"
                  read-only="false"
                  [label]="param?.label"
                  [placeholder]="param?.label"
                >
                </app-datepicker>


              <app-yearpicker
              *ngIf="param?.type == 'Year'"
              place-holder="Choose.."
              [parent-form-group]="paramsFormGroup()"
              [control-name]="param?.field"
              [label]="param?.label"
              [error]="paramsFormGroup()?.controls[param?.field].errors | estatus"
              [error-message]="paramsFormGroup().controls[param?.field].errors?.required"
            >
            </app-yearpicker>

            <app-monthpicker
            *ngIf="param?.type == 'Month'"
            place-holder="Choose.."
            [parent-form-group]="paramsFormGroup()"
            [control-name]="param?.field"
            [label]="param?.label"
            [error]="paramsFormGroup()?.controls[param?.field].errors | estatus"
            [error-message]="paramsFormGroup().controls[param?.field].errors?.required"
          >
          </app-monthpicker>

                <app-dropdown-v2
                  *ngIf="param?.type == 'Dropdown'"
                  place-holder="Choose.."
                  [parent-form-group]="paramsFormGroup()"
                  [control-name]="param?.field"
                  [label]="param?.label"
                  [data]="param?.options"
                  [error]="paramsFormGroup()?.controls[param?.field].errors | estatus"
                  [error-message]="paramsFormGroup().controls[param?.field].errors?.required"
                >
                </app-dropdown-v2>
              </div>

              <div class="col-sm-12 col-md-2"></div>
            </div>
          </div>
        </div>

        <div class="tab-pane" [ngClass]="activeTab == 3 ? 'active' : ''" id="confirmationTab">
          <div class="form-group">
            <p>
              <strong>Payment Confirmation</strong><br />
              <small>Please make sure that the all details is correct.</small>
            </p>
            <div class="card">
              <div class="list list-row">
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Biler Category</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">{{ biller?.type }}</div>
                  </div>
                </div>
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Biler Name</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">{{ biller?.name }}</div>
                  </div>
                </div>
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Biler Fee</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">{{ biller?.fee }}</div>
                  </div>
                </div>
                <div *ngFor="let param of biller?.meta" class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">
                      {{ param?.label }}
                    </div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">{{ paramsFormGroup()?.controls[param?.field].value }}</div>
                  </div>
                </div>
                <hr />
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Total Amount</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ totalAmount(biller?.fee, paramsFormGroup()?.controls["amount"]?.value) }}
                    </div>
                  </div>
                </div>
                <hr />
                <div class="col-sm-12 col-md-12">
                  <div class="col-sm-12 col-md-6" style="float: none; margin: auto">
                    <div class="item-feed h-2x text-center">ENTER PINCODE</div>
                    <code-input
                      [isCodeHidden]="true"
                      [codeLength]="6"
                      (codeChanged)="onCodeChanged($event)"
                      (codeCompleted)="onCodeCompleted($event)"
                      [code]="code"
                    >
                    </code-input>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>

        <!-- previous next -->
        <div class="row py-3">
          <div class="col-6">
            <a class="btn btn-white button-previous" *ngIf="activeTab != 1" (click)="setActiveTabPrev()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-arrow-left"
              >
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
              &nbsp; Previous
            </a>
          </div>
          <div class="col-6">
            <div class="d-flex justify-content-end">
              <a
                *ngIf="activeTab == 1 && billerFormGroup.status != 'INVALID' && !isShortcutExisting"
                appDebounceClick 
                [debounceTime]="500" 
                (debounceClick)="addShortcut()"
                class="btn btn-danger button-next mr-3 text-white"
              >
                Add to Favorites &nbsp;
                <span class="fa fa-heart"></span>
              </a>

              <a
                *ngIf="activeTab == 1 && billerFormGroup.status != 'INVALID' && isShortcutExisting"
                appDebounceClick 
                [debounceTime]="500" 
                (debounceClick)="removeShortcut()"
                class="btn btn-outline-danger text-danger mr-3"
                id="remove"
              >
                Remove to Favorites &nbsp;
                <span class="fa fa-minus"></span>
              </a>

              <a *ngIf="activeTab != 3" class="btn btn-white button-next" (click)="setActiveTab()">
                Next &nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-arrow-right"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </a>
              <a
                *ngIf="activeTab == 3 && codeStatus"
                appDebounceClick 
                [debounceTime]="500" 
                (debounceClick)="processPayment()"
                class="btn btn-info button-next"
                style="color: white"
              >
                Process Payment &nbsp;
                <span class="fa fa-hand-pointer"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
