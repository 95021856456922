import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { environment } from "src/environments/environment";
import { FormGroup } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class ECPayService {
  private localStorageCacheService: LocalStorageCacheService;

  constructor(
    private httpClient: HttpClient,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
  }
  private httpClientNoInterceptor: HttpClient;

  private baseAPIUrl = environment.API_URL;

  public getBiller(code: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/Billers/${code}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getSSSValidateBiller(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/SSSValidateBiller`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getCashes(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/Cashes`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getTopUps(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/TopUps`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getBillers(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/Billers`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getTransactions(url: string): Observable<any> {
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${url}`, { headers: httpHeaders });
  }

  public getTransaction(requestId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/Transaction/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public rejectTransactionPay(requestId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/Billers/Pay/Failed/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.put(`${endPointUrl}`, "", { headers: httpHeaders });
  }

  public completeTransactionPay(requestId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/Billers/Pay/Complete/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.put(`${endPointUrl}`, "", { headers: httpHeaders });
  }

  public rejectTransaction(requestId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/Billers/Cash/Failed/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.put(`${endPointUrl}`, "", { headers: httpHeaders });
  }

  public completeTransaction(requestId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/Billers/Cash/Complete/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.put(`${endPointUrl}`, "", { headers: httpHeaders });
  }

  public reProcessPayment(biller: any, billerName: any, billerCategory: any, requestId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/Billers/Cash/ReProcess/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    const json = {
      biller: biller,
      billerName: billerName,
      billerCategory: billerCategory,
    };

    return this.httpClient.put(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public getTransactionCash(requestId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/TransactionCash/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getTransactionTopUp(requestId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/TransactionTopup/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public inquireBiller(biller: any, billerCode: any, fee: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/Billers/Inquiry`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

  
    biller.biller = billerCode;
    biller.channel = "1A85";
    biller.fee = fee;

    const json = { biller };

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public processPayment(
    biller: any,
    billerCode: any,
    fee: any,
    billerName: any,
    billerCategory: any,
    code: any
  ): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/Billers/Process`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

  
    biller.biller = billerCode;
    biller.fee = fee;

    const json = {
      biller,
      billerName: billerName,
      billerCategory: billerCategory,
      pinCode: code,
    };

    console.log("ecpay-json-sss-validate", json);

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public reProcessPaymentPay(biller: any, billerName: any, billerCategory: any, requestId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/Billers/Pay/ReProcess/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    const json = {
      biller: biller,
      billerName: billerName,
      billerCategory: billerCategory,
    };

    return this.httpClient.put(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public processSSSValidate(
    biller: any,
    billerCode: any,
    fee: any,
    billerName: any,
    billerCategory: any,
    code: any
  ): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/SSSValidateBiller/Process`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

   
    biller.biller = billerCode;
    biller.fee = fee;

    const json = {
      biller,
      billerName: billerName,
      billerCategory: billerCategory,
      pinCode: code,
    };

    console.log("ecpay-json", json);

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public processCash(
    biller: any,
    billerCode: any,
    fee: any,
    billerName: any,
    billerCategory: any,
    code: any,
    otp: any
   
  ): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/Cashes/Process`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

 
    biller.biller = billerCode;
    biller.fee = fee;
  //  biller.amount = amountToPaid;
    const json = {
      biller,
      billerName: billerName,
      billerCategory: billerCategory,
      pinCode: code,
      otp : otp
    };

    console.log("ecpay-json", json);
//return null;
   return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public sendOTP(
    biller: any,
    billerCode: any,
    fee: any,
    billerName: any,
    billerCategory: any,
    code: any,
    
  ): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/SendOTP/Cash`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

   
    biller.biller = billerCode;
    biller.fee = fee;
    
    const json = {
      biller,
      billerName: billerName,
      billerCategory: billerCategory,
      pinCode: code,
    };

    console.log("ecpay-json", json);

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public processTopUp(amount: any, telco: any, cellphoneNo: any, extTag: any, code: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/TopUps/Process`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

   

    const json = {
      biller: {
        amount: amount,
      },
      telco: telco,
      cellphoneNo: cellphoneNo,
      extTag: extTag,
      pinCode: code,
    };

    console.log("ectopup-json", json);

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public reProcessTopUp(biller: any, telco: any, cellphoneNo: any, extTag: any, requestId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/Billers/TopUp/ReProcess/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    const json = {
      biller: biller,
      telco: telco,
      cellphoneNo: cellphoneNo,
      extTag: extTag,
    };

    return this.httpClient.put(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public rejectTransactionTopUp(requestId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/Billers/TopUp/Failed/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.put(`${endPointUrl}`, "", { headers: httpHeaders });
  }

  public completeTransactionTopUp(requestId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/Billers/TopUp/Complete/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.put(`${endPointUrl}`, "", { headers: httpHeaders });
  }

  // tslint:disable-next-line: typedef
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  // tslint:disable-next-line: typedef
  private log(message: string) {
    //
  }

  public addFavoriteBills(billerCode : any, billerName: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/AddFavoriteBills`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = 
      { 
        billerCode: billerCode,
        billerName: billerName
      };

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public removeFavoriteBills(billerCode : any, billerName: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/RemoveFavoriteBills`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = 
      { 
        billerCode: billerCode,
        billerName: billerName
      };

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public addFavoriteCash(billerCode : any, billerName: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/AddFavoriteCash`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = 
      { 
        billerCode: billerCode,
        billerName: billerName
      };

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public removeFavoriteCash(billerCode : any, billerName: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/ECPay/RemoveFavoriteCash`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = 
      { 
        billerCode: billerCode,
        billerName: billerName
      };

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }
}
