import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { environment } from "src/environments/environment";
import { FormGroup } from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class PhpayService {

  private localStorageCacheService: LocalStorageCacheService;

  constructor(
    private httpClient: HttpClient,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
  }
  private httpClientNoInterceptor: HttpClient;

  private baseAPIUrl = environment.API_URL;

  public getTransaction(requestId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/PHPay/Transaction/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getTransactions(url: string): Observable<any> {
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${url}`, { headers: httpHeaders });
  }

  public validateQR(policyFormGroup: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/PHPay/Validate`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    var json = {
      referenceNo: policyFormGroup.referenceNo,
      amount: Number(policyFormGroup.amount)
    }
    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public processPayment(policyFormGroup: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/PHPay/Process`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(`${endPointUrl}`, policyFormGroup, { headers: httpHeaders });
  }
}
