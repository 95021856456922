import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-top-up-edit",
  templateUrl: "./top-up-edit.component.html",
  styleUrls: ["./top-up-edit.component.less"],
})
export class TopUpEditComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
