import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { StoreItemService } from 'src/data/services/web/store-item.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';

@Component({
  selector: 'app-purchase-order-view',
  templateUrl: './purchase-order-view.component.html',
  styleUrls: ['./purchase-order-view.component.less']
})
export class PurchaseOrderViewComponent implements OnInit {

  @Input() purchaseOrder: any;

  
  imageWidth = 64; // set the width of each image
  imageHeight = 68; // set the width of each image
  visibleImages = 3; // set the number of visible images
  currentIndex = 0; // set the initial index of the first visible image

  constructor(
    private modalServe: NgbModal,
    private webService: WebSettingsService,
    public datepipe: DatePipe,
    public storeItemService: StoreItemService,
    private currencyPipe: CurrencyPipe,
    private localStorageCacheService: LocalStorageCacheService
  ) { }

  ngOnInit(): void {
    this.getProofOfDelivery();
  }

  images:any = [];
  getProofOfDelivery(){
    this.storeItemService.getPoD(this.purchaseOrder.id).subscribe((result) => {
      this.images = result;
    });
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  displayImagePoD: any;
  displayImageModalRef: NgbModalRef;
  showImage(content, path){
    this.displayImagePoD = path;
    this.displayImageModalRef = this.modalServe.open(content, {
      centered: true,
      size: "md",
    });
  }

  closeDisplay(){
    this.displayImageModalRef.close();
  }
}
