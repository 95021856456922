<app-loader *ngIf="loader"></app-loader>

<div *ngIf="!loader" [class.isPrinting]="printService.isPrinting" class="col-lg-12 col-md-12 col-sm-12">
  <div class="col-lg-12 col-md-12 col-sm-12">
    <div class="card">
      <div class="list list-row">
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-md d-none d-md-block color-red">
              <span class="text-dark">Request ID: </span> <strong>{{ requestId }}</strong>
            </div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <a style="color: blue" (click)="printReceipt()">Print Receipt <span class="fa fa-print"></span></a> <br />
              <a style="color: blue" (click)="openWaybill(myQRTemplateModal)"
                >Waybill <span class="fa fa-print"></span
              ></a>
              <br />
              <!-- <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" [formControl]="isA4" />
                <label class="form-check-label text-muted" for="flexCheckDefault" style="font-size: 12px">
                  Print via A4
                </label>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group">
    <div class="card">
      <div class="list list-row row">
        <div class="col-sm-5 col-md-5">
          <div>
            <div class="card-header p-0" style="background-color: rgb(225, 225, 225)">
              <h4 class="mb-0">
                <button class="btn btn-block custom-left-align" type="button">Sender Details</button>
              </h4>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date font-weight-bold text-sm d-none d-md-block">Sender Name</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ data.sender.name.toUpperCase() }}
                </div>
              </div>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date font-weight-bold text-sm d-none d-md-block">Phone</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ data.sender.phone }}
                </div>
              </div>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date font-weight-bold text-sm d-none d-md-block">Province</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ data.sender.prov }}
                </div>
              </div>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date font-weight-bold text-sm d-none d-md-block">City</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ data.sender.city }}
                </div>
              </div>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date font-weight-bold text-sm d-none d-md-block">Barangay</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ data.sender.area }}
                </div>
              </div>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date font-weight-bold text-sm d-none d-md-block">Address</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x" style="white-space: normal; word-break: break-word; text-align: right">
                  {{ data.sender.address }}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div class="card-header p-0" style="background-color: rgb(225, 225, 225)">
              <h4 class="mb-0">
                <button class="btn btn-block custom-left-align" type="button">Receiver Details</button>
              </h4>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date font-weight-bold text-sm d-none d-md-block">Receiver Name</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ data.receiver.name.toUpperCase() }}
                </div>
              </div>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date font-weight-bold text-sm d-none d-md-block">Phone</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ data.receiver.phone }}
                </div>
              </div>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date font-weight-bold text-sm d-none d-md-block">Province</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ data.receiver.prov }}
                </div>
              </div>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date font-weight-bold text-sm d-none d-md-block">City</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ data.receiver.city }}
                </div>
              </div>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date font-weight-bold text-sm d-none d-md-block">Barangay</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x">
                  {{ data.receiver.area }}
                </div>
              </div>
            </div>

            <div class="list-item" data-id="19">
              <div class="flex">
                <div class="item-date font-weight-bold text-sm d-none d-md-block">Address</div>
              </div>
              <div class="no-wrap">
                <div class="item-feed h-2x" style="white-space: normal; word-break: break-word; text-align: right">
                  {{ data.receiver.address }}
                </div>
              </div>
            </div>
          </div>

          <div class="card-header p-0" style="background-color: rgb(225, 225, 225)">
            <h4 class="mb-0">
              <button class="btn btn-block custom-left-align" type="button">Order Details</button>
            </h4>
          </div>

          <div class="list-item" data-id="19">
            <div class="flex">
              <div class="item-date font-weight-bold text-sm d-none d-md-block">Total Item</div>
            </div>
            <div class="no-wrap">
              <div class="item-feed h-2x">
                {{ data.totalquantity }}
              </div>
            </div>
          </div>

          <div class="list-item" data-id="19">
            <div class="flex">
              <div class="item-date font-weight-bold text-sm d-none d-md-block">Total Weight (KG)</div>
            </div>
            <div class="no-wrap">
              <div class="item-feed h-2x">
                {{ data.weight }}
              </div>
            </div>
          </div>

          <div class="list-item" data-id="19">
            <div class="flex">
              <div class="item-date font-weight-bold text-sm d-none d-md-block">Order Type</div>
            </div>
            <div class="no-wrap">
              <div class="item-feed h-2x">
                {{ data.isCOD ? "COD" : "Non-COD" }}
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-7 col-md-7">
          <div class="card-header p-0" style="background-color: rgb(225, 225, 225)">
            <h4 class="mb-0">
              <button class="btn btn-block custom-left-align" type="button">Item Details</button>
            </h4>
          </div>

          <div class="list-item" data-id="19">
            <div class="flex">
              <div class="item-date font-weight-bold text-sm d-none d-md-block">Item Name</div>
            </div>
            <div class="no-wrap">
              <div class="item-feed h-2x">
                {{ data.item[0].itemName }}
              </div>
            </div>
          </div>

          <div class="list-item" data-id="19">
            <div class="flex">
              <div class="item-date font-weight-bold text-sm d-none d-md-block">Description</div>
            </div>
            <div class="no-wrap">
              <div class="item-feed h-2x">
                {{ data.item[0].desc }}
              </div>
            </div>
          </div>

          <div class="list-item" data-id="19">
            <div class="flex">
              <div class="item-date font-weight-bold text-sm d-none d-md-block">No of Item</div>
            </div>
            <div class="no-wrap">
              <div class="item-feed h-2x">
                {{ data.item[0].number }}
              </div>
            </div>
          </div>

          <div class="list-item" data-id="19">
            <div class="flex">
              <div class="item-date font-weight-bold text-sm d-none d-md-block">Item Value</div>
            </div>
            <div class="no-wrap">
              <div class="item-feed h-2x">
                {{ makeMoney(data.item[0]?.itemValue) }}
              </div>
            </div>
          </div>

          <div class="list-item" data-id="19">
            <div class="flex">
              <div class="item-date font-weight-bold text-sm d-none d-md-block">Remarks</div>
            </div>
            <div class="no-wrap">
              <div class="item-feed h-2x">
                {{ data.remark }}
              </div>
            </div>
          </div>

          <div class="card-header p-0" style="background-color: rgb(225, 225, 225)">
            <h4 class="mb-0">
              <button class="btn btn-block custom-left-align" type="button">Transaction Details</button>
            </h4>
          </div>

          <div class="list-item" data-id="19">
            <div class="flex">
              <div class="item-date font-weight-bold text-sm d-none d-md-block">Mail No</div>
            </div>
            <div class="no-wrap">
              <div class="item-feed h-2x">
                {{ data.mailno }}
              </div>
            </div>
          </div>

          <div class="list-item" data-id="19">
            <div class="flex">
              <div class="item-date font-weight-bold text-sm d-none d-md-block">TX Logistic ID</div>
            </div>
            <div class="no-wrap">
              <div class="item-feed h-2x">
                {{ data.txlogisticid }}
              </div>
            </div>
          </div>

          <div class="list-item" data-id="19">
            <div class="flex">
              <div class="item-date font-weight-bold text-sm d-none d-md-block">Commission</div>
            </div>
            <div class="no-wrap">
              <div class="item-feed h-2x">
                {{ makeMoney(data.commission) }}
              </div>
            </div>
          </div>

          <hr />

          <div class="list-item" data-id="19">
            <div class="flex">
              <div class="item-date font-weight-bold text-sm d-none d-md-block">Valuation Fee</div>
            </div>
            <div class="no-wrap">
              <div class="item-feed h-2x">
                {{ makeMoney(data?.valuationFee) }}
              </div>
            </div>
          </div>

          <div class="list-item" data-id="19">
            <div class="flex">
              <div class="item-date font-weight-bold text-sm d-none d-md-block">Service Fee</div>
            </div>
            <div class="no-wrap">
              <div class="item-feed h-2x">
                {{ makeMoney(data?.serviceFee) }}
              </div>
            </div>
          </div>

          <div class="list-item" data-id="19">
            <div class="flex">
              <div class="item-date font-weight-bold text-sm d-none d-md-block">Amount</div>
            </div>
            <div class="no-wrap">
              <div class="item-feed h-2x">
                {{ makeMoney(data.itemsvalue) }}
              </div>
            </div>
          </div>

          <hr />

          <div class="list-item" data-id="19">
            <div class="flex">
              <div class="item-date font-weight-bold text-sm d-none d-md-block">Total Amount</div>
            </div>
            <div class="no-wrap">
              <div class="item-feed h-2x">
                {{ makeMoney(getTotal()) }}
              </div>
            </div>
          </div>

          <div class="list-item" data-id="19">
            <div class="flex">
              <div class="item-date font-weight-bold text-sm d-none d-md-block">Status</div>
            </div>
            <div class="no-wrap">
              <div class="item-feed h-2x">
                <app-status-table-data [status]="data?.statusId"></app-status-table-data>
              </div>
            </div>
          </div>

          <div class="list-item" data-id="19">
            <div class="flex">
              <div class="item-date font-weight-bold text-sm d-none d-md-block">Created Date</div>
            </div>
            <div class="no-wrap">
              <div class="item-feed h-2x">{{ data?.createordertime | date: "MMM dd, yyyy hh:mm:ss a" }}</div>
            </div>
          </div>

          <div *ngIf="data?.statusId == 3" class="list-item" data-id="19">
            <div class="no-wrap">
              <div class="item-feed h-2x">
                <button class="btn btn-sm btn-danger w-100" (click)="cancelTransaction(confirmCancelModal)">
                  <i class="fa fa-times"></i> &nbsp; Cancel Transaction
                </button>
              </div>
            </div>
            <div class="no-wrap">
              <div class="item-feed h-2x">
                <button class="btn btn-sm btn-primary w-100" (click)="pickupItem(confirmPickUpModal)">
                  <i class="fa fa-truck"></i> &nbsp; Pick-up Item
                </button>
              </div>
            </div>
          </div>
          <div *ngIf="data?.statusId == 2" class="list-item" data-id="19">
            <div class="flex"></div>
            <div class="no-wrap">
              <div class="item-feed h-2x">
                <button class="btn btn-sm btn-primary w-100" (click)="trackOrder(trackOrderModal, data?.mailno)">
                  <i class="fa fa-map-marker"></i> &nbsp; Track Order
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div #vipLogo hidden>
  <img
    src="https://juanpaycdn.azureedge.net/juanpayassets/LOGO_logowithtagline-removebg-preview.png"
    alt="Go-VIP-Logo-Logo-Small"
    height="80px"
  />
</div>

<ng-template #viewImageModal let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Image Viewer</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <ngx-extended-pdf-viewer
      [base64Src]="imagePreview"
      backgroundColor="#ffffff"
      height="100%"
      [useBrowserLocale]="true"
      [handTool]="true"
      [showHandToolButton]="false"
      [showSidebarButton]="false"
      [showFindButton]="false"
      [showPagingButtons]="true"
      [showZoomButtons]="true"
      [showPresentationModeButton]="false"
      [showOpenFileButton]="false"
      [showPrintButton]="false"
      [showDownloadButton]="true"
      [filenameForDownload]="false"
      [showBookmarkButton]="false"
      [showSecondaryToolbarButton]="false"
      [showRotateButton]="false"
      [showHandToolButton]="false"
      [showScrollingButton]="false"
      [showSpreadButton]="false"
      [showPropertiesButton]="false"
    >
    </ngx-extended-pdf-viewer>
  </div>
</ng-template>

<ng-template #myQRTemplateModal let-modal>
  <!-- background: url(../../../assets/images/png\ QR.png) !important 
  linear-gradient(#044660, #02554c)-->

  <div class="card">
    <button class="btn btn-dark btn-sm" (click)="printWaybill(myQRTemplate)">
      Print &nbsp; <i class="fa fa-print"></i>
    </button>

    <div style="display: flex; justify-content: center; margin: 20px 0 0 0; padding: 0">
      <div #myQRTemplate style="color: black; margin: 0; padding: 0">
        <div
          style="
            background: url(../../../assets/images/Snip.png);
            background-repeat: no-repeat !important;
            background-size: cover;
            width: 4.94in !important;
            height: 9in !important;
            border-top: 1px solid black;
            border-right: 1px solid black;
          "
        >
          <div class="w-100 d-flex" style="height: 500px">
            <div style="width: 95%">
              <div class="billHeader d-flex">
                <div class="d-flex justify-content-center align-items-center">
                  <img src="../../../assets/images/flogo.png" alt="" width="75px" style="margin: 0 10px 0 10px" />
                </div>
                <div style="padding-left: 15px">
                  <ngx-barcode [bc-value]="data.mailno" [bc-display-value]="false" [bc-height]="38" [bc-width]="2.3">
                  </ngx-barcode>
                </div>
                <div>
                  <p
                    style="
                      font-weight: 900;
                      margin: 0 0 0 18px;
                      font-size: 30pt;
                      font-family: 'HarmonyOS Sans SC Black';
                    "
                  >
                    EZ
                  </p>
                </div>
              </div>

              <div class="orderNo d-flex">
                <div
                  style="display: flex; justify-content: center; height: 28px; font-family: 'HarmonyOS Sans SC Regular'"
                >
                  <p style="margin: 8px 0 0 15px; font-size: 6pt; width: 232px">Order No: {{ data.txlogisticid }}</p>
                  <p style="margin: 8px 0 0 15px; font-size: 6pt">{{ data.createordertime | date: "yyyy/MM/dd" }}</p>
                  <p style="margin: 8px 0 0 20px; font-size: 6pt">{{ data.createordertime | date: "hh:mm:ss a" }}</p>
                </div>
              </div>

              <div class="receiver d-flex">
                <div
                  style="
                    display: flex;
                    justify-content: start;
                    flex-direction: column;
                    height: 74px;
                    width: 100%;
                    font-family: 'HarmonyOS Sans SC Regular';
                  "
                >
                  <p style="margin: 4px 0 0 15px; font-size: 8pt">
                    RECEIVER: &nbsp;
                    <span style="font-weight: bold; font-size: 8pt">
                      {{ data.receiver.name.toUpperCase() }}
                    </span>
                  </p>
                  <p style="margin: 0 0 0 15px; font-size: 8pt; word-wrap: break-word">
                    {{ data.receiver.prov }}, {{ data.receiver.city }}, {{ data.receiver.area }}
                    {{ data.receiver.address }}
                  </p>
                  <p style="margin: 0 0 0 15px; font-size: 8pt">Contact No.: {{ data.receiver.phone }}</p>
                </div>
              </div>

              <div class="sender d-flex">
                <div
                  style="
                    display: flex;
                    justify-content: start;
                    flex-direction: column;
                    height: 74px;
                    width: 250px;
                    font-family: 'HarmonyOS Sans SC Regular';
                  "
                >
                  <p style="margin: 4px 0 0 15px; font-size: 6pt">
                    SENDER: &nbsp;
                    <span style="font-weight: bold; font-size: 6pt">
                      {{ data.sender.name.toUpperCase() }}
                    </span>
                  </p>
                  <p style="margin: 0 0 0 15px; font-size: 6pt; word-wrap: break-word">
                    {{ data.sender.prov }}, {{ data.sender.city }}, {{ data.sender.area }} {{ data.sender.address
                    }}<br />
                    Contact No.: {{ data.sender.phone }}
                  </p>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: start;
                    width: 195px;
                    font-family: 'HarmonyOS Sans SC Regular';
                  "
                >
                  <div style="display: flex">
                    <p style="margin: 4px 0 0 10px; font-size: 6pt">
                      COD: &nbsp; {{ data.isCOD ? makeMoney(data.itemsvalue) : "0" }}
                    </p>
                  </div>
                  <div style="display: flex">
                    <p style="margin: 7px 0 0 10px; font-size: 6pt">Goods: &nbsp; {{ data.item[0].itemName }}</p>
                  </div>
                  <div style="display: flex">
                    <p style="margin: 7px 0 0 10px; font-size: 6pt">Piece: &nbsp; {{ data.item[0].number }}</p>
                    <p style="margin: 7px 0 0 25px; font-size: 6pt">Weight: &nbsp; {{ data.weight }}</p>
                  </div>
                </div>
              </div>

              <div class="billHeader2 d-flex">
                <div class="d-flex justify-content-center align-items-center">
                  <img src="../../../assets/images/flogo.png" alt="" width="75px" style="margin: 0 10px 0 10px" />
                </div>
                <div style="padding: 2px 0 0 15px">
                  <ngx-barcode [bc-value]="data.mailno" [bc-display-value]="false" [bc-height]="38" [bc-width]="2.3">
                  </ngx-barcode>
                </div>
                <div>
                  <p
                    style="
                      font-weight: 900;
                      margin: 0 0 0 18px;
                      font-size: 30pt;
                      font-family: 'HarmonyOS Sans SC Black';
                    "
                  >
                    EZ
                  </p>
                </div>
              </div>

              <div class="orderNo d-flex">
                <div
                  style="display: flex; justify-content: center; height: 28px; font-family: 'HarmonyOS Sans SC Regular'"
                >
                  <p style="margin: 8px 0 0 15px; font-size: 6pt; width: 250px">Order No: {{ data.txlogisticid }}</p>
                  <p style="margin: 8px 0 0 15px; font-size: 6pt">{{ data.createordertime | date: "yyyy/MM/dd" }}</p>
                  <p style="margin: 8px 0 0 20px; font-size: 6pt">{{ data.createordertime | date: "hh:mm:ss a" }}</p>
                </div>
              </div>

              <div class="sortingCode d-flex">
                <div
                  class="w-100"
                  style="display: flex; justify-content: center; height: 48px; font-family: 'HarmonyOS Sans SC Black'"
                >
                  <p style="font-size: 23pt; font-weight: 900; margin: 0 0 0 0">
                    {{ data.sortingcode }} {{ data.sortingno ? data.sortingno : "000" }}
                  </p>
                </div>
              </div>

              <div class="sideBarcode d-flex">
                <div style="display: flex; justify-content: start">
                  <div style="display: flex; flex-direction: column; justify-content: start; width: 370px">
                    <div
                      style="
                        display: flex;
                        justify-content: start;
                        height: 48px;
                        font-family: 'HarmonyOS Sans SC Regular';
                      "
                    >
                      <p style="font-size: 16pt; margin: 8px 0 0 15px">{{ data.receiver.area }}</p>
                    </div>
                    <div
                      style="
                        display: flex;
                        justify-content: start;
                        flex-direction: column;
                        height: 100px;
                        font-family: 'HarmonyOS Sans SC Regular';
                      "
                    >
                      <p style="margin: 5px 0 0 15px; font-size: 8pt">
                        RECEIVER: &nbsp;
                        <span style="font-weight: bold; font-size: 8pt">{{ data.receiver.name.toUpperCase() }}</span>
                      </p>
                      <p style="margin: 0 0 0 15px; font-size: 8pt; word-wrap: break-word">
                        {{ data.receiver.prov }}, {{ data.receiver.city }}, {{ data.receiver.area }}
                        {{ data.receiver.address }}
                      </p>
                      <p style="margin: 0 0 0 15px; font-size: 8pt">Contact No.: {{ data.receiver.phone }}</p>
                    </div>
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        justify-content: start;
                        width: 250px;
                        height: 50px;
                        width: 370px;
                        font-family: 'HarmonyOS Sans SC Regular';
                      "
                    >
                      <p style="margin: 4px 0 0 15px; font-size: 6pt">
                        SENDER: &nbsp;
                        <span style="font-weight: bolder; font-size: 6pt">{{ data.sender.name.toUpperCase() }}</span>
                      </p>
                      <p style="margin: 0 0 0 15px; font-size: 6pt; word-wrap: break-word">
                        {{ data.sender.prov }}, {{ data.sender.city }}, {{ data.sender.area }} {{ data.sender.address
                        }}<br />
                        Contact No.: {{ data.sender.phone }}
                      </p>
                    </div>
                    <div
                      style="
                        display: flex;
                        flex-direction: column;
                        justify-content: start;
                        height: 73px;
                        font-family: 'HarmonyOS Sans SC Regular';
                      "
                    >
                      <div style="display: flex">
                        <p style="margin: 4px 0 0 15px; font-size: 6pt">
                          COD: &nbsp; {{ data.isCOD ? makeMoney(data.itemsvalue) : "0" }}
                        </p>
                      </div>
                      <div style="display: flex">
                        <p style="margin: 7px 0 0 15px; font-size: 6pt">Goods: &nbsp; {{ data.item[0].itemName }}</p>
                      </div>
                      <div style="display: flex">
                        <p style="margin: 7px 0 0 15px; font-size: 6pt">Piece: &nbsp; {{ data.item[0].number }}</p>
                        <p style="margin: 7px 0 0 25px; font-size: 6pt">Weight: &nbsp; {{ data.weight }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center align-items-center" style="width: 77px; height: 270px">
                    <div style="transform: rotate(-270deg); display: flex; justify-content: center; align-items: end">
                      <ngx-barcode
                        [bc-value]="data.mailno"
                        [bc-display-value]="false"
                        [bc-height]="40"
                        [bc-width]="2.3"
                      >
                      </ngx-barcode>
                    </div>
                  </div>
                </div>
              </div>

              <div class="qrCode d-flex">
                <div class="w-100" style="display: flex; justify-content: end; height: 198px; border: 1px solid black">
                  <div
                    class="w-50 d-flex flex-column justify-content-center align-items-center font-family: 'HarmonyOS Sans SC Regular';"
                  >
                    <p class="m-0" style="font-weight: 900; font-size: 12px" *ngIf="data.remark">
                      {{ data.remark }}
                    </p>
                    <p
                      class="m-0"
                      style="font-weight: 900; font-size: 70px; color: #a7a7a7; transform: rotate(-10deg)"
                      *ngIf="data.isCOD"
                    >
                      COD
                    </p>
                  </div>
                  <div class="w-50 d-flex justify-content-center align-items-center">
                    <ngx-qrcode
                      [elementType]="elementType"
                      [errorCorrectionLevel]="correctionLevel"
                      [value]="data.mailno"
                      [width]="194"
                    ></ngx-qrcode>
                  </div>
                </div>
              </div>

              <div class="mailno d-flex">
                <div
                  class="w-100 d-flex justify-content-around align-items-center"
                  style="font-family: 'HarmonyOS Sans SC Medium'"
                >
                  <p class="p-0" style="font-size: 8pt; margin: 4px 0 0 0">{{ data.mailno }}</p>
                  <p class="p-0" style="font-size: 8pt; margin: 4px 0 0 0">{{ data.mailno }}</p>
                </div>
              </div>
            </div>
            <div style="width: 6%; height: 839px; overflow: hidden">
              <div
                style="
                  transform: rotate(-270deg);
                  transform-origin: bottom left;
                  width: 839px;
                  position: relative;
                  bottom: 0;
                "
              >
                <div class="d-flex justify-content-around" style="width: 100%; font-family: 'HarmonyOS Sans SC Medium'">
                  <p class="mb-1 pb-0 flex-grow-1" style="margin-left: 90px; font-size: 8pt">{{ data.mailno }}</p>
                  <p class="mb-1 pb-0 flex-grow-1" style="font-size: 8pt">{{ data.mailno }}</p>
                  <p class="mb-1 pb-0 flex-grow-1" style="font-size: 8pt">{{ data.mailno }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #confirmCancelModal let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Are you sure you want to Cancel this transaction?</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <button class="btn btn-sm btn-success float-right" (click)="processCancel()">Proceed</button>
    <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #confirmPickUpModal let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Are you sure, this transaction is for Pick-Up?</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <button class="btn btn-sm btn-success float-right" (click)="processPickUp()">Proceed</button>
    <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #trackOrderModal let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Express Tracking</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="tracking-details">
      <ul class="list-group">
        <li class="list-group-item pt-0" *ngIf="trackingData.details.length == 0">
          <!-- <div class="tracking-item">
            <div class="time">{{ detail.scantime }}</div>
            <div class="type">{{ detail.scantype }}</div>
            <div class="description">{{ detail.desc }}</div>
          </div> -->
          <div class="notification box-2">
            <div class="notiglow"></div>
            <div class="notiborderglow"></div>
            <div class="notititle">Pending</div>
            <div class="notibody">Ready to be picked up</div>
            <!-- <div class="notibody">{{ detail.scantime }}</div> -->
          </div>
        </li>

        <li class="list-group-item pt-0" *ngFor="let detail of trackingData.details">
          <!-- <div class="tracking-item">
            <div class="time">{{ detail.scantime }}</div>
            <div class="type">{{ detail.scantype }}</div>
            <div class="description">{{ detail.desc }}</div>
          </div> -->
          <div class="notification box-2">
            <div class="notiglow"></div>
            <div class="notiborderglow"></div>
            <div class="notititle">{{ detail.scantype }}</div>
            <div class="notibody">{{ detail.desc }}</div>
            <div class="notibody">{{ detail.scantime }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
