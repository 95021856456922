<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex" >
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">Sales Migration List</h2>
          <small class="text-highlight font-weight-normal font-italic">List of sales agent to be migrated from VIP Exclusive Club.</small>
        </div>
        <div class="flex"></div>
       
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="col-lg-4" style="float:left">

        <div class="card mt-2">
          <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
            <thead>
              <tr>
                <th data-sortable="true" data-field="owner">Signed Up ({{migrationSalesList.length}} Accounts)</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let migration of migrationSalesList"
                style="cursor: pointer"
                class="odd"
                role="row"
                (click)="openSendMessage(migrationModal,migration.username)"
              >
               
                <td>
                  <strong>Username : {{ migration.username }}</strong><br/>
                  <small class="">Created Date : {{ migration.createdDate | date: "dd-MMM-yyyy hh:mm:ss a" }}</small><br/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<ng-template #migrationModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Migration Form</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
      <!-- <div>
          {{username}}
      </div> -->
       <app-migration-sales-form [username]="username" (closeModal)="closeModal()">

       </app-migration-sales-form>
  </div>
</ng-template>
