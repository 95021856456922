import { Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { TransactionService } from "../../../data/services/web/transaction.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ApplicationService } from "src/data/services/web/application.service";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { WalletService } from "src/data/services/web/wallet.service";
import { PrintService } from "src/data/services/web/print.service";
import { CurrencyPipe, DatePipe, DecimalPipe, PlatformLocation } from "@angular/common";
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { PosService } from "src/data/services/web/pos.service";
import { WebSettingsService } from "src/data/services/web/web-settings.service";
import { replace } from "lodash";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from "@techiediaries/ngx-qrcode";
import { environment } from "src/environments/environment";
import { DomSanitizer } from "@angular/platform-browser";
import { CashierUsersService } from "src/data/services/web/cashier-users.service";
import { ChatService } from "src/app/chat.service";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

declare let LiveAgent: any;
@Component({
  selector: "app-pos-transaction",
  templateUrl: "./pos-transaction.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./pos-transaction.component.less"],
})
export class PosTransactionComponent implements OnInit {
  public SelectBillerFormGroup: FormGroup;
  public posTransactionFormGroup: FormGroup;
  public oldPosTransactionFormGroup: FormGroup;
  public favoriteFormGroup: FormGroup;
  public NewTransactionFormGroup: FormGroup;
  applicationService: ApplicationService;
  @ViewChild("containerQRDiv") containerQRDiv: ElementRef;
  @ViewChild("createTransactionModal") createTransactionModal: ElementRef;
  @ViewChild("addSunLife") addSunLife: ElementRef;
  @ViewChild("vipLogo") vipLogo: ElementRef;
  @ViewChild('previewProductEventLeaderboard') previewProductEventLeaderboard: TemplateRef<any>;
  
  public activeBillsApplications: any = [];
  public activeAirtimeApplications: any = [];
  public activeRemitanceApplications: any = [];
  public activeOtherApplications: any = [];
  public activeGoVIPCenterServices: any = [];
  public activeInsurances: any = [];
  public shortcutAppList: any = [];

  public transactionService: TransactionService;

  public emptyString = "";
  localStorageCacheService: LocalStorageCacheService;
  walletService: WalletService;
  wallet: any;
  csrCode: any;
  currentDate: any;
  selectedApplication: any;
  transactionStatus = false;
  loader = false;
  pos: any;
  isOldTransaction = false;
  footPrint: any;
  userId: any;
  public username: any;
  public receipt: any;
  public isprinting = true;
  public isA4 = new FormControl();
  public photo: any;

  public announcementString: any;
  public announcement: any;
  public dti: any;
  public tin: any;
  public address: any

  trans;
  categories;
  billers;
  items;
  fees;
  bill;

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = "https://www.techiediaries.com/";

  constructor(
    service: TransactionService,
    private posService: PosService,
    private modalService: NgbModal,
    private platformLocation: PlatformLocation,
    private router: Router,
    public datepipe: DatePipe,
    private _decimalPipe: DecimalPipe,
    localStorageCacheService: LocalStorageCacheService,
    applicationService: ApplicationService,
    walletService: WalletService,
    public printService: PrintService,
    private webService: WebSettingsService,
    private sanitizer: DomSanitizer,
    private currencyPipe: CurrencyPipe,
    private cashierUsersService : CashierUsersService,
    private chatService: ChatService
  ) {
    this.applicationService = applicationService;
    this.items = service.getItem();
    this.categories = service.getCategories();
    this.billers = service.getBiller();
    this.trans = service.getTrans();
    this.fees = service.getRates();
    this.bill = service.getTransById(1);
    this.localStorageCacheService = localStorageCacheService;
    this.walletService = walletService;
    this.transactionService = service;
    PNotifyButtons;
  }
  elem: any;
  ngOnInit(): void {
    this.elem = document.documentElement;
    this.loader = true;
    this.username = this.localStorageCacheService.getStorage("username");
    this.photo = this.localStorageCacheService.getStorage("photo");
    this.footPrint = this.localStorageCacheService.getStorage("footprint");
    this.subscribeToEventsAll();
    this.getCSRCode();
    this.getWallet();
    this.getCurrentDate();
    this.getApplications();
    this.getActiveTransaction();
    this.initSelectBillerFormGroup();
    this.initNewTransactionFormGroup();
    this.initOldPosTransactionFormGroup();
    this.initFavoriteFormGroup();
    this.initReceipt();
    this.initAnnouncement(27);

    const idDefault = this.localStorageCacheService.getStorage("id");
    this.userId =  this.localStorageCacheService.getStorage("id");
    this.getCashierUserDetails(idDefault);
    setTimeout(() => {
      this.getShortcutApps();
      
  //this.openPreviewProductEventLeaderboard(this.previewProductEventLeaderboard);
    }, 2000);
  }

  public subscribeToEventsAll(): void {
    this.chatService.messageReceived.subscribe((data: any) => {
        PNotify.notice({
          title: "JuanPay Notification",
          text: data,
          hide : false
        });
     });
  }

  public cashierUser;
  public cashierName: any;
  getCashierUserDetails(id: any): void {
    this.cashierUsersService.getCashierUser(id).subscribe((result) => {
    var user = result.data;
    this.cashierName = result.data.cashierUser.firstName +  ' ' + result.data.cashierUser.lastName;
     this.cashierUser = result.data.cashierUser;
      const scriptUrl = "https://juanpay.ladesk.com/scripts/track.js";
      const node = document.createElement("script");
      node.src = scriptUrl;
      node.id = "la_x2s6df8d";
      node.type = "text/javascript";
      node.async = true;
      node.charset = "utf-8";
      node.onload = function (e) {
      LiveAgent.clearAllUserDetails();
      LiveAgent.setUserDetails(result.data.email, result.data.cashierUser.firstName, result.data.cashierUser.lastName);
      LiveAgent.addContactField('username', result.data.username);
      LiveAgent.addContactField('contactnumber', result.data.cashierUser.contactNumber);
      LiveAgent.addContactField('email', result.data.email);
      LiveAgent.addContactField('name', result.data.cashierUser.firstName +  ' ' + result.data.cashierUser.lastName);
      LiveAgent.addContactField('csrcode',result.data.cashierUser.csrCode);
      LiveAgent.createButton("01n1ydwf", document.getElementById("chatButton"));
    };
    document.getElementsByTagName("head")[0].appendChild(node);
    this.initReceiptDetails(result.data.cashierUser.managerUserId);
    });
  }

  initReceiptDetails(managerUserId: any){
    this.transactionService.getReceiptDetails(managerUserId).subscribe((result) => {
      this.dti = result.dti;
      this.address = result.address;
      this.tin = result.tin != null ? result.tin : "***-***-***-***";
    });
  }

  partnerswitch: any;
  initReceipt() {
    this.loader = true;
    this.webService.getWebSettingsByIdentifier("INVOICE_RECEIPT").subscribe((result) => {
      this.receipt = result.data;
      this.loader = false;
    });
    
    this.loader = true;
    this.webService.getWebSettingsByIdentifier("PARTNER_SWITCH").subscribe((result) => {
      this.partnerswitch = result.data;
      this.loader = false;
    });


  }

  initAnnouncement(id: any): void {
    this.webService.getWebSettingsByIdentifier("ANNOUNCEMENT").subscribe((result) => {
      const val = result.data;
      this.announcementString = this.sanitizer.bypassSecurityTrustHtml(val[0].value);
    });
  }

  displayAnnouncement(content: any){
    this.modalService.open(content, { centered: true, size: "lg" })
  }

  //#region API Integrated
  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
    `]`;
  }

  getActiveTransaction() {
    this.isOldTransaction = false;
    this.loader = true;
    this.posService.getActiveTransaction().subscribe((pos) => {
      this.pos = pos;
   
      this.loader = false;
      this.value = `${environment.WEB_URL}${this.pos.id}`;
    });

    if (this.oldPosTransactionFormGroup) this.oldPosTransactionFormGroup.controls.oldTransactionId.setValue("");
  }

  openProcessOrder(processOrder) {
    if (this.pos.posTransactionItems.length != 0) {
      this.initPosTransactionFormGroup();
      this.modalService.open(processOrder, { centered: true, size: "lg", backdrop: "static", keyboard: false });
    } else {
      PNotify.error({
        title: "Error",
        text: "Add item first.",
      });
    }
  }

  viewPreviousTransaction() {
    this.loader = true;
    this.posService.gePreviousTransaction(this.pos.id).subscribe(
      (pos) => {
        this.pos = pos;
        this.value = `${environment.WEB_URL}${this.pos.id}`;
        this.isOldTransaction = true;
        this.loader = false;
        this.modalService.dismissAll();
      },
      (error) => {
        
        if (error.status === 404) {
          PNotify.notice({
            title: "Warning",
            text: "You've already reach the last record.",
          });
          this.modalService.dismissAll();
          this.loader = false;
        } else {
          // Other error
          this.loader = false;

          PNotify.error({
            title: "Error",
            text: "Something went wrong.",
          });
        }
      }
    );
  }

  viewNextTransaction() {
    this.loader = true;
    this.posService.geNextTransaction(this.pos.id).subscribe(
      (pos) => {
        this.pos = pos;
        this.value = `${environment.WEB_URL}${this.pos.id}`;
        this.isOldTransaction = this.pos.statusId == 3 ? false : true;
        this.loader = false;
        this.modalService.dismissAll();
      },
      (error) => {
        
        if (error.status === 404) {
          PNotify.notice({
            title: "Warning",
            text: "You've already reach the active record.",
          });
          this.modalService.dismissAll();
          this.loader = false;
        } else {
          // Other error
          this.loader = false;

          PNotify.error({
            title: "Error",
            text: "Something went wrong.",
          });
        }
      }
    );
  }

  completeTransaction() {
    var cashTendered = Number(this.replaceComma(this.posTransactionFormGroup.controls.cashTendered.value));
    
    this.posService.completeTransaction(cashTendered).subscribe(
      (pos) => {
        
        this.printPOSReceipt(pos.completedDate);
        this.pos = pos;

      
        this.loader = false;

        PNotify.success({
          title: "Success",
          text: "Transaction completed.",
        });
        PNotify.info({
          title: "Information",
          text: "New transaction initiated.",
        });
        this.modalService.dismissAll();
      },
      (error) => {
       
        if (error.status === 404) {
          PNotify.error({
            title: "Error",
            text: "Add item first.",
          });
          this.modalService.dismissAll();
          this.loader = false;
        } else if (error.status === 400) {
          const obj = error.error.errors;
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              let str: string = key;
              str = str[0].toLowerCase() + str.slice(1);
              this.posTransactionFormGroup.controls[str].setErrors({
                required: Validators.required,
                apiMessage: obj[key],
              });
            }
          }
          this.loader = false;
        } else {
          // Other error
          this.loader = false;

          PNotify.error({
            title: "Error",
            text: "Something went wrong.",
          });
        }
      }
    );
  }

  initPosTransactionFormGroup(): void {
    this.posTransactionFormGroup = new FormGroup({
      subTotalAmount: new FormControl(this._decimalPipe.transform(this.pos.notMappedSubTotalAmount, "1.2-2")),
      taxAmount: new FormControl(this._decimalPipe.transform(this.pos.notMappedTaxAmount, "1.2-2")),
      totalAmount: new FormControl(this._decimalPipe.transform(this.pos.notMappedTotalAmount, "1.2-2")),
      cashTendered: new FormControl(this.emptyString),
      cashChange: new FormControl(this.emptyString),
    });

    this.posTransactionFormGroup.controls.cashTendered.valueChanges.subscribe((cashTendered) => {
       
      if (!isNaN(+cashTendered)) {
        const cashChange: any = Number(cashTendered) - Number(this.replaceComma(this.posTransactionFormGroup.controls.totalAmount.value));
        
        this.posTransactionFormGroup.controls.cashChange.setValue(this._decimalPipe.transform(cashChange, "1.2-2"));
      }
    });
  }

  replaceComma(num) {
    return num.replace(/,/g, '');
  };

  openPreviewOldTransactionModal(previewOldTransactionModal) {
    if (this.oldPosTransactionFormGroup.controls.oldTransactionId.value.length > 10) {
      PNotify.error({
        title: "Error",
        text: "Transaction number is not valid.",
      });
    } else if (this.oldPosTransactionFormGroup.controls.oldTransactionId.value.length < 10) {
      this.oldPosTransactionFormGroup.controls.oldTransactionId.value.padStart(10, "0");
      this.initPosTransactionFormGroup();
      this.modalService.open(previewOldTransactionModal, {
        centered: true,
        size: "sm",
        backdrop: "static",
        keyboard: false,
      });
    } else if (this.oldPosTransactionFormGroup.controls.oldTransactionId.value) {
      this.initPosTransactionFormGroup();
      this.modalService.open(previewOldTransactionModal, {
        centered: true,
        size: "sm",
        backdrop: "static",
        keyboard: false,
      });
    } else {
      PNotify.error({
        title: "Error",
        text: "Transaction number is required.",
      });
    }
  }

  getOldTransaction() {
    this.loader = true;

    this.posService.getOldTransaction(this.oldPosTransactionFormGroup.controls.oldTransactionId.value).subscribe(
      (pos) => {
        this.pos = pos;
        this.value = `${environment.WEB_URL}${this.pos.id}`;
        
        this.isOldTransaction = true;
        this.loader = false;
        this.modalService.dismissAll();
      },
      (error) => {
       
        if (error.status === 404) {
          PNotify.error({
            title: "Error",
            text: "Transaction number not existing.",
          });
          this.modalService.dismissAll();
          this.loader = false;
        } else {
          // Other error
          this.loader = false;

          PNotify.error({
            title: "Error",
            text: "Something went wrong.",
          });
        }
      }
    );
  }

  initOldPosTransactionFormGroup(): void {
    this.oldPosTransactionFormGroup = new FormGroup({
      oldTransactionId: new FormControl(""),
    });
  }

  shortcutAccumulator: any = [];
  initFavoriteFormGroup(): void {
    this.favoriteFormGroup = new FormGroup({
      search: new FormControl(""),
    });

    this.favoriteFormGroup.controls.search.valueChanges.subscribe((value) => {
      if(value){
        this.shortcutAppList = this.shortcutAccumulator.filter(x => x.name.toUpperCase().includes(value.toUpperCase()));
      } else {
        this.shortcutAppList = this.shortcutAccumulator;
      }
    });
  }

  //#endregion

  initNewTransactionFormGroup(): void {
    this.NewTransactionFormGroup = new FormGroup({
      firstname: new FormControl(this.emptyString),
      middleinitial: new FormControl(this.emptyString),
      lastname: new FormControl(this.emptyString),
      accountnumber: new FormControl(this.emptyString),
      amountpaid: new FormControl(this.emptyString),
      contactnumber: new FormControl(this.emptyString),
      vwalletamount: new FormControl(this.emptyString),
      amounttopaid: new FormControl(this.emptyString),
      rateID: new FormControl(this.emptyString),
    });

    this.NewTransactionFormGroup.valueChanges.subscribe((value) => {});
  }

  initSelectBillerFormGroup(): void {
    this.SelectBillerFormGroup = new FormGroup({
      categoryId: new FormControl(this.emptyString),
      billerId: new FormControl(this.emptyString),
    });

    this.SelectBillerFormGroup.valueChanges.subscribe((value) => {});
  }

  private modalCreateTransaction: any;

  openCreateTransaction(createTransactionModal, application) {
    
    if (this.isOldTransaction) {
      PNotify.error({
        title: "Error",
        text: "You are viewing an old transaction.",
      });
    } else {
      if(application == 'Meralco'){

        var Meralco =  {
            "parameter": "Meralco",
            "value": {
                "thumbnail": {
                    "title": "Meralco",
                    "logo": "https://i.ibb.co/hccjJt7/244165832-227976089383846-571834486858879262-n.png",
                    "price": 0,
                    "details": {}
                },
                "acquired": {
                    "title": "Meralco",
                    "logo": "https://i.ibb.co/hccjJt7/244165832-227976089383846-571834486858879262-n.png",
                    "description": "",
                    "purchase_progress": [
                    ]
                },
                "active": true,
                "network": "BILLS"
            }
        }

        
        this.selectedApplication = Meralco;
      } else if(application == 'BAYAD_CENTER'){
          var Bayad =  {
            "parameter": "BAYAD_CENTER",
            "value": {
                "thumbnail": {
                    "title": "Bayad",
                    "logo": "https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file72ada932-2876-4f0a-b2b7-3a919cabefbf.png",
                    "price": 0,
                    "details": {}
                },
                "acquired": {
                    "title": "Bayad",
                    "logo": "https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file72ada932-2876-4f0a-b2b7-3a919cabefbf.png",
                    "description": "Bayad Center is a leader in the Philippines' Outsourced Payment Collection Industry. It continues to innovate by providing a reliable bills collection solution for its corporate partners, and a convenient bills payment option for every Filipino.",
                    "purchase_progress": [
                    ]
                },
                "active": true,
                "network": "BILLS"
            }
        }

        this.selectedApplication = Bayad;
      } else if(application == 'GOVIPX_TOPUP') {
          var GOVIPX_TOPUP =  {
            "parameter": "GOVIPX_TOPUP",
            "value": {
                "thumbnail": {
                    "title": "GoVIPX Topup",
                    "logo": "https://govipcenterstorage.blob.core.windows.net/govipcenterassets/filecc1b1c35-c2ca-40eb-bd54-b7ac951c98f5.png",
                    "price": 0,
                    "details": {}
                },
                "acquired": {
                    "title": "GoVIPX Topup",
                    "logo": "https://govipcenterstorage.blob.core.windows.net/govipcenterassets/filecc1b1c35-c2ca-40eb-bd54-b7ac951c98f5.png",
                    "description": "",
                    "purchase_progress": [
                    ]
                },
                "active": true,
                "network": "GS"
            }
        }

        this.selectedApplication = GOVIPX_TOPUP;
      } else if(application == 'GOVIPX_CASHOUT') {
        var GOVIPX_CASHOUT =  {
          "parameter": "GOVIPX_CASHOUT",
          "value": {
              "thumbnail": {
                  "title": "GoVIPX Cashout",
                  "logo": "https://govipcenterstorage.blob.core.windows.net/govipcenterassets/fileadfeea13-f2a1-4eeb-8f65-6e8b62f1b479.png",
                  "price": 0,
                  "details": {}
              },
              "acquired": {
                  "title": "GoVIPX Cashout",
                  "logo": "https://govipcenterstorage.blob.core.windows.net/govipcenterassets/fileadfeea13-f2a1-4eeb-8f65-6e8b62f1b479.png",
                  "description": "",
                  "purchase_progress": [
                  ]
              },
              "active": true,
              "network": "GS"
          }
      }

      this.selectedApplication = GOVIPX_CASHOUT;
    }  else if(application == 'PAYMENT_COLLECTION') {
      var PAYMENT_COLLECTION =  {
        "parameter": "PAYMENT_COLLECTION",
        "value": {
            "thumbnail": {
                "title": "Payment Collection",
                "logo": "https://govipcenterstorage.blob.core.windows.net/govipcenterassets/filea11e61ea-84bd-4b9a-9a47-5e0f962f2e59.png",
                "price": 0,
                "details": {}
            },
            "acquired": {
                "title": "Payment Collection",
                "logo": "https://govipcenterstorage.blob.core.windows.net/govipcenterassets/filea11e61ea-84bd-4b9a-9a47-5e0f962f2e59.png",
                "description": "",
                "purchase_progress": [
                ]
            },
            "active": true,
            "network": "GS"
        }
    }

    this.selectedApplication = PAYMENT_COLLECTION;
  } else if(application == 'PAYGRAM') {
      var PAYGRAM =  {
        "parameter": "PAYGRAM",
        "value": {
            "thumbnail": {
                "title": "PAYGRAM",
                "logo": "https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file59e06210-2d00-4191-83c5-b95b9f41cd2a.png",
                "price": 0,
                "details": {}
            },
            "acquired": {
                "title": "PAYGRAM",
                "logo": "https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file59e06210-2d00-4191-83c5-b95b9f41cd2a.png",
                "description": "Cash In and Cash Out at GoVIPCenter Outlets: Paygram integrates with GoVIPCenter outlets, allowing users to easily convert their cryptocurrency to cash and vice versa. This feature brings added convinience, especially for those who prefer physical transactions or need quick access to cash.",
                "purchase_progress": [
                ]
            },
            "active": true,
            "network": "GS"
        }
    }

    this.selectedApplication = PAYGRAM;
  } else if(application == 'SPX Rider Remit') {
      var SPXRiderRemit =  {
        "parameter": "SPX Rider Remit",
        "value": {
          "thumbnail": {
            "title": "SPX Rider Remit",
            "logo": "https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file09355088-c489-48e6-b6ce-ab08b4788de4.png",
            "price": 0,
            "details": {}
          },
          "acquired": {
            "title": "SPX Rider Remit",
            "logo": "https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file09355088-c489-48e6-b6ce-ab08b4788de4.png",
            "description": "Rider Remittance Service enables SPX Riders to remit cash-on-delivery (COD) amounts received within the day to a partner outlet.",
            "purchase_progress": []
          },
          "active": true,
          "network": "REMITTANCE"
        }
    }

    this.selectedApplication = SPXRiderRemit;
  }  else if(application.parameter == 'Converge Flexibiz') 
    { 
      window.open('https://legacy.gofiber.ph/applicant#/application-tpa','_blank');
      // this.router.navigateByUrl('https://legacy.gofiber.ph/applicant#/application-tpa');
    }
    else {
        this.selectedApplication = application;
        this.selectedApplication.value.acquired.logo = this.getImageFromMasterList(application.parameter);
        this.selectedApplication.value.thumbnail.logo = this.getImageFromMasterList(application.parameter);
      }
    
      this.modalCreateTransaction = this.modalService.open(createTransactionModal, {
        centered: true,
        size: "xl",
        backdrop: "static",
        keyboard: false,
      });
    }
  }

  selectedShortCut: any;
  openCreateTransactionShortcut(createTransactionModal, application) {
    
    if (this.isOldTransaction) {
      PNotify.error({
        title: "Error",
        text: "You are viewing an old transaction.",
      });
    } else {
      
      if(application.providerName == "Multisys"){
        var appMultisys = {
          "parameter": "MULTISYS_SC",
          "value": ""
        }
        appMultisys.value = this.getApplicationshortcut("MULTISYS");
        this.selectedShortCut = application;
        this.selectedApplication = appMultisys;

      } else if(application.providerName == "ECPAY_BILLS"){
        var appECpay = {
          "parameter": "ECPay Bills SC",
          "value": ""
        }
        appECpay.value = this.getApplicationshortcut("ECPay Bills");
        this.selectedShortCut = application;
        this.selectedApplication = appECpay;
      } else if(application.providerName == "CTI"){
        var appCTI = {
          "parameter": "Smart Eload SC",
          "value": ""
        }
        appCTI.value = this.getApplicationshortcut("Smart Eload");
        this.selectedShortCut = application;
        this.selectedApplication = appCTI;
      } else if(application.providerName == "EC_CASH"){
        var appECCASH = {
          "parameter": "EC_CASH_SC",
          "value": ""
        }
        appECCASH.value = this.getApplicationshortcut("ECPay Remittance");
        this.selectedShortCut = application;
        this.selectedApplication = appECCASH;
      } else if(application.providerName == "EC_TOPUP"){
        var appECTOPUP = {
          "parameter": "EC_TOPUP_SC",
          "value": ""
        }
        appECTOPUP.value = this.getApplicationshortcut("ECPay Airtime");
        this.selectedShortCut = application;
        this.selectedApplication = appECTOPUP;
      }



      // if(application == 'Meralco'){

      // var Meralco =  {
      //     "parameter": "Meralco",
      //     "value": {
      //         "thumbnail": {
      //             "title": "Meralco",
      //             "logo": "https://i.ibb.co/hccjJt7/244165832-227976089383846-571834486858879262-n.png",
      //             "price": 0,
      //             "details": {}
      //         },
      //         "acquired": {
      //             "title": "Meralco",
      //             "logo": "https://i.ibb.co/hccjJt7/244165832-227976089383846-571834486858879262-n.png",
      //             "description": "",
      //             "purchase_progress": [
      //             ]
      //         },
      //         "active": true,
      //         "network": "BILLS"
      //     }
      // }

      
      //   this.selectedApplication = Meralco;
      // }else{
      //   this.selectedApplication = application;
      //   this.selectedApplication.value.acquired.logo = this.getImageFromMasterList(application.parameter);
      //   this.selectedApplication.value.thumbnail.logo = this.getImageFromMasterList(application.parameter);
      // }
    
      this.modalCreateTransaction = this.modalService.open(createTransactionModal, {
        centered: true,
        size: "xl",
        backdrop: "static",
        keyboard: false,
      });
    }
  }

  closeTransaction() {
    if (confirm("Are you sure you want to cancel your transaction?")) {
      PNotify.error({
        title: "Information",
        text: "Transaction Cancelled.",
      });
      this.modalCreateTransaction.close();
    }
  }

  closeOnly() {
    this.modalCreateTransaction.close();
  }

  closeModal() {
    this.modalCreateTransaction.close();
    this.getWallet();
    this.getActiveTransaction();
    if(this.isSunlifeActive && this.selectedApplication.parameter != 'Sunlife Grepa'){
      this.openSunlife();
    }
    
  }
  modalSunlife : any;
  openSunlife() {
    if (this.isOldTransaction) {
      PNotify.error({
        title: "Error",
        text: "You are viewing an old transaction.",
      });
    } else {
      this.modalSunlife = this.modalService.open(this.addSunLife, {
        centered: true,
        size: "sm",
        backdrop: "static",
        keyboard: false,
      });
    }
  }
  

  openModalSunlife() {
    this.closeModalSunlife();
    this.AddSunlifeGrepa();
    
  }

  closeModalSunlife() {
    this.modalSunlife.close();
  }

  AddSunlifeGrepa(){
  

      
      var application = this.activeInsurances.filter(x => x.parameter == "Sunlife Grepa")[0];

      this.openCreateTransaction(this.createTransactionModal, application);
  }

  emitRetransact() {
    this.getWallet();
    this.getActiveTransaction();
  }

  onPrintInvoice() {
    const invoiceIds = ["101", "102"];
    this.printService.printDocument("invoice", invoiceIds);
  }

  openTransDetail(viewDetailsModal) {
    this.modalService.open(viewDetailsModal, { centered: true, size: "sm" });
  }

  openReceipt(receiptModal) {
    this.modalService.open(receiptModal, { centered: true, size: "sm" });
  }

  backToDashboard(): void {
    
    window.location.href = `cashier/dashboard`;
   
  }

  applicationList: any;

  getApplications() {
    this.webService.getWebSettingsByIdentifier("VIP_MARKETPLACE").subscribe((result) => {
      
      this.applicationList = result.data.map((application) => {
        return {
          active: application.value.active,
          parameter: application.parameter,
          network: application.value.network,
          logo : application.value.acquired.logo,
          value : application.value
        };
      });
      
      this.getActiveApplication(this.applicationList);
      
    });
  }

  getImageFromMasterList(parameter){
   return this.applicationList.filter(x=>x.parameter == parameter)[0].logo;
  }

  getApplicationshortcut(parameter){
    const val = this.applicationList.filter(x=>x.parameter == parameter)[0].value
    return val;
  }

  isSunlifeActive : any = false;

  getShortcutApps(){
    this.applicationService.getFavorites(this.userId).subscribe((result) => {
      
      // if(!this.isMultisys){
        
      //   for (const { i, val } of result.data.map((val, i) => ({ i, val }))) {
      //     if(val.value.provider == "Multisys"){
      //       result.data.splice(i, 1);
      //     }
      //   }
      // }

      // if(!this.isECPay){
        
      //   for (const { i, val } of result.data.map((val, i) => ({ i, val }))) {
      //     if(val.value.provider == "ECPAY_BILLS"){
      //       result.data.splice(i, 1);
      //     }
      //   }
      // }

      // if(!this.isECCash){
        
      //   for (const { i, val } of result.data.map((val, i) => ({ i, val }))) {
      //     if(val.value.provider == "EC_CASH"){
      //       result.data.splice(i, 1);
      //     }
      //   }
      // }

      // if(!this.isECTopup){
        
      //   for (const { i, val } of result.data.map((val, i) => ({ i, val }))) {
      //     if(val.value.provider == "EC_TOPUP"){
      //       result.data.splice(i, 1);
      //     }
      //   }
      // }

      // if(!this.isCTI){
        
      //   for (const { i, val } of result.data.map((val, i) => ({ i, val }))) {
      //     if(val.value.provider == "CTI"){
      //       result.data.splice(i, 1);
      //     }
      //   }
      // }
      //}

      this.shortcutAccumulator = result.data.map((application) => {
        return {
          name: application.parameter,
          providerName: application.value.provider,
          logo: application.value.logo,
          category: application.value.category ? application.value.category : true,
          product_type: application.value.product_type ? application.value.product_type : true,
          itemCode: application.value.billerCode,
        };
      });

      this.shortcutAppList = result.data.map((application) => {
        return {
          name: application.parameter,
          providerName: application.value.provider,
          logo: application.value.logo,
          category: application.value.category ? application.value.category : true,
          product_type: application.value.product_type ? application.value.product_type : true,
          itemCode: application.value.billerCode,
        };
      });
    });
  }

  isMultisys: boolean = false;
  isECPay: boolean = false;
  isECCash: boolean = false;
  isECTopup: boolean = false;
  isCTI: boolean = false;

  getActiveApplication(applicationList) {
    
    this.loader = true;
    this.applicationService.getApplications().subscribe((result) => {


      result.forEach((application) => {
        const applicationRef = JSON.parse(application.meta);
       
        debugger
        if (
          applicationList.filter((x) => x.parameter == applicationRef.parameter)[0].active &&
          applicationList.filter((x) => x.parameter == applicationRef.parameter)[0].network == "BILLS"
        ) {
          if (this.checkProgressStatus(JSON.parse(application.meta))) {
            this.activeBillsApplications.push(JSON.parse(application.meta));
            
            if(applicationRef.parameter == "MULTISYS"){
              this.isMultisys = true;
            }
            if(applicationRef.parameter == "ECPay Bills"){
              this.isECPay = true;
            }
          }
        }
        if (
          applicationList.filter((x) => x.parameter == applicationRef.parameter)[0].active &&
          applicationList.filter((x) => x.parameter == applicationRef.parameter)[0].network == "AIRTIME"
        ) {
          if (this.checkProgressStatus(JSON.parse(application.meta))) {
            this.activeAirtimeApplications.push(JSON.parse(application.meta));
            if(applicationRef.parameter == "ECPay Airtime"){
              this.isECTopup = true;
            }
            if(applicationRef.parameter == "Smart Eload"){
              this.isCTI = true;
            }
          }
        }
        if (
          applicationList.filter((x) => x.parameter == applicationRef.parameter)[0].active &&
          applicationList.filter((x) => x.parameter == applicationRef.parameter)[0].network == "REMITTANCE"
        ) {
          if (this.checkProgressStatus(JSON.parse(application.meta))) {
            this.activeRemitanceApplications.push(JSON.parse(application.meta));
            if(applicationRef.parameter == "ECPay Remittance"){
              this.isECCash = true;
            }
          }
        }
        if (
          applicationList.filter((x) => x.parameter == applicationRef.parameter)[0].active &&
          applicationList.filter((x) => x.parameter == applicationRef.parameter)[0].network == "OTHER"
        ) {
          if (this.checkProgressStatus(JSON.parse(application.meta))) {
            this.activeOtherApplications.push(JSON.parse(application.meta));
          }
        }
        if (
          applicationList.filter((x) => x.parameter == applicationRef.parameter)[0].active &&
          applicationList.filter((x) => x.parameter == applicationRef.parameter)[0].network == "JUANPAY SERVICES"
        ) {
          if (this.checkProgressStatus(JSON.parse(application.meta))) {
            this.activeGoVIPCenterServices.push(JSON.parse(application.meta));
          }
        }
        if (
          applicationList.filter((x) => x.parameter == applicationRef.parameter)[0].active &&
          applicationList.filter((x) => x.parameter == applicationRef.parameter)[0].network == "INSURANCE"
        ) {
          if (this.checkProgressStatus(JSON.parse(application.meta))) {
            if( applicationRef.parameter == "Sunlife Grepa"){
              this.isSunlifeActive =  true;
            }
            this.activeInsurances.push(JSON.parse(application.meta));
          }
        }
      });

      this.loader = false;
      this.modalService.dismissAll();
     
    });
  }

  checkProgressStatus(application: any) {
    let status = true;
    application.value.acquired.purchase_progress.forEach((item) => {
      if (item.status == false) {
        status = false;
      }
    });
    return status;
  }

  messages : [];
  getWallet(): void {
    this.walletService.getWallet().subscribe((result) => {
      this.wallet = result.wallet;
      this.messages = result.messages
      if(result.footPrint != this.footPrint)
      {
        this.forceLogOut();
      }else
      {
        this.startTimer();
      }
    });
  }

  forceLogOut(): void {
    //  this.authService.logOut().then((result) => {
    // if (result) {
    //const loginRoute = this.authService.getRoleLevelName(this.localStorageCacheService.getStorage("role_level"));

    PNotify.notice({
      title: "Sign Out",
      text: "Same account has been logged in from another device.",
    });

    // var email = this.localStorageCacheService.getStorage("email_address");
    // this.logoutSocket(email);

   // this.router.navigate(["login"]);
   this.localStorageCacheService.removeStorage("email_address");
   this.localStorageCacheService.removeStorage("access_token");
   this.localStorageCacheService.removeStorage("refresh_token");
   window.location.href = `login`;
    //  } else {
    //    this.logoutErrorDialog();
    //  }
    // });
  }


  timeLeft: number = 60;
  interval;
  startTimer() {
    this.interval = setInterval(() => {
      var currentTime = this.datepipe.transform(new Date(), 'hh:mm a');
      this.messages.forEach(result => {
          var message : any = result;
          var times = JSON.parse(message.times);

          for (let index = 0; index < times.length; index++) {
            if(currentTime ==times[index].time){
                PNotify.notice({
                  title: "JuanPay Notification",
                  text: message.message,
                  hide : false
                });
            }
          }
      });
    },45000)
  }

  getCSRCode(): void {
    this.walletService.getCSRCode().subscribe((result) => {
      this.csrCode = result.csrCode;
    });
  }

  i;
  getCurrentDate() {
    setInterval(() => {
      this.currentDate = new Date();
    }, 1000);
  }

  getTotalByQuantity(amount: any, quantity: any) {
    return amount * quantity;
  }

  pad(num: number, size: number): string {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  isA4Reprint: boolean = false;
  onReprintReceipt() {
    this.isA4Reprint = false;
    this.reprintPOSReceipt();
   
  }

  onReprintReceiptA4() {
    this.isA4Reprint = true;
    this.reprintPOSReceipt();
  }

  buildUserPhoto(user: any): any {
    return user.cashierUser.photoFormat + "," + user.cashierUser.photo;
  }

  getData() {
    let finalStr = this.receipt[6].value;
    const htlmQR = this.containerQRDiv.nativeElement.innerHTML;
    const logo = this.vipLogo.nativeElement.innerHTML;

    finalStr = finalStr.replace("{{QRCode}}", htlmQR);
    finalStr = finalStr.replace("{{VIPLogo}}", logo);

    const mywindow = window.open("", "PRINT", "height=400,width=600");
    mywindow.document.write(finalStr);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    return true;
  }

  printPOSReceipt(completedDate: any) {
    const pos = this.pos;
    pos.completedDate = completedDate;
    const posTrans = pos.posTransactionItems;

    if (this.isA4.value) {
      let newPosRowA4 = "";
      let billernameA4 = "";
      let billerCodeA4 = "";
      let accountNumberA4 = "";
      let dateA4 = "";
      let reqIdA4 = "";
      let quantityA4 = "";
      let amountA4 = "";
      let subtotal;
      let posTemplateA4 = "";

      for (let i = 0; i < pos.posTransactionItems.length; i++) {

        billernameA4 = posTrans[i].itemName;
        billerCodeA4 = posTrans[i].itemCode;
        accountNumberA4 = posTrans[i].accountNumber;
        dateA4 = this.datepipe.transform(posTrans[i].completedDate);
        quantityA4 = posTrans[i].quantity;
        reqIdA4 = posTrans[i].requestId;
        amountA4 = parseFloat(posTrans[i].amount).toFixed(2);

        var posRow = this.receipt[7].value.replace("{{Date}}", dateA4);
        posRow = posRow.replace("{{BillerName}}", billernameA4);
        posRow = posRow.replace("{{BillerCode}}", billerCodeA4);
        posRow = posRow.replace("{{AccountNumber}}", accountNumberA4);
        posRow = posRow.replace("{{RequestID}}", reqIdA4);
        posRow = posRow.replace("{{Amount}}", amountA4);

        newPosRowA4 += posRow;
        
      }
    
      posTemplateA4 = this.ReplaceValues(pos, 6);
      this.PrintReceipt(posTemplateA4, newPosRowA4);
    } else {
      
      let posTemplate = "";
      let newPosRow = "";
      let transType = "";
      let accountNumber = "";
      let transName = "";
      let quantity = "";
      let amount = "";

      for (let i = 0; i < pos.posTransactionItems.length; i++) {

        transType = posTrans[i].transactionType;
        transName = posTrans[i].itemName;
        quantity = posTrans[i].quantity;
        accountNumber = posTrans[i].accountNumber;
        amount = parseFloat(posTrans[i].amount).toFixed(2);
        
        var posRow = this.receipt[4].value.replace("{{TrasactionType}}", transType);
        posRow = posRow.replace("{{BillerName}}", transName);
        posRow = posRow.replace("{{AccountNumber}}", accountNumber);
        posRow = posRow.replace("{{Quantity}}", quantity);
        posRow = posRow.replace("{{BillAmount}}", amount);
        if (transType == "CEBUANA_SEND" || transType == "CEBUANA_PAYOUT" || transType == "CEBUANA"){
          posRow = (posRow).replace("Customer Account Number", "Control No");
        } else if(transType == "JNT"){
          posRow = (posRow).replace("Customer Account Number", "Mail No");
        } else if(transType == "CARDLESS WITHDRAWAL"){
          posRow = (posRow).replace("Customer Account Number", "Reference No");
          posRow = (posRow).replace("Biller Name", "Transaction Name");
        }

        newPosRow += posRow;
      }
     
      posTemplate = this.ReplaceValues(pos, 2);
      this.PrintReceipt(posTemplate, newPosRow);
    }
  }

  ReplaceValues(pos: any, val: number) {
    
    const logo =
      '<img src="https://juanpaycdn.azureedge.net/juanpayassets/LOGO_logowithtagline-removebg-preview.png" alt="Go-VIP-Logo-Logo-Small" height="50px" border="0">';
    const cashier = this.cashierName;
    const completedDate = this.datepipe.transform(pos.completedDate, "MMM dd, yyyy");
    const transTime = this.datepipe.transform(pos.completedDate, "hh:mm:ss a");
    const htlmQR = this.containerQRDiv.nativeElement.innerHTML;
    const transNo = this.pad(pos?.id, 10);

    

    if (this.isOldTransaction) {
      var subTotal = pos.notMappedSubTotalAmount;
      var tax = pos.notMappedTaxAmount;

      var total = pos.notMappedTotalAmount;
      var cashTendered = pos.cashTendered;
      var cashChange = pos.cashChange;
    } else {
      var subTotal = this.posTransactionFormGroup.controls["subTotalAmount"].value;
      var tax = this.posTransactionFormGroup.controls["taxAmount"].value;

      var total = this.posTransactionFormGroup.controls["totalAmount"].value;
      var cashTendered = this.posTransactionFormGroup.controls["cashTendered"].value;
      var cashChange = this.posTransactionFormGroup.controls["cashChange"].value;
    }

    let posTemplate = this.receipt[val].value.replace("{{Cashier}}", cashier);
    //posRow = (posRow).replace("", htlmQR);
    posTemplate = posTemplate.replace("{{receiptLogo}}", logo);
    posTemplate = posTemplate.replace("{{dti}}", this.dti);
    posTemplate = posTemplate.replace("{{tinNo}}", this.tin);
    posTemplate = posTemplate.replace("{{address}}", this.address);
    posTemplate = posTemplate.replace("{{QRHtml}}", htlmQR);
    posTemplate = posTemplate.replace("{{Date}}", completedDate);
    posTemplate = posTemplate.replace("{{Time}}", transTime);
    posTemplate = posTemplate.replace("{{TransNo}}", transNo);

    posTemplate = posTemplate.replace("{{SubTotal}}", subTotal);
    posTemplate = posTemplate.replace("{{Tax}}", tax);

    posTemplate = posTemplate.replace("{{Total}}", total);
    posTemplate = posTemplate.replace("{{CashTendered}}", cashTendered);
    posTemplate = posTemplate.replace("{{Change}}", cashChange);

    return posTemplate;
  }

  PrintReceipt(NewStr: any, NewRows: any) {
  
    const finalStr = NewStr.replace("{{tablerows}}", NewRows);

    const mywindow = window.open("", "PRINT", "height=400,width=600");
    mywindow.document.write(finalStr);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    return true;
  }

  reprintPOSReceipt() {
    const pos = this.pos;
    const posTrans = pos.posTransactionItems;
 
    if (this.isA4Reprint) {
      let newPosRowA4 = "";
      let billernameA4 = "";
      let billerCodeA4 = "";
      let accountNumberA4 = "";
      let dateA4 = "";
      let reqIdA4 = "";
      let quantityA4 = "";
      let amountA4 = "";
      let subtotal;
      let posTemplateA4 = "";

      for (let i = 0; i < pos.posTransactionItems.length; i++) {
        if(posTrans[i].statusId == 2 || posTrans[i].statusId == 3){
          billernameA4 = posTrans[i].itemName;
          billerCodeA4 = posTrans[i].itemCode;
          accountNumberA4 = posTrans[i].accountNumber;
          dateA4 = this.datepipe.transform(posTrans[i].createdDate);
          quantityA4 = posTrans[i].quantity;
          reqIdA4 = posTrans[i].requestId;
          amountA4 = parseFloat(posTrans[i].amount).toFixed(2);

          var posRow = this.receipt[7].value.replace("{{Date}}", dateA4);
          posRow = posRow.replace("{{BillerName}}", billernameA4);
          posRow = posRow.replace("{{BillerCode}}", billerCodeA4);
          posRow = posRow.replace("{{AccountNumber}}", accountNumberA4);
          posRow = posRow.replace("{{RequestID}}", reqIdA4);
          posRow = posRow.replace("{{Amount}}", this.makeMoney(amountA4));

          newPosRowA4 += posRow;
        }
        
      }
      
      posTemplateA4 = this.ReplaceValues(pos, 6);
      this.PrintReceipt(posTemplateA4, newPosRowA4);
    } else {
      let posTemplate = "";
      let newPosRow = "";
      let transType = "";
      let accountNumber = "";
      let transName = "";
      let quantity = "";
      let amount = "";

      for (let i = 0; i < pos.posTransactionItems.length; i++) {
        if(posTrans[i].statusId == 2 || posTrans[i].statusId == 3){
          transType = posTrans[i].transactionType;
          transName = posTrans[i].itemName;
          quantity = posTrans[i].quantity;
          accountNumber = posTrans[i].accountNumber;
          amount = parseFloat(posTrans[i].amount).toFixed(2);
         
          var posRow = this.receipt[4].value.replace("{{TrasactionType}}", transType);
          posRow = posRow.replace("{{BillerName}}", transName);
          posRow = posRow.replace("{{AccountNumber}}", accountNumber);
          posRow = posRow.replace("{{Quantity}}", quantity);
          posRow = posRow.replace("{{BillAmount}}", this.makeMoney(amount));
          if (transType == "CEBUANA_SEND" || transType == "CEBUANA_PAYOUT" || transType == "CEBUANA"){
            posRow = (posRow).replace("Customer Account Number", "Control No");
          } else if(transType == "JNT"){
            posRow = (posRow).replace("Customer Account Number", "Mail No");
          } else if(transType == "CARDLESS WITHDRAWAL"){
            posRow = (posRow).replace("Customer Account Number", "Reference No");
            posRow = (posRow).replace("Biller Name", "Transaction Name");
          }
          
          newPosRow += posRow;
        }
      }
    
      posTemplate = this.ReplaceValues(pos, 2);
      this.PrintReceipt(posTemplate, newPosRow);
    }
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }




  //Product Event 



  openPreviewProductEventLeaderboard(previewProductEventLeaderboardModal) {
    this.modalService.open(previewProductEventLeaderboardModal, {
      centered: true,
      size:  <any>'xl',
      backdrop: "static",
      keyboard: false,
    });
  }

  //Product Event 
}
