import { Component, OnInit, ɵCodegenComponentFactoryResolver } from '@angular/core';
import { SettlementsService } from 'src/data/services/web/settlements.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5
import { Select2OptionData } from "ng-select2";
import { CryptoService } from 'src/data/crypto/crypto.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SunlifeGrepaExportExcelService } from 'src/data/services/web/sunlife-grepa-export-excel.service';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-nationlink-settlement',
  templateUrl: './nationlink-settlement.component.html',
  styleUrls: ['./nationlink-settlement.component.less']
})
export class NationlinkSettlementComponent implements OnInit {


  public uploadModal = null;
  public fileUploadFormGroup: FormGroup;
  emptyString = '';
  p: number = 1;
  public pageRows: any = 10;
  
  constructor(private settlementService : SettlementsService,
    private websettings : WebSettingsService,
    private modalServe: NgbModal,
    private router: Router,
    private currencyPipe : CurrencyPipe,
    formBuilder : FormBuilder,
    public ete: SunlifeGrepaExportExcelService,
    private route: ActivatedRoute, 
    private cryptoService: CryptoService,
    public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.initFileUploadFormGroup();
    PNotifyButtons;
    this.initSearchFormGroup();
    this.getTransaction();
    this.getShowRows();
    this.getStatus();
    this.initTextFileDownloadFormGroup();
  }

  public textFileDownloadFormGroup: FormGroup;
  initTextFileDownloadFormGroup(): void {
    this.textFileDownloadFormGroup = new FormGroup({
      dateTime: new FormControl(this.emptyString)
    });
  }
  downloadModal = null;
  openTextFileDownload(content: any) : void {
    this.downloadModal = this.modalServe.open(content, { centered: true, size: "md" });
  }

  downloadTextFileBankSettlement(transactionType : any){
    const dateRange : string = this.textFileDownloadFormGroup.controls.dateTime.value;
    var datetime = `${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}`
    this.settlementService.downloadTextFileBankSettlement("Nationlink", transactionType,datetime)
    .subscribe((result) => {
      
      var blob = new Blob([result.data], {type: "text/plain;charset=utf-8"});
      FileSaver.saveAs(blob, result.fileName);

      PNotify.success({
        title: "Success",
        text: "Download Complete.",
      });
    }, error =>{
      PNotify.error({
        title: "Failed",
        text: "Please double check the data and try again.",
      });
    });
  }

  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type});
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
        alert( 'Please disable your Pop-up blocker and try again.');
    }
  }

  loader = false;
  submitSettlement() : void {
    if (confirm("Are you sure you want to continue? [BATCH UPLOAD]")) {
    this.loader = true;

    this.settlementService.uploadNationlinkData(this.listItems)
    .subscribe((result) => {
      PNotify.success({
        title: "Success",
        text: "File uploaded successfully!",
      });
      this.fileUploadFormGroup.patchValue({
        photoFile: "",
      })
      this.loader = false;
      this.getTransaction();
      this.uploadModal.close();
    
    }, error => {
      if(error.error.status == 400){
        PNotify.error({
          title: "Failed",
          text: "Transaction dates are uploaded already!",
        });
      }else{
        PNotify.error({
          title: "Failed",
          text: "Error uploading file!",
        });
      }
    
      this.loader = false;
    });
  }
  }

  initFileUploadFormGroup(): void {
    this.fileUploadFormGroup = new FormGroup({
      reportFile: new FormControl(this.emptyString)
    });
  }



  

  openFileUpload(content: any) : void {
    this.listItems = [];
    this.uploadModal = this.modalServe.open(content, { centered: true, size: "xl" });
  }

  public selectedRowsId: any = 0;
  selectedRows(e){
    this.selectedRowsId = e;
    this.pageRows = e;
   
  }

  getSettlementBank(id : any) {

    if(id){
    var settlementBanksArray = [
      { id: "0001", name: "UNION BANK" },
      { id: "0102", name: "ASIA UNITED BANK" },
      { id: "0053", name: "BANCO DE ORO" },
      { id: "0004", name: "BANK OF THE PHILIPPINE ISLANDS" },
      { id: "0030", name: "BDO NETWORK BANK" },
      { id: "0010", name: "CHINABANK" },
      { id: "0035", name: "LANDBANK OF THE PHILS" },
      { id: "0026", name: "METROBANK" },
      { id: "0008", name: "PHILIPPINE NATIONAL BANK" },
      { id: "0670", name: "QUEZON CAPITAL RURAL BANK" },
      { id: "0014", name: "SECURITY BANK" },
    ];

   return settlementBanksArray.filter(x=>x.id == id)[0].name;
  }else{
    return this.emptyString;
  }
  }

 listItems = [];
  
  public fileName: any = "Click here to choose file...";
  public onFileChange(event: any) {
    var i  = 0;
    var date;
    var listItems = [];
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.fileName = file.name;
      reader.onload = () => {
        let skipCount = 0;
        const base64Data = reader.result.toString().replace("data:text/plain;base64,",this.emptyString);
        for (const line of atob(base64Data).split(/[\r\n]+/)){
          if(line.replace(/\s/g, "") != this.emptyString){
            i++;
            if(i == 5){
              date = line.replace("FOR",this.emptyString).replace(/\s/g,this.emptyString);
            }else if (i >= 10) {
              if(line.length == 153 && skipCount < i){
                  if(line.includes("RUN DATE")){
                    skipCount = i + 8
                  }else if(line.includes('-----------------')){
                    break
                  }else{
                    var cleanLine = line.trim();
                    var storeName = cleanLine.substring(0,17);
                    var forExtractLine = cleanLine.replace(storeName, this.emptyString);
                    var readyToLoopLine = forExtractLine.replace(/  +/g,'-----');
                    var splitted = readyToLoopLine.split('-----'); 
                    var data = {
                      storeName : storeName.trim(),
                      terminalId : splitted[0],
                      transactionCountFirst: splitted[1],
                      amountFirst: splitted[2],
                      transactionCountSecond: splitted[3],
                      amountSecond: splitted[4],
                      amountThird: splitted[5],
                      fee: splitted[6],
                      transactionCountThird: splitted[7],
                      amountForth: splitted[8],
                      netPayable: splitted[9],
                      //transactionDate: date
                      transactionDate: this.datepipe.transform(date, 'MM/dd/yyyy'),
                    }
                    listItems.push(data);
                  } 
              }
             
            }
          }
        }

        this.listItems = listItems;

        debugger
        console.log(listItems);

        this.fileUploadFormGroup.patchValue({
          photoFile: reader.result,
        });
      };
    }
  }

  searchByFormGroup: FormGroup;
  public showRowsOption: Array<Select2OptionData>;
  public transactions: any = [];
  public transaction: any;
  public totals : any;

  initSearchFormGroup(): void {
    this.searchByFormGroup = new FormGroup({
      showRows: new FormControl(null),
      dateRange: new FormControl(this.emptyString),
      userId: new FormControl(this.emptyString),
      storeName: new FormControl(null),
      status: new FormControl(null),
      requestId: new FormControl(null),
    });


    this.searchByFormGroup.controls.showRows.valueChanges.subscribe(e => {
      this.selectedRows(e);
    });
  }

  public statusIdFilterOptions: Array<Select2OptionData>;
  getStatus() {
    this.statusIdFilterOptions = [
      { id: "3", text: "PENDING" },
      { id: "2", text: "COMPLETED" }
      // { id: "1", text: "DECLINED" }
    ];
  }


  getShowRows() {
    this.websettings.getWebSettingsByIdentifier("SHOW_ROWS").subscribe((result) => {
     
      this.showRowsOption = result.data.map((status) => {
        return {
          id: status.parameter,
          text: status.value,
        };
      });
       
    });
  }


  getTransaction(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader =true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    
    const userId : string = this.searchByFormGroup.controls.userId.value;
    const storeName : string = this.searchByFormGroup.controls.storeName.value;
    const showRows : string = this.searchByFormGroup.controls.showRows.value;
    const dateRange : string = this.searchByFormGroup.controls.dateRange.value;
    const status : string = this.searchByFormGroup.controls.status.value;
    const requestId : string = this.searchByFormGroup.controls.requestId.value;
    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_userId: string = userId ? `userId=${userId}&` : "";
    const queryString_storeName: string = storeName ? `storeName=${storeName}&` : "";
    const queryString_requestId: string = requestId ? `requestId=${requestId}&` : "";
    const queryString_status: string = status ? `status=${status}&` : "";
    const queryString_showRows: string = showRows ? `showRows=${showRows}&` : "showRows=10&";
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    const queryString = queryString_orderby + queryString_order +  queryString_userId+ queryString_storeName + queryString_requestId +queryString_status+ queryString_dateRange + queryString_showRows;
 
    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/Settlements/Nationlink/Settlements?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/Settlements/Nationlink/Settlements?${queryString}`;
      }
    }

    this.settlementService.getSettlements(endPointUrl.slice(0, -1)).subscribe((result) => {
      this.loader =false;
      if(result){
        if (result.data.length == 0 && result.pageNumber != 1) {
          this.getTransaction(result.previousPage, _sortBy, _sort);
        } else {
          this.transactions = result.data;
          this.totals = result.totals;
        }
      }
     
    });
  }

 

  searchData(){
    this.pageRows = 1;
    this.getTransaction(this.buildPageUrl(1,
      this.searchByFormGroup.controls.showRows.value
      ?this.searchByFormGroup.controls.showRows.value : 10),null,null,true);

  }

  createdDate = "";
  searchString = "";
  
  private _sortBy: any;
  private _sort: any;

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.createdDate = field == "createdDate" ? "th-inner sortable both " + sortBy : "";
   
    this.getTransaction(
      `${environment.API_URL}/api/Settlements/Nationlink/Settlements?pageNumber=${
        page ? page : this.transactions.pageNumber
      }`,
      this._sortBy,
      this._sort
    );
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.transactions?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }

  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p = pageNumber;
    return `${environment.API_URL}/api/Settlements/Nationlink/Settlements?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }

  statusOfAmount(money: any): boolean {
    return money < 0 ? false : true;
  }

  makeMoney(money: any) {
    if(money){
      return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
    }else{
      return "N/A";
    }
   
  }


  
  public editModal = null;

  openEditView(content: any,transaction : any) : void {
    this.getSettlementbyId(content,transaction,true);
  }

  getSettlementbyId(content: any,transaction : any, openModal : any){
    this.settlementService.getSettlementbyIdNationLink(transaction.id).subscribe((result) => {
      this.loader =false;
      if(result){
        this.transaction = result;
        if(openModal){
          this.editModal = this.modalServe.open(content, { centered: true, size: "xl" });
          transaction = result;
        }else{
          this.searchData();
        }
      }
    });
  }

 closeModal(){
  this.getSettlementbyId(null,this.transaction,false);
  this.editModal.close();
 }

  exportToExcel(){

  }

}

