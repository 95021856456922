<app-loader *ngIf="loader"></app-loader>

<div class="container-fluid --home">
    <div class="row">
        <div class="col-12">
            <div class="header mt-3">
                <div class="text">
                    <img src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file260fa82a-0150-4c07-a413-e289dffd98fb.png" width="65px" height="65px" alt=""> 
                    VIPS RIDER CREDIT
                </div>
            </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 pl-5 pr-4" style="max-height: 316px;">
            <div class="box p-0 m-0">
                <app-widget-basic-profile></app-widget-basic-profile>
            </div>
        </div>
        <div class="col-lg-7 col-md-12 col-sm-12 pl-4 pr-5">
            <div class="card">
                <div class="b-b">
                    <div class="nav-active-border b-success top">
                        <ul class="nav" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active text-primary font-weight-bold" id="home-tab" data-toggle="tab" href="#home4" role="tab" aria-controls="home" aria-selected="false">
                                    Rider Credit
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-danger font-weight-bold" id="profile-tab" data-toggle="tab" href="#profile4" role="tab" aria-controls="profile" aria-selected="true">
                                    VWallet
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="tab-content p-3">
                    <div class="tab-pane fade active show" id="home4" role="tabpanel" aria-labelledby="home-tab">
                        <div class="row box1 box">
                            <div class="col-12 pt-2" style="min-height: 141.56px;">
                                <h5>Transfer VWallet to Rider Credit</h5>
                                <hr>
            
                                <div class="form-group">
                                  <app-textbox-currency
                                    [parent-form-group]="sendFormGroup" 
                                    tab-index-start="0"
                                    control-name="creditamount"
                                    [read-only]="false"
                                    [error]="sendFormGroup?.controls.creditamount.errors | estatus"
                                    number-only="false"
                                    read-only="false"
                                    label="Amount"
                                    placeholder="0.00"
                                    max-length="10"
                                    >
                                    </app-textbox-currency>
                                </div>
                              
                                <hr>
                                <button (click)="confirmRiderModal(confirmRiderCreditModal)" class="btn btn-primary button-next float-right mb-3"
                                  style="color: white;" >
                                  Transfer to Rider Credit &nbsp;
                                  <span class="fa fa-hand-pointer"></span>
                                </button>
                                
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="profile4" role="tabpanel" aria-labelledby="profile-tab">
                        <div class="row box1 box">
                            <div class="col-12 pt-2" style="min-height: 141.56px;">
                                <h5>Transfer Rider Credit to VWallet</h5>
                                <hr>
            
                                <div class="form-group">
                                  <app-textbox-currency
                                    [parent-form-group]="sendFormGroup" 
                                    tab-index-start="0"
                                    control-name="amount"
                                    [read-only]="false"
                                    [error]="sendFormGroup?.controls.amount.errors | estatus"
                                    number-only="false"
                                    read-only="false"
                                    label="Amount"
                                    placeholder="0.00"
                                    max-length="10"
                                    >
                                    </app-textbox-currency>
                                </div>
                              
                                <hr>
                                <button (click)="confirmModal(confirmVWalletModal)" class="btn btn-danger button-next float-right mb-3"
                                  style="color: white;" >
                                  Transfer to VWallet &nbsp;
                                  <span class="fa fa-hand-pointer"></span>
                                </button>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="row box1 box">
                <div class="col-12 pt-2" style="min-height: 180.56px;">
                    <h5>Transfer Rider Credit</h5>
                    <hr>

                    <div class="form-group">
                      <app-textbox-currency
                      [parent-form-group]="sendFormGroup" 
                      tab-index-start="0"
                      control-name="amount"
                      [read-only]="false"
                      [error]="sendFormGroup?.controls.amount.errors | estatus"
                      number-only="false"
                      read-only="false"
                      label="Amount"
                      placeholder="0.00"
                      max-length="10"
                    >
                    </app-textbox-currency>
                    </div>
                  
                    <hr>
                    <a (click)="transferCredit()" class="btn btn-info button-next float-right mb-3"
                      style="color: white;" >
                      Transfer to Rider Credit &nbsp;
                      <span class="fa fa-hand-pointer"></span>
                    </a>
                    
                </div>
            </div> -->
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 pl-5 pr-5">
            <div class="row box mt-3">
                <div class="col-12 pt-2">
                    <h5>RIDER CREDIT HISTORY</h5>
                    <div class="row mt-2 p-2" [formGroup]="searchTranscationFormGroup">
                        <div class="col-lg-2 col-md-6 col-sm-12">
                          <!-- <button class="btn btn-success btn-sm" (click)="getTransactionExcel()"><i class="fa fa-file-excel"></i> &nbsp; Export</button> -->
                        </div>

                        <div class="col-lg-3 col-md-6 col-sm-12">
                          <input
                            type="text"
                            placeholder="Date Range"
                            class="form-control"
                            bsDaterangepicker
                            formControlName="dateRange"
                            style="background-color: white; color: black;"
                            [bsConfig]="{
                              rangeInputFormat: 'YYYY-MM-DD',
                              dateInputFormat: 'YYYY-MM-DD',
                              showWeekNumbers: false
                            }"
                          />
                        </div>

                        <div class="col-lg-7 col-md-7 col-sm-12 pr-0">
                            <div class="input-group">
                                <input type="search" class="form-control rounded" formControlName="searchString" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                                <button type="button" (click)="getTransaction()" class="btn btn-outline-primary mr-2">Search</button>
                            </div>
                        </div>

                        <div class="col-12 mt-3"  style="max-height: calc(100vh - 665px); overflow-y: scroll;">
                            <table id="datatable" class="table table-striped table-hover " role="grid" aria-describedby="datatable_info">
                                <thead>
                                  <tr>
                                    <th data-sortable="true" data-field="name">
                                      <div>Date</div>
                                    </th>
                                    <th data-sortable="true" data-field="value">
                                      <div>Transaction Type</div>
                                    </th>
                                    <!-- <th data-sortable="true" data-field="description">
                                      <div>User Type</div>
                                    </th> -->
                                    <th data-sortable="true" data-field="description">
                                        <div>Reference</div>
                                    </th>
                                    <th class="text-center" data-sortable="true" data-field="description">
                                        <div>Amount</div>
                                    </th>
                                    <th data-field="finish">
                                        <span class="d-none d-sm-block">Status</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    *ngFor="let transaction of transactions?.data | paginate: { itemsPerPage: pageRows, currentPage: p, totalItems: transactions?.totalRecords }"
                                    
                                    style="cursor: pointer"
                                    class="odd"
                                    role="row"
                                  >
                                    <td class="flex">
                                      <div class="item-except text-sm h-1x">
                                        {{ transaction?.createdDate | date: "dd-MMM-yyyy" }}
                                      </div>
                                    </td>
                                    <td class="flex">
                                      <div class="item-except text-sm h-1x">
                                        {{ transaction?.transactionName }}
                                      </div>
                                    </td>
                                  <!-- <td class="flex">
                                    <div class="item-except text-sm h-1x">
                                      {{ transaction?.transactionName.split(" - ")[1] }}
                                    </div>
                                  </td> -->
                                    <td class="flex">
                                      <div class="item-except text-sm h-1x">
                                        {{ transaction?.requestId }}
                                      </div>
                                    </td>
                                    <td class="flex">
                                        <div class="text-center">
                                            <strong *ngIf="transaction?.transactionName == 'RIDER CREDIT TO VWALLET'" style="color: red"
                                                >{{ makeMoney(transaction?.amount * -1) }}
                                            </strong>
                                            <strong *ngIf="transaction?.transactionName == 'VWALLET TO RIDER CREDIT'" style="color: green"
                                                >{{ makeMoney(transaction?.amount * -1) }}
                                            </strong>
                                        </div>
                                    </td>

                                    <td class="flex" style="max-width: 75px">
                                        <app-status-table-data [status]="transaction?.statusId"></app-status-table-data>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>

                        </div>
                        <div class="col-12">
                          <div class="row mt-5">
                            <div class="col-sm-12 col-md-5">
                              <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                                <div class="col-sm-12 col-md-12">
                                  <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                                    <span class="font-weight-bold"
                                      >Showing {{ (transactions?.pageNumber - 1) * transactions?.pageSize + 1 }} to
                                      {{ (transactions?.pageNumber - 1) * transactions?.pageSize + transactions?.data?.length }} of
                                      {{ transactions?.data?.length }} entries</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-7" style="float: right">
                              <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                                <ul class="pagination" style="float: right; margin-right: 36px">
                                  <pagination-controls (pageChange)="getTransaction(buildPageUrl($event, 10))"></pagination-controls>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #confirmRiderCreditModal let-modal>
    <div class="modal-header">
      <h6 class="modal-title">Are you sure you want to transfer VWallet to Rider Credit?</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
      <button class="btn btn-sm btn-primary float-right" (click)="transferCredit()">Proceed</button>
      <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
        Cancel
      </button>
    </div>
  </ng-template> 

  <ng-template #confirmVWalletModal let-modal>
    <div class="modal-header">
      <h6 class="modal-title">Are you sure you want to transfer Rider Credit to VWallet?</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
      <button class="btn btn-sm btn-primary float-right" (click)="transferToVWallet()">Proceed</button>
      <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
        Cancel
      </button>
    </div>
  </ng-template> 