import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { StandardInsuranceService } from 'src/data/services/web/standard-insurance.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';

PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5";

@Component({
  selector: 'app-standard-insurance',
  templateUrl: './standard-insurance.component.html',
  styleUrls: ['./standard-insurance.component.less']
})
export class StandardInsuranceComponent implements OnInit {

  @Input("application") application: any;
  standardInsuranceFormGroup : FormGroup;
  formBuilder : FormBuilder;
  public emptyString = "";
  public billers : any = [];
  public biller : any;
  public billerCode : any;
  public selectedBillerCode : any;

  public activeTab = 1;
  public loader = true;
  public fee : any;
  public amount : any;

  public clientTypes : any = [];
  public industryTypes : any = [];
  public productTypes : any = [];
  public premiumTypes : any = [];
  public mvTypes : any = [];
  public titles : any = [];
  public registrationTypes : any = [];
  public taxTypes : any = [];

  public bodyTypes : any = [];
  public fmvManufacturers : any = [];
  public fmvModels : any = [];
  public subBodyTypes : any = [];
  public vehicleTypes : any = [];

  @Output("closeModal") closeModal = new EventEmitter();
  
    constructor(
    private standardInsuranceService : StandardInsuranceService,
    private router: Router,
    localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    formBuilder : FormBuilder,
    private webService: WebSettingsService,
    private datepipe: DatePipe) {
    this.formBuilder = formBuilder;
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.initStandardInsuranceFormGroup();
    this.initAPI();
    this.initClientType();
    this.initPostalRef();

    // this.myControl.valueChanges.subscribe((value) => {
    //   debugger
    //   this.filteredOptions = this.options.filter((option) =>
    //     option.area.toLowerCase().includes(value.toLowerCase())
    //   );
    // });
    // this.initVIPProtectFormGroup();
    // this.initSunLifeGrepaProducts();
  }

  postalRef: any;
  originalPostalRef: any;
  initPostalRef(){
    this.webService.getWebSettingsByIdentifier("POSTAL_REFERENCE").subscribe((result) => {
      const provinceCities = result.data[0].value;
      const uniqueProvinceCities = [...new Set(provinceCities.map(item => item.PROVINCE_CITY))];
      this.postalRef = uniqueProvinceCities.sort((a, b) => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
      // You can also store the original JSON result in another variable if needed
      this.originalPostalRef = provinceCities;
    });
  }

  initStandardInsuranceFormGroup(): void {
    this.standardInsuranceFormGroup = new FormGroup({
      clientType: new FormControl(this.emptyString, [Validators.required]),
      title: new FormControl(this.emptyString, [Validators.required]),
      firstName: new FormControl(this.emptyString, [Validators.required]),
      middleName: new FormControl(this.emptyString, [Validators.required]),
      lastName: new FormControl(this.emptyString, [Validators.required]),
      companyName: new FormControl(this.emptyString),
      industryType: new FormControl(this.emptyString),
      contactNo: new FormControl(this.emptyString, [Validators.required]),
      emailAddress: new FormControl(this.emptyString, [Validators.required]),
      address1: new FormControl(this.emptyString, [Validators.required]),
      address2: new FormControl(this.emptyString, [Validators.required]),
      province: new FormControl(this.emptyString, [Validators.required]),
      postalCode: new FormControl(this.emptyString, [Validators.required]),
      inceptionDate: new FormControl(this.emptyString, [Validators.required]),
      expiryDate: new FormControl(this.emptyString, [Validators.required]),
      branchCode: new FormControl("GC", [Validators.required]),
      productCode: new FormControl(this.emptyString, [Validators.required]),
      plateNo: new FormControl(this.emptyString, [Validators.required]),
      yearModel: new FormControl(this.emptyString, [Validators.required]),
      manufacturer: new FormControl(this.emptyString, [Validators.required]),
      makeModel: new FormControl(this.emptyString, [Validators.required]),
      bodyType: new FormControl(this.emptyString),
      bodySubType: new FormControl(this.emptyString),
      vehicleType: new FormControl(this.emptyString, [Validators.required]),
      engineType: new FormControl(this.emptyString, [Validators.required]),
      seat: new FormControl(this.emptyString, [Validators.required]),
      wheels: new FormControl(this.emptyString, [Validators.required]),
      engineNo: new FormControl(this.emptyString, [Validators.required]),
      chassisNo: new FormControl(this.emptyString, [Validators.required]),
      mvFileNo: new FormControl(this.emptyString, [Validators.required]),
      vehicleColor: new FormControl(this.emptyString, [Validators.required]),
      registrationType: new FormControl(this.emptyString, [Validators.required]),
      mvType: new FormControl(this.emptyString, [Validators.required]),
      taxType: new FormControl(this.emptyString, [Validators.required]),
      mvPremiumType: new FormControl(this.emptyString, [Validators.required]),
      transactionId: new FormControl(this.emptyString)
    });

    this.standardInsuranceFormGroup.controls.clientType.valueChanges.subscribe((value) => {
      if(value == "C"){
        this.isIndividual = false;
        this.standardInsuranceFormGroup.get('companyName').setValidators(Validators.required)
        this.standardInsuranceFormGroup.get('industryType').setValidators(Validators.required)
        this.standardInsuranceFormGroup.get('title').clearValidators();
        this.standardInsuranceFormGroup.get('firstName').clearValidators();
        this.standardInsuranceFormGroup.get('middleName').clearValidators();
        this.standardInsuranceFormGroup.get('lastName').clearValidators();
      } else if(value == "I") {
        this.isIndividual = true;
        this.standardInsuranceFormGroup.get('companyName').clearValidators();
        this.standardInsuranceFormGroup.get('industryType').clearValidators();
        this.standardInsuranceFormGroup.get('title').setValidators(Validators.required)
        this.standardInsuranceFormGroup.get('firstName').setValidators(Validators.required)
        this.standardInsuranceFormGroup.get('middleName').setValidators(Validators.required)
        this.standardInsuranceFormGroup.get('lastName').setValidators(Validators.required)
      } else {
        this.isIndividual = true;
      }
    });

    this.standardInsuranceFormGroup.controls.inceptionDate.valueChanges.subscribe((value) => { 
      var year = this.standardInsuranceFormGroup.controls.inceptionDate.value.getFullYear();
      var month = this.standardInsuranceFormGroup.controls.inceptionDate.value.getMonth();
      var day = this.standardInsuranceFormGroup.controls.inceptionDate.value.getDate();
      var c = new Date(year + 1, month, day);

      this.standardInsuranceFormGroup.controls.expiryDate.patchValue(c);
    });

    this.standardInsuranceFormGroup.controls.yearModel.valueChanges.subscribe((value) => {
      if(value.length == 4 && this.productCode != null){
        this.getManufacturer(this.productCode);
        this.getBodyType(this.productCode);
        this.getVehicleType(this.productCode);
      } else if(value.length == 0){
        this.clearFields();
      }
    });

    // this.standardInsuranceFormGroup.controls.province.valueChanges.subscribe((value) => { 
    //   this.standardInsuranceFormGroup.controls.postalCode.patchValue(value);
    // });
  }

  provinceDescItems: any;
  getPostal($event){
    debugger
    this.provinceDescItems = this.originalPostalRef.filter(x => x.PROVINCE_CITY == $event);
    //this.standardInsuranceFormGroup.controls.postalCode.patchValue($event.POSTAL_CODE);
  }

  onRemoveUpline($event) {
    this.provinceDescItems = [];
    this.standardInsuranceFormGroup.controls.postalCode.patchValue(this.emptyString);
    this.standardInsuranceFormGroup.controls.postalCode.updateValueAndValidity({emitEvent:false, onlySelf:true});
  }

  isIndividual: boolean = true;
  initAPI(){
    this.standardInsuranceService.getAuthenticated().subscribe((result) => {
      if(result){
        this.initIndustryType();
        this.initProductType();
        this.initPremiumType();
        this.initMVType();
        this.initTitle();
        this.initRegistrationType();
        this.initTaxType();
      } else {
        PNotify.error({
          title: "Error",
          text: "Something went wrong. Please try again.",
        });
      }
    });
  }

  initIndustryType(){
    this.standardInsuranceService.getIndustryType().subscribe((result) => {
      if(result){
        this.industryTypes = result;
      } else {
        PNotify.error({
          title: "Error",
          text: "Something went wrong. Please try again.",
        });
      }
    });
  }

  initClientType(){
    this.clientTypes = [
      { id : "C", value: "C"},
      { id : "I", value: "I"}
    ];
  }

  initProductType(){
    this.standardInsuranceService.getProductType().subscribe((result) => {
      if(result){
        this.productTypes = result;
      } else {
        PNotify.error({
          title: "Error",
          text: "Something went wrong. Please try again.",
        });
      }
    });
  }

  initPremiumType(){
    this.standardInsuranceService.getPremiumType().subscribe((result) => {
      if(result){
        this.premiumTypes = result;
      } else {
        PNotify.error({
          title: "Error",
          text: "Something went wrong. Please try again.",
        });
      }
    });
  }

  initMVType(){
    this.standardInsuranceService.getMVType().subscribe((result) => {
      if(result){
        this.mvTypes = result.sort((a,b) => 0 - (a > b ? -1 : 1));
      } else {
        PNotify.error({
          title: "Error",
          text: "Something went wrong. Please try again.",
        });
      }
    });
  }

  initTitle(){
    this.standardInsuranceService.getTitle().subscribe((result) => {
      if(result){
        this.titles = result;
        this.loader = false;
      } else {
        PNotify.error({
          title: "Error",
          text: "Something went wrong. Please try again.",
        });
      }
    });
  }

  initRegistrationType(){
    this.standardInsuranceService.getRegistrationType().subscribe((result) => {
      if(result){
        this.registrationTypes = result;
        this.loader = false;
      } else {
        PNotify.error({
          title: "Error",
          text: "Something went wrong. Please try again.",
        });
      }
    });
  }

  initTaxType(){
    this.standardInsuranceService.getTaxType().subscribe((result) => {
      if(result){
        this.taxTypes = result;
        this.loader = false;
      } else {
        PNotify.error({
          title: "Error",
          text: "Something went wrong. Please try again.",
        });
      }
    });
  }

  productCode: any = null;
  productTypeChange(productType : any){
    if(productType != null && productType != ""){
      this.productCode = productType;
      this.getManufacturer(productType);
      this.getBodyType(productType);
      this.getVehicleType(productType);
    } else {
      this.productCode = null;
      this.clearFields();
    }
  }

  getManufacturer(productType : any){
    this.productCode = productType;
    if(this.standardInsuranceFormGroup.controls.yearModel.value){
      const year = this.standardInsuranceFormGroup.controls.yearModel.value
      this.standardInsuranceService.getManufacturer(productType, year).subscribe((result) => {
        if(result){
          //console.log(result);
          if(result[0]?.manufacturer){
            this.fmvManufacturers = result;
            this.loader = false;
          } else {
            var jsonResult = JSON.parse(result[0]);
            this.clearFields();
            PNotify.error({
              title: "Manufacturer",
              text: jsonResult?.description,
            });
          }
        } else {
          PNotify.error({
            title: "Error",
            text: "Something went wrong. Please try again.",
          });
        }
      });
    } else {
      this.clearFields();
      // PNotify.error({
      //   title: "Invalid",
      //   text: "Product Type and Year Model is Required.",
      // });
    }
  }

  handleSelect(event: Event) {
    if(this.productCode == null || this.standardInsuranceFormGroup.controls.yearModel.value == ""){
      PNotify.error({
        title: "Invalid",
        text: "Product Type and Year Model is Required.",
      });
    }
  }

  clearFields() {
    this.standardInsuranceFormGroup.controls.manufacturer.patchValue(this.emptyString);
    this.standardInsuranceFormGroup.controls.makeModel.patchValue(this.emptyString);
    this.standardInsuranceFormGroup.controls.bodyType.patchValue(this.emptyString);
    this.standardInsuranceFormGroup.controls.bodySubType.patchValue(this.emptyString);
    this.standardInsuranceFormGroup.controls.vehicleType.patchValue(this.emptyString);
    this.standardInsuranceFormGroup.controls.mvPremiumType.patchValue(this.emptyString);
    
    this.standardInsuranceFormGroup.controls.manufacturer.updateValueAndValidity({emitEvent:false, onlySelf:true});
    this.standardInsuranceFormGroup.controls.makeModel.updateValueAndValidity({emitEvent:false, onlySelf:true});
    this.standardInsuranceFormGroup.controls.bodyType.updateValueAndValidity({emitEvent:false, onlySelf:true});
    this.standardInsuranceFormGroup.controls.bodySubType.updateValueAndValidity({emitEvent:false, onlySelf:true});
    this.standardInsuranceFormGroup.controls.vehicleType.updateValueAndValidity({emitEvent:false, onlySelf:true});
    this.standardInsuranceFormGroup.controls.mvPremiumType.updateValueAndValidity({emitEvent:false, onlySelf:true});

    this.productCode == null;
    this.fmvManufacturers = [];
    this.fmvModels = [];
    this.bodyTypes = [];
    this.subBodyTypes = [];
    this.vehicleTypes = [];
    this.premiumTypes = [];
  }

  getMakeModel(manufacturer : any){
    if(this.standardInsuranceFormGroup.controls.yearModel.value && this.productCode){
      const year = this.standardInsuranceFormGroup.controls.yearModel.value
      const productType = this.productCode;
      this.standardInsuranceService.getMakeModel(productType, year, manufacturer).subscribe(
        (result) => {
          if(result) {
            this.fmvModels = result;
            this.loader = false;
          } else {
            PNotify.error({
              title: "Invalid",
              text: "No Record Available",
            });
          }
        }, 
        (error) => {
          PNotify.error({
            title: "Error",
            text: "Something went wrong. Please try again.",
          });
        }
      );
    } else {
      PNotify.error({
        title: "Invalid",
        text: "Product Type, Manufacturer and Year Model is Required.",
      });
    }
  }

  bodyTypeChange(bodyType: any){
    this.getSubBodyType(bodyType, this.productCode);
  }

  fmvDetails: any = [];
  makeModelChange(makeModel: any){
    var ref = this.standardInsuranceFormGroup.controls;
    let yearModel = ref.yearModel.value;
    let manufacturer = ref.manufacturer.value;
    let model = makeModel;
    let productCode = ref.productCode.value;

    this.standardInsuranceService.getfmvDetails(yearModel, manufacturer, model, productCode).subscribe((result) => {
      this.fmvDetails = result;
      console.log(result);
    });
  }

  async fmvChange(fmvCode){
    console.log(fmvCode);
    var fmv = this.fmvDetails.filter(x => x.fmvCode == fmvCode);
    var bodytype = this.bodyTypes.filter(x => x.description == fmv[0].bodyType);
    await this.getAutoSubBodyType(bodytype[0].code, this.productCode, fmv);
    
    this.standardInsuranceFormGroup.controls.bodyType.patchValue(bodytype[0].code);
  }

  getBodyType(productType : any){
    this.standardInsuranceService.getBodyType(productType).subscribe(
      (result) => {
        if(result){
          this.bodyTypes = result;
          this.loader = false;
        } else {
          PNotify.error({
            title: "Invalid",
            text: "No Record Available.",
          });
        }
      }, 
      (error) => {
        PNotify.error({
          title: "Error",
          text: "Something went wrong. Please try again.",
        });
      }
    );
  }

  getBodyTypeDetail(bodyType){
    return this.bodyTypes.find(x => x.code == bodyType).description;;
  }

  getVehicleTypeDetails(vehicleType){
    return this.vehicleTypes.find(x => x.code == vehicleType).description;;
  }

  getTaxTypeDetail(taxType){
    return this.taxTypes.find(x => x.code == taxType).description;;
  }

  getMVTypeDetail(mvType){
    return this.mvTypes.find(x => x.code == mvType).description;
  }

  getPremiumTypeDetail(premiumType){
    return this.premiumTypes.find(x => x.code == premiumType).description;
  }

  getSubBodyType(bodyType: any, productType : any){
    this.standardInsuranceService.getSubBodyType(bodyType , productType).subscribe(
      (result) => {
        if(result){
          this.subBodyTypes = result;
          this.loader = false;
        } else {
          PNotify.error({
            title: "Invalid",
            text: "No Record Available.",
          });
        }
      }, 
      (error) => {
        PNotify.error({
          title: "Error",
          text: "Something went wrong. Please try again.",
        });
      }
    );
  }

  getAutoSubBodyType(bodyType: any, productType : any, fmv: any){
    this.standardInsuranceService.getSubBodyType(bodyType , productType).subscribe((result) => {
      if(result){
        this.subBodyTypes = result;
        debugger
        var subbodytype = result.filter(x => x.description == fmv[0].subType);
        this.standardInsuranceFormGroup.controls.bodySubType.patchValue(subbodytype[0].code);
        this.standardInsuranceFormGroup.controls.engineType.patchValue(fmv[0].engine);
        this.standardInsuranceFormGroup.controls.seat.patchValue(fmv[0].seatCapacity);
        
        this.standardInsuranceFormGroup.controls.engineType.updateValueAndValidity({emitEvent:false, onlySelf:true});

        this.loader = false;
      } else {
        PNotify.error({
          title: "Error",
          text: "Something went wrong. Please try again.",
        });
      }
    });
  }

  getVehicleType(productType : any){
    this.standardInsuranceService.getVehicleType(productType).subscribe((result) => {
      if(result){
        this.vehicleTypes = result;
        this.loader = false;
      } else {
        PNotify.error({
          title: "Error",
          text: "Something went wrong. Please try again.",
        });
      }
    });
    
  }

  setActiveTab(){

    debugger
    this.standardInsuranceFormGroup.controls.inceptionDate.setValue(this.datepipe.transform(this.standardInsuranceFormGroup.controls.inceptionDate.value, 'yyyy-MM-dd'));
    this.standardInsuranceFormGroup.controls.expiryDate.setValue(this.datepipe.transform(this.standardInsuranceFormGroup.controls.expiryDate.value, 'yyyy-MM-dd'));
    //console.log(this.standardInsuranceFormGroup.value);
    if(this.activeTab == 1){
       if(this.standardInsuranceFormGroup.invalid){
        PNotify.error({
          title: "Error",
          text: "All fields are required to proceed to next step.",
        });
      }else{
        this.activeTab++;
        this.loader = false;
      }
    }else if(this.activeTab == 2){

      var hasError : boolean  = false;
      if(hasError){
        PNotify.error({
          title: "Request denied",
          text: "Please fill up all the required fields.",
        });
      }else{

      }

     
    }
  }

  setActiveTabPrev(){
    if(this.activeTab == 1){
      PNotify.error({
        title: "Error",
        text: "Action Denied.",
      });
    }else{
      this.activeTab--;
    }
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  codeStatus : boolean = false;
  code : any;
  
  onCodeCompleted(code: string) {
    this.code = code;
    this.codeStatus = code.length == 6;
  }

  
  processPayment(){
    this.loader = true;
    //console.log(this.standardInsuranceFormGroup.value);
    this.standardInsuranceFormGroup.controls.inceptionDate.setValue(this.datepipe.transform(this.standardInsuranceFormGroup.controls.inceptionDate.value, 'yyyy-MM-dd'));
    this.standardInsuranceFormGroup.controls.expiryDate.setValue(this.datepipe.transform(this.standardInsuranceFormGroup.controls.expiryDate.value, 'yyyy-MM-dd'));
    this.standardInsuranceService.processStandardInsurance(this.standardInsuranceFormGroup.value,this.code)
      .subscribe((result) => {
        PNotify.success({
          title: "Transaction Success",
          text: "Kindly wait a few minutes for the COC File.",
        });
        this.loader = false;
        this.code = "";
        this.codeStatus=false;
        this.closeModal.emit();
      
    }, error => {
      PNotify.error({
        title: "Failed",
        text: error.error.reason,
      });
      this.loader = false;
      this.code = "";
      this.codeStatus = false;
    });
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  totalAmount(fee:any,amount:any){
    try  {
    return this.makeMoney(Number(fee) + Number(amount));
    } catch(e){
      return "0.00";
    }
  }

  selectOption(option: any) {
    this.standardInsuranceFormGroup.controls.address2.setValue(option.area);
    this.filteredOptions = [];
  }

  filteredOptions: string[] = [];
  options: any[] = [
    {"area": "Abra Bangued", "zip": "2800"},
  {"area": "Abra Boliney", "zip": "2815"},
  {"area": "Abra Bucay", "zip": "2805"},
  {"area": "Abra Bucloc", "zip": "2817"},
  {"area": "Abra Daguioman", "zip": "2816"},
  {"area": "Abra Danglas", "zip": "2825"},
  {"area": "Abra Dolores", "zip": "2801"},
  {"area": "Abra La Paz", "zip": "2826"},
  {"area": "Abra Lacub", "zip": "2821"},
  {"area": "Abra Lagangilang", "zip": "2802"},
  {"area": "Abra Lagayan", "zip": "2824"},
  {"area": "Abra Langiden", "zip": "2807"},
  {"area": "Abra Licuan (Baay)", "zip": "2819"},
  {"area": "Abra Luba", "zip": "2813"},
  {"area": "Abra Malibcong", "zip": "2820"},
  {"area": "Abra Manabo", "zip": "2810"},
  {"area": "Abra Pe?arrubia", "zip": "2804"},
  {"area": "ABRA PENARRUBIA", "zip": "2804"},
  {"area": "Abra Pidigan", "zip": "2806"},
  {"area": "Abra Pilar", "zip": "2812"},
  {"area": "Abra Sallapadan", "zip": "2818"},
  {"area": "Abra San Isidro", "zip": "2809"},
  {"area": "Abra San Juan", "zip": "2823"},
  {"area": "Abra San Quintin", "zip": "2808"},
  {"area": "Abra Tayum", "zip": "2803"},
  {"area": "Abra Tineg", "zip": "2822"},
  {"area": "Abra Tubo", "zip": "2814"},
  {"area": "Abra Villaviciosa", "zip": "2811"},
  {"area": "Agusan del Norte Buenavista", "zip": "8601"},
  {"area": "Agusan del Norte Butuan City", "zip": "8600"},
  {"area": "Agusan del Norte Cabadbaran City", "zip": "8605"},
  {"area": "Agusan del Norte Carmen", "zip": "8603"},
  {"area": "Agusan del Norte Jabonga", "zip": "8607"},
  {"area": "Agusan del Norte Kitcharao", "zip": "8609"},
  {"area": "Agusan del Norte Las Nieves", "zip": "8610"},
  {"area": "Agusan del Norte Magallanes", "zip": "8604"},
  {"area": "Agusan del Norte Nasipit", "zip": "8602"},
  {"area": "Agusan del Norte Remedios T. Romualdez", "zip": "8611"},
  {"area": "Agusan del Norte Santiago", "zip": "8608"},
  {"area": "Agusan del Norte Tubay", "zip": "8606"},
  {"area": "Agusan del Sur Bayugan", "zip": "8502"},
  {"area": "Agusan del Sur Bunawan", "zip": "8506"},
  {"area": "Agusan del Sur Esperanza", "zip": "8513"},
  {"area": "Agusan del Sur La Paz", "zip": "8508"},
  {"area": "Agusan del Sur Loreto", "zip": "8507"},
  {"area": "Agusan del Sur Prosperidad", "zip": "8500"},
  {"area": "Agusan del Sur Rosario", "zip": "8504"},
  {"area": "Agusan del Sur San Francisco", "zip": "8501"},
  {"area": "Agusan del Sur San Luis", "zip": "8511"},
  {"area": "Agusan del Sur Santa Josefa", "zip": "8512"},
  {"area": "Agusan del Sur Sibagat", "zip": "8503"},
  {"area": "Agusan del Sur Talacogon", "zip": "8510"},
  {"area": "Agusan del Sur Trento", "zip": "8505"},
  {"area": "Agusan del Sur Veruela", "zip": "8509"},
  {"area": "Aklan Altavas", "zip": "5616"},
  {"area": "Aklan Balete", "zip": "5614"},
  {"area": "Aklan Banga", "zip": "5601"},
  {"area": "Aklan Batan", "zip": "5615"},
  {"area": "Aklan Buruanga", "zip": "5609"},
  {"area": "Aklan Ibajay", "zip": "5613"},
  {"area": "Aklan Kalibo", "zip": "5600"},
  {"area": "Aklan Lezo", "zip": "5605"},
  {"area": "Aklan Libacao", "zip": "5602"},
  {"area": "Aklan Madalag", "zip": "5603"},
  {"area": "Aklan Makato", "zip": "5611"},
  {"area": "Aklan Malay", "zip": "5608"},
  {"area": "Aklan Malinao", "zip": "5606"},
  {"area": "Aklan Nabas", "zip": "5607"},
  {"area": "Aklan New Washington", "zip": "5610"},
  {"area": "Aklan Numancia", "zip": "5604"},
  {"area": "Aklan Tangalan", "zip": "5612"},
  {"area": "Albay Bacacay", "zip": "4509"},
  {"area": "Albay Camalig", "zip": "4502"},
  {"area": "Albay Daraga (Locsin)", "zip": "4501"},
  {"area": "Albay Guinobatan", "zip": "4503"},
  {"area": "Albay Jovellar", "zip": "4515"},
  {"area": "Albay Legazpi City", "zip": "4500"},
  {"area": "Albay Libon", "zip": "4507"},
  {"area": "Albay Ligao City", "zip": "4504"},
  {"area": "Albay Malilipot", "zip": "4510"},
  {"area": "Albay Malinao", "zip": "4512"},
  {"area": "Albay Manito", "zip": "4514"},
  {"area": "Albay Oas", "zip": "4505"},
  {"area": "Albay Pio Duran (Malacbalac)", "zip": "4516"},
  {"area": "Albay Polangui", "zip": "4506"},
  {"area": "Albay Rapu-Rapu", "zip": "4517"},
  {"area": "Albay Santo Domingo", "zip": "4508"},
  {"area": "Albay Tabaco City", "zip": "4511"},
  {"area": "Albay Tiwi", "zip": "4513"},
  {"area": "Antique Anini-y", "zip": "5717"},
  {"area": "Antique Barbaza", "zip": "5706"},
  {"area": "Antique Belison", "zip": "5701"},
  {"area": "Antique Bugasong", "zip": "5704"},
  {"area": "Antique Caluya", "zip": "5711"},
  {"area": "Antique Culasi", "zip": "5708"},
  {"area": "Antique Hamtic", "zip": "5715"},
  {"area": "Antique Laua-an", "zip": "5705"},
  {"area": "Antique Libertad", "zip": "5710"},
  {"area": "Antique Pandan", "zip": "5712"},
  {"area": "Antique Patnongon", "zip": "5702"},
  {"area": "Antique San Jose", "zip": "5700"},
  {"area": "Antique San Remigio", "zip": "5714"},
  {"area": "Antique Sebaste", "zip": "5709"},
  {"area": "Antique Sibalom", "zip": "5713"},
  {"area": "Antique Tibiao", "zip": "5707"},
  {"area": "Antique Tobias Fornier (Dao)", "zip": "5716"},
  {"area": "Antique Valderrama", "zip": "5703"},
  {"area": "Apayao Calanasan (Bayag)", "zip": "3814"},
  {"area": "Apayao Conner", "zip": "3807"},
  {"area": "Apayao Flora", "zip": "3810"},
  {"area": "Apayao Kabugao", "zip": "3809"},
  {"area": "Apayao Luna", "zip": "3813"},
  {"area": "Apayao Pudtol", "zip": "3812"},
  {"area": "Apayao Santa Marcela", "zip": "3811"},
  {"area": "Aurora Baler", "zip": "3200"},
  {"area": "Aurora Casiguran", "zip": "3204"},
  {"area": "Aurora Dilasag", "zip": "3205"},
  {"area": "Aurora Dinalungan", "zip": "3206"},
  {"area": "Aurora Dingalan", "zip": "3207"},
  {"area": "Aurora Dipaculao", "zip": "3203"},
  {"area": "Aurora Maria Aurora", "zip": "3202"},
  {"area": "Aurora San Luis", "zip": "3201"},
  {"area": "Basilan Froilie", "zip": "7308"},
  {"area": "Basilan Isabela City", "zip": "7300"},
  {"area": "Basilan Lamitan City", "zip": "7302"},
  {"area": "Basilan Lantawan", "zip": "7301"},
  {"area": "Basilan Maluso", "zip": "7303"},
  {"area": "Basilan Sumisip", "zip": "7305"},
  {"area": "Basilan Tipo-Tipo", "zip": "7304"},
  {"area": "Basilan Tuburan", "zip": "7306"},
  {"area": "Bataan Abucay", "zip": "2114"},
  {"area": "Bataan Bagac", "zip": "2107"},
  {"area": "Bataan Balanga City", "zip": "2100"},
  {"area": "Bataan Dinalupihan", "zip": "2110"},
  {"area": "Bataan Hermosa", "zip": "2111"},
  {"area": "Bataan Lamao", "zip": "2104"},
  {"area": "Bataan Limay", "zip": "2103"},
  {"area": "Bataan Mariveles", "zip": "2105"},
  {"area": "Bataan Mariveles (Bataan Export Processing Zone)", "zip": "2106"},
  {"area": "Bataan Morong", "zip": "2108"},
  {"area": "Bataan Morong (Refugee Processing Center)", "zip": "2109"},
  {"area": "Bataan Orani", "zip": "2112"},
  {"area": "Bataan Orion", "zip": "2102"},
  {"area": "Bataan Pilar", "zip": "2101"},
  {"area": "Bataan Samal", "zip": "2113"},
  {"area": "Batanes Basco", "zip": "3900"},
  {"area": "Batanes Itbayat", "zip": "3905"},
  {"area": "Batanes Ivana", "zip": "3902"},
  {"area": "Batanes Mahatao", "zip": "3901"},
  {"area": "Batanes Sabtang", "zip": "3904"},
  {"area": "Batanes Uyugan", "zip": "3903"},
  {"area": "Batangas Agoncillo", "zip": "4211"},
  {"area": "Batangas Alitagtag", "zip": "4205"},
  {"area": "Batangas Balayan", "zip": "4213"},
  {"area": "Batangas Balete", "zip": "4219"},
  {"area": "Batangas Batangas City", "zip": "4200"},
  {"area": "Batangas Bauan", "zip": "4201"},
  {"area": "Batangas Calaca", "zip": "4212"},
  {"area": "Batangas Calatagan, incl. Punta Baluarte", "zip": "4215"},
  {"area": "Batangas Cuenca", "zip": "4222"},
  {"area": "Batangas Fernando Airbase", "zip": "4218"},
  {"area": "Batangas Ibaan", "zip": "4230"},
  {"area": "Batangas Laurel, incl. Diokno Highway", "zip": "4221"},
  {"area": "Batangas Lemery", "zip": "4209"},
  {"area": "Batangas Lian, incl. Matabungkay", "zip": "4216"},
  {"area": "Batangas Lipa City", "zip": "4217"},
  {"area": "Batangas Lobo, incl. Laiya", "zip": "4229"},
  {"area": "Batangas Mabini", "zip": "4202"},
  {"area": "Batangas Malvar", "zip": "4233"},
  {"area": "Batangas Mataas na Kahoy", "zip": "4223"},
  {"area": "Batangas Nasugbu, incl. Punta Fuego and Calaruega", "zip": "4231"},
  {"area": "Batangas Padre Garcia", "zip": "4224"},
  {"area": "Batangas Rosario", "zip": "4225"},
  {"area": "Batangas San Jose", "zip": "4227"},
  {"area": "Batangas San Juan", "zip": "4226"},
  {"area": "Batangas San Luis", "zip": "4210"},
  {"area": "Batangas San Nicolas", "zip": "4207"},
  {"area": "Batangas San Pascual", "zip": "4204"},
  {"area": "Batangas Santa Teresita", "zip": "4206"},
  {"area": "Batangas Santo Tomas", "zip": "4234"},
  {"area": "Batangas Taal", "zip": "4208"},
  {"area": "Batangas Talisay", "zip": "4220"},
  {"area": "Batangas Tanauan City", "zip": "4232"},
  {"area": "Batangas Taysan", "zip": "4228"},
  {"area": "Batangas Tingloy", "zip": "4203"},
  {"area": "Batangas Tuy", "zip": "4214"},
  {"area": "Benguet Atok", "zip": "2612"},
  {"area": "Benguet Baguio City", "zip": "2600"},
  {"area": "Benguet Bakun", "zip": "2610"},
  {"area": "Benguet Bokod", "zip": "2605"},
  {"area": "Benguet Buguias", "zip": "2607"},
  {"area": "Benguet Itogon", "zip": "2604"},
  {"area": "Benguet Kabayan", "zip": "2606"},
  {"area": "Benguet Kapangan", "zip": "2613"},
  {"area": "Benguet Kibungan", "zip": "2611"},
  {"area": "Benguet La Trinidad, including Pico", "zip": "2601"},
  {"area": "Benguet Lepanto, Mankayan", "zip": "2609"},
  {"area": "Benguet Mankayan", "zip": "2608"},
  {"area": "Benguet Philippine Military Academy", "zip": "2602"},
  {"area": "Benguet Sablan", "zip": "2614"},
  {"area": "Benguet Tuba, including Marcos Highway and Kennon Road", "zip": "2603"},
  {"area": "Benguet Tublay", "zip": "2615"},
  {"area": "Biliran Almeria", "zip": "6544"},
  {"area": "Biliran Biliran", "zip": "6549"},
  {"area": "Biliran Cabucgayan", "zip": "6550"},
  {"area": "Biliran Caibiran", "zip": "6548"},
  {"area": "Biliran Culaba", "zip": "6547"},
  {"area": "Biliran Kawayan", "zip": "6545"},
  {"area": "Biliran Maripipi", "zip": "6546"},
  {"area": "Biliran Naval", "zip": "6543"},
  {"area": "Bohol Alburquerque", "zip": "6302"},
  {"area": "Bohol Alicia", "zip": "6314"},
  {"area": "Bohol Anda", "zip": "6311"},
  {"area": "Bohol Antequera", "zip": "6335"},
  {"area": "Bohol Baclayon", "zip": "6301"},
  {"area": "Bohol Balilihan", "zip": "6342"},
  {"area": "Bohol Batuan", "zip": "6318"},
  {"area": "Bohol Bien Unido", "zip": "6326"},
  {"area": "Bohol Bilar", "zip": "6317"},
  {"area": "Bohol Buenavista", "zip": "6333"},
  {"area": "Bohol Calape", "zip": "6328"},
  {"area": "Bohol Candijay", "zip": "6312"},
  {"area": "Bohol Carmen", "zip": "6319"},
  {"area": "Bohol Catigbian", "zip": "6343"},
  {"area": "Bohol Clarin", "zip": "6330"},
  {"area": "Bohol Corella", "zip": "6337"},
  {"area": "Bohol Cortes", "zip": "6341"},
  {"area": "Bohol Dagohoy", "zip": "6322"},
  {"area": "Bohol Danao", "zip": "6344"},
  {"area": "Bohol Dauis", "zip": "6339"},
  {"area": "Bohol Dimiao", "zip": "6305"},
  {"area": "Bohol Duero", "zip": "6309"},
  {"area": "Bohol Garcia Hernandez", "zip": "6307"},
  {"area": "Bohol Guindulman", "zip": "6310"},
  {"area": "Bohol Inabanga", "zip": "6332"},
  {"area": "Bohol Jagna", "zip": "6308"},
  {"area": "Bohol Jetafe", "zip": "6334"},
  {"area": "Bohol Lila", "zip": "6304"},
  {"area": "Bohol Loay", "zip": "6303"},
  {"area": "Bohol Loay", "zip": "6202"},
  {"area": "Bohol Loboc", "zip": "6316"},
  {"area": "Bohol Loon", "zip": "6327"},
  {"area": "Bohol Mabini", "zip": "6313"},
  {"area": "Bohol Maribojoc", "zip": "6336"},
  {"area": "Bohol Panglao", "zip": "6340"},
  {"area": "Bohol Pilar", "zip": "6321"},
  {"area": "Bohol Pres. Carlos P. Garcia (Pitogo)", "zip": "6346"},
  {"area": "Bohol Sagbayan", "zip": "6331"},
  {"area": "Bohol San Isidro", "zip": "6345"},
  {"area": "Bohol San Miguel", "zip": "6323"},
  {"area": "Bohol Sevilla", "zip": "6347"},
  {"area": "Bohol Sierra Bullones", "zip": "6320"},
  {"area": "Bohol Sikatuna", "zip": "6338"},
  {"area": "Bohol Tagbilaran City", "zip": "6300"},
  {"area": "Bohol Talibon", "zip": "6325"},
  {"area": "Bohol Trinidad", "zip": "6324"},
  {"area": "Bohol Tubigon", "zip": "6329"},
  {"area": "Bohol Ubay", "zip": "6315"},
  {"area": "Bohol Valencia", "zip": "6306"},
  {"area": "Bukidnon Baungon", "zip": "8707"},
  {"area": "Bukidnon Cabanglasan", "zip": "8723"},
  {"area": "Bukidnon Damulog", "zip": "8721"},
  {"area": "Bukidnon Dangcagan", "zip": "8719"},
  {"area": "Bukidnon Don Carlos", "zip": "8712"},
  {"area": "Bukidnon Impasugong", "zip": "8702"},
  {"area": "Bukidnon Kadingilan", "zip": "8713"},
  {"area": "Bukidnon Kalilangan", "zip": "8718"},
  {"area": "Bukidnon Kibawe", "zip": "8720"},
  {"area": "Bukidnon Kitaotao", "zip": "8716"},
  {"area": "Bukidnon Lantapan", "zip": "8722"},
  {"area": "Bukidnon Libona", "zip": "8706"},
  {"area": "Bukidnon Malaybalay City", "zip": "8700"},
  {"area": "Bukidnon Malitbog", "zip": "8704"},
  {"area": "Bukidnon Manolo Fortich", "zip": "8703"},
  {"area": "Bukidnon Maramag", "zip": "8714"},
  {"area": "Bukidnon Musuan", "zip": "8710"},
  {"area": "Bukidnon Pangantucan", "zip": "8717"},
  {"area": "Bukidnon Phillips", "zip": "8705"},
  {"area": "Bukidnon Quezon", "zip": "8715"},
  {"area": "Bukidnon San Fernando", "zip": "8711"},
  {"area": "Bukidnon Sumilao", "zip": "8701"},
  {"area": "Bukidnon Talakag", "zip": "8708"},
  {"area": "Bukidnon Valencia City", "zip": "8709"},
  {"area": "Bulacan Angat", "zip": "3012"},
  {"area": "Bulacan Balagtas (Bigaa)", "zip": "3016"},
  {"area": "Bulacan Baliuag", "zip": "3006"},
  {"area": "Bulacan Bocaue", "zip": "3018"},
  {"area": "Bulacan Bulacan", "zip": "3017"},
  {"area": "Bulacan Bustos", "zip": "3007"},
  {"area": "Bulacan Calumpit", "zip": "3003"},
  {"area": "Bulacan Dona Remedios Trinidad", "zip": "3009"},
  {"area": "Bulacan Guiguinto", "zip": "3015"},
  {"area": "Bulacan Hagonoy", "zip": "3002"},
  {"area": "Bulacan Malolos City", "zip": "3000"},
  {"area": "Bulacan Marilao", "zip": "3019"},
  {"area": "Bulacan Meycauayan City", "zip": "3020"},
  {"area": "Bulacan Norzagaray", "zip": "3013"},
  {"area": "Bulacan Obando", "zip": "3021"},
  {"area": "Bulacan Pandi", "zip": "3014"},
  {"area": "Bulacan Paombong", "zip": "3001"},
  {"area": "Bulacan Plaridel", "zip": "3004"},
  {"area": "Bulacan Pulilan", "zip": "3005"},
  {"area": "Bulacan San Ildefonso", "zip": "3010"},
  {"area": "Bulacan San Jose del Monte City", "zip": "3023"},
  {"area": "Bulacan San Miguel", "zip": "3011"},
  {"area": "Bulacan San Rafael", "zip": "3008"},
  {"area": "Bulacan Santa Maria", "zip": "3022"},
  {"area": "Bulacan Sapang Palay, San Jose del Monte City", "zip": "3024"},
  {"area": "Cagayan Abulug", "zip": "3517"},
  {"area": "Cagayan Alcala", "zip": "3507"},
  {"area": "Cagayan Allacapan", "zip": "3523"},
  {"area": "Cagayan Amulung", "zip": "3505"},
  {"area": "Cagayan Aparri", "zip": "3515"},
  {"area": "Cagayan Baggao", "zip": "3506"},
  {"area": "Cagayan Ballesteros", "zip": "3516"},
  {"area": "Cagayan Buguey", "zip": "3511"},
  {"area": "Cagayan Calayan", "zip": "3520"},
  {"area": "Cagayan Camalaniugan", "zip": "3510"},
  {"area": "Cagayan Claveria", "zip": "3519"},
  {"area": "Cagayan Enrile", "zip": "3501"},
  {"area": "Cagayan Gattaran", "zip": "3508"},
  {"area": "Cagayan Gonzaga", "zip": "3513"},
  {"area": "Cagayan Iguig", "zip": "3504"},
  {"area": "Cagayan Lal-Lo", "zip": "3509"},
  {"area": "Cagayan Lasam", "zip": "3524"},
  {"area": "Cagayan Pamplona", "zip": "3522"},
  {"area": "Cagayan Pe?ablanca", "zip": "3502"},
  {"area": "CAGAYAN PENABLANCA", "zip": "3502"},
  {"area": "Cagayan Piat", "zip": "3527"},
  {"area": "Cagayan Rizal", "zip": "3526"},
  {"area": "Cagayan Sanchez-Mira", "zip": "3518"},
  {"area": "Cagayan Santa Ana", "zip": "3514"},
  {"area": "Cagayan Santa Praxedes", "zip": "3521"},
  {"area": "Cagayan Santa Teresita", "zip": "3512"},
  {"area": "Cagayan Santo Ni?o", "zip": "3525"},
  {"area": "CAGAYAN SANTO NINO", "zip": "3525"},
  {"area": "Cagayan Solana", "zip": "3503"},
  {"area": "Cagayan Tuao", "zip": "3528"},
  {"area": "Cagayan Tuguegarao City", "zip": "3500"},
  {"area": "Caloocan City Amparo Subdivision", "zip": "1425"},
  {"area": "Caloocan City Baesa", "zip": "1401"},
  {"area": "Caloocan City Bagong Silang", "zip": "1428"},
  {"area": "Caloocan City Bagumbong/Pag-asa", "zip": "1421"},
  {"area": "Caloocan City Bankers Village", "zip": "1426"},
  {"area": "Caloocan City Caloocan City Central Post Office", "zip": "1400"},
  {"area": "Caloocan City Captol Parkland Subdivision", "zip": "1424"},
  {"area": "Caloocan City First Avenue to Seventh Avenue West", "zip": "1405"},
  {"area": "Caloocan City Fish Market (shared with Navotas)", "zip": "1411"},
  {"area": "Caloocan City Grace Park East", "zip": "1403"},
  {"area": "Caloocan City Grace Park West", "zip": "1406"},
  {"area": "Caloocan City Isla de Cocomo", "zip": "1412"},
  {"area": "Caloocan City Kapitbayahan", "zip": "1413"},
  {"area": "Caloocan City Kaunlaran Village (shared with Malabon City and Navotas)", "zip": "1409"},
  {"area": "Caloocan City Kaybiga/Deparo", "zip": "1420"},
  {"area": "Caloocan City Lilles Ville Subdivision", "zip": "1423"},
  {"area": "Caloocan City Maypajo", "zip": "1410"},
  {"area": "Caloocan City Novaliches North", "zip": "1422"},
  {"area": "Caloocan City San Jose", "zip": "1404"},
  {"area": "Caloocan City Sangandaan", "zip": "1408"},
  {"area": "Caloocan City Santa Quiteria", "zip": "1402"},
  {"area": "Caloocan City Tala Leprosarium and Victory Heights", "zip": "1427"},
  {"area": "Caloocan City University Hills", "zip": "1407"},
  {"area": "Camarines Norte Basud", "zip": "4608"},
  {"area": "Camarines Norte Capalonga", "zip": "4607"},
  {"area": "Camarines Norte Daet", "zip": "4600"},
  {"area": "Camarines Norte Imelda / San Lorenzo Ruiz", "zip": "4610"},
  {"area": "Camarines Norte Jose Panganiban", "zip": "4606"},
  {"area": "Camarines Norte Labo", "zip": "4604"},
  {"area": "Camarines Norte Mercedes", "zip": "4601"},
  {"area": "Camarines Norte Paracale", "zip": "4605"},
  {"area": "Camarines Norte San Vicente", "zip": "4609"},
  {"area": "Camarines Norte Santa Elena", "zip": "4611"},
  {"area": "Camarines Norte Talisay", "zip": "4602"},
  {"area": "Camarines Norte Tulay-na-Lupa", "zip": "4612"},
  {"area": "Camarines Norte Vinzons", "zip": "4603"},
  {"area": "Camarines Sur Baao", "zip": "4432"},
  {"area": "Camarines Sur Balatan", "zip": "4436"},
  {"area": "Camarines Sur Bato", "zip": "4435"},
  {"area": "Camarines Sur Bombon", "zip": "4404"},
  {"area": "Camarines Sur Buhi", "zip": "4433"},
  {"area": "Camarines Sur Bula", "zip": "4430"},
  {"area": "Camarines Sur Cabusao", "zip": "4406"},
  {"area": "Camarines Sur Calabanga", "zip": "4405"},
  {"area": "Camarines Sur Camaligan", "zip": "4401"},
  {"area": "Camarines Sur Canaman", "zip": "4402"},
  {"area": "Camarines Sur Caramoan", "zip": "4429"},
  {"area": "Camarines Sur Del Gallego", "zip": "4411"},
  {"area": "Camarines Sur Gainza", "zip": "4412"},
  {"area": "Camarines Sur Garchitorena", "zip": "4428"},
  {"area": "Camarines Sur Goa", "zip": "4422"},
  {"area": "Camarines Sur Iriga City", "zip": "4431"},
  {"area": "Camarines Sur Lagonoy", "zip": "4425"},
  {"area": "Camarines Sur Libmanan", "zip": "4407"},
  {"area": "Camarines Sur Lupi", "zip": "4409"},
  {"area": "Camarines Sur Magarao", "zip": "4403"},
  {"area": "Camarines Sur Milaor", "zip": "4413"},
  {"area": "Camarines Sur Minalabac", "zip": "4414"},
  {"area": "Camarines Sur Nabua", "zip": "4434"},
  {"area": "Camarines Sur Naga City", "zip": "4400"},
  {"area": "Camarines Sur Ocampo", "zip": "4419"},
  {"area": "Camarines Sur Pamplona", "zip": "4416"},
  {"area": "Camarines Sur Pasacao", "zip": "4417"},
  {"area": "Camarines Sur Pili", "zip": "4418"},
  {"area": "Camarines Sur Presentacion", "zip": "4424"},
  {"area": "Camarines Sur Ragay", "zip": "4410"},
  {"area": "Camarines Sur Sagnay", "zip": "4421"},
  {"area": "Camarines Sur San Fernando", "zip": "4415"},
  {"area": "Camarines Sur San Jose", "zip": "4423"},
  {"area": "Camarines Sur Sipocot", "zip": "4408"},
  {"area": "Camarines Sur Siruma", "zip": "4427"},
  {"area": "Camarines Sur Tigaon", "zip": "4420"},
  {"area": "Camarines Sur Tinambac", "zip": "4426"},
  {"area": "Camiguin Catarman", "zip": "9104"},
  {"area": "Camiguin Guinsiliban", "zip": "9102"},
  {"area": "Camiguin Mahinog", "zip": "9101"},
  {"area": "Camiguin Mambajao", "zip": "9100"},
  {"area": "Camiguin Sagay", "zip": "9103"},
  {"area": "Capiz Cuartero", "zip": "5811"},
  {"area": "Capiz Dao", "zip": "5810"},
  {"area": "Capiz Dumalag", "zip": "5813"},
  {"area": "Capiz Dumarao", "zip": "5812"},
  {"area": "Capiz Ivisan", "zip": "5805"},
  {"area": "Capiz Jamindan", "zip": "5808"},
  {"area": "Capiz Ma-ayon", "zip": "5809"},
  {"area": "Capiz Mambusao", "zip": "5807"},
  {"area": "Capiz Panay", "zip": "5801"},
  {"area": "Capiz Panitan", "zip": "5815"},
  {"area": "Capiz Pilar", "zip": "5804"},
  {"area": "Capiz Pontevedra", "zip": "5802"},
  {"area": "Capiz President Roxas", "zip": "5803"},
  {"area": "Capiz Roxas City", "zip": "5800"},
  {"area": "Capiz Sapian", "zip": "5806"},
  {"area": "Capiz Sigma", "zip": "5816"},
  {"area": "Capiz Tapaz", "zip": "5814"},
  {"area": "Catanduanes Bagamanoc", "zip": "4807"},
  {"area": "Catanduanes Baras", "zip": "4803"},
  {"area": "Catanduanes Bato", "zip": "4801"},
  {"area": "Catanduanes Caramoran", "zip": "4808"},
  {"area": "Catanduanes Gigmoto", "zip": "4804"},
  {"area": "Catanduanes Pandan", "zip": "4809"},
  {"area": "Catanduanes Panganiban", "zip": "4806"},
  {"area": "Catanduanes San Andres", "zip": "4810"},
  {"area": "Catanduanes San Miguel", "zip": "4802"},
  {"area": "Catanduanes Viga", "zip": "4805"},
  {"area": "Catanduanes Virac", "zip": "4800"},
  {"area": "Cavite Alfonso", "zip": "4123"},
  {"area": "Cavite Amadeo", "zip": "4119"},
  {"area": "Cavite Bacoor", "zip": "4102"},
  {"area": "Cavite Bailen", "zip": "4133"},
  {"area": "Cavite Carmona", "zip": "4116"},
  {"area": "Cavite Cavite City", "zip": "4100"},
  {"area": "Cavite Corregidor Island", "zip": "4125"},
  {"area": "Cavite Dasmari?as", "zip": "4114"},
  {"area": "CAVITE DASMARINAS", "zip": "4114"},
  {"area": "Cavite Dasmarinas Resettlement Area", "zip": "4115"},
  {"area": "Cavite General Emilio Aguinaldo (Bailen)", "zip": "4124"},
  {"area": "Cavite General Mariano Alvarez", "zip": "4117"},
  {"area": "Cavite General Trias", "zip": "4107"},
  {"area": "Cavite Imus", "zip": "4103"},
  {"area": "Cavite Indang", "zip": "4122"},
  {"area": "Cavite Kawit", "zip": "4104"},
  {"area": "Cavite Magallanes", "zip": "4113"},
  {"area": "Cavite Maragondon", "zip": "4112"},
  {"area": "Cavite Mendez (Mendez-Nu?ez)", "zip": "4121"},
  {"area": "Cavite Naic", "zip": "4110"},
  {"area": "Cavite Noveleta", "zip": "4105"},
  {"area": "Cavite Rosario", "zip": "4106"},
  {"area": "Cavite Sangley Point Naval Base", "zip": "4101"},
  {"area": "Cavite Silang", "zip": "4118"},
  {"area": "Cavite Tagaytay City", "zip": "4120"},
  {"area": "Cavite Tanza", "zip": "4108"},
  {"area": "Cavite Ternate incl. Caylabne Bay & Puerto Azul", "zip": "4111"},
  {"area": "Cavite Trece Martires City", "zip": "4109"},
  {"area": "Cebu Alcantara", "zip": "6055"},
  {"area": "CEBU ALCANTARA", "zip": "6033"},
  {"area": "CEBU ALCOY", "zip": "6023"},
  {"area": "Cebu Alcoy", "zip": "6066"},
  {"area": "Cebu Alegria", "zip": "6077"},
  {"area": "CEBU ALEGRIA", "zip": "6030"},
  {"area": "CEBU ALOGUINSAN", "zip": "6040"},
  {"area": "Cebu Aloguinsan", "zip": "6088"},
  {"area": "Cebu Argao", "zip": "6099"},
  {"area": "CEBU ARGAO", "zip": "6021"},
  {"area": "Cebu Asturias", "zip": "6010"},
  {"area": "Cebu Badian", "zip": "6011"},
  {"area": "CEBU BADIAN", "zip": "6031"},
  {"area": "Cebu Balamban", "zip": "6041"},
  {"area": "Cebu Bantayan", "zip": "6052"},
  {"area": "Cebu Barili", "zip": "6036"},
  {"area": "CEBU BOGO", "zip": "6010"},
  {"area": "Cebu Bogo City", "zip": "6010"},
  {"area": "Cebu Boljoon", "zip": "6024"},
  {"area": "Cebu Borbon", "zip": "6008"},
  {"area": "Cebu Carcar City", "zip": "6019"},
  {"area": "Cebu Carmen", "zip": "6005"},
  {"area": "Cebu Catmon", "zip": "6006"},
  {"area": "Cebu Cebu City", "zip": "6000"},
  {"area": "Cebu Compostela", "zip": "6003"},
  {"area": "Cebu Consolacion", "zip": "6001"},
  {"area": "Cebu Cordova", "zip": "6017"},
  {"area": "Cebu Daanbantayan", "zip": "6013"},
  {"area": "Cebu Dalaguete", "zip": "6022"},
  {"area": "Cebu Danao City", "zip": "6004"},
  {"area": "Cebu Dumanjug", "zip": "6035"},
  {"area": "Cebu Ginatilan", "zip": "6028"},
  {"area": "Cebu Lapu-Lapu City (Opon)", "zip": "6015"},
  {"area": "Cebu Liloan", "zip": "6002"},
  {"area": "Cebu Mactan Airport", "zip": "6016"},
  {"area": "Cebu Madridejos", "zip": "6053"},
  {"area": "Cebu Malabuyoc", "zip": "6029"},
  {"area": "Cebu Mandaue City", "zip": "6014"},
  {"area": "Cebu Medellin", "zip": "6012"},
  {"area": "Cebu Minglanilla", "zip": "6046"},
  {"area": "Cebu Moalboal", "zip": "6032"},
  {"area": "Cebu Naga", "zip": "6037"},
  {"area": "Cebu Oslob", "zip": "6025"},
  {"area": "Cebu Pilar", "zip": "6048"},
  {"area": "Cebu Pinamungahan", "zip": "6039"},
  {"area": "Cebu Poro", "zip": "6049"},
  {"area": "Cebu Ronda", "zip": "6034"},
  {"area": "Cebu Samboan", "zip": "6027"},
  {"area": "Cebu San Fernando", "zip": "6018"},
  {"area": "Cebu San Francisco", "zip": "6050"},
  {"area": "Cebu San Remigio", "zip": "6011"},
  {"area": "Cebu Santa Fe", "zip": "6047"},
  {"area": "Cebu Santander", "zip": "6026"},
  {"area": "Cebu Sibonga", "zip": "6020"},
  {"area": "Cebu Sogod", "zip": "6007"},
  {"area": "Cebu Tabogon", "zip": "6009"},
  {"area": "Cebu Tabuelan", "zip": "6044"},
  {"area": "Cebu Talisay City", "zip": "6045"},
  {"area": "Cebu Toledo City", "zip": "6038"},
  {"area": "Cebu Tuburan", "zip": "6043"},
  {"area": "Cebu Tudela", "zip": "6051"},
  {"area": "Compostela Valley Compostela", "zip": "8803"},
  {"area": "Compostela Valley Compostela", "zip": "8109"},
  {"area": "Compostela Valley Laak (San Vicente)", "zip": "8103"},
  {"area": "Compostela Valley Laak (San Vicente)", "zip": "8810"},
  {"area": "Compostela Valley Mabini (Do?a Alicia)", "zip": "8115"},
  {"area": "COMPOSTELA VALLEY MABINI (DONA ALICIA)", "zip": "8807"},
  {"area": "Compostela Valley Maco", "zip": "8806"},
  {"area": "Compostela Valley Maco", "zip": "8114"},
  {"area": "Compostela Valley Maragusan (San Mariano)", "zip": "8116"},
  {"area": "Compostela Valley Maragusan (San Mariano)", "zip": "8808"},
  {"area": "Compostela Valley Mawab", "zip": "8802"},
  {"area": "Compostela Valley Mawab", "zip": "8108"},
  {"area": "Compostela Valley Monkayo", "zip": "8111"},
  {"area": "Compostela Valley Monkayo", "zip": "8805"},
  {"area": "Compostela Valley Montevista", "zip": "8801"},
  {"area": "Compostela Valley Montevista", "zip": "8107"},
  {"area": "Compostela Valley Nabunturan", "zip": "8106"},
  {"area": "Compostela Valley Nabunturan", "zip": "8800"},
  {"area": "Compostela Valley New Bataan", "zip": "8804"},
  {"area": "Compostela Valley New Bataan", "zip": "8110"},
  {"area": "Compostela Valley Pantukan", "zip": "8117"},
  {"area": "Compostela Valley Pantukan", "zip": "8809"},
  {"area": "Cotabato Alamada", "zip": "9413"},
  {"area": "Cotabato Aleosan", "zip": "9415"},
  {"area": "Cotabato Antipas", "zip": "9414"},
  {"area": "Cotabato Arakan", "zip": "9417"},
  {"area": "Cotabato Banisilan", "zip": "9416"},
  {"area": "Cotabato Carmen", "zip": "9408"},
  {"area": "Cotabato Kabacan", "zip": "9407"},
  {"area": "Cotabato Kidapawan City", "zip": "9400"},
  {"area": "Cotabato Libungan", "zip": "9411"},
  {"area": "Cotabato M`Lang", "zip": "9402"},
  {"area": "Cotabato Magpet", "zip": "9404"},
  {"area": "Cotabato Makilala", "zip": "9401"},
  {"area": "Cotabato Matalam", "zip": "9406"},
  {"area": "Cotabato Midsayap", "zip": "9410"},
  {"area": "Cotabato Pigkawayan", "zip": "9412"},
  {"area": "Cotabato Pikit", "zip": "9409"},
  {"area": "Cotabato President Roxas", "zip": "9405"},
  {"area": "Cotabato Tulunan", "zip": "9403"},
  {"area": "Davao del Norte Asuncion", "zip": "8102"},
  {"area": "Davao del Norte Babak", "zip": "8118"},
  {"area": "Davao del Norte Carmen", "zip": "8101"},
  {"area": "Davao del Norte Island Garden City of Samal", "zip": "8119"},
  {"area": "Davao del Norte Kapalong", "zip": "8113"},
  {"area": "Davao del Norte Kaputian", "zip": "8120"},
  {"area": "Davao del Norte New Corella", "zip": "8104"},
  {"area": "Davao del Norte Panabo City", "zip": "8105"},
  {"area": "DAVAO DEL NORTE SAN MARIANO", "zip": "8116"},
  {"area": "Davao del Norte Santo Tomas", "zip": "8112"},
  {"area": "Davao del Norte Tagum City", "zip": "8100"},
  {"area": "Davao del Sur Ateneo de Davao University", "zip": "8016"},
  {"area": "Davao del Sur Bansalan", "zip": "8005"},
  {"area": "Davao del Sur Davao City", "zip": "8000"},
  {"area": "Davao del Sur Digos City", "zip": "8002"},
  {"area": "Davao del Sur Don Marcelino", "zip": "8013"},
  {"area": "Davao del Sur Hagonoy", "zip": "8006"},
  {"area": "Davao del Sur Jose Abad Santos", "zip": "8014"},
  {"area": "Davao del Sur Kiblawan", "zip": "8008"},
  {"area": "Davao del Sur Magsaysay", "zip": "8004"},
  {"area": "Davao del Sur Malalag", "zip": "8010"},
  {"area": "Davao del Sur Malita", "zip": "8012"},
  {"area": "Davao del Sur Matanao", "zip": "8003"},
  {"area": "Davao del Sur Mati", "zip": "8200"},
  {"area": "Davao del Sur Padada", "zip": "8007"},
  {"area": "Davao del Sur San Isidro", "zip": "8209"},
  {"area": "Davao del Sur Santa Cruz", "zip": "8001"},
  {"area": "Davao del Sur Santa Maria", "zip": "8011"},
  {"area": "Davao del Sur Sarangani", "zip": "8015"},
  {"area": "Davao del Sur Sulop", "zip": "8009"},
  {"area": "Davao del Sur Tarragona", "zip": "8201"},
  {"area": "DAVAO ORIENTAL BANAYBANAY", "zip": "8208"},
  {"area": "DAVAO ORIENTAL BANGANGA", "zip": "8204"},
  {"area": "DAVAO ORIENTAL BOSTON", "zip": "8206"},
  {"area": "DAVAO ORIENTAL CARAGA", "zip": "8203"},
  {"area": "DAVAO ORIENTAL CATEEL", "zip": "8205"},
  {"area": "DAVAO ORIENTAL GOV. GENEROSO", "zip": "8210"},
  {"area": "DAVAO ORIENTAL LOPON", "zip": "8207"},
  {"area": "DAVAO ORIENTAL MANAY", "zip": "8202"},
  {"area": "DAVAO ORIENTAL MATI", "zip": "8200"},
  {"area": "DAVAO ORIENTAL SAN ISIDRO", "zip": "8209"},
  {"area": "DAVAO ORIENTAL TARRAGONA", "zip": "8201"},
  {"area": "Dinagat Islands Basilisa (Rizal)", "zip": "8413"},
  {"area": "Dinagat Islands Cagdianao", "zip": "8411"},
  {"area": "Dinagat Islands Dinagat", "zip": "8412"},
  {"area": "Dinagat Islands Libjo (Albor)", "zip": "8414"},
  {"area": "Dinagat Islands Loreto", "zip": "8415"},
  {"area": "Dinagat Islands San Jose", "zip": "8427"},
  {"area": "Dinagat Islands Tubajon", "zip": "8426"},
  {"area": "Eastern Samar Arteche", "zip": "6822"},
  {"area": "Eastern Samar Balangiga", "zip": "6812"},
  {"area": "Eastern Samar Balangkayan", "zip": "6801"},
  {"area": "Eastern Samar Borongan", "zip": "6800"},
  {"area": "Eastern Samar Can-avid", "zip": "6806"},
  {"area": "Eastern Samar Dolores", "zip": "6817"},
  {"area": "Eastern Samar General MacArthur", "zip": "6805"},
  {"area": "Eastern Samar Giporlos", "zip": "6811"},
  {"area": "Eastern Samar Guiuan", "zip": "6809"},
  {"area": "Eastern Samar Hernani", "zip": "6804"},
  {"area": "Eastern Samar Jipapad", "zip": "6819"},
  {"area": "Eastern Samar Lawaan", "zip": "6813"},
  {"area": "Eastern Samar Llorente", "zip": "6803"},
  {"area": "Eastern Samar Maslog", "zip": "6820"},
  {"area": "Eastern Samar Maydolong", "zip": "6802"},
  {"area": "Eastern Samar Mercedes", "zip": "6808"},
  {"area": "Eastern Samar Oras", "zip": "6818"},
  {"area": "Eastern Samar Quinapondan", "zip": "6810"},
  {"area": "Eastern Samar Salcedo", "zip": "6807"},
  {"area": "Eastern Samar San Julian", "zip": "6814"},
  {"area": "Eastern Samar San Policarpo", "zip": "6821"},
  {"area": "Eastern Samar Sulat", "zip": "6815"},
  {"area": "Eastern Samar Taft", "zip": "6816"},
  {"area": "Guimaras Buenavista", "zip": "5044"},
  {"area": "Guimaras Jordan", "zip": "5045"},
  {"area": "Guimaras Nueva Valencia", "zip": "5046"},
  {"area": "Guimaras San Lorenzo", "zip": "5047"},
  {"area": "Guimaras Sibunag", "zip": "5048"},
  {"area": "Ifugao Aguinaldo", "zip": "3606"},
  {"area": "Ifugao Alfonso Lista (Potia)", "zip": "3608"},
  {"area": "Ifugao Asipulo", "zip": "3610"},
  {"area": "Ifugao Banaue", "zip": "3601"},
  {"area": "Ifugao Hingyon", "zip": "3607"},
  {"area": "Ifugao Hungduan", "zip": "3603"},
  {"area": "Ifugao Kiangan", "zip": "3604"},
  {"area": "Ifugao Lagawe", "zip": "3600"},
  {"area": "Ifugao Lamut", "zip": "3605"},
  {"area": "Ifugao Mayoyao (Mayaoyao)", "zip": "3602"},
  {"area": "Ifugao Tinoc", "zip": "3609"},
  {"area": "Ilocos Norte Adams", "zip": "2922"},
  {"area": "Ilocos Norte Bacarra", "zip": "2916"},
  {"area": "Ilocos Norte Badoc", "zip": "2904"},
  {"area": "Ilocos Norte Bangui", "zip": "2920"},
  {"area": "Ilocos Norte Banna (Espiritu)", "zip": "2908"},
  {"area": "Ilocos Norte Batac", "zip": "2906"},
  {"area": "Ilocos Norte Burgos", "zip": "2918"},
  {"area": "Ilocos Norte Carasi", "zip": "2911"},
  {"area": "Ilocos Norte Currimao", "zip": "2903"},
  {"area": "Ilocos Norte Dingras", "zip": "2913"},
  {"area": "Ilocos Norte Dumalneg", "zip": "2921"},
  {"area": "Ilocos Norte Laoag City", "zip": "2900"},
  {"area": "Ilocos Norte Marcos", "zip": "2907"},
  {"area": "Ilocos Norte Nueva Era", "zip": "2909"},
  {"area": "Ilocos Norte Pagudpud", "zip": "2919"},
  {"area": "Ilocos Norte Paoay", "zip": "2902"},
  {"area": "Ilocos Norte Pasuquin", "zip": "2917"},
  {"area": "Ilocos Norte Piddig", "zip": "2912"},
  {"area": "Ilocos Norte Pinili", "zip": "2905"},
  {"area": "Ilocos Norte San Nicolas", "zip": "2901"},
  {"area": "Ilocos Norte Sarrat", "zip": "2914"},
  {"area": "Ilocos Norte Solsona", "zip": "2910"},
  {"area": "Ilocos Norte Vintar", "zip": "2915"},
  {"area": "Ilocos Sur Alilem", "zip": "2716"},
  {"area": "Ilocos Sur Banayoyo", "zip": "2708"},
  {"area": "Ilocos Sur Bantay", "zip": "2727"},
  {"area": "Ilocos Sur Burgos", "zip": "2724"},
  {"area": "Ilocos Sur Cabugao", "zip": "2732"},
  {"area": "Ilocos Sur Candon City", "zip": "2710"},
  {"area": "Ilocos Sur Caoayan", "zip": "2702"},
  {"area": "Ilocos Sur Cervantes", "zip": "2718"},
  {"area": "Ilocos Sur Galimuyod", "zip": "2709"},
  {"area": "Ilocos Sur Gregorio del Pilar", "zip": "2720"},
  {"area": "Ilocos Sur Lidlidda", "zip": "2723"},
  {"area": "Ilocos Sur Magsingal", "zip": "2730"},
  {"area": "Ilocos Sur Nagbukel", "zip": "2725"},
  {"area": "Ilocos Sur Narvacan", "zip": "2704"},
  {"area": "Ilocos Sur Quirino (Angkaki)", "zip": "2721"},
  {"area": "Ilocos Sur Salcedo (Baugen)", "zip": "2711"},
  {"area": "Ilocos Sur San Emilio", "zip": "2722"},
  {"area": "Ilocos Sur San Esteban", "zip": "2706"},
  {"area": "Ilocos Sur San Ildefonso", "zip": "2728"},
  {"area": "Ilocos Sur San Juan (Lapog)", "zip": "2731"},
  {"area": "Ilocos Sur San Vicente", "zip": "2726"},
  {"area": "Ilocos Sur Santa", "zip": "2703"},
  {"area": "Ilocos Sur Santa Catalina", "zip": "2701"},
  {"area": "Ilocos Sur Santa Cruz", "zip": "2713"},
  {"area": "Ilocos Sur Santa Lucia", "zip": "2712"},
  {"area": "Ilocos Sur Santa Maria", "zip": "2705"},
  {"area": "Ilocos Sur Santiago", "zip": "2707"},
  {"area": "Ilocos Sur Santo Domingo", "zip": "2729"},
  {"area": "Ilocos Sur Sigay", "zip": "2719"},
  {"area": "Ilocos Sur Sinait", "zip": "2733"},
  {"area": "Ilocos Sur Sugpon", "zip": "2717"},
  {"area": "Ilocos Sur Suyo", "zip": "2715"},
  {"area": "Ilocos Sur Tagudin", "zip": "2714"},
  {"area": "Ilocos Sur Vigan City", "zip": "2700"},
  {"area": "Iloilo Ajuy", "zip": "5012"},
  {"area": "Iloilo Alimodian", "zip": "5028"},
  {"area": "Iloilo Anilao", "zip": "5009"},
  {"area": "Iloilo Badiangan", "zip": "5033"},
  {"area": "Iloilo Balasan", "zip": "5018"},
  {"area": "Iloilo Banate", "zip": "5010"},
  {"area": "Iloilo Barotoc Nuevo", "zip": "5007"},
  {"area": "Iloilo Barotoc Viejo", "zip": "5011"},
  {"area": "Iloilo Batad", "zip": "5016"},
  {"area": "Iloilo Bingawan", "zip": "5041"},
  {"area": "Iloilo Cabatuan", "zip": "5031"},
  {"area": "Iloilo Calinog", "zip": "5040"},
  {"area": "Iloilo Carles", "zip": "5019"},
  {"area": "Iloilo Concepcion", "zip": "5013"},
  {"area": "Iloilo Dingle", "zip": "5035"},
  {"area": "Iloilo Duenas", "zip": "5038"},
  {"area": "Iloilo Dumangas", "zip": "5006"},
  {"area": "Iloilo Estancia", "zip": "5017"},
  {"area": "Iloilo Guimbal", "zip": "5022"},
  {"area": "Iloilo Igbaras", "zip": "5029"},
  {"area": "Iloilo Iloilo City", "zip": "5000"},
  {"area": "Iloilo Janiuay", "zip": "5034"},
  {"area": "Iloilo Lambunao", "zip": "5042"},
  {"area": "Iloilo Leganes", "zip": "5003"},
  {"area": "Iloilo Lemery", "zip": "5043"},
  {"area": "Iloilo Leon", "zip": "5026"},
  {"area": "Iloilo Maasin", "zip": "5030"},
  {"area": "Iloilo Miagao", "zip": "5023"},
  {"area": "Iloilo Mina", "zip": "5032"},
  {"area": "Iloilo New Lucena", "zip": "5005"},
  {"area": "Iloilo Oton", "zip": "5020"},
  {"area": "Iloilo Passi City", "zip": "5037"},
  {"area": "Iloilo Pavia", "zip": "5001"},
  {"area": "Iloilo Pototan", "zip": "5008"},
  {"area": "Iloilo San Dionisio", "zip": "5015"},
  {"area": "Iloilo San Enrique", "zip": "5036"},
  {"area": "Iloilo San Joaquin", "zip": "5024"},
  {"area": "Iloilo San Miguel", "zip": "5025"},
  {"area": "Iloilo San Rafael", "zip": "5039"},
  {"area": "Iloilo Santa Barbara", "zip": "5002"},
  {"area": "Iloilo Sara", "zip": "5014"},
  {"area": "Iloilo Tigbauan", "zip": "5021"},
  {"area": "Iloilo Tubungan", "zip": "5027"},
  {"area": "Iloilo Zarraga", "zip": "5004"},
  {"area": "Isabela Alicia", "zip": "3306"},
  {"area": "Isabela Angadanan", "zip": "3307"},
  {"area": "Isabela Aurora", "zip": "3316"},
  {"area": "Isabela Benito Soliven", "zip": "3331"},
  {"area": "Isabela Burgos", "zip": "3322"},
  {"area": "Isabela Cabagan", "zip": "3328"},
  {"area": "Isabela Cabatuan", "zip": "3315"},
  {"area": "Isabela Cauayan City", "zip": "3305"},
  {"area": "Isabela Cordon", "zip": "3312"},
  {"area": "Isabela Delfin Albano", "zip": "3326"},
  {"area": "Isabela Dinapigui", "zip": "3336"},
  {"area": "Isabela Divilacan", "zip": "3335"},
  {"area": "Isabela Echague", "zip": "3309"},
  {"area": "Isabela Gamu", "zip": "3301"},
  {"area": "Isabela Ilagan", "zip": "3300"},
  {"area": "Isabela Jones", "zip": "3313"},
  {"area": "Isabela Luna", "zip": "3304"},
  {"area": "Isabela Maconacon", "zip": "3333"},
  {"area": "Isabela Mallig", "zip": "3323"},
  {"area": "Isabela Naguillan", "zip": "3302"},
  {"area": "Isabela Palanan", "zip": "3334"},
  {"area": "Isabela Quezon", "zip": "3324"},
  {"area": "Isabela Quirino", "zip": "3321"},
  {"area": "Isabela Ramon", "zip": "3319"},
  {"area": "Isabela Reina Mercedes", "zip": "3303"},
  {"area": "Isabela Roxas", "zip": "3320"},
  {"area": "Isabela San Agustin", "zip": "3314"},
  {"area": "Isabela San Guillermo", "zip": "3308"},
  {"area": "Isabela San Isidro", "zip": "3310"},
  {"area": "Isabela San Manuel (Callang)", "zip": "3317"},
  {"area": "Isabela San Mariano", "zip": "3332"},
  {"area": "Isabela San Mateo", "zip": "3318"},
  {"area": "Isabela San Pablo", "zip": "3329"},
  {"area": "Isabela Santa Maria", "zip": "3330"},
  {"area": "Isabela Santiago City", "zip": "3311"},
  {"area": "Isabela Santo Tomas", "zip": "3327"},
  {"area": "Isabela Tumauini", "zip": "3325"},
  {"area": "Kalinga Balbalan", "zip": "3801"},
  {"area": "Kalinga Liwan (Rizal)", "zip": "3808"},
  {"area": "Kalinga Lubuagan", "zip": "3802"},
  {"area": "Kalinga Pasil", "zip": "3803"},
  {"area": "Kalinga Pinukpuk", "zip": "3806"},
  {"area": "Kalinga Tabuk City", "zip": "3800"},
  {"area": "Kalinga Tanudan", "zip": "3805"},
  {"area": "Kalinga Tinglayan", "zip": "3804"},
  {"area": "KALINGA-APAYAO BALBALAN", "zip": "3801"},
  {"area": "KALINGA-APAYAO CALANASAN (BAYAG)", "zip": "3814"},
  {"area": "KALINGA-APAYAO CONNER", "zip": "3807"},
  {"area": "KALINGA-APAYAO FLORA", "zip": "3810"},
  {"area": "KALINGA-APAYAO KABUGAO", "zip": "3809"},
  {"area": "KALINGA-APAYAO LIWAN (RIZAL)", "zip": "3808"},
  {"area": "KALINGA-APAYAO LUBUAGAN", "zip": "3802"},
  {"area": "KALINGA-APAYAO LUNA", "zip": "3813"},
  {"area": "KALINGA-APAYAO PASIL", "zip": "3803"},
  {"area": "KALINGA-APAYAO PINUKPUK", "zip": "3806"},
  {"area": "KALINGA-APAYAO PUDTOL", "zip": "3812"},
  {"area": "KALINGA-APAYAO SANTA MARCELA", "zip": "3811"},
  {"area": "KALINGA-APAYAO TABUK CITY", "zip": "3800"},
  {"area": "KALINGA-APAYAO TANUDAN", "zip": "3805"},
  {"area": "KALINGA-APAYAO TINGLAYAN", "zip": "3804"},
  {"area": "La Union Agoo", "zip": "2504"},
  {"area": "La Union Aringay", "zip": "2503"},
  {"area": "La Union Bacnotan", "zip": "2515"},
  {"area": "La Union Bagulin", "zip": "2512"},
  {"area": "La Union Balaoan", "zip": "2517"},
  {"area": "La Union Bangar", "zip": "2519"},
  {"area": "La Union Bauang", "zip": "2501"},
  {"area": "La Union Burgos", "zip": "2510"},
  {"area": "La Union Caba", "zip": "2502"},
  {"area": "La Union Damortis", "zip": "2507"},
  {"area": "La Union Luna", "zip": "2518"},
  {"area": "La Union Naguillan", "zip": "2511"},
  {"area": "La Union Pugo", "zip": "2508"},
  {"area": "La Union Rosario", "zip": "2506"},
  {"area": "La Union San Fernando City", "zip": "2500"},
  {"area": "La Union San Gabriel", "zip": "2513"},
  {"area": "La Union San Juan", "zip": "2514"},
  {"area": "La Union Santo Tomas", "zip": "2505"},
  {"area": "La Union Santol", "zip": "2516"},
  {"area": "La Union Sudepen", "zip": "2520"},
  {"area": "La Union Tubao", "zip": "2509"},
  {"area": "Laguna Alaminos", "zip": "4001"},
  {"area": "Laguna Bay", "zip": "4033"},
  {"area": "Laguna Bi?an", "zip": "4024"},
  {"area": "LAGUNA BINAN", "zip": "4024"},
  {"area": "Laguna Botocan, Magdalena", "zip": "4006"},
  {"area": "Laguna Cabuyao", "zip": "4025"},
  {"area": "Laguna Calamba City", "zip": "4027"},
  {"area": "Laguna Calauan", "zip": "4012"},
  {"area": "Laguna Camp Vicente Lim", "zip": "4029"},
  {"area": "Laguna Canlubang, Calamba City", "zip": "4028"},
  {"area": "Laguna Cavinti", "zip": "4013"},
  {"area": "Laguna Famy", "zip": "4021"},
  {"area": "Laguna Kalayaan", "zip": "4015"},
  {"area": "Laguna Liliw", "zip": "4004"},
  {"area": "Laguna Los Ba?os", "zip": "4030"},
  {"area": "LAGUNA LOS BANOS", "zip": "4030"},
  {"area": "Laguna Luisiana", "zip": "4032"},
  {"area": "Laguna Lumban", "zip": "4014"},
  {"area": "Laguna Mabitac", "zip": "4020"},
  {"area": "Laguna Magdalena", "zip": "4007"},
  {"area": "Laguna Majayjay", "zip": "4005"},
  {"area": "Laguna Nagcarlan", "zip": "4002"},
  {"area": "Laguna Paete", "zip": "4016"},
  {"area": "Laguna Pagsanjan", "zip": "4008"},
  {"area": "Laguna Pakil", "zip": "4017"},
  {"area": "Laguna Pangil", "zip": "4018"},
  {"area": "Laguna Pila", "zip": "4010"},
  {"area": "Laguna Rizal", "zip": "4003"},
  {"area": "Laguna San Pablo City", "zip": "4000"},
  {"area": "Laguna San Pedro", "zip": "4023"},
  {"area": "Laguna Santa Cruz", "zip": "4009"},
  {"area": "Laguna Santa Maria", "zip": "4022"},
  {"area": "Laguna Santa Rosa City", "zip": "4026"},
  {"area": "Laguna Siniloan", "zip": "4019"},
  {"area": "Laguna University of the Philippines, Los Ba?os", "zip": "4031"},
  {"area": "LAGUNA UNIVERSITY OF THE PHILIPPINES, LOS BANOS", "zip": "4031"},
  {"area": "Laguna Victoria", "zip": "4011"},
  {"area": "Lanao del Norte Bacolod", "zip": "9205"},
  {"area": "Lanao del Norte Baloi", "zip": "9217"},
  {"area": "Lanao del Norte Baroy", "zip": "9210"},
  {"area": "Lanao del Norte Iligan City", "zip": "9200"},
  {"area": "Lanao del Norte Kapatagan", "zip": "9214"},
  {"area": "Lanao del Norte Karomatan", "zip": "9215"},
  {"area": "Lanao del Norte Kauswagan", "zip": "9202"},
  {"area": "Lanao del Norte Kolambogan", "zip": "9207"},
  {"area": "Lanao del Norte Lala", "zip": "9211"},
  {"area": "Lanao del Norte Linamon", "zip": "9201"},
  {"area": "Lanao del Norte Magsaysay", "zip": "9221"},
  {"area": "Lanao del Norte Maigo", "zip": "9206"},
  {"area": "Lanao del Norte Matungao", "zip": "9203"},
  {"area": "Lanao del Norte Munai", "zip": "9219"},
  {"area": "Lanao del Norte Nunungan", "zip": "9216"},
  {"area": "Lanao del Norte Pantao Ragat", "zip": "9208"},
  {"area": "Lanao del Norte Pantar", "zip": "9218"},
  {"area": "Lanao del Norte Poona Piagapo", "zip": "9204"},
  {"area": "Lanao del Norte Salvador", "zip": "9212"},
  {"area": "Lanao del Norte Sapad", "zip": "9213"},
  {"area": "Lanao del Norte Tagoloan", "zip": "9222"},
  {"area": "Lanao del Norte Tangkal", "zip": "9220"},
  {"area": "Lanao del Norte Tubod", "zip": "9209"},
  {"area": "Lanao del Sur Bacolod Kalawi", "zip": "9316"},
  {"area": "Lanao del Sur Balabagan", "zip": "9302"},
  {"area": "Lanao del Sur Balindong", "zip": "9318"},
  {"area": "Lanao del Sur Bayang", "zip": "9309"},
  {"area": "Lanao del Sur Binidayan", "zip": "9310"},
  {"area": "Lanao del Sur Buadiposo Buntong", "zip": "9714"},
  {"area": "Lanao del Sur Bubong", "zip": "9708"},
  {"area": "Lanao del Sur Bumbaran", "zip": "9320"},
  {"area": "Lanao del Sur Butig", "zip": "9305"},
  {"area": "Lanao del Sur Calanogas", "zip": "9319"},
  {"area": "Lanao del Sur Ganassi", "zip": "9311"},
  {"area": "Lanao del Sur Kapai", "zip": "9709"},
  {"area": "Lanao del Sur Lumba Bayabao", "zip": "9703"},
  {"area": "Lanao del Sur Lumbatan", "zip": "9307"},
  {"area": "Lanao del Sur Lumbayanague", "zip": "9306"},
  {"area": "Lanao del Sur Macadar Andong", "zip": "9308"},
  {"area": "Lanao del Sur Madalum", "zip": "9315"},
  {"area": "Lanao del Sur Madamba", "zip": "9314"},
  {"area": "Lanao del Sur Maguing", "zip": "9715"},
  {"area": "Lanao del Sur Malabang", "zip": "9300"},
  {"area": "Lanao del Sur Marantao", "zip": "9711"},
  {"area": "Lanao del Sur Marawi City", "zip": "9700"},
  {"area": "Lanao del Sur Marogong", "zip": "9303"},
  {"area": "Lanao del Sur Masui", "zip": "9706"},
  {"area": "Lanao del Sur Mulondo", "zip": "9702"},
  {"area": "Lanao del Sur Pagayawan", "zip": "9312"},
  {"area": "Lanao del Sur Piagapo", "zip": "9710"},
  {"area": "Lanao del Sur Picong(Sultan Gumander)", "zip": "9301"},
  {"area": "Lanao del Sur Poona Bayabao", "zip": "9705"},
  {"area": "Lanao del Sur Pualas", "zip": "9313"},
  {"area": "Lanao del Sur Ramain-Ditsaan", "zip": "9713"},
  {"area": "Lanao del Sur Saguiaran", "zip": "9701"},
  {"area": "Lanao del Sur Tagoloan-II", "zip": "9321"},
  {"area": "Lanao del Sur Tamparan", "zip": "9704"},
  {"area": "Lanao del Sur Taraka", "zip": "9712"},
  {"area": "Lanao del Sur Tubaran", "zip": "9304"},
  {"area": "Lanao del Sur Tugaya", "zip": "9317"},
  {"area": "Lanao del Sur Wao", "zip": "9716"},
  {"area": "Las Pi?as City Almanza", "zip": "1750"},
  {"area": "Las Pi?as City Angela Village", "zip": "1749"},
  {"area": "Las Pi?as City Cut-cut", "zip": "1743"},
  {"area": "Las Pi?as City Gatchalian Subdivision", "zip": "1745"},
  {"area": "Las Pi?as City Greymarville Subdivision", "zip": "1741"},
  {"area": "Las Pi?as City Las Pi?as Central Post Office", "zip": "1740"},
  {"area": "Las Pi?as City Manila Doctors Village", "zip": "1748"},
  {"area": "Las Pi?as City Manuyo", "zip": "1744"},
  {"area": "Las Pi?as City Moonwalk Subdivision and Talon", "zip": "1747"},
  {"area": "Las Pi?as City Pulang Lupa and Zapote", "zip": "1742"},
  {"area": "Las Pi?as City Soldiers Hills Subdivision", "zip": "1752"},
  {"area": "Las Pi?as City T.S. Cruz Subdivision", "zip": "1751"},
  {"area": "Las Pi?as City Verdant Acres Subdivision", "zip": "1746"},
  {"area": "LAS PINAS CITY ALMANZA", "zip": "1750"},
  {"area": "LAS PINAS CITY ANGELA VILLAGE", "zip": "1749"},
  {"area": "LAS PINAS CITY CUT-CUT", "zip": "1743"},
  {"area": "LAS PINAS CITY GATCHALIAN SUBDIVISION", "zip": "1745"},
  {"area": "LAS PINAS CITY GREYMARVILLE SUBDIVISION", "zip": "1741"},
  {"area": "LAS PINAS CITY LAS PINAS CENTRAL POST OFFICE", "zip": "1740"},
  {"area": "LAS PINAS CITY MANILA DOCTORS VILLAGE", "zip": "1748"},
  {"area": "LAS PINAS CITY MANUYO", "zip": "1744"},
  {"area": "LAS PINAS CITY MOONWALK SUBDIVISION AND TALON", "zip": "1747"},
  {"area": "LAS PINAS CITY PULANG LUPA AND ZAPOTE", "zip": "1742"},
  {"area": "LAS PINAS CITY SOLDIERS HILLS SUBDIVISION", "zip": "1752"},
  {"area": "LAS PINAS CITY T.S. CRUZ SUBDIVISION", "zip": "1751"},
  {"area": "LAS PINAS CITY VERDANT ACRES SUBDIVISION", "zip": "1746"},
  {"area": "Leyte Abuyog", "zip": "6510"},
  {"area": "Leyte Alangalang", "zip": "6517"},
  {"area": "Leyte Albuera", "zip": "6542"},
  {"area": "Leyte Babatngon", "zip": "6520"},
  {"area": "Leyte Barugo", "zip": "6519"},
  {"area": "Leyte Bato", "zip": "6525"},
  {"area": "Leyte Baybay", "zip": "6521"},
  {"area": "Leyte Burauen", "zip": "6516"},
  {"area": "Leyte Calubian", "zip": "6534"},
  {"area": "Leyte Capoocan", "zip": "6530"},
  {"area": "Leyte Carigara", "zip": "6529"},
  {"area": "Leyte Dagami", "zip": "6515"},
  {"area": "Leyte Dulag", "zip": "6505"},
  {"area": "Leyte Hilongos", "zip": "6524"},
  {"area": "Leyte Hindang", "zip": "6523"},
  {"area": "Leyte Inopacan", "zip": "6522"},
  {"area": "Leyte Isabel", "zip": "6539"},
  {"area": "Leyte Jaro", "zip": "6527"},
  {"area": "Leyte Javier", "zip": "6511"},
  {"area": "Leyte Julita", "zip": "6506"},
  {"area": "Leyte Kananga", "zip": "6531"},
  {"area": "Leyte La Paz", "zip": "6508"},
  {"area": "Leyte Leyte", "zip": "6533"},
  {"area": "LEYTE MAC ARTHUR", "zip": "6509"},
  {"area": "Leyte Macarthur", "zip": "6509"},
  {"area": "Leyte Mahaplag", "zip": "6512"},
  {"area": "Leyte Matag-ob", "zip": "6532"},
  {"area": "Leyte Matalom", "zip": "6526"},
  {"area": "Leyte Mayorga", "zip": "6507"},
  {"area": "Leyte Merida", "zip": "6540"},
  {"area": "Leyte Ormoc City", "zip": "6541"},
  {"area": "Leyte Palo", "zip": "6501"},
  {"area": "Leyte Palompon", "zip": "6538"},
  {"area": "Leyte Pastrana", "zip": "6514"},
  {"area": "Leyte San Isidro", "zip": "6535"},
  {"area": "Leyte San Miguel", "zip": "6518"},
  {"area": "Leyte Santa Fe", "zip": "6513"},
  {"area": "Leyte Tabango", "zip": "6536"},
  {"area": "Leyte Tabontabon", "zip": "6504"},
  {"area": "Leyte Tacloban City", "zip": "6500"},
  {"area": "Leyte Tanuan", "zip": "6502"},
  {"area": "Leyte Tolosa", "zip": "6503"},
  {"area": "Leyte Tunga", "zip": "6528"},
  {"area": "Leyte Villaba", "zip": "6537"},
  {"area": "Maguindanao Ampatuan", "zip": "9609"},
  {"area": "Maguindanao Buluan", "zip": "9616"},
  {"area": "Maguindanao Cotabato City", "zip": "9600"},
  {"area": "MAGUINDANAO DATU ABDULLAH SANGKI", "zip": "9621"},
  {"area": "MAGUINDANAO DATU ANGGAL MIDTIMBANG", "zip": "9622"},
  {"area": "MAGUINDANAO DATU BLAH T. SINSUAT", "zip": "9623"},
  {"area": "MAGUINDANAO DATU HOFFER AMPATUAN", "zip": "9624"},
  {"area": "Maguindanao Datu Paglas", "zip": "9617"},
  {"area": "Maguindanao Datu Piang", "zip": "9607"},
  {"area": "MAGUINDANAO DATU SALIBO", "zip": "9625"},
  {"area": "MAGUINDANAO DATU SAUDI-AMPATUAN", "zip": "9626"},
  {"area": "MAGUINDANAO DATU UNSAY", "zip": "9627"},
  {"area": "Maguindanao Gen. S.K. Pendatun", "zip": "9618"},
  {"area": "MAGUINDANAO GUINDULUNGAN", "zip": "9628"},
  {"area": "MAGUINDANAO MAMASAPANO", "zip": "9629"},
  {"area": "MAGUINDANAO MANGUDADATU", "zip": "9620"},
  {"area": "MAGUINDANAO NORTHERN KABUNTALAN", "zip": "9630"},
  {"area": "MAGUINDANAO PAGAGAWAN", "zip": "9631"},
  {"area": "Maguindanao Pagalungan", "zip": "9610"},
  {"area": "MAGUINDANAO PAGLAT", "zip": "9632"},
  {"area": "MAGUINDANAO PANDAG", "zip": "9633"},
  {"area": "MAGUINDANAO RAJAH BUAYAN", "zip": "9634"},
  {"area": "Maguindanao Sarip Aguak (Maganoy)", "zip": "9608"},
  {"area": "MAGUINDANAO SHARIFF SAYDONA MUSTAPHA", "zip": "9635"},
  {"area": "Maguindanao South Upi", "zip": "9603"},
  {"area": "MAGUINDANAO SULTAN MASTURA", "zip": "9636"},
  {"area": "Maguindanao Sultan sa Barongis", "zip": "9611"},
  {"area": "MAGUINDANAO SULTAN SUMAGKA (FORMERLY TALITAY)", "zip": "9637"},
  {"area": "Maguindanao Talayan", "zip": "9612"},
  {"area": "Makati City ACCRA Law Offices", "zip": "770"},
  {"area": "Makati City Allied Bank", "zip": "716"},
  {"area": "Makati City American Express", "zip": "710"},
  {"area": "Makati City Atlantic, Gulf and Pacific", "zip": "790"},
  {"area": "Makati City Ayala Avenue-Paseo de Roxas", "zip": "1226"},
  {"area": "Makati City A-Z Direct Marketing", "zip": "709"},
  {"area": "Makati City Bangkal", "zip": "1233"},
  {"area": "Makati City Bank of the Philippine Islands", "zip": "720"},
  {"area": "Makati City BCD Direct Marketing", "zip": "731"},
  {"area": "Makati City Bel-Air", "zip": "1209"},
  {"area": "Makati City BPI Family Savings Bank", "zip": "713"},
  {"area": "Makati City Canadian Embassy", "zip": "707"},
  {"area": "Makati City Carmona and Olympia", "zip": "1207"},
  {"area": "Makati City Cembo", "zip": "1214"},
  {"area": "Makati City Citibank", "zip": "702"},
  {"area": "Makati City Colgate Palmolive Philippines", "zip": "701"},
  {"area": "Makati City Comembo", "zip": "1217"},
  {"area": "Makati City Dasmari?as Village North", "zip": "1221"},
  {"area": "Makati City Dasmari?as Village South", "zip": "1222"},
  {"area": "MAKATI CITY DASMARINAS VILLAGE NORTH", "zip": "1221"},
  {"area": "MAKATI CITY DASMARINAS VILLAGE SOUTH", "zip": "1222"},
  {"area": "Makati City Diners Club", "zip": "717"},
  {"area": "Makati City Directories Pilipinas Corporation", "zip": "788"},
  {"area": "Makati City East Rembo and Malapad na Bato", "zip": "1216"},
  {"area": "Makati City Equitable PCI Bank", "zip": "726"},
  {"area": "Makati City Export and Industry Bank", "zip": "732"},
  {"area": "Makati City Faith Embassy", "zip": "706"},
  {"area": "Makati City Forbes Park North", "zip": "1219"},
  {"area": "Makati City Forbes Park South", "zip": "1220"},
  {"area": "Makati City Fort Bonifacio (now part of Taguig City)", "zip": "1201"},
  {"area": "Makati City Fort Bonifacio Naval Station", "zip": "1202"},
  {"area": "Makati City Greenbelt", "zip": "1228"},
  {"area": "Makati City Guadalupe Nuevo (includes Visayan Village)", "zip": "1212"},
  {"area": "Makati City Guadalupe Viejo (includes Palm Village)", "zip": "1211"},
  {"area": "Makati City Insular Life Assurance Company", "zip": "725"},
  {"area": "Makati City International Center for Aquatic Resources", "zip": "718"},
  {"area": "Makati City Japanese Embassy", "zip": "723"},
  {"area": "Makati City Kasilawan", "zip": "1206"},
  {"area": "Makati City La Paz, Singkamas, and Tejeros", "zip": "1204"},
  {"area": "Makati City Land Bank of the Philippines", "zip": "711"},
  {"area": "Makati City Legaspi Village", "zip": "1229"},
  {"area": "Makati City Magallanes Village", "zip": "1232"},
  {"area": "Makati City Makati Central Post Office", "zip": "1200"},
  {"area": "Makati City Makati Commercial Center", "zip": "1224"},
  {"area": "Makati City Makati CPO-PO Box# 1000 to 1099", "zip": "1250"},
  {"area": "Makati City Makati CPO-PO Box# 1100 to 1199", "zip": "1251"},
  {"area": "Makati City Makati CPO-PO Box# 1200 to 1299", "zip": "1252"},
  {"area": "Makati City Makati CPO-PO Box# 1300 to 1399", "zip": "1253"},
  {"area": "Makati City Makati CPO-PO Box# 1400 to 1499", "zip": "1254"},
  {"area": "Makati City Makati CPO-PO Box# 1500 to 1599", "zip": "1255"},
  {"area": "Makati City Makati CPO-PO Box# 1600 to 1699", "zip": "1256"},
  {"area": "Makati City Makati CPO-PO Box# 1700 to 1799", "zip": "1257"},
  {"area": "Makati City Makati CPO-PO Box# 1800 to 1899", "zip": "1258"},
  {"area": "Makati City Makati CPO-PO Box# 1900 to 1999", "zip": "1259"},
  {"area": "Makati City Makati CPO-PO Box# 2000 to 2099", "zip": "1260"},
  {"area": "Makati City Makati CPO-PO Box# 2100 to 2199", "zip": "1261"},
  {"area": "Makati City Makati CPO-PO Box# 2200 to 2299", "zip": "1262"},
  {"area": "Makati City Makati CPO-PO Box# 2300 to 2399", "zip": "1263"},
  {"area": "Makati City Makati CPO-PO Box# 2400 to 2499", "zip": "1264"},
  {"area": "Makati City Makati CPO-PO Box# 2500 to 2599", "zip": "1265"},
  {"area": "Makati City Makati CPO-PO Box# 2600 to 2699", "zip": "1266"},
  {"area": "Makati City Makati CPO-PO Box# 2700 to 2799", "zip": "1267"},
  {"area": "Makati City Makati CPO-PO Box# 2800 to 2899", "zip": "1268"},
  {"area": "Makati City Makati CPO-PO Box# 2900 to 2999", "zip": "1269"},
  {"area": "Makati City Makati CPO-PO Box# 3000 to 3099", "zip": "1270"},
  {"area": "Makati City Makati CPO-PO Box# 3100 to 3199", "zip": "1271"},
  {"area": "Makati City Makati CPO-PO Box# 3200 to 3299", "zip": "1272"},
  {"area": "Makati City Makati CPO-PO Box# 3300 to 3399", "zip": "1273"},
  {"area": "Makati City Makati CPO-PO Box# 3400 to 3499", "zip": "1274"},
  {"area": "Makati City Makati CPO-PO Box# 3500 to 3599", "zip": "1275"},
  {"area": "Makati City Makati CPO-PO Box# 3600 to 3699", "zip": "1276"},
  {"area": "Makati City Makati CPO-PO Box# 3700 to 3799", "zip": "1277"},
  {"area": "Makati City Makati CPO-PO Box# 3800 to 3899", "zip": "1278"},
  {"area": "Makati City Makati CPO-PO Box# 3900 to 3999", "zip": "1279"},
  {"area": "Makati City Makati CPO-PO Box# 4000 to 4099", "zip": "1280"},
  {"area": "Makati City Makati CPO-PO Box# 4100 to 4199", "zip": "1281"},
  {"area": "Makati City Makati CPO-PO Box# 4200 to 4299", "zip": "1282"},
  {"area": "Makati City Makati CPO-PO Box# 4300 to 4399", "zip": "1283"},
  {"area": "Makati City Makati CPO-PO Box# 4400 to 4499", "zip": "1284"},
  {"area": "Makati City Makati CPO-PO Box# 4500 to 4599", "zip": "1285"},
  {"area": "Makati City Makati CPO-PO Box# 4600 to 4699", "zip": "1286"},
  {"area": "Makati City Makati CPO-PO Box# 4700 to 4799", "zip": "1287"},
  {"area": "Makati City Makati CPO-PO Box# 4800 to 4899", "zip": "1288"},
  {"area": "Makati City Makati CPO-PO Box# 4900 to 4999", "zip": "1289"},
  {"area": "Makati City Makati CPO-PO Box# 5000 to 5099", "zip": "1290"},
  {"area": "Makati City Makati CPO-PO Box# 5100 to 5199", "zip": "1291"},
  {"area": "Makati City Makati CPO-PO Box# 5200 to 5299", "zip": "1292"},
  {"area": "Makati City Makati CPO-PO Box# 5300 to 5399", "zip": "1293"},
  {"area": "Makati City Makati CPO-PO Box# 5400 to 5499", "zip": "1294"},
  {"area": "Makati City Makati CPO-PO Box# 5500 to 5599", "zip": "1295"},
  {"area": "Makati City Makati CPO-PO Box# 5600 to 5699", "zip": "1296"},
  {"area": "Makati City Makati CPO-PO Box# 5700 to 5799", "zip": "1297"},
  {"area": "Makati City Makati CPO-PO Box# 5800 to 5899", "zip": "1298"},
  {"area": "Makati City Makati CPO-PO Box# 5900 to 5999", "zip": "1299"},
  {"area": "Makati City Marcopper Mining Corporation", "zip": "715"},
  {"area": "Makati City Mead Johnson Philippines", "zip": "780"},
  {"area": "Makati City Not used", "zip": "729"},
  {"area": "Makati City Palanan", "zip": "1235"},
  {"area": "Makati City Pasong Tamo and Ecology Village", "zip": "1231"},
  {"area": "Makati City Pembo", "zip": "1218"},
  {"area": "Makati City Philippine Airlines", "zip": "750"},
  {"area": "Makati City Philippine Long Distance Telephone Company", "zip": "721"},
  {"area": "Makati City Philippine Manila Mission", "zip": "714"},
  {"area": "Makati City Philippine National Oil Company", "zip": "708"},
  {"area": "Makati City Pilipinas Shell (subsidiary of Royal Dutch Shell)", "zip": "760"},
  {"area": "Makati City Pinagkaisahan - Pitogo", "zip": "1213"},
  {"area": "Makati City Pio del Pilar", "zip": "1230"},
  {"area": "Makati City Plain-Truth", "zip": "700"},
  {"area": "Makati City Poblacion", "zip": "1210"},
  {"area": "Makati City Producers Bank", "zip": "704"},
  {"area": "Makati City Prudential Bank", "zip": "712"},
  {"area": "Makati City Rizal Commercial Banking Corporation", "zip": "727"},
  {"area": "Makati City Salcedo Village", "zip": "1227"},
  {"area": "Makati City San Antonio Village", "zip": "1203"},
  {"area": "Makati City San Isidro", "zip": "1234"},
  {"area": "Makati City San Lorenzo Village", "zip": "1223"},
  {"area": "Makati City Santa Cruz", "zip": "1205"},
  {"area": "Makati City Sarmiento Enterprises", "zip": "703"},
  {"area": "Makati City Security Bank", "zip": "719"},
  {"area": "Makati City Sun Life Financial", "zip": "724"},
  {"area": "Makati City Sycip, Gorres and Velayo", "zip": "740"},
  {"area": "Makati City Union Ajinomoto", "zip": "705"},
  {"area": "Makati City United Coconut Planters Bank", "zip": "728"},
  {"area": "Makati City Urdaneta Village", "zip": "1225"},
  {"area": "Makati City Valenzuela (includes Rizal, San Miguel, and Santiago Villages)", "zip": "1208"},
  {"area": "Makati City West Rembo", "zip": "1215"},
  {"area": "Makati City World Executive Digest", "zip": "722"},
  {"area": "Makati City Zuellig Pharma", "zip": "730"},
  {"area": "Malabon City Acacia", "zip": "1474"},
  {"area": "Malabon City Araneta Subdivision", "zip": "1476"},
  {"area": "Malabon City Dampalit", "zip": "1480"},
  {"area": "Malabon City Flores", "zip": "1471"},
  {"area": "Malabon City Kaunlaran Village (shared with Caloocan City and Navotas)", "zip": "1409"},
  {"area": "Malabon City Longos", "zip": "1472"},
  {"area": "Malabon City Malabon", "zip": "1470"},
  {"area": "Malabon City Maysilo", "zip": "1477"},
  {"area": "Malabon City Muzon", "zip": "1479"},
  {"area": "Malabon City Potrero", "zip": "1475"},
  {"area": "Malabon City Santolan", "zip": "1478"},
  {"area": "Malabon City Tinajeros", "zip": "1470"},
  {"area": "Malabon City Tonsuya", "zip": "1473"},
  {"area": "Mandaluyong City East EDSA", "zip": "1554"},
  {"area": "Mandaluyong City Greenhills South", "zip": "1556"},
  {"area": "Mandaluyong City Mandaluyong Central Post Office", "zip": "1550"},
  {"area": "Mandaluyong City National Center for Mental Health", "zip": "1553"},
  {"area": "Mandaluyong City Shaw Boulevard", "zip": "1552"},
  {"area": "Mandaluyong City Vergara", "zip": "1551"},
  {"area": "Mandaluyong City Wack Wack", "zip": "1555"},
  {"area": "Manila Adventist University of the Philippines", "zip": "901"},
  {"area": "Manila Asian Development Bank", "zip": "980"},
  {"area": "Manila Ateneo de Manila University", "zip": "917"},
  {"area": "Manila Binondo", "zip": "1006"},
  {"area": "Manila Bureau of Plant Industry", "zip": "912"},
  {"area": "Manila Caltex Philippines", "zip": "926"},
  {"area": "Manila Chinabank", "zip": "925"},
  {"area": "Manila De La Salle University", "zip": "922"},
  {"area": "Manila Department of Justice", "zip": "970"},
  {"area": "Manila Department of Labor and Employment", "zip": "910"},
  {"area": "Manila Don Stewart Evangelistic Association", "zip": "923"},
  {"area": "Manila Ermita P.O. Box", "zip": "1045"},
  {"area": "Manila Far East Broadcasting Corporation", "zip": "902"},
  {"area": "Manila Fil-Japan Shipping Company", "zip": "918"},
  {"area": "Manila International Correspondence Institute", "zip": "915"},
  {"area": "Manila International Rice Research Institute", "zip": "960"},
  {"area": "Manila Intramuros", "zip": "1002"},
  {"area": "Manila Malate", "zip": "1004"},
  {"area": "Manila Manila Bulletin", "zip": "900"},
  {"area": "Manila Manila Central Post Office", "zip": "1000"},
  {"area": "Manila Manila CPO-PO Box# 1000 to 1099", "zip": "1050"},
  {"area": "Manila Manila CPO-PO Box# 1100 to 1199", "zip": "1051"},
  {"area": "Manila Manila CPO-PO Box# 1200 to 1299", "zip": "1052"},
  {"area": "Manila Manila CPO-PO Box# 1300 to 1399", "zip": "1053"},
  {"area": "Manila Manila CPO-PO Box# 1400 to 1499", "zip": "1054"},
  {"area": "Manila Manila CPO-PO Box# 1500 to 1599", "zip": "1055"},
  {"area": "Manila Manila CPO-PO Box# 1600 to 1699", "zip": "1056"},
  {"area": "Manila Manila CPO-PO Box# 1700 to 1799", "zip": "1057"},
  {"area": "Manila Manila CPO-PO Box# 1800 to 1899", "zip": "1058"},
  {"area": "Manila Manila CPO-PO Box# 1900 to 1999", "zip": "1059"},
  {"area": "Manila Manila CPO-PO Box# 2000 to 2099", "zip": "1060"},
  {"area": "Manila Manila CPO-PO Box# 2100 to 2199", "zip": "1061"},
  {"area": "Manila Manila CPO-PO Box# 2200 to 2299", "zip": "1062"},
  {"area": "Manila Manila CPO-PO Box# 2300 to 2399", "zip": "1063"},
  {"area": "Manila Manila CPO-PO Box# 2400 to 2499", "zip": "1064"},
  {"area": "Manila Manila CPO-PO Box# 2500 to 2599", "zip": "1065"},
  {"area": "Manila Manila CPO-PO Box# 2600 to 2699", "zip": "1066"},
  {"area": "Manila Manila CPO-PO Box# 2700 to 2799", "zip": "1067"},
  {"area": "Manila Manila CPO-PO Box# 2800 to 2899", "zip": "1068"},
  {"area": "Manila Manila CPO-PO Box# 2900 to 2999", "zip": "1069"},
  {"area": "Manila Manila CPO-PO Box# 3000 to 3099", "zip": "1070"},
  {"area": "Manila Manila CPO-PO Box# 3100 to 3199", "zip": "1071"},
  {"area": "Manila Manila CPO-PO Box# 3200 to 3299", "zip": "1072"},
  {"area": "Manila Manila CPO-PO Box# 3300 to 3399", "zip": "1073"},
  {"area": "Manila Manila CPO-PO Box# 3400 to 3499", "zip": "1074"},
  {"area": "Manila Manila CPO-PO Box# 3500 to 3599", "zip": "1075"},
  {"area": "Manila Manila CPO-PO Box# 3600 to 3699", "zip": "1076"},
  {"area": "Manila Manila CPO-PO Box# 3700 to 3799", "zip": "1077"},
  {"area": "Manila Manila CPO-PO Box# 3800 to 3899", "zip": "1078"},
  {"area": "Manila Manila CPO-PO Box# 3900 to 3999", "zip": "1079"},
  {"area": "Manila Manila CPO-PO Box# 4000 to 4099", "zip": "1080"},
  {"area": "Manila Manila CPO-PO Box# 4100 to 4199", "zip": "1081"},
  {"area": "Manila Manila CPO-PO Box# 4200 to 4299", "zip": "1082"},
  {"area": "Manila Manila CPO-PO Box# 4300 to 4399", "zip": "1083"},
  {"area": "Manila Manila CPO-PO Box# 4400 to 4499", "zip": "1084"},
  {"area": "Manila Manila CPO-PO Box# 4500 to 4599", "zip": "1085"},
  {"area": "Manila Manila CPO-PO Box# 4600 to 4699", "zip": "1086"},
  {"area": "Manila Manila CPO-PO Box# 4700 to 4799", "zip": "1087"},
  {"area": "Manila Manila CPO-PO Box# 4800 to 4899", "zip": "1088"},
  {"area": "Manila Manila CPO-PO Box# 4900 to 4999", "zip": "1089"},
  {"area": "Manila Manila CPO-PO Box# 5000 to 5099", "zip": "1090"},
  {"area": "Manila Manila CPO-PO Box# 5100 to 5199", "zip": "1091"},
  {"area": "Manila Manila CPO-PO Box# 5200 to 5299", "zip": "1092"},
  {"area": "Manila Manila CPO-PO Box# 5300 to 5399", "zip": "1093"},
  {"area": "Manila Manila CPO-PO Box# 5400 to 5499", "zip": "1094"},
  {"area": "Manila Manila CPO-PO Box# 5500 to 5599", "zip": "1095"},
  {"area": "Manila Manila CPO-PO Box# 5600 to 5699", "zip": "1096"},
  {"area": "Manila Manila Hilton", "zip": "903"},
  {"area": "Manila Manila Hotel", "zip": "913"},
  {"area": "Manila Manila Medical Center", "zip": "906"},
  {"area": "Manila Manila Midtown Hotel", "zip": "950"},
  {"area": "Manila Manila Police District", "zip": "919"},
  {"area": "Manila Midland Hotel", "zip": "904"},
  {"area": "Manila National Statistics Office", "zip": "911"},
  {"area": "Manila Not used", "zip": "929"},
  {"area": "Manila Paco", "zip": "1007"},
  {"area": "Manila Pandacan", "zip": "1011"},
  {"area": "Manila Pan-Filipino Shipping", "zip": "908"},
  {"area": "Manila Philamlife", "zip": "990"},
  {"area": "Manila Philippine Campus Crusade", "zip": "928"},
  {"area": "Manila Philippine National Bank", "zip": "940"},
  {"area": "Manila Philippine Plaza Hotel", "zip": "921"},
  {"area": "Manila Philippine Refining Company and Unilever Philippines", "zip": "914"},
  {"area": "Manila Philippine Veterans Affairs Office", "zip": "927"},
  {"area": "Manila Port Area South", "zip": "1018"},
  {"area": "Manila Quiapo", "zip": "1001"},
  {"area": "Manila Radio Veritas", "zip": "916"},
  {"area": "Manila Sampaloc East", "zip": "1008"},
  {"area": "Manila Sampaloc West", "zip": "1015"},
  {"area": "Manila San Andres", "zip": "1017"},
  {"area": "Manila San Miguel", "zip": "1005"},
  {"area": "Manila San Miguel Corporation", "zip": "907"},
  {"area": "Manila San Nicolas", "zip": "1010"},
  {"area": "Manila Santa Ana", "zip": "1009"},
  {"area": "Manila Santa Cruz North", "zip": "1014"},
  {"area": "Manila Santa Cruz P.O. Box", "zip": "1040"},
  {"area": "Manila Santa Cruz South", "zip": "1003"},
  {"area": "Manila Santa Mesa", "zip": "1016"},
  {"area": "Manila Santa Mesa P.O. Box", "zip": "1035"},
  {"area": "Manila Summer Institute of Linguistics", "zip": "909"},
  {"area": "Manila Sunbursts Publications", "zip": "920"},
  {"area": "Manila The Philippine Star", "zip": "905"},
  {"area": "Manila Tondo North", "zip": "1013"},
  {"area": "Manila Tondo South", "zip": "1012"},
  {"area": "Manila United States Embassy", "zip": "930"},
  {"area": "Manila World Map", "zip": "924"},
  {"area": "Marikina City Bagong Nayon, Cupang, Mambagat, and Mayamot", "zip": "1820"},
  {"area": "Marikina City Barangka and Ta?ong", "zip": "1803"},
  {"area": "MARIKINA CITY BARANGKA AND TANONG", "zip": "1803"},
  {"area": "Marikina City Concepcion 2", "zip": "1811"},
  {"area": "Marikina City Concepcion tarlac", "zip": "1807"},
  {"area": "Marikina City Industrial Valley", "zip": "1802"},
  {"area": "Marikina City J. De La Pe?a", "zip": "1804"},
  {"area": "MARIKINA CITY J. DE LA PENA", "zip": "1804"},
  {"area": "Marikina City Malanday", "zip": "1805"},
  {"area": "Marikina City Marikina Central Post Office", "zip": "1800"},
  {"area": "Marikina City Marikina Heights", "zip": "1810"},
  {"area": "Marikina City Nangka", "zip": "1808"},
  {"area": "Marikina City Northern and Western Marikina River", "zip": "1806"},
  {"area": "Marikina City Not used", "zip": "1812-1819"},
  {"area": "Marikina City Parang", "zip": "1809"},
  {"area": "Marikina City San Roque-Calumpang", "zip": "1801"},
  {"area": "Marinduque Boac", "zip": "4900"},
  {"area": "Marinduque Buenavista", "zip": "4904"},
  {"area": "Marinduque Gasan", "zip": "4905"},
  {"area": "Marinduque Mogpog", "zip": "4901"},
  {"area": "Marinduque Santa Cruz", "zip": "4902"},
  {"area": "Marinduque Torrijos", "zip": "4903"},
  {"area": "Masbate Aroroy", "zip": "5414"},
  {"area": "Masbate Baleno", "zip": "5413"},
  {"area": "Masbate Balud", "zip": "5412"},
  {"area": "Masbate Batuan", "zip": "5415"},
  {"area": "Masbate Buenavista", "zip": "5421"},
  {"area": "Masbate Cataingan", "zip": "5405"},
  {"area": "Masbate Cawayan", "zip": "5409"},
  {"area": "Masbate Claveria", "zip": "5419"},
  {"area": "Masbate Dimasalang", "zip": "5403"},
  {"area": "Masbate Esperanza", "zip": "5407"},
  {"area": "Masbate Mandaon", "zip": "5411"},
  {"area": "Masbate Masbate City", "zip": "5400"},
  {"area": "Masbate Milagros", "zip": "5410"},
  {"area": "Masbate Mobo", "zip": "5401"},
  {"area": "Masbate Monreal", "zip": "5418"},
  {"area": "Masbate Palanas", "zip": "5404"},
  {"area": "Masbate Pio V. Corpuz", "zip": "5406"},
  {"area": "Masbate Placer", "zip": "5408"},
  {"area": "Masbate San Fernando", "zip": "5416"},
  {"area": "Masbate San Jacinto", "zip": "5417"},
  {"area": "Masbate San Pascual", "zip": "5420"},
  {"area": "Masbate Uson", "zip": "5402"},
  {"area": "Misamis Occidental Aloran", "zip": "7206"},
  {"area": "Misamis Occidental Baliangao", "zip": "7211"},
  {"area": "Misamis Occidental Bonifacio", "zip": "7215"},
  {"area": "Misamis Occidental Calamba", "zip": "7210"},
  {"area": "Misamis Occidental Clarin", "zip": "7201"},
  {"area": "Misamis Occidental Concepcion", "zip": "7213"},
  {"area": "Misamis Occidental Jimenez", "zip": "7204"},
  {"area": "Misamis Occidental Lopez Jaena", "zip": "7208"},
  {"area": "Misamis Occidental Oroquieta City", "zip": "7207"},
  {"area": "Misamis Occidental Ozamis City", "zip": "7200"},
  {"area": "Misamis Occidental Panaon", "zip": "7205"},
  {"area": "Misamis Occidental Plaridel", "zip": "7209"},
  {"area": "Misamis Occidental Sapang Dalaga", "zip": "7212"},
  {"area": "Misamis Occidental Sinacaban", "zip": "7203"},
  {"area": "Misamis Occidental Tangub City", "zip": "7214"},
  {"area": "Misamis Occidental Tudela", "zip": "7202"},
  {"area": "Misamis Oriental Alubijid", "zip": "9018"},
  {"area": "Misamis Oriental Balingasag", "zip": "9005"},
  {"area": "Misamis Oriental Balinguan", "zip": "9011"},
  {"area": "Misamis Oriental Binuangan", "zip": "9008"},
  {"area": "Misamis Oriental Cagayan de Oro City", "zip": "9000"},
  {"area": "Misamis Oriental Claveria", "zip": "9004"},
  {"area": "Misamis Oriental El Salvador", "zip": "9017"},
  {"area": "Misamis Oriental Gingoog City", "zip": "9014"},
  {"area": "Misamis Oriental Gitagum", "zip": "9020"},
  {"area": "Misamis Oriental Initao", "zip": "9022"},
  {"area": "Misamis Oriental Jasaan", "zip": "9003"},
  {"area": "Misamis Oriental Kinogitan", "zip": "9010"},
  {"area": "Misamis Oriental Lagonglong", "zip": "9006"},
  {"area": "Misamis Oriental Laguindingan", "zip": "9019"},
  {"area": "Misamis Oriental Libertad", "zip": "9021"},
  {"area": "Misamis Oriental Lugait", "zip": "9025"},
  {"area": "Misamis Oriental Magsaysay", "zip": "9015"},
  {"area": "Misamis Oriental Manticao", "zip": "9024"},
  {"area": "Misamis Oriental Medina", "zip": "9013"},
  {"area": "Misamis Oriental Naawan", "zip": "9023"},
  {"area": "Misamis Oriental Opol", "zip": "9016"},
  {"area": "Misamis Oriental Salay", "zip": "9007"},
  {"area": "Misamis Oriental Sugbungcogon", "zip": "9009"},
  {"area": "Misamis Oriental Tagoloan", "zip": "9001"},
  {"area": "Misamis Oriental Talisayan", "zip": "9012"},
  {"area": "Misamis Oriental Villanueva", "zip": "9002"},
  {"area": "Mountain Province Barlig", "zip": "2623"},
  {"area": "Mountain Province Bauko", "zip": "2621"},
  {"area": "Mountain Province Besao", "zip": "2618"},
  {"area": "Mountain Province Bontoc", "zip": "2616"},
  {"area": "Mountain Province Natonin", "zip": "2624"},
  {"area": "Mountain Province Paracelis", "zip": "2625"},
  {"area": "Mountain Province Sabangan", "zip": "2622"},
  {"area": "Mountain Province Sadanga", "zip": "2617"},
  {"area": "Mountain Province Sagada", "zip": "2619"},
  {"area": "Mountain Province Tadian", "zip": "2620"},
  {"area": "Muntinlupa City Ayala Alabang P.O. Boxes", "zip": "1799"},
  {"area": "Muntinlupa City Ayala Alabang Subdivision", "zip": "1780"},
  {"area": "Muntinlupa City Bayanan/Putatan", "zip": "1772"},
  {"area": "Muntinlupa City Buli/Cupang", "zip": "1771"},
  {"area": "Muntinlupa City Muntinlupa Central Post Office", "zip": "1770"},
  {"area": "Muntinlupa City Pearl Heights", "zip": "1775"},
  {"area": "Muntinlupa City Pleasant Village", "zip": "1777"},
  {"area": "Muntinlupa City Poblacion", "zip": "1776"},
  {"area": "Muntinlupa City Soldiers` Hills", "zip": "1772"},
  {"area": "Muntinlupa City Susana Heights", "zip": "1774"},
  {"area": "Muntinlupa City Tunasan", "zip": "1773"},
  {"area": "Navotas City Fish Market", "zip": "1411"},
  {"area": "Navotas City Isla de Cocomo", "zip": "1412"},
  {"area": "Navotas City Kapitbahayan [East]", "zip": "1413"},
  {"area": "Navotas City Kaunlaran Village", "zip": "1409"},
  {"area": "Navotas City Navotas", "zip": "1485"},
  {"area": "Navotas City Tangos", "zip": "1489"},
  {"area": "Navotas City Tanza", "zip": "1490"},
  {"area": "Negros Occidental Bacolod City", "zip": "6100"},
  {"area": "Negros Occidental Bago City", "zip": "6101"},
  {"area": "Negros Occidental Binalbagan City", "zip": "6107"},
  {"area": "Negros Occidental Cadiz City", "zip": "6121"},
  {"area": "Negros Occidental Calatrava", "zip": "6126"},
  {"area": "Negros Occidental Candoni", "zip": "6110"},
  {"area": "Negros Occidental Cauayan", "zip": "6112"},
  {"area": "Negros Occidental Enrique Magalona", "zip": "6118"},
  {"area": "Negros Occidental Escalante City", "zip": "6124"},
  {"area": "Negros Occidental Himamaylan City", "zip": "6108"},
  {"area": "Negros Occidental Hinigaran City", "zip": "6106"},
  {"area": "Negros Occidental Hinoba-an", "zip": "6114"},
  {"area": "Negros Occidental Ilog", "zip": "6109"},
  {"area": "Negros Occidental Isabela", "zip": "6128"},
  {"area": "Negros Occidental Kabankalan City", "zip": "6111"},
  {"area": "Negros Occidental La Carlota City", "zip": "6130"},
  {"area": "Negros Occidental La Castillana", "zip": "6131"},
  {"area": "Negros Occidental Manapla", "zip": "6120"},
  {"area": "Negros Occidental Moises Padilla", "zip": "6132"},
  {"area": "Negros Occidental Murcia", "zip": "6129"},
  {"area": "Negros Occidental Paraiso (Fabrica)", "zip": "6123"},
  {"area": "Negros Occidental Pontevedra", "zip": "6105"},
  {"area": "Negros Occidental Pulupandan", "zip": "6102"},
  {"area": "Negros Occidental Sagay City", "zip": "6122"},
  {"area": "Negros Occidental San Carlos City", "zip": "6127"},
  {"area": "Negros Occidental San Enrique", "zip": "6104"},
  {"area": "Negros Occidental Silay City", "zip": "6116"},
  {"area": "Negros Occidental Silay Hawaiian Central", "zip": "6117"},
  {"area": "Negros Occidental Sipalay City", "zip": "6113"},
  {"area": "Negros Occidental Talisay City", "zip": "6115"},
  {"area": "Negros Occidental Toboso", "zip": "6125"},
  {"area": "Negros Occidental Valladolid", "zip": "6103"},
  {"area": "Negros Occidental Victorias City", "zip": "6119"},
  {"area": "Negros Oriental Amlan", "zip": "6203"},
  {"area": "Negros Oriental Ayungon", "zip": "6210"},
  {"area": "Negros Oriental Bacong", "zip": "6216"},
  {"area": "Negros Oriental Bais City", "zip": "6206"},
  {"area": "Negros Oriental Basay", "zip": "6222"},
  {"area": "Negros Oriental Bayawan City", "zip": "6221"},
  {"area": "Negros Oriental Bindoy", "zip": "6209"},
  {"area": "Negros Oriental Canlaon City", "zip": "6223"},
  {"area": "Negros Oriental Dauin", "zip": "6217"},
  {"area": "Negros Oriental Dumaguete City", "zip": "6200"},
  {"area": "Negros Oriental Guihulngan", "zip": "6214"},
  {"area": "Negros Oriental Jimalalud", "zip": "6212"},
  {"area": "Negros Oriental La Libertad", "zip": "6213"},
  {"area": "Negros Oriental Mabinay", "zip": "6207"},
  {"area": "Negros Oriental Manjuyod", "zip": "6208"},
  {"area": "Negros Oriental Pamplona", "zip": "6205"},
  {"area": "Negros Oriental San Jose", "zip": "6303"},
  {"area": "Negros Oriental San Jose", "zip": "6202"},
  {"area": "Negros Oriental Siaton", "zip": "6219"},
  {"area": "Negros Oriental Sibulan", "zip": "6201"},
  {"area": "Negros Oriental Sta. Catalina", "zip": "6220"},
  {"area": "Negros Oriental Tanjay City", "zip": "6204"},
  {"area": "Negros Oriental Tayasan", "zip": "6211"},
  {"area": "Negros Oriental Valencia", "zip": "6215"},
  {"area": "Negros Oriental Valle Hermoso", "zip": "6224"},
  {"area": "Negros Oriental Zamboanguita", "zip": "6218"},
  {"area": "NORTH COTABATO ALAMADA", "zip": "9413"},
  {"area": "NORTH COTABATO ALEOSAN", "zip": "9415"},
  {"area": "NORTH COTABATO ANTIPAS", "zip": "9414"},
  {"area": "NORTH COTABATO ARAKAN", "zip": "9417"},
  {"area": "NORTH COTABATO BANISILAN", "zip": "9416"},
  {"area": "NORTH COTABATO CARMEN", "zip": "9408"},
  {"area": "NORTH COTABATO KABACAN", "zip": "9407"},
  {"area": "NORTH COTABATO KIDAPAWAN CITY", "zip": "9400"},
  {"area": "NORTH COTABATO LIBUNGAN", "zip": "9411"},
  {"area": "NORTH COTABATO M`LANG", "zip": "9402"},
  {"area": "NORTH COTABATO MAGPET", "zip": "9404"},
  {"area": "NORTH COTABATO MAKILALA", "zip": "9401"},
  {"area": "NORTH COTABATO MATALAM", "zip": "9406"},
  {"area": "NORTH COTABATO MIDSAYAP", "zip": "9410"},
  {"area": "NORTH COTABATO PIGKAWAYAN", "zip": "9412"},
  {"area": "NORTH COTABATO PIKIT", "zip": "9409"},
  {"area": "NORTH COTABATO PRESIDENT ROXAS", "zip": "9405"},
  {"area": "NORTH COTABATO TULUNAN", "zip": "9403"},
  {"area": "Northern Samar Allen", "zip": "6405"},
  {"area": "Northern Samar Biri", "zip": "6410"},
  {"area": "Northern Samar Bobon", "zip": "6401"},
  {"area": "Northern Samar Capul", "zip": "6408"},
  {"area": "Northern Samar Catarman", "zip": "6400"},
  {"area": "Northern Samar Catubig", "zip": "6418"},
  {"area": "Northern Samar Gamay", "zip": "6422"},
  {"area": "Northern Samar Laoang", "zip": "6411"},
  {"area": "Northern Samar Lapinig", "zip": "6423"},
  {"area": "Northern Samar Las Navas", "zip": "6420"},
  {"area": "Northern Samar Lavezares", "zip": "6404"},
  {"area": "Northern Samar Lope de Vega", "zip": "6403"},
  {"area": "Northern Samar Mapanas", "zip": "6412"},
  {"area": "Northern Samar Mondragon", "zip": "6417"},
  {"area": "Northern Samar Palapag", "zip": "6421"},
  {"area": "Northern Samar Pambujan", "zip": "6413"},
  {"area": "Northern Samar Rosario", "zip": "6416"},
  {"area": "Northern Samar San Antonio", "zip": "6407"},
  {"area": "Northern Samar San Isidro", "zip": "6409"},
  {"area": "Northern Samar San Jose", "zip": "6402"},
  {"area": "Northern Samar San Roque", "zip": "6415"},
  {"area": "Northern Samar San Vicente", "zip": "6419"},
  {"area": "Northern Samar Silvino Lobos", "zip": "6414"},
  {"area": "Northern Samar Victoria", "zip": "6406"},
  {"area": "Nueva Ecija Aliaga", "zip": "3111"},
  {"area": "Nueva Ecija Bongabon", "zip": "3128"},
  {"area": "Nueva Ecija Cabanatuan City", "zip": "3100"},
  {"area": "Nueva Ecija Cabiao", "zip": "3107"},
  {"area": "Nueva Ecija Carranglan", "zip": "3123"},
  {"area": "Nueva Ecija Central Luzon State University", "zip": "3120"},
  {"area": "Nueva Ecija Cuyapo", "zip": "3117"},
  {"area": "Nueva Ecija Fort Magsaysay", "zip": "3130"},
  {"area": "Nueva Ecija Gabaldon", "zip": "3131"},
  {"area": "Nueva Ecija Gapan City", "zip": "3105"},
  {"area": "Nueva Ecija General M. Natividad", "zip": "3125"},
  {"area": "Nueva Ecija General Tinio", "zip": "3104"},
  {"area": "Nueva Ecija Guimba", "zip": "3115"},
  {"area": "Nueva Ecija Jaen", "zip": "3109"},
  {"area": "Nueva Ecija Laur", "zip": "3129"},
  {"area": "Nueva Ecija Licab", "zip": "3112"},
  {"area": "Nueva Ecija Llanera", "zip": "3126"},
  {"area": "Nueva Ecija Lupao", "zip": "3122"},
  {"area": "Nueva Ecija Nampicuan", "zip": "3116"},
  {"area": "Nueva Ecija Palayan City", "zip": "3132"},
  {"area": "Nueva Ecija Pantabangan", "zip": "3124"},
  {"area": "Nueva Ecija Pe?aranda", "zip": "3103"},
  {"area": "NUEVA ECIJA PENARANDA", "zip": "3103"},
  {"area": "Nueva Ecija Quezon", "zip": "3113"},
  {"area": "Nueva Ecija Rizal", "zip": "3127"},
  {"area": "Nueva Ecija San Antonio", "zip": "3108"},
  {"area": "Nueva Ecija San Isidro", "zip": "3106"},
  {"area": "Nueva Ecija San Jose City", "zip": "3121"},
  {"area": "Nueva Ecija San Leonardo", "zip": "3102"},
  {"area": "Nueva Ecija Santa Rosa", "zip": "3101"},
  {"area": "Nueva Ecija Santo Domingo", "zip": "3133"},
  {"area": "Nueva Ecija Science City of Mu?oz", "zip": "3119"},
  {"area": "NUEVA ECIJA SCIENCE CITY OF MUNOZ", "zip": "3119"},
  {"area": "Nueva Ecija Talavera", "zip": "3114"},
  {"area": "Nueva Ecija Talugtog", "zip": "3118"},
  {"area": "Nueva Ecija Zaragoza", "zip": "3110"},
  {"area": "Nueva Vizcaya Alfonso Casta?eda", "zip": "3714"},
  {"area": "NUEVA VIZCAYA ALFONSO CASTANEDA", "zip": "3714"},
  {"area": "Nueva Vizcaya Ambaguio", "zip": "3701"},
  {"area": "Nueva Vizcaya Aritao", "zip": "3704"},
  {"area": "Nueva Vizcaya Bagabag", "zip": "3711"},
  {"area": "Nueva Vizcaya Bambang", "zip": "3702"},
  {"area": "Nueva Vizcaya Bayombong", "zip": "3700"},
  {"area": "Nueva Vizcaya Diadi", "zip": "3712"},
  {"area": "Nueva Vizcaya Dupax del Norte", "zip": "3706"},
  {"area": "Nueva Vizcaya Dupax del Sur", "zip": "3707"},
  {"area": "Nueva Vizcaya Kasibu", "zip": "3703"},
  {"area": "Nueva Vizcaya Kayapa", "zip": "3708"},
  {"area": "Nueva Vizcaya Quezon", "zip": "3714"},
  {"area": "Nueva Vizcaya Santa Fe (Imugan)", "zip": "3705"},
  {"area": "Nueva Vizcaya Solano", "zip": "3709"},
  {"area": "Nueva Vizcaya Villa Verde (Ibung)", "zip": "3710"},
  {"area": "Occidental Mindoro Abra de Ilog", "zip": "5108"},
  {"area": "Occidental Mindoro Calintaan", "zip": "5102"},
  {"area": "Occidental Mindoro Looc", "zip": "5111"},
  {"area": "Occidental Mindoro Lubang", "zip": "5109"},
  {"area": "Occidental Mindoro Magsaysay", "zip": "5101"},
  {"area": "Occidental Mindoro Mamburao", "zip": "5106"},
  {"area": "Occidental Mindoro Paluan", "zip": "5107"},
  {"area": "Occidental Mindoro Rizal", "zip": "5103"},
  {"area": "Occidental Mindoro Sablayan", "zip": "5104"},
  {"area": "Occidental Mindoro San Jose", "zip": "5100"},
  {"area": "Occidental Mindoro Santa Cruz", "zip": "5105"},
  {"area": "Occidental Mindoro Tilik", "zip": "5110"},
  {"area": "Oriental Mindoro Baco", "zip": "5201"},
  {"area": "Oriental Mindoro Bansud", "zip": "5210"},
  {"area": "Oriental Mindoro Bongabon", "zip": "5211"},
  {"area": "Oriental Mindoro Bulalacao", "zip": "5214"},
  {"area": "Oriental Mindoro Calapan", "zip": "5200"},
  {"area": "Oriental Mindoro Gloria", "zip": "5209"},
  {"area": "Oriental Mindoro Mansalay", "zip": "5213"},
  {"area": "Oriental Mindoro Naujan", "zip": "5204"},
  {"area": "Oriental Mindoro Pinamalayan", "zip": "5208"},
  {"area": "Oriental Mindoro Pola", "zip": "5206"},
  {"area": "Oriental Mindoro Puerto Galera", "zip": "5203"},
  {"area": "Oriental Mindoro Roxas", "zip": "5212"},
  {"area": "Oriental Mindoro San Teodoro", "zip": "5202"},
  {"area": "Oriental Mindoro Socorro", "zip": "5207"},
  {"area": "Oriental Mindoro Victoria", "zip": "5205"},
  {"area": "Palawan Aborlan", "zip": "5302"},
  {"area": "Palawan Agutaya", "zip": "5320"},
  {"area": "Palawan Araceli", "zip": "5311"},
  {"area": "Palawan Balabac", "zip": "5307"},
  {"area": "Palawan Bataraza", "zip": "5306"},
  {"area": "Palawan Brooke`s Point", "zip": "5305"},
  {"area": "Palawan Busuanga", "zip": "5317"},
  {"area": "Palawan Cagayancillo", "zip": "5321"},
  {"area": "Palawan Coron", "zip": "5316"},
  {"area": "Palawan Culion", "zip": "5315"},
  {"area": "Palawan Cuyo", "zip": "5318"},
  {"area": "Palawan Dumaran", "zip": "5310"},
  {"area": "Palawan El Nido (Bacuit)", "zip": "5313"},
  {"area": "Palawan Iwahig Penal Colony", "zip": "5301"},
  {"area": "Palawan Kalayaan", "zip": "5322"},
  {"area": "Palawan Linapacan", "zip": "5314"},
  {"area": "Palawan Magsaysay", "zip": "5319"},
  {"area": "Palawan Narra (Panacan)", "zip": "5303"},
  {"area": "Palawan Puerto Princesa", "zip": "5300"},
  {"area": "Palawan Quezon", "zip": "5304"},
  {"area": "PALAWAN RIZAL (MARCOS)", "zip": "5323"},
  {"area": "Palawan Roxas", "zip": "5308"},
  {"area": "Palawan San Vicente", "zip": "5309"},
  {"area": "PALAWAN SOFRONIO ESPANOLA", "zip": "5324"},
  {"area": "Palawan Taytay", "zip": "5312"},
  {"area": "Pampanga Angeles City/Clark Special Economic ZoneBalibago a Barangay in Angeles city has its own zip code 2024", "zip": "2009"},
  {"area": "Pampanga Apalit", "zip": "2016"},
  {"area": "Pampanga Arayat", "zip": "2012"},
  {"area": "Pampanga Bacolor", "zip": "2001"},
  {"area": "Pampanga Basa Airbase", "zip": "2007"},
  {"area": "Pampanga Candaba", "zip": "2013"},
  {"area": "Pampanga City of San Fernando", "zip": "2000"},
  {"area": "Pampanga Floridablanca", "zip": "2006"},
  {"area": "Pampanga Guagua", "zip": "2003"},
  {"area": "Pampanga Lubao", "zip": "2005"},
  {"area": "Pampanga Mabalacat", "zip": "2010"},
  {"area": "Pampanga Macabebe", "zip": "2018"},
  {"area": "Pampanga Magalang", "zip": "2011"},
  {"area": "Pampanga Masantol", "zip": "2017"},
  {"area": "Pampanga Mexico", "zip": "2021"},
  {"area": "Pampanga Minalin", "zip": "2019"},
  {"area": "Pampanga Porac", "zip": "2008"},
  {"area": "Pampanga San Luis", "zip": "2014"},
  {"area": "Pampanga San Simon", "zip": "2015"},
  {"area": "Pampanga Santa Ana", "zip": "2022"},
  {"area": "Pampanga Santa Rita", "zip": "2002"},
  {"area": "Pampanga Santo Tomas", "zip": "2020"},
  {"area": "Pampanga Sasmuan (Sexmoan)", "zip": "2004"},
  {"area": "Pangasinan Agno", "zip": "2408"},
  {"area": "Pangasinan Aguilar", "zip": "2415"},
  {"area": "Pangasinan Alaminos", "zip": "2404"},
  {"area": "Pangasinan Alcala", "zip": "2425"},
  {"area": "Pangasinan Anda", "zip": "2405"},
  {"area": "Pangasinan Asingan", "zip": "2439"},
  {"area": "Pangasinan Balungao", "zip": "2442"},
  {"area": "Pangasinan Bani", "zip": "2407"},
  {"area": "Pangasinan Basista", "zip": "2422"},
  {"area": "Pangasinan Bautista", "zip": "2424"},
  {"area": "Pangasinan Bayambang", "zip": "2423"},
  {"area": "Pangasinan Binalonan", "zip": "2436"},
  {"area": "Pangasinan Binmaley", "zip": "2417"},
  {"area": "Pangasinan Bolinao", "zip": "2406"},
  {"area": "Pangasinan Bugallon", "zip": "2416"},
  {"area": "Pangasinan Burgos", "zip": "2410"},
  {"area": "Pangasinan Calasiao", "zip": "2418"},
  {"area": "Pangasinan Dagupan City", "zip": "2400"},
  {"area": "Pangasinan Dasol", "zip": "2411"},
  {"area": "Pangasinan Infanta", "zip": "2412"},
  {"area": "Pangasinan Labrador", "zip": "2402"},
  {"area": "Pangasinan Laoac", "zip": "2437"},
  {"area": "Pangasinan Lingayen", "zip": "2401"},
  {"area": "Pangasinan Mabini", "zip": "2409"},
  {"area": "Pangasinan Malasiqui", "zip": "2421"},
  {"area": "Pangasinan Manaoag", "zip": "2430"},
  {"area": "Pangasinan Mangaldan", "zip": "2432"},
  {"area": "Pangasinan Mangatarem", "zip": "2413"},
  {"area": "Pangasinan Mapandan", "zip": "2429"},
  {"area": "Pangasinan Natividad", "zip": "2446"},
  {"area": "Pangasinan Pozorrubio", "zip": "2435"},
  {"area": "Pangasinan Rosales", "zip": "2441"},
  {"area": "Pangasinan San Carlos City", "zip": "2420"},
  {"area": "Pangasinan San Fabian", "zip": "2433"},
  {"area": "Pangasinan San Jacinto", "zip": "2431"},
  {"area": "Pangasinan San Manuel", "zip": "2438"},
  {"area": "Pangasinan San Nicolas", "zip": "2447"},
  {"area": "Pangasinan San Quintin", "zip": "2444"},
  {"area": "Pangasinan Santa Barbara", "zip": "2419"},
  {"area": "Pangasinan Santa Maria", "zip": "2440"},
  {"area": "Pangasinan Santo Tomas", "zip": "2426"},
  {"area": "Pangasinan Sison", "zip": "2434"},
  {"area": "Pangasinan Sual", "zip": "2403"},
  {"area": "Pangasinan Tayug", "zip": "2445"},
  {"area": "Pangasinan Umingan", "zip": "2443"},
  {"area": "Pangasinan Urbiztondo", "zip": "2414"},
  {"area": "Pangasinan Urdaneta", "zip": "2428"},
  {"area": "Pangasinan Villasis", "zip": "2427"},
  {"area": "Parañaque City Aeropark Subdivision", "zip": "1714"},
  {"area": "Parañaque City Baclaran", "zip": "1702"},
  {"area": "Parañaque City Better Living Subdivision", "zip": "1711"},
  {"area": "Parañaque City BF Homes 1", "zip": "1720"},
  {"area": "Parañaque City BF Homes 2", "zip": "1718"},
  {"area": "Parañaque City Domestic Airport P.O. - P.O. Box Nos. 1000 to 1099", "zip": "1375"},
  {"area": "Parañaque City Domestic Airport P.O. - P.O. Box Nos. 1100 to 1199", "zip": "1376"},
  {"area": "Parañaque City Domestic Airport P.O. - P.O. Box Nos. 1200 to 1299", "zip": "1377"},
  {"area": "Parañaque City Domestic Airport P.O. - P.O. Box Nos. 1300 to 1399", "zip": "1378"},
  {"area": "Parañaque City Domestic Airport P.O. - P.O. Box Nos. 1400 to 1499", "zip": "1379"},
  {"area": "Parañaque City Domestic Airport P.O. - P.O. Box Nos. 1500 to 1599", "zip": "1380"},
  {"area": "Parañaque City Domestic Airport P.O. - P.O. Box Nos. 1600 to 1699", "zip": "1381"},
  {"area": "Parañaque City Executive Heights Subdivision", "zip": "1710"},
  {"area": "Parañaque City Ireneville Subdivision I & III", "zip": "1719"},
  {"area": "Parañaque City Ireneville Subdivision II", "zip": "1714"},
  {"area": "Parañaque City Manila Memorial Park", "zip": "1717"},
  {"area": "Parañaque City Marina Subdivision (Reclamation)", "zip": "1703"},
  {"area": "Parañaque City Maywood Village I", "zip": "1719"},
  {"area": "Parañaque City Maywood Village II", "zip": "1716"},
  {"area": "Parañaque City Miramar Subdivision", "zip": "1712"},
  {"area": "Parañaque City Multinational Village", "zip": "1708"},
  {"area": "Parañaque City Ninoy Aquino International Airport", "zip": "1705"},
  {"area": "Parañaque City Parañaque CPO", "zip": "1700"},
  {"area": "Parañaque City Pulo", "zip": "1706"},
  {"area": "Parañaque City San Antonio Valley 1", "zip": "1715"},
  {"area": "Parañaque City San Antonio Valley 11 & 12", "zip": "1707"},
  {"area": "Parañaque City Santo Ni?o", "zip": "1704"},
  {"area": "Parañaque City South Admiral Village, Merville Park & Moonwalk Subdivision", "zip": "1709"},
  {"area": "Parañaque City Tambo", "zip": "1701"},
  {"area": "Parañaque City United Parañaque Subdivision", "zip": "1713"},
  {"area": "PARANAQUE CITY AEROPARK SUBDIVISION", "zip": "1714"},
  {"area": "PARANAQUE CITY BACLARAN", "zip": "1702"},
  {"area": "PARANAQUE CITY BETTER LIVING SUBDIVISION", "zip": "1711"},
  {"area": "PARANAQUE CITY BF HOMES 1", "zip": "1720"},
  {"area": "PARANAQUE CITY BF HOMES 2", "zip": "1718"},
  {"area": "PARANAQUE CITY DOMESTIC AIRPORT P.O. - P.O. BOX NOS. 1000 TO 1099", "zip": "1375"},
  {"area": "PARANAQUE CITY DOMESTIC AIRPORT P.O. - P.O. BOX NOS. 1100 TO 1199", "zip": "1376"},
  {"area": "PARANAQUE CITY DOMESTIC AIRPORT P.O. - P.O. BOX NOS. 1200 TO 1299", "zip": "1377"},
  {"area": "PARANAQUE CITY DOMESTIC AIRPORT P.O. - P.O. BOX NOS. 1300 TO 1399", "zip": "1378"},
  {"area": "PARANAQUE CITY DOMESTIC AIRPORT P.O. - P.O. BOX NOS. 1400 TO 1499", "zip": "1379"},
  {"area": "PARANAQUE CITY DOMESTIC AIRPORT P.O. - P.O. BOX NOS. 1500 TO 1599", "zip": "1380"},
  {"area": "PARANAQUE CITY DOMESTIC AIRPORT P.O. - P.O. BOX NOS. 1600 TO 1699", "zip": "1381"},
  {"area": "PARANAQUE CITY EXECUTIVE HEIGHTS SUBDIVISION", "zip": "1710"},
  {"area": "PARANAQUE CITY IRENEVILLE SUBDIVISION I  III", "zip": "1719"},
  {"area": "PARANAQUE CITY IRENEVILLE SUBDIVISION II", "zip": "1714"},
  {"area": "PARANAQUE CITY MANILA MEMORIAL PARK", "zip": "1717"},
  {"area": "PARANAQUE CITY MARINA SUBDIVISION (RECLAMATION)", "zip": "1703"},
  {"area": "PARANAQUE CITY MAYWOOD VILLAGE I", "zip": "1719"},
  {"area": "PARANAQUE CITY MAYWOOD VILLAGE II", "zip": "1716"},
  {"area": "PARANAQUE CITY MIRAMAR SUBDIVISION", "zip": "1712"},
  {"area": "PARANAQUE CITY MULTINATIONAL VILLAGE", "zip": "1708"},
  {"area": "PARANAQUE CITY NINOY AQUINO INTERNATIONAL AIRPORT", "zip": "1705"},
  {"area": "PARANAQUE CITY PARANAQUE CPO", "zip": "1700"},
  {"area": "PARANAQUE CITY PULO", "zip": "1706"},
  {"area": "PARANAQUE CITY SAN ANTONIO VALLEY 1", "zip": "1715"},
  {"area": "PARANAQUE CITY SAN ANTONIO VALLEY 11  12", "zip": "1707"},
  {"area": "PARANAQUE CITY SANTO NINO", "zip": "1704"},
  {"area": "PARANAQUE CITY SOUTH ADMIRAL VILLAGE, MERVILLE PARK  MOONWALK SUBDIVISION", "zip": "1709"},
  {"area": "PARANAQUE CITY TAMBO", "zip": "1701"},
  {"area": "PARANAQUE CITY UNITED PARANAQUE SUBDIVISION", "zip": "1713"},
  {"area": "Pasay City Bay City", "zip": "1308"},
  {"area": "Pasay City Domestic Airport P.O.", "zip": "1301"},
  {"area": "Pasay City Pasay City Central Post Office", "zip": "1300"},
  {"area": "Pasay City Pasay City CPO - P.O. Box Nos. 1000 to 1099", "zip": "1350"},
  {"area": "Pasay City Pasay City CPO - P.O. Box Nos. 1100 to 1199", "zip": "1351"},
  {"area": "Pasay City Pasay City CPO - P.O. Box Nos. 1200 to 1299", "zip": "1352"},
  {"area": "Pasay City Pasay City CPO - P.O. Box Nos. 1300 to 1399", "zip": "1353"},
  {"area": "Pasay City Pasay City CPO - P.O. Box Nos. 1400 to 1499", "zip": "1354"},
  {"area": "Pasay City Philippine International Convention Center", "zip": "1307"},
  {"area": "Pasay City San Isidro", "zip": "1306"},
  {"area": "Pasay City San Jose", "zip": "1305"},
  {"area": "Pasay City San Rafael", "zip": "1302"},
  {"area": "Pasay City San Roque", "zip": "1303"},
  {"area": "Pasay City Santa Clara", "zip": "1304"},
  {"area": "Pasay City Villamor Airbase", "zip": "1309"},
  {"area": "Pasig City Caniogan", "zip": "1606"},
  {"area": "Pasig City Green Park", "zip": "1612"},
  {"area": "Pasig City Kapitolyo", "zip": "1603"},
  {"area": "Pasig City Manggahan", "zip": "1611"},
  {"area": "Pasig City Maybunga", "zip": "1607"},
  {"area": "Pasig City Ortigas Post Office", "zip": "1605"},
  {"area": "Pasig City Pasig Central Post Office", "zip": "1600"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 1000 to 1099", "zip": "1650"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 1100 to 1199", "zip": "1651"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 1200 to 1299", "zip": "1652"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 1300 to 1399", "zip": "1653"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 1400 to 1499", "zip": "1654"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 1500 to 1599", "zip": "1655"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 1600 to 1699", "zip": "1656"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 1700 to 1799", "zip": "1657"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 1800 to 1899", "zip": "1658"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 1900 to 1999", "zip": "1659"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 2000 to 2099", "zip": "1660"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 2100 to 2199", "zip": "1661"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 2200 to 2299", "zip": "1662"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 2300 to 2399", "zip": "1663"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 2400 to 2499", "zip": "1664"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 2500 to 2599", "zip": "1665"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 2600 to 2699", "zip": "1666"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 2700 to 2799", "zip": "1667"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 2800 to 2899", "zip": "1668"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 2900 to 2999", "zip": "1669"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 3000 to 3099", "zip": "1670"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 3100 to 3199", "zip": "1671"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 3200 to 3299", "zip": "1672"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 3300 to 3399", "zip": "1673"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 3400 to 3499", "zip": "1674"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 3500 to 3599", "zip": "1675"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 3600 to 3699", "zip": "1676"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 3700 to 3799", "zip": "1677"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 3800 to 3899", "zip": "1678"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 3900 to 3999", "zip": "1679"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 4000 to 4099", "zip": "1680"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 4100 to 4199", "zip": "1681"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 4200 to 4299", "zip": "1682"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 4300 to 4399", "zip": "1683"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 4400 to 4499", "zip": "1684"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 4500 to 4599", "zip": "1685"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 4600 to 4699", "zip": "1686"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 4700 to 4799", "zip": "1687"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 4800 to 4899", "zip": "1688"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 4900 to 4999", "zip": "1689"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 5000 to 5099", "zip": "1690"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 5100 to 5199", "zip": "1691"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 5200 to 5299", "zip": "1692"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 5300 to 5399", "zip": "1693"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 5400 to 5499", "zip": "1694"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 5500 to 5599", "zip": "1695"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 5600 to 5699", "zip": "1696"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 5700 to 5799", "zip": "1697"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 5800 to 5899", "zip": "1698"},
  {"area": "Pasig City Pasig Ortigas CTR-PO Box# 5900 to 5999", "zip": "1699"},
  {"area": "Pasig City Pinagbuhatan", "zip": "1602"},
  {"area": "Pasig City Rosario", "zip": "1609"},
  {"area": "Pasig City San Joaquin", "zip": "1601"},
  {"area": "Pasig City Santa Lucia", "zip": "1608"},
  {"area": "Pasig City Santolan", "zip": "1610"},
  {"area": "Pasig City Ugong", "zip": "1604"},
  {"area": "Pateros Aguho", "zip": "1620"},
  {"area": "Pateros Santa Ana", "zip": "1621"},
  {"area": "Quezon Agdangan", "zip": "4304"},
  {"area": "Quezon Alabat", "zip": "4333"},
  {"area": "Quezon Atimonan", "zip": "4331"},
  {"area": "Quezon Buenavista", "zip": "4320"},
  {"area": "Quezon Burdeos", "zip": "4340"},
  {"area": "Quezon Calauag", "zip": "4318"},
  {"area": "Quezon Candelaria", "zip": "4323"},
  {"area": "Quezon Catanauan", "zip": "4311"},
  {"area": "Quezon City Alicia", "zip": "1105"},
  {"area": "Quezon City Amihan", "zip": "1102"},
  {"area": "Quezon City Apolonio Samson", "zip": "1106"},
  {"area": "Quezon City Araneta Center", "zip": "810"},
  {"area": "Quezon City Araneta Center P.O. Boxes", "zip": "1135"},
  {"area": "Quezon City Baesa", "zip": "1106"},
  {"area": "Quezon City Bagbag", "zip": "1116"},
  {"area": "Quezon City Bagong Bayan", "zip": "1110"},
  {"area": "Quezon City Bagong Buhay", "zip": "1109"},
  {"area": "Quezon City Bagong Lipunan", "zip": "1111"},
  {"area": "Quezon City Bagong Pag-Asa", "zip": "1105"},
  {"area": "Quezon City Bagong Silangan", "zip": "1119"},
  {"area": "Quezon City Bahay Toro", "zip": "1106"},
  {"area": "Quezon City Balingasa", "zip": "1115"},
  {"area": "Quezon City Balintawak", "zip": "1106"},
  {"area": "Quezon City Balumbato", "zip": "1106"},
  {"area": "Quezon City Batasan Hills", "zip": "1126"},
  {"area": "Quezon City Bayanihan", "zip": "1109"},
  {"area": "Quezon City BF Homes", "zip": "1120"},
  {"area": "Quezon City Blue Ridge", "zip": "1109"},
  {"area": "Quezon City Botocan", "zip": "1101"},
  {"area": "Quezon City Broadway Center P.O. Boxes", "zip": "1141"},
  {"area": "Quezon City Bungad", "zip": "1105"},
  {"area": "Quezon City Bureau of Internal Revenue", "zip": "820"},
  {"area": "Quezon City Camp Aguinaldo", "zip": "1110"},
  {"area": "Quezon City Camp Aguinaldo", "zip": "802"},
  {"area": "Quezon City Camp Crame", "zip": "801"},
  {"area": "Quezon City Capitol Hills/Park", "zip": "1126"},
  {"area": "Quezon City Capri", "zip": "1117"},
  {"area": "Quezon City Central", "zip": "1100"},
  {"area": "Quezon City Claro", "zip": "1102"},
  {"area": "Quezon City Commission on Audit", "zip": "880"},
  {"area": "Quezon City Commonwealth", "zip": "1121"},
  {"area": "Quezon City Crame", "zip": "1111"},
  {"area": "Quezon City Cubao", "zip": "1109"},
  {"area": "Quezon City Culiat", "zip": "1128"},
  {"area": "Quezon City Damar", "zip": "1115"},
  {"area": "Quezon City Damayan", "zip": "1104"},
  {"area": "Quezon City Damayan Lagi", "zip": "1112"},
  {"area": "Quezon City Damong Maliit", "zip": "1123"},
  {"area": "Quezon City Del Monte", "zip": "1105"},
  {"area": "Quezon City Diliman", "zip": "1104"},
  {"area": "Quezon City Dioquino Zobel", "zip": "1109"},
  {"area": "Quezon City Do?a Aurora", "zip": "1113"},
  {"area": "Quezon City Do?a Faustina Subdivision", "zip": "1125"},
  {"area": "Quezon City Do?a Imelda", "zip": "1113"},
  {"area": "Quezon City Do?a Josefa", "zip": "1113"},
  {"area": "Quezon City Don Manuel", "zip": "1113"},
  {"area": "QUEZON CITY DONA AURORA", "zip": "1113"},
  {"area": "QUEZON CITY DONA FAUSTINA SUBDIVISION", "zip": "1125"},
  {"area": "QUEZON CITY DONA IMELDA", "zip": "1113"},
  {"area": "QUEZON CITY DONA JOSEFA", "zip": "1113"},
  {"area": "Quezon City Duyan-Duyan", "zip": "1102"},
  {"area": "Quezon City E. Rodriguez", "zip": "1102"},
  {"area": "Quezon City Escopa", "zip": "1109"},
  {"area": "Quezon City Fairview", "zip": "1118"},
  {"area": "Quezon City Fairview [North]", "zip": "1121"},
  {"area": "Quezon City Fairview [South]", "zip": "1122"},
  {"area": "Quezon City Gintong Silahis", "zip": "1114"},
  {"area": "Quezon City Gulod", "zip": "1117"},
  {"area": "Quezon City Holy Spirit", "zip": "1127"},
  {"area": "Quezon City Horseshoe", "zip": "1112"},
  {"area": "Quezon City Immaculate Concepcion", "zip": "1111"},
  {"area": "Quezon City Kaligayahan", "zip": "1124"},
  {"area": "Quezon City Kalusugan", "zip": "1112"},
  {"area": "Quezon City Kamias", "zip": "1102"},
  {"area": "Quezon City Kamuning", "zip": "1103"},
  {"area": "Quezon City Katipunan", "zip": "1105"},
  {"area": "Quezon City Kaunlaran", "zip": "1111"},
  {"area": "Quezon City Kristong Hari", "zip": "1112"},
  {"area": "Quezon City Krus na Ligas", "zip": "1101"},
  {"area": "Quezon City La Loma", "zip": "1114"},
  {"area": "Quezon City Laging Handa", "zip": "1103"},
  {"area": "Quezon City Libis", "zip": "1110"},
  {"area": "Quezon City Lourdes", "zip": "1114"},
  {"area": "Quezon City Loyola Heights", "zip": "1108"},
  {"area": "Quezon City Maharlika", "zip": "1114"},
  {"area": "Quezon City Malaya", "zip": "1101"},
  {"area": "Quezon City Mangga", "zip": "1109"},
  {"area": "Quezon City Manresa", "zip": "1115"},
  {"area": "Quezon City Mariana", "zip": "1112"},
  {"area": "Quezon City Mariblo", "zip": "1104"},
  {"area": "Quezon City Marilag", "zip": "1109"},
  {"area": "Quezon City Masagana", "zip": "1109"},
  {"area": "Quezon City Masambong", "zip": "1115"},
  {"area": "Quezon City Matalahib", "zip": "1114"},
  {"area": "Quezon City Matandang Balara", "zip": "1119"},
  {"area": "Quezon City Milagrosa", "zip": "1109"},
  {"area": "Quezon City Murphy District P.O. Boxes", "zip": "1138"},
  {"area": "Quezon City Nagkaisang Nayon", "zip": "1125"},
  {"area": "Quezon City National Irrigation Administration", "zip": "830"},
  {"area": "Quezon City Nayon Kaunlaran", "zip": "1104"},
  {"area": "Quezon City New Era", "zip": "1107"},
  {"area": "Quezon City Novaliches P.O. Boxes", "zip": "1147"},
  {"area": "Quezon City Novaliches Proper", "zip": "1123"},
  {"area": "Quezon City Obrero", "zip": "1103"},
  {"area": "Quezon City Old Capitol Site", "zip": "1101"},
  {"area": "Quezon City Paang Bundok", "zip": "1114"},
  {"area": "Quezon City Pag-Ibig sa Nayon", "zip": "1115"},
  {"area": "Quezon City Paligsahan", "zip": "1103"},
  {"area": "Quezon City Paltok", "zip": "1105"},
  {"area": "Quezon City Pansol", "zip": "1108"},
  {"area": "Quezon City Paraiso", "zip": "1104"},
  {"area": "Quezon City Pasong Putik", "zip": "1118"},
  {"area": "Quezon City Pasong Tamo", "zip": "1107"},
  {"area": "Quezon City Payatas", "zip": "1119"},
  {"area": "Quezon City Phil-Am", "zip": "1104"},
  {"area": "Quezon City Philippine Heart Center", "zip": "850"},
  {"area": "Quezon City Pi?ahan", "zip": "1100"},
  {"area": "Quezon City Pinagkaisahan", "zip": "1111"},
  {"area": "QUEZON CITY PINAHAN", "zip": "1100"},
  {"area": "Quezon City Project 4", "zip": "1109"},
  {"area": "Quezon City Project 6", "zip": "1100"},
  {"area": "Quezon City Project 7", "zip": "1105"},
  {"area": "Quezon City Project 8", "zip": "1106"},
  {"area": "Quezon City Quezon City CPO", "zip": "1100"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 1000 to 1099", "zip": "1150"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 1100 to 1199", "zip": "1151"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 1200 to 1299", "zip": "1152"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 1300 to 1399", "zip": "1153"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 1400 to 1499", "zip": "1154"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 1500 to 1599", "zip": "1155"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 1600 to 1699", "zip": "1156"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 1700 to 1799", "zip": "1157"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 1800 to 1899", "zip": "1158"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 1900 to 1999", "zip": "1159"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 2000 to 2099", "zip": "1160"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 2100 to 2199", "zip": "1161"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 2200 to 2299", "zip": "1162"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 2300 to 2399", "zip": "1163"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 2400 to 2499", "zip": "1164"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 2500 to 2599", "zip": "1165"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 2600 to 2699", "zip": "1166"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 2700 to 2799", "zip": "1167"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 2800 to 2899", "zip": "1168"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 2900 to 2999", "zip": "1169"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 3000 to 3099", "zip": "1170"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 3100 to 3199", "zip": "1171"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 3200 to 3299", "zip": "1172"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 3300 to 3399", "zip": "1173"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 3400 to 3499", "zip": "1174"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 3500 to 3599", "zip": "1175"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 3600 to 3699", "zip": "1176"},
  {"area": "Quezon City Quezon City CPO - P.O. Box Nos. 3700 to 3799", "zip": "1177"},
  {"area": "Quezon City Quezon City Hall", "zip": "860"},
  {"area": "Quezon City Quirino District (Project 2 & 3)", "zip": "1102"},
  {"area": "Quezon City R. Magsaysay", "zip": "1105"},
  {"area": "Quezon City Roxas District", "zip": "1103"},
  {"area": "Quezon City Sacred Heart", "zip": "1103"},
  {"area": "Quezon City Salvacion", "zip": "1114"},
  {"area": "Quezon City San Agustin", "zip": "1117"},
  {"area": "Quezon City San Antonio, Quezon City", "zip": "1105"},
  {"area": "Quezon City San Bartolome", "zip": "1116"},
  {"area": "Quezon City San Isidro", "zip": "1113"},
  {"area": "Quezon City San Isidro Labrador", "zip": "1114"},
  {"area": "Quezon City San Jose", "zip": "1115"},
  {"area": "Quezon City San Roque", "zip": "1109"},
  {"area": "Quezon City San Vicente", "zip": "1101"},
  {"area": "Quezon City Sangandaan", "zip": "1116"},
  {"area": "Quezon City Santa Cruz", "zip": "1104"},
  {"area": "Quezon City Santa Lucia", "zip": "1117"},
  {"area": "Quezon City Santa Monica", "zip": "1117"},
  {"area": "Quezon City Santa Teresita", "zip": "1114"},
  {"area": "Quezon City Santo Cristo", "zip": "1105"},
  {"area": "Quezon City Santo Ni?o", "zip": "1113"},
  {"area": "QUEZON CITY SANTO NINO", "zip": "1113"},
  {"area": "Quezon City Santol", "zip": "1113"},
  {"area": "Quezon City Sauyo", "zip": "1116"},
  {"area": "Quezon City Sienna", "zip": "1114"},
  {"area": "Quezon City Sikatuna Village", "zip": "1101"},
  {"area": "Quezon City Silangan", "zip": "1102"},
  {"area": "Quezon City Social Security System", "zip": "800"},
  {"area": "Quezon City Socorro", "zip": "1109"},
  {"area": "Quezon City South Triangle", "zip": "1103"},
  {"area": "Quezon City St. Ignatius", "zip": "1110"},
  {"area": "Quezon City St. Martin de Porres", "zip": "1111"},
  {"area": "Quezon City St. Peter", "zip": "1114"},
  {"area": "Quezon City Tagumpay", "zip": "1109"},
  {"area": "Quezon City Talampas", "zip": "1110"},
  {"area": "Quezon City Talayan", "zip": "1104"},
  {"area": "Quezon City Talipapa", "zip": "1116"},
  {"area": "Quezon City Tandang Sora", "zip": "1116"},
  {"area": "Quezon City Tatalon", "zip": "1113"},
  {"area": "Quezon City Teachers Village", "zip": "1101"},
  {"area": "Quezon City Ugong Norte", "zip": "1110"},
  {"area": "Quezon City Unang Sigaw", "zip": "1106"},
  {"area": "Quezon City University of the Philippines P.O. Boxes", "zip": "1144"},
  {"area": "Quezon City University of the Philippines Post Office", "zip": "1101"},
  {"area": "Quezon City University of the Philippines Village", "zip": "1101"},
  {"area": "Quezon City V. Luna Hospital", "zip": "840"},
  {"area": "Quezon City Valencia", "zip": "1112"},
  {"area": "Quezon City Vasra", "zip": "1128"},
  {"area": "Quezon City Veterans Hospital", "zip": "870"},
  {"area": "Quezon City Veterans Village", "zip": "1105"},
  {"area": "Quezon City Villa Maria Clara", "zip": "1109"},
  {"area": "Quezon City Violago Homes", "zip": "1120"},
  {"area": "Quezon City West Triangle", "zip": "1104"},
  {"area": "Quezon City White Plains", "zip": "1110"},
  {"area": "Quezon Dolores", "zip": "4326"},
  {"area": "Quezon General Antonio Luna", "zip": "4310"},
  {"area": "Quezon General Nakar", "zip": "4338"},
  {"area": "Quezon Guinayangan", "zip": "4319"},
  {"area": "Quezon Gumaca", "zip": "4307"},
  {"area": "Quezon Hondagua", "zip": "4317"},
  {"area": "Quezon Infanta", "zip": "4336"},
  {"area": "Quezon Jomalig", "zip": "4342"},
  {"area": "Quezon Lopez", "zip": "4316"},
  {"area": "Quezon Lucban", "zip": "4328"},
  {"area": "Quezon Lucena City", "zip": "4301"},
  {"area": "Quezon Macalelon", "zip": "4309"},
  {"area": "Quezon Mauban", "zip": "4330"},
  {"area": "Quezon Mulanay", "zip": "4312"},
  {"area": "Quezon Padre Burgos", "zip": "4303"},
  {"area": "Quezon Pagbilao", "zip": "4302"},
  {"area": "Quezon Panukulan", "zip": "4337"},
  {"area": "Quezon Patnanungan", "zip": "4341"},
  {"area": "Quezon Perez", "zip": "4334"},
  {"area": "Quezon Pitogo", "zip": "4308"},
  {"area": "Quezon Plaridel", "zip": "4306"},
  {"area": "Quezon Polilio", "zip": "4339"},
  {"area": "QUEZON PROVINCE AGDANGAN", "zip": "4304"},
  {"area": "QUEZON PROVINCE ALABAT", "zip": "4333"},
  {"area": "QUEZON PROVINCE ATIMONAN", "zip": "4331"},
  {"area": "QUEZON PROVINCE BUENAVISTA", "zip": "4320"},
  {"area": "QUEZON PROVINCE BURDEOS", "zip": "4340"},
  {"area": "QUEZON PROVINCE CALAUAG", "zip": "4318"},
  {"area": "QUEZON PROVINCE CANDELARIA", "zip": "4323"},
  {"area": "QUEZON PROVINCE CATANAUAN", "zip": "4311"},
  {"area": "QUEZON PROVINCE DOLORES", "zip": "4326"},
  {"area": "QUEZON PROVINCE GENERAL ANTONIO LUNA", "zip": "4310"},
  {"area": "QUEZON PROVINCE GENERAL NAKAR", "zip": "4338"},
  {"area": "QUEZON PROVINCE GUINAYANGAN", "zip": "4319"},
  {"area": "QUEZON PROVINCE GUMACA", "zip": "4307"},
  {"area": "QUEZON PROVINCE HONDAGUA", "zip": "4317"},
  {"area": "QUEZON PROVINCE INFANTA", "zip": "4336"},
  {"area": "QUEZON PROVINCE JOMALIG", "zip": "4342"},
  {"area": "QUEZON PROVINCE LOPEZ", "zip": "4316"},
  {"area": "QUEZON PROVINCE LUCBAN", "zip": "4328"},
  {"area": "QUEZON PROVINCE LUCENA CITY", "zip": "4301"},
  {"area": "QUEZON PROVINCE MACALELON", "zip": "4309"},
  {"area": "QUEZON PROVINCE MAUBAN", "zip": "4330"},
  {"area": "QUEZON PROVINCE MULANAY", "zip": "4312"},
  {"area": "QUEZON PROVINCE PADRE BURGOS", "zip": "4303"},
  {"area": "QUEZON PROVINCE PAGBILAO", "zip": "4302"},
  {"area": "QUEZON PROVINCE PANUKULAN", "zip": "4337"},
  {"area": "QUEZON PROVINCE PATNANUNGAN", "zip": "4341"},
  {"area": "QUEZON PROVINCE PEREZ", "zip": "4334"},
  {"area": "QUEZON PROVINCE PITOGO", "zip": "4308"},
  {"area": "QUEZON PROVINCE PLARIDEL", "zip": "4306"},
  {"area": "QUEZON PROVINCE POLILIO", "zip": "4339"},
  {"area": "QUEZON PROVINCE PROVINCIAL CAPITOL, LUCENA CITY", "zip": "4300"},
  {"area": "QUEZON PROVINCE QUEZON", "zip": "4332"},
  {"area": "QUEZON PROVINCE REAL", "zip": "4335"},
  {"area": "QUEZON PROVINCE SAMPALOC", "zip": "4329"},
  {"area": "QUEZON PROVINCE SAN ANDRES", "zip": "4314"},
  {"area": "QUEZON PROVINCE SAN ANTONIO", "zip": "4324"},
  {"area": "QUEZON PROVINCE SAN FRANCISCO (AURORA)", "zip": "4315"},
  {"area": "QUEZON PROVINCE SAN NARCISO", "zip": "4313"},
  {"area": "QUEZON PROVINCE SARIAYA", "zip": "4322"},
  {"area": "QUEZON PROVINCE TAGKAWAYAN", "zip": "4321"},
  {"area": "QUEZON PROVINCE TAYABAS", "zip": "4327"},
  {"area": "QUEZON PROVINCE TIAONG", "zip": "4325"},
  {"area": "QUEZON PROVINCE UNISAN", "zip": "4305"},
  {"area": "Quezon Provincial Capitol, Lucena City", "zip": "4300"},
  {"area": "Quezon Quezon", "zip": "4332"},
  {"area": "Quezon Real", "zip": "4335"},
  {"area": "Quezon Sampaloc", "zip": "4329"},
  {"area": "Quezon San Andres", "zip": "4314"},
  {"area": "Quezon San Antonio", "zip": "4324"},
  {"area": "Quezon San Francisco (Aurora)", "zip": "4315"},
  {"area": "Quezon San Narciso", "zip": "4313"},
  {"area": "Quezon Sariaya", "zip": "4322"},
  {"area": "Quezon Tagkawayan", "zip": "4321"},
  {"area": "Quezon Tayabas", "zip": "4327"},
  {"area": "Quezon Tiaong", "zip": "4325"},
  {"area": "Quezon Unisan", "zip": "4305"},
  {"area": "Quirino Aglipay", "zip": "3403"},
  {"area": "Quirino Cabarroguis", "zip": "3400"},
  {"area": "Quirino Diffun", "zip": "3401"},
  {"area": "Quirino Maddela", "zip": "3404"},
  {"area": "Quirino Nagtipunan (Abbag)", "zip": "3405"},
  {"area": "Quirino Saguday", "zip": "3402"},
  {"area": "Rizal Angono", "zip": "1930"},
  {"area": "Rizal Antipolo City", "zip": "1870"},
  {"area": "Rizal Baras", "zip": "1970"},
  {"area": "Rizal Binangonan", "zip": "1940"},
  {"area": "Rizal Cainta", "zip": "1900"},
  {"area": "Rizal Cardona", "zip": "1950"},
  {"area": "Rizal Jalajala", "zip": "1900"},
  {"area": "Rizal Morong", "zip": "1960"},
  {"area": "Rizal Pililla", "zip": "1910"},
  {"area": "Rizal Rodriguez", "zip": "1860"},
  {"area": "Rizal San Mateo", "zip": "1850"},
  {"area": "Rizal Tanay", "zip": "1980"},
  {"area": "Rizal Taytay", "zip": "1920"},
  {"area": "Rizal Teresa", "zip": "1880"},
  {"area": "Romblon Alcantara", "zip": "5509"},
  {"area": "Romblon Banton (Jones)", "zip": "5515"},
  {"area": "Romblon Cajidiocan", "zip": "5512"},
  {"area": "Romblon Calatrava", "zip": "5503"},
  {"area": "Romblon Concepcion", "zip": "5516"},
  {"area": "Romblon Corcuera", "zip": "5514"},
  {"area": "Romblon Ferrol", "zip": "5506"},
  {"area": "Romblon Imelda", "zip": "5502"},
  {"area": "Romblon Looc", "zip": "5507"},
  {"area": "Romblon Magdiwang", "zip": "5511"},
  {"area": "Romblon Odiongan", "zip": "5505"},
  {"area": "Romblon Romblon", "zip": "5500"},
  {"area": "Romblon San Agustin", "zip": "5501"},
  {"area": "Romblon San Andres", "zip": "5504"},
  {"area": "Romblon San Fernando", "zip": "5513"},
  {"area": "Romblon San Jose", "zip": "5510"},
  {"area": "Romblon Santa Fe", "zip": "5508"},
  {"area": "San Juan City Asian Development Bank", "zip": "401"},
  {"area": "San Juan City Bible School on the Air", "zip": "420"},
  {"area": "San Juan City Eisenhower - Crame", "zip": "1504"},
  {"area": "San Juan City Greenhills [North]", "zip": "1503"},
  {"area": "San Juan City Greenhills P.O. Box Nos. 1000 to 1099", "zip": "1530"},
  {"area": "San Juan City Greenhills P.O. Box Nos. 1100 to 1199", "zip": "1531"},
  {"area": "San Juan City Greenhills P.O. Box Nos. 1200 to 1299", "zip": "1532"},
  {"area": "San Juan City Greenhills P.O. Box Nos. 1300 to 1399", "zip": "1533"},
  {"area": "San Juan City Greenhills P.O. Box Nos. 1400 to 1499", "zip": "1534"},
  {"area": "San Juan City Greenhills P.O. Box Nos. 1500 to 1599", "zip": "1535"},
  {"area": "San Juan City Greenhills P.O. Box Nos. 1600 to 1699", "zip": "1536"},
  {"area": "San Juan City Greenhills Post Office", "zip": "1502"},
  {"area": "San Juan City International Correspondence School", "zip": "400"},
  {"area": "San Juan City Radio Bible Class", "zip": "410"},
  {"area": "San Juan City San Juan Central Post Office", "zip": "1500"},
  {"area": "Sarangani Alabel", "zip": "9501"},
  {"area": "Sarangani Glan", "zip": "9517"},
  {"area": "Sarangani Kiamba", "zip": "9514"},
  {"area": "Sarangani Maasim", "zip": "9502"},
  {"area": "Sarangani Maitum", "zip": "9515"},
  {"area": "Sarangani Malapatan", "zip": "9516"},
  {"area": "Sarangani Malungon", "zip": "9503"},
  {"area": "Shariff Kabunsuan Ampatuan City", "zip": "9603"},
  {"area": "Shariff Kabunsuan Barira", "zip": "9614"},
  {"area": "Shariff Kabunsuan Buldon", "zip": "9615"},
  {"area": "Shariff Kabunsuan Datu Odin Sinsuat (Dinaig)", "zip": "9601"},
  {"area": "Shariff Kabunsuan Kabuntalan/Northern Kabuntalan", "zip": "9606"},
  {"area": "Shariff Kabunsuan Matanog", "zip": "9613"},
  {"area": "Shariff Kabunsuan Parang", "zip": "9604"},
  {"area": "Shariff Kabunsuan Sultan Kudarat/Sultan Mastura", "zip": "9605"},
  {"area": "Shariff Kabunsuan Upi/Datu Blah T. Sinsuat", "zip": "9602"},
  {"area": "Siquijor Enrique Villanueva", "zip": "6230"},
  {"area": "Siquijor Larena", "zip": "6226"},
  {"area": "Siquijor Lazi", "zip": "6228"},
  {"area": "Siquijor Maria", "zip": "6229"},
  {"area": "Siquijor San Juan", "zip": "6227"},
  {"area": "Siquijor Siquij", "zip": "6225"},
  {"area": "SIQUIJOR SIQUIJOR", "zip": "6225"},
  {"area": "Sorsogon Bacon", "zip": "4701"},
  {"area": "Sorsogon Barcelona", "zip": "4712"},
  {"area": "Sorsogon Bulan", "zip": "4706"},
  {"area": "Sorsogon Bulusan", "zip": "4704"},
  {"area": "Sorsogon Casiguran", "zip": "4702"},
  {"area": "Sorsogon Castilla", "zip": "4713"},
  {"area": "Sorsogon Donsol", "zip": "4715"},
  {"area": "Sorsogon Gubat", "zip": "4710"},
  {"area": "Sorsogon Irosin", "zip": "4707"},
  {"area": "Sorsogon Juban", "zip": "4703"},
  {"area": "Sorsogon Magallanes", "zip": "4705"},
  {"area": "Sorsogon Matnog", "zip": "4708"},
  {"area": "Sorsogon Pilar", "zip": "4714"},
  {"area": "Sorsogon Prieto Diaz", "zip": "4711"},
  {"area": "Sorsogon Santa Magdalena", "zip": "4709"},
  {"area": "Sorsogon Sorsogon", "zip": "4700"},
  {"area": "South Cotabato Banga", "zip": "9511"},
  {"area": "South Cotabato General Santos City (Dadiangas)", "zip": "9500"},
  {"area": "South Cotabato Koronadal City", "zip": "9506"},
  {"area": "South Cotabato Norala", "zip": "9508"},
  {"area": "South Cotabato Polomolok", "zip": "9504"},
  {"area": "South Cotabato Sto. Ni?o", "zip": "9509"},
  {"area": "SOUTH COTABATO STO. NINO", "zip": "9509"},
  {"area": "South Cotabato Surallah", "zip": "9512"},
  {"area": "South Cotabato T`Boli", "zip": "9513"},
  {"area": "South Cotabato Tampakan", "zip": "9507"},
  {"area": "South Cotabato Tantangan", "zip": "9510"},
  {"area": "South Cotabato Tupi", "zip": "9505"},
  {"area": "Southern Leyte Anahawan", "zip": "6610"},
  {"area": "Southern Leyte Bontoc", "zip": "6604"},
  {"area": "Southern Leyte Hinunangan", "zip": "6608"},
  {"area": "Southern Leyte Hinundayan", "zip": "6609"},
  {"area": "Southern Leyte Libagon", "zip": "6615"},
  {"area": "Southern Leyte Liloan", "zip": "6612"},
  {"area": "Southern Leyte Maasin", "zip": "6600"},
  {"area": "Southern Leyte Macrohon", "zip": "6601"},
  {"area": "Southern Leyte Malitbog", "zip": "6603"},
  {"area": "Southern Leyte Padre Burgos", "zip": "6602"},
  {"area": "Southern Leyte Pintuyan", "zip": "6614"},
  {"area": "Southern Leyte San Francisco", "zip": "6613"},
  {"area": "Southern Leyte San Juan (Cabalian)", "zip": "6611"},
  {"area": "Southern Leyte San Ricardo", "zip": "6617"},
  {"area": "Southern Leyte Silago", "zip": "6607"},
  {"area": "Southern Leyte Sogod", "zip": "6606"},
  {"area": "Southern Leyte St. Bernard", "zip": "6616"},
  {"area": "Southern Leyte Tomas Oppus", "zip": "6605"},
  {"area": "Sultan Kudarat Bagumbayan", "zip": "9810"},
  {"area": "Sultan Kudarat Columbio", "zip": "9801"},
  {"area": "Sultan Kudarat Esperanza (Ampatuan)", "zip": "9806"},
  {"area": "Sultan Kudarat Isulan", "zip": "9805"},
  {"area": "Sultan Kudarat Kalamansig", "zip": "9808"},
  {"area": "Sultan Kudarat Lebak (Salaman)", "zip": "9807"},
  {"area": "Sultan Kudarat Lutayan", "zip": "9803"},
  {"area": "Sultan Kudarat Mariano Marcos", "zip": "9802"},
  {"area": "Sultan Kudarat Palimbang", "zip": "9809"},
  {"area": "Sultan Kudarat Pres. Quirino", "zip": "9804"},
  {"area": "Sultan Kudarat Sen. Ninoy Aquino", "zip": "9811"},
  {"area": "Sultan Kudarat Tacurong", "zip": "9800"},
  {"area": "Sulu Indanan", "zip": "7407"},
  {"area": "Sulu Jolo", "zip": "7400"},
  {"area": "Sulu Kalingalan Kalauang", "zip": "7416"},
  {"area": "Sulu Lugus", "zip": "7411"},
  {"area": "Sulu Luuk", "zip": "7404"},
  {"area": "Sulu Maimbung", "zip": "7409"},
  {"area": "Sulu Marungas", "zip": "7413"},
  {"area": "Sulu Panamao", "zip": "7402"},
  {"area": "Sulu Panglima Estino", "zip": "7415"},
  {"area": "Sulu Panguntaran", "zip": "7414"},
  {"area": "Sulu Parang", "zip": "7408"},
  {"area": "Sulu Pata", "zip": "7405"},
  {"area": "Sulu Patikul", "zip": "7401"},
  {"area": "Sulu Siasi", "zip": "7412"},
  {"area": "Sulu Talipao", "zip": "7403"},
  {"area": "Sulu Tapul", "zip": "7410"},
  {"area": "Sulu Tongkil", "zip": "7406"},
  {"area": "Surigao del Norte Alegria", "zip": "8425"},
  {"area": "Surigao del Norte Bacuag", "zip": "8408"},
  {"area": "Surigao del Norte Burgos", "zip": "8424"},
  {"area": "Surigao del Norte Claver", "zip": "8410"},
  {"area": "Surigao del Norte Dapa", "zip": "8417"},
  {"area": "Surigao del Norte Del Carmen", "zip": "8418"},
  {"area": "Surigao del Norte Gen. Luna", "zip": "8419"},
  {"area": "Surigao del Norte Gigaquit", "zip": "8409"},
  {"area": "Surigao del Norte Mainit", "zip": "8407"},
  {"area": "Surigao del Norte Malimano", "zip": "8402"},
  {"area": "Surigao del Norte Pilar", "zip": "8420"},
  {"area": "Surigao del Norte Placer", "zip": "8405"},
  {"area": "Surigao del Norte San Benito", "zip": "8423"},
  {"area": "Surigao del Norte San Francisco", "zip": "8401"},
  {"area": "Surigao del Norte San Isidro", "zip": "8421"},
  {"area": "Surigao del Norte Santa Monica", "zip": "8422"},
  {"area": "Surigao del Norte Sison", "zip": "8404"},
  {"area": "Surigao del Norte Socorro", "zip": "8416"},
  {"area": "Surigao del Norte Surigao City", "zip": "8400"},
  {"area": "Surigao del Norte Tagana-an", "zip": "8403"},
  {"area": "Surigao del Norte Tubod", "zip": "8406"},
  {"area": "Surigao del Sur Barobo", "zip": "8309"},
  {"area": "Surigao del Sur Bayabas", "zip": "8303"},
  {"area": "Surigao del Sur Bislig", "zip": "8311"},
  {"area": "Surigao del Sur Cagwait", "zip": "8304"},
  {"area": "Surigao del Sur Cantillan", "zip": "8317"},
  {"area": "Surigao del Sur Carmen", "zip": "8315"},
  {"area": "Surigao del Sur Carrascal", "zip": "8318"},
  {"area": "Surigao del Sur Cortez", "zip": "8313"},
  {"area": "Surigao del Sur Hinatuan", "zip": "8310"},
  {"area": "Surigao del Sur Lanuza", "zip": "8314"},
  {"area": "Surigao del Sur Lianga", "zip": "8307"},
  {"area": "Surigao del Sur Lingig", "zip": "8312"},
  {"area": "Surigao del Sur Madrid", "zip": "8316"},
  {"area": "Surigao del Sur Marihatag", "zip": "8306"},
  {"area": "Surigao del Sur San Agustin", "zip": "8305"},
  {"area": "Surigao del Sur San Miguel", "zip": "8301"},
  {"area": "Surigao del Sur Tagbina", "zip": "8308"},
  {"area": "Surigao del Sur Tago", "zip": "8302"},
  {"area": "Surigao del Sur Tandag", "zip": "8300"},
  {"area": "Taguig City Bay Breeze Executive Village", "zip": "1636"},
  {"area": "Taguig City Bicutan", "zip": "1631"},
  {"area": "Taguig City Ligid", "zip": "1638"},
  {"area": "Taguig City Nichols - McKinley", "zip": "1634"},
  {"area": "Taguig City Tuktukan", "zip": "1637"},
  {"area": "Taguig City Upper Bicutan", "zip": "1633"},
  {"area": "Taguig City ususan", "zip": "1632"},
  {"area": "Taguig City Western Bicutan (including Food Terminal, Inc. complex)", "zip": "1630"},
  {"area": "Tarlac [Paniqui]", "zip": "2307"},
  {"area": "Tarlac Anao", "zip": "2310"},
  {"area": "Tarlac Bamban", "zip": "2317"},
  {"area": "Tarlac Bongao", "zip": "7500"},
  {"area": "Tarlac Camiling", "zip": "2306"},
  {"area": "Tarlac Capas", "zip": "2315"},
  {"area": "Tarlac Capas", "zip": "2333"},
  {"area": "Tarlac Concepcion", "zip": "2316"},
  {"area": "Tarlac Gerona", "zip": "2302"},
  {"area": "Tarlac La Paz", "zip": "2314"},
  {"area": "Tarlac Languyan", "zip": "7509"},
  {"area": "Tarlac Mapun (Cagayan de Sulu)", "zip": "7508"},
  {"area": "Tarlac Mayantoc", "zip": "2304"},
  {"area": "Tarlac Moncada", "zip": "2308"},
  {"area": "Tarlac Moncada", "zip": "2334"},
  {"area": "Tarlac Panglima Sugala (Balimbing)", "zip": "7501"},
  {"area": "TARLAC PANIQUI", "zip": "2307"},
  {"area": "Tarlac Pura", "zip": "2312"},
  {"area": "Tarlac Ramos", "zip": "2311"},
  {"area": "Tarlac San Clemente", "zip": "2305"},
  {"area": "Tarlac San Jose", "zip": "2301"},
  {"area": "Tarlac San Manuel", "zip": "2309"},
  {"area": "TARLAC SAN MIGUEL", "zip": "2301"},
  {"area": "Tarlac Santa Ignacia", "zip": "2303"},
  {"area": "Tarlac Sapa-Sapa", "zip": "7503"},
  {"area": "Tarlac Simunul", "zip": "7505"},
  {"area": "Tarlac Sitangkai", "zip": "7506"},
  {"area": "Tarlac South Ubian", "zip": "7504"},
  {"area": "Tarlac Tandubas", "zip": "7502"},
  {"area": "Tarlac Tarlac City", "zip": "2300"},
  {"area": "Tarlac Turtle Island (Taganak)", "zip": "7507"},
  {"area": "Tarlac Victoria", "zip": "2313"},
  {"area": "TAWI-TAWI BONGAO", "zip": "7500"},
  {"area": "TAWI-TAWI LANGUYAN", "zip": "7509"},
  {"area": "TAWI-TAWI MAPUN (CAGAYAN DE SULU)", "zip": "7508"},
  {"area": "TAWI-TAWI PANGLIMA SUGALA (BALIMBING)", "zip": "7501"},
  {"area": "TAWI-TAWI SAPA-SAPA", "zip": "7503"},
  {"area": "TAWI-TAWI SIMUNUL", "zip": "7505"},
  {"area": "TAWI-TAWI SITANGKAI", "zip": "7506"},
  {"area": "TAWI-TAWI SOUTH UBIAN", "zip": "7504"},
  {"area": "TAWI-TAWI TANDUBAS", "zip": "7502"},
  {"area": "TAWI-TAWI TURTLE ISLAND (TAGANAK)", "zip": "7507"},
  {"area": "Valenzuela City Arkong Bato", "zip": "1444"},
  {"area": "Valenzuela City Balangkas - Caloong", "zip": "1445"},
  {"area": "Valenzuela City Dalandanan - West Canumay", "zip": "1443"},
  {"area": "Valenzuela City East Canumay - Lawang Bato Punturin", "zip": "1447"},
  {"area": "Valenzuela City Far East Broadcasting Corporation", "zip": "560"},
  {"area": "Valenzuela City Febias College of Bible", "zip": "550"},
  {"area": "Valenzuela City Fortune Village", "zip": "1442"},
  {"area": "Valenzuela City General T. de Leon", "zip": "1442"},
  {"area": "Valenzuela City Karuhatan", "zip": "1441"},
  {"area": "Valenzuela City Lingunan", "zip": "1446"},
  {"area": "Valenzuela City Mabolo", "zip": "1444"},
  {"area": "Valenzuela City Malanday", "zip": "1444"},
  {"area": "Valenzuela City Mapulang Lupa", "zip": "1448"},
  {"area": "Valenzuela City Paso de Blas", "zip": "1442"},
  {"area": "Valenzuela City Pasolo", "zip": "1444"},
  {"area": "Valenzuela City Polo", "zip": "1444"},
  {"area": "Valenzuela City Rincon", "zip": "1444"},
  {"area": "Valenzuela City Valenzuela Central Post Office - Malinta", "zip": "1440"},
  {"area": "Valenzuela City Valenzuela P.O. Boxes", "zip": "1469"},
  {"area": "Western Samar Almagro", "zip": "6724"},
  {"area": "Western Samar Basey", "zip": "6720"},
  {"area": "Western Samar Calbayog City", "zip": "6710"},
  {"area": "Western Samar Calbiga", "zip": "6715"},
  {"area": "Western Samar Catbalogan", "zip": "6700"},
  {"area": "Western Samar Daram", "zip": "6722"},
  {"area": "Western Samar Gandara", "zip": "6706"},
  {"area": "Western Samar Hinabangan", "zip": "6713"},
  {"area": "Western Samar Jiabong", "zip": "6701"},
  {"area": "Western Samar Marabut", "zip": "6721"},
  {"area": "Western Samar Matuguinao", "zip": "6708"},
  {"area": "Western Samar Motiong", "zip": "6702"},
  {"area": "Western Samar Pagsanghan", "zip": "6705"},
  {"area": "Western Samar Paranas (Wright)", "zip": "6703"},
  {"area": "Western Samar Pinabacdao", "zip": "6716"},
  {"area": "Western Samar San Jorge", "zip": "6707"},
  {"area": "Western Samar San Jose De Buan", "zip": "6723"},
  {"area": "Western Samar San Sebastian", "zip": "6714"},
  {"area": "Western Samar Santa Margarita", "zip": "6709"},
  {"area": "Western Samar Santa Rita", "zip": "6718"},
  {"area": "Western Samar Santo Ni?o", "zip": "6711"},
  {"area": "WESTERN SAMAR SANTO NINO", "zip": "6711"},
  {"area": "Western Samar Tagapul-an", "zip": "6712"},
  {"area": "Western Samar Talalora", "zip": "6719"},
  {"area": "Western Samar Tarangnan", "zip": "6704"},
  {"area": "Western Samar Villareal", "zip": "6717"},
  {"area": "Western Samar Zumarraga", "zip": "6725"},
  {"area": "Zambales Botolan", "zip": "2202"},
  {"area": "Zambales Cabangan", "zip": "2203"},
  {"area": "Zambales Candelaria", "zip": "2212"},
  {"area": "Zambales Castillejos", "zip": "2208"},
  {"area": "Zambales Iba", "zip": "2201"},
  {"area": "Zambales Masinloc", "zip": "2211"},
  {"area": "Zambales Olongapo City", "zip": "2200"},
  {"area": "Zambales Palauig & Scarborough Shoal", "zip": "2210"},
  {"area": "Zambales San Antonio", "zip": "2206"},
  {"area": "Zambales San Felipe", "zip": "2204"},
  {"area": "Zambales San Marcelino", "zip": "2207"},
  {"area": "Zambales San Narciso", "zip": "2205"},
  {"area": "Zambales Santa Cruz", "zip": "2213"},
  {"area": "Zambales Subic", "zip": "2209"},
  {"area": "Zambales Subic Bay Freeport Zone (Metro Subic)", "zip": "2222"},
  {"area": "Zamboanga del Norte Baliguian", "zip": "7123"},
  {"area": "Zamboanga del Norte Dapitan City", "zip": "7101"},
  {"area": "Zamboanga del Norte Dipolog City", "zip": "7100"},
  {"area": "ZAMBOANGA DEL NORTE GODOD", "zip": "7126"},
  {"area": "Zamboanga del Norte Gutalac", "zip": "7118"},
  {"area": "Zamboanga del Norte Jose Dalman (Ponot)", "zip": "7111"},
  {"area": "Zamboanga del Norte Kalawit", "zip": "7124"},
  {"area": "Zamboanga del Norte Katipunan", "zip": "7109"},
  {"area": "Zamboanga del Norte La Libertad", "zip": "7119"},
  {"area": "Zamboanga del Norte Labason", "zip": "7117"},
  {"area": "ZAMBOANGA DEL NORTE LEON POSTIGO", "zip": "7125"},
  {"area": "Zamboanga del Norte Liloy", "zip": "7115"},
  {"area": "Zamboanga del Norte Manukan", "zip": "7110"},
  {"area": "Zamboanga del Norte Mutia", "zip": "7107"},
  {"area": "Zamboanga del Norte Pinan", "zip": "7105"},
  {"area": "Zamboanga del Norte Polanco", "zip": "7106"},
  {"area": "Zamboanga del Norte Rizal", "zip": "7104"},
  {"area": "Zamboanga del Norte Roxas", "zip": "7102"},
  {"area": "Zamboanga del Norte Salug", "zip": "7114"},
  {"area": "Zamboanga del Norte Sergio Osme?a", "zip": "7108"},
  {"area": "ZAMBOANGA DEL NORTE SERGIO OSMENA", "zip": "7108"},
  {"area": "Zamboanga del Norte Siayan", "zip": "7113"},
  {"area": "Zamboanga del Norte Sibuco", "zip": "7122"},
  {"area": "Zamboanga del Norte Sibutad", "zip": "7103"},
  {"area": "Zamboanga del Norte Sindangan (Leon B. Postigo)", "zip": "7112"},
  {"area": "Zamboanga del Norte Siocon", "zip": "7120"},
  {"area": "Zamboanga del Norte Siraway", "zip": "7121"},
  {"area": "Zamboanga del Norte Tampilisan", "zip": "7116"},
  {"area": "Zamboanga del Sur Aurora", "zip": "7020"},
  {"area": "Zamboanga del Sur Bayog", "zip": "7011"},
  {"area": "Zamboanga del Sur Dimataling", "zip": "7032"},
  {"area": "Zamboanga del Sur Dinas", "zip": "7030"},
  {"area": "Zamboanga del Sur Dumalinao", "zip": "7015"},
  {"area": "Zamboanga del Sur Dumingag", "zip": "7028"},
  {"area": "Zamboanga del Sur Guipos", "zip": "7042"},
  {"area": "Zamboanga del Sur Josefina", "zip": "7027"},
  {"area": "ZAMBOANGA DEL SUR KUMALARANG", "zip": "7013"},
  {"area": "Zamboanga del Sur Kumalarang", "zip": "7005"},
  {"area": "Zamboanga del Sur Labangan", "zip": "7017"},
  {"area": "Zamboanga del Sur Lakewood", "zip": "7014"},
  {"area": "Zamboanga del Sur Lapuyan", "zip": "7037"},
  {"area": "Zamboanga del Sur Mahayag", "zip": "7026"},
  {"area": "Zamboanga del Sur Margosatubig", "zip": "7035"},
  {"area": "Zamboanga del Sur Midsalip", "zip": "7021"},
  {"area": "Zamboanga del Sur Molave", "zip": "7023"},
  {"area": "Zamboanga del Sur Pagadian City", "zip": "7016"},
  {"area": "Zamboanga del Sur Pitogo", "zip": "7033"},
  {"area": "Zamboanga del Sur Ramon Magsaysay (Liargo)", "zip": "7024"},
  {"area": "Zamboanga del Sur San Miguel", "zip": "7029"},
  {"area": "Zamboanga del Sur San Pablo", "zip": "7031"},
  {"area": "Zamboanga del Sur Sominot (Don Mariano Marcos)", "zip": "7022"},
  {"area": "Zamboanga del Sur Tabina", "zip": "7034"},
  {"area": "Zamboanga del Sur Tambulig", "zip": "7025"},
  {"area": "Zamboanga del Sur Tigbao", "zip": "7043"},
  {"area": "Zamboanga del Sur Tukuran", "zip": "7019"},
  {"area": "Zamboanga del Sur Vincenzo A. Sagun", "zip": "7036"},
  {"area": "Zamboanga del Sur Zamboanga City", "zip": "7000"},
  {"area": "Zamboanga Sibugay Alicia", "zip": "7040"},
  {"area": "Zamboanga Sibugay Buug", "zip": "7009"},
  {"area": "Zamboanga Sibugay Diplahan", "zip": "7039"},
  {"area": "Zamboanga Sibugay Imelda", "zip": "7007"},
  {"area": "Zamboanga Sibugay Ipil", "zip": "7001"},
  {"area": "Zamboanga Sibugay Kabasalan", "zip": "7005"},
  {"area": "Zamboanga Sibugay Mabuhay", "zip": "7010"},
  {"area": "Zamboanga Sibugay Malangas", "zip": "7038"},
  {"area": "Zamboanga Sibugay Naga", "zip": "7004"},
  {"area": "Zamboanga Sibugay Olutanga", "zip": "7041"},
  {"area": "Zamboanga Sibugay Payao", "zip": "7008"},
  {"area": "Zamboanga Sibugay Roseller T. Lim", "zip": "7002"},
  {"area": "Zamboanga Sibugay Siay", "zip": "7006"},
  {"area": "Zamboanga Sibugay Talusan", "zip": "7012"},
  {"area": "Zamboanga Sibugay Titay", "zip": "7003"},
  {"area": "ZAMBOANGA SIBUGAY TUNGAWAN", "zip": "7018"}
  ];
}
