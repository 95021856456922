import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'ts-xlsx';
import * as FileSaver from 'file-saver';
import { GovipxService } from 'src/data/services/web/govipx.service';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { Select2OptionData } from 'ng-select2';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { StoreItemService } from 'src/data/services/web/store-item.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-item-settings-list',
  templateUrl: './item-settings-list.component.html',
  styleUrls: ['./item-settings-list.component.less']
})
export class ItemSettingsListComponent implements OnInit {

  loader: any = false;
  uom: any = [];
  categories: any = [];
  emptyString: any = "";

  username: any;
  public categoryFormGroup: FormGroup;
  public uomFormGroup: FormGroup;

  public localStorageCacheService: LocalStorageCacheService;
  public userIdFilterOptions: Array<Select2OptionData>;

  constructor(
    private modalServe: NgbModal,
    private webService: WebSettingsService,
    public datepipe: DatePipe,
    public storeItemService: StoreItemService,
    localStorageCacheService: LocalStorageCacheService
  ) { 
    this.localStorageCacheService = localStorageCacheService;
  }

  ngOnInit(): void {
    this.getCategories();
    this.getUoM();
    this.initCategoryFormGroup();
    this.initUomFormGroup();

    this.username = this.localStorageCacheService.getStorage("username");
  }
  
  initCategoryFormGroup(): void {
    this.categoryFormGroup= new FormGroup({
      category: new FormControl(this.emptyString, [Validators.required])
    });
  }

  initUomFormGroup(): void {
    this.uomFormGroup= new FormGroup({
      unit: new FormControl(this.emptyString, [Validators.required]),
      abrUnit: new FormControl(this.emptyString, [Validators.required])
    });
  }

  getCategories(){
    this.storeItemService.getCategories().subscribe((result) => {
      this.categories = result;
    });
  }

  getUoM(){
    this.storeItemService.getUoM().subscribe((result) => {
      this.uom = result;
    });
  }

  addCategoryModelRef: NgbModalRef;
  AddCategory(content: any){
    this.addCategoryModelRef = this.modalServe.open(content, { centered: true, size: "md" });
  }
  
  addUoMModelRef: NgbModalRef;
  AddUoM(content: any){
    this.addUoMModelRef = this.modalServe.open(content, { centered: true, size: "md" });
  }

  submitCategory(){
    if(this.categoryFormGroup.invalid){
      PNotify.error({
        title: "Invalid",
        text: "Please fill all fields.",
      });

      return
    }

    this.storeItemService.postCategory(this.categoryFormGroup.value).subscribe((result) => {
      if(result){
        PNotify.success({
          title: "Add Category",
          text: "Success",
        });

        this.categoryFormGroup.reset();
        this.getCategories();
        this.closeModal();
      } else {
        PNotify.error({
          title: "Add Category",
          text: "Someting went wrong. Please try again.",
        });
      }
    });
  }

  submitUom(){
    if(this.uomFormGroup.invalid){
      PNotify.error({
        title: "Invalid",
        text: "Please fill all fields.",
      });

      return
    }
    
    this.storeItemService.postUoM(this.uomFormGroup.value).subscribe((result) => {
      if(result){
        PNotify.success({
          title: "Add Category",
          text: "Success",
        });

        this.uomFormGroup.reset();
        this.getUoM();
        this.closeModal();
      } else {
        PNotify.error({
          title: "Add Category",
          text: "Someting went wrong. Please try again.",
        });
      }
    });
  }

  closeModal(){
    this.modalServe.dismissAll();
  }
}
