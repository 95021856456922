import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[restrict-characters]'
})
export class RestrictCharactersDirective {

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === '.' || event.key.toLowerCase() === 'e') {
      event.preventDefault();
    }
  }

}