<app-loader *ngIf="!webSettingsFormGroup"></app-loader>
<app-loader *ngIf="processCreate"></app-loader>
<div *ngIf="webSettingsFormGroup" id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">Update Web Settings</h2>
          <small class="text-highlight font-weight-normal font-italic">Make sure all the information are correct</small>
        </div>
        <div class="flex"></div>

        <div>
          <div
            data-toggle="modal"
            data-target="#modal-left"
            data-toggle-class="fade-left"
            data-toggle-class-target=".animate"
            class="btn btn-md btn-info"
          >
            <span class="d-none d-sm-inline mx-1">Save</span>
            <i data-feather="arrow-right"></i>
          </div>
        </div>

        <!-- (click)="saveWebSettings()"  -->
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="padding">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <strong>Web Setting</strong>
              </div>
              <div class="card-body">
                <form>
                  <app-textbox
                    [parent-form-group]="webSettingsFormGroup"
                    tab-index-start="0"
                    control-name="value"
                    [error]="webSettingsFormGroup?.controls.value.errors | estatus"
                    [error-message]="webSettingsFormGroup?.controls.value.errors | econtent"
                    read-only="false"
                    label="Identifier"
                    placeholder="Identifier"
                  >
                  </app-textbox>

                  <app-textbox
                    [parent-form-group]="webSettingsFormGroup"
                    tab-index-start="0"
                    control-name="name"
                    [error]="webSettingsFormGroup?.controls.name.errors | estatus"
                    [error-message]="webSettingsFormGroup?.controls.name.errors | econtent"
                    read-only="false"
                    no-special-char="false"
                    label="Name"
                    placeholder="Name"
                  >
                  </app-textbox>

                  <app-textarea
                    [parent-form-group]="webSettingsFormGroup"
                    tab-index-start="0"
                    control-name="description"
                    [error]="webSettingsFormGroup?.controls.description.errors | estatus"
                    [error-message]="webSettingsFormGroup?.controls.description.errors | econtent"
                    read-only="false"
                    no-special-char="false"
                    label="Description"
                    placeholder="Description"
                  >
                  </app-textarea>

                  <app-boolslider
                    *ngIf="true"
                    [parentFormGroup]="webSettingsFormGroup"
                    controlName="status"
                    label="Status"
                  >
                  </app-boolslider>
                </form>
              </div>
              <div class="card-header">
                <strong>Meta Values</strong>
                <div
                  data-toggle="modal"
                  data-target="#modal-left-address"
                  data-toggle-class="fade-left"
                  data-toggle-class-target=".animate"
                  style="float: right"
                >
                  <button
                    (click)="addUpWebSettingValues()"
                    class="btn btn-md btn-raised btn-wave btn-icon btn-rounded mb-2 blue text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-plus"
                    >
                      <line x1="12" y1="5" x2="12" y2="19"></line>
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                    &nbsp;
                    <i class="fa fa-angle-up"></i>
                  </button>
                </div>
                <div
                  data-toggle="modal"
                  data-target="#modal-left-address"
                  data-toggle-class="fade-left"
                  data-toggle-class-target=".animate"
                  style="float: right; margin-right: 5px"
                >
                  <button
                    (click)="addWebSettingValues()"
                    class="btn btn-md btn-raised btn-wave btn-icon btn-rounded mb-2 green text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-plus"
                    >
                      <line x1="12" y1="5" x2="12" y2="19"></line>
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                    &nbsp;
                    <i class="fa fa-angle-down"></i>
                  </button>
                </div>
              </div>
              <div class="card-body">
                <div *ngFor="let webSetting of webSettingsFormGroup?.controls.values.controls; let i = index">
                  <div [formGroup]="webSetting" class="input-group mb-3">
                    <div (click)="deleteWebSettingValues(i)" class="input-group-prepend red" style="cursor: pointer">
                      <span class="input-group-text" id=""
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-x"
                        >
                          <line x1="18" y1="6" x2="6" y2="18"></line>
                          <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                      </span>
                    </div>
                    <input formControlName="parameter" type="text" class="form-control" />
                    <input formControlName="value" type="text" class="form-control" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<div id="modal-left" class="modal fade" data-backdrop="true">
  <div class="modal-dialog animate">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title text-md">Updating of Web Settings</div>
        <button class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="p-4 text-center">
          <p>Are you sure you want continue?</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
        <button (click)="actionEventSaveWebSettings()" type="button" class="btn btn-primary" data-dismiss="modal">
          Save Changes
        </button>
        <!--  -->
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
</div>
