import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { ECPayService } from 'src/data/services/web/ecpay.service';

import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { CurrencyPipe } from '@angular/common';
import { PrintService } from 'src/data/services/web/print.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-ectopup',
  templateUrl: './ectopup.component.html',
  styleUrls: ['./ectopup.component.less']
})
export class EctopupComponent implements OnInit {

  @Input("application") application: any;
  @Input("shortcutCode") shortcutCode: any;

  billerFormGroup : FormGroup;
  formBuilder : FormBuilder;
  ecPayService : ECPayService;
  public emptyString = "";
  public billers : any = [];
  public biller : any;
  public billerCode : any;
  public billerTypes : any = [];
  public billersByBillerTypes : any = [];
  public selectedBillerCode : any;

  public activeTab = 1;
  public loader = true;
  public fee : any;

  public detail : any;

  
  @Output("closeModal") closeModal = new EventEmitter();
  

  constructor(ecPayService : ECPayService, 
    private router: Router,
    localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    formBuilder : FormBuilder) {
    this.ecPayService = ecPayService;
    this.formBuilder = formBuilder;
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.initBillerTypeFormGroup();
    this.getBillers() ;

    if(this.shortcutCode){
      setTimeout(() => {
        this.initShortcut();
      }, 1500);
    }
  }

  initShortcut(): void {
    this.billerCode = this.shortcutCode.itemCode;
    this.billerFormGroup.controls.billerType.patchValue(this.shortcutCode.category);
    this.getBillersByBillerType(this.shortcutCode.category);
    this.billerFormGroup.controls.billerCode.patchValue(this.shortcutCode.itemCode);
    this.setBillerCode(this.shortcutCode.itemCode);
  }
  
  initBillerTypeFormGroup(): void {
    this.billerFormGroup = new FormGroup({
      billerType: new FormControl(this.emptyString, [Validators.required]),
      billerCode: new FormControl(this.emptyString, [Validators.required]),
      mobileNumber: new FormControl(this.emptyString, [Validators.required]),
      amount: new FormControl(this.emptyString),
      params: this.formBuilder.group({})
    });
  }

  reInitBillerTypeFormGroup(): void {
    this.billerFormGroup = new FormGroup({
      billerType: new FormControl(this.billerFormGroup.controls.billerType.value, [Validators.required]),
      billerCode: new FormControl(this.billerFormGroup.controls.billerCode.value, [Validators.required]),
      mobileNumber: new FormControl(this.billerFormGroup.controls.mobileNumber.value,[Validators.required]),
      amount: new FormControl(this.billerFormGroup.controls.amount.value),
      params: this.formBuilder.group({})
    });
  }

  getBillers() {
    this.ecPayService.getTopUps().subscribe((billers) => {
       this.billers = billers;
      this.getBillerTypes(billers);
    });
  }

  getBillerTypes(billers : any){
  var lookup = {};
  var items = billers;
  
  for (var item, i = 0; item = items[i++];) {
    var type = item.type;
  
    if (!(type in lookup)) {
      lookup[type] = 1;
      this.billerTypes.push(type);
    }
  }
  this.loader = false;
 
}
public billerType : any; 
public selectedProduct : any; 

  getBillersByBillerType(billerType : any){
    this.billersByBillerTypes = this.billers.filter(x=>x.type == billerType);
    this.billerType = billerType;
  }

  setBillerCode(billerCode : any){
    this.billerCode = billerCode;
    this.selectedProduct = this.billersByBillerTypes.filter(x=>x.type==this.billerType && x.code == billerCode)[0];
    this.detail = this.selectedProduct.category;
    
    this.billerFormGroup.controls.amount.setValue(  this.currencyPipe
      .transform(this.selectedProduct.fee.replace(/,/g, ""), "PHP")
      .replace("PHP", ""));

  
  }

 

  getBiller() {
    this.activeTab++;
    this.biller= this.billers.filter(x=>x.code == this.billerCode)[0];
    this.addBillerParam(this.biller.meta);
    this.fee = this.biller.fee;
    // this.loader = true;
    // this.ectopupService.getBiller(this.billerCode).subscribe((biller) => {
    //   if(biller.meta.length == 0){
    //     PNotify.error({
    //       title: "Error",
    //       text: "Biller is unavailable.",
    //     });
    //     this.loader = false;
    //   }else{
    //     this.activeTab++;
    //     this.addBillerParam(biller.meta);
    //     this.fee = biller.fee;
    //     this.biller = biller;
    //     this.loader = false;
    //   }
    // });
  }

  inquireBiller() {
    this.loader = true;
  //  this.ectopupService.inquireBiller(this.paramsFormGroup().value,this.billerCode, this.fee).subscribe((biller) => {
      
        PNotify.info({
          title: "Validation will be execute after submitting",
          text: "Accepted",
        });
        this.loader = false;
        this.code = "";
        this.activeTab = 3;
        this.codeStatus=false;
      
  //  }, error => {
  //    PNotify.error({
  //      title: "Payment Validation",
  //      text: error.error.reason,
   //   });
   //   this.loader = false;

 //   });
  }//

  paramsFormGroup() : FormGroup{
    return this.billerFormGroup.get('params') as FormGroup;
  } 


  addBillerParam(meta: any){
    if (this.selectedBillerCode != this.billerFormGroup.controls.billerCode.value){
        this.reInitBillerTypeFormGroup();
        
        meta.forEach(param => {
          (this.billerFormGroup.get("params") as FormGroup).addControl(
            param.field,
            new FormControl("")
          );
        });
   }
    this.selectedBillerCode = this.billerFormGroup.controls.billerCode.value;
  }

  setActiveTab(){

    if(this.activeTab == 1){
      if(this.billerFormGroup.status == "INVALID"){
        PNotify.error({
          title: "Error",
          text: "All fields are required to proceed to next step.",
        });
      }
      else{
        this.getBiller();
      }
    }
    
    // else if(this.activeTab == 2){

    //   var hasError : boolean  = false;
    //   this.biller.meta.forEach(param => {
    //     if(!this.paramsFormGroup().controls[param.field].value && param.is_required){
    //       this.paramsFormGroup().controls[param.field].setErrors({'required': param.label + " is required."});
    //       hasError = true;
    //     }
    //   });


    //   if(hasError){
    //     PNotify.error({
    //       title: "Request denied",
    //       text: "Please fill up all the required fields.",
    //     });
    //   }else{
    //     this.inquireBiller();
    //   }

     
    // }


  }

  setActiveTabPrev(){
    if(this.activeTab == 1){
      PNotify.error({
        title: "Error",
        text: "Action Denied.",
      });
    }else{
      this.activeTab--;
    }
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  codeStatus : boolean = false;
  code : any;
  onCodeCompleted(code: string) {
    this.code = code;
    this.codeStatus = code.length == 6;
  }


  processPayment(){
      this.loader = true;
      this.ecPayService.processTopUp(
        this.selectedProduct.code == 'LD' ? this.billerFormGroup.controls.amount.value : this.selectedProduct.fee,
        this.selectedProduct.type,
        this.billerFormGroup.controls.mobileNumber.value,
        this.selectedProduct.code,
        this.code)
        .subscribe((biller) => {
          PNotify.success({
            title: "Information",
            text: "Transaction successfully completed.",//biller.reason,
          });
          this.loader = false;
          this.code = "";
          this.codeStatus=false;
          this.closeModal.emit();
        
      }, error => {
        PNotify.error({
          title: "Payment Failed",
          text: error.error.reason,
        });
        this.loader = false;
        this.code = "";
        this.codeStatus = false;
      });
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  totalAmount(fee:any,amount:any){
    try  {
    return this.makeMoney(Number(fee) + Number(amount));
    } catch(e){
      return "0.00";
    }
    }

    optionValue(param)
  {

    return param.options.filter(x=>x.value == this.paramsFormGroup().controls[param.field].value)[0].key;
  }


  
}
