<app-loader *ngIf="!applications"></app-loader>
<app-loader *ngIf="loader"></app-loader>

<div *ngIf="applications" class="padding" id="page-hero">
  <div>
    <div class="card">
      <div class="b-b">
        <div class="nav-active-border b-primary bottom">
          <ul class="nav" id="myTab" role="tablist">
            <li class="nav-item pb-2 pr-3 pl-3 pt-4" *ngIf="roleLevelId == 1">
              <a
                class="nav-link active font-weight-bold"
                id="admin-home-tab"
                data-toggle="tab"
                href="#adminhome3"
                role="tab"
                aria-controls="adminhome"
                aria-selected="true"
                >Juan Marketplace</a
              >
            </li>
            <li class="nav-item pb-2 pr-3 pl-3 pt-4" *ngIf="roleLevelId == 2">
              <a
                class="nav-link active font-weight-bold"
                id="home-tab"
                data-toggle="tab"
                href="#home3"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                >Juan Marketplace</a
              >
            </li>
            <li class="nav-item pb-2 pr-3 pl-3 pt-4" *ngIf="roleLevelId == 2">
              <a
                class="nav-link font-weight-bold"
                id="profile-tab"
                data-toggle="tab"
                href="#profile3"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                >My Applications</a
              >
            </li>
            <li class="nav-item pb-2 pr-3 pl-3 pt-4" *ngIf="roleLevelId == 2">
              <a
                class="nav-link font-weight-bold"
                id="contact-tab"
                data-toggle="tab"
                href="#contact3"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
                >Declined Apps</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="tab-content p-3">
        <div
          class="tab-pane fade"
          [ngClass]="roleLevelId == 1 ? 'active show' : ''"
          id="adminhome3"
          role="tabpanel"
          aria-labelledby="admin-home-tab"
        >
          <div class="row">
            <div *ngFor="let network of adminNetworks; let i = index" class="col-12 mb-2">
              <div class="panel panel-default">
                <div class="panel-heading bg-dark d-flex justify-content-between align-items-center">
                  <h6 class="panel-title col-title p-4" style="text-align: center; flex-grow: 1; margin-bottom: 0">
                    <a
                      class="text-white"
                      data-toggle="collapse"
                      href="#collapse{{ i }}"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapse{{ i }}"
                    >
                      {{ network }} Network
                    </a>
                  </h6>
                </div>

                <div id="collapse{{ i }}" class="collapse show">
                  <div class="panel-body">
                    <div class="row">
                      <ng-container *ngFor="let application of getApplicationsByAdminNetwork(network)">
                        <div
                          class="col-lg-3 col-md-4 col-sm-6 dynamic-col"
                          *ngIf="application.value.thumbnail.price != 10000000"
                          [ngStyle]="{
                            opacity:
                              checkIfPurchasedV2(application, network) == true
                                ? application.parameter == 'Anti-Money Laundering Council'
                                  ? '100%'
                                  : '40%'
                                : '100%'
                          }"
                        >
                          <div
                            class="card p-3"
                            style="margin: 10px; text-align: center"
                            *ngIf="application.value.thumbnail.price != 10000000"
                          >
                            <div
                              class="media media-4x1"
                              style="
                                width: 100%;
                                height: 150px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                overflow: hidden;
                              "
                            >
                              <img
                                [src]="application.value.thumbnail.logo"
                                alt="{{ application.value.thumbnail.title }}"
                                style="width: 100%; height: 75%; object-fit: cover; object-position: center"
                              />
                            </div>
                            <div class="card-body">
                              <h5 class="card-title">{{ application.value.thumbnail.title }}</h5>
                            </div>
                            <ul class="list-group list-group-flush">
                              <li class="list-group-item">
                                <strong *ngIf="application.value.thumbnail.price == 1000000" style="font-size: 1.2vh">
                                  <span class="text-danger font-weight-bold">Coming soon...</span>
                                </strong>
                                <strong *ngIf="application.value.thumbnail.price != 1000000" style="font-size: 1.2vh">
                                  ₱
                                  {{
                                    application.parameter == "Anti-Money Laundering Council"
                                      ? AMCLCount == 0
                                        ? makeMoney(0)
                                        : makeMoney(application.value.thumbnail.price)
                                      : makeMoney(application.value.thumbnail.price)
                                  }}
                                </strong>
                              </li>
                              <li class="list-group-item">{{ application.value?.network }}</li>
                            </ul>
                            <div class="card-body">
                              <button
                                *ngIf="
                                  !checkIfPurchasedV2(application.parameter) ||
                                  application.parameter == 'Anti-Money Laundering Council'
                                "
                                (click)="openContentUponPurchased(contentUponPurchased, application)"
                                class="btn bg-primary-lt btn-sm w-100 text-bold"
                                style="border-radius: 0"
                                [disabled]="application.value.thumbnail.price == 1000000 && roleLevelId != 1"
                              >
                                <span *ngIf="roleLevelId == 2">Purchase</span>
                                <span *ngIf="roleLevelId == 1">More Info</span>
                              </button>
                              <button
                                *ngIf="
                                  checkIfPurchasedV2(application.parameter) &&
                                  application.parameter != 'Anti-Money Laundering Council'
                                "
                                class="btn bg-default-lt btn-sm w-100 text-bold"
                                style="border-radius: 0; cursor: text"
                              >
                                Installed
                              </button>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          [ngClass]="roleLevelId == 2 ? 'active show' : ''"
          id="home3"
          role="tabpanel"
          aria-labelledby="home-tab"
        >
          <div class="row">
            <div class="col-12 mb-2">
              <h6 class="ml-3">
                <!-- MSF: {{ msf?.parameter }}
                <span class="ml-5" *ngIf="msf?.parameter == '3C'"
                  >AVAILABLE SERVICES:
                  {{ msf?.value.network[0] === "ALL" ? "ALL" : msf?.value.network.join(", ") }}</span
                > -->
                <span class="ml-5" *ngIf="msf?.parameter != '3C'"
                  >Remaining Add-On Services: {{ msf?.value.addons - activeNetworks.length }}
                </span>
              </h6>
            </div>

            <div *ngFor="let network of networks; let i = index" class="col-12 mb-2">
              <div class="panel panel-default">
                <div class="panel-heading bg-dark d-flex justify-content-between align-items-center">
                  <h6 class="panel-title col-title p-4" style="text-align: center; flex-grow: 1; margin-bottom: 0">
                    <a
                      class="text-white"
                      data-toggle="collapse"
                      href="#collapse{{ i }}"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapse{{ i }}"
                    >
                      {{ network }} Network
                    </a>
                  </h6>
                  <button
                    class="btn btn-primary mr-3"
                    style="margin-left: auto"
                    (click)="confirmPurchaseOfNetwork(confirmAppPurchaseModal, network)"
                    [ngClass]="i != 0 ? '' : 'hidden'"
                    *ngIf="checkAddOnCredit(network)"
                  >
                    Activate
                  </button>
                </div>

                <div id="collapse{{ i }}" class="collapse show">
                  <div class="panel-body">
                    <div class="row">
                      <ng-container *ngFor="let application of getApplicationsByNetwork(network)">
                        <div
                          class="col-lg-3 col-md-4 col-sm-6 dynamic-col"
                          *ngIf="application.value.thumbnail.price != 10000000"
                          [ngStyle]="{
                            opacity:
                              checkIfPurchased(application, network) == true
                                ? application.parameter == 'Anti-Money Laundering Council'
                                  ? '100%'
                                  : '40%'
                                : '100%'
                          }"
                        >
                          <div
                            class="card p-3"
                            style="margin: 10px; text-align: center"
                            *ngIf="application.value.thumbnail.price != 10000000"
                          >
                            <div
                              class="media media-4x1"
                              style="
                                width: 100%;
                                height: 150px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                overflow: hidden;
                              "
                            >
                              <img
                                [src]="application.value.thumbnail.logo"
                                alt="{{ application.value.thumbnail.title }}"
                                style="width: 100%; height: 75%; object-fit: cover; object-position: center"
                              />
                            </div>
                            <div class="card-body">
                              <h5 class="card-title">{{ application.value.thumbnail.title }}</h5>
                            </div>
                            <ul class="list-group list-group-flush">
                              <li class="list-group-item">
                                <strong *ngIf="application.value.thumbnail.price == 1000000" style="font-size: 1.2vh">
                                  <span class="text-danger font-weight-bold">Coming soon...</span>
                                </strong>
                                <strong *ngIf="application.value.thumbnail.price != 1000000" style="font-size: 1.2vh">
                                  ₱
                                  {{
                                    application.parameter == "Anti-Money Laundering Council"
                                      ? AMCLCount == 0
                                        ? makeMoney(0)
                                        : makeMoney(application.value.thumbnail.price)
                                      : makeMoney(application.value.thumbnail.price)
                                  }}
                                </strong>
                              </li>
                              <li class="list-group-item">{{ application.value?.network }}</li>
                            </ul>
                            <div class="card-body">
                              <button
                                *ngIf="
                                  !checkIfPurchasedV2(application.parameter) ||
                                  application.parameter == 'Anti-Money Laundering Council'
                                "
                                (click)="openContentUponPurchased(contentUponPurchased, application)"
                                class="btn bg-primary-lt btn-sm w-100 text-bold"
                                style="border-radius: 0"
                                [disabled]="application.value.thumbnail.price == 1000000 && roleLevelId != 1"
                              >
                                <span *ngIf="roleLevelId == 2">Purchase</span>
                                <span *ngIf="roleLevelId == 1">More Info</span>
                              </button>
                              <button
                                *ngIf="
                                  checkIfPurchasedV2(application.parameter) &&
                                  application.parameter != 'Anti-Money Laundering Council'
                                "
                                class="btn bg-default-lt btn-sm w-100 text-bold"
                                style="border-radius: 0; cursor: text"
                              >
                                Installed
                              </button>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="profile3" role="tabpanel" aria-labelledby="profile-tab">
          <div class="row">
            <div class="col-12 mb-2">
              <h6 class="ml-3">
                <!-- MSF: {{ msf?.parameter }}
                <span class="ml-5" *ngIf="msf?.parameter == '3C'"
                  >AVAILABLE SERVICES:
                  {{ msf?.value.network[0] === "ALL" ? "ALL" : msf?.value.network.join(", ") }}</span
                > -->
                <span class="ml-5" *ngIf="msf?.parameter != '3C'"
                  >Remaining Add-On Services: {{ msf?.value.addons - activeNetworks.length }}
                </span>
              </h6>
            </div>

            <div *ngIf="activeApplications.length == 0 && roleLevelId == 2" class="text-center">
              <small class="ml-5 text-highlight font-weight-normal font-italic">No application installed. </small>
            </div>

            <ng-container *ngFor="let application of activeApplications">
              <div class="col-lg-3 col-md-4 col-sm-6 dynamic-col">
                <div class="card p-3" style="margin: 10px; text-align: center">
                  <div
                    class="media media-4x1"
                    style="
                      width: 100%;
                      height: 150px; /* Set a fixed height to reflect landscape */
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      overflow: hidden;
                    "
                  >
                    <img
                      [src]="application.value.thumbnail.logo"
                      alt="{{ application.value.thumbnail.title }}"
                      style="width: 100%; height: 75%; object-fit: cover; object-position: center"
                    />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">{{ application.value.thumbnail.title }}</h5>
                  </div>
                  <div class="py-4">
                    <a [innerHtml]="checkProgressStatus(application)"></a>
                  </div>
                  <!-- <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <strong *ngIf="application.value.thumbnail.price == 1000000" style="font-size: 1.2vh">
                        <span class="text-danger font-weight-bold">Coming soon...</span>
                      </strong>
                      <strong *ngIf="application.value.thumbnail.price != 1000000" style="font-size: 1.2vh">
                        ₱
                        {{
                          application.parameter == "Anti-Money Laundering Council"
                            ? AMCLCount == 0
                              ? makeMoney(0)
                              : makeMoney(application.value.thumbnail.price)
                            : makeMoney(application.value.thumbnail.price)
                        }}
                      </strong>
                    </li>
                    <li class="list-group-item">{{ application.value?.network }}</li>
                  </ul> -->
                  <div class="card-body">
                    <button
                      class="btn bg-primary-lt btn-sm w-100 text-bold"
                      (click)="openContentDonePurchased(application)"
                    >
                      More Info
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="tab-pane fade" id="contact3" role="tabpanel" aria-labelledby="contact-tab">
          <div class="row">
            <div class="col-12 mb-2">
              <h6 class="ml-3">
                <!-- MSF: {{ msf?.parameter }}
                <span class="ml-5" *ngIf="msf?.parameter == '3C'"
                  >AVAILABLE SERVICES:
                  {{ msf?.value.network[0] === "ALL" ? "ALL" : msf?.value.network.join(", ") }}</span
                > -->
                <span class="ml-5" *ngIf="msf?.parameter != '3C'"
                  >Remaining Add-On Services: {{ msf?.value.addons - activeNetworks.length }}
                </span>
              </h6>
            </div>

            <ng-container *ngFor="let application of declinedApplications">
              <div class="col-lg-3 col-md-4 col-sm-6 dynamic-col">
                <div class="card p-3" style="margin: 10px; text-align: center">
                  <div
                    class="media media-4x1"
                    style="
                      width: 100%;
                      height: 150px; /* Set a fixed height to reflect landscape */
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      overflow: hidden;
                    "
                  >
                    <img
                      [src]="application.value.thumbnail.logo"
                      alt="{{ application.value.thumbnail.title }}"
                      style="width: 100%; height: 75%; object-fit: cover; object-position: center"
                    />
                  </div>
                  <div class="card-body">
                    <h5 class="card-title">{{ application.value.thumbnail.title }}</h5>
                  </div>
                  <div class="py-4">
                    <a><span class="badge badge-danger text-uppercase">Declined</span></a>
                  </div>

                  <div class="card-body">
                    <button
                      class="btn bg-primary-lt btn-sm w-100 text-bold"
                      (click)="openContentDonePurchased(application)"
                    >
                      More Info
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div *ngIf="roleLevelId == 2" class="padding d-flex">
    <div class="page-title">
      <h2 class="text-md text-highlight">My Applications</h2>
      <small class="text-highlight font-weight-normal font-italic">List of purchased applications.</small>
    </div>
    <div class="flex"></div>
  </div>

  <div *ngIf="activeApplications.length == 0 && roleLevelId == 2" class="text-center">
    <small class="text-highlight font-weight-normal font-italic">No application installed. </small>
  </div>

  <div *ngIf="roleLevelId == 2" class="text-center">
    <div *ngFor="let application of activeApplications" class="block d-inline-flex" style="margin-bottom: 1px">
      <div class="p-4 p-sm-5 b-r">
        <img
          [src]="application.value.thumbnail.logo"
          height="70"
          width="70"
          class="img-responsive"
          style="object-fit: contain"
        />
        <div class="text-muted">{{ application.value.thumbnail.title }}</div>
        <div class="py-4">
          <a [innerHtml]="checkProgressStatus(application)"></a>
        </div>
        <div class="col-sm-12 col-md-12">
          <button class="btn bg-primary-lt btn-sm w-100 text-bold" (click)="openContentDonePurchased(application)">
            More Info
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="padding d-flex">
    <div class="page-title">
      <h2 class="text-md text-highlight">Juan Marketplace</h2>
      <small>Collection of available services.</small><br />
      <small>({{ msf?.parameter }}) Add-On Service: {{ msf?.value.addons }}</small>
    </div>
    <div class="flex"></div>
  </div>

  <div *ngIf="applications" class="text-center">
    <div
      *ngFor="let application of applications"
      class="block d-inline-flex"
      style="margin-bottom: 4px; margin-left: 4px"
      [ngStyle]="{
        opacity:
          checkIfPurchased(application.parameter) == true
            ? application.parameter == 'Anti-Money Laundering Council'
              ? '100%'
              : '40%'
            : '100%'
      }"
    >
      <div class="p-2 p-sm-5 b-r" *ngIf="application.value.thumbnail.price != 10000000">
        <img
          [src]="application.value.thumbnail.logo"
          height="70"
          width="70"
          class="img-responsive"
          style="object-fit: contain"
        />
        <div class="font-weight-bold" style="font-size: 1.1vh">{{ application.value?.network }}</div>
        <div class="font-weight-bold" style="font-size: 1.1vh">{{ application.value.thumbnail.title }}</div>
        <div class="py-4">
          <strong *ngIf="application.value.thumbnail.price == 1000000" style="font-size: 1.2vh">
            <span class="text-danger font-weight-bold">Coming soon...</span>
          </strong>
          <strong *ngIf="application.value.thumbnail.price != 1000000" style="font-size: 1.2vh"
            >₱
            {{
              application.parameter == "Anti-Money Laundering Council"
                ? AMCLCount == 0
                  ? makeMoney(0)
                  : makeMoney(application.value.thumbnail.price)
                : makeMoney(application.value.thumbnail.price)
            }}</strong
          >
        </div>
        <div class="col-sm-12 col-md-12">
          <button
            *ngIf="!checkIfPurchased(application.parameter) || application.parameter == 'Anti-Money Laundering Council'"
            (click)="openContentUponPurchased(contentUponPurchased, application)"
            class="btn bg-primary-lt btn-sm w-100 text-bold"
            style="border-radius: 0"
            [disabled]="application.value.thumbnail.price == 1000000 && roleLevelId != 1"
          >
            <span *ngIf="roleLevelId == 2">Purchase</span>
            <span *ngIf="roleLevelId == 1">More Info</span>
          </button>
          <button
            *ngIf="checkIfPurchased(application.parameter) && application.parameter != 'Anti-Money Laundering Council'"
            class="btn bg-default-lt btn-sm w-100 text-bold"
            style="border-radius: 0; cursor: text"
          >
            Installed
          </button>
        </div>
      </div>

    </div>
  </div>

  <div *ngIf="roleLevelId == 2" class="padding d-flex">
    <div class="page-title">
      <h2 class="text-md text-highlight">Declined Applications</h2>
      <small class="text-muted">Collection of declined services.</small>
    </div>
    <div class="flex"></div>
  </div>

  <div *ngIf="roleLevelId == 2" class="text-center">
    <div *ngFor="let application of declinedApplications" class="block d-inline-flex" style="margin-bottom: 1px">
      <div class="p-4 p-sm-5 b-r">
        <img
          [src]="application.value.thumbnail.logo"
          height="70"
          width="70"
          class="img-responsive"
          style="object-fit: contain"
        />
        <div class="text-muted">{{ application.value.thumbnail.title }}</div>
        <div class="py-4">
          <a><span class="badge badge-danger text-uppercase">Declined</span></a>
        </div>
        <div class="col-sm-12 col-md-12">
          <button class="btn bg-primary-lt btn-sm w-100 text-bold" (click)="openContentDonePurchased(application)">
            More Info
          </button>
        </div>
      </div>
    </div>
  </div> -->
</div>

<ng-template #contentUponPurchased let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Product Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row pl-3" *ngIf="selectedOnepager.length == 0">
      <div class="col-3 d-flex justify-content-center align-items-center">
        <img [src]="selectedApplication?.value.thumbnail.logo" width="100%" alt="" />
      </div>
      <div class="col-8 d-flex justify-content-center">
        <p>{{ selectedApplication?.value.acquired.description }}</p>
      </div>
      <div class="col-1"></div>
    </div>

    <div class="container pt-0">
      <div class="card" *ngIf="selectedOnepager.length == 0">
        <div class="row d-flex justify-content-center">
          <div class="col-12">
            <ul id="progressbar" class="text-center">
              <div class="d-flex justify-content-center">
                <li *ngFor="let progress of selectedApplication?.value.acquired.purchase_progress" class="step0">
                  {{ progress.title }} <br />
                  <img class="icon" [src]="progress.icon" />
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>

      <div *ngIf="selectedOnepager.length == 1">
        <div>
          <img [src]="selectedOnepager[0]?.value" width="100%" alt="" />
        </div>
      </div>

      <div class="col-12 p-0 d-flex justify-content-end">
        <button
          *ngIf="roleLevelId == 2 && !purchaseApplicationProcessing"
          class="btn btn-info mt-2"
          (click)="purchaseApplication(confirmAppPurchaseModal)"
        >
          Continue Purchase
        </button>
        <span *ngIf="roleLevelId == 2 && purchaseApplicationProcessing">
          <img
            class="icon mr-2"
            src="../../../assets/loader/custom-spinner-final.gif"
            style="margin-right: -20px; margin-top: -2px"
          />
          Processing please wait..
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #confirmAppPurchaseModal let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Confirmation</h6>

    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <h4 class="ml-3">Are you sure you want to active this (Network: {{ selectedNetwork }})?</h4>
    <br />
    <h6 class="ml-3">
      Note: Purchasing this network will allow you to access all other apps within the ({{ selectedNetwork }}) network.
    </h6>
    <br />
    <h6 class="ml-3">
      Add-On Service Remaining: {{ msf?.value.addons - activeNetworks.length }}
      <!-- Replace currentPurchasedNetworks with your function -->
    </h6>
    <h6 class="mb-2 ml-3">You can only purchase apps within your current add-on service limit.</h6>

    <button class="btn btn-sm btn-primary float-right" (click)="activateNetwork(selectedNetwork)">
      Activate Network
    </button>
    <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Product Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="selectedOnepager.length == 0">
      <div class="row">
        <div class="col-3 d-flex justify-content-center">
          <img [src]="selectedApplicationPurchased?.value.thumbnail.logo" height="100" width="100" alt="" />
        </div>
        <div class="col-8 d-flex justify-content-center">
          <p>{{ selectedApplicationPurchased?.value.acquired.description }}</p>
        </div>
        <div class="col-1"></div>
      </div>

      <div class="container pt-0">
        <div class="card">
          <div class="row d-flex justify-content-center">
            <div class="col-12">
              <ul id="progressbar" class="text-center">
                <div class="d-flex justify-content-center">
                  <li
                    *ngFor="let progress of selectedApplicationPurchased?.value.acquired.purchase_progress"
                    [ngClass]="checkProgressClass(progress.status)"
                  >
                    {{ progress.title }}<br />
                    <img class="icon" [src]="progress.icon" />
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="selectedOnepager.length == 1">
      <div>
        <img [src]="selectedOnepager[0]?.value" width="100%" alt="" />
      </div>
    </div>
  </div>
</ng-template>

<ng-template *ngIf="roleLevelId == 2" #viewStoreHoursSettingsModal let-modal>
  <div class="card">
    <div class="card-header">
      <h3 style="text-align: center">STORE HOURS</h3>
      <h5 style="text-align: center">Indicate your store's operating hours for a more manageable account</h5>
    </div>
    <div class="card-body">
      <app-store-hours-settings (closeModal)="closeSettingsModal()"></app-store-hours-settings>
    </div>
  </div>
</ng-template>
