<app-loader *ngIf="loader"></app-loader>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w==" crossorigin="anonymous" referrerpolicy="no-referrer" />
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css">
<div id="content" class="flex">
    <!-- ############ Main START-->
    <div>
      <!-- <div   id="page-hero">
        <div class="padding d-flex">
          <div class="page-title">
            <h2 class="--spam">Vips Users</h2>
            <small class="--desc">GoVIPCenter list Vips User</small>
          </div>
          <div class="flex"></div>
        </div>
      </div> -->
      <div class="container-fluid" style="padding-inline:10px; width: 95%;display: flex;justify-content: space-between; align-items: center;gap: 20px; margin-block: 5px; padding-right: 25px;">
        <div class="d-flex align-items-center header" style="gap: 15px;">
          <img src="../../../../assets/vips2/1.png" width="90px" alt="">
          <h1 class="m-0" style="font-size: 24px; font-weight: bolder;color: #5e676f;">VIPS CUSTOM LIST</h1>
        </div>
      </div>
      <div class="pr-3 pl-3" id="page-content">
        <div class="card p-2 box-2 d-flex">
          <div>
            <div class="b-b">
              <div class="nav-active-border b-primary bottom">
                <ul class="nav" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active font-weight-bold" id="home-tab" data-toggle="tab" 
                    href="#home3" role="tab" aria-controls="home" aria-selected="true">Application</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link  font-weight-bold" id="profile-tab" data-toggle="tab" 
                    href="#profile3" role="tab" aria-controls="profile" aria-selected="false">Approved</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="flex"></div>
          <div class="card mb-2 mt-0 p-2">
              <div class="row" [formGroup]="searchByFormGroup">
                  <div class="col-12 d-flex justify-content-end">
                      <input 
                          formControlName="searchString" 
                          type="text" 
                          placeholder="Search" 
                          name="text" 
                          class="input"
                          (keyup.enter)="searchData()"
                      >
                      <button class="Btn" (click)="searchData()">
                          Search &nbsp; 
                          <svg class="svg" viewBox="0 0 512 512">
                              <g>
                                  <g>
                                  <path style="fill:#FFF;" d="M447.5,477.85l30.3-30.3c4-4,4-10.4,0-14.4l-149.2-149.2l-44.7,44.7l149.2,149.2
                                      C437.1,481.85,443.5,481.85,447.5,477.85z"/>
                                  <path style="fill:#FFF;" d="M163.4,67.05c-53.2,0-96.5,43.3-96.5,96.5c0,5,4.1,9.1,9.1,9.1s9.1-4.1,9.1-9.1
                                      c0-43.2,35.1-78.3,78.3-78.3c5,0,9.1-4.1,9.1-9.1C172.5,71.05,168.4,67.05,163.4,67.05z"/>
                                  <path style="fill:#FFF;" d="M335,277.55c-3.5-3.5-9.3-3.5-12.8,0l-15.9,15.9l-21.1-21.1c25.9-28.9,41.7-67.1,41.7-108.9
                                      c0-90.1-73.3-163.4-163.4-163.4S0,73.35,0,163.45s73.3,163.4,163.4,163.4c41.8,0,79.9-15.8,108.9-41.7l21.1,21.1l-15.9,15.9
                                      c-1.7,1.7-2.7,4-2.7,6.4s1,4.7,2.7,6.4l141.9,141.9c5.6,5.6,13,8.7,20.9,8.7s15.3-3.1,20.9-8.7l15.7-15.7c5.6-5.6,8.7-13,8.7-20.9
                                      s-3.1-15.3-8.7-20.9L335,277.55z M18.2,163.45c0-80.1,65.2-145.3,145.3-145.3s145.3,65.2,145.3,145.3s-65.2,145.3-145.3,145.3
                                      S18.2,243.55,18.2,163.45z M464.1,448.45l-15.7,15.7c-2.2,2.2-5,3.4-8.1,3.4s-5.9-1.2-8.1-3.4l-135.5-135.5l31.9-31.9l135.5,135.5
                                      c2.2,2.2,3.4,5,3.4,8.1S466.2,446.35,464.1,448.45z"/>
                                  </g>
                              </g>
                          </svg>
                        </button>
                  </div>
          
              </div>
            </div>
            
            <div class="tab-content pl-3 pl-3 pb-0 pt-0">
              <div class="tab-pane fade active show" id="home3" role="tabpanel" aria-labelledby="home-tab">
                <div class="card mb-0 pl-2 pr-2">
                  <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
                    <thead>
                      <tr>
                          <th data-sortable="true" data-field="id">
                              <div>App Details</div>
                          </th>
                          <th data-sortable="true" colspan="2" data-field="owner">
                              <div>Custom Details</div>
                          </th>
                          <th data-field="finish" class="text-center">Logo</th>
                          <th data-field="finish" class="text-center">Banner</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let item of customList
                        "
                        (click)="openCustom(customAppThemeModal, item)"
                        style="cursor: pointer"
                        class="odd"
                        role="row"
                      >
                        <td class="flex">
                          <div class="item-except text-sm h-1x">
                            Username : <span style="color: cadetblue; font-weight: bold;"> {{ item?.username }}</span>
                          </div>
                          <div class="item-except text-sm h-1x ">
                            App Custom Name : <span style="color: cadetblue; font-weight: bold;"> {{ item?.customAppName }}</span>
                          </div>
                          <div class="item-except text-sm h-1x ">
                            Mode : <span style="color: cadetblue; font-weight: bold;"> {{ item?.mode }}</span>
                          </div>
                        </td>
                        <td class="flex">
                          <div class="item-except text-sm h-1x">
                            Background Color : <span style="color: cadetblue; font-weight: bold;">{{ item?.backgroundCustomColor }}</span>
                          </div>
                          <div class="item-except text-sm h-1x ">
                            Button Color : <span style="color: cadetblue; font-weight: bold;">{{ item?.buttonCustomColor }}</span>
                          </div>
                          <div class="item-except text-sm h-1x ">
                            Button Font Color : <span style="color: cadetblue; font-weight: bold;">{{ item?.buttonCustomFontColor }}</span>
                          </div>
                        </td>
      
                        <td class="flex">
                          <div class="item-except text-sm h-1x">
                            Primary Color : <span style="color: cadetblue; font-weight: bold;">{{ item?.primaryCustomColor }}</span>
                          </div>
                          <div class="item-except text-sm h-1x ">
                            Primary Font Color : <span style="color: cadetblue; font-weight: bold;">{{ item?.primaryCustomFontColor }}</span>
                          </div>
                          <div class="item-except text-sm h-1x ">
                            Primary Wallet Font Color : <span style="color: cadetblue; font-weight: bold;">{{ item?.primaryCustomWalletFontColor }}</span>
                          </div>
                        </td>
                        
                        <td class="flex text-center">
                          <img class="pb-2" [src]="item?.customImageUrl" style="width: 80px;"/>
                        </td>
      
                        <td class="flex text-center" style="width: 250px;">
                          <img class="pb-2" [src]="item?.customImageLandscapeUrl" style="max-height: 100px; max-width: 100%; object-fit: contain;" />
                        </td>
      
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="tab-pane fade" id="profile3" role="tabpanel" aria-labelledby="profile-tab">
                <div class="card mb-0 pl-2 pr-2">
                  <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
                    <thead>
                      <tr>
                          <th data-sortable="true" data-field="id">
                              <div>App Details</div>
                          </th>
                          <th data-sortable="true" colspan="2" data-field="owner">
                              <div>Custom Details</div>
                          </th>
                          <th data-field="finish" class="text-center">Logo</th>
                          <th data-field="finish" class="text-center">Banner</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let item of customApprovedList
                        "
                        (click)="openCustom(customAppThemeModal, item)"
                        style="cursor: pointer"
                        class="odd"
                        role="row"
                      >
                        <td class="flex">
                          <div class="item-except text-sm h-1x">
                            Username : <span style="color: cadetblue; font-weight: bold;"> {{ item?.username }}</span>
                          </div>
                          <div class="item-except text-sm h-1x ">
                            App Custom Name : <span style="color: cadetblue; font-weight: bold;"> {{ item?.customAppName }}</span>
                          </div>
                          <div class="item-except text-sm h-1x ">
                            Mode : <span style="color: cadetblue; font-weight: bold;"> {{ item?.mode }}</span>
                          </div>
                        </td>
                        <td class="flex">
                          <div class="item-except text-sm h-1x">
                            Background Color : <span style="color: cadetblue; font-weight: bold;">{{ item?.backgroundCustomColor }}</span>
                          </div>
                          <div class="item-except text-sm h-1x ">
                            Button Color : <span style="color: cadetblue; font-weight: bold;">{{ item?.buttonCustomColor }}</span>
                          </div>
                          <div class="item-except text-sm h-1x ">
                            Button Font Color : <span style="color: cadetblue; font-weight: bold;">{{ item?.buttonCustomFontColor }}</span>
                          </div>
                        </td>
      
                        <td class="flex">
                          <div class="item-except text-sm h-1x">
                            Primary Color : <span style="color: cadetblue; font-weight: bold;">{{ item?.primaryCustomColor }}</span>
                          </div>
                          <div class="item-except text-sm h-1x ">
                            Primary Font Color : <span style="color: cadetblue; font-weight: bold;">{{ item?.primaryCustomFontColor }}</span>
                          </div>
                          <div class="item-except text-sm h-1x ">
                            Primary Wallet Font Color : <span style="color: cadetblue; font-weight: bold;">{{ item?.primaryCustomWalletFontColor }}</span>
                          </div>
                        </td>
                        
                        <td class="flex text-center">
                          <img class="pb-2" [src]="item?.customImageUrl" style="width: 80px;"/>
                        </td>
      
                        <td class="flex text-center" style="width: 250px;">
                          <img class="pb-2" [src]="item?.customImageLandscapeUrl" style="max-height: 100px; max-width: 100%; object-fit: contain;" />
                        </td>
      
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
  
         
  
          <!-- <div class="row pl-2 pr-2">
            <div class="col-sm-12 col-md-5">
              <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                <div class="col-sm-12 col-md-12">
                  <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                    Showing {{ (users?.pageNumber - 1) * users?.pageSize + 1 }} to
                    {{ (users?.pageNumber - 1) * users?.pageSize + users?.data?.length }} of
                    {{ users?.data?.length }} entries
                  </div>
                </div>
              </div>
            </div>
  
            <div class="col-sm-12 col-md-7" style="float: right">
              <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                <ul class="pagination" style="float: right">
                  <pagination-controls (pageChange)="getVipsUser(buildPageUrl($event, 10))"></pagination-controls>
                </ul>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- ############ Main END-->
  </div>

  <ng-template #verifyApplicationModal let-modal>
    <div class="modal-header">
      <h6 class="modal-title">Are you sure you want to approve this user?</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
      <button class="btn btn-sm btn-primary float-right" (click)="approve(selectedUser)">Proceed</button>
      <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
        Cancel
      </button>
    </div>
  </ng-template>
  
  <ng-template #rejectApplicationModal let-modal>
    <div class="modal-header">
      <h6 class="modal-title">Are you sure you want to reject this user?</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
      <textarea 
          type="text"
          class="form-control"
          id="remm"
          [(ngModel)] = "remarksStr"
        ></textarea>
    </div>
  
    <div class="modal-body">
      <button class="btn btn-sm btn-danger float-right" (click)="reject(selectedUser)">Proceed</button>
      <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
        Cancel
      </button>
    </div>
  </ng-template>

  <ng-template #myQRTemplateModal let-modal>    
    <!-- <button class="btn btn-dark btn-lg" (click)="getQR(myQRTemplate)">Print &nbsp; <i class="fa fa-print"></i></button> -->
    <div class="d-flex justify-content-center">
      <div #myQRTemplate >
          <div style="background: url(../../../assets/images/Vips\ QR.png);
          background-repeat: no-repeat !important;
          background-size: 210mm 297mm !important;
          width: 210mm !important;
          height: 297mm !important;" 
          >
            <div style='display:flex; justify-content: center'>
              <ngx-qrcode
                [elementType]="elementType"
                [errorCorrectionLevel]="correctionLevel"
                [value]="selectedItem.qr"
                cssClass="bshadow"
                [width]="300"
                style='margin-left:10px; margin-top:320px; border-radius: 2%'
              ></ngx-qrcode>
            </div>
            <div style='display:flex; justify-content: center;'>
              <div style="width: 180px; height: 35px; display:flex; justify-content: center; align-items:center;">
                <h1 style="font-weight: bolder; font-family: Consolas;" class="text-primary">{{ "V" + selectedItem.id.padStart(5,0) + selectedItem.username.substring(0,2).toUpperCase() }}</h1>
              </div>
            </div>
            <div style='margin: 45px; display:flex; justify-content: center;'>
                <div style="width: 470px; height: 55px; display:flex; justify-content: center; align-items:center;">
                  <h3 
                    style='font-family: "Segoe UI", Arial, sans-serif; max-width: 470px; text-align:center; font-weight: 700; color: #000;'
                    >
                      {{ selectedItem?.username.toUpperCase() }}
                  </h3>
                </div>
               
            </div>
          </div>
        </div>
    </div>
  </ng-template>

  <ng-template #customAppThemeModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title font-weight-bold">Custom App Theme</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body pr-0 pt-0">
      <div class="row p-0 m-0">
        <div class="col-9 box-2 row p-0 m-0">
          <div class="col-12 pb-2">
            <div class="w-100 d-flex justify-content-center">
              <img [src]="selectedItem?.customImageLandscapeUrl" alt="" height="150" width="auto" style="max-width: 800px">
            </div>
          </div>

          <div class="col-3 w-100 d-flex justify-content-center">
            <img class="box-2" [src]="selectedItem?.customImageUrl" alt="" height="150" width="150">
          </div>
          <div class="col-6 d-flex w-100">
            <div style="height: 150;" class="w-100 d-flex flex-column justify-content-center align-items-center">
              <h2 class="font-weight-bold">{{ selectedItem?.customAppName }} </h2>
              <h5>Username: {{ selectedItem?.username }} </h5>
            </div>
          </div>
          <div class="col-3 d-flex">
            <div class="w-100 d-flex flex-column justify-content-center" style="height: 150;">
              <div class="w-100 d-flex justify-content-end">
                <h5>Mode: </h5>
                <label class="switch ml-2 mr-2" title="Mode: To set the mood of your custom app">
                  <span class="sun"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g fill="#ffd43b"><circle r="5" cy="12" cx="12"></circle><path d="m21 13h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm-17 0h-1a1 1 0 0 1 0-2h1a1 1 0 0 1 0 2zm13.66-5.66a1 1 0 0 1 -.66-.29 1 1 0 0 1 0-1.41l.71-.71a1 1 0 1 1 1.41 1.41l-.71.71a1 1 0 0 1 -.75.29zm-12.02 12.02a1 1 0 0 1 -.71-.29 1 1 0 0 1 0-1.41l.71-.66a1 1 0 0 1 1.41 1.41l-.71.71a1 1 0 0 1 -.7.24zm6.36-14.36a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm0 17a1 1 0 0 1 -1-1v-1a1 1 0 0 1 2 0v1a1 1 0 0 1 -1 1zm-5.66-14.66a1 1 0 0 1 -.7-.29l-.71-.71a1 1 0 0 1 1.41-1.41l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.29zm12.02 12.02a1 1 0 0 1 -.7-.29l-.66-.71a1 1 0 0 1 1.36-1.36l.71.71a1 1 0 0 1 0 1.41 1 1 0 0 1 -.71.24z"></path></g></svg></span>
                  <span class="moon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="m223.5 32c-123.5 0-223.5 100.3-223.5 224s100 224 223.5 224c60.6 0 115.5-24.2 155.8-63.4 5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6-96.9 0-175.5-78.8-175.5-176 0-65.8 36-123.1 89.3-153.3 6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"></path></svg></span>   
                  <input type="checkbox" class="input" [checked]="selectedItem?.mode" disabled>
                  <span class="slider"></span>
                </label>
              </div>
              <div class="w-100 d-flex justify-content-end" *ngIf="selectedItem?.status == 'Approved'" [formGroup]="customFormGroup">
                <h5>Extras: </h5>
                <label class="switch1 ml-2 mr-3">
                  <input class="toggle1" type="checkbox" formControlName="extras">
                  <span class="slider1"></span>
                  <span class="card-side"></span>
                </label>
              </div>
            </div>
            
          </div>
          <div class="col-6 pt-3">
            <div class="form-group row">
              <label class="col-sm-7">
                Background Color: 
                <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                  title="This will set the background color of the app."></i>
              </label>
              <div class="col-sm-5">
                <div>
                  <input 
                    class="input"
                    [colorPicker]="'#' + selectedItem?.backgroundCustomColor" 
                    [style.background]="'#' + selectedItem?.backgroundCustomColor" 
                    [value]="'#' + selectedItem?.backgroundCustomColor"
                    disabled
                  />
                </div>
              </div>
            </div>
            

            <div class="form-group row">
              <label class="col-sm-7">
                Button Color:
                <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                  title="This will set the color of the buttons. For best results, please match the colors in Button Color, Primary Color and Primary Font Color."></i>
              </label>
              <div class="col-sm-5 d-flex">
                <div class="align-items-end">
                  <input 
                    class="input"
                    [colorPicker]="'#' + selectedItem?.buttonCustomColor" 
                    [style.background]="'#' + selectedItem?.buttonCustomColor" 
                    [value]="'#' + selectedItem?.buttonCustomColor"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-7">
                Button Font Color:
                <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                title="This will set the font color of the buttons."></i>
              </label>
              <div class="col-sm-5 d-flex">
                <div class="align-items-end">
                  <input 
                    class="input"
                    [colorPicker]="'#' + selectedItem?.buttonCustomFontColor" 
                    [style.background]="'#' + selectedItem?.buttonCustomFontColor" 
                    [value]="'#' + selectedItem?.buttonCustomFontColor"
                    disabled
                  />
                  </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-7">
                Primary Color:
                <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                title="This will set the colors for the wallet background icons. For best results, please match the colors in Button Color, Primary Color and Primary Font Color."></i>
              </label>
              <div class="col-sm-5 d-flex">
                <div class="align-items-end">
                  <input 
                    class="input"
                    [colorPicker]="'#' + selectedItem?.primaryCustomColor" 
                    [style.background]="'#' + selectedItem?.primaryCustomColor" 
                    [value]="'#' + selectedItem?.primaryCustomColor"
                    disabled
                  />
                  </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-7">
                Primary Font Color:
                <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                title="	This will set the color for the Vips Kabuhayan Program. For best results, please match the colors in Button Color, Primary Color and Primary Font Color."></i>
              </label>
              <div class="col-sm-5 d-flex">
                <div class="align-items-end">
                  <input 
                    class="input"
                    [colorPicker]="'#' + selectedItem?.primaryCustomFontColor" 
                    [style.background]="'#' + selectedItem?.primaryCustomFontColor" 
                    [value]="'#' + selectedItem?.primaryCustomFontColor"
                    disabled
                  />
                  </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-7">
                Primary Wallet Font Color:
                <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" 
                title="This will set the font colors for the wallet and Vips Kabuhayan Program"></i>
              </label>
              <div class="col-sm-5 d-flex">
                <div class="align-items-end">
                  <input 
                    class="input"
                    [colorPicker]="'#' + selectedItem?.primaryCustomWalletFontColor" 
                    [style.background]="'#' + selectedItem?.primaryCustomWalletFontColor" 
                    [value]="'#' + selectedItem?.primaryCustomWalletFontColor"
                    disabled
                  />
                  </div>
              </div>
            </div>

          </div>
          <div class="col-6 pt-3">
            <div>
              <img class="card-image" [src]="cardImage" alt="Card image cap">
            </div>
            <div class="mt-3">
              <div class="slider2">
                  <div class="slider2-wrapper pt-1 pb-2" [style.width.px]="imageWidth * images.length + (images.length * 5)">
                      <div class="slider2-item" *ngFor="let image of images">
                          <img class="item-img" (mouseover)="changeBigImage(image.path)" [src]="image.path" [style.width.px]="imageWidth">
                      </div>
                  </div>
                  <button class="slider2-arrow left" *ngIf="images.length >= 6" (click)="scroll(-1)">&lt;</button>
                  <button class="slider2-arrow right" *ngIf="images.length >= 6" (click)="scroll(1)">&gt;</button>
              </div>
          </div>
          </div>
          
        </div>
        
        <div class="col-3 p-2 w-100 d-flex flex-column">
          <div class="w-100 d-flex justify-content-center">
            <h3 class="font-weight-bold">Outlet QR</h3>
          </div>
          <div class="w-100 d-flex justify-content-center">
            <ngx-qrcode
              [elementType]="elementType"
              [errorCorrectionLevel]="correctionLevel"
              [value]="selectedItem?.qr"
              cssClass="bshadow"
              [width]="200"
              style='border-radius: 2%'
            ></ngx-qrcode>
          </div>
          <div class="w-100 d-flex justify-content-center">
            <h6>Scan QR to test</h6>
          </div>
          <div class="d-flex flex-column align-items-center" *ngIf="selectedItem?.status == 'Applied'">
            <button class="button-confirm mb-2 mt-2" style="width: 100px;" (click)="openConfirmation(verifyApplicationModal, selectedItem)">
                Approve
            </button>
            <button class="button-confirm" style="width: 100px;"  (click)="openConfirmation(rejectApplicationModal, selectedItem)">
                Reject
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>