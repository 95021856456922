import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ChatService } from 'src/app/chat.service';
import { LocationTracker, MarkerTracker,Cashier } from 'src/data/classes/location-interface';
import { CashierUsersService } from 'src/data/services/web/cashier-users.service';
import { ManagerUsersService } from 'src/data/services/web/manager-users.service';

@Component({
  selector: 'app-location-tracker',
  templateUrl: './location-tracker.component.html',
  styleUrls: ['./location-tracker.component.less']
})
export class LocationTrackerComponent implements OnInit {

  @ViewChild('divMessages') divMessages:ElementRef;


  loader : any = false;
  location: LocationTracker;
  storeAddressFormGroup : FormGroup;
  dragPin : boolean = true;
  storeCount = 0;

  constructor(private usersService : ManagerUsersService,
    private chatService : ChatService,
    private renderer:Renderer2) { }

  ngOnInit(): void {
    this.initStoreAddressFormGroup();
    this.initializeStartingMapPoint();
    this.initializeStoreCount();
  }
  ngAfterViewInit(){
    this.divMessages.nativeElement.insertAdjacentHTML('beforeend', '<span>JuanPay realtime logger initialize successfully...<br/></span>');
  }
  
  public subscribeToEvents(): void {  

    this.chatService.onlineUsersReceived.subscribe((data: any) => {  
     var result =[];
      data.forEach(user => {
        if(!result.find(x=>x.name == user.name)){
          result.push(user)
        }
      });
      result = result.sort(function(a, b){
        return b.name - a.name;
      });

      result.forEach(online => {
        var len = this.location.markers.filter(x=>x.managerEmail == online.name 
          || x.cashiers.filter(c=> c.email == online.name).length > 0).length;

          if(len > 0){
            var marker= this.location.markers.filter(x=>x.managerEmail == online.name 
              || x.cashiers.filter(c=> c.email == online.name).length > 0)[0];
              marker.iconValue = 1;
          }
      }); 

     
      
    });  
    this.chatService.logoutUserEmail.subscribe((email: any) => {  
      var len = this.location.markers.filter(x=>x.managerEmail == email
        || x.cashiers.filter(c=> c.email == email).length > 0).length;
        if(len > 0){
          var marker= this.location.markers.filter(x=>x.managerEmail == email 
            || x.cashiers.filter(c=> c.email == email).length > 0)[0];
            marker.iconValue = 0;
        }

    });

    this.chatService.logsReceived.subscribe((data: any) => {  
     this.divMessages.nativeElement.insertAdjacentHTML('afterbegin', '<span>'+data+'<br/></span>');
    });


    
 
  }  

  getOnlineCount() :  any {
    try   
    {
      return this.location.markers.filter(x => x.iconValue == 1).length - 1;
    } catch {
      return 0;
    }
  } 

  getPinnedStores() :  any {
    try   
    {
      return this.location.markers.length - 1;
    } catch {
      return 0;
    }
  } 


  initializeStoreCount(){
    this.usersService.storeAddressesCount().subscribe((storeCount) => {
      this.storeCount = (storeCount);
    });
  }

 initializeStartingMapPoint(){

    this.loader = true;
    this.usersService.storeAddressesCashiers().subscribe((storeAddress) => {
      this.loader = false;
      var markers : MarkerTracker[] = [];
      markers.push({
        lat: Number(this.storeAddressFormGroup.controls.latitude.value),
        lng:  Number(this.storeAddressFormGroup.controls.longtitude.value),
        iconGreen : { url: '../../../assets/images/marker_green_40.svg',
              scaledSize: {
                width: 40,
                height: 40
              }
          },
        iconRed : { url: '../../../assets/images/marker_red_40.svg',
            scaledSize: {
              width: 40,
              height: 40
            }
        },
        name : "My Location",
        showRadius : false,
        meterDistanceInRadius : -1,
        meterDistanceInRoad : -1,
        kmDistanceInRoad : "N/A",
        originAddresses : "N/A",
        destinationAddresses : "N/A",
        managerEmail : "N/A",
        cashiers : [],
        iconValue : 1
      });
  
     
 
      storeAddress.forEach(storeAddress => {
        markers.push({
          lat: Number(storeAddress.latitude),
          lng:  Number(storeAddress.longtitude),
          iconGreen : { url: '../../../assets/images/marker_green_40.svg',
          scaledSize: {
                width: 40,
                height: 40
              }
          },
          iconRed : { url: '../../../assets/images/marker_red_40.svg',
              scaledSize: {
                width: 40,
                height: 40
              }
          },
          name : storeAddress.name,
          showRadius : false,
          meterDistanceInRadius : 0,
          meterDistanceInRoad : -1,
          kmDistanceInRoad : "N/A",
          originAddresses : "N/A",
          destinationAddresses : "N/A",
          managerEmail : storeAddress.managerEmail,
          cashiers : storeAddress.cashierEmails.map(x => ({
            email: x.email
          })),
          iconValue : 0
        });
      });
  
      var locationRef: LocationTracker = {
        latitude:  Number(this.storeAddressFormGroup.controls.latitude.value),
        longitude:  Number(this.storeAddressFormGroup.controls.longtitude.value),
        zoom: 6,
        isFullScreen: true,
        markers: markers
      };
      console.log(locationRef);
      this.location = locationRef;
      this.subscribeToEvents();
      },(error)=>{
        this.loader = false
      });

 }

 initStoreAddressFormGroup(): void {
  this.storeAddressFormGroup = new FormGroup({
    latitude : new FormControl("12.1806408"),
    longtitude: new FormControl("122.7772939"),
  });
}

updateCoordinates(e){
  
  this.storeAddressFormGroup.controls.latitude.setValue(e.lat);
  this.storeAddressFormGroup.controls.longtitude.setValue(e.lng);
}


}
