<app-loader *ngIf="loader"></app-loader>

<form class="" role="form" [formGroup]="smsFormGroup" >
    <div class="form-group">
        <label>Mobile Number : {{smsFormGroup.controls.mobileNo.value}}</label>
    </div>
    <div class="form-group">
        <label>Message</label>
        <input formControlName="message" type="text" class="form-control" placeholder="Enter message here!">
    </div>
   
    <button type="button" class="btn btn-primary mb-4" (click)="sendSms()">Send Message</button>
</form>