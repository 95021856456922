import { Component, OnInit } from "@angular/core";
import { ChatService } from "src/app/chat.service";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { EmailSubsService } from 'src/app/services/email-subs.service';


declare let LiveAgent: any;
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.less"],
})
export class HomeComponent implements OnInit {
  FormData : FormGroup;
  public showMore1: boolean;
  public showMore2: boolean;
  public showMore3: boolean;
  public showMore4: boolean;
  public showMore5: boolean;
  public showMoreArticle1: boolean;
  public showMoreArticle2: boolean;
  public showMoreArticle3: boolean;
  public showMoreArticle4: boolean;
  public showMoreArticle5: boolean;
  public toggleImageBackground: boolean;
  public toggleImage1: boolean;
  public toggleImage2: boolean;
  public toggleImage3: boolean;
  public toggleImage4: boolean;
  public toggleImage5: boolean;
  public toggleImage6: boolean;
  public toggleImage7: boolean;
  public toggleImage8: boolean;
  public toggleImage9: boolean;



  constructor(
    private chatService: ChatService,
    private localStorageCacheService : LocalStorageCacheService,
    private emailSubs: EmailSubsService,
    private builder : FormBuilder
    ) {}
    // message:boolean = false;
  ngOnInit(): void {
    // this.FormData = this.builder.group({
    //   email: new FormControl('',[Validators.compose([Validators.required,Validators.email])]),
    // })

    let nav = document.querySelector('.vip--nav');
    let header = document.getElementById('header');
    let logo = document.getElementById('goviplogo');

    nav.addEventListener('mouseover', () => header.style.background = "white");
    nav.addEventListener('mouseover', () => logo.style.filter = "none");
    nav.addEventListener('mouseleave', () => logo.style.filter = "invert(100%) sepia(0%) saturate(2%) hue-rotate(346deg) brightness(107%) contrast(101%)");
    nav.addEventListener('mouseleave', () => header.style.background = "linear-gradient(180deg,#07243c -35%,rgba(255,255,255,0) 95%)");

    // window.onscroll = () => {
    //   if(window.scrollY > 300){
    //     header.classList.add('nav-colored');
    //   } else {
    //     header.classList.remove('nav-colored');
    //   }
    // }

    const scriptUrl = "https://juanpay.ladesk.com/scripts/track.js";
    const node = document.createElement("script");
    node.src = scriptUrl;
    node.id = "la_x2s6df8d";
    node.type = "text/javascript";
    node.async = true;
    node.charset = "utf-8";
    node.onload = function (e) {

    LiveAgent.createButton("f19q1v7l", document.getElementById("chatButton"));
    };
    document.getElementsByTagName("head")[0].appendChild(node);


    this.resetClass();

    var email = this.localStorageCacheService.getStorage("email_address");
    this.logoutSocket(email);

   // this.router.navigate(["login"]);
   this.localStorageCacheService.removeStorage("email_address");
   this.localStorageCacheService.removeStorage("access_token");
   this.localStorageCacheService.removeStorage("refresh_token");





  }
  // Email Subscription Function
  // onSubmit(FormData){
  //   const btn = document.querySelector('.btn-secondary');

  //   this.emailSubs.PostSubscribe(FormData).subscribe((result )=>
  //   {
  //     btn.classList.add('animate__animated', 'animate__bounce');
  //     console.log(FormData);
  //     console.log(result);
  //     this.message = true;
  //     this.FormData.reset({});
  //     setTimeout(() => {
  //               this.message = false;
  //            }, 5000);
  //   });
  // }
  // close(){
  //   this.message = false;
  // }


  logoutSocket(email : any): void {
    this.chatService.logoutSocket(email);
  }

  public resetClass(): void {
    this.showMore1 = false;
    this.showMore2 = false;
    this.showMore3 = false;
    this.showMore4 = false;
    this.showMore5 = false;
    this.showMoreArticle1 = false;
    this.showMoreArticle2 = false;
    this.showMoreArticle3 = false;
    this.showMoreArticle4 = false;
    this.showMoreArticle5 = false;
    this.toggleImageBackground = false;
    this.toggleImage1 = false;
    this.toggleImage2 = false;
    this.toggleImage3 = false;
    this.toggleImage4 = false;
    this.toggleImage5 = false;
    this.toggleImage6 = false;
    this.toggleImage7 = false;
    this.toggleImage8 = false;
    this.toggleImage9 = false;
  }


}
