<div class="container">
  
<ngx-simple-calendar [options]="options2" (click)="onChangeDate($event)" >
    <ng-template #day
                 let-date="number"
                 let-events="events"
                 let-active="today"
                 let-weekend="weekend">
      <div class="round-date"
           [class.active]="active"
           [class.weekend]="weekend">
        <span>{{date}}</span>
        <span style="font-size:8.5px; color:darkgreen">IN: {{getIn(date)}}</span>
        <span style="font-size:8.5px; color:darkgreen">OUT: {{getOut(date)}}</span>
        <!-- <div class="events">
          <div class="event" [style.background-color]="event.data.color" *ngFor="let event of events" [title]="event.data.name + event.data.description"></div>
        </div> -->
      </div>
    </ng-template>
  </ngx-simple-calendar>
</div>