// html-parser.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HtmlParserService {
  static convertHtmlToTextWithLineBreaks(html: string): string {
    return html?.replace(/<br>/g, '\n');
  }
}
