import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { ManagerUsersService } from 'src/data/services/web/manager-users.service';
import { VipcoTransactionService } from 'src/data/services/web/vipco-transaction.service';
import { WalletService } from 'src/data/services/web/wallet.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { environment } from 'src/environments/environment';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { DatePipe } from '@angular/common';
import { TransactionService } from 'src/data/services/web/transaction.service';
import { Select2OptionData } from 'ng-select2';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-vips-dashboard',
  templateUrl: './vips-dashboard.component.html',
  styleUrls: ['./vips-dashboard.component.less']
})
export class VipsDashboardComponent implements OnInit {

  public localStorageCacheService: LocalStorageCacheService;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value:any = "";
  emptyString: any = "";

  searchByFormGroup: FormGroup;
  public filterUserFormGroup: FormGroup;
  public searchByUsernameFormGroup: FormGroup;
  public searchTranscationFormGroup: FormGroup;

  users: any = [];
  userAccumulator: any = [];
  // [
  //   { "id": 1, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 2, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 3, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 4, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 5, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 6, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true },
  //   { "id": 7, "date": "Nov 6, 2022", "userType": "Merchant", "mobileNo": "09305897363", "city": "Quezon City", "status": true }
  // ];
  
  transactions: any = [];
  // [
  //   { "id": 1, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10001, "rewards": 50, "status": 2 },
  //   { "id": 2, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10002, "rewards": 50, "status": 2 },
  //   { "id": 3, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10003, "rewards": 50, "status": 2 },
  //   { "id": 4, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10004, "rewards": 50, "status": 2 },
  //   { "id": 5, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10005, "rewards": 50, "status": 2 },
  //   { "id": 6, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10006, "rewards": 50, "status": 2 },
  //   { "id": 7, "date": "Nov 6, 2022", "userType": "Merchant", "referenceNo": "ABC123DEF456GHI789", "transactionNo": 10007, "rewards": 50, "status": 2 }
  // ];

  constructor(localStorageCacheService: LocalStorageCacheService,
    private modalServe: NgbModal,
    private usersService: ManagerUsersService,
    private walletService: WalletService,
    private datepipe: DatePipe,
    private websettings: WebSettingsService,
    private transactionService: TransactionService,
    private vipcoTransactionService: VipcoTransactionService,
    public webSettingsService: WebSettingsService) { 
   
    this.localStorageCacheService = localStorageCacheService;
  }

  ngOnInit(): void {
    this.value = this.localStorageCacheService.getStorage("forQR");
    var managerId = this.localStorageCacheService.getStorage("id");
    this.managerUserId = managerId;
    this.getManagerUser(managerId);
    this.initReceipt();
    this.getUserTypes();
    this.initFilterUserFormGroup();
    this.initSearchByUsernameFormGroup();
    this.initSearchTransactionFormGroup();
    this.getWallet();
    this.getVipsUsers();
    this.getTransaction();
  }


  managerUserId: any = 0;
  userCount: any;
  managerCount: any;
  riderCount: any;

getVipsUsers(){
  this.vipcoTransactionService.getVipsLinkedUsers().subscribe((result) => {
    this.users = (result.response_data) != null ? result.response_data : 0;
    this.userAccumulator = result.response_data != null ? result.response_data : [];
    if (result.response_data.length != 0){
      this.userCount = (result.response_data).filter(x=>x.Usertype != "LEVEL_6" && x.Usertype != "LEVEL_7").length;
      this.managerCount = (result.response_data).filter(x=>x.Usertype == "LEVEL_6" ).length;
      this.riderCount = (result.response_data).filter(x=>x.Usertype == "LEVEL_7").length;
    }
    
    // if (result.data.length == 0 && result.pageNumber != 1) {
    //   this.getVIPCOTransactions(result.previousPage, _sortBy, _sort);
    // } else {
    //   this.transactions = result;
    // }
  });
}

userOptions: Array<Select2OptionData>;;
getUserTypes() {
  this.websettings.getWebSettingsByIdentifier("VIPS_USER_TYPES").subscribe((result) => {
    this.userOptions = result.data.map((status) => {
      return {
        id: status.parameter,
        text: status.value,
      };
    });
  });
}

initFilterUserFormGroup() {
  this.filterUserFormGroup = new FormGroup({
    searchString: new FormControl(null),
    userType: new FormControl(this.emptyString)
  });

  this.filterUserFormGroup.controls.searchString.valueChanges.subscribe(e =>{
    if(e){
      this.users = this.userAccumulator.filter(x => x.FullName.toUpperCase().includes(e.toUpperCase()) || x.MobileNo.includes(e));
    } else {
      this.users = this.userAccumulator;
    }
  });

  this.filterUserFormGroup.controls.userType.valueChanges.subscribe((e) => {
    this.selectedUserType(e);
  })
}

selectedUserType(e) {
  switch(e){
    case "User":
      this.users = this.userAccumulator
        .filter(x => x.Usertype == "LEVEL_0" || x.Usertype == "LEVEL_1" || x.Usertype == "LEVEL_2" || x.Usertype == "LEVEL_3" || x.Usertype == "LEVEL_4" || x.Usertype == "LEVEL_5")
      break;
    case "Merchant":
      this.users = this.userAccumulator.filter(x => x.Usertype == "LEVEL_6" || x.Usertype == "LEVEL_7")
      break;
    case "Rider":
      this.users = this.userAccumulator.filter(x => x.Usertype == "LEVEL_8" || x.Usertype == "LEVEL_9")
      break;
    default: 
      this.users = this.userAccumulator;
  }
}

clearValue(){
  this.filterUserFormGroup.controls.searchString.patchValue('');
  this.users = this.userAccumulator;
}

getUserType(userType){
  switch (userType) {
    case "LEVEL_0":
    case "LEVEL_1":
    case "LEVEL_2":
    case "LEVEL_3":
    case "LEVEL_4":
    case "LEVEL_5":
      return "User";
    case "LEVEL_6":
    case "LEVEL_7":
      return "Merchant";
    case "LEVEL_8":
    case "LEVEL_9":
      return "Rider";
  
    default:
      break;
  }

}

  getWallet(): void {
    this.walletService.getWallet().subscribe((result) => {
      this.wallet = result.wallet;
    });
  }

  wallet: any;
  user: any;
  getManagerUser(id: any): void {
    this.usersService.getManagerUser(id).subscribe((result) => {
      this.user = result.data;
    });
  }


  searchData(){

  }

  initSearchFormGroup(): void {
    this.searchByFormGroup = new FormGroup({
      showRows: new FormControl(null),
      dateRange: new FormControl(this.emptyString),
      searchString: new FormControl(null),
    });
  }

  viewUser(id: any){

  }

  receipt: any;
  initReceipt() {
    this.webSettingsService.getWebSettingsByIdentifier("GoVIPX_QR_TEMPLATE").subscribe((result) => {
      this.receipt = result.data;
    });
  }

  openQR(content: any){
    this.modalServe.open(content, { centered: true, size: "lg" });
  }

  getQR(myQRTemplate: any){
    var qrtemplate = myQRTemplate.innerHTML;

    var Newstr = (this.receipt[0].value)
      .replace("{{QR}}", qrtemplate);
    
    this.PrintReceipt(Newstr);
  }

  PrintReceipt(Newstr: any)
  {
    var mywindow = window.open('', 'PRINT', 'height=700,width=1000');
    mywindow.document.write(Newstr);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    this.modalServe.dismissAll();

    return true;
  }

  initSearchByUsernameFormGroup() {
    this.searchByUsernameFormGroup = new FormGroup({
      status: new FormControl(0),
      username: new FormControl(null),
      dateRange : new FormControl(null),
      requestId : new FormControl(null),
      showRows: new FormControl(null),
      transactFrom: new FormControl(1)
    });
  
    // this.searchByUsernameFormGroup.controls.username.valueChanges.subscribe(e =>{
    //   //this.selectedUser(e);
    //   this.getManagerUser(this.selectedManager);
    //   this.getVipsUsers();
    //   this.getWallet();
    //   this.clearValue();
    // });
  }

  initSearchTransactionFormGroup() {
    this.searchTranscationFormGroup = new FormGroup({
      status: new FormControl(0),
      username: new FormControl(null),
      dateRange : new FormControl(null),
      requestId : new FormControl(null),
      showRows: new FormControl(null),
      searchString: new FormControl(this.emptyString)
    });
  }

  public loader = false;
  p: number = 1;
  public pageRows: any = 10;

  getTransaction(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader = true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }
    //debugger
    this.loader = true;

    //const requestId : string = this.searchTranscationFormGroup.controls.requestId.value;
    const dateRange : string = this.searchTranscationFormGroup.controls.dateRange.value;
    //const statusId : string = this.searchTranscationFormGroup.controls.status.value;
    const searchString : string = this.searchTranscationFormGroup.controls.searchString.value;
    const showRows : string = this.searchTranscationFormGroup.controls.showRows.value;
    const userId : string = this.managerUserId ? this.managerUserId : 0;

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    //const queryString_requestId: string = requestId ? `requestId=${requestId}&` : "";
    //const queryString_statusId: string = statusId ? `statusId=${statusId}&` : "";
    //const queryString_transactFrom: string = transactFrom ? `transactFrom=${transactFrom}&` : "";
    const queryString_userId: string = userId ? `userId=${userId}&` : "";
    const queryString_searchString: string = searchString ? `requestId=${searchString}&` : "";
    const queryString_showRows: string = showRows ? `showRows=${showRows}&` : `showRows=10&`;
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    var queryString = queryString_orderby + queryString_order + queryString_userId + queryString_dateRange + queryString_showRows + queryString_searchString;
    if (fromSearch) {
      this.p = 1;
      queryString =`pageNumber=${1}&pageSize=${10}&${queryString}`;
    }

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/VIPCOTransactions/VipsTransactions?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/VIPCOTransactions/VipsTransactions?${queryString}`;
      }
    }

    this.transactionService.getTransaction(endPointUrl.slice(0, -1)).subscribe((result) => {
      if(result){
        if (result.data.length == 0 && result.pageNumber != 1  && fromSearch == false) {
          this.getTransaction(result.previousPage, _sortBy, _sort);
        } else {
          this.transactions = result;
        }
        this.loader = false;
      }
    },
    (error) => {
      if (error.status === 400) {
        PNotify.notice({
          title: "Warning",
          text: "Username is required.",
        });
      } else {

        PNotify.error({
          title: "Error",
          text: "Server unavailable. PLease try again later.",
        });
      }
      this.loader = false;
    });
  }

  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p=pageNumber;
    return `${environment.API_URL}/api/VIPCOTransactions/VipsTransactions?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  createdDate = "";
  searchString = "";

  private _sortBy: any;
  private _sort: any;

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.createdDate = field == "createdDate" ? "th-inner sortable both " + sortBy : "";
    
    this.getTransaction(
      `${environment.API_URL}/api/VIPCOTransactions/VipsTransactions?pageNumber=${
        page ? page : this.transactions.pageNumber
      }`,
      this._sortBy,
      this._sort
    );
  }
  
}
