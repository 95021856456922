import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { StoreItemService } from 'src/data/services/web/store-item.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { CryptoService } from 'src/data/crypto/crypto.service';
import { Router } from '@angular/router';
import { Data } from 'src/data/data';
import { result } from 'lodash';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.less']
})
export class CartComponent implements OnInit {

  loader: any = false;
  totalAmount: any = 0;
  totalQuantity: any = 0;

  constructor(
    private modalServe: NgbModal,
    private data: Data,
    private webService: WebSettingsService,
    private cryptoService: CryptoService,
    public datepipe: DatePipe,
    private router: Router,
    public storeItemService: StoreItemService,
    private currencyPipe: CurrencyPipe,
    private formBuilder: FormBuilder,
    private localStorageCacheService: LocalStorageCacheService
  ) { }

  ngOnInit(): void {
    this.getCategories();
    this.getCart();
  }

  itemFormGroups: FormGroup[] = [];
  cartItems: any;
  getCart(){
    this.storeItemService.getCart().subscribe((result) => {
      this.cartItems = result;

      for (let i = 0; i < this.cartItems.length; i++) {
        this.quantities.push(this.cartItems[i].quantity);
      }
    });
  }

  checkedItems: any[] = [];
  onCheckboxChange(event, item) {
    if (event.target.checked) {
      this.checkedItems.push(item);
    } else {
      const index = this.checkedItems.indexOf(item);
      if (index > -1) {
        this.checkedItems.splice(index, 1);
      }
    }

    this.totalAmount = this.checkedItems.reduce((acc, curr) => acc + curr.price * curr.quantity, 0);
    this.totalQuantity = this.sum(this.checkedItems.map(x => x.quantity));
  }

  sum(array: number[]): number {
    return array.reduce((a, b) => a + b, 0);
  }

  increment(index) {
    this.quantities[index]++;
    this.cartItems[index].quantity = this.quantities[index];

    if(this.checkedItems.some(x => x.id == this.cartItems[index].id)){
      this.totalAmount = this.checkedItems.reduce((acc, curr) => acc + curr.price * curr.quantity, 0);
      this.totalQuantity = this.sum(this.checkedItems.map(x => x.quantity));
    }
  }

  decrement(index) {
    if (this.quantities[index] > 1) {
      this.quantities[index]--;
      this.cartItems[index].quantity = this.quantities[index];

      if(this.checkedItems.some(x => x.id == this.cartItems[index].id)){
        this.totalAmount = this.checkedItems.reduce((acc, curr) => acc + curr.price * curr.quantity, 0);
        this.totalQuantity = this.sum(this.checkedItems.map(x => x.quantity));
      }
    }
  }

  quantities = [];
  quantityChanged(item){

  }
  
  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  categories: any;
  getCategories(){
    this.storeItemService.getCategories().subscribe((result) => {
      //this.categories = result;
      this.categories = result.map((status) => {
        return {
          id: status.id,
          text: status.category,
        };
      });
    });
  }

  checkOut(){
    if(this.checkedItems.length == 0){
      PNotify.error({
        title: "Invalid",
        text: "No item selected!",
      });

      return;
    }

    var json = this.checkedItems.map((value) => {
      return { ItemID: value.storeItemId, Quantity: value.quantity }
    });

    this.storeItemService.checkOut(json).subscribe((result) => {
      if(result != null){
        this.router.navigate(["manager", "checkout"]);
      }
    });
    
  }

  removeItem(item:any){
    this.storeItemService.removeToCart(item.id).subscribe((result) => {
      if(result){
        PNotify.success({
          title: "Success",
          text: "Item Removed",
        });

        this.getCart();
      } else {
        PNotify.error({
          title: "Invalid",
          text: "something went wrong",
        });
      }
    });
  }

  backToStore(){
    this.router.navigate(['manager','govip-store']);
  }
}
