import { CurrencyPipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { CashierUsersService } from "src/data/services/web/cashier-users.service";
import { RolesService } from "src/data/services/web/roles.service";
import { StoreService } from "src/data/services/web/store.service";
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { UserService } from "src/data/services/web/user.service";
import { StoreItemService } from "src/data/services/web/store-item.service";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-store-item',
  templateUrl: './store-item.component.html',
  styleUrls: ['./store-item.component.less']
})
export class StoreItemComponent implements OnInit {

  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public storeItemService: StoreItemService;
  public userService: UserService;
  public storeService: StoreService;
  public emptyString = "";
  public roles: any;
  public role: any;
  public modalService: BsModalService;

  public accessToken: string;
  public newuser: boolean;
  public user: any;
  public userId: any;
  public roleLevelId: any;

  public maxCashiersArray: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    private currencyPipe: CurrencyPipe,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    storeItemService: StoreItemService,
    storeService: StoreService,
    modalService: BsModalService,
    userService: UserService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.storeItemService = storeItemService;
    this.storeService = storeService;
    this.modalService = modalService;
    this.userService= userService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.route.params.subscribe((params) => {
      const id = decodeURI(params.id || "");
      console.log("id yarn: ", this.cryptoService.get(this.data.authentication.privateKey, id));

      this.rolesService.getRoles(3).then((roles) => {
        this.roles = roles;
        if (id) {
          this.userId = this.cryptoService.get(this.data.authentication.privateKey, id);
        } else {
          this.authService.validateAccessToken(this.roleLevelId).then((res) => {
            const idDefault = this.localStorageCacheService.getStorage("id");
            this.userId = idDefault;
          });
        }
      });
    });
  }
}
