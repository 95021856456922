import { Injectable } from '@angular/core';
import { SalesUserService } from 'src/data/services/web/sales-user.service';
import { TreeDiagramNodesList } from '../classes';
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { Observable, of } from "rxjs";
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';

@Injectable()
export class NodesListService {
  private nodesList: TreeDiagramNodesList;
  
  constructor(
    private httpClient: HttpClient,
    private localStorageCacheService: LocalStorageCacheService
  ) {
  }

  public loadNodes(nodes: any[], config) {
    this.nodesList = new TreeDiagramNodesList(nodes, config,this.httpClient,this.localStorageCacheService);

    return this.nodesList;
  }

  public getNode(guid) {
    return this.nodesList.getNode(guid);
  }

  public newNode() {
    // this.nodesList.newNode();
  }

  public makerNode() {
    return this.nodesList.makerGuid;
  }

  public toJsonString() {
    return JSON.stringify(this.nodesList.serialize());
  }




  
}
