<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">Shopee Settlements</h2>
          <small class="text-highlight font-weight-normal font-italic">JuanPay list of Shopee settlements</small>
        </div>
        <div class="flex"></div>
        <div>
          <!-- <div (click)="exportToExcel()" class="btn btn-sm btn-success mr-2">
                <span class="d-none d-sm-inline mx-1">Export to Excel</span>
                <i data-feather="arrow-right"></i>
              </div> -->
          <div (click)="openTextFileDownload(downloadTextFilemodal)" class="btn btn-sm btn-primary mr-2">
            <span class="d-none d-sm-inline mx-1">Download Textfile</span>
            <i data-feather="arrow-right"></i>
          </div>

          <div (click)="openFileUpload(uploadFilemodal)" class="btn btn-sm btn-success mr-2">
            <span class="d-none d-sm-inline mx-1">Upload File</span>
            <i data-feather="arrow-right"></i>
          </div>
        </div>
        <!-- <div>
              <div *ngIf="isDataNotNull">
                <button class="btn btn-md btn-success w-100" (click)="exportToExcel()">
                  <i class="fa fa-file-excel"></i> &nbsp; Export to Excel
                </button>
              </div>
            </div> -->
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="" style="padding-right: 0px">
        <div class="card mb-2" style="background-color: #182038">
          <div class="row" [formGroup]="searchByFormGroup">
            <table id="datatable" class="table" role="grid" aria-describedby="datatable_info">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th class="search">
                    <button class="btn btn-sm btn-info" style="float: right" (click)="searchData()">
                      Search &nbsp; <i class="fa fa-filter text-highlight"></i>
                    </button>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor: pointer" class="odd" role="row">
                  <td class="td-title">
                    <label class="label-filter">Set No. of Rows</label>
                  </td>
                  <td class="td-value">
                    <ng-select
                      [items]="showRowsOption"
                      placeholder="No of Rows"
                      bindLabel="text"
                      bindValue="id"
                      formControlName="showRows"
                    >
                    </ng-select>
                  </td>

                  <td class="td-title">
                    <label class="label-filter">Store Name</label>
                  </td>
                  <td class="td-value">
                    <input
                      type="text"
                      placeholder="Store Name"
                      class="form-control w-100"
                      formControlName="storeName"
                      style="background-color: rgb(255, 255, 255); color: black"
                    />
                  </td>
                </tr>
                <tr style="cursor: pointer" class="odd" role="row">
                  <td class="td-title">
                    <label class="label-filter">Transaction Status</label>
                  </td>
                  <td class="td-value">
                    <ng-select
                      [items]="statusIdFilterOptions"
                      placeholder="Filter by Status"
                      bindLabel="text"
                      bindValue="id"
                      formControlName="status"
                    >
                    </ng-select>
                  </td>
                  <td class="td-title">
                    <label class="label-filter">Request Id</label>
                  </td>
                  <td class="td-value">
                    <input
                      type="text"
                      placeholder="Request Id"
                      class="form-control w-100"
                      formControlName="requestId"
                      style="background-color: rgb(255, 255, 255); color: black"
                    />
                  </td>
                  <td></td>
                </tr>

                <tr style="cursor: pointer" class="odd" role="row">
                  <td class="td-title">
                    <label class="label-filter">Date Range</label>
                  </td>

                  <td class="td-value">
                    <input
                      type="text"
                      placeholder="Date Range"
                      class="form-control"
                      bsDaterangepicker
                      formControlName="dateRange"
                      style="background-color: white; color: black"
                      [bsConfig]="{
                        rangeInputFormat: 'YYYY-MM-DD',
                        dateInputFormat: 'YYYY-MM-DD',
                        showWeekNumbers: false
                      }"
                    />
                  </td>
                  <td class="td-title">
                    <label class="label-filter">Summary Based on Query</label>
                  </td>

                  <td>
                    <br />
                    <table style="margin-left: 10px; width: 96%">
                      <tr>
                        <th>Total Net Amount Payable</th>
                        <td class="amount-total">{{ totals?.netPayable | number: "1.2-2" }}</td>
                      </tr>
                      <tr>
                        <th>Total Shopee Transactions</th>
                        <td class="amount-total">{{ totals?.transactionCount | number: "1.2-2" }}</td>
                      </tr>
                      <tr>
                        <th>Total Items</th>
                        <td class="amount-total">{{ totals?.totalItems | number: "1.2-2" }}</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card">
          <table
            id="table"
            class="table table-hover"
            data-plugin="bootstrapTable"
            data-toolbar="#toolbar"
            data-search="true"
            data-search-align="left"
            data-show-export="true"
            data-show-columns="true"
            data-detail-view="false"
            data-mobile-responsive="true"
            data-pagination="true"
            data-page-list="[10, 25, 50, 100, ALL]"
          >
            <thead style="background-color: #182038">
              <tr style="background-color: #182038">
                <th style="width: 400px"><span>Store Details</span></th>
                <th><span>Status</span></th>
                <th><span>Settlement Details</span></th>
                <th>&nbsp;</th>
              </tr>
            </thead>

            <tbody>
              <tr
                class="pt-1 pb-1"
                *ngFor="
                  let transaction of transactions?.data
                    | paginate
                      : { itemsPerPage: transactions.pageSize, currentPage: p, totalItems: transactions.totalRecords }
                "
                class="odd"
                role="row"
              >
                <td>
                  <table class="w-100">
                    <tr>
                      <th style="width: 120px">Sequence</th>
                      <td style="text-align: left">{{ transaction.id }}</td>
                    </tr>
                    <tr>
                      <th>Manager Username</th>
                      <td style="text-align: left">
                        {{
                          transaction?.userSystemOrigin == "[OLD] ENTERPRISE"
                            ? transaction?.managerUsername
                            : transaction?.transactBy
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th>Manager Name</th>
                      <td style="text-align: left">
                        {{
                          transaction?.userSystemOrigin == "[OLD] ENTERPRISE"
                            ? transaction?.managerName
                            : transaction?.name
                        }}
                      </td>
                    </tr>
                    <tr *ngIf="transaction?.userSystemOrigin == '[NEW] GOVIPCENTER'">
                      <th>Store Name</th>
                      <td style="text-align: left">{{ transaction?.storeName }}</td>
                    </tr>
                    <tr>
                      <th>[AC]Store Name</th>
                      <td style="text-align: left">{{ transaction?.storeNameFromShopee }}</td>
                    </tr>

                    <tr>
                      <th>Email Address</th>
                      <td style="text-align: left">
                        {{
                          transaction?.userSystemOrigin == "[OLD] ENTERPRISE"
                            ? transaction?.managerEmailAddress
                            : transaction?.email
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th>Contact Number</th>
                      <td style="text-align: left">
                        {{
                          transaction?.userSystemOrigin == "[OLD] ENTERPRISE"
                            ? transaction?.managerContactNumber
                            : transaction?.contactNumber
                        }}
                      </td>
                    </tr>
                  </table>
                </td>

                <td>
                  <table class="w-100">
                    <tr>
                      <th style="width: 150px">System Origin</th>
                      <td style="text-align: left" *ngIf="transaction?.userSystemOrigin == '[NEW] GOVIPCENTER'">
                        <span class="badge badge-success">{{ transaction?.userSystemOrigin }}</span>
                      </td>
                      <td style="text-align: left" *ngIf="transaction?.userSystemOrigin == '[OLD] ENTERPRISE'">
                        <span class="badge badge-primary">{{ transaction?.userSystemOrigin }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Completed By</th>
                      <td style="text-align: left">{{ transaction?.completedBy }}</td>
                    </tr>
                    <tr>
                      <th>Completed Date</th>
                      <td style="text-align: left">{{ transaction?.completedDate | date: "MM/dd/yyyy" }}</td>
                    </tr>
                    <tr>
                      <th>Transaction Status</th>
                      <td style="text-align: left">
                        <app-status-table-data [status]="transaction?.statusId"></app-status-table-data>
                      </td>
                    </tr>
                    <tr>
                      <th>Uploaded Date</th>
                      <td style="text-align: left">{{ transaction?.uploadedDate | date: "MM/dd/yyyy" }}</td>
                    </tr>
                    <tr>
                      <th>Uploaded By</th>
                      <td style="text-align: left">{{ transaction?.uploadedBy }}</td>
                    </tr>
                  </table>
                </td>

                <td>
                  <table class="w-100">
                    <tr *ngIf="transaction?.statusId != 3">
                      <th style="width: 180px">RequestId</th>
                      <td style="text-align: left">{{ transaction?.requestId }}</td>
                    </tr>
                    <tr>
                      <th style="width: 180px">Transactions Date</th>
                      <td style="text-align: left">{{ transaction?.transactionDate | date: "MM/dd/yyyy" }}</td>
                    </tr>

                    <tr>
                      <th style="width: 180px">Transactions Count</th>
                      <td style="text-align: left">{{ transaction?.transactionCount }}</td>
                    </tr>
                    <tr>
                      <th style="width: 180px">Transactions Fee</th>
                      <td style="text-align: left">{{ transaction?.fee | number: "1.2-2" }}</td>
                    </tr>
                    <tr>
                      <th style="width: 180px">Transactions Amount</th>
                      <td style="text-align: left">
                        {{
                          transaction?.netPayable - transaction?.fee * transaction?.transactionCount | number: "1.2-2"
                        }}
                      </td>
                    </tr>

                    <tr>
                      <th>Net Amount Payable</th>
                      <td style="text-align: left; font-size: 20px; font-weight: bolder">
                        {{ transaction?.netPayable | number: "1.2-2" }}
                      </td>
                    </tr>
                    <tr>
                      <th>Settlement Type</th>
                      <td style="text-align: left" *ngIf="transaction?.settlementId == 'VIA_BANK_TRANSFER'">
                        <span class="badge badge-success">Bank Transfer</span>
                      </td>
                      <td style="text-align: left" *ngIf="transaction?.settlementId == 'VIA_V_WALLET'">
                        <span class="badge badge-primary">V-Wallet Transfer</span>
                      </td>
                    </tr>
                    <tr *ngIf="transaction?.settlementId == 'VIA_BANK_TRANSFER'">
                      <th>Bank Name</th>
                      <td style="text-align: left">{{ getSettlementBank(transaction?.bankSettlementName) }}</td>
                    </tr>
                    <tr *ngIf="transaction?.settlementId == 'VIA_BANK_TRANSFER'">
                      <th>Bank Account Number</th>
                      <td style="text-align: left; font-size: 20px">
                        <strong>{{ transaction?.bankAccountNumber }}</strong>
                      </td>
                    </tr>
                    <tr *ngIf="transaction?.settlementId == 'VIA_BANK_TRANSFER'">
                      <th>Bank Account Name</th>
                      <td style="text-align: left">{{ transaction?.bankAccountName }}</td>
                    </tr>
                    <tr *ngIf="transaction?.settlementId == 'VIA_BANK_TRANSFER'">
                      <th>Deposit RefNo.</th>
                      <td style="text-align: left">{{ transaction?.depositReferenceNumber }}</td>
                    </tr>
                    <tr>
                      <th>Remarks</th>
                      <td style="text-align: left">{{ transaction?.remarks }}</td>
                    </tr>
                  </table>
                  <button
                    *ngIf="transaction?.statusId == 3"
                    class="btn btn-sm btn-info"
                    style="float: right"
                    (click)="openEditView(editModal, transaction)"
                  >
                    Edit &nbsp; <i class="fa fa-edit text-highlight"></i>
                  </button>
                </td>

                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
              <div class="col-sm-12 col-md-12">
                <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                  Showing {{ (transactions?.pageNumber - 1) * transactions?.pageSize + 1 }} to
                  {{ (transactions?.pageNumber - 1) * transactions?.pageSize + transactions?.data?.length }} of
                  {{ transactions?.totalRecords }} entries
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-7" style="float: right">
            <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
              <ul class="pagination" style="float: right; margin-right: 36px">
                <pagination-controls (pageChange)="getTransaction(buildPageUrl($event, 10))"></pagination-controls>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #uploadFilemodal let-modal>
      <div class="modal-header">
        <h4 class="modal-title">Upload Files</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div>
          <div class="page-content page-container" id="page-content">
            <div class="padding" *ngIf="fileUploadFormGroup">
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <input type="file" (change)="onFileChange($event)" class="custom-file-input" id="inputFile" />
                  <label class="custom-file-label" for="inputFile">{{ fileName }}</label>
                </div>

                <span *ngIf="fileUploadFormGroup?.controls.reportFile.errors | estatus" class="text-error"
                  ><small>{{ fileUploadFormGroup?.controls.reportFile.errors | econtent }}</small>
                </span>
              </div>

              <div class="col-lg-12">
                <table style="width: 100%">
                  <tr>
                    <th style="text-align: center; color: white; background-color: rgb(79, 79, 79)">
                      Transaction Date
                    </th>
                    <th style="text-align: center; color: white; background-color: rgb(79, 79, 79)">Terminal Name</th>
                    <th style="text-align: center; color: white; background-color: rgb(79, 79, 79)">Terminal ID</th>
                    <th style="text-align: right; color: white; background-color: rgb(79, 79, 79)">Count</th>
                    <th style="text-align: right; color: white; background-color: rgb(79, 79, 79)">Fee</th>
                    <th style="text-align: right; color: white; background-color: rgb(79, 79, 79)">Amount</th>
                    <th style="text-align: right; color: white; background-color: rgb(79, 79, 79)">Net Payable</th>
                  </tr>
                  <tr *ngFor="let item of listItems">
                    <td style="text-align: center">{{ item.transactionDate }}</td>
                    <td style="text-align: center">{{ item.storeName }}</td>
                    <td style="text-align: center">{{ item.terminalId }}</td>
                    <td style="text-align: right">{{ item.transactionCountFirst | number: "1.2-2" }}</td>
                    <td style="text-align: right">{{ item.fee | number: "1.2-2" }}</td>
                    <td style="text-align: right">
                      {{ item.netPayable - item.fee * item.transactionCountFirst | number: "1.2-2" }}
                    </td>
                    <td style="text-align: right">{{ item.netPayable | number: "1.2-2" }}</td>
                  </tr>

                  <tr>
                    <td style="text-align: center"></td>
                    <td style="text-align: center"></td>
                    <td style="text-align: center"></td>
                    <td style="text-align: right"></td>
                    <td style="text-align: right"></td>
                    <td style="text-align: right"></td>
                    <td style="text-align: right"></td>
                  </tr>
                  <tr>
                    <td style="text-align: right"></td>
                    <td style="text-align: right"></td>
                    <td style="text-align: right"></td>
                    <td style="text-align: right"></td>
                    <td style="text-align: right"></td>
                    <td style="text-align: right"><strong>Settlement Count :</strong></td>
                    <td style="text-align: right">
                      <strong>{{ listItems.length }}</strong>
                    </td>
                  </tr>
                </table>
              </div>

              <button class="btn btn-sm btn-primary mt-2 float-right" (click)="submitSettlement()">
                <i class="fa fa-upload"></i> &nbsp; Upload Shopee Settlements
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template #editModal let-modal>
      <div class="modal-header">
        <h4 class="modal-title">Shopee Settlement</h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <app-shopee-settlement-edit
          (closeModal)="closeModal()"
          [transaction]="transaction"
        ></app-shopee-settlement-edit>
      </div>
    </ng-template>

    <ng-template #downloadTextFilemodal let-modal>
      <div class="modal-header">
        <h4 class="modal-title">Download Textfile</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div>
          <div class="page-content page-container" id="page-content">
            <div class="padding" [formGroup]="textFileDownloadFormGroup">
              <label style="color: black">Date Range</label>
              <input
                type="text"
                placeholder="Date Range"
                class="form-control"
                bsDaterangepicker
                formControlName="dateTime"
                style="background-color: white; color: black"
                [bsConfig]="{
                  rangeInputFormat: 'YYYY-MM-DD',
                  dateInputFormat: 'YYYY-MM-DD',
                  showWeekNumbers: false
                }"
              />

              <button class="btn btn-sm btn-primary mt-2" (click)="downloadTextFileBankSettlement('UnionBank')">
                <i class="fa fa-download"></i> &nbsp; UNIONBANK TXT
              </button>
              <button class="btn btn-sm btn-success mt-2" (click)="downloadTextFileBankSettlement('Instapay')">
                <i class="fa fa-download"></i> &nbsp; INSTAPAY TXT
              </button>
              <button class="btn btn-sm btn-warning mt-2" (click)="downloadTextFileBankSettlement('Pesonet')">
                <i class="fa fa-download"></i> &nbsp; PESONET TXT
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
