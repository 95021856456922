import { CurrencyPipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Select2OptionData } from "ng-select2";
import { BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { ExportExcelService } from "src/data/services/web/export-excel.service";
import { ManagerUsersService } from "src/data/services/web/manager-users.service";
import { RolesService } from "src/data/services/web/roles.service";
import { StoreItemService } from "src/data/services/web/store-item.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'app-govip-store',
  templateUrl: './govip-store.component.html',
  styleUrls: ['./govip-store.component.less']
})
export class GovipStoreComponent implements OnInit {
  public managerUserFilterFormGroup: FormGroup;
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public storeItemService: StoreItemService;
  public emptyString = "";
  public roles: any;
  public role: any;
  public statuses: any;
  public status: any;
  public modalService: BsModalService;

  public accessToken: string;
  public newManagerUser: boolean;
  public stockItem: any;
  public stockItemExcel: any = [];
  public pageCount: any;
  public roleId: any;
  dataForExcel = [];
  // Filters
  public listFilterForm = new FormGroup({
    searchString: new FormControl(""),
    searchStringField: new FormControl(""),
  });
  public filterByOptions: Array<Select2OptionData>;
  public filterBy: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    private currencyPipe: CurrencyPipe,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    storeItemService: StoreItemService,
    modalService: BsModalService,
    private modService: NgbModal,
    public ete: ExportExcelService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.storeItemService = storeItemService;
    this.modalService = modalService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
  }

  ngOnInit(): void {
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    // this.rolesService.getRoles(2).then((roles) => {
    //   this.roles = roles;
    //   this.getStockItem();
    // });
    this.initFormGroup();
    this.getStockItem();
    this.getCategories();
  }

  private _sortBy: any;
  private _sort: any;
  searchText: string = '';
  filteredItems: any = [];

  stockItemCreateFormGroup: FormGroup;
  initFormGroup(){
    this.stockItemCreateFormGroup = new FormGroup({
      categoryId: new FormControl(null, [Validators.required])
    });
  }

  filterItems() {
    if(this.searchText != ''){
      this.filteredItems = this.stockItem.data.filter(item => item.name.toLowerCase().includes(this.searchText.toLowerCase()));
    } else {
      this.filteredItems = this.stockItem.data;
    }
  }

  categories: any;
  getCategories(){
    this.storeItemService.getCategories().subscribe((result) => {
      //this.categories = result;
      this.categories = result.map((status) => {
        return {
          id: status.id,
          text: status.category,
        };
      });
    });
  }

  getStockItem(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    if (fromSearch) {
      this.managerId = this.emptyString;
      this.username = this.emptyString;
      this.email = this.emptyString;
      this.firstName = this.emptyString;
      this.lastName = this.emptyString;
      this.roleName = this.emptyString;
    }

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_searchString: string = this.listFilterForm.get("searchString").value
      ? `searchString=${this.listFilterForm.get("searchString").value}&`
      : "";
    const queryString_filterBy: string = this.filterBy ? `filterBy=${this.filterBy}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_searchString + queryString_filterBy;

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/StoreItem/StoreItems?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/StoreItem/StoreItems${queryString}`;
      }
    }

    this.storeItemService.getStoreItems(endPointUrl).subscribe((result) => {
      if (result.data.length == 0 && result.pageNumber != 1) {
        this.getStockItem(result.previousPage, _sortBy, _sort);
      } else {
        this.stockItem = result;
        this.filteredItems = result.data;
      }
    });
  }

  loader: any = false;
  getStockItemForExcel(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    // this.loader = true;
    // if (fromSearch) {
    //   this.managerId = this.emptyString;
    //   this.username = this.emptyString;
    //   this.email = this.emptyString;
    //   this.firstName = this.emptyString;
    //   this.lastName = this.emptyString;
    //   this.roleName = this.emptyString;
    // }

    // const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    // const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    // const queryString_searchString: string = this.listFilterForm.get("searchString").value
    //   ? `searchString=${this.listFilterForm.get("searchString").value}&`
    //   : "";
    // const queryString_filterBy: string = this.filterBy ? `filterBy=${this.filterBy}&` : "";
    // const queryString = queryString_orderby + queryString_order + queryString_searchString + queryString_filterBy;

    // let endPointUrl: string;
    // if (url) {
    //   endPointUrl = `${url}&${queryString}`;
    // } else {
    //   if (queryString) {
    //     endPointUrl = `${environment.API_URL}/api/StoreItem/StoreItems?${queryString}`;
    //   } else {
    //     endPointUrl = `${environment.API_URL}/api/StoreItem/StoreItems${queryString}`;
    //   }
    // }

    // this.storeItemService.getStoreItems(endPointUrl).subscribe((result) => {
    //   if (result.data.length == 0 && result.pageNumber != 1) {
    //     this.getStockItemForExcel(result.previousPage, _sortBy, _sort);
    //   } else {
    //     //debugger
    //     this.generateExcel(result);
    //   }
    // });
  }

  generateExcel(user: any): void {
    let usersExcel = [];
    
      for(var i = 0; i < user.totalRecords; i++){
        let meta = [
          {
              "Username": user.data[i].username,
              "Owner's Full Name": user.data[i].fullname,
              "Store Name": user.data[i].storeName,
              "Current Balance": user.data[i].balance,
              "Allocated Fund":	user.data[i].totalCashierBalance,
              "Total Running Balance":	user.data[i].totalRunningBalance,
              "No of Cashier": user.data[i].cashierList.length
          }
        ]

        usersExcel.push(meta);
      }

      debugger
    for(var x = 0; x < usersExcel.length; x++){
      this.dataForExcel.push(Object.values(usersExcel[x][0]));
    }

    let reportData = {
      title: 'Manager Running Wallet',
      data: this.dataForExcel,
      headers: Object.keys(usersExcel[0][0])
    }

    this.loader = false;
    this.ete.exportExcel(reportData, "Manager Running Wallet");
  }

  managerId = "";
  username = "";
  email = "";
  firstName = "";
  lastName = "";
  roleName = "";
  searchString = "";

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.managerId = field == "managerId" ? "th-inner sortable both " + sortBy : "";
    this.username = field == "username" ? "th-inner sortable both " + sortBy : "";
    this.email = field == "email" ? "th-inner sortable both " + sortBy : "";
    this.firstName = field == "firstName" ? "th-inner sortable both " + sortBy : "";
    this.lastName = field == "lastName" ? "th-inner sortable both " + sortBy : "";
    this.roleName = field == "roleName" ? "th-inner sortable both " + sortBy : "";
   
    this.getStockItem(
      `${environment.API_URL}/api/StoreItem/StoreItems?pageNumber=${page ? page : this.stockItem.pageNumber}`,
      this._sortBy,
      this._sort
    );
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.stockItem?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }

  // tslint:disable-next-line: variable-name
  getRole(role_id: any): string {
    if (role_id) {
      return this.roles.filter((x) => x.id === role_id)[0].name;
    } else {
      return "No Role";
    }
  }
p:Number =1;
  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p = pageNumber;
    return `${environment.API_URL}/api/StoreItem/StoreItems?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  buildUserPhoto(user: any): any {
    return user.managerUser.photoFormat + "," + user.managerUser.photo;
  }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }



  exportManagerData(){

  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  closeModal(){
    this.modService.dismissAll();
  }

  modalItemRef: NgbModalRef;
  selectedItem: any;
  selectItem(walletModal: any, user: any){
    this.selectedItem = user;
    this.modalItemRef = this.modService.open(walletModal, {
      centered: true,
      size: "xl"
    });
  }

  // createModal: NgbModalRef;
  // createStockItem(content: any){
  //   this.createModal = this.modService.open(content, {
  //     centered: true,
  //     size: "xl",
  //     backdrop: "static",
  //     keyboard: false,
  //   });
  // }

  goToCart(){
    this.router.navigate(['manager/my-cart']);
  }
}
