import { AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { FileUploadService } from 'src/data/services/web/file-upload.service';
import { ManagerUsersService } from 'src/data/services/web/manager-users.service';
import { WalletService } from 'src/data/services/web/wallet.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { FormControl, FormGroup } from '@angular/forms';
import { stat } from 'fs';
import { switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { HtmlParserService } from 'src/data/services/tool/html-parser.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-vips-community',
  templateUrl: './vips-community.component.html',
  styleUrls: ['./vips-community.component.less']
})
export class VipsCommunityComponent implements OnInit {

  loader: boolean = false;
  userId: any;
  username: any;

  messagePostContext: any;
  imagesPostContext: any;
  videoPostContext: any;

  config: AngularEditorConfig = {
    editable: true,
    height: 'auto',
    minHeight: '100',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    showToolbar: false,
    enableToolbar: false,
    placeholder: "Your thoughts, ideas, or announcements."
  };

  constructor(private localStorageCacheService: LocalStorageCacheService,
    private modalServe: NgbModal,
    private htmlParserService: HtmlParserService,
    private usersService: ManagerUsersService,
    private uploadService: FileUploadService,
    private walletService: WalletService,
    public webSettingsService: WebSettingsService,
    private sanitizer: DomSanitizer,
    private store: AngularFirestore) { 
   
    this.localStorageCacheService = localStorageCacheService;
  }

  ngOnInit(): void {
    this.userId = this.localStorageCacheService.getStorage("id");
    this.username = this.localStorageCacheService.getStorage("username");
    this.getManagerUser(this.userId);
    this.fetchCustomVipsData();
    //this.getCommunity();
    this.getApprovedPosts();
    this.getPendingPosts();
    this.getRejectedPosts();
    this.initPostFormGroup();
    this.initImagePostFormGroup();
    this.initMessagePostFormGroup();

    this.messagePostContext = { $implicit: null };
    this.imagesPostContext = { $implicit: null };
    this.videoPostContext = { $implicit: null };
  }

  postFormGroup: FormGroup;
  initPostFormGroup(){
    this.postFormGroup = new FormGroup({
      contentType : new FormControl,
      customAppName : new FormControl,
      logo : new FormControl,
      message : new FormControl,
      status : new FormControl,
      timestamp : new FormControl,
      title : new FormControl,
      thumbnailUrl : new FormControl,
      videoUrl : new FormControl,
      username: new FormControl
    });

    this.postFormGroup.controls.title.valueChanges.subscribe((val) => {
      this.isValid = this.checkIfValid();
    });

    this.postFormGroup.controls.message.valueChanges.subscribe((val) => {
      this.isValid = this.checkIfValid();
    });
  }

  messagePostFormGroup: FormGroup;
  initMessagePostFormGroup(){
    this.messagePostFormGroup = new FormGroup({
      contentType : new FormControl,
      customAppName : new FormControl,
      logo : new FormControl,
      message : new FormControl,
      status : new FormControl,
      timestamp : new FormControl,
      title : new FormControl,
      thumbnailUrl : new FormControl,
      videoUrl : new FormControl,
      username : new FormControl
    });
  }

  imageFormGroup: FormGroup;
  initImagePostFormGroup(){
    this.imageFormGroup = new FormGroup({
      url: new FormControl,
      thumbnailUrl : new FormControl,
      timestamp : new FormControl,
      duration : new FormControl,
      viewCount : new FormControl
    });
  }

  scrollHandler(e){
    if(this.activeTabIndex == 0){
      //if (e === 'bottom'){
      //   this.page.more()
      // }
      console.log("approved", e);
    } else if(this.activeTabIndex == 0){
      console.log("pending", e);
    } else {
      console.log("rejected", e);
    }
  }

  activeTabIndex: number = 0;

  @ViewChild('textareaRef') textareaRef!: ElementRef<HTMLTextAreaElement>;
  @ViewChild('textareaImageRef') textareaImageRef!: ElementRef<HTMLTextAreaElement>;
  @ViewChild('textareaVideoRef') textareaVideoRef!: ElementRef<HTMLTextAreaElement>;
  @ViewChild('textareaVideoEditRef') textareaVideoEditRef!: ElementRef<HTMLTextAreaElement>;

  resizeTextarea(textarea: HTMLTextAreaElement) {
    textarea.style.height = 'auto'; // Reset the height to auto
    textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to the scrollHeight
  }

  user: any;
  getManagerUser(id: any): void {
    this.usersService.getManagerUser(id).subscribe((result) => {
      this.user = result.data;
    });
  }

  community: any;
  posts: any = [];
  postsPending: any = [];
  postsRejected: any = [];
  // async getCommunity(){
  //   try {
  //     this.loader = true;
  //     this.posts = [];
  //     this.postsPending = [];
  //     this.postsRejected = [];

  //     const snapshot = await this.store.collection("CustomVipsCommunity").doc(this.userId).get().toPromise();
      
  //     if (snapshot.exists) {
  //       // Document exists, you can access the data using snapshot.data()
  //       this.community = snapshot.data();
        
  //       // Fetch data from the "Banners" subcollection
  //       const bannersSnapshot = await this.store.collection("CustomVipsCommunity").doc(this.userId).collection("Posts").get().toPromise();
        
  //       this.community = snapshot.data();
  //       // Initialize an array to store the images

        
  //       for (const doc of bannersSnapshot.docs) {
  //         const imagesSnapshot = await this.store.collection("CustomVipsCommunity").doc(this.userId).collection("Posts").doc(doc.id).collection("images").get().toPromise();
  //         const images = [];
  //         var imgCount = 0;

  //         imagesSnapshot.forEach((imageDoc) => {
  //             images.push(imageDoc.data());
  //             imgCount++;
  //         });

  //         if(doc.data().status == "pending"){
  //           this.postsPending.push({ id: doc.id, data: doc.data(), images: images, count: imgCount });
  //         } else if(doc.data().status == "rejected"){
  //           this.postsRejected.push({ id: doc.id, data: doc.data(), images: images, count: imgCount });
  //         } else {
  //           this.posts.push({ id: doc.id, data: doc.data(), images: images, count: imgCount });
  //         }
  //       }

  //       this.posts.sort((a, b) => b.data.timestamp - a.data.timestamp);
  //       this.postsPending.sort((a, b) => b.data.timestamp - a.data.timestamp);
  //       this.postsRejected.sort((a, b) => b.data.timestamp - a.data.timestamp);
        
  //       // console.log("community: ", this.community);
  //       // console.log("posts: ", this.posts);
  //       this.loader = false;
  //     } else {
  //       // Document does not exist
  //       this.loader = false;
  //       console.log('Document does not exist');
  //     }
  //   } catch (error) {
  //     this.loader = false;
  //     console.error('Error fetching document:', error);
  //     PNotify.error({
  //       title: "Error fetching document",
  //       text: "Please reload",
  //     });
  //   }
  // }

  async getApprovedPosts() {
    try {
      this.loader = true;
      this.posts = []; // Clear the existing posts array
  
      // Fetch approved posts
      await this.fetchPostsByStatus("approved", this.posts, 50);
      this.posts.sort((a, b) => b.data.approvalTimestamp - a.data.approvalTimestamp);
  
      this.loader = false;
    } catch (error) {
      this.loader = false;
      console.error('Error fetching approved posts:', error);
      PNotify.error({
        title: "Error fetching approved posts",
        text: "Please try again later",
      });
    }
  }
  
  async getPendingPosts() {
    try {
      this.loader = true;
      this.postsPending = []; // Clear the existing pending posts array
  
      // Fetch pending posts
      await this.fetchPostsByStatus("pending", this.postsPending, 30);
      this.postsPending.sort((a, b) => b.data.timestamp - a.data.timestamp);
      console.log(this.postsPending);
  
      this.loader = false;
    } catch (error) {
      this.loader = false;
      console.error('Error fetching pending posts:', error);
      PNotify.error({
        title: "Error fetching pending posts",
        text: "Please try again later",
      });
    }
  }
  
  async getRejectedPosts() {
    try {
      this.loader = true;
      this.postsRejected = []; // Clear the existing rejected posts array
  
      // Fetch rejected posts
      await this.fetchPostsByStatus("rejected", this.postsRejected, 30);
      this.postsRejected.sort((a, b) => b.data.timestamp - a.data.timestamp);

      this.loader = false;
    } catch (error) {
      this.loader = false;
      console.error('Error fetching rejected posts:', error);
      PNotify.error({
        title: "Error fetching rejected posts",
        text: "Please try again later",
      });
    }
  }
  

  async fetchPostsByStatus(status: string, targetArray: any[], limit: number) {
    const postsSnapshot = await this.store.collection("CustomVipsCommunity").doc(this.userId).collection("Posts")
      .ref.where("status", "==", status)
      .limit(limit) // Apply limit here
      .get();
  
    const promises = [];
    postsSnapshot.forEach(postDoc => {
      const postId = postDoc.id;
      const postData = postDoc.data();
  
      const imagesRef = this.store.collection("CustomVipsCommunity").doc(this.userId)
        .collection("Posts").doc(postId).collection("images");
  
      const promise = imagesRef.get().toPromise()
        .then(imagesSnapshot => {
          const images = [];
          let imgCount = 0;
          imagesSnapshot.forEach(imageDoc => {
            const imageData = imageDoc.data();
            images.push(imageData);
            imgCount++;
          });
  
          targetArray.push({ id: postId, data: postData, images: images, count: imgCount });
        });
      promises.push(promise);
    });
  
    await Promise.all(promises);
    targetArray.sort((a, b) => b.data.timestamp - a.data.timestamp); // Sort posts by timestamp
  }
  

  computeTimeAgo(timestamp: number): string {
    const now = new Date();
    const pastTime = new Date(timestamp);

    const timeDiff = Math.abs(now.getTime() - pastTime.getTime());
    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
        return years === 1 ? "a year ago" : `${years} years ago`;
    } else if (months > 0) {
        return months === 1 ? "a month ago" : `${months} months ago`;
    } else if (days > 0) {
        return days === 1 ? "Yesterday" : `${days} days ago`;
    } else if (hours > 0) {
        return hours === 1 ? "an hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
        return minutes === 1 ? "a minute ago" : `${minutes} minutes ago`;
    } else {
        return "just now";
    }
  }

  customVips:any;
  images: any = [];
  async fetchCustomVipsData() {
    try {
      this.loader = true;
      const snapshot = await this.store.collection("CustomVipsApplication").doc(this.userId).get().toPromise();
      
      if (snapshot.exists) {
        // Document exists, you can access the data using snapshot.data()
        this.customVips = snapshot.data();
        
        // Fetch data from the "Banners" subcollection
        const bannersSnapshot = await this.store.collection("CustomVipsApplication").doc(this.userId).collection("Banners").get().toPromise();
        
        // Initialize an array to store the images
        this.images = [];
        var i = 0;

        bannersSnapshot.forEach((doc) => {
          // Push each banner data into the images array
          const bannerUrl = doc.data().bannerUrl;
          const index = doc.id; // Use document ID as index
          this.images.push({ path: bannerUrl, valid: true });
        });
        
        // console.log("custom: ", this.customVips);
        // console.log("images: ", this.images);
        this.loader = false;
      } else {
        // Document does not exist
        this.loader = false;
        console.log('Document does not exist');
        this.customVips =
          {
            customImageUrl: "../../../assets/vips2/1.png",
            customAppName: "Vips User",
            customImageLandscapeUrl: "../../../assets/vips2/1.png"
          };

          this.images = [
            {path:"https://firebasestorage.googleapis.com/v0/b/vipcomegawallet.appspot.com/o/vipsAppMainAssets%2FvipsAppCarousel%2F1.png?alt=media&token=7383a863-6d56-4890-974d-f0cfd41bbaf1"},
            {path:"https://firebasestorage.googleapis.com/v0/b/vipcomegawallet.appspot.com/o/vipsAppMainAssets%2FvipsAppCarousel%2F2.png?alt=media&token=114405a8-3769-4735-8c5f-93a173dbfbe9"},
            {path:"https://firebasestorage.googleapis.com/v0/b/vipcomegawallet.appspot.com/o/vipsAppMainAssets%2FvipsAppCarousel%2F3.png?alt=media&token=427f9b73-d926-42ca-bc2f-6ee5cd824496"},
            {path:"https://firebasestorage.googleapis.com/v0/b/vipcomegawallet.appspot.com/o/vipsAppMainAssets%2FvipsAppCarousel%2F4.png?alt=media&token=9f6c134c-31e6-4163-9dac-6737c0079fd6"}
          ];
      }
    } catch (error) {
      this.loader = false;
      console.error('Error fetching document:', error);
      PNotify.error({
        title: "Error fetching document",
        text: "Please reload",
      });
    }
  } 

  imageViewerRef: NgbModalRef;
  selectedPost: any = [];
  openImageViewer(content: any, post: any, index: boolean){
    this.selectedPost = post;
    this.currentImageIndex = index;
    this.imageViewerRef = this.modalServe.open(content, { centered: true, size: "xl" });
  }

  videoViewerRef: NgbModalRef;
  selectedVideoPost: any;
  openVideoViewer(content: any, post: any){
    this.selectedVideoPost = post;
    this.videoViewerRef = this.modalServe.open(content, { centered: true, size: "xl" });
  }

  currentImageIndex: any = 0;

  nextImage() {
    if (this.currentImageIndex < this.selectedPost.images.length - 1) {
      this.currentImageIndex++;
    } else {
      this.currentImageIndex = 0;
      
    }
  }

  prevImage() {
    if (this.currentImageIndex > 0) {
      this.currentImageIndex--;
    } else {
      this.currentImageIndex = this.selectedPost.images.length - 1;
    }
  }
  


  sanitizeInput(htmlInput: string): string {
    // Replace single newline characters with <br> tags
    const withBrTags = htmlInput.replace(/\n/g, '<br>');
    const withAddBr = this.insertBrAfterOpeningPTags(withBrTags);
  
    // Remove any other tags except for entities
    const sanitized = withAddBr.replace(/<(?!br\s*\/?)[^>]+>|&[^;]+;/g, '');
  
    console.log("with placeholder: ", withBrTags);
    console.log("sanitized: ", sanitized);
    return sanitized;
  }
  
  insertBrAfterOpeningPTags(input: string): string {
    // Define the pattern to search for <p> tags
    const pattern = /<p[^>]*>(?!<br>)/g;
  
    // Replace occurrences of <p> tags with <p> tags followed by <br>
    return input.replace(pattern, (match) => {
      // Insert <br> after the opening <p> tag
      return match + '<br>';
    });
  }
  
  getTextWithNewlines(element): string {
    const textarea = element.nativeElement;
    const text = textarea.value;
    return text;
  }

  convertToNewline(text: string): string {
    // Replace all occurrences of "\r\n" or "\r" with "\n"
    text = text.replace(/\r\n/g, '\n').replace(/\r/g, '\n');
    // Replace all remaining "\n" with "\\n"
    text = text.replace(/\n/g, '\\n');
    return text;
  }

  isValid: boolean = false;
  async processImagesPost(element) {
    const sanitizedMessage = this.postFormGroup.controls.message.value;
    const convertedMessage = this.postFormGroup.controls.message.value ? this.convertToNewline(sanitizedMessage) : "";

    this.loader = true;
    let timestamp = Date.now();
    let customAppName = this.customVips?.customAppName;
    let logo = this.customVips?.customImageUrl;
    let status = "pending";
    let contentType = this.getContentType();
    let message = convertedMessage;

    if (this.checkIfValid()) {
      this.store.collection("CustomVipsCommunity")
        .doc(this.userId).set({
            timestamp: Date.now()
          }, {
          merge: true,
      });
      
      this.postFormGroup.patchValue({
          approvalTimestamp: "",
          timestamp: timestamp,
          customAppName: customAppName,
          logo: logo,
          title: this.postFormGroup.controls.title.value,
          message: message,
          status: status,
          contentType: contentType,
          username: this.username
      });

      try {
        const postRef = await this.store
              .collection("CustomVipsCommunity")
              .doc(this.userId)
              .collection("Posts")
              .add(this.postFormGroup.value);

        var index = 0;
        for (const image of this.backgroundImages) {
          const imageUrl = await this.uploadCustomImage(image.url);
          await this.store
            .collection("CustomVipsCommunity")
            .doc(this.userId)
            .collection("Posts")
            .doc(postRef.id)
            .collection("images")
            .doc(index.toString()) // Use index as document ID
            .set({ url: imageUrl });

            if(index == 0){
              this.store.collection("CustomVipsCommunity")
                .doc(this.userId)
                .collection("Posts")
                .doc(postRef.id)
                .set({
                    thumbnailUrl: imageUrl
                  }, {
                  merge: true,
              });
            }
            index++;
        }

        this.postFormGroup.reset();
        this.backgroundImages = [];
        this.currentBGIndex = 0;
        this.getPendingPosts();
        this.resizeTextarea(element);
        this.openCreateImageRef.close();
        this.loader = false;
      } catch (error) {
          console.error("Error adding document: ", error);
          this.loader = false;
      }
    } else {
        console.log("Invalid data");
        this.loader = false;
    }
  }

  async postMessage() {
    // const sanitizedMessage = this.messagePostFormGroup.controls.message.value ? 
    //   this.sanitizeInput(this.postFormGroup.controls.message.value) : 
    //   "";
    // const messageWithLineBreaks = sanitizedMessage.replace(/<br>/g, '\\n');

    const sanitizedMessage = this.messagePostFormGroup.controls.message.value;
    const convertedMessage = this.messagePostFormGroup.controls.message.value ? this.convertToNewline(sanitizedMessage) : "";

    // console.log("messageWithLineBreaks: ", messageWithLineBreaks);
    // alert(messageWithLineBreaks);
    this.loader = true;
    let timestamp = Date.now();
    let customAppName = this.customVips?.customAppName;
    let logo = this.customVips?.customImageUrl;
    let status = "pending";
    let contentType = "post";
    let message = convertedMessage;

    // console.log(this.postFormGroup.value);
    // console.log(this.backgroundImages);

    if (this.checkIfValidPost()) {
      this.store.collection("CustomVipsCommunity")
        .doc(this.userId).set({
            timestamp: Date.now()
          }, {
          merge: true,
      });
      // Update form controls with values
      this.messagePostFormGroup.patchValue({
          approvalTimestamp: "",
          timestamp: timestamp,
          customAppName: customAppName,
          logo: logo,
          title: this.messagePostFormGroup.controls.title.value,
          message: message,
          status: status,
          contentType: contentType,
          username: this.username
      });

      // Save post data to main collection
      try {
        const postRef = await this.store
              .collection("CustomVipsCommunity")
              .doc(this.userId)
              .collection("Posts")
              .add(this.messagePostFormGroup.value);

        this.messagePostFormGroup.reset();
        this.backgroundImages = [];
        this.currentBGIndex = 0;
        this.getPendingPosts();
        this.resizeTextarea(this.textareaRef.nativeElement);
        this.loader = false;
      } catch (error) {
          console.error("Error adding document: ", error);
          this.loader = false;
      }
    } else {
        // Handle invalid data
        console.log("Invalid data");
        this.loader = false;
    }
  }

  async processVideoPost(element) {
    const sanitizedMessage = this.postFormGroup.controls.message.value;
    const convertedMessage = this.postFormGroup.controls.message.value ? this.convertToNewline(sanitizedMessage) : "";

    this.loader = true;
    let timestamp = Date.now();
    let customAppName = this.customVips?.customAppName;
    let logo = this.customVips?.customImageUrl;
    let status = "pending";
    let contentType = this.getVideoContentType();

    if (this.checkVideoPostIfValid()) {
      this.store.collection("CustomVipsCommunity")
        .doc(this.userId).set({
            timestamp: Date.now()
          }, {
          merge: true,
      });
      // Update form controls with values
      const videoUrl = contentType == "video" ? await this.uploadCustomImage(this.videoUrl) : "";
      const thumbnailUrl = contentType == "video" ? this.thumbnailUrl : "" ;

      this.postFormGroup.patchValue({
          approvalTimestamp: "",
          timestamp: timestamp,
          customAppName: customAppName,
          logo: logo,
          title: this.postFormGroup.controls.title.value,
          message: convertedMessage,
          status: status,
          contentType: contentType,
          thumbnailUrl: thumbnailUrl,
          videoUrl: videoUrl,
          username: this.username
      });

      // Save post data to main collection
      try {
        const postRef = await this.store
              .collection("CustomVipsCommunity")
              .doc(this.userId)
              .collection("Posts")
              .add(this.postFormGroup.value);

        this.postFormGroup.reset();
        this.videoUrl = null;
        this.getPendingPosts();
        this.openCreatevideoRef.close();
        this.resizeTextarea(element);
        this.loader = false;
      } catch (error) {
          console.error("Error adding document: ", error);
          this.loader = false;
      }
    } else {
        // Handle invalid data
        console.log("Invalid data");
        this.loader = false;
    }
  }

  async processEditVideoPost(id, element) {
    const sanitizedMessage = this.postFormGroup.controls.message.value;
    const convertedMessage = this.postFormGroup.controls.message.value ? this.convertToNewline(sanitizedMessage) : "";

    this.loader = true;
    let timestamp = Date.now();
    let customAppName = this.customVips?.customAppName;
    let logo = this.customVips?.customImageUrl;
    let status = "pending";
    let contentType = "video";

    // console.log(this.postFormGroup.value);
    // console.log(this.backgroundImages);

    if (this.checkVideoPostIfValid()) {
      this.store.collection("CustomVipsCommunity")
        .doc(this.userId).set({
            timestamp: Date.now()
          }, {
          merge: true,
      });
      debugger
      // Update form controls with values
      const videoUrl = this.isVideoUploaded ? this.videoUrl : await this.uploadCustomImage(this.videoUrl);
      const thumbnailUrl = this.thumbnailUrl;

      this.postFormGroup.patchValue({
          approvalTimestamp: "",
          timestamp: timestamp,
          customAppName: customAppName,
          logo: logo,
          status: status,
          contentType: contentType,
          thumbnailUrl: thumbnailUrl,
          videoUrl: videoUrl,
          username: this.username
      });

      // Save post data to main collection
      try {
        const postRef = await this.store
          .collection("CustomVipsCommunity")
          .doc(this.userId)
          .collection("Posts")
          .doc(id)
          .set({
            contentType: contentType,
            customAppName: customAppName,
            logo: logo,
            title: this.postFormGroup.controls.title.value,
            message: convertedMessage,
            remarks: "",
            status: status,
            timestamp: timestamp,
            thumbnailUrl: thumbnailUrl,
            videoUrl: videoUrl
          }, {
            merge: true
          })


        this.postFormGroup.reset();
        this.videoUrl = null;
        this.getPendingPosts();
        this.getRejectedPosts();
        this.resizeTextarea(element);
        this.modalServe.dismissAll();
        this.loader = false;
      } catch (error) {
          console.error("Error adding document: ", error);
          this.loader = false;
      }
    } else {
        // Handle invalid data
        console.log("Invalid data");
        this.loader = false;
    }
  }

  formattedMessage: string;
  async processEditImagesPost(postsId, element) {
    const sanitizedMessage = this.postFormGroup.controls.message.value;
    const convertedMessage = this.postFormGroup.controls.message.value ? this.convertToNewline(sanitizedMessage) : "";

    this.loader = true;
    let timestamp = Date.now();
    let customAppName = this.customVips?.customAppName;
    let logo = this.customVips?.customImageUrl;
    let status = "pending";
    let contentType = this.getEditContentType();

    // console.log(this.postFormGroup.value);
    // console.log(this.backgroundImages);

    if (this.checkIfValid()) {
      this.store.collection("CustomVipsCommunity")
        .doc(this.userId).set({
            timestamp: Date.now()
          }, {
          merge: true,
      });
      // Update form controls with values
      this.postFormGroup.patchValue({
          timestamp: timestamp,
          customAppName: customAppName,
          logo: logo,
          status: status,
          contentType: contentType
      });

      // Save post data to main collection

      try {
          const postRef = await this.store
            .collection("CustomVipsCommunity")
            .doc(this.userId)
            .collection("Posts")
            .doc(postsId)
            .set({
              contentType: contentType,
              customAppName: customAppName,
              logo: logo,
              title: this.postFormGroup.controls.title.value,
              message: convertedMessage,
              remarks: "",
              status: status,
              timestamp: timestamp,
              username: this.username
            }, {
              merge: true
            })

          // Clear existing images collection
          await this.store
              .collection("CustomVipsCommunity")
              .doc(this.userId)
              .collection("Posts")
              .doc(postsId)
              .collection("images")
              .get()
              .pipe(
                  switchMap(snapshot => {
                      const deleteOps = [];
                      snapshot.forEach(doc => {
                          deleteOps.push(doc.ref.delete());
                      });
                      // Use of operator to emit a value and complete the observable chain
                      return deleteOps.length > 0 ? of(deleteOps) : of(null);
                  }),
                  tap(() => console.log("Images collection cleared")),
              )
              .toPromise();

          // Proceed to add new images
          debugger
          var index = 0;
          for (const image of this.backgroundEditImages) {
            var imageUrl;
              if(image.valid){
                await this.store
                    .collection("CustomVipsCommunity")
                    .doc(this.userId)
                    .collection("Posts")
                    .doc(postsId)
                    .collection("images")
                    .doc(index.toString()) // Use index as document ID
                    .set({ url: image.url });
              } else {
                imageUrl = await this.uploadCustomImage(image.url);
                await this.store
                    .collection("CustomVipsCommunity")
                    .doc(this.userId)
                    .collection("Posts")
                    .doc(postsId)
                    .collection("images")
                    .doc(index.toString()) // Use index as document ID
                    .set({ url: imageUrl });
              }

              if(index == 0){
                this.store.collection("CustomVipsCommunity")
                  .doc(this.userId)
                  .collection("Posts")
                  .doc(postsId)
                  .set({
                      thumbnailUrl: image.valid ? image.url : imageUrl
                    }, {
                    merge: true,
                });
              }
              index++;
          }

          this.getPendingPosts();
          this.getRejectedPosts();
          this.resizeTextarea(element);
          this.openEditImageRef.close();
          this.loader = false;
      } catch (error) {
          console.error("Error adding document: ", error);
          this.loader = false;
      }

    
    } else {
        // Handle invalid data
        console.log("Invalid data");
        this.loader = false;
    }
  }
  
  // async processImagesPost(){
  //   let timestamp = Date.now();
  //   let customAppName = this.customVips?.customAppName;
  //   let logo = this.customVips?.customImageUrl;
  //   let status = "pending";
  //   let contentType = this.getContentType();

  //   console.log(this.postFormGroup.value);
  //   console.log(this.backgroundImages);

  //   if (this.checkIfValid()) {
  //     // Update form controls with values
  //     this.postFormGroup.patchValue({
  //         timestamp: timestamp,
  //         customName: customAppName,
  //         logo: logo,
  //         status: status,
  //         contentType: contentType
  //     });

  //     // Save post data to main collection
  //     this.store
  //         .collection("CustomVipsCommunity")
  //         .doc(this.userId)
  //         .collection("Posts")
  //         .add(this.postFormGroup.value)
  //         .then((docRef) => {
  //             //console.log("Document written with ID: ", docRef.id);
  //             // Iterate over backgroundImages and add them to another collection
              
  //             this.backgroundImages.forEach((image: any) => {
  //               var imageUrl = this.uploadCustomImage(image.url);
  //                 this.store
  //                     .collection("CustomVipsCommunity")
  //                     .doc(this.userId)
  //                     .collection("Posts")
  //                     .doc(docRef.id)
  //                     .collection("images")
  //                     .add({ url: imageUrl });
  //             });
  //         })
  //         .catch((error) => {
  //             console.error("Error adding document: ", error);
  //         });
  //   } else {
  //       // Handle invalid data
  //       console.log("Invalid data");
  //   }
  // }

  getContentType(): string {
    if(this.backgroundImages.length > 0){
      return "images";
    } else {
      return "post";
    }
  }

  getVideoContentType(): string {
    if(this.videoUrl){
      return "video";
    } else {
      return "post";
    }
  }

  getEditContentType(): string {
    if(this.backgroundEditImages.length > 0){
      return "images";
    } else {
      return "post";
    }
  }

  // uploadCustomImage(base64Img: any): string {  
  //   var image = base64Img;
  //   var base64 = image.split(',')[1];
  //   this.uploadService.uploadCustomImage(base64).subscribe((result) => {
  //     return result.response_data;
  //   }, (error) => {
  //     return "";
  //   });
  //   return "";
  // }

  async uploadCustomImage(base64Img: any): Promise<string> {  
    var image = base64Img;
    var base64 = image.split(',')[1];
    debugger
    return new Promise<string>((resolve, reject) => {
        this.uploadService.uploadCustomImage(base64).subscribe(
            (result) => {
                resolve(result.response_data);
            },
            (error) => {
                reject(error);
            }
        );
    });
  }

  async uploadthumbnail(base64Img: any): Promise<string> {  
    var image = base64Img;
    var base64 = image.split(',')[1];
    debugger
    return new Promise<string>((resolve, reject) => {
        this.uploadService.uploadCustomImage(base64).subscribe(
            (result) => {
                resolve(result.response_data);
            },
            (error) => {
                reject(error);
            }
        );
    });
  }

  checkIfValid(): boolean {
    let title = this.postFormGroup.controls.title.value;
    let message = this.postFormGroup.controls.message.value;
    let imgCount = this.backgroundImages.length;

    if (title !== null && title !== "") {
        return true;
    } else if (message !== null && message !== "") {
        return true;
    } else if (imgCount > 0) {
        return true;
    } else {
        return false;
    }
  }

  checkIfValidPost(): boolean {
    let title = this.messagePostFormGroup.controls.title.value;
    let message = this.messagePostFormGroup.controls.message.value;

    if (title !== null && title !== "") {
        return true;
    } else if (message !== null && message !== "") {
        return true;
    } else {
        return false;
    }
  }

  checkVideoPostIfValid(): boolean {
    let title = this.postFormGroup.controls.title.value;
    let message = this.postFormGroup.controls.message.value;

    if (title !== null && title !== "") {
        return true;
    } else if (message !== null && message !== "") {
        return true;
    } else if (this.videoUrl) {
        return true;
    } else {
        return false;
    }
  }

  removeImage(): void {
    if (this.backgroundImages.length > 0) {
        this.backgroundImages.splice(this.currentBGIndex, 1);
        // Update currentBGIndex if necessary
        if (this.currentBGIndex >= this.backgroundImages.length) {
            this.currentBGIndex = this.backgroundImages.length - 1;
        }
        
        if(this.backgroundImages.length == 0){
          this.backgroundImageUrl = null;
          this.currentBGIndex = 0;

          this.isValid = this.checkIfValid();
        }

        //console.log(this.backgroundImages);
    }
  }

  openCreateImageRef: NgbModalRef;
  openCreateImagePost(content: any){
    this.backgroundImages = [];
    this.currentBGIndex = 0;
    this.postFormGroup.reset();
    this.openCreateImageRef = this.modalServe.open(content, { centered: true, size: "md", backdrop: 'static' });
  }

  openCreatevideoRef: NgbModalRef;
  openCreateVideoPost(content: any){
    this.postFormGroup.reset();
    this.openCreatevideoRef = this.modalServe.open(content, { centered: true, size: "md", backdrop: 'static' });
  }

  backgroundImageUrl: string | ArrayBuffer | null = null;
  backgroundImages: any = [];
  currentBGIndex: any = 0;
  handleFileInput(event: any): void {
    console.log("create")
    debugger;
    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.backgroundImageUrl = e.target?.result;
        this.backgroundImages.push({ url: e.target?.result });
        this.currentBGIndex = this.backgroundImages.length - 1;
        this.isValid = this.checkIfValid();

        // Clear the value of the file input field
        event.target.value = '';
      };
      reader.readAsDataURL(file);
    }
  }

  prevBGImage(): void {
    if (this.currentBGIndex > 0) {
      this.currentBGIndex--;
    } else {
      this.currentBGIndex = this.backgroundImages.length - 1;
    }
  }
  
  nextBGImage(): void {
    if (this.currentBGIndex < this.backgroundImages.length - 1) {
      this.currentBGIndex++;
    } else {
      this.currentBGIndex = 0;
    }
  }

  triggerFileInput(content) {
    this.isEdit = false;
    console.log("false");
    content.click();
  }

  isEdit: boolean = false;
  
  triggerEditFileInput(content) {
    this.isEdit = true;
    console.log("true");
    content.click();
  }

  triggerVideoInput(content) {
    content.click();
  }

  imagePostEdit: any;
  openEditImageRef: NgbModalRef;
  editImagePost(content: any, data: any){
    this.backgroundEditImages = [];
    this.currentEditBGIndex = 0;

    this.imagePostEdit = data;
    //this.backgroundEditImages = data.images;
    this.backgroundEditImages = data.images.map(image => ({ ...image, valid: true }));
    this.backgroundEditImageUrl = data.images[0]?.url;
    this.postFormGroup.controls.title.patchValue(data.data?.title);
    this.postFormGroup.controls.message.patchValue(this.transformtextEdit(data.data?.message));

    this.openEditImageRef = this.modalServe.open(content, { centered: true, size: "md", backdrop: 'static' });
  }

  videoPostEdit: any;
  openEditVideoRef: NgbModalRef;
  isVideoUploaded: boolean = false;
  editVideoPost(content: any, data: any){
    this.videoUrl = data.data.videoUrl;
    this.isVideoUploaded = true;
    this.videoPostEdit = data;
    this.thumbnailUrl = data.data.thumbnailUrl;
    this.postFormGroup.controls.title.patchValue(data.data?.title);
    this.postFormGroup.controls.message.patchValue(data.data?.message);
    this.openEditVideoRef = this.modalServe.open(content, { centered: true, size: "md", backdrop: 'static' });
  }

  backgroundEditImageUrl: string | ArrayBuffer | null = null;
  backgroundEditImages: any = [];
  currentEditBGIndex: any = 0;
  handleEditFileInput(event: any): void {
    console.log("edit")

    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.backgroundEditImageUrl = e.target?.result;
        this.backgroundEditImages.push({ url: e.target?.result, valid: false });
        this.currentEditBGIndex = this.backgroundEditImages.length - 1;
        this.isValid = this.checkIfValid();

        // Clear the value of the file input field
        event.target.value = '';
      };
      reader.readAsDataURL(file);
    }
  }

  videoUrl: any = null;
  thumbnailUrl: any = null;
  removeVideo(){
    this.videoUrl = null;
    this.thumbnailUrl = null;
    // if (this.backgroundImages.length > 0) {
    //   this.backgroundImages.splice(this.currentBGIndex, 1);
    //   // Update currentBGIndex if necessary
    //   if (this.currentBGIndex >= this.backgroundImages.length) {
    //       this.currentBGIndex = this.backgroundImages.length - 1;
    //   }
      
    //   if(this.backgroundImages.length == 0){
    //     this.backgroundImageUrl = null;
    //     this.currentBGIndex = 0;

    //     this.isValid = this.checkIfValid();
    //   }
    // }
  }

  isVideoValid: boolean = false;
  // handleVideoFileInput(event: any): void {
  //   console.log("video")
  //   const file = event.target.files && event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       this.videoUrl = e.target?.result;
  //       this.isVideoValid = this.checkVideoPostIfValid();
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // }

  async handleVideoFileInput(event: any) {
    const file = event.target.files && event.target.files[0];
    if (file && file.size <= 25 * 1024 * 1024) { // Check if file size is less than or equal to 25MB
      const reader = new FileReader();
      reader.onload = (e) => {
        this.videoUrl = e.target?.result;
        this.isVideoValid = true;
        this.isVideoUploaded = false;
      };
      reader.readAsDataURL(file);
      
      try {
        // get the frame at 1.5 seconds of the video file
        const cover: Blob = await this.getVideoCover(file, 1.5);
        // print out the result image blob
        console.log(cover);
      
        // Convert Blob to base64
        const base64String = await this.blobToBase64(cover);
        this.thumbnailUrl = await this.uploadthumbnail(base64String);

        // Clear the value of the file input field
        event.target.value = '';
        console.log(this.thumbnailUrl); // Base64 string representation of the blob
      } catch (ex) {
        event.target.value = '';
        console.log("ERROR: ", ex);
      }
    } else {
      event.target.value = '';
      //console.log("File size exceeds the maximum limit of 25MB");
      PNotify.error({
        title: "Invalid",
        text: "File size exceeds the maximum limit of 25MB",
      });
    }
  }

  async handleVideoEditFileInput(event: any) {
    const file = event.target.files && event.target.files[0];
    if (file && file.size <= 25 * 1024 * 1024) { // Check if file size is less than or equal to 25MB
      const reader = new FileReader();
      reader.onload = (e) => {
        this.videoUrl = e.target?.result;
        this.isVideoValid = true;
        this.isVideoUploaded = false;
      };
      reader.readAsDataURL(file);
      
      try {
        // get the frame at 1.5 seconds of the video file
        const cover: Blob = await this.getVideoCover(file, 1.5);
        // print out the result image blob
        console.log(cover);
      
        // Convert Blob to base64
        const base64String = await this.blobToBase64(cover);
        this.thumbnailUrl = await this.uploadthumbnail(base64String);
        console.log(this.thumbnailUrl); // Base64 string representation of the blob
      } catch (ex) {
        console.log("ERROR: ", ex);
      }
    } else {
      PNotify.error({
        title: "Invalid",
        text: "File size exceeds the maximum limit of 25MB",
      });
    }
  }
  
  getVideoCover(file: File, seekTo = 0.0): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const videoPlayer = document.createElement('video');
      videoPlayer.setAttribute('src', URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener('error', (ex) => {
        reject("Error when loading video file: " + ex);
      });
      videoPlayer.addEventListener('loadedmetadata', () => {
        if (videoPlayer.duration < seekTo) {
          reject("Video is too short.");
          return;
        }
        setTimeout(() => {
          videoPlayer.currentTime = seekTo;
        }, 200);
        videoPlayer.addEventListener('seeked', () => {
          const canvas = document.createElement("canvas");
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          canvas.toBlob(
            (blob: Blob | null) => {
              if (blob) {
                resolve(blob);
              } else {
                reject("Failed to create Blob from canvas.");
              }
            },
            "image/jpeg",
            0.75
          );
        });
      });
    });
  }
  
  blobToBase64(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject(new Error('Failed to read the blob as base64'));
        }
      };
      reader.onerror = () => {
        reject(new Error('Failed to read the blob as base64'));
      };
      reader.readAsDataURL(blob);
    });
  }

  removeEditImage(): void {
    if (this.backgroundEditImages.length > 0) {
        this.backgroundEditImages.splice(this.currentEditBGIndex, 1);
        // Update currentEditBGIndex if necessary
        if (this.currentEditBGIndex >= this.backgroundEditImages.length) {
            this.currentEditBGIndex = this.backgroundEditImages.length - 1;
        }
        
        if(this.backgroundEditImages.length == 0){
          this.backgroundEditImageUrl = null;
          this.currentEditBGIndex = 0;

          this.isValid = this.checkIfValid();
        }

        //console.log(this.backgroundImages);
    }
  }

  prevEditImage(): void {
    if (this.currentEditBGIndex > 0) {
      this.currentEditBGIndex--;
    } else {
      this.currentEditBGIndex = this.backgroundEditImages.length - 1;
    }
  }
  
  nextEditImage(): void {
    if (this.currentEditBGIndex < this.backgroundEditImages.length - 1) {
      this.currentEditBGIndex++;
    } else {
      this.currentEditBGIndex = 0;
    }
  }

  confirmDeletePostRef: NgbModalRef;
  selectedToDelete: any;
  openConfirmDeletePost(content: any, postId: any){
    this.selectedToDelete = postId;
    this.confirmDeletePostRef = this.modalServe.open(content, { centered: true, size: "md" });
  }

  async deletePost(post): Promise<void> {
    const postRef: firebase.firestore.DocumentReference = this.store.collection("CustomVipsCommunity")
      .doc(this.userId)
      .collection("Posts")
      .doc(post.id)
      .ref; // Access the underlying DocumentReference
    
    const batch = this.store.firestore.batch();
  
    // Delete the post document
    batch.delete(postRef);
  
    // Delete the subcollections
    await this.deleteSubcollections(postRef, batch);
  
    // Commit the batched delete operation
    await batch.commit().then(() => {
      PNotify.success({
        title: "Success",
        text: "Post deleted successfully",
      });
      this.getApprovedPosts();
      this.getPendingPosts();
      this.getRejectedPosts();
      this.confirmDeletePostRef.close();
    }).catch((error) => {
      PNotify.error({
        title: "Error deleting post",
        text: error,
      });
    });
  }
  
  async deleteSubcollections(postRef, batch): Promise<void> {
    const subcollectionNames = ["images"];
  
    for (const subcollectionName of subcollectionNames) {
      const subcollectionRef = postRef.collection(subcollectionName);
      await this.deleteCollection(subcollectionRef, batch);
    }
  }
  
  async deleteCollection(collectionRef, batch): Promise<void> {
    const querySnapshot = await collectionRef.get();
  
    querySnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });
  }

  getMessage(data: string): string{
    return HtmlParserService.convertHtmlToTextWithLineBreaks(data);
  }

  transformtext(value: string): any {
    const replacedValue = value.replace(/\\n/g, '<br/>');
    return this.sanitizer.bypassSecurityTrustHtml(replacedValue);
  }

  transformtextEdit(value: string): any {
    return value.replace(/\\n/g, '<br/>');
  }

  closeVideoPost(){
    this.resetVideoPost();
    this.openCreatevideoRef.close();
  }

  resetVideoPost(){
    this.postFormGroup.reset();
    this.videoUrl = "";
    this.isVideoValid = false;
    this.isVideoUploaded = false;
  }

  closeImagePost(){
    this.resetImagePost();
    this.openCreateImageRef.close();
  }

  resetImagePost(){
    this.postFormGroup.reset();
    this.backgroundImageUrl = null;
    this.backgroundImages = [];
    this.currentEditBGIndex = 0
  }
}
