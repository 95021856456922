<app-loader *ngIf="!walletFormGroup"></app-loader>
<app-loader *ngIf="!transactions"></app-loader>
<app-loader *ngIf="processCreate"></app-loader>

<div class="container-fluid">
  <div class="page-content page-container mt-3">
    <div class="shadow-lg mb-3 p-4 bg-white rounded">
      <div class="row">
        <div class="col">
          <h5 class="card-title text-uppercase font-weight-bold mb-0" style="color: #0e1b82">Send VIPCO</h5>
          <span class="h5 mb-0">JuanPay wallet transfer module</span>
        </div>
        <div class="col-auto">
          <div class="icon icon-shape text-dark rounded-circle shadow" style="background-color: #9dcb5f">
            <i class="fas fa-toggle-on"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="card p-3 bg-light" *ngIf="walletFormGroup">
      <div class="row pl-3 pr-3" [formGroup]="walletFormGroup">
        <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
          <div class="shadow-lg mb-2 p-3 bg-white rounded">
            <div class="row">
              <div class="col-6">
                <div class="card card-stats mb-4 mb-xl-0">
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <h5 class="card-title text-uppercase text-muted mb-0">VIPCO Balance:</h5>
                        <span class="h2 font-weight-bold mb-0">{{
                          _decimalPipe.transform(vipcoBalance, "1.2-2")
                        }}</span>
                      </div>
                      <div class="col-auto">
                        <div class="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i class="fas fa-coins"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="card card-stats mb-4 mb-xl-0">
                  <div class="card-body">
                    <div class="row">
                      <div class="col">
                        <h5 class="card-title text-uppercase text-muted mb-0">Special Points Balance:</h5>
                        <span class="h2 font-weight-bold mb-0">{{ _decimalPipe.transform(spbalance, "1.2-2") }}</span>
                      </div>
                      <div class="col-auto">
                        <div class="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i class="fas fa-star"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="walletFormGroup">
          <div class="shadow-lg mb-4 bg-white rounded p-3" [formGroup]="walletFormGroup">
            <h5>Complete the form to transfer wallet.</h5>
            <hr />
            <!-- <div class="input-group shadow-lg mb-4">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="">Enter amount: </span>
                                  </div>
                                <input type="text" class="form-control" aria-label="Text input with dropdown button" formControlName="vipco">
                                <div class="input-group-append">
                                    <select formControlName="sel1" >
                                        <option [value]="item1.id" *ngFor="let item1 of walletOption1">{{ item1.name }}</option>
                                    </select>
                                </div>
                            </div> -->

            <div class="row mb-3">
              <label class="col-3 col-form-label d-flex align-items-center" for="sponsor">Send to:</label>
              <div class="col-9">
                <ng-select
                  [items]="salesUser"
                  [parent-form-group]="walletFormGroup"
                  placeholder="Select Username"
                  bindLabel="text"
                  bindValue="id"
                  formControlName="ReceiverUserId"
                  class="custom"
                  [searchable]="true"
                  [clearable]="true"
                  [virtualScroll]="true"
                  (clear)="onRemoveSponsor($event)"
                  (change)="getValues($event)"
                  (open)="onOpen()"
                  (search)="onSearch($event)"
                  (keyup.enter)="onEnter()"
                  [ngModelOptions]="{ standalone: true }"
                >
                </ng-select>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-12">
                <app-textbox
                  [parent-form-group]="walletFormGroup"
                  control-name="amount"
                  [error]="walletFormGroup?.controls.amount.errors | estatus"
                  [error-message]="walletFormGroup?.controls.amount.errors | econtent"
                  read-only="{{ isActivated }}"
                  label="Amount:"
                  placeholder="Enter amount"
                >
                </app-textbox>
                <!-- <input type="text" class="form-control" aria-label="Text input with dropdown button" formControlName="vipco"> -->
              </div>
            </div>

            <div class="d-grid gap-2 d-md-block">
              <button class="btn btn-sm btn-primary w-100" (click)="openConfirmation(confirmSend)">
                Send &nbsp; <i class="fa fa-paper-plane"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <div class="shadow-lg mb-4 bg-white rounded pt-3 pl-3 pr-3 pb-0">
            <h5>Recent Transfer</h5>
            <hr />

            <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
              <thead>
                <tr>
                  <th data-field="Reference">
                    <div>Date</div>
                  </th>
                  <th class="text-center" data-field="itemDescription">
                    <div>Sent to</div>
                  </th>
                  <th class="text-center" data-field="Amount">
                    <div>Amount</div>
                  </th>
                  <th class="text-center" data-field="Before">
                    <div>Balance Before</div>
                  </th>
                  <th class="text-center" data-field="After">
                    <div>Balance After</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let item of transactions?.data
                      | paginate: { itemsPerPage: 10, currentPage: p, totalItems: transactions.totalRecords }
                  "
                  style="cursor: pointer"
                  class="odd"
                  role="row"
                >
                  <td style="width: 20px" class="pt-1 pb-0">
                    <p class="mb-0" style="font-size: 8px">{{ item.createdDate | date: "MMM" }}</p>
                    <p class="mb-0 mt-0 text-highlight font-weight-bold" style="font-size: 10px">
                      {{ item.createdDate | date: "dd" }}
                    </p>
                    <!-- <p class="mt-0 mb-0" style="font-size: 8px">{{ item.createdDate | date: "yyyy" }}</p> -->
                  </td>

                  <td class="text-center">
                    <div class="item-except text-sm h-1x">{{ item?.receiverUsername }}</div>
                  </td>

                  <td>
                    <div class="text-center">
                      <strong *ngIf="statusOfAmount(item?.amount)" style="color: green"
                        >{{ makeMoney(item?.amount) }}
                      </strong>
                      <strong *ngIf="!statusOfAmount(item?.amount)" style="color: red"
                        >{{ makeMoney(item?.amount) }}
                      </strong>
                    </div>
                  </td>

                  <td>
                    <div class="text-center">
                      <strong>{{ makeMoney(item?.originOpeningBalance) }}</strong>
                    </div>
                  </td>

                  <td>
                    <div class="text-center">
                      <strong>{{ makeMoney(item?.originOpeningBalance - item?.amount) }}</strong>
                    </div>
                  </td>

                  <!-- <td class="flex" style="max-width: 75px">
                                          <app-status-table-data [status]="item?.statusId"></app-status-table-data>
                                      </td> -->
                </tr>
              </tbody>
            </table>

            <div class="row">
              <div class="col-sm-12 col-md-5">
                <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                  <div class="col-sm-12 col-md-12">
                    <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                      Showing {{ (transactions?.pageNumber - 1) * transactions?.pageSize + 1 }} to
                      {{ (transactions?.pageNumber - 1) * transactions?.pageSize + transactions?.data?.length }} of
                      {{ transactions?.data?.length }} entries
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-7" style="float: right">
                <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                  <ul class="pagination" style="float: right">
                    <pagination-controls
                      (pageChange)="getVIPCOTransactions(buildPageUrl($event, 10))"
                    ></pagination-controls>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #confirmSend let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Are you sure you want to refund the application?</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <button class="btn btn-sm btn-success float-right" (click)="actionEventCreateAllocateFund()">Proceed</button>
    <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
      Cancel
    </button>
  </div>
</ng-template>
