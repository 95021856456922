import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { ManagerUsersService } from 'src/data/services/web/manager-users.service';
import { WalletService } from 'src/data/services/web/wallet.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { ColorPickerService, Cmyk } from 'ngx-color-picker';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { AngularFirestore } from '@angular/fire/firestore';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { FileUploadService } from 'src/data/services/web/file-upload.service';
import { ColorService } from 'src/data/services/web/color-service.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-vips-profile',
  templateUrl: './vips-profile.component.html',
  styleUrls: ['./vips-profile.component.less']
})
export class VipsProfileComponent implements OnInit {

  userId: any;
  username: any;
  qrCode: any;
  loader: boolean = false;
  public localStorageCacheService: LocalStorageCacheService;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value:any = "";
  emptyString: any = "";
  color = '#ffffff';
  public cmykColor: Cmyk = new Cmyk(0, 0, 0, 0);

  searchByFormGroup: FormGroup;
  customFormGroup: FormGroup;
  
  constructor(localStorageCacheService: LocalStorageCacheService,
    private modalServe: NgbModal,
    private usersService: ManagerUsersService,
    private uploadService: FileUploadService,
    private walletService: WalletService,
    public webSettingsService: WebSettingsService,
    private cpService: ColorPickerService,
    private sanitizer: DomSanitizer,
    private colorService: ColorService,
    private store: AngularFirestore) { 
   
    this.localStorageCacheService = localStorageCacheService;
  }

  ngOnInit(): void {
    this.value = this.localStorageCacheService.getStorage("forQR");
    this.userId = this.localStorageCacheService.getStorage("id");
    this.username = this.localStorageCacheService.getStorage("username");
    this.qrCode = this.localStorageCacheService.getStorage("forQR");
    this.getManagerUser(this.userId);
    this.getWallet();
    this.initReceipt();
    this.initCustomVips();
  }

  customVips:any;
  initCustomVips(){
    this.fetchCustomVipsData();

    //this.initFormGroup(this.customVips);
  }

  get colorServiceInstance(): ColorService {
    return this.colorService;
  }
  
  // async fetchCustomVipsData() {
  //   try {
  //     this.loader = true;
  //     const snapshot = await this.store.collection("CustomVipsApplication").doc(this.userId).get().toPromise();
      
  //     if (snapshot.exists) {
  //       // Document exists, you can access the data using snapshot.data()
  //       this.customVips = snapshot.data();
  //       this.initFormGroup(this.customVips);
  //       //console.log(this.customVips);
  //       this.loader = false;
  //     } else {
  //       // Document does not exist
  //       this.loader = false;
  //       this.initFormGroup(null);
  //       console.log('Document does not exist');
  //     }
  //   } catch (error) {
  //     this.loader = false;
  //     console.error('Error fetching document:', error);
  //     PNotify.error({
  //       title: "Error fetching document",
  //       text: "Please reload",
  //     });
  //   }
  // }

  async fetchCustomVipsData() {
    try {
      this.loader = true;
      const snapshot = await this.store.collection("CustomVipsApplication").doc(this.userId).get().toPromise();
      
      if (snapshot.exists) {
        // Document exists, you can access the data using snapshot.data()
        this.customVips = snapshot.data();
        this.initFormGroup(this.customVips);
        
        // Fetch data from the "Banners" subcollection
        const bannersSnapshot = await this.store.collection("CustomVipsApplication").doc(this.userId).collection("Banners").get().toPromise();
        
        // Initialize an array to store the images
        this.images = [];
        var i = 0;

        bannersSnapshot.forEach((doc) => {
          // Push each banner data into the images array
          const bannerUrl = doc.data().bannerUrl;
          const index = doc.id; // Use document ID as index
          this.images.push({ path: bannerUrl, valid: true });

          if(i == 0){
            this.cardImage = bannerUrl;
            i += 1;
          }
        });

        console.log("images: ", this.images);
        this.loader = false;
      } else {
        // Document does not exist
        this.loader = false;
        this.initFormGroup(null);
        console.log('Document does not exist');
      }
    } catch (error) {
      this.loader = false;
      console.error('Error fetching document:', error);
      PNotify.error({
        title: "Error fetching document",
        text: "Please reload",
      });
    }
  }  

  initFormGroup(data: any) {
    this.customFormGroup = new FormGroup({
      backgroundColor: new FormControl(data != null ? '#'+ data.backgroundCustomColor : '', [Validators.required]),
      buttonColor: new FormControl(data != null ? '#'+ data.buttonCustomColor : '', [Validators.required]),
      buttonFontColor: new FormControl(data != null ? '#'+ data.buttonCustomFontColor : '', [Validators.required]),
      primaryColor: new FormControl(data != null ? '#'+ data.primaryCustomColor : '', [Validators.required]),
      primaryFontColor: new FormControl(data != null ? '#'+ data.primaryCustomFontColor : '', [Validators.required]),
      primaryWalletFontColor: new FormControl(data != null ? '#'+ data.primaryCustomWalletFontColor : '', [Validators.required]),
      imageLandscapeUrl: new FormControl(data != null ? data.customImageLandscapeUrl : '', [Validators.required]),
      imageUrl: new FormControl(data != null ? data.customImageUrl : '', [Validators.required]),
      status: new FormControl(data != null ? data.status : ''),
      mode: new FormControl(data != null ? data.mode : false),
      customAppName: new FormControl(data != null ? data.customAppName: '', [Validators.required]),
      municipality_features: new FormControl(data != null ? data.municipality_features: false),
    });

    this.croppedImage = data != null ? data.customImageLandscapeUrl : '';
    this.croppedLogo = data != null ? data.customImageUrl : '';
  }

  getWallet(): void {
    this.walletService.getWallet().subscribe((result) => {
      this.wallet = result.wallet;
    });
  }

  wallet: any;
  user: any;
  getManagerUser(id: any): void {
    this.usersService.getManagerUser(id).subscribe((result) => {
      this.user = result.data;
    });
  }

  updateBackgroundColor(color: string, formName: string) {
    (this.customFormGroup.controls[formName] as FormControl).setValue(color);
    // Additional logic if needed
  }  

  searchData(){

  }

  initSearchFormGroup(): void {
    this.searchByFormGroup = new FormGroup({
      showRows: new FormControl(null),
      dateRange: new FormControl(this.emptyString),
      searchString: new FormControl(null),
    });
  }

  viewUser(id: any){

  }

  receipt: any;
  initReceipt() {
    this.webSettingsService.getWebSettingsByIdentifier("GoVIPX_QR_TEMPLATE").subscribe((result) => {
      this.receipt = result.data;
    });
  }

  openQR(content: any){
    this.modalServe.open(content, { centered: true, size: "lg" });
  }

  getQR(myQRTemplate: any){
    var qrtemplate = myQRTemplate.innerHTML;

    var Newstr = (this.receipt[0].value)
      .replace("{{QR}}", qrtemplate);
    
    this.PrintReceipt(Newstr);
  }

  PrintReceipt(Newstr: any)
  {
    var mywindow = window.open('', 'PRINT', 'height=700,width=1000');
    mywindow.document.write(Newstr);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    this.modalServe.dismissAll();

    return true;
  }

  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageMaxSizeKB = 3072;

  fileChangeEvent(event: any): void {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      console.log("Selected file size:", selectedFile.size);
      const maxSizeBytes = 3 * 1024 * 1024; // 3MB in bytes
      console.log("Max size allowed:", maxSizeBytes);
  
      if (selectedFile.size > maxSizeBytes) {
        // Display an error message or take appropriate action
        PNotify.error({
          title: "Error Uploading Image",
          text: "Please upload an image with a size less than 3MB.",
        });
        event.target.value = '';
        return;
      } else {
        // Proceed with the image cropper
        this.logoChangedEvent = event;
      }
    }
  }

  fileLandScapeChangeEvent(event: any): void {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      console.log("Selected file size:", selectedFile.size);
      const maxSizeBytes = 9 * 1024 * 1024; // 3MB in bytes
      console.log("Max size allowed:", maxSizeBytes);
  
      if (selectedFile.size > maxSizeBytes) {
        // Display an error message or take appropriate action
        PNotify.error({
          title: "Error Uploading Image",
          text: "Please upload an image with a size less than 9MB.",
        });
        event.target.value = '';
        return;
      } else {
        // Proceed with the image cropper
        this.imageChangedEvent = event;
      }
    }
  }
  
  

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.base64);
    // event.blob can be used to upload the cropped image
  }
  imageLoaded(image: LoadedImage) {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
    // this.croppedImage = this.customFormGroup.controls.imageLandscapeUrl.value;
  }

  landscapeRef: NgbModalRef;
  uploadLandscape(content){
    this.croppedImage = '';
    this.landscapeRef = this.modalServe.open(content, { centered: true, size: "md", backdrop: 'static' });
  }

  customNameRef: NgbModalRef;
  openUpdateCustomName(content){
    this.customNameRef = this.modalServe.open(content, { centered: true, size: "sm", backdrop: 'static' });
  }

  logoChangedEvent: any = '';
  croppedLogo: any = '';

  fileChangeLogoEvent(event: any): void {
    this.logoChangedEvent = event;
  }
  logoCropped(event: ImageCroppedEvent) {
    this.croppedLogo = this.sanitizer.bypassSecurityTrustUrl(event.base64);
    // event.blob can be used to upload the cropped image
  }
  logoLoaded(image: LoadedImage) {
    // this.croppedLogo = this.customFormGroup.controls.imageUrl.value;
  }

  cropperLogoReady() {
    // cropper ready
  }

  loadLogoFailed() {
      // show message
  }

  logoRef: NgbModalRef;
  uploadLogo(content){
    this.croppedLogo = '';
    this.logoRef = this.modalServe.open(content, { centered: true, size: "md", backdrop: 'static' });
  }

  uploadCustomImage(){  
    var image = this.croppedImage.changingThisBreaksApplicationSecurity;
    var base64 = image.split(',')[1];
    this.uploadService.uploadCustomImage(base64).subscribe((result) => {
      this.croppedImage = result.response_data;
      this.customFormGroup.controls.imageLandscapeUrl.patchValue(result.response_data);
      this.landscapeRef.close();
      PNotify.success({
        title: "Upload Success",
        text: "",
      });
    }, (error) => {
      PNotify.error({
        title: "Error Uploading Image",
        text: "Please try again",
      });
    });
  }

  uploadCustomLogo(){  
    var image = this.croppedLogo.changingThisBreaksApplicationSecurity;
    var base64 = image.split(',')[1];
    this.uploadService.uploadCustomImage(base64).subscribe((result) => {
      this.croppedLogo = result.response_data;
      this.customFormGroup.controls.imageUrl.patchValue(result.response_data);
      this.logoRef.close();
      PNotify.success({
        title: "Upload Success",
        text: "",
      });
    }, (error) => {
      PNotify.error({
        title: "Error Uploading Image",
        text: "Please try again",
      });
    });
  }
 
  saveChanges(){
    if(this.customFormGroup.valid){
      this.store.collection("CustomVipsApplication")
        .doc(this.userId).set({
            backgroundCustomColor: this.customFormGroup.controls.backgroundColor.value.replace('#',''),
            buttonCustomColor: this.customFormGroup.controls.buttonColor.value.replace('#',''),
            buttonCustomFontColor: this.customFormGroup.controls.buttonFontColor.value.replace('#',''),
            customImageLandscapeUrl: this.customFormGroup.controls.imageLandscapeUrl.value,
            customImageUrl: this.customFormGroup.controls.imageUrl.value,
            primaryCustomColor: this.customFormGroup.controls.primaryColor.value.replace('#',''),
            primaryCustomFontColor: this.customFormGroup.controls.primaryFontColor.value.replace('#',''),
            primaryCustomWalletFontColor: this.customFormGroup.controls.primaryWalletFontColor.value.replace('#',''),
            status: "Saved",
            username: this.username,
            id: this.userId,
            qr: this.qrCode,
            savedDate: Date.now(),
            mode: this.customFormGroup.controls.mode.value,
            customAppName: this.customFormGroup.controls.customAppName.value,
            municipality_features: this.customFormGroup.controls.municipality_features.value
          }, {
          merge: true,
      });

      this.customFormGroup.controls.status.patchValue("Saved");
      this.applyBannerChanges();

      PNotify.success({
        title: "Saved Success",
        text: "Customization has been saved.",
      });
    } else {
      PNotify.error({
        title: "Invalid",
        text: "Please complete the setup",
      });
    }
  }

  applyChanges(){
    if(this.customFormGroup.valid){
      this.store.collection("CustomVipsApplication")
      .doc(this.userId).set({
          backgroundCustomColor: this.customFormGroup.controls.backgroundColor.value.replace('#',''),
          buttonCustomColor: this.customFormGroup.controls.buttonColor.value.replace('#',''),
          buttonCustomFontColor: this.customFormGroup.controls.buttonFontColor.value.replace('#',''),
          customImageLandscapeUrl: this.customFormGroup.controls.imageLandscapeUrl.value,
          customImageUrl: this.customFormGroup.controls.imageUrl.value,
          primaryCustomColor: this.customFormGroup.controls.primaryColor.value.replace('#',''),
          primaryCustomFontColor: this.customFormGroup.controls.primaryFontColor.value.replace('#',''),
          primaryCustomWalletFontColor: this.customFormGroup.controls.primaryWalletFontColor.value.replace('#',''),
          status: "Applied",
          username: this.username,
          id: this.userId,
          qr: this.qrCode,
          appliedDate: Date.now(),
          mode: this.customFormGroup.controls.mode.value,
          customAppName: this.customFormGroup.controls.customAppName.value,
          municipality_features: this.customFormGroup.controls.municipality_features.value
        }, {
          merge: true,
      });
      this.customFormGroup.controls.status.patchValue("Applied");
      this.applyBannerChanges();

      PNotify.success({
        title: "Applied Success",
        text: "Please wait for the approval of your customization",
      });
    } else {
      PNotify.error({
        title: "Invalid",
        text: "Please complete the setup",
      });
    }
  }

  previewRef: NgbModalRef;
  previewChanges(content){
    this.previewRef = this.modalServe.open(content, { centered: true, size: "md" });
  }

  imageWidth = 64; // set the width of each image
  imageHeight = 68; // set the width of each image
  visibleImages = 3; // set the number of visible images
  currentIndex = 0; // set the initial index of the first visible image
  
  scroll(direction: number): void {
    const wrapper = document.querySelector('.slider1-wrapper') as HTMLElement;
    const itemWidth = this.imageWidth + 4; // include margin-right
    const wrapperWidth = wrapper.offsetWidth;
    const maxIndex = this.images.length - this.visibleImages;
    let nextIndex = this.currentIndex + direction;
    if (nextIndex < 0) {
      nextIndex = 0;
    } else if (nextIndex > maxIndex) {
      nextIndex = maxIndex;
    }
    this.currentIndex = nextIndex;
    const transform = `translateX(-${this.currentIndex * itemWidth}px)`;
    wrapper.style.transform = transform;
  }

  cardImage: any = '../../../../assets/vips2/empty2.png';
  changeBigImage(image: any){
    this.cardImage = image;
  }

  images:any = [];
  files: File[] = [];

  onSelect(event) {
    console.log(event);
    // this.files = [];
    // this.files.push(...event.addedFiles);

    this.files = [];
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif']; // Valid image MIME types
  
    for (const file of event.addedFiles) {
      if (validImageTypes.includes(file.type)) {
        this.files.push(file);
      } else {
        console.log(`Invalid file type: ${file.type}`);
        // Display an error message or perform any necessary actions for invalid file types
      }
    }
  }
  
  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  addBannerModalRef: NgbModalRef;
  addBanner(content: any){
    this.addBannerModalRef = this.modalServe.open(content, {
      centered: true,
      size: "md",
      backdrop: "static",
      keyboard: false,
    });
  }

  removeBanner(){
    if(this.currentImageIndex >= 0 && this.currentImageIndex < this.images.length){
        this.images.splice(this.currentImageIndex, 1); // Remove the image at currentImageIndex
        // Optionally, you might want to reset the currentImageIndex or update it accordingly
        if (this.currentImageIndex >= this.images.length) {
            this.currentImageIndex = this.images.length - 1; // Adjust currentImageIndex if it's out of bounds
        }
        // Optionally, you might want to update this.cardImage or any other variables as needed
    } else {
        // Handle error if currentImageIndex is out of bounds
        console.error("Invalid currentImageIndex:", this.currentImageIndex);
    }
  }

  bannerChangedEvent: any = '';
  croppedBanner: any = '';
  bannerMaxSizeKB = 9216;
  
  fileBannerChangeEvent(event: any): void {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Check the file size
      const maxSizeBytes = 9 * 1024 * 1024; // 9MB in bytes
  
      if (selectedFile.size > maxSizeBytes) {
        // Display an error message or take appropriate action
        PNotify.error({
          title: "Error Uploading Image",
          text: "Please upload an image with a size less than 9MB.",
        });
        return;
      }
  
      // Proceed with the image cropper
      this.bannerChangedEvent = event;
    }
  }
  

  bannerCropped(event: ImageCroppedEvent) {
    this.croppedBanner = this.sanitizer.bypassSecurityTrustUrl(event.base64);
    // event.blob can be used to upload the cropped image
  }
  bannerLoaded(image: LoadedImage) {
      // show cropper
  }

  uploadBanner(){
    if(this.croppedBanner){
      var image = this.croppedBanner.changingThisBreaksApplicationSecurity;
    
      let path = { path: this.croppedBanner, valid: false };
      this.images.push(path);

      this.cardImage = this.croppedBanner;
      this.croppedBanner = "";
      this.bannerChangedEvent = "";
      this.currentImageIndex = this.images.length -1;
      this.addBannerModalRef.close();
    } else {
      PNotify.error({
        title: "Error Uploading Image",
        text: "Please select an image.",
      });
    }
  }

  // saveBannerChanges(){
  //   if(this.images){
  //     this.images.forEach((obj, index) => {
  //       debugger
  //       var base64 = obj.path.changingThisBreaksApplicationSecurity.split(',')[1];
  //       this.uploadService.uploadCustomImage(base64).subscribe((result) => {
  //         this.store.collection("CustomVipsApplication")
  //         .doc(this.userId)
  //         .collection("BannersSaved")
  //         .doc(index.toString()) // Use the index as the document ID
  //         .set({ bannerUrl: result.response_data }) // Set the object as the document data
  //         .then(() => {
  //           console.log(`Document with ID ${index} added successfully.`);
  //         })
  //         .catch((error) => {
  //           console.error(`Error adding document with ID ${index}:`, error);
  //         });

  //       }, (error) => {
  //         PNotify.error({
  //           title: "Error Uploading Image",
  //           text: "1 Image is fail to upload. Please try again",
  //         });
  //       });

  //     });

  //     this.customFormGroup.controls.status.patchValue("Saved");
  //     PNotify.success({
  //       title: "Saved Success",
  //       text: "Changes have been applied to your app.",
  //     });
  //   } else {
  //     PNotify.error({
  //       title: "Invalid",
  //       text: "Please upload at least 1 Banner to proceed",
  //     });
  //   }
  // }

  // applyBannerChanges(){
  //   if(this.images){
  //     this.images.forEach((obj, index) => {
  //       // debugger
  //       if(!obj.valid){
  //         var base64 = obj.path.changingThisBreaksApplicationSecurity.split(',')[1];
  //         this.uploadService.uploadCustomImage(base64).subscribe((result) => {
  //           this.store.collection("CustomVipsApplication")
  //           .doc(this.userId)
  //           .collection("Banners")
  //           .doc(index.toString()) // Use the index as the document ID
  //           .set({ bannerUrl: result.response_data, sequence: index }) // Set the object as the document data
  //           .then(() => {
  //             console.log(`Document with ID ${index} added successfully.`);
  //           })
  //           .catch((error) => {
  //             console.error(`Error adding document with ID ${index}:`, error);
  //           });

  //         }, (error) => {
  //           PNotify.error({
  //             title: "Error Uploading Image",
  //             text: "1 Image is fail to upload. Please try again",
  //           });
  //         });
  //       } else {
  //         this.store.collection("CustomVipsApplication")
  //           .doc(this.userId)
  //           .collection("Banners")
  //           .doc(index.toString()) // Use the index as the document ID
  //           .set({ bannerUrl: obj.path, sequence: index }) // Set the object as the document data
  //           .then(() => {
  //             console.log(`Document with ID ${index} added successfully.`);
  //           })
  //           .catch((error) => {
  //             console.error(`Error adding document with ID ${index}:`, error);
  //           });
  //       }

  //     });
  //   } else {
  //     PNotify.error({
  //       title: "Invalid",
  //       text: "Please upload at least 1 Banner to proceed",
  //     });
  //   }
  // }

  applyBannerChanges(){
    // Clear existing banners
    const batch = this.store.firestore.batch();
    const bannersCollectionRef = this.store.collection("CustomVipsApplication").doc(this.userId).collection("Banners");
    bannersCollectionRef.get().subscribe((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            batch.delete(doc.ref);
        });

        // Commit the batched deletion operation
        batch.commit().then(() => {
            console.log("Existing banners deleted successfully.");
            // Proceed to upload new banners
            this.uploadNewBanners();
        }).catch((error) => {
            console.error("Error deleting existing banners:", error);
            // Handle error if necessary
        });
    }, (error) => {
        console.error("Error retrieving existing banners:", error);
        // Handle error if necessary
    });
  }

  uploadNewBanners() {
    if (this.images) {
        this.images.forEach((obj, index) => {
          if (!obj.valid) {
            var base64 = obj.path.changingThisBreaksApplicationSecurity.split(',')[1];
            this.uploadService.uploadCustomImage(base64).subscribe((result) => {
              this.store.collection("CustomVipsApplication")
              .doc(this.userId)
              .collection("Banners")
              .doc(index.toString()) // Use the index as the document ID
              .set({ bannerUrl: result.response_data, sequence: index }) // Set the object as the document data
              .then(() => {
                console.log(`Document with ID ${index} added successfully.`);
              })
              .catch((error) => {
                console.error(`Error adding document with ID ${index}:`, error);
              });
            }, (error) => {
              PNotify.error({
                title: "Error Uploading Image",
                text: "1 Image is fail to upload. Please try again",
              });
            });
          } else {
            // Set the new banner URL in Firestore
            this.store.collection("CustomVipsApplication")
              .doc(this.userId)
              .collection("Banners")
              .doc(index.toString()) // Use the index as the document ID
              .set({ bannerUrl: obj.path, sequence: index }) // Set the object as the document data
              .then(() => {
                  console.log(`Document with ID ${index} added successfully.`);
              })
              .catch((error) => {
                  console.error(`Error adding document with ID ${index}:`, error);
              });
          }
        });
    } else {
      PNotify.error({
          title: "Invalid",
          text: "Please upload at least 1 Banner to proceed",
      });
    }
  }

  currentImageIndex: any = 0;

  nextImage() {
    if (this.currentImageIndex < this.images.length - 1) {
      this.currentImageIndex++;
    } else {
      this.currentImageIndex = 0;
      
    }
  }

  prevImage() {
    if (this.currentImageIndex > 0) {
      this.currentImageIndex--;
    } else {
      this.currentImageIndex = this.images.length - 1;
    }
  }

  removeImage(){

  }

  openConfirmReset(content){
    this.addBannerModalRef = this.modalServe.open(content, {
      centered: true,
      size: "sm",
      backdrop: "static",
      keyboard: false,
    });
  }

  async proceedReset() {
    try {
        const userId = this.userId;

        // Get a reference to the collection
        const collectionRef = this.store.collection("CustomVipsApplication").doc(userId).collection("Banners");

        // Get all documents in the collection
        const querySnapshot = await collectionRef.get().toPromise();

        // Create a batch
        const batch = this.store.firestore.batch();

        // Delete each document in the collection
        querySnapshot.forEach((doc) => {
            batch.delete(doc.ref);
        });

        // Commit the batched deletion operation
        await batch.commit();

        // Now delete the collection itself (workaround)
        await this.deleteCollection(this.store.firestore, `CustomVipsApplication/${userId}/Banners`, 10); // 10 is the batch size

        // Delete the CustomVipsApplication document
        await this.store.collection("CustomVipsApplication").doc(userId).delete();

        // Delete the CustomVips document
        await this.store.collection("CustomVips").doc(userId).delete();

        this.customFormGroup.reset();
        this.images = [];
        this.modalServe.dismissAll();

        PNotify.success({
          title: "Sucess",
          text: "Customization has been deleted",
        });

        console.log("Collection and all documents within it deleted successfully.");
    } catch (error) {
        console.error("Error deleting collection:", error);
        // Handle error if necessary
    }
  }

  // Function to recursively delete a collection
  async deleteCollection(db: any, collectionPath: string, batchSize: number) {
      const collectionRef = db.collection(collectionPath);

      // Get the documents in the collection
      const querySnapshot = await collectionRef.get();

      // Delete documents in a batch
      const batch = db.batch();
      querySnapshot.docs.forEach((doc: any) => {
          batch.delete(doc.ref);
      });

      // Commit the batch
      await batch.commit();

      // Recursively delete subcollections
      if (querySnapshot.size > batchSize) {
          return this.deleteCollection(db, collectionPath, batchSize);
      }
  }

}
