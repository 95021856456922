import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalRef, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { FileUploadService } from 'src/data/services/web/file-upload.service';
import { ManagerUsersService } from 'src/data/services/web/manager-users.service';
import { WalletService } from 'src/data/services/web/wallet.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { FormControl, FormGroup } from '@angular/forms';
import { stat } from 'fs';
import { Observable, of } from 'rxjs';
import * as _ from 'lodash';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-vips-community-list',
  templateUrl: './vips-community-list.component.html',
  styleUrls: ['./vips-community-list.component.less']
})
export class VipsCommunityListComponent implements OnInit {

  loader: boolean = false;
  userId: any;
  username: any;

  messagePostContext: any;
  imagesPostContext: any;
  videoPostContext: any;

  constructor(private localStorageCacheService: LocalStorageCacheService,
    private modalServe: NgbModal,
    private usersService: ManagerUsersService,
    private uploadService: FileUploadService,
    private walletService: WalletService,
    public webSettingsService: WebSettingsService,
    private sanitizer: DomSanitizer,
    private store: AngularFirestore) { 
   
    this.localStorageCacheService = localStorageCacheService;
  }

  ngOnInit(): void {
    this.userId = this.localStorageCacheService.getStorage("id");
    this.username = this.localStorageCacheService.getStorage("username");
    this.getManagerUser(this.userId);
    //this.fetchCustomVipsData();
    this.getApprovedPosts();
    this.getPendingPosts();
    this.initPostFormGroup();
    this.initImagePostFormGroup();
    
    //window.addEventListener('scroll', this.onScroll, true);

    this.messagePostContext = { $implicit: null };
    this.imagesPostContext = { $implicit: null };
    this.videoPostContext = { $implicit: null };
  }

  // Inside your component class
lastVisibleDoc: any;
loadingMorePosts: boolean = false; // Flag to prevent redundant loading

// ngOnDestroy() {
//   window.removeEventListener('scroll', this.onScroll, true);
// }

// async onScroll(event: Event): Promise<void> {
//   const scrollPosition = window.scrollY + window.innerHeight;
//   const totalHeight = document.body.scrollHeight;

//   // Check if the scroll position is near the bottom (within 100px)
//   if (scrollPosition >= totalHeight - 100 && !this.loadingMorePosts) {
//     debugger
//     // Load more posts here
//     //await this.loadMorePosts();
//   }
// }

// async loadMorePosts() {
//   try {
//     this.loadingMorePosts = true; // Set loading flag
//     const communitiesSnapshot = await this.store.collection("CustomVipsCommunity").get().toPromise();
    
//     for (const communityDoc of communitiesSnapshot.docs) {
//       const communityId = communityDoc.id;
//       const postsSnapshot = await this.store.firestore.collection("CustomVipsCommunity").doc(communityId)
//         .collection("Posts")
//         .where("status", "==", "approved")
//         .orderBy("timestamp") // Order by timestamp field
//         .startAfter(this.lastVisibleDoc || null) // Start after the last visible document
//         .limit(10)
//         .get();

//       if (!postsSnapshot.empty) {
//         // Only process posts if there are more to load
//         await this.processPosts(communityId, postsSnapshot, this.posts);
//         this.lastVisibleDoc = postsSnapshot.docs[postsSnapshot.docs.length - 1]; // Update the last visible document
//       }
//     }

//     this.posts.sort((a, b) => b.data.timestamp - a.data.timestamp);
//   } catch (error) {
//     console.error('Error fetching approved posts:', error);
//     PNotify.error({
//       title: "Error fetching approved posts",
//       text: "Please reload",
//     });
//   } finally {
//     this.loadingMorePosts = false; // Reset loading flag
//   }
// }

scrollHandler(e){
  // if (e === 'bottom'){
  //   this.page.more()
  // }

  console.log(e);
}

activeTabIndex: number = 0; // Initialize to 0 for "My Community" tab

// Function to update active tab index
setActiveTabIndex(index: number) {
  this.activeTabIndex = index;
}

  postFormGroup: FormGroup;
  initPostFormGroup(){
    this.postFormGroup = new FormGroup({
      contentType : new FormControl,
      customAppName : new FormControl,
      logo : new FormControl,
      message : new FormControl,
      status : new FormControl,
      timestamp : new FormControl,
      title : new FormControl
    });

    this.postFormGroup.controls.title.valueChanges.subscribe((val) => {
      this.isValid = this.checkIfValid();
    });

    this.postFormGroup.controls.message.valueChanges.subscribe((val) => {
      this.isValid = this.checkIfValid();
    });
  }

  imageFormGroup: FormGroup;
  initImagePostFormGroup(){
    this.imageFormGroup = new FormGroup({
      url: new FormControl,
      thumbnailUrl : new FormControl,
      timestamp : new FormControl,
      duration : new FormControl,
      viewCount : new FormControl
    });
  }

  resizeTextarea(textarea: HTMLTextAreaElement) {
    textarea.style.height = 'auto'; // Reset the height to auto
    textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to the scrollHeight
  }

  user: any;
  getManagerUser(id: any): void {
    this.usersService.getManagerUser(id).subscribe((result) => {
      this.user = result.data;
    });
  }

  communities: any[] = [];
  community: any;
  posts: any = [];
  postsPending: any = [];
  postsRejected: any = [];
//   async getAllCommunities() {
//     try {
//         this.loader = true;
//         this.posts = [];
//         this.postsPending = [];
//         this.postsRejected = [];
//         // Fetch all communities
//         const communitiesSnapshot = await this.store.collection("CustomVipsCommunity").get().toPromise();

//         // Iterate over each community document
//         for (const communityDoc of communitiesSnapshot.docs) {
//             const communityId = communityDoc.id;
//             const communityData = communityDoc.data();

//             // Fetch posts for the current community
//             const postsSnapshot = await this.store.collection("CustomVipsCommunity").doc(communityId).collection("Posts").get().toPromise();

//             // Iterate over each post document
//             for (const postDoc of postsSnapshot.docs) {
//                 const postId = postDoc.id;
//                 const postData = postDoc.data();

//                 // Fetch images for the current post
//                 const imagesSnapshot = await this.store.collection("CustomVipsCommunity").doc(communityId)
//                     .collection("Posts").doc(postId)
//                     .collection("images").get().toPromise();
//                 const postImages = [];
//                 var imgCount = 0;

//                 // Iterate over each image document
//                 imagesSnapshot.forEach(imageDoc => {
//                     postImages.push(imageDoc.data());
//                     imgCount++;
//                 });

//                 // Add the community ID to the post data
//                 postData["communityId"] = communityId;

//                 // Add the post data along with its images to the array
//                 if(postData.status == "approved"){
//                   this.posts.push({ id: postId, data: postData, images: postImages, count: imgCount });
//                 } else if(postData.status == "pending") {
//                   this.postsPending.push({ id: postId, data: postData, images: postImages, count: imgCount });
//                 } else {
//                   this.postsRejected.push({ id: postId, data: postData, images: postImages, count: imgCount });
//                 }
//             }
//         }

//         // Sort posts by timestamp (newest to oldest)
//         this.posts.sort((a, b) => b.data.timestamp - a.data.timestamp);
//         this.postsPending.sort((a, b) => b.data.timestamp - a.data.timestamp);
//         this.postsRejected.sort((a, b) => b.data.timestamp - a.data.timestamp);

//         console.log("All Posts: ", this.posts);
//         this.loader = false;
//     } catch (error) {
//         this.loader = false;
//         console.error('Error fetching communities:', error);
//         PNotify.error({
//             title: "Error fetching communities",
//             text: "Please reload",
//         });
//     }
// }

async getApprovedPosts() {
  try {
    this.loader = true;
    this.posts = [];

    const communitiesSnapshot = await this.store.collection("CustomVipsCommunity").get().toPromise();

    for (const communityDoc of communitiesSnapshot.docs) {
      const communityId = communityDoc.id;

      const postsSnapshot = await this.store.collection("CustomVipsCommunity")
        .doc(communityId)
        .collection("Posts")
        .ref
        .where("status", "==", "approved")
        .limit(25)
        .get();

      await this.processPosts(communityId, postsSnapshot, this.posts);
    }

    this.posts.sort((a, b) => b.data.approvalTimestamp - a.data.approvalTimestamp);
    //console.log(this.posts);
    this.loader = false;
  } catch (error) {
    this.loader = false;
    console.error('Error fetching approved posts:', error);
    PNotify.error({
      title: "Error fetching approved posts",
      text: "Please reload",
    });
  }
}

async getPendingPosts() {
  try {
    this.loader = true;
    this.postsPending = [];

    const communitiesSnapshot = await this.store.collection("CustomVipsCommunity").get().toPromise();

    for (const communityDoc of communitiesSnapshot.docs) {
      const communityId = communityDoc.id;

      const postsSnapshot = await this.store.collection("CustomVipsCommunity")
        .doc(communityId)
        .collection("Posts")
        .ref
        .where("status", "==", "pending")
        .limit(20)
        .get();

      await this.processPosts(communityId, postsSnapshot, this.postsPending);
    }

    this.postsPending.sort((a, b) => b.data.timestamp - a.data.timestamp);
    console.log(this.postsPending);
    this.loader = false;
  } catch (error) {
    this.loader = false;
    console.error('Error fetching pending posts:', error);
    PNotify.error({
      title: "Error fetching pending posts",
      text: "Please reload",
    });
  }
}


// async getRejectedPosts() {
//   try {
//     this.loader = true;
//     this.postsRejected = [];

//     // Fetch all communities
//     const communitiesSnapshot = await this.store.collection("CustomVipsCommunity").get().toPromise();

//     // Iterate over each community document
//     for (const communityDoc of communitiesSnapshot.docs) {
//       const communityId = communityDoc.id;

//       // Fetch rejected posts for the current community
//       const postsSnapshot = await this.store.collection("CustomVipsCommunity")
//         .doc(communityId)
//         .collection("Posts")
//         .ref
//         .where("status", "==", "rejected")
//         .limit(5)
//         .get();

//       await this.processPosts(communityId, postsSnapshot, this.postsRejected);
//     }

//     this.postsRejected.sort((a, b) => b.data.timestamp - a.data.timestamp);
//     this.loader = false;
//   } catch (error) {
//     this.loader = false;
//     console.error('Error fetching rejected posts:', error);
//     PNotify.error({
//       title: "Error fetching rejected posts",
//       text: "Please reload",
//     });
//   }
// }

async processPosts(communityId: any, postsSnapshot: any, postsArray: any[]) {
  for (const postDoc of postsSnapshot.docs) {
      const postId = postDoc.id;
      const postData = postDoc.data();
      //debugger

      const imagesSnapshot = await this.store.collection("CustomVipsCommunity").doc(communityId)
          .collection("Posts").doc(postId).collection("images").get().toPromise();
      const postImages = [];
      var imgCount = 0;

      imagesSnapshot.forEach(imageDoc => {
          postImages.push(imageDoc.data());
          imgCount++;
          //debugger
      });

      postData["communityId"] = communityId;
      postsArray.push({ id: postId, data: postData, images: postImages, count: imgCount });
  }
}

  
  // async getCommunity(){
  //   try {
  //     this.loader = true;
  //     const snapshot = await this.store.collection("CustomVipsCommunity").doc(this.userId).get().toPromise();
      
  //     if (snapshot.exists) {
  //       // Document exists, you can access the data using snapshot.data()
  //       this.community = snapshot.data();
        
  //       // Fetch data from the "Banners" subcollection
  //       const bannersSnapshot = await this.store.collection("CustomVipsCommunity").doc(this.userId).collection("Posts").get().toPromise();
        
  //       this.community = snapshot.data();
  //       // Initialize an array to store the images

        
  //       for (const doc of bannersSnapshot.docs) {
  //         const imagesSnapshot = await this.store.collection("CustomVipsCommunity").doc(this.userId).collection("Posts").doc(doc.id).collection("images").get().toPromise();
  //         const images = [];
  //         var imgCount = 0;

  //         imagesSnapshot.forEach((imageDoc) => {
  //             images.push(imageDoc.data());
  //             imgCount++;
  //         });

  //         if(doc.data().status == "pending"){
  //           this.postsPending.push({ id: doc.id, data: doc.data(), images: images, count: imgCount });
  //         } else if(doc.data().status == "rejected"){
  //           this.postsRejected.push({ id: doc.id, data: doc.data(), images: images, count: imgCount });
  //         } else {
  //           this.posts.push({ id: doc.id, data: doc.data(), images: images, count: imgCount });
  //         }
  //       }
        
  //       // console.log("community: ", this.community);
  //       // console.log("posts: ", this.posts);
  //       this.loader = false;
  //     } else {
  //       // Document does not exist
  //       this.loader = false;
  //       console.log('Document does not exist');
  //     }
  //   } catch (error) {
  //     this.loader = false;
  //     console.error('Error fetching document:', error);
  //     PNotify.error({
  //       title: "Error fetching document",
  //       text: "Please reload",
  //     });
  //   }
  // }

  altData: string = "../../../assets/vips2/1.png";
  computeTimeAgo(timestamp: string): string {
    const now = new Date();
    const pastTime = new Date(timestamp);

    const timeDiff = Math.abs(now.getTime() - pastTime.getTime());
    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (years > 0) {
        return years === 1 ? "a year ago" : `${years} years ago`;
    } else if (months > 0) {
        return months === 1 ? "a month ago" : `${months} months ago`;
    } else if (days > 0) {
        return days === 1 ? "Yesterday" : `${days} days ago`;
    } else if (hours > 0) {
        return hours === 1 ? "an hour ago" : `${hours} hours ago`;
    } else if (minutes > 0) {
        return minutes === 1 ? "a minute ago" : `${minutes} minutes ago`;
    } else {
        return "just now";
    }
  }

  customVips:any;
  images: any = [
    {path:"https://firebasestorage.googleapis.com/v0/b/vipcomegawallet.appspot.com/o/vipsAppMainAssets%2FvipsAppCarousel%2F1.png?alt=media&token=7383a863-6d56-4890-974d-f0cfd41bbaf1"},
    {path:"https://firebasestorage.googleapis.com/v0/b/vipcomegawallet.appspot.com/o/vipsAppMainAssets%2FvipsAppCarousel%2F2.png?alt=media&token=114405a8-3769-4735-8c5f-93a173dbfbe9"},
    {path:"https://firebasestorage.googleapis.com/v0/b/vipcomegawallet.appspot.com/o/vipsAppMainAssets%2FvipsAppCarousel%2F3.png?alt=media&token=427f9b73-d926-42ca-bc2f-6ee5cd824496"},
    {path:"https://firebasestorage.googleapis.com/v0/b/vipcomegawallet.appspot.com/o/vipsAppMainAssets%2FvipsAppCarousel%2F4.png?alt=media&token=9f6c134c-31e6-4163-9dac-6737c0079fd6"}
  ];
  async fetchCustomVipsData() {
    try {
      this.loader = true;
      const snapshot = await this.store.collection("CustomVipsApplication").doc(this.userId).get().toPromise();
      
      if (snapshot.exists) {
        // Document exists, you can access the data using snapshot.data()
        this.customVips = snapshot.data();
        
        // Fetch data from the "Banners" subcollection
        const bannersSnapshot = await this.store.collection("CustomVipsApplication").doc(this.userId).collection("Banners").get().toPromise();
        
        // Initialize an array to store the images
        this.images = [];
        var i = 0;

        // console.log("custom: ", this.customVips);
        // console.log("images: ", this.images);
        this.loader = false;
      } else {
        // Document does not exist
        this.loader = false;
        console.log('Document does not exist');
      }
    } catch (error) {
      this.loader = false;
      console.error('Error fetching document:', error);
      PNotify.error({
        title: "Error fetching document",
        text: "Please reload",
      });
    }
  } 

  imageViewerRef: NgbModalRef;
  selectedPost: any = [];
  openImageViewer(content: any, post: any, index: boolean){
    this.selectedPost = post;
    this.currentImageIndex = index;
    this.imageViewerRef = this.modalServe.open(content, { centered: true, size: "xl" });
  }

  videoViewerRef: NgbModalRef;
  selectedVideoPost: any;
  openVideoViewer(content: any, post: any){
    this.selectedVideoPost = post;
    this.videoViewerRef = this.modalServe.open(content, { centered: true, size: "xl" });
  }

  currentImageIndex: any = 0;

  nextImage() {
    if (this.currentImageIndex < this.selectedPost.images.length - 1) {
      this.currentImageIndex++;
    } else {
      this.currentImageIndex = 0;
      
    }
  }

  prevImage() {
    if (this.currentImageIndex > 0) {
      this.currentImageIndex--;
    } else {
      this.currentImageIndex = this.selectedPost.images.length - 1;
    }
  }
  
  isValid: boolean = false;
  // async processImagesPost() {
  //   this.loader = true;
  //   let timestamp = Date.now();
  //   let customAppName = this.customVips?.customAppName;
  //   let logo = this.customVips?.customImageUrl;
  //   let status = "pending";
  //   let contentType = this.getContentType();

  //   console.log(this.postFormGroup.value);
  //   console.log(this.backgroundImages);

  //   if (this.checkIfValid()) {
  //     this.store.collection("CustomVipsCommunity")
  //       .doc(this.userId).set({
  //           timestamp: Date.now()
  //         }, {
  //         merge: true,
  //     });
  //     // Update form controls with values
  //     this.postFormGroup.patchValue({
  //         approvalTimestamp: "",
  //         timestamp: timestamp,
  //         customAppName: customAppName,
  //         logo: logo,
  //         status: status,
  //         contentType: contentType
  //     });

  //     // Save post data to main collection
  //     try {
  //       const postRef = await this.store
  //             .collection("CustomVipsCommunity")
  //             .doc(this.userId)
  //             .collection("Posts")
  //             .add(this.postFormGroup.value);

  //       // Iterate over backgroundImages and add them to another collection
  //       for (const image of this.backgroundImages) {
  //           const imageUrl = await this.uploadCustomImage(image.url);
  //           await this.store
  //             .collection("CustomVipsCommunity")
  //             .doc(this.userId)
  //             .collection("Posts")
  //             .doc(postRef.id)
  //             .collection("images")
  //             .add({ url: imageUrl });
  //       }

  //       //this.getCommunity();
  //       this.openCreateImageRef.close();
  //       this.loader = false;
  //     } catch (error) {
  //         console.error("Error adding document: ", error);
  //         this.loader = false;
  //     }
  //   } else {
  //       // Handle invalid data
  //       console.log("Invalid data");
  //       this.loader = false;
  //   }
  // }
  
  // async processImagesPost(){
  //   let timestamp = Date.now();
  //   let customAppName = this.customVips?.customAppName;
  //   let logo = this.customVips?.customImageUrl;
  //   let status = "pending";
  //   let contentType = this.getContentType();

  //   console.log(this.postFormGroup.value);
  //   console.log(this.backgroundImages);

  //   if (this.checkIfValid()) {
  //     // Update form controls with values
  //     this.postFormGroup.patchValue({
  //         timestamp: timestamp,
  //         customName: customAppName,
  //         logo: logo,
  //         status: status,
  //         contentType: contentType
  //     });

  //     // Save post data to main collection
  //     this.store
  //         .collection("CustomVipsCommunity")
  //         .doc(this.userId)
  //         .collection("Posts")
  //         .add(this.postFormGroup.value)
  //         .then((docRef) => {
  //             //console.log("Document written with ID: ", docRef.id);
  //             // Iterate over backgroundImages and add them to another collection
              
  //             this.backgroundImages.forEach((image: any) => {
  //               var imageUrl = this.uploadCustomImage(image.url);
  //                 this.store
  //                     .collection("CustomVipsCommunity")
  //                     .doc(this.userId)
  //                     .collection("Posts")
  //                     .doc(docRef.id)
  //                     .collection("images")
  //                     .add({ url: imageUrl });
  //             });
  //         })
  //         .catch((error) => {
  //             console.error("Error adding document: ", error);
  //         });
  //   } else {
  //       // Handle invalid data
  //       console.log("Invalid data");
  //   }
  // }

  getContentType(): string {
    if(this.backgroundImages.length > 0){
      return "images";
    } else {
      return "post";
    }
  }

  // uploadCustomImage(base64Img: any): string {  
  //   var image = base64Img;
  //   var base64 = image.split(',')[1];
  //   this.uploadService.uploadCustomImage(base64).subscribe((result) => {
  //     return result.response_data;
  //   }, (error) => {
  //     return "";
  //   });
  //   return "";
  // }

  async uploadCustomImage(base64Img: any): Promise<string> {  
    var image = base64Img;
    var base64 = image.split(',')[1];
    
    return new Promise<string>((resolve, reject) => {
        this.uploadService.uploadCustomImage(base64).subscribe(
            (result) => {
                resolve(result.response_data);
            },
            (error) => {
                reject(error);
            }
        );
    });
  }

  checkIfValid(): boolean {
    let title = this.postFormGroup.controls.title.value;
    let message = this.postFormGroup.controls.message.value;
    let imgCount = this.backgroundImages.length;

    if (title !== null && title !== "") {
        return true;
    } else if (message !== null && message !== "") {
        return true;
    } else if (imgCount > 0) {
        return true;
    } else {
        return false;
    }
  }

  removeImage(): void {
    if (this.backgroundImages.length > 0) {
        this.backgroundImages.splice(this.currentBGIndex, 1);
        // Update currentBGIndex if necessary
        if (this.currentBGIndex >= this.backgroundImages.length) {
            this.currentBGIndex = this.backgroundImages.length - 1;
        }
        
        if(this.backgroundImages.length == 0){
          this.backgroundImageUrl = null;
          this.currentBGIndex = 0;

          this.isValid = this.checkIfValid();
        }

        //console.log(this.backgroundImages);
    }
  }

  openCreateImageRef: NgbModalRef;
  openCreateImagePost(content: any){
    this.openCreateImageRef = this.modalServe.open(content, { centered: true, size: "md" });
  }

  openCreatevideoRef: NgbModalRef;
  openCreateVideoPost(content: any){
    this.openCreatevideoRef = this.modalServe.open(content, { centered: true, size: "md" });
  }

  backgroundImageUrl: string | ArrayBuffer | null = null;
  backgroundImages: any = [];
  currentBGIndex: any = 0;
  handleFileInput(event: any): void {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.backgroundImageUrl = e.target?.result;
        this.backgroundImages.push({ url: e.target?.result });
        this.currentBGIndex = this.backgroundImages.length - 1;
        this.isValid = this.checkIfValid();
      };
      reader.readAsDataURL(file);
    }
  }

  prevBGImage(): void {
    if (this.currentBGIndex > 0) {
      this.currentBGIndex--;
    } else {
      this.currentBGIndex = this.backgroundImages.length - 1;
    }
  }
  
  nextBGImage(): void {
    if (this.currentBGIndex < this.backgroundImages.length - 1) {
      this.currentBGIndex++;
    } else {
      this.currentBGIndex = 0;
    }
  }
  
  triggerFileInput() {
    document.getElementById('file').click();
  }

  selectedConfirmPost: any;
  openConfirmation(content, data){
    this.selectedConfirmPost = data;
    
    this.modalServe.open(content, { centered: true, size: "sm" });
  }

  remarksStr: any;
  async reject(data): Promise<void> {
    this.loader = true;
    try {
      await this.store.collection("CustomVipsCommunity")
        .doc(data.data.communityId)
        .collection("Posts")
        .doc(data.id)
        .set({
          status: "rejected",
          remarks: this.remarksStr
        }, {
          merge: true,
        });
  
      var json = {
        communityId: this.userId,
        remarks: this.remarksStr
      };
  
      // Wait for the API call to complete
      this.usersService.sendCommunityRejected(json).toPromise();
  
      // Update the communities list
      await this.getPendingPosts();
      //await this.getRejectedPosts();
      
      this.remarksStr = "";
      // Dismiss the modal
      this.modalServe.dismissAll();

      this.loader = false;
      PNotify.success({
        title: "Success",
        text: "Post Rejected",
      });
      
    } catch (error) {
      console.error("Error rejecting post:", error);
      this.loader = false;
      PNotify.error({
        title: "Error",
        text: "Missing reason for rejection",
      });
    }
  }
  

  async approve(data): Promise<void> {
    this.loader = true;
    try {
      await this.store.collection("CustomVipsCommunity")
        .doc(data.data.communityId)
        .collection("Posts")
        .doc(data.id)
        .set({
          status: "approved",
          approvalTimestamp: Date.now()
        }, {
          merge: true,
        });
  
      var json = {
        communityId: this.userId
      };
  
      // Wait for the API call to complete
      await this.usersService.sendCommunityApproved(json).toPromise();
  
      // Update the communities list
      await this.getPendingPosts();
      await this.getApprovedPosts();
  
      // Dismiss the modal
      this.modalServe.dismissAll();
      this.loader = false;
      PNotify.success({
        title: "Success",
        text: "Post Approved",
      });
    } catch (error) {
      console.error("Error approving post:", error);
      this.loader = false;
      PNotify.error({
        title: "Error",
        text: "Failed to approve post",
      });
    }
  }

  transformtext(value: string): any {
    const replacedValue = value.replace(/\\n/g, '<br/>');
    return this.sanitizer.bypassSecurityTrustHtml(replacedValue);
  }
}
