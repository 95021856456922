import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { CryptoService } from 'src/data/crypto/crypto.service';
import { Data } from 'src/data/data';

@Component({
  selector: 'app-vips-merchant-store-application',
  templateUrl: './vips-merchant-store-application.component.html',
  styleUrls: ['./vips-merchant-store-application.component.less']
})
export class VipsMerchantStoreApplicationComponent implements OnInit {

  public merchantList = [];
  public operatingHours = [];

  constructor(
    private store: AngularFirestore,
    private router: Router,
    private data: Data,
    private cryptoService: CryptoService
    ) { }

  ngOnInit(): void {
    this.store.collection('business_registration').valueChanges().subscribe((val: any) => {
      this.merchantList = val.filter(x => x.application_store_status == 0);
      //this.merchantList = val;
    });

    // this.store.collection('business_registration').doc().collection('operating_hours').valueChanges().subscribe((val: any) => {
    //   debugger
    //   this.operatingHours = val;
    // });
  }

  viewDetails(mobileNo: any){
    debugger
    const encId = this.cryptoService.set(this.data.authentication.privateKey, mobileNo);
    this.router.navigate(["acm", "vips-store-products", { id: encId }]);
  }

  async getDocID(merchant_id: any){
    var mobileNo;
    await this.store.firestore.collection("business_registration").where("merchant_id", "==", merchant_id)
    .get()
    .then(function(querySnapshot) {
      console.log(querySnapshot);
        querySnapshot.forEach(function(doc) {
          console.log(doc.id, " => ", doc.data());
          mobileNo = doc.id;
        });
    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });

    this.viewDetails(mobileNo);
  }

}
