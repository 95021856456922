import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { environment } from "src/environments/environment";
import { FormGroup } from "@angular/forms";
import { identity } from "lodash";
import { DatePipe } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class SalesUserService {
  private localStorageCacheService: LocalStorageCacheService;

  constructor(
    private httpClient: HttpClient,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService,
    private datepipe : DatePipe
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
  }
  private httpClientNoInterceptor: HttpClient;

  private baseAPIUrl = environment.API_URL;

  public createDraftSalesUserInitial(salesUserMeta: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/DraftSalesUser`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");
    const httpHeaders: HttpHeaders = new HttpHeaders().append("Authorization", "Bearer " + accessToken);

    return this.httpClient.post(endPointUrl, salesUserMeta, { headers: httpHeaders });
  }

  public createSalesUserInitial(salesUserMeta: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/CreateInitial`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");
    const httpHeaders: HttpHeaders = new HttpHeaders().append("Authorization", "Bearer " + accessToken);
    return this.httpClient.post(endPointUrl, salesUserMeta, { headers: httpHeaders });
  }


  public getDownlines(id): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/Community/${id}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getDetails(id): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/Details/${id}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

      return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }
  

  public editSalesUser(SalesUserMeta: FormGroup, id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/SalesUser/${id}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClientNoInterceptor.put(endPointUrl, SalesUserMeta, { headers: httpHeaders });
  }

  // public resetSalesUser(SalesUserMeta: FormGroup, id: any): Observable<any> {
  //   const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/ResetSalesUser/${id}`;
  //   const accessToken = this.localStorageCacheService.getStorage("access_token");

  //   const httpHeaders: HttpHeaders = new HttpHeaders()
  //     .append("Authorization", "Bearer " + accessToken)
  //     .append("Accept", "application/json");

  //   return this.httpClientNoInterceptor.put(endPointUrl, SalesUserMeta, { headers: httpHeaders });
  // }

  public getSalesUser(id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/${id}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }
  
  public getSalesUserById(id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/SalesUserById/${id}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getSalesUserActivation(id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/SalesUserActivation/${id}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  // public getSalesUserv2(SalesUserId: any): Observable<any> {
  //   const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/v2/${SalesUserId}`;
  //   const accessToken = this.localStorageCacheService.getStorage("access_token");

  //   const httpHeaders: HttpHeaders = new HttpHeaders()
  //     .append("Authorization", "Bearer " + accessToken)
  //     .append("Content-Type", "application/x-www-form-urlencoded");

  //   return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  // }

  public getSalesUserAll(pageNumber: any, pageSize: any, searchString: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/SalesUserAll/${pageNumber}/${pageSize}/${searchString}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getSalesUserUpline(id: any, pageNumber: any, pageSize: any, searchString: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/SalesUserUpline/${id}/${pageNumber}/${pageSize}/${searchString}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getSalesUserLeft(id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/SalesUserLeft/${id}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getSalesUserRight(id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/SalesUserRight/${id}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getSalesAgentUsers(url: any): Observable<any> {
    let endPointUrl: any;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    endPointUrl = `${url}`;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getSalesExecutiveUsers(url: any): Observable<any> {
    let endPointUrl: any;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    endPointUrl = `${url}`;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getSalesDirectorUsers(url: any): Observable<any> {
    let endPointUrl: any;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    endPointUrl = `${url}`;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getExecutiveDirectorUsers(url: any): Observable<any> {
    let endPointUrl: any;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    endPointUrl = `${url}`;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public createVIPCOTransfer(walletFormGroup: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/SendVIPCO`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.post(endPointUrl, walletFormGroup, { headers: httpHeaders });
  }

  public verifySalesUserEmail(loginForm: any, email: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/VerifySalesEmail`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      //var json = { "email" : email }
      loginForm.Email = email;
    
    return this.httpClient.post(endPointUrl, loginForm, { headers: httpHeaders });
  }

  public verifySalesUserNumber(token: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/VerifySalesNumber`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      //var json = { "email" : email }
      var json = { Token : token };
    
    return this.httpClient.post(endPointUrl, json, { headers: httpHeaders });
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  // tslint:disable-next-line: typedef
  private log(message: string) {
    //
  }
}
