<div class="vip">
  <div class="vip__modal">
    <div class="vip__modal-header">
      <span class="vip__modal-header--title">{{ meta.title }}</span>
    </div>
    <div class="vip__modal-body">
      <p>{{ meta.message }}</p>
    </div>
    <div class="vip__modal-footer">
      <button class="btn btn-info" aria-label="Close" (click)="onConfirm(0)">
        {{meta.btnTitle}}
      </button>
      <button *ngIf="meta.showCancel" class="btn btn-outline-secondary" aria-label="Close" (click)="onCancel()">
        Cancel
      </button>
    </div>
  </div>
</div>
