<app-loader *ngIf="loader"></app-loader>
<div class="button-container p-2 d-flex justify-content-end align-items-center">
    <button class="btn btn-danger btn-sm" style="width:200px" (click)="printDiv()">Print &nbsp; <i class="fa fa-print"></i></button>
</div>
<div class="a4-container" #myDiv>
    <div class="content">
                
        <div class="background mt-3"></div>
            <div class="row mb-3">
                <div class="col-8 m-0 p-0" style="background-color: #4B727C;">
                    <div class="flex m-0 p-0" style="background-color: #76A06A; height: 8px"></div>
                    <h1 class="text-white m-0 p-0">Sales Invoice</h1> 
                </div>
                <div class="col-4 d-flex justify-content-center align-items-center">
                    <img src="../../../../../assets/images/logo/GoVIP Logo.png" width="120px" alt="">
                </div>

                <div class="col-7">
                    <div class="mt-0 p-2">
                        <div class="d-flex">
                            <div>
                                <h6 class="text-muted font-weight-bolder">Shipped From</h6>
                            </div>
                        </div>
                        <div>
                            <h6 class="mb-0 font-weight-bolder" style="text-align: left;">GoVIPCenter Lemon-Branch</h6>
                            <p class="mb-0" style="text-align: left;">0000-000-000</p>
                            <p class="text-muted m-0" style="text-align: left;">Lemon Square Building 1st Floor, Muñoz, EDSA, Quezon City</p>
                        </div>
                    </div>
                </div>
                <div class="col-5">
                    <div class="row">
                        <div class="col-2"></div>
                        <div class="col-10 mt-4 d-flex justify-content-center align-items-center">
                            <h4>PO no: <span style="color: #1B3D90; font-weight: bolder;">{{ purchaseOrder.pono }}</span> </h4>
                        </div>
                        <div class="col-12 mb-2 ml-0 mr-0 p-0" style="background-color: #4B727C;">
                            <div class="flex m-0 p-0" style="background-color: #76A06A; height: 8px"></div>
                            <div class="pl-3">
                                <h5 class="text-white m-0 p-0">Order Details</h5>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <table>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Description</th>
                        <th style="text-align: right;">Quantity</th>
                        <th style="text-align: right;">Unit Price</th>
                        <th style="text-align: right;">Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngFor="let item of purchaseOrder.purchaseOrderItems"
                    >
                        <td>
                            {{ item.storeItem.itemCode }}
                        </td>
                        <td>
                            {{ item.storeItem.name }}
                        </td>
                        <td style="text-align: right;">
                            {{ item.orderQuantity }}
                        </td>
                        <td style="text-align: right;">
                            {{ makeMoney(item.storeItem.price) }}
                        </td>
                        <td style="text-align: right;">
                            {{ makeMoney(item.orderQuantity * item.storeItem.price) }}
                        </td>
                    </tr>

                </tbody>
            </table>
        <div class="mt-2">
            <div class="row">
                <div class="col-6 pr-0">
                    <div class="mt-0 p-2">
                        <div class="d-flex">
                            <div>
                                <h5 class="text-muted font-weight-bolder">Delivery Address</h5>
                            </div>
                        </div>
                        <div>
                            <h6 class="mb-0 font-weight-bolder" style="text-align: left;">{{ purchaseOrder.name }}</h6>
                            <p class="mb-0" style="text-align: left;">{{ purchaseOrder.contact }}</p>
                            <p class="text-muted" style="text-align: left;">{{ purchaseOrder.address }}</p>
                        </div>
                    </div>
                </div>
        
                <div class="col-6">
                    <div class="mt-0 p-2">
                        <h5 class="text-muted font-weight-bolder">Total Summary</h5>
                        <div class="d-flex align-items-center">
                            <div class="font-weight-bold">Payment Method</div>
                            <div class="flex">
        
                            </div>
                            <span class="mr-2">
                                <span class="text-muted font-weight-bold color-red">
                                    {{ purchaseOrder.paymentMethod == 1 ? "V-Wallet" : "Vips"}}
                                </span>
                            </span>
                        </div>
                        <div class="d-flex align-items-center">
                            <div class="font-weight-bold">Shipping Fee</div>
                            <div class="flex">
        
                            </div>
                            <span class="mr-2">
                                <span class="text-muted font-weight-bold color-red">(Not Included)</span>
                            </span>
                        </div>
                        <hr class="mt-0">
                        <div class="d-flex align-items-center">
                            <div class="font-weight-bold">Total Payment</div>
                            <div class="flex">
        
                            </div>
                            <span class="font-weight-bold mr-2">
                                <span class="color-red">₱ {{ makeMoney(purchaseOrder.totalAmount) }}</span>
                            </span>
                        </div>
                    </div>
                    
                </div>
            </div>        
        </div>

    </div>
    <div class="w-100 mt-0 ml-0 mr-0 p-0" style="background-color: #4B727C;">
        <div class="flex m-0 p-0" style="background-color: #76A06A; height: 8px"></div>
        <div class="pl-3 d-flex justify-content-center">
            <h6 class="text-white m-0 p-0">Thank you for your Purchase. Have a nice Day.</h6>
        </div>
    </div>
</div>