<div class="">
    <div class="row p-3" >
        <div class="col-sm-3 d-flex justify-content-center align-items-center">
            <img src="https://i.ibb.co/5xRpXTj/SC-GO.jpg" alt="SC-GO" height="140" width="140" style="border-radius: 10%;">
        </div>
    </div>

    <div class="pr-5 pl-5 pb-3">
        <table style="width: 100%;" border="1px solid white">
            <tr >
                <th colspan="2" style="text-align: center; color: white; background-color: black;">Insurance Transaction Form</th>
            </tr>
            <tr>
                <th colspan="2" style="text-align: center; color: white; background-color:dimgray;">Member Information</th>
            </tr>
            <tr>
                <td style=width:30%>Name:</td>
                <td>{{data.customerName}}</td>
            </tr>
            <tr>
                <td>Birthday:</td>
                <td>{{data.birthDate | date: "MM/dd/yyyy"}}</td>
            </tr>
            <tr>
                <td>Beneficiary Name:</td>
                <td>{{data.beneficiary}}</td>
            </tr>
            <tr>
                <td>Residence:</td>
                <td>{{data.residence}}</td>
            </tr>
            <tr>
                <td>Contact Number:</td>
                <td>{{data.contactNumber}}</td>
            </tr>
            <tr>
                <td>Transaction Code:</td>
                <td>{{requestId}}</td>
            </tr>
        </table>
    </div>


    <div class="pr-5 pl-5 pb-3">
        <table style="width: 100%;">
            <tr>
                <th colspan="2" style="text-align: center; color: white; background-color: black;">Declaration and Representation</th>
            </tr>
        </table>
    </div>

    <div class="pr-5 pl-5 pb-3">
        <p class="font-weight-bolder" style="color: black;">By signing below: I declare, understand and agree that:</p>
        <ol>
            <li>
                I am at least 18 but not more than 65 years old;
            </li>
            <li>
                I am physically and mentally healthy and actively performing my normal daily activities;
            </li>
            <li>
                If further required by insurance company for product covered, I never had any congenital 
                birth disease, heart disease, high blood pressure, lung, Kidney ailment, Tumor, mass or 
                cancer or any other physical impairment; have not undergone operations or hospitalized, 
                nor been treated for any illness in the past twelve (12) consecutive months;
            </li>
            <li>
                I hereby authorize Go VIP Center (ACM Business Solutions Inc) and insurance company 
                of the selected product above, to process my personal and sensitive information and 
                to disclose such confidential, personal and sensitive information, including relevant 
                information on my account, age, gender and health, and to their affiliated third persons, 
                entity or entities providing services on their behalf, to enable them to evaluate and 
                asses my enrolment as well as to service my policy or policies and other insurance needs, 
                and pursue their legitimate and lawful rights and interests and other purposes allowed 
                under laws and regulations, including, but not limited to, those relating to data privacy 
                and anti-money laundering. I understand that my personal data shall be retained throughout 
                the existence of the policy and/or expiration until the expiration of the retention limit 
                by laws and regulations.
            </li>
            <li>
                I declare that all information indicated above is true and correct. 
            </li>
        </ol>

        <br>
        <p>Date: <strong>{{data.createdDate | date: "MM/dd/yyyy"}}</strong></p>
    </div>
    
    <hr style="width:90%; margin-left:5% !important; margin-right:5% !important; border: 1px solid black;" />

 
    <div class="row pl-3" >
        <div class="col-sm-3 d-flex justify-content-center align-items-center">
            <img src="https://i.ibb.co/p11rQ3k/Sun-Life-Grepa-thin.png" alt="SC-GO"  width="210" style="border-radius: 10%;">
        </div>
        <div class="col-sm-9">
            <div class="col-sm-12 align-items-center mt-5 center">
                <!-- <h3><span class="text-danger">Go VIP Family Accident Care</span></h3> -->
                <h4>Proof of Cover</h4>
            </div>
            <hr class="mb-1 mt-0" style="border:5px solid rgb(4,60,116)">
            <hr class="mt-0" style="border:5px solid rgb(252,188,20)">
        </div>
    </div>

    <div class="pl-5 pr-5 pb-3">
        <!-- <table style="width: 100%;" border="1px solid white" class="w-100">
            <tr>
                <td>
                    <strong>Group Policy Number:</strong>
                </td>
                <td>
                    {{data.groupPolicyNumber}}
                </td>
            </tr>
            <tr>
                <td>
                    <strong>Group Policy Effective Date:</strong>
                </td>
                <td>
                    08/27/2021
                </td>
            </tr>
            <tr>
                <td>
                    <strong>Policyholder Name:</strong>
                </td>
                <td>
                    ACM Business Solutions, Inc.
                </td>
            </tr>
            <tr>
                <td>
                    <strong>Amount of Insurance:</strong>
                </td>
                <td>
                    {{data.amountOfInsurance}}
                </td>
            </tr>
            <tr>
                <td>
                    <strong>Member’s Effective Date of Insurance:</strong>
                </td>
                <td>
                    {{data.createdDate | date: "MM/dd/yyyy"}}
                </td>
            </tr>
            <tr>
                <td>
                    <strong>Member’s Name:</strong>
                </td>
                <td>
                    {{data.customerName}}
                </td>
            </tr>
            <tr>
                <td>
                    <strong>Premium:</strong>
                </td>
                <td>
                    {{data.amountToBePaidByCustomer | number: "1.2-2"}}
                </td>
            </tr> 
            <tr>
                <td colspan=2>
                    <strong>Plan of Insurance:</strong>
                </td>
            </tr>
            <tr>
                <td colspan=2>
                    <ol>
                        <li>GROUP 1 Month Standard Personal Accident;</li>
                        <li>Accidental Death, Dismemberment and Loss of Sight;</li>
                        <li>NON-Participating</li>
                        <li>Coverage for one (1) month is effective on the date completed Enrollment Form is submitted to the Policyholder.</li>
                    </ol>
                </td>
            </tr>
        </table> -->

        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 row">
                <div class="col-sm-6 col-md-6 col-lg-6">
                    <strong>Group Policy Number:</strong>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                    G-2021/071-02 {{data.groupPolicyNumber}}
                </div>

                <div class="col-sm-6 col-md-6 col-lg-6">
                    <strong>Group Policy Effective Date:</strong>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                    08/27/2021
                </div>

                <div class="col-sm-6 col-md-6 col-lg-6">
                    <strong>Policyholder Name:</strong>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                    ACM Business Solutions, Inc.
                </div>

                <div class="col-sm-6 col-md-6 col-lg-6">
                    <strong>Amount of Insurance:</strong>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                    {{data.amountOfInsurance}}
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 row">
                <div class="col-sm-6 col-md-6 col-lg-6">
                    <strong>Member’s Effective Date of Insurance:</strong>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                    {{data.createdDate | date: "MM/dd/yyyy"}}
                </div>

                <div class="col-sm-6 col-md-6 col-lg-6">
                    <strong>Member’s Name:</strong>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                    {{data.customerName}}
                </div>

                <div class="col-sm-6 col-md-6 col-lg-6">
                    <strong>Premium:</strong>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6">
                    {{data.amountToBePaidByCustomer | number: "1.2-2"}}
                </div>
            </div>
            <div class="col-sm-12">
                <strong>Plan of Insurance:</strong>
                <ol>
                    <li>GROUP 1 Month Standard Personal Accident;</li>
                    <li>Accidental Death, Dismemberment and Loss of Sight;</li>
                    <li>NON-Participating</li>
                    <li>Coverage for one (1) month is effective on the date completed Enrollment Form is submitted to the Policyholder.</li>
                </ol>
            </div>
        </div>

      <br/>
       <p>
            The amount insurance is subject to terms, provisions and conditions set forth in the Group Personal Accident Policy issued to the Policyholder. 
            The maximum coverage on any one life under the Policy is limited to Php 100,000.00 only and Sun Life Grepa Financial shall not be liable for claims in excess of the maximum amount stated herein.
        </p>
    </div>
  
    <div class="row pr-5 pl-5 pb-3">
        <table style="width: 100%;">
            <tr>
                <th colspan="2" style="text-align: center; color: white; background-color: rgb(79, 79, 79);">Extract of Policy Provisions</th>
            </tr>
        </table>

        <div class="col-sm-6 mt-2">
            <p class="font-weight-bolder" style="color: black;">Eligibility</p>
            <p style="color: black;">To be eligible for insurance coverage, an Individual must meet all of the following requirements:</p>
            <ol>
                <li>he is a Filipino and a permanent resident of the Philippines;</li>
                <li>he is at least eighteen (18) years old but not more than sixty-five(65) years of age at the time of enrollment;</li>
                <li>is Actively-At-Work; and</li>
                <li>not involved in military, naval or air service.</li>
            </ol>


            <p class="font-weight-bolder" style="color: black;">Accidental Death, Dismemberment and Loss of Sight</p>
            <p>
                If Sun Life Grepa Financial receives Notice and Proof of Claim that a Member while insured 
                sustains accidental bodily injury evidenced by a visible contusion or wound except in the case of drowning or internal injury revealed by a medical examination or an autopsy, 
            </p>

            <ol type="a">
                <li>resulting directly and independently of all other causes in any of the losses listed below, and </li>
                <li>such loss(es) occurs within one hundred eighty (180) days from the date of the accident; </li>
            </ol>

            <p>
                Sun Life Grepa Financial shall pay to such Member, if living, or to his beneficiary, subject to the Exclusions, the corresponding percentage 
                of the Accidental Death, Dismemberment and Loss of Sight Insurance in force for such loss on such date: 
            </p>

            <table class="w-100" border="1px solid white" >
                <tr>
                    <th>Accidental Death or Loss of Life</th>
                    <td style="text-align: center;">100%</td>
                </tr>
                <tr>
                    <th>Dismemberment and Loss of Sight Loss of entire sight of <br> both eyes</th>
                    <td style="text-align: center;">100%</td>
                </tr>
                <tr>
                    <td>&nbsp;Loss of both hands or both feet</td>
                    <td style="text-align: center;">100%</td>
                </tr>
                <tr>
                    <td>&nbsp;Loss of one hand and one foot</td>
                    <td style="text-align: center;">100%</td>
                </tr>
                <tr>
                    <td>&nbsp;Loss of either hand or foot and sight of one eye</td>
                    <td style="text-align: center;">100%</td>
                </tr>
                <tr>
                    <td>&nbsp;Loss of either hand or foot or sight of one eye</td>
                    <td style="text-align: center;">50%</td>
                </tr>
            </table>
            
            <br>
            <p>“LOSS” as used with reference to hand or foot shall mean complete severance at or above the wrist or ankle respectively and as used with reference to eyes means total and irrecoverable loss of sight. </p>

            <p class="font-weight-bolder" style="color: black;">Cooling Off Period </p>
            <p>
                A Member shall have the right to examine and cancel the proof of cover within a period of fifteen (15) days from receipt of the proof of cover. 
                A Member may call Sun Life Grepa Financial Client Care at its official hotline number to cancel his insurance coverage. 
                Upon Sun Life Grepa Financial’s receipt of cancellation request, the premium charged will then be refunded to the Policyholder’s account. 
                All of Sun Life Grepa Financial’s obligations and liabilities under this insurance coverage will end immediately upon receipt of request of cancellation.
            </p>
            
            <br>
            <p>
                The proof of cover is considered to have been read by the Member 3 days after the proof of cover has been sent to the member.
            </p>
            
        </div>

        <div class="col-sm-6 mt-2">
            <p class="font-weight-bolder" style="color: black;">
                Right to Designate and Change Beneficiary
            </p>
            <p>
                A Member may designate a beneficiary or beneficiaries. The designation of beneficiary is revocable unless otherwise stated by the Member.
            </p>
            <br>
            <p>
                The Member may, at any time, change a revocable beneficiary by filing a written notice with Sun Life Grepa Financial on a form provided by 
                Sun Life Grepa Financial or by calling Sun Life Grepa Financial Client Care at its official hotline number. 
                The change shall then be effective as of the date the form was signed or on the date the call was received, 
                but it shall be subject to any action taken before it was received by Sun Life Grepa Financial. 
                If Sun Life Grepa Financial has taken any action or made payment prior to receiving notice of that change, 
                the change of beneficiary will not affect any action or payment made by Sun Life Grepa Financial.
            </p>

            <p class="font-weight-bolder" style="color: black;">Notice and Proof of Claim </p>
            <p>
                Written notice of claim must be submitted to Sun Life Grepa Financial within thirty (30) days from date of death. 
                Such notice given by the beneficiary to Sun Life Grepa Financial, with information sufficient to identify the Member shall be deemed to be notice to Sun Life Grepa Financial. 
                Proof of claim must be submitted not later than ninety (90) days from date of death. 
                All certificates, information and evidence required by Sun Life Grepa Financial shall be furnished at the expense of the Member’s beneficiary. 
                Failure to submit the written notice and proof of claim within the time limits shall not invalidate or reduce any claim if it shall be shown not to have been reasonably possible and was submitted as soon as was reasonably possible. 
            </p>

            <p class="font-weight-bolder" style="color: black;">Availability of Master Policy </p>
            <p>
                The Group Master Policy shall be kept in the Policyholder’s main office. 
                It will be made available to the Members for their inspection during the regular office hours of the Policyholder, upon presentation of proof of coverage satisfactory to the Policyholder 
            </p>

            <p class="font-weight-bolder" style="color: black;">DATA PRIVACY AND AUTHORIZATION  </p>
            <p>
                By availing of an insurance coverage under this Policy, the Member acknowledges that Sun Life Grepa Financial shall process your personal data to: 
                a) evaluate your application and administer your account; 
                b) process transactions and enforce/fulfill contractual rights/obligations; 
                c) improve the provision of products and services (including improvement in systems and business processes, data analytics, automated processing, etc.); 
                d) comply with legal obligations, as well as laws and regulations (domestic or foreign); and 
                e) manage risks and pursue its legitimate interests, including verification and obtaining additional personal data from third party sources. 
                Sun Life Grepa Financial may disclose your personal data to its affiliates, service providers, and other third parties for processing consistent with the foregoing purposes, 
                who shall be bound by contractual or other reasonable means to protect your personal data. 
                Your personal data shall be retained for the duration of your coverage under your plan or existence of your account(s) and/or upon the later of the expiration of the retention limit set by Company standards, 
                laws and regulations, counted from account closure. You certify that you understand and agree with the declarations and authorizations above and the 
                Sun Life Grepa Financial’s privacy policy at  <a href="https://www.sunlifegrepa.com/LegalPrivacySecurity.aspx">Sunlife Legal Privacy Security</a>
            </p>
        </div>
    </div>

    <hr>

    <div class="pl-5 pr-5">
        <p style="text-align: center;">
            This document describes in general the insurance protection under the Policy. The full details are in the Policy Contract and in the event of discrepancy or dispute, the provisions of the Group Personal Accident Policy shall prevail. <br> <br>
            Issued by Sun Life Grepa Financial, Inc. (“Sun Life Grepa Financial”) <br>
            A joint venture of Sun Life and the Yuchengco Group of Companies <br>
            221 Sen. Gil J. Puyat Ave., P.O. Box 2042 MCPO Makati City <br>
            TIN 000-460-716-000
        </p>    
    </div>



    <div class="pl-5 pr-5 pb-3"></div>
    <div class="pl-5 pr-5 pb-3"></div>

</div>