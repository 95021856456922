<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-content page-container" id="page-content">
      <div class="" style="padding-right: 0px">
        <div class="card mb-2 mt-0" style="box-shadow: 0 2px 4px 0 #ee4d2d, 0 2px 5px 0 #ee4d2d">
          <div class="row" [formGroup]="searchByFormGroup">
            <table id="datatable" class="table" role="grid" aria-describedby="datatable_info">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th class="search">
                    <button
                      class="btn btn-sm btn-primary button--search font-weight-bold"
                      style="float: right"
                      (click)="searchData()"
                    >
                      Search &nbsp; <i class="fa fa-filter text-highlight"></i>
                    </button>

                    <button
                      class="btn btn-sm btn-success mr-2 button--search font-weight-bold"
                      style="float: right"
                      (click)="exportToExcel()"
                    >
                      Export to Excel &nbsp; <i class="fa fa-file-excel text-highlight"></i>
                    </button>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor: pointer" class="odd" role="row">
                  <td class="td-title">
                    <label class="label-filter" style="color: #ee4d2d; font-weight: bolder">Set No. of Rows</label>
                  </td>
                  <td class="td-value">
                    <ng-select
                      [items]="showRowsOption"
                      placeholder="No of Rows"
                      bindLabel="text"
                      bindValue="id"
                      formControlName="showRows"
                    >
                    </ng-select>
                  </td>

                  <td class="td-title">
                    <label class="label-filter" style="color: #ee4d2d; font-weight: bolder">Rider Reference ID</label>
                  </td>
                  <td class="td-value">
                    <input
                      type="text"
                      placeholder="Rider Reference ID"
                      class="form-control w-100"
                      formControlName="groupPolicyNumber"
                      style="background-color: rgb(255, 255, 255); color: black; height: 36px; border-color: #ee4d2d"
                    />
                  </td>
                </tr>

                <tr style="cursor: pointer" class="odd" role="row">
                  <td class="td-title">
                    <label class="label-filter" style="color: #ee4d2d; font-weight: bolder">Rider Name</label>
                  </td>
                  <td class="td-value">
                    <input
                      type="text"
                      placeholder="Rider Name"
                      class="form-control w-100"
                      formControlName="customerName"
                      style="background-color: rgb(255, 255, 255); color: black; height: 36px; border-color: #ee4d2d"
                    />
                  </td>

                  <td class="td-title">
                    <label class="label-filter" style="color: #ee4d2d; font-weight: bolder">Request ID</label>
                  </td>
                  <td class="td-value">
                    <input
                      type="text"
                      placeholder="Request ID"
                      class="form-control w-100"
                      formControlName="productCode"
                      style="background-color: rgb(255, 255, 255); color: black; height: 36px; border-color: #ee4d2d"
                    />
                  </td>
                </tr>

                <tr style="cursor: pointer" class="odd" role="row">
                  <td class="td-title">
                    <label class="label-filter" style="color: #ee4d2d; font-weight: bolder">Date Range</label>
                  </td>

                  <td class="td-value">
                    <input
                      type="text"
                      placeholder="Date Range"
                      class="form-control"
                      bsDaterangepicker
                      formControlName="dateRange"
                      style="background-color: white; color: black; height: 36px; border-color: #ee4d2d"
                      [bsConfig]="{
                        rangeInputFormat: 'YYYY-MM-DD',
                        dateInputFormat: 'YYYY-MM-DD',
                        showWeekNumbers: false
                      }"
                    />
                  </td>

                  <td class="td-title">
                    <label class="label-filter" style="color: #ee4d2d; font-weight: bolder"
                      >Summary Based on Query</label
                    >
                  </td>

                  <td>
                    <br />
                    <table style="margin-left: 10px; width: 96%">
                      <tr>
                        <th>Total Item</th>
                        <td class="amount-total text-dark">{{ totals?.totalItem | number: "1.2-2" }}</td>
                      </tr>
                      <!-- <tr>
                            <th>Total Withholding Tax</th>
                            <td class="amount-total text-dark">  {{ totals?.withHoldingTax | number : "1.2-3"  }}</td>
                        </tr> -->
                      <tr>
                        <th>Total Net SPX Remittance</th>
                        <td class="amount-total text-dark">{{ totals?.totalAmount | number: "1.2-2" }}</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card">
          <table
            id="table"
            class="table table-striped table-hover"
            data-plugin="bootstrapTable"
            data-toolbar="#toolbar"
            data-search="true"
            data-search-align="left"
            data-show-export="true"
            data-show-columns="true"
            data-detail-view="false"
            data-mobile-responsive="true"
            data-pagination="true"
            data-page-list="[10, 25, 50, 100, ALL]"
          >
            <thead style="background-color: #ee4d2d">
              <tr>
                <th>&nbsp;</th>
                <th class="text-white" style="width: 350px"><span>Remittance Details</span></th>
                <th class="text-white"><span>Rider Details</span></th>
                <th class="text-white" style="width: 100px; text-align: right">
                  <span class="d-none d-sm-block">Total Amount</span>
                </th>
                <!-- <th class="text-white" style="width: 100px;text-align: right;" ><span class="d-none d-sm-block">Tax</span></th> -->
                <th class="text-white" style="width: 100px; text-align: right">
                  <span class="d-none d-sm-block">Comission Earned</span>
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>

            <tbody>
              <tr
                class="pt-1 pb-1"
                *ngFor="
                  let transaction of reportTransactions?.data
                    | paginate
                      : {
                          id: 'pagination3',
                          itemsPerPage: reportTransactions.pageSize,
                          currentPage: pp,
                          totalItems: reportTransactions.totalRecords
                        }
                "
                (click)="openSelectedTransactionDetail(viewMultisys, transaction.requestID)"
                class="odd"
                role="row"
              >
                <td></td>

                <td>
                  <table class="w-100">
                    <tr>
                      <th>Sequence</th>
                      <td style="text-align: left">{{ transaction.id }}</td>
                    </tr>
                    <tr>
                      <th>Transaction Date</th>
                      <td style="text-align: left">
                        {{ transaction?.createdDate | date: "MMM dd, yyyy hh:mm:ss aa" }}
                      </td>
                    </tr>
                    <tr>
                      <th>Request ID</th>
                      <td style="text-align: left">{{ transaction?.requestID }}</td>
                    </tr>
                    <tr>
                      <th>Service Fee Status</th>
                      <td style="vertical-align: middle" class="mt-2 pb-2">
                        <div *ngIf="transaction.settlementStatus == 3">
                          <span class="text-info" style="width: 63px">Settled To Shopee</span>
                        </div>
                        <div *ngIf="transaction.settlementStatus == 0">
                          <span class="text-warning" style="width: 63px">Pending</span>
                        </div>
                        <div *ngIf="transaction.settlementStatus == 2">
                          <span class="text-success" style="width: 63px">Settled To Outlet</span>
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>

                <td>
                  <table class="w-100">
                    <tr>
                      <th style="width: 100px">Rider Name</th>
                      <td style="text-align: left">{{ transaction?.fmsDriverName }}</td>
                    </tr>
                    <tr>
                      <th>Mobile Number</th>
                      <td style="text-align: left">{{ transaction?.mobileNo }}</td>
                    </tr>
                    <tr>
                      <th>Rider Reference ID</th>
                      <td style="text-align: left">{{ transaction?.lifetimeID }}</td>
                    </tr>
                  </table>
                </td>

                <td class="pt-1 pb-1">
                  <div class="currency-data">
                    {{ transaction?.amount | number: "1.2-2" }}
                  </div>
                </td>
                <!-- <td class="pt-1 pb-1">
                    <div class="currency-data">
                        {{ transaction?.tax | number : "1.2-3"  }}
                    </div>
                </td> -->
                <td class="pt-1 pb-1">
                  <div class="currency-data">
                    {{ transaction.settlementStatus == 2 ? (amount | number: "1.2-2") : (0 | number: "1.2-2") }}
                  </div>
                </td>

                <td></td>
              </tr>
            </tbody>
          </table>

          <div class="row">
            <div class="col-sm-12 col-md-5">
              <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                <div class="col-sm-12 col-md-12">
                  <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                    Showing {{ (reportTransactions?.pageNumber - 1) * reportTransactions?.pageSize + 1 }} to
                    {{
                      (reportTransactions?.pageNumber - 1) * reportTransactions?.pageSize +
                        reportTransactions?.data?.length
                    }}
                    of {{ reportTransactions?.totalRecords }} entries
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-7" style="float: right">
              <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                <ul class="pagination" style="float: right; margin-right: 36px">
                  <pagination-controls
                    id="pagination3"
                    (pageChange)="getTransaction(buildPageUrl($event, 10))"
                  ></pagination-controls>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #viewMultisys let-modal>
      <div class="modal-header">
        <h4 class="modal-title">[SPX_REMITTANCE] Transaction Details</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-spx-remittance-transaction (closeModal)="closeModal()" [requestId]="requestID">
        </app-spx-remittance-transaction>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Close</button>
      </div>
    </ng-template>
  </div>
</div>
