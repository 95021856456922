<app-loader *ngIf="verifyingForgotPassword"></app-loader>

<body class="layout-row">
  <div class="d-flex flex-column flex">
    <div class="row no-gutters h-100">
      <div
        class="col-md-6 bg-primary"
        style="
          background-image: url('https://juanpaycdn.azureedge.net/juanpayassets/JuanPay-Portrait1.jpg');
          background-size: cover;
          background-position: center;
        "
      >
        <div class="p-3 p-md-5 d-flex flex-column h-100">
          <!-- <h4 class="mb-3 text-white">GO VIP Center</h4>
          <div class="text-fade">The Business of the Current</div> -->
          <div class="d-flex flex align-items-center justify-content-center"></div>

          <div class="d-flex text-sm text-fade">
            <a routerLink="/about" class="text-white">About</a>
            <span class="flex"></span>
            <a routerLink="/terms-and-policies" class="text-white mx-1">Terms and Policies</a>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div id="content-body">
          <div class="p-3 p-md-5">
            <div>
              <h5>Forgot your password?</h5>
              <p class="text-muted my-3">
                Enter your email below and we will send you instructions on how to change your password.
              </p>
            </div>
            <div *ngIf="verifyForgotPasswordStatus == false">
              <div [formGroup]="userFormGroup" name="reset" class="py-5 my-md-5">
                <div class="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    (keyup.enter)="verifyForgotPassword()"
                    formControlName="email"
                    class="form-control"
                    placeholder="Enter email"
                  />
                </div>

                <button type="button" (click)="verifyForgotPassword()" class="btn btn-primary btn-block">Send</button>
              </div>
            </div>

            <div *ngIf="verifyForgotPasswordStatus == true" class="alert alert-success" role="alert">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-check"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
              <span class="mx-2">Please check your email to reset your password. </span>
            </div>

            <div class="pt-1">
              Return to
              <a href="login" class="text-primary font-weight-bold">Sign in</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- build:js ../assets/js/site.min.js -->
  <!-- jQuery -->
  <script src="../libs/jquery/dist/jquery.min.js"></script>
  <!-- Bootstrap -->
  <script src="../libs/popper.js/dist/umd/popper.min.js"></script>
  <script src="../libs/bootstrap/dist/js/bootstrap.min.js"></script>
  <!-- ajax page -->
  <script src="../libs/pjax/pjax.min.js"></script>
  <script src="../assets/js/ajax.js"></script>
  <!-- lazyload plugin -->
  <script src="../assets/js/lazyload.config.js"></script>
  <script src="../assets/js/lazyload.js"></script>
  <script src="../assets/js/plugin.js"></script>
  <!-- scrollreveal -->
  <script src="../libs/scrollreveal/dist/scrollreveal.min.js"></script>
  <!-- feathericon -->
  <script src="../libs/feather-icons/dist/feather.min.js"></script>
  <script src="../assets/js/plugins/feathericon.js"></script>
  <!-- theme -->
  <script src="../assets/js/theme.js"></script>
  <script src="../assets/js/utils.js"></script>
  <!-- endbuild -->
</body>

<!-- 
<div *ngIf="!verifyingLogin"  class="vip">
<div class="vip__bg-dark">
</div>
<div class="container">
  <div class="vip__login">
    <div class="row align-items-center">
      <div class="col-md-6 d-flex flex-column">
        <h1 class="vip__title">go VIP Center</h1>
      </div>
      <div class="col-md-6 d-flex flex-column">
        <div class="vip__login-form">
          <h2 class="vip__login-form--title">Sign In</h2>
          <div class="vip__login-content">
  
            <app-textbox 
            [parent-form-group]="userFormGroup"
            tab-index-start="0"
            control-name="email" 
            [error]=""
            error-message="Email is required"
            label="Email" 
            placeholder="Email">
            </app-textbox>
    
            <app-password 
            [parent-form-group]="userFormGroup"
            tab-index-start="0"
            control-name="password" 
            [error]=""
            error-message="Password is required"
            label="Password" 
            placeholder="Password">
            </app-password> 
  
          </div>
  
          <div class="vip__login-content">
            <button (click)="verifyLogin()" type="button" class="btn btn-dark btn-block rounded-0 my-4">Sign In</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div> -->

<!-- <app-loader *ngIf="loader"></app-loader> -->
