import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-govip-privacy-policy',
  templateUrl: './govip-privacy-policy.component.html',
  styleUrls: ['./govip-privacy-policy.component.less']
})
export class GovipPrivacyPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
