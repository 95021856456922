import { Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";

@Component({
  selector: 'app-datepicker-wo-label',
  templateUrl: './datepicker-wo-label.component.html',
  styleUrls: ['./datepicker-wo-label.component.less']
})
export class DatepickerWoLabelComponent implements OnInit {
  dpConfig: Partial<BsDatepickerConfig>;

  @Input("parent-form-group") parentFormGroup: FormGroup;
  @Input("control-name") controlName: string;
  @Input("label") label: string;
  @Input("placeholder") placeholder: string;
  @Input("upper-case") upperCase: boolean;
  @Input("no-special-char") noSpecialChar: boolean;
  @Input("max-length") maxLength: number;
  @Input("tab-index-start") tabIndexStart: number;

  @Input("error") error: boolean;
  @Input("error-message") errorMessage: string;

  constructor() {}

  ngOnInit(): void {
    this.dpConfig = new BsDatepickerConfig();
    this.dpConfig.containerClass = "theme-dark-blue";
    this.dpConfig.dateInputFormat = "YYYY-MM-DD";
    this.dpConfig.isAnimated = true;
  }
}
