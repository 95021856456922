<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex">
  <!-- ############ Main START-->
  <div class="pl-3 pr-3">
    <div id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="--spam">JuanPay Store | <span class="color-blue fonr-weight-bold">Shopping Cart</span></h2>
          <!-- <small class="--desc">GoVIPCenter list of Stock Item</small> -->
        </div>
        <div class="flex"></div>
      </div>
    </div>
    <div id="page-content">
      <div class="pl-3 pr-3 pt-1" style="background-color: rgb(247, 247, 247)">
        <div class="card p-2">
          <div
            class="search-container"
            style="display: flex; justify-content: space-between; align-items: center; padding-right: 10px"
          >
            <div style="display: flex; align-items: center" class="ml-3">
              <!-- <input type="text" placeholder="Search..." style="margin-right: 5px; margin-left: 5px;">
                      <button type="submit">Search</button> -->
              <p class="font-weight-bold m-0 p-0">
                Total ({{ totalQuantity }} items): <span class="color-red">₱ {{ makeMoney(totalAmount) }}</span>
              </p>
            </div>
            <div class="ml-2">
              <!-- <ng-select class="my-select" [items]="categories" bindLabel="text" bindValue="id" placeholder="Select Category" formControlName="categoryId"></ng-select> -->
            </div>
            <div class="flex"></div>
            <div class="mr-3 d-flex justify-content-center align-items-center"></div>
            <div>
              <button class="btn btn-secondary mr-2" style="width: 200px" (click)="backToStore()">Back to Store</button>
              <button class="btn button-red" style="width: 200px" (click)="checkOut()">Check Out</button>
            </div>
          </div>
        </div>

        <div>
          <div class="card p-2 container-card">
            <div class="d-flex">
              <div class="ml-5">&nbsp;</div>
              <div class="div-item ml-4 mr-5">
                <h6 class="mb-0 pb-0">Image</h6>
              </div>
              <div class="div-item ml-2">
                <div>
                  <h6 class="mb-0 pb-0">Item Description</h6>
                </div>
              </div>
              <div class="flex"></div>

              <div class="div-item mr-4 price-div">
                <div class="quantity">
                  <h6 class="mb-0 pb-0">Quantity</h6>
                </div>
              </div>

              <div class="ml-2 mr-5">&nbsp;</div>

              <div class="div-item ml-4 mr-5 price-div">
                <h6 class="mb-0 pb-0">Amount</h6>
              </div>

              <div class="ml-3 mr-3">
                <h6 class="mb-0 pb-0">Remove</h6>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="card p-2 container-card" *ngFor="let item of cartItems; let i = index">
            <div class="d-flex">
              <div class="div-item ml-3 mr-3">
                <div class="form-group form-check">
                  <input
                    type="checkbox"
                    (change)="onCheckboxChange($event, item)"
                    class="form-check-input custom-checkbox"
                  />
                </div>
              </div>
              <div class="div-item mr-3">
                <img class="item-img" [src]="item?.storeItemImages[0]?.path" />
              </div>
              <div class="div-item">
                <div>
                  <h5 class="m-0 p-0">{{ item.name }}</h5>
                  <p class="text-muted description m-0 p-0">{{ item.description }}</p>
                </div>
              </div>
              <div class="flex"></div>

              <div class="div-item mr-5"><div class="vl"></div></div>
              <div class="div-item mr-5">
                <div class="quantity">
                  <button class="minus-btn" type="button" (click)="decrement(i)">-</button>
                  <input
                    type="number"
                    class="count"
                    name="quantity"
                    [min]="1"
                    pattern="[1-9]"
                    style="width: 100px"
                    [value]="item.quantity"
                    restrict-characters
                  />
                  <button class="plus-btn" type="button" (click)="increment(i)">+</button>
                </div>
              </div>

              <div class="div-item mr-5"><div class="vl"></div></div>
              <div class="div-item mr-5 price-div">
                <span class="color-red font-weight-bolder">{{ makeMoney(item.price) }} </span>
              </div>

              <div class="div-item mr-4"><div class="vl"></div></div>
              <div class="div-item mr-3">
                <button class="btn btn-danger" (click)="removeItem(item)"><i class="fa fa-trash"></i></button>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <!-- <div class="col-6 pr-0">
                    <div class="card p-2">
                        <h5 class="text-muted font-weight-bolder">Delivery Address</h5>
                        <h6 class="mb-0 font-weight-bolder">Vincent Dolor</h6>
                        <p class="mb-0">09462374088</p>
                        <p class="text-muted">05 Kabilugan Street Gulod Novaliches Quezon City</p>
                    </div>
                </div>
                <div class="col-6">
                    <div class="card p-2">
                        <h5 class="text-muted font-weight-bolder">Total Summary</h5>
                        <div class="d-flex align-items-center">
                            <div class=" font-weight-bold">Subtotal</div>
                            <div class="flex">
        
                            </div>
                            <span>
                                ₱ {{ makeMoney(350) }}
                            </span>
                        </div>
                        <div class="d-flex align-items-center">
                            <div class=" font-weight-bold">Tax</div>
                            <div class="flex">
        
                            </div>
                            <span>
                                ₱ {{ makeMoney(0) }}
                            </span>
                        </div>
                        <hr class="mt-0">
                        <div class="d-flex align-items-center">
                            <div class=" font-weight-bold">Total</div>
                            <div class="flex">
        
                            </div>
                            <span class="font-weight-bold">
                                ₱ {{ makeMoney(350) }}
                            </span>
                        </div>
                    </div>
                    
                </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>
