import { CurrencyPipe, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { InvoiceComponent } from 'src/app/elements/invoice/invoice.component';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { PrintService } from 'src/data/services/web/print.service';
import { VipProtectService } from 'src/data/services/web/vip-protect.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { IbayadMeralcoService } from 'src/data/services/web/ibayad-meralco.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { TransactionService } from 'src/data/services/web/transaction.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5


@Component({
  selector: 'app-ibayad-meralco-transaction',
  templateUrl: './ibayad-meralco-transaction.component.html',
  styleUrls: ['./ibayad-meralco-transaction.component.less']
})
export class IbayadMeralcoTransactionComponent implements OnInit {

  @Output("closeModal") closeModal = new EventEmitter();
  @ViewChild('vipLogo') vipLogo: ElementRef;

  @Input() requestId: string;
  public data :  any;
  public loader : boolean = false;
  fileUploadFormGroup : FormGroup;
  emptyString: any = '';
  public base64Photo: string | ArrayBuffer;
  roleLevelId : any;
  public receipt: any;
  public isA4 = new FormControl();

  public transactionService: TransactionService;
  public dti: any;
  public tin: any;
  public address: any;

  constructor(private ibayadMeralcoService : IbayadMeralcoService, 
    private router: Router,
    private localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    public printService: PrintService,
    private changeDetectorRef: ChangeDetectorRef,
    private modalServe: NgbModal,
    public datepipe: DatePipe,
    private webService: WebSettingsService, 
    transactionService: TransactionService) {
    this.transactionService = transactionService;
      PNotifyButtons;
  }


  ngOnInit(): void {
    this.loader = true;
    this.getTransaction();
    this.initReceipt();
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
  }

  
  initReceipt() {
    this.webService.getWebSettingsByIdentifier("INVOICE_RECEIPT").subscribe((result) => {
      this.receipt = result.data;
    });
  }
  // getTransaction() {
  //   this.ibayadMeralcoService.getTransaction(this.requestId).subscribe((vipProtect) => {
  //      this.data = vipProtect;
  //      this.loader = false;
  //      this.initReceiptDetails(vipProtect.transactByUser.cashierUser.managerUserId);
  //   }, error => {
  //     this.loader = false;
  //   });
  // }

  getTransaction() {
    this.ibayadMeralcoService.getTransaction(this.requestId).subscribe((billers) => {
       this.data = billers;
       this.loader = false;
       this.initReceiptDetails(billers.transactByUser.cashierUser.managerUserId);
    }, error => {
      this.loader = false;
    });
  }

  initReceiptDetails(managerUserId: any){
    this.transactionService.getReceiptDetails(managerUserId).subscribe((result) => {
      this.dti = result.dti;
      this.tin = result.tin != null ? result.tin : "***-***-***-***";
      this.address = result.address;
    });
  }

  // initReceiptDetails(managerUserId: any){
  //   this.transactionService.getReceiptDetails(managerUserId).subscribe((result) => {
  //     this.dti = result.dti;
  //     this.address = result.address;
  //   });
  // }

    public padIntegerLeftWithZeros(rawInteger: number, numberOfDigits: number): any {
      let paddedInteger: string = rawInteger + '';
      while (paddedInteger.length < numberOfDigits) {
        paddedInteger = '0' + paddedInteger;
      }
      return paddedInteger;
    }
  
    

    printReceipt(){
      debugger
      var completedDate = this.data.completedDate ? this.datepipe.transform(this.data.completedDate, 'MMM-dd-yyyy') : null;
      var transTime = this.data.completedDate ? this.datepipe.transform(this.data.completedDate, 'hh:mm:ss a') : null;
      var reqId = this.data.requestId;
      var recValNo = ""; //this.data.responseJsonData ? this.data.responseJsonData.data.meta.ReceiptValidationNo : null;
      var cashier = this.data.transactByUser.cashierUser.firstName + ' ' + this.data.transactByUser.cashierUser.lastName;
      var billerName = this.data.billerName;
      var refNo = this.data.billerPartnerRefNo;
      var can = this.data.serviceReferenceNumber;
      //var peyeeContact = this.data.payeeContactNumber ? this.data.payeeContactNumber : null;
      var billAmount = this.data.amount ; 
      var amountPaid =  this.data.amount;
      var fee = "0.00";
     // var transNo = this.data.responseJsonData ? this.data.responseJsonData.data.meta.TransactionNo : null;
      var AccountNo = this.data.serviceReferenceNumber;
      var billerCode = "MERALCO-FLEXI";
      var vipLogo = this.vipLogo.nativeElement.innerHTML;
  
      if(this.isA4.value){
        // if(this.data[i].statusId == 2){
  
        // }
        var Newstr = (this.receipt[1].value).replace("{{Date}}", completedDate);
        Newstr = (Newstr).replace("{{Time}}", transTime);
        Newstr = (Newstr).replace("{{Cashier}}", cashier);
  
        Newstr = (Newstr).replace("{{RefNo}}", refNo);
        Newstr = (Newstr).replace("{{RecValNo}}", recValNo);
      //  Newstr = (Newstr).replace("{{TransactionNo}}", transNo);
      //  Newstr = (Newstr).replace("{{PayeeContact}}", peyeeContact);
        
        Newstr = (Newstr).replace("{{AccountNo}}", AccountNo);
        Newstr = (Newstr).replace("{{BillerCode}}", billerCode);
        Newstr = (Newstr).replace("{{BillerName}}", billerName);
        Newstr = (Newstr).replace("{{BillAmount}}", parseFloat(billAmount).toFixed(2));
        Newstr = (Newstr).replace("{{AmountPaid}}", parseFloat(amountPaid).toFixed(2));
        Newstr = Newstr.replace("{{receiptLogo}}", vipLogo);
        Newstr = Newstr.replace("{{dti}}", this.dti);
        Newstr = Newstr.replace("{{tinNo}}", this.tin);
        Newstr = Newstr.replace("{{address}}", this.address);
        
        this.PrintReceipt(Newstr);
        } else {
    
          var Newstr = (this.receipt[0].value).replace("{{Date}}", completedDate);
          Newstr = (Newstr).replace("{{Time}}", transTime);
          Newstr = (Newstr).replace("{{RecID}}", reqId);
          Newstr = (Newstr).replace("{{RecValNo}}", recValNo);
          Newstr = (Newstr).replace("{{Cashier}}", cashier);
          Newstr = (Newstr).replace("{{BillerName}}", billerName);
          Newstr = (Newstr).replace("{{RefNo}}", refNo);
          Newstr = (Newstr).replace("{{CAN}}", can);
        //  Newstr = (Newstr).replace("{{PayeeContact}}", peyeeContact);
          Newstr = (Newstr).replace("{{BillAmount}}", parseFloat(billAmount).toFixed(2));
          Newstr = (Newstr).replace("{{billerFee}}", fee);
          Newstr = (Newstr).replace("{{AmountPaid}}", parseFloat(amountPaid).toFixed(2));
          Newstr = Newstr.replace("{{receiptLogo}}", vipLogo);
          Newstr = Newstr.replace("{{dti}}", this.dti);
          Newstr = Newstr.replace("{{tinNo}}", this.tin);
          Newstr = Newstr.replace("{{address}}", this.address);
   
       this.PrintReceipt(Newstr);
     }
    }
  
    PrintReceipt(Newstr: any)
    {
      var mywindow = window.open('', 'PRINT', 'height=400,width=600');
      mywindow.document.write(Newstr);
  
      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
      mywindow.close();
  
      return true;
    }

    rejectTransaction(){
      this.loader = true;
      this.ibayadMeralcoService.rejectTransaction(this.requestId).subscribe((billers) => {
        PNotify.success({
          title: "Failed Status",
          text: "Transaction successfully rejected.",
        });
        this.loader = false;
        this.closeModal.emit();
     }, error => {
      PNotify.error({
        title: "Failed",
        text: "There's a problem occured. Please contant ACM support.",
      });
       this.loader = false;
     });
  
    }
  
    completeTransaction(){
      this.loader = true;
      this.ibayadMeralcoService.completeTransaction(this.requestId).subscribe((billers) => {
        PNotify.success({
          title: "Information",
          text: "Transaction successfully completed.",
        });
        this.loader = false;
        this.closeModal.emit();
     }, error => {
      PNotify.error({
        title: "Failed",
        text: "There's a problem occured. Please contant JuanPay support.",
      });
       this.loader = false;
     });
  
    
    }
  
}



