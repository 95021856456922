import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StoreItemService {

  private localStorageCacheService: LocalStorageCacheService;
  httpClientNoInterceptor: HttpClient;

  constructor(
    private httpClient: HttpClient,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
  }

  private baseAPIUrl = environment.API_URL;

  // public getStoreItems(): Observable<any> {
  //   const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/StoreItems`;
  //   const accessToken = this.localStorageCacheService.getStorage("access_token");

  //   const httpHeaders: HttpHeaders = new HttpHeaders()
  //     .append("Authorization", "Bearer " + accessToken)
  //     .append("Content-Type", "application/x-www-form-urlencoded");

  //   return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  // }

  public getStoreItems(url: any): Observable<any> {
    let endPointUrl: any;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    endPointUrl = `${url}`;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public storePurchase(storeItem: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/Purchase`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(endPointUrl, storeItem, { headers: httpHeaders });
  }

  public getUoM(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/GetUoM`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getConversion(storeId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/GetConversion/${storeId}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getCategories(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/GetCategories`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public postCategory(json: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/Categories`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(endPointUrl, json, { headers: httpHeaders });
  }

  public postUoM(json: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/UnitOfMeasure`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(endPointUrl, json, { headers: httpHeaders });
  }

  public getPObyStatus(status: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/PObyStatus/${status}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getPObyAdmin(status: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/GetPOByAdmin/${status}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }
 
  public GetPOByPONO(pono: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/GetPOByPONO/${pono}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }
  
  public GetToShip(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/GetToShip`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getAddresses(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/GetAddresses`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getCart(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/GetCart`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public removeToCart(itemId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/RemoveToCart`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        ItemId : itemId
      };

    return this.httpClient.put(endPointUrl, json, { headers: httpHeaders });
  }


  public getPoD(poId): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/POD/${poId}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getInventory(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/GetInventory`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getCheckout(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/GetCheckout`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public stockItemPost(storeItem: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/StockItem`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(endPointUrl, storeItem, { headers: httpHeaders });
  }

  public stockItemPut(storeItem: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/PutStockItem`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.put(endPointUrl, storeItem, { headers: httpHeaders });
  }

  public addToCartPost(json: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/AddToCart`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(endPointUrl, json, { headers: httpHeaders });
  }

  public checkOut(json: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/CheckOut`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(endPointUrl, json, { headers: httpHeaders });
  }

  public placeOrder(json: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/PlaceOrder`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(endPointUrl, json, { headers: httpHeaders });
  }

  public pickPO(poID: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/ProceedPO`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    let json = {
      poId : poID,
      dr: true
    }

    return this.httpClient.put(endPointUrl, json, { headers: httpHeaders });
  }

  public drPO(poID: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/ProceedPO`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      let json = {
        poId : poID,
        intransit: true
      }

    return this.httpClient.put(endPointUrl, json, { headers: httpHeaders });
  }

  public receivePO(poID: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/ProceedPO`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      let json = {
        poId : poID,
        received: true
      }

    return this.httpClient.put(endPointUrl, json, { headers: httpHeaders });
  }

  public cancelPO(poID: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/ProceedPO`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      let json = {
        poId : poID,
        cancel: true
      }
      
    return this.httpClient.put(endPointUrl, json, { headers: httpHeaders });
  }

  public returnPO(poID: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/ProceedPO`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      let json = {
        poId : poID,
        returnPO: true
      }

    return this.httpClient.put(endPointUrl, json, { headers: httpHeaders });
  }

  public replenish(json: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/Replenish`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.put(endPointUrl, json, { headers: httpHeaders });
  }

  public depletion(json: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/Depletion`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.put(endPointUrl, json, { headers: httpHeaders });
  }

  public updateLevel(json: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StoreItem/InventoryLevel`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.put(endPointUrl, json, { headers: httpHeaders });
  }

  // tslint:disable-next-line: typedef
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  // tslint:disable-next-line: typedef
  private log(message: string) {
    //
  }

}
