import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { environment } from "src/environments/environment";
import { FormGroup } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
export class SettlementsService {
  private localStorageCacheService: LocalStorageCacheService;
  constructor(
    private httpClient: HttpClient,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
  }
  private httpClientNoInterceptor: HttpClient;

  private baseAPIUrl = environment.API_URL;

  public getSettlements(url: string): Observable<any> {
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${url}`, { headers: httpHeaders });
  }
  public getSettlementbyStoreName(storeName : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Settlements/Settlements/StoreName/${storeName}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getManagerStores(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Account/ManagerUserStores`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public updateLatestBankSettlement(id : any, route : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Settlements/UpdateLatestBankSettlement/${route}/${id}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");
      return this.httpClient.put(`${endPointUrl}`, {},{ headers: httpHeaders });
  }

  public downloadTextFileBankSettlement(route : any, transferType, dateRange): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Settlements/Export/${route}/Settlements/${transferType}?DateRange=${dateRange}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");
      return this.httpClient.get(`${endPointUrl}`,{ headers: httpHeaders });
  }



  //#region Nationlink

  public uploadNationlinkData(nationlinkSettlementPost): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Settlements/UploadNationlinkData`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      return this.httpClient.post(`${endPointUrl}`, nationlinkSettlementPost,{ headers: httpHeaders });
  }
  public updateNationlinkData(settlementPut : any, id :any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Settlements/Settlements/Nationlink/${id}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      return this.httpClient.put(`${endPointUrl}`, settlementPut,{ headers: httpHeaders });
  }

 

  
  public updateAndCompleteNationlinkData(settlementPut : any, id :any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Settlements/Settlements/Nationlink/Complete/${id}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      return this.httpClient.put(`${endPointUrl}`, settlementPut,{ headers: httpHeaders });
  }
  public getSettlementbyIdNationLink(id : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Settlements/Nationlink/Settlements/${id}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }
  
  //#endregion

   //#region Encash

   public uploadEncashData(encashSettlementPost): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Settlements/UploadEncashData`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      return this.httpClient.post(`${endPointUrl}`, encashSettlementPost,{ headers: httpHeaders });
  }
  public updateEncashData(settlementPut : any, id :any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Settlements/Settlements/Encash/${id}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      return this.httpClient.put(`${endPointUrl}`, settlementPut,{ headers: httpHeaders });
  }
  public updateAndCompleteEncashData(settlementPut : any, id :any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Settlements/Settlements/Encash/Complete/${id}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      return this.httpClient.put(`${endPointUrl}`, settlementPut,{ headers: httpHeaders });
  }
  
  public getSettlementbyIdEncash(id : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Settlements/Encash/Settlements/${id}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }
  
  //#endregion

 //#region EasyDebit

 public uploadEasydebitData(easydebitSettlementPost): Observable<any> {
  const endPointUrl = `${this.baseAPIUrl}/api/Settlements/UploadEasydebitData`;
  const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  const httpHeaders: HttpHeaders = new HttpHeaders()
    .append("Authorization", "Bearer " + accessToken)
    .append("Accept", "application/json");

    return this.httpClient.post(`${endPointUrl}`, easydebitSettlementPost,{ headers: httpHeaders });
}
public updateEasydebitData(settlementPut : any, id :any): Observable<any> {
  const endPointUrl = `${this.baseAPIUrl}/api/Settlements/Settlements/Easydebit/${id}`;
  const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  const httpHeaders: HttpHeaders = new HttpHeaders()
    .append("Authorization", "Bearer " + accessToken)
    .append("Accept", "application/json");

    return this.httpClient.put(`${endPointUrl}`, settlementPut,{ headers: httpHeaders });
}
public updateAndCompleteEasydebitData(settlementPut : any, id :any): Observable<any> {
  const endPointUrl = `${this.baseAPIUrl}/api/Settlements/Settlements/Easydebit/Complete/${id}`;
  const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  const httpHeaders: HttpHeaders = new HttpHeaders()
    .append("Authorization", "Bearer " + accessToken)
    .append("Accept", "application/json");

    return this.httpClient.put(`${endPointUrl}`, settlementPut,{ headers: httpHeaders });
}

//NEW
public checkStatusEasydebitData(settlementPut : any, id :any): Observable<any> {
  const endPointUrl = `${this.baseAPIUrl}/api/Settlements/Settlements/Easydebit/CheckForBatch/${id}`;
  const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  const httpHeaders: HttpHeaders = new HttpHeaders()
    .append("Authorization", "Bearer " + accessToken)
    .append("Accept", "application/json");

    return this.httpClient.put(`${endPointUrl}`, settlementPut,{ headers: httpHeaders });
}

public updateAndCompleteBatchEasydebitData(settlementPuts : any): Observable<any> {
  const endPointUrl = `${this.baseAPIUrl}/api/Settlements/Settlements/Easydebit/Complete/Batch`;
  const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  const httpHeaders: HttpHeaders = new HttpHeaders()
    .append("Authorization", "Bearer " + accessToken)
    .append("Accept", "application/json");

    return this.httpClient.post(`${endPointUrl}`, settlementPuts,{ headers: httpHeaders });
}


//NEW
public getSettlementbyIdEasydebit(id : any): Observable<any> {
  const endPointUrl = `${this.baseAPIUrl}/api/Settlements/Easydebit/Settlements/${id}`;
  const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  const httpHeaders: HttpHeaders = new HttpHeaders()
    .append("Authorization", "Bearer " + accessToken)
    .append("Content-Type", "application/x-www-form-urlencoded");

  return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
}

//#endregion

 //#region Shopee

 public uploadShopeeData(shopeeSettlementPost): Observable<any> {
  const endPointUrl = `${this.baseAPIUrl}/api/Settlements/UploadShopeeData`;
  const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  const httpHeaders: HttpHeaders = new HttpHeaders()
    .append("Authorization", "Bearer " + accessToken)
    .append("Accept", "application/json");

    return this.httpClient.post(`${endPointUrl}`, shopeeSettlementPost,{ headers: httpHeaders });
}
public updateShopeeData(settlementPut : any, id :any): Observable<any> {
  const endPointUrl = `${this.baseAPIUrl}/api/Settlements/Settlements/Shopee/${id}`;
  const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  const httpHeaders: HttpHeaders = new HttpHeaders()
    .append("Authorization", "Bearer " + accessToken)
    .append("Accept", "application/json");

    return this.httpClient.put(`${endPointUrl}`, settlementPut,{ headers: httpHeaders });
}
public updateAndCompleteShopeeData(settlementPut : any, id :any): Observable<any> {
  const endPointUrl = `${this.baseAPIUrl}/api/Settlements/Settlements/Shopee/Complete/${id}`;
  const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  const httpHeaders: HttpHeaders = new HttpHeaders()
    .append("Authorization", "Bearer " + accessToken)
    .append("Accept", "application/json");

    return this.httpClient.put(`${endPointUrl}`, settlementPut,{ headers: httpHeaders });
}

public updateAndCompleteGVCData(id :any): Observable<any> {
  //debugger
  const endPointUrl = `${this.baseAPIUrl}/api/SPXRemittance/GVC/Complete/${id}`;
  const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  const httpHeaders: HttpHeaders = new HttpHeaders()
    .append("Authorization", "Bearer " + accessToken)
    .append("Accept", "application/json");

    return this.httpClient.put(`${endPointUrl}`, {}, { headers: httpHeaders });
}

public sendInvoiceSPX(id :any): Observable<any> {
  //debugger
  const endPointUrl = `${this.baseAPIUrl}/api/SPXRemittance/SPX/Invoice/${id}`;
  const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  const httpHeaders: HttpHeaders = new HttpHeaders()
    .append("Authorization", "Bearer " + accessToken)
    .append("Accept", "application/json");

    return this.httpClient.put(`${endPointUrl}`, {}, { headers: httpHeaders });
}

public updateAndCompleteSPXData(id :any): Observable<any> {
  //debugger
  const endPointUrl = `${this.baseAPIUrl}/api/SPXRemittance/SPX/Complete/${id}`;
  const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  const httpHeaders: HttpHeaders = new HttpHeaders()
    .append("Authorization", "Bearer " + accessToken)
    .append("Accept", "application/json");

    return this.httpClient.put(`${endPointUrl}`, {}, { headers: httpHeaders });
}

public uploadSPXReceipt(id :any, base64: any, isgovip): Observable<any> {
  debugger
  const endPointUrl = `${this.baseAPIUrl}/api/SPXRemittance/UploadReceipt`;
  const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  const httpHeaders: HttpHeaders = new HttpHeaders()
    .append("Authorization", "Bearer " + accessToken)
    .append("Accept", "application/json");

    var json = {
      id : id,
      base64: base64,
      isGoVIP: isgovip
    };

    return this.httpClient.put(`${endPointUrl}`, json, { headers: httpHeaders });
}

public uploadSPXInvoice(id :any, base64: any, isgovip): Observable<any> {
  debugger
  const endPointUrl = `${this.baseAPIUrl}/api/SPXRemittance/UploadInvoice`;
  const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  const httpHeaders: HttpHeaders = new HttpHeaders()
    .append("Authorization", "Bearer " + accessToken)
    .append("Accept", "application/json");

    var json = {
      id : id,
      base64: base64,
      isGoVIP: isgovip
    };

    return this.httpClient.put(`${endPointUrl}`, json, { headers: httpHeaders });
}

public getSettlementbyIdShopee(id : any): Observable<any> {
  const endPointUrl = `${this.baseAPIUrl}/api/Settlements/Shopee/Settlements/${id}`;
  const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  const httpHeaders: HttpHeaders = new HttpHeaders()
    .append("Authorization", "Bearer " + accessToken)
    .append("Content-Type", "application/x-www-form-urlencoded");

  return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
}

//#endregion

public getTransactionByDateRange(date : any): Observable<any> {
  const endPointUrl = `${this.baseAPIUrl}/api/SPXRemittance/TransactionByDateRange`;
  const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  const httpHeaders: HttpHeaders = new HttpHeaders()
    .append("Authorization", "Bearer " + accessToken)
    .append("Content-Type", "application/json");

    var transaction = { daterange : date.toString() };
  return this.httpClient.post(`${endPointUrl}`, transaction, { headers: httpHeaders });
}

public getGVCTransactionByDateRange(date : any): Observable<any> {
  const endPointUrl = `${this.baseAPIUrl}/api/SPXRemittance/GVCTransactionByDateRange`;
  const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  const httpHeaders: HttpHeaders = new HttpHeaders()
    .append("Authorization", "Bearer " + accessToken)
    .append("Content-Type", "application/json");

    var transaction = { daterange : date.toString() };
  return this.httpClient.post(`${endPointUrl}`, transaction, { headers: httpHeaders });
}

public postSPXSettlementHeader(date : any, totalItem: any, totalAmout: any): Observable<any> {
  const endPointUrl = `${this.baseAPIUrl}/api/SPXRemittance/postSPXSettlementHeader`;
  const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  const httpHeaders: HttpHeaders = new HttpHeaders()
    .append("Authorization", "Bearer " + accessToken)
    .append("Content-Type", "application/json");

    var transaction = { 
      daterange : date.toString(),
      totalItem : totalItem,
      totalAmount: totalAmout
    };
  return this.httpClient.post(`${endPointUrl}`, transaction, { headers: httpHeaders });
}

public postSPXBillingHeader(date : any, totalItem: any, totalAmout: any): Observable<any> {
  const endPointUrl = `${this.baseAPIUrl}/api/SPXRemittance/postSPXBillingHeader`;
  const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  const httpHeaders: HttpHeaders = new HttpHeaders()
    .append("Authorization", "Bearer " + accessToken)
    .append("Content-Type", "application/json");

    var transaction = { 
      daterange : date.toString() ,
      totalItem : totalItem,
      totalAmount: totalAmout
    };
  return this.httpClient.post(`${endPointUrl}`, transaction, { headers: httpHeaders });
}

  

  // tslint:disable-next-line: typedef
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  // tslint:disable-next-line: typedef
  private log(message: string) {
    //
  }
}
