import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { environment } from "src/environments/environment";
import { FormGroup } from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class IglooService {
  private localStorageCacheService: LocalStorageCacheService;

  constructor(
    private httpClient: HttpClient,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
  }
  private httpClientNoInterceptor: HttpClient;

  private baseAPIUrl = environment.API_URL;

  public getTransaction(requestId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Igloo/Transaction/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getCTPLTransaction(requestId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Igloo/TransactionCTPL/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }


  // public rejectTransaction(requestId : any): Observable<any> {
  //   const endPointUrl = `${this.baseAPIUrl}/api/Igloo/Billers/Failed/${requestId}`;
  //   const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  //   const httpHeaders: HttpHeaders = new HttpHeaders()
  //     .append("Authorization", "Bearer " + accessToken)
  //     .append("Content-Type", "application/json");

  //   return this.httpClient.put(`${endPointUrl}`,"",{ headers: httpHeaders });
  // }

  // public completeTransaction(requestId : any): Observable<any> {
  //   const endPointUrl = `${this.baseAPIUrl}/api/Igloo/Billers/Complete/${requestId}`;
  //   const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  //   const httpHeaders: HttpHeaders = new HttpHeaders()
  //     .append("Authorization", "Bearer " + accessToken)
  //     .append("Content-Type", "application/json");

  //   return this.httpClient.put(`${endPointUrl}`,"",{ headers: httpHeaders });
  // }


  // public inquireBiller(biller : any, billerCode: any, fee : any): Observable<any> {
  //   const endPointUrl = `${this.baseAPIUrl}/api/Igloo/Billers/Inquiry`;
  //   const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  //   const httpHeaders: HttpHeaders = new HttpHeaders()
  //     .append("Authorization", "Bearer " + accessToken)
  //     .append("Content-Type", "application/json");

      
  //     biller.biller = billerCode;
  //     //biller.channel = "A4EZ";
  //      biller.channel = billerCode == "BC_MECOA" ? "A9ZK" : "A4EZ";
  //     //biller.channel = billerCode == "BC_MECOA" ? "1A85" : "1A85";
  //     biller.fee = fee;

  //     var json = 
  //     {biller};

  //   return this.httpClient.post(`${endPointUrl}`, json,{ headers: httpHeaders });
  // }

  public getTransactions(url: string): Observable<any> {
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${url}`, { headers: httpHeaders });
  }

  public validatePayment(policyFormGroup: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Igloo/Validate`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(`${endPointUrl}`, policyFormGroup, { headers: httpHeaders });
  }

  public processPayment(policyFormGroup: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Igloo/Process`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(`${endPointUrl}`, policyFormGroup, { headers: httpHeaders });
  }

  public getTransactionsCTPL(url: string): Observable<any> {
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${url}`, { headers: httpHeaders });
  }

  public validateCTPLPayment(policyFormGroup: any, ctplFormGroup: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Igloo/ValidateCTPL`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        policy: policyFormGroup,
        file: ctplFormGroup
      }

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public processCTPLPayment(policyFormGroup: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Igloo/ProcessCTPL`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(`${endPointUrl}`, policyFormGroup, { headers: httpHeaders });
  }


  // public reProcessPayment(biller : any, billerName : any,billerCategory: any ,  requestId : any): Observable<any> {
  //   const endPointUrl = `${this.baseAPIUrl}/api/Igloo/Billers/Reprocess/${requestId}`;
  //   const accessToken: any = this.localStorageCacheService.getStorage("access_token");

  //   const httpHeaders: HttpHeaders = new HttpHeaders()
  //     .append("Authorization", "Bearer " + accessToken)
  //     .append("Content-Type", "application/json");

  //     var json = 
  //     { 
  //       biller : biller,
  //       billerName : billerName,
  //       billerCategory : billerCategory
  //     };

  //   return this.httpClient.put(`${endPointUrl}`, json,{ headers: httpHeaders });
  // }


  // tslint:disable-next-line: typedef
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  // tslint:disable-next-line: typedef
  private log(message: string) {
    //
  }

  public addFavorite(billerCode : any, billerName: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Igloo/AddFavorite`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = 
      { 
        billerCode: billerCode,
        billerName: billerName
      };

    return this.httpClient.post(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  
  public removeFavorite(billerCode : any, billerName: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Igloo/RemoveFavorite`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = 
      { 
        billerCode: billerCode,
        billerName: billerName
      };

    return this.httpClient.put(`${endPointUrl}`, json, { headers: httpHeaders });
  }
}
