<app-loader *ngIf="!allocateFundFormGroup"></app-loader>
<app-loader *ngIf="processCreate"></app-loader>
<div *ngIf="allocateFundFormGroup" id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">Create Allocate Fund</h2>
          <small class="text-highlight font-weight-normal font-italic">Funds will be transferred immediately</small>
        </div>
        <div class="flex"></div>

        <div>
          <div
            data-toggle="modal"
            data-target="#modal-left"
            data-toggle-class="fade-left"
            data-toggle-class-target=".animate"
            class="btn btn-md btn-info"
          >
            <span class="d-none d-sm-inline mx-1">Allocate</span>
            <i data-feather="arrow-right"></i>
          </div>
        </div>

        <!-- (click)="saveAllocate FundUser()"  -->
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="padding">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <strong>Allocate Fund Information</strong>
              </div>
              <div class="card-body">
                <form>
                  <div class="row mb-2">
                    <div class="col-3">
                      <div class="my-2 text-muted">Wallet Balance</div>
                    </div>
                    <div class="col-9">
                      <div class="my-2 font-weight-bold">{{ wallet }}</div>
                    </div>
                  </div>

                  <app-textbox-number-only
                    [parent-form-group]="allocateFundFormGroup"
                    tab-index-start="0"
                    control-name="amount"
                    [read-only]="false"
                    [error]="allocateFundFormGroup?.controls.amount.errors | estatus"
                    [error-message]="allocateFundFormGroup?.controls.amount.errors | econtent"
                    read-only="false"
                    number-only="false"
                    label="Amount"
                    placeholder="0.00"
                    max-length="20"
                  >
                  </app-textbox-number-only>

                  <app-dropdown
                    *ngIf="roleLevelId == 2"
                    place-holder="Cashier"
                    [parent-form-group]="allocateFundFormGroup"
                    control-name="destinationUserId"
                    label="Cashier"
                    [data]="cashiers"
                    [error]="allocateFundFormGroup?.controls.destinationUserId.errors | estatus"
                    [error-message]="allocateFundFormGroup?.controls.destinationUserId.errors | econtent"
                  >
                  </app-dropdown>

                  <app-dropdown
                    *ngIf="roleLevelId == 3"
                    place-holder="Manager"
                    [parent-form-group]="allocateFundFormGroup"
                    control-name="destinationUserId"
                    label="Manager"
                    [data]="cashiers"
                    [error]="allocateFundFormGroup?.controls.destinationUserId.errors | estatus"
                    [error-message]="allocateFundFormGroup?.controls.destinationUserId.errors | econtent"
                  >
                  </app-dropdown>
                </form>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <strong>Sender Information</strong>
              </div>
              <div class="card" *ngIf="roleLevelId == 2">
                <div class="px-4 py-4">
                  <div class="row mb-2">
                    <div class="col-6">
                      <small class="text-muted">First Name</small>
                      <div class="my-2">{{ user?.managerUser.firstName }}</div>
                    </div>
                    <div class="col-6">
                      <small class="text-muted">Last Name</small>
                      <div class="my-2">{{ user?.managerUser.lastName }}</div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6">
                      <small class="text-muted">Username</small>
                      <div class="my-2">{{ user?.username }}</div>
                    </div>
                    <div class="col-6">
                      <small class="text-muted">Email Address</small>
                      <div class="my-2">{{ user?.email }}</div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6">
                      <small class="text-muted">Manager ID</small>
                      <div class="my-2">{{ user?.managerUser?.managerId }}</div>
                    </div>
                    <div class="col-6">
                      <small class="text-muted">Contact Number</small>
                      <div class="my-2">{{ user?.managerUser?.contactNumber }}</div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6">
                      <small class="text-muted">Birth Date</small>
                      <div class="my-2">{{ user?.managerUser?.birthDate }}</div>
                    </div>
                  </div>
                  <div>
                    <small class="text-muted">Address</small>
                    <div class="my-2">{{ user?.managerUser?.address }}</div>
                  </div>
                </div>
              </div>
              <div class="card" *ngIf="roleLevelId == 3">
                <div class="px-4 py-4">
                  <div class="row mb-2">
                    <div class="col-6">
                      <small class="text-muted">First Name</small>
                      <div class="my-2">{{ user?.cashierUser.firstName }}</div>
                    </div>
                    <div class="col-6">
                      <small class="text-muted">Last Name</small>
                      <div class="my-2">{{ user?.cashierUser.lastName }}</div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6">
                      <small class="text-muted">Username</small>
                      <div class="my-2">{{ user?.username }}</div>
                    </div>
                    <div class="col-6">
                      <small class="text-muted">Email Address</small>
                      <div class="my-2">{{ user?.email }}</div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6">
                      <small class="text-muted">Manager ID</small>
                      <div class="my-2">{{ user?.cashierUser?.managerId }}</div>
                    </div>
                    <div class="col-6">
                      <small class="text-muted">Contact Number</small>
                      <div class="my-2">{{ user?.cashierUser?.contactNumber }}</div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6">
                      <small class="text-muted">Birth Date</small>
                      <div class="my-2">{{ user?.cashierUser?.birthDate }}</div>
                    </div>
                  </div>
                  <div>
                    <small class="text-muted">Address</small>
                    <div class="my-2">{{ user?.cashierUser?.address }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <strong>Personal Information</strong>
              </div>
              <div class="card-body">
                <form>

                  <app-textbox [parent-form-group]="allocateFundFormGroup" tab-index-start="0" control-name="firstName"
                    [error]="allocateFundFormGroup?.controls.firstName.errors | estatus"
                    [error-message]="allocateFundFormGroup?.controls.firstName.errors | econtent" read-only="false"
                    no-special-char="false" label="First name" placeholder="First name">
                  </app-textbox>

                  <app-textbox [parent-form-group]="allocateFundFormGroup" tab-index-start="0" control-name="lastName"
                    [error]="allocateFundFormGroup?.controls.lastName.errors | estatus"
                    [error-message]="allocateFundFormGroup?.controls.lastName.errors | econtent" read-only="false"
                    no-special-char="false" label="Last name" placeholder="Last name">
                  </app-textbox>


                  <app-textarea [parent-form-group]="allocateFundFormGroup" tab-index-start="0" control-name="address"
                    [error]="allocateFundFormGroup?.controls.address.errors | estatus"
                    [error-message]="allocateFundFormGroup?.controls.address.errors | econtent" read-only="false"
                    no-special-char="false" label="Address" placeholder="Address">
                  </app-textarea>

                  <app-textbox [parent-form-group]="allocateFundFormGroup" tab-index-start="0"
                    control-name="contactNumber" [error]="allocateFundFormGroup?.controls.contactNumber.errors | estatus"
                    [error-message]="allocateFundFormGroup?.controls.contactNumber.errors | econtent" read-only="false"
                    no-special-char="false" label="Contact No." placeholder="Contact No.">
                  </app-textbox>

                  <app-datepicker [parent-form-group]="allocateFundFormGroup" tab-index-start="0"
                    control-name="birthDate" [error]="allocateFundFormGroup?.controls.birthDate.errors | estatus"
                    [error-message]="allocateFundFormGroup?.controls.birthDate.errors | econtent" no-special-char="false"
                    label="Birth Date " placeholder="Birth Date ">
                  </app-datepicker>

                </form>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <strong>Emergency Contact</strong>
              </div>
              <div class="card-body">
                <form>

                  <app-textbox [parent-form-group]="allocateFundFormGroup" tab-index-start="0"
                    control-name="emergencyContactNumber"
                    [error]="allocateFundFormGroup?.controls.emergencyContactNumber.errors | estatus"
                    [error-message]="allocateFundFormGroup?.controls.emergencyContactNumber.errors | econtent"
                    read-only="false" no-special-char="false" label="Mobile No." placeholder="Mobile Number">
                  </app-textbox>

                  <app-textbox [parent-form-group]="allocateFundFormGroup" tab-index-start="0"
                    control-name="emergencyContactName"
                    [error]="allocateFundFormGroup?.controls.emergencyContactName.errors | estatus"
                    [error-message]="allocateFundFormGroup?.controls.emergencyContactName.errors | econtent"
                    read-only="false" no-special-char="false" label="Full Name" placeholder="Full Name">
                  </app-textbox>

                  <app-textbox [parent-form-group]="allocateFundFormGroup" tab-index-start="0"
                    control-name="emergencyContactRelationship"
                    [error]="allocateFundFormGroup?.controls.emergencyContactRelationship.errors | estatus"
                    [error-message]="allocateFundFormGroup?.controls.emergencyContactRelationship.errors | econtent"
                    read-only="false" no-special-char="false" label="Relationship" placeholder="Relationship">
                  </app-textbox>
                </form>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<div id="modal-left" class="modal fade" data-backdrop="true">
  <div class="modal-dialog animate">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title text-md">Updating of Allocate Fund</div>
        <button class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="p-4 text-center">
          <p>Are you sure you want continue?</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
        <button type="button" (click)="actionEventCreateAllocateFund()" class="btn btn-primary" data-dismiss="modal">
          Save Changes
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
</div>
