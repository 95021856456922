<!-- https://valor-software.com/ngx-bootstrap/#/datepicker -->

<div [formGroup]="parentFormGroup" class="form-group" [class.error]="error">
    <input
      bsDatepicker
      autocomplete="off"
      container="app-root"
      class="md-input vip-form-control"
      [bsConfig]="dpConfig"
      [formControlName]="controlName"
      [id]="controlName"
      [placeholder]="placeholder"
      [upper-case]="upperCase"
      [no-special-char]="noSpecialChar"
      [maxlength]="maxLength"
      [tabindex]="tabIndexStart"
      readonly
    />

    <span *ngIf="error" class="error-msg"
      ><small><div [innerHTML]="errorMessage"></div></small></span
    >
  </div>
