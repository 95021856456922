<div class="mx-3">
  <h1>Examples</h1>

  <!-- Datepicker -->
  <h3>Datepicker</h3>
  <div class="row">
    <div class="col-xs-12 col-12 col-md-4 form-group">
      <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker />
    </div>
    <div class="col-xs-12 col-12 col-md-4 form-group">
      <input type="text" placeholder="Daterangepicker" class="form-control" bsDaterangepicker />
    </div>
  </div>

  <!-- Select2 -->
  <h3>Select2</h3>
  <p>fruit selected: {{ fruit }}</p>
  <ng-select2 [(ngModel)]="fruit" [data]="fruitList" [placeholder]="'Select a fruit...'"></ng-select2>
</div>
