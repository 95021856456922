<div class="container invoice" style="background-color: rgb(40, 77, 64)">
  <div>
    <div class="d-flex justify-content-center">
      <img src="../../../favicon.ico" height="90px" width="90px" />
    </div>

    <div class="d-flex justify-content-center">
      <h1 class="font-weight-bold mb-0">VIP PAYMENT CENTER</h1>
    </div>

    <div class="d-flex justify-content-center">
      <p class="mt-0" style="text-align: center">
        4th level unit 888, Lemon Square Building, <br />
        Muñoz - EDSA, Quezon City, Philippines <br />
      </p>
    </div>

    <div id="bot" style="height: 100px">
      <div class="info">
        <table
          id="datatable"
          class="table table-theme table-row v-middle dataTable no-footer table-hover"
          role="grid"
          aria-describedby="datatable_info"
        >
          <thead>
            <tr>
              <th data-sortable="true" data-field="Product" style="width: 20px">
                <div>Date</div>
              </th>
              <th data-sortable="true" data-field="Status">
                <div>Time</div>
              </th>
              <th data-sortable="true" data-field="Quantity">
                <div>Biller Details</div>
              </th>

              <th data-sortable="true" data-field="Subtotal">
                <div>Transact By</div>
              </th>
              <th data-sortable="true" data-field="Status">
                <div>Via</div>
              </th>
              <th data-sortable="true" data-field="Price">
                <div>Paid Bill Amount</div>
              </th>
            </tr>
          </thead>

          <!-- (click)="viewTransactionDetails(ii?.id)" -->
          <tbody>
            <tr *ngFor="let tran of trans" style="cursor: pointer" class="odd" role="row">
              <td style="width: 20px; padding-bottom: 2px">
                <p class="text-muted mb-0" style="font-size: 8px">Mar</p>
                <p class="mb-0 mt-0 text-highlight font-weight-bold" style="font-size: 12px">01</p>
                <p class="text-muted mt-0" style="font-size: 8px">2021</p>
              </td>

              <td style="padding-bottom: 2px">
                <p class="text-muted mt-0" style="font-size: 12px">
                  <span class="text-highlight font-weight-bold"> 10:54 PM </span>
                  <!-- <a>
                            <span class="badge badge-success text-uppercase"><i class="fa fa-check"></i></span>
                          </a> -->
                </p>
              </td>

              <td style="padding-bottom: 2px">
                <!-- <small>{{ ii.product }}</small> -->
                <p class="mb-0" style="font-size: 12px">
                  <span class="text-highlight font-weight-bold"> {{ tran.biller }} </span>
                  ( <span class="font-highlighted"> {{ tran.billerCode }} </span> )
                </p>
                <p class="text-muted mt-0 mb-0" style="font-size: 10px">
                  Request ID:<span class="font-highlighted"> {{ invoiceId }} </span>
                </p>
                <p class="text-muted mt-0" style="font-size: 10px">
                  Account No:<span class="font-highlighted"> {{ tran.accountno }} </span>
                </p>
              </td>

              <td style="padding-bottom: 2px">
                <p class="text-muted mt-0" style="font-size: 12px">
                  <span class="font-highlighted"> {{ tran.transactBy }} </span>
                </p>
              </td>

              <td style="padding-bottom: 2px">
                <p class="text-muted mt-0" style="font-size: 12px">
                  <span class="font-highlighted"> {{ tran.via }} </span>
                </p>
              </td>

              <td style="padding-bottom: 2px">
                <p class="text-muted mt-0" style="font-size: 12px">
                  <span class="font-highlighted"> {{ tran.paidBillAmount }} </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="footer">
    <hr class="mr-4 ml-4 mb-1" style="border: 1px dashed rgb(43, 42, 42)" />
    <div class="d-flex justify-content-center">
      <p style="text-align: center">
        All payment are transmitted directly to our partners Thank you for paying at
        <br />
        <strong>VIP PAYMENT CENTER</strong>
      </p>
    </div>

    <hr class="mr-4 ml-4 mt-1" style="border: 1px dashed rgb(43, 42, 42)" />
    <div class="d-flex justify-content-center">
      <p style="text-align: center">
        For concern regarding this transaction directly message us using our Facebook page. http://m.me./VIPSupportTeam
      </p>
    </div>
  </div>
</div>
