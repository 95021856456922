<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex" *ngIf="!loader">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">Audit Checker</h2>
          <small class="text-highlight font-weight-normal font-italic"></small>
        </div>
        <div class="flex"></div>
        
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="padding" style="padding-right: 0px">
        <!-- <div class="card mb-2 p-1" style="background-color: #182038"> -->
          <!-- <div class="row">
            <div class="col-sm-12 col-md-7"></div>
            <div class="col-sm-12 col-md-5">
              <form class="flex" [formGroup]="listFilterForm">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control form-control-theme form-control-sm search"
                    placeholder="Search..."
                    formControlName="searchString"
                    (keyup.enter)="getCashierUsers(null, null, null, true)"
                    style="background-color: white; color: black"
                  />
                  <span class="input-group-append">
                    <button
                      class="btn btn-white no-border btn-sm"
                      type="button"
                      (click)="getCashierUsers(null, null, null, true)"
                      style="background-color: gainsboro"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div> -->

          <!-- List Toolbar Form -->

          <!-- Search Button -->
        <!-- </div> -->

        <div class="card mt-2">
          <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
            <thead>
              <tr>
              
                <th data-sortable="true" data-field="id">
                  <div [ngClass]="cashierId">Username</div>
                </th>
              
                <th data-sortable="true" data-field="owner">
                  <div [ngClass]="username" >Role</div>
                </th>
                <th style="text-align: right;"  data-sortable="true" data-field="id">
                  <div [ngClass]="cashierId" >Credit</div>
                </th>
                <th style="text-align: right;" data-sortable="true" data-field="owner">
                  <div [ngClass]="email" >Debit</div>
                </th>
                <th style="text-align: right;" data-sortable="true" data-field="project">
                    <div [ngClass]="lastName" >Wallet Balance</div>
                  </th>
                <th style="text-align: right;" data-sortable="true" data-field="project">
                  <div [ngClass]="firstName" >Actual Balance</div>
                </th>
               
                <th style="text-align: right;" data-sortable="true" data-field="project">
                  <div [ngClass]="roleName" >Discrepancy</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let audit of audits"
                style="cursor: pointer"
                class="odd"
                role="row"
                (click)="adjustWallet(audit.username)"
              >

                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ audit?.username }} 
                  </div>
                </td>

                <td style="text-align: right;"  class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ audit?.role }}
                  </div>
                </td>

                <td style="text-align: right;" class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ audit?.debit | number: "1.2-2" }}
                  </div>
                </td>

                <td style="text-align: right;" class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ audit?.credit | number: "1.2-2" }}
                  </div>
                </td>
                <td style="text-align: right;" class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ audit?.wallet | number: "1.2-2" }}
                  </div>
                </td>
                <td style="text-align: right;" class="flex">
                  <div class="item-except text-sm h-1x">
                    {{audit?.actualbalance | number: "1.2-2"}}
                  </div>
                </td>
                <td style="text-align: right;" class="flex">
                    <div class="item-except text-sm h-1x">
                      {{audit?.discrepancy | number: "1.2-2"}}
                    </div>
                  </td>
              </tr>
            </tbody>
          </table>
        </div>
    
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>
