<div [formGroup]="parentFormGroup" class="row">
  <div class="form-group">
    <div class="col-sm-12 d-flex">
      <div class="generic-checkbox {{ controlName }}">
        <input
          type="checkbox"
          [formControlName]="controlName"
          [id]="controlName"
        />
        <label *ngIf="label" for="{{ controlName }}"></label>
      </div>
      <div class="checkbox-label">
        <span>{{ label }}</span>
      </div>
    </div>
  </div>
</div>
  