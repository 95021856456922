import { FormGroup } from "@angular/forms";
import { animate, style, transition, trigger } from "@angular/animations";
import { Component, Input, OnInit } from "@angular/core";
import { Meta } from "src/data/models/meta.model";

@Component({
  selector: "app-dropdown-v3",
  templateUrl: "./dropdown-v3.component.html",
  styleUrls: ["./dropdown-v3.component.less"],
  animations: [
    trigger("inOutAnimation", [transition(":leave", [animate("0.2s ease-out", style({ height: 0, opacity: 0 }))])]),
  ],
})
export class DropdownV3Component implements OnInit {
  @Input("parent-form-group") parentFormGroup: FormGroup;
  @Input("control-name") controlName: string;
  @Input() id: string;
  @Input() value = "";
  @Input() label: string;
  @Input() maxlength = "100";
  @Input("place-holder") placeholder: string;
  @Input() data: any;
  @Input("error") error: boolean;
  @Input("error-message") errorMessage: string;
  toggle = false;
  constructor() {}

  onClickEventOutSide(val): void {
    this.toggle = val;
  }

  async ngOnInit() {
    
    if (this.parentFormGroup.controls[this.controlName].value) {
      const value: number = this.parentFormGroup.controls[this.controlName].value;
      this.value = this.data.filter((x) => x.value === value)[0].key;
    }
  }

  onClick(action): void {
    setTimeout(() => {
      this.toggle = action === "close" ? false : true;
    }, 200);
  }

  selectedOption(option): void {
    this.value = option.value;
    this.toggle = false;
    this.parentFormGroup.controls[this.controlName].setValue(option.key);
  }
}
