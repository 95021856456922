import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'textarea[autoresize]'
})
export class AutoResizeDirective {

  constructor(private elementRef: ElementRef) {}

  @HostListener('input', ['$event.target'])
  onInput(textarea: HTMLTextAreaElement): void {
    this.adjustTextArea();
  }

  private adjustTextArea(): void {
    const textarea = this.elementRef.nativeElement;
    textarea.style.overflow = 'hidden';
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }
}
