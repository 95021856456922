import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-role-edit",
  templateUrl: "./role-edit.component.html",
  styleUrls: ["./role-edit.component.less"],
})
export class RoleEditComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
