import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';

import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { CurrencyPipe } from '@angular/common';
import { ServiceChargeService } from 'src/data/services/web/service-charge.service';
import { PaygramService } from 'src/data/services/web/paygram.service';
import { stringify } from 'querystring';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-paygram',
  templateUrl: './paygram.component.html',
  styleUrls: ['./paygram.component.less']
})
export class PaygramComponent implements OnInit {

  @Input("application") application: any;
  serviceChargeFormGroup : FormGroup;
  formBuilder : FormBuilder;
  public emptyString = "";
  public billers : any = [];
  public biller : any;
  public billerCode : any;
  public billerTypes : any = [];
  public billersByBillerTypes : any = [];
  public selectedBillerCode : any;

  public activeTab = 1;
  public loader = true;
  public fee : any;

  
  @Output("closeModal") closeModal = new EventEmitter();
  

  constructor(
    private serviceChargeService : ServiceChargeService,
    private router: Router,
    localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    private paygramService : PaygramService,
    formBuilder : FormBuilder) {
    this.formBuilder = formBuilder;
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.initServiceChargeFormGroup();
    this.loader = false;
  }

  initServiceChargeFormGroup(): void {
    this.serviceChargeFormGroup = new FormGroup({
      type: new FormControl(this.emptyString, Validators.required),
      amount: new FormControl(this.emptyString, Validators.required),
      code: new FormControl(this.emptyString)
    });

    this.transactionType = [
      { id: "Redeem", value : "Redeem Voucher"},
      { id: "Buy", value : "Buy Voucher"}
    ];
  }
 
  transactionInfo : any;

  setActiveTab(){
    if(this.activeTab == 1){
      console.log(this.serviceChargeFormGroup.value);
      if(this.serviceChargeFormGroup.controls.amount.value == ""){
        PNotify.error({
          title: "Error",
          text: "All fields are required to proceed to next step.",
        });
      }else{
        //ADD API CALL 
        this.paygramService.getInvoiceInfo(this.serviceChargeFormGroup.controls.code.value)
          .subscribe((result) => {
            debugger
            if(result.friendlyVoucherCode != null){
              if(parseFloat(result.amount) ==parseFloat(this.serviceChargeFormGroup.controls.amount.value.replaceAll(",","")) ){
                this.transactionInfo = result;
                this.activeTab++;
                this.loader = false;
              }else{
                PNotify.error({
                  title: "Voucher Not Found",
                  text: "The entered voucher code is invalid or does not exist. Please check the code and try again.",
                });
                this.loader = false;
              }
            }else{
              PNotify.error({
                title: "Voucher Not Found",
                text: "The entered voucher code is invalid or does not exist. Please check the code and try again.",
              });
              this.loader = false;
            }
            // PNotify.success({
            //   title: "Success",
            //   text: "Service Charge Successfully Added!",
            // });
            // this.loader = false;
            // this.code = "";
            // this.codeStatus=false;
            // this.closeModal.emit();
          
        }, error => {
           PNotify.error({
            title: "Service Temporarily Unavailable",
            text: "We're unable to process your request right now. Please try again later.",
          });
          this.loader = false;
          alert(stringify(error));
        });
    
      }
    }else if(this.activeTab == 2){

      var hasError : boolean  = false;
      if(hasError){
        PNotify.error({
          title: "Request denied",
          text: "Please fill up all the required fields.",
        });
      }else{

      }

     
    }
  }

  setActiveTabPrev(){
    if(this.activeTab == 1){
      PNotify.error({
        title: "Error",
        text: "Action Denied.",
      });
    }else{
      this.activeTab--;
    }
  }

  transactionType: any[];
  selectedTransactionType: any;

  // this called every time when user changed the code
  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  codeStatus : boolean = false;
  code : any;
  onCodeCompleted(code: string) {
    this.code = code;
    this.codeStatus = code.length == 6;
  }

  
  processPayment(){

    if(this.serviceChargeFormGroup.controls.type.value == "Redeem"){
      this.loader = true;
      this.paygramService.redeemVoucher({
        "voucherCode":this.serviceChargeFormGroup.controls.code.value,
        "pin": this.code
      })
        .subscribe((result) => {
          PNotify.success({
            title: "Success",
            text: "Paygram Voucher has been redeemed successfully!",
          });
          this.loader = false;
          this.code = "";
          this.codeStatus=false;
          this.closeModal.emit();
        
      }, error => {
        PNotify.error({
          title: "Failed",
          text: error.error.reason,
        });
        this.loader = false;
        this.code = "";
        this.codeStatus = false;
      });

    } else if(this.serviceChargeFormGroup.controls.type.value == "Buy"){

      this.loader = true;
      this.paygramService.buyVoucher({
        "voucherCode":this.serviceChargeFormGroup.controls.code.value,
        "pin": this.code,
        "amount": this.serviceChargeFormGroup.controls.amount.value
      })
        .subscribe((result) => {
          PNotify.success({
            title: "Success",
            text: "Paygram Voucher has been paid successfully!",
          });
          this.loader = false;
          this.code = "";
          this.codeStatus=false;
          this.closeModal.emit();
        
      }, error => {
        PNotify.error({
          title: "Failed",
          text: error.error.reason,
        });
        this.loader = false;
        this.code = "";
        this.codeStatus = false;
      });
    }


    
}

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  totalAmount(fee:any,amount:any){
    try  {
    return this.makeMoney(Number(fee) + Number(amount));
    } catch(e){
      return "0.00";
    }
    }

}
