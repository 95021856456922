<app-loader *ngIf="loader"></app-loader>
<div class="modal-body" *ngIf="activeTab!=7" >
  <form id=" form" novalidate="">
    <div id="rootwizard">
      <ul class="nav mb-3">
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="(activeTab==1)?'active':''">
            <span
              class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary">1</span>
            <div class="mt-2">
              <div class="text-muted">Select Biller</div>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="(activeTab==2)?'active':''">
            <span
              class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary">2</span>
            <div class="mt-2">
              <div class="text-muted">New Transaction</div>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="(activeTab==3)?'active':''">
            <span
              class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary">3</span>
            <div class="mt-2">
              <div class="text-muted">&nbsp;&nbsp;&nbsp;Babala&nbsp;&nbsp;&nbsp;</div>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="(activeTab==4)?'active':''">
            <span
              class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary">4</span>
            <div class="mt-2">
              <div class="text-muted">&nbsp;&nbsp;&nbsp;Babala&nbsp;&nbsp;&nbsp;</div>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="(activeTab==5)?'active':''">
            <span
              class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary">5</span>
            <div class="mt-2">
              <div class="text-muted">&nbsp;&nbsp;&nbsp;Babala&nbsp;&nbsp;&nbsp;</div>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="(activeTab==6)?'active':''">
            <span
              class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary">6</span>
            <div class="mt-2">
              <div class="text-muted">Confirmation</div>
            </div>
          </a>
        </li>

        <li *ngIf="biller && activeTab!=1" class="nav-item" style="margin-left: auto;">
          <a class="nav-link text-center" [ngClass]="(activeTab==3)?'active':''">
            <img [src]="biller?.logo" height="50" class="img-responsive" style="object-fit: contain;">
          </a>
        </li>
      </ul>

      <hr class="bg-dark" />

      <div class="tab-content p-3">
        <div class="tab-pane" [ngClass]="(activeTab==1)?'active':''" id="selectBillerTab">
          <div class="row">
            <div class="col-sm-12 col-md-2 d-flex justify-content-center align-items-center">
              <img [src]="application.value.thumbnail.logo" height="100" width="100" class="img-responsive"
                style="object-fit: contain;">
            </div>
            <div class="col-sm-12 col-md-5 d-flex flex-wrap">
              <h4>{{application.value.thumbnail.title}}</h4>
              <p>
                {{application.value.acquired.description}}
              </p>
            </div>
            <div class="col-sm-12 col-md-5" [formGroup]="billerFormGroup">

              <div class="form-group">
                <label>Select Product Code: </label>
                <select (change)="getBillersByBillerType($event.target.value)" formControlName="billerType"
                  class="form-control " tabindex="-1" aria-hidden="true">
                  <option value="">Choose...</option>
                  <option *ngFor="let billerType of billerTypes | sortdata" [value]="billerType">
                    {{billerType}}</option>
                </select>
              </div>

              <div class="form-group">
                <label>Select Biller:</label>
                <select (change)="setBillerCode($event.target.value)" formControlName="billerCode"
                  class="form-control " tabindex="-1" aria-hidden="true">
                  <option value="">Choose...</option>
                  <option *ngFor="let biller of billersByBillerTypes | sort : 'name'" [value]="biller.code">
                    {{biller.name}}</option>
                </select>
              </div>

            </div>
          </div>
        </div>

        <div class="tab-pane" [ngClass]="(activeTab==2)?'active':''" id="newTransTab">
          <div class="row">
            <div class="col-sm-12 col-md-2">
            </div>
            <div class="col-sm-12 col-md-8">
              <span class="text-highlight font-weight-bold"> Payee Details : {{biller?.name}} 
              </span> 
              <!-- <br/>
              <span class="text-highlight font-weight-bold"> Biller Fee : {{biller?.fee}} </span> -->

           

              <hr />
              
              <div *ngFor="let param of biller?.meta">

                <app-textbox-currency *ngIf="param?.type=='Number' && param?.field=='amount'"
                  [parent-form-group]="paramsFormGroup()" tab-index-start="0" [control-name]="param?.field"
                  [read-only]="false" [error]="paramsFormGroup()?.controls[param?.field].errors | estatus"
                  [error-message]="paramsFormGroup().controls[param?.field].errors?.required" read-only=false
                  number-only="false" [label]="param?.label" placeholder="0.00" max-length="10" (change)="computeBillerRate(paramsFormGroup()?.controls[param?.field].value)">
                </app-textbox-currency>

                <app-textbox-number *ngIf="param?.type=='Numeric' && param?.field!='amount'"
                  [parent-form-group]="paramsFormGroup()" tab-index-start="0" [control-name]="param?.field"
                  [read-only]="false" [error]="paramsFormGroup()?.controls[param?.field].errors | estatus"
                  [error-message]="paramsFormGroup().controls[param?.field].errors?.required" read-only=false
                  no-special-char="false" [label]="param?.label" [placeholder]="param?.label" max-length="50">
                </app-textbox-number>

                <app-textbox *ngIf="param?.type=='Alphanumeric' && biller.code != 'EASYTRIP'" [parent-form-group]="paramsFormGroup()" tab-index-start="0"
                  [control-name]="param?.field" [read-only]="false"
                  [error]="paramsFormGroup()?.controls[param?.field].errors | estatus"
                  [error-message]="paramsFormGroup().controls[param?.field].errors?.required" read-only=false
                  [label]="param?.label" [placeholder]="param?.label" max-length="50">
                </app-textbox>

                <app-textbox-number-only *ngIf="param?.type=='Alphanumeric' && biller.code == 'EASYTRIP'" [parent-form-group]="paramsFormGroup()" tab-index-start="0"
                  [control-name]="param?.field" [read-only]="false"
                  [error]="paramsFormGroup()?.controls[param?.field].errors | estatus"
                  [error-message]="paramsFormGroup().controls[param?.field].errors?.required" read-only=false
                  [label]="param?.label" [number-only]="true" [placeholder]="param?.label" max-length="12">
                </app-textbox-number-only>

                <app-datepicker *ngIf="param?.type=='Calendar'" [parent-form-group]="paramsFormGroup()"
                  tab-index-start="0" [control-name]="param?.field" [read-only]="false"
                  [error]="paramsFormGroup()?.controls[param?.field].errors | estatus"
                  [error-message]="paramsFormGroup().controls[param?.field].errors?.required" read-only=false
                  [label]="param?.label" [placeholder]="param?.label">
                </app-datepicker>

                <app-dropdown-v2 *ngIf="param?.type=='Dropdown'" place-holder="Choose.."
                  [parent-form-group]="paramsFormGroup()" [control-name]="param?.field" [label]="param?.label"
                  [data]="param?.options" [error]="paramsFormGroup()?.controls[param?.field].errors | estatus"
                  [error-message]="paramsFormGroup().controls[param?.field].errors?.required">
                </app-dropdown-v2>

              </div>

            

            <div [formGroup]="billerFormGroup" class="form-group row" >
              <label class="col-sm-3 col-form-label">Biller Fee <label *ngIf="type">({{type}})</label> </label>
              <div class="col-sm-9">
                <input
                  type="text"
                  formControlName="billerFee"
                  id="billerFee"
                  placeholder="0.00"
                  readonly="true"
                  class="form-control"
                />
              </div>
            </div>

            <div [formGroup]="billerFormGroup" class="form-group row" >
              <label class="col-sm-3 col-form-label">Amount to be paid </label>
              <div class="col-sm-9">
                <input
                  type="text"
                  formControlName="amountToPaid"
                  id="amountToPaid"
                  placeholder="0.00"
                  readonly="true"
                  class="form-control"
                />
              </div>
            </div>

         

              <div class="col-sm-12 col-md-2">
              </div>

            </div>
          </div>
        </div>

        <div class="tab-pane" [ngClass]="(activeTab==3)?'active':''" id="confirmationTab">
          <div>
            <div class="card">
              <div class="card-body alert alert-danger" style="text-align: center;">
                <br/>
                <br/>
                <br/>
                <h1 class="card-title blink" style="font-size: 90px;">
                  <svg style="margin-top: -10px;" xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12" y2="16"></line></svg>
                  BABALA/WARNING:</h1>
                <h2 class="card-text" style="font-size: 50px;">WALA KANG DAPAT BAYARAN SA PAG-ACTIVATE NG IYONG WESTERN UNION O KAHIT ANO PANG PRODUKTO SA GOVIPCENTER MARKETPLACE.</h2>
                
                <br/>
                <br/>
                <br/>
            </div>
          </div>
          </div>
        </div>

        <div class="tab-pane" [ngClass]="(activeTab==4)?'active':''" id="confirmationTab">
          <div>
            <div class="card">
              <div class="card-body alert alert-danger" style="text-align: center;">
                <br/>
                <br/>
                <br/>
                <h2 class="card-text" style="font-size: 50px;">ANG CUSTOMER MO BA AY KAHARAP MO O KAUSAP LAMANG SA TELEPONO?</h2>
                <br/>
                <br/>
                <br/>
                <div class="col-lg-12">
                  <div class="col-lg-5" style="float: left;">
                    <div class="media media-2x1 gd-primary" (click)="transactionStatusLegit(true)">
                      <a class="media-content" style="background-image:url()">
                          <strong  style="font-size: 50px; color: black;" class="text-fade">KAHARAP</strong>
                      </a>
                  </div>
                  </div>
                  <div class="col-lg-5" style="float: right;">
                    <div class="media media-2x1 gd-danger" (click)="transactionStatusLegit(false)">
                      <a class="media-content" style="background-image:url()">
                          <strong  style="font-size: 50px;color: black;" class="text-fade">KAUSAP</strong>
                      </a>
                  </div>
                  </div>

                  <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                
             
                </div>
               
            </div>
          </div>
          </div>
        </div>

        <div class="tab-pane" [ngClass]="(activeTab==5)?'active':''" id="confirmationTab">
          <!--  -->
          <div *ngIf="isTransactionStatusLegit">
              <div class="card">
                <div class="card-body alert alert-danger" style="text-align: center;">
                  <br/>
                  <br/>
                  <br/>
                  <h1 class="card-title blink" style="font-size: 90px;">
                    <svg style="margin-top: -10px;" xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12" y2="16"></line></svg>
                    BABALA/WARNING:</h1>
                  <h2 class="card-text" style="font-size: 50px;">SIGURADUHING LEHITIMO ANG TRANSACTION NA ITO. MAG-INGAT SA SCAM!</h2>
                  
                  <br/>
                  <br/>
                  <br/>
              </div>
            </div>
          </div>

          <div *ngIf="!isTransactionStatusLegit">
            <div class="card">
              <div class="card-body alert alert-danger" style="text-align: center;">
                <br/>
                <br/>
                <br/>
                <h1 class="card-title blink" style="font-size: 90px;">
                  <svg style="margin-top: -10px;" xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12" y2="16"></line></svg>
                  BABALA/WARNING:</h1>
                <h2 class="card-text" style="font-size: 50px;">IBABA ANG TELEPONO. WALANG SINUMAN SA ACM/GOVIPCENTER ANG MANININGIL NG BAYAD PARA SA MGA PRODUKTO SA MARKETPLACE! ISA ITONG SCAM!</h2>
                
                <br/>
                <br/>
                <br/>
            </div>
          </div>
        </div>

        </div>

        <div class="tab-pane" [ngClass]="(activeTab==6)?'active':''" id="confirmationTab">
          <div class="form-group">
            <p><strong>Payment Confirmation</strong><br /> <small>Please make sure that the all details is
                correct.</small>
            </p>
            <div class="card">
              <div class="list list-row">
                <div class="list-item " data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">
                      Biler Category
                    </div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">{{biller?.type}}</div>
                  </div>
                </div>
                <div class="list-item " data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">
                      Biler Name
                    </div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">{{biller?.name}}</div>
                  </div>
                </div>
               
                <div *ngFor="let param of biller?.meta" class="list-item " data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">
                      {{param?.label}}
                    </div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x" *ngIf="param.type != 'Dropdown'">{{paramsFormGroup()?.controls[param?.field].value}}</div>
                    <div class="item-feed h-2x" *ngIf="param.type == 'Dropdown'">{{ optionValue(param) }}</div>
                  </div>
                </div>
                <div class="list-item " data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">
                      Biller Fee
                    </div>
                  </div>
                  <div class="no-wrap" >
                    <div class="item-feed h-2x">{{billerFormGroup.controls.billerFee.value}}</div>
                  </div>
                 
                </div>
               <hr/>
               <div class="list-item " data-id="19">
                <div class="flex">
                  <div class="item-date text-muted text-sm d-none d-md-block">
                    Total Amount
                  </div>
                </div>
                <div class="no-wrap">
                  <div class="item-feed h-2x">{{billerFormGroup.controls.amountToPaid.value}}</div>
                </div>
              </div>
                <hr />
                <div class="col-sm-12 col-md-12">
                  <div class="col-sm-12 col-md-6" style="float:none;margin:auto;">
                    <div class="item-feed h-2x text-center">
                      ENTER PINCODE
                    </div>
                    <code-input [isCodeHidden]="true" [codeLength]="6" (codeChanged)="onCodeChanged($event)"
                      (codeCompleted)="onCodeCompleted($event)" [code]="code">
                    </code-input>
                  </div>
                </div>
                <br />


              </div>
            </div>
          </div>
        </div>

        <!-- previous next -->
        <div class="row py-3">
          <div class="col-6">
            <a class="btn btn-white button-previous" (click)="setActiveTabPrev()">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-arrow-left">
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
              &nbsp; Previous
            </a>
          </div>
          <div class="col-6">
            <div class="d-flex justify-content-end">
              <a
                *ngIf="activeTab == 1 && billerFormGroup.status != 'INVALID' && !isShortcutExisting"
                appDebounceClick 
                [debounceTime]="500" 
                (debounceClick)="addShortcut()"
                class="btn btn-danger button-next mr-3 text-white"
              >
                Add to Favorites &nbsp;
                <span class="fa fa-heart"></span>
              </a>

              <a
                *ngIf="activeTab == 1 && billerFormGroup.status != 'INVALID' && isShortcutExisting"
                appDebounceClick 
                [debounceTime]="500" 
                (debounceClick)="removeShortcut()"
                class="btn btn-outline-danger text-danger mr-3"
                id="remove"
              >
                Remove to Favorites &nbsp;
                <span class="fa fa-minus"></span>
              </a>
              
              <a *ngIf="activeTab != 6 && activeTab != 4 && activeTab != 5" class="btn btn-white button-next" (click)="setActiveTab()">
                Next &nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-arrow-right">
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </a>

              <a *ngIf="isTransactionStatusLegit" class="btn btn-white button-next" (click)="setActiveTab()">
                Next &nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                  class="feather feather-arrow-right">
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </a>

              
              <a *ngIf="activeTab == 6 &&  codeStatus" (click)="requestOtp()" class="btn btn-info button-next"
                style="color: white;" >
                Request OTP &nbsp;
                <span class="fa fa-hand-pointer"></span>
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </form>
</div>

<ng-template #pinModal let-modal >
  <div class="bg-dark">
    <div class="modal-header justify-content-center">
      <img src="https://i.ibb.co/vsQZ3MC/Go-VIP-Logo.png" height="29.12" width="59.68" alt="Go-VIP-Logo" />

   

      <h4 class="modal-title ml-5">ENTER MOBILE OTP</h4>
      <!-- <img src="https://i.ibb.co/vsQZ3MC/Go-VIP-Logo.png" height="101.92" width="208.88" alt="Go-VIP-Logo" /> -->
      <button type="button" class="close" aria-label="Close" (click)="closeTransaction()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <!-- <app-pin (doLoginPin)="processPayment($event)" [code]="otp"></app-pin> -->
  <div class="modal-body">
    <div class="col-sm-12 col-md-12 p-3">

      <div class="col-sm-12 col-md-12" style="float: none; margin: auto">

      
        <div class="alert alert-danger" role="alert" style="text-align: center">
          <i class="fa fa-warning"></i>
          <span class="mx-2" style="text-align:  center; font-weight: bolder;">REMINDER: No one from ACM/GoVIPCenter will ask you to process, send or transfer a payment for upgrades or product purchases! 
          </span><br/><br/>
          <span class="mx-2" style="text-align:  center;" >If you are on the phone / email / chat with an “alleged” company representative and is asking you to process this transaction, take note of the number and END the call immediately. BE CAREFUL 
            WITH THIS TRANSACTION.</span>
      </div>
</div>

      <div class="col-sm-12 col-md-12" style="float: none; margin: auto">
        <!-- <div class="text-center">
          <h3>ENTER SECURITY PASSCODE</h3>
        </div> -->
        <code-input
          [isCodeHidden]="false"
          [codeLength]="6"
          (codeChanged)="onCodeChangedOtp($event)"
          (codeCompleted)="onCodeCompletedOtp($event)"
          [code]="otp"
          isCharsCode="true"
        >
        </code-input>
        <br/>
      
        <a *ngIf="activeTab == 6 &&  otpStatus" appDebounceClick [debounceTime]="500" (debounceClick)="processPayment(otp)" class="btn btn-info button-next"
        style="color: white;float: right;" >
        Process Remittance &nbsp;
        <span class="fa fa-hand-pointer"></span>
      </a>
      </div>
      
     
    </div>
  </div>
</ng-template>
