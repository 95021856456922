import { Pipe, PipeTransform } from "@angular/core";
import { FormControl } from "@angular/forms";

@Pipe({
  name: "estatus",
})
export class ErrorStatusPipe implements PipeTransform {
  transform(errors: any): boolean {
    return errors !== null;
  }
}
