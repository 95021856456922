<app-loader *ngIf="!cashierUserFormGroup"></app-loader>
<app-loader *ngIf="processCreate"></app-loader>
<div *ngIf="cashierUserFormGroup" id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">Create Cashier</h2>
          <small class="text-muted">Make sure all the information are correct</small>
        </div>
        <div class="flex"></div>

        <div>
          <div
            data-toggle="modal"
            data-target="#modal-left"
            data-toggle-class="fade-left"
            data-toggle-class-target=".animate"
            class="btn btn-md btn-info"
          >
            <span class="d-none d-sm-inline mx-1">Save</span>
            <i data-feather="arrow-right"></i>
          </div>
        </div>

        <!-- (click)="saveCashierUser()"  -->
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="padding">
        <div class="row">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <strong>Account Information</strong>
              </div>
              <div class="card-body">
                <form>
                  <div class="form-group">
                    <div class="p-4">
                      <div class="d-flex">
                        <a href="#">
                          <span class="avatar w-64">
                            <img
                              *ngIf="cashierUserFormGroup?.controls.photo.value"
                              [src]="cashierUserFormGroup?.controls.photo.value | authImage | async"
                              alt="Juan"
                              onerror="this.src='https://via.placeholder.com/64x64?text=Juan';"
                            />
                            <img
                              *ngIf="!cashierUserFormGroup?.controls.photo.value"
                              src="https://via.placeholder.com/64x64?text=Juan"
                              alt="Juan"
                            />
                            <i class="on"></i>
                          </span>
                        </a>
                        <div class="mx-3">
                          <h5 class="mt-2">
                            {{ cashierUserFormGroup?.controls.firstName.value }}
                            {{ cashierUserFormGroup?.controls.lastName.value }}
                          </h5>
                          <div class="text-fade text-sm">
                            <span class="m-r"></span>
                            <small> <input id="file-upload" type="file" (change)="onPhotoChange($event)" /></small>
                          </div>
                          <span *ngIf="cashierUserFormGroup?.controls.photo.errors | estatus" class="error-msg"
                            ><small>{{ cashierUserFormGroup?.controls.photo.errors | econtent }}</small></span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <app-textbox
                    [parent-form-group]="cashierUserFormGroup"
                    tab-index-start="0"
                    control-name="username"
                    [error]="cashierUserFormGroup?.controls.username.errors | estatus"
                    [error-message]="cashierUserFormGroup?.controls.username.errors | econtent"
                    read-only="false"
                    label="Username"
                    placeholder="Username"
                  >
                  </app-textbox>

                  <app-textbox
                    [parent-form-group]="cashierUserFormGroup"
                    tab-index-start="0"
                    control-name="email"
                    [error]="cashierUserFormGroup?.controls.email.errors | estatus"
                    [error-message]="cashierUserFormGroup?.controls.email.errors | econtent"
                    read-only="false"
                    label="Email Address"
                    placeholder="Email Address"
                  >
                  </app-textbox>

                  <app-dropdown
                    place-holder="Role"
                    [parent-form-group]="cashierUserFormGroup"
                    control-name="roleId"
                    label="Role"
                    [data]="roles"
                    [error]="cashierUserFormGroup?.controls.roleId.errors | estatus"
                    [error-message]="cashierUserFormGroup?.controls.roleId.errors | econtent"
                  >
                  </app-dropdown>

                  <app-boolslider
                    *ngIf="true"
                    [parentFormGroup]="cashierUserFormGroup"
                    controlName="status"
                    label="Account"
                  >
                  </app-boolslider>
                </form>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <strong>Personal Information</strong>
              </div>
              <div class="card-body">
                <form>
                  <app-textbox
                    [parent-form-group]="cashierUserFormGroup"
                    tab-index-start="0"
                    control-name="firstName"
                    [error]="cashierUserFormGroup?.controls.firstName.errors | estatus"
                    [error-message]="cashierUserFormGroup?.controls.firstName.errors | econtent"
                    read-only="false"
                    no-special-char="false"
                    label="First name"
                    placeholder="First name"
                  >
                  </app-textbox>

                  <app-textbox
                    [parent-form-group]="cashierUserFormGroup"
                    tab-index-start="0"
                    control-name="lastName"
                    [error]="cashierUserFormGroup?.controls.lastName.errors | estatus"
                    [error-message]="cashierUserFormGroup?.controls.lastName.errors | econtent"
                    read-only="false"
                    no-special-char="false"
                    label="Last name"
                    placeholder="Last name"
                  >
                  </app-textbox>

                  <app-textarea
                    [parent-form-group]="cashierUserFormGroup"
                    tab-index-start="0"
                    control-name="address"
                    [error]="cashierUserFormGroup?.controls.address.errors | estatus"
                    [error-message]="cashierUserFormGroup?.controls.address.errors | econtent"
                    read-only="false"
                    label="Address"
                    placeholder="Address"
                  >
                  </app-textarea>

                  <app-textbox
                    [parent-form-group]="cashierUserFormGroup"
                    tab-index-start="0"
                    control-name="contactNumber"
                    [error]="cashierUserFormGroup?.controls.contactNumber.errors | estatus"
                    [error-message]="cashierUserFormGroup?.controls.contactNumber.errors | econtent"
                    read-only="false"
                    label="Contact No."
                    placeholder="Contact No."
                  >
                  </app-textbox>

                  <app-datepicker
                    [parent-form-group]="cashierUserFormGroup"
                    tab-index-start="0"
                    control-name="birthDate"
                    [error]="cashierUserFormGroup?.controls.birthDate.errors | estatus"
                    [error-message]="cashierUserFormGroup?.controls.birthDate.errors | econtent"
                    no-special-char="false"
                    label="Birth Date "
                    placeholder="Birth Date "
                  >
                  </app-datepicker>
                </form>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <strong>Emergency Contact</strong>
              </div>
              <div class="card-body">
                <form>
                  <app-textbox
                    [parent-form-group]="cashierUserFormGroup"
                    tab-index-start="0"
                    control-name="emergencyContactNumber"
                    [error]="cashierUserFormGroup?.controls.emergencyContactNumber.errors | estatus"
                    [error-message]="cashierUserFormGroup?.controls.emergencyContactNumber.errors | econtent"
                    read-only="false"
                    label="Mobile No."
                    placeholder="Mobile Number"
                  >
                  </app-textbox>

                  <app-textbox
                    [parent-form-group]="cashierUserFormGroup"
                    tab-index-start="0"
                    control-name="emergencyContactName"
                    [error]="cashierUserFormGroup?.controls.emergencyContactName.errors | estatus"
                    [error-message]="cashierUserFormGroup?.controls.emergencyContactName.errors | econtent"
                    read-only="false"
                    label="Full Name"
                    placeholder="Full Name"
                  >
                  </app-textbox>

                  <app-textbox
                    [parent-form-group]="cashierUserFormGroup"
                    tab-index-start="0"
                    control-name="emergencyContactRelationship"
                    [error]="cashierUserFormGroup?.controls.emergencyContactRelationship.errors | estatus"
                    [error-message]="cashierUserFormGroup?.controls.emergencyContactRelationship.errors | econtent"
                    read-only="false"
                    label="Relationship"
                    placeholder="Relationship"
                  >
                  </app-textbox>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<div id="modal-left" class="modal fade" data-backdrop="true">
  <div class="modal-dialog animate">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title text-md">Updating of Cashier</div>
        <button class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="p-4 text-center">
          <p>Are you sure you want continue?</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
        <button type="button" (click)="actionEventCreateCashierUser()" class="btn btn-primary" data-dismiss="modal">
          Save Changes
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
</div>
