<app-loader *ngIf="!stockItem"></app-loader>
<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex" *ngIf="stockItem">
  <!-- ############ Main START-->
  <div class="pl-3 pr-3">
    <div id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="--spam">Stock Item</h2>
          <small class="--desc">JuanPay list of Stock Item</small>
        </div>
        <div class="flex"></div>
      </div>
    </div>
    <div id="page-content">
      <div class="card p-2">
        <!-- <div class="card mb-2 p-1" style="background-color: #182038"> -->
        <!-- Filters -->

        <div class="card mb-2 mt-0" style="box-shadow: 0 2px 4px 0 #182038, 0 2px 5px 0 #182038">
          <div class="row" [formGroup]="listFilterForm">
            <table id="datatable" class="table" role="grid" aria-describedby="datatable_info">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>
                    <button
                      class="btn btn-sm mt-2 button-blue button--search btn-hover font-weight-bold"
                      (click)="createStockItem(createItemModal)"
                    >
                      Add Stock Item &nbsp; <i class="fa fa-plus text-highlight"></i>
                    </button>

                    <button
                      class="btn btn-sm mt-2 btn-info button--search font-weight-bold"
                      style="float: right"
                      (click)="getStockItem(null, null, null, true)"
                    >
                      Search &nbsp; <i class="fa fa-search text-highlight"></i>
                    </button>

                    <!-- <button
                          class="btn btn-sm mr-2 mt-2 btn-success button--create font-weight-bold"
                          *ngIf="roleId == 1"
                          (click)="getManagerUsersForExcel()">
                          Export Data &nbsp; <i class="fa fa-file-export text-highlight"></i>
                      </button> -->

                    <!-- <button
                          class="btn btn-sm mr-2 mt-2 btn-primary button--create font-weight-bold"
                          *ngIf="roleId == 1"
                          (click)="createManagerUser(uploadCreateBilling)">
                          Create Manager &nbsp; <i class="fa fa-plus text-highlight"></i>
                      </button> -->
                  </th>
                  <th style="width: 50px"></th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor: pointer" class="odd" role="row">
                  <td class="td-title">
                    <label class="label-filter" style="color: #182038; font-weight: bold"> &nbsp;</label>
                  </td>
                  <td class="td-value">&nbsp;</td>

                  <td class="td-title" style="text-align: end; vertical-align: middle">
                    <label class="label-filter" style="color: #182038; font-weight: bold">Search</label>
                  </td>

                  <td class="td-value" style="width: 50%">
                    <input
                      type="text"
                      (keyup.enter)="getStockItem(null, null, null, true)"
                      placeholder="Search..."
                      class="form-control w-100"
                      formControlName="searchString"
                      style="background-color: rgb(255, 255, 255); color: black; height: 36px; border-color: #182038"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card mt-2">
          <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
            <thead>
              <tr>
                <!-- <th data-sortable="true" data-field="owner">Avatar</th> -->
                <th data-sortable="true" data-field="id">
                  <div class="color-blue">Stock Item</div>
                </th>
                <th data-sortable="true" data-field="owner">
                  <div class="color-blue">Item Name</div>
                </th>

                <th class="color-blue">Item Description</th>

                <th class="color-blue" data-sortable="true" data-field="project" style="text-align: right">
                  <div>Unit Price</div>
                </th>

                <th class="color-blue" style="text-align: center"><span class="d-none d-sm-block">Status</span></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let item of stockItem?.data
                    | paginate: { itemsPerPage: 10, currentPage: p, totalItems: stockItem.totalRecords }
                "
                (click)="selectManager(itemModal, item)"
                style="cursor: pointer"
                class="odd"
                role="row"
              >
                <!-- <td>
                  <a href="#">
                    <span>
                      <img class="w-32 avatar" *ngIf="user.managerUser?.photo" [src]="buildUserPhoto(user) | authImage | async" />
                      <img
                        class="w-32 avatar"
                        *ngIf="!user.managerUser?.photo"
                        src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file64407579-6a37-49ab-b083-b882c28eea4e.png"
                        alt="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file64407579-6a37-49ab-b083-b882c28eea4e.png"
                      />
                    </span>
                  </a>
                </td> -->
                <!-- <td>
                  {{ user.managerUser?.managerId }}
                </td> -->

                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    <span style="font-weight: bolder">{{ item?.itemCode }}</span>
                  </div>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    <span style="color: rgb(60, 62, 62); font-weight: bold">{{ item?.name }}</span>
                  </div>
                </td>

                <td class="flex">
                  <div style="max-width: 600px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                    <span style="color: rgb(60, 62, 62)">{{ item.description }}</span>
                  </div>
                </td>

                <td class="flex" style="text-align: right">
                  <div class="item-except text-sm h-1x">
                    <span class="color-red" style="font-weight: bold">
                      {{ makeMoney(item.price) }}
                    </span>
                  </div>
                </td>

                <td style="vertical-align: middle" class="mt-2 pb-2">
                  <span>
                    <label *ngIf="item.active == true" class="ui-switch ui-switch-sm dark mt-1 mr-2">
                      <input type="checkbox" checked disabled />
                      <i></i>
                    </label>

                    <label *ngIf="item.active != true" class="ui-switch ui-switch-sm dark mt-1 mr-2">
                      <input type="checkbox" disabled />
                      <i></i>
                    </label>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
              <div class="col-sm-12 col-md-12">
                <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                  Showing {{ (users?.pageNumber - 1) * users?.pageSize + 1 }} to
                  {{ (users?.pageNumber - 1) * users?.pageSize + users?.data?.length }} of
                  {{ users?.data?.length }} entries
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-7" style="float: right">
            <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
              <ul class="pagination" style="float: right">
                <pagination-controls (pageChange)="getStockItem(buildPageUrl($event, 10))"></pagination-controls>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<ng-template #itemModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Stock Item Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body pt-0 pb-0">
    <app-stock-item
      [stockItem]="selectedItem"
      (closeModal)="closeModal()"
      (getStockItem)="getStockItem()"
    ></app-stock-item>
  </div>
</ng-template>

<ng-template #createItemModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Create Stock Item</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body pt-0 pb-0">
    <app-stock-item-create
      [stockItem]="selectedItem"
      (closeModal)="closeModal()"
      (getStockItem)="getStockItem()"
    ></app-stock-item-create>
  </div>
</ng-template>
