<div class="vip">
  <!-- <div class="vip__loader" *ngIf="isLoading | async">
    <i class="vip__loader--image"></i>
    <div class="vip__loader--background"></div>
  </div>

  <div class="vip__loader" *ngIf="isLoadingManual | async">
    <i class="vip__loader--image"></i>
    <div class="vip__loader--background"></div>
  </div> -->

  <div class="vip__loader">
    <i class="vip__loader--image"></i>
    <div class="vip__loader--background"></div>
    {{message}}
  </div>
</div>