<!-- <h1>Under Construction</h1> -->
<div id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">List of POS Transactions</h2>
          <small class="text-highlight font-weight-normal font-italic">JuanPay list of POS Transactions</small>
        </div>
      </div>
    </div>

    <div class="page-content page-container" id="page-content">
      <div class="">
        <div class="card mb-2" style="background-color: #182038">
          <div class="row" [formGroup]="searchByUsernameFormGroup">
            <table id="datatable" class="table mb-0" role="grid" aria-describedby="datatable_info">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>
                    <button class="btn btn-sm btn-info" style="float: right" (click)="searchData()">
                      Search &nbsp; <i class="fa fa-filter text-highlight"></i>
                    </button>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor: pointer" class="odd" role="row">
                  <td class="td-title">
                    <label class="label-filter">Set No. of Rows</label>
                  </td>
                  <td class="td-value">
                    <ng-select [items]="showRowsOption" bindLabel="text" bindValue="id" formControlName="showRows">
                    </ng-select>
                  </td>

                  <td class="td-title">
                    <label class="label-filter">Enter Transaction No.</label>
                  </td>
                  <td class="td-value">
                    <input
                      type="text"
                      class="form-control form-control-sm search w-100"
                      placeholder="Transaction No"
                      formControlName="posTransactionNumber"
                      style="background-color: white; color: black"
                    />
                  </td>
                </tr>
                <tr style="cursor: pointer" class="odd" role="row">
                  <td class="td-title">
                    <label class="label-filter">Date Range</label>
                  </td>

                  <td class="td-value">
                    <input
                      type="text"
                      placeholder="Date Range"
                      class="form-control"
                      bsDaterangepicker
                      formControlName="dateRange"
                      style="background-color: white; color: black"
                      [bsConfig]="{
                        rangeInputFormat: 'YYYY-MM-DD',
                        dateInputFormat: 'YYYY-MM-DD',
                        showWeekNumbers: false
                      }"
                    />
                  </td>

                  <td class="td-title">
                    <label *ngIf="roleLevelId != 3" class="label-filter">Cashier Username</label>
                  </td>
                  <td class="td-value">
                    <ng-select
                      *ngIf="roleLevelId != 3"
                      [items]="userIdFilterOptions"
                      bindLabel="text"
                      bindValue="id"
                      formControlName="username"
                    ></ng-select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Filters -->
        <!-- <div [formGroup]="searchByUsernameFormGroup">
            <div class="card w-100 mb-2 p-1" style="background-color: #182038">
              <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4 d-flex justify-content-center align-items-center">
                  <ng-select2
                    *ngIf="roleLevelId != 3"
                    [(ngModel)]="userIdFilter"
                    [data]="userIdFilterOptions"
                    [placeholder]="'Select Username'"
                    [allowClear]="true"
                    formControlName="username"
                    style="width: 100%"
                  ></ng-select2>
                </div>
                <div class="col-sm-12 col-md-3 col-lg-3 d-flex justify-content-center align-items-center">
                  <input
                    type="text"
                    placeholder="Date Range"
                    class="form-control form-control-sm w-100"
                    bsDaterangepicker
                    formControlName="dateRange"
                    style="background-color: white; color: black"
                    [bsConfig]="{
                      rangeInputFormat: 'YYYY-MM-DD',
                      dateInputFormat: 'YYYY-MM-DD',
                      showWeekNumbers: false
                    }"
                  />
                </div>
                <div class="col-sm-9 col-md-3 col-lg-3 pl-0 pr-0 d-flex justify-content-center align-items-center">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control form-control-sm search w-100"
                      placeholder="Transaction No"
                      formControlName="posTransactionNumber"
                      style="background-color: white; color: black"
                    />
                  </div>
                </div>
                <div class="col-sm-3 col-md-2 col-lg-2 d-flex justify-content-end align-items-center">
                  <button class="btn btn-sm btn-primary w-100" (click)="getPosTransactions(null, null, null, true)">
                    Search &nbsp;
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
  
            <form class="form-inline"></form>
          </div> -->

        <div class="card">
          <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
            <thead>
              <tr>
                <th data-sortable="true" data-field="id">POS Transaction Id</th>
                <th data-sortable="true" data-field="id">Total Amount</th>
                <th data-sortable="true" data-field="owner">Tax Amount</th>
                <th data-sortable="true" data-field="owner">Cash Tendered</th>
                <th data-sortable="true" data-field="project">Cash Change</th>
                <th data-sortable="true" data-field="project">Transact By</th>
                <th data-sortable="true" data-field="project">Transaction Date</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let posTransaction of posTransactions?.data
                    | paginate
                      : {
                          itemsPerPage: posTransactions?.pageSize,
                          currentPage: p,
                          totalItems: posTransactions?.totalRecords
                        }
                "
                (click)="setSelectedPos(posTransaction.id)"
                style="cursor: pointer"
                class="odd"
                role="row"
              >
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ pad(posTransaction?.id, 10) }}
                  </div>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ posTransaction?.totalAmount | number: "1.2-2" }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ posTransaction?.taxPercentage | number: "1.2-2" }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ posTransaction?.cashTendered | number: "1.2-2" }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ posTransaction?.cashChange | number: "1.2-2" }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ posTransaction.transactByUser.username }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ posTransaction.completedDate | date: "dd-MMM-yyyy hh:mm:ss a" }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
              <div class="col-sm-12 col-md-12">
                <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                  Showing {{ (posTransactions?.pageNumber - 1) * posTransactions?.pageSize + 1 }} to
                  {{ (posTransactions?.pageNumber - 1) * posTransactions?.pageSize + posTransactions?.data?.length }} of
                  {{ posTransactions?.data?.length }} entries
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-7" style="float: right">
            <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
              <ul class="pagination" style="float: right; margin-right: 36px">
                <pagination-controls (pageChange)="getPosTransactions(buildPageUrl($event, 10))"></pagination-controls>
              </ul>
            </div>
          </div>
          <!-- <div class="col-sm-12 col-md-7" style="float: right">
              <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                <ul class="pagination" style="float: right">
  
                  <li
                    (click)="getPosTransactions(posTransactions?.previousPage)"
                    class="paginate_button page-item text-dark"
                  >
                    <a class="page-link">&laquo; Previous</a>
                  </li>
  
                  <li
                    *ngFor="let page of totalPages(posTransactions?.totalPages)"
                    (click)="getPosTransactions(buildPageUrl(page + 1, posTransactions.pageSize))"
                    [ngClass]="getPageNumberClass(page + 1)"
                    class="text-dark"
                  >
                    <a class="page-link" [innerHTML]="page + 1"></a>
                  </li>
  
                  <li (click)="getPosTransactions(posTransactions?.nextPage)" class="paginate_button page-item text-dark">
                    <a class="page-link">Next &raquo;</a>
                  </li>
  
                </ul>
              </div>
            </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<ng-template #viewPosTransaction let-modal>
  <div class="modal-header">
    <h4 class="modal-title">[POS] Transaction Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="overflow-y: scroll">
    <div class="col-sm-12 col-md-12">
      <div class="card w-100" style="height: 75vh">
        <app-cashier-history-items
          [pos]="selectedPos"
          [isPos]="false"
          isPrint="true"
          [posTransactionId]="pad(selectedPos, 10)"
        ></app-cashier-history-items>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>
