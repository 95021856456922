import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ModalConfirmationComponent } from "src/app/pages/modal-confirmation/modal-confirmation.component";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { EmitterService } from "src/data/services/web/emitter.service";
import { WalletService } from "src/data/services/web/wallet.service";

// PNotify
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { ChatService } from "src/app/chat.service";
import { WebSettingsService } from "src/data/services/web/web-settings.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DomSanitizer } from "@angular/platform-browser";
import { DatePipe, DecimalPipe } from "@angular/common";
import { Console } from "console";
import { UserService } from "src/data/services/web/user.service";
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { AngularFirestore } from "@angular/fire/firestore";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.less"],
})

export class HeaderComponent implements OnInit {
  public roleLevelName: any;
  public authService: AuthService;
  public bsModalRefConfirmation: BsModalRef;
  public localStorageCacheService: LocalStorageCacheService;
  public webSettingsService: WebSettingsService;
  public modalService: BsModalService;
  public walletService: WalletService;
  public userService: UserService;
  public photo: any;
  public roleName: any;
  public roleLevelId: any;
  public roleId: any;
  public isPartner: any;
  public userId: any;
  
  @Input() wallet: string;
  public csrCode: any;
  public onlineUsersData : any;
  public announcementString: any;
  public announcement: any;
  public footPrint : any;

  public isToggled = false;

  constructor(
    webSettingsService: WebSettingsService,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    private router: Router,
    private route: ActivatedRoute,
    modalService: BsModalService,
    walletService: WalletService,
    private emitter: EmitterService,
    private chatService: ChatService,
    private store: AngularFirestore,
    private modalServe: NgbModal,
    private sanitizer: DomSanitizer,
    userService: UserService,
    private datePipe : DatePipe,
    public _decimalPipe: DecimalPipe,
  ) {
    this.webSettingsService = webSettingsService;
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.modalService = modalService;
    this.userService = userService;
    this.walletService = walletService;

    PNotifyButtons; // Initiate PNotify buttons. Important!
  }

  userID: any;
  username: any;
  ngOnInit(): void { 
    this.getWallet();
    this.getWebSettings(27);
    this.getCSRCode();
    this.photo = this.localStorageCacheService.getStorage("photo");
    this.roleName = this.localStorageCacheService.getStorage("role_name");
    this.roleLevelName = this.authService.getRoleLevelName(this.localStorageCacheService.getStorage("role_level"));
    var rolelevel = this.localStorageCacheService.getStorage("role_level")
    this.roleLevelId = rolelevel;
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    this.footPrint = this.localStorageCacheService.getStorage("footprint");
    const userId = this.localStorageCacheService.getStorage("userId");
    this.userID = this.localStorageCacheService.getStorage("userId");
    this.username = this.localStorageCacheService.getStorage("username");
    this.value = this.localStorageCacheService.getStorage("forQR");
    this.fullname = this.localStorageCacheService.getStorage("fullname");
    this.dti = this.localStorageCacheService.getStorage("dti");
    this.isPartner = this.roleId == 10 ||  this.roleId == 12 || this.roleId == 19 ? true : false;

    this.emitter.reloadWalletEmitter.subscribe((status) => {
      this.getWallet();
      this.checkSalesAccount(userId, rolelevel);
    });

    if(rolelevel == 2){
      this.initReceipt();
      this.getRiderCredit();
    }
    
    this.initSalesWallet(userId);
    //this.getAccountPrefix();

    var element = document.getElementById('myQRTemplate');
    if(element != null){
        element.style.display = 'none';
    }
  }

  @ViewChild('govipxQR') govipxQR: ElementRef;
  @ViewChild('vipLogo') vipLogo: ElementRef;
  @ViewChild('myQRTemplate') myQRTemplate: ElementRef;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = '';
  fullname = '';
  dti = '';

  public salesWallet: any;
  isSalesUser: boolean = false;

  checkSalesAccount(userId: any, roleLevelId: any){
    if(roleLevelId == 2 || roleLevelId == 4){
      this.initSalesWallet(userId);
    }
  }

  initSalesWallet(userId: any) : void{
    this.userService.GetSalesUserWallet(userId).subscribe((result) => {
      this.salesWallet = result.data;
      this.isSalesUser = result.data.isSalesUser;
    });
  }

  getWebSettings(id: any): void {
    this.webSettingsService.getWebSettingsByIdentifier("ANNOUNCEMENT").subscribe((result) => {
      const val = result.data;
      this.announcementString = this.sanitizer.bypassSecurityTrustHtml(val[0].value);
    });
  }

  accountPrefix: any;
  getAccountPrefix(): void {
    this.webSettingsService.getWebSettingsByIdentifier("AccountPrefix").subscribe((result) => {
      this.accountPrefix = result.data;
    });
  }

  displayAnnouncenment(content: any){
    this.modalServe.open(content, { centered: true, size: "lg" });
  }
  
  displayOnline(){
    this.onlineUsersData = this.onlineUsersData;
  }

  timeLeft: number = 60;
  interval;

  public subscribeToEvents(): void {  
      this.chatService.onlineUsersReceived.subscribe((data: any) => {  
       var result =[];
        data.forEach(user => {
          if(!result.find(x=>x.name == user.name)){
            result.push(user)
          }
        });
        result = result.sort(function(a, b){
          return b.name - a.name;
        });
        this.onlineUsersData = result;
      });     
    }

  public subscribeToEventsAll(): void {
    this.chatService.messageReceived.subscribe((data: any) => {  
      console.log(data);
        PNotify.notice({
          title: "JuanPay Notification",
          text: data,
          hide : false
        });
     });  
  }  

  getCSRCode(): void {
    this.walletService.getCSRCode().subscribe((result) => {
      this.csrCode = result.csrCode;
    });
  }

  messages : [];
  riderCreditWallet: any;
  getWallet(): void {
    this.walletService.getWallet().subscribe((result) => {
      this.wallet = result.wallet;
      this.messages = result.messages
      if(result.footPrint != this.footPrint)
      {
        this.forceLogOut();
      }else
      {
        this.startTimer();
      }
    });
  }

  getRiderCredit(){
    let userId = this.localStorageCacheService.getStorage("id");
      this.store.collection("RiderCreditWallet").doc(userId).valueChanges().subscribe((result: any) => {
        this.riderCreditWallet = result.balance;
      });
  }

  startTimer() {
    this.interval = setInterval(() => {
      var currentTime = this.datePipe.transform(new Date(), 'hh:mm a');
      this.messages.forEach(result => {
          var message : any = result;
          var times = JSON.parse(message.times);

          for (let index = 0; index < times.length; index++) {
            if(currentTime ==times[index].time){
                PNotify.notice({
                  title: "JuanPay Notification",
                  text: message.message,
                  hide : false
                });
            }
          }
      });
      console.log();
    },60000)
  }

  showSideNav(): void {
    document.getElementById("side-nav").classList.add("active");
    document.getElementById("nav-bg-wrap").classList.add("active");
  }

  forceLogOut(): void {
    //  this.authService.logOut().then((result) => {
    // if (result) {
    //const loginRoute = this.authService.getRoleLevelName(this.localStorageCacheService.getStorage("role_level"));

    PNotify.notice({
      title: "Sign Out",
      text: "Same account has been logged in from another device.",
    });

    // var email = this.localStorageCacheService.getStorage("email_address");
    // this.logoutSocket(email);
    // this.router.navigate(["login"]);
   this.localStorageCacheService.removeStorage("email_address");
   this.localStorageCacheService.removeStorage("access_token");
   this.localStorageCacheService.removeStorage("refresh_token");
   window.location.href = `login`;
    //  } else {
    //    this.logoutErrorDialog();
    //  }
    // });
  }

  logOut(): void {
    //  this.authService.logOut().then((result) => {
    // if (result) {
    //const loginRoute = this.authService.getRoleLevelName(this.localStorageCacheService.getStorage("role_level"));
    PNotify.success({
      title: "Sign Out",
      text: "Successfully signed-out of your account.",
    });
    
    // var email = this.localStorageCacheService.getStorage("email_address");
    // this.logoutSocket(email);
    // this.router.navigate(["login"]);
   this.localStorageCacheService.removeStorage("email_address");
   this.localStorageCacheService.removeStorage("access_token");
   this.localStorageCacheService.removeStorage("refresh_token");
   window.location.href = `login`;
    //  } else {
    //    this.logoutErrorDialog();
    //  }
    // });
  }
  
  logoutSocket(email : any): void {  
    this.chatService.logoutSocket(email);
  }

  logoutErrorDialog(): void {
    const initialState = {
      meta: {
        title: "Logout",
        message: "Unable to to logout: An error occured.",
        btnTitle: "Ok",
        showCancel: false,
      },
    };

    this.bsModalRefConfirmation = this.modalService.show(ModalConfirmationComponent, {
      initialState,
    });

    this.bsModalRefConfirmation.content.clickevent.subscribe((response) => {
      if (response.result) {
      }
    });
  }

  toggleSideNav(){
    if(this.isToggled){
      this.isToggled = false;
    } else {
      this.isToggled = true;
    }
  }

  receipt: any;
  initReceipt() {
    this.webSettingsService.getWebSettingsByIdentifier("GoVIPX_QR_TEMPLATE").subscribe((result) => {
      this.receipt = result.data;
    });
  }

  openQR(content: any){
    this.modalServe.open(content, { centered: true, size: "lg" });
  }

  getQR(myQRTemplate: any){
    var qrtemplate = myQRTemplate.innerHTML;

    var Newstr = (this.receipt[0].value)
      .replace("{{QR}}", qrtemplate);
    
    this.PrintReceipt(Newstr);
  }

  PrintReceipt(Newstr: any)
  {
    var mywindow = window.open('', 'PRINT', 'height=700,width=1000');
    mywindow.document.write(Newstr);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    mywindow.close();

    this.modalServe.dismissAll();

    return true;
  }

}


