<app-loader *ngIf="loader"></app-loader>

<div class="carousel slide" >
    <ol class="carousel-indicators">
        <li *ngFor="let screen of screens; let index = index"
         data-target="#carouselCaptions" [data-slide-to]="index" [ngClass]="activeScreen == (index+1) ? 'active' : ''"></li>
    </ol>
    <div class="carousel-inner">
        <div *ngFor="let screen of screens; let index = index" [ngClass]="activeScreen == (index+1) ? 'carousel-item active' : 'carousel-item'" >
            <div class="media media-16x9 gd-primary image-bg" [ngStyle]="{'background-image': 'url(' + screen + ')'}">
                <div class="media-content"></div>
            </div>
        </div>
        
    </div>
    <a *ngIf="activeScreen != 1" style="margin-left:-135px;" class="carousel-control-prev" (click)="setActiveScreen(-1)" role="button" data-slide="prev">

        <button class="btn btn-lg btn-raised btn-wave btn-icon btn-rounded mb-2 blue text-white">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </button>
    </a>
    <a *ngIf="activeScreen != cntScreen" style="margin-right:-135px;" class="carousel-control-next" (click)="setActiveScreen(1)" role="button" data-slide="next">
        <button class="btn btn-lg btn-raised btn-wave btn-icon btn-rounded mb-2 blue text-white">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </button>
    </a>

    <a *ngIf="activeScreen == cntScreen" style="margin-right:-185px;" class="carousel-control-next"  role="button" data-slide="next">
        <!-- <button class="btn btn-lg btn-raised btn-wave btn-icon btn-rounded mb-2 blue text-white" >
            I Understand The Reminders
        </button> -->

        <!-- <button class="btn btn-lg btn-raised btn-wave btn-icon btn-rounded mb-2 blue text-white" (click)="closeSplashScreen()">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </button> -->

        <!-- <button class="btn btn-lg btn-raised btn-wave btn-icon btn-rounded mb-2 green text-white" (click)="closeSplashScreen()">
            <span class="fa fa-check" aria-hidden="true"></span>
            <span class="sr-only">Finish</span>
        </button> -->

        <div class="card">
            <div class="card-body">
                <p class="card-text">I have read and understood the reminders.</p>
                <p class="card-text">
                    <button class="btn w-sm mb-1 btn-rounded btn-info" (click)="closeSplashScreen()">Continue</button>
                </p>
            </div>
        </div>

        <!-- <button class="btn w-sm mb-1 btn-primary" (click)="closeSplashScreen()"></button>
        <span class="sr-only">Finish</span> -->
    </a>
</div>
