<div  class="col-8 pt-5" style="float:left" >
    <div class="card">
      <div class="card-header">
        <strong>Tracker Longitude and Latitude</strong>
      </div>
      <div class="card-body">
        <app-textbox
            [parent-form-group]="storeAddressFormGroup"
            tab-index-start="0"
            control-name="longtitude"
            [error]="storeAddressFormGroup?.controls.longtitude?.errors | estatus"
            [error-message]="storeAddressFormGroup?.controls.longtitude?.errors | econtent"
            [read-only]=false
            label="Longtitude"
            placeholder="Longtitude"
            (keyup.enter)="initializeStartingMapPoint()"
        > </app-textbox>

      <app-textbox
        [parent-form-group]="storeAddressFormGroup"
        tab-index-start="0"
        control-name="latitude"
        [error]="storeAddressFormGroup?.controls.latitude?.errors | estatus"
        [error-message]="storeAddressFormGroup?.controls.latitude?.errors | econtent"
        [read-only]=false
        label="Latitude"
        placeholder="Latitude"
        (keyup.enter)="initializeStartingMapPoint()"
        > </app-textbox>

 
      <app-agm-map-single *ngIf="location" [location]="location" [dragPin]="dragPin" (updateCoordinates)="updateCoordinates($event)"></app-agm-map-single>


      </div>
    </div>
</div>

<div class="col-4 pt-5" style="float:left" >
    <div class="card">

        <div class="col-md-12">
            <div class="row row-sm">
                <div class="col-12">
                    <div class="card" data-sr-id="2" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                        <div class="card-body">
                            <div class="row row-sm">
                                <div class="col-4">
                                    <small class="text-muted">Total Stores</small>
                                    <div class="mt-2 font-weight-500" style="font-size:xx-large;"><span class="text-info">{{storeCount}}</span></div>
                                </div>
                                <div class="col-4">
                                    <small class="text-muted">Online Count</small>
                                    <div class="text-highlight mt-2 font-weight-500" style="font-size:xx-large;">{{getOnlineCount()}}</div>
                                </div>
                                <div class="col-4">
                                    <small class="text-muted">Pinned Stores</small>
                                    <div class="mt-2 font-weight-500" style="font-size:xx-large;">{{getPinnedStores()}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row row-sm">
                <div class="col-6 d-flex">
                    <div class="card flex" data-sr-id="3" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                        <div class="card-body">
                            <small>Resolved Tickets:
                                <strong class="text-primary">65%</strong>
                            </small>
                            <div class="progress my-3 circle" style="height:6px;">
                                <div class="progress-bar circle gd-primary" data-toggle="tooltip" title="" style="width: 65%" data-original-title="65%"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 d-flex">
                    <div class="card flex" data-sr-id="4" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                        <div class="card-body">
                            <small>Pending Tickets:
                                <strong class="text-primary">25%</strong>
                            </small>
                            <div class="progress my-3 circle" style="height:6px;">
                                <div class="progress-bar circle gd-warning" data-toggle="tooltip" title="" style="width: 25%" data-original-title="25%"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>

    </div>

    <div class="col-12" style="height: 925px;background-color: black;margin-top: -30px;scroll-behavior: smooth;overflow-y: scroll;" >
        <!-- <div style="color: white;" #divMessages>
           
        </div> -->
    </div>
    
</div>

