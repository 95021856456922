import { Component, OnInit } from '@angular/core';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';

@Component({
  selector: 'app-govip-express',
  templateUrl: './govip-express.component.html',
  styleUrls: ['./govip-express.component.less']
})
export class GovipExpressComponent implements OnInit {

  contentJson: any;
  constructor(private webService: WebSettingsService) { }

  ngOnInit(): void {
    this.initVIPContent();
  }

  initVIPContent(){
    this.webService.getWebSettingsByIdentifierNonAdmin("GOVIP_EXPRESS_CONTENT").subscribe((result) => { 
      this.contentJson = result.data;
      console.log(this.contentJson);
    });
  }
}
