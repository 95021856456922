import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-top-up-list",
  templateUrl: "./top-up-list.component.html",
  styleUrls: ["./top-up-list.component.less"],
})
export class TopUpListComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
