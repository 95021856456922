import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-top-up-view",
  templateUrl: "./top-up-view.component.html",
  styleUrls: ["./top-up-view.component.less"],
})
export class TopUpViewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
