import { CurrencyPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserService } from 'src/app/guards/user.service';
import { CryptoService } from 'src/data/crypto/crypto.service';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { AuthService } from 'src/data/services/authentication/auth.service';
import { ManagerUsersService } from 'src/data/services/web/manager-users.service';
import { RolesService } from 'src/data/services/web/roles.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { Data } from 'src/data/data';
import { ApplicationService } from 'src/data/services/web/application.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FileUploadService } from 'src/data/services/web/file-upload.service';
import { ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
//import { encodeBase64, saveAs } from '@progress/kendo-file-saver';
import { saveAs } from 'file-saver';
import { Content } from '@angular/compiler/src/render3/r3_ast';

PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5
@Component({
  selector: 'app-application-view',
  templateUrl: './application-view.component.html',
  styleUrls: ['./application-view.component.less']
})
export class ApplicationViewComponent implements OnInit {

  loader : any = false;
  @Input() id: string;

  public fileUploadFormGroup: FormGroup;
  public emptyString = "";
  public userID: any;

  public authService: AuthService;
  public appService: ApplicationService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public usersService: ManagerUsersService;
  public userService: UserService;
  public modalService: BsModalService;
  public accessToken: string;
  public fileUploadService: FileUploadService;

  public roleLevelId: any;
  public roleId: any;
  public roles: any;
  public role: any;
  public user: any;
  public userId: any;
  public username: any;
  public managerFiles: any;

  public appID: any;
  public application: any;

  public modalRemarksRef: NgbModalRef;
  public modalUploadRef: NgbModalRef;
  public modalLargeIMageViewRef: NgbModalRef;
  public modalConfirmRef: NgbModalRef;
  public modalLinkUpdateRef: NgbModalRef;

  public applicationFormGroup: FormGroup;
  public valueFormGroup: FormGroup;
  public thumbnailFormGroup: FormGroup;
  public acquiredFormGroup: FormGroup;
  public purchaseProgressFormArray: FormArray;
  public requirementsFormArray: FormArray;
  public selectedApplication: any;
  public formBuilder: FormBuilder;
  public saveApplicationProcessing = false;
  public applicationsService: ApplicationService;
  public progressIndex: any = 0;
  public curProgress: any = 0;
  public reqIndex: any = 0;
  public remarksStr : any;
  public linkStr: any;
  public progressCount: any;
  public progressName: any;

  public acquired: any;
  public thumbnail: any;
  public purchase_progress: any;

  public requirementsList: any;
  public isCompleted: boolean = false;
  public fileData: any;
  public fileToDL: any;

  public base64Photo: string | ArrayBuffer;
  public RequirementsIndex: any;
  public managerUserId: any;
  
  constructor(private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    private currencyPipe: CurrencyPipe,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    usersService: ManagerUsersService,
    applicationsService: ApplicationService,
    modalService: BsModalService,
    userService: UserService,
    appService: ApplicationService,
    private modalServe: NgbModal,
    private modalServeConfirm: NgbModal,
    private uploadModal: NgbModal,
    private sanitizer: DomSanitizer,
    fileUploadService: FileUploadService,

    formBuilder: FormBuilder,
  ) {
    this.appService = appService;
    this.fileUploadService = fileUploadService;
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.applicationsService = applicationsService;
    this.usersService = usersService;
    this.modalService = modalService;
    this.userService= userService;
    this.formBuilder = formBuilder;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");

    PNotifyButtons; // Initiate PNotify buttons. Important!
  }

  ngOnInit(): void {
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    this.userID = this.localStorageCacheService.getStorage("userId");
    this.username = this.localStorageCacheService.getStorage("username");
    this.getProgress();
    this.initFileUploadFormGroup();
  }

  initFileUploadFormGroup(): void {
    this.fileUploadFormGroup = new FormGroup({
      photoFile: new FormControl(this.emptyString)
    });
  }

  getProgress(){
    this.route.params.subscribe((params) => {
      const id = decodeURI(params.id || "");

      if(id && this.roleLevelId == 2){
        this.appID = this.cryptoService.get(this.data.authentication.privateKey, id);
        this.appService.getApplicationById(this.appID).subscribe((result) => {

          
          const application = JSON.parse(result.meta);
          this.application = result;
          this.managerUserId = result.userId;
          this.purchase_progress = application.value.acquired.purchase_progress;
          this.progressIndex = this.getPosition(application.value.acquired.purchase_progress, application.value.acquired.purchase_progress.length);
          this.progressName = application.value.acquired.purchase_progress[this.progressIndex].title;
          this.progressCount = application.value.acquired.purchase_progress.length - 1;

          this.openContentDonePurchased(result);
          this.getManager(result.userId);
        });
      } else if(this.id && this.roleLevelId == 1){
        this.appID = this.cryptoService.get(this.data.authentication.privateKey, this.id);
        this.appService.getApplicationById(this.appID).subscribe((result) => {

         
          const application = JSON.parse(result.meta);
          this.application = result;
          this.managerUserId = result.userId;
          this.purchase_progress = application.value.acquired.purchase_progress;
          this.progressIndex = this.getPosition(application.value.acquired.purchase_progress, application.value.acquired.purchase_progress.length);
          this.progressName = application.value.acquired.purchase_progress[this.progressIndex].title;
          this.progressCount = application.value.acquired.purchase_progress.length - 1;

          this.openContentDonePurchased(result);
          this.getManager(result.userId);
        });
      }
    });

    
  }

  public imagePreview: any;
  public photoFormat: any;
  public photo: any;
  public photoFileName: any;
  fileType : any = "IMAGE";
  public previewModal = null;
  
  async preview(content: any,filename : any){
    //console.log(this.user.username + " ???? " + filename);
    await this.fileUploadService.GetFileRequirements(this.user.username,filename).then(data =>{
      this.photoFormat = data.photoFormat;
      this.photo = data.photo;
      this.photoFileName = filename;
        if(data.photoFormat == 'data:application/pdf;base64'){
          this.imagePreview = data.photo;
          this.fileType = "PDF";
        }else{
          this.imagePreview = `${data.photoFormat},${data.photo}`;
          this.fileType = "IMAGE";
        }
      this.previewModal = this.modalServe.open(content, { centered: true,size:'xl', backdrop: 'static'});
    });
  }

  getManagerFiles(userID: any){

    this.fileUploadService.getManagerFiles(userID).subscribe((result) => {
      this.managerFiles = result;
    });

    
  }
  
  getManager(userID: any){
    this.usersService.getManagerUser(userID).subscribe((result) => {
      this.user = result.data;
    });
  }

  goBack(){
    if(this.roleId != 3){
      this.router.navigate(["acm", "application-list"]);
    } else if (this.roleId == 3){
      this.router.navigate(["manager", "market-place"]);
    }
    
  }
 

  viewRequirements(ii: any){
    
    this.progressIndex = ii;
    this.progressName = this.purchase_progress[ii].title;
   
  }

  viewImage(file_name: any){
    
    this.fileUploadService.getSingleFile(file_name).subscribe((result) => {
      if(result){
        this.fileData = result;
      } else {
        PNotify.error({
          title: "File Not Found!",
          text: "File has been removed or deleted! \nPlease upload new file.",
        });
      }
    });
  }

  downloadImage(file_id: any){
    
    this.fileUploadService.getSingleFile(file_id).subscribe((result) => {
      this.fileToDL = result;
      debugger
      
      const dataURI = result.photoFormat + "," + result.photo;
      saveAs(dataURI, result.fileName);
    });
  }

  getFormPosition(progress: FormArray) {
     

    var counter = 0;

    for(let prog of progress.controls){
      if(prog.value.status){
        counter++;
      }
    }
    
    
    return counter == 0 ? 0 : (counter - 1);
  }

  getPosition(progress: any, progressLength: any) {
    var counter = 0;

    progress.forEach(element => {
      if(element.status && counter < progressLength - 1){
        counter++;
          
          this.curProgress++;
      }
      
    });

    
    return counter;
  }

  checkCompleted(progress: any){
    var counter = 0;

    progress.forEach(element => {
      if(!element.status){
        counter++;
      }
    });

    if (counter == 0) {
      this.isCompleted = true;
    }
  }

  checkCompleteRequirements(req_progress: any){
    var counter = 0;
    
    
    for(let req_prog of req_progress){
      if(!req_prog.controls.req_status.value){
        if(req_prog.controls.is_upload.value){
          counter++;
        }
      }
    }

    
    return counter == 0 ? false : true;
  }

  checkIfHasInput(req_progress: any){
    var hasInput = false;

    for(let req_prog of req_progress){
      if(req_prog.controls.is_for_manager.value){
        hasInput = true;
      }
    }

    return hasInput;
  }

  addRemarks(content: any, reqIndex: any, remarksValue: any){
    this.reqIndex = reqIndex;

    //this.purchaseProgressFormArray.controls[this.progressIndex].controls.requirements.controls[reqIndex].controls.remarks.value

    this.remarksStr = remarksValue.controls[reqIndex].controls.remarks.value;
    const modal = this.uploadModal.open(content, { centered: true, size: "md" });
    this.modalRemarksRef = modal;
  }

  getRemarks(ii: any){
    this.reqIndex = ii;
  }

  submitRemarks(remarks: FormControl){
    
    remarks.setValue(this.remarksStr);
    
    this.saveRemarks(false);
    this.modalRemarksRef.close();
  }

  saveRemarks(isProceed) {
    this.saveApplicationProcessing = true;
    clearInterval(this.interval);

    const json = {
      id: this.selectedApplication.id,
      userId: this.selectedApplication.userId,
      meta: JSON.stringify(this.applicationFormGroup.value),
    };
    
    
    if(this.modalConfirmRef) this.modalConfirmRef.close();
    if(this.modalUploadRef) this.modalUploadRef.close();
    if(this.modalRemarksRef) this.modalRemarksRef.close();
    
    this.applicationsService.saveAndEmailRemarks(this.selectedApplication.id, json, isProceed, this.progressIndex - 1, this.remarksStr).subscribe(
      (result) => {
        PNotify.success({
          title: "Success",
          text: "Application progress updated successfully.",
        });
      },
      (error) => {
        if (error.status === 400) {
          PNotify.error({
            title: "Error",
            text: "Bad request. Please try again.",
          });
        } else {
          PNotify.error({
            title: "Error",
            text: "Internal server error.",
          });
        }
      }
    );
  }

  proceedToNext(status: FormControl){
    
    
    if(this.progressIndex < this.progressCount){
      this.progressIndex++;
      
    }
    status.setValue(true);

    this.saveProgress(true);
    
    
  }


  proceedToNextConditional(status: FormControl , conditional : any){
   


    if(this.progressIndex < this.progressCount){
      this.progressIndex++;
    }

    if(conditional == true){
      status.setValue(true);
      this.saveProgress(true);
    }else{
      status.setValue(true);
      this.modalConfirmRef.close();
      this.saveProgressDeclined(true);
      
    }
   
 
  }

  viewLargeImage(content: any){
    const modal = this.uploadModal.open(content, { centered: true, size: "lg" });
    this.modalLargeIMageViewRef = modal;
  }

  public fileName: any = "Click here to choose file...";

  public onPhotoChange(event: any) {
    const reader = new FileReader();
    
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.fileName = file.name;
      reader.onload = () => {
        this.base64Photo = reader.result;

        this.fileUploadFormGroup.patchValue({
          photoFile: reader.result,
        });

        this.changeDetectorRef.markForCheck();
      };
    }
  }

  uploadFileModal(content: any, i: any){
    this.RequirementsIndex = i;
    this.reqIndex = i;
    this.fileUploadFormGroup?.controls.photoFile.setValue("");
    const modal = this.uploadModal.open(content, { centered: true, size: "md" });
    this.modalUploadRef = modal;
  }

  submitFile(photo: any){
    this.loader = true;
   
    var filename = this.username + "'s " + photo.name.value;
    

    var managerId = 0;
    if(this.roleLevelId == 2){
       managerId = this.userID;
    } else {
      managerId = this.application.userId;
      filename = this.application.user.username + "'s " + photo.name.value;
    }

    this.fileUploadService.PostFileUploadManager(this.fileUploadFormGroup.value, managerId, filename)
    .subscribe((result) => {
      this.fileUploadFormGroup.patchValue({
        photoFile: "",
      });
      this.fileName = "";
      photo.file_id.setValue(result);
      photo.file_name.setValue(filename);
      this.saveProgress(false);
      
      this.loader = false;

     
      PNotify.success({
        title: "Success",
        text: "File uploaded successfully!",
      });

      this.modalUploadRef.close();
    }, error => {
      PNotify.error({
        title: "Failed",
        text: "Error uploading file!",
      });
      this.loader = false;
    });
  }

  buildUserPhoto(managerFile: any): any {
    return managerFile?.photoFormat + "," + managerFile?.photo;
  }

  // public applicationFormGroup: FormGroup;
  // public valueFormGroup: FormGroup;
  // public thumbnailFormGroup: FormGroup;
  // public acquiredFormGroup: FormGroup;
  // public purchaseProgressFormArray: FormArray;
  // public selectedApplication: any;

  openContentDonePurchased(_application: any): void {
    
    this.selectedApplication = _application;
    const application = JSON.parse(_application.meta);
    
    this.initApplicationFormGroup(application);

    this.valueFormGroup = this.applicationFormGroup.controls.value as FormGroup;
    this.thumbnailFormGroup = this.valueFormGroup.controls.thumbnail as FormGroup;
    this.acquiredFormGroup = this.valueFormGroup.controls.acquired as FormGroup;
    this.purchaseProgressFormArray = this.acquiredFormGroup.controls.purchase_progress as FormArray;
    
  }

  initApplicationFormGroup(application: any): void {
    this.applicationFormGroup = new FormGroup({
      parameter: new FormControl(application.parameter),
      value: this.populateValueFormGroup(application.value),
    });
  }

  populateValueFormGroup(value: any): FormGroup {
    return new FormGroup({
      thumbnail: this.populateThumbnailFormGroup(value.thumbnail),
      acquired: this.populateAcquiredFormGroup(value.acquired),
      active: new FormControl(value.active),
      network: new FormControl(value.network),
    });
  }

  populateThumbnailFormGroup(thumbnail: any): FormGroup {
    return new FormGroup({
      title: new FormControl(thumbnail.title),
      logo: new FormControl(thumbnail.logo),
      price: new FormControl(thumbnail.price),
      details: new FormControl({}),
    });
  }

  populateAcquiredFormGroup(acquired: any): FormGroup {
    const acquiredFormGroup: FormGroup = new FormGroup({
      title: new FormControl(acquired.title),
      logo: new FormControl(acquired.logo),
      description: new FormControl(acquired.description),
      purchase_progress: this.formBuilder.array([]),
    });
    
    this.populatePurchaseProgressFormArray(acquired.purchase_progress, acquiredFormGroup);
    return acquiredFormGroup;
  }

  populatePurchaseProgressFormArray(purchase_progress: any, acquiredFormGroup: FormGroup): void {
    purchase_progress.forEach((step, index) => {
     
      const purchaseProgressFormGroup = this.formBuilder.group({
        title: new FormControl(step.title),
        icon: new FormControl(step.icon),
        requirements: this.formBuilder.array([]),
        instruction: new FormControl(step.instruction),
        emailNotification: this.populateEmailNotificationFormGroup(step.emailNotification),
        systemTrigger: new FormControl(step.systemTrigger),
        download: step.download? this.populateDownloadFormGroup(step.download) : null,
        status: new FormControl(step.status),
        conditional: new FormControl(step.conditional),
      });

      
      this.populateRequirementsFormArray(purchase_progress[index].requirements, purchaseProgressFormGroup);
      (acquiredFormGroup.controls.purchase_progress as FormArray).push(purchaseProgressFormGroup);

    });
  }

  populateRequirementsFormArray(requirements: any, purchaseProgressFromGroup: FormGroup): void {
    requirements.forEach((step) => {
      const requirementsFormGroup = this.formBuilder.group({
        name: new FormControl(step.name),
        is_upload: new FormControl(step.is_upload),
        is_auto: new FormControl(step.is_auto),
        is_input: new FormControl(step.is_input),
        is_image: new FormControl(step.is_image),
        is_for_manager: new FormControl(step.is_for_manager),
        input_title: new FormControl(step.input_title),
        input_value: new FormControl(step.input_value),
        file_id: new FormControl(step.file_id),
        file_name: new FormControl(step.file_name),
        req_status: new FormControl(step.req_status),
        remarks: new FormControl(step.remarks),
        is_api_hook: new FormControl(step.is_api_hook),
        isHidden: new FormControl(step.isHidden),
        api_hook_meta: step.is_api_hook  ? this.populateApiHookMetaFormGroup(step.api_hook_meta) : null
      });

      (purchaseProgressFromGroup.controls.requirements as FormArray).push(requirementsFormGroup);
       
    });

     
  }


  populateApiHookMetaFormGroup(api_hook_meta: any): FormGroup {
    const apiHookMetaFormGroup: FormGroup = new FormGroup({
      url: new FormControl(api_hook_meta.url),
      method: new FormControl(api_hook_meta.method),
      type: new FormControl(api_hook_meta.type),
      hook_type: new FormControl(api_hook_meta.hook_type),
      parameters: this.formBuilder.array([]),
    });
    this.populateApiHookMetaParamsFormArray(apiHookMetaFormGroup.controls.parameters as FormArray,api_hook_meta.parameters,api_hook_meta.url);
    return apiHookMetaFormGroup;
  }

  populateApiHookMetaParamsFormArray(paramsFormArray : FormArray,  parameters: any, apiHookMetaUrl : any) {

    
    this.appService.getAPIHookInternal(apiHookMetaUrl.replace("{UserId}",this.managerUserId)).subscribe((result) => {
       

      parameters.forEach((step) => {

        const paramsFormGroup = this.formBuilder.group({
          label: new FormControl(step.label),
          param: new FormControl(step.param),
          value: new FormControl(result[step.param])
        });
        paramsFormArray.push(paramsFormGroup);
      });

    });


  

  }

  populateDownloadFormGroup(download: any): FormGroup{
    return new FormGroup({
      file_name: new FormControl(download?.file_name),
      link: new FormControl(download?.link)
    });
  }

  populateEmailNotificationFormGroup(emailNotification: any): FormGroup {
    const emailNotificationFormGroup = this.formBuilder.group({
      status: new FormControl(emailNotification.status),
      identifier: new FormControl(emailNotification.identifier),
      template: new FormControl(emailNotification.template),
      emailJob: this.formBuilder.array([]),
      overideTitle: this.formBuilder.array([])
    });
   if(emailNotification.emailJob) this.populateEmailJobFormArray(emailNotification.emailJob, emailNotificationFormGroup);
   if(emailNotification.overideTitle) this.populateOverrideTitleFormArray(emailNotification.overideTitle, emailNotificationFormGroup);
    

    return emailNotificationFormGroup;
  }

  populateEmailJobFormArray(emailJob: any, emailNotificationFormGroup: FormGroup): void {
    emailJob.forEach((step) => {
      const emailJobFormGroup = this.formBuilder.group({
        recipient: new FormControl(step.recipient),
        address: new FormControl(step.address)
      });

      (emailNotificationFormGroup.controls.emailJob as FormArray).push(emailJobFormGroup);
    });
  }

  populateOverrideTitleFormArray(overideTitle: any, emailNotificationFormGroup: FormGroup): void {
    overideTitle.forEach((step) => {
      const overideTitleFormGroup = this.formBuilder.group({
        reference: new FormControl(step.reference),
        self: new FormControl(step.self)
      });

      (emailNotificationFormGroup.controls.overideTitle as FormArray).push(overideTitleFormGroup);
    });
  }

  parametersFormArray(paramMeta : FormGroup) : FormArray{
     const paramsFormArray :FormArray= (paramMeta.controls.parameters as FormArray);
     return paramsFormArray.value;

  }

  openProgress(content: any): void {
    this.modalServe.open(content, { centered: true, size: "lg" })
  }

  openUpdateLinkModal(content: any): void {
    const modal = this.modalServe.open(content, { centered: true, size: "lg" });
    this.modalLinkUpdateRef = modal;
  }

  checkProgressClass(status: any) {
    return status ? "step0 active" : "step0";
  }

  changeProgresStatus(status: FormControl) {
    
  }

  reqStatusChange(status: FormControl){
    status.setValue(!status.value);
  }

  submitDownloadLinkUpdate(link: FormControl){
    link.setValue(this.linkStr);
    console.log(this.purchaseProgressFormArray);
    this.modalLinkUpdateRef.close();
  }

  onSearchChange(val: string, input_value: FormControl): void {  
    input_value.setValue(val);
  }

  confrimProcess(content: any){
   const modal = this.modalServeConfirm.open(content, { centered: true, size: "md" });
   this.modalConfirmRef = modal;
  }

  interval;
  saveProgress(isProceed) {
    this.saveApplicationProcessing = true;
    clearInterval(this.interval);
 
    debugger 
    const json = {
      id: this.selectedApplication.id,
      userId: this.selectedApplication.userId,
      meta: JSON.stringify(this.applicationFormGroup.value),
    };
    
    
    if(this.modalConfirmRef) this.modalConfirmRef.close();
    if(this.modalUploadRef) this.modalUploadRef.close();
    if(this.modalRemarksRef) this.modalRemarksRef.close();
    
    this.applicationsService.updateApplication(this.selectedApplication.id, json, isProceed, this.progressIndex - 1).subscribe(
      (result) => {
        PNotify.success({
          title: "Success",
          text: "Application progress updated successfully.",
        });
      },
      (error) => {
        if (error.status === 400) {
          PNotify.error({
            title: "Error",
            text: "Bad request. Please try again.",
          });
        } else {
          PNotify.error({
            title: "Error",
            text: "Internal server error.",
          });
        }
      }
    );
  }

 
  saveProgressDeclined(isProceed) {
    this.loader = true;
    this.saveApplicationProcessing = true;
    clearInterval(this.interval);

    const json = {
      id: this.selectedApplication.id,
      userId: this.selectedApplication.userId,
      meta: JSON.stringify(this.applicationFormGroup.value),
    };
     
    this.applicationsService.updateApplicationDeclined(this.selectedApplication.id, json, isProceed, this.progressIndex - 1, this.remarksStr).subscribe(
      (result) => {
        PNotify.success({
          title: "Information",
          text: "Application progress updated to declined successfully.",
        });
       
        this.getProgress();
        this.initFileUploadFormGroup();
        this.loader = false;
       
      },
      (error) => {
        if (error.status === 400) {
          PNotify.error({
            title: "Error",
            text: "Bad request. Please try again.",
          });
        } else {
          PNotify.error({
            title: "Error",
            text: "Internal server error.",
          });
        }
      }
    );
  }

  modalSmsTransaction;
  mobileNo;
  openSendSms(smsModal, mobileNo) {
    this.mobileNo = mobileNo;
    this.modalSmsTransaction = this.modalServe.open(smsModal, {
      centered: true,
      size: "xl",
      backdrop: "static",
      keyboard: false,
    });
  }

  closeModal(){
    this.modalSmsTransaction.close();
  }


  openConfirmRefund(content: any){
    const modal = this.modalServeConfirm.open(content, { centered: true, size: "md" });
    this.modalConfirmRef = modal;
   }

  refundApplication(){
    this.loader = true;
    
    const json = {
      id: this.selectedApplication.id,
      userId: this.selectedApplication.userId,
      meta: JSON.stringify(this.applicationFormGroup.value),
    };
    

    this.applicationsService.updateApplicationRefund(this.selectedApplication.id, json, this.progressIndex - 1).subscribe(
      (result) => {
        PNotify.success({
          title: "Information",
          text: "Application successfully refunded.",
        });
       
        this.modalConfirmRef.close();
        this.getProgress();
        this.loader = false;
       
      },
      (error) => {
        if (error.status === 400) {
          PNotify.error({
            title: "Error",
            text: "Bad request. Please try again.",
          });
        } else {
          PNotify.error({
            title: "Error",
            text: "Internal server error.",
          });
        }
      }
    )
  }
}
