<div>
    <div class="col-12" *ngIf="storeHoursFormGroup">
        <strong style="font-size: 15px;">Store Hours</strong>
        <button class="btn btn-sm btn-primary float-right mb-2" *ngIf="roleId == 3" (click)="uploadStoreHours()">Update Store Hours &nbsp; <i class="fa fa-edit"></i></button>
        <br>
        <strong style="font-size: 12px;"><span class="text-danger">*</span> &nbsp; (Opening and Closing Hours are required if day is selected)</strong>
        <hr>

        <div class="row" [formGroup]="storeHoursFormGroup">
          <div class="col-6">
            <div class="d-flex flex-column">
              <div class="pr-2 pl-2 pb-0 w-100">
                <app-boolslider
                  [parentFormGroup]="storeHoursFormGroup"
                  controlName="monday"
                  label="Monday"
                  [readonly]="roleId != 3"
                  >
                </app-boolslider>
              </div>
              <div class="pl-2 pr-2 pt-0">
                <div class="d-flex justify-content-around">
                  <div class="pl-2 pr-2 pt-0" style="vertical-align: middle; width: 50%;">
                    <div class="row">
                        <label class="col-sm-4 col-form-label">Open: </label>
                        <div class="col-sm-8">
                            <ng-select [items]="timeTemplateArray"
                                parentFormGroup="storeHoursFormGroup" 
                                place-holder="00:00 AM/PM"
                                bindLabel="text" 
                                bindValue="id" 
                                #mondayOpenSelect
                                formControlName="mondayStart">
                            </ng-select>
                        </div>
                    </div>
                  </div>
                  <div class="pl-2 pr-2 pt-0" style="vertical-align: middle; width: 50%;">
                    <div class="row">
                        <label class="col-sm-4 col-form-label">Close: </label>
                        <div class="col-sm-8">
                            <ng-select [items]="timeTemplateArray"
                                parentFormGroup="storeHoursFormGroup" 
                                place-holder="00:00 AM/PM"
                                bindLabel="text" 
                                bindValue="id" 
                                #mondayCloseSelect
                                formControlName="mondayEnd">
                            </ng-select>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr>

              <div class="pr-2 pl-2 pb-0 w-100">
                <app-boolslider
                  [parentFormGroup]="storeHoursFormGroup"
                  controlName="tuesday"
                  label="Tuesday"
                  [readonly]="roleId != 3"
                  >
                </app-boolslider>
              </div>
              <div class="pl-2 pr-2 pt-0">
                <div class="d-flex justify-content-around">
                  <div class="pl-2 pr-2 pt-0" style="vertical-align: middle; width: 50%;">
                    <div class="row">
                        <label class="col-sm-4 col-form-label">Open: </label>
                        <div class="col-sm-8">
                            <ng-select [items]="timeTemplateArray"
                                parentFormGroup="storeHoursFormGroup" 
                                place-holder="00:00 AM/PM"
                                bindLabel="text" 
                                bindValue="id" 
                                #tuesdayOpenSelect
                                formControlName="tuesdayStart">
                            </ng-select>
                        </div>
                    </div>
                  </div>
                  <div class="pl-2 pr-2 pt-0" style="vertical-align: middle; width: 50%;">
                    <div class="row">
                        <label class="col-sm-4 col-form-label">Close: </label>
                        <div class="col-sm-8">
                            <ng-select [items]="timeTemplateArray"
                                parentFormGroup="storeHoursFormGroup" 
                                place-holder="00:00 AM/PM"
                                bindLabel="text" 
                                bindValue="id" 
                                #tuesdayCloseSelect
                                formControlName="tuesdayEnd">
                            </ng-select>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr>

              <div class="pr-2 pl-2 pb-0 w-100">
                <app-boolslider
                  [parentFormGroup]="storeHoursFormGroup"
                  controlName="wednesday"
                  label="Wednesday"
                  [readonly]="roleId != 3"
                  >
                </app-boolslider>
              </div>
              <div class="pl-2 pr-2 pt-0">
                <div class="d-flex justify-content-around">
                  <div class="pl-2 pr-2 pt-0" style="vertical-align: middle; width: 50%;">
                    <div class="row">
                        <label class="col-sm-4 col-form-label">Open: </label>
                        <div class="col-sm-8">
                            <ng-select [items]="timeTemplateArray"
                                parentFormGroup="storeHoursFormGroup" 
                                place-holder="00:00 AM/PM"
                                bindLabel="text" 
                                bindValue="id" 
                                #wednesdayOpenSelect
                                formControlName="wednesdayStart">
                            </ng-select>
                        </div>
                    </div>
                  </div>
                  <div class="pl-2 pr-2 pt-0" style="vertical-align: middle; width: 50%;">
                    <div class="row">
                        <label class="col-sm-4 col-form-label">Close: </label>
                        <div class="col-sm-8">
                            <ng-select [items]="timeTemplateArray"
                                parentFormGroup="storeHoursFormGroup" 
                                place-holder="00:00 AM/PM"
                                bindLabel="text" 
                                bindValue="id" 
                                #wednesdayCloseSelect
                                formControlName="wednesdayEnd">
                            </ng-select>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr>

              <div class="pr-2 pl-2 pb-0 w-100">
                <app-boolslider
                  [parentFormGroup]="storeHoursFormGroup"
                  controlName="thursday"
                  label="Thursday"
                  [readonly]="roleId != 3"
                  >
                </app-boolslider>
              </div>
              <div class="pl-2 pr-2 pt-0">
                <div class="d-flex justify-content-around">
                  <div class="pl-2 pr-2 pt-0" style="vertical-align: middle; width: 50%;">
                    <div class="row">
                        <label class="col-sm-4 col-form-label">Open: </label>
                        <div class="col-sm-8">
                            <ng-select [items]="timeTemplateArray"
                                parentFormGroup="storeHoursFormGroup" 
                                place-holder="00:00 AM/PM"
                                bindLabel="text" 
                                bindValue="id" 
                                #thursdayOpenSelect
                                formControlName="thursdayStart">
                            </ng-select>
                        </div>
                    </div>
                  </div>
                  <div class="pl-2 pr-2 pt-0" style="vertical-align: middle; width: 50%;">
                    <div class="row">
                        <label class="col-sm-4 col-form-label">Close: </label>
                        <div class="col-sm-8">
                            <ng-select [items]="timeTemplateArray"
                                parentFormGroup="storeHoursFormGroup" 
                                place-holder="00:00 AM/PM"
                                bindLabel="text" 
                                bindValue="id" 
                                #thursdayCloseSelect
                                formControlName="thursdayEnd">
                            </ng-select>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="col-6">
            <div class="d-flex flex-column">
              <div class="pr-2 pl-2 pb-0 w-100">
                <app-boolslider
                  [parentFormGroup]="storeHoursFormGroup"
                  controlName="friday"
                  label="Friday"
                  [readonly]="roleId != 3"
                  >
                </app-boolslider>
              </div>
              <div class="pl-2 pr-2 pt-0">
                <div class="d-flex justify-content-around">
                  <div class="pl-2 pr-2 pt-0" style="vertical-align: middle; width: 50%;">
                    <div class="row">
                        <label class="col-sm-4 col-form-label">Open: </label>
                        <div class="col-sm-8">
                            <ng-select [items]="timeTemplateArray"
                                parentFormGroup="storeHoursFormGroup" 
                                place-holder="00:00 AM/PM"
                                bindLabel="text" 
                                bindValue="id" 
                                #fridayOpenSelect
                                formControlName="fridayStart">
                            </ng-select>
                        </div>
                    </div>
                  </div>
                  <div class="pl-2 pr-2 pt-0" style="vertical-align: middle; width: 50%;">
                    <div class="row">
                        <label class="col-sm-4 col-form-label">Close: </label>
                        <div class="col-sm-8">
                            <ng-select [items]="timeTemplateArray"
                                parentFormGroup="storeHoursFormGroup" 
                                place-holder="00:00 AM/PM"
                                bindLabel="text" 
                                bindValue="id" 
                                #fridayCloseSelect
                                formControlName="fridayEnd">
                            </ng-select>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr>

              <div class="pr-2 pl-2 pb-0 w-100">
                <app-boolslider
                  [parentFormGroup]="storeHoursFormGroup"
                  controlName="saturday"
                  label="Saturday"
                  [readonly]="roleId != 3"
                  >
                </app-boolslider>
              </div>
              <div class="pl-2 pr-2 pt-0">
                <div class="d-flex justify-content-around">
                  <div class="pl-2 pr-2 pt-0" style="vertical-align: middle; width: 50%;">
                    <div class="row">
                        <label class="col-sm-4 col-form-label">Open: </label>
                        <div class="col-sm-8">
                            <ng-select [items]="timeTemplateArray"
                                parentFormGroup="storeHoursFormGroup" 
                                place-holder="00:00 AM/PM"
                                bindLabel="text" 
                                bindValue="id" 
                                #saturdayOpenSelect
                                formControlName="saturdayStart">
                            </ng-select>
                        </div>
                    </div>
                  </div>
                  <div class="pl-2 pr-2 pt-0" style="vertical-align: middle; width: 50%;">
                    <div class="row">
                        <label class="col-sm-4 col-form-label">Close: </label>
                        <div class="col-sm-8">
                            <ng-select [items]="timeTemplateArray"
                                parentFormGroup="storeHoursFormGroup" 
                                place-holder="00:00 AM/PM"
                                bindLabel="text" 
                                bindValue="id" 
                                #saturdayCloseSelect
                                formControlName="saturdayEnd">
                            </ng-select>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr>

              <div class="pr-2 pl-2 pb-0 w-100">
                <app-boolslider
                  [parentFormGroup]="storeHoursFormGroup"
                  controlName="sunday"
                  label="Sunday"
                  [readonly]="roleId != 3"
                  >
                </app-boolslider>
              </div>
              <div class="pl-2 pr-2 pt-0">
                <div class="d-flex justify-content-around">
                  <div class="pl-2 pr-2 pt-0" style="vertical-align: middle; width: 50%;">
                    <div class="row">
                        <label class="col-sm-4 col-form-label">Open: </label>
                        <div class="col-sm-8">
                            <ng-select [items]="timeTemplateArray"
                                parentFormGroup="storeHoursFormGroup" 
                                place-holder="00:00 AM/PM"
                                bindLabel="text" 
                                bindValue="id" 
                                #sundayOpenSelect
                                formControlName="sundayStart">
                            </ng-select>
                        </div>
                    </div>
                  </div>
                  <div class="pl-2 pr-2 pt-0" style="vertical-align: middle; width: 50%;">
                    <div class="row">
                        <label class="col-sm-4 col-form-label">Close: </label>
                        <div class="col-sm-8">
                            <ng-select [items]="timeTemplateArray"
                                parentFormGroup="storeHoursFormGroup" 
                                place-holder="00:00 AM/PM"
                                bindLabel="text" 
                                bindValue="id" 
                                #sundayCloseSelect
                                formControlName="sundayEnd">
                            </ng-select>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr>

              <div class="pr-2 pl-2 pb-0 w-100">
                <app-boolslider
                  [parentFormGroup]="storeHoursFormGroup"
                  controlName="holiday"
                  label="Holiday"
                  [readonly]="roleId != 3"
                  >
                </app-boolslider>
              </div>
              <div class="pl-2 pr-2 pt-0">
                <div class="d-flex justify-content-around">
                  <div class="pl-2 pr-2 pt-0" style="vertical-align: middle; width: 50%;">
                    <div class="row">
                        <label class="col-sm-4 col-form-label">Open: </label>
                        <div class="col-sm-8">
                            <ng-select [items]="timeTemplateArray"
                                parentFormGroup="storeHoursFormGroup" 
                                place-holder="00:00 AM/PM"
                                bindLabel="text" 
                                bindValue="id" 
                                #holidayOpenSelect
                                formControlName="holidayStart">
                            </ng-select>
                        </div>
                    </div>
                  </div>
                  <div class="pl-2 pr-2 pt-0" style="vertical-align: middle; width: 50%;">
                    <div class="row">
                        <label class="col-sm-4 col-form-label">Close: </label>
                        <div class="col-sm-8">
                            <ng-select [items]="timeTemplateArray"
                                parentFormGroup="storeHoursFormGroup" 
                                place-holder="00:00 AM/PM"
                                bindLabel="text" 
                                bindValue="id" 
                                #holidayCloseSelect
                                formControlName="holidayEnd">
                            </ng-select>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="col-12">
            <hr>
            <div class="d-flex flex-column">
              <div class="p-2">
                <app-textbox
                  [parent-form-group]="storeHoursFormGroup"
                  tab-index-start="0"
                  control-name="specific_holiday"
                  [read-only]="roleId != 3"
                  label="Specify Holidays"
                  placeholder="Ex. All Holiday except Christmas and New Year"
                >
                </app-textbox>
              </div>
            </div>
          </div>
        </div>

      </div>
</div>