<div class="flex scrollable hover overflow-auto">
    <div class="nav-active-text-primary">
      <ul class="nav bg">
        <li class="nav-header hidden-folded">
          <span class="text-primary">Main</span>
        </li>
        <li>
          <a *ngIf="isPartnerSunlife" href="#" routerLink="partner-sunlife-grepa/reports">
            <span class="nav-icon"><i class="fa fa-th-list"></i></span>
            <span class="nav-text">Reports</span>
          </a>
        </li>
        <li>
          <a *ngIf="isPartnerVIPProtect" href="#" routerLink="partner-vip-protect/reports">
            <span class="nav-icon"><i class="fa fa-th-list"></i></span>
            <span class="nav-text">Reports</span>
          </a>
        </li>
        <li  *ngIf="!isPartner">
          <a href="#" routerLink="{{ roleLevelName }}/">
            <span class="nav-icon"><i class="fa fa-home"></i></span>
            <span class="nav-text">Dashboard</span>
          </a>
        </li>
        <li *ngIf="!isPartner">
          <a routerLink="{{ roleLevelName }}/{{ roleLevelName == 'acm' ? 'company' : roleLevelName }}-user-view" class="">
            <span class="nav-icon"><i class="fa fa-user"></i></span>
            <span class="nav-text">View Profile</span>
          </a>
        </li>
        <li *ngIf="soaModule">
          <a href="#soa" class="" data-toggle="collapse" aria-expanded="false">
            <span class="nav-icon"><i class="fa fa-archive"></i></span>
            <span class="nav-text">Statement of Account</span>
            <span class="nav-caret"></span>
          </a>
            <ul class="collapse list-unstyled" id="soa">
              <li *ngIf="individualSoaModule">
                <a
                  routerLink="{{ roleLevelName }}/{{
                    roleLevelName == 'acm' ? 'transaction-list-acm' : 'transaction-list-acm'
                  }}"
                  class=""
                >
                  <span class="nav-text">Fund History</span>
                </a>
        </li>
              <li *ngIf="allSoaModule">
                <a *ngIf="roleLevelId == 1" routerLink="acm/transaction-list-all" class="">
                  <span class="nav-text">Global Transaction</span>
                </a>
              </li>
              <li *ngIf="transactionHistoryModule">
                <a routerLink="{{ roleLevelName }}/transaction-history" class="">
                  <span class="nav-text">Transaction History</span>
                </a>
              </li>
              <li *ngIf="serviceChargeHistoryModule">
                <a routerLink="{{ roleLevelName }}/service-charge-history" class="">
                  <span class="nav-text">Service Charge History</span>
                </a>
              </li>
            </ul>
          </li>
  
          <li *ngIf="settlementModule" class="nav-header hidden-folded">
            <span class="text-primary">Settlements</span>
          </li>
          <li *ngIf="settlementModule">
            <a class="" routerLink="{{ roleLevelName }}/easydebit-settlement">
              <span class="nav-icon"><i class="fa fa-th-list"></i></span>
              <span class="nav-text">Easy Debit</span>
            </a>
          </li>
  
          <li *ngIf="settlementModule">
            <a class="" routerLink="{{ roleLevelName }}/nationlink-settlement">
              <span class="nav-icon"><i class="fa fa-th-list"></i></span>
              <span class="nav-text">Nationlink</span>
            </a>
          </li>
  
          <li *ngIf="settlementModule">
            <a routerLink="{{ roleLevelName }}/encash-settlement">
              <span class="nav-icon"><i class="fa fa-th-list"></i></span>
              <span class="nav-text">Encash</span>
            </a>
          </li>
  
          <li *ngIf="settlementModule">
            <a routerLink="{{ roleLevelName }}/shopee-settlement" >
              <span class="nav-icon"><i class="fa fa-th-list"></i></span>
              <span class="nav-text">Shopee</span>
            </a>
          </li>
  
          <li *ngIf="settlementModule">
            <a class="" >
              <span class="nav-icon"><i class="fa fa-th-list"></i></span>
              <span class="nav-text">Lazada</span>
            </a>
          </li>
         
          
          <li *ngIf="partnerModule" class="nav-header hidden-folded">
            <span class="text-primary">Partner Reports</span>
          </li>
          <li *ngIf="partnerModule">
            <a class="" routerLink="{{ roleLevelName }}/sun-life-grepa-reports">
              <span class="nav-icon"><i class="fa fa-th-list"></i></span>
              <span class="nav-text">Sunlife Grepa</span>
            </a>
          </li>
          <li *ngIf="partnerModule">
            <a class="" routerLink="{{ roleLevelName }}/vip-protect-reports">
              <span class="nav-icon"><i class="fa fa-th-list"></i></span>
              <span class="nav-text">VIP Protect</span>
            </a>
          </li>
  
          <li *ngIf="settingsModule" class="nav-header hidden-folded">
            <span class="text-primary">Settings</span>
          </li>
  
          <li *ngIf="webSettingsModule">
            <a class="" routerLink="{{ roleLevelName }}/web-settings-list">
              <span class="nav-icon"><i class="fa fa-cog"></i></span>
              <span class="nav-text">Web Settings</span>
            </a>
          </li>
         
          
          <li *ngIf="realtimeMessageModule">
            <a class="" routerLink="{{ roleLevelName }}/realtime-message">
              <span class="nav-icon"><i class="fa fa-eye"></i></span>
              <span class="nav-text">Message Blast Scheduling</span>
            </a>
          </li>
          <li *ngIf="realtimeMessageModule">
            <a class="" routerLink="{{ roleLevelName }}/location-tracker">
              <span class="nav-icon"><i class="fa fa-map-marker"></i></span>
              <span class="nav-text">Location Tracker</span>
            </a>
          </li>
          <li *ngIf="realtimeMessageModule">
            <a class="" routerLink="{{ roleLevelName }}/migration-list">
              <span class="nav-icon"><i class="fa fa-user-plus"></i></span>
              <span class="nav-text">Migration List</span>
            </a>
          </li>
          <li *ngIf="realtimeMessageModule">
            <a class="" routerLink="{{ roleLevelName }}/migration-sales-list">
              <span class="nav-icon"><i class="fa fa-user-plus"></i></span>
              <span class="nav-text">Sales Migration List</span>
            </a>
          </li>
          <li *ngIf="passwordResetModule">
            <a class="">
              <span class="nav-icon"><i class="fa fa-asterisk"></i></span>
              <span class="nav-text">Password Reset Logs</span>
            </a>
          </li>
          <li *ngIf="logsModule">
            <a class="">
              <span class="nav-icon"><i class="fa fa-th-list"></i></span>
              <span class="nav-text">Logs</span>
            </a>
          </li>
          <li *ngIf="auditCheckerModule">
            <a class="" routerLink="{{ roleLevelName }}/audit-wallet">
              <span class="nav-icon"><i class="fa fa-terminal"></i></span>
              <span class="nav-text">Audit Checker</span>
            </a>
          </li>
          <li *ngIf="announcenmentModule">
            <a class="" routerLink="{{ roleLevelName }}/announcement-builder">
              <span class="nav-icon"><i class="fa fa-bullhorn"></i></span>
              <span class="nav-text">Announcement Builder</span>
            </a>
          </li>
  
          <li *ngIf="vipMarketPlaceModule" class="nav-header hidden-folded">
            <span class="text-primary">GoVIP Marketplace</span>
          </li>
  
          <li *ngIf="marketPlaceModule">
            <a class="" routerLink="{{ roleLevelName }}/market-place">
              <span class="nav-icon"><i class="fa fa-store"></i></span>
              <span class="nav-text">Marketplace</span>
            </a>
          </li>
          <li *ngIf="applicationModule">
            <a class="" routerLink="{{ roleLevelName }}/application-list">
              <span class="nav-icon"><i class="fa fa-th-large"></i></span>
              <span class="nav-text">Applications</span>
            </a>
          </li>
  
          <li *ngIf="accessManagementModule" class="nav-header hidden-folded">
            <span class="text-primary">Access Management</span>
          </li>
  
          <li *ngIf="roleModule">
            <a href="#role" class="" data-toggle="collapse" aria-expanded="false">
              <span class="nav-icon"><i class="fa fa-user-plus"></i></span>
              <span class="nav-text">Role</span>
              <span class="nav-caret"></span>
            </a>
            <ul class="collapse list-unstyled" id="role">
              <li *ngIf="manageRoleModule">
                <a routerLink="{{ roleLevelName }}/role-list">
                  <span class="nav-text">Manage Roles</span>
                </a>
              </li>
            </ul>
          </li>
  
          <li *ngIf="companyModule" class="nav-header hidden-folded">
            <span class="text-primary">Company</span>
          </li>
          <li *ngIf="companyUserModule">
            <a href="#" routerLink="{{ roleLevelName }}/company-user-list">
              <span class="nav-icon"><i class="fa fa-user-secret"></i></span>
              <span class="nav-text">Admin Users</span>
            </a>
          </li>
  
          <li *ngIf="managerUserModule">
            <a href="#" routerLink="{{ roleLevelName }}/manager-user-list">
              <span class="nav-icon"><i class="fa fa-user-tie"></i></span>
              <span class="nav-text">Manager Users</span>
            </a>
          </li>
  
          <li *ngIf="AllFilesModule">
            <!-- <a href="#manager" class="" data-toggle="collapse" aria-expanded="false">
              <span class="nav-icon"><i class="fa fa-user"></i></span>
              <span class="nav-text">Manager Users</span>
              <span class="nav-caret"></span>
            </a> -->
            <a href="#" routerLink="{{ roleLevelName }}/manager-user-list">
              <span class="nav-icon"><i class="fa fa-user-tie"></i></span>
              <span class="nav-text">Manager Users</span>
            </a>
            <!-- <ul class="collapse list-unstyled" id="manager">
              <li>
                <a href="#" routerLink="{{ roleLevelName }}/manager-user-list">
                  <span class="nav-text">Manager Users List</span>
                </a>
              </li>
              <li>
                <a routerLink="{{ roleLevelName }}/all-files-list" class="">
                  <span class="nav-text">All Files</span>
                </a>
              </li>
            </ul> -->
          </li>
  
          <li *ngIf="cashierUserModule">
            <a href="#" routerLink="{{ roleLevelName }}/cashier-user-list">
              <span class="nav-icon"><i class="fa fa-chalkboard-teacher"></i></span>
              <span class="nav-text">Cashier Users</span>
            </a>
          </li>
  
          <li *ngIf="salesUserModule">
            <a href="#sales" class="" data-toggle="collapse" aria-expanded="false">
              <span class="nav-icon"><i class="fa fa-users"></i></span>
              <span class="nav-text">Sales User</span>
              <span class="nav-caret"></span>
            </a>
            <ul class="collapse list-unstyled" id="sales">
              <li *ngIf="executiveDirectorModule">
                <a>
                  <span class="nav-text">Executive Director</span>
                </a>
              </li>
              <li *ngIf="salesDirectorModule">
                <a>
                  <span class="nav-text">Sales Director</span>
                </a>
              </li>
              <li *ngIf="salesManagerModule">
                <a>
                  <span class="nav-text">Sales Manager</span>
                </a>
              </li>
              <li *ngIf="salesAgentModule">
                <a>
                  <span class="nav-text">Sales Agent</span>
                </a>
              </li>
            </ul>
          </li>
  
          <li *ngIf="storeModule" class="nav-header hidden-folded">
            <span class="text-primary">Store</span>
          </li>
          <li *ngIf="posModule">
            <a class="" href="/pos-transaction">
              <span class="nav-icon"><i class="fa fa-desktop"></i></span>
              <span class="nav-text">Point of Sales</span>
            </a>
          </li>
          <li *ngIf="cashierHistoryModule">
            <a routerLink="{{ roleLevelName }}/pos-transaction-list">
              <span class="nav-icon"><i class="fa fa-qrcode"></i></span>
              <span class="nav-text">Cashier History</span>
            </a>
          </li>
  
          <li *ngIf="productModule" class="nav-header hidden-folded">
            <span class="text-primary">Product</span>
          </li>
          <li *ngIf="vipProtectModule">
            <a routerLink="{{ roleLevelName }}/vip-protect">
              <span class="nav-icon"><i class="fa fa-th-large"></i></span>
              <span class="nav-text">VIP Protect</span>
            </a>
          </li>
          <li *ngIf="da5CertificateModule">
            <a routerLink="{{ roleLevelName }}/da5-certificate">
              <span class="nav-icon"><i class="fa fa-th-large"></i></span>
              <span class="nav-text">BSP Certification</span>
            </a>
          </li>
  
          <li *ngIf="fundsModule" class="nav-header hidden-folded">
            <span class="text-primary">Funds</span>
          </li>
          <li *ngIf="requestFundsModule">
            <a href="#" routerLink="{{ roleLevelName }}/request-fund-list">
              <span class="nav-icon"><i class="fa fa-sign-language"></i></span>
              <span class="nav-text">Request Funds</span>
            </a>
          </li>

          <li *ngIf="paygramLedgerModule">
            <a href="#" routerLink="{{ roleLevelName }}/paygram-ledger">
              <span class="nav-icon"><i class="fa fa-coins"></i></span>
              <span class="nav-text">Paygram Ledger</span>
            </a>
          </li>
          
  
          <li *ngIf="allocateFundsModule">
            <a href="#" routerLink="{{ roleLevelName }}/allocate-fund-list">
              <span class="nav-icon"><i class="fa fa-address-book"></i></span>
              <span class="nav-text">Allocate Funds</span>
            </a>
          </li>
  
          <li *ngIf="supportModule" class="nav-header hidden-folded">
            <span class="text-primary">Support</span>
          </li>
  
          <li *ngIf="ticketModule">
            <a href="#ticket" class="" data-toggle="collapse" aria-expanded="false">
              <span class="nav-icon"><i class="fa fa-object-group"></i></span>
              <span class="nav-text">Ticket</span>
              <span class="nav-caret"></span>
            </a>
            <ul class="collapse list-unstyled" id="ticket">
              <li>
                <a class="">
                  <span class="nav-text">New Ticket</span>
                </a>
              </li>
              <li>
                <a class="">
                  <span class="nav-text">Open Ticket</span>
                </a>
              </li>
              <li>
                <a class="">
                  <span class="nav-text">Close Ticket</span>
                </a>
              </li>
            </ul>
          </li>
          <li *ngIf="discussionModule">
            <a>
              <span class="nav-icon"><i class="fa fa-map"></i></span>
              <span class="nav-text">Discussion</span>
            </a>
          </li>
          <li *ngIf="libraryModule">
            <a href="#library" class="" data-toggle="collapse" aria-expanded="false">
              <span class="nav-icon"><i class="fa fa-paperclip"></i></span>
              <span class="nav-text">Library</span>
              <span class="nav-caret"></span>
            </a>
            <ul class="collapse list-unstyled" id="library">
              <li *ngIf="downloadAreModule">
                <a class="" href="https://sites.google.com/acmbusinesssolutions.net/govipcenter-migration/download-area" target="_blank">
                  <span class="nav-text">Download Area</span>
                </a>
              </li>
              <li *ngIf="uploadAreModule">
                <a class="">
                  <span class="nav-text">Upload Area</span>
                </a>
              </li>
            </ul>
          </li>
          <li *ngIf="videoTutorialModule">
            <a routerLink="{{ roleLevelName }}/video-collections">
              <span class="nav-icon"><i class="fa fa-play"></i></span>
              <span class="nav-text">Video Tutorial Collections</span>
            </a>
          </li>
  
          <!-- ############ Sales User ############ -->
  
          <li *ngIf="salesModule" class="nav-header hidden-folded">
            <span class="text-primary">Rewards</span>
          </li>
          <li *ngIf="salesModule">
            <a>
              <span class="nav-icon"><i class="fa fa-store"></i></span>
              <span class="nav-text">Store</span>
            </a>
          </li>
          <li *ngIf="salesModule">
            <a>
              <span class="nav-icon"><i class="fa fa-users"></i></span>
              <span class="nav-text">Community</span>
            </a>
          </li>
          <li *ngIf="salesModule">
            <a>
              <span class="nav-icon"><i class="fa fa-toggle-on"></i></span>
              <span class="nav-text">Actiivation Center</span>
            </a>
          </li>
          <li *ngIf="salesModule">
            <a>
              <span class="nav-icon"><i class="fa fa-sync-alt"></i></span>
              <span class="nav-text">Send VIPCO</span>
            </a>
          </li>
  
          <li *ngIf="salesModule" class="nav-header hidden-folded">
            <span class="text-primary">Transaction History</span>
          </li>
          <li *ngIf="salesModule">
            <a>
              <span class="nav-icon"><i class="fa fa-coins"></i></span>
              <span class="nav-text">Rewards</span>
            </a>
          </li>
          <li *ngIf="salesModule">
            <a>
              <span class="nav-icon"><i class="fa fa-handshake"></i></span>
              <span class="nav-text">Pairing</span>
            </a>
          </li>
          <!-- ############ Sales User END ############ -->
        </ul>
      </div>
    </div>
  
  
  <!--   
  </div> -->
  <!-- ############ Aside END-->
  