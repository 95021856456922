<!-- <app-loader *ngIf="loader"></app-loader> -->
<div id="content" class="flex bg-dark">
      <div class="page-hero page-container" id="page-hero">
        <div class="padding pr-0">
            <div class="card p-4">
                <h1 style="text-align: center;" class="text-white">
                    ACM Business Solutions Inc.
                </h1>
                <h2 style="text-align: center;" class="text-warning">VIP PROTECT (Microinsurance)</h2>
            </div>
        
            <div>
                <p class="pl-4"><b>Version: </b> &nbsp; <span class="text-danger">1.0.0</span></p>
            </div>
        
            <div class="card p-4">
                <p class="font-weight-bold text-white">
                    <b>VIP Protect™</b> is a microinsurance product of Go VIP Center, aims to provide the low-income person an One (1) month period of coverage for;
                </p>

                <ol>
                    <li>Accidental Death/Death Php 25,000.00</li>
                    <li>Medical Reimbursement due to accident: Php 5,000.00</li>
                    <li>Burial assistance due to accident: Php 5,000.00</li>
                    <li>Fire insurance: Php 5,000.00</li>
                </ol>

                <ol>
                    <li>Age covered: 15-65 years old</li>
                    <li>One (1) month period coverage only maximum of four (4) Certificate of Coverage (COC) per person.</li>
                </ol>

                <h5 class="text-white">
                    Exclusions of VIP Protect Microinsurance
                </h5>
                <p class="font-weight-bold text-white">
                    This insurance does not cover incident occasioned by or happening through:
                </p>
                <ol>
                    <li>
                        War, invasion, act of foreign enemy hostilities or warlike operation (whether was be
                        declared or not), civil war, mutiny, riot, military or popular rising, insurrection, rebellion,
                        revolution, military or usurped power.
                    </li>
                    <li>
                        Suicide or attempted suicide (whether felonious or not, sane or insane), hernia, alcoholism,
                        intoxication, drugs, intentional self-injury.
                    </li>
                    <li>
                        Incident, consequent upon the insured engaging in the making of explosives or upon being
                        engaged as a custodian or explosives.
                    </li>
                    <li>
                        Insured's commission of or attempt to commit felony as consequent upon the insured's being
                        engaged in the illegal occupation or occupation or performing an unlawful act.
                    </li>
                    <li>
                        Miscarriage or pregnancy, any disease of the female reproductive organ.
                    </li>
                </ol>

                <h5 class="text-white">
                    Claim Settlement
                </h5>
                <h6 class="text-white">
                    Written notice of claim must be forwarded to the company within thirty (30) days after the date of
                    the incident, support by the following documents:
                </h6>

                <p>
                    Personal/Accident - Death or Disablement Claim
                </p>
                <ul>
                    <li>
                        Police/Incident Report - original copy
                    </li>
                    <li>
                        Birth/Death Certificate - PSA original copy
                    </li>
                    <li>
                        Medical Certificate - original copy
                    </li>
                    <li>
                        Valid ID's of the Insured and Beneficiary
                    </li>
                </ul>
                <p>
                    And other necessary documents that may be required by Western Guaranty Corporation
                </p>
                <h5 class="text-white">
                    Fire &/or Lightning Insurance Claim:
                </h5>
                <ul>
                    <li>
                        Bureau of Fire Protection (BFP) Report/Certificate - original copy
                    </li>
                    <li>
                        Barangay Certificate of Fire Loss
                    </li>
                    <li>
                        Photos of Fire Loss showing affected property
                    </li>
                    <li>
                        Valid ID's of the Insured
                    </li>
                </ul>
                <p>
                    And other necessary documents that may be required by Western Guaranty Corporation.
                </p>
                <p>
                    The amount of any loss for which the Company may be liable under the policy, shall be paid within
                    ten (10) days after all the necessary documents are received by the company.
                </p>
        </div>
    </div>
</div>

    
      <!-- end of transaction items -->


<!-- Modal for Creating Transaction -->


