<app-loader *ngIf="!allocateFunds"></app-loader>
<app-loader *ngIf="processUpdate"></app-loader>

<div id="content" class="flex" *ngIf="allocateFunds">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">List of Allocate Funds</h2>
          <small class="text-highlight font-weight-normal font-italic">JuanPay list of allocate funds</small>
        </div>
        <div class="flex"></div>
        <div>
          <div *ngIf="roleLevelId == 3 || roleLevelId == 2" (click)="createAllocateFund()" class="btn btn-md btn-info">
            <span class="d-none d-sm-inline mx-1">Allocate Fund</span>
            <i data-feather="arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="padding" style="padding-right: 0px">
        <div class="card mb-1 p-1" style="background-color: #182038">
          <div class="row">
            <div class="col-sm-12 col-md-7" style="padding-left: 0px"></div>
            <div class="col-sm-12 col-md-5" style="padding-left: 0px">
              <div class="flex">
                <div class="input-group">
                  <input
                    type="text"
                    [(ngModel)]="searchString"
                    class="form-control form-control-theme form-control-sm search"
                    placeholder="Search Request ID..."
                    style="background-color: white; color: black"
                  />
                  <span class="input-group-append">
                    <button
                      (click)="getAllocateFund(null, null, null, true)"
                      class="btn btn-white no-border btn-sm"
                      type="button"
                      style="background-color: gainsboro"
                    >
                      <span class="d-flex text-muted"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-search"
                        >
                          <circle cx="11" cy="11" r="8"></circle>
                          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                        </svg>
                      </span>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card">
          <table
            id="table"
            class="table table-striped no-footer"
            data-plugin="bootstrapTable"
            data-toolbar="#toolbar"
            data-search="true"
            data-search-align="left"
            data-show-export="true"
            data-show-columns="true"
            data-detail-view="false"
            data-mobile-responsive="true"
            data-pagination="true"
            data-page-list="[10, 25, 50, 100, ALL]"
          >
            <thead>
              <tr>
                <th data-sortable="true" data-field="id">Details</th>
                <th data-sortable="true" data-field="owner">Identity</th>
                <th data-sortable="true" data-field="project">Amount</th>
                <th data-field="task"><span class="d-none d-sm-block">Balance Before</span></th>
                <th data-field="finish"><span class="d-none d-sm-block">Balance After</span></th>
                <th data-field="finish"><span class="d-none d-sm-block">Status</span></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class=" "
                *ngFor="
                  let allocateFund of allocateFunds?.data
                    | paginate: { itemsPerPage: 10, currentPage: p, totalItems: allocateFunds.totalRecords }
                "
                class="odd"
                role="row"
                (click)="viewDetails(allocateFundModal, allocateFund)"
              >
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary font-weight-bold">Request ID : </span> {{ allocateFund?.requestId }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary font-weight-bold">Fund Sender : </span>
                    {{ allocateFund?.originUsername }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary font-weight-bold">Fund Receipient : </span>
                    {{ allocateFund?.destinationUsername }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary font-weight-bold">Created date : </span> {{ allocateFund?.createdDate }}
                  </div>
                </td>
                <td>
                  <div class="text-center">
                    <strong style="color: red" *ngIf="username == allocateFund?.originUsername"
                      >-{{ makeMoney(allocateFund?.amount) }}
                    </strong>
                    <strong style="color: green" *ngIf="username == allocateFund?.destinationUsername"
                      >{{ makeMoney(allocateFund?.amount) }}
                    </strong>
                  </div>
                </td>
                <td>
                  <div class="text-center">
                    <strong *ngIf="username == allocateFund?.originUsername">{{
                      makeMoney(allocateFund?.originOpeningBalance)
                    }}</strong>
                    <strong *ngIf="username == allocateFund?.destinationUsername">{{
                      makeMoney(allocateFund?.destinationOpeningBalance)
                    }}</strong>
                  </div>
                </td>
                <td>
                  <div class="text-center">
                    <strong *ngIf="username == allocateFund?.originUsername">{{
                      makeMoney(allocateFund?.originOpeningBalance - allocateFund?.amount)
                    }}</strong>
                    <strong *ngIf="username == allocateFund?.destinationUsername">{{
                      makeMoney(allocateFund?.destinationOpeningBalance + allocateFund?.amount)
                    }}</strong>
                  </div>
                </td>
                <td>
                  <div *ngIf="username == allocateFund?.originUsername">
                    <!-- <span class="badge badge-circle text-info"></span>
                    <small class=""> Send</small> -->
                    <span class="badge badge-info" style="width: 60px"> Send</span>
                  </div>
                  <div *ngIf="username != allocateFund?.originUsername">
                    <!-- <span class="badge badge-circle text-success"></span>
                    <small class=""> Received</small> -->
                    <span class="badge badge-success" style="width: 60px"> Received</span>
                  </div>
                </td>
                <!-- <td>
                  <div class="item-action dropdown">
                    <a href="#" data-toggle="dropdown" class="text-muted">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-more-vertical"
                      >
                        <circle cx="12" cy="12" r="1"></circle>
                        <circle cx="12" cy="5" r="1"></circle>
                        <circle cx="12" cy="19" r="1"></circle>
                      </svg>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right bg-black" role="menu">
                      <div *ngIf="roleLevelId == 1" class="dropdown-divider"></div>
                      <a
                        data-toggle="modal"
                        (click)="setSelectedAllocateFund(allocateFund)"
                        data-target="#modal-receipt"
                        data-toggle-class-target=".animate"
                        class="dropdown-item trash"
                      >
                        See Official Receipt
                      </a>
                    </div>
                  </div>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
              <div class="col-sm-12 col-md-12">
                <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                  Showing {{ (allocateFunds?.pageNumber - 1) * allocateFunds?.pageSize + 1 }} to
                  {{ (allocateFunds?.pageNumber - 1) * allocateFunds?.pageSize + allocateFunds?.data?.length }} of
                  {{ allocateFunds?.data?.length }} entries
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-7" style="float: right">
            <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
              <ul class="pagination" style="float: right; margin-right: 36px">
                <pagination-controls (pageChange)="getAllocateFund(buildPageUrl($event, 10))"></pagination-controls>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<ng-template #allocateFundModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Allocate Fund Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-12">
        <div class="card bg-dark mb-2 p-1 d-flex align-items-center justify-content-center">
          <p class="font-weight-bold mb-0">Transaction Details</p>
        </div>

        <table class="table table-striped bg-white">
          <tbody>
            <tr>
              <td colspan="5">Amount</td>
              <th class="text-nowrap" scope="row">{{ makeMoney(allocateFundDetail?.amount) }}</th>
            </tr>
            <tr>
              <td colspan="5">Fund Sender</td>
              <th class="text-nowrap" scope="row">{{ allocateFundDetail?.originUsername }}</th>
            </tr>
            <tr>
              <td colspan="5">Fund Receipient</td>
              <th class="text-nowrap" scope="row">{{ allocateFundDetail?.destinationUsername }}</th>
            </tr>
            <tr>
              <td colspan="5">Created Date</td>
              <th class="text-nowrap" scope="row">{{ allocateFundDetail?.createdDate }}</th>
            </tr>
          </tbody>
        </table>

        <div *ngIf="!isTicketActive && username == allocateFundDetail?.originUsername">
          <br />

          <div class="w-100 d-flex justify-content-end">
            <button class="btn btn-sm btn-warning" style="width: 120px" (click)="openTicket()">
              Open Ticket &nbsp; <i class="fa fa-tag"></i>
            </button>
          </div>
          <br />
        </div>

        <!-- <div class=" bg-dark mb-2 p-1 d-flex align-items-center justify-content-center">
          <p class="font-weight-bold mb-0">Note</p>
        </div> -->
      </div>

      <div class="col-sm-12">
        <app-ticket-create-transaction
          *ngIf="isTicketActive"
          [requestId]="allocateFundDetail?.requestId"
          transactionType="ALLOCATE_FUND"
          [username]="username"
        >
        </app-ticket-create-transaction>
      </div>
    </div>
  </div>
</ng-template>

<div id="modal-receipt" class="modal fade" data-backdrop="true">
  <div class="modal-dialog animate">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title text-md">Original Official Reciept</div>
        <button class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="p-4 text-center">
          <p>Make sure that this receipt was validated</p>
          <img
            *ngIf="allocateFund?.officialReceiptPhoto"
            [src]="buildUserPhoto(allocateFund) | authImage | async"
            alt="VIP"
            onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
            style="width: 100%"
          />
          <img *ngIf="!allocateFund?.officialReceiptPhoto" src="https://via.placeholder.com/64x64?text=VIP" alt="VIP" />
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
        <!--  -->
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
</div>
