import { CurrencyPipe, DatePipe } from "@angular/common";
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { sortedIndexOf } from "lodash";
import { Select2OptionData } from "ng-select2";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { ExportExcelService } from "src/data/services/web/export-excel.service";
import { RequestFundService } from "src/data/services/web/request-fund.service";
import { RolesService } from "src/data/services/web/roles.service";
import { UserService } from "src/data/services/web/user.service";
import { WebSettingsService } from "src/data/services/web/web-settings.service";
import { environment } from "src/environments/environment";
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { GovipxService } from "src/data/services/web/govipx.service";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-vips-level2-verification',
  templateUrl: './vips-level2-verification.component.html',
  styleUrls: ['./vips-level2-verification.component.less']
})
export class VipsLevel2VerificationComponent implements OnInit {
  public requestFundFilterFormGroup: FormGroup;
  public requestFundRemarkFormGroup: FormGroup;
  //remarks = new FormControl('');


  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public govipxService: GovipxService;
  public websettings: WebSettingsService;
  
  public userService: UserService;
  public statusOPtion: Array<Select2OptionData>;
 
  public emptyString = "";
  public statuses: any;
  public status: any;
  public modalService: BsModalService;

  public accessToken: string;
  public newRequestFund: boolean;
  public accounts: any;
  public requestFundsExport: any;
  public requestFund: any;

  public pageRows: any = 10;

  public lastTenFunds: any;
  public lastTenFund: any;

  public officialReceipt: any;
  public pageCount: any;
  public roleLevelId: any;
  public roleId: any;
  public processUpdate: any = false;
  public userID: any;

  public bsModalRefConfirmation: BsModalRef;

  // Filters
  public listFilterForm: FormGroup;
  public selectedUserId: any = 0;
  public selectedStatusId: any = 0;
  public selectedRowsId: any = 0;

  public showRowsOption: Array<Select2OptionData>
  public userIdFilterOptions: Array<Select2OptionData>;
  public managerUsers: Array<Select2OptionData>;
  public userIdFilter: any;
  public managers: string[] = [];

  public Transactions = [];
  public isDataNotNull: boolean = false;
  dataForExcel = [];
  public isTicketActive : any = false;
  
  @ViewChild("pinModal") pinModal: TemplateRef<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    private currencyPipe: CurrencyPipe,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    govipxService: GovipxService,
    modalService: BsModalService,
    bsModalRefConfirmation: BsModalRef,
    websettings: WebSettingsService,
    userService: UserService,
    public datepipe: DatePipe,
    private modalServe: NgbModal,
    public ete: ExportExcelService
  ) {
    PNotifyButtons;
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.govipxService = govipxService;
    this.modalService = modalService;
    this.websettings = websettings;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
    this.bsModalRefConfirmation = bsModalRefConfirmation;
    this.userService = userService;
  }

  ngOnInit(): void {
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    this.userID = this.localStorageCacheService.getStorage("userId");

    this.getAccountValidationList();
  }

  loader = false;

  getAccountValidationList(): void {
    this.loader = true;

    this.govipxService.getGovipxValidationLevel2().subscribe((result) => {
      this.accounts = result;
      this.loader = false;
    });
  }

  selectedAccount: any;
  similarAccount: any;

  viewDetails(content, account){
    this.selectedAccount = account;
    this.modalServe.open(content, { centered: true, size: "xl" });
    this.loader = false;
  }

  //isFindMatch: boolean = false;
  // findMatch(selectedAccount: any){
  //   this.loader = true;

  //   this.govipxService.postGovipxValidation(selectedAccount.userFName, selectedAccount.userLname).subscribe((result) => {
  //     // this.isFindMatch = true;
  //     // alert(result.response_data.length);
  //     if(result.response_data.length > 0){
  //       this.similarAccount = result.response_data[0]
  //     } else {
  //       this.similarAccount = null;
  //       PNotify.success({
  //         title: "Similar Account",
  //         text: "There is no similar account found.",
  //       });
  //     }

  //     this.loader = false;
  //   });
  // }

  remarksStr: any;
  processVerification(isApproved: boolean){
    this.loader = true;
    if(isApproved){
      this.govipxService.putGovipxValidationLevel2("APPROVED", this.selectedAccount.userID, "Application successfully approved.", this.selectedAccount.usertype).subscribe((result) => {
        PNotify.success({
          title: "Verification",
          text: "Account successfully approved.",
        });
        this.getAccountValidationList();
        this.modalServe.dismissAll();
        this.loader = false;
      });
    } else {
      this.govipxService.putGovipxValidationLevel2("REJECTED", this.selectedAccount.userID, this.remarksStr, this.selectedAccount.usertype).subscribe((result) => {
        PNotify.success({
          title: "Verification",
          text: "Account successfully rejected.",
        });
        this.getAccountValidationList();
        this.modalServe.dismissAll();
        this.loader = false;
      });
    }
  }

  openVerify(content){
    this.modalServe.open(content, { centered: true, size: "md" });
  }

  openReject(content){
    this.modalServe.open(content, { centered: true, size: "md" });
  }
}
