<app-loader *ngIf="verifyingLogin"></app-loader>
<app-loader *ngIf="loader"></app-loader>

<body>
  <!-- <div class="container">
	<div class="d-flex justify-content-center h-100">
		<div class="card">
			<div class="card-header">
				<h3>GoVIP Center</h3>
        <h4>Welcome!</h4>
			</div>
			<div class="card-body">
				<form [formGroup]="userFormGroup" class="" role="form">
					<div class="input-group form-group">
						<div class="input-group-prepend">
							<span class="input-group-text"><i class="fas fa-user"></i></span>
						</div>
						<input
              type="email"
              appDebounceClick [debounceTime]="500"
              formControlName="email"
              class="form-control"
              placeholder="Enter email"
              autofocus
              class="form-control" placeholder="email address">
					</div>
					<div class="input-group form-group">
						<div class="input-group-prepend">
							<span class="input-group-text"><i class="fas fa-key"></i></span>
						</div>
						<input type="password"
              appDebounceClick [debounceTime]="500"
              formControlName="password"
              class="form-control"
              placeholder="Password"
              class="form-control" placeholder="password">
					</div>
					<div class="row align-items-center remember">
						<input type="checkbox">Remember Me
					</div>
					<div class="form-group">
						<button class="btn  float-right login_btn" appDebounceClick [debounceTime]="500" (keyup.enter)="verifyLogin()" (debounceClick)="verifyLogin()">Login</button>
					</div>
				</form>
			</div>
			<div class="card-footer">
				<div class="d-flex justify-content-center">
					<a href="forgot-password">Forgot your password?</a>
				</div>
			</div>
		</div>
	</div>
</div> -->
  <!-- <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w==" crossorigin="anonymous" referrerpolicy="no-referrer" />
<div class="container">
  <div class="screen">
    <div class="screen__content">

      <form style="display: flex; flex-direction: column; align-items: center;" [formGroup]="userFormGroup" class="" role="form">
        <img src="../../../assets/images/logo/GoVIP Logo.png" alt="" style="width: 120px; margin-bottom: 20px;">
        <h4 style="line-height: 3;font-weight: 600; font-size: 27px; color: white;">Welcome back!</h4>
        <h6 class="text-white">Please login your account.</h6>
        <div class="login__field">
          <i class="login__icon fas fa-user"></i>
          <input
              type="email"
              appDebounceClick [debounceTime]="500"
              formControlName="email"
              class="form-control"
              placeholder="Email"
              autofocus
              class="form-control login__input" placeholder="Email">
        </div>
        <div class="login__field">
          <i class="login__icon fas fa-lock"></i>
          <input type="password"
              appDebounceClick [debounceTime]="500"
              formControlName="password"
              class="form-control"
              placeholder="Password"
              class="form-control login__input" placeholder="Password">
        </div>
        <div style="padding-left: 8px; margin-top: 8px; margin-left: 45px; color: white; align-self: flex-start;" class="row align-items-center remember">
          <input type="checkbox">Remember me
        </div>
        <button class="button login__submit btn login_btn" appDebounceClick [debounceTime]="500" (keyup.enter)="verifyLogin()" (debounceClick)="verifyLogin()">
          <span class="button__text">Login</span>
          <i class="button__icon fas fa-chevron-right"></i>
        </button>
        <a href="forgot-password" style="font-size: 12px; color: white;">Forgot password?</a>
      </form>
    </div>
  </div>
</div> -->
  <link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
    integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"
    crossorigin="anonymous"
  />
  <div class="container-fluid">
    <div class="row">
      <div
        class="col-8"
        style="
          display: flex;
          flex-direction: column;
          padding-top: 10px;
          overflow: hidden;
          justify-content: center;
          padding: 0 115px 0 115px;
        "
      >
        <h1
          style="font-weight: 700; color: #1376a0; letter-spacing: 1px"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          Juan<span style="color: #1e9809">Pay</span>
        </h1>
        <p style="width: 80%; letter-spacing: 1px" data-aos="fade-right" data-aos-duration="1000">
          The first Philippine premiere website creating innovative web-based business solutions. Providing the
          underserved and unserved population access to Financial Services.
        </p>
        <img
          class="illus"
          style="width: 100%; align-self: center; border-radius: 10px"
          src="https://juanpaycdn.azureedge.net/juanpayassets/10x6.jpg"
          alt=""
          data-aos="fade-up"
          data-aos-duration="1500"
        />
      </div>

      <div
        [formGroup]="userFormGroup"
        role="form"
        class="col-4 login-form"
        data-aos-duration="1500"
        data-aos="zoom-in-up"
      >
        <!-- <img
          width="10%"
          src="https://juanpaycdn.azureedge.net/juanpayassets/LOGO_juanpay12x5.png"
          alt=""
          style="width: 250px; position: relative; align-self: center; margin-bottom: 40px"
        /> -->

        <img
          width="10%"
          src="https://juanpaycdn.azureedge.net/juanpayassets/LOGO_juanpay12x5.png"
          alt=""
          style="width: 250px; position: relative; margin-bottom: 40px"
          class="d-block d-lg-none"
        />

        <h4>Sign in</h4>
        <div class="input-group mb-3 login__field">
          <input
            type="email"
            appDebounceClick
            [debounceTime]="500"
            formControlName="email"
            class="form-control"
            placeholder="Enter email"
            autofocus
            (keyup.enter)="verifyLogin()"
            class="form-control"
            placeholder="Enter email"
          />
          <!-- <input required="" type="email" name="email" autocomplete="off" class="input" placeholder="Enter email" appDebounceClick [debounceTime]="500"
      formControlName="email"> -->
        </div>
        <div class="input-group mb-2 login__field">
          <input
            type="password"
            appDebounceClick
            [debounceTime]="500"
            formControlName="password"
            class="form-control"
            placeholder="Password"
            (keyup.enter)="verifyLogin()"
            class="form-control"
            placeholder="Password"
          />
          <!-- <input required="" type="password" name="password" autocomplete="off" class="input" placeholder="Enter password" appDebounceClick [debounceTime]="500"
      formControlName="password"> -->
        </div>
        <a href="forgot-password">Forgot password?</a>
        <div class="input-group" style="margin-top: 45px">
          <button
            class="login__submit btn login_btn"
            appDebounceClick
            [debounceTime]="500"
            (keyup.enter)="verifyLogin()"
            (debounceClick)="verifyLogin()"
          >
            <span class="button__text" style="font-size: 16px; letter-spacing: 1px; color: white">Login</span>
          </button>
          <!-- <input required="" type="button" name="password" autocomplete="off" class="input submit" value="Login"> -->
        </div>
        <div class="link_container">
          <button data-text="Awesome" class="button">
            <span class="actual-text text-dark" (click)="goToHome()">&nbsp;About&nbsp;</span>
            <span class="hover-text" aria-hidden="true" (click)="goToHome()">&nbsp;About&nbsp;</span>
          </button>
          <button data-text="Awesome" class="button">
            <span class="actual-text text-dark" (click)="goToPP()">&nbsp;Privacy Policy&nbsp;</span>
            <span class="hover-text" aria-hidden="true" (click)="goToPP()">&nbsp;Privacy Policy&nbsp;</span>
          </button>
          <!-- <a routerLink="/about" class="text-dark">About</a>
        <a href="https://govipcenter.com/govip-privacy-policy" class="text-dark">Terms and Policies</a> -->
        </div>
      </div>
    </div>
  </div>

  <ng-template #pinModal let-modal data-aos="fade-up" data-aos-anchor-placement="top-bottom">
    <!-- <div class="rounded">
  <div class="modal-header justify-content-center pb-0" style="border-bottom: none;">
    <img width="70px" src="../../../assets/images/logo/GoVIP Logo.png" alt=""> -->
    <button type="button" class="close" aria-label="Close" (click)="closeTransaction()" style="position: absolute">
      <span style="padding-right: 10px" aria-hidden="true">&times;</span>
    </button>
    <!-- </div>
</div> -->
    <app-pin class="rounded-circle" (doLoginPin)="doLoginPin($event)"></app-pin>
    <div>
      <button
        style="padding-right: 8px; position: absolute; right: 20px; top: 160px; font-size: 10px; color: #013773"
        class="btn btn-xs float-right mb-2 pin--button"
        (click)="confirmChangePIN()"
      >
        Forgot Pin?
      </button>
    </div>
  </ng-template>

  <ng-template #confirmChangePINModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Forgot your PIN?</h4>
      <button type="button" class="close" aria-label="Close" (click)="closeTransaction()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <h5>Click Proceed to reset your PIN.</h5>
      <button class="btn btn-sm btn-info float-right" (click)="proceedToChangePIN()">Proceed</button>
      <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
        Cancel
      </button>
    </div>
  </ng-template>

  <ng-template #pinEnrollModal let-modal>
    <div class="modal-header justify-content-center pb-0">
      <h5 class="modal-title ml-6 justify-content-center">ENROLL SECURITY PIN</h5>
      <button style="padding-right: 25px" type="button" class="close" aria-label="Close" (click)="closeTransaction()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <app-pin-enroll
      [email]="userFormGroup.controls.email.value"
      [password]="userFormGroup.controls.password.value"
      (showLoginPin)="showLoginPin()"
    ></app-pin-enroll>
  </ng-template>

  <div id="chatButton"></div>

  <!-- <div class="d-flex flex-column ml-3">
  <div class="d-flex text-sm text-fade vip--about">
    <a routerLink="/about" class="text-white">About</a>
  </div>
</div>
<div class="d-flex flex-column ml-3">
  <div class="d-flex text-sm text-fade vip--terms">
    <a href="https://govipcenter.com/govip-privacy-policy" class="text-white">Terms and Policies</a>
  </div>
</div> -->
</body>
