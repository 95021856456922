import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-basik-non-admin",
  templateUrl: "./basik-non-admin.component.html",
  styleUrls: ["./basik-non-admin.component.less"],
})
export class BasikNonAdminComponent implements OnInit {
  constructor() {
    //
  }

  ngOnInit(): void {
    //
  }
}
