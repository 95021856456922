import { Directive, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[number-only]",
})
export class NumberOnlyDirective {
  @Input("number-only") activateNumberOnly: boolean;

  private regex: RegExp;

  constructor() { 
    //this.regex = new RegExp(/^[0-9]+(\[0-9]*){0,1}$/g);
    this.regex = new RegExp(/[1234567890.]/g);
  }

  @HostListener("paste", ["$event"])
  public onPaste(clipboardEvent: ClipboardEvent): ClipboardEvent {
    if (!this.activateNumberOnly) {
      return clipboardEvent;
    }

    if (clipboardEvent.clipboardData.getData("text").match(this.regex) != null) {
      return clipboardEvent;
    }

    clipboardEvent.preventDefault();
  }

  @HostListener("keydown", ["$event"])
  public onKeyDown(keyboardEvent: KeyboardEvent): KeyboardEvent {
    if (!this.activateNumberOnly) {
      return keyboardEvent;
    }

    if (keyboardEvent.ctrlKey || keyboardEvent.metaKey) {
      if (new Array<string>("a", "c", "v").includes(keyboardEvent.key)) {
        return keyboardEvent; // Allow Ctrl+A, Ctrl+C, Ctrl+V
      }
    }

    if (keyboardEvent.shiftKey || keyboardEvent.metaKey) {
      if (new Array<string>("Home", "End").includes(keyboardEvent.key)) {
        return keyboardEvent; // Allow Shft+Home, Shft+End
      }
    }

    const keyWhiteList: Array<string> = new Array<string>(
      "Tab",
      "Home",
      "End",
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight"
    );

    if (keyWhiteList.includes(keyboardEvent.key)) {
      return keyboardEvent;
    }

    if (keyboardEvent.key.match(this.regex) != null) {
      return keyboardEvent;
    }

    keyboardEvent.preventDefault();
  }
}
