<div class="tab-content p-3 bg-white">
    <div class="page-hero page-container" id="page-hero">
        <div id="page-hero">
            <div class="padding pb-0 pt-0">
                <!-- <img src="../../../assets/images/logo/GoVIP Logo Size  230.16 x 72 Pixels.png" alt="company logo" class="vip--logo"> -->
                <img src="../../../assets/images/banner/banner_1.jpg" alt="company logo" class="vip--logo">
            </div>
        </div>
        <div id="page-hero">
            <div class="padding pb-0">
                <h2 class="--spam">>> Payment Information</h2>
                <div class="row">
                    <div class="col-lg-5 col-sm-12">
                        <div class="c-shadow mb-3 bg-white rounded">
                            <div class="card-header">
                              <strong class="subtitle">Account Information</strong>
                            </div>
                            <div class="card-body pt-0">
                                <div class="row">
                                    <div class="col-3">
                                        <strong class="title-form">Username </strong>
                                    </div>
                                    <div class="col-9">
                                        <small class="font-weight-bold body-form">{{ migrationDetail.Username }}</small>
                                    </div>
            
                                    <div class="col-3">
                                        <strong class="title-form">Email Address </strong>
                                    </div>
                                    <div class="col-9">
                                        <small class="font-weight-bold body-form">{{ migrationDetail.Email }}</small>
                                    </div>
            
                                    <div class="col-3">
                                        <strong class="title-form">Role </strong>
                                    </div>
                                    <div class="col-9">
                                        <small class="font-weight-bold body-form">Manager</small>
                                        
                                    </div>
                                </div>
                            </div>

                            <div class="card-header">
                                <strong class="subtitle">Personal Information</strong>
                              </div>
                              <div class="card-body pt-0">
                                  <div class="row">
                                      <div class="col-3">
                                          <strong class="title-form">First Name </strong>
                                      </div>
                                      <div class="col-9">
                                          <small class="font-weight-bold body-form">{{ migrationDetail.FirstName }}</small>
                                      </div>
              
                                      <div class="col-3">
                                          <strong class="title-form">Last Name </strong>
                                      </div>
                                      <div class="col-9">
                                          <small class="font-weight-bold body-form">{{ migrationDetail.LastName }}</small>
                                      </div>
              
                                      <div class="col-3">
                                          <strong class="title-form">Birthdate </strong>
                                      </div>
                                      <div class="col-9">
                                          <small class="font-weight-bold body-form">{{ migrationDetail.BirthDate | date : "MMM-dd-yyyy" }}</small>
                                      </div>
              
                                      <div class="col-3">
                                          <strong class="title-form">Gender </strong>
                                      </div>
                                      <div class="col-9">
                                          <small class="font-weight-bold body-form">{{ migrationDetail.Gender }}</small>
                                      </div>
              
                                      <div class="col-3">
                                          <strong class="title-form">Contact No </strong>
                                      </div>
                                      <div class="col-9">
                                          <small class="font-weight-bold body-form">{{ migrationDetail.ContactNumber }}</small>
                                      </div>
              
                                      <div class="col-3">
                                          <strong class="title-form">Address </strong>
                                      </div>
                                      <div class="col-9">
                                          <small class="font-weight-bold body-form">{{ migrationDetail.Address }}</small>
                                      </div>
                                  </div>
                              </div>

                              <div class="card-header">
                                <strong class="subtitle">Bank Account Details</strong>
                              </div>
                              <div class="card-body pt-0">
                                  <div class="row">
                                      <div class="col-3">
                                          <strong class="title-form">Bank Name </strong>
                                      </div>
                                      <div class="col-9">
                                          <small class="font-weight-bold body-form">{{ migrationDetail.BankName }}</small>
                                      </div>
                
                                      <div class="col-3">
                                          <strong class="title-form">Account Number </strong>
                                      </div>
                                      <div class="col-9">
                                          <small class="font-weight-bold body-form">{{ migrationDetail.BankAccountNumber }}</small>
                                      </div>
                
                                      <div class="col-3">
                                          <strong class="title-form">Account Name </strong>
                                      </div>
                                      <div class="col-9">
                                          <small class="font-weight-bold body-form">{{ migrationDetail.BankAccountName }}</small>
                                      </div>
                                  </div>
                              </div>


                        </div>
                    </div>
                    <div class="col-lg-7 col-sm-12">
                        <div class="card p-2">
                            <div class="card-header">
                                <strong class="subtitle">List of Payment</strong>
                            </div>
                            <div class="card-body pt-0">
                                <div class="table-responsive">
                                    <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
                                        <thead>
                                            <tr>
                                                <!-- <th data-sortable="true" data-field="owner">Avatar</th> -->
                                                <th data-sortable="true" data-field="id">
                                                    <div class="color-blue">Date</div>
                                                </th>
                                                <th data-sortable="true" data-field="owner">
                                                    <div class="color-blue">Bank Code</div>
                                                </th>
                            
                                                <th class="color-blue">Merchant Ref ID</th>
                            
                                                <th class="color-blue" data-sortable="true" data-field="project" style="text-align: right;">
                                                    <div>Amount</div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of payments" style="cursor: pointer" class="odd" role="row">
                                                <td class="flex">
                                                    <div>
                                                        <span>{{item?.createdDate | date: "MMM dd,yyyy"}}</span>
                                                    </div>
                                                </td>
                            
                                                <td class="flex">
                                                    <div>
                                                        <span>{{item?.bankCode}}</span>
                                                    </div>
                                                </td>
                            
                                                <td class="flex">
                                                    <div>
                                                        <span style="color: rgb(60, 62, 62);">{{item?.merchantReferenceId}}</span>
                                                    </div>
                                                </td>
                            
                                                <td class="flex" style="text-align: right;">
                                                    <div>
                                                        <span class="color-red">
                                                            {{ makeMoney(item.amount) }}
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <button class="btn btn-primary btn-sm float-right" (click)="openPaymentModal(paymentModal)">
                                    Add Payment &nbsp; <i class="fa fa-plus"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
        </div>
    </div>

    <!-- <div class="tab-pane" [ngClass]="(activeTab==1)?'active':''" id="selectBillerTab">
      <div class="row">
      <div class="col-12">
          <div class="shadow-lg mb-3 bg-white rounded">
              <div class="card-header">
                  <strong style="color: #0e1b82;">Payment Information</strong>
                  <a class="btn btn-success text-white" *ngIf="activeTab == 1" (click)="openPaymentModal(paymentModal)">
                      &nbsp; Add Payment
                    </a>
                </div>
          </div>
      </div>
        <div class="col-6">
            <div class="shadow-lg mb-3 bg-white rounded">
                <div class="card-header">
                  <strong style="color: #0e1b82;">Account Information</strong>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-3">
                            <strong class="title-form">Username </strong>
                        </div>
                        <div class="col-9">
                            <small class="font-weight-bold body-form">{{ migrationDetail.Username }}</small>
                        </div>

                        <div class="col-3">
                            <strong class="title-form">Email Address </strong>
                        </div>
                        <div class="col-9">
                            <small class="font-weight-bold body-form">{{ migrationDetail.Email }}</small>
                        </div>

                        <div class="col-3">
                            <strong class="title-form">Role </strong>
                        </div>
                        <div class="col-9">
                            <small class="font-weight-bold body-form">Manager</small>
                            
                        </div>
                    </div>
                </div>
            </div>

            <div class="shadow-lg mb-3 bg-white rounded">
                <div class="card-header">
                  <strong style="color: #0e1b82;">Personal Information</strong>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-3">
                            <strong class="title-form">First Name </strong>
                        </div>
                        <div class="col-9">
                            <small class="font-weight-bold body-form">{{ migrationDetail.FirstName }}</small>
                        </div>

                        <div class="col-3">
                            <strong class="title-form">Last Name </strong>
                        </div>
                        <div class="col-9">
                            <small class="font-weight-bold body-form">{{ migrationDetail.LastName }}</small>
                        </div>

                        <div class="col-3">
                            <strong class="title-form">Birthdate </strong>
                        </div>
                        <div class="col-9">
                            <small class="font-weight-bold body-form">{{ migrationDetail.BirthDate | date : "MMM-dd-yyyy" }}</small>
                        </div>

                        <div class="col-3">
                            <strong class="title-form">Gender </strong>
                        </div>
                        <div class="col-9">
                            <small class="font-weight-bold body-form">{{ migrationDetail.Gender }}</small>
                        </div>

                        <div class="col-3">
                            <strong class="title-form">Contact No </strong>
                        </div>
                        <div class="col-9">
                            <small class="font-weight-bold body-form">{{ migrationDetail.ContactNumber }}</small>
                        </div>

                        <div class="col-3">
                            <strong class="title-form">Address </strong>
                        </div>
                        <div class="col-9">
                            <small class="font-weight-bold body-form">{{ migrationDetail.Address }}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6">
            <div class="shadow-lg mb-3 bg-white rounded">
              <div class="card-header">
                <strong style="color: #0e1b82;">Bank Account Details</strong>
              </div>
              <div class="card-body">
                  <div class="row">
                      <div class="col-3">
                          <strong class="title-form">Bank Name </strong>
                      </div>
                      <div class="col-9">
                          <small class="font-weight-bold body-form">{{ migrationDetail.BankName }}</small>
                      </div>

                      <div class="col-3">
                          <strong class="title-form">Account Number </strong>
                      </div>
                      <div class="col-9">
                          <small class="font-weight-bold body-form">{{ migrationDetail.BankAccountNumber }}</small>
                      </div>

                      <div class="col-3">
                          <strong class="title-form">Account Name </strong>
                      </div>
                      <div class="col-9">
                          <small class="font-weight-bold body-form">{{ migrationDetail.BankAccountName }}</small>
                      </div>
                  </div>
              </div>
          </div>

          

            </div>
     </div>
    </div> -->

    
  </div>


  <ng-template #paymentModal let-modal>
    <div class="modal-header pb-0">
      <!-- <h4 class="modal-title">Payment Form</h4> -->
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>

    </div>
      <div class="modal-body pt-0 pb-0">
        <div class="card">
            <div class="b-b">
              <div class="nav-active-border b-primary bottom">
                <ul class="nav" id="myTab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="home-tab" data-toggle="tab" 
                    href="#home3" role="tab" aria-controls="home" aria-selected="true">Online Banking</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" 
                    href="#profile3" role="tab" aria-controls="profile" aria-selected="false">Credit Card</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="contact-tab" data-toggle="tab" 
                    href="#contact3" role="tab" aria-controls="contact" aria-selected="false">E-Wallet</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="tab-content pl-3 pl-3 pb-0 pt-0">
              <div class="tab-pane fade active show" id="home3" role="tabpanel" aria-labelledby="home-tab">
                <form class="form pt-0 pb-0" [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="separator mt-2">
                        <hr class="line">
                        <p>Select Bank</p>
                        <hr class="line">
                    </div>
                    <div class="payment--options">
                        <button type="button" *ngFor="let bank of banks">
                            <img [src]="bank.logo_url" [alt]="bank.title">
                        </button>
                    </div>
                    <div class="separator">
                        <hr class="line">
                        <p>Please fill all required fields</p>
                        <hr class="line">
                    </div>

                    <!-- Form for Bank Details -->
                    <div class="credit-card-info--form">
                        <div class="row">
                            <div class="col-6">
                                <div class="input_container">
                                    <label for="password_field" class="input_label">First Name <span class="text-danger font-weight-bold">*</span></label>
                                    <input id="password_field" class="input_field" type="text" name="input-name" title="Inpit title" placeholder="First name">
                                  </div>
                                  <div class="input_container">
                                      <label for="password_field" class="input_label">Last Name</label>
                                      <input id="password_field" class="input_field" type="text" name="input-name" title="Inpit title" placeholder="Last name">
                                  </div>
                            </div>
                            <div class="col-6">
                                <div class="input_container">
                                    <label for="password_field" class="input_label">Middle Name</label>
                                    <input id="password_field" class="input_field" type="text" name="input-name" title="Inpit title" placeholder="Middle name">
                                </div>
                                <div class="input_container">
                                    <label for="password_field" class="input_label">Mobile</label>
                                    <input id="password_field" class="input_field" maxlength="11" type="text" name="input-name" title="Inpit title" placeholder="09XXXXXXXXX">
                                </div>
                            </div>
                        </div>
                        <div class="input_container">
                            <label for="password_field" class="input_label">Email</label>
                            <input id="password_field" class="input_field" type="text" name="input-name" title="Inpit title" placeholder="Email">
                        </div>
                        <div class="input_container">
                            <label for="password_field" class="input_label">Address 1</label>
                            <input id="password_field" class="input_field" type="text" name="input-name" title="Inpit title" placeholder="Address 1">
                        </div>
                        <div class="input_container">
                            <label for="password_field" class="input_label">Address 2</label>
                            <input id="password_field" class="input_field" type="text" name="input-name" title="Inpit title" placeholder="Address 2">
                        </div>
                        <div class="split">
                            <div class="input_item">
                                <div class="input_container">
                                    <label for="province_field" class="input_label">Province</label>
                                    <input id="province_field" class="input_field" type="text" name="input-name" placeholder="Province">
                                </div>
                            </div>
                            <div class="input_item">
                                <div class="input_container">
                                    <label for="city_field" class="input_label">City</label>
                                    <input id="city_field" class="input_field" type="text" placeholder="City">
                                </div>
                            </div>
                        </div>
                        <div class="split">
                            <div class="input_item">
                                <div class="input_container">
                                    <label for="province_field" class="input_label">Country</label>
                                    <input id="province_field" class="input_field" type="text" name="input-name" placeholder="Country">
                                </div>
                            </div>
                            <div class="input_item">
                                <div class="input_container">
                                    <label for="city_field" class="input_label">Zip Code</label>
                                    <input id="city_field" class="input_field" type="text" placeholder="0000">
                                </div>
                            </div>
                        </div>
                        <div class="input_container">
                            <label for="password_field" class="input_label">Amount</label>
                            <input id="password_field" class="input_field" type="number" name="input-name" title="Inpit title" placeholder="Amount">
                        </div>
                    </div>
                    <button class="purchase--btn">Pay Now</button>
                </form>

              </div>
              <div class="tab-pane fade" id="profile3" role="tabpanel" aria-labelledby="profile-tab">
                <!-- <form class="form">
                    <div class="credit-card-info--form">
                        <div class="input_container">
                          <label for="password_field" class="input_label">Card holder full name</label>
                          <input id="password_field" class="input_field" type="text" name="input-name" title="Inpit title" placeholder="Enter your full name">
                        </div>
                        <div class="input_container">
                          <label for="password_field" class="input_label">Card Number</label>
                          <input id="password_field" class="input_field" type="number" name="input-name" title="Inpit title" placeholder="0000 0000 0000 0000">
                        </div>
                        <div class="split">
                            <div class="input_item">
                                <div class="input_container">
                                    <label for="province_field" class="input_label">Expiry Date</label>
                                    <input id="province_field" class="input_field" type="text" name="input-name" placeholder="01/23">
                                </div>
                            </div>
                            <div class="input_item">
                                <div class="input_container">
                                    <label for="city_field" class="input_label">CVV</label>
                                    <input id="city_field" class="input_field" type="number" placeholder="CVV">
                                </div>
                            </div>
                            <div class="input_container">
                                <label for="password_field" class="input_label">Amount</label>
                                <input id="password_field" class="input_field" type="number" name="input-name" title="Inpit title" placeholder="0.00">
                            </div>
                        </div>
                    </div>
                    <button class="purchase--btn">Pay Now</button>
                </form> -->
                <img src="../../../../../assets/images/banner/cs.jpg" class="comingsoon" alt="comingsoon">
              </div>
              <div class="tab-pane fade" id="contact3" role="tabpanel" aria-labelledby="contact-tab">
                <!-- <form class="form">
                    <div class="payment--options">
                        <button type="button" *ngFor="let bank of banks">
                            <img [src]="bank.logo_url" [alt]="bank.title">
                        </button>
                    </div>
                    <div class="separator">
                        <hr class="line">
                        <p>Please fill all fields</p>
                        <hr class="line">
                    </div>
                    <div class="credit-card-info--form">
                        <div class="input_container">
                          <label for="password_field" class="input_label">Full name</label>
                          <input id="password_field" class="input_field" type="text" name="input-name" title="Inpit title" placeholder="Enter your full name">
                        </div>
                        <div class="input_container">
                          <label for="password_field" class="input_label">Account Number</label>
                          <input id="password_field" class="input_field" type="number" name="input-name" title="Inpit title" placeholder="Account Number">
                        </div>
                        <div class="input_container">
                            <label for="password_field" class="input_label">Amount</label>
                            <input id="password_field" class="input_field" type="number" name="input-name" title="Inpit title" placeholder="0.00">
                        </div>
                    </div>
                    <button class="purchase--btn">Pay Now</button>
                </form> -->
                <img src="../../../../../assets/images/banner/cs.jpg" class="comingsoon" alt="comingsoon">
              </div>
            </div>
          </div>































        <!-- <div style="display: flex; justify-content: left; margin-bottom: 20px; max-width: 600px; margin: 20px auto; background: #f9f9f9; padding: 20px; border-radius: 8px; box-shadow: 0 2px 4px rgba(0,0,0,0.1);">
            <div style="cursor: pointer; margin-right: 10px;" (click)="selectPaymentMethod('onlineBanking')">
                <span [class.active-tab]="selectedPaymentMethod === 'onlineBanking'">Online Banking</span>
            </div>
            <div style="cursor: pointer; margin-right: 10px;" (click)="selectPaymentMethod('creditCard')">
                <span [class.active-tab]="selectedPaymentMethod === 'creditCard'">Credit Card</span>
            </div>
            <div style="cursor: pointer; margin-right: 10px;" (click)="selectPaymentMethod('digitalPayment')">
                <span [class.active-tab]="selectedPaymentMethod === 'digitalPayment'">E-Wallet</span>
            </div>
            </div> -->

            <div [ngSwitch]="selectedPaymentMethod">
            <!-- <div *ngSwitchCase="'onlineBanking'">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="grid">
                        <div *ngFor="let bank of banks" class="grid-item" [class.selected]="bank.bank_code === selectedBankCode" (click)="selectBank(bank.bank_code)">
                            <img [src]="bank.logo_url" [alt]="bank.title">
                            <p>{{ bank.title }}</p>
                          </div>
                      </div>


                    <div style="margin-bottom: 10px;">
                      <label for="fname" style="display: block; margin-bottom: 5px; color: #333;">First Name</label>
                      <input type="text" id="fname" formControlName="fname" style="width: 100%; padding: 10px; border: 1px solid #ccc; border-radius: 4px; box-sizing: border-box;">
                    </div>
        
                    <div style="margin-bottom: 10px;">
                        <label for="lname" style="display: block; margin-bottom: 5px; color: #333;">Last Name</label>
                        <input type="text" id="lname" formControlName="lname" style="width: 100%; padding: 10px; border: 1px solid #ccc; border-radius: 4px; box-sizing: border-box;">
                      </div>
        
                      <div style="margin-bottom: 10px;">
                        <label for="mname" style="display: block; margin-bottom: 5px; color: #333;">Middle Name</label>
                        <input type="text" id="mname" formControlName="mname" style="width: 100%; padding: 10px; border: 1px solid #ccc; border-radius: 4px; box-sizing: border-box;">
                      </div>
        
                      <div style="margin-bottom: 10px;">
                        <label for="email" style="display: block; margin-bottom: 5px; color: #333;">Email</label>
                        <input type="text" id="email" formControlName="email" style="width: 100%; padding: 10px; border: 1px solid #ccc; border-radius: 4px; box-sizing: border-box;">
                      </div>
                  
                    <div style="margin-bottom: 10px;">
                        <label for="mobile" style="display: block; margin-bottom: 5px; color: #333;">Mobile</label>
                        <div style="display: flex; align-items: center; border: 1px solid #ccc; border-radius: 4px; padding: 10px; box-sizing: border-box;">
                        <span style="margin-right: 5px; color: #333;">+63</span>
                        <input type="tel" id="mobile" maxlength="10" formControlName="mobile" placeholder="9_________" style="flex: 1; padding: 0; border: none; outline: none; box-sizing: border-box;">
                        </div>
                    </div>
          
        
                    <div style="margin-bottom: 10px;">
                        <label for="address_line1" style="display: block; margin-bottom: 5px; color: #333;">Address Line 1</label>
                        <input type="text" id="address_line1" formControlName="address_line1" style="width: 100%; padding: 10px; border: 1px solid #ccc; border-radius: 4px; box-sizing: border-box;">
                      </div>
        
                      <div style="margin-bottom: 10px;">
                        <label for="address_line2" style="display: block; margin-bottom: 5px; color: #333;">Address Line 2</label>
                        <input type="text" id="address_line2" formControlName="address_line2" style="width: 100%; padding: 10px; border: 1px solid #ccc; border-radius: 4px; box-sizing: border-box;">
                      </div>
        
                    <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">
                    
                        <div style="flex: 1; margin-right: 10px;">
                        <label for="address_city" style="display: block; margin-bottom: 5px; color: #333;">City</label>
                        <input type="text" id="address_city" formControlName="address_city" style="width: 100%; padding: 10px; border: 1px solid #ccc; border-radius: 4px; box-sizing: border-box;">
                        </div>
                        
                        <div style="flex: 1; margin-left: 10px;">
                        <label for="address_province" style="display: block; margin-bottom: 5px; color: #333;">Province</label>
                        <input type="text" id="address_province" formControlName="address_province" style="width: 100%; padding: 10px; border: 1px solid #ccc; border-radius: 4px; box-sizing: border-box;">
                        </div>
                        
          </div>
          
          <div style="display: flex; justify-content: space-between;">
            
            <div style="flex: 1; margin-right: 10px;">
              <label for="address_zip_code" style="display: block; margin-bottom: 5px; color: #333;">Zip Code</label>
              <input type="text" id="address_zip_code" formControlName="address_zip_code" style="width: 100%; padding: 10px; border: 1px solid #ccc; border-radius: 4px; box-sizing: border-box;">
            </div>
            
            <div style="flex: 1; margin-left: 10px;">
              <label for="address_country" style="display: block; margin-bottom: 5px; color: #333;">Country</label>
              <input type="text" id="address_country" formControlName="address_country" style="width: 100%; padding: 10px; border: 1px solid #ccc; border-radius: 4px; box-sizing: border-box;">
            </div>
            
          </div>
          
        
                      <div style="margin-bottom: 10px;">
                        <label for="amount" style="display: block; margin-bottom: 5px; color: #333;">Amount</label>
                        <input type="text" id="amount" formControlName="amount" style="width: 100%; padding: 10px; border: 1px solid #ccc; border-radius: 4px; box-sizing: border-box;">
                      </div>
                  
                    <div style="text-align: center; margin-top: 20px;">
                        <button class="btn btn-info text-white" type="submit" 
                                style="color: white; 
                                       padding: 10px; 
                                       width: 100%; /* Set the width to 100% to match the input boxes */
                                       border: 1px solid #ccc; /* Optional: to match the input style */
                                       border-radius: 4px; 
                                       cursor: pointer; 
                                       box-sizing: border-box;">PAY NOW</button>
                      </div>
                      
                  
                  </form>
            </div> -->
            <div *ngSwitchCase="'creditCard'">
                <!-- Credit Card Form Fields -->
                <div style="max-width: 600px; margin: 20px auto; background: #f9f9f9; padding: 20px; border-radius: 8px; box-shadow: 0 2px 4px rgba(0,0,0,0.1);">
                    
                </div>
  
              
            </div>
            <div *ngSwitchCase="'digitalPayment'">
                <!-- Digital Payment Form Fields -->
                <div style="max-width: 600px; margin: 20px auto; background: #f9f9f9; padding: 20px; border-radius: 8px; box-shadow: 0 2px 4px rgba(0,0,0,0.1);">
                    <p>Coming Soon</p>
                </div>
            </div>
            <!-- <div *ngSwitchCase="'cashCounter'"> -->
                <!-- Cash Counter Payment Form Fields -->
            <!-- </div> -->
        </div>

  

      
          


      </div>
     
      

</ng-template>