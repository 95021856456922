<!-- <app-loader *ngIf="!(users && roles)"></app-loader>-->
<app-loader *ngIf="loader"></app-loader> 

<div id="content" class="flex">
  <!-- ############ Main START-->
  <div class="pl-3 pr-3">
    <div   id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="--spam">Paygram Fund Transactions</h2>
          <small class="--desc">GoVIPCenter list of Paygram fund transactions</small>
        </div>
        <div class="flex"></div>
      </div>
    </div>
    <div id="page-content">
      <div class="card p-2">
        <!-- <div class="card mb-2 p-1" style="background-color: #182038"> -->
          <!-- Filters -->


          <div class="card mb-2 mt-0 p-3" style="box-shadow: 0 2px 4px 0 #182038, 0 2px 5px 0 #182038;">
            <div class="row">
                <div class="col-3">
                    <span class="font-weight-bold text-primary">Balance: </span> 
                    <span style="color:cadetblue; font-weight: bold;">{{ makeMoney(balance.govip_paygram) }}</span>
                </div>
                <div class="col-3 d-flex justify-content-end">
                    <button
                        class="btn btn-sm btn-primary font-weight-bold"
                        style="width: 170px;"
                        (click)="openCreateModal(createTransactionModal)"
                    >
                        Create Transaction &nbsp; <i class="fa fa-plus text-highlight"></i>
                    </button>
                </div>
                <div class="col-6" style="display: flex; align-items: center;">
                    <input
                        type="text"
                        (keyup.enter)="getFundTransaction(null, null, null, true)"
                        placeholder="Search..."
                        class="form-control"
                        formControlName="searchString"
                        style="background-color: rgb(255, 255, 255); color: black; border-color: #182038;"
                    />
                
                    <button
                        class="btn btn-sm btn-info ml-2 button--search font-weight-bold"
                        style="width: 150px;"
                        (click)="getFundTransaction(null, null, null, true)"
                    >
                        Search &nbsp; <i class="fa fa-search text-highlight"></i>
                    </button>
                </div>
                
            </div>
          </div>


        <div class="card mt-2">
          <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
            <thead>
              <tr>
                <!-- <th data-sortable="true" data-field="owner">Avatar</th> -->

                                
                <th data-sortable="true" data-field="project">
                    <div>Created Date</div>
                  </th>

                <th data-sortable="true" data-field="id">
                  <div [ngClass]="managerId" (click)="setPageSorting('managerId')">Transaction Name</div>
                </th>
                <th data-sortable="true" data-field="owner">
                  <div [ngClass]="username" (click)="setPageSorting('username')">Reference No</div>
                </th>

                <th data-field="finish"><span class="d-none d-sm-block">Transact by</span></th>

                <th data-field="finish" style="text-align: right;"><span class="d-none d-sm-block">Amount</span></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let user of users?.data
                    | paginate: { itemsPerPage: 10, currentPage: p, totalItems: users.totalRecords }
                "
                (click)="selectManager(walletModal, user)"
                style="cursor: pointer"
                class="odd"
                role="row"
              >
                <!-- <td>
                  <a href="#">
                    <span>
                      <img class="w-32 avatar" *ngIf="user.managerUser?.photo" [src]="buildUserPhoto(user) | authImage | async" />
                      <img
                        class="w-32 avatar"
                        *ngIf="!user.managerUser?.photo"
                        src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file64407579-6a37-49ab-b083-b882c28eea4e.png"
                        alt="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file64407579-6a37-49ab-b083-b882c28eea4e.png"
                      />
                    </span>
                  </a>
                </td> -->
                <td class="flex">
                    <div class="item-except text-sm h-1x">
                        {{ user.createdDate | date : "MMM dd, yyyy hh:mm:ss aa" }}
                    </div>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    <span style="color: cadetblue; font-weight: bold;">{{user?.transactionName}}</span>
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    <i *ngIf="user.imageUrl" class="fa fa-file"></i> &nbsp;
                    <span style="color: cadetblue; font-weight: bold;">{{user.refNo}}</span>
                  </div>
                </td>

                <td class="flex">
                    <div class="item-except text-sm h-1x align-left">
                        {{ user.transactByUser.username }}
                    </div>
                </td>

                <td class="flex" style="text-align: right;">
                    <div class="item-except text-sm h-1x">
                        <span *ngIf="user.transactionName == 'WITHDRAW'" style="color: red; font-weight: bold;">
                            {{ makeMoney(user.amount * -1) }}
                        </span>
                        <span *ngIf="user.transactionName == 'DEPOSIT'" style="color: green; font-weight: bold;">
                            {{ makeMoney(user.amount) }}
                        </span>
                    </div>
                  </td>
                
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
              <div class="col-sm-12 col-md-12">
                <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                  Showing {{ (users?.pageNumber - 1) * users?.pageSize + 1 }} to
                  {{ (users?.pageNumber - 1) * users?.pageSize + users?.data?.length }} of
                  {{ users?.data?.length }} entries
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-7" style="float: right">
            <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
              <ul class="pagination" style="float: right">
                <pagination-controls (pageChange)="getFundTransaction(buildPageUrl($event, 10))"></pagination-controls>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<ng-template #walletModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Paygram Fund Transaction Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
      <div class="d-flex flex-column">
        <div class="row">
            <div class="col-6">
                <div class="d-flex justify-content-center">
                    <img *ngIf="selectedManager.imageUrl" [src]="selectedManager.imageUrl" style="max-height: 820px; max-width: 100%;" alt="imgUrl">

                    <div class="form-group w-100" *ngIf="!selectedManager.imageUrl">
                        <div class="custum-file-upload-2 d-flex flex-column">
                            <div class="w-100 d-flex justify-content-center" *ngIf="fileView">
                                <img [src]="fileView" alt="fileView" style="max-height: 430px; max-width: 100%;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="user">
                    <div class="image"></div>
                    <div class="user__content">
                      <div class="text">
                        <span class="name">{{ selectedManager.transactionName }}</span>
                        <p class="username">Transaction Name</p>
                      </div>
                    </div>
                </div>

                <div class="user">
                    <div class="image"></div>
                    <div class="user__content">
                      <div class="text">
                        <span class="name">{{ selectedManager.refNo }}</span>
                        <p class="username">Reference No</p>
                      </div>
                    </div>
                </div>

                <div class="user">
                    <div class="image"></div>
                    <div class="user__content">
                      <div class="text">
                        <span class="name">{{ makeMoney(selectedManager.amount) }}</span>
                        <p class="username">Amount</p>
                      </div>
                    </div>
                </div>

                <div class="user">
                    <div class="image"></div>
                    <div class="user__content">
                      <div class="text">
                        <span class="name">{{ selectedManager.transactByUser.username }}</span>
                        <p class="username">Transact By</p>
                      </div>
                    </div>
                </div>

                <div class="user">
                    <div class="image"></div>
                    <div class="user__content">
                      <div class="text">
                        <span class="name">{{ selectedManager.createdDate | date : "MMM dd, yyyy hh:mm:ss a" }}</span>
                        <p class="username">Created Date</p>
                      </div>
                    </div>
                </div>

                <div class="form-group w-100" *ngIf="!selectedManager.imageUrl">
                    <label for="imageUrl" class="font-weight-bold">Upload Receipt 
                        <span *ngIf="isRequire" class="text-danger font-weight-bold">*</span> 
                    </label>

                    <div>
                        <div class="pointer custum-file-upload" (click)="triggerFile(receiptFile)">
                            <span class="w-40 avatar gd-info">
                                <i class="fa fa-upload" aria-hidden="true"></i>
                                <input #receiptFile  type="file" (change)="onUploadFile($event)" style="display: none;"
                                accept="image/jpeg,image/png,application/pdf" />
                            </span>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="col-12" *ngIf="!selectedManager.imageUrl">
                <button class="btn btn-sm btn-primary float-right mr-2" (click)="uploadReceipt(selectedManager)">Upload Receipt</button>
            </div>
            
        </div>
        
      </div>
  </div>
</ng-template>



<ng-template #createTransactionModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Create Transaction</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
        <form [formGroup]="fundTransactionForm">
            <div class="row">
                <div class="col-6">
                    <div class="form-group" [formGroup]="fundTransactionForm">
                        <div class="form-group row">
                            <div class="col-sm-3">
                            <label>Transaction: </label>
                            </div>
                            <div class="col-sm-9">
                            <select class="form-control " tabindex="-1" aria-hidden="true"
                                formControlName="transactionName">
                                <option value="">Choose...</option>
                                <option *ngFor="let type of filterByOptions" [value]="type.id">
                                {{ type.text }}
                                </option>
                            </select>
                            </div>
                        </div>
                    </div>

                    <app-textbox
                        [parent-form-group]="fundTransactionForm"
                        tab-index-start="0"
                        control-name="refNo"
                        read-only="false"
                        [no-special-char]="false"
                        label="Reference No"
                        placeholder="Reference No"
                        max-length="12"
                    >
                    </app-textbox>
            
                    <app-textbox-currency
                        [parent-form-group]="fundTransactionForm"
                        tab-index-start="0"
                        control-name="amount"
                        [read-only]="false"
                        read-only="false"
                        number-only="false"
                        label="Amount"
                        placeholder="0.00"
                        max-length="20"
                    >
                    </app-textbox-currency>

                    <div class="d-flex justify-content-end">
                        <div>
                            <span class="font-weight-bold text-primary">Avaialable Balance: </span> 
                            <span style="color:cadetblue; font-weight: bold;">{{ makeMoney(balance.govip_paygram) }}</span>
                        </div>
                    </div>

                    <div class="form-group w-100">
                        <label for="imageUrl" class="font-weight-bold">Upload Receipt 
                            <span *ngIf="isRequire" class="text-danger font-weight-bold">*</span> 
                        </label>

                        <div class="pointer custum-file-upload" (click)="triggerFile(receiptFile)">
                            <span class="w-40 avatar gd-info">
                                <i class="fa fa-upload" aria-hidden="true"></i>
                                <input #receiptFile  type="file" (change)="onUploadFile($event)" style="display: none;"
                                accept="image/jpeg,image/png,application/pdf" />
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-6 mb-0">
                    <div class="custum-file-upload-2 d-flex flex-column">
                        <div class="w-100 d-flex justify-content-center" *ngIf="fileView">
                            <img [src]="fileView" alt="fileView" style="max-height: 380px; max-width: 100%;">
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <hr>
                    <button
                        class="btn btn-sm btn-primary ml-2 font-weight-bold float-right mr-2"
                        style="width: 170px;"
                        (click)="processTransaction()"
                    >
                        Process Transaction &nbsp; <i class="fa fa-plus text-highlight"></i>
                    </button>
                </div>
            </div>
    
            <!-- Add your submit button or any other necessary buttons -->
          </form>
    </div>
</ng-template>