<app-loader *ngIf="!woSign"></app-loader>

<div class="page-hero page-container" id="page-hero">
  <div class="padding d-flex">
    <div class="page-title">
      <h2 class="text-md text-highlight">JuanPay BSP Certification</h2>
      <small class="text-highlight font-weight-normal font-italic">Module for BSP Certification.</small>
    </div>
    <div class="flex"></div>
  </div>

  <div class="text-center">
    <div class="padding card pt-5 pl-5 pr-5 pb-3">
      <div class="row">
        <div class="col-sm-12">
          <app-textbox
            [parent-form-group]="certificateFormGroup"
            tab-index-start="0"
            control-name="name"
            [error]="certificateFormGroup?.controls.name.errors | estatus"
            [error-message]="certificateFormGroup?.controls.name.errors | econtent"
            read-only="false"
            label="Name"
            placeholder="Name"
          >
          </app-textbox>

          <app-textbox
            [parent-form-group]="certificateFormGroup"
            tab-index-start="0"
            control-name="dti"
            [error]="certificateFormGroup?.controls.dti.errors | estatus"
            [error-message]="certificateFormGroup?.controls.dti.errors | econtent"
            read-only="false"
            label="DTI Name"
            placeholder="DTI Name"
          >
          </app-textbox>

          <app-textarea
            [parent-form-group]="certificateFormGroup"
            tab-index-start="0"
            control-name="address"
            [error]="certificateFormGroup?.controls.address.errors | estatus"
            [error-message]="certificateFormGroup?.controls.address.errors | econtent"
            read-only="false"
            label="Address"
            placeholder="Address"
          >
          </app-textarea>
          <hr />
          <button class="btn btn-primary btn-sm float-right" (click)="printWithoutSign('withSign')">
            Print With Signature &nbsp; <i class="fa fa-print"></i>
          </button>
          <button class="btn btn-primary btn-sm float-right mr-2" (click)="printWithoutSign('withoutsign')">
            Print W/O Signature&nbsp; <i class="fa fa-print"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- <div  class="padding d-flex">
    <div class="page-title">
      <h2 class="text-md text-highlight">List of issued DA5 Certificate</h2>
    </div>
    <div class="flex"></div>
  </div> -->
</div>

<div style="position: relative; text-align: center; color: white" id="withoutsign" hidden>
  <img
    src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/filea6aa96ab-7612-44a2-b716-cd51385d4031.png"
    alt="Snow"
    style="width: 100%"
  />
  <div style="position: absolute; top: 45%; left: 50%; transform: translate(-50%, -50%); width: 100%">
    <div>
      <div style="margin-bottom: 0px; margin-top: 4px">&nbsp;</div>
      <div style="padding: 50px">
        <h3 style="text-align: center"><u>CERTIFICATION</u></h3>
        <br />
        <br />
        <h5
          style="
            text-align: justify;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
              'Helvetica Neue', sans-serif;
            font-weight: 400;
          "
        >
          &nbsp; &nbsp; &nbsp; &nbsp; This is to certify that
          <span style="font-weight: 700">%%DTI%%</span>
          owned and operated by %%NAME%%, has a pending application to operate remittance services under Direct 5 Agent,
          Inc. (DA5). As per record %%DTI%% principal head office is located at %%ADDRESS%%.
        </h5>
        <br />
        <h5
          style="
            text-align: justify;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
              'Helvetica Neue', sans-serif;
            font-weight: 400;
          "
        >
          &nbsp; &nbsp; &nbsp; &nbsp; This certification is being issued upon the request of the said Prospective
          Sub-agent for whatever legal purpose it may serve
        </h5>
        <br />
        <h5
          style="
            text-align: justify;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
              'Helvetica Neue', sans-serif;
            font-weight: 400;
          "
        >
          &nbsp; &nbsp; &nbsp; &nbsp; Issued this {{ deyt }} at Quezon City, Philippines
        </h5>
        <br />
        <br />
        <h5 style="margin-bottom: 0">RICO FERNANDO M. CHICO</h5>
        <h5 style="font-weight: 400; margin-top: 0">
          President <br />ACM Business Solution Inc. <br />BSP - Operator Payment System <br />Reg. No. OPSCOR-2021-0008
        </h5>
      </div>
    </div>
  </div>
</div>

<div style="position: relative; text-align: center; color: white" id="withSign" hidden>
  <img
    src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/filea6aa96ab-7612-44a2-b716-cd51385d4031.png"
    alt="Snow"
    style="width: 100%"
  />
  <div style="position: absolute; top: 45%; left: 50%; transform: translate(-50%, -50%); width: 100%">
    <div>
      <div style="margin-bottom: 0px; margin-top: 4px">&nbsp;</div>
      <div style="padding: 50px">
        <h3 style="text-align: center">CERTIFICATION</h3>
        <br />
        <br />
        <h5
          style="
            text-align: justify;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
              'Helvetica Neue', sans-serif;
            font-weight: 400;
          "
        >
          &nbsp; &nbsp; &nbsp; &nbsp; This is to certify that
          <span style="font-weight: 700">%%DTI%%</span>
          owned and operated by %%NAME%%, has a pending application to operate remittance services under Direct 5 Agent,
          Inc. (DA5). As per record %%DTI%% principal head office is located at %%ADDRESS%%.
        </h5>
        <br />
        <h5
          style="
            text-align: justify;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
              'Helvetica Neue', sans-serif;
            font-weight: 400;
          "
        >
          &nbsp; &nbsp; &nbsp; &nbsp; This certification is being issued upon the request of the said Prospective
          Sub-agent for whatever legal purpose it may serve
        </h5>
        <br />
        <h5
          style="
            text-align: justify;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
              'Helvetica Neue', sans-serif;
            font-weight: 400;
          "
        >
          &nbsp; &nbsp; &nbsp; &nbsp; Issued this {{ deyt }} at Quezon City, Philippines
        </h5>
        <br />
        <br />
        <img
          src="../../../assets/images/gallery/eSign.png"
          height="90"
          width="90"
          alt=""
          style="margin-bottom: 0; padding-bottom: 0"
        />
        <h5 style="margin-bottom: 0; margin-top: 0; padding-top: 0">RICO FERNANDO M. CHICO</h5>
        <h5 style="font-weight: 400; margin-top: 0">
          President <br />ACM Business Solution Inc. <br />BSP - Operator Payment System <br />Reg. No. OPSCOR-2021-0008
        </h5>
      </div>
    </div>
  </div>
</div>
