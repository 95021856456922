import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';

import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { CurrencyPipe } from '@angular/common';
import { PrintService } from 'src/data/services/web/print.service';
import { ServiceChargeService } from 'src/data/services/web/service-charge.service';
import { PaymentCollectionService } from 'src/data/services/web/payment-collection.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-payment-collection',
  templateUrl: './payment-collection.component.html',
  styleUrls: ['./payment-collection.component.less']
})
export class PaymentCollectionComponent implements OnInit {

  @Input("application") application: any;
  paymentCollectionFormGroup : FormGroup;
  newFieldsFormGroup : FormGroup;
  
  formBuilder : FormBuilder;
  public emptyString = "";
  public billers : any = [];
  public biller : any;
  public billerCode : any;
  public billerTypes : any = [];
  public billersByBillerTypes : any = [];
  public selectedBillerCode : any;

  public activeTab = 1;
  public loader = true;
  public fee : any;

  @Output("closeModal") closeModal = new EventEmitter();
  
  constructor(
    private paymentCollectionService : PaymentCollectionService,
    private router: Router,
    private modalService: NgbModal,
    localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    formBuilder : FormBuilder) {
    this.formBuilder = formBuilder;
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.initPaymentCollectionFormGroup();
    this.initOtherDetailsFormGroup();
    this.loader = false;
  }

  initPaymentCollectionFormGroup(): void {
    this.paymentCollectionFormGroup = new FormGroup({
      billerName: new FormControl(this.emptyString, [Validators.required]),
      accountNumber: new FormControl(this.emptyString, [Validators.required]),
      accountName: new FormControl(this.emptyString, [Validators.required]),
      dueDate: new FormControl(this.emptyString),
      amount: new FormControl(this.emptyString, [Validators.required]),
      serviceCharge: new FormControl(this.emptyString, [Validators.required]),
      otherDetails: new FormControl(this.emptyString) //Json for new text fields
    });
  }

  initOtherDetailsFormGroup() {
    this.newFieldsFormGroup = new FormGroup({});

    // Listen for changes in the newFieldsFormGroup and update otherDetails accordingly
    this.newFieldsFormGroup.valueChanges.subscribe(() => {
      this.updateOtherDetails();
    });
  }

  dynamicFields: any[] = [];
  newFieldTitle: string = '';

  newFieldModalRef: NgbModalRef;
  // Open Add Field Modal
  openAddFieldModal(content: any) {
    this.newFieldModalRef = this.modalService.open(content, { centered: true, size: 'md' });
  }

  // Add New Field
  addNewField() {
    if (this.newFieldTitle) {
      const formControlName = this.newFieldTitle.replace(/\s+/g, '_').toLowerCase();

      // Add the new control to the form group
      this.newFieldsFormGroup.addControl(formControlName, new FormControl(this.emptyString));

      // Add the new field to the dynamicFields array
      this.dynamicFields.push({
        formControlName: formControlName,
        label: this.newFieldTitle
      });

      // Reset modal field title
      this.newFieldTitle = '';

      // Update the otherDetails JSON
      this.updateOtherDetails();
    }
  }

  // Remove Field
  removeField(index: number) {
    const field = this.dynamicFields[index];
    // Remove the control from the FormGroup
    this.newFieldsFormGroup.removeControl(field.formControlName);
    
    // Remove the field from dynamicFields array
    this.dynamicFields.splice(index, 1);

    // Update the otherDetails JSON
    this.updateOtherDetails();
  }

  // Update the otherDetails field
  updateOtherDetails() {
    const otherDetails: any = {};
    
    // Loop through dynamic fields and gather their values
    this.dynamicFields.forEach(field => {
      otherDetails[field.label] = this.newFieldsFormGroup.get(field.formControlName)?.value;
    });

    // Set the JSON value to otherDetails form control
    this.paymentCollectionFormGroup.get('otherDetails')?.patchValue(this.newFieldsFormGroup.value);
  }

 
  setActiveTab(){
    if(this.activeTab == 1){
      if(this.paymentCollectionFormGroup.controls.amount.value == "" ){
        PNotify.error({
          title: "Error",
          text: "All fields are required to proceed to next step.",
        });
      }else{
        this.activeTab++;
        console.log(this.paymentCollectionFormGroup.value);
        this.loader = false;
      }
    }else if(this.activeTab == 2){

      var hasError : boolean  = false;
      if(hasError){
        PNotify.error({
          title: "Request denied",
          text: "Please fill up all the required fields.",
        });
      }else{

      }
    }
  }

  setActiveTabPrev(){
    if(this.activeTab == 1){
      PNotify.error({
        title: "Error",
        text: "Action Denied.",
      });
    }else{
      this.activeTab--;
    }
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  codeStatus : boolean = false;
  code : any;
  onCodeCompleted(code: string) {
    this.code = code;
    this.codeStatus = code.length == 6;
  }

  
  processPayment()  {
    this.loader = true;
  
    this.paymentCollectionService.processPaymentCollection(this.paymentCollectionFormGroup.value, this.code)
      .subscribe((result) => {
        // Success case
        PNotify.success({
          title: "Success",
          text: "Payment Collection Successfully Added!",
        });
        this.loader = false;
        this.code = "";
        this.codeStatus = false;
        this.closeModal.emit();
  
      }, error => {
        // Error handling based on error status or error.reason
        this.loader = false;
        this.code = "";
        this.codeStatus = false;
  
        if (error.error && error.error.status === "490") {
          // Incorrect PIN code
          PNotify.error({
            title: "Failed",
            text: "Incorrect PIN code, please try again."
          });
        } else if (error.error && error.error.reason) {
          // Generic backend error message
          PNotify.error({
            title: "Failed",
            text: error.error.reason
          });
        } else {
          // Fallback for any other error
          PNotify.error({
            title: "Error",
            text: "An unexpected error occurred. Please try again."
          });
        }
      });
  }  

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  totalAmount(fee:any,amount:any) {
    try  {
    return this.makeMoney(Number(fee) + Number(amount));
    } catch(e){
      return "0.00";
    }
  }

}
