import { Message } from "@angular/compiler/src/i18n/i18n_ast";
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { AuthService } from "src/data/services/authentication/auth.service";
import { ManagerUsersService } from "src/data/services/web/manager-users.service";
import { RolesService } from "src/data/services/web/roles.service";
import { environment } from "src/environments/environment";
import { ModalConfirmationComponent } from "../modal-confirmation/modal-confirmation.component";

// PNotify
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { WebSettingsService } from "src/data/services/web/web-settings.service";
import { Select2OptionData } from "ng-select2";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";
import { FileUploadService } from "src/data/services/web/file-upload.service";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

declare let LiveAgent: any;
@Component({
    selector: 'app-migration-form',
    templateUrl: './migration-form.component.html',
    styleUrls: ['./migration-form.component.less']
  })
  export class MigrationFormComponent implements OnInit {


  @Output("closeModal") closeModal = new EventEmitter();
  @ViewChild('viewLoginModal') viewLoginModal: ElementRef;
  @Input("username") username: any;
  
  public formBuilder: FormBuilder;
  public loaderMessage : any;
  public storeActivationFormGroup: FormGroup;
  public isStoreActivationCreated: boolean;
  public user: any;

  public loginUserFormGroup: FormGroup;
  public signOffFormGroup: FormGroup;
  public storeSettingFormGroup: FormGroup;
  public storeAddressFormGroup: FormGroup;

  public commentFormGroup: FormGroup;
  public managerUserFormGroup: FormGroup;
  public fileFormGroup: FormGroup;
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public managerUsersService: ManagerUsersService;
  public emptyString = "";
  public roles: any;
  public bsModalRefConfirmation: BsModalRef;
  public modalService: BsModalService;
  public websettings: WebSettingsService;

  public corporateServicesSignOff:any = [];
  public financeSignOff: any = [];
  public supportSignOff: any = [];

  public comments: any = [];

  public accessToken: string;

  public base64Photo: string | ArrayBuffer;
  public base64Photo1: string | ArrayBuffer;
  public base64Photo2: string | ArrayBuffer;
  public base64Photo3: string | ArrayBuffer;

  public roleLevelId: any;
  public roleId: any;
  public acmUsername: any;

  public maxCashiersArray: any;
  public storeTypeOption: any;

  processCreate = false;
  loader : any = true;
  validAccount : any = false;
  public fileName: any = "Click here to choose...";

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    managerUsersService: ManagerUsersService,
    websettings: WebSettingsService,
    modalService: BsModalService,
    private route: ActivatedRoute,
    private modalServe: NgbModal,
    formBuilder: FormBuilder,
    private datepipe : DatePipe,
    private fileUploadService : FileUploadService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.managerUsersService = managerUsersService;
    this.websettings = websettings;
    this.modalService = modalService;
    this.formBuilder = formBuilder;
    this.accessToken = this.localStorageCacheService.getStorage("access-token");
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    this.acmUsername = this.localStorageCacheService.getStorage("username");
    PNotifyButtons; // Initiate PNotify buttons. Important!
  }


  viewLoginModalRef : any;

  ngOnInit(): void {
    
    if(!this.username){
      this.initLoginFormGroup();
      this.loader = false;
    }
  }

  

  ngAfterViewInit(){
    if(this.username){
      this.loadData();
      this.initCommentFormGroup();
      this.initSignOffFormGroup();
    }else{
      console.log('test');
      this.viewLoginModalRef = this.modalServe.open(this.viewLoginModal, { centered: true,size:'sm', backdrop: 'static'});
    }
  }

 

  loadData(){
    this.loader = true;
    this.initManagerUserFormGroup();
    this.initFilesFormGroup();
    this.setMaxCashiers();

  }

  initCommentFormGroup(): void {
    this.commentFormGroup = new FormGroup({
      comment: new FormControl(this.emptyString)
    });
  }

  initLoginFormGroup(): void {
    this.loginUserFormGroup = new FormGroup({
      username: new FormControl(this.emptyString),
      password: new FormControl(this.emptyString)
    });
  }

  initSignOffFormGroup(): void {
    this.signOffFormGroup = new FormGroup({
      corpServButton: new FormControl(this.emptyString),
      financeSignOff: new FormControl(this.emptyString),
      supportSignOff: new FormControl(this.emptyString)
    });
  }
  
  

  triggerFile(inputFile){
    inputFile.click();
  }

  
  saveComment() {
    if(confirm(`Are you sure you want to continue?`)) {
      var data = {
        username : this.acmUsername,
        comment : this.commentFormGroup.controls.comment.value
      }
      this.managerUsersService.postComment(this.username, "COMMENTS",JSON.stringify(data)).subscribe(data =>{
        this.comments = data;
        this.commentFormGroup.controls.comment.setValue(this.emptyString);
        PNotify.success({
          title: "Migration Form",
          text: "Comment posted successfully.",
        });
    });
    }


  }

  saveMigrationSignOff(status,origin) {

    if(confirm(`Are you sure you want to continue?`)) {
    this.signOffFormGroup.controls.corpServButton.setValue(status);

    if(origin == "CORPSERV" && this.roleId != "9" ){
      PNotify.error({
        title: "Migration Form SignOff",
        text: "Corporate Services Account is Required.",
      });
    }else if(origin == "FINANCE" && this.roleId != "7"){
      PNotify.error({
        title: "Migration Form SignOff",
        text: "Finance Account is Required.",
      });
    }else if(origin == "SUPPORT" && (this.roleId != "5" && this.roleId != "6")){
      PNotify.error({
        title: "Migration Form SignOff",
        text: "Support Account is Required.",
      });
    }else{

      switch(this.roleId){
        case "9" : 
          if(origin == "CORPSERV"){
            var data = {
              username : this.acmUsername,
              status : status
            }

            if(this.corporateServicesSignOff.length > 0){
              if (this.corporateServicesSignOff[0].status == status){
                PNotify.error({
                  title: "Invalid Action",
                  text: "Status change request is the last state already.",
                });
                break;
              } 
            }
          

            this.managerUsersService.postSignOffStatus(this.username, origin,JSON.stringify(data)).subscribe(data =>{
                this.corporateServicesSignOff = data;
                PNotify.success({
                  title: "Migration Form",
                  text: "Status updated successfully.",
                });
            });
          }else{
            PNotify.error({
              title: "Migration Form SignOff",
              text: "Corporate Services Account is Required.",
            });
          }
        break; 
        case "7" : 
          if(origin == "FINANCE"){
            var data = {
              username : this.acmUsername,
              status : status
            }

            if(this.financeSignOff.length > 0){
              if (this.financeSignOff[0].status == status){
                PNotify.error({
                  title: "Invalid Action",
                  text: "Status change request is the last state already.",
                });
                break;
              } 
            }


            this.managerUsersService.postSignOffStatus(this.username, origin,JSON.stringify(data)).subscribe(data =>{
                this.financeSignOff = data;
                PNotify.success({
                  title: "Migration Form",
                  text: "Status updated successfully.",
                });
            });
          }else{
            PNotify.error({
              title: "Migration Form SignOff",
              text: "Finance Account is Required.",
            });
          }
        break; 
        case "5" : 
        case "6" : 
          if(origin == "SUPPORT"){
              var data = {
                username : this.acmUsername,
                status : status
              }


              if(this.supportSignOff.length > 0){
                if (this.supportSignOff[0].status == status){
                  PNotify.error({
                    title: "Invalid Action",
                    text: "Status change request is the last state already.",
                  });
                  break;
                } 
              }

              this.managerUsersService.postSignOffStatus(this.username, origin,JSON.stringify(data)).subscribe(data =>{
                  this.supportSignOff = data;
                  PNotify.success({
                    title: "Migration Form",
                    text: "Status updated successfully.",
                  });
              });
            }else{
              PNotify.error({
                title: "Migration Form SignOff",
                text: "Support Account is Required.",
              });
            }
        break; 
    }  
    }
   
  }

    
   
    

  }
  

  public onUploadFile(event: any, formControlName : any) {
    const reader = new FileReader();
   
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      this.fileName = file.name;
      reader.onload = () => {
        this.base64Photo = reader.result;
        if(this.calculateImageSize(this.base64Photo)){
          this.fileFormGroup.controls[formControlName].patchValue(reader.result);
          this.fileFormGroup.controls[formControlName].markAsTouched();
          this.changeDetectorRef.markForCheck();
          PNotify.info({
            title: "Migration Form",
            text: "You need to save the changes before the image reflects on view file.",
          });
        }else{
          PNotify.error({
                title: "Migration Form",
                text: "File is too big. Must be less than or equal to 2mb.",
              });
        }
      };
    }
  }
  
  calculateImageSize(base64String) : boolean {
    let padding;
    let inBytes;
    let base64StringLength;
    if (base64String.endsWith('==')) { padding = 2; }
    else if (base64String.endsWith('=')) { padding = 1; }
    else { padding = 0; }

    base64StringLength = base64String.length;
    console.log(base64StringLength);
    inBytes = (base64StringLength / 4) * 3 - padding;
    return inBytes < 2000000;
  }


  public imagePreview: any;
  public uploadModal = null;
  fileType : any = "IMAGE";

  async preview(content: any,id : any){
    await this.fileUploadService.GetFileOutSingle(id).then(data =>{
        if(data.photoFormat == 'data:application/pdf;base64'){
          this.imagePreview = data.photo;
          this.fileType = "PDF";
        }else{
          this.imagePreview = `${data.photoFormat},${data.photo}`;
          this.fileType = "IMAGE";
        }
      this.uploadModal = this.modalServe.open(content, { centered: true,size:'xl', backdrop: 'static'});
    });

  }


  closePdf(){
    this.imagePreview = null;
  }


  getFileType(data){
    var array = data.split(',');
    return array[0].replace('data:','').replace(';base64','');
  }

  getBase64Type(data){
    var array = data.split(',');
    return array[1];
  }

  submitted = false;
  get f() { return this.managerUserFormGroup.controls; }
  get fi() { return this.fileFormGroup.controls; }

  initManagerUserFormGroup(): void {
    this.managerUserFormGroup = new FormGroup({
      username: new FormControl(this.emptyString),
      email: new FormControl(this.emptyString),
      firstName: new FormControl(this.emptyString),
      lastName: new FormControl(this.emptyString),
      middleName: new FormControl(this.emptyString),
      address: new FormControl(this.emptyString),
      contactNumber: new FormControl(this.emptyString),
      roleId: new FormControl("Manager"),
      employeeId: new FormControl(this.emptyString),
      longitude: new FormControl(this.emptyString),
      latitude: new FormControl(this.emptyString),
      photo: new FormControl(this.emptyString),
      status: new FormControl(true),
      redirect: new FormControl(environment.EMAIL_VERIFICATION_REDIRECT),

      dtiFormId: new FormControl(this.emptyString),
      applicationFormId: new FormControl(this.emptyString),
      validID1Id: new FormControl(this.emptyString),
      validID2Id: new FormControl(this.emptyString),
      unionBankApplicationFormId: new FormControl(this.emptyString),
      localRemittanceFormId: new FormControl(this.emptyString),
      atmWithdrawalApplicationFormId: new FormControl(this.emptyString),
      mayorsPermitId: new FormControl(this.emptyString),
      birRegistrationId: new FormControl(this.emptyString),
      googleMapScreenShotId: new FormControl(this.emptyString),
      shopFacadeImagesId: new FormControl(this.emptyString),
      amlcCertificateId: new FormControl(this.emptyString),

      mainActivationDate: new FormControl(this.emptyString),
      enterpriseActivationDate: new FormControl(this.emptyString),
      sponsor: new FormControl(this.emptyString),
      upline: new FormControl(this.emptyString),
      placement: new FormControl(this.emptyString),
      accountType: new FormControl(this.emptyString),
      storeType: new FormControl(this.emptyString, Validators.required),

      name: new FormControl(this.emptyString, Validators.required),
      maxCashiers: new FormControl(1),
      accountRemarks: new FormControl(this.emptyString),
      maxBillsAmountPerDay: new FormControl("100,000.00"),
      maxRemittanceAmountPerDay: new FormControl("100,000.00"),
      maxEpinsAmountPerDay: new FormControl("100,000.00"),
      maxEloadAmountPerDay: new FormControl("100,000.00"),

      number: new FormControl(this.emptyString),
      street: new FormControl(this.emptyString),
      subdivision: new FormControl(this.emptyString),
      barangay: new FormControl(this.emptyString, Validators.required),
      city: new FormControl(this.emptyString),
      municipality: new FormControl(this.emptyString),
      region: new FormControl(this.emptyString),

      dtiRegistrationDate: new FormControl(this.emptyString, Validators.required),
      dtiRegistrationName: new FormControl(this.emptyString, Validators.required),
      atmWithdrawalMachine: new FormControl(this.emptyString, Validators.required),
      bankName: new FormControl(this.emptyString, Validators.required),
      bankAccountNumber: new FormControl(this.emptyString, Validators.required),
      bankAccountName: new FormControl(this.emptyString, Validators.required),
      
      lifeTimeSystemUsage: new FormControl(false),
      areaProtection: new FormControl(false),
      completeEquipmentInclusion: new FormControl(false),

      comment: new FormArray([])
    });


    if(this.username){
      this.checkMemberDetailsACM();
    }else{
      this.checkMemberDetails();
    }
  }

  initFilesFormGroup(): void {
    this.fileFormGroup = new FormGroup({
      dtiForm: new FormControl(this.emptyString, Validators.required),
      applicationForm: new FormControl(this.emptyString),
      validID1: new FormControl(this.emptyString, Validators.required),
      validID2: new FormControl(this.emptyString , Validators.required),
      unionBankApplicationForm: new FormControl(this.emptyString),
      localRemittanceForm: new FormControl(this.emptyString),
      atmWithdrawalApplicationForm: new FormControl(this.emptyString),
      mayorsPermit: new FormControl(this.emptyString),
      birRegistration: new FormControl(this.emptyString),
      googleMapScreenShot: new FormControl(this.emptyString),
      shopFacadeImages: new FormControl(this.emptyString),
      amlcCertificate: new FormControl(this.emptyString)
    });
  }

  loadLaDesk(email:any,firstName:any,lastName:any,username:any,contactNumber:any): void {
  
    const scriptUrl = "https://juanpay.ladesk.com/scripts/track.js";
    const node = document.createElement("script");
    node.src = scriptUrl;
    node.id = "la_x2s6df8d";
    node.type = "text/javascript";
    node.async = true;
    node.charset = "utf-8";
    node.onload = function (e) {
    LiveAgent.clearAllUserDetails();
    LiveAgent.setUserDetails(email, firstName, lastName);
    LiveAgent.addContactField('username', username);
    LiveAgent.addContactField('contactnumber', contactNumber);
    LiveAgent.addContactField('email', email);
    LiveAgent.addContactField('name', firstName +  ' ' + lastName);
    LiveAgent.addContactField('csrcode','FROM MIGRATION');
    LiveAgent.createButton("01n1ydwf", document.getElementById("chatButton"));
  
  document.getElementsByTagName("head")[0].appendChild(node);
}
}
  
  async submitChanges(){
    this.submitted = true;

    // stop here if form is invalid
    if (this.managerUserFormGroup.invalid && !this.username) {
      debugger

      PNotify.error({
        title: "Migration Form",
        text: "Error in saving. Please fill all required fields.",
      });
        return;
    }

    // dtiFormId: new FormControl(this.emptyString),
    // applicationFormId: new FormControl(this.emptyString),
    // validID1Id: new FormControl(this.emptyString),
    // validID2Id: new FormControl(this.emptyString),

    if((this.managerUserFormGroup.controls.dtiFormId.value == "" || this.managerUserFormGroup.controls.validID1Id.value == "" || this.managerUserFormGroup.controls.validID2Id.value == "") && !this.username){
      if (this.fileFormGroup.invalid) {
        debugger
  
        PNotify.error({
          title: "Migration Form",
          text: "Error in saving. Please upload all required files.",
        });
          return;
      }
    }

    this.loader = true;
    var fileCount = this.fileFormGroup.controls.length;
    var fileCounter = 1;
    for (const field in this.fileFormGroup.controls) { 
      debugger
      if(this.fileFormGroup.controls[field].value){
        var file = {
          fileName : field,
          managerUserId : 0,
          photoFile : this.fileFormGroup.controls[field].value,
          purpose : "File Manager",
          username : this.username ? this.username : this.loginUserFormGroup.controls.username.value
        }
        await this.fileUploadService.FileUploadOutSingle(file).then(data =>{ 
          this.managerUserFormGroup.controls[field + 'Id'].setValue(data);
          this.loaderMessage = `Uploading ${fileCounter} of ${fileCount}. Please wait.`;
            fileCounter++;
        });
      }
    }

    this.loader = true;
    this.managerUsersService.postManagerUserMigration(this.managerUserFormGroup.value).subscribe(
    (result) => {
      PNotify.success({
        title: "Migration Form",
        text: "Successfully Saved.",
      });
      this.initFilesFormGroup();
      this.loaderMessage = "";
      this.loader = false;
    }, error => {
      PNotify.error({
        title: "Migration Form",
        text: "Error in saving. Please contact ACM support.",
      });
      this.loaderMessage = "";
      this.loader = false;
    });
    
    
  }

  setMaxCashiers() {
    this.maxCashiersArray = [
      { id: 1, name: "1" },
      { id: 2, name: "2" },
      { id: 3, name: "3" },
      { id: 4, name: "4" },
      { id: 5, name: "5" },
      { id: 6, name: "6" },
      { id: 7, name: "7" },
      { id: 8, name: "8" },
      { id: 9, name: "9" },
      { id: 10, name: "10" },
    ];

   
  }

  alreadyExist : any = false;
  enterpriseData : any;
  checkMemberDetails() {
    
    this.managerUsersService.getManagerVIPDetailsMigration(this.loginUserFormGroup.value).subscribe(
      (result) => {
          this.loadMemberData(result);
      },
      (error) => {
        PNotify.error({
          title: "Connecting to VIP",
          text: "Invalid old enterprise username or password.",
        });
        this.initLoginFormGroup();
        this.loader = false;
      }
    );
  }

  checkMemberDetailsACM() {
    
    this.managerUsersService.getManagerVIPDetailsMigrationACM(this.username).subscribe(
      (result) => {
        console.log(result);
        this.loadMemberData(result);
      },
      (error) => {
        PNotify.error({
          title: "Connecting to VIP",
          text: "Invalid old enterprise username or password.",
        });
        this.initLoginFormGroup();
        this.loader = false;
      }
    );
  }

  loadMemberData(result){
    this.enterpriseData = result;
    this.loadLaDesk(result.old.EmailAddress,result.old.FirstName,result.old.LastName,result.old.Username,result.old.MobileNo1);
    
   this.corporateServicesSignOff = result.corpServeSignOff ?  result.corpServeSignOff : []  ;
    this.financeSignOff = result.financeSignOff ?result.financeSignOff : [] ;
    this.supportSignOff = result.supportSignOff? result.supportSignOff :  [];
    this.comments = result.comments ? result.comments :  [];

    


    if(!result.isExist){
      if (result.old.Username) {
        this.managerUserFormGroup.controls.username.setValue(result.old.Username);
        this.managerUserFormGroup.controls.email.setValue(result.old.EmailAddress);
        this.managerUserFormGroup.controls.contactNumber.setValue(result.old.MobileNo1 ? result.old.MobileNo1 : 'Go to https://vipexclusiveclub.net and update your mobile no.');
        
        this.managerUserFormGroup.controls.address.setValue(result.old.Address1);
        this.managerUserFormGroup.controls.firstName.setValue(result.old.FirstName);
        this.managerUserFormGroup.controls.lastName.setValue(result.old.LastName);
        this.managerUserFormGroup.controls.middleName.setValue(result.old.MiddleName);

        this.managerUserFormGroup.controls.mainActivationDate.setValue(result.old.Created_Dt);
        this.managerUserFormGroup.controls.enterpriseActivationDate.setValue(result.old.Created_Dt);
        this.managerUserFormGroup.controls.sponsor.setValue(result.old.Sponsored_Id);
        this.managerUserFormGroup.controls.upline.setValue(result.old.Upline_Id);
        this.managerUserFormGroup.controls.placement.setValue(result.old.Placement);
        this.managerUserFormGroup.controls.accountType.setValue(result.old.Reg_Type);
        this.managerUserFormGroup.controls.address.setValue(result.old.Address1);

        this.managerUserFormGroup.controls.number.setValue(result.old.HouseUnitNumber);
        this.managerUserFormGroup.controls.street.setValue(result.old.StreetBuildingName);
        this.managerUserFormGroup.controls.subdivision.setValue(result.old.Subdivision);
        this.managerUserFormGroup.controls.city.setValue(result.old.City);
        this.managerUserFormGroup.controls.municipality.setValue(result.old.Municipality);
        this.managerUserFormGroup.controls.region.setValue(result.old.Region);
        
        
        this.loader = false;
        this.validAccount = true;
         this.viewLoginModalRef.close();
      } else {
        PNotify.error({
          title: "Connecting to VIP",
          text: "Invalid old enterprise username or password.",
        });
        this.initLoginFormGroup();
        this.loader = false;
      }
    }else{

      if (result.old.Username) {
        this.managerUserFormGroup.controls.username.setValue(result.old.Username);
        this.managerUserFormGroup.controls.email.setValue(result.old.EmailAddress);
        this.managerUserFormGroup.controls.contactNumber.setValue(result.old.MobileNo1 ? result.old.MobileNo1 : 'Go to https://vipexclusiveclub.net and update your mobile no.');
        
        this.managerUserFormGroup.controls.address.setValue(result.old.Address1);
        this.managerUserFormGroup.controls.firstName.setValue(result.old.FirstName);
        this.managerUserFormGroup.controls.lastName.setValue(result.old.LastName);
        this.managerUserFormGroup.controls.middleName.setValue(result.old.MiddleName);

        this.managerUserFormGroup.controls.mainActivationDate.setValue(this.datepipe.transform(result.old.Created_Dt, 'MM/dd/yyyy'));
        this.managerUserFormGroup.controls.enterpriseActivationDate.setValue(this.datepipe.transform(result.old.Created_Dt, 'MM/dd/yyyy'));
        this.managerUserFormGroup.controls.sponsor.setValue(result.old.Sponsored_Id);
        this.managerUserFormGroup.controls.upline.setValue(result.old.Upline_Id);
        this.managerUserFormGroup.controls.placement.setValue(result.old.Placement);
        this.managerUserFormGroup.controls.accountType.setValue(result.old.Reg_Type);
        
        this.managerUserFormGroup.controls.address.setValue(result.old.Address1);
        this.managerUserFormGroup.controls.longitude.setValue(result.meta.Longitude);
        this.managerUserFormGroup.controls.latitude.setValue(result.meta.Latitude);

        this.managerUserFormGroup.controls.dtiFormId.setValue(result.meta.DTIFormId);
        this.managerUserFormGroup.controls.applicationFormId.patchValue(result.meta.ApplicationFormId);
        this.managerUserFormGroup.controls.validID1Id.patchValue(result.meta.ValidID1Id);
        this.managerUserFormGroup.controls.validID2Id.patchValue(result.meta.ValidID2Id);
        this.managerUserFormGroup.controls.unionBankApplicationFormId.patchValue(result.meta.UnionBankApplicationFormId);
        this.managerUserFormGroup.controls.localRemittanceFormId.patchValue(result.meta.LocalRemittanceFormId);
        this.managerUserFormGroup.controls.atmWithdrawalApplicationFormId.patchValue(result.meta.AtmWithdrawalApplicationFormId);
        this.managerUserFormGroup.controls.mayorsPermitId.patchValue(result.meta.MayorsPermitId);
        this.managerUserFormGroup.controls.birRegistrationId.patchValue(result.meta.BirRegistrationId);
        this.managerUserFormGroup.controls.googleMapScreenShotId.patchValue(result.meta.GoogleMapScreenShotId);
        this.managerUserFormGroup.controls.shopFacadeImagesId.patchValue(result.meta.ShopFacadeImagesId);
        this.managerUserFormGroup.controls.amlcCertificateId.patchValue(result.meta.AmlcCertificateId);


        this.managerUserFormGroup.controls.storeType.setValue(result.meta.StoreType);
        this.managerUserFormGroup.controls.name.setValue(result.meta.Name);
        this.managerUserFormGroup.controls.number.setValue(result.old.HouseUnitNumber);
        this.managerUserFormGroup.controls.street.setValue(result.old.StreetBuildingName);
        this.managerUserFormGroup.controls.subdivision.setValue(result.old.Subdivision);
        this.managerUserFormGroup.controls.city.setValue(result.old.City);
        this.managerUserFormGroup.controls.municipality.setValue(result.old.Municipality);
        this.managerUserFormGroup.controls.region.setValue(result.old.Region);
        this.managerUserFormGroup.controls.barangay.setValue(result.meta.Barangay);
        
        this.managerUserFormGroup.controls.dtiRegistrationDate.setValue( this.datepipe.transform(result.meta.DtiRegistrationDate, 'MM/dd/yyyy'));
        this.managerUserFormGroup.controls.dtiRegistrationName.setValue(result.meta.DtiRegistrationName);
        this.managerUserFormGroup.controls.atmWithdrawalMachine.setValue(result.meta.AtmWithdrawalMachine);
        this.managerUserFormGroup.controls.bankName.setValue(result.meta.BankName);
        this.managerUserFormGroup.controls.bankAccountNumber.setValue(result.meta.BankAccountNumber);
        this.managerUserFormGroup.controls.bankAccountName.setValue(result.meta.BankAccountName);

        
        this.managerUserFormGroup.controls.lifeTimeSystemUsage.setValue(result.meta.LifeTimeSystemUsage);
        this.managerUserFormGroup.controls.areaProtection.setValue(result.meta.AreaProtection);
        this.managerUserFormGroup.controls.completeEquipmentInclusion.setValue(result.meta.CompleteEquipmentInclusion);



        // this.managerUserFormGroup.controls.comment.setValue(result.meta.Comment ? this.populateCommentDataFormArray(result.meta.Comment) : []);
      

        this.loader = false;
        this.validAccount = true;
       if(!this.username) this.viewLoginModalRef.close();
      } else {
        PNotify.error({
          title: "Connecting to VIP",
          text: "Invalid old enterprise username or password.",
        });
        this.initLoginFormGroup();
        this.loader = false;
      }
    }
  }

  // populateCommentDataFormArray(comments: any): void {
  //   console.log("comment: ", comments);

  //   comments.forEach((step) => {
  //     const commentFormGroup = this.formBuilder.group({
  //       username: new FormControl(step.username),
  //       createdDate: new FormControl(step.createdDate),
  //       comment: new FormControl(step.comment)
  //     });

  //     (this.managerUserFormGroup.controls.comment as FormArray).push(commentFormGroup);
  //   }); 
  // }

  // sendComment(){
  //   console.log(this.commentFormGroup);
  //   console.log(this.managerUserFormGroup);
  // }

  logout(){
    location.reload();
  }

  public onPhotoChange(event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.base64Photo = reader.result;

        this.managerUserFormGroup.patchValue({
          photo: reader.result,
        });

        this.changeDetectorRef.markForCheck();
      };
    }
  }

  backToManagerUsersList(): void {
    this.router.navigate(["acm", "manager-user-list"]);
  }



  createAccount(){
    if(this.roleId != "8"){
      PNotify.error({
        title: "Account Creation",
        text: "Compliance Account is Required.",
      });
    } else{
      if((this.corporateServicesSignOff.length > 0 && 
        this.financeSignOff.length > 0 && 
        this.supportSignOff.length > 0)){
        if (this.corporateServicesSignOff[0].status == 1 && this.financeSignOff[0].status == 1
          && this.supportSignOff[0].status == 1){

            if(confirm(`Are you sure you want to migrate the account of ${this.managerUserFormGroup.controls.username.value} to govipcenter?`)) {

              var createAccountPayload =
              {
                username: this.managerUserFormGroup.controls.username.value,
                email: this.managerUserFormGroup.controls.email.value,
                firstName: this.managerUserFormGroup.controls.firstName.value,
                lastName: this.managerUserFormGroup.controls.lastName.value,
                address: this.managerUserFormGroup.controls.address.value,
                contactNumber: this.managerUserFormGroup.controls.contactNumber.value,
                roleId: "Manager",
                employeeId: this.emptyString,
                photo: this.emptyString,
                status: true,
                redirect: environment.EMAIL_VERIFICATION_REDIRECT,
                // dtiForm: "",
                // applicationForm: "",
                // validID1: "",
                // validID2: "",
                mainActivationDate: this.managerUserFormGroup.controls.mainActivationDate.value,
                enterpriseActivationDate:  this.managerUserFormGroup.controls.enterpriseActivationDate.value,
                sponsor: this.managerUserFormGroup.controls.sponsor.value,
                upline: this.managerUserFormGroup.controls.upline.value,
                placement: this.managerUserFormGroup.controls.placement.value,
                accountType: this.managerUserFormGroup.controls.accountType.value,
                storeType: this.managerUserFormGroup.controls.storeType.value,
        
                name: this.managerUserFormGroup.controls.name.value,
                maxCashiers: this.managerUserFormGroup.controls.maxCashiers.value,
                accountRemarks: this.managerUserFormGroup.controls.accountRemarks.value,
                maxBillsAmountPerDay: this.managerUserFormGroup.controls.maxBillsAmountPerDay.value,
                maxRemittanceAmountPerDay: this.managerUserFormGroup.controls.maxRemittanceAmountPerDay.value,
                maxEpinsAmountPerDay: this.managerUserFormGroup.controls.maxEpinsAmountPerDay.value,
                maxEloadAmountPerDay: this.managerUserFormGroup.controls.maxEloadAmountPerDay.value,
        
                number: this.managerUserFormGroup.controls.number.value,
                street: this.managerUserFormGroup.controls.street.value,
                subdivision: this.managerUserFormGroup.controls.subdivision.value,
                barangay: this.managerUserFormGroup.controls.barangay.value,
                city: this.managerUserFormGroup.controls.city.value,
                municipality: this.managerUserFormGroup.controls.municipality.value,
                region: this.managerUserFormGroup.controls.region.value,
                longtitude: this.managerUserFormGroup.controls.longitude.value,
                latitude: this.managerUserFormGroup.controls.latitude.value
              };
        
              this.actionEventCreateManagerUser(createAccountPayload);
        
            }

          
        } else{
          PNotify.error({
            title: "Invalid Action",
            text: "Not yet signed off.",
          });
        }
      }else{
        PNotify.error({
          title: "Invalid Action",
          text: "Not yet signed off.",
        });
      }

    }
  }

  actionEventCreateManagerUser(createAccountPayload : any): void {
    this.processCreate = true;

    this.managerUsersService.createManagerUserMigration(createAccountPayload).subscribe(
      (result) => {
        const encId = this.cryptoService.set(this.data.authentication.privateKey, result.data.id);
        const encNewData = this.cryptoService.set(this.data.authentication.privateKey, "true");
        this.router.navigate(["acm", "manager-user-view", { id: encId, status: encNewData }]);

        PNotify.success({
          title: "Success",
          text: "The account has been successfully created.",
        });
        this.processCreate = false;
        this.closeModal.emit();
      },
      (error) => {

        if (error.status === 400) {

          this.processCreate = false;
          PNotify.notice({
            title: "Warning",
            text: "Could not store account with errors. " + JSON.stringify(error.error.errors)  ,
          });
        } else {
          // Other error
          this.processCreate = false;

          PNotify.error({
            title: "Error",
            text: "Something went wrong.",
          });
        }
      }
    );
  }

}

