import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { last } from 'lodash';
import { Data } from "src/data/data";

declare let LiveAgent: any;

@Component({
  selector: 'app-vips-live-agent',
  templateUrl: './vips-live-agent.component.html',
  styleUrls: ['./vips-live-agent.component.less']
})
export class VipsLiveAgentComponent implements OnInit {
  
  email;
  firstname;
  lastname;
  username;
  contactnumber;
  csrcode;
  privateKey;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParams;
    const email = queryParams['email'];
    const firstname = queryParams['firstname'];
    const lastname = queryParams['lastname'];
    const username = queryParams['username'];
    const contactnumber = queryParams['contactnumber'];
    const csrcode = queryParams['csrcode'];
    const privateKey = queryParams['key'];
    debugger
    console.log(email, firstname, lastname, username, contactnumber, csrcode);
    this.initLiveAgent(email, firstname,lastname,username,contactnumber,csrcode,privateKey);
  }

  initLiveAgent(email, firstName, lastName,username,contactNumber,csrCode,privateKey): void {
    //alert(email + "\n" + firstName + "\n" + lastName + "\n" + username + "\n" + contactNumber + "\n" + csrCode + "\n" + this.privateKey);
    if(privateKey == "3KU4@$K1B4S" || privateKey == "ekupaskibas"){
      const scriptUrl = "https://juanpay.ladesk.com/scripts/track.js";
      const node = document.createElement("script");
      node.src = scriptUrl;
      node.id = "la_x2s6df8d";
      node.type = "text/javascript";
      node.async = true;
      node.charset = "utf-8";
      node.onload = function (e) {
      LiveAgent.clearAllUserDetails();
      LiveAgent.setUserDetails(email, firstName, lastName);
      LiveAgent.addContactField('username', username);
      LiveAgent.addContactField('contactnumber', contactNumber);
      LiveAgent.addContactField('email', email);
      LiveAgent.addContactField('name', firstName +  ' ' + lastName);
      LiveAgent.addContactField('csrcode',csrCode);
      LiveAgent.createButton("01n1ydwf", document.getElementById("chatButton"));
    };
    document.getElementsByTagName("head")[0].appendChild(node);
    } else {

    }
  }
}
