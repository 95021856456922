<app-loader *ngIf="processUpdate"></app-loader>
<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">Transaction History</h2>
          <small class="text-highlight font-weight-normal font-italic">GoVIPCenter list of transactions</small>
        </div>
        <div class="flex"></div>
        <div>
          <div *ngIf="isDataNotNull">
            <button class="btn btn-md btn-success w-100" (click)="exportToExcel()">
              <i class="fa fa-file-excel"></i> &nbsp; Export to Excel
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="padding">
     

        <div class="toolbar" [formGroup]="searchByUsernameFormGroup">
          <!-- Filters -->

          <div class="card p-2 mb-2 w-100" style="background-color: #182038">
            <div class="row" [formGroup]="searchFormGroup">
              <div class="col-sm-4 mb-2 pr-0">
                <ng-select
                  [placeholder]="'Select Provider'"
                  [items]="providersOption"
                  [allowClear]="true"
                  style="width: 100%"
                  bindLabel="text" 
                  bindValue="id" 
                  formControlName="provider"
                ></ng-select>
              </div>

              <div class="col-sm-3 mb-2 pr-0">
                <ng-select
                  [items]="billerOption"
                  [placeholder]="'Select Biller'"
                  [allowClear]="true"
                  bindLabel="text" 
                  bindValue="id" 
                  formControlName="biller"
                >
                </ng-select>
              </div>

              <div class="col-sm-3 mb-2 pr-0">
                <ng-select
                  [items]="userIdFilterOptions"
                  [placeholder]="'Select Username'"
                  [allowClear]="true"
                  bindLabel="text" 
                  bindValue="id" 
                  formControlName="userId"
                ></ng-select>
              </div>

              <div class="col-sm-2 mb-2">
                <ng-select
                  [items]="statusOPtion"
                  [placeholder]="'Select Status'"
                  [allowClear]="true"
                  bindLabel="text" 
                  bindValue="id" 
                  formControlName="status"
                ></ng-select>
              </div>

              <div class="col-sm-4 pr-0">
                <input
                  type="text"
                  placeholder="Date Range"
                  class="form-control form-control-sm"
                  bsDaterangepicker
                  formControlName="dateRange"
                  style="background-color: white; color: black"
                  [bsConfig]="{
                    rangeInputFormat: 'YYYY-MM-DD',
                    dateInputFormat: 'YYYY-MM-DD',
                    showWeekNumbers: false
                  }"
                />
              </div>

              <div class="col-sm-3 pr-0">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control form-control-sm search"
                    placeholder="Account No"
                    formControlName="accountNo"
                    style="background-color: white; color: black"
                  />
                </div>
              </div>

              <div class="col-sm-3 pr-0">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control form-control-sm search"
                    placeholder="Request ID"
                    formControlName="requestId"
                    style="background-color: white; color: black"
                  />
                </div>
              </div>

              <div class="col-2">
                <button class="btn btn-sm btn-primary w-100" (click)="getTransaction()">
                  <i class="fa fa-search"></i> &nbsp; Search
                </button>
              </div>
              <div class="col-2"></div>
            </div>
          </div>

          <!-- List Toolbar Form -->
          <form class="form-inline"></form>

          <!-- Search Button -->
        </div>

        <app-igloo-transactions *ngIf="provider == 'Igloo'" [formGroupSearch]="searchFormGroup">
        </app-igloo-transactions>
        <app-igloo-ctpl-transactions *ngIf="provider == 'Igloo CTPL'" [formGroupSearch]="searchFormGroup">
        </app-igloo-ctpl-transactions>

      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>
