<div *ngIf="status == 1">
  <!-- <span class="badge badge-circle text-danger"></span>
  <small class=""> Rejected</small> -->
  <span class="badge badge-danger" style="width: 63px">Rejected</span>
</div>
<div *ngIf="status == 2">
  <!-- <span class="badge badge-circle text-success"></span>
  <small class=""> Completed</small> -->
  <span class="badge badge-success" style="width: 63px">Completed</span>
</div>
<div *ngIf="status == 3">
  <!-- <span class="badge badge-circle text-warning"></span>
  <small class=""> Pending</small> -->
  <span class="badge badge-warning" style="width: 63px">Pending</span>
</div>
<div *ngIf="status == 4">
  <!-- <span class="badge badge-circle text-primary"></span>
  <small class=""> Refund</small> -->
  <span class="badge badge-primary" style="width: 63px"> Refund</span>
</div>
<div *ngIf="status == 5">
  <!-- <span class="badge badge-circle text-primary"></span>
  <small class=""> Refund</small> -->
  <span class="badge badge-info" style="width: 63px"> For Verification</span>
</div>
<div *ngIf="status == 6">
  <!-- <span class="badge badge-circle text-primary"></span>
  <small class=""> Refund</small> -->
  <span class="badge badge-danger" style="width: 63px"> Voided</span>
</div>
<div *ngIf="status == 7">
  <!-- <span class="badge badge-circle text-primary"></span>
  <small class=""> Refund</small> -->
  <span class="badge badge-info" style="width: 63px"> Submitted</span>
</div>
