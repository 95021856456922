import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { CryptoService } from 'src/data/crypto/crypto.service';
import { Data } from 'src/data/data';
import { SunlifeGrepaService } from 'src/data/services/web/sunlife-grepa.service';

@Component({
  selector: 'app-gvlpl',
  templateUrl: './gvlpl.component.html',
  styleUrls: ['./gvlpl.component.less']
})
export class GvlplComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private cryptoService: CryptoService,
    private sunlifeGrepaService: SunlifeGrepaService,
    private datakey: Data) { }
    requestId: any;
    data : any;
    loader : any = true;
  ngOnInit(): void {
  
    this.route.params.subscribe((params) => {
      const id = decodeURI(params.id || "");
      this.requestId = this.cryptoService.get(this.datakey.authentication.privateKey,id);
      
      this.getTransaction();
    });
  }

  getTransaction() {
    this.sunlifeGrepaService.getTransaction(this.requestId).subscribe((sunlifeGrepa) => {
    this.data = sunlifeGrepa;
    let date: Date = new Date(this.data.createdDate);
    date.setDate(date.getDate() + 1);
    this.data.createdDate = date;
       this.loader = false;
    }, error => {
      this.loader = false;
    });
  }


}
