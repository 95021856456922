import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { ECPayService } from 'src/data/services/web/ecpay.service';

import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { CurrencyPipe } from '@angular/common';
import { PrintService } from 'src/data/services/web/print.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-ecpay',
  templateUrl: './ecpay.component.html',
  styleUrls: ['./ecpay.component.less']
})
export class EcpayComponent implements OnInit {


  @Input("application") application: any;
  @Input("shortcutCode") shortcutCode: any;
  @Input("shortcutAccumulator") shortcutAccumulator: any;
  @Output("closeOnly") closeOnly = new EventEmitter();
  @Output("getShortcutApps") getShortcutApps = new EventEmitter();
  
  billerFormGroup : FormGroup;
  formBuilder : FormBuilder;
  ecpayService : ECPayService;
  public emptyString = "";
  public billers : any = [];
  public biller : any;
  public billerRate : any;
  public billerCode : any;
  public billerTypes : any = [];
  public billersByBillerTypes : any = [];
  public selectedBillerCode : any;

  public activeTab = 1;
  public loader = true;
  public fee : any;

  
  @Output("closeModal") closeModal = new EventEmitter();
  

  constructor(ecpayService : ECPayService, 
    private router: Router,
    localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    formBuilder : FormBuilder,
    private webSettingsService : WebSettingsService) {
    this.ecpayService = ecpayService;
    this.formBuilder = formBuilder;
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.initBillerTypeFormGroup();
    this.getBillers();

    if(this.shortcutCode){
      setTimeout(() => {
        this.initShortcut();
      }, 1000);
    }
  }

  // initShortcut(): void {
  //   this.billerCode = this.shortcutCode.itemCode;
  //   this.getBiller();
  // }

  initShortcut(): void {
    this.billerFormGroup.controls.billerType.patchValue(this.shortcutCode.category);
    this.getBillersByBillerType(this.shortcutCode.category);
    this.billerFormGroup.controls.billerCode.patchValue(this.shortcutCode.itemCode);
    this.billerCode = this.shortcutCode.itemCode;
    this.getBiller();
  }

  initBillerTypeFormGroup(): void {
    this.billerFormGroup = new FormGroup({
      billerType: new FormControl(this.emptyString, [Validators.required]),
      billerCode: new FormControl(this.emptyString, [Validators.required]),
      params: this.formBuilder.group({})
    });
  }

  reInitBillerTypeFormGroup(): void {
    this.billerFormGroup = new FormGroup({
      billerType: new FormControl(this.billerFormGroup.controls.billerType.value, [Validators.required]),
      billerCode: new FormControl(this.billerFormGroup.controls.billerCode.value, [Validators.required]),
      params: this.formBuilder.group({})
    });
  }

  async getBillers() {
    await this.ecpayService.getBillers().subscribe((billers) => {
      this.billers = billers;
      this.getBillerTypes(billers);
    });
  }

  addShortcut(){
    this.ecpayService.addFavoriteBills(this.billerCode, this.billerType).subscribe((result) => {
      debugger
      if(result){
        PNotify.success({
          title: "Success",
          text: "Biller successfully added to your favorites.",
        });

        this.getShortcutApps.emit();
        this.isShortcutExisting = true;
      } else {
        PNotify.error({
          title: "Duplicate",
          text: "Biller is already exist.",
        });
        this.getShortcutApps.emit();
        this.isShortcutExisting = true;
      }
    }, error => {
      PNotify.error({
        title: "Error",
        text: "Something went wrong. Please contact support for assistance.",
      });
    });
  }

  removeShortcut(){
    this.ecpayService.removeFavoriteBills(this.billerCode, this.billerType).subscribe((result) => {
      PNotify.success({
        title: "Success",
        text: "Biller successfully removed to your favorites.",
      });
      this.getShortcutApps.emit();
      this.isShortcutExisting = false;
    }, error => {
      PNotify.error({
        title: "Error",
        text: "Something went wrong. Please contact support for assistance.",
      });
    });
  }
  

  isShortcutExisting: any = false;
  checkExisting(billerCode: any){
    this.isShortcutExisting = this.shortcutAccumulator.some(x => x.itemCode == billerCode);
    debugger
    return this.shortcutAccumulator.some(x => x.itemCode == billerCode);
  }

  getBillerTypes(billers: any) {
    var lookup = {};
    var items = billers;

    // Sort the items by type in ascending order
    items.sort((a, b) => a.type.localeCompare(b.type));

    for (var item, i = 0; item = items[i++];) {
        var type = item.type;

        if (!(type in lookup)) {
            lookup[type] = 1;
            this.billerTypes.push(type);
        }
    }
    this.loader = false;
}

  billerType: any;
  getBillersByBillerType(billerType : any){
    this.billerType = billerType;
    this.billersByBillerTypes = this.billers.filter(x=>x.type == billerType);
  }

  setBillerCode(billerCode : any){
    this.billerCode = billerCode;
    this.checkExisting(billerCode);
  }


  getBiller() {
    this.activeTab++;
    this.biller= this.billers.filter(x=>x.code == this.billerCode)[0];
    this.addBillerParam(this.biller.meta);
    this.fee = this.biller.fee;
    
  }

  inquireBiller() {
    this.loader = true;
  //  this.ecpayService.inquireBiller(this.paramsFormGroup().value,this.billerCode, this.fee).subscribe((biller) => {
      
        PNotify.info({
          title: "Validation will be execute after submitting",
          text: "Accepted",
        });
        this.loader = false;
        this.code = "";
        this.activeTab = 3;
        this.codeStatus=false;
      
  //  }, error => {
  //    PNotify.error({
  //      title: "Payment Validation",
  //      text: error.error.reason,
   //   });
   //   this.loader = false;

 //   });
  }//

  paramsFormGroup() : FormGroup{
    return this.billerFormGroup.get('params') as FormGroup;
  } 

  sssPaymentTypeAmount(){
try {
  return this.paramsFormGroup().controls['paymentType'].value == '5';
}catch{

}
  return false;
  }


  addBillerParam(meta: any){
    if (this.selectedBillerCode != this.billerFormGroup.controls.billerCode.value){
        this.reInitBillerTypeFormGroup();
        
        meta.forEach(param => {
          (this.billerFormGroup.get("params") as FormGroup).addControl(
            param.field,
            new FormControl("")
          );

          if(param.field == "paymentType"  && param.type == "Dropdown"){
            this.paramsFormGroup().controls[param.field].valueChanges.subscribe(data =>{
              
                if(this.paramsFormGroup().controls[param.field].value == 5){
                  this.paramsFormGroup().controls["amount"].setValue("200.00");
                }else{
                  this.paramsFormGroup().controls["amount"].setValue("");
                }
            });
          }
        });
        
   }
    this.selectedBillerCode = this.billerFormGroup.controls.billerCode.value;
  }

  setActiveTab(){

    if(this.activeTab == 1){
      if(this.billerFormGroup.status == "INVALID"){
        PNotify.error({
          title: "Error",
          text: "All fields are required to proceed to next step.",
        });
      }
      else{
        this.getBiller();
        //this.getBillerRates();
      }
    }else if(this.activeTab == 2){

      var hasError : boolean  = false;
      this.biller.meta.forEach(param => {
        if(!this.paramsFormGroup().controls[param.field].value && param.is_required){
          this.paramsFormGroup().controls[param.field].setErrors({'required': param.label + " is required."});
          hasError = true;

          
        }
      });


      if(hasError){
        PNotify.error({
          title: "Request denied",
          text: "Please fill up all the required fields.",
        });
      } else{
        this.inquireBiller();
      }

     
    }
  }

  setActiveTabPrev(){
    if(this.activeTab == 1){
      PNotify.error({
        title: "Error",
        text: "Action Denied.",
      });
    }else{
      this.activeTab--;
    }
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  codeStatus : boolean = false;
  code : any;
  onCodeCompleted(code: string) {
    this.code = code;
    this.codeStatus = code.length == 6;
  }


  processPayment(){
      this.loader = true;
      this.ecpayService.processPayment(
        this.paramsFormGroup().value,
        this.billerCode, 
        this.fee, 
        this.biller.name, 
        this.biller.type,
        this.code)
        .subscribe((biller) => {
          PNotify.success({
            title: "Payment Success",
            text: biller.reason,
          });
          this.loader = false;
          this.code = "";
          this.codeStatus=false;
          this.closeModal.emit();
        
      }, error => {
        PNotify.error({
          title: "Payment Failed",
          text: error.error.reason,
        });
        this.loader = false;
        this.code = "";
        this.codeStatus = false;
      });
  }



  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  totalAmount(fee:any,amount:any){
    try  {
    return this.makeMoney(Number(fee) + Number(amount.replace(/,/g, '')));
    } catch(e){
      return "0.00";
    }
    }

  optionValue(param)
  {
    try  {
      return param.options.filter(x=>x.value == this.paramsFormGroup().controls[param.field].value)[0].key;
    } catch{


    }
  
  }
}
