import { Component, Input, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { UserService } from 'src/data/services/web/user.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5
import { Select2OptionData } from "ng-select2";
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-ticket-create-transaction',
  templateUrl: './ticket-create-transaction.component.html',
  styleUrls: ['./ticket-create-transaction.component.less']
})
export class TicketCreateTransactionComponent implements OnInit {

  @Input() requestId: string;
  @Input() transactionType: string;
  @Input() username: string;
  ticketFormGroup : FormGroup;
  emptyString = '';
  roleLevelId : any;
  htmlContent = '';
  loader : any = false;
  ticketId : any;
  public statusOPtion: Array<Select2OptionData>;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    minHeight: '5rem',
    placeholder: 'Enter ticket message here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    toolbarHiddenButtons: [
      [
        // 'undo',
        // 'redo',
        // 'bold',
        // 'italic',
        // 'underline',
        // 'strikeThrough',
        // 'subscript',
        'superscript',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        // 'indent',
        // 'outdent',
        // 'insertUnorderedList',
        // 'insertOrderedList',
        // 'heading',
        // 'fontName'
      ],
      [
        // 'fontSize',
        // 'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };

  isOpenTicketVisible : any = false;

  constructor(private userService : UserService,
    private localStorageCacheService : LocalStorageCacheService,
    private websettings : WebSettingsService) { 
    
    PNotifyButtons; // Initiate PNotify buttons. Important!
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");

   
  }

  ngOnInit(): void {

    switch (this.transactionType) {
      case "BILLS_PAYMENT_MULTISYS":
        this.isOpenTicketVisible = this.roleLevelId == "1" || this.roleLevelId == "2"  ? false : true;
        break;
      case "BILLS_PAYMENT_ECPAY":
        this.isOpenTicketVisible = this.roleLevelId == "1" || this.roleLevelId == "2"  ? false : true;
        break;
      case "BILLS_PAYMENT_CTI":
          this.isOpenTicketVisible = this.roleLevelId == "1" || this.roleLevelId == "2"  ? false : true;
          break;
        case "TOP_UP_ECPAY":
          this.isOpenTicketVisible = this.roleLevelId == "1" || this.roleLevelId == "2"  ? false : true;
          break;
        case "CASH_ECPAY":
          this.isOpenTicketVisible = this.roleLevelId == "1" || this.roleLevelId == "2"  ? false : true;
          break;
          case "IBAYAD_TRANSFER":
            this.isOpenTicketVisible = this.roleLevelId == "1" || this.roleLevelId == "2"  ? false : true;
            break;
        case "REQUEST_FUND":
          this.isOpenTicketVisible = this.roleLevelId == "1" || this.roleLevelId == "3"  ? false : true;
          break;
        case "ALLOCATE_FUND":
          this.isOpenTicketVisible = this.roleLevelId == "1" ? false : true;
          break;
          
      default:
        break;
    }

    this.loadTicketHistory();
    this.getStatus();
    this.initSearchFormGroup();

  }

  
  getStatus() {
    this.websettings.getWebSettingsByIdentifier("TICKET_OPTIONS").subscribe((result) => {
     
      this.statusOPtion = result.data.map((status) => {
        return {
          id: status.parameter,
          text: status.value,
        };
      });
    });
  }

  initSearchFormGroup(): void {
    this.ticketFormGroup = new FormGroup({
      status: new FormControl(this.emptyString)
    });
  }



 loadTicketHistory(){
  this.loader = true;
  var ticketHistoryDetails = {
    username: this.username,
    requestId: this.requestId,
    transactionType: this.transactionType
  }

  this.userService.getTransactionTickets(ticketHistoryDetails).subscribe(
    (result) => {
      if(result.data.result.length > 0){
        this.ticketId = result.data.result[0].id;
       
      } 
      this.ticketHistories = result.data.result;
        this.loader = false;
    }, error => {
        this.loader = false;

    });
 }


  ticketHistories : any;

  submitTicket(){
    var ticketReason = this.statusOPtion.filter(x=>x.id == this.ticketFormGroup.controls.status.value)[0].text;
    this.loader = true;
    var ticketDetails = {
      message: '<h3> Ticket Reason : ' + ticketReason + '<h3>' + this.htmlContent,
      requestId: this.requestId,
      transactionType: this.transactionType
    }
  

    this.userService.createTicket(ticketDetails).subscribe(
      (result) => {
        
          this.loadTicketHistory();
          PNotify.success({
            title: "Ticket Created Successfully",
            text: "Please wait for one of our CET to respond to your ticket thru your registered email.",
          });
      }, error => {
          this.loader = false;

      });
  }

  reOpenTicket(){
    this.loader = true;
    this.userService.reOpenTicket(this.ticketId).subscribe(
      (result) => {
         
          this.loadTicketHistory();
          PNotify.success({
            title: "Ticket Re-Opened Successfully",
            text: "Please wait for one of our CET to respond to your ticket thru your registered email.",
          });
      }, error => {
          this.loader = false;

      });

    
   

  }

  trailColor(status): any{
    switch (status) {
      case "I": return "danger";
      case "N": return "primary";
      case "A": return "info";
      case "C": return "warning";
      case "R": return "success";
      default: return "default";
    }

    
  
    
  }

  reOpenButton(status): any{
    switch (status) {
      case "R": return true;
      default: return false;
    }
  }
  
  lastStatus(status): any{
    switch (status) {
      case "I": return "Chat Initiated";
      case "N": return "New Ticket Filed";
      case "A": return "Answered By Agent";
      case "C": return "Outlet Responded";
      case "R": return "Resolved";
      default: return "default";
    }
    
  }

}
