

import { FormGroup } from "@angular/forms";
import { animate, style, transition, trigger } from "@angular/animations";
import { Component, Input, OnInit } from "@angular/core";
import { Meta } from "src/data/models/meta.model";

@Component({
  selector: "app-monthpicker",
  templateUrl: "./monthpicker.component.html",
  styleUrls: ["./monthpicker.component.less"],
  animations: [
    trigger("inOutAnimation", [transition(":leave", [animate("0.2s ease-out", style({ height: 0, opacity: 0 }))])]),
  ],
})
export class MonthpickerComponent implements OnInit {
  @Input("parent-form-group") parentFormGroup: FormGroup;
  @Input("control-name") controlName: string;
  @Input() id: string;
  @Input() value = "";
  @Input() label: string;
  @Input() maxlength = "100";
  @Input("place-holder") placeholder: string;
data: any;
  @Input("error") error: boolean;
  @Input("error-message") errorMessage: string;
  toggle = false;
  constructor() {}

  onClickEventOutSide(val): void {
    this.toggle = val;
  }

  async ngOnInit() {
    

    var currentTime = new Date()
    var month = currentTime.getMonth() + 1
    
    // returns the day of the month (from 1 to 31)
    var day = currentTime.getDate()
    
    // returns the year (four digits)
    var year = currentTime.getFullYear()

    this.data = [
      { value : year + "-" +  "01" + "-" + this.pad(1, 2), key : "January" },
      { value : year + "-" +  "02"+ "-" + this.pad(1, 2), key : "February" },
      { value : year + "-" +  "03"+ "-" + this.pad(1, 2),  key : "March" },
      { value : year + "-" +  "04"+ "-" + this.pad(1, 2),  key : "April" },
      { value : year + "-" +  "05"+ "-" + this.pad(1, 2),  key : "May" },
      { value : year + "-" +  "06"+ "-" + this.pad(1, 2),  key : "June" },
      { value : year + "-" +  "07"+ "-" + this.pad(1, 2),  key : "July" },
      { value : year + "-" +  "08"+ "-" + this.pad(1, 2),  key : "August" },
      { value : year + "-" +  "09"+ "-" + this.pad(1, 2),  key : "September" },
      { value : year + "-" +  "10"+ "-" + this.pad(1, 2),  key : "October" },
      { value : year + "-" +  "11"+ "-" + this.pad(1, 2),  key : "November" },
      { value : year + "-" +  "12"+ "-" + this.pad(1, 2),  key : "December" }
     ]

    if (this.parentFormGroup.controls[this.controlName].value) {
      const value: number = this.parentFormGroup.controls[this.controlName].value;
      this.value = this.data.filter((x) => x.value === value)[0].key;
    }
  }

  pad(num:number, size:number): string {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}

  onClick(action): void {
    setTimeout(() => {
      this.toggle = action === "close" ? false : true;
    }, 200);
  }

  selectedOption(option): void {
    this.value = option.key;
    this.toggle = false;
    this.parentFormGroup.controls[this.controlName].setValue(option.value);
  }
}
