<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="--spam">List of SPX Rider</h2>
          <small class="--desc">JuanPay settings for managing SPX Riders</small>
        </div>
        <div class="flex"></div>
      </div>
    </div>
    <div id="page-content">
      <div class="card p-2">
        <!-- <div class="card mb-2 p-1" style="background-color: #182038"> -->
        <!-- Filters -->

        <div class="card mb-2 mt-0" style="box-shadow: 0 2px 4px 0 #ee4d2d, 0 2px 5px 0 #ee4d2d">
          <div class="row" [formGroup]="searchByFormGroup">
            <table id="datatable" class="table" role="grid" aria-describedby="datatable_info">
              <thead>
                <tr>
                  <th>
                    <button
                      class="btn btn-sm mr-2 ml-5 mt-2 btn-secondary button--create font-weight-bold"
                      *ngIf="roleId == 1 || roleId == 2 || roleId == 7 || roleId == 19"
                      style="float: right"
                      (click)="openBatchDeacModal(batchDeactivationModal)"
                    >
                      Batch Deactivation &nbsp; <i class="fa fa-file-excel text-highlight"></i>
                    </button>
                  </th>
                  <th></th>
                  <th></th>
                  <th>
                    <button
                      class="btn btn-sm mt-2 mr-2 btn-info button--search font-weight-bold"
                      style="float: right"
                      (click)="searchData()"
                    >
                      Search &nbsp; <i class="fa fa-filter text-highlight"></i>
                    </button>

                    <button
                      class="btn btn-sm mr-2 mt-2 ml-2 btn-success button--create font-weight-bold"
                      *ngIf="roleId == 1 || roleId == 2 || roleId == 7 || roleId == 19"
                      (click)="getTransactionExcel()"
                    >
                      Export to Excel &nbsp; <i class="fa fa-file-excel text-highlight"></i>
                    </button>

                    <button
                      class="btn btn-sm mr-2 mt-2 btn-primary button--create font-weight-bold"
                      *ngIf="roleId == 1 || roleId == 2 || roleId == 7 || roleId == 19"
                      (click)="openFileUpload(uploadFilemodal)"
                    >
                      Upload Batch &nbsp; <i class="fa fa-file-excel text-highlight"></i>
                    </button>

                    <button
                      class="btn btn-sm mr-2 mt-2 btn-danger button--create font-weight-bold"
                      *ngIf="roleId == 1 || roleId == 2 || roleId == 7 || roleId == 19"
                      (click)="createModal(addRiderModal)"
                    >
                      Create Rider &nbsp; <i class="fa fa-plus text-highlight"></i>
                    </button>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor: pointer" class="odd" role="row">
                  <td class="td-title">
                    <label class="label-filter" style="color: #ee4d2d; font-weight: bolder">Set No. of Rows</label>
                  </td>
                  <td class="td-value">
                    <ng-select
                      [items]="showRowsOption"
                      placeholder="No of Rows"
                      bindLabel="text"
                      bindValue="id"
                      formControlName="showRows"
                    >
                    </ng-select>
                  </td>

                  <td class="td-title">
                    <label class="label-filter" style="color: #ee4d2d; font-weight: bold">Search</label>
                  </td>

                  <td class="td-value">
                    <!-- <input
                                type="text"
                                placeholder="Date Range"
                                class="form-control"
                                bsDaterangepicker
                                formControlName="dateRange"
                                style="background-color: white; color: black; height: 37px; border-color: #EE4D2D;"
                                [bsConfig]="{
                                rangeInputFormat: 'YYYY-MM-DD',
                                dateInputFormat: 'YYYY-MM-DD',
                                showWeekNumbers: false
                                }"
                            /> -->
                    <input
                      type="text"
                      class="form-control form-control-sm search"
                      placeholder="Search..."
                      formControlName="searchString"
                      style="background-color: white; color: black; height: 37px; border-color: #ee4d2d"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card mt-2 pl-2 pr-2">
          <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
            <thead>
              <tr>
                <th data-sortable="true" data-field="id">
                  <div>Rider Details</div>
                </th>
                <th data-sortable="true" data-field="owner">
                  <div>Contact Details</div>
                </th>
                <th data-sortable="true" data-field="project">
                  <div>Location Details</div>
                </th>
                <th data-field="finish"><span class="d-none d-sm-block">Status</span></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let user of transactions?.data
                    | paginate: { itemsPerPage: 10, currentPage: p, totalItems: transactions.totalRecords }
                "
                style="cursor: pointer"
                class="odd"
                role="row"
              >
                <td class="flex">
                  <div class="item-except text-sm h-1x mt-3">
                    FMS Rider Name : <span style="color: cadetblue; font-weight: bold">{{ user?.fmsDriverName }}</span>
                  </div>
                  <div class="item-except text-sm h-1x mb-3">
                    SPX Rider ID No : <span style="color: cadetblue; font-weight: bold">{{ user?.lifetimeID }}</span>
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x mt-3">
                    Hub Location : <span style="color: cadetblue; font-weight: bold">{{ user?.hubLocation }}</span>
                  </div>
                  <div
                    class="item-except text-sm h-1x mb-3"
                    *ngIf="roleId == 1 || roleId == 2 || roleId == 7 || roleId == 5"
                  >
                    Mobile No : <span style="color: cadetblue; font-weight: bold">{{ user?.mobileNo }}</span>
                  </div>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x mt-3">
                    Region:
                    <span style="color: cadetblue; font-weight: bold">
                      {{ user?.region }}
                    </span>
                  </div>
                  <div class="item-except text-sm h-1x mb-3">
                    Sub Region :
                    <span style="color: cadetblue; font-weight: bold">
                      {{ user?.subRegion }}
                    </span>
                  </div>
                </td>

                <td class="flex pb-0">
                  <div class="mb-3 mt-3">
                    Active :
                    <label class="ui-switch ui-switch-sm dark mt-1 mr-2">
                      <input
                        type="checkbox"
                        [checked]="user.active == 2 ? true : false"
                        (change)="changeStatus(user?.id, $event)"
                      />
                      <i></i>
                    </label>

                    <!-- <label *ngIf="user.active != 2" class="ui-switch ui-switch-sm dark mt-1 mr-2">
                        <input type="checkbox" />
                        <i></i>
                      </label> -->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row pl-2 pr-2">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
              <div class="col-sm-12 col-md-12">
                <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                  Showing {{ (transactions?.pageNumber - 1) * transactions?.pageSize + 1 }} to
                  {{ (transactions?.pageNumber - 1) * transactions?.pageSize + transactions?.data?.length }} of
                  {{ transactions?.data?.length }} entries
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-7" style="float: right">
            <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
              <ul class="pagination" style="float: right">
                <pagination-controls (pageChange)="getTransaction(buildPageUrl($event, 10))"></pagination-controls>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<ng-template #viewRiderModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">[Shopee] Rider Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-textbox
      [parent-form-group]="adjustWalletFormGroup"
      tab-index-start="0"
      control-name="remarks"
      [error]="adjustWalletFormGroup?.controls.remarks?.errors | estatus"
      [error-message]="adjustWalletFormGroup?.controls.remarks?.errors | econtent"
      [read-only]="false"
      label="Remarks"
      placeholder="Remarks"
    ></app-textbox>

    <app-textbox
      [parent-form-group]="adjustWalletFormGroup"
      tab-index-start="0"
      control-name="adjustmentAmount"
      [error]="adjustWalletFormGroup?.controls.adjustmentAmount?.errors | estatus"
      [error-message]="adjustWalletFormGroup?.controls.adjustmentAmount?.errors | econtent"
      [read-only]="false"
      label="Amount"
      placeholder="Amount"
    ></app-textbox>
  </div>
  <div class="modal-footer">
    <button class="btn btn-sm btn-primary float-right" (click)="proceedAdjustment()">Create Rider</button>
    <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>

<ng-template #uploadFilemodal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Upload Files</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div>
      <div class="page-content page-container" id="page-content">
        <div class="padding" *ngIf="fileUploadFormGroup">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <input type="file" (change)="onFileChange($event)" class="custom-file-input" id="inputFile" />
              <label class="custom-file-label" for="inputFile">{{ listFileNames }}</label>
            </div>

            <span *ngIf="fileUploadFormGroup?.controls.reportFile.errors | estatus" class="text-error"
              ><small>{{ fileUploadFormGroup?.controls.reportFile.errors | econtent }}</small>
            </span>
          </div>

          <div class="col-lg-12">
            <table style="width: 100%">
              <tr>
                <th style="text-align: center; color: white; background-color: rgb(79, 79, 79)">FMS Driver Name</th>
                <th style="text-align: center; color: white; background-color: rgb(79, 79, 79)">Hub Location</th>
                <th style="text-align: center; color: white; background-color: rgb(79, 79, 79)">Region</th>
                <th style="text-align: center; color: white; background-color: rgb(79, 79, 79)">Sub Region</th>
                <th style="text-align: center; color: white; background-color: rgb(79, 79, 79)">Rider Reference ID</th>
              </tr>
              <tr *ngFor="let item of listItems">
                <td style="text-align: center">{{ item.fMSDriverName }}</td>
                <td style="text-align: center">{{ item.hubLocation }}</td>
                <td style="text-align: center">{{ item.region }}</td>
                <td style="text-align: right">{{ item.subRegion }}</td>
                <td style="text-align: right">{{ item.lifetimeID }}</td>
              </tr>

              <tr>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
              </tr>
              <tr>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
              </tr>
              <tr>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
              </tr>
              <tr>
                <td style="text-align: right"></td>
                <td style="text-align: right"></td>
                <td style="text-align: right"></td>
                <td style="text-align: right"><strong></strong></td>
                <td style="text-align: right">
                  <strong>Rider Count : {{ listItems.length }}</strong>
                </td>
              </tr>
            </table>
          </div>

          <button class="btn btn-sm btn-success mt-1 ml-3" (click)="exportReturnData()" *ngIf="listItems.length != 0">
            <i class="fa fa-file-excel"></i> &nbsp; Export Data
          </button>

          <button class="btn btn-sm btn-primary mt-2 mr-3 float-right" (click)="submitRiderList()">
            <i class="fa fa-upload"></i> &nbsp; Upload Riders
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #batchDeactivationModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Rider Deactivation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div>
      <div class="page-content page-container" id="page-content">
        <div class="padding" *ngIf="deacFileUploadFormGroup">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <input type="file" (change)="onDeacFileChange($event)" class="custom-file-input" id="inputFile" />
              <label class="custom-file-label" for="inputFile">{{ listFileNames }}</label>
            </div>

            <span *ngIf="deacFileUploadFormGroup?.controls.reportFile.errors | estatus" class="text-error"
              ><small>{{ deacFileUploadFormGroup?.controls.reportFile.errors | econtent }}</small>
            </span>
          </div>

          <div class="col-lg-12">
            <table style="width: 100%">
              <tr>
                <th style="text-align: center; color: white; background-color: rgb(79, 79, 79)">FMS Driver Name</th>
                <th style="text-align: center; color: white; background-color: rgb(79, 79, 79)">Hub Location</th>
                <th style="text-align: center; color: white; background-color: rgb(79, 79, 79)">Region</th>
                <th style="text-align: center; color: white; background-color: rgb(79, 79, 79)">Sub Region</th>
                <th style="text-align: center; color: white; background-color: rgb(79, 79, 79)">Rider Reference ID</th>
              </tr>
              <tr *ngFor="let item of deactListItems">
                <td style="text-align: center">{{ item.fMSDriverName }}</td>
                <td style="text-align: center">{{ item.hubLocation }}</td>
                <td style="text-align: center">{{ item.region }}</td>
                <td style="text-align: right">{{ item.subRegion }}</td>
                <td style="text-align: right">{{ item.lifetimeID }}</td>
              </tr>

              <tr>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
              </tr>
              <tr>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
              </tr>
              <tr>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
                <td style="text-align: center"></td>
              </tr>
              <tr>
                <td style="text-align: right"></td>
                <td style="text-align: right"></td>
                <td style="text-align: right"></td>
                <td style="text-align: right"><strong></strong></td>
                <td style="text-align: right">
                  <strong>Rider Count : {{ deactListItems.length }}</strong>
                </td>
              </tr>
            </table>
          </div>

          <button class="btn btn-sm btn-danger mt-2 float-right" (click)="submitDeactivationList()">
            <i class="fa fa-upload"></i> &nbsp; Process Batch Deactivation
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addRiderModal let-modal>
  <div *ngIf="riderFormGroup">
    <div class="modal-header">
      <h6 class="modal-title">Add Rider Form</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body" [formGroup]="riderFormGroup">
      <app-textbox
        [parent-form-group]="riderFormGroup"
        tab-index-start="0"
        control-name="fmsDriverName"
        [error]="riderFormGroup?.controls.fmsDriverName?.errors | estatus"
        [error-message]="riderFormGroup?.controls.fmsDriverName?.errors | econtent"
        [read-only]="false"
        label="FMS Driver Name"
        placeholder="FMS Driver Name"
      ></app-textbox>

      <!-- <app-textbox
          [parent-form-group]="riderFormGroup"
          tab-index-start="0"
          control-name="hubLocation"
          [error]="riderFormGroup?.controls.hubLocation?.errors | estatus"
          [error-message]="riderFormGroup?.controls.hubLocation?.errors | econtent"
          [read-only]="false"
          label="Hub Location"
          placeholder="Hub Location"
        ></app-textbox> -->

      <div class="row mb-2">
        <div class="col-3">
          <label class="text-muted">Hub Location</label>
        </div>
        <div class="col-9">
          <ng-select
            [items]="hubOption"
            parent-form-group="riderFormGroup"
            placeholder="Select Hub Location"
            bindLabel="text"
            bindValue="id"
            formControlName="hubLocation"
          >
          </ng-select>
        </div>
      </div>

      <app-textbox
        [parent-form-group]="riderFormGroup"
        tab-index-start="0"
        control-name="region"
        [error]="riderFormGroup?.controls.region?.errors | estatus"
        [error-message]="riderFormGroup?.controls.region?.errors | econtent"
        [read-only]="false"
        label="Region"
        placeholder="Region"
      ></app-textbox>

      <app-textbox
        [parent-form-group]="riderFormGroup"
        tab-index-start="0"
        control-name="subRegion"
        [error]="riderFormGroup?.controls.subRegion?.errors | estatus"
        [error-message]="riderFormGroup?.controls.subRegion?.errors | econtent"
        [read-only]="false"
        label="Sub Region"
        placeholder="Sub Region"
      ></app-textbox>

      <app-textbox
        [parent-form-group]="riderFormGroup"
        tab-index-start="0"
        control-name="lifetimeID"
        [error]="riderFormGroup?.controls.lifetimeID?.errors | estatus"
        [error-message]="riderFormGroup?.controls.lifetimeID?.errors | econtent"
        [read-only]="false"
        label="Rider Reference ID"
        placeholder="Rider Reference ID"
      ></app-textbox>

      <hr />

      <div>
        <button class="btn btn-sm btn-primary float-right" (click)="processAddManual()">Add Rider</button>
        <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
          Cancel
        </button>
      </div>
    </div>

    <div class="modal-body"></div>
  </div>
</ng-template>
