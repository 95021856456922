import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-modal-confirmation",
  templateUrl: "./modal-confirmation.component.html",
  styleUrls: ["./modal-confirmation.component.less"],
})
export class ModalConfirmationComponent implements OnInit {
  groupFormGroup: FormGroup;
  @Input() value = "";
  @Input() meta: any;
  @Output() clickevent = new EventEmitter<any>();
  toggle = false;

  constructor(private bsModalRef: BsModalRef) {}

  ngOnInit() {}

  onConfirm(status): void {
    const response = {
      result: true,
      status: status,
    };

    this.clickevent.emit(response);
    this.bsModalRef.hide();
  }

  onCancel(): void {
    const response = {
      result: false,
      status: null,
    };

    this.clickevent.emit(response);
    this.bsModalRef.hide();
  }

  onClick(action) {
    setTimeout(() => {
      this.toggle = action === "close" ? false : true;
    }, 200);
  }

  selectedOption(option) {
    this.value = option.value;
  }
}
