<app-loader *ngIf="loader"></app-loader>

<div *ngIf="!loader" [class.isPrinting]="printService.isPrinting" class="col-lg-12 col-md-12 col-sm-12">
  <div class="col-lg-12 col-md-12 col-sm-12">
    <div class="card">
      <div class="list list-row">
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-md d-none d-md-block color-red">
              <span class="text-dark">Request ID: </span> <strong>{{ requestId }}</strong>
            </div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <a style="color: blue" (click)="printReceipt()">Print Receipt <span class="fa fa-print"></span></a> <br />
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" [formControl]="isA4" />
                <label class="form-check-label text-muted" for="flexCheckDefault" style="font-size: 12px">
                  Print via A4
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-6 col-md-6 col-sm-12 pull-right">
    <div class="card">
      <div class="list list-row">
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">
              <strong
                >{{ data?.transactByUser.cashierUser.firstName + " " + data?.transactByUser.cashierUser.lastName }}
                <br />
                {{ data?.transactByUser.email }}</strong
              >
            </div>
          </div>
          <!-- <div class="no-wrap">
              <div class="item-feed h-2x">
                <img
                  [src]="buildUserPhoto(data?.transactByUser?.cashierUser) | authImage | async"
                  height="50"
                  class="avatar w-40 img-responsive"
                  style="object-fit: cover"
                />
              </div>
            </div> -->
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Status</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x"><app-status-table-data [status]="data?.statusId"></app-status-table-data></div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Created Date</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.createdDate | date: "MMM dd, yyyy hh:mm:ss a" }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Completed Date</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.completedDate | date: "MMM dd, yyyy hh:mm:ss a" }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Amount</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.amount | number: "1.2-2" }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Service Charge</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.serviceCharge | number: "1.2-2" }}</div>
          </div>
        </div>

        <hr />
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Total Amount</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ totalAmount(data?.serviceCharge, data?.amount) | number: "1.2-2" }}</div>
          </div>
        </div>

        <hr />

        <!-- <div *ngIf="data?.statusId == 3 && roleLevelId == 3" class="list-item" data-id="19">
          <div class="flex"></div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <button class="btn btn-sm btn-default w-100 mb-2" (click)="openCancelModal(cancelTransactionModal)">
                <i class="fa fa-save"></i> &nbsp; Cancel Transaction
              </button>
            </div>
          </div>
        </div> -->

        <div *ngIf="data?.statusId == 3 && roleLevelId == 3" class="list-item" data-id="19">
          <div class="flex"></div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <button class="btn btn-sm btn-secondary w-100 mb-2" (click)="openCancelModal(cancelTransactionModal)">
                <i class="fa fa-times"></i> &nbsp; Cancel Transaction
              </button>
            </div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <button class="btn btn-sm btn-primary w-100 mb-2" (click)="openCompleteModal(completeTransactionModal)">
                <i class="fa fa-save"></i> &nbsp; Complete Transaction
              </button>
            </div>
          </div>
        </div>

        <div class="list list-row block" *ngIf="data?.transactionReceipt">
          <div class="list-item" data-id="19">
            <div class="flex">
              <div class="item-date text-muted text-sm d-none d-md-block">Reference No</div>
            </div>
            <div class="no-wrap">
              <div class="item-feed h-2x">{{ data?.referenceNo }}</div>
            </div>
          </div>

          <div class="list-item" data-id="20">
            <div class="pointer">
              <span class="w-40 avatar gd-info"><i class="fa fa-cloud"></i></span>
            </div>
            <div class="flex">
              <a class="item-author text-color">Official Receipt</a>
              <div class="item-except text-muted text-sm h-1x">
                <label (click)="preview(viewImageModal, data?.transactionReceipt)" style="color: blue; cursor: pointer">
                  <span class="fa fa-eye">&nbsp;</span>View File
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-6 col-md-6 col-sm-12 pull-left">
    <div class="card">
      <div class="list list-row">
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">
              <strong
                >Payment Collection <br />
                Payment Collection (PC)</strong
              >
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Biller Details</div>
          </div>
          <div class="no-wrap"></div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Biller Name</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.billerName }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Account Name</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.accountName }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Account Number</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.accountNumber }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Due Date</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.dueDate | date: "MMM dd, yyyy" }}</div>
          </div>
        </div>

        <hr />

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Other Details</div>
          </div>
          <div class="no-wrap"></div>
        </div>

        <div *ngFor="let key of parsedOtherDetails | keyvalue" class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">
              {{ key.key }}
              <!-- This will be the label -->
            </div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              {{ key.value }}
              <!-- This will be the value -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div #vipLogo hidden>
  <img
    src="https://juanpaycdn.azureedge.net/juanpayassets/LOGO_logowithtagline-removebg-preview.png"
    alt="Go-VIP-Logo-Logo-Small"
    height="80px"
  />
</div>

<ng-template #viewImageModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">File Viewer</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      <img [src]="imagePreview" onerror="this.src='https://via.placeholder.com/64x64?text=VIP';" style="width: 100%" />
    </div>
  </div>
</ng-template>

<ng-template #completeTransactionModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Receipt Validation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-textbox
      [parent-form-group]="receiptFormGroup"
      tab-index-start="3"
      control-name="referenceNo"
      read-only="false"
      [no-special-char]="false"
      label="Reference No"
      placeholder="Reference No"
      max-length="50"
    >
    </app-textbox>

    <div class="list list-row block">
      <div class="list-item" data-id="20">
        <div class="pointer" (click)="triggerFile(transactionReceipt)">
          <span class="w-40 avatar gd-info">
            <i class="fa fa-upload" aria-hidden="true"></i>
            <input
              #transactionReceipt
              type="file"
              (change)="onUploadFile($event, 'transactionReceipt')"
              style="display: none"
              accept="image/jpeg,image/png,application/pdf"
            />
          </span>
        </div>
        <div class="flex">
          <a class="item-author text-color"
            ><span class="text-danger font-weight-bold">*</span> &nbsp; Official Receipt</a
          >
          <div
            class="item-except text-muted text-sm h-1x"
            [ngClass]="{ 'is-invalid': submitted && fi.transactionReceipt.errors }"
          >
            <label
              *ngIf="
                !receiptFormGroup.controls.transactionReceipt.value && !fileFormGroup.controls.transactionReceipt.value
              "
              >Click the button to upload File...</label
            >
            <label *ngIf="fileFormGroup.controls.transactionReceipt.value" style="color: blue; cursor: pointer">
              <span class="fa fa-tag">&nbsp;</span>Next to preview file
            </label>
          </div>
          <div *ngIf="submitted && fi.transactionReceipt.errors" class="invalid-feedback">
            <div *ngIf="fi.transactionReceipt.errors">Official Receipt is required</div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-3">
      <button class="btn btn-sm btn-primary float-right" (click)="ProcessComplete()">Complete Transaction</button>
      <button class="btn btn-sm btn-dark float-right mr-2" (click)="modal.dismiss('Cancel click')">Cancel</button>
    </div>
  </div>
</ng-template>

<ng-template #cancelTransactionModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>are you sure, you want to cancel this transaction?</p>

    <div class="mt-3">
      <button class="btn btn-sm btn-primary float-right" (click)="ProcessCancel()">Proceed</button>
      <button class="btn btn-sm btn-dark float-right mr-2" (click)="modal.dismiss('Cancel click')">Cancel</button>
    </div>
  </div>
</ng-template>
