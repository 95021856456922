import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Location, Marker } from 'src/data/classes/location-interface';  
import { ManagerUsersService } from 'src/data/services/web/manager-users.service';

@Component({
  selector: 'app-branch-locator',
  templateUrl: './branch-locator.component.html',
  styleUrls: ['./branch-locator.component.less']
})
export class BranchLocatorComponent implements OnInit {

  location: Location;
  public storeAddressFormGroup: FormGroup;
  public emptyString = "";
  public user: any;
  public userId: any;
  public roleLevelId: any;
  public roleId: any;
  public roles: any;
  public role: any;
  public modalService: BsModalService;

  public usersService: ManagerUsersService;

  constructor(usersService: ManagerUsersService) {
    this.usersService = usersService;
   }

  ngOnInit(): void {
    this.getManagerUser(3);
  }

  updateCoordinates(e){
  
    this.storeAddressFormGroup.controls.latitude.setValue(e.lat);
    this.storeAddressFormGroup.controls.longtitude.setValue(e.lng);
  }

  getManagerUser(id: any): void {
    this.usersService.getManagerUser(id).subscribe((result) => {
      this.user = result.data;
      this.role = this.roles.filter((x) => x.id === this.user.roleId)[0].name;

      this.initStoreAddressFormGroup(this.user.managerUser.storeAddress);
    });
  }

  initStoreAddressFormGroup(storeAddress: any): void {
    this.storeAddressFormGroup = new FormGroup({
      id: new FormControl(storeAddress.id),
      number: new FormControl(storeAddress.number),
      street: new FormControl(storeAddress.street),
      subdivision: new FormControl(storeAddress.subdivision),
      barangay: new FormControl(storeAddress.barangay),
      municipality: new FormControl(storeAddress.municipality),
      city: new FormControl(storeAddress.city),
      region: new FormControl(storeAddress.region),
      longtitude: new FormControl(storeAddress.longtitude),
      latitude: new FormControl(storeAddress.latitude),
      managerUserId: new FormControl(this.user.managerUser.id),
    });
}

}
