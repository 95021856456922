import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { error } from 'console';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { stringify } from 'querystring';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { CebuanaService } from 'src/data/services/web/cebuana.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';

PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5";

@Component({
  selector: 'app-cebuana',
  templateUrl: './cebuana.component.html',
  styleUrls: ['./cebuana.component.less']
})
export class CebuanaComponent implements OnInit {

  @Input("application") application: any;
  
  formBuilder : FormBuilder;
  public emptyString = "";
  public billers : any = [];
  public biller : any;
  public billerCode : any;
  public selectedBillerCode : any;

  public activeTab = 1;
  public loader = false;
  public fee : any;
  public amount : any;

  public clientTypes : any = [];
  public industryTypes : any = [];
  public productTypes : any = [];

  @Output("closeModal") closeModal = new EventEmitter();
  @Output("closeOnly") closeOnly = new EventEmitter();
  
  constructor(
    private cebuanaService : CebuanaService,
    private router: Router,
    localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    formBuilder : FormBuilder,
    private modalService: NgbModal,
    private webService: WebSettingsService,
    private datepipe: DatePipe) {
    this.formBuilder = formBuilder;
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.initFormGroup();
    this.getCurrencyCollection();
    this.getSourceOfFundCollection();
    this.getOccupationCollection();
    this.getIDCollection();
    this.getFees();
  }

  ammendFee: any;
  cancelFee: any;
  getFees(){
    this.webService.getWebSettingsByIdentifier("cebuana_fee").subscribe((result) => {
      this.ammendFee = result.data[0].value;
      this.cancelFee = result.data[1].value;
    });
  }

  transactionType: any[];
  selectedTransactionType: any;

  sendDomesticFormGroup : FormGroup;
  findClientFormGroup : FormGroup;
  addClientFormGroup : FormGroup;
  findBeneficiaryFormGroup : FormGroup;
  addBeneficiaryFormGroup : FormGroup;
  payoutFormGroup : FormGroup;
  amendDomesticFormGroup : FormGroup;
  cancelDomesticFormGroup : FormGroup;
  initFormGroup(): void {
    this.sendDomesticFormGroup = new FormGroup({
      beneficiaryID: new FormControl(this.emptyString, [Validators.required]),
      sendCurrencyID: new FormControl(6, [Validators.required]),
      principalAmount: new FormControl(null, [Validators.required]),
      clientID: new FormControl(this.emptyString, [Validators.required]),
      clientFirstName: new FormControl(this.emptyString, [Validators.required]),
      clientLastName: new FormControl(this.emptyString, [Validators.required]),
      beneficiaryFirstName: new FormControl(this.emptyString, [Validators.required]),
      beneficiaryLastName: new FormControl(this.emptyString, [Validators.required]),
      serviceFee: new FormControl(this.emptyString, [Validators.required]),
      pinCode: new FormControl(this.emptyString)
    });

    this.findClientFormGroup = new FormGroup({
      firstname: new FormControl(this.emptyString, [Validators.required]),
      lastname: new FormControl(this.emptyString, [Validators.required])
    });

    this.findBeneficiaryFormGroup = new FormGroup({
      firstname: new FormControl(this.emptyString, [Validators.required]),
      lastname: new FormControl(this.emptyString, [Validators.required])
    });

    this.addClientFormGroup = new FormGroup({
      firstName: new FormControl(this.emptyString, [Validators.required]),
      middleName: new FormControl(this.emptyString),
      lastName: new FormControl(this.emptyString, [Validators.required]),
      birthDate: new FormControl(this.emptyString, [Validators.required]),
      cellphoneCountryID: new FormControl(166, [Validators.required]),
      cellphoneNumber: new FormControl(this.emptyString, [Validators.required]),
      telephoneCountryID: new FormControl(0, [Validators.required]),
      telephoneAreaCode: new FormControl(this.emptyString),
      telephoneNumber: new FormControl(this.emptyString),
      countryAddressID: new FormControl(166, [Validators.required]),
      provinceAddress: new FormControl(this.emptyString),
      address: new FormControl(this.emptyString),
      clientSourceOfFundID: new FormControl(1),
      tin: new FormControl(this.emptyString)
    });

    this.addBeneficiaryFormGroup = new FormGroup({
      firstName: new FormControl(this.emptyString, [Validators.required]),
      middleName: new FormControl(this.emptyString),
      lastName: new FormControl(this.emptyString, [Validators.required]),
      birthDate: new FormControl(this.emptyString, [Validators.required]),
      senderClientID: new FormControl(0, [Validators.required]),
      cellphoneCountryID: new FormControl(166, [Validators.required]),
      cellphoneNumber: new FormControl(this.emptyString, [Validators.required]),
      telephoneCountryID: new FormControl(0, [Validators.required]),
      telephoneAreaCode: new FormControl(this.emptyString),
      telephoneNumber: new FormControl(this.emptyString),
      countryAddressID: new FormControl(0, [Validators.required]),
      birthCountryID: new FormControl(166),
      provinceAddress: new FormControl(this.emptyString),
      address: new FormControl(this.emptyString),
      zipCode: new FormControl(this.emptyString),
      occupationID: new FormControl(16),
      tin: new FormControl(this.emptyString)
    });

    this.payoutFormGroup = new FormGroup({
      controlNumber: new FormControl('', [Validators.required]),
      identificationTypeID: new FormControl(null, [Validators.required]),
      identificationNumber: new FormControl('', [Validators.required]),
      beneficiaryID: new FormControl(0, [Validators.required]),
      pullTransactionPartnerCode: new FormControl(''),
      pin: new FormControl(''),
      terminalCode: new FormControl(''),
      serviceFee: new FormControl(0, [Validators.required]),
      principalAmount: new FormControl(0, [Validators.required]),
      pinCode: new FormControl('')
    });

    this.amendDomesticFormGroup = new FormGroup({
      controlNumber: new FormControl('', [Validators.required]),
      newBeneficiaryID: new FormControl(0, [Validators.required]),
      amendmentFee: new FormControl(0, [Validators.required]),
      pinCode: new FormControl('')
    });

    this.cancelDomesticFormGroup = new FormGroup({
      controlNumber: new FormControl('', [Validators.required]),
      cancelFee: new FormControl(0, [Validators.required]),
      pinCode: new FormControl('')
    });

    this.transactionType = [
      { id: "Send", value : "Send Domestic"},
      { id: "Payout", value : "Payout Domestic"},
      { id: "Amend", value : "Amend Domestic"},
      { id: "Cancel", value : "Cancel Domestic"}
    ];
  }

  onPrincipalAmountChange(event: Event) {
    const principalAmount = (event.target as HTMLInputElement).value;
  
    if (principalAmount != null && principalAmount !== '') {
      this.cebuanaService.getServiceFee(principalAmount).subscribe((result) => {
        this.sendDomesticFormGroup.controls['serviceFee'].setValue(result.serviceFee);
      });
    } else {
      PNotify.error({
        title: 'Invalid',
        text: 'Please input Principal Amount.',
      });
    }
  }

  getStatus(controlName: string, parentFormGroup): boolean {
    const control = parentFormGroup.get(controlName);
    return control ? control.touched && control.invalid : false;
  }

  getErrorMessage(controlName: string, parentFormGroup): string {
    const control = parentFormGroup.get(controlName);
    if (control && control.errors) {
      // You may need to adjust this logic based on the structure of your error messages
      if (control.errors.required) {
        return "This field is required.";
      } else if (control.errors.maxlength) {
        return `Maximum length exceeded (${control.errors.maxlength.requiredLength} characters allowed).`;
      } // Add more error message handling logic as needed
    }
    return "";
  }

  async getServiceFee() {
    //alert(value);
    this.loader = true;
    
    var principalAmount = Number(this.replaceComma(this.sendDomesticFormGroup.controls.principalAmount.value));
    if (principalAmount > 0) {
      await this.cebuanaService.getServiceFee(principalAmount).subscribe((result) => {
        if(result.resultStatus == 0){
          this.sendDomesticFormGroup.controls['serviceFee'].setValue(result.serviceFee);

          if(this.sendDomesticFormGroup.valid){
            this.activeTab++;
          } else {
            PNotify.error({
              title: "Request denied",
              text: "Please fill up all the required fields.",
            });
          }
        } else {
          this.sendDomesticFormGroup.controls['serviceFee'].setValue(0);
          PNotify.notice({
            title: 'Invalid Amount',
            text: result.message,
          });
        }
        this.loader = false;
      });
    } else {
      PNotify.error({
        title: 'Invalid',
        text: 'Please input Principal Amount.',
      });
      this.loader = false;
    }
  }

  getAmendFee(value: string) {
    var principalAmount = Number(this.payoutFormGroup.controls.principalAmount.value);

    if (principalAmount > 0) {
      this.cebuanaService.getAmendFee(principalAmount).subscribe((result) => {
        this.amendDomesticFormGroup.controls.amendmentFee.setValue(result.serviceFee);
      });
    } else {
      PNotify.error({
        title: 'Invalid',
        text: 'Something went wrong',
      });
    }
  }

  replaceComma(num) {
    return num.replace(/,/g, '');
  };

  currencyCollection: any[];
  getCurrencyCollection() {
    this.loader = true;
    this.cebuanaService.getCurrencyCollection().subscribe(
      (result) => {
        this.currencyCollection = result.currencyCollection;
        this.loader = false;
      },
      (error) => {
        this.loader = false;
        console.error('An error occurred:', error);  // Log the error for debugging
        PNotify.error({
          title: 'Something went wrong',
          text: 'Please try again in a moment.',
        });
        this.closeOnly.emit();
      }
    );
  }

  idCollection: any[];
  getIDCollection() {
    this.loader = true;
    this.cebuanaService.getIDCollection().subscribe(
      (result) => {
        this.idCollection = result.identificationTypeCollection;
        this.loader = false;
      },
      (error) => {
        this.loader = false;
        console.error('An error occurred:', error);  // Log the error for debugging
        PNotify.error({
          title: 'Something went wrong',
          text: 'Please try again in a moment.',
        });
        this.closeOnly.emit();
      }
    );
  }
 
  sourceCollection: any[];
  getSourceOfFundCollection() {
    this.sourceCollection = [
      {
        "sourceOfFund": "Employed",
        "sourceOfFundID": 1
      },
      {
        "sourceOfFund": "Self-Employed",
        "sourceOfFundID": 2
      },
      {
        "sourceOfFund": "Retirement Funds",
        "sourceOfFundID": 3
      },
      {
        "sourceOfFund": "Others",
        "sourceOfFundID": 4
      }
    ];
    this.addClientFormGroup.controls.clientSourceOfFundID.patchValue(1);
    // this.loader = true;
    // this.cebuanaService.getSourceOfFundCollection().subscribe(
    //   (result) => {
    //     this.sourceCollection = result.clientSourceOfFundCollection;
    //     this.addClientFormGroup.controls.clientSourceOfFundID.patchValue(1);
    //     this.loader = false;
    //   },
    //   (error) => {
    //     this.loader = false;
    //     console.error('An error occurred:', error);  // Log the error for debugging
    //     PNotify.error({
    //       title: 'Something went wrong',
    //       text: 'Please try again in a moment.',
    //     });
    //     this.closeOnly.emit();
    //   }
    // );
  }

  occupationCollection: any[];
  getOccupationCollection() {
    this.occupationCollection = [
      {
        "dateLastUpdated": "2020-09-23T00:00:00",
        "occupationCode": "ADMIN",
        "occupationDescription": "Administrative",
        "occupationID": 1
      },
      {
        "dateLastUpdated": "2020-09-23T00:00:00",
        "occupationCode": "AGRICULTURE",
        "occupationDescription": "Agriculture",
        "occupationID": 2
      },
      {
        "dateLastUpdated": "2020-09-23T00:00:00",
        "occupationCode": "AUTOMOTIVE_SALES",
        "occupationDescription": "Automotive",
        "occupationID": 3
      },
      {
        "dateLastUpdated": "2020-09-23T00:00:00",
        "occupationCode": "BANKING",
        "occupationDescription": "Financial Services",
        "occupationID": 4
      },
      {
        "dateLastUpdated": "2020-09-23T00:00:00",
        "occupationCode": "CLERGY",
        "occupationDescription": "Religious",
        "occupationID": 5
      },
      {
        "dateLastUpdated": "2020-09-23T00:00:00",
        "occupationCode": "COMPUTER",
        "occupationDescription": "Information Technology (IT)",
        "occupationID": 6
      },
      {
        "dateLastUpdated": "2020-09-23T00:00:00",
        "occupationCode": "CUSTOMER_CARE",
        "occupationDescription": "Customer Care/Retail",
        "occupationID": 7
      },
      {
        "dateLastUpdated": "2020-09-23T00:00:00",
        "occupationCode": "EDUCATION",
        "occupationDescription": "Education",
        "occupationID": 8
      },
      {
        "dateLastUpdated": "2020-09-23T00:00:00",
        "occupationCode": "ENGINEER",
        "occupationDescription": "Engineer",
        "occupationID": 9
      },
      {
        "dateLastUpdated": "2020-09-23T00:00:00",
        "occupationCode": "FACTORY",
        "occupationDescription": "Physical Worker",
        "occupationID": 10
      },
      {
        "dateLastUpdated": "2020-09-23T00:00:00",
        "occupationCode": "HEALTH_SERVICES",
        "occupationDescription": "Healthcare",
        "occupationID": 11
      },
      {
        "dateLastUpdated": "2020-09-23T00:00:00",
        "occupationCode": "PUBLIC_OFFICIAL",
        "occupationDescription": "Public Servant",
        "occupationID": 12
      },
      {
        "dateLastUpdated": "2020-09-23T00:00:00",
        "occupationCode": "RESTAURANT",
        "occupationDescription": "Restaurant",
        "occupationID": 13
      },
      {
        "dateLastUpdated": "2020-09-23T00:00:00",
        "occupationCode": "RETIRED",
        "occupationDescription": "Retiree",
        "occupationID": 14
      },
      {
        "dateLastUpdated": "2020-09-23T00:00:00",
        "occupationCode": "STUDENT",
        "occupationDescription": "Student",
        "occupationID": 15
      },
      {
        "dateLastUpdated": "2024-03-11T11:44:04.27",
        "occupationCode": "UNEMPLOYED",
        "occupationDescription": "Unemployed",
        "occupationID": 16
      },
      {
        "dateLastUpdated": "2020-09-23T00:00:00",
        "occupationCode": "CONSULTING",
        "occupationDescription": "Consulting",
        "occupationID": 17
      },
      {
        "dateLastUpdated": "2020-09-23T00:00:00",
        "occupationCode": "ENTERTAINMENT",
        "occupationDescription": "Entertainment",
        "occupationID": 18
      },
      {
        "dateLastUpdated": "2020-09-23T00:00:00",
        "occupationCode": "ENTREPRENEUR",
        "occupationDescription": "Entrepreneur",
        "occupationID": 19
      },
      {
        "dateLastUpdated": "2020-09-23T00:00:00",
        "occupationCode": "EXECUTIVE",
        "occupationDescription": "Executive",
        "occupationID": 20
      },
      {
        "dateLastUpdated": "2020-09-23T00:00:00",
        "occupationCode": "EXPLICIT_SERVICES",
        "occupationDescription": "Explicit Services",
        "occupationID": 21
      },
      {
        "dateLastUpdated": "2020-09-23T00:00:00",
        "occupationCode": "HUMAN_RESOURCES",
        "occupationDescription": "Human Resources",
        "occupationID": 22
      },
      {
        "dateLastUpdated": "2020-09-23T00:00:00",
        "occupationCode": "LEGAL",
        "occupationDescription": "Legal",
        "occupationID": 23
      },
      {
        "dateLastUpdated": "2020-09-23T00:00:00",
        "occupationCode": "PERSONAL_SERVICES",
        "occupationDescription": "Personal Services",
        "occupationID": 24
      },
      {
        "dateLastUpdated": "2020-09-23T00:00:00",
        "occupationCode": "SPORT",
        "occupationDescription": "Sport",
        "occupationID": 25
      }
    ];
    this.addBeneficiaryFormGroup.controls.occupationID.patchValue(16);
    // this.loader = true;
    // this.cebuanaService.getOccupationCollection().subscribe(
    //   (result) => {
    //     this.occupationCollection = result.occupationCollection;
    //     this.loader = false;
    //   },
    //   (error) => {
    //     this.loader = false;
    //     console.error('An error occurred:', error);  // Log the error for debugging
    //     PNotify.error({
    //       title: 'Something went wrong',
    //       text: 'Please try again in a moment.',
    //     });
    //     this.modalService.dismissAll();
    //     this.closeOnly.emit();
    //   }
    // );
  }

  findClientModalRef: NgbModalRef;
  openFindClientModal(content){
    this.findClientModalRef = this.modalService.open(content, { backdrop: 'static', centered: true, size: "lg" });
  }

  clientCollection: any;
  findClient(){
    this.loader = true;
    if(this.findClientFormGroup.valid){
      this.cebuanaService.findClient(this.findClientFormGroup.value).subscribe((result) => {
        this.clientCollection = result.clientCollection;
        if(!result.clientCollection){
          PNotify.error({
            title: "No Record",
            text: "Client not found!",
          });
        }1
        this.loader = false;
      });
    } else {
      this.findClientFormGroup.markAllAsTouched();
      this.loader = false;
    }
  }

  closeFindClient(){
    this.findClientFormGroup.reset();
    this.clientCollection = [];
    this.findClientModalRef.close();
  }

  selectedClient: any;
  selectClient(client: any){
    this.selectedClient = client;
    this.addBeneficiaryFormGroup.controls.senderClientID.patchValue(client.clientID);

    this.sendDomesticFormGroup.controls.clientID.patchValue(client.clientID);
    this.sendDomesticFormGroup.controls.clientFirstName.patchValue(client.firstName);
    this.sendDomesticFormGroup.controls.clientLastName.patchValue(client.lastName);

    this.findClientModalRef.close();
  }
  
  addClient(){
    this.loader = true;
    if(this.addClientFormGroup.valid){
      this.cebuanaService.addClient(this.addClientFormGroup.value).subscribe((result) => {
        if(result.item1){
          var client = result.item3.clientCollection[0];

          this.sendDomesticFormGroup.controls.clientID.patchValue(client.clientID);
          this.sendDomesticFormGroup.controls.clientFirstName.patchValue(client.firstName);
          this.sendDomesticFormGroup.controls.clientLastName.patchValue(client.lastName);

          this.addBeneficiaryFormGroup.controls.senderClientID.patchValue(client.clientID);

          this.selectedClient = client;
          this.addClientFormGroup.reset();
          this.addClientModalRef.close();
        } else {
          PNotify.error({
            title: "Error on Adding Client",
            text: result.item2,
          });
        }
        this.loader = false;
      }, (error) => {
        PNotify.error({
          title: "Error on Adding Client",
          text: error?.error.result?.item2
        });
        this.loader = false;
      });
    } else {
      this.addClientFormGroup.markAllAsTouched();
      PNotify.error({
        title: "Invalid",
        text: "Please fill all required fields.",
      });
      this.loader = false;
    }
  }

  addClientModalRef: NgbModalRef;
  openAddClientModal(content){
    this.closeFindClient();
    this.getSourceOfFundCollection();
    this.addClientModalRef = this.modalService.open(content, { backdrop: 'static', centered: true, size: "lg" });
  }

  beneficiaryCollection: any;
  findBeneficiary(clientID){
    this.loader = true;
    this.addBeneficiaryFormGroup.controls.senderClientID.patchValue(clientID);
    this.cebuanaService.getBeneficiaryBySender(clientID).subscribe((result) => {
      this.beneficiaryCollection = result.beneficiaryCollection;
      if(!result.beneficiaryCollection){
        PNotify.error({
          title: "No Record",
          text: "Client not found!",
        });
      }
      this.loader = false;
    });
  }

  closeAddClientModal(){
    this.addClientFormGroup.reset();
    this.addClientModalRef.close();
  }

  findBeneficiaryModalRef: NgbModalRef;
  async openFindBeneficiaryModal(content){
    if(this.selectedClient){
      await this.findBeneficiary(this.selectedClient.clientID);
      this.findBeneficiaryModalRef = this.modalService.open(content, { backdrop: 'static', centered: true, size: "lg" });
    } else {
      PNotify.error({
        title: "Invalid",
        text: "Select Client First!",
      });
    }
  }
  
  findNewBeneficiaryModalRef: NgbModalRef;
  isAmend = false;
  isCancel = false;
  async openFindNewBeneficiaryModal(content){
    if(this.senderClientID){
      await this.findBeneficiary(this.senderClientID);
      this.findBeneficiaryModalRef = this.modalService.open(content, { backdrop: 'static', centered: true, size: "lg" });
    } else {
      PNotify.error({
        title: "Invalid",
        text: "Select Client First!",
      });
    }
  }

  selectedBeneficiary: any;
  selectbeneficiary(beneficiary: any){
    this.selectedBeneficiary = beneficiary;

    this.sendDomesticFormGroup.controls.beneficiaryID.patchValue(beneficiary.beneficiaryID);
    this.sendDomesticFormGroup.controls.beneficiaryFirstName.patchValue(beneficiary.firstName);
    this.sendDomesticFormGroup.controls.beneficiaryLastName.patchValue(beneficiary.lastName);

    this.amendDomesticFormGroup.controls.newBeneficiaryID.patchValue(beneficiary.beneficiaryID);

    this.findBeneficiaryModalRef.close();
  }

  closeFindBeneficiary(){
    this.findBeneficiaryFormGroup.reset();
    this.beneficiaryCollection = [];
    this.findBeneficiaryModalRef.close();
  }

  closeAddBeneficiaryModal(){
    this.addBeneficiaryFormGroup.reset();
    this.addBeneficiaryModalRef.close();
  }

  openAddBeneficiaryModal(content){
    this.closeFindBeneficiary();
    this.getOccupationCollection();
    this.addBeneficiaryModalRef = this.modalService.open(content, { backdrop: 'static', centered: true, size: "lg" });
  }

  addBeneficiaryModalRef: NgbModalRef;
  addBeneficiary(){
    this.loader = true;
    //console.log(this.addBeneficiaryFormGroup.value);
    if(this.addBeneficiaryFormGroup.valid){
      this.cebuanaService.addBeneficiary(this.addBeneficiaryFormGroup.value).subscribe((result) => {
        if(result.item1){
          var beneficiary = result.item3.beneficiary;

          this.sendDomesticFormGroup.controls.beneficiaryID.patchValue(beneficiary.beneficiaryID);
          this.sendDomesticFormGroup.controls.beneficiaryFirstName.patchValue(beneficiary.firstName);
          this.sendDomesticFormGroup.controls.beneficiaryLastName.patchValue(beneficiary.lastName);

          this.amendDomesticFormGroup.controls.newBeneficiaryID.patchValue(beneficiary.beneficiaryID);

          this.selectedBeneficiary = beneficiary;
          this.addBeneficiaryFormGroup.reset();
          this.addBeneficiaryModalRef.close();
        } else {
          PNotify.error({
            title: "Error on Adding Beneficiary",
            text: result.item2,
          });
        }
        this.loader = false;
      }, (error) => {
        console.log(error);
        PNotify.error({
          title: "Error on Adding Beneficiary",
          text: error.error.result.item2,
        });
        this.loader = false;
      });
    } else {
      this.addBeneficiaryFormGroup.markAllAsTouched();
      PNotify.error({
        title: "Invalid",
        text: "Please fill all required fields.",
      });
      this.loader = false;
    }
  }

  getTotal(){
    var form = this.sendDomesticFormGroup.controls;
    var amount = this.replaceComma(form.principalAmount.value.toString())
    var total = Number(amount) + Number(form.serviceFee.value);

    return total;
  }

  getTotalPayout(payoutTransaction){
    var amount = this.replaceComma(payoutTransaction.principalAmount.toString());
    var total = Number(amount) - Number(payoutTransaction.serviceFee);
    
    return total;
  }
  
  getTotalCancel(payoutTransaction){
    var amount = this.replaceComma(payoutTransaction.principalAmount.toString());
    var total = Number(amount) - Number(this.cancelFee);
    
    return total;
  }

  getIDDetails(id){
    var detail = this.idCollection.find(x => x.identificationTypeID == id);

    return detail.description;
  }

  payoutTransaction: any
  senderClientID: any;
  async pullPayoutTransaction(controlNumber){
    this.loader = true;
    this.cebuanaService.getPayoutTransaction(controlNumber).subscribe((result) => {
      if(result.item1){
        this.payoutTransaction = result.item3;
        this.payoutFormGroup.controls.beneficiaryID.patchValue(result.item3.beneficiaryID);
        this.payoutFormGroup.controls.controlNumber.patchValue(result.item3.controlNumber);
        this.payoutFormGroup.controls.serviceFee.patchValue(result.item3.serviceFee);
        this.payoutFormGroup.controls.principalAmount.patchValue(result.item3.principalAmount);

        if(this.isAmend){
          this.senderClientID = result.item3.senderClientID;
          this.addBeneficiaryFormGroup.controls.senderClientID.patchValue(result.item3.senderClientID);
          this.amendDomesticFormGroup.controls.controlNumber.patchValue(result.item3.controlNumber);
          this.amendDomesticFormGroup.controls.amendmentFee.patchValue(this.ammendFee);
        }

        if(this.isCancel){
          this.senderClientID = result.item3.senderClientID;
          this.cancelDomesticFormGroup.controls.controlNumber.patchValue(result.item3.controlNumber);
          this.cancelDomesticFormGroup.controls.cancelFee.patchValue(this.cancelFee);
        }

      } else {
        PNotify.error({
          title: "Something went wrong",
          text: result.item2,
        });
      }
      this.loader = false;
    }, (error) => {
      console.log(error);
      PNotify.error({
        title: "Invalid",
        text: error.error.result.item2
      });
      this.loader = false;
    });
  }

  isError: boolean = false;
  setActiveTab(){
    if(this.activeTab == 1){
       if(this.selectedTransactionType != null && this.selectedTransactionType != ""){
        if(this.selectedTransactionType == "Send"){
          this.activeTab++;
        } else if(this.selectedTransactionType == "Payout"){
          this.payoutTransaction = null;
          this.isAmend = false;
          this.activeTab = 4;
        } else if(this.selectedTransactionType == "Amend"){
          console.log(this.amendDomesticFormGroup.value);
          this.payoutTransaction = null;
          this.isAmend = true;
          this.activeTab = 6;
        } else if(this.selectedTransactionType == "Cancel"){
          this.payoutTransaction = null;
          console.log(this.cancelDomesticFormGroup.value);
          this.isCancel = true;
          this.activeTab = 8;
        }
        this.loader = false;
        this.isError = false;
      } else {
        this.isError = true;
        PNotify.error({
          title: "Error",
          text: "Please select Transaction Type to proceed to next step.",
        });
        setTimeout(() => {
          this.isError = false;
        }, 5000);
      }
    } else if(this.activeTab == 2){
      const principalAmountControl = this.sendDomesticFormGroup.get('principalAmount');

      if (principalAmountControl.valid) {
        this.getServiceFee();
      } else {
        principalAmountControl.markAsTouched();
      }
      
    } else if(this.activeTab == 4){
      const controlNumber = this.payoutFormGroup.get('controlNumber');
      const identificationTypeID = this.payoutFormGroup.get('identificationTypeID');

      // Check if principalAmount control is valid
      if (controlNumber.valid && identificationTypeID.valid) {
        if(this.payoutFormGroup.valid){
          this.activeTab++;
        } else {
          this.payoutFormGroup.markAllAsTouched();
          
          PNotify.error({
            title: "Access Denied",
            text: "Please fill all fields",
          });
        }
      } else {
        this.payoutFormGroup.markAllAsTouched();
        // identificationTypeID.markAsTouched();
        // controlNumber.markAsTouched();
        PNotify.error({
          title: "Access Denied",
          text: "Please fill all fields",
        });

        if(identificationTypeID.invalid){
          this.isError = true;
          setTimeout(() => {
            this.isError = false;
          }, 5000); 
        }
      }
    } else if(this.activeTab == 6){
      const controlNumber = this.amendDomesticFormGroup.get('controlNumber');

      if (controlNumber.valid) {
        if(this.selectedBeneficiary){
          if(this.amendDomesticFormGroup.valid){
            this.activeTab++;
          } else {
            PNotify.error({
              title: "Access Denied",
              text: "Please fill all fields",
            });
          }
        } else {
          PNotify.error({
            title: "Access Denied",
            text: "Please select new Beneficiary",
          });
        }
      } else {
        // Mark the principalAmount control as touched to trigger validation messages
        controlNumber.markAsTouched();
      }
    } else if(this.activeTab == 8){
      const controlNumber = this.cancelDomesticFormGroup.get('controlNumber');
      if (controlNumber.valid) {
        if(this.cancelDomesticFormGroup.valid){
          this.activeTab++;
        } else {
          PNotify.error({
            title: "Access Denied",
            text: "Please fill all fields",
          });
        }
      } else {
        controlNumber.markAsTouched();
      }
    } else {
      PNotify.error({
        title: "Access denied",
        text: "",
      });
    }
  }

  setActiveTabPrev(){
    if(this.activeTab == 1){
      PNotify.error({
        title: "Error",
        text: "Action Denied.",
      });
    } else if(this.activeTab == 4 || this.activeTab == 6 || this.activeTab == 8){
      this.activeTab = 1;
    } else {
      this.activeTab--;
    }
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  codeStatus : boolean = false;
  code : any;
  
  onCodeCompleted(code: string) {
    this.code = code;
    this.codeStatus = code.length == 6;
  }

  
  processSendPayment(){
    this.loader = true;
    this.sendDomesticFormGroup.controls.pinCode.patchValue(this.code);
    this.cebuanaService.processSendRemittance(this.sendDomesticFormGroup.value)
      .subscribe((result) => {
        PNotify.success({
          title: "Transaction Success",
          text: "",
        });
        this.loader = false;
        this.code = "";
        this.codeStatus=false;
        this.closeModal.emit();
      
    }, error => {
      PNotify.error({
        title: "Failed",
        text: error.error.reason,
      });
      this.loader = false;
      this.code = "";
      this.codeStatus = false;
    });
  }

  processPayoutPayment(){
    this.loader = true;
    this.payoutFormGroup.controls.pinCode.patchValue(this.code);
    this.cebuanaService.processPayoutRemittance(this.payoutFormGroup.value)
      .subscribe((result) => {
        PNotify.success({
          title: "Transaction Success",
          text: "",
        });
        this.loader = false;
        this.code = "";
        this.codeStatus=false;
        this.closeModal.emit();
      
    }, error => {
      PNotify.error({
        title: "Failed",
        text: error.error.reason,
      });
      this.loader = false;
      this.code = "";
      this.codeStatus = false;
    });
  }
  
  processAmendPayment(){
    this.loader = true;
    this.amendDomesticFormGroup.controls.pinCode.patchValue(this.code);
    this.cebuanaService.processAmendRemittance(this.amendDomesticFormGroup.value)
      .subscribe((result) => {
        PNotify.success({
          title: "Transaction Success",
          text: "",
        });
        this.loader = false;
        this.code = "";
        this.codeStatus=false;
        this.closeModal.emit();
      
    }, error => {
      PNotify.error({
        title: "Failed",
        text: error.error.reason,
      });
      this.loader = false;
      this.code = "";
      this.codeStatus = false;
    });
  }
  
  processCancelPayment(){
    this.loader = true;
    this.cancelDomesticFormGroup.controls.pinCode.patchValue(this.code);
    this.cebuanaService.processCancelRemittance(this.cancelDomesticFormGroup.value)
      .subscribe((result) => {
        PNotify.success({
          title: "Transaction Success",
          text: "",
        });
        this.loader = false;
        this.code = "";
        this.codeStatus=false;
        this.closeModal.emit();
      
    }, error => {
      PNotify.error({
        title: "Failed",
        text: error.error.reason,
      });
      this.loader = false;
      this.code = "";
      this.codeStatus = false;
    });
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  totalAmount(fee:any,amount:any){
    try  {
    return this.makeMoney(Number(fee) + Number(amount));
    } catch(e){
      return "0.00";
    }
  }

  selectOption(option: any) {
    this.sendDomesticFormGroup.controls.address2.setValue(option.area);
    this.filteredOptions = [];
  }

  filteredOptions: string[] = [];
}
