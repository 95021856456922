import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ModalConfirmationComponent } from "src/app/pages/modal-confirmation/modal-confirmation.component";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";

@Component({
  selector: "app-side-nav-mobile",
  templateUrl: "./side-nav-mobile.component.html",
  styleUrls: ["./side-nav-mobile.component.less"],
})
export class SideNavMobileComponent implements OnInit {
  encapsulation: ViewEncapsulation.None;

  public authService: AuthService;
  public bsModalRefConfirmation: BsModalRef;
  public localStorageCacheService: LocalStorageCacheService;
  public modalService: BsModalService;
  public roleLevelId: any;
  public roleLevelName: any;
  public roleId: any;
  
  public soaModule: boolean = false;
  public individualSoaModule: boolean = false;
  public allSoaModule: boolean = false;
  public transactionHistoryModule: boolean = false;
  public settingsModule: boolean = false;
  public webSettingsModule: boolean = false;
  public passwordResetModule: boolean = false;
  public logsModule: boolean = false;
  public auditCheckerModule: boolean = false;
  public announcenmentModule: boolean = false;
  public vipMarketPlaceModule: boolean = false;
  public marketPlaceModule: boolean = false;
  public applicationModule: boolean = false;
  public accessManagementModule: boolean = false;
  public roleModule: boolean = false;
  public manageRoleModule: boolean = false;
  public companyModule: boolean = false;
  public companyUserModule: boolean = false;
  public managerUserModule: boolean = false;
  public cashierUserModule: boolean = false;

  public salesUserModule: boolean = false;
  public executiveDirectorModule: boolean = false;
  public salesDirectorModule: boolean = false;
  public salesManagerModule: boolean = false;
  public salesAgentModule: boolean = false;
  public paygramLedgerModule: boolean = false;
  
  public salesModule: boolean = false;

  public storeModule: boolean = false;
  public posModule: boolean = false;
  public cashierHistoryModule: boolean = false;
  public fundsModule: boolean = false;
  public requestFundsModule: boolean = false;
  public allocateFundsModule: boolean = false;
  public supportModule: boolean = false;
  public ticketModule: boolean = false;
  public discussionModule: boolean = false;
  public libraryModule: boolean = false;
  public downloadAreModule: boolean = false;
  public uploadAreModule: boolean = false;
  public videoTutorialModule: boolean = false;
  public AllFilesModule: boolean = false;
  public managerFilesModule: boolean = false;
  public vipProtectModule: boolean = false;
  public da5CertificateModule: boolean = false;
  public productModule: boolean = false;
  public partnerModule: boolean = false;
  public serviceChargeHistoryModule: boolean = false;
  public realtimeMessageModule: boolean = false;
  public isPartner: boolean = false;
  public isPartnerSunlife: boolean = false;
  public isPartnerVIPProtect: boolean = false;
  public settlementModule: boolean = false;
  public timeKeepingModule: boolean = false;
  
  constructor(
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    private router: Router,
    private route: ActivatedRoute,
    modalService: BsModalService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.modalService = modalService;
  }
  ngOnInit(): void {
   
    this.roleLevelName = this.authService.getRoleLevelName(this.localStorageCacheService.getStorage("role_level"));
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    this.isPartner = this.roleId == 10 || this.roleId == 12 ? true : false;
    this.isPartnerSunlife = this.roleId == 10 ? true : false;
    this.isPartnerVIPProtect = this.roleId == 12 ? true : false;
    this.loadSideNav();
    this.validateAccess();
  }

  validateAccess(): void {
    switch (this.roleId){
      case "1" : //super user
      case "2" : //admin
      this.partnerModule= true;
      this.realtimeMessageModule = true;
        this.soaModule = true;
        this.individualSoaModule = true;
        this.allSoaModule = true;
        this.serviceChargeHistoryModule = true;
        this.transactionHistoryModule = true;
        this.settingsModule = true;
        this.webSettingsModule = true;
        this.passwordResetModule = true;
        this.logsModule = true;
        this.auditCheckerModule = true;
        this.announcenmentModule = true;
        this.vipMarketPlaceModule = true;
        this.marketPlaceModule = true;
        this.applicationModule = true;
        this.accessManagementModule = true;
        this.roleModule = true;
        this.manageRoleModule = true;
        this.companyModule = true;
        this.companyUserModule = true;
        this.AllFilesModule = true;
        this.cashierUserModule = true;

        this.salesUserModule = true;
        this.executiveDirectorModule = true;
        this.salesDirectorModule = true;
        this.salesManagerModule = true;
        this.salesAgentModule = true;

        this.storeModule = true;
        this.cashierHistoryModule = true;
        this.fundsModule = true;
        this.requestFundsModule = true;
        //this.allocateFundsModule = true;
        this.supportModule = true;
        this.ticketModule = true;
        this.discussionModule = true;
        this.libraryModule = true;
        this.downloadAreModule = true;
        this.uploadAreModule = true;
        this.videoTutorialModule = true;
        this.vipProtectModule = true;
        this.da5CertificateModule = true;
        this.productModule = true;
        this.settlementModule = true;
        this.paygramLedgerModule = true;
      break;
      case "3" : //manager
        this.managerFilesModule = true;
        this.soaModule = true;
        this.individualSoaModule = true;
        this.serviceChargeHistoryModule = true;
        this.transactionHistoryModule = true;

        this.vipMarketPlaceModule = true;
        this.marketPlaceModule = true;
        
        this.companyModule = true;
        this.cashierUserModule = true;

        this.storeModule = true;
        this.cashierHistoryModule = true;
        this.fundsModule = true;
        this.requestFundsModule = true;
        this.allocateFundsModule = true;

        this.supportModule = true;
        // this.ticketModule = true;
        // this.discussionModule = true;
         this.libraryModule = true;
        this.downloadAreModule = true;
        // this.uploadAreModule = true;
         this.videoTutorialModule = true;
      break;
      case "4" : //cashier
        this.soaModule = true;
        this.individualSoaModule = true;
        this.serviceChargeHistoryModule = true;
        this.transactionHistoryModule = true;
        
        this.storeModule = true;
        this.posModule = true;
        this.cashierHistoryModule = true;

        this.fundsModule = true;
        this.allocateFundsModule = true;

        // this.supportModule = true;
        // this.ticketModule = true;
        // this.discussionModule = true;
         this.libraryModule = true;
        this.downloadAreModule = true;
        // this.uploadAreModule = true;
         this.videoTutorialModule = true;
      break;
      case "5" : //techinical supervisor
      this.realtimeMessageModule = true;
        this.soaModule = true;
        this.individualSoaModule = true;
        this.allSoaModule = true;
        this.transactionHistoryModule = true;
        this.serviceChargeHistoryModule = true;
        
        this.settingsModule = true;
        this.passwordResetModule = true;
        this.logsModule = true;
        this.auditCheckerModule = true;
        this.announcenmentModule = true;

        this.vipMarketPlaceModule = true;
        this.marketPlaceModule = true;
        this.applicationModule = true;

        this.companyModule = true;
        this.managerUserModule = true;
        this.cashierUserModule = true;

        this.salesUserModule = true;
        this.executiveDirectorModule = true;
        this.salesDirectorModule = true;
        this.salesManagerModule = true;
        this.salesAgentModule = true;

        this.storeModule = true;
        this.cashierHistoryModule = true;

        this.fundsModule = true;
        this.requestFundsModule = true;

        this.productModule = true;
        this.vipProtectModule = true;
        this.da5CertificateModule = true;

        this.supportModule = true;
        this.ticketModule = true;
        this.discussionModule = true;
        this.libraryModule = true;
        this.downloadAreModule = true;
        this.uploadAreModule = true;
        this.videoTutorialModule = true;
        this.paygramLedgerModule = true;
      break;
      case "6" : //csr
      this.settingsModule = true;
      this.realtimeMessageModule = true;
        this.soaModule = true;
        this.individualSoaModule = true;
        this.allSoaModule = true;
        this.transactionHistoryModule = true;
        this.serviceChargeHistoryModule = true;

        this.settingsModule = true;
        this.announcenmentModule = true;

        this.companyModule = true;
        this.managerUserModule = true;
        this.cashierUserModule = true;

        this.salesUserModule = true;
        this.executiveDirectorModule = true;
        this.salesDirectorModule = true;
        this.salesManagerModule = true;
        this.salesAgentModule = true;

        this.storeModule = true;
        this.cashierHistoryModule = true;

        this.vipMarketPlaceModule = true;
        this.marketPlaceModule = true;
        this.applicationModule = true;

        this.fundsModule = true;
        this.requestFundsModule = true;

        this.productModule = true;
        this.vipProtectModule = true;
        this.da5CertificateModule = true;
        
        this.supportModule = true;
        this.ticketModule = true;
        this.discussionModule = true;
        this.libraryModule = true;
        this.downloadAreModule = true;
        this.uploadAreModule = true;
        this.videoTutorialModule = true;
        this.paygramLedgerModule = true;
      break;
      case "7" : //finance
      this.partnerModule= true;
      this.settingsModule = true;
      this.realtimeMessageModule = true;
        this.companyModule = true;
        this.managerUserModule = true;
        this.cashierUserModule = true;

        this.salesUserModule = true;
        this.executiveDirectorModule = true;
        this.salesDirectorModule = true;
        this.salesManagerModule = true;
        this.salesAgentModule = true;

        this.fundsModule = true;
        this.requestFundsModule = true;
 
        this.supportModule = true;
        this.discussionModule = true;
        this.settlementModule = true;
        this.paygramLedgerModule = true;
      break;
      case "8" : //compliance
      this.partnerModule= true;
      this.settingsModule = true;
      this.realtimeMessageModule = true;
        this.vipMarketPlaceModule = true;
        this.marketPlaceModule = true;
        this.applicationModule = true;

        this.productModule = true;
        this.vipProtectModule = true;
        this.da5CertificateModule = true;

        this.companyModule = true;
        this.AllFilesModule = true;
        this.settlementModule = true;

        this.salesUserModule = true;
        this.executiveDirectorModule = true;
        this.salesDirectorModule = true;
        this.salesManagerModule = true;
        this.salesAgentModule = true;
        this.paygramLedgerModule = true;
        
      break;
      case "9" : //corporate
      this.settingsModule = true;
      this.realtimeMessageModule = true;
        this.companyModule = true;
        this.managerUserModule = true;
        this.paygramLedgerModule = true;
      break;
      case "13" : //corporate
      this.settingsModule = true;
      this.realtimeMessageModule = true;
        this.companyModule = true;
        this.companyUserModule = true;
        this.paygramLedgerModule = true;
      break;
      case "18" : //sales
      this.salesModule = true;
      break;
      
    }
  }

  hideSideNav(): void {
    document.getElementById("side-nav").classList.remove("active");
    document.getElementById("nav-bg-wrap").classList.remove("active");
  }

  loadSideNav(): void {
    const dropdown = document.getElementsByClassName("dropdown-btn");
    let i;
    for (i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener("click", function () {
        this.classList.toggle("active");
        const dropdownContent = this.nextElementSibling;
        if (dropdownContent.style.display === "block") {
          dropdownContent.style.display = "none";
        } else {
          dropdownContent.style.display = "block";
        }
      });
    }
  }

  messageDialog(title, message): void {
    const initialState = {
      meta: {
        title: title,
        message: message,
        btnTitle: "Ok",
        showCancel: false,
      },
    };

    this.bsModalRefConfirmation = this.modalService.show(ModalConfirmationComponent, {
      initialState,
    });

    this.bsModalRefConfirmation.content.clickevent.subscribe((response) => {
      if (response.result) {
        //
      }
    });
  }

  angleClass(event: any) {
  }
}
