<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w==" crossorigin="anonymous" referrerpolicy="no-referrer" />
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css">
<app-loader *ngIf="loader"></app-loader>
<div class="container-fluid" style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
  <div class="container-fluid" style="padding-inline:10px; width: 95%;display: flex;justify-content: space-between; align-items: center;gap: 20px; margin-block: 5px; padding-right: 25px;">
    <div class="d-flex align-items-center header" style="gap: 15px;">
      <img src="../../../../../assets/vips/Vips_Riders-Horizontal_01_PNG (1).png" width="170px" alt="">
      <h1 style="font-size: 24px; font-weight: 600;color: #5e676f;">APPLICATION HISTORY</h1>
  </div>
    <div class="search">
      <button type="button" class="btn btn-success p-1" (click)="exportexcel()"><i class="bi bi-file-earmark-spreadsheet"></i>Excel</button>
      <b style="white-space: nowrap;">Total Riders: {{page.totalOf}} </b>
      <div class="search_">
        <input type="text" class="form-control search__input" id="myInput" placeholder="Search here" [(ngModel)]="searchText">
      <!-- <button class="search__button">
          <svg class="search__icon" aria-hidden="true" viewBox="0 0 24 24">
              <g>
                  <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
              </g>
          </svg>
      </button> -->
      </div>
  </div>
  </div>
  <div class="table" style="margin-top: 53px;width: 95%; max-height: 65vh !important;overflow-y: scroll;" scrollable (scrollPosition)="scrollHandler($event)">
      <table id="myTable" class="table table-striped">
          <thead class="w-100">
              <tr style="position: fixed;
              width: 77%;
              background: white;
              z-index: 9;
              top: 162px;
              padding: 0;">
                  <th class="fw-600" style="width: 330px;">Rider Name & Email</th>
                  <th class="fw-600" style="width: 198.76px;">Vehicle & Plate No.</th>
                  <th class="fw-600" style="width: 386.4px;">Address</th>
                  <th class="fw-600" style="width: 140.04px;">Date</th>
                  <th class="fw-600" style="width: 110.56px;">Status</th>
                  <th>
                </th>
              </tr>
          </thead>
          <tbody>
              <tr (click)="openRemark(Remark,rider)" class="align-middle alert" role="alert" *ngFor="let rider of page.data | async | search: searchText;let i = index;">
                  <td>
                      <div class="d-flex align-items-center" style="gap: 10px;">
                          <div class="img-container">
                              <img [src]="rider?.identification_selfie"
                                  alt="">
                          </div>
                          <div class="ps-3">
                              <div class="fw-400 pb-1">{{ rider?.first_name + " " + rider?.last_name }}</div>
                              <p class="m-0 text-grey fs-09">{{ rider?.email_address }}</p>
                          </div>
                      </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center" style="gap: 10px;">
                      <div class="img-container">
                          <img [src]="rider?.vehicle_photo"
                              alt="">
                      </div>
                      <div class="ps-3">
                          <div class="fw-400 pb-1">{{ rider?.vehicle_plate_no }}</div>
                      </div>
                  </div>
                  </td>
                  <td>
                    <div class="fw-400">{{ rider?.address_address + '' +
                      rider?.address_barangay + ', ' +
                      rider?.address_municipalty + ' ' +
                      rider?.address_province
                    }}</div>
                  </td>
                  <td>
                    <div class="fw-400 text-muted" style="font-size: 12px;">{{ rider?.time_stamp?.toDate() | date:'MMM dd, yyyy h:mm a' }}</div>
                  </td>
                  <td>
                      <div class="d-inline-flex align-items-center active" *ngIf="!rider?.application_rejected">
                          <div class="circle"></div>
                          <div class="ps-2">Approved</div>
                      </div>
                      <div class="d-inline-flex align-items-center waiting" *ngIf="rider?.application_rejected">
                        <div class="circle"></div>
                        <div class="ps-2">Rejected</div>
                    </div>
                  </td>
                  <td></td>
              </tr>
          </tbody>
      </table>
      <!-- <div class="pagin d-flex justify-content-center mt-2" style="padding-left: -40px;">
        <div class="text-center d-flex align-items-center">
          <button class="btn btn btn-info btn-sm float-left" (click)="prevPage()"
              [disabled]="disable_prev || !(pagination_clicked_count>0)">Prev</button>
               <b class="page__counter">{{pagination_clicked_count+1}}</b>
          <button class="btn btn btn-info btn-sm float-right" (click)="nextPage()" [disabled]="disable_next">Next</button>
      </div>
      </div> -->
      <loading-spinner *ngIf="page.loading | async"></loading-spinner>
      <p class="text-center text-muted" *ngIf="page.done | async">No more data to show...</p>

  </div>
</div>
<ng-template #Remark let-modal>
          <div class="card">
            <div class="rounded-top text-white d-flex flex-row" style="background-image: url(../../../../../assets/vips/rider___\ s.png); height:200px; padding-inline: 20px; background-size: cover;">
              <div class="card_0" *ngIf="selectedRider.remarks">
                <div class="align">
                    <span class="red"></span>
                    <h1 style="margin: 0;
                    padding: 0;
                    font-size: 12px;
                    color: white;">Remarks</h1>
                </div>
                <p class="mt-4 text-left" style="font-size: 11px;">
                  {{selectedRider?.remarks}}
                </p>
              </div>
              <div class="ms-4 mt-5 d-flex flex-column" style="width: 150px;">
                <img [src]="selectedRider?.profile_pic"
                  alt="Generic placeholder image" class="img-fluid img-thumbnail mt-4 mb-2"
                  style="width: 150px; z-index: 1; height: 155px;">
                  <span class="badge badge-pill badge-danger" *ngIf="selectedRider?.application_rejected">Rejected</span>
                  <span class="badge badge-pill badge-success" *ngIf="!selectedRider?.application_rejected">Approved</span>
              </div>
              <div class="ms-2" style="margin-top: 130px;">
                <h3 class="ml-2 text-white">{{ selectedRider?.first_name + " " + selectedRider?.last_name }}</h3>
                <p class="ml-2"> {{selectedRider?.address_address}} </p>
              </div>
            </div>
            <div class="p-4 text-black" style="background-color: #f8f9fa;padding-bottom: 10px !important;">
              <div class="d-flex justify-content-end text-center py-1">
                <div>
                  <span class="badge badge-pill badge-primary mb-0" *ngIf="selectedRider?.is_active">Active</span>
                  <span class="badge badge-pill badge-secondary mb-0" *ngIf="!selectedRider?.is_active">Inactive</span>
                  <p class="small text-muted mb-0">Status</p>
                </div>
                <div class="px-3">
                  <p class="mb-1 h5" *ngIf="selectedRider?.rider_rating_count">{{selectedRider?.rider_rating_count}}</p>
                  <p class="mb-1 h5" *ngIf="!selectedRider?.rider_rating_count">0</p>
                  <p class="small text-muted mb-0">Reviews</p>
                </div>
                <div style="text-align: center;
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: center;">
                  <p class="mb-1 h5">{{selectedRider?.rider_rating | slice:0:3}}
                    <span class = "fa fa-star checked" *ngIf="selectedRider?.rider_rating>5 || selectedRider?.rider_rating==5"></span>
                    <span class = "fa fa-star zero" *ngIf="selectedRider?.rider_rating<0 || selectedRider?.rider_rating==0"></span>
                    <span *ngIf="!selectedRider?.rider_rating">--</span>
                  </p>
                  <p class="small text-muted mb-0">Rating</p>
                </div>
              </div>
            </div>
            <div class="card-body p-4 text-black" style="padding-top: 0 !important;">
              <div class="mb-2">
                <p class="lead fw-normal mb-1">About</p>
                <div class="p-4" style="background-color: #f8f9fa;padding-top: 5px !important; display: flex; gap: 20px;">
                  <div class="dets">
                    <p class="mb-1 h6">E-mail:</p>
                    <p class="mb-1 h6">Phone:</p>
                    <p class="mb-1 h6">Birthday:</p>
                    <p class="mb-1 h6">Gender:</p>
                    <p class="mb-0 h6">Plate Number:</p>
                  </div>
                  <div class="inf">
                    <p class="mb-1 h6">{{selectedRider?.email_address}}</p>
                    <p class="mb-1 h6">{{selectedRider?.doc.id}}</p>
                    <p class="mb-1 h6">{{selectedRider?.birth_date}}</p>
                    <p class="mb-1 h6">{{selectedRider?.gender}}</p>
                    <p class="mb-1 h6">{{selectedRider?.vehicle_plate_no}}</p>
                  </div>

                </div>
                <!-- <div class="p-4" style="background-color: #f8f9fa;padding-top: 5px !important;">
                  <p class="mb-1">E-mail:&nbsp;{{selectedRider?.email_address}}</p>
                  <p class="mb-1">Birthday:&nbsp;{{selectedRider?.birth_date}}</p>
                  <p class="mb-1">Gender:&nbsp;{{selectedRider?.gender}}</p>
                  <p class="mb-0">Plate Number:&nbsp;{{selectedRider?.vehicle_plate_no}}</p>
                </div> -->
              </div>

              <div class="img_container mb-3">
                <p class="lead fw-normal mb-1">Identifications</p>
                <img style="max-width:100%;cursor:pointer"
                (click)="onClick($event)"
                class="modal-hover-opacity imgs"
                data-toggle="modal" data-target="#myModal" [src]="selectedRider?.identification_front_url" alt="Snow">

                <img style="max-width:100%;cursor:pointer"
                (click)="onClick($event)"
                class="modal-hover-opacity imgs"
                data-toggle="modal" data-target="#myModal" [src]="selectedRider?.identification_back_url" alt="Snow">

                <img style="max-width:100%;cursor:pointer"
                (click)="onClick($event)"
                class="modal-hover-opacity imgs"
                data-toggle="modal" data-target="#myModal" [src]="selectedRider?.identification_selfie" alt="Snow">

                <img style="max-width:100%;cursor:pointer"
                (click)="onClick($event)"
                class="modal-hover-opacity imgs"
                data-toggle="modal" data-target="#myModal" [src]="selectedRider?.identification_selfie_with_id" alt="Snow">

                <img style="max-width:100%;cursor:pointer"
                (click)="onClick($event)"
                class="modal-hover-opacity imgs"
                data-toggle="modal" data-target="#myModal" [src]="selectedRider?.vaccination_card" alt="Snow">
              </div>

              <div class="img_container">
                <p class="lead fw-normal mb-1">Vehicle Details</p>
                <img style="max-width:100%;cursor:pointer"
                (click)="onClick($event)"
                class="modal-hover-opacity imgs"
                data-toggle="modal" data-target="#myModal" [src]="selectedRider?.vehicle_cr" alt="Snow">

                <img style="max-width:100%;cursor:pointer"
                (click)="onClick($event)"
                class="modal-hover-opacity imgs"
                data-toggle="modal" data-target="#myModal" [src]="selectedRider?.vehicle_or" alt="Snow">

                <img style="max-width:100%;cursor:pointer"
                (click)="onClick($event)"
                class="modal-hover-opacity imgs"
                data-toggle="modal" data-target="#myModal" [src]="selectedRider?.vehicle_photo" alt="Snow">
              </div>

            </div>
          </div>

          <div class="modal fade" id="myModal" role="dialog" style="backdrop-filter: blur(8px);">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title text-white">Image Viewer</h4>
                  <button type="button" #closebutton class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body">
                <img
                src="{{imgSrc}}"
                style="max-width:100%;cursor:pointer; max-height: 590px;"
                class="modal-hover-opacity"
              />
                </div>
              </div>
            </div>
            </div>
</ng-template>
