import { Message } from "@angular/compiler/src/i18n/i18n_ast";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { AuthService } from "src/data/services/authentication/auth.service";
import { CompanyUsersService } from "src/data/services/web/company-users.service";
import { RolesService } from "src/data/services/web/roles.service";
import { ModalConfirmationComponent } from "../modal-confirmation/modal-confirmation.component";

// PNotify
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: "app-company-user-edit",
  templateUrl: "./company-user-edit.component.html",
  styleUrls: ["./company-user-edit.component.less"],
})
export class CompanyUserEditComponent implements OnInit {
  public companyUserFormGroup: FormGroup;
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public companyUsersService: CompanyUsersService;
  public emptyString = "";
  public roles: any;
  public role: any;
  public bsModalRefConfirmation: BsModalRef;
  public modalService: BsModalService;

  public accessToken: string;
  public newCompanyUser: boolean | string;
  public user: any;
  public loadFlag = false;
  public userId: any;
  public base64Photo: string | ArrayBuffer;
  public roleLevelId: any;

  processCreate = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    companyUsersService: CompanyUsersService,
    modalService: BsModalService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.companyUsersService = companyUsersService;
    this.modalService = modalService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");

    PNotifyButtons; // Initiate PNotify buttons. Important!
  }

  ngOnInit(): void {
   
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.route.params.subscribe((params) => {
      const id = decodeURI(params.id || "");
      const status = decodeURI(params.status || "");
      this.newCompanyUser = this.cryptoService.get(this.data.authentication.privateKey, status);
      this.rolesService.getRoles(1).then((roles) => {
        this.roles = roles;
        if (id) {
          this.userId = this.cryptoService.get(this.data.authentication.privateKey, id);
          this.getCompanyUser(this.userId);
        } else {
          this.authService.validateAccessToken(this.roleLevelId).then((res) => {
            const idDefault = this.localStorageCacheService.getStorage("id");
            this.getCompanyUser(idDefault);
          });
        }
      });
    });
  }

  initCompanyUserFormGroup(user: any): void {
    this.companyUserFormGroup = new FormGroup({
      email: new FormControl(user.email ? user.email : this.emptyString),
      username: new FormControl(user.username ? user.username : this.emptyString),
      firstName: new FormControl(user.companyUser.firstName ? user.companyUser.firstName : this.emptyString),
      lastName: new FormControl(user.companyUser.lastName ? user.companyUser.lastName : this.emptyString),
      address: new FormControl(user.companyUser.address ? user.companyUser.address : this.emptyString),
      contactNumber: new FormControl(
        user.companyUser.contactNumber ? user.companyUser.contactNumber : this.emptyString
      ),
      birthDate: new FormControl(user.companyUser.birthDate ? user.companyUser.birthDate : this.emptyString),
      roleId: new FormControl(user.roleId ? user.roleId : this.emptyString),
      employeeId: new FormControl(user.companyUser.employeeId ? user.companyUser.employeeId : this.emptyString),
      emergencyContactName: new FormControl(
        user.companyUser.emergencyContactName ? user.companyUser.emergencyContactName : this.emptyString
      ),
      emergencyContactNumber: new FormControl(
        user.companyUser.emergencyContactNumber ? user.companyUser.emergencyContactNumber : this.emptyString
      ),
      emergencyContactRelationship: new FormControl(
        user.companyUser.emergencyContactRelationship ? user.companyUser.emergencyContactRelationship : this.emptyString
      ),
      photo: new FormControl(
        user.companyUser.photoFormat ? `${user.companyUser.photoFormat},${user.companyUser.photo}` : this.emptyString
      ),
      hiringDate: new FormControl(user.companyUser.hiringDate ? user.companyUser.hiringDate : this.emptyString),
      status: new FormControl(user.companyUser.status ? true : false),
    });
    this.loadFlag = true;

    this.companyUserFormGroup.valueChanges.subscribe((value) => {
    });
  }

  getCompanyUser(id: any): void {
    this.companyUsersService.getCompanyUser(id).subscribe((result) => {
      this.initCompanyUserFormGroup(result.data);
    });
  }

  public actionEventEditCompanyUser(): void {
   
    this.processCreate = true;

    this.companyUsersService.editCompanyUser(this.companyUserFormGroup.value, this.userId).subscribe(
      (result) => {
        const encId = this.cryptoService.set(this.data.authentication.privateKey, result.data.id);
        const encNewData = this.cryptoService.set(this.data.authentication.privateKey, "true");

        PNotify.success({
          title: "Success",
          text: "Your updates have been saved.",
        });

        this.router.navigate(["acm", "company-user-view", { id: encId }]);
      },
      (error) => {
        if (error.status === 400) {

          const obj = error.error.errors;
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              let str: string = key;
              str = str[0].toLowerCase() + str.slice(1);
              this.companyUserFormGroup.controls[str].setErrors({
                required: Validators.required,
                apiMessage: obj[key],
              });
            }
          }

          this.processCreate = false;

          PNotify.notice({
            title: "Warning",
            text: "Could not save updates with errors.",
          });
        } else {
          // Other error
          this.processCreate = false;

          PNotify.error({
            title: "Error",
            text: "Something went wrong.",
          });
        }
      }
    );
  }

  public onPhotoChange(event: any): void {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.base64Photo = reader.result;

        this.companyUserFormGroup.patchValue({
          photo: reader.result,
        });

        // need to run CD since file load runs outside of zone
        this.changeDetectorRef.markForCheck();
      };
    }
  }

  backToCompanyUsersList(): void {
    this.router.navigate(["acm", "company-user-list"]);
  }
}
