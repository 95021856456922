import { Component, EventEmitter, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.less']
})
export class PinComponent implements OnInit {


  @Output("doLoginPin") doLoginPin = new EventEmitter<string>();

  constructor(
     ) { }

  ngOnInit(): void {
  }

    // this called every time when user changed the code
    onCodeChanged(code: string) {
    }
  
    // this called only if user entered full code
    codeStatus : boolean = false;
    code : any = "";
    onCodeCompleted(code: string) {
      //this.code = code;
      this.doLoginPin.emit(code);
    }
  
    doLoginPinAction(){
      this.doLoginPin.emit(this.code);
    }

}
