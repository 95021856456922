<app-loader *ngIf="loader"></app-loader>
<div class="card">
  <table
    id="table"
    class="table table-theme table-row v-middle dataTable no-footer table-hover"
    data-plugin="bootstrapTable"
    data-toolbar="#toolbar"
    data-search="true"
    data-search-align="left"
    data-show-export="true"
    data-show-columns="true"
    data-detail-view="false"
    data-mobile-responsive="true"
    data-pagination="true"
    data-page-list="[10, 25, 50, 100, ALL]"
  >
    <thead>
      <tr>
        <th data-sortable="true" data-field="id">Details</th>
        <th data-sortable="true" data-field="owner">Identity</th>
        <th data-sortable="true" data-field="project">Figures</th>
        <th data-sortable="true" data-field="project">Amount</th>
        <th data-field="task"><span class="d-none d-sm-block">Settlement Status</span></th>
        <th data-field="finish"><span class="d-none d-sm-block">Tnx Status</span></th>
      </tr>
    </thead>

    <tbody>
      <tr
        class=" "
        *ngFor="
          let transaction of transactions?.data
            | paginate: { itemsPerPage: 10, currentPage: p, totalItems: transactions.totalRecords }
        "
        class="odd"
        role="row"
        (click)="setSelectedTransaction(transaction)"
      >
        <td class="flex">
          <div class="item-except text-sm h-1x">
            <span class="text-secondary" style="font-weight: bold">Req ID: </span> {{ transaction?.requestId }}
          </div>
          <div class="item-except text-sm h-1x">
            <span class="text-secondary" style="font-weight: bold">Fullname: </span> {{ transaction?.fullname }}
          </div>
          <div class="item-except text-sm h-1x">
            <span class="text-secondary" style="font-weight: bold">Reference No: </span> {{ transaction?.referenceNo }}
          </div>
        </td>

        <td class="flex">
          <div class="item-except text-sm h-1x">
            <span class="text-secondary" style="font-weight: bold">Transact by: </span>
            {{ transaction?.transactByUser.username }}
          </div>
          <div class="item-except text-sm h-1x">
            <span class="text-secondary" style="font-weight: bold">Created date: </span>
            {{ transaction?.createdDate | date: "MMM dd, yyyy hh:mm:ss a" }}
          </div>
          <div class="item-except text-sm h-1x">
            <span class="text-secondary" style="font-weight: bold">Completed date: </span>
            {{ transaction?.completedDate | date: "MMM dd, yyyy hh:mm:ss a" }}
          </div>
        </td>
        <td>
          <div class="flex-container">
            <div class="flex-row">
              <span class="text-secondary description">Payout: </span>
              <span class="amount">{{ makeMoney(transaction?.amount - transaction?.serviceFee) }}</span>
            </div>
            <div class="flex-row">
              <span class="text-secondary description">Service Fee: </span>
              <span class="amount">{{ makeMoney(transaction?.serviceFee) }}</span>
            </div>
            <div class="flex-row">
              <span class="text-secondary description">Commission: </span>
              <span class="amount">{{ makeMoney(transaction?.outletShare) }}</span>
            </div>
          </div>
        </td>

        <td>
          <div class="text-center">
            <strong style="color: green">{{ makeMoney(transaction?.amount) }} </strong>
          </div>
        </td>
        <!-- <td>
          <div class="text-center">
            <strong *ngIf="transaction?.statusId == 2">{{ makeMoney(transaction?.transactByOpeningBalance) }}</strong>
            <i class="" *ngIf="transaction?.statusId != 2">N/A</i>
          </div>
        </td>
        <td>
          <div class="text-center">
            <strong *ngIf="transaction?.statusId == 2">{{
              makeMoney(transaction?.transactByOpeningBalance - transaction?.amount)
            }}</strong>
            <i class="" *ngIf="transaction?.statusId != 2">N/A</i>
          </div>
        </td> -->
        <td>
          <app-settlement-status-data [status]="transaction?.settlementStatus"></app-settlement-status-data>
        </td>
        <td>
          <app-status-table-data [status]="transaction?.statusId"></app-status-table-data>
        </td>
        <td>
          <div class="item-action dropdown">
            <div class="dropdown-menu dropdown-menu-right bg-black" role="menu"></div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="row">
  <div class="col-sm-12 col-md-5">
    <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
      <div class="col-sm-12 col-md-12">
        <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
          Showing {{ (transactions?.pageNumber - 1) * transactions?.pageSize + 1 }} to
          {{ (transactions?.pageNumber - 1) * transactions?.pageSize + transactions?.data?.length }} of
          {{ transactions?.data?.length }} entries
        </div>
      </div>
    </div>
  </div>

  <div class="col-sm-12 col-md-7" style="float: right">
    <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
      <ul class="pagination" style="float: right; margin-right: 36px">
        <pagination-controls (pageChange)="getTransaction(buildPageUrl($event, 10))"></pagination-controls>
      </ul>
    </div>
  </div>
</div>

<ng-template #viewPHPay let-modal>
  <div class="modal-header">
    <h4 class="modal-title">[Cardless Withdrawal] Transaction Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-phpay-transaction (closeModal)="closeModal()" [requestId]="requestId"></app-phpay-transaction>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>
