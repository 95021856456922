<script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.6.9/angular.min.js"></script>
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css" />
<div class="container-fluid --home" ng-app="">
  <div class="row justify-content-start">
    <div class="row container-fluid align-self-start justify-content-between">
      <div class="header mt-3">
        <div class="text">
          <img
            src="../../../../../assets/vips/Vips_Merchants-Horizontal_01_PNG (1).png"
            width="150px"
            height="65px"
            alt=""
          />
          <h4 class="text-muted">Product Approval</h4>
        </div>
      </div>
      <div class="dropdown show" style="display: flex; align-items: flex-end; margin-bottom: 8px;">
        <a class="btn btn-light dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Operating Hours
        </a>

        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <div class="d-flex justify-content-evenly" *ngFor="let category of hours ; let i = index">
            <span class="dropdown-item text-primary">{{category.docId | titlecase}}</span>
            <span class="text-muted w">{{category.docData?.opening_hour + '-' + category.docData?.closing_hour}}</span>
          </div>
        </div>
      </div>
      <div class="mb-2 mr-2 d-flex justify-content-end align-items-end">
        <button type="button" class="btn btn-outline-success mr-2" style="border-radius: 20px;" (click)="openVerify(verifyApplicationModal)">Approve</button>
        <button type="button" class="btn btn-outline-danger" style="border-radius: 20px;" (click)="openReject(rejectApplicationModal)">Decline</button>
    </div>
    </div>

    <div class="col6 width rounded container-fluid m-0">
      <!-- <table class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
        <thead>
          <tr>
            <th>
              <div>Owner Picture</div>
            </th>
            <th>
              <div>Full Name</div>
            </th>
            <th>
              <div>Address</div>
            </th>
            <th>
              <div>Birthday</div>
            </th>
            <th>
              <div>Submitted ID</div>
            </th>
            <th>
              <div>Joining Date</div>
            </th>
            <th>
              <div>Linked GoVIPCenter Outlet</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td>{{ merchant?.first_name + ' ' + merchant?.middle_name + ' ' + merchant?.last_name }}</td>
            <td>{{ merchant?.address_address + ' ' + merchant?.address_province + ' ' + merchant?.address_country }}</td>
            <td> {{ merchant?.birth_date }} </td>
            <td> {{ merchant?.identification_type }} </td>
            <td> {{ merchant?.timestamp.toDate() | date:'MMM dd, yyyy' }} </td>
            <td></td>
          </tr>
        </tbody>
      </table> -->
      <table class="table table-striped rounded overflow-hidden align-middle mb-0">
        <thead style="background: #2064aa;">
          <tr>
            <th class="fonts">Owner Picture</th>
            <th class="fonts">Full Name</th>
            <th class="fonts">Phone Number</th>
            <th class="fonts">Address</th>
            <th class="fonts">Birthday</th>
            <th class="fonts">Submitted ID</th>
            <th class="fonts">Joining Date</th>
          </tr>
        </thead>
        <tbody class="border-3">
          <tr class="td">
            <td>
              <img
                [src]="merchant?.identification_selfie"
                    style="width: 45px; height: 45px"
                    class="enlarge1"
                    />
            </td>
            <td>
              <div class="d-flex align-items-center justify-content-center">

                <div class="ms-3 text-left">
                  {{ merchant?.first_name + ' ' + merchant?.middle_name + ' ' + merchant?.last_name }}
                  <p class="text-muted mb-0 text-left">{{ merchant?.email_address }}</p>
                </div>
              </div>
            </td>
            <td>
              {{ mobileNo }}
            </td>
            <td>
              {{ merchant?.address_address + ' ' + merchant?.address_province + ' ' + merchant?.address_country }}
            </td>
            <!-- <td class="text-muted">
              {{ merchant?.birth_date | date:'MMM dd, yyyy' }}
            </td> -->
            <td style="width: 20px; padding-bottom: 2px">
              <p class="text-muted mb-0" style="font-size: 10px">{{ merchant?.birth_date | date: "MMM" }}</p>
              <p class="mb-0 mt-0 text-highlight font-weight-bold" style="font-size: 12px">
                {{ merchant?.birth_date | date: "dd" }}
              </p>
              <p class="text-muted mt-0 m-0" style="font-size: 10px">{{ merchant?.birth_date | date: "yyyy" }}</p>
            </td>
            <td>{{ merchant?.identification_type }}</td>
            <!-- <td class="text-muted">
              {{ merchant?.timestamp.toDate() | date:'MMM dd, yyyy' }}
            </td> -->
            <td style="width: 20px; padding-bottom: 2px">
              <p class="text-muted mb-0" style="font-size: 10px">{{ merchant?.timestamp.toDate() | date: "MMM" }}</p>
              <p class="mb-0 mt-0 text-highlight font-weight-bold" style="font-size: 12px">
                {{ merchant?.timestamp.toDate() | date: "dd" }}
              </p>
              <p class="text-muted mt-0 m-0" style="font-size: 10px">{{ merchant?.timestamp.toDate() | date: "yyyy" }}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

</div>

<div class="row">
  <div class="col-9">

      <!-- <div class="d-flex justify-content-center align-items-center">
        <div class="profile-container">
          <div class="profile">
            <img [src]="merchant?.business_store_logo" alt="" width="130px" height="130px">
          </div>
          <h5>{{ merchant?.business_name }}</h5>
          <h6>{{ merchant?.business_address_address }}</h6>
        </div>
      </div> -->
      <div class="mt-2">
        <table id="datatable" class="table table-striped table-hover rounded overflow-hidden" role="grid" aria-describedby="datatable_info">
          <thead style="background: #2064aa;">
            <tr>
              <th data-sortable="true" data-field="owner" class="fonts">Store Facade</th>
              <th data-sortable="true" data-field="id">
                <div class="fonts">Store Photo</div>
              </th>
              <th data-sortable="true" data-field="owner">
                <div class="fonts">Business Name</div>
              </th>
              <th data-sortable="true" data-field="owner">
                <div class="fonts">Business Address</div>
              </th>
              <th data-sortable="true" data-field="project">
                <div class="fonts">Food Tags</div>
              </th>
              <th data-sortable="true" data-field="project">
                <div class="fonts">Food Category</div>
              </th>
              <!-- <th data-sortable="true" data-field="project">
                <div [ngClass]="roleName" (click)="setPageSorting('roleName')"></div>
              </th> -->
              <!-- <th data-field="finish"><span class="d-none d-sm-block">Status</span></th> -->
            </tr>
          </thead>
          <tbody>
            <tr style="cursor: default;" class="odd td" role="row">
              <td >
                  <span class="d-flex justify-content-center">
                    <img class="w-32 enlarge" [src]="merchant?.business_store_facade" />
                  </span>
              </td>
              <td>
                  <span class="d-flex justify-content-center">
                    <img class="w-32 enlarge" [src]="merchant?.business_store_logo" />
                  </span>
              </td>

              <td class="flex">
                <div class="item-except text-sm h-1x">
                  <b>{{ merchant?.business_name }}</b>
                </div>
              </td>
              <td class="flex">
                <div class="item-except text-sm h-1x">
                  {{ merchant?.business_address_address }}
                </div>
              </td>
              <td class="flex">
                <div class="item-except text-sm h-1x">
                  <span class="badge badge-success rounded-pill d-inline">
                    {{ merchant?.business_food_tags }}
                  </span>
                </div>
              </td>
              <td class="flex">
                <div class="item-except text-sm h-1x">
                  {{ merchant?.business_food_type }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div class="mb-2 mr-2 d-flex justify-content-end align-items-end">
        <div>
          <button type="button" class="btn btn-outline-success mr-2" (click)="openVerify(verifyApplicationModal)">Approve</button>
          <button type="button" class="btn btn-outline-danger" (click)="openReject(rejectApplicationModal)">Decline</button>
        </div>
      </div> -->

    <div class="accordion" id="accordionExample" style="max-height: calc(100vh - 440px);">
      <div class="w-100" *ngFor="let category of products ; let i = index">
        <div class="card-header p-0" id="headingOne" (click)="toggleClass(i)">
          <h2 class="mb-0 bord">
            <button style="font-size: 18px; color: white;" class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              {{ category.docData.name }}
            </button>
          </h2>
        </div>
        <!-- [ngClass]="activeIndex != i ? '': 'show'" -->
        <div id="collapseOne" class="collapse show rounded"   aria-labelledby="headingOne" data-parent="#accordionExample" style="max-height: 345px; overflow-y: scroll; overflow-x: hidden;">
          <div class="card-body p-2">
            <table class="table table-striped rounded pb-3">
              <thead style="position: sticky; top: 0; background-color: white">
                <tr>
                  <th scope="col" class="text-left" >Product Name</th>
                  <!-- <th scope="col" class="text-left" >SRP</th>
                  <th scope="col" class="text-left" >Display Price</th> -->
                  <th scope="col" class="text-center" >Reviewed Date </th>
                  <th scope="col" class="text-center" >Last Remarks </th>
                  <th scope="col" class="text-center" >Reviewed by: </th>
                  <th scope="col" class="text-center">Date Publish </th>
                  <!-- <th scope="col" >Status </th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let product of category.products; let i = index" (click)="viewProduct(product.docId, category.docId, product) "  class="td">
                  <!-- <td class=" text-left text-primary font-weight-bold">{{ product.docData.product_name }}</td> -->
                  <td style="padding-bottom: 2px">
                    <!-- <small>{{ ii.product }}</small> -->
                    <p class="mb-0 text-left" style="font-size: 12px">
                      <span class="text-highlight font-weight-bold"> {{ product.docData.product_name }} </span>
                    </p>
                    <p class="text-muted mt-0 mb-0 text-left" style="font-size: 10px">
                      SRP:<span class="font-highlighted"> {{ ' ₱' + makeMoney(displayPrice(product.docData?.base_price)) }} </span>
                    </p>
                    <p class="text-muted mt-0 text-left" style="font-size: 10px">
                      Display Price:<span class="font-highlighted"> {{ ' ₱' + makeMoney(product.docData?.base_price) }} </span>
                    </p>
                  </td>
                  <!-- <td class=" text-left text-primary">{{'₱ ' + makeMoney(displayPrice(product.docData?.base_price)) }}</td>
                  <td class=" text-left text-primary">{{'₱ ' + makeMoney(product.docData?.base_price)  }}</td> -->
                  <!-- <td class="text-muted text-left">{{ product.docData?.last_review_date | date:'MMM dd, yyyy h:mm:ss a' }}</td> -->
                  <td style="width: 20px; padding-bottom: 2px">
                    <p class="text-muted mb-0" style="font-size: 10px">{{ product.docData?.last_review_date | date: "MMM" }}</p>
                    <p class="mb-0 mt-0 text-highlight font-weight-bold" style="font-size: 12px">
                      {{ product.docData?.last_review_date | date: "dd" }}
                    </p>
                    <p class="text-muted mt-0 m-0" style="font-size: 10px">{{ product.docData?.last_review_date | date: "yyyy" }}</p>
                    <p class="text-muted mt-0 m-0" style="font-size: 10px">{{ product.docData?.last_review_date | date: "h:mm:ss a" }}</p>
                  </td>
                  <td class="text-muted text-center">{{ product.docData?.product_remarks }}</td>
                  <td class="text-muted text-center">{{ product.docData?.last_updated_by ? (product?.docData?.last_updated_by) : ''}}</td>
                  <!-- <td class="text-left"> {{ product.docData.timestamp.toDate() | date:'MMM dd, yyyy' }} </td> -->
                  <td style="width: 20px; padding-bottom: 2px">
                    <p class="text-muted mb-0" style="font-size: 10px">{{ product.docData.timestamp.toDate() | date: "MMM" }}</p>
                    <p class="mb-0 mt-0 text-highlight font-weight-bold" style="font-size: 12px">
                      {{ product.docData.timestamp.toDate() | date: "dd" }}
                    </p>
                    <p class="text-muted mt-0 m-0" style="font-size: 10px">{{ product.docData.timestamp.toDate() | date: "yyyy" }}</p>
                  </td>
                  <!-- <td class=""><input type="checkbox"/></td>
                  <td class=""><input type="text" id="remarks{{product.docId}}" appDebounceClick [debounceTime]="500" name="remarks" (input)="onSearchChange($event.target.value, product.docId, category.docId)" placeholder="Input remarks here.."></td> -->
                  <td>
                    <div class="btn-group dropright">

                      <button type="button" class="btn   dropdown-toggle btn-link btn-sm" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="bi bi-three-dots"></i>
                      </button>
                      <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 20px; left: 50px; transform: translate3d(-60px, 21px, 0px);">
                          <button type="button" class="dropdown-item" (click)="openRemark(remarkApplicationModal)">Add Remarks</button>
                      </div>
                  </div>
                </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div style="width: 20%; position: fixed; right: 30px;" class="col-3">
    <div class="row card_container1" *ngIf="selectedProduct">
      <div class="col-xs-4 ms-lg-3 w-100">
          <div class="card1 w-100">
              <div class="cards1 active w-100" data-target="test-1">
                <div class="img01">
                  <img [src]="selectedProduct.docData?.product_image_url" alt="" height="90px" width="90px">
                </div>
                  <h5 class="heading">{{selectedProduct.docData.product_name}}</h5>
                  <p class="descrip">{{selectedProduct.docData.product_description}}</p>
                  <h6>{{selectedProduct? "₱ " + selectedProduct.docData.base_price : ''}}</h6>

                  <div class="w-100" style="max-height: calc(100vh - 500px); overflow-y: scroll;">
                    <table class="table rounded pb-2" *ngFor="let option of selectedOption; let i = index">
                      <thead>
                        <tr>
                          <th style="width: 100%;" scope="col" class="text-left"><span class="text-uppercase">{{option?.docData?.option_name}}</span> &nbsp;&nbsp;
                            <span class="text-muted">{{option?.docData?.condition}}</span>
                            &nbsp;<span class="badge badge-danger rounded-pill mr-2" *ngIf="option.docData.is_required">Required</span>
                            <span class="badge badge-danger rounded-pill mr-2" *ngIf="option.docData.is_multiple">Can Select Multiple</span>
                            <span class="badge badge-danger rounded-pill mr-2" *ngIf="!option.docData.is_multiple">Select only one</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let choice of option.choices; let i = index">
                          <th class="text-left">{{choice.docData.choice_name}}</th>
                          <div style="width: 70px;"><th class="text-left">{{ ' + ₱' + makeMoney(choice.docData.add_price)}}</th></div>
                        </tr>
                      </tbody>
                    </table>

                  </div>
              </div>
          </div>
      </div>
    </div>
  </div>
</div>



    <!-- <div class="col-xs-4 ms-lg-3 card00" data-name="test-1">
        <div class="card">
            <div class="mt-1 justify-content-center cards">
              <div class="img0">
                <img src="../../../../assets/vips/icetea.jpg" alt="" height="130px" width="130px">
              </div>
                <h5 class="heading">Regular Ice Tea</h5>
                <h4>₱99.00</h4>
                <div class="d-flex">
                  <p><i class="bi bi-star"></i> 4.8(276)</p>&nbsp;&nbsp;&nbsp;&nbsp;
                  <p><i class="bi bi-stopwatch"></i> 10 min.</p>
                </div>
            </div>
        </div>
    </div> -->
    <!-- <div class="col-12 mt-3 shadow-lg rounded" style="max-height: 390px; overflow-y: scroll; width: 961px; overflow-x: hidden; padding: 0px;border-radius: 20px;">

    </div> -->
</div>




<script>
  let previewContainer = document.querySelector('.card1');
  let previewBox = previewContainer.querySelectorAll('.cards1');

  document.querySelectorAll('.card_container').forEach(product =>{
    product.onclick = () => {
      previewContainer.style.display = 'flex';
      let name = product.getAttribute('data-name');
      previewBox.forEach(preview =>{
      let target = preview.getAttribute('data-target');
      if(name == target){
        preview.classList.add('active');
      }
      });
    };
  });

  previewBox.forEach(close =>{
    close.querySelector('').onclick = () =>{
      close.classList.remove('active');
      previewContainer.style.display = 'none';
    };
  });

</script>



<ng-template #verifyApplicationModal let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Are you sure you want to verify merchant?</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <button class="btn btn-sm btn-primary float-right" (click)="processVerification(true)">Proceed</button>
    <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #rejectApplicationModal let-modal>
  <div class="modal-header">
    <h3 class="modal-title">Are you sure you want to reject this merchant?</h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="align-items: center; display: flex; flex-direction: column;">
    <div class="card bg-light mb-3" style="max-width: 18rem;">
      <div class="card-header">Merchant</div>
      <div class="card-body">
        <div style="display: flex;gap: 20px;">
        <div>
          <h6>Store Photo</h6>
          <img [src]="merchant?.business_store_facade" alt="" height="90px" width="90px" class="rounded-circle">
        </div>
        <div>
          <h6>Store Logo</h6>
          <img [src]="merchant?.business_store_logo" alt="" height="90px" width="90px" class="rounded-circle">
        </div>
        </div>
        <h5 class="card-title">{{ merchant?.business_name }}</h5>
          <h6>{{ merchant?.business_address_address }}</h6>
          <span class="badge badge-success rounded-pill d-inline">
            {{ merchant?.business_food_tags }}
          </span>
      </div>
    </div>
    <label for="remm" style="align-self: flex-start;">Merchant Remarks</label>
    <textarea
        placeholder="Comment here why you want to reject this merchant..."
        type="text"
        class="form-control"
        id="remm"
        [(ngModel)] = "remarksStr"
      ></textarea>
  </div>

  <div class="modal-body">
    <button class="btn btn-sm btn-danger float-right" (click)="processVerification(false)">Proceed</button>
    <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #remarkApplicationModal let-modal>
  <div class="modal-header">
    <h3 class="modal-title">Leave a comment to this product.</h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
    <div class="card bg-light mb-3" style="max-width: 18rem;">
      <div class="card-header">Product</div>
      <div class="card-body">
        <img [src]="selectedProduct.docData?.product_image_url" alt="" height="90px" width="90px" class="rounded-circle">
        <h5 class="card-title">{{selectedProduct.docData.product_name}}</h5>
          <h6>{{'₱ ' + makeMoney(selectedProduct.docData?.base_price)  }}</h6>
        <p class="card-text">{{selectedProduct.docData.product_description}}</p>
      </div>
    </div>
    <div class="form-group" style="width: 100%;">
      <label for="exampleFormControlTextarea1">Product Remarks</label>
      <textarea placeholder="Input remarks here..." class="form-control" id="exampleFormControlTextarea1" rows="3" [(ngModel)]="remarksText"></textarea>
    </div>
    <button type="submit" class="btn btn-primary" style="align-self: flex-end;" (click)="onSearchChange(remarksText, selectedProduct.docId, selectedCategory)">Submit</button>
    <!-- <input type="text" id="remarks{{product.docId}}" appDebounceClick [debounceTime]="500" name="remarks" (input)="onSearchChange($event.target.value, product.docId, category.docId)" placeholder="Input remarks here.."> -->
  </div>

  <!-- <div class="modal-body">
    <button class="btn btn-sm btn-danger float-right" (click)="processVerification(false)">Submit</button>
    <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
      Cancel
    </button> -->
  <!-- </div> -->
</ng-template>
