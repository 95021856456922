<app-loader *ngIf="!applications"></app-loader>
<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex" *ngIf="applications">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">List of Applications</h2>
          <small class="">JuanPay list of staffs</small>
        </div>
        <div class="flex"></div>
        <div>
          <!-- <div (click)="createApplication()" class="btn btn-md btn-info">
            <span class="d-none d-sm-inline mx-1">Create New</span>
            <i data-feather="arrow-right"></i>
          </div> -->
        </div>
      </div>
    </div>

    <div class="page-content page-container" id="page-content">
      <div class="" style="padding-right: 0px">
        <div class="card mb-2" style="background-color: #182038">
          <div class="row" [formGroup]="searchByFormGroup">
            <table id="datatable" class="table" role="grid" aria-describedby="datatable_info">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>
                    <button class="btn btn-sm btn-info" style="float: right" (click)="searchData()">
                      Search &nbsp; <i class="fa fa-filter text-highlight"></i>
                    </button>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor: pointer" class="odd" role="row">
                  <td class="td-title">
                    <label class="label-filter">Set No. of Rows</label>
                  </td>
                  <td class="td-value">
                    <ng-select
                      [items]="showRowsOption"
                      placeholder="No of Rows"
                      bindLabel="text"
                      bindValue="id"
                      formControlName="showRows"
                    >
                    </ng-select>
                  </td>

                  <td class="td-title">
                    <label class="label-filter">Manager Username</label>
                  </td>
                  <td class="td-value">
                    <ng-select
                      [items]="userIdFilterOptions"
                      placeholder="Filter by User"
                      bindLabel="text"
                      bindValue="id"
                      formControlName="username"
                    >
                    </ng-select>
                  </td>
                  <td></td>
                </tr>
                <tr style="cursor: pointer" class="odd" role="row">
                  <td class="td-title">
                    <label class="label-filter">Application Name</label>
                  </td>
                  <td class="td-value">
                    <ng-select
                      [items]="applicationNameOptions"
                      placeholder="Filter by Application Name"
                      bindLabel="text"
                      bindValue="id"
                      formControlName="applicationName"
                    >
                    </ng-select>
                  </td>
                  <td class="td-title">
                    <label class="label-filter">Application Process</label>
                  </td>
                  <td class="td-value">
                    <ng-select
                      [items]="showProgressOption"
                      placeholder="Filter by Application Process"
                      bindLabel="text"
                      bindValue="id"
                      formControlName="applicationProcess"
                    >
                    </ng-select>
                  </td>
                  <td></td>
                </tr>

                <tr style="cursor: pointer" class="odd" role="row">
                  <td class="td-title">
                    <label class="label-filter">Date Range</label>
                  </td>

                  <td class="td-value">
                    <input
                      type="text"
                      placeholder="Date Range"
                      class="form-control"
                      bsDaterangepicker
                      formControlName="dateRange"
                      style="background-color: white; color: black"
                      [bsConfig]="{
                        rangeInputFormat: 'YYYY-MM-DD',
                        dateInputFormat: 'YYYY-MM-DD',
                        showWeekNumbers: false
                      }"
                    />
                  </td>

                  <td class="td-title">
                    <label class="label-filter">Application Status</label>
                  </td>
                  <td class="td-value">
                    <ng-select
                      [items]="statusIdFilterOptions"
                      placeholder="Filter by Status"
                      bindLabel="text"
                      bindValue="id"
                      formControlName="status"
                    >
                    </ng-select>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card mt-2 bg-white" *ngIf="applications">
          <table id="datatable" class="table table-striped table-hover" role="grid">
            <thead>
              <tr>
                <th class="th-title-left">Username</th>
                <th class="th-title-center">Application Progess</th>
                <th class="th-title-center">Requirements Completion</th>
                <th class="th-title-left">Application Name</th>
                <th class="th-title-left">Purchased Date</th>
                <th class="th-title-left">Last Edit Date</th>
                <th class="th-title-left"><span class="d-none d-sm-block">Status</span></th>
              </tr>
            </thead>
            <!-- (click)="openContentDonePurchased(content, application)" -->
            <tbody>
              <tr
                *ngFor="
                  let application of applications?.data
                    | paginate: { itemsPerPage: pageRows, currentPage: p, totalItems: applications.totalRecords }
                "
                (click)="viewApplication(application?.id, getApplicationName(application?.meta))"
                style="cursor: pointer"
                class="odd"
                role="row"
              >
                <td>
                  <span class="w-32 avatar gd-info" style="float: left">
                    {{ application?.user.username.substring(0, 2).toUpperCase() }}
                  </span>
                  <label class="text-sm" style="margin: 5px"> {{ application?.user.username }}</label>
                </td>

                <td class="flex">
                  <center>
                    <div class="item-except h-1x" [innerHtml]="checkProgressTrail(application?.meta)"></div>
                  </center>
                </td>

                <td class="flex" style="width: 20%">
                  <div class="progress no-bg mt-2 align-items-center circle" style="height: 6px">
                    <div
                      [ngClass]="checkRequirementsProgressColor(application?.meta)"
                      [ngStyle]="{ width: checkRequirementsProgress(application?.meta) }"
                    ></div>
                    <span class="mx-2">{{ checkRequirementsProgress(application?.meta) }}</span>
                  </div>
                  <!-- <div [innerHtml]="checkRequirementsProgress(application?.meta)"></div> -->
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x" [innerHtml]="getApplicationName(application?.meta)"></div>
                </td>

                <td>
                  <div class="item-except text-sm h-1x">
                    {{ application?.createdDate | date: "MMMM dd, yyyy - hh:mm:ss a" }}
                  </div>
                </td>

                <td>
                  <div class="item-except text-sm h-1x">
                    {{ application?.managerLastUpdate | date: "MMMM dd, yyyy - hh:mm:ss a" }}
                  </div>
                </td>

                <td class="flex">
                  <div
                    class="item-except h-1x"
                    [innerHtml]="checkProgressStatus(application?.meta, application?.declined)"
                  ></div>
                </td>
              </tr>
            </tbody>
          </table>

          <!-- <div style="float: left">
           
          </div> -->

          <div>
            <div style="float: left" class="dataTables_info col-5" id="datatable_info" role="status" aria-live="polite">
              <div
                style="margin-top: 10px"
                class="dataTables_info"
                id="datatable_info"
                role="status"
                aria-live="polite"
              >
                Showing {{ (applications?.pageNumber - 1) * applications?.pageSize + 1 }} to
                {{ (applications?.pageNumber - 1) * applications?.pageSize + applications?.data?.length }} of
                {{ applications?.data?.length }} entries
              </div>
            </div>

            <div style="float: right" class="dataTables_paginate paging_simple_numbers col-6" id="datatable_paginate">
              <ul class="pagination" style="float: right; margin-right: 17px">
                <pagination-controls
                  (pageChange)="getApplications(buildPageUrl($event, pageRows))"
                ></pagination-controls>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<ng-template #viewApplicationModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      Application Progress <small class=""><br />JuanPay application progress</small>
    </h4>

    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-application-view [id]="id"></app-application-view>
  </div>
</ng-template>

<ng-template #viewApplicationDA5Modal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      Application Progress <small class=""><br />JuanPay application progress</small>
    </h4>

    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-application-da5-view [id]="id"></app-application-da5-view>
  </div>
</ng-template>

<ng-template #viewApplicationSendahModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      Application Progress <small class=""><br />JuanPay application progress</small>
    </h4>

    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-application-sendah-view [id]="id"></app-application-sendah-view>
  </div>
</ng-template>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Product Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-3 d-flex justify-content-center">
        <img [src]="thumbnailFormGroup?.controls.logo.value" height="100" width="100" alt="" />
      </div>
      <div class="col-8">
        <p>{{ acquiredFormGroup?.controls.description.value }}</p>
      </div>
      <div class="col-1"></div>
    </div>

    <div class="container pt-0">
      <div class="card">
        <div class="row d-flex justify-content-center">
          <div class="col-12">
            <ul id="progressbar" class="text-center">
              <div class="d-flex justify-content-center">
                <li
                  *ngFor="let progress of purchaseProgressFormArray?.controls"
                  [ngClass]="checkProgressClass(progress.controls.status.value)"
                >
                  {{ progress.controls.title.value }}<br />
                  <img
                    class="icon"
                    [src]="progress.controls.icon.value"
                    (click)="changeProgresStatus(progress.controls.status)"
                  />
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <button *ngIf="!saveApplicationProcessing" (click)="saveProgress()" class="btn btn-info" style="float: right">
        Save
      </button>
      <span *ngIf="saveApplicationProcessing" style="float: right">
        <img
          class="icon"
          src="https://i.ibb.co/pw2cPp7/custom-spinner-final.gif"
          style="margin-right: -20px; margin-top: -2px"
        />
        Processing please wait..
      </span>
    </div>
  </div>
</ng-template>
