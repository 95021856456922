import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { FormGroup } from "@angular/forms";
import { ConvertToApiDatetimePipe } from "src/app/pipes/convert-to-api-datetime.pipe";
import { DatePipe } from "@angular/common";
import { CryptoService } from "src/data/crypto/crypto.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class StoreService {
  private localStorageCacheService: LocalStorageCacheService;
  private convertToApiDatetimePipe: ConvertToApiDatetimePipe;

  constructor(
    private httpClient: HttpClient,
    private datePipe: DatePipe,
    private cryptoService: CryptoService,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService,
    convertToApiDatetimePipe: ConvertToApiDatetimePipe
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
    this.convertToApiDatetimePipe = convertToApiDatetimePipe;
  }
  private httpClientNoInterceptor: HttpClient;

  private baseAPIUrl = environment.API_URL;

  public createStore(storeMeta: FormGroup): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/managers`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    const body = new FormData();
    body.append("email", storeMeta.controls.email.value);
    body.append("username", storeMeta.controls.username.value);
    body.append("first_name", storeMeta.controls.first_name.value);
    body.append("last_name", storeMeta.controls.last_name.value);
    body.append("address", storeMeta.controls.address.value);
    body.append("contact_number", storeMeta.controls.contact_number.value);
    body.append(
      "birth_date",
      this.convertToApiDatetimePipe.transform(
        this.datePipe.transform(storeMeta.controls.birth_date.value, "yyyy-MM-dd")
      )
    );
    body.append("emergency_contact_name", storeMeta.controls.emergency_contact_name.value);
    body.append("emergency_contact_number", storeMeta.controls.emergency_contact_number.value);
    body.append("emergency_contact_relationship", storeMeta.controls.emergency_contact_relationship.value);
    body.append(
      "hiring_date",
      this.convertToApiDatetimePipe.transform(
        this.datePipe.transform(storeMeta.controls.hiring_date.value, "yyyy-MM-dd")
      )
    );
    body.append("employee_id", storeMeta.controls.employee_id.value);
    body.append("activate", storeMeta.controls.activate.value);
    body.append("photo", storeMeta.controls.photo.value);

    return this.httpClient.post(endPointUrl, storeMeta.value, { headers: httpHeaders });
  }

 

  public editStore(storeMeta: FormGroup, id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/managers/${id}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    const body = new FormData();
    body.append("_method", "PATCH");
    body.append("email", storeMeta.controls.email.value);
    body.append("username", storeMeta.controls.username.value);
    body.append("first_name", storeMeta.controls.first_name.value);
    body.append("last_name", storeMeta.controls.last_name.value);
    body.append("address", storeMeta.controls.address.value);
    body.append("contact_number", storeMeta.controls.contact_number.value);
    body.append(
      "birth_date",
      this.convertToApiDatetimePipe.transform(
        this.datePipe.transform(storeMeta.controls.birth_date.value, "yyyy-MM-dd")
      )
    );
    body.append("emergency_contact_name", storeMeta.controls.emergency_contact_name.value);
    body.append("emergency_contact_number", storeMeta.controls.emergency_contact_number.value);
    body.append("emergency_contact_relationship", storeMeta.controls.emergency_contact_relationship.value);
    body.append(
      "hiring_date",
      this.convertToApiDatetimePipe.transform(
        this.datePipe.transform(storeMeta.controls.hiring_date.value, "yyyy-MM-dd")
      )
    );
    body.append("employee_id", storeMeta.controls.employee_id.value);
    body.append("role_id", storeMeta.controls.role_id.value);
    if (storeMeta.controls.photo.value && !storeMeta.controls.photo.value.includes(this.baseAPIUrl)) {
      body.append("photo", storeMeta.controls.photo.value);
    }
    body.append("activate", "0");

    return this.httpClientNoInterceptor.post(endPointUrl, body, { headers: httpHeaders });
  }

  public getStore(storeId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/stores/${storeId}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }




  // public getStore(url: any): Observable<any> {

  //   var endPointUrl : any;
  //   var accessToken : any = this.localStorageCacheService.getStorage("access_token");
  //   endPointUrl  = `${url}`;

  //   const httpHeaders: HttpHeaders = new HttpHeaders()
  //     .append("Authorization", "Bearer " + accessToken)
  //     .append("Content-Type", "application/x-www-form-urlencoded");

  //   return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  // }

  // tslint:disable-next-line: typedef
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  // tslint:disable-next-line: typedef
  private log(message: string) {
    //
  }
}
