import { Directive, ElementRef, Input, OnInit, SimpleChanges } from "@angular/core";

@Directive({
  selector: "[animated-hide]",
})
export class AnimatedHideDirective implements OnInit {
  @Input("animated-hide") activateHide: boolean;

  private nativeElement: HTMLElement;

  constructor(elementRef: ElementRef) {
    this.nativeElement = elementRef.nativeElement;
  }

  ngOnInit(): void {
    if (!this.activateHide) {
      // Show
      this.nativeElement.style.transition = "all 150ms";
      this.nativeElement.style.height = "0";
      this.nativeElement.hidden = false;
      this.nativeElement.style.height = "auto";
      this.nativeElement.style.visibility = "visible";
      this.nativeElement.style.opacity = "1";
    }

    if (this.activateHide) {
      // Hide
      this.nativeElement.style.transition = "all 100ms";
      this.nativeElement.style.height = "auto";
      this.nativeElement.hidden = true;
      this.nativeElement.style.height = "0";
      this.nativeElement.style.visibility = "hidden";
      this.nativeElement.style.opacity = "0";
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activateHide != undefined && !changes.activateHide.isFirstChange()) {
      if (!changes.activateHide.currentValue) {
        // Show
        this.nativeElement.style.transition = "all 150ms ease-out";
        this.nativeElement.style.height = "0";
        this.nativeElement.hidden = false;
        setTimeout(() => {
          this.nativeElement.style.height = "auto";
          this.nativeElement.style.visibility = "visible";
          this.nativeElement.style.opacity = "1";
        }, 50);
      }

      if (changes.activateHide.currentValue) {
        // Hide
        this.nativeElement.style.transition = "all 100ms ease-in";
        this.nativeElement.style.height = "0";
        this.nativeElement.style.visibility = "hidden";
        this.nativeElement.style.opacity = "0";

        setTimeout(() => {
          this.nativeElement.hidden = true;
          this.nativeElement.style.height = "auto";
        }, 300);
      }
    }
  }
}
