import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.less']
})
export class SplashScreenComponent implements OnInit {
  loader : false;
  constructor(private webSettingsService : WebSettingsService,
    private localStorageCacheService : LocalStorageCacheService) { }
  
  @Output("closeModal") closeModal = new EventEmitter();
  activeScreen = 1;
  cntScreen = 0

  screens: any = [];
  roleLevelId: any;

  ngOnInit(): void {

    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");

    this.webSettingsService.getWebSettingsByIdentifier('SPLASH_SCREEN').subscribe(
      (result: any) => {
        if(result.data.length > 0){

          //console.log(this.roleLevelId, "      SCREEEEEEEEEEEEEEEEEEEENS      sssssss    ", result.data);

          result.data.forEach((item) => {
            if(item.value.level == this.roleLevelId){
              console.log(item.value.img);
              this.screens.push(item.value.img);
              
            }
          });


          // this.screens = result.data;
          //result.data.filter(x => x.level == this.roleLevelId);
          //this.screens = result.data;
          console.log( "      SCREEEEEEEEEEEEEEEEEEEENS          ", this.screens);
          this.cntScreen = this.screens.length;
        }else{
          this.closeModal.emit();
        }
      });
  }

  setActiveScreen(cnt){
    
    this.activeScreen = this.activeScreen + cnt;

  }

  closeSplashScreen(){
   this.closeModal.emit();
  }

}
