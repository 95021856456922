import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { FormGroup } from "@angular/forms";
import { ConvertToApiDatetimePipe } from "src/app/pipes/convert-to-api-datetime.pipe";
import { DatePipe } from "@angular/common";
import { CryptoService } from "src/data/crypto/crypto.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CashierUsersService {
  private localStorageCacheService: LocalStorageCacheService;
  private convertToApiDatetimePipe: ConvertToApiDatetimePipe;

  constructor(
    private httpClient: HttpClient,
    private datePipe: DatePipe,
    private cryptoService: CryptoService,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService,
    convertToApiDatetimePipe: ConvertToApiDatetimePipe
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
    this.convertToApiDatetimePipe = convertToApiDatetimePipe;
  }
  private httpClientNoInterceptor: HttpClient;

  private baseAPIUrl = environment.API_URL;

  public createCashierUser(CashierUserMeta: FormGroup): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/CashierUsers`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClient.post(endPointUrl, CashierUserMeta, { headers: httpHeaders });
  }

  public editCashierUser(CashierUserMeta: FormGroup, id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/CashierUsers/CashierUsers/${id}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClientNoInterceptor.put(endPointUrl, CashierUserMeta, { headers: httpHeaders });
  }

  public resetCashierUser(CashierUserMeta: FormGroup, id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/CashierUsers/ResetCashierUser/${id}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    return this.httpClientNoInterceptor.put(endPointUrl, CashierUserMeta, { headers: httpHeaders });
  }

  public getCashierUser(CashierUserId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/CashierUsers/${CashierUserId}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getCashierUserv2(CashierUserId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/CashierUsers/v2/${CashierUserId}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getCashierUsers(url: any): Observable<any> {
    let endPointUrl: any;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    endPointUrl = `${url}`;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }


  public getCashierUsersWithRoles(ManagerUserId: any): Observable<any> {
    
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    const endPointUrl = `${this.baseAPIUrl}/api/CashierUsers/Manager/Network/Role/${ManagerUserId}`;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");


    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }


  public putCashierUsersWithRoles(type: any, status:any,actUsername:any,managerUserId:any): Observable<any> {
    
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    const endPointUrl = `${this.baseAPIUrl}/api/CashierUsers/Manager/Network/Role/Update`;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        type : type,
        status : status,
        actUsername : actUsername,
        managerUserId : managerUserId
      }

    return this.httpClient.put(`${endPointUrl}`, json,{ headers: httpHeaders });
  }

  public putAccountLock(status:any,managerUserId:any): Observable<any> {
    
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    const endPointUrl = `${this.baseAPIUrl}/api/StoreSettings/AccountLock`;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        status : status,
        managerUserId : managerUserId
      }

    return this.httpClient.put(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public putSalesModule(status:any,managerUserId:any): Observable<any> {
    
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    const endPointUrl = `${this.baseAPIUrl}/api/StoreSettings/SalesModuleEnable`;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        status : status,
        managerUserId : managerUserId
      }

    return this.httpClient.put(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  public putIsDeactivated(status:any,managerUserId:any): Observable<any> {
    
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    const endPointUrl = `${this.baseAPIUrl}/api/StoreSettings/IsDeactivated`;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        status : status,
        managerUserId : managerUserId
      }

    return this.httpClient.put(`${endPointUrl}`, json, { headers: httpHeaders });
  }

  

  

  // tslint:disable-next-line: typedef
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  // tslint:disable-next-line: typedef
  private log(message: string) {
    //
  }

  public updateCashierSchedule(schedule: any, id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/CashierUsers/Schedule`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

    const json = {
      id: id,
      schedule: schedule,
    };

    console.log(json);

    return this.httpClient.post(endPointUrl, json, { headers: httpHeaders });
  }
}
