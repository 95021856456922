import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { MultisysService } from 'src/data/services/web/multisys.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-multisys-favorite',
  templateUrl: './multisys-favorite.component.html',
  styleUrls: ['./multisys-favorite.component.less']
})
export class MultisysFavoriteComponent implements OnInit {

  @Input("application") application: any;
  
  @Output("closeFavoriteAdd") closeFavoriteAdd = new EventEmitter();

  billerFormGroup : FormGroup;
  formBuilder : FormBuilder;
  multisysService : MultisysService;

  public emptyString = "";
  public billers : any = [];
  public biller : any;
  public billerCode : any;
  public billerTypes : any = [];
  public billersByBillerTypes : any = [];
  public selectedBillerCode : any;

  public loader = true;
  
  constructor(multisysService : MultisysService, 
    private router: Router,
    localStorageCacheService : LocalStorageCacheService,
    formBuilder : FormBuilder) {
      this.multisysService = multisysService;
      this.formBuilder = formBuilder;
     }

  ngOnInit(): void {
    this.initBillerTypeFormGroup();
    this.getBillers();
  }

  initBillerTypeFormGroup(): void {
    this.billerFormGroup = new FormGroup({
      billerType: new FormControl(this.emptyString, [Validators.required]),
      billerCode: new FormControl(this.emptyString, [Validators.required]),
      params: this.formBuilder.group({})
    });
  }

  getBillers() {
    this.multisysService.getBillers().subscribe((billers) => {
      this.billers = billers;
      this.getBillerTypes(billers);
    }, error => {
      PNotify.error({
        title: "Service Unavailable",
        text: "[Error 331] Partner services unreachable at the moment. Please retry after 30 minutes or check our announcement for more details. Thank you for your usual support and cooperation.",
      });
      this.loader = false;
      this.closeFavoriteAdd.emit();
    });
  }

  getBillerTypes(billers : any){
    var lookup = {};
    var items = billers;
    
    for (var item, i = 0; item = items[i++];) {
      var type = item.type;
    
      if (!(type in lookup)) {
        lookup[type] = 1;
        this.billerTypes.push(type);
      }
    }
    this.loader = false;
  }

  getBillersByBillerType(billerType : any){
    this.billersByBillerTypes = this.billers.filter(x=>x.type == billerType);
  }

  setBillerCode(billerCode : any){
    this.billerCode = billerCode;
  }
}
