<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex" >
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">Migration List</h2>
          <small class="text-highlight font-weight-normal font-italic">List of manager to be migrated from old enterprise.</small>
        </div>
        <div class="flex"></div>
       
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="col-lg-4" style="float:left">
        <div class="card mt-2">
          <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
            <thead>
              <tr>
                <th data-sortable="true" data-field="owner">Signed Up ({{migrationList.length}} Accounts)</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let migration of migrationList"
                style="cursor: pointer"
                class="odd"
                role="row"
                (click)="openSendMessage(migrationModal,migration.username)"
              >
               
                <td>
                  <strong>Username : {{ migration.username }}</strong><br/>
                  <small class="">Created Date : {{ migration.createdDate | date: "dd-MMM-yyyy hh:mm:ss a" }}</small><br/>
                  <small class="">
                    Corporate Services : 
                    <span *ngIf="migration.corporateServicesSignOff.length == 0">
                      <span class="text-warning">Pending</span>
                    </span>
                    <span *ngIf="migration.corporateServicesSignOff[0]?.status == 1">
                      <span class="text-success">Completed</span>
                    </span>
                    <span *ngIf="migration.corporateServicesSignOff[0]?.status == 0">
                      <span class="text-danger">Rejected</span>
                    </span>
                      <!-- {{ migration.corporateServicesSignOff.length == 0 ? 'Pending' : migration.corporateServicesSignOff[0].status == 1 ? 'Completed' : 'Rejected' }} -->
                  </small><br/>
                  <small class="">Finance : 
                    <span *ngIf="migration.financeSignOff.length == 0">
                      <span class="text-warning">Pending</span>
                    </span>
                    <span *ngIf="migration.financeSignOff[0]?.status == 1">
                      <span class="text-success">Completed</span>
                    </span>
                    <span *ngIf="migration.financeSignOff[0]?.status == 0">
                      <span class="text-danger">Rejected</span>
                    </span>
                    <!-- {{ migration.financeSignOff.length == 0 ? 'Pending' : migration.financeSignOff[0].status == 1 ? 'Completed' : 'Rejected' }} -->
                  </small><br/>
                  <small class="">Support : 
                    <span *ngIf="migration.supportSignOff.length == 0">
                      <span class="text-warning">Pending</span>
                    </span>
                    <span *ngIf="migration.supportSignOff[0]?.status == 1">
                      <span class="text-success">Completed</span>
                    </span>
                    <span *ngIf="migration.supportSignOff[0]?.status == 0">
                      <span class="text-danger">Rejected</span>
                    </span>
                    <!-- {{ migration.supportSignOff.length == 0 ? 'Pending' : migration.supportSignOff[0].status == 1 ? 'Completed' : 'Rejected' }} -->
                  </small>
                
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-lg-4" style="float:left"> 
        <div class="card mt-2"  >
          <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
            <thead>
              <tr>
                <th data-sortable="true" data-field="owner">Ignored ({{migrationListNeed.vip.length}} Accounts)</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let migration of migrationListNeed.vip"
                style="cursor: pointer"
                class="odd"
                role="row"
               
              >
               
                <td>
                  <strong>Username : {{ migration.username }}</strong><br/>
                  <small>Email Address : {{ migration.emailAddress }}</small><br/>
                  <small class="">Mobile Number {{ migration.mobileno }}</small>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-lg-4" style="float:left"> 
        <div class="card mt-2"  >
          <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
            <thead>
              <tr>
                <th data-sortable="true" data-field="owner">Migrated ({{migrationListNeed.govip.length}} Accounts)</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let migration of migrationListNeed.govip"
                style="cursor: pointer"
                class="odd"
                role="row"
              
              >
               
                <td>
                  <strong>Username : {{ migration.username }}</strong><br/>
                  <small>Created Date : {{ migration.createdDate | date: "dd-MMM-yyyy hh:mm:ss a"  }}</small><br/>
                  <small>Created By : {{ migration.createdBy }}</small><br/>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<ng-template #migrationModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Migration Form</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
       <app-migration-form [username]="username" (closeModal)="closeModal()">

       </app-migration-form>
  </div>
</ng-template>
