<app-loader *ngIf="loader"></app-loader>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" integrity="sha512-MV7K8+y+gLIBoVD59lQIYicR65iaqukzvf/nwasF0nqhPay5w/9lJmVM2hMDcnK1OnMGCdVK+iQrJ7lzPJQd1w==" crossorigin="anonymous" referrerpolicy="no-referrer" />
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css">
<div id="content" class="flex">
    <!-- ############ Main START-->
    <div>
      <!-- <div   id="page-hero">
        <div class="padding d-flex">
          <div class="page-title">
            <h2 class="--spam">Vips Users</h2>
            <small class="--desc">GoVIPCenter list Vips User</small>
          </div>
          <div class="flex"></div>
        </div>
      </div> -->
      <div class="container-fluid" style="padding-inline:10px; width: 95%;display: flex;justify-content: space-between; align-items: center;gap: 20px; margin-block: 5px; padding-right: 25px;">
        <div class="d-flex align-items-center header" style="gap: 15px;">
          <img src="../../../../assets/vips2/1.png" width="90px" alt="">
          <h1 class="m-0" style="font-size: 24px; font-weight: bolder;color: #5e676f;">VIPS ACCOUNT REACTIVATION</h1>
        </div>
      </div>
      <div class=" pr-3 pl-3" id="page-content">
        <div class="card p-2">
          <!-- <div class="card mb-2 p-1" style="background-color: #182038"> -->
            <!-- Filters -->
  
  
            <div class="card mb-2 mt-0" style="box-shadow: 0 2px 4px 0 #182038, 0 2px 5px 0 #182038;">
                <div class="row" [formGroup]="searchByFormGroup">
            
                    <table id="datatable" class="table" role="grid" aria-describedby="datatable_info">
                    <thead>
                        <tr>
                        <th>
                          <!-- <button
                              class="btn btn-sm mr-2 ml-5 mt-2 btn-secondary button--create font-weight-bold"
                              *ngIf="roleId == 1 || roleId == 2 || roleId == 7 || roleId == 19" 
                              style="float:right"
                              (click)="openBatchDeacModal(batchDeactivationModal)">
                              Batch Deactivation &nbsp; <i class="fa fa-file-excel text-highlight"></i>
                          </button> -->
                        </th>
                        <th></th>
                        <th></th>
                        <th>
                          <button
                              class="btn btn-sm mt-2 mr-2 btn-info button--search font-weight-bold" style="float:right"
                              (click)="searchData()">
                              Search &nbsp; <i class="fa fa-filter text-highlight"></i>
                          </button>
            
                          <!-- <button
                              class="btn btn-sm mr-2 mt-2 ml-2 btn-success button--create font-weight-bold"
                              *ngIf="roleId == 1 || roleId == 2 || roleId == 6 || roleId == 7 || roleId == 19 || roleId == 22 || roleId == 23" 
                              (click)="getTransactionExcel()">
                              Export to Excel &nbsp; <i class="fa fa-file-excel text-highlight"></i>
                          </button> -->
                          
                          <!-- <button
                              class="btn btn-sm mr-2 mt-2 btn-primary button--create font-weight-bold"
                              *ngIf="roleId == 1 || roleId == 2 || roleId == 7 || roleId == 19" 
                              (click)="openFileUpload(uploadFilemodal)">
                              Upload Batch &nbsp; <i class="fa fa-file-excel text-highlight"></i>
                          </button> -->

                          <!-- <button
                              class="btn btn-sm mr-2 mt-2 btn-danger button--create font-weight-bold"
                              *ngIf="roleId == 1 || roleId == 2 || roleId == 7 || roleId == 19" 
                              (click)="createModal(addRiderModal)">
                              Create Rider &nbsp; <i class="fa fa-plus text-highlight"></i>
                          </button> -->

                        </th>
                        <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        style="cursor: pointer"
                        class="odd"
                        role="row"
                        >
                        <td class="td-title">
                            <!-- <label class="label-filter" style="color: #182038; font-weight: bolder;">Date Range</label> -->
                            &nbsp;
                        </td>
                        <td class="td-value">
                             <!-- <input
                                type="text"
                                placeholder="Date Range"
                                class="form-control"
                                bsDaterangepicker
                                formControlName="dateRange"
                                style="background-color: white; color: black; height: 37px; border-color: #000;"
                                [bsConfig]="{
                                rangeInputFormat: 'YYYY-MM-DD',
                                dateInputFormat: 'YYYY-MM-DD',
                                showWeekNumbers: false
                                }"
                            /> -->
                            &nbsp;
                        </td>
            
                        <td class="td-title">
                          <label class="label-filter" style="color: #182038; font-weight: bold;">Search</label>
                          </td>
            
                          <td class="td-value">
                            <input
                              type="text"
                              class="form-control form-control-sm search"
                              placeholder="Search..."
                              formControlName="searchString"
                              style="background-color: white; color: black; height: 37px; border-color: #182038;"
                            />
                          </td>
            
                    </tbody>
                    </table>
            
                </div>
              </div>
  
  
          <div class="card mt-2 pl-2 pr-2">
            <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
              <thead>
                <tr>
                  <th data-sortable="true" data-field="id">
                    <div>Personal Details</div>
                  </th>
                  <th data-sortable="true" data-field="owner">
                    <div>Account Details</div>
                  </th>
                  <th data-field="finish"><span class="d-none d-sm-block">Status</span></th>
                  <th data-field="finish"><span class="d-none d-sm-block">Action</span></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let user of users?.data
                      | paginate: { itemsPerPage: 10, currentPage: p, totalItems: users?.totalRecords }
                  "
                 
                  style="cursor: pointer"
                  class="odd"
                  role="row"
                >
                  <td class="flex">
                    <div class="item-except text-sm h-1x">
                      Name : <span style="color: cadetblue; font-weight: bold;">{{ getFullName(user) }}</span>
                    </div>
                    <div class="item-except text-sm h-1x">
                        Mobile No : <span style="color: cadetblue; font-weight: bold;">{{user?.mobileNo}}</span>
                    </div>
                  </td>
                  <td class="flex">
                    
                    <div class="item-except text-sm h-1x">
                        Email Address : <span style="color: cadetblue; font-weight: bold;">{{user?.userEmail}}</span>
                    </div>
                    <div class="item-except text-sm h-1x ">
                      LinkedStore : <span style="color: cadetblue; font-weight: bold;">{{ user?.linkedStore }}</span>
                    </div>
                  </td>
  
                  <td class="flex pb-0">
                    <div>
                      Active : 
                      <label class="ui-switch ui-switch-sm dark mt-1 mr-2">
                        <input type="checkbox" [checked]="user.status == 'ACTIVE' ? true : false" />
                        <i></i>
                      </label>

                      <div class="item-except text-sm h-1x">
                        User Level : 
                        <span style="color: cadetblue; font-weight: bold;">
                        {{ getLevel(user.usertype) }}
                        </span>
                    </div>
                    </div>
                  </td>

                  <td>
                    <div class="d-flex justify-content-center align-items-center">
                        <button class="btn btn-primary btn-sm" (click)="confirmReactivate(confirmReactivateModal, user)">
                            Reactivate
                        </button>
                    </div>
                  </td>

                </tr>
              </tbody>
            </table>
          </div>
  
          <div class="row pl-2 pr-2">
            <div class="col-sm-12 col-md-5">
              <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                <div class="col-sm-12 col-md-12">
                  <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                    Showing {{ (users?.pageNumber - 1) * users?.pageSize + 1 }} to
                    {{ (users?.pageNumber - 1) * users?.pageSize + users?.data?.length }} of
                    {{ users?.data?.length }} entries
                  </div>
                </div>
              </div>
            </div>
  
            <div class="col-sm-12 col-md-7" style="float: right">
              <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                <ul class="pagination" style="float: right">
                  <pagination-controls (pageChange)="getVipsUser(buildPageUrl($event, 10))"></pagination-controls>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ############ Main END-->
  </div>

  <ng-template #confirmReactivateModal let-modal>
    <div class="modal-header">
      <h6 class="modal-title">Are you sure you want to Reactivate <br> <span class="font-weight-bold">{{ getFullName(selectedUser) }}</span> ?</h6>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
      <button class="btn btn-sm btn-success float-right" (click)="activateAccount(selectedUser.userID)">Proceed</button>
      <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
        Cancel
      </button>
    </div>
  </ng-template>