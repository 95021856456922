import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { result } from 'lodash';
import { StoreItemService } from 'src/data/services/web/store-item.service';
import { UploadService } from 'src/data/services/web/upload.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-stock-item',
  templateUrl: './stock-item.component.html',
  styleUrls: ['./stock-item.component.less']
})
export class StockItemComponent implements OnInit {
  @Input() stockItem: any;
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() getStockItem: EventEmitter<any> = new EventEmitter();

  imageWidth = 64; // set the width of each image
  imageHeight = 68; // set the width of each image
  visibleImages = 3; // set the number of visible images
  currentIndex = 0; // set the initial index of the first visible image
  
  scroll(direction: number): void {
    const wrapper = document.querySelector('.slider-wrapper') as HTMLElement;
    const itemWidth = this.imageWidth + 4; // include margin-right
    const wrapperWidth = wrapper.offsetWidth;
    const maxIndex = this.images.length - this.visibleImages;
    let nextIndex = this.currentIndex + direction;
    if (nextIndex < 0) {
      nextIndex = 0;
    } else if (nextIndex > maxIndex) {
      nextIndex = maxIndex;
    }
    this.currentIndex = nextIndex;
    const transform = `translateX(-${this.currentIndex * itemWidth}px)`;
    wrapper.style.transform = transform;
  }

  isToUpdate: boolean = false;
  stockItemFormGroup: FormGroup;

  constructor(private router: Router, 
    private storeItemService: StoreItemService,
    private uploadService: UploadService,
    private modService: NgbModal,
    private currencyPipe: CurrencyPipe
    ) { }

  ngOnInit(): void {
    this.initFormGroup();
    this.getCategories();
    this.getUoM();
    this.getConversion();

    this.cardImage = this.stockItem.storeItemImages[0].path;
    this.images = this.stockItem.storeItemImages;
  }

  initFormGroup(){
    this.stockItemFormGroup = new FormGroup({
      id: new FormControl(this.stockItem.id, [Validators.required]),
      itemCode: new FormControl(this.stockItem.itemCode, [Validators.required]),
      name: new FormControl(this.stockItem.name, [Validators.required]),
      description: new FormControl(this.stockItem.description, [Validators.required]),
      price: new FormControl(this.stockItem.price, [Validators.required]),
      categoryId: new FormControl(this.stockItem.category.id, [Validators.required]),
      uomId: new FormControl(this.stockItem.unit.id, [Validators.required]),
      active: new FormControl(this.stockItem.active, [Validators.required]),
      isPreOrder: new FormControl(this.stockItem.isPreOrder, [Validators.required])
    });
  }

  categories: any;
  getCategories(){
    this.storeItemService.getCategories().subscribe((result) => {
      //this.categories = result;
      this.categories = result.map((status) => {
        return {
          id: status.id,
          text: status.category,
        };
      });
    });
  }

  uom: any;
  getUoM(){
    this.storeItemService.getUoM().subscribe((result) => {
      this.uom = result.map((status) => {
        return {
          id: status.id,
          text: status.abrUnit,
        };
      });
    });
  }

  itemConversion: any;
  getConversion(){
    this.storeItemService.getConversion(this.stockItem.id).subscribe((result) => {
      this.itemConversion = result;
    });
  }

  setoUpdate(){
    this.isToUpdate = true;
  }

  saveChange(){
    if(this.stockItemFormGroup.invalid){
      PNotify.error({
        title: "Invalid",
        text: "Please fill all fields",
      });

      return
    }

    this.storeItemService.stockItemPut(this.stockItemFormGroup.value).subscribe((result) => {
      if(result){
        PNotify.success({
          title: "Stock Item Update",
          text: "Item successfully updated",
        });
        this.isToUpdate = false;
        this.getStockItem.emit();
        this.closeModal.emit();
      } else {
        PNotify.error({
          title: "Updating",
          text: "Something went wrong",
        });
      }
    });
  }

  cardImage: any;
  changeBigImage(image: any){
    this.cardImage = image;
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }


  images:any = [];
  files: File[] = [];

  onSelect(event) {
    console.log(event);
    // this.files = [];
    // this.files.push(...event.addedFiles);

    this.files = [];
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif']; // Valid image MIME types
  
    for (const file of event.addedFiles) {
      if (validImageTypes.includes(file.type)) {
        this.files.push(file);
      } else {
        console.log(`Invalid file type: ${file.type}`);
        // Display an error message or perform any necessary actions for invalid file types
      }
    }
  }
  
  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  addImageModalRef: NgbModalRef;
  addImage(content: any){
    this.addImageModalRef = this.modService.open(content, {
      centered: true,
      size: "sm",
      backdrop: "static",
      keyboard: false,
    });
  }

  uploadImage(){
    if(!this.files[0]){
      PNotify.error({
        title: "Invalid",
        text: "Please attach a valid image.",
      });
        return;
    }

    const file = this.files[0];
    const maxSize = 1 * 1024 * 1024; // 1MB in bytes
    
    if (file.size > maxSize) {
      PNotify.error({
        title: "Invalid",
        text: "Please select an image that is less than 1MB in size.",
      });
      return;
    }
    
    const file_data = this.files[0];

    //var filedata = '';
    const reader = new FileReader();
    reader.readAsDataURL(file_data);
    reader.onload = () => {
      const filedata = reader.result.toString().substring(reader.result.toString().indexOf(',') + 1);

      this.uploadService.uploadImageCreatedItem(filedata, this.stockItem.id).subscribe(response => {
        if(response){
          const json = JSON.parse(response);
          let path = { path: json.response_data };
          this.images.push(path);
          this.files = [];
          this.cardImage = json.response_data;
          //console.log(this.images);
        }
      });
    }
    
    this.addImageModalRef.close();
  }
}
