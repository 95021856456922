export class Schedule {
  mon: number[];
  tue: number[];
  wed: number[];
  thu: number[];
  fri: number[];
  sat: number[];
  sun: number[];

  public static DAYS_SEED = {
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
    sun: [],
  };

  public static HOURS_SEED = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
}
