<app-loader *ngIf="loader"></app-loader>
<div class="modal-body" *ngIf="activeTab != 3">
  <form id=" form" novalidate="">
    <div id="rootwizard">
      <ul class="nav mb-3">
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 1 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >1</span
            >
            <div class="mt-2">
              <div class="text-muted">Standard Insurance</div>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 2 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >2</span
            >
            <div class="mt-2">
              <div class="text-muted">Confirmation</div>
            </div>
          </a>
        </li>
        <li  class="nav-item" style="margin-left: auto">
          <a class="nav-link text-center" [ngClass]="activeTab == 3 ? 'active' : ''">
            <img [src]="application.value.thumbnail.logo" height="50" class="img-responsive" style="object-fit: contain" />
          </a>
        </li>
      </ul>

      <hr class="bg-dark" />

      <div class="tab-content p-3">
        <div class="tab-pane" [ngClass]="activeTab == 1 ? 'active' : ''" id="selectBillerTab">
          <div class="row" [formGroup]="standardInsuranceFormGroup">
           
            <div class="col-sm-4 col-md-4" style="float:left">
                <h4>Vehicle Details</h4>
                <hr/>                

                <app-datepicker
                  [parent-form-group]="standardInsuranceFormGroup"
                  tab-index-start="1"
                  control-name="inceptionDate"
                  [error]="standardInsuranceFormGroup?.controls.inceptionDate.errors"
                  read-only="false"
                  label="Inception Date"
                  placeholder="Inception Date"
                >
                </app-datepicker>

                <app-datepicker
                  [parent-form-group]="standardInsuranceFormGroup"
                  tab-index-start="1"
                  control-name="expiryDate"
                  [error]="standardInsuranceFormGroup?.controls.expiryDate.errors"
                  read-only="false"
                  label="Expiry Date"
                  placeholder="Expiry Date"
                >
                </app-datepicker>

                <app-text-all-caps
                  [parent-form-group]="standardInsuranceFormGroup"
                  tab-index-start="0"
                  control-name="plateNo"
                  [error]="standardInsuranceFormGroup?.controls.plateNo.errors"
                  read-only="false"
                  no-special-char="false"
                  label="Plate No"
                  placeholder="Plate No"
                  max-length="6"
                >
                </app-text-all-caps>

                <app-textbox-number-only
                  [parent-form-group]="standardInsuranceFormGroup"
                  tab-index-start="0"
                  control-name="yearModel"
                  [error]="standardInsuranceFormGroup?.controls.yearModel.errors"
                  read-only="false"
                  no-special-char="false"
                  label="Year Model"
                  placeholder="Year Model"
                  max-length="4"
                >
                </app-textbox-number-only>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Product Type: </label>
                  <div class="col-sm-9">
                    <select
                      (change)="productTypeChange($event.target.value)"
                      formControlName="productCode"
                      class="form-control"
                      tabindex="-1"
                      aria-hidden="true"
                    >
                      <option value="">Choose...</option>
                      <option *ngFor="let type of (productTypes | sortdata:'description')" [value]="type.code">
                        {{ type.description }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Manufacturer: </label>
                  <div class="col-sm-9">
                    <select
                      (mousedown)="handleSelect($event)"
                      (change)="getMakeModel($event.target.value)"
                      formControlName="manufacturer"
                      class="form-control"
                      tabindex="-1"
                      aria-hidden="true"
                    >
                      <option value="">Choose...</option>
                      <option *ngFor="let type of (fmvManufacturers | sortdata:'manufacturer')" [value]="type.manufacturer">
                        {{ type.manufacturer }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Make Model: </label>
                  <div class="col-sm-9">
                    <select
                      (change)="makeModelChange($event.target.value)"
                      formControlName="makeModel"
                      class="form-control"
                      tabindex="-1"
                      aria-hidden="true"
                    >
                      <option value="">Choose...</option>
                      <option *ngFor="let type of (fmvModels | sortdata:'model')" [value]="type.model">
                        {{ type.model }}
                      </option>
                    </select>
                  </div>
                </div>
                
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">FMV: </label>
                  <div class="col-sm-9">
                    <select
                      (change)="fmvChange($event.target.value)"
                      class="form-control"
                      tabindex="-1"
                      aria-hidden="true"
                    >
                      <option value="">Choose...</option>
                      <option *ngFor="let type of fmvDetails | sortdata" [value]="type.fmvCode">
                        {{ makeMoney(type.fmv) }} - {{ type.modelVariant }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Body Type: </label>
                  <div class="col-sm-9">
                    <select
                      (change)="bodyTypeChange($event.target.value)"
                      formControlName="bodyType"
                      class="form-control"
                      tabindex="-1"
                      aria-hidden="true"
                    >
                      <option value="">Choose...</option>
                      <option *ngFor="let type of (bodyTypes | sortdata:'description')" [value]="type.code">
                        {{ type.description }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Sub-Body Type: </label>
                  <div class="col-sm-9">
                    <select
                      formControlName="bodySubType"
                      class="form-control"
                      tabindex="-1"
                      aria-hidden="true"
                    >
                      <option value="">Choose...</option>
                      <option *ngFor="let type of (subBodyTypes | sortdata:'description')" [value]="type.code">
                        {{ type.description }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Vehicle Type: </label>
                  <div class="col-sm-9">
                    <select
                      formControlName="vehicleType"
                      class="form-control"
                      tabindex="-1"
                      aria-hidden="true"
                    >
                      <option value="">Choose...</option>
                      <option *ngFor="let type of (vehicleTypes | sortdata:'description')" [value]="type.code">
                        {{ type.description }}
                      </option>
                    </select>
                  </div>
                </div>

              </div>
              <div class="col-sm-4 col-md-4" style="float:left">
                <h4>&nbsp;</h4>
                <hr/> 

                <!-- <app-textbox
                  [parent-form-group]="standardInsuranceFormGroup"
                  tab-index-start="0"
                  control-name="engineType"
                  [error]="standardInsuranceFormGroup?.controls.engineType.errors"
                  read-only="false"
                  no-special-char="false"
                  label="Engine Type"
                  placeholder="Engine Type"
                  max-length="50"
                >
                </app-textbox> -->

                <app-text-alpha
                  [parent-form-group]="standardInsuranceFormGroup"
                  tab-index-start="0"
                  control-name="engineType"
                  [error]="standardInsuranceFormGroup?.controls.engineType.errors"
                  read-only="false"
                  no-special-char="false"
                  alpha-numeric="false"
                  label="Engine Type"
                  placeholder="Engine Type"
                  alphaOnly
                >
                </app-text-alpha>

                <app-textbox-number-only
                  [parent-form-group]="standardInsuranceFormGroup"
                  tab-index-start="0"
                  control-name="seat"
                  [error]="standardInsuranceFormGroup?.controls.seat.errors"
                  read-only="false"
                  no-special-char="false"
                  label="Seat"
                  number-only="true"
                  placeholder="Seat"
                  max-length="2"
                >
                </app-textbox-number-only>

                <app-textbox-number-only
                  [parent-form-group]="standardInsuranceFormGroup"
                  tab-index-start="0"
                  control-name="wheels"
                  [error]="standardInsuranceFormGroup?.controls.wheels.errors"
                  read-only="false"
                  no-special-char="false"
                  label="Wheels"
                  number-only="true"
                  placeholder="Wheels"
                  max-length="2"
                >
                </app-textbox-number-only>

                <app-text-all-caps
                  [parent-form-group]="standardInsuranceFormGroup"
                  tab-index-start="0"
                  control-name="engineNo"
                  [error]="standardInsuranceFormGroup?.controls.engineNo.errors"
                  read-only="false"
                  no-special-char="false"
                  label="Engine No"
                  placeholder="Engine No"
                  max-length="30"
                >
                </app-text-all-caps>

                <app-text-all-caps
                  [parent-form-group]="standardInsuranceFormGroup"
                  tab-index-start="0"
                  control-name="chassisNo"
                  [error]="standardInsuranceFormGroup?.controls.chassisNo.errors"
                  read-only="false"
                  no-special-char="false"
                  label="Chassis No"
                  placeholder="Chassis No"
                  max-length="30"
                >
                </app-text-all-caps>
                
                <app-text-all-caps
                  [parent-form-group]="standardInsuranceFormGroup"
                  tab-index-start="0"
                  control-name="mvFileNo"
                  [error]="standardInsuranceFormGroup?.controls.mvFileNo.errors"
                  read-only="false"
                  no-special-char="false"
                  label="MV File No"
                  placeholder="MV File No"
                  max-length="30"
                >
                </app-text-all-caps>

                <app-text-all-caps
                  [parent-form-group]="standardInsuranceFormGroup"
                  tab-index-start="0"
                  control-name="vehicleColor"
                  [error]="standardInsuranceFormGroup?.controls.vehicleColor.errors"
                  read-only="false"
                  allowed-dash="true"
                  label="Vehicle Color"
                  placeholder="Vehicle Color"
                  max-length="20"
                >
                </app-text-all-caps>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Registration Type: </label>
                  <div class="col-sm-9">
                    <select
                      formControlName="registrationType"
                      class="form-control"
                      tabindex="-1"
                      aria-hidden="true"
                    >
                      <option value="">Choose...</option>
                      <option *ngFor="let type of (registrationTypes | sortdata:'description')" [value]="type.code">
                        {{ type.description }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">MV Type: </label>
                  <div class="col-sm-9">
                    <select
                    
                      formControlName="mvType"
                      class="form-control"
                      tabindex="-1"
                      aria-hidden="true"
                    >
                      <option value="">Choose...</option>
                      <option *ngFor="let type of (mvTypes | sortdata:'description')" [value]="type.code">
                        {{ type.description }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Tax Type: </label>
                  <div class="col-sm-9">
                    <select
                    
                      formControlName="taxType"
                      class="form-control"
                      tabindex="-1"
                      aria-hidden="true"
                    >
                      <option value="">Choose...</option>
                      <option *ngFor="let type of (taxTypes | sortdata:'description')" [value]="type.code">
                        {{ type.description }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Premium Type: </label>
                  <div class="col-sm-9">
                    <select
                    
                      formControlName="mvPremiumType"
                      class="form-control"
                      tabindex="-1"
                      aria-hidden="true"
                    >
                      <option value="">Choose...</option>
                      <option *ngFor="let type of (premiumTypes | sortdata:'description')" [value]="type.code">
                        {{ type.description.split("(Php")[0] }}
                      </option>
                    </select>
                  </div>
                </div>

              </div>
           
            <div class="col-sm-4 col-md-4" style="float:left" >

            <h4>Client Details</h4>
            <hr/>
              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Select Client Type: </label>
                <div class="col-sm-9">
                  <select
                    formControlName="clientType"
                    class="form-control"
                    tabindex="-1"
                    aria-hidden="true"
                  >
                    <option value="">Choose...</option>
                    <option *ngFor="let type of clientTypes" [value]="type.id">
                      {{ type.value }}
                    </option>
                  </select>
                </div>
              </div>
              
              <app-textbox
                [parent-form-group]="standardInsuranceFormGroup"
                tab-index-start="2"
                control-name="companyName"
                [error]="standardInsuranceFormGroup?.controls.companyName.errors"
                read-only="false"
                no-special-char="false"
                label="Company Name"
                placeholder="Company Name"
                max-length="105"
                *ngIf="!isIndividual"
              >
              </app-textbox>

              <div class="form-group row" *ngIf="!isIndividual">
                <label class="col-sm-3 col-form-label">Industry Type: </label>
                <div class="col-sm-9">
                  <select
                  
                  formControlName="industryType"
                  class="form-control"
                  tabindex="-1"
                  aria-hidden="true"
                >
                  <option value="">Choose...</option>
                  <option *ngFor="let type of industryTypes | sortdata" [value]="type.code">
                    {{ type.description }}
                  </option>
                </select>
                </div>
              </div>
                    
              <!-- <app-textbox
                [parent-form-group]="standardInsuranceFormGroup"
                tab-index-start="0"
                control-name="clientType"
                [error]="standardInsuranceFormGroup?.controls.clientType.errors"
                read-only="false"
                no-special-char="true"
                label="Client Type"
                placeholder="Client Type"
                max-length="1"
              >
              </app-textbox> -->


              <div class="form-group row" *ngIf="isIndividual">
                <label class="col-sm-3 col-form-label">Select Title: </label>
                <div class="col-sm-9">
                  <select
                  
                    formControlName="title"
                    class="form-control"
                    tabindex="-1"
                    aria-hidden="true"
                  >
                    <option value="">Choose...</option>
                    <option *ngFor="let type of titles | sortdata" [value]="type.code">
                      {{ type.description }}
                    </option>
                  </select>
                </div>
              </div>

              <app-text-all-caps
                *ngIf="isIndividual"
                [parent-form-group]="standardInsuranceFormGroup"
                tab-index-start="2"
                control-name="firstName"
                [error]="standardInsuranceFormGroup?.controls.firstName.errors"
                read-only="false"
                no-special-char="false"
                label="First Name"
                placeholder="First Name"
                max-length="35"
              >
              </app-text-all-caps>

              <app-text-all-caps
                *ngIf="isIndividual"
                [parent-form-group]="standardInsuranceFormGroup"
                tab-index-start="3"
                control-name="middleName"
                [error]="standardInsuranceFormGroup?.controls.middleName.errors"
                read-only="false"
                no-special-char="false"
                label="Middle Name"
                placeholder="Middle Name"
                max-length="35"
              >
              </app-text-all-caps>

              <app-text-all-caps
                *ngIf="isIndividual"
                [parent-form-group]="standardInsuranceFormGroup"
                tab-index-start="3"
                control-name="lastName"
                [error]="standardInsuranceFormGroup?.controls.lastName.errors"
                read-only="false"
                no-special-char="false"
                label="Last Name"
                placeholder="last Name"
                max-length="35"
              >
              </app-text-all-caps>

              <app-textbox-number-only
                [parent-form-group]="standardInsuranceFormGroup"
                tab-index-start="3"
                control-name="contactNo"
                [error]="standardInsuranceFormGroup?.controls.contactNo.errors"
                read-only="false"
                no-special-char="false"
                number-only="true"
                label="Contact No"
                placeholder="Contact No (ex. 09123456789)"
                max-length="11"
              >
              </app-textbox-number-only>
              
              <app-textbox
                [parent-form-group]="standardInsuranceFormGroup"
                tab-index-start="3"
                control-name="emailAddress"
                [error]="standardInsuranceFormGroup?.controls.emailAddress.errors"
                read-only="false"
                label="Email Address"
                upper-case="true"
                placeholder="Email Address"
                max-length="50"
              >
              </app-textbox>

              <app-textarea
                [parent-form-group]="standardInsuranceFormGroup"
                tab-index-start="4"
                control-name="address1"
                [error]="standardInsuranceFormGroup?.controls.address1.errors | estatus"
                read-only="false"
                allowed-dash="true"
                label="Address 1"
                placeholder="Address 1"
                max-length="100"
                upper-case="true"
              > 
              </app-textarea>

              <app-textarea
                [parent-form-group]="standardInsuranceFormGroup"
                tab-index-start="4"
                control-name="address2"
                [error]="standardInsuranceFormGroup?.controls.address2.errors | estatus"
                read-only="false"
                allowed-dash="true"
                label="Address 2"
                placeholder="Address 2"
                max-length="100"
                upper-case="true"
              >
              </app-textarea>

              <!-- <input type="text" [formControl]="myControl"/>
              <ul *ngIf="filteredOptions.length">
                <li *ngFor="let option of filteredOptions" (click)="selectOption(option)">{{ option.area }}</li>
              </ul> -->

              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Province</label>
                <div class="col-sm-9">
                  <ng-select 
                    [items]="postalRef" 
                    placeholder="Province"
                    bindLabel="PROVINCE_CITY" 
                    bindValue="PROVINCE_CITY"
                    max-length="20"
                    formControlName="province"
                    (change)="getPostal($event)"
                    (clear)="onRemoveUpline($event)">
                  </ng-select>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label">Postal Code</label>
                <div class="col-sm-9">
                  <!-- <ng-select 
                    [items]="provinceDescItems" 
                    placeholder="Select Province"
                    bindLabel="POSTAL_CODE_DESC" 
                    bindValue="POSTAL_CODE"
                    max-length="20"
                    formControlName="postalCode">
                    <ng-template ng-label-tmp let-item="item">
                      {{ item.POSTAL_CODE_DESC }} {{ item.POSTAL_CODE }}
                    </ng-template>
                  </ng-select> -->
                  <ng-select 
                    [items]="provinceDescItems" 
                    placeholder="Postal Code"
                    bindLabel="POSTAL_CODE_DESC" 
                    bindValue="POSTAL_CODE"
                    max-length="20"
                    formControlName="postalCode">
                    <ng-template ng-label-tmp let-item="item">
                      {{ item.POSTAL_CODE_DESC }} {{ item.POSTAL_CODE }}
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                      {{ item.POSTAL_CODE_DESC }} {{ item.POSTAL_CODE }}
                    </ng-template>
                  </ng-select>
                </div>
              </div>

              <!-- <app-textbox-all-caps
                [parent-form-group]="standardInsuranceFormGroup"
                tab-index-start="3"
                control-name="province"
                [error]="standardInsuranceFormGroup?.controls.province.errors"
                read-only="false"
                no-special-char="false"
                label="Province"
                placeholder="Province"
                max-length="100"
              >
              </app-textbox-all-caps> -->

              <!-- <app-textbox-number-only
                [parent-form-group]="standardInsuranceFormGroup"
                tab-index-start="3"
                control-name="postalCode"
                [error]="standardInsuranceFormGroup?.controls.postalCode.errors"
                read-only="true"
                no-special-char="false"
                label="Postal Code"
                placeholder="Postal Code"
                max-length="4"
              >
              </app-textbox-number-only> -->
            </div>
            
            <div class="col-sm-12 col-md-12"  style="float:left">
              
            </div>
          </div>
        </div>

        <div class="tab-pane" [ngClass]="activeTab == 2 ? 'active' : ''" id="newTransTab">
          <div class="form-group" *ngIf="activeTab == 2">
            <p>
              <strong>Standard Insurance Confirmation</strong><br />
              <small>Please make sure that the all details is correct.</small>
            </p>
            <div class="card">
              <div class="list list-row row">

                

                <div class="col-sm-6 col-md-6">


                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Client Type</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.clientType.value == "C" ? "Client" : "Individual" }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19" *ngIf="isIndividual">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Full Name</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.title?.value + " " + standardInsuranceFormGroup.controls.firstName?.value + " " + standardInsuranceFormGroup.controls.middleName?.value + " " + standardInsuranceFormGroup.controls.lastName?.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19" *ngIf="!isIndividual">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Company Name</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.companyName.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19" *ngIf="!isIndividual">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Industry Type</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.industryType.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Contact No</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.contactNo.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Email Address</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.emailAddress.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Address 1</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.address1.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Address 2</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.address2.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Province</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.province.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Postal Code</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.postalCode.value }}
                      </div>
                    </div>
                  </div>

                </div>

                <div class="col-sm-6 col-md-6">
                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Inception Date</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.inceptionDate.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Expiry Date</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.expiryDate.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Branch Code</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.branchCode.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Product Type</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.productCode.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Plate No</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.plateNo.value.toUpperCase() }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Year No</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.yearModel.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Manufacturer</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.manufacturer.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Make Model</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.makeModel.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Body Type</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ getBodyTypeDetail(standardInsuranceFormGroup.controls.bodyType.value) }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Sub-Body Type</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.bodySubType.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Vehicle Type</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ getVehicleTypeDetails(standardInsuranceFormGroup.controls.vehicleType.value) }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Engine Type</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.engineType.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Seat</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.seat.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Wheel</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.wheels.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Engine No</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.engineNo.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Chassis No</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.chassisNo.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">MV File No</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.mvFileNo.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Vehicle Color</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.vehicleColor.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Registration Type</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ standardInsuranceFormGroup.controls.registrationType.value == "N" ? "New" : "Renewal" }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">MV Type</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ getMVTypeDetail(standardInsuranceFormGroup.controls.mvType.value) }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Tax Type</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ getTaxTypeDetail(standardInsuranceFormGroup.controls.taxType.value) }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date font-weight-bold text-sm d-none d-md-block">Premium Type</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ getPremiumTypeDetail(standardInsuranceFormGroup.controls.mvPremiumType.value) }}
                      </div>
                    </div>
                  </div>

                </div>

                <div class="col-sm-12 col-md-12">
                  <hr />
                  <div class="col-sm-12 col-md-6" style="float: none; margin: auto">
                    <div class="item-feed h-2x text-center">ENTER PINCODE</div>
                    <code-input
                      [isCodeHidden]="true"
                      [codeLength]="6"
                      (codeChanged)="onCodeChanged($event)"
                      (codeCompleted)="onCodeCompleted($event)"
                      [code]="code"
                    >
                    </code-input>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>

        <!-- previous next -->
        <div class="row py-3">
          <div class="col-6">
            <a class="btn btn-white button-previous" (click)="setActiveTabPrev()" *ngIf="activeTab != 1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-arrow-left"
              >
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
              &nbsp; Previous
            </a>
          </div>
          <div class="col-6">
            <div class="d-flex justify-content-end">
              <a *ngIf="activeTab != 2" class="btn btn-white button-next" (click)="setActiveTab()">
                Next &nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-arrow-right"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </a>
              <a
                *ngIf="activeTab == 2 && codeStatus"
                appDebounceClick 
                [debounceTime]="500" 
                (debounceClick)="processPayment()"
                class="btn btn-info button-next"
                style="color: white"
              >
                Process Standard Insurance &nbsp;
                <span class="fa fa-hand-pointer"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
