import { Component, Input, OnInit } from '@angular/core';
import { CompanyUsersService } from 'src/data/services/web/company-users.service';

@Component({
  selector: 'app-timekeeping-logs',
  templateUrl: './timekeeping-logs.component.html',
  styleUrls: ['./timekeeping-logs.component.less']
})
export class TimekeepingLogsComponent implements OnInit {

  @Input("userId") userId: any;
  calendar;
  constructor(private companyUsersService : CompanyUsersService) {

  }

  ngOnInit(): void {
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];  

    const dateN = new Date();
    this.monthYear = monthNames[dateN.getMonth()] + ' ' + new Date().getFullYear().toString();
    this.getData(this.monthYear);

  }

  options2 = {
    outline: false
  };
  monthYear : any;
  onChangeDate(date: any) {
    this.monthYear = date.currentTarget.firstChild.innerText.replace("<\n","").replace("\n>","");
    this.getData(this.monthYear);
  }

  result : any;
  getIn(dayNumber)
  {
    try{
      if(dayNumber && this.result){ 
        return (this.result.filter(x=>x.day == dayNumber)[0]).in;
      }
      else{
        return "";
      }
    } catch(e){


    }
   
  }

  getOut(dayNumber)
  {
    try{
    if(dayNumber && this.result) { 
      if(dayNumber){ 
        return (this.result.filter(x=>x.day == dayNumber)[0]).out;
      }
    }else{
      return "";
    }
  } catch(e){


  }
 
  }

  getData(monthYear : any){
    this.companyUsersService.getUserLogs(monthYear,parseInt(this.userId)).subscribe((result) => {
      if(result){
        this.result = result;
        console.log(result)
      }
     
    },
    (error) => {
     
    });

  }
}
