import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'app-loader-pdf',
  templateUrl: './loader-pdf.component.html',
  styleUrls: ['./loader-pdf.component.less']
})
export class LoaderPdfComponent implements OnInit {

  @Input("imagePreview") imagePreview: any;

  constructor() {}

  ngOnInit(): void {}
}

