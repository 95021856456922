import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
// import { UserDetail } from "src/app/models/user-detail";
import { AccountServiceService } from "src/app/services/account-service.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";

import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: "app-account-verify",
  templateUrl: "./account-verify.component.html",
  styleUrls: ["./account-verify.component.less"],
})
export class AccountVerifyComponent implements OnInit {
  private token: string;
  public userDetail: any; // UserDetail
  public error: any;

  public loader: any = false;
  public step: any = 1;

  // Account Verify Steps

  public accountVerifyFormGroup: FormGroup = new FormGroup({
    otp: new FormControl(null, [Validators.required]),
    password: new FormControl(null, [Validators.required]),
    password_confirmation: new FormControl(null, [Validators.required]),
  });

  constructor(private route: ActivatedRoute, private router: Router, private accountService: AccountServiceService,
    private formBuilder: FormBuilder) {
      PNotifyButtons;
    //
  }

  timeLeft = 60;
  interval;

  ngOnInit(): void {
    this.loader = true;
    this.initOtpFormGroup();
    this.initPasswordFormGroup();
    this.route.queryParams.subscribe((params) => {
   
      this.token = params["token"];
      this.verifyEmail();
    });
  }

  otpFormGroup: FormGroup;
  passwordFormGroup: FormGroup;

  initOtpFormGroup(): void {
    this.otpFormGroup = new FormGroup({
      otp: new FormControl(""),
    });
  }

  initPasswordFormGroup(): void {
    this.passwordFormGroup = this.formBuilder.group({
      password: new FormControl("",Validators.required),
      passwordConfirm: new FormControl("",Validators.required)
      });

      this.passwordFormGroup.controls.password.valueChanges.subscribe((password) => {
        if(password.length == 15){
          var passElem = document.getElementById("password");
          passElem.classList.add("maxPassword");

          PNotify.error({
            title: "Password Validation",
            text: "Password must be 15 characters only.",
          });

          setTimeout(function() {
            passElem.classList.remove('maxPassword');
          }, 200);
        }
      });

      this.passwordFormGroup.controls.passwordConfirm.valueChanges.subscribe((password) => {
        if(password.length == 15){
          var passElem = document.getElementById("passwordConfirm");
          passElem.classList.add("maxPassword");

          PNotify.error({
            title: "Password Validation",
            text: "Password must be 15 characters only.",
          });

          setTimeout(function() {
            passElem.classList.remove('maxPassword');
          }, 200);
        }
      });
  }

  /**
   * Verify the user's email address via API.
   */

  public verifyEmailStatus;

  public verifyEmail(): void {
    this.accountService.verifyEmail(this.token).subscribe(
      (response: any) => {
        this.userDetail = response.data;
        this.loader = false;
        this.verifyEmailStatus = true;

        PNotify.success({
          title: "Welcome to Verification of Account",
          text: "Please finish all the steps to verify your account.",
        });

      },
      (errorResponse: any) => {
        this.loader = false;
        this.verifyEmailStatus = false;
        PNotify.error({
          title: "Reset Password",
          text: errorResponse.error.result.message,
        });
        
        this.router.navigate([`login`]); //${this.roleLevelName}
      }
    );
  }

  public sendSmsStatus;
  public resendSmsStatus;
  public resendSmsSuccessStatus;

  public sendSMSOTP(): void {
    this.loader = true;
    this.accountService.sendSMSOTP(this.token).subscribe(
      (response: any) => {
        this.loader = false;
        this.sendSmsStatus = true;
        this.step = 2;

        PNotify.success({
          title: "Mobile OTP",
          text: "Successfully send the otp to your number.",
        });


        this.interval = setInterval(() => {
          this.resendSmsStatus = false;
          if (this.timeLeft > 0) {
            this.timeLeft--;
          } else {
            clearInterval(this.interval);
            this.resendSmsStatus = true;
            this.timeLeft = 60;
          }
        }, 1000);
      },
      (errorResponse: any) => {
        
        PNotify.error({
          title: "Server Error",
          text: "Unable to send OTP at this time. Please try again later.",
        });

        this.loader = false;
        this.sendSmsStatus = false;
        this.step = 1;
      }
    );
  }

  public verifySMSStatus;

  /**
   * Verify the user's SMS via API.
   */
  public verifySMS(): void {


  if(this.token,this.otpFormGroup.controls.otp.value == ""){
    PNotify.error({
      title: "Mobile OTP",
      text: "Mobile OTP field is required",
    });
  }else{
    this.loader = true;
    this.accountService.verifySMS(this.token, this.otpFormGroup.controls.otp.value).subscribe(
      (response: any) => {
        this.loader = false;
        this.verifySMSStatus = true;
        this.step = 3;

        PNotify.success({
          title: "Mobile OTP",
          text: "Mobile OTP successfully verified.",
        });

      },
      (errorResponse: any) => {
        this.loader = false;
        this.verifySMSStatus = false;
        this.step = 2;

        PNotify.error({
          title: "Mobile OTP",
          text: errorResponse.error.result.message,
        });
      }
    );
  }

}

    

  /**
   * Submit the user's password via API.
   */

public resetPasswordStatus;

  public resetPassword(): void {
    this.loader = true;
if(this.passwordFormGroup.status != "VALID"){
  this.resetPasswordStatus = false;
  this.loader = false;
  
  PNotify.error({
    title: "Creation of Password",
    text: "All fields are required.",
  });
}
else if(this.passwordFormGroup.controls.password.value != this.passwordFormGroup.controls.passwordConfirm.value){
  this.resetPasswordStatus = false;
  this.loader = false;
  PNotify.error({
    title: "Creation of Password",
    text: "Password did not match.",
  });
}else{

  this.loader = true;
  this.accountService.resetPassword(this.token,this.passwordFormGroup.controls.passwordConfirm.value).subscribe(
    (response: any) => {
      
      this.loader = false;
      this.resetPasswordStatus = true;
      this.step = 4;
      PNotify.success({
        title: "Creation of Password",
        text: "Congratulations! Your account has been verified successfully. Please proceed to login.",
      });
    },
    (errorResponse: any) => {
      this.loader = false;
      PNotify.error({
        title: "Creation of Password",
        text: errorResponse.error.result.message,
      });

          this.loader = false;
          this.resetPasswordStatus = false;
          this.step = 3;
        }
      );
    }
  }
}
