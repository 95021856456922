import { FormGroup } from "@angular/forms";
import { Input } from "@angular/core";
import { Component } from "@angular/core";

@Component({
  selector: "app-schedule-ticker",
  templateUrl: "./schedule-ticker.component.html",
  styleUrls: ["./schedule-ticker.component.less"],
})
export class ScheduleTickerComponent {
  @Input("parent-form-group") parentFormGroup: FormGroup;
  @Input("control-name") controlName: string;
  @Input("disabled") disabled: boolean;

  constructor() {}

  ngOnInit(): void {}
}
