<!-- <app-loader *ngIf="!accounts || loader"></app-loader> -->
<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div id="page-hero">
        <div class="padding d-flex">
          <div class="page-title">
            <h2 class="--spam">JuanPay Store | <span class="color-blue fonr-weight-bold">Purchase Order</span></h2>
            <!-- <small class="--desc">GoVIPCenter list of Stock Item</small> -->
          </div>
          <div class="flex"></div>
        </div>
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="">
        <div class="card pb-0 pt-0 mb-0 mt-0">
          <div class="b-b">
            <div class="nav-active-border b-primary bottom">
              <ul class="nav" id="myTab" role="tablist">
                <li class="nav-item col-2 pt-3 pr-3 pl-3">
                  <a
                    class="text-center pl-0 pr-0 nav-link"
                    [ngClass]="{ active: activeTab == 1 }"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile3"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    <i class="fa fa-credit-card"></i> &nbsp; To Pay
                    <span class="badge badge-danger ml-2" *ngIf="toPay.length > 0">{{ toPay.length }}</span>
                  </a>
                </li>
                <li class="nav-item col-2 pt-3 pr-3 pl-3">
                  <a
                    class="text-center pl-0 pr-0 nav-link"
                    [ngClass]="{ active: activeTab == 2 }"
                    id="contact-tab"
                    data-toggle="tab"
                    href="#contact3"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    <i class="fa fa-ship"></i> &nbsp; To Ship
                    <span class="badge badge-danger ml-2" *ngIf="toShip.length > 0">{{ toShip.length }}</span>
                  </a>
                </li>
                <li class="nav-item col-2 pt-3 pr-3 pl-3">
                  <a
                    class="text-center pl-0 pr-0 nav-link"
                    [ngClass]="{ active: activeTab == 3 }"
                    id="about-tab"
                    data-toggle="tab"
                    href="#about3"
                    role="tab"
                    aria-controls="about"
                    aria-selected="false"
                  >
                    <i class="fa fa-truck"></i> &nbsp; To Receive
                    <span class="badge badge-danger ml-2" *ngIf="toReceive.length > 0">{{ toReceive.length }}</span>
                  </a>
                </li>
                <li class="nav-item col-2 pt-3 pr-3 pl-3">
                  <a
                    class="text-center pl-0 pr-0 nav-link"
                    [ngClass]="{ active: activeTab == 4 }"
                    id="services-tab"
                    data-toggle="tab"
                    href="#services3"
                    role="tab"
                    aria-controls="services"
                    aria-selected="false"
                  >
                    <i class="fa fa-list-alt"></i> &nbsp; Completed
                    <!-- <span class="badge badge-danger ml-2" *ngIf="completed.length > 0">{{ completed.length }}</span> -->
                  </a>
                </li>
                <li class="nav-item col-2 pt-3 pr-3 pl-3">
                  <a
                    class="text-center pl-0 pr-0 nav-link"
                    id="test-tab"
                    data-toggle="tab"
                    href="#test3"
                    role="tab"
                    aria-controls="test"
                    aria-selected="false"
                  >
                    <i class="fa fa-ban"></i> &nbsp; Cancelled
                    <!-- <span class="badge badge-danger ml-2" *ngIf="cancelled.length > 0">{{ cancelled.length }}</span> -->
                  </a>
                </li>
                <li class="nav-item col-2 pt-3 pr-3 pl-3">
                  <a
                    class="text-center pl-0 pr-0 nav-link"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home3"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    <i class="fa fa-reply-all"></i> &nbsp; Return
                    <!-- <span class="badge badge-danger ml-2" *ngIf="returnPO.length > 0">{{ returnPO.length }}</span> -->
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="tab-content p-3" style="background-color: rgb(247, 247, 247)">
            <!-- Start Tab-->

            <!-- To Pay -->
            <div
              class="tab-pane fade"
              [ngClass]="{ active: activeTab == 1, show: activeTab == 1 }"
              id="profile3"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div *ngIf="toPay.length > 0">
                <div class="card p-2 container-card" (click)="openPO(openPOview, po)" *ngFor="let po of toPay">
                  <div class="d-flex">
                    <div>
                      <span class="font-weight-bold text-muted">Purchase Order No:</span> &nbsp;
                      <span class="font-weight-bold color-blue">{{ po.pono }}</span>
                    </div>
                    <div class="flex"></div>
                    <div>
                      |
                      <span class="text-muted font-weight-bold mr-2">{{
                        po.detailedStatus != null ? po.detailedStatus : "Order Preparing"
                      }}</span>
                    </div>
                  </div>
                  <hr class="ml-0 mr-0 mt-1 mb-2 p-0" />
                  <div class="d-flex">
                    <div class="d-flex justify-content-center align-items-center ml-2 mr-3">
                      <div tooltip="Created Date">
                        <p class="text-muted mb-0" style="font-size: 12px">{{ po.createdDate | date: "MMM" }}</p>
                        <p class="mb-0 mt-0 text-highlight font-weight-bold" style="font-size: 16px">
                          {{ po.createdDate | date: "dd" }}
                        </p>
                        <p class="text-muted mt-0" style="font-size: 12px">{{ po.createdDate | date: "yyyy" }}</p>
                      </div>
                    </div>
                    <div>
                      <img class="item-img" [src]="po.purchaseOrderItems[0].storeItem.storeItemImages[0].path" />
                    </div>
                    <div class="d-flex justify-content-center align-items-center">
                      <div>
                        <h5>{{ po.purchaseOrderItems[0].storeItem.name }}</h5>
                        <p class="text-muted description m-0 p-0">
                          {{ po.purchaseOrderItems[0].storeItem.description }}
                        </p>
                      </div>
                    </div>
                    <div class="flex"></div>
                    <div class="d-flex justify-content-center align-items-center mr-5">
                      <p class="text-muted m-0 p-0">
                        Total Quantity: <span class="color-blue font-weight-bolder">{{ po.totalQuantity }}</span>
                      </p>
                    </div>
                    <div class="d-flex justify-content-center align-items-center ml-5 mr-2">
                      <span class="color-red font-weight-bolder">₱ {{ makeMoney(po.totalAmount) }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <ng-template [ngTemplateOutlet]="noOrdertemplate" *ngIf="toPay.length == 0"></ng-template>
            </div>

            <!-- To Ship -->
            <div
              class="tab-pane fade"
              [ngClass]="{ active: activeTab == 2, show: activeTab == 2 }"
              id="contact3"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <div *ngIf="toShip.length > 0">
                <div class="card p-2 container-card" (click)="openPO(openPOview, po)" *ngFor="let po of toShip">
                  <div class="d-flex">
                    <div>
                      <span class="font-weight-bold text-muted">Purchase Order No:</span> &nbsp;
                      <span class="font-weight-bold color-blue">{{ po.pono }}</span>
                    </div>
                    <div class="flex"></div>
                    <div>
                      |
                      <span class="text-muted font-weight-bold mr-2">{{
                        po.detailedStatus != null ? po.detailedStatus : "Order Preparing"
                      }}</span>
                    </div>
                  </div>
                  <hr class="ml-0 mr-0 mt-1 mb-2 p-0" />
                  <div class="d-flex">
                    <div class="d-flex justify-content-center align-items-center ml-2 mr-3">
                      <div tooltip="Order Placed Date">
                        <p class="text-muted mb-0" style="font-size: 12px">{{ po.placedDate | date: "MMM" }}</p>
                        <p class="mb-0 mt-0 text-highlight font-weight-bold" style="font-size: 16px">
                          {{ po.placedDate | date: "dd" }}
                        </p>
                        <p class="text-muted mt-0" style="font-size: 12px">{{ po.placedDate | date: "yyyy" }}</p>
                      </div>
                    </div>
                    <div>
                      <img class="item-img" [src]="po.purchaseOrderItems[0].storeItem.storeItemImages[0].path" />
                    </div>
                    <div class="d-flex justify-content-center align-items-center">
                      <div>
                        <h5>{{ po.purchaseOrderItems[0].storeItem.name }}</h5>
                        <p class="text-muted description m-0 p-0">
                          {{ po.purchaseOrderItems[0].storeItem.description }}
                        </p>
                      </div>
                    </div>
                    <div class="flex"></div>
                    <div class="d-flex justify-content-center align-items-center mr-5">
                      <p class="text-muted m-0 p-0">
                        Total Quantity: <span class="color-blue font-weight-bolder">{{ po.totalQuantity }}</span>
                      </p>
                    </div>
                    <div class="d-flex justify-content-center align-items-center ml-5 mr-2">
                      <span class="color-red font-weight-bolder">₱ {{ makeMoney(po.totalAmount) }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <ng-template [ngTemplateOutlet]="noOrdertemplate" *ngIf="toShip.length == 0"></ng-template>
            </div>

            <!-- To Receive -->
            <div
              class="tab-pane fade"
              [ngClass]="{ active: activeTab == 3, show: activeTab == 3 }"
              id="about3"
              role="tabpanel"
              aria-labelledby="about-tab"
            >
              <div *ngIf="toReceive.length > 0">
                <div class="card p-2 container-card" (click)="openPO(openPOview, po)" *ngFor="let po of toReceive">
                  <div class="d-flex">
                    <div>
                      <span class="font-weight-bold text-muted">Purchase Order No:</span> &nbsp;
                      <span class="font-weight-bold color-blue">{{ po.pono }}</span>
                    </div>
                    <div class="flex"></div>
                    <div>
                      |
                      <span class="text-muted font-weight-bold mr-2">{{
                        po.detailedStatus != null ? po.detailedStatus : "Order Preparing"
                      }}</span>
                    </div>
                  </div>
                  <hr class="ml-0 mr-0 mt-1 mb-2 p-0" />
                  <div class="d-flex">
                    <div class="d-flex justify-content-center align-items-center ml-2 mr-3">
                      <div tooltip="Delivery Date">
                        <p class="text-muted mb-0" style="font-size: 12px">{{ po.drDate | date: "MMM" }}</p>
                        <p class="mb-0 mt-0 text-highlight font-weight-bold" style="font-size: 16px">
                          {{ po.drDate | date: "dd" }}
                        </p>
                        <p class="text-muted mt-0" style="font-size: 12px">{{ po.drDate | date: "yyyy" }}</p>
                      </div>
                    </div>
                    <div>
                      <img class="item-img" [src]="po.purchaseOrderItems[0].storeItem.storeItemImages[0].path" />
                    </div>
                    <div class="d-flex justify-content-center align-items-center">
                      <div>
                        <h5>{{ po.purchaseOrderItems[0].storeItem.name }}</h5>
                        <p class="text-muted description m-0 p-0">
                          {{ po.purchaseOrderItems[0].storeItem.description }}
                        </p>
                      </div>
                    </div>
                    <div class="flex"></div>
                    <div class="d-flex justify-content-center align-items-center mr-5">
                      <p class="text-muted m-0 p-0">
                        Total Quantity: <span class="color-blue font-weight-bolder">{{ po.totalQuantity }}</span>
                      </p>
                    </div>
                    <div class="d-flex justify-content-center align-items-center ml-5 mr-2">
                      <span class="color-red font-weight-bolder">₱ {{ makeMoney(po.totalAmount) }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <ng-template [ngTemplateOutlet]="noOrdertemplate" *ngIf="toReceive.length == 0"></ng-template>
            </div>

            <!-- Completed -->
            <div
              class="tab-pane fade"
              [ngClass]="{ active: activeTab == 4, show: activeTab == 4 }"
              id="services3"
              role="tabpanel"
              aria-labelledby="services-tab"
            >
              <div *ngIf="completed.length > 0">
                <div class="card p-2 container-card" (click)="openPO(openPOview, po)" *ngFor="let po of completed">
                  <div class="d-flex">
                    <div>
                      <span class="font-weight-bold text-muted">Purchase Order No:</span> &nbsp;
                      <span class="font-weight-bold color-blue">{{ po.pono }}</span>
                    </div>
                    <div class="flex"></div>
                    <div>
                      |
                      <span class="text-muted font-weight-bold mr-2">{{
                        po.detailedStatus != null ? po.detailedStatus : "Order Preparing"
                      }}</span>
                    </div>
                  </div>
                  <hr class="ml-0 mr-0 mt-1 mb-2 p-0" />
                  <div class="d-flex">
                    <div class="d-flex justify-content-center align-items-center ml-2 mr-3">
                      <div tooltip="Completed Date">
                        <p class="text-muted mb-0" style="font-size: 12px">{{ po.receivedDate | date: "MMM" }}</p>
                        <p class="mb-0 mt-0 text-highlight font-weight-bold" style="font-size: 16px">
                          {{ po.receivedDate | date: "dd" }}
                        </p>
                        <p class="text-muted mt-0" style="font-size: 12px">{{ po.receivedDate | date: "yyyy" }}</p>
                      </div>
                    </div>
                    <div>
                      <img class="item-img" [src]="po.purchaseOrderItems[0].storeItem.storeItemImages[0].path" />
                    </div>
                    <div class="d-flex justify-content-center align-items-center">
                      <div>
                        <h5>{{ po.purchaseOrderItems[0].storeItem.name }}</h5>
                        <p class="text-muted description m-0 p-0">
                          {{ po.purchaseOrderItems[0].storeItem.description }}
                        </p>
                      </div>
                    </div>
                    <div class="flex"></div>
                    <div class="d-flex justify-content-center align-items-center mr-5">
                      <p class="text-muted m-0 p-0">
                        Total Quantity: <span class="color-blue font-weight-bolder">{{ po.totalQuantity }}</span>
                      </p>
                    </div>
                    <div class="d-flex justify-content-center align-items-center ml-5 mr-2">
                      <span class="color-red font-weight-bolder">₱ {{ makeMoney(po.totalAmount) }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <ng-template [ngTemplateOutlet]="noOrdertemplate" *ngIf="completed.length == 0"></ng-template>
            </div>

            <!-- Cancelled -->
            <div class="tab-pane fade" id="test3" role="tabpanel" aria-labelledby="test-tab">
              <div *ngIf="cancelled.length > 0">
                <div class="card p-2 container-card" (click)="openPO(openPOview, po)" *ngFor="let po of cancelled">
                  <div class="d-flex">
                    <div>
                      <span class="font-weight-bold text-muted">Purchase Order No:</span> &nbsp;
                      <span class="font-weight-bold color-blue">{{ po.pono }}</span>
                    </div>
                    <div class="flex"></div>
                    <div>
                      |
                      <span class="text-muted font-weight-bold mr-2">{{
                        po.detailedStatus != null ? po.detailedStatus : "Order Preparing"
                      }}</span>
                    </div>
                  </div>
                  <hr class="ml-0 mr-0 mt-1 mb-2 p-0" />
                  <div class="d-flex">
                    <div class="d-flex justify-content-center align-items-center ml-2 mr-3">
                      <div tooltip="Cancelled Date">
                        <p class="text-muted mb-0" style="font-size: 12px">{{ po.cancelledDate | date: "MMM" }}</p>
                        <p class="mb-0 mt-0 text-highlight font-weight-bold" style="font-size: 16px">
                          {{ po.cancelledDate | date: "dd" }}
                        </p>
                        <p class="text-muted mt-0" style="font-size: 12px">{{ po.cancelledDate | date: "yyyy" }}</p>
                      </div>
                    </div>
                    <div>
                      <img class="item-img" [src]="po.purchaseOrderItems[0].storeItem.storeItemImages[0].path" />
                    </div>
                    <div class="d-flex justify-content-center align-items-center">
                      <div>
                        <h5>{{ po.purchaseOrderItems[0].storeItem.name }}</h5>
                        <p class="text-muted description m-0 p-0">
                          {{ po.purchaseOrderItems[0].storeItem.description }}
                        </p>
                      </div>
                    </div>
                    <div class="flex"></div>
                    <div class="d-flex justify-content-center align-items-center mr-5">
                      <p class="text-muted m-0 p-0">
                        Total Quantity: <span class="color-blue font-weight-bolder">{{ po.totalQuantity }}</span>
                      </p>
                    </div>
                    <div class="d-flex justify-content-center align-items-center ml-5 mr-2">
                      <span class="color-red font-weight-bolder">₱ {{ makeMoney(po.totalAmount) }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <ng-template [ngTemplateOutlet]="noOrdertemplate" *ngIf="cancelled.length == 0"></ng-template>
            </div>

            <!-- Return -->
            <div class="tab-pane fade" id="home3" role="tabpanel" aria-labelledby="home-tab">
              <div *ngIf="returnPO.length > 0">
                <div class="card p-2 container-card" (click)="openPO(openPOview, po)" *ngFor="let po of returnPO">
                  <div class="d-flex">
                    <div>
                      <span class="font-weight-bold text-muted">Purchase Order No:</span> &nbsp;
                      <span class="font-weight-bold color-blue">{{ po.pono }}</span>
                    </div>
                    <div class="flex"></div>
                    <div>
                      |
                      <span class="text-muted font-weight-bold mr-2">{{
                        po.detailedStatus != null ? po.detailedStatus : "Order Preparing"
                      }}</span>
                    </div>
                  </div>
                  <hr class="ml-0 mr-0 mt-1 mb-2 p-0" />
                  <div class="d-flex">
                    <div class="d-flex justify-content-center align-items-center ml-2 mr-3">
                      <div tooltip="Return Date">
                        <p class="text-muted mb-0" style="font-size: 12px">{{ po?.returnDate | date: "MMM" }}</p>
                        <p class="mb-0 mt-0 text-highlight font-weight-bold" style="font-size: 16px">
                          {{ po?.returnDate | date: "dd" }}
                        </p>
                        <p class="text-muted mt-0" style="font-size: 12px">{{ po?.returnDate | date: "yyyy" }}</p>
                      </div>
                    </div>
                    <div>
                      <img class="item-img" [src]="po.purchaseOrderItems[0].storeItem.storeItemImages[0].path" />
                    </div>
                    <div class="d-flex justify-content-center align-items-center">
                      <div>
                        <h5>{{ po.purchaseOrderItems[0].storeItem.name }}</h5>
                        <p class="text-muted description m-0 p-0">
                          {{ po.purchaseOrderItems[0].storeItem.description }}
                        </p>
                      </div>
                    </div>
                    <div class="flex"></div>
                    <div class="d-flex justify-content-center align-items-center mr-5">
                      <p class="text-muted m-0 p-0">
                        Total Quantity: <span class="color-blue font-weight-bolder">{{ po.totalQuantity }}</span>
                      </p>
                    </div>
                    <div class="d-flex justify-content-center align-items-center ml-5 mr-2">
                      <span class="color-red font-weight-bolder">₱ {{ makeMoney(po.totalAmount) }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <ng-template [ngTemplateOutlet]="noOrdertemplate" *ngIf="returnPO.length == 0"></ng-template>
            </div>

            <!-- End Tab-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<ng-template #addCateogryModal let-modal>
  <div *ngIf="categoryFormGroup">
    <div class="modal-header">
      <h6 class="modal-title">Add Category</h6>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <app-textbox
        [parent-form-group]="categoryFormGroup"
        tab-index-start="0"
        control-name="category"
        [error]="categoryFormGroup?.controls.category?.errors | estatus"
        [error-message]="categoryFormGroup?.controls.category?.errors | econtent"
        [read-only]="false"
        label="Category"
        placeholder="Category"
      ></app-textbox>

      <hr />

      <div>
        <!-- <button class="btn btn-sm btn-primary float-right" (click)="proceedAddManual()">Add Employee</button>
          <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
            Cancel
          </button> -->
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addUoMModal let-modal>
  <div *ngIf="uomFormGroup">
    <div class="modal-header">
      <h6 class="modal-title">Add Unit of Measure</h6>
      <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <app-textbox
        [parent-form-group]="uomFormGroup"
        tab-index-start="0"
        control-name="unit"
        [error]="uomFormGroup?.controls.unit?.errors | estatus"
        [error-message]="uomFormGroup?.controls.unit?.errors | econtent"
        [read-only]="false"
        label="Unit"
        placeholder="Unit"
      ></app-textbox>

      <app-textbox
        [parent-form-group]="uomFormGroup"
        tab-index-start="0"
        control-name="abrUnit"
        [error]="uomFormGroup?.controls.abrUnit?.errors | estatus"
        [error-message]="uomFormGroup?.controls.abrUnit?.errors | econtent"
        [read-only]="false"
        label="Short Unit"
        placeholder="Short Unit"
      ></app-textbox>
      <hr />

      <div>
        <!-- <button class="btn btn-sm btn-primary float-right" (click)="proceedAddManual()">Add Employee</button>
          <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
            Cancel
          </button> -->
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noOrdertemplate>
  <div class="card p-2 container-card d-flex justify-content-center align-items-center" style="height: 475px">
    <div class="text-center">
      <img src="../../../../../assets/vips2/no-orders_2x.png" style="height: 120px; width: 160px" />
      <h5>No Orders Yet</h5>
    </div>
  </div>
</ng-template>

<ng-template #openPOview>
  <div class="modal-header">
    <h6 class="modal-title">
      <span class="text-muted font-weight-bold">Purchase Order No: </span>
      <span class="color-blue font-weight-bolder">PO00012345</span>
    </h6>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div>
    <app-purchase-order-view [purchaseOrder]="selectedPO"></app-purchase-order-view>
  </div>
</ng-template>
