import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/data/services/web/user.service';

@Component({
  selector: 'app-realtime-message',
  templateUrl: './realtime-message.component.html',
  styleUrls: ['./realtime-message.component.less']
})
export class RealtimeMessageComponent implements OnInit {

  @ViewChild('sendMessageModal') sendMessageModal: ElementRef;
  messageFormGroup : FormGroup;
  timeToAddFormGroup : FormGroup;
  formBuilder : FormBuilder;
  emptyString : any = '';
  loader : any = true;
  timeData;
  constructor(  private usersService: UserService,
    private datePipe : DatePipe,
    private modService: NgbModal,
//    private chatService: ChatService,
    formBuilder : FormBuilder) {
      this.formBuilder = formBuilder;
     }

  ngOnInit(): void {
    this.getMessages();
    this.initTimeToAdd();
  }


  initTimeToAdd(){
    this.timeToAddFormGroup = new FormGroup({
      time: new FormControl(this.emptyString),
    });
  }

  initMessageFormGroup(){
    this.messageFormGroup = new FormGroup({
      id: new FormControl(this.emptyString),
      message: new FormControl(this.emptyString),
      startDate: new FormControl(this.emptyString),
      endDate: new FormControl(this.emptyString),
      status: new FormControl(true),
      times :   this.formBuilder.array([]),
    });
  }

  initMessageWithDataFormGroup(selectedRealtimeMessage : any){
    this.messageFormGroup = new FormGroup({
      id: new FormControl(selectedRealtimeMessage.id),
      message: new FormControl(selectedRealtimeMessage.message),
      startDate: new FormControl(selectedRealtimeMessage.startDate),
      endDate: new FormControl(selectedRealtimeMessage.endDate),
      status: new FormControl(selectedRealtimeMessage.status),
      times :   this.formBuilder.array([]),
    });

    var times = JSON.parse(selectedRealtimeMessage.times);
    times.forEach(time => {
      //console.log(this.datePipe.transform('01/01/2001 ' + time.time));
      this.addTimeWithDataValuesV2(time.time);
    });


    console.log(this.messageFormGroup.value);
  }

addTimeWithDataValues(): void {
  var timex = this.datePipe.transform('2021-01-01 ' + this.timeToAddFormGroup.controls.time.value, 'hh:mm a');
    const messageValuesFormGroup = this.formBuilder.group({
      time: this.formBuilder.control(timex)
    });
    (this.messageFormGroup.controls.times as FormArray).push(messageValuesFormGroup);
    this.timeToAddFormGroup.controls.time.setValue(this.emptyString);
  }
  
  addTimeWithDataValuesV2(time): void {
      const messageValuesFormGroup = this.formBuilder.group({
        time: this.formBuilder.control(time)
      });
      (this.messageFormGroup.controls.times as FormArray).push(messageValuesFormGroup);
    }



  deleteTimeValues(webTimeIndex: number): void {
    (this.messageFormGroup.controls.times as FormArray).removeAt(webTimeIndex);
  }

 
  postMessage() {
    if(confirm(`Are you sure you want to send the message to all online users?.`)) {
      this.loader = true;
      console.log(this.messageFormGroup.value);
        this.usersService.realtimeMessage(this.messageFormGroup.value).subscribe((result) => {
        //  this.chatService.sendMessage(this.messageFormGroup.controls.message.value);
          this.getMessages();
        });
    }
  }

  selectedRealtimeMessage;
  
  getRealtimeMessageSingle(id) {
    this.loader = true;
    this.initTimeToAdd();
    this.usersService.getRealtimeMessageSingle(id).subscribe((result) => {
      this.selectedRealtimeMessage = result;
      this.initMessageWithDataFormGroup(this.selectedRealtimeMessage);
      this.openSendMessage(this.sendMessageModal);
      this.loader = false;
    });
  }

  messages : any;
  
  getMessages() {
    this.usersService.getRealtimeMessage().subscribe((result) => {
      this.initMessageFormGroup();
      this.messages = result;
      this.loader = false;
    });
    if(this.modalMessageTransaction){
      this.closeModal();
    }
  }

  modalMessageTransaction;
  mobileNo;
  openSendMessage(messageModal) {
    this.modalMessageTransaction = this.modService.open(messageModal, {
      centered: true,
      size: "xl",
      backdrop: "static",
      keyboard: false,
    });
  }

  openSendMessageCreate(messageModal) {
    this.initMessageFormGroup();
    this.selectedRealtimeMessage = null;
    this.modalMessageTransaction = this.modService.open(messageModal, {
      centered: true,
      size: "xl",
      backdrop: "static",
      keyboard: false,
    });
  }

  closeModal(){
    this.modalMessageTransaction.close();
  }

  convertToJSON(strData){
    return JSON.parse(strData);
  }
}
