<app-loader *ngIf="verifyingLogin"></app-loader>
<app-loader *ngIf="loader"></app-loader>

<body class="layout-row">
  <div class="d-flex flex-column flex">
    <div class="row no-gutters h-100">
      <div
        class="col-md-6 bg-primary"
        style="background-image: url(../../../assets/images/banner/B3.jpg); background-size: cover"
      >
        <div class="p-3 p-md-5 d-flex flex-column h-100">
          <!-- <h4 class="mb-3 text-white">GO VIP Center</h4>
          <div class="text-fade">The Business of the Current</div> -->
          <div class="d-flex flex align-items-center justify-content-center"></div>

          <div class="d-flex text-sm text-fade">
            <a routerLink="/about" class="text-white">About</a>
            <span class="flex"></span>
            <a href="https://govipcenter.com/govip-privacy-policy" class="text-white mx-1">Terms and Policies</a>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div id="content-body">
          <div class="p-3 p-md-5">
            <h5>Welcome</h5>
            <p>
              <small class="text-muted">Login to manage your account</small>
            </p>
            <div [formGroup]="userFormGroup" class="" role="form">
              <div class="form-group">
                <label>Email</label>
                <input
                  type="email"
                  (keyup.enter)="verifyLogin()"
                  formControlName="email"
                  class="form-control"
                  placeholder="Enter email"
                  autofocus
                />
              </div>

              <div class="form-group">
                <label>Password</label>
                <input
                  type="password"
                  (keyup.enter)="verifyLogin()"
                  formControlName="password"
                  class="form-control"
                  placeholder="Password"
                />
                <!-- <div class="my-3 text-right">
                  <a href="forgot-password" class="text-muted">Forgot password?</a>
                </div> -->
              </div>
              <div class="checkbox mb-3">
                <label class="ui-check">
                  <input type="checkbox" />
                  <i></i> Remember me
                </label>
              </div>
              <button type="button" (click)="verifyLogin()" class="btn btn-primary mb-4">Sign in</button>
            </div>
          </div>
        </div>

        <!-- <div>
                Do not have an account?
                <a routerLink="/register" class="text-primary">Sign up</a>
              </div> -->
      </div>
    </div>
  </div>
</body>

<ng-template #pinModal let-modal>
  <div class="bg-dark">
    <div class="modal-header justify-content-center">
      <img src="https://i.ibb.co/vsQZ3MC/Go-VIP-Logo.png" height="29.12" width="59.68" alt="Go-VIP-Logo" />
      <h4 class="modal-title ml-5">ENTER SECURITY PINCODE</h4>
      <!-- <img src="https://i.ibb.co/vsQZ3MC/Go-VIP-Logo.png" height="101.92" width="208.88" alt="Go-VIP-Logo" /> -->
      <button type="button" class="close" aria-label="Close" (click)="closeTransaction()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <app-pin (doLoginPin)="doLoginPin($event)"></app-pin>
  <div class="bg-secondary">
    <button class="btn btn-xs btn-secondary float-right" (click)="confirmChangePIN()">Forgot Pin?</button>
  </div>
</ng-template>

<ng-template #confirmChangePINModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Forgot your PIN?</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeTransaction()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <h5>Click Proceed to reset your PIN.</h5>
    <button class="btn btn-sm btn-info float-right" (click)="proceedToChangePIN()">Proceed</button>
    <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
      Cancel
    </button>
  </div>
  
</ng-template>

<ng-template #pinEnrollModal let-modal>
  <!-- <div class="modal-header">
    <h4 class="modal-title"></h4>
    <button type="button" class="close" aria-label="Close" (click)="closeTransaction()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> -->
  <div class="bg-dark">
    <div class="modal-header justify-content-center">
      <img src="https://i.ibb.co/vsQZ3MC/Go-VIP-Logo.png" height="29.12" width="59.68" alt="Go-VIP-Logo" />
      <h4 class="modal-title ml-5">ENROLL SECURITY PINCODE</h4>
      <!-- <img src="https://i.ibb.co/vsQZ3MC/Go-VIP-Logo.png" height="101.92" width="208.88" alt="Go-VIP-Logo" /> -->
      <button type="button" class="close" aria-label="Close" (click)="closeTransaction()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <app-pin-enroll
    [email]="userFormGroup.controls.email.value"
    [password]="userFormGroup.controls.password.value"
    (showLoginPin)="showLoginPin()"
  ></app-pin-enroll>
</ng-template>


<div id="chatButton"></div>