<div class="container-fluid --home">
    <div class="row">
        <div class="col-12">
            <div class="header mt-3">
                <div class="text">
                    <img src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file260fa82a-0150-4c07-a413-e289dffd98fb.png" width="65px" height="65px" alt=""> 
                    VIPS TUTORIALS
                </div>
            </div>
        </div>
        <!-- <div class="col-lg-5 col-md-12 col-sm-12 pl-5 pr-4" style="max-height: 316px;">
          <div class="box p-0 m-0">
            <app-widget-basic-profile></app-widget-basic-profile>
        </div>
        </div> -->
        <div class="col-lg-12 col-md-12 col-sm-12 pl-4 pr-5" style="max-height: calc(100vh - 260px);">
            <div class="row box mt-3">
                <div class="col-12 pt-2">
                    <div class="mb-3" style="max-height: calc(100vh - 255px); overflow-y: scroll; overflow-x: hidden;" *ngFor="let video of videoTutorials">
                        <div class="row">
                            <div class="col-6">
                                <div *ngFor="let meta of video.value; let i = index" >
                                    <div *ngIf="i % 2 == 0" class="col-lg-12 pl-0 pr-0 ml-0 mr-0"  >
                                        <div class="card-header d-flex align-items-center">
                                            <a >
                                                <img src="../../../../assets/icons/yt.png" class="avatar w-40 img-responsive">
                                            </a>
                                            <div class="ml-3 d-flex align-items-center">
                                                <a class="font-weight-bold" style="font-size: 16px;">{{meta.title}}</a>
                                            </div>
                                        </div>
                                        <div class="">
                                            <div class="card-text mb-3 ml-5">
                                                <p>{{meta.description}} </p>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="d-flex justify-content-center">
                                                        <iframe class="videos"  [src]="meta.url" width="600" height="315" frameborder="0" webkitallowfullscreen mozallowfullscreen
                                                        allowfullscreen></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div *ngFor="let meta of video.value; let i = index" >
                                    <div *ngIf="i % 2 != 0" class="col-lg-12 pl-0 pr-0 ml-0 mr-0"  >
                                        <div class="card-header d-flex align-items-center">
                                            <a >
                                                <img src="../../../../assets/icons/yt.png" class="avatar w-40 img-responsive">
                                            </a>
                                            <div class="ml-3 d-flex align-items-center">
                                                <a class="font-weight-bold" style="font-size: 16px;">{{meta.title}}</a>
                                            </div>
                                        </div>
                                        <div class="">
                                            <div class="card-text mb-3 ml-5">
                                                <p>{{meta.description}} </p>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="d-flex justify-content-center">
                                                        <iframe class="videos"  [src]="meta.url" width="600" height="315" frameborder="0" webkitallowfullscreen mozallowfullscreen
                                                        allowfullscreen></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                           
                        
                    </div>
                    
                </div>
            </div>
        </div>
        <!-- <div class="col-lg-12 col-md-12 col-sm-12 pl-5 pr-5 mt-3">
            

        </div> -->
    </div>
</div>