import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';

import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { CurrencyPipe } from '@angular/common';
import { PrintService } from 'src/data/services/web/print.service';
import { ServiceChargeService } from 'src/data/services/web/service-charge.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-service-charge',
  templateUrl: './service-charge.component.html',
  styleUrls: ['./service-charge.component.less']
})
export class ServiceChargeComponent implements OnInit {

  @Input("application") application: any;
  serviceChargeFormGroup : FormGroup;
  formBuilder : FormBuilder;
  public emptyString = "";
  public billers : any = [];
  public biller : any;
  public billerCode : any;
  public billerTypes : any = [];
  public billersByBillerTypes : any = [];
  public selectedBillerCode : any;

  public activeTab = 1;
  public loader = true;
  public fee : any;

  
  @Output("closeModal") closeModal = new EventEmitter();
  

  constructor(
    private serviceChargeService : ServiceChargeService,
    private router: Router,
    localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    formBuilder : FormBuilder) {
    this.formBuilder = formBuilder;
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.initServiceChargeFormGroup();
    this.loader = false;
  }

  initServiceChargeFormGroup(): void {
    this.serviceChargeFormGroup = new FormGroup({
      amount: new FormControl(this.emptyString),
      remarks: new FormControl(this.emptyString)
    });
  }
 
  setActiveTab(){

    if(this.activeTab == 1){
      if(this.serviceChargeFormGroup.controls.amount.value == "" ||
      this.serviceChargeFormGroup.controls.remarks.value == "" ){
        PNotify.error({
          title: "Error",
          text: "All fields are required to proceed to next step.",
        });
      }else{
        this.activeTab++;
        this.loader = false;
      }
    }else if(this.activeTab == 2){

      var hasError : boolean  = false;
      if(hasError){
        PNotify.error({
          title: "Request denied",
          text: "Please fill up all the required fields.",
        });
      }else{

      }

     
    }
  }

  setActiveTabPrev(){
    if(this.activeTab == 1){
      PNotify.error({
        title: "Error",
        text: "Action Denied.",
      });
    }else{
      this.activeTab--;
    }
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  codeStatus : boolean = false;
  code : any;
  onCodeCompleted(code: string) {
    this.code = code;
    this.codeStatus = code.length == 6;
  }

  
  processPayment(){
    this.loader = true;
    this.serviceChargeService.processServiceCharge(this.serviceChargeFormGroup.value,this.code)
      .subscribe((result) => {
        PNotify.success({
          title: "Success",
          text: "Service Charge Successfully Added!",
        });
        this.loader = false;
        this.code = "";
        this.codeStatus=false;
        this.closeModal.emit();
      
    }, error => {
      PNotify.error({
        title: "Failed",
        text: error.error.reason,
      });
      this.loader = false;
      this.code = "";
      this.codeStatus = false;
    });
}

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  totalAmount(fee:any,amount:any){
    try  {
    return this.makeMoney(Number(fee) + Number(amount));
    } catch(e){
      return "0.00";
    }
    }

}
