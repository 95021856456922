<div class="card flex flex-column">
    <div class="row" [formGroup]="stockItemFormGroup">
        <div class="col-4" style="border-radius: 15px;">
            <div>
                <img class="card-image" [src]="cardImage" alt="Card image cap">
            </div>
            <div class="mt-3">
                <div class="slider">
                    <div class="slider-wrapper pt-1 pb-2" [style.width.px]="imageWidth * images.length + (images.length * 5)">
                        <div class="slider-item" *ngFor="let image of images">
                            <img class="item-img" (mouseover)="changeBigImage(image.path)" [src]="image.path" [style.width.px]="imageWidth">
                        </div>
                    </div>
                    <button class="slider-arrow left" *ngIf="images.length >= 6" (click)="scroll(-1)">&lt;</button>
                    <button class="slider-arrow right" *ngIf="images.length >= 6" (click)="scroll(1)">&gt;</button>
                </div>
                <div class="mt-2">
                    <button class="item-img btn btn-sm btn-primary text-dark" (click)="addImage(addImageModal)"><i class="fa fa-plus"></i></button>
                </div>
            </div>
        </div>
        <div class="col-8 p-3">
            <!-- <h4> <span class="color-blue font-weight-bolder">{{ stockItem.name }}</span> &nbsp; <span class="color-blue font-weight">({{ stockItem.itemCode }})</span> </h4>
            <button
                class="btn btn-sm mt-2 btn-secondary button--search btn-hover font-weight-bold" style="float:right">
                <i class="fa fa-edit"></i>
            </button>

            <p class="card-text mt-2 mb-2">{{ stockItem.description }}</p> -->
            <div class="row">
                <div class="col-md-11">
                    <h4 *ngIf="!isToUpdate" >
                        <span class="color-blue font-weight-bolder">
                            {{ stockItem.name }}
                        </span>
                        
                        &nbsp;
                        <span class="color-blue font-weight" >
                            (
                                {{ stockItem.itemCode }}
                            )
                        </span>
                    </h4>
                    <span *ngIf="isToUpdate">
                        <app-textbox
                            [parent-form-group]="stockItemFormGroup"
                            tab-index-start="0"
                            control-name="name"
                            [error]="stockItemFormGroup?.controls.name?.errors | estatus"
                            [error-message]="stockItemFormGroup?.controls.name?.errors | econtent"
                            [read-only]="false"
                            label="Item Name"
                            placeholder="Item Name"
                        ></app-textbox>

                        <app-textbox
                            [parent-form-group]="stockItemFormGroup"
                            tab-index-start="0"
                            control-name="itemCode"
                            [error]="stockItemFormGroup?.controls.itemCode?.errors | estatus"
                            [error-message]="stockItemFormGroup?.controls.itemCode?.errors | econtent"
                            [read-only]="false"
                            label="Item Code"
                            placeholder="Item Code"
                        ></app-textbox>
                    </span>

                    <p *ngIf="!isToUpdate" class="card-text mt-2 mb-2">{{ stockItem.description }}</p>
                    <span *ngIf="isToUpdate">
                        <app-textarea
                            [parent-form-group]="stockItemFormGroup"
                            tab-index-start="4"
                            control-name="description"
                            [error]="stockItemFormGroup?.controls.description.errors | estatus"
                            read-only="false"
                            label="Description"
                            placeholder="Description"
                            max-length="250"
                        >
                        </app-textarea>
                    </span>
                </div>
                <div class="col-md-1 text-right">
                  <button 
                    *ngIf="!isToUpdate" 
                    class="btn btn-sm btn-secondary button--search btn-hover font-weight-bold"
                    (click)="setoUpdate()" 
                    tooltip="Update Item">
                    <i class="fa fa-edit"></i>
                  </button>

                  <button 
                    *ngIf="isToUpdate" 
                    class="btn btn-sm btn-danger button--search btn-hover font-weight-bold" 
                    tooltip="Save Changes"
                    (click)="saveChange()">
                    <i class="fa fa-save"></i>
                  </button>
                </div>
            </div>

            <div class="w-100 d-flex justify-content-end p-2" style="background-color: rgb(239, 239, 239);">
                <h3 class="color-red font-weight-bolder mr-3 mb-0" *ngIf="!isToUpdate">₱ {{ makeMoney(stockItem.price) }}</h3>
                <app-textbox
                    *ngIf="isToUpdate"
                    [parent-form-group]="stockItemFormGroup"
                    tab-index-start="0"
                    control-name="price"
                    [error]="stockItemFormGroup?.controls.price?.errors | estatus"
                    [error-message]="stockItemFormGroup?.controls.price?.errors | econtent"
                    [read-only]="false"
                    label="Price"
                    placeholder="Price"
                ></app-textbox>
            </div>

            <div class="card">
                <div class="d-flex align-items-center px-4 py-3 b-t">
                    <div class="color-blue font-weight-bold">Category</div>
                    <div class="flex">

                    </div>
                    <span>
                        <label *ngIf="!isToUpdate" class="mr-2">
                            {{ stockItem.category.category }}
                        </label>
                        <ng-select 
                            *ngIf="isToUpdate"
                            [items]="categories"
                            bindLabel="text" 
                            bindValue="id"
                            placeholder="Select Category"
                            formControlName="categoryId">
                        </ng-select>
                    </span>
                </div>

                <div class="d-flex align-items-center px-4 py-3 b-t">
                    <div class="color-blue font-weight-bold">Unit of Measurement</div>
                    <div class="flex">

                    </div>
                    <span>
                        <label *ngIf="!isToUpdate" class="mr-2">
                            {{ stockItem.unit.abrUnit }}
                        </label>
                        <ng-select 
                            *ngIf="isToUpdate"
                            [items]="uom"
                            bindLabel="text" 
                            bindValue="id"
                            placeholder="Select Unit of Measurement"
                            formControlName="uomId">
                        </ng-select>
                    </span>
                </div>

                <div class="d-flex align-items-center px-4 py-3 b-t">
                    <div class="color-blue font-weight-bold">Active</div>
                    <div class="flex"></div>
                    <span>
                      <label class="ui-switch ui-switch-md dark mt-1 mr-2">
                        <input type="checkbox" [formControl]="stockItemFormGroup.get('active')" [attr.disabled]="!isToUpdate ? true : null"/>
                        <i></i>
                      </label>
                    </span>
                  </div>
                  <div class="d-flex align-items-center px-4 py-3 b-t">
                    <div class="color-blue font-weight-bold">Is Pre-Order</div>
                    <div class="flex"></div>
                    <span>
                      <label class="ui-switch ui-switch-md dark mt-1 mr-2">
                        <input type="checkbox" [formControl]="stockItemFormGroup.get('isPreOrder')" [attr.disabled]="!isToUpdate ? true : null"/>
                        <i></i>
                      </label>
                    </span>
                  </div>

                <div class="d-flex align-items-center px-4 py-3 b-t">
                    <div class="color-blue font-weight-bold">Created Date</div>
                    <div class="flex">

                    </div>
                    <span>
                        <label class="mr-2">
                            {{ stockItem.createdDate | date : "MMM dd, yyyy" }}
                        </label>
                    </span>
                </div>
                <div class="d-flex align-items-center px-4 py-3 b-t">
                    <div class="color-blue font-weight-bold">Created By</div>
                    <div class="flex"></div>
                    <span>
                        <label class="mr-2">
                            {{ stockItem.createdBy }}
                        </label>
                    </span>
                </div>
            </div>

            <!-- Table of Conversion -->

            <!-- <div class="w-100 p-2 mt-2" style="background-color: rgb(239, 239, 239);">
                <h5 class="font-weight-bolder mr-3 mb-0">Conversion Table</h5>
            </div>

            <button
                class="btn btn-sm mt-2 button-blue button--search btn-hover font-weight-bold" style="float:right">
                Add Conversion &nbsp; <i class="fa fa-plus text-highlight"></i>
            </button>

            <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
                <thead>
                  <tr>
                    <th data-sortable="true" data-field="id">
                      <div class="color-blue">From Unit</div>
                    </th>
                    <th data-sortable="true" data-field="owner">
                      <div class="color-blue">To Unit</div>
                    </th>
                    
                    <th class="color-blue">Conversion Type</th>

                    <th class="color-blue" style="text-align: right;">Conversion Factor</th>

                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let conversion of itemConversion" 
                    style="cursor: pointer"
                    class="odd"
                    role="row"
                  >
                    <td class="flex">
                      <div class="item-except text-sm h-1x">
                        <span style="font-weight: bolder;">{{ conversion.fromUnit }}</span>
                      </div>
                    </td>
    
                    <td class="flex">
                        <div class="item-except text-sm h-1x">
                          <span style="color: rgb(60, 62, 62); font-weight: bold;">{{ conversion.toUnit }}</span>
                        </div>
                    </td>

                    <td class="flex">
                        <div class="item-except text-sm h-1x">
                          <span style="color: rgb(60, 62, 62); font-weight: bold;">{{ conversion.conversionType }}</span>
                        </div>
                    </td>
    
                    <td class="flex">
                        <div style="text-align: right;">
                            <span class="color-red" style="color: rgb(60, 62, 62); font-weight: bold;">{{ conversion.conversionFactor }}</span>
                        </div>
                    </td>
    
                  </tr>
                </tbody>
            </table> -->
        </div>
    </div>

</div>

<ng-template #addImageModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">Add Image</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
        <ngx-dropzone [multiple]="false" (change)="onSelect($event)" style="height:250px">
            <ngx-dropzone-label>Click to upload or drop the image here!</ngx-dropzone-label>
          
            <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" 
            [removable]="true" (removed)="onRemove(f)">
              <ngx-dropzone-label></ngx-dropzone-label>
            </ngx-dropzone-image-preview>
        </ngx-dropzone>
      <!-- <app-stock-item [stockItem]="selectedItem"></app-stock-item> -->
      <hr>

        <div>
          <button class="btn btn-sm btn-primary float-right" (click)="uploadImage()">Upload Image</button>
          <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
            Cancel
          </button>
        </div>
    </div>
  </ng-template>
