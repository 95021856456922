import { CurrencyPipe, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { InvoiceComponent } from 'src/app/elements/invoice/invoice.component';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { PrintService } from 'src/data/services/web/print.service';
import { VipProtectService } from 'src/data/services/web/vip-protect.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { TransactionService } from 'src/data/services/web/transaction.service';

@Component({
  selector: 'app-vip-protect-transaction',
  templateUrl: './vip-protect-transaction.component.html',
  styleUrls: ['./vip-protect-transaction.component.less']
})
export class VipProtectTransactionComponent implements OnInit {

  @ViewChild(InvoiceComponent ) child: InvoiceComponent ; 
  @ViewChild("termsAndConditionQR") termsAndConditionQR: ElementRef;
  @ViewChild('vipLogo') vipLogo: ElementRef;
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = "https://govipcenter.com/vip-protect-terms-and-condition";

  @Input() requestId: string;
  public data :  any;
  public loader : boolean = false;
  fileUploadFormGroup : FormGroup;
  emptyString: any = '';
  public base64Photo: string | ArrayBuffer;
  roleLevelId : any;
  public receipt: any;
  public isA4 = new FormControl();

  public transactionService: TransactionService;
  public dti: any;
  public tin: any;
  public address: any;

  constructor(private vipProtectService : VipProtectService, 
    private router: Router,
    private localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    public printService: PrintService,
    private changeDetectorRef: ChangeDetectorRef,
    private modalServe: NgbModal,
    public datepipe: DatePipe,
    private webService: WebSettingsService, 
    transactionService: TransactionService) {
    this.transactionService = transactionService;
  }


  ngOnInit(): void {
    this.loader = true;
    this.initFileUploadFormGroup();
    this.getTransaction();
    this.initReceipt();
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
  }

  
  initReceipt() {
    this.webService.getWebSettingsByIdentifier("INVOICE_RECEIPT").subscribe((result) => {
      this.receipt = result.data;
    });
  }

  initFileUploadFormGroup(): void {
    this.fileUploadFormGroup = new FormGroup({
      photoFile: new FormControl(this.emptyString),
      purpose: new FormControl(this.emptyString),
      meta: new FormControl(this.emptyString)
    });
  }

  getTransaction() {
    this.vipProtectService.getTransaction(this.requestId).subscribe((vipProtect) => {
       this.data = vipProtect;
       this.initReceiptDetails(vipProtect.transactByUser.cashierUser.managerUserId);
  
    this.fileUploadFormGroup.patchValue({
      photoFile: this.data.photoFormat + ','+ this.data.photo,
    });
       this.loader = false;
    }, error => {
      this.loader = false;
    });
  }

  initReceiptDetails(managerUserId: any){
    this.transactionService.getReceiptDetails(managerUserId).subscribe((result) => {
      this.dti = result.dti;
      this.tin = result.tin != null ? result.tin : "***-***-***-***";
      this.address = result.address;
    });
  }

  buildUserPhoto(user: any): any {
    if(user){
    return user.photoFormat + "," + user.photo;
    }
  }

  public fileName: any = "Click here..";

    public onPhotoChange(event: any) {
      this.loader = true;
      const reader = new FileReader();
      
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);
        this.fileName = file.name;
        reader.onload = () => {

          this.base64Photo = reader.result;
          this.uploadImage(this.base64Photo);
  
          this.fileUploadFormGroup.patchValue({
            photoFile: reader.result,
          });
  
          this.changeDetectorRef.markForCheck();
        };
      }
    }

    uploadImage(photo) {
      this.vipProtectService.uploadImage(photo,this.data.id).subscribe((vipProtect) => {
         this.loader = false;
      }, error => {
        this.loader = false;
      });
    }
   


    public uploadModal = null;
    preview(content: any){

      this.uploadModal = this.modalServe.open(content, { centered: true, size: "lg" });
    }


    public padIntegerLeftWithZeros(rawInteger: number, numberOfDigits: number): any {
      let paddedInteger: string = rawInteger + '';
      while (paddedInteger.length < numberOfDigits) {
        paddedInteger = '0' + paddedInteger;
      }
      return paddedInteger;
    }
  
    

    printReceipt(){
      
      var vipLogo = this.vipLogo.nativeElement.innerHTML;
      const termsAndConditionQR = this.termsAndConditionQR.nativeElement.innerHTML;

      var createdDate = this.datepipe.transform(this.data.completedDate, 'MMM-dd-yyyy');
      var transTime = this.datepipe.transform(this.data.completedDate, 'hh:mm:ss a');
      var reqId = this.data.requestId;
      var cashier = this.data.transactByUser.cashierUser.firstName + ' ' + this.data.transactByUser.cashierUser.lastName;
      var billerName = 'VIP Protect';
   
      var amountPaid =  this.data.amount;
   
      var COCNumber = this.padIntegerLeftWithZeros(this.data.cocNumber,5);
      var CustomerName = this.data.customerName;
      var BirthDate = this.datepipe.transform(this.data.birthDate, 'MMM-dd-yyyy');;
      var Beneficiary = this.data.beneficiary;
      var Relationship = this.data.relationship;
      var ContactNumber = this.data.contactNumber;
      var Residence = this.data.residence;
      
  
      if(this.isA4.value){
        var Newstr = ((this.receipt.filter(x=>x.parameter == 'IN58mm_VIPProtect')[0]).value).replace("{{Date}}", createdDate);
        Newstr = (Newstr).replace("{{Time}}", transTime);
        Newstr = (Newstr).replace("{{Cashier}}", cashier);
  
        Newstr = (Newstr).replace("{{RecID}}", reqId);
        
        Newstr = (Newstr).replace("{{ProductName}}", billerName);
        Newstr = (Newstr).replace("{{COCNumber}}", COCNumber);
        Newstr = (Newstr).replace("{{CustomerName}}", CustomerName);
        Newstr = (Newstr).replace("{{BirthDate}}", BirthDate);
        Newstr = (Newstr).replace("{{Beneficiary}}", Beneficiary);
        Newstr = (Newstr).replace("{{Relationship}}", Relationship);
        Newstr = (Newstr).replace("{{ContactNumber}}", ContactNumber);
        Newstr = (Newstr).replace("{{Residence}}", Residence);
        Newstr = (Newstr).replace("{{BillAmount}}", parseFloat(amountPaid).toFixed(2));
        Newstr = (Newstr).replace("{{AmountPaid}}", parseFloat(amountPaid).toFixed(2));
        Newstr = Newstr.replace("{{receiptLogo}}", vipLogo);
        Newstr = Newstr.replace("{{TermsAndCondtionQR}}", termsAndConditionQR);
        Newstr = Newstr.replace("{{dti}}", this.dti);
        Newstr = Newstr.replace("{{tinNo}}", this.tin);
        Newstr = Newstr.replace("{{address}}", this.address);
        
        this.PrintReceipt(Newstr);
        } else {
    
          var Newstr = ((this.receipt.filter(x=>x.parameter == 'IN58mm_VIPProtect')[0]).value).replace("{{Date}}", createdDate);
        Newstr = (Newstr).replace("{{Time}}", transTime);
        Newstr = (Newstr).replace("{{Cashier}}", cashier);
  
        Newstr = (Newstr).replace("{{RecID}}", reqId);
        
        Newstr = (Newstr).replace("{{ProductName}}", billerName);
        Newstr = (Newstr).replace("{{COCNumber}}", COCNumber);
        Newstr = (Newstr).replace("{{CustomerName}}", CustomerName);
        Newstr = (Newstr).replace("{{BirthDate}}", BirthDate);
        Newstr = (Newstr).replace("{{Beneficiary}}", Beneficiary);
        Newstr = (Newstr).replace("{{Relationship}}", Relationship);
        Newstr = (Newstr).replace("{{ContactNumber}}", ContactNumber);
        Newstr = (Newstr).replace("{{Residence}}", Residence);
        Newstr = (Newstr).replace("{{BillAmount}}", parseFloat(amountPaid).toFixed(2));
        Newstr = (Newstr).replace("{{AmountPaid}}", parseFloat(amountPaid).toFixed(2));
        Newstr = Newstr.replace("{{receiptLogo}}", vipLogo);
        Newstr = Newstr.replace("{{TermsAndCondtionQR}}", termsAndConditionQR);
        Newstr = Newstr.replace("{{dti}}", this.dti);
        Newstr = Newstr.replace("{{tinNo}}", this.tin);
        Newstr = Newstr.replace("{{address}}", this.address);
   
       this.PrintReceipt(Newstr);
     }
    }
  
    PrintReceipt(Newstr: any)
    {
      var mywindow = window.open('', 'PRINT', 'height=400,width=600');
      mywindow.document.write(Newstr);
  
      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
      mywindow.close();
  
      return true;
    }
}
