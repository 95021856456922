import { ApplicationInitStatus, Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { ApplicationService } from "src/data/services/web/application.service";
import { RolesService } from "src/data/services/web/roles.service";
import { environment } from "src/environments/environment";
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { Select2OptionData } from "ng-select2";
import { UserService } from "src/data/services/web/user.service";
import { WebSettingsService } from "src/data/services/web/web-settings.service";
import { CurrencyPipe, DatePipe } from "@angular/common";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: "app-application-list",
  templateUrl: "./application-list.component.html",
  styleUrls: ["./application-list.component.less"],
})
export class ApplicationListComponent implements OnInit {
  
  @ViewChild('viewApplicationModal') viewApplicationModal: TemplateRef<any>;
  @ViewChild('viewApplicationDA5Modal') viewApplicationDA5Modal: TemplateRef<any>;
  @ViewChild('viewApplicationSendahModal') viewApplicationSendahModal: TemplateRef<any>;

  public searchByFormGroup: FormGroup;
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;

  public websettings: WebSettingsService;
  public rolesService: RolesService;
  public applicationsService: ApplicationService;
  public emptyString = "";
  public roles: any;
  public role: any;
  public statuses: any;
  public status: any;

  public accessToken: string;
  public newApplication: boolean;
  public applications: any;
  public pageCount: any;
  interval;

  public formBuilder: FormBuilder;
  public saveApplicationProcessing = false;

  public userIdFilterOptions: Array<Select2OptionData>;
  public selectedUserId: any = 0;

  public showRowsOption: Array<Select2OptionData>;
  public showProgressOption: Array<Select2OptionData>;
  public pageRows: any = 10;
  public selectedRowsId: any = 0;

  public applicationNameOptions: Array<Select2OptionData>;
  public selectedApplicationName: any = 0;

  public statusIdFilterOptions: Array<Select2OptionData>;
  public selectedStatusId: any = 0;

  public userService: UserService;
  public cashiersAndManagers: any;

  public loader : any = true;

 

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    applicationsService: ApplicationService,
    private modalService: NgbModal,
    websettings: WebSettingsService,
    formBuilder: FormBuilder,
    public datepipe: DatePipe,
    userService: UserService,
    private currencyPipe: CurrencyPipe,
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.applicationsService = applicationsService;
    this.formBuilder = formBuilder;
    this.websettings = websettings;
    this.userService = userService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
  }

  ngOnInit(): void {
    this.initSearchByFormGroup();
    this.getApplications();
    this.getManagers();
    this.getAppName();
    this.getStatus();
    this.getShowRows();
    PNotifyButtons;

    this.p = this.localStorageCacheService.getStorage("applicationPage");
  }

  initSearchByFormGroup(): void {
    this.searchByFormGroup = new FormGroup({
      showRows: new FormControl(null),
      status: new FormControl(null),
      username: new FormControl(null),
      dateRange: new FormControl(""),
      applicationName: new FormControl(null),
      applicationProcess: new FormControl(null)
    });

    this.searchByFormGroup.controls.showRows.valueChanges.subscribe((e) => {
      this.selectedRows(e);
    })

    this.searchByFormGroup.controls.username.valueChanges.subscribe((e) => {
      this.selectedUser(e);
    });
    this.searchByFormGroup.controls.applicationName.valueChanges.subscribe((e) => {
      this.getProcessOptions(this.searchByFormGroup.controls.applicationName.value);
      this.selectedApp(e);
    });
    this.searchByFormGroup.controls.status.valueChanges.subscribe((e) => {
      this.selectedStatus(e);
    });
    this.searchByFormGroup.controls.dateRange.valueChanges.subscribe((e) => {
      //
    })

    this.searchByFormGroup.controls.applicationProcess.valueChanges.subscribe((e) => {
      this.selectedApp(e);
    })
  }

  searchData(){
    this.getApplications(null,null,null,true);

  }

  getShowRows() {
    this.websettings.getWebSettingsByIdentifier("SHOW_ROWS").subscribe((result) => {
     
      this.showRowsOption = result.data.map((status) => {
        return {
          id: status.parameter,
          text: status.value,
        };
      });
    });
  }


  getProcessOptions(application : any) {
    
if(application){
  this.websettings.getWebSettingsByIdentifier("VIP_MARKETPLACE").subscribe((result) => {
    
    const paramJson = result.data.filter(x=> x.parameter.toUpperCase() == application.toUpperCase())[0].value;
    const purchaseProgresses = paramJson.acquired.purchase_progress;
    this.showProgressOption = [];
    this.showProgressOption.push({
      id: 'No Progress',
      text: 'No Progress',
    });
    purchaseProgresses.forEach(status => {
      this.showProgressOption.push({
        id: status.title,
        text: status.title,
      });
    });

   
  });

}else{

  this.showProgressOption = [];
}

    
  }

  
  selectedRows(e) {
    this.selectedRowsId = e;
    this.pageRows = e;
  }

  selectedUser(e) {
    this.selectedUserId = e;
  }

  selectedApp(e) {
    this.selectedApplicationName = e;
  }

  selectedStatus(e) {
    this.selectedStatusId = e;
  }

  private _sortBy: any;
  private _sort: any;
  public lastStateEndPointUrl : any ;

  getApplications(url = null, _sortBy = null, _sort = null, fromSearch = false): void {

if(url == null){
  this.p = 1;
}

    this.loader = true;
    if (fromSearch) {
      this.employeeId = this.emptyString;
      this.username = this.emptyString;
      this.email = this.emptyString;
      this.firstName = this.emptyString;
      this.lastName = this.emptyString;
      this.roleName = this.emptyString;
    }

    _sortBy = this._sortBy;
    _sort = this._sort;

    const userId: string = this.searchByFormGroup.controls.username.value;
    const appName: string = this.searchByFormGroup.controls.applicationName.value;
    const appProgress: string = this.searchByFormGroup.controls.applicationProcess.value;
    const status: string = this.searchByFormGroup.controls.status.value;

    const dateRange : string = this.searchByFormGroup.controls.dateRange.value;
    const showRows : string = this.searchByFormGroup.controls.showRows.value;

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_userId: string = userId ? `userId=${userId}&` : "";
    const queryString_statusId: string = status ? `status=${status}&` : "";
    const queryString_appName: string = appName ? `appName=${appName}&` : "";
    const queryString_appProgress: string = appName ? `appProgress=${appProgress}&` : "";
    const queryString_showRows: string = showRows ? `showRows=${showRows}&` : "";
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    
    const queryString =
      queryString_orderby + queryString_order + queryString_userId + queryString_statusId + queryString_appName + queryString_appProgress + queryString_showRows + queryString_dateRange;

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/applications/acm?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/applications/acm?${queryString}`;
      }
    }

    this.applicationsService.getApplicationsList(endPointUrl).subscribe((result) => {
      this.lastStateEndPointUrl = endPointUrl;
      if (result.data.length == 0 && result.pageNumber != 1) {
        this.getApplications(result.previousPage, _sortBy, _sort);
      } else {
        this.applications = result;
        this.modalService.dismissAll();
        this.interval = setInterval(() => {
          this.loader = false;
          this.saveApplicationProcessing = false;
          clearInterval(this.interval);
        }, 2000);
      }
    });

    
  }

  viewApplication(id: any, application: any): void {
  
    const encId = this.cryptoService.set(this.data.authentication.privateKey, id);
    this.id = encId;
    
    //272    8087
    if(application == "DA5 Wester Union" && id > 8087){
      this.openViewApplication(this.viewApplicationDA5Modal);
    } else if((application == "Sendah Direct" && id > 8514) || (application == "Sendah Remit" && id > 8514)){
      this.openViewApplication(this.viewApplicationSendahModal);
    } else {
      this.openViewApplication(this.viewApplicationModal);
    }
    
  }

  getManagers() {
    this.userService.getManagersUsername().subscribe((result) => {
    
      this.userIdFilterOptions = result.map((user) => {
        return {
          id: user.id,
          text: user.name,
        };
      });

    });
  }

  getAppName() {
   

    this.websettings.getWebSettingsByIdentifier("VIP_MARKETPLACE").subscribe((result) => {
    
      this.applicationNameOptions = result.data.map((provider) => {
        return {
          id: provider.parameter,
          text: provider.parameter,
        };
      });
     
    });
  }

  getStatus() {
    this.statusIdFilterOptions = [
      { id: "1", text: "Completed" },
      { id: "0", text: "On Process" },
      { id: "2", text: "Declined"}
    ];
  }

  employeeId = "";
  username = "";
  email = "";
  firstName = "";
  lastName = "";
  roleName = "";
  searchString = "";

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.employeeId = field == "employeeId" ? "th-inner sortable both " + sortBy : "";
    this.username = field == "username" ? "th-inner sortable both " + sortBy : "";
    this.email = field == "email" ? "th-inner sortable both " + sortBy : "";
    this.firstName = field == "firstName" ? "th-inner sortable both " + sortBy : "";
    this.lastName = field == "lastName" ? "th-inner sortable both " + sortBy : "";
    this.roleName = field == "roleName" ? "th-inner sortable both " + sortBy : "";

    this.getApplications(
      `${environment.API_URL}/api/applications/acm?pageNumber=${page ? page : this.applications.pageNumber}`,
      this._sortBy,
      this._sort
    );
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.applications?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }

  // tslint:disable-next-line: variable-name
  getRole(role_id: any): string {
    if (role_id) {
      return this.roles.filter((x) => x.id === role_id)[0].name;
    } else {
      return "No Role";
    }
  }
  p = 1;
  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p = pageNumber;
    this.localStorageCacheService.setStorage("applicationPage", pageNumber, 1800000);
    return `${environment.API_URL}/api/applications/acm?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  buildUserPhoto(user: any): any {
    return user.companyUser.photoFormat + "," + user.companyUser.photo;
  }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }

  id : any;
  viewApplicationProfile(id: any): void {
    const encId = this.cryptoService.set(this.data.authentication.privateKey, id);
    this.id = encId;
    this.openViewApplication(this.viewApplicationModal);
  }

  editApplicationProfile(id: any): void {
    const encId = this.cryptoService.set(this.data.authentication.privateKey, id);
    this.router.navigate(["acm", "application-list-edit", { id: encId }]);
  
  }

  createApplication(): void {
    this.router.navigate(["acm", "application-list-create"]);
  }

  checkProgressTrail(meta: any) {
    const application = JSON.parse(meta);
    let statusFalseCount = 0;
    let statusTrueCount = 0;
    application.value.acquired.purchase_progress.forEach((item) => {
      if (item.status == false) {
        statusFalseCount++;
      } else {
        statusTrueCount++;
      }
    });

    const totalSteps: number = statusFalseCount + statusTrueCount;

    if (statusFalseCount == 0) {
      return `<span class='text text-success font-weight-bold'>${statusTrueCount}</span> of <span class='text text-success font-weight-bold'>${totalSteps}</span> Steps `;
    } else {
      return `<span class='text text-warning font-weight-bold'>${statusTrueCount}</span> of <span class='text text-warning font-weight-bold'>${totalSteps}</span> Steps `;
    }
  }

  checkRequirementsProgress(meta: any){
    const application = JSON.parse(meta);
    let statusTrueCount = 0;
    let totalRequirements = 0;
    let currentProgress= false;
    application.value.acquired.purchase_progress.forEach((item) => {
      if(currentProgress == false){
        if (item.status == false) {
          currentProgress = true;
          totalRequirements = item.requirements.filter(x=>x.is_input == true || x.is_upload == true).length;
          //console.log(totalRequirements);
          item.requirements.forEach((req) => {
            if(req.file_id && req.is_upload == true) {
              statusTrueCount++;
            } 
            else if(req.input_value && req.is_input == true) {
              statusTrueCount++;
            } 
            
          });
        }
      }
    });

    if(totalRequirements == 0){
      
      return this.makeMoney(100) + '%';
    }else{
      const totalSteps: number = (statusTrueCount/totalRequirements) * 100;
      return this.makeMoney(totalSteps) + '%';
      // if(totalSteps ==0){
      //   return `<div class="progress no-bg mt-2 align-items-center circle" style="height:6px"><div class="progress-bar circle gd-danger" style="width:${this.makeMoney(totalSteps)}"></div><span class="mx-2">${this.makeMoney(totalSteps)}%</span></div>`;
      // }else if(totalSteps >0 && totalSteps < 50 ){
      // return `<div class="progress no-bg mt-2 align-items-center circle" style="height:6px"><div class="progress-bar circle gd-warning" style="width:${this.makeMoney(totalSteps)}"></div><span class="mx-2">${this.makeMoney(totalSteps)}%</span></div>`;
      // }else if(totalSteps >= 50 && totalSteps < 99.99 ){
      //   return `<div class="progress no-bg mt-2 align-items-center circle" style="height:6px"><div class="progress-bar circle gd-info" style="width:${this.makeMoney(totalSteps)}"></div><span class="mx-2">${this.makeMoney(totalSteps)}%</span></div>`;
      // }else{
      //   return `<div class="progress no-bg mt-2 align-items-center circle" style="height:6px"><div class="progress-bar circle gd-success" style="width:${this.makeMoney(totalSteps)}"></div><span class="mx-2">${this.makeMoney(totalSteps)}%</span></div>`;
     
      // }
    }
  }

  checkRequirementsProgressColor(meta: any){
    const application = JSON.parse(meta);
    let statusTrueCount = 0;
    let totalRequirements = 0;
    let currentProgress= false;
    application.value.acquired.purchase_progress.forEach((item) => {
      if(currentProgress == false){
        if (item.status == false) {
          currentProgress = true;
          totalRequirements = item.requirements.filter(x=>x.is_input == true || x.is_upload == true).length;
          //console.log(totalRequirements);
          item.requirements.forEach((req) => {
            if(req.file_id && req.is_upload == true) {
              statusTrueCount++;
            } 
           else  if(req.input_value && req.is_input == true) {
              statusTrueCount++;
            } 
            
          });
        }
      }
    });

    if(totalRequirements == 0){
      
      return `progress-bar circle gd-success`;
    }else{
      const totalSteps: number = (statusTrueCount/totalRequirements) * 100;
     // return this.makeMoney(totalSteps) + '%';
      if(totalSteps ==0){
        return `progress-bar circle gd-danger`;
      }else if(totalSteps >0 && totalSteps < 50 ){
        return `progress-bar circle gd-warning`;
      }else if(totalSteps >= 50 && totalSteps < 99.99 ){
        return `progress-bar circle gd-info`;
      }else{
        return `progress-bar circle gd-success`;
     
      }
    }
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP", undefined, '1.0-0' ).replace("PHP", "");
  }


  getApplicationName(meta: any) {
    const application = JSON.parse(meta);

    return application.value.acquired.title;
  }

  checkProgressStatus(meta: any, declined : any) {

    if(declined) {
      return `<span class="badge bg-danger">Declined</span>`;
    }

    const application = JSON.parse(meta);
    let statusFalseCount = 0;
    let statusTrueCount = 0;
    application.value.acquired.purchase_progress.forEach((item) => {
      if (item.status == false) {
        statusFalseCount++;
      } else {
        statusTrueCount++;
      }
    });

    if (statusFalseCount == 0) {
      return `<span class="badge bg-success">Completed</span>`;
    } else {
      return `<span class="badge bg-warning">On Process</span>`;
    }

    // <span class="badge bg-success">Success</span>
    // <span class="badge bg-danger">Danger</span>
  }

  public applicationFormGroup: FormGroup;
  public valueFormGroup: FormGroup;
  public thumbnailFormGroup: FormGroup;
  public acquiredFormGroup: FormGroup;
  public purchaseProgressFormArray: FormArray;
  public selectedApplication: any;

  openContentDonePurchased(content: any, _application: any): void {
    this.selectedApplication = _application;
    const application = JSON.parse(_application.meta);
    this.initApplicationFormGroup(application);

    this.valueFormGroup = this.applicationFormGroup.controls.value as FormGroup;
    this.thumbnailFormGroup = this.valueFormGroup.controls.thumbnail as FormGroup;
    this.acquiredFormGroup = this.valueFormGroup.controls.acquired as FormGroup;
    this.purchaseProgressFormArray = this.acquiredFormGroup.controls.purchase_progress as FormArray;

    this.openProgress(content);
  }

  initApplicationFormGroup(application: any): void {
    this.applicationFormGroup = new FormGroup({
      parameter: new FormControl(application.parameter),
      value: this.populateValueFormGroup(application.value),
    });
  }

  populateValueFormGroup(value: any): FormGroup {
    return new FormGroup({
      thumbnail: this.populateThumbnailFormGroup(value.thumbnail),
      acquired: this.populateAcquiredFormGroup(value.acquired),
      active: new FormControl(value.active),
    });
  }

  populateThumbnailFormGroup(thumbnail: any): FormGroup {
    return new FormGroup({
      title: new FormControl(thumbnail.title),
      logo: new FormControl(thumbnail.logo),
      price: new FormControl(thumbnail.price),
      details: new FormControl({}),
    });
  }

  populateAcquiredFormGroup(acquired: any): FormGroup {
    const acquiredFormGroup: FormGroup = new FormGroup({
      title: new FormControl(acquired.title),
      logo: new FormControl(acquired.logo),
      description: new FormControl(acquired.description),
      purchase_progress: this.formBuilder.array([]),
    });

    this.populatePurchaseProgressFormArray(acquired.purchase_progress, acquiredFormGroup);
    return acquiredFormGroup;
  }

  populatePurchaseProgressFormArray(purchase_progress: any, acquiredFormGroup: FormGroup): void {
    purchase_progress.forEach((step) => {
      const purchaseProgressFromGroup = this.formBuilder.group({
        title: new FormControl(step.title),
        icon: new FormControl(step.icon),
        status: new FormControl(step.status),
      });

      (acquiredFormGroup.controls.purchase_progress as FormArray).push(purchaseProgressFromGroup);
    });
  }

  openProgress(content: any): void {
    this.modalService.open(content, { centered: true, size: "lg" });
  }

  checkProgressClass(status: any) {
    return status ? "step0 active" : "step0";
  }

  changeProgresStatus(status: FormControl) {
    
    status.setValue(!status.value);
  }

  saveProgress() {
    this.saveApplicationProcessing = true;
    clearInterval(this.interval);

    const json = {
      id: this.selectedApplication.id,
      userId: this.selectedApplication.userId,
      meta: JSON.stringify(this.applicationFormGroup.value),
    };
    
    
    this.applicationsService.updateApplication(this.selectedApplication.id, json, false, 0).subscribe(
      (result) => {
        this.getApplications(null, null, null, true);
        PNotify.success({
          title: "Success",
          text: "Application progress updated successfully.",
        });
      },
      (error) => {
        if (error.status === 400) {
          PNotify.error({
            title: "Error",
            text: "Bad request. Please try again.",
          });
        } else {
          PNotify.error({
            title: "Error",
            text: "Internal server error.",
          });
        }
      }
    );
  }
  private modalViewApplication: any;
  
  openViewApplication(modal) {
    this.modalViewApplication = this.modalService.open(modal, {
      centered: true,
      size: "xl",
      backdrop: "static",
      keyboard: false,
    });
  }


closeModal() {
  this.loader = true;
    this.applicationsService.getApplicationsList(this.lastStateEndPointUrl).subscribe((result) => {
      this.lastStateEndPointUrl = this.lastStateEndPointUrl;
      if (result.data.length == 0 && result.pageNumber != 1) {
        this.getApplications(result.previousPage, null, null);
      } else {
        this.applications = result;
        this.modalService.dismissAll();
        this.interval = setInterval(() => {
          this.loader = false;
          this.saveApplicationProcessing = false;
          this.modalViewApplication.close();
          clearInterval(this.interval);
        }, 2000);
      }
    });

   
}


}


