<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">VIP Protect Transactions</h2>
          <small class="text-highlight font-weight-normal font-italic">JuanPay list of VIP Protect transactions</small>
        </div>
        <div class="flex"></div>
        <div>
          <div (click)="exportToExcel()" class="btn btn-sm btn-success mr-2">
            <span class="d-none d-sm-inline mx-1">Export to Excel</span>
            <i data-feather="arrow-right"></i>
          </div>
        </div>
        <!-- <div>
            <div *ngIf="isDataNotNull">
              <button class="btn btn-md btn-success w-100" (click)="exportToExcel()">
                <i class="fa fa-file-excel"></i> &nbsp; Export to Excel
              </button>
            </div>
          </div> -->
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="" style="padding-right: 0px">
        <div class="card mb-2" style="background-color: #182038">
          <div class="row" [formGroup]="searchByFormGroup">
            <table id="datatable" class="table" role="grid" aria-describedby="datatable_info">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th class="search">
                    <button class="btn btn-sm btn-info" style="float: right" (click)="searchData()">
                      Search &nbsp; <i class="fa fa-filter text-highlight"></i>
                    </button>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor: pointer" class="odd" role="row">
                  <td class="td-title">
                    <label class="label-filter">Set No. of Rows</label>
                  </td>
                  <td class="td-value">
                    <ng-select
                      [items]="showRowsOption"
                      placeholder="No of Rows"
                      bindLabel="text"
                      bindValue="id"
                      formControlName="showRows"
                    >
                    </ng-select>
                  </td>

                  <td class="td-title">
                    <label class="label-filter">COC Number</label>
                  </td>
                  <td class="td-value">
                    <input
                      type="text"
                      placeholder="POC Number"
                      class="form-control w-100"
                      formControlName="groupPolicyNumber"
                      style="background-color: rgb(255, 255, 255); color: black"
                    />
                  </td>
                </tr>
                <tr style="cursor: pointer" class="odd" role="row">
                  <td class="td-title">
                    <label class="label-filter">Customer Name</label>
                  </td>
                  <td class="td-value">
                    <input
                      type="text"
                      placeholder="Customer Name"
                      class="form-control w-100"
                      formControlName="customerName"
                      style="background-color: rgb(255, 255, 255); color: black"
                    />
                  </td>

                  <td class="td-title">
                    <label class="label-filter">Product Code</label>
                  </td>
                  <td class="td-value">
                    <ng-select
                      [items]="productsOption"
                      placeholder="Filter by Product Code"
                      bindLabel="text"
                      bindValue="id"
                      formControlName="productCode"
                    >
                    </ng-select>
                  </td>
                  <td></td>
                </tr>

                <tr style="cursor: pointer" class="odd" role="row">
                  <td class="td-title">
                    <label class="label-filter">Date Range</label>
                  </td>

                  <td class="td-value">
                    <input
                      type="text"
                      placeholder="Date Range"
                      class="form-control"
                      bsDaterangepicker
                      formControlName="dateRange"
                      style="background-color: white; color: black"
                      [bsConfig]="{
                        rangeInputFormat: 'YYYY-MM-DD',
                        dateInputFormat: 'YYYY-MM-DD',
                        showWeekNumbers: false
                      }"
                    />
                  </td>
                  <td class="td-title">
                    <label class="label-filter">Summary Based on Query</label>
                  </td>

                  <td>
                    <br />
                    <table style="margin-left: 10px; width: 96%">
                      <tr>
                        <th>Total Enrollment</th>
                        <td class="amount-total">{{ totals?.totalEnrollment | number: "1.2-2" }}</td>
                      </tr>
                      <tr>
                        <th>Total Premium</th>
                        <td class="amount-total">{{ totals?.grossPremium | number: "1.2-2" }}</td>
                      </tr>
                      <tr>
                        <th>Total Service Fees</th>
                        <td class="amount-total">{{ totals?.serviceFee | number: "1.2-2" }}</td>
                      </tr>
                      <tr>
                        <th>Total Withholding Tax</th>
                        <td class="amount-total">{{ totals?.withHoldingTax | number: "1.2-3" }}</td>
                      </tr>
                      <tr>
                        <th>Total Net Premium to VIP Protect</th>
                        <td class="amount-total">{{ totals?.netPremiumToVIPProtect | number: "1.2-2" }}</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card">
          <table
            id="table"
            class="table table-striped table-hover"
            data-plugin="bootstrapTable"
            data-toolbar="#toolbar"
            data-search="true"
            data-search-align="left"
            data-show-export="true"
            data-show-columns="true"
            data-detail-view="false"
            data-mobile-responsive="true"
            data-pagination="true"
            data-page-list="[10, 25, 50, 100, ALL]"
          >
            <thead style="background-color: #182038">
              <tr style="background-color: #182038">
                <th style="width: 200px"><span>Insurance Details</span></th>
                <!-- <th data-sortable="true" data-field="owner">Transaction Date</th>
                <th data-sortable="true" data-field="project">Effectivate Date</th>
                <th data-field="task"><span class="d-none d-sm-block">POC Number</span></th> -->
                <th><span>Customer Details</span></th>
                <!-- <th data-field="finish"><span class="d-none d-sm-block">Customer Name</span></th>
                <th data-field="finish"><span class="d-none d-sm-block">Customer Bday</span></th>
                <th data-field="finish"><span class="d-none d-sm-block">Address</span></th>
                <th data-field="finish"><span class="d-none d-sm-block">Contact Number</span></th>
                <th data-field="finish"><span class="d-none d-sm-block">Beneficiary Name</span></th>
                <th data-field="finish"><span class="d-none d-sm-block">Relationship</span></th> -->
                <th style="width: 100px; text-align: right"><span class="d-none d-sm-block">Gross Premium</span></th>
                <th style="width: 100px; text-align: right"><span class="d-none d-sm-block">Service Fee</span></th>
                <th style="width: 100px; text-align: right"><span class="d-none d-sm-block">Withholding Tax</span></th>
                <th style="width: 100px; text-align: right">
                  <span class="d-none d-sm-block">Net Premium to VIP Protect</span>
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>

            <tbody>
              <tr
                class="pt-1 pb-1"
                *ngFor="
                  let transaction of transactions?.data
                    | paginate
                      : { itemsPerPage: transactions.pageSize, currentPage: p, totalItems: transactions.totalRecords }
                "
                class="odd"
                role="row"
              >
                <td>
                  <table class="w-100">
                    <tr>
                      <th>Sequence</th>
                      <td style="text-align: left">{{ transaction.id }}</td>
                    </tr>
                    <tr>
                      <th>Transaction Date</th>
                      <td style="text-align: left">{{ transaction?.createdDate | date: "MM/dd/yyyy" }}</td>
                    </tr>
                    <tr>
                      <th>Effectivity Date</th>
                      <td style="text-align: left">{{ transaction?.effectivityDate | date: "MM/dd/yyyy" }}</td>
                    </tr>
                    <tr>
                      <th>POC Number</th>
                      <td style="text-align: left">{{ transaction?.pocNumber }}</td>
                    </tr>
                  </table>
                </td>

                <td>
                  <table class="w-100">
                    <tr>
                      <th style="width: 100px">Customer Name</th>
                      <td style="text-align: left">{{ transaction?.customerName }}</td>
                    </tr>
                    <tr>
                      <th>Birth Date</th>
                      <td style="text-align: left">{{ transaction?.birthDate | date: "MM/dd/yyyy" }}</td>
                    </tr>
                    <tr>
                      <th>Contact Number</th>
                      <td style="text-align: left">{{ transaction?.contactNumber }}</td>
                    </tr>
                    <tr>
                      <th>Beneficiary Name</th>
                      <td style="text-align: left">{{ transaction?.beneficiaryName }}</td>
                    </tr>
                    <tr>
                      <th>Relationship</th>
                      <td style="text-align: left">{{ transaction?.relationship }}</td>
                    </tr>
                    <tr>
                      <th>Address</th>
                      <td style="text-align: left">{{ transaction?.address }}</td>
                    </tr>
                  </table>
                </td>

                <!-- <td class="pt-1 pb-1">
                    <div class="item-except text-sm h-1x">
                        {{ transaction?.birthDate | date: "MM/dd/yyyy" }}
                        </div>
                    </td>

                    <td class="pt-1 pb-1">
                    <div class="item-except text-sm h-1x">
                        {{ transaction?.address  }}
                        </div>
                    </td>

                    <td class="pt-1 pb-1">
                    <div class="item-except text-sm h-1x">
                        {{ transaction?.contactNumber  }}
                        </div>
                    </td>


                    <td class="pt-1 pb-1">
                    <div class="item-except text-sm h-1x">
                        {{ transaction?.beneficiaryName  }}
                        </div>
                    </td>

                    <td class="pt-1 pb-1">
                    <div class="item-except text-sm h-1x">
                        {{ transaction?.relationship  }}
                        </div>
                    </td> -->

                <td class="pt-1 pb-1">
                  <div class="currency-data">
                    {{ transaction?.grossPremium | number: "1.2-2" }}
                  </div>
                </td>
                <td class="pt-1 pb-1">
                  <div class="currency-data">
                    {{ transaction?.serviceFee | number: "1.2-2" }}
                  </div>
                </td>
                <td class="pt-1 pb-1">
                  <div class="currency-data">
                    {{ transaction?.withHoldingTax | number: "1.2-3" }}
                  </div>
                </td>
                <td class="pt-1 pb-1">
                  <div class="currency-data">
                    {{ transaction?.netPremiumToVIPProtect | number: "1.2-2" }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
              <div class="col-sm-12 col-md-12">
                <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                  Showing {{ (transactions?.pageNumber - 1) * transactions?.pageSize + 1 }} to
                  {{ (transactions?.pageNumber - 1) * transactions?.pageSize + transactions?.data?.length }} of
                  {{ transactions?.totalRecords }} entries
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-7" style="float: right">
            <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
              <ul class="pagination" style="float: right; margin-right: 36px">
                <pagination-controls (pageChange)="getTransaction(buildPageUrl($event, 10))"></pagination-controls>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
