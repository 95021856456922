import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: 'app-settlement-status-data',
  templateUrl: './settlement-status-data.component.html',
  styleUrls: ['./settlement-status-data.component.less']
})
export class SettlementStatusDataComponent implements OnInit {
  @Input("status") status: number;

  constructor() {}

  ngOnInit(): void {}
}
