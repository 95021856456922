import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { AngularFirestore, DocumentData } from '@angular/fire/firestore';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CryptoService } from 'src/data/crypto/crypto.service';
import { Data } from 'src/data/data';
import { ExportExcelService } from 'src/data/services/web/export-excel.service';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
@Component({
  selector: 'app-food-order-history',
  templateUrl: './food-order-history.component.html',
  styleUrls: ['./food-order-history.component.less']
})

export class FoodOrderHistoryComponent implements OnInit {

  @ViewChild('viewTransactionDetails') viewTransaction: TemplateRef<any>;
  foods: any;

  constructor(
    private modalServe: NgbModal,
    private store: AngularFirestore,
    private router: Router,
    private currencyPipe: CurrencyPipe,
    public datepipe: DatePipe,
    private data: Data,
    private cryptoService: CryptoService,
    public ete: ExportExcelService
  ) {  }

  ngOnInit(): void {
    this.initSearchFormGroup();
    this.initStores();
    this.initRiders();

    this.getFoodOrders();
    this.getCommission();
  }
  
  stores = [];
  riders = [];
  foodOrders = [];
  loader = false;

  async initStores(){
    await this.store.collection('business_registration').get().subscribe((querySnapshot) => {
      this.stores = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        const id = doc.id;
        return { docId: id, docData: data };
      });
    });
  }

  async initRiders(){
    await this.store.collection('rider_registration').get().subscribe((querySnapshot) => {
      this.riders = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        const id = doc.id;
        return { docId: id, docData: data };
      });
    });
  }

  async exportexcel() {
    this.loader = true;

    const dateRange: string = this.searchByFormGroup.controls.dateRange.value;

    const userIds = ['09111222223', '09444111111', '09012345678', '09088888877', '09950141482','09998483938','09603644038','09111222333','09000000005','09012345676'];
    const storeIds = ['09111222223', '09556025238','09515424280'];
    const riderIds = ['09000000088', '09950141501','09618242643','09055555552'];

    // const query1 = this.store.collection('food_order', ref => ref
    //   .where('user_id', 'not-in', userIds)
    // );

    // const query2 = this.store.collection('food_order', ref => ref
    //   .where('store_id', 'not-in', storeIds)
    // );

    // const query3 = this.store.collection('food_order', ref => ref
    //   .where('rider_id', 'not-in', riderIds)
    // );

    // const [querySnapshot1, querySnapshot2, querySnapshot3] = await Promise.all([query1.get().toPromise(), query2.get().toPromise(), query3.get().toPromise()]);

    // this.foodOrders = [...querySnapshot1.docs, ...querySnapshot2.docs, ...querySnapshot3.docs].map((doc) => {
    //   const data = doc.data();
    //   const id = doc.id;
    //   return { docId: id, docData: data };
    // });

    //var foods = this.store.collection('food_order', ref => ref.where('user_id', 'not-in', userIds)).get();

    await this.store.collection('food_order', ref => 
        ref.where('user_id', 'not-in', userIds)
      ).get().subscribe((querySnapshot) => {
      this.foodOrders = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        const id = doc.id;
        return { docId: id, docData: data };
      });

      
      this.generateExcel(this.foodOrders, "Food Order History");
    });

    
  }

  getDocsInChunks(collection: string, field: string, values: any[], chunkSize: number): Observable<any[]> {
    const chunks = values.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / chunkSize);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);
    const chunkQueries$ = chunks.map(chunk => {
      return this.store.collection(collection, ref => ref.where(field, 'in', chunk)).snapshotChanges();
    });
    return forkJoin(chunkQueries$)
      .pipe(
        map(results => [].concat(...results))
      );
  }

  // exportexcel() {
  //   this.store.collection('food_order', ref => ref.orderBy("order_created_date", "desc")).valueChanges().subscribe((val: any) => {
  //     let orderList = val;
  //     if (this.startDate && this.endDate) {
  //       orderList = orderList.filter(x => x.order_created_date.toDate() >= new Date(this.startDate) && x.order_created_date.toDate() <= new Date(this.endDate));
  //     }
  //     this.generateExcel(orderList, "Food Order History");
  //   });
  // }
  dataForExcel = [];
  Transactions : any;
  async generateExcel(orderList: any, name: any) {
    this.Transactions = [];
    //const options = { month: 'short', day: 'numeric', year: 'numeric' };
    debugger
      
    await orderList.forEach((element) => {
      var rider = element.docData.rider_id ? this.riders.find(x => x.docId === element.docData.rider_id) : "";

      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const createddate = new Date(element?.docData?.order_created_date?.seconds * 1000);
      const dayCreate = createddate.getDate().toString().padStart(2, '0');
      const formattedCreatedDate = `${monthNames[createddate.getMonth()]} ${dayCreate}, ${createddate.getFullYear()}`;
      
      const completeddate = new Date(element?.docData?.order_completed_date?.seconds * 1000);
      const dayComplete = completeddate.getDate().toString().padStart(2, '0');
      const monthComplete = monthNames[completeddate.getMonth()];
      const yearComplete = completeddate.getFullYear();
      let hoursComplete = completeddate.getHours();
      const minutesComplete = completeddate.getMinutes().toString().padStart(2, '0');
      let ampmComplete = hoursComplete >= 12 ? 'PM' : 'AM';
      hoursComplete = hoursComplete % 12 || 12;
      
      const formattedCompletedDate = `${monthComplete} ${dayComplete}, ${yearComplete} ${hoursComplete}:${minutesComplete} ${ampmComplete}`;
      
      const cancelleddate = new Date(element?.docData?.order_cancelled_date?.seconds * 1000);
      const dayCancelled = cancelleddate.getDate().toString().padStart(2, '0');
      const monthCancelled = monthNames[cancelleddate.getMonth()];
      const yearCancelled = cancelleddate.getFullYear();
      let hoursCancelled = cancelleddate.getHours();
      const minutesCancelled = cancelleddate.getMinutes().toString().padStart(2, '0');
      let ampmCancelled = hoursCancelled >= 12 ? 'PM' : 'AM';
      hoursCancelled = hoursCancelled % 12 || 12;
      
      const formattedCancelledDate = `${monthCancelled} ${dayCancelled}, ${yearCancelled} ${hoursCancelled}:${minutesCancelled} ${ampmCancelled}`;      

      //console.log(index);
      var reportData = {
        CreatedDate: formattedCreatedDate,
        CompletedDate: element.docData.order_status == 5 ? formattedCompletedDate : "",
        CancelledDate: element.docData.order_status == 6 ? formattedCancelledDate : "",
        OrderId: element.docData.order_id,
        CustomerName: element.docData?.user_delivery_instructions?.name,
        CustomerNumber: element.docData?.user_delivery_instructions?.mobile_number,
        StoreName: element.docData.store_name,
        MerchantNumber: element.docData.store_id,
        AmountWith20: element.docData.total_price,
        DeliveryFee: element.docData.rider_fee,
        ModeOfPayment: element.docData.payment_method,
        RiderName: rider ? rider?.docData?.first_name + ' ' +  rider?.docData?.last_name : "",
        RiderNumber: element.docData.rider_id,
        Status: element.docData.order_status == 5 ? 'Completed' : element.docData.order_status == 6 ? "Cancelled" : "Pending"
      };
      this.Transactions.push(reportData);

      // element.docData.store_data = this.stores.find(x => x.docId === element.docData.store_id);
      // element.docData.rider_data = this.riders.find(x => x.docId === element.docData.rider_id);
    });
    
    console.log(this.Transactions);
    for(var x = 0; x < this.Transactions.length; x++){
      this.dataForExcel.push(Object.values(this.Transactions[x]));
    }

    let reportData = {
      title: name + ' Report',
      data: this.dataForExcel,
      headers: Object.keys(this.Transactions[0])
    }
    
    this.loader = false;
    this.ete.exportExcel(reportData, name);
  }

  getCommission(){
    this.store.collection('food_commission_rates').doc("percentages").valueChanges().subscribe((val: any) => {
      this.foodCommissionRate = val;
    });
  }

  getFoodOrders(){
    this.store.collection('food_order', ref => ref
    .limit(15)
    .orderBy('order_created_date', 'desc')).valueChanges().subscribe((val: any) => {
      //this.foods = val.filter(x => x.application_store_status == 0);
      this.foods = val;

      this.firstInResponse = val[0];
        this.lastInResponse = val.length - 1;

        this.tableData = [];
        for (let item of val) {
          this.tableData.push(item);
        }

        // initialize values
        this.prev_strt_at = [];
        this.pagination_clicked_count = 0;
        this.disable_next = false;
        this.disable_prev = false;

        // push first item to use for Previous action
        this.push_prev_startAt(this.firstInResponse);
    });
  }

  public searchByFormGroup: FormGroup;
  emptyString: any = "";
  initSearchFormGroup(): void {
    this.searchByFormGroup = new FormGroup({
      searchString: new FormControl(null),
      dateRange: new FormControl(this.emptyString)
    });

    this.searchByFormGroup.controls.searchString.valueChanges.subscribe((val) => {
      if(!val){
        this.getFoodOrders();
      }
    });
  }

  _stepperTitle =  ["Waiting to accept", "Preparing order", "Arrived at the store", "Rider is on its way", "Rider has arrived", "Order Completed", "Order Cancelled"];
  searchOrderID(){
    let orderID = this.searchByFormGroup.controls.searchString.value;
    debugger
    this.store.collection('food_order', ref => ref
      .where("order_id","==",orderID)
      .limit(15)
      .orderBy('order_created_date', 'desc'))
      .valueChanges().subscribe((val: any) => {
        this.foods = val;
      //this.foods = val.filter(x => x.application_store_status == 0);
      //this.foods = val.filter(x => x.order_id.toUpperCase().includes(orderID.toUpperCase()));

      // this.firstInResponse = val[0];
      //   this.lastInResponse = val.length - 1;

      //   this.tableData = [];
      //   for (let item of val) {
      //     this.tableData.push(item);
      //   }

      //   // initialize values
      //   this.prev_strt_at = [];
      //   this.pagination_clicked_count = 0;
      //   this.disable_next = false;
      //   this.disable_prev = false;

      //   // push first item to use for Previous action
      //   this.push_prev_startAt(this.firstInResponse);
    });
  }

  selectedOrder;
  selectedRider;
  selectedUser;
  selectedMerchant;
  detailModal: NgbModalRef;
  openDetails(content, order){
    this.selectedOrder = order;
    this.getUser(order.user_delivery_instructions);
    this.getMerchant(order.store_id);
    if(order.rider_id){
      this.getRider(order?.rider_id);
    }
    this.detailModal = this.modalServe.open(content, { centered: true, size: "xl" });
  }

  async getUser(user_delivery_instructions){
    this.selectedUser = user_delivery_instructions;
  }

  async getMerchant(docID){
    //debugger
    await this.store.collection("business_registration").doc(docID).valueChanges().subscribe((val) => {
      this.selectedMerchant = val;
    });
  }

  async getRider(docID){
    //debugger
    await this.store.collection("rider_registration").doc(docID).valueChanges().subscribe((val) => {
      this.selectedRider = val;
    });
  }

  getTotalAmount(orders){

    let sum = 0;

    orders.forEach((element) => {
      sum += element.total_price;
    });

    return sum;
  }

  foodCommissionRate;
  getInstantReward(order){
    //debugger
    let foodPrice = Number(this.getTotalAmount(order.orders));

    let instantReward = ((foodPrice * this.foodCommissionRate.store_service_charge_reverse_compute) + (order.rider_fee * this.foodCommissionRate.rider_service_charge)) * this.foodCommissionRate.user_commission;

    return this.makeMoney(instantReward);
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  updateFoodOrder(foodOrder: any){
    //alert(foodOrder);
    this.store
      .firestore
      .collection('food_order')
      .doc(foodOrder)
      .set({
        order_status: 1
      }, {
        merge: true,
    });
  }




  //PAGINATION

  // tableData will contains the document items get from collection
  tableData: any[] = [];

  // save first document in snapshot of items received
  firstInResponse: any = [];

  // save last document in snapshot of items received
  lastInResponse: any = [];

  // keep the array of first document of previous pages
  prev_strt_at: any = [];

  // maintain the count of clicks on Next Prev button
  pagination_clicked_count = 0;

  // two buttons will be needed by which next data or prev data will be loaded
  // disable next and prev buttons
  disable_next: boolean = false;
  disable_prev: boolean = true;

  // add a document
  push_prev_startAt(prev_first_doc) {
    this.prev_strt_at.push(prev_first_doc);
  }
  // remove non required document
  pop_prev_startAt(prev_first_doc) {
    this.prev_strt_at.forEach(element => {
      if (prev_first_doc.order_id == element.order_id) {
        element = null;
      }
    });
  }

  // return the Doc rem where previous page will startAt
  get_prev_startAt() {
    if (this.prev_strt_at.length > (this.pagination_clicked_count + 1)) {
      this.prev_strt_at.splice(this.prev_strt_at.length - 2, this.prev_strt_at.length - 1);
    }
    return this.prev_strt_at[this.pagination_clicked_count - 1];
  }

  nextPage() {
    this.disable_next = true;
    this.store.collection('food_order', ref => ref
    .limit(10)
    .orderBy('order_created_date', 'desc')).valueChanges().subscribe((val: any) => {
      //this.foods = val.filter(x => x.application_store_status == 0);
      this.foods = val;

      if (!val.length) {
        console.log("No More Data Available");
        this.disable_next = true;
        return;
      }
      this.firstInResponse = val[0];
      this.lastInResponse = val.length - 1;
      this.tableData = [];
      for (let item of val) {
        this.tableData.push(item.data());
      }
      this.pagination_clicked_count++;
      this.push_prev_startAt(this.firstInResponse);
      if (val.length < 5) {
          // disable next button if data fetched is less than 5 - means no more data left to load
          // because limit ti get data is set to 5
          this.disable_next = true;
      } else {
          this.disable_next = false;
      }
      this.disable_prev = false;
    });

  }

  prevPage() {
    this.disable_prev = true;
    this.store.collection('food_order', ref => ref
    .limit(15)
    .orderBy('order_created_date', 'desc')).valueChanges().subscribe((val: any) => {
      //this.foods = val.filter(x => x.application_store_status == 0);
      this.foods = val;

      this.firstInResponse = val[0];
        this.lastInResponse = val.length - 1;

        this.tableData = [];
        for (let item of val) {
          this.tableData.push(item.data());
        }

        // maintaing page no.
        this.pagination_clicked_count--;

        // pop not required value in array
        this.pop_prev_startAt(this.firstInResponse);

        // enable buttons again
        if (this.pagination_clicked_count == 0) {
            this.disable_prev = true;
        } else {
            this.disable_prev = false;
        }
        this.disable_next = false;
    });

  }

  //PAGINATION END

}
