<div style="border-radius: 2%; padding-right: 12mm; font-family: sans-serif">
  <div>
    <div style="display: flex; justify-content: center; margin-bottom: 0">
      <img src="../../../favicon.ico" height="100px" width="100px" />
    </div>
    <div class="info">
      <div style="display: flex; justify-content: center">
        <h3 style="font-weight: bold; margin-bottom: 0; font-size: 14px">VIP PAYMENT CENTER</h3>
      </div>
      <p style="text-align: center; margin-top: 0; font-size: 10px">
        4th level unit 888, <br />
        Lemon Square Building, <br />
        Muñoz - EDSA, Quezon City, <br />
        Philippines <br />
      </p>
      <div style="display: flex; justify-content: center">
        <h2 style="font-size: 12px; font-weight: bold">TRANSACTION DETAILS</h2>
      </div>

      <hr style="border: 1px dashed rgb(43, 42, 42); margin: 0px 16px 4px 16px" />
    </div>
  </div>

  <div>
    <div>
      <div>
        <p>
          <span style="font-size: 12px">Date:</span>
          <span style="float: right; font-size: 12px">March 11,2021</span> <br />

          <span style="font-size: 12px">Time:</span>
          <span style="float: right; font-size: 12px">10:30 PM</span> <br />

          <span style="font-size: 12px">Request ID:</span> <br />
          <span style="font-size: 10px; float: right"> </span> <br />

          <span style="font-size: 12px">Rec Val No:</span> <br />
          <span style="float: right; font-size: 12px">54ASD5DSW89</span> <br />
        </p>

        <div style="display: flex; justify-content: center">
          <h2 style="font-size: 12px; font-weight: bold">SYSTEM DETAILS</h2>
        </div>

        <hr style="border: 1px dashed rgb(43, 42, 42); margin: 0px 16px 4px 16px" />

        <p>
          <span style="font-size: 12px">Biller Name:</span> <br />
          <span style="float: right; font-size: 12px"></span> <br />

          <span style="font-size: 12px">Reference No:</span>
          <span style="float: right; font-size: 12px">32165458</span> <br />

          <span style="font-size: 12px">Contact No:</span>
          <span style="float: right; font-size: 12px">09123456789</span> <br />

          <span style="font-size: 12px">Bill Amount:</span>
          <span style="float: right; font-size: 12px">₱ </span> <br />

          <span style="font-size: 12px">Biller Fee:</span>
          <span style="float: right; font-size: 12px">₱ 0</span> <br />

          <span style="font-size: 12px">Amount Paid:</span>
          <span style="float: right; font-size: 12px">₱ </span> <br />
        </p>
      </div>
    </div>

    <div>
      <hr style="border: 1px dashed rgb(43, 42, 42); margin: 0px 16px 0px 16px" />
      <div style="display: flex; justify-content: center">
        <p style="text-align: center; font-size: 12px">
          All payment are <br />
          transmitted directly <br />
          to our partners <br />
          Thank you for paying at <br />
          <br />
          <strong>VIP PAYMENT CENTER</strong>
        </p>
      </div>

      <hr style="border: 1px dashed rgb(43, 42, 42); margin: 0px 16px 0px 16px" />
      <div style="display: flex; justify-content: center">
        <p style="text-align: center; font-size: 12px">
          For concern regarding this <br />
          transaction directly <br />
          message us using <br />
          our Facebook page. <br />
          http://m.me./VIPSupportTeam
        </p>
      </div>
      <br />
      <hr style="border: 1px dashed rgb(43, 42, 42); margin: 0px 16px 0px 16px" />
    </div>
  </div>
</div>
