<app-loader *ngIf="loader"></app-loader>

  <div class="page-content">
   
    <div class="card w-100" style="height: 73vh; overflow-y: scroll">
        <app-pos-transaction-item  [pos]="pos" [posTransactionId]="pad(pos?.id, 10)" showDetails=false></app-pos-transaction-item>
      </div>
   
   
      <div class="card-body pt-0 pb-0 mt-0 ml-0">
        <div class="pos-rlt" style="width: 100%">
          <div>
            <hr/>
            <div #containerQRDiv>
              <ngx-qrcode
              [elementType]="elementType"
              [errorCorrectionLevel]="correctionLevel"
              [value]="value"
              cssClass="bshadow"></ngx-qrcode>
            </div>
            <hr />
            <span class="text-highlight font-weight-bold" style="font-size: 10px"> Subtotal </span>
            <span class="text-highlight font-weight-bold" style="float: right; font-size: 10px">
              ₱ {{ pos?.notMappedSubTotalAmount | number: "1.2-2" }}</span
            >
            <br />

            <span class="text-muted" style="font-size: 10px"> Tax </span>
            <span class="text-highlight font-weight-bold" style="float: right; font-size: 10px"
              >₱ {{ pos?.notMappedTaxAmount | number: "1.2-2" }}</span
            >

            <hr />
            <span class="text-highlight font-weight-bold mb-3" style="font-size: 10px"> Total </span>
            <span class="text-highlight font-weight-bold mb-3" style="float: right; font-size: 10px">
              ₱ {{ pos?.notMappedTotalAmount | number: "1.2-2" }}</span
            >
          </div>
        </div>
      </div>
      <!-- end of transaction items -->
</div>

<!-- Modal for Creating Transaction -->


