import { Message } from "@angular/compiler/src/i18n/i18n_ast";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Role } from "src/data/models/role.model";
import { AuthService } from "src/data/services/authentication/auth.service";
import { CompanyUsersService } from "src/data/services/web/company-users.service";
import { RolesService } from "src/data/services/web/roles.service";
import { environment } from "src/environments/environment";
import { ModalConfirmationComponent } from "../modal-confirmation/modal-confirmation.component";

// PNotify
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: "app-company-user-create",
  templateUrl: "./company-user-create.component.html",
  styleUrls: ["./company-user-create.component.less"],
})
export class CompanyUserCreateComponent implements OnInit {
  public companyUserFormGroup: FormGroup;
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public companyUsersService: CompanyUsersService;
  public emptyString = "";
  public roles: any;
  public bsModalRefConfirmation: BsModalRef;
  public modalService: BsModalService;

  public accessToken: string;

  public base64Photo: string | ArrayBuffer;

  processCreate = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    companyUsersService: CompanyUsersService,
    modalService: BsModalService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.companyUsersService = companyUsersService;
    this.modalService = modalService;
    this.accessToken = this.localStorageCacheService.getStorage("access-token");

    PNotifyButtons; // Initiate PNotify buttons. Important!
  }

  ngOnInit(): void {
    this.initCompanyUserFormGroup();
    this.initRoles();
  }

  initCompanyUserFormGroup(): void {
    this.companyUserFormGroup = new FormGroup({
      username: new FormControl(this.emptyString),
      email: new FormControl(this.emptyString),
      firstName: new FormControl(this.emptyString),
      lastName: new FormControl(this.emptyString),
      address: new FormControl(this.emptyString),
      contactNumber: new FormControl(this.emptyString),
      birthDate: new FormControl(this.emptyString),
      hiringDate: new FormControl(this.emptyString),
      roleId: new FormControl(this.emptyString),
      employeeId: new FormControl(this.emptyString),
      emergencyContactName: new FormControl(this.emptyString),
      emergencyContactNumber: new FormControl(this.emptyString),
      emergencyContactRelationship: new FormControl(this.emptyString),
      photo: new FormControl(this.emptyString),
      status: new FormControl(true),
      redirect: new FormControl(environment.EMAIL_VERIFICATION_REDIRECT),
    });

    this.companyUserFormGroup.valueChanges.subscribe((value) => {
      
    });
  }

  initRoles(): any {
    this.rolesService.getRoles(1).then((roles) => {
      this.roles = roles;
    });
  }

  actionEventCreateCompanyUser(): void {
    this.processCreate = true;

    this.companyUsersService.createCompanyUser(this.companyUserFormGroup.value).subscribe(
      (result) => {
        const encId = this.cryptoService.set(this.data.authentication.privateKey, result.data.id);
        const encNewData = this.cryptoService.set(this.data.authentication.privateKey, "true");
        this.router.navigate(["acm", "company-user-view", { id: encId, status: encNewData }]);

        PNotify.success({
          title: "Success",
          text: "The account has been stored.",
        });
      },
      (error) => {
       
        if (error.status === 400) {
         
          const obj = error.error.errors;
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              let str: string = key;
              str = str[0].toLowerCase() + str.slice(1);
              this.companyUserFormGroup.controls[str].setErrors({
                required: Validators.required,
                apiMessage: obj[key],
              });
            }
          }
          this.processCreate = false;

          PNotify.notice({
            title: "Warning",
            text: "Could not store account with errors.",
          });
        } else {
          // Other error
          this.processCreate = false;

          PNotify.error({
            title: "Error",
            text: "Something went wrong.",
          });
        }
      }
    );
  }

  public onPhotoChange(event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.base64Photo = reader.result;

        this.companyUserFormGroup.patchValue({
          photo: reader.result,
        });

        this.changeDetectorRef.markForCheck();
      };
    }
  }

  backToCompanyUsersList(): void {
    this.router.navigate(["acm", "company-user-list"]);
  }
}
