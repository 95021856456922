import { CurrencyPipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { AllocateFundService } from "src/data/services/web/allocate-fund.service";
import { RolesService } from "src/data/services/web/roles.service";
import { environment } from "src/environments/environment";
import { ModalConfirmationComponent } from "../modal-confirmation/modal-confirmation.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-allocate-fund-list",
  templateUrl: "./allocate-fund-list.component.html",
  styleUrls: ["./allocate-fund-list.component.less"],
})
export class AllocateFundListComponent implements OnInit {
  public allocateFundFilterFormGroup: FormGroup;
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public allocateFundService: AllocateFundService;
  public emptyString = "";
  public statuses: any;
  public status: any;
  public modalService: BsModalService;

  public accessToken: string;
  public newAllocateFund: boolean;
  public allocateFunds: any;
  public allocateFund: any;
  public pageCount: any;
  public roleLevelId: any;

  public username: any;
  public processUpdate: any = false;

  public bsModalRefConfirmation: BsModalRef;

  
  public allocateFundDetail: any;
  public isTicketActive = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    private currencyPipe: CurrencyPipe,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    allocateFundService: AllocateFundService,
    modalService: BsModalService,
    bsModalRefConfirmation: BsModalRef,
    private modalServe: NgbModal,
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.allocateFundService = allocateFundService;
    this.modalService = modalService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
    this.bsModalRefConfirmation = bsModalRefConfirmation;
  }

  ngOnInit(): void {
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.username = this.localStorageCacheService.getStorage("username");
    this.getAllocateFund();
  }

  private _sortBy: any;
  private _sort: any;

  getAllocateFund(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_searchString: string = this.searchString ? `searchString=${this.searchString}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_searchString;

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/allocatefunds?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/allocatefunds${queryString}`;
      }
    }

    this.allocateFundService.getAllocateFund(endPointUrl).subscribe((result) => {
     
      if (result.data.length == 0 && result.pageNumber != 1) {
        this.getAllocateFund(result.previousPage, _sortBy, _sort);
      } else {
        this.allocateFunds = result;
      }
    });
  }

  createdDate = "";
  searchString = "";

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.createdDate = field == "createdDate" ? "th-inner sortable both " + sortBy : "";

    this.getAllocateFund(
      `${environment.API_URL}/api/allocatefunds?pageNumber=${page ? page : this.allocateFunds.pageNumber}`,
      this._sortBy,
      this._sort
    );
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.allocateFunds?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }
  p:Number =1;
  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p = pageNumber;
    return `${environment.API_URL}/api/allocatefunds?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }

  editAllocateFundProfile(id: any): void {
   
    const encId = this.cryptoService.set(this.data.authentication.privateKey, id);
    this.router.navigate([this.roleLevelId == 1 ? "acm" : "manager", "allocate-fund-edit", { id: encId }]);
  }

  createAllocateFund(): void {
    this.router.navigate([this.roleLevelId == 2 ? "manager" : "cashier", "allocate-fund-create"]);
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  setSelectedAllocateFund(allocateFund: any) {
    this.allocateFund = allocateFund;
  }

  processAllocateFund(status: any) {
    this.processUpdate = true;
    this.allocateFundService.updateAllocateFund(this.allocateFund.id, status).subscribe((result) => {
      this.searchString = this.emptyString;
      this.getAllocateFund();
      this.messageDialog();
    });
  }

  messageDialog(): void {
    const initialState = {
      meta: {
        title: "Allocate Fund",
        message: "Processing of allocate fund was completed.",
        btnTitle: "Ok",
        showCancel: false,
      },
    };

    this.processUpdate = false;

    this.bsModalRefConfirmation = this.modalService.show(ModalConfirmationComponent, {
      initialState,
    });

    this.bsModalRefConfirmation.content.clickevent.subscribe((response) => {
      if (response.result) {
      }
    });
  }

  buildUserPhoto(allocateFund: any): any {
    return allocateFund?.officialReceiptPhotoFormat + "," + allocateFund?.officialReceiptPhoto;
  };

  
  viewDetails(content: any, allocateFund: any): void {
    this.allocateFundDetail = allocateFund;
   
    this.isTicketActive = false;
    this.modalServe.open(content, { centered: true, size: "xl" });
  }

  
  openTicket(){

    this.isTicketActive = true;
  }


}
