import { CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { StoreItemService } from 'src/data/services/web/store-item.service';
import { UploadService } from 'src/data/services/web/upload.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-stock-item-create',
  templateUrl: './stock-item-create.component.html',
  styleUrls: ['./stock-item-create.component.less']
})
export class StockItemCreateComponent implements OnInit {
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  @Output() getStockItem: EventEmitter<any> = new EventEmitter();
  
  stockItem: any;

  imageWidth = 64; // set the width of each image
  imageHeight = 68; // set the width of each image
  visibleImages = 3; // set the number of visible images
  currentIndex = 0; // set the initial index of the first visible image
  
  scroll(direction: number): void {
    const wrapper = document.querySelector('.slider-wrapper') as HTMLElement;
    const itemWidth = this.imageWidth + 4; // include margin-right
    const wrapperWidth = wrapper.offsetWidth;
    const maxIndex = this.images.length - this.visibleImages;
    let nextIndex = this.currentIndex + direction;
    if (nextIndex < 0) {
      nextIndex = 0;
    } else if (nextIndex > maxIndex) {
      nextIndex = maxIndex;
    }
    this.currentIndex = nextIndex;
    const transform = `translateX(-${this.currentIndex * itemWidth}px)`;
    wrapper.style.transform = transform;
  }

  isToUpdate: boolean = true;
  stockItemCreateFormGroup: FormGroup;
  emptyString: any = "";
  imageList: any = [];

  constructor(private router: Router, 
    private storeItemService: StoreItemService,
    private uploadService: UploadService,
    private modService: NgbModal,
    private http: HttpClient,
    private currencyPipe: CurrencyPipe
    ) { }

  ngOnInit(): void {
    this.initFormGroup();
    this.getCategories();
    this.getUoM();
  }

  initFormGroup(){
    this.stockItemCreateFormGroup = new FormGroup({
      itemCode: new FormControl(this.emptyString, [Validators.required]),
      name: new FormControl(this.emptyString, [Validators.required]),
      description: new FormControl(this.emptyString, [Validators.required]),
      price: new FormControl(this.emptyString, [Validators.required]),
      categoryId: new FormControl(null, [Validators.required]),
      uomId: new FormControl(null, [Validators.required]),
      images: new FormControl(null, [Validators.required])
    });
  }

  categories: any;
  getCategories(){
    this.storeItemService.getCategories().subscribe((result) => {
      //this.categories = result;
      this.categories = result.map((status) => {
        return {
          id: status.id,
          text: status.category,
        };
      });
    });
  }

  uom: any;
  getUoM(){
    this.storeItemService.getUoM().subscribe((result) => {
      this.uom = result.map((status) => {
        return {
          id: status.id,
          text: status.abrUnit,
        };
      });
    });
  }

  setoUpdate(){
    this.isToUpdate = false;
  }

  saveChange(){
    if(this.stockItemCreateFormGroup.valid){
      //console.log("Submitted File: ",this.stockItemCreateFormGroup.value);
      this.storeItemService.stockItemPost(this.stockItemCreateFormGroup.value).subscribe((result) => {
        if(result){
          PNotify.success({
            title: "Stock Item",
            text: "Successfully Created",
          });
          this.getStockItem.emit();
          this.closeModal.emit();
        } else {
          PNotify.error({
            title: "Stock Item",
            text: "Something went wrong. Please try again.",
          });
        }
      });
    } else {
      PNotify.error({
        title: "Invalid",
        text: "Please fill all fields.",
      });
    }
  }

  cardImage = '../../../../../assets/vips2/empty2.png';
  changeBigImage(image: any){
    this.cardImage = image;
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  images:any = [];
  files: File[] = [];

  onSelect(event) {
    console.log(event);
    // this.files = [];
    // this.files.push(...event.addedFiles);

    this.files = [];
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif']; // Valid image MIME types
  
    for (const file of event.addedFiles) {
      if (validImageTypes.includes(file.type)) {
        this.files.push(file);
      } else {
        console.log(`Invalid file type: ${file.type}`);
        // Display an error message or perform any necessary actions for invalid file types
      }
    }
  }
  
  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  addImageModalRef: NgbModalRef;
  addImage(content: any){
    this.addImageModalRef = this.modService.open(content, {
      centered: true,
      size: "sm",
      backdrop: "static",
      keyboard: false,
    });
  }

  uploadImage(){
    debugger
    if(!this.files[0]){
      PNotify.error({
        title: "Invalid",
        text: "Please attach a valid image.",
      });
        return;
    }

    const file = this.files[0];
    const maxSize = 1 * 1024 * 1024; // 1MB in bytes
    
    if (file.size > maxSize) {
      PNotify.error({
        title: "Invalid",
        text: "Please select an image that is less than 1MB in size.",
      });
      return;
    }
    
    const file_data = this.files[0];

    //var filedata = '';
    const reader = new FileReader();
    reader.readAsDataURL(file_data);
    reader.onload = () => {
      const filedata = reader.result.toString().substring(reader.result.toString().indexOf(',') + 1);

      this.uploadService.uploadImageGovipStore(filedata).subscribe(response => {
        if(response){
          const json = JSON.parse(response);
          this.images.push(json.response_data);
          this.files = [];
          this.cardImage = json.response_data;
          this.stockItemCreateFormGroup.controls.images.patchValue(this.images);
          //console.log(this.images);
        }
      });
    }
    
    this.addImageModalRef.close();
  }

  // onUpload(){
  //   if(!this.files[0]){
  //     const file_data = this.files[0];
  //     const data = new FormData();
  //     data.append('file',file_data);
  //     data.append('upload_preset','govipstore');
  //     data.append('cloud_name','govipcenter-com');
  
  //     this.uploadService.uploadImage(data).subscribe(response => {
  //         if(response){
  //             console.log(response.url);
  //         }
  //     });
  //   }
  // }
}
