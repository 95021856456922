<!-- <app-loader *ngIf="!accounts || loader"></app-loader> -->
<app-loader *ngIf="loader"></app-loader>

<!-- *ngIf="requestFunds" -->
<div id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="--spam">Vips User Verification</h2>
          <small class="--desc" style="font-weight: bold">JuanPay list of Vips Users for verification</small>
        </div>
        <div class="flex"></div>
        <div class="mr-2"></div>

        <div>
          <!-- <div *ngIf="roleLevelId == 2" (click)="createRequestFund()" class="btn btn-md btn-info">
            <span class="d-none d-sm-inline mx-1">Create New</span>
            <i data-feather="arrow-right"></i>
          </div> -->
        </div>
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="">
        <div class="card mt-2">
          <table
            id="table"
            class="table table-striped table-hover"
            data-plugin="bootstrapTable"
            data-toolbar="#toolbar"
            data-search="true"
            data-search-align="left"
            data-show-export="true"
            data-show-columns="true"
            data-detail-view="false"
            data-mobile-responsive="true"
            data-pagination="true"
            data-page-list="[10, 25, 50, 100, ALL]"
          >
            <thead>
              <tr>
                <th data-sortable="true" data-field="id">No</th>
                <th data-sortable="true" data-field="id">Name</th>
                <th data-sortable="true" data-field="owner">Email</th>
                <th data-sortable="true" data-field="owner">Created Date</th>
              </tr>
            </thead>
            <tbody *ngIf="accounts">
              <!-- *ngFor="
                let requestFund of requestFunds?.data
                  | paginate: { itemsPerPage: pageRows, currentPage: p, totalItems: requestFunds.totalRecords }; let i = index
              " account
              (click)="viewDetails(requestFundModal, requestFund)" -->
              <tr
                class=" "
                class="odd"
                role="row"
                (click)="viewDetails(verificationDetails, account)"
                *ngFor="let account of accounts.response_data; let i = index"
              >
                <td class="flex">
                  <div class="item-except text-sm text-dark h-1x">
                    {{ i + 1 }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm text-dark h-1x">
                    {{ account.userFName + " " + account.userLname }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm text-dark h-1x">
                    {{ account.userEmail }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ account.levelOneDateApplication | date: "MMMM dd, yyyy hh:mm:ss a" }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- <div class="row">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
              <div class="col-sm-12 col-md-12">
                <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                  <span style="font-weight: bold">
                    Showing {{ (requestFunds?.pageNumber - 1) * requestFunds?.pageSize + 1 }} to
                    {{ (requestFunds?.pageNumber - 1) * requestFunds?.pageSize + requestFunds?.data?.length }} of
                    {{ requestFunds?.data?.length }} entries
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-7" style="float: right">
            <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
              <ul class="pagination" style="float: right; margin-right: 36px">
                <pagination-controls (pageChange)="getRequestFund(buildPageUrl($event, 10))"></pagination-controls>
              </ul>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<div id="modal-approve" class="modal fade" data-backdrop="true">
  <!-- <div class="modal-dialog animate">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title text-md text-info">Approving of Request Fund</div>
        <button class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="p-4 text-center">
          <p>Are you sure you want to approve the request fund?</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
        <button type="button" (click)="processRequestFund(true)" class="btn btn-primary" data-dismiss="modal">
          Continue
        </button>
      </div>
    </div>
  </div> -->
</div>

<div id="modal-reject" class="modal fade" data-backdrop="true">
  <!-- <div class="modal-dialog animate">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title text-md text-danger">Rejecting of Request Fund</div>
        <button class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="p-4 text-center">
          <p>Are you sure you want to reject the request fund?</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
        <button type="button" (click)="processRequestFund(false)" class="btn btn-danger" data-dismiss="modal">
          Continue
        </button>
      </div>
    </div>
  </div> -->
</div>

<!-- <div id="modal-receipt" class="modal fade" data-backdrop="true">
  <div class="modal-dialog animate">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title text-md">Original Official Reciept</div>
        <button class="close" data-dismiss="modal">&times;</button>
      </div>

      <div class="modal-body">
        <div class="p-4 text-center">
          <p>Make sure that this receipt was validated</p>
          <img
            *ngIf="requestFund?.officialReceiptPhoto"
            [src]="buildUserPhoto(requestFund) | authImage | async"
            alt="VIP"
            onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
            style="width: 100%"
          />
          <img *ngIf="!requestFund?.officialReceiptPhoto" src="https://via.placeholder.com/64x64?text=VIP" alt="VIP" />
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div> -->

<!-- <ng-template #requestFundModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Request Fund Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-sm-6">
        <div class="card bg-dark mb-2 p-1 d-flex align-items-center justify-content-center">
          <p class="font-weight-bold mb-0">Transaction Details</p>
        </div>
        <form action="" [formGroup]="requestFundRemarkFormGroup">
          <table class="table table-striped bg-white">
            <tbody>
              <tr>
                <td colspan="5">Amount</td>
                <th class="text-nowrap" scope="row">{{ makeMoney(requestFund?.amount) }}</th>
              </tr>
              <tr>
                <td colspan="5">Reference number</td>
                <th class="text-nowrap" scope="row">{{ requestFund?.referenceNumber }}</th>
              </tr>
              <tr>
                <td colspan="5">Request origin</td>
                <th class="text-nowrap" scope="row">{{ requestFund?.origin }}</th>
              </tr>
              <tr>
                <td colspan="5">Request destination</td>
                <th class="text-nowrap" scope="row">{{ requestFund?.destination }}</th>
              </tr>
              <tr>
                <td colspan="5">Requested by</td>
                <th class="text-nowrap" scope="row">{{ requestFund?.createdByUsername }}</th>
              </tr>
              <tr>
                <td colspan="5">Created date</td>
                <th class="text-nowrap" scope="row">{{ requestFund?.createdDate }}</th>
              </tr>
              <tr>
                <td colspan="5">Notes</td>
                <th style="max-width:40em; max-width: 100ch" scope="row">{{ requestFund?.managerRemarks }}</th>
              </tr>
              <tr>
                <td colspan="5" class="pb-0">Remarks</td>
                <th style="max-width:40em; max-width: 100ch" scope="row">
                  <span *ngIf="requestFund?.statusId != 3 || roleId != 7">{{ requestFund?.financeRemarks }}</span>
                  <textarea
                    formControlName="remarks"
                    placeholder="Remarks..."
                    class="form-control w-100"
                    *ngIf="requestFund?.statusId == 3 && (roleId == 7 || roleId == 1 || roleId == 2)"
                  ></textarea>
                </th>
              </tr>
              <tr *ngIf="(requestFund?.statusId == 6)">
                <td colspan="5" class="pb-0">Void Remarks</td>
                <th style="max-width:40em; max-width: 100ch" scope="row">
                  <span >{{ requestFund?.voidRemarks }}</span>
                </th>
              </tr>
              <tr>
                <td colspan="6" class="pt-0 pb-0">
                  <button
                    class="btn btn-primary mt-2"
                    style="float: right; width: 100px"
                    [disabled]="isRemark"
                    *ngIf="requestFund?.statusId == 3 && (roleId == 7 || roleId == 1 || roleId == 2)"
                    (click)="approveRequest(approvedFund)"
                  >
                    Approve
                  </button>

                  <button
                    class="btn btn-danger mr-2 mt-2"
                    style="float: right; width: 100px"
                    [disabled]="isRemark"
                    *ngIf="requestFund?.statusId == 3 && (roleId == 7 || roleId == 1 || roleId == 2)"
                    (click)="rejectRequest(rejectFund)"
                  >
                    Reject
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>

        <div *ngIf="!isTicketActive">
          
          <br/>

          
     
  
          <div class="w-100 d-flex justify-content-end">
            <button *ngIf="(requestFund?.statusId == 2) && roleLevelId == 1" class="btn btn-sm btn-danger" style="width: 120px;" (click)="void()" > Void &nbsp; <i class="fa fa-step-backward"></i> </button> 
            &nbsp;&nbsp;
            <button class="btn btn-sm btn-warning" style="width: 120px;" (click)="openTicket()" > Open Ticket &nbsp; <i class="fa fa-tag"></i> </button>
        </div>
        <br/> 
        </div>
      
      </div>

      <div class="col-sm-6">
        <div class="card bg-dark mb-2 p-1 d-flex align-items-center justify-content-center">
          <p class="font-weight-bold mb-0">Attachment: Official Receipt</p>
        </div>
        <div class="text-center" style="max-height: 410px; overflow-y: scroll">
          <img
            *ngIf="currentImage?.officialReceiptPhoto"
            [src]="buildUserPhoto(currentImage) | authImage | async"
            alt="VIP"
            onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
            style="width: 100%"
          />
          <img
            *ngIf="requestFund.officialReceiptPhotoUrl"
            [src]="requestFund.officialReceiptPhotoUrl"
            alt="VIP"
            onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
            style="width: 100%"
          />
          <img
            *ngIf="!currentImage?.officialReceiptPhoto && !requestFund.officialReceiptPhotoUrl"
            src="https://via.placeholder.com/64x64?text=VIP"
            alt="VIP"
            style="width: 100%"
          />
        </div>
      </div>

      

      <div class="col-sm-12">
      <app-ticket-create-transaction 
      *ngIf="isTicketActive"
      [requestId]="requestFund.requestId"
      transactionType="REQUEST_FUND"
      [username]="requestFund?.createdByUsername">
    </app-ticket-create-transaction>
  </div>

  
      <div class="col-sm-12 mt-2" *ngIf="requestFund?.statusId == 3 && (roleId == 7 || roleId == 1 || roleId == 2)">
        <div class="card bg-dark mb-2 p-1 d-flex align-items-center justify-content-center">
          <p class="font-weight-bold mb-0">Transaction History</p>
        </div>

        <div style="max-height: 300px; overflow-y: scroll">
          <table
            id="table"
            class="table table-striped table-hover"
            data-plugin="bootstrapTable"
            data-toolbar="#toolbar"
            data-search="true"
            data-search-align="left"
            data-show-export="true"
            data-show-columns="true"
            data-detail-view="false"
            data-mobile-responsive="true"
            data-pagination="true"
            data-page-list="[10, 25, 50, 100, ALL]"
          >
            <thead>
              <tr>
                <th data-sortable="true" data-field="id">Details</th>
                <th data-sortable="true" data-field="owner">Identity</th>
                <th data-sortable="true" data-field="project">Amount</th>
                <th data-field="task"><span class="d-none d-sm-block">Balance Before</span></th>
                <th data-field="finish"><span class="d-none d-sm-block">Balance After</span></th>
                <th data-field="finish"><span class="d-none d-sm-block">Status</span></th>
              </tr>
            </thead>
            <tbody>
              <tr
                class=" "
                *ngFor="let lastTenFund of lastTenFunds"
                (click)="viewOfficialReceipt(officialReceiptModal, lastTenFund)"
                class="odd"
                role="row"
              >
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    <span style="font-weight: bold" class="text-secondary">Reference Number :</span>
                    {{ lastTenFund?.referenceNumber }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span style="font-weight: bold" class="text-secondary">Fund Origin :</span>
                    {{ lastTenFund?.origin }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span style="font-weight: bold" class="text-secondary">Fund Destination :</span>
                    {{ lastTenFund?.destination }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    <span style="font-weight: bold" class="text-secondary">Requested by :</span>
                    {{ lastTenFund?.createdByUsername }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span style="font-weight: bold" class="text-secondary">Created date :</span>
                    {{ lastTenFund?.createdDate | date: "MMMM dd, yyyy hh:mm:ss a" }}
                  </div>
                </td>
                <td>
                  <div class="text-center">
                    <strong style="color: green">{{ makeMoney(lastTenFund?.amount) }} </strong>
                  </div>
                </td>
                <td>
                  <div class="text-center">
                    <strong *ngIf="lastTenFund?.statusId == 2">{{ makeMoney(lastTenFund?.openingBalance) }}</strong>
                    <i class="" *ngIf="lastTenFund?.statusId != 2">N/A</i>
                  </div>
                </td>
                <td>
                  <div class="text-center">
                    <strong *ngIf="lastTenFund?.statusId == 2">{{
                      makeMoney(lastTenFund?.openingBalance + lastTenFund?.amount)
                    }}</strong>
                    <i class="" *ngIf="lastTenFund?.statusId != 2">N/A</i>
                  </div>
                </td>
                <td>
                  <app-status-table-data [status]="lastTenFund?.statusId"></app-status-table-data>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template> -->

<ng-template #officialReceiptModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Compare Receipt</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!-- <div class="modal-body">
    <div class="row">
      <div class="col-sm-6">
        <div class="card bg-dark mb-2 p-1 d-flex align-items-center justify-content-center">
          <p class="font-weight-bold mb-0">Attachment: Official Receipt</p>
        </div>
        <div class="text-center" style="max-height: 410px; overflow-y: scroll">
          <img
            *ngIf="currentImage?.officialReceiptPhoto"
            [src]="buildUserPhoto(currentImage) | authImage | async"
            alt="VIP"
            onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
            style="width: 100%"
          />
          <img
            *ngIf="requestFund.officialReceiptPhotoUrl"
            [src]="requestFund.officialReceiptPhotoUrl"
            alt="VIP"
            onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
            style="width: 100%"
          />
          <img
            *ngIf="!currentImage?.officialReceiptPhoto && !requestFund.officialReceiptPhotoUrl"
            src="https://via.placeholder.com/64x64?text=VIP"
            alt="VIP"
            style="width: 100%"
          />
        </div>
      </div>

      <div class="col-sm-6">
        <div class="card bg-dark mb-2 p-1 d-flex align-items-center justify-content-center">
          <p class="font-weight-bold mb-0">History: Official Receipt</p>
        </div>
        <div class="text-center" style="max-height: 410px; overflow-y: scroll">
          <img
            *ngIf="currentImageinLastTen?.officialReceiptPhoto"
            [src]="buildUserHPhoto(currentImageinLastTen) | authImage | async"
            alt="VIP"
            onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
            style="width: 100%"
          />
          <img
          *ngIf="officialReceipt.officialReceiptPhotoUrl"
          [src]="officialReceipt.officialReceiptPhotoUrl"
          alt="VIP"
          onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
          style="width: 100%"
        />
          
          <img
            *ngIf="!currentImageinLastTen?.officialReceiptPhoto && !officialReceipt.officialReceiptPhotoUrl"
            src="https://via.placeholder.com/64x64?text=VIP"
            alt="VIP"
            style="width: 100%"
          />
        </div>
      </div>

     

    </div>
  </div> -->
</ng-template>

<ng-template #verifyApplicationModal let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Are you sure you want to approve this account?</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <button class="btn btn-sm btn-primary float-right" (click)="processVerification(true)">Proceed</button>
    <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #rejectApplicationModal let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Are you sure you want to reject this account?</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <textarea type="text" class="form-control" id="remm" [(ngModel)]="remarksStr"></textarea>
  </div>

  <div class="modal-body">
    <button class="btn btn-sm btn-danger float-right" (click)="processVerification(false)">Proceed</button>
    <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
      Cancel
    </button>
  </div>
</ng-template>

<!-- <ng-template #verificationDetails let-modal >
  <div class="bg-dark">
    <div class="modal-header justify-content-center">
      <img src="https://i.ibb.co/vsQZ3MC/Go-VIP-Logo.png" height="29.12" width="59.68" alt="Go-VIP-Logo" />

   

      <h4 class="modal-title ml-5">Account Verification Details</h4>
      
      <button type="button" class="close" aria-label="Close" (click)="closeTransaction()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  
  <div class="modal-body">
    <div class="col-sm-12 col-md-12 p-3">
      <h1>Details Here...</h1>
    </div>
  </div>
</ng-template> -->

<ng-template #verificationDetails let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Account Activation</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body pl-5 pr-5">
    <div class="row">
      <div class="col-sm-6">
        <h5 class="text-center">Personal Details</h5>
        <hr />
        <div class="row">
          <div class="col-3 text-sm mb-1">Firstname</div>
          <div class="col-9">
            <div class="text-sm mb-1 font-weight-bold">
              {{ selectedAccount.userFName }}
            </div>
          </div>

          <div class="col-3 text-sm mb-1">Middlename</div>
          <div class="col-9">
            <div class="text-sm mb-1 font-weight-bold">
              {{ selectedAccount.middleName }}
            </div>
          </div>

          <div class="col-3 text-sm mb-1">Lastname</div>
          <div class="col-9">
            <div class="text-sm mb-1 font-weight-bold">
              {{ selectedAccount.userLname }}
            </div>
          </div>

          <div class="col-3 text-sm mb-1">Email Address</div>
          <div class="col-9">
            <div class="text-sm mb-1 font-weight-bold">
              {{ selectedAccount.userEmail }}
            </div>
          </div>

          <div class="col-3 text-sm mb-1">Birthdate</div>
          <div class="col-9">
            <div class="text-sm mb-1 font-weight-bold">
              {{ selectedAccount.bday ? getDate(selectedAccount.bday) : "" }}
            </div>
          </div>

          <div class="col-3 text-sm mb-1">Gender</div>
          <div class="col-9">
            <div class="text-sm mb-1 font-weight-bold">
              {{ selectedAccount.gender }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-6">
        <h5 class="text-center">Address</h5>
        <hr />
        <div class="row">
          <div class="col-3 text-sm mb-1">Address 1</div>
          <div class="col-9">
            <div class="text-sm mb-1 font-weight-bold">
              {{ selectedAccount.address1 }}
            </div>
          </div>

          <div class="col-3 text-sm mb-1">Address 2</div>
          <div class="col-9">
            <div class="text-sm mb-1 font-weight-bold">
              {{ selectedAccount.address2 }}
            </div>
          </div>

          <div class="col-3 text-sm mb-1">Barangay</div>
          <div class="col-9">
            <div class="text-sm mb-1 font-weight-bold">
              {{ selectedAccount.brgy }}
            </div>
          </div>

          <div class="col-3 text-sm mb-1">Municipality</div>
          <div class="col-9">
            <div class="text-sm mb-1 font-weight-bold">
              {{ selectedAccount.municipality }}
            </div>
          </div>

          <div class="col-3 text-sm mb-1">Province</div>
          <div class="col-9">
            <div class="text-sm mb-1 font-weight-bold">
              {{ selectedAccount.province }}
            </div>
          </div>

          <div class="col-3 text-sm mb-1">Region</div>
          <div class="col-9">
            <div class="text-sm mb-1 font-weight-bold">
              {{ selectedAccount.region }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12">
        <hr />
        <h5 class="text-center">Selfies</h5>
        <hr />
        <div class="row">
          <div class="col-6 d-flex justify-content-center pl-0 pr-0 zoom">
            <img style="width: 100%" [src]="selectedAccount.selfiePath" />
          </div>
          <div class="col-6 d-flex justify-content-center pl-0 pr-0 zoom">
            <img style="width: 100%" [src]="selectedAccount.selfieWithIDPath" />
          </div>
        </div>
      </div>

      <div class="col-sm-12">
        <hr />
        <h5 class="text-center">ID's ({{ selectedAccount.governmentID }})</h5>
        <hr />
        <div class="row">
          <div class="col-6 d-flex justify-content-center zoom">
            <img style="width: 100%" [src]="selectedAccount.identificationCardPathFront" />
          </div>
          <div class="col-6 d-flex justify-content-center zoom">
            <img style="width: 100%" [src]="selectedAccount.identificationCardPathBack" />
          </div>
        </div>
      </div>

      <div class="col-sm-12" *ngIf="similarAccount">
        <div class="accordion" id="accordionExample" style="z-index: 3">
          <div class="card" style="z-index: 3">
            <div class="card-header m-0" id="headingOne">
              <h2 class="mb-0">
                <button
                  class="btn btn-block text-start"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Similar Data &nbsp; <i class="fa fa-angle-right"></i>
                </button>
              </h2>
            </div>

            <div
              id="collapseOne"
              class="collapse"
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
              style="z-index: 3"
            >
              <div class="row" style="z-index: 3">
                <div class="col-sm-6">
                  <hr />
                  <h5 class="text-center">Personal Details</h5>
                  <hr />
                  <div class="row">
                    <div class="col-3 text-sm mb-1">Firstname</div>
                    <div class="col-9">
                      <div class="text-sm mb-1 font-weight-bold">
                        {{ similarAccount.userFName }}
                      </div>
                    </div>

                    <div class="col-3 text-sm mb-1">Middlename</div>
                    <div class="col-9">
                      <div class="text-sm mb-1 font-weight-bold">
                        {{ similarAccount.middleName }}
                      </div>
                    </div>

                    <div class="col-3 text-sm mb-1">Lastname</div>
                    <div class="col-9">
                      <div class="text-sm mb-1 font-weight-bold">
                        {{ similarAccount.userLname }}
                      </div>
                    </div>

                    <div class="col-3 text-sm mb-1">Email Address</div>
                    <div class="col-9">
                      <div class="text-sm mb-1 font-weight-bold">
                        {{ similarAccount.userEmail }}
                      </div>
                    </div>

                    <div class="col-3 text-sm mb-1">Birthdate</div>
                    <div class="col-9">
                      <div class="text-sm mb-1 font-weight-bold">
                        {{ getDate(similarAccount.bday) }}
                      </div>
                    </div>

                    <div class="col-3 text-sm mb-1">Gender</div>
                    <div class="col-9">
                      <div class="text-sm mb-1 font-weight-bold">
                        {{ similarAccount.gender }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4">
                  <hr />
                  <h5 class="text-center">Address</h5>
                  <hr />
                  <div class="row">
                    <div class="col-3 text-sm mb-1">Address 1</div>
                    <div class="col-9">
                      <div class="text-sm mb-1 font-weight-bold">
                        {{ similarAccount.address1 }}
                      </div>
                    </div>

                    <div class="col-3 text-sm mb-1">Address 2</div>
                    <div class="col-9">
                      <div class="text-sm mb-1 font-weight-bold">
                        {{ similarAccount.address2 }}
                      </div>
                    </div>

                    <div class="col-3 text-sm mb-1">Barangay</div>
                    <div class="col-9">
                      <div class="text-sm mb-1 font-weight-bold">
                        {{ similarAccount.brgy }}
                      </div>
                    </div>

                    <div class="col-3 text-sm mb-1">Municipality</div>
                    <div class="col-9">
                      <div class="text-sm mb-1 font-weight-bold">
                        {{ similarAccount.municipality }}
                      </div>
                    </div>

                    <div class="col-3 text-sm mb-1">Province</div>
                    <div class="col-9">
                      <div class="text-sm mb-1 font-weight-bold">
                        {{ similarAccount.province }}
                      </div>
                    </div>

                    <div class="col-3 text-sm mb-1">Region</div>
                    <div class="col-9">
                      <div class="text-sm mb-1 font-weight-bold">
                        {{ similarAccount.region }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12">
                  <hr />
                  <h5 class="text-center">Selfies</h5>
                  <hr />
                  <div class="row">
                    <div class="col-6 d-flex justify-content-center pl-0 pr-0 zoom1">
                      <img style="width: 100%" [src]="similarAccount.selfiePath" />
                    </div>
                    <div class="col-6 d-flex justify-content-center pl-0 pr-0 zoom1">
                      <img style="width: 100%" [src]="similarAccount.selfieWithIDPath" />
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 mb-5" style="z-index: 3">
                  <hr />
                  <h5 class="text-center">ID's ({{ similarAccount.governmentID }})</h5>
                  <hr />
                  <div class="row">
                    <div class="col-6 d-flex justify-content-center zoom1">
                      <img style="width: 100%" [src]="similarAccount.identificationCardPathFront" />
                    </div>
                    <div class="col-6 d-flex justify-content-center zoom1">
                      <img style="width: 100%" [src]="similarAccount.identificationCardPathBack" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-12 mt-2">
        <div>
          <button class="btn btn-sm btn-info float-left" (click)="findMatch(selectedAccount)">Find Match</button>
        </div>

        <div>
          <button class="btn btn-sm btn-primary float-right mr-3" (click)="openVerify(verifyApplicationModal)">
            Approve
          </button>
        </div>
        <div>
          <button class="btn btn-sm btn-danger float-right mr-2" (click)="openReject(rejectApplicationModal)">
            Reject
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
