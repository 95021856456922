import { Injectable } from "@angular/core";
import { HttpBackend, HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Token } from "src/data/models/token.model";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Data } from "src/data/data";
import { environment } from "src/environments/environment";

// import { NgZone } from "@angular/core";
// import { ChatService } from "src/app/chat.service";
// import { Message } from "src/app/models/message";


@Injectable({
  providedIn: "root",
})
export class AuthService {
  private localStorageCacheService: LocalStorageCacheService;

  // title = 'ClientApp';  
  // txtMessage: string = '';  
  // uniqueID: string = new Date().getTime().toString();  
  // messages = new Array<Message>();  
  // message = new Message();  

  constructor(
    private httpClient: HttpClient,
    private data: Data,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService,
    // private chatService: ChatService,  
    // private _ngZone: NgZone
  ) {
 
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
  }
  private httpClientNoInterceptor: HttpClient;

  private baseAPIUrl = environment.API_URL;

  // sendMessage(): void {  
  //   if (this.txtMessage) {  
  //     this.message = new Message();  
  //     this.message.clientuniqueid = this.uniqueID;  
  //     this.message.type = "sent";  
  //     this.message.message = this.txtMessage;  
  //     this.message.date = new Date();  
  //     this.messages.push(this.message);  
  //     this.chatService.sendMessage(this.message);  
  //     this.txtMessage = '';  
  //   }  
  // }  
  // private subscribeToEvents(): void {  
  
  //   this.chatService.messageReceived.subscribe((message: Message) => {  
  //     this._ngZone.run(() => {  
  //       if (message.clientuniqueid !== this.uniqueID) {  
  //         message.type = "received";  
  //         this.messages.push(message);  
  //       }  
  //     });  
  //   });  
  // }  

  public setPinCode(email: string, password: string, pinCode : string): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/account/Enroll/Pin`;

    const json: any = {
      email: email,
      password: password,
      pinCode : pinCode
    };

    return this.httpClientNoInterceptor.post<any>(endPointUrl, json);
  }


  public getAccessToken(email: string, password: string,pinCode : string, footPrint : string): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/account/login`;

    const json: any = {
      email: email,
      password: password,
      pinCode : pinCode,
      footPrint : footPrint
    };

    return this.httpClientNoInterceptor.post<any>(endPointUrl, json);
  }

  public doResetPassword(email: string): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/account/ResetPassword`;

    const json: any = {
      email: email,
      baseUrl: environment.EMAIL_RESET_PASSWORD_REDIRECT,
    };

    return this.httpClientNoInterceptor.post<any>(endPointUrl, json);
  }

  public async validateAccessToken(loggedRoleLevelId: any): Promise<boolean> {
    const endPointUrl = `${this.baseAPIUrl}/api/account/authenticated`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");
    const email = this.localStorageCacheService.getStorage("email_address");
    const roleLevelId = this.localStorageCacheService.getStorage("role_level");
    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    const body = new FormData();

    const response: any = await this.httpClient
      .get(`${endPointUrl}`, { headers: httpHeaders })
      .pipe(catchError(this.handleError<any>("validateAccessToken")))
      .toPromise();

    if (!response) {
      return false;
    }
    if (response.details.email === email) {
     // this.subscribeToEvents();  
      this.localStorageCacheService.setStorage("id", response.details.id, 20000);
      return true && roleLevelId === loggedRoleLevelId;
    } else {
      return false;
    }
  }

  public async logOut(): Promise<boolean> {
    const endPointUrl = `${this.baseAPIUrl}/api/auth/logout`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    const body = new FormData();

    const response: any = await this.httpClient
      .post(`${endPointUrl}`, { headers: httpHeaders })
      .pipe(catchError(this.handleError<any>("validateAccessToken")))
      .toPromise();

    if (!response) {
      return false;
    } else {
      return true;
    }
  }

  // tslint:disable-next-line: typedef
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  // tslint:disable-next-line: typedef
  private log(message: string) {
    //
  }

  public getRoleLevelName(roleLevelId: any): string {
    switch (roleLevelId) {
      case "1":
        return "acm";
      case "2":
        return "manager";
      case "3":
        return "cashier";
      case "4":
        return "sales";
      default:
        return "none";
    }
  }

  public verifyEmail(token: string, expiration: string): Observable<any> {
    const url = `${this.baseAPIUrl}/api/Account/VerifyEmail`;

    const body = {
      token: token,
      expiration: expiration,
    };

    return this.httpClientNoInterceptor.post<any>(url, body);
  }
}
