<app-loader *ngIf="loader"></app-loader>

<div *ngIf="!loader" [class.isPrinting]="printService.isPrinting" class="col-lg-12 col-md-12 col-sm-12">
  <div class="col-lg-12 col-md-12 col-sm-12">
    <div class="card">
      <div class="list list-row">
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-md d-none d-md-block color-red">
              <span class="text-dark">Request ID: </span> <strong>{{ requestId }}</strong>
            </div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <a style="color: blue" (click)="printReceipt()">Print Receipt <span class="fa fa-print"></span></a> <br />
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" [formControl]="isA4" />
                <label class="form-check-label text-muted" for="flexCheckDefault" style="font-size: 12px">
                  Print via A4
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-6 col-md-6 col-sm-12 pull-right">
    <div class="card">
      <div class="list list-row">
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">
              <strong
                >{{ data?.billerCategory }} <br />
                {{ data?.billerName }}</strong
              >
              <!-- {{ data?.billerData.code }} -->
            </div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <!-- <img [src]="data?.billerData.logo" height="50" class="img-responsive" style="object-fit: cover" /> -->
            </div>
          </div>
        </div>
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Status</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x"><app-status-table-data [status]="data?.statusId"></app-status-table-data></div>
          </div>
        </div>

        <div *ngIf="data?.statusId==3 && roleLevel == 1" class="list-item" data-id="19">
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <button class="btn btn-sm btn-danger w-100" (click)="rejectTransaction()">
                <i class="fa fa-times"></i> &nbsp; Reject
              </button>
          </div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
                <button class="btn btn-sm btn-success w-100" (click)="completeTransaction()">
                  <i class="fa fa-check"></i> &nbsp; Complete
                </button>
            </div>
          </div>
        </div>

        
        <div *ngIf="data?.statusId==1 && roleLevel == 3" class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">
              Click "Retransact" button if you wish to reprocess the transacion. Please make sure that this transaction was not yet refunded to customer.
            </div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <button class="btn btn-sm btn-primary w-100" (click)="reProcessPayment()">
                <i class="fa fa-send"></i> &nbsp; Retransact
              </button>
            </div>
          </div>
        </div>

        <!-- *ngFor="let param of data?.billerData.meta" -->
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">
              <!-- {{ param?.label }} -->
            </div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <!-- {{ data?.requestJsonData[param?.field] }} -->
            </div>
          </div>
        </div>
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Biler Fee</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.billerFee | number: "1.2-2" }}</div>
          </div>
        </div>
        <hr />
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Total Amount</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.amount | number: "1.2-2" }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-6 col-md-6 col-sm-12 pull-left">
    <div class="card">
      <div class="list list-row">
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">
              <strong
                >{{ data?.transactByUser.cashierUser.firstName + " " + data?.transactByUser.cashierUser.lastName }}
                <br />
                {{ data?.transactByUser.email }}</strong
              >
            </div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Partner Reference Number</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.billerPartnerRefNo }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Account Number</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.billerAccountNo }}</div>
          </div>
        </div>


        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Other Details</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ requestJson?.secondField }}</div>
          </div>
        </div>


       

   

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Created Date</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.createdDate | date: "MMM dd, yyyy hh:mm:ss a" }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Completed Date</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.completedDate | date: "MMM dd, yyyy hh:mm:ss a" }}</div>
          </div>
        </div>

        <div *ngIf="!isTicketActive">
          <br/>

          <div class="w-100 d-flex justify-content-end">
            <button class="btn btn-sm btn-warning" style="width: 120px;" (click)="openTicket()" > Open Ticket &nbsp; <i class="fa fa-tag"></i> </button>
        </div>
        <br/> 
        </div>

      </div>
    </div>
  </div>

  <app-ticket-create-transaction 
    *ngIf="isTicketActive"
    [requestId]="requestId"
    transactionType="BILLS_PAYMENT_ECPAY"
    [username]="data.transactByUser.username">
  </app-ticket-create-transaction>


</div>
