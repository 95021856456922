import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VipcoTransactionService {

  private localStorageCacheService: LocalStorageCacheService;

  constructor(
    private httpClient: HttpClient,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
  }
  private httpClientNoInterceptor: HttpClient;

  private baseAPIUrl = environment.API_URL;
  
  public getVipsUsers(url: any): Observable<any> {
    let endPointUrl: any;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    endPointUrl = `${url}`;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getVIPCOTransactions(url: any): Observable<any> {
    let endPointUrl: any;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    endPointUrl = `${url}`;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }


  public getVipsLinkedUsers(): Observable<any> {
    let endPointUrl: any;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    endPointUrl = `${this.baseAPIUrl}/api/VIPCOTransactions/LinkedVipsUsers`;;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getVipsLinkedRiders(): Observable<any> {
    let endPointUrl: any;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    endPointUrl = `${this.baseAPIUrl}/api/VIPCOTransactions/LinkedRiders`;;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }
  
  public getVipsLinkedUsersByAdmin(id: any): Observable<any> {
    let endPointUrl: any;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    endPointUrl = `${this.baseAPIUrl}/api/VIPCOTransactions/LinkedVipsUsersByAdmin/${id}`;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }


  public transferVIPCoToAppData(id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/VIPCOTransactions/TransferVIPCoToApp`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      var json =  {id: id
      };
      
    return this.httpClient.post(endPointUrl, json, { headers: httpHeaders });
  }

  public transferRiderCreditWallet(amount: any, toRiderCredit: boolean): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/VIPCOTransactions/RiderCreditWallet`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    let json = {
      amount: amount,
      toRiderCredit: toRiderCredit
    };

    return this.httpClient.post(`${endPointUrl}`, json , { headers: httpHeaders });
  }

  public creditVIPLoot(requestId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/VIPCOTransactions/CreditVIPLoot`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    let json = {
      requestId: requestId
    };

    return this.httpClient.post(`${endPointUrl}`, json , { headers: httpHeaders });
  }

  public getAllRiders(): Observable<any> {
    let endPointUrl: any;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    endPointUrl = `${this.baseAPIUrl}/api/VIPCOTransactions/GetAllRiders`;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getAllMerchants(): Observable<any> {
    let endPointUrl: any;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    endPointUrl = `${this.baseAPIUrl}/api/VIPCOTransactions/GetAllMerchants`;;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public reactivateAccount(userId: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/GoVIPX/ReactivateAccount`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    let json = {
      vipsUserId: userId
    };

    return this.httpClient.post(`${endPointUrl}`, json , { headers: httpHeaders });
  }
}
