<app-loader *ngIf="!requestFundFormGroup"></app-loader>
<app-loader *ngIf="processCreate"></app-loader>
<div *ngIf="requestFundFormGroup" id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">Create Request Fund</h2>
          <small class="text-highlight font-weight-normal font-italic">Make sure all the information are correct</small>
        </div>
        <div class="flex"></div>

        <div>
          <div
            data-toggle="modal"
            data-target="#modal-left"
            data-toggle-class="fade-left"
            data-toggle-class-target=".animate"
            class="btn btn-md btn-info"
          >
            <span class="d-none d-sm-inline mx-1">Save</span>
            <i data-feather="arrow-right"></i>
          </div>
        </div>

        <!-- (click)="saveRequest FundUser()"  -->
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="padding">
        <div class="row">
          <div class="col-md-8">
            <div class="card">
              <div class="card-header">
                <strong>Request Fund Information</strong>
              </div>
              <div class="card-body">
                <form>
                  
                  <app-textbox-currency
                    [parent-form-group]="requestFundFormGroup"
                    tab-index-start="0"
                    control-name="amount"
                    [read-only]="false"
                    [error]="requestFundFormGroup?.controls.amount.errors | estatus"
                    [error-message]="requestFundFormGroup?.controls.amount.errors | econtent"
                    read-only="false"
                    number-only="false"
                    label="Amount *"
                    placeholder="0.00"
                    max-length="20"
                  >
                  </app-textbox-currency>

                  <app-textbox
                    [parent-form-group]="requestFundFormGroup"
                    tab-index-start="0"
                    control-name="referenceNumber"
                    [error]="requestFundFormGroup?.controls.referenceNumber.errors | estatus"
                    [error-message]="requestFundFormGroup?.controls.referenceNumber.errors | econtent"
                    read-only="false"
                    alpha-numeric="true"
                    label="Reference number *"
                    placeholder="Reference number"
                  >
                  </app-textbox>

                  <app-dropdown-identifier
                    place-holder="Request origin"
                    [parent-form-group]="requestFundFormGroup"
                    control-name="origin"
                    label="Origin *"
                    identifier="REQUEST_FUNDS_ORIGIN"
                    [error]="requestFundFormGroup?.controls.origin.errors | estatus"
                    [error-message]="requestFundFormGroup?.controls.origin.errors | econtent"
                  >
                  </app-dropdown-identifier>

                  <app-dropdown-identifier
                    place-holder="Request destination"
                    [parent-form-group]="requestFundFormGroup"
                    control-name="destination"
                    label="Destination *"
                    identifier="REQUEST_FUNDS_DESTINATION"
                    [error]="requestFundFormGroup?.controls.destination.errors | estatus"
                    [error-message]="requestFundFormGroup?.controls.destination.errors | econtent"
                  >
                  </app-dropdown-identifier>
                </form>
              </div>
            </div>

            <div class="card">
              <div class="row">
                <div class="col-12">
                  <img src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file9d89d099-ce0b-4d79-a4a6-83b3971a2e0d.png" 
                      alt="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file9d89d099-ce0b-4d79-a4a6-83b3971a2e0d.png" style="max-width: 100%;" width="100%">
                </div>
                
              </div>

              <!-- <div class="px-4 py-4">
                <div class="row mb-2">
                  <div class="col-6">
                    <small class="text-muted">First Name</small>
                    <div class="my-2">{{ user?.managerUser.firstName }}</div>
                  </div>
                  <div class="col-6">
                    <small class="text-muted">Last Name</small>
                    <div class="my-2">{{ user?.managerUser.lastName }}</div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-6">
                    <small class="text-muted">Username</small>
                    <div class="my-2">{{ user?.username }}</div>
                  </div>
                  <div class="col-6">
                    <small class="text-muted">Email Address</small>
                    <div class="my-2">{{ user?.email }}</div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-6">
                    <small class="text-muted">Manager ID</small>
                    <div class="my-2">{{ user?.managerUser?.managerId }}</div>
                  </div>
                  <div class="col-6">
                    <small class="text-muted">Contact Number</small>
                    <div class="my-2">{{ user?.managerUser?.contactNumber }}</div>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-6">
                    <small class="text-muted">Birth Date</small>
                    <div class="my-2">{{ user?.managerUser?.birthDate }}</div>
                  </div>
                </div>
                <div>
                  <small class="text-muted">Address</small>
                  <div class="my-2">{{ user?.managerUser?.address }}</div>
                </div>
              </div> -->
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <div class="card-header">
                <strong>Original Official Receipt <span class="text-danger">*</span></strong>
              </div>
              <div class="card-body">
                <app-textarea
                  [parent-form-group]="requestFundFormGroup"
                  tab-index-start="0"
                  control-name="managerRemarks"
                  [error]="requestFundFormGroup?.controls.managerRemarks.errors | estatus"
                  [error-message]="requestFundFormGroup?.controls.managerRemarks.errors | econtent"
                  read-only="false"
                  label="Note"
                  placeholder="Note ... "
                >
                </app-textarea>


                  <ngx-dropzone [multiple]="false" (change)="onSelect($event)" style="height:350px">
                    <ngx-dropzone-label>Click to upload or drop the image here!</ngx-dropzone-label>
                  
                    <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" *ngFor="let f of files" [file]="f" 
                    [removable]="true" (removed)="onRemove(f)">
                      <ngx-dropzone-label></ngx-dropzone-label>
                    </ngx-dropzone-image-preview>
                  </ngx-dropzone>

                <!-- <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <input type="file" (change)="onPhotoChange($event)" class="custom-file-input" id="inputFile" />
                    <label class="custom-file-label" for="inputFile">{{ fileName }}</label>
                  </div>
                  <span *ngIf="requestFundFormGroup?.controls.officialReceiptPhoto.errors | estatus" class="text-error"
                    ><small>{{ requestFundFormGroup?.controls.officialReceiptPhoto.errors | econtent }}</small></span
                  >
                </div>
                <div
                  *ngIf="!requestFundFormGroup?.controls.officialReceiptPhoto.value"
                  class="dropzone white b-a b-3x b-dashed b-primary p-a rounded p-5 text-center"
                >
                  <br />
                  <br />
                  <br />
                  <h4 class="my-4">Original official receipt.</h4>
                  <br />
                  <br />
                  <br />
                </div>
                <img
                  *ngIf="requestFundFormGroup?.controls.officialReceiptPhoto.value"
                  [src]="requestFundFormGroup?.controls.officialReceiptPhoto.value | authImage | async"
                  alt="VIP"
                  onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
                  style="width: 100%"
                /> -->
              </div>


           
              


            </div>
          </div>
          <!-- <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <strong>Personal Information</strong>
              </div>
              <div class="card-body">
                <form>

                  <app-textbox [parent-form-group]="requestFundFormGroup" tab-index-start="0" control-name="firstName"
                    [error]="requestFundFormGroup?.controls.firstName.errors | estatus"
                    [error-message]="requestFundFormGroup?.controls.firstName.errors | econtent" read-only="false"
                    no-special-char="false" label="First name" placeholder="First name">
                  </app-textbox>

                  <app-textbox [parent-form-group]="requestFundFormGroup" tab-index-start="0" control-name="lastName"
                    [error]="requestFundFormGroup?.controls.lastName.errors | estatus"
                    [error-message]="requestFundFormGroup?.controls.lastName.errors | econtent" read-only="false"
                    no-special-char="false" label="Last name" placeholder="Last name">
                  </app-textbox>


                  <app-textarea [parent-form-group]="requestFundFormGroup" tab-index-start="0" control-name="address"
                    [error]="requestFundFormGroup?.controls.address.errors | estatus"
                    [error-message]="requestFundFormGroup?.controls.address.errors | econtent" read-only="false"
                    no-special-char="false" label="Address" placeholder="Address">
                  </app-textarea>

                  <app-textbox [parent-form-group]="requestFundFormGroup" tab-index-start="0"
                    control-name="contactNumber" [error]="requestFundFormGroup?.controls.contactNumber.errors | estatus"
                    [error-message]="requestFundFormGroup?.controls.contactNumber.errors | econtent" read-only="false"
                    no-special-char="false" label="Contact No." placeholder="Contact No.">
                  </app-textbox>

                  <app-datepicker [parent-form-group]="requestFundFormGroup" tab-index-start="0"
                    control-name="birthDate" [error]="requestFundFormGroup?.controls.birthDate.errors | estatus"
                    [error-message]="requestFundFormGroup?.controls.birthDate.errors | econtent" no-special-char="false"
                    label="Birth Date " placeholder="Birth Date ">
                  </app-datepicker>

                </form>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <strong>Emergency Contact</strong>
              </div>
              <div class="card-body">
                <form>

                  <app-textbox [parent-form-group]="requestFundFormGroup" tab-index-start="0"
                    control-name="emergencyContactNumber"
                    [error]="requestFundFormGroup?.controls.emergencyContactNumber.errors | estatus"
                    [error-message]="requestFundFormGroup?.controls.emergencyContactNumber.errors | econtent"
                    read-only="false" no-special-char="false" label="Mobile No." placeholder="Mobile Number">
                  </app-textbox>

                  <app-textbox [parent-form-group]="requestFundFormGroup" tab-index-start="0"
                    control-name="emergencyContactName"
                    [error]="requestFundFormGroup?.controls.emergencyContactName.errors | estatus"
                    [error-message]="requestFundFormGroup?.controls.emergencyContactName.errors | econtent"
                    read-only="false" no-special-char="false" label="Full Name" placeholder="Full Name">
                  </app-textbox>

                  <app-textbox [parent-form-group]="requestFundFormGroup" tab-index-start="0"
                    control-name="emergencyContactRelationship"
                    [error]="requestFundFormGroup?.controls.emergencyContactRelationship.errors | estatus"
                    [error-message]="requestFundFormGroup?.controls.emergencyContactRelationship.errors | econtent"
                    read-only="false" no-special-char="false" label="Relationship" placeholder="Relationship">
                  </app-textbox>
                </form>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<div id="modal-left" class="modal fade" data-backdrop="true">
  <div class="modal-dialog animate">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title text-md">Updating of Request Fund</div>
        <button class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="p-4 text-center">
          <p>Are you sure you want continue?</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-dismiss="modal">Close</button>
        <button type="button" (click)="actionEventCreateRequestFund()" class="btn btn-primary" data-dismiss="modal">
          Save Changes
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
</div>
