import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CashierUser } from "src/app/models/cashier-user";
import { CashierUsersService } from "src/data/services/web/cashier-users.service";
import { ModalConfirmationComponent } from "../modal-confirmation/modal-confirmation.component";
import { finalize, map } from "rxjs/operators";
import { CashierUserError } from "src/app/models/cashier-user-error";
import * as dayjs from "dayjs";
import { KeyValue } from "@angular/common";
import { cloneDeep, without } from "lodash";
import { Schedule } from "src/app/models/schedule";
import { CashierUserScheduleService } from "src/app/services/cashier-user-schedule.service";

@Component({
  selector: "app-cashier-user-schedule-edit",
  templateUrl: "./cashier-user-schedule-edit.component.html",
  styleUrls: ["./cashier-user-schedule-edit.component.less"],
})
export class CashierUserScheduleEditComponent implements OnInit {
  public cashierUser: CashierUser;
  public cashierUserDetailFormGroup: FormGroup;
  public errors: CashierUserError;
  public isSubmitting: boolean;

  // Schedule
  public hours: number[] = Schedule.HOURS_SEED; // The hours to show in 0-24 format.
  public defaultSchedule: Schedule = Schedule.DAYS_SEED;
  public newSchedule: Schedule = Schedule.DAYS_SEED;

  constructor(
    private bsModalRefConfirmation: BsModalRef,
    private cashierUsersService: CashierUsersService,
    private cashierUserScheduleService: CashierUserScheduleService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    //
  }

  ngOnInit(): void {}
}
