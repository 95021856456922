<app-loader *ngIf="!managerFiles"></app-loader>

<div id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">List of Manager Files</h2>
          <small class="text-highlight font-weight-normal font-italic">JuanPay list of Manager Files</small>
        </div>
        <div class="flex"></div>
        <div>
          <div>
            <button class="btn btn-md btn-primary w-100" (click)="openFileUpload(uploadmodal)">
              <i class="fa fa-upload"></i> &nbsp; Upload File
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="padding" style="padding-right: 0px">
        <div class="card" style="overflow-y: scroll">
          <table
            id="datatable"
            class="table table-striped table-hover"
            role="grid"
            aria-describedby="datatable_info"
            style="max-height: 600px"
          >
            <thead>
              <tr>
                <th data-sortable="true" data-field="name">File Name</th>
                <th data-field="finish"><span class="d-none d-sm-block">Date Upload</span></th>
                <th data-sortable="true" data-field="name">Purpose</th>
              </tr>
            </thead>
            <tbody>
              <tr
                style="cursor: pointer"
                class="odd"
                role="row"
                *ngFor="let managerFile of managerFiles?.data"
                (click)="viewContent(contentModal, managerFile)"
              >
                <td>
                  <small class="">{{ managerFile?.fileName }}</small>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ managerFile?.createdDate | date: "dd-MMM-yyyy hh:mm:ss a" }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">{{ managerFile?.purpose }}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<ng-template #uploadmodal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Upload Files</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div>
      <div class="page-content page-container" id="page-content">
        <div class="padding" *ngIf="fileUploadFormGroup">
          <!-- <div>
            <form (ngSubmit)="onSubmit()">
              <div class="form-group">
                <input type="file" name="image" />
              </div>
              <div class="form-group">
                <button class="btn btn-primary">Submit</button>
              </div>
            </form>
          </div> -->

          <div>
            <app-dropdown-identifier
              place-holder="Purpose"
              [parent-form-group]="fileUploadFormGroup"
              control-name="purpose"
              label="Purpose :"
              identifier="FILE_UPLOAD_PURPOSE"
            >
            </app-dropdown-identifier>
          </div>
          <div>
            <app-dropdown-identifier
              place-holder="Purpose"
              [parent-form-group]="fileUploadFormGroup"
              control-name="meta"
              label="Type:"
              identifier="APPLICATION_META"
            >
            </app-dropdown-identifier>
          </div>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <input type="file" (change)="onPhotoChange($event)" class="custom-file-input" id="inputFile" />
              <label class="custom-file-label" for="inputFile">{{ fileName }}</label>
            </div>
            <span *ngIf="fileUploadFormGroup?.controls.photoFile.errors | estatus" class="text-error"
              ><small>{{ fileUploadFormGroup?.controls.photoFile.errors | econtent }}</small>
            </span>
          </div>
          <div
            *ngIf="!fileUploadFormGroup?.controls.photoFile.value"
            class="dropzone white b-a b-3x b-dashed b-primary p-a rounded p-5 text-center"
          >
            <br />
            <br />
            <br />
            <h4 class="my-4">Upload Photo Here.</h4>
            <span class="text-muted d-block mb-4"
              >(Allowed photo format: <strong>.png </strong> <strong>.jpeg </strong> <strong>.jpg</strong> )</span
            >
            <br />
            <br />
            <br />
          </div>
          <img
            *ngIf="fileUploadFormGroup?.controls.photoFile.value"
            [src]="fileUploadFormGroup?.controls.photoFile.value | authImage | async"
            alt="VIP"
            onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
            style="width: 100%"
          />
          <button class="btn btn-sm btn-primary mt-2 float-right" (click)="submitFile()">
            <i class="fa fa-upload"></i> &nbsp; Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contentModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ managerFile.fileName }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="p-4 text-center">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6">
          <p class="text-secondary text-left font-weight-bold">
            Manager User:
            <span class="font-weight-bold text-dark"> {{ managerFile.managerUsername }}</span>
          </p>
          <p class="text-secondary text-left font-weight-bold">
            Purpose:
            <span class="font-weight-bold text-dark"> {{ managerFile.purpose }}</span>
          </p>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
          <p class="text-secondary text-left font-weight-bold">
            Date Uploaded:
            <span class="font-weight-bold text-dark">
              {{ managerFile.createdDate | date: "dd-MMM-yyyy hh:mm:ss a" }}</span
            >
          </p>
        </div>
      </div>

      <img
        *ngIf="managerFile?.photo"
        [src]="buildUserPhoto(managerFile) | authImage | async"
        alt="VIP"
        onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
        style="width: 100%"
      />
      <img *ngIf="!managerFile?.photo" src="https://via.placeholder.com/64x64?text=VIP" alt="VIP" />
    </div>
  </div>
</ng-template>
