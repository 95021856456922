import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';

import { Label } from 'ng2-charts';
import { WalletService } from 'src/data/services/web/wallet.service';

import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { Data } from 'src/data/data';
import { DatePipe } from '@angular/common';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';


@Component({
  selector: 'app-chart-report',
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: '200px',
        opacity: 1,
        backgroundColor: 'yellow'
      })),
      state('closed', style({
        height: '100px',
        opacity: 0.8,
        backgroundColor: '#c6ecff'
      })),
      transition('open => closed', [
        animate('2s')
      ]),
      transition('closed => open', [
        animate('2s')
      ]),
    ]),
  ],
  templateUrl: './chart-report.component.html',
  styleUrls: ['./chart-report.component.less']
})
export class ChartReportComponent implements OnInit {


  @Input() isEnterprise: boolean = false;
  @Input() provider: string;

  @Input() role: Number;
  @Input() hideBillers: boolean;
  @Input() title: string;
  @Input() providerA: string;
  @Input() providerB: string;
  @Input() apiRoute: string;
  public barChartLabels: Label[] = [];// = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  public barChartData: ChartDataSets[]; //[
  //   { data: [65, 59, 80, 81, 56, 55, 40], 
  //     label: 'Failed'
  //    },
  //   { data: [28, 48, 40, 19, 86, 27, 90], 
  //     label: 'Completed'
  //    },
  //   { data: [28, 48, 40, 19, 86, 27, 90], 
  //     label: 'Pending'
  //    }
  // ];

  providerHashA : any;
  providerHashB : any;

  loader = true;
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartOptions: ChartOptions;
  perDayCosts;

  constructor(private walletService : WalletService,
    private datepipe: DatePipe) {
    PNotifyButtons;
   }
  ngOnInit(): void {
  
    this.providerA = this.isEnterprise ? this.apiRoute + this.provider +  'A' : this.apiRoute + 'A';
    this.providerB = this.isEnterprise ? this.apiRoute + this.provider +'B' : this.apiRoute + 'B';
    this.providerHashA = '#'+this.providerA;
    this.providerHashB = '#'+this.providerB;
   this.reportChart(this.apiRoute);
  }

  sorting(key_to_sort_by) {

    function sortByKey(a, b) {
        var x = a[key_to_sort_by];
        var y = b[key_to_sort_by];
        return ((x > y) ? -1 : ((x < y) ? 1 : 0));
    }

    this.transactionPerDay.billersBreakdowns.sort(sortByKey);
    this.transactionPerDay.managersBreakdowns.sort(sortByKey);
}



  
 reportChart(apiRoute){

    this.walletService.getReportsTransactions(7,apiRoute,this.isEnterprise,this.provider).subscribe(
      (result) => {
        var perDayCosts = result.data.result.perDayCost;
        this.perDayCosts = perDayCosts
        var barChartDataTemporary = [];
        var completed = {
          data : [],
          minBarLength: 7,
          label : 'Completed',
          backgroundColor: '#007bff',
          hoverBackgroundColor: '#007bff',
          borderColor: '#007bff',
          hoverBorderColor: '#007bff'

        }
        var pending = {
          data : [],
          minBarLength: 7,
          label : 'Pending',
          backgroundColor: '#ffc107',
          hoverBackgroundColor: '#ffc107',
          borderColor: '#ffc107',
          hoverBorderColor: '#ffc107'
        }
        var failed = {
          data : [],
          minBarLength: 7,
          label : 'Failed',
          backgroundColor: '#dc3545',
          hoverBackgroundColor: '#dc3545',
          borderColor: '#dc3545',
          hoverBorderColor: '#dc3545'
        }


        this.barChartLabels = [];
        this.barChartData = [];


        perDayCosts.forEach(perDayCost => {
          this.barChartLabels.push(this.datepipe.transform(perDayCost.date, 'yyyy-MM-dd'));
          completed.data.push(perDayCost.successCost);
          pending.data.push(perDayCost.pendingCost);
          failed.data.push(perDayCost.failedCost);
        });

        barChartDataTemporary.push(completed);
        barChartDataTemporary.push(pending);
        barChartDataTemporary.push(failed);
        this.barChartData = barChartDataTemporary;

        this.barChartOptions = {
          responsive: true,
          // We use these empty structures as placeholders for dynamic theming.
          scales: { xAxes: [{}], yAxes: [{}] },
          plugins: {
            datalabels: {
              anchor: 'end',
              align: 'end',
            }
          },
          title: {
            display: true,
            position: "top",
            text: this.title,
            fontSize: 12,
            fontColor: "#666"
          },
          legend: {
            display: true,
            position: "bottom",
            labels: {
              fontColor: "#999",
              fontSize: 14
            }
          }
        };
        var myDate = new Date();
        this.getReportsTransactionsBillers(myDate);
        this.loader = false;
      },
      (error) => {
       
        // PNotify.error({
        //   title: "Error",
        //   text: "Something went wrong.",
        // });
        this.loader = false;
      }
    );

    
  }


  transactionPerDay : any;
  loaderTabs = true;
  sDate : any;
  getReportsTransactionsBillers(date : any){
    this.transactionPerDay = true;
    this.sDate = this.datepipe.transform(date, 'yyyy-MM-dd');
    this.walletService.getReportsTransactionsBillers(this.sDate,this.apiRoute,this.isEnterprise,this.provider).subscribe(
      (result) => {
        this.transactionPerDay = result.data.result;
       
        this.loaderTabs = false;
      },
      (error) => {
       
        // PNotify.error({
        //   title: "Error",
        //   text: "Something went wrong.",
        // });
        this.loaderTabs = false;
      }
    );
  }
  
 
  // public barChartPlugins = [pluginDataLabels];

  
 

  

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    
  }

  public randomize(): void {
    // Only Change 3 values
    this.barChartData[0].data = [
      Math.round(Math.random() * 100),
      59,
      80,
      (Math.random() * 100),
      56,
      (Math.random() * 100),
      40 ];
  }
}