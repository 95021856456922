<app-loader *ngIf="loader"></app-loader>
<div class="container-fluid --home">
    <div class="row">
        <div class="col-12">
            <div class="header mt-3">
                <div class="text">
                    <img src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file260fa82a-0150-4c07-a413-e289dffd98fb.png" width="65px" height="65px" alt=""> 
                    DASHBOARD
                </div>
            </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 pl-5 pr-4 mb-3" style="max-height: 290px;">
          <div class="box p-0 m-0">
            <app-widget-basic-profile></app-widget-basic-profile>
          </div>
        </div>
        <div class="col-lg-7 col-md-12 col-sm-12 pl-5 pr-5">
            <div class="row" style="height: 290px;">
                <div class="col-12 pt-2">
                    <h5>Table for Vips User - Total Linked Users : {{users.length}}</h5>
                    <div class="row mt-2 p-2" [formGroup]="filterUserFormGroup">
                      <div class="col-lg-3 col-md-12 col-sm-12 pr-0">
                        <ng-select [items]="userOptions" 
                          placeholder="Select User Type"
                          bindLabel="text" 
                          bindValue="id" 
                          formControlName="userType">
                        </ng-select>
                      </div>
                      <div class="col-lg-9 col-md-12 col-sm-12 pr-0">
                            <div class="input-group">
                              <input 
                                type="search" 
                                class="form-control rounded" 
                                placeholder="Search" 
                                formControlName="searchString"
                                appDebounceClick
                                [debounceTime]="500"
                                aria-label="Search" 
                                (click)="clearValue()"
                                aria-describedby="search-addon" />
                                <button type="button" class="btn btn-outline-primary">Search</button>
                            </div>
                        </div>
                        <!-- <div class="col-6" [formGroup]="searchByFormGroup">
                            <input
                            type="text"
                            placeholder="Date Range"
                            class="form-control"
                            bsDaterangepicker
                            formControlName="dateRange"
                            style="background-color: white; color: black; height: 37px;"
                            [bsConfig]="{
                            rangeInputFormat: 'YYYY-MM-DD',
                            dateInputFormat: 'YYYY-MM-DD',
                            showWeekNumbers: false
                            }"
                        />
                        </div> -->

                        <div class="col-12 mt-3"  style="max-height: 210px; overflow-y: scroll;">
                            <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
                                <thead>
                                  <tr>
                                    <th data-sortable="true" data-field="name">
                                      <div>Date</div>
                                    </th>
                                    <th data-sortable="true" data-field="value">
                                      <div>User Type</div>
                                    </th>
                                    <th data-sortable="true" data-field="description">
                                      <div>Mobile No</div>
                                    </th>
                                    <th data-sortable="true" data-field="description">
                                        <div>Full Name</div>
                                    </th>
                                    <!-- <th data-field="finish">
                                        <span class="d-none d-sm-block">Status</span>
                                    </th> -->
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    *ngFor="let user of users | sortDesc:'Createdate'" 
                                    (click)="viewUser(user?.id)"
                                    style="cursor: pointer"
                                    class="odd"
                                    role="row"
                                  >
                                    <td class="flex">
                                      <div class="item-except text-sm h-1x">
                                        {{ user?.Createdate | date: "dd-MMM-yyyy"}}
                                      </div>
                                    </td>
                                    <td class="flex">
                                      <div class="item-except text-sm h-1x">
                                        {{ getUserType(user?.Usertype) }}
                                      </div>
                                    </td>
                                    <td class="flex">
                                      <div class="item-except text-sm h-1x">
                                        {{ user?.MobileNo }}
                                      </div>
                                    </td>
                                    <td class="flex">
                                        <div class="item-except text-sm h-1x">
                                          {{ user?.FullName }}
                                        </div>
                                      </td>
                    
                                    <!-- <td class="flex" style="max-width: 75px">
                                      <div class="item-except">
                                        <label *ngIf="user?.status == true">
                                       Active
                                        </label>
                    
                                        <label *ngIf="user?.status != true">
                                         
                                          Inactive
                                        </label>
                                      </div>
                                    </td> -->
                                  </tr>
                                </tbody>
                              </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 pl-5 pr-5">
            <div class="row box mt-3">
                <div class="col-12 pt-2">
                    <h5>Table for Vips Transactions</h5>
                    <div class="row mt-2 p-2" [formGroup]="searchTranscationFormGroup">
                      <div class="col-lg-2 col-md-6 col-sm-12">
                        <!-- <button class="btn btn-success btn-sm" *ngIf="selectedManager != 0" (click)="getTransactionExcel()"><i class="fa fa-file-excel"></i> &nbsp; Export</button> -->
                      </div>

                      <div class="col-lg-3 col-md-6 col-sm-12">
                        <input
                          type="text"
                          placeholder="Date Range"
                          class="form-control"
                          bsDaterangepicker
                          formControlName="dateRange"
                          style="background-color: white; color: black;"
                          [bsConfig]="{
                            rangeInputFormat: 'YYYY-MM-DD',
                            dateInputFormat: 'YYYY-MM-DD',
                            showWeekNumbers: false
                          }"
                        />
                      </div>

                      <div class="col-lg-7 col-md-7 col-sm-12 pr-0">
                          <div class="input-group">
                              <input type="search" class="form-control rounded" formControlName="searchString" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                              <button type="button" (click)="getTransaction()" class="btn btn-outline-primary mr-2">Search</button>
                          </div>
                      </div>
        
                        <div class="col-12 mt-3"  style="max-height: calc(100vh - 659x); overflow-y: scroll;">
                          <table id="datatable" class="table table-striped table-hover " role="grid" aria-describedby="datatable_info">
                              <thead>
                                <tr>
                                  <th data-sortable="true" data-field="name">
                                    <div>Date</div>
                                  </th>
                                  <th data-sortable="true" data-field="value">
                                    <div>Transaction Type</div>
                                  </th>
                                  <th data-sortable="true" data-field="description">
                                    <div>User Type</div>
                                  </th>
                                  <th data-sortable="true" data-field="description">
                                      <div>Reference</div>
                                  </th>
                                  <th data-sortable="true" data-field="description">
                                      <div>Rewards</div>
                                  </th>
                                  <th data-field="finish">
                                      <span class="d-none d-sm-block">Status</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  *ngFor="let transaction of transactions?.data | paginate: { itemsPerPage: pageRows, currentPage: p, totalItems: transactions?.totalRecords }"
                                  (click)="viewUser(transaction?.id)"
                                  style="cursor: pointer"
                                  class="odd"
                                  role="row"
                                >
                                  <td class="flex">
                                    <div class="item-except text-sm h-1x">
                                      {{ transaction?.createdDate | date: "dd-MMM-yyyy" }}
                                    </div>
                                  </td>
                                  <td class="flex">
                                      <div class="item-except text-sm h-1x">
                                        {{ transaction?.transactionName.split(" - ")[0] }}
                                      </div>
                                    </td>
                                  <td class="flex">
                                    <div class="item-except text-sm h-1x">
                                      {{ transaction?.transactionName.split(" - ")[1] }}
                                    </div>
                                  </td>
                                  <td class="flex">
                                    <div class="item-except text-sm h-1x">
                                      {{ transaction?.requestId }}
                                    </div>
                                  </td>
                                  <td class="flex">
                                      <div class="item-except text-sm h-1x">
                                        {{ transaction?.amount | number : "1.2-2"}}
                                      </div>
                                    </td>
                  
                                  <td class="flex" style="max-width: 75px">
                                      <app-status-table-data [status]="transaction?.statusId"></app-status-table-data>
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                      </div>

                      <div class="col-12">
                        <div class="row mt-5" *ngIf="transactions">
                          <div class="col-sm-12 col-md-5">
                            <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                              <div class="col-sm-12 col-md-12">
                                <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                                  <span class="font-weight-bold"
                                    >Showing {{ (transactions?.pageNumber - 1) * transactions?.pageSize + 1 }} to
                                    {{ (transactions?.pageNumber - 1) * transactions?.pageSize + transactions?.data?.length }} of
                                    {{ transactions?.data?.length }} entries</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-12 col-md-7" style="float: right">
                            <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                              <ul class="pagination" style="float: right; margin-right: 36px">
                                <pagination-controls (pageChange)="getTransaction(buildPageUrl($event, 10))"></pagination-controls>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div #govipxQR hidden>
    <ngx-qrcode
      [elementType]="elementType"
      [errorCorrectionLevel]="correctionLevel"
      [value]="value"
      cssClass="bshadow"
      [width]="300"
      style='margin-left:21px; margin-top:290px; border-radius: 2%'
    ></ngx-qrcode>
  </div>
  
  <div #vipLogo hidden>
    <img src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file9828bf15-729f-4650-97a6-476c13f02ce0.png" alt="Go-VIP-Logo-Logo-Small" />
  </div>
  
  <ng-template #myQRTemplateModal let-modal>
    <!-- background: url(../../../assets/images/png\ QR.png) !important 
    linear-gradient(#044660, #02554c)-->
    
    <button class="btn btn-dark btn-lg" (click)="getQR(myQRTemplate)">Print &nbsp; <i class="fa fa-print"></i></button>
    <div class="d-flex justify-content-center">
      <div #myQRTemplate >
          <div style="background: url(../../../assets/images/png\ QR.png);
          background-repeat: no-repeat !important;
          background-size: 210mm 297mm !important;
          width: 210mm !important;
          height: 297mm !important;" 
          >
            <div style='display:flex; justify-content: center'>
              <ngx-qrcode
                [elementType]="elementType"
                [errorCorrectionLevel]="correctionLevel"
                [value]="value"
                cssClass="bshadow"
                [width]="300"
                style='margin-left:21px; margin-top:290px; border-radius: 2%'
              ></ngx-qrcode>
            </div>
            <div style='display:flex; justify-content: center; padding-top: 45px'>
                <h3 
                style='margin-left:27px; font-family: "Segoe UI", Arial, sans-serif; max-width: 360px; text-align:center; font-weight: 700'
                >
                    {{dti}}
                </h3>
            </div>
          </div>
        </div>
    </div>
  </ng-template>