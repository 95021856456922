


import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { ECPayService } from 'src/data/services/web/ecpay.service';

import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { CurrencyPipe } from '@angular/common';
import { PrintService } from 'src/data/services/web/print.service';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-ec-sss-validate',
  templateUrl: './ec-sss-validate.component.html',
  styleUrls: ['./ec-sss-validate.component.less']
})
export class EcSssValidateComponent implements OnInit {

  @Input("application") application: any;
  billerFormGroup : FormGroup;
  formBuilder : FormBuilder;
  ecpayService : ECPayService;
  public emptyString = "";
  public billers : any = [];
  public biller : any;
  public billerCode : any;
  public billerTypes : any = [];
  public billersByBillerTypes : any = [];
  public selectedBillerCode : any;

  public activeTab = 1;
  public loader = true;
  public fee : any;

  
  @Output("closeModal") closeModal = new EventEmitter();
  

  constructor(ecpayService : ECPayService, 
    private router: Router,
    localStorageCacheService : LocalStorageCacheService,
    private currencyPipe : CurrencyPipe,
    formBuilder : FormBuilder) {
    this.ecpayService = ecpayService;
    this.formBuilder = formBuilder;
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.initBillerTypeFormGroup();
    this.getBiller() ;
  }

  initBillerTypeFormGroup(): void {
    this.billerFormGroup = new FormGroup({
      billerType: new FormControl("SSSValidate", [Validators.required]),
      billerCode: new FormControl("SSSValidate", [Validators.required]),
      params: this.formBuilder.group({})
    });
  }

  reInitBillerTypeFormGroup(): void {
    this.billerFormGroup = new FormGroup({
      billerType: new FormControl("SSSValidate", [Validators.required]),
      billerCode: new FormControl("SSSValidate", [Validators.required]),
      params: this.formBuilder.group({})
    });
  }

  setBillerCode(billerCode : any){
    this.billerCode = billerCode;
  }


  getBiller() {
    this.billerCode = "SSSValidate";
    this.loader = true;
    this.ecpayService.getSSSValidateBiller().subscribe((biller) => {
      if(biller.meta.length == 0){
        PNotify.error({
          title: "Error",
          text: "Biller is unavailable.",
        });
        this.loader = false;
      }else{
        this.activeTab++;
        this.addBillerParam(biller.meta);
        this.fee = biller.fee;
        this.biller = biller;
        this.loader = false;
      }
    });
  }

  inquireBiller() {
    this.loader = true;
  //  this.ecpayService.inquireBiller(this.paramsFormGroup().value,this.billerCode, this.fee).subscribe((biller) => {
      
        PNotify.info({
          title: "Validation will be execute after submitting",
          text: "Accepted",
        });
        this.loader = false;
        this.code = "";
        this.activeTab = 3;
        this.codeStatus=false;
      
  //  }, error => {
  //    PNotify.error({
  //      title: "Payment Validation",
  //      text: error.error.reason,
   //   });
   //   this.loader = false;

 //   });
  }//

  paramsFormGroup() : FormGroup{
    return this.billerFormGroup.get('params') as FormGroup;
  } 


  addBillerParam(meta: any){
    if (this.selectedBillerCode != this.billerFormGroup.controls.billerCode.value){
        this.reInitBillerTypeFormGroup();
        
        meta.forEach(param => {
          (this.billerFormGroup.get("params") as FormGroup).addControl(
            param.field,
            new FormControl("")
          );
        });
   }
    this.selectedBillerCode = this.billerFormGroup.controls.billerCode.value;
  }

  setActiveTab(){

    if(this.activeTab == 1){
      if(this.billerFormGroup.status == "INVALID"){
        PNotify.error({
          title: "Error",
          text: "All fields are required to proceed to next step.",
        });
      }
      else{
        this.getBiller();
      }
    }else if(this.activeTab == 2){

      var hasError : boolean  = false;
      this.biller.meta.forEach(param => {
        if(!this.paramsFormGroup().controls[param.field].value && param.is_required){
          this.paramsFormGroup().controls[param.field].setErrors({'required': param.label + " is required."});
          hasError = true;
        }
      });


      if(hasError){
        PNotify.error({
          title: "Request denied",
          text: "Please fill up all the required fields.",
        });
      }else{
        this.inquireBiller();
      }

     
    }
  }

  setActiveTabPrev(){
    if(this.activeTab == 1){
      PNotify.error({
        title: "Error",
        text: "Action Denied.",
      });
    }else{
      this.activeTab--;
    }
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
  }

  // this called only if user entered full code
  codeStatus : boolean = false;
  code : any;
  onCodeCompleted(code: string) {
    this.code = code;
    this.codeStatus = code.length == 6;
  }


  processPayment(){
      this.loader = true;
      this.ecpayService.processSSSValidate(
        this.paramsFormGroup().value,
        this.billerCode, 
        this.fee, 
        this.biller.name, 
        this.biller.type,
        this.code)
        .subscribe((biller) => {
          PNotify.success({
            title: "Payment Success",
            text: biller.reason,
          });
          this.loader = false;
          this.code = "";
          this.codeStatus=false;
          this.closeModal.emit();
        
      }, error => {
        PNotify.error({
          title: "Payment Failed",
          text: error.error.reason,
        });
        this.loader = false;
        this.code = "";
        this.codeStatus = false;
      });
  }

  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  totalAmount(fee:any,amount:any){
    try  {
    return this.makeMoney(Number(fee) + Number(amount));
    } catch(e){
      return "0.00";
    }
    }

    optionValue(param)
  {
   
    if(this.paramsFormGroup().controls[param.field].value){
      return param.options.filter(x=>x.value == this.paramsFormGroup().controls[param.field].value)[0].key;
    }
   return null;
  }
}
