import { FormGroup } from "@angular/forms";
import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.less"],
})
export class CheckboxComponent implements OnInit {
  @Input() parentFormGroup: FormGroup;
  @Input() controlName: string;
  @Input() label: string;
  constructor() {}

  ngOnInit() {}
}
