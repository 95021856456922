import { CurrencyPipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { RolesService } from "src/data/services/web/roles.service";
import { StoreService } from "src/data/services/web/store.service";
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { UserService } from "src/data/services/web/user.service";
import { SalesUserService } from "src/data/services/web/sales-user.service";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: "app-sales-user-view",
  templateUrl: "./sales-user-view.component.html",
  styleUrls: ["./sales-user-view.component.less"],
})
export class SalesUserViewComponent implements OnInit {
  public userFormGroup: FormGroup;

  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public salesUsersService: SalesUserService;
  public userService: UserService;
  public storeService: StoreService;
  public emptyString = "";
  public roles: any;
  public role: any;
  public modalService: BsModalService;

  public accessToken: string;
  public newuser: boolean;
  public user: any;
  public userId: any;
  public roleLevelId: any;
  public roleId: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    private currencyPipe: CurrencyPipe,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    salesUsersService: SalesUserService,
    storeService: StoreService,
    modalService: BsModalService,
    userService: UserService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.salesUsersService = salesUsersService;
    this.storeService = storeService;
    this.modalService = modalService;
    this.userService= userService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
    PNotifyButtons;
  }

  ngOnInit(): void {
    this.roleId = this.localStorageCacheService.getStorage("roleId");
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.route.params.subscribe((params) => {
      const id = decodeURI(params.id || "");
      const status = decodeURI(params.status || "");
      this.rolesService.getRoles(1).then((roles) => {
        
        this.roles = roles;
        if (id) {
          this.userId = this.cryptoService.get(this.data.authentication.privateKey, id);
          this.getUser(this.userId);
        } else {
          this.authService.validateAccessToken(this.roleLevelId).then((res) => {
            const idDefault = this.localStorageCacheService.getStorage("id");
            this.userId = this.cryptoService.get(this.data.authentication.privateKey, id);
          
            this.getUser(idDefault);
          });
        }
      });
    });
    
  }

  getUser(id: any): void {
    this.salesUsersService.getSalesUser(id).subscribe((result) => {
      this.user = result.data;
      this.role = this.roles.filter((x) => x.id === this.user.roleId)[0].name;
    });
  }

  
  loader : any = false;
  resendEmailVerification(id: any): void {
    this.loader = true;
    this.userService.resendEmailVerification(id).subscribe((result) => {
      this.loader = false;
      PNotify.success({
        title: "Success",
        text: "Account activation resend to email successfully.",
      });
    },(error)=>{
      this.loader = false
      PNotify.error({
        title: "Error",
        text: "Request is not possible at this time.",
      });
    });
  }

  backTousersList(): void {
    this.router.navigate(["sales", "sales-user-list"]);
  }

  edituserProfile(): void {
    const encId = this.cryptoService.set(this.data.authentication.privateKey, this.userId);

    this.router.navigate(["sales", "sales-user-edit", { id: encId }]);
  }

  public processStoreSave = false;

  buildUserPhoto(user: any): any {
    return user.salesUser.photoFormat + "," + user.salesUser.photo;
  }

  editCashierUserProfile(): void {
    const encId = this.cryptoService.set(this.data.authentication.privateKey, this.userId);

    this.router.navigate(["sales", "sales-user-edit", { id: encId }]);
  }
}
