import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { MultisysService } from 'src/data/services/web/multisys.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
import { CryptoService } from 'src/data/crypto/crypto.service';
import { AuthService } from 'src/data/services/authentication/auth.service';
import { environment } from 'src/environments/environment';
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { VipProtectService } from 'src/data/services/web/vip-protect.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { Select2OptionData } from "ng-select2";
import { ExportExcelService } from 'src/data/services/web/export-excel.service';

@Component({
  selector: 'app-vip-protect-list',
  templateUrl: './vip-protect-list.component.html',
  styleUrls: ['./vip-protect-list.component.less']
})
export class VipProtectListComponent implements OnInit {


  @ViewChild('viewUploadCOCs') viewUploadCOCs: TemplateRef<any>;


  
  billerFormGroup : FormGroup;
  searchFormGroup : FormGroup;
  batchUploadCOCsFormGroup  : FormGroup;
  formBuilder : FormBuilder;
  vipProtectService : VipProtectService;
  loader : boolean = false;
  emptyString : any = "";
  public transactions: any = [];
  public transactionsForExcel: any = [];
  public transaction: any;
  p: number = 1;
  public statusOPtion: Array<Select2OptionData>;


  constructor(vipProtectService : VipProtectService, 
    private router: Router,
    private currencyPipe : CurrencyPipe,
    formBuilder : FormBuilder,
    public ete: ExportExcelService,
    private route: ActivatedRoute, 
    private cryptoService: CryptoService,
    public datepipe: DatePipe,
    localStorageCacheService: LocalStorageCacheService,
    private modalBService: NgbModal,
    private websettings : WebSettingsService) {
    this.vipProtectService = vipProtectService;
    this.formBuilder = formBuilder;
    PNotifyButtons;
  }


  ngOnInit(): void {
    this.initSearchFormGroup();
    this.initBatchUploadCOCsFormGroup();
    this.getStatus();
    this.getTransaction();
  }


   
  getStatus() {
    this.websettings.getWebSettingsByIdentifier("STATUS_VIP_PROTECT").subscribe((result) => {
    
      this.statusOPtion = result.data.map((status) => {
        return {
          id: status.parameter,
          text: status.value,
        };
      }); 
    });
  }



  initSearchFormGroup(): void {
    this.searchFormGroup = new FormGroup({
      dateRange: new FormControl(this.emptyString),
      status: new FormControl(this.emptyString),
      requestId: new FormControl(this.emptyString)
    });
  }

  initBatchUploadCOCsFormGroup(): void {
    this.batchUploadCOCsFormGroup = new FormGroup({
      start: new FormControl(this.emptyString),
      end: new FormControl(this.emptyString)
    });
  }


  

  getTransaction(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader =true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    
    const status : string = this.searchFormGroup.controls.status.value;
    const requestId : string = this.searchFormGroup.controls.requestId.value;
    const dateRange : string = this.searchFormGroup.controls.dateRange.value;
    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_status: string = status ? `status=${status}&` : "";
    const queryString_requestId: string = requestId ? `requestId=${requestId}&` : "";
    const queryString_showRows: string = `showRows=10&`;
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    const queryString = queryString_orderby + queryString_order +  queryString_status+ queryString_requestId + queryString_dateRange + queryString_showRows;
 
    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/VIPProtect/Inventory?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/VIPProtect/Inventory?${queryString}`;
      }
    }

    this.vipProtectService.getInventory(endPointUrl.slice(0, -1)).subscribe((result) => {
      this.loader =false;
      if(result){
        if (result.data.length == 0 && result.pageNumber != 1) {
          this.getTransaction(result.previousPage, _sortBy, _sort);
        } else {
          this.transactions = result;
      
        }
      }
     
    });
  }

  getTransactionForExcel(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    this.loader =true;
    if (fromSearch) {
      this.createdDate = this.emptyString;
    }

    
    const status : string = this.searchFormGroup.controls.status.value;
    const requestId : string = this.searchFormGroup.controls.requestId.value;
    const dateRange : string = this.searchFormGroup.controls.dateRange.value;
    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_status: string = status ? `status=${status}&` : "";
    const queryString_requestId: string = requestId ? `requestId=${requestId}&` : "";
    const queryString_showRows: string = `showRows=10000&`;
    const queryString_dateRange: string = dateRange ? `dateRange=${this.datepipe.transform(dateRange[0], 'yyyy-MM-dd')},${this.datepipe.transform(dateRange[1], 'yyyy-MM-dd')}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_status+ queryString_requestId + queryString_dateRange + queryString_showRows;
 
    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/VIPProtect/Inventory?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/VIPProtect/Inventory?${queryString}`;
      }
    }

    this.vipProtectService.getInventory(endPointUrl.slice(0, -1)).subscribe((result) => {
      this.loader =false;
      if(result){
        if (result.data.length == 0 && result.pageNumber != 1) {
          this.getTransaction(result.previousPage, _sortBy, _sort);
        } else {
          this.transactionsForExcel = result;
        
          this.generateExcel(result.data, "VIP Protect");
        }
      }
     
    });
  }

  createdDate = "";
  searchString = "";
  
  private _sortBy: any;
  private _sort: any;

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.createdDate = field == "createdDate" ? "th-inner sortable both " + sortBy : "";
   
    this.getTransaction(
      `${environment.API_URL}/api/VIPProtect/Inventory?pageNumber=${
        page ? page : this.transactions.pageNumber
      }`,
      this._sortBy,
      this._sort
    );
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.transactions?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }

  buildPageUrl(pageNumber: any, pageSize: any): any {
    this.p = pageNumber;
    return `${environment.API_URL}/api/VIPProtect/Inventory?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }

  statusOfAmount(money: any): boolean {
    return money < 0 ? false : true;
  }

  makeMoney(money: any) {
    if(money){
      return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
    }else{
      return "N/A";
    }
   
  }

  requestId;
  setSelectedTransaction(transaction: any) {
   
    this.transaction = transaction;
    this.requestId = this.transaction.requestId;
   
  }


  viewUploadCOCModal(){
    this.openUploadCOC(this.viewUploadCOCs);
    this.initBatchUploadCOCsFormGroup();
  }
 
  modalUploadCOC:any;
  openUploadCOC(viewModal) {
    this.modalUploadCOC = this.modalBService.open(viewModal, { centered: true, size: "xl" });
  }

  closeModal(){
    this.modalUploadCOC.close();
    this.getTransaction(null,null,null,null);
  }

  addCOCsToInventory(){
    this.loader = true;
    if(this.cOCCount() < 0) {
      PNotify.error({
        title: "Error",
        text: "Invalid COC's count.",
      });
      this.loader = false;
    }else{
      this.vipProtectService.generateBatchCOC(this.batchUploadCOCsFormGroup.value).subscribe(
        (response) => {
          PNotify.success({
            title: "COC Batch Upload",
            text: response.reason,
          });
          this.closeModal();
          this.loader = false;
        },
        (error) => {
          PNotify.error({
            title: "COC Batch Upload",
            text: error.error.reason,
          });
          this.loader = false;
        }
      );

    }
  }

  cOCCount(){

  
  if(this.batchUploadCOCsFormGroup?.controls.end.value && this.batchUploadCOCsFormGroup?.controls.start.value){
    return (this.batchUploadCOCsFormGroup?.controls.end.value - this.batchUploadCOCsFormGroup?.controls.start.value) + 1;
  }else{

    return 0;
  }
  }

  public padIntegerLeftWithZeros(rawInteger: number, numberOfDigits: number): any {
    let paddedInteger: string = rawInteger + '';
    while (paddedInteger.length < numberOfDigits) {
      paddedInteger = '0' + paddedInteger;
    }
    return paddedInteger;
  }

  buildUserPhoto(transaction: any): any {
    return transaction.photoFormat + "," + transaction.photo;
  }

  public uploadModal = null;
  public vipProtect = null;
  preview(content: any, vipProtect : any){
this.vipProtect = vipProtect;
    this.uploadModal = this.modalBService.open(content, { centered: true, size: "lg" });
  }

  dataForExcel = [];
  public Transactions: any;

  exportToExcel() {
    this.Transactions = [];
    this.dataForExcel = [];

    this.getTransactionForExcel();
  }

  generateExcel(vipProtect: any, name: any): void {
    this.Transactions = [];

    
      for(var i = 0; i < vipProtect.length; i++){
        
        let status = "";

        if(vipProtect[i].statusId == "2"){
          status = "Sold"
        }  else if (vipProtect[i].statusId == "3"){
          status = "Available"
        }

        let meta = [
          {
              "COC Number":	vipProtect[i].cocNumber ? vipProtect[i].cocNumber : "",
              "Request ID": vipProtect[i].requestId ? vipProtect[i].requestId : "",
              "Customer Name":	vipProtect[i].vipProtect ? vipProtect[i].vipProtect.customerName : "",
              "Beneficiary":	vipProtect[i].vipProtect ? vipProtect[i].vipProtect.beneficiary : "",
              "Relationship":	vipProtect[i].vipProtect ? vipProtect[i].vipProtect.relationship : "",
              "Contact Number":	vipProtect[i].vipProtect ? vipProtect[i].vipProtect.contactNumber : "", 
              "Birthday":	vipProtect[i].vipProtect ? vipProtect[i].vipProtect.birthDate : "",
              "Residence":	vipProtect[i].vipProtect ? vipProtect[i].vipProtect.residence : "",
              "Completed date": vipProtect[i].vipProtect ? this.datepipe.transform(vipProtect[i].vipProtect.completedDate, "MMM-dd-yyyy hh:mm:ss") : "",
              "Status":	vipProtect[i].statusId ? status : "",       
          }
        ]

        this.Transactions.push(meta);
      }

   

    for(var x = 0; x < this.Transactions.length; x++){
      this.dataForExcel.push(Object.values(this.Transactions[x][0]));
      
    }

    

    let reportData = {
      title: name + ' Report',
      data: this.dataForExcel,
      headers: Object.keys(this.Transactions[0][0])
    }

    this.ete.exportExcel(reportData, name);
  }
}
