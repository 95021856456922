<!-- <app-loader *ngIf="loader"></app-loader> -->
<div id="content" class="flex">
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">VIP Cloud</h2>
          <small class="text-highlight font-weight-normal font-italic">JuanPay Cloud list of requests </small>
        </div>
        <div class="flex"></div>
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="padding" style="padding-right: 0px">
        <div class="card">
          <table
            id="table"
            class="table table-striped no-footer"
            data-plugin="bootstrapTable"
            data-toolbar="#toolbar"
            data-search="true"
            data-search-align="left"
            data-show-export="true"
            data-show-columns="true"
            data-detail-view="false"
            data-mobile-responsive="true"
            data-pagination="true"
            data-page-list="[10, 25, 50, 100, ALL]"
          >
            <thead>
              <tr>
                <th data-sortable="true" data-field="id">Details</th>
                <th data-sortable="true" data-field="project">Amount</th>
                <th data-sortable="true" data-field="project">Possible Income</th>
                <th data-field="finish"><span class="d-none d-sm-block">Action</span></th>
              </tr>
            </thead>
            <tbody>
              <tr class=" " *ngFor="let cloudTransaction of cloudTransactionList" class="odd" role="row">
                <td class="flex" *ngIf="cloudTransaction.vip_cloud_body.process_to == 'ECPAY_REMITTANCE'">
                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary font-weight-bold">Request ID : </span>
                    {{ cloudTransaction.vip_cloud_body.request_id }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary font-weight-bold">Requestor Tag : </span
                    >{{ cloudTransaction.vip_cloud_body.requestor_user_id }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary font-weight-bold">Bank : </span>
                    {{ cloudTransaction.vip_cloud_body.bill_details.biller.biller }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary font-weight-bold">Account Number : </span>
                    {{ cloudTransaction.vip_cloud_body.bill_details.biller.firstField }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary font-weight-bold">Account Name : </span>
                    {{ cloudTransaction.vip_cloud_body.bill_details.biller.secondField }}
                  </div>
                </td>

                <td *ngIf="cloudTransaction.vip_cloud_body.process_to == 'ECPAY_REMITTANCE'">
                  <div class="text-lg text-highlight font-weight-bold text-info">
                    {{
                      computeTotalAmount(
                        cloudTransaction.vip_cloud_body.bill_details.biller.amount,
                        cloudTransaction.vip_cloud_body.bill_details.biller.fee
                      ) | number: "1.2-2"
                    }}
                  </div>
                </td>

                <td *ngIf="cloudTransaction.vip_cloud_body.process_to == 'ECPAY_REMITTANCE'">
                  <div class="text-lg text-highlight font-weight-bold text-success">
                    {{ 4.0 | number: "1.2-2" }}
                  </div>
                </td>
                <td *ngIf="cloudTransaction.vip_cloud_body.process_to == 'ECPAY_REMITTANCE'">
                  <div class="text-lg text-highlight font-weight-bold text-info">
                    <div
                      class="btn btn-md btn-info"
                      (click)="processTransaction(cloudTransaction.vip_cloud_body.request_id)"
                    >
                      <span class="d-none d-sm-inline mx-1">Process</span>
                      <i data-feather="arrow-right"></i>
                    </div>
                  </div>
                </td>

                <td class="flex" *ngIf="cloudTransaction.vip_cloud_body.process_to == 'MULTISYS'">
                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary font-weight-bold">Request ID : </span>
                    {{ cloudTransaction.vip_cloud_body.request_id }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary font-weight-bold">Requestor Tag : </span
                    >{{ cloudTransaction.vip_cloud_body.requestor_user_id }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary font-weight-bold">Biller : </span>
                    {{ cloudTransaction.vip_cloud_body.bill_name }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary font-weight-bold">Biller Category : </span>
                    {{ cloudTransaction.vip_cloud_body.bill_category }}
                  </div>
                </td>

                <td *ngIf="cloudTransaction.vip_cloud_body.process_to == 'MULTISYS'">
                  <div class="text-lg text-highlight font-weight-bold text-info">
                    {{
                      computeTotalAmount(
                        convertToDynamicJson(cloudTransaction.vip_cloud_body.biller_details).amount,
                        cloudTransaction.vip_cloud_body.fee
                      ) | number: "1.2-2"
                    }}
                  </div>
                </td>

                <td *ngIf="cloudTransaction.vip_cloud_body.process_to == 'MULTISYS'">
                  <div class="text-lg text-highlight font-weight-bold text-success">
                    {{ 4.0 | number: "1.2-2" }}
                  </div>
                </td>
                <td *ngIf="cloudTransaction.vip_cloud_body.process_to == 'MULTISYS'">
                  <div class="text-lg text-highlight font-weight-bold text-info">
                    <div
                      class="btn btn-md btn-info"
                      (click)="processTransaction(cloudTransaction.vip_cloud_body.request_id)"
                    >
                      <span class="d-none d-sm-inline mx-1">Process</span>
                      <i data-feather="arrow-right"></i>
                    </div>
                  </div>
                </td>

                <td class="flex" *ngIf="cloudTransaction.vip_cloud_body.process_to == 'ECPAY_BILL'">
                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary font-weight-bold">Request ID : </span>
                    {{ cloudTransaction.vip_cloud_body.request_id }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary font-weight-bold">Requestor Tag : </span
                    >{{ cloudTransaction.vip_cloud_body.requestor_user_id }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary font-weight-bold">Biller : </span>
                    {{ cloudTransaction.vip_cloud_body.bill_name }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary font-weight-bold">Biller Category : </span>
                    {{ cloudTransaction.vip_cloud_body.bill_category }}
                  </div>
                </td>

                <td *ngIf="cloudTransaction.vip_cloud_body.process_to == 'ECPAY_BILL'">
                  <div class="text-lg text-highlight font-weight-bold text-info">
                    {{ convertToDynamicJson(cloudTransaction.vip_cloud_body.biller_details).amount | number: "1.2-2" }}
                  </div>
                </td>

                <td *ngIf="cloudTransaction.vip_cloud_body.process_to == 'ECPAY_BILL'">
                  <div class="text-lg text-highlight font-weight-bold text-success">
                    {{ 4.0 | number: "1.2-2" }}
                  </div>
                </td>
                <td *ngIf="cloudTransaction.vip_cloud_body.process_to == 'ECPAY_BILL'">
                  <div class="text-lg text-highlight font-weight-bold text-info">
                    <div
                      class="btn btn-md btn-info"
                      (click)="processTransaction(cloudTransaction.vip_cloud_body.request_id)"
                    >
                      <span class="d-none d-sm-inline mx-1">Process</span>
                      <i data-feather="arrow-right"></i>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css">
<body>
  <div id="brd"></div>
  <div class="main-game">
    <h3>REVOLUTION</h3>
    <span>Bang! Bang! Bang!</span>
    <h1>Are you ready to earn points?</h1>
    <h2 >GO START AND COLLECT YOUR REWARDS!</h2>
    <button class="start-btn game-button" id="strt-gme">Start</button>
</div>




<audio preload="auto" id="sound1" src="../../../assets/vips/gunbird.wav"></audio>
<audio preload="auto" id="sound2" src="../../../assets/vips/coin-upaif-14631.mp3"></audio>
<audio preload="auto" id="sound3" src="../../../assets/vips/audiomass-output.wav"></audio>
<audio id="bg-music" loop src="../../../assets/vips/Komiku_-_12_-_Bicycle.mp3" [volume]=".5"></audio>
</body> -->

<!-- <div class="top-btn">
  <a id="muteBtn" class="muteBtn"><i id="iconmute" class="iconmute bi bi-volume-up-fill"></i></a>
  <a style="padding-bottom: 5px;"><i class="bi bi-gear"></i></a>
</div> -->
