<app-loader *ngIf="!transactions"></app-loader>
<app-loader *ngIf="processUpdate"></app-loader>

<div id="content" class="flex" *ngIf="transactions">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">List of Transactions</h2>
          <small class="text-highlight font-weight-normal font-italic">JuanPay list of transactions</small>
        </div>
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="padding" style="padding-right: 0px">
        <div class="col-sm-12 col-md-5" style="padding-left: 0px">
          <div class="input-group">
            <input
              type="text"
              [(ngModel)]="searchString"
              class="form-control form-control-theme form-control-sm search"
              placeholder="Search"
            />

            <span class="input-group-append">
              <button
                (click)="getTransaction(null, null, null, true)"
                class="btn btn-white no-border btn-sm"
                type="button"
              >
                <span class="d-flex text-muted">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-search"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </span>
              </button>
            </span>
          </div>

          <!-- <div class="col-sm-12">
            <app-dropdown-alphabetical place-holder="Select username..." [parent-form-group]="searchByUsernameFormGroup"
              control-name="username" label="Search by Username" [data]="cashiersAndManagers"
              [error]="searchByUsernameFormGroup?.controls.username.errors | estatus"
              [error-message]="searchByUsernameFormGroup?.controls.username.errors | econtent">
            </app-dropdown-alphabetical>

          </div> -->

          <!-- <input type="text" class="btn btn-primary" data-plugin="daterangepicker" data-option="{
            opens: 'left',
            ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        }" /> -->
        </div>
        <div class="col-sm-12 col-md-5" style="padding-left: 0px"></div>
        <table
          id="table"
          class="table table-striped table-hover"
          data-plugin="bootstrapTable"
          data-toolbar="#toolbar"
          data-search="true"
          data-search-align="left"
          data-show-export="true"
          data-show-columns="true"
          data-detail-view="false"
          data-mobile-responsive="true"
          data-pagination="true"
          data-page-list="[10, 25, 50, 100, ALL]"
        >
          <thead>
            <tr>
              <th data-sortable="true" data-field="id">Details</th>
              <th data-sortable="true" data-field="owner">Identity</th>
              <th data-sortable="true" data-field="project">Amount</th>
              <th data-field="task"><span class="d-none d-sm-block">Balance Before</span></th>
              <th data-field="finish"><span class="d-none d-sm-block">Balance After</span></th>
              <th data-field="finish"><span class="d-none d-sm-block">Status</span></th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr
              class=" "
              *ngFor="
                let transaction of transactions?.data
                  | paginate: { itemsPerPage: pageRows, currentPage: p, totalItems: transactions?.totalRecords }
              "
              class="odd"
              role="row"
            >
              <td class="flex">
                <div class="item-except text-sm h-1x">
                  <strong>Transaction Name : {{ transaction?.transactionName }}</strong>
                </div>
                <div class="item-except text-muted text-sm h-1x">Request ID : {{ transaction?.requestId }}</div>
                <div class="item-except text-muted text-sm h-1x">
                  Reference Number : {{ transaction?.referenceNumber }}
                </div>
              </td>
              <td class="flex">
                <div class="item-except text-muted text-sm h-1x">Transact by : {{ transaction?.user.username }}</div>
                <div class="item-except text-muted text-sm h-1x">
                  Created date : {{ transaction?.createdDate | date: "MMM dd, yyyy hh:mm:ss aa" }}
                </div>
                <div class="item-except text-muted text-sm h-1x">
                  Completed date : {{ transaction?.completedDate | date: "MMM dd, yyyy hh:mm:ss aa" }}
                  <!-- {{transaction?.completedByUsername}} -->
                </div>
              </td>
              <td>
                <div class="text-center">
                  <strong *ngIf="statusOfAmount(transaction?.amount)" style="color: green"
                    >{{ makeMoney(transaction?.amount) }}
                  </strong>
                  <strong *ngIf="!statusOfAmount(transaction?.amount)" style="color: red"
                    >{{ makeMoney(transaction?.amount) }}
                  </strong>
                </div>
              </td>
              <td>
                <div class="text-center">
                  <strong *ngIf="transaction?.statusId == 2">{{ makeMoney(transaction?.openingBalance) }}</strong>
                  <i class="text-muted" *ngIf="transaction?.statusId != 2">N/A</i>
                </div>
              </td>
              <td>
                <div class="text-center">
                  <strong *ngIf="transaction?.statusId == 2">{{
                    makeMoney(transaction?.openingBalance + transaction?.amount)
                  }}</strong>
                  <i class="text-muted" *ngIf="transaction?.statusId != 2">N/A</i>
                </div>
              </td>
              <td>
                <app-status-table-data [status]="transaction?.statusId"></app-status-table-data>
              </td>
              <td style="">
                <div class="item-action dropdown">
                  <a href="#" data-toggle="dropdown" class="text-muted">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-more-vertical"
                    >
                      <circle cx="12" cy="12" r="1"></circle>
                      <circle cx="12" cy="5" r="1"></circle>
                      <circle cx="12" cy="19" r="1"></circle>
                    </svg>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right bg-black" role="menu">
                    <!-- <a *ngIf="roleLevelId == 1" data-toggle="modal" (click)="setSelectedTransaction(transaction)"
                      data-target="#modal-approve" data-toggle-class-target=".animate" class="dropdown-item trash">
                      Approve
                    </a>
                    <div *ngIf="roleLevelId == 1" class="dropdown-divider"></div>
                    <a *ngIf="roleLevelId == 1" data-toggle="modal" (click)="setSelectedTransaction(transaction)"
                      data-target="#modal-reject" data-toggle-class-target=".animate" class="dropdown-item trash">
                      Reject
                    </a>
                    <div *ngIf="roleLevelId == 1" class="dropdown-divider"></div> -->
                    <a
                      data-toggle="modal"
                      (click)="setSelectedTransaction(transaction)"
                      data-target="#modal-receipt"
                      data-toggle-class-target=".animate"
                      class="dropdown-item trash"
                    >
                      See Details
                    </a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
              <div class="col-sm-12 col-md-12">
                <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                  Showing {{ (transactions?.pageNumber - 1) * transactions?.pageSize + 1 }} to
                  {{ (transactions?.pageNumber - 1) * transactions?.pageSize + transactions?.data?.length }} of
                  {{ transactions?.data?.length }} entries
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-7" style="float: right">
            <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
              <ul class="pagination" style="float: right; margin-right: 36px">
                <pagination-controls (pageChange)="getTransaction(buildPageUrl($event, 10))"></pagination-controls>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<ng-template #viewMultisys let-modal>
  <div class="modal-header">
    <h4 class="modal-title">[Multisys] Transaction Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-multisys-transaction [requestId]="requestId"></app-multisys-transaction>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>
