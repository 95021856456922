import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { RolesService } from "src/data/services/web/roles.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-role-list",
  templateUrl: "./role-list.component.html",
  styleUrls: ["./role-list.component.less"],
})
export class RoleListComponent implements OnInit {
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public emptyString = "";
  public statuses: any;
  public status: any;
  public modalService: BsModalService;

  public accessToken: string;
  public newRole: boolean;
  public roles: any;
  public pageCount: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    modalService: BsModalService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.modalService = modalService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
  }

  ngOnInit(): void {
    this.getRoles();
  }

  private _order_by: any;
  private _order: any;

  getRoles(url = null, _order_by = null, _order = null): void {
  
    const queryString_orderby: string = _order_by ? `order_by=details.${_order_by}&` : "";
    const queryString_order: string = _order ? `order=${_order}&` : "";
    const queryString_q: string = this.searchString ? `q=${this.searchString}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_q;

    let endPointUrl = "";
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/roles&${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/roles${queryString}`;
      }
    }

    this.rolesService.getRolesList(endPointUrl).subscribe((result) => {
     
      if (result.data.length == 0 && result.current_page != 1) {
        this.getRoles(result.prev_page_url, _order_by, _order);
      } else {
        this.roles = result;
      }
    });
  }

  name = "";
  searchString = "";

  setPageSorting(field: any, page: any = null) {
    let order_by: any;
    let order: any;

    if (this._order_by == field) {
      order_by = field;
      order = this._order == "desc" ? "asc" : "desc";
    } else {
      order_by = field;
      order = "asc";
    }
    this._order_by = order_by;
    this._order = order;

    this.name = field == "name" ? "th-inner sortable both " + order : "";
    this.getRoles(`${this.roles.path}?page=${page ? page : this.roles.current_page}`, this._order_by, this._order);
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.roles?.current_page) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }
}
