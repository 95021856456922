import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { first } from 'lodash';
import { Observable } from 'rxjs';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class GovipxService {

  private localStorageCacheService: LocalStorageCacheService;

  constructor(
    private httpClient: HttpClient,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
  }
  private httpClientNoInterceptor: HttpClient;

  private baseAPIUrl = environment.API_URL;

  public getAuthenticated(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/GoVIPX/Authenticate`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }
  
  public validateReference(reference: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/GoVIPX/ValidateReference/${reference}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public validateCashoutReference(reference: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/GoVIPX/ValidateCashoutReference/${reference}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public processGoVIPXTopup(goVIPX:any ,code:any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/GoVIPX/Process`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");
      goVIPX.PinCode  = code;

    return this.httpClient.post(`${endPointUrl}`, goVIPX ,{ headers: httpHeaders });
  }

  public processGoVIPXCashout(goVIPX:any ,code:any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/GoVIPX/ProcessCashout`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");
      goVIPX.PinCode  = code;

    return this.httpClient.post(`${endPointUrl}`, goVIPX ,{ headers: httpHeaders });
  }

  public getTransactionCashout(requestId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/GoVIPX/TransactionCashout/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getTransactionTopup(requestId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/GoVIPX/TransactionTopup/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getGovipxValidation(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/GoVIPX/GoVIPXValidationList`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public postGovipxValidation(firstname: any, lastname: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/GoVIPX/GoVIPXValidation`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        firstname : firstname,
        lastname : lastname
      }
      
    return this.httpClient.post(`${endPointUrl}`, json , { headers: httpHeaders });
  }

  public putGovipxValidation(status: any, user_id, remarks: any, usertype: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/GoVIPX/PutGoVIPXValidation`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        status : status,
        user_id : user_id,
        remarks : remarks,
        usertype: usertype
      }
      
    return this.httpClient.put(`${endPointUrl}`, json , { headers: httpHeaders });
  }

  public getGovipxValidationLevel2(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/GoVIPX/GoVIPXValidationLevel2`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public putGovipxValidationLevel2(status: any, user_id, remarks: any, usertype: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/GoVIPX/PutGoVIPXValidationLevel2`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = {
        status : status,
        user_id : user_id,
        remarks : remarks,
        usertype: usertype
      }
      
    return this.httpClient.put(`${endPointUrl}`, json , { headers: httpHeaders });
  }

  public postSalaryEmployeeList(employeeList: any, id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/GoVIPX/SalaryEmployeeList/${id}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(`${endPointUrl}`, employeeList , { headers: httpHeaders });
  }

  public validateEmployee(employeeList: any, cutoffID: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/GoVIPX/ValidatePayroll`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = 
      { 
        employeeList : employeeList,
        cutoffID : cutoffID
      };

    return this.httpClient.post(`${endPointUrl}`, json , { headers: httpHeaders });
  }

  public postCutoff(cutoff: FormGroup): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/GoVIPX/Cutoff`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(`${endPointUrl}`, cutoff , { headers: httpHeaders });
  }

  public getCutoff(): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/GoVIPX/Cutoff`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getCutoffV2(url: any): Observable<any> {
    let endPointUrl: any;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");
    endPointUrl = `${url}`;

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public getSelectedEmployeeList(id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/GoVIPX/CutoffEmployees/${id}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public putCutoff(id: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/GoVIPX/CutoffEmployees/${id}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.put(`${endPointUrl}`,"", { headers: httpHeaders });
  }

  public ProcessPayroll(salary: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/GoVIPX/ProcessPayroll/`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.put(`${endPointUrl}`,salary, { headers: httpHeaders });
  }

  public putEmployee(id: any, cutoffID: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/GoVIPX/RemoveEmployee/${id}/${cutoffID}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.put(`${endPointUrl}`,"", { headers: httpHeaders });
  }

  public putRemoveCutoff(cutoffID: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/GoVIPX/RemoveCutoff/${cutoffID}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.put(`${endPointUrl}`,"", { headers: httpHeaders });
  }

  public rejectTransaction(requestId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/GoVIPX/Billers/Failed/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.put(`${endPointUrl}`,"",{ headers: httpHeaders });
  }

  public completeTransaction(requestId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/GoVIPX/Billers/Complete/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.put(`${endPointUrl}`,"",{ headers: httpHeaders });
  }










 

  public getTransaction(requestId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/StandardInsurance/Transaction/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/x-www-form-urlencoded");

    return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }

  public reProcessPayment(biller : any, billerName : any,billerCategory: any ,  requestId : any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Multisys/Billers/Reprocess/${requestId}`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

      var json = 
      { 
        biller : biller,
        billerName : billerName,
        billerCategory : billerCategory
      };

    return this.httpClient.put(`${endPointUrl}`, json,{ headers: httpHeaders });
  }
}
