<app-loader *ngIf="processUpdate || loader"></app-loader>
<div id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container pr-0 pl-0" id="page-hero">
      <div class="padding d-flex pl-0 pr-0">
        <div class="page-title">
          <h2 class="text-md text-highlight">List of Transactions</h2>
          <small class="text-highlight font-weight-normal font-italic">JuanPay list of transactions</small>
        </div>
        <div class="flex"></div>
        <div>
          <button class="btn btn-sm btn-success" (click)="exportToExcel()">
            <span class="d-none d-sm-inline mx-1">Export to excel</span> &nbsp;
            <i class="fa fa-file-excel"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="page-content page-container pl-0 pr-0" id="page-content">
      <div class="padding pl-0 pr-0">
        <div class="card mb-2 p-1" [formGroup]="searchByUsernameFormGroup" style="background-color: #182038">
          <div class="row">
            <div class="col-sm-12 col-md-2 col-lg-2 pl-0 d-flex justify-content-center align-items-center">
              <ng-select2
                [(ngModel)]="showRowsFilter"
                [data]="showRowsOption"
                [placeholder]="'  No of Rows '"
                [allowClear]="true"
                formControlName="showRows"
              ></ng-select2>
            </div>
            <div class="col-sm-12 col-md-2 col-lg-2 pl-0 d-flex justify-content-center align-items-center">
              <ng-select2
                [(ngModel)]="statusIdFilter"
                [data]="statusIdFilterOptions"
                [placeholder]="' Select Status '"
                [allowClear]="true"
                formControlName="status"
              ></ng-select2>
            </div>
            <div class="col-sm-12 col-md-3 col-lg-3 pl-0 d-flex justify-content-center align-items-center">
              <ng-select2
                [(ngModel)]="userIdFilter"
                [data]="userIdFilterOptions"
                [placeholder]="
                  ' &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Select Username &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; '
                "
                [allowClear]="true"
                formControlName="username"
              ></ng-select2>
            </div>
            <div class="col-sm-12 col-md-2 col-lg-2 pl-0 d-flex justify-content-center align-items-center">
              <input
                type="text"
                placeholder="Date Range"
                class="form-control mx-1"
                bsDaterangepicker
                formControlName="dateRange"
                [bsConfig]="{ rangeInputFormat: 'YYYY-MM-DD', dateInputFormat: 'YYYY-MM-DD', showWeekNumbers: false }"
                style="background-color: white; color: black"
              />
            </div>
            <div class="col-sm-12 col-md-3 col-lg-3 pl-0 inline-block">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Request Id" formControlName="requestId" />
                <div class="input-group-append">
                  <button class="btn btn-sm btn-primary" (click)="getTransaction(null, null, null, true)" type="button">
                    <i class="fa fa-search"></i> &nbsp; Search
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- List Toolbar Form -->
          <!-- <form class="form-inline" > -->

          <!-- </form> -->
          <!-- Search Button -->
        </div>

        <div class="card">
          <table
            id="table"
            class="table table-striped table-hover"
            data-plugin="bootstrapTable"
            data-toolbar="#toolbar"
            data-search="true"
            data-search-align="left"
            data-show-export="true"
            data-show-columns="true"
            data-detail-view="false"
            data-mobile-responsive="true"
            data-pagination="true"
            data-page-list="[10, 25, 50, 100, ALL]"
          >
            <thead>
              <tr>
                <th data-sortable="true" data-field="id">Details</th>
                <th data-sortable="true" data-field="owner">Identity</th>
                <th data-sortable="true" data-field="project">Amount</th>
                <th data-field="task"><span class="d-none d-sm-block">Balance Before</span></th>
                <th data-field="finish"><span class="d-none d-sm-block">Balance After</span></th>
                <th data-field="finish"><span class="d-none d-sm-block">Status</span></th>
              </tr>
            </thead>

            <tbody>
              <tr
                class=" "
                *ngFor="
                  let transaction of transactions?.data
                    | paginate: { itemsPerPage: pageRows, currentPage: p, totalItems: transactions?.totalRecords }
                "
                class="odd"
                role="row"
                (click)="setSelectedTransaction(transaction)"
              >
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">Transaction Name :</span>
                      {{ transaction?.transactionName }}</strong
                    >
                  </div>

                  <!--TOP UP ECPAY-->
                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType == 'TOP_UP_ECPAY'">
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">Telco :</span>
                      {{ transaction?.ecTopUp.telco }}</strong
                    >
                  </div>

                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType == 'TOP_UP_ECPAY'">
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">Mobile Number :</span>
                      {{ transaction?.ecTopUp.cellphoneNumber }}</strong
                    >
                  </div>

                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType == 'TOP_UP_ECPAY'">
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">Product :</span>
                      {{ transaction?.ecTopUp.extensionTag }}</strong
                    >
                  </div>
                  <!--TOP UP ECPAY-->

                  <!--MULTISYS-->
                  <div
                    class="item-except text-sm h-1x"
                    *ngIf="transaction?.transactionType == 'BILLS_PAYMENT_MULTISYS'"
                  >
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">Category :</span>
                      {{ transaction?.multisys.billerCategory }}</strong
                    >
                  </div>

                  <div
                    class="item-except text-sm h-1x"
                    *ngIf="transaction?.transactionType == 'BILLS_PAYMENT_MULTISYS'"
                  >
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">Biller Name :</span>
                      {{ transaction?.multisys.billerName }}</strong
                    >
                  </div>
                  <div
                    class="item-except text-sm h-1x"
                    *ngIf="transaction?.transactionType == 'BILLS_PAYMENT_MULTISYS'"
                  >
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">Account Number :</span>
                      {{ transaction?.multisys.billerAccountNo }}</strong
                    >
                  </div>

                  <!--MULTISYS-->

                  <!--BILLS_PAYMENT_ECPAY-->
                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType == 'BILLS_PAYMENT_ECPAY'">
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">Category :</span>
                      {{ transaction?.ecPay.billerCategory }}</strong
                    >
                  </div>

                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType == 'BILLS_PAYMENT_ECPAY'">
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">Biller Name :</span>
                      {{ transaction?.ecPay.billerName }}</strong
                    >
                  </div>
                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType == 'BILLS_PAYMENT_ECPAY'">
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">Account Number :</span>
                      {{ transaction?.ecPay.billerAccountNo }}</strong
                    >
                  </div>

                  <!--BILLS_PAYMENT_ECPAY-->

                  <!--CASH_ECPAY-->
                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType == 'CASH_ECPAY'">
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">Category :</span>
                      {{ transaction?.ecCash.billerCategory }}</strong
                    >
                  </div>

                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType == 'CASH_ECPAY'">
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">Biller Name :</span>
                      {{ transaction?.ecCash.billerName }}</strong
                    >
                  </div>
                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType == 'CASH_ECPAY'">
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">Account Number :</span>
                      {{ transaction?.ecCash.billerAccountNo }}</strong
                    >
                  </div>

                  <!--CASH_ECPAY-->
                  <!--BILLS_PAYMENT_CTI-->
                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType == 'BILLS_PAYMENT_CTI'">
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">Product Category :</span>
                      {{ transaction?.cti.productCategory }}</strong
                    >
                  </div>

                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType == 'BILLS_PAYMENT_CTI'">
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">Product Code :</span>
                      {{ transaction?.cti.productCode }}</strong
                    >
                  </div>
                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType == 'BILLS_PAYMENT_CTI'">
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">Product Name :</span>
                      {{ transaction?.cti.productName }}</strong
                    >
                  </div>

                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType == 'BILLS_PAYMENT_CTI'">
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">Mobile Number :</span>
                      {{ transaction?.cti.mobileNumber }}</strong
                    >
                  </div>

                  <!--BILLS_PAYMENT_CTI-->

                  <!--_IBAYAD_BANK_TRANSFER-->

                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType == 'IBAYAD_TRANSFER'">
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">Product :</span>
                      {{ transaction?.iBayadBankTransfer.billerName }}</strong
                    >
                  </div>
                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType == 'IBAYAD_TRANSFER'">
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">Account Number :</span>
                      {{ transaction?.iBayadBankTransfer.billerAccountNo }}</strong
                    >
                  </div>

                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType == 'IBAYAD_TRANSFER'">
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">Account Name :</span>
                      {{ transaction?.iBayadBankTransfer.billerAccountName }}</strong
                    >
                  </div>
                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType == 'IBAYAD_TRANSFER'">
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">Bank :</span>
                      {{ transaction?.iBayadBankTransfer.billerBank }}</strong
                    >
                  </div>

                  <!--_IBAYAD_BANK_TRANSFER-->

                  <!--_IBAYAD_VIP_Protect-->

                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType == 'VIP_PROTECT'">
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">Customer Name :</span>
                      {{ transaction?.vipProtect.customerName }}</strong
                    >
                  </div>
                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType == 'VIP_PROTECT'">
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">COC Number :</span>
                      {{ padIntegerLeftWithZeros(transaction?.vipProtect.cocNumber, 6) }}</strong
                    >
                  </div>

                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType == 'VIP_PROTECT'">
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">Birth Date :</span>
                      {{ transaction?.vipProtect.birthDate }}</strong
                    >
                  </div>
                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType == 'VIP_PROTECT'">
                    <strong>
                      <span class="text-secondary" style="font-weight: bold">Beneficiary :</span>
                      {{ transaction?.vipProtect.beneficiary }}</strong
                    >
                  </div>

                  <!--_IBAYAD_VIP_Protect-->

                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary" style="font-weight: bold">Req. ID :</span>
                    {{ transaction?.requestId }}
                  </div>
                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType != 'VIP_PROTECT'">
                    <span class="text-secondary" style="font-weight: bold">Ref. No :</span>
                    {{ transaction?.referenceNumber }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType != 'ALLOCATE_FUND'">
                    <span class="text-secondary" style="font-weight: bold">Transact by :</span>
                    {{ transaction?.user.username }}
                  </div>

                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType == 'ALLOCATE_FUND'">
                    <span class="text-secondary" style="font-weight: bold">Allocated From :</span>
                    {{ transaction?.allocateFund.originUser.username }}
                  </div>

                  <div class="item-except text-sm h-1x" *ngIf="transaction?.transactionType == 'ALLOCATE_FUND'">
                    <span class="text-secondary" style="font-weight: bold">Allocated To :</span>
                    {{ transaction?.allocateFund.destinationUser.username }}
                  </div>

                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary" style="font-weight: bold">Created date :</span>
                    {{ transaction?.createdDate | date: "MMM dd, yyyy hh:mm:ss a" }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary" style="font-weight: bold">Completed date :</span>
                    {{ transaction?.completedDate | date: "MMM dd, yyyy hh:mm:ss a" }}
                    <!-- {{transaction?.completedByUsername}} -->
                  </div>
                </td>
                <td>
                  <div class="text-center">
                    <!-- <strong>{{makeMoney(transaction?.amount)}} </strong> -->
                    <strong *ngIf="statusOfAmount(transaction?.amount)" style="color: green"
                      >{{ makeMoney(transaction?.amount) }}
                    </strong>
                    <strong *ngIf="!statusOfAmount(transaction?.amount)" style="color: red"
                      >{{ makeMoney(transaction?.amount) }}
                    </strong>
                  </div>
                </td>
                <td>
                  <div class="text-center">
                    <strong>{{ makeMoney(transaction?.openingBalance) }}</strong>
                    <!-- <i class="" *ngIf="transaction?.statusId != 2">N/A</i> -->
                  </div>
                </td>
                <td>
                  <div class="text-center">
                    <strong>{{ makeMoney(transaction?.openingBalance + transaction?.amount) }}</strong>
                    <!-- <i class="" *ngIf="transaction?.statusId != 2">N/A</i> -->
                  </div>
                </td>
                <td>
                  <app-status-table-data [status]="transaction?.statusId"></app-status-table-data>
                </td>
                <td style="">
                  <div class="item-action dropdown">
                    <!-- <a href="#" data-toggle="dropdown" class="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-more-vertical"
                      >
                        <circle cx="12" cy="12" r="1"></circle>
                        <circle cx="12" cy="5" r="1"></circle>
                        <circle cx="12" cy="19" r="1"></circle>
                      </svg>
                    </a> -->
                    <div class="dropdown-menu dropdown-menu-right bg-black" role="menu">
                      <!-- <a *ngIf="roleLevelId == 1" data-toggle="modal" (click)="setSelectedTransaction(transaction)"
                      data-target="#modal-approve" data-toggle-class-target=".animate" class="dropdown-item trash">
                      Approve
                    </a>
                    <div *ngIf="roleLevelId == 1" class="dropdown-divider"></div>
                    <a *ngIf="roleLevelId == 1" data-toggle="modal" (click)="setSelectedTransaction(transaction)"
                      data-target="#modal-reject" data-toggle-class-target=".animate" class="dropdown-item trash">
                      Reject
                    </a>
                    <div *ngIf="roleLevelId == 1" class="dropdown-divider"></div> -->
                      <!-- <a
                        data-toggle="modal"
                        (click)="setSelectedTransaction(transaction)"
                        data-target="#modal-receipt"
                        data-toggle-class-target=".animate"
                        class="dropdown-item trash"
                      >
                        See Details
                      </a> -->
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
              <div class="col-sm-12 col-md-12">
                <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                  <span class="font-weight-bold"
                    >Showing {{ (transactions?.pageNumber - 1) * transactions?.pageSize + 1 }} to
                    {{ (transactions?.pageNumber - 1) * transactions?.pageSize + transactions?.data?.length }} of
                    {{ transactions?.data?.length }} entries</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-7" style="float: right">
            <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
              <ul class="pagination" style="float: right; margin-right: 36px">
                <pagination-controls (pageChange)="getTransaction(buildPageUrl($event, 10))"></pagination-controls>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<ng-template #viewMultisys let-modal>
  <div class="modal-header">
    <h4 class="modal-title">[Multisys] Transaction Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-multisys-transaction [requestId]="requestId"></app-multisys-transaction>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>

<ng-template #viewEcpay let-modal>
  <div class="modal-header">
    <h4 class="modal-title">[ECPay] Transaction Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-ecpay-transaction [requestId]="requestId"></app-ecpay-transaction>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>

<ng-template #viewCti let-modal>
  <div class="modal-header">
    <h4 class="modal-title">[CTI] Transaction Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-cti-transaction [requestId]="requestId"></app-cti-transaction>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>

<ng-template #viewEccash let-modal>
  <div class="modal-header">
    <h4 class="modal-title">[ECCash] Transaction Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-eccash-transaction [requestId]="requestId"></app-eccash-transaction>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>

<ng-template #viewEctopup let-modal>
  <div class="modal-header">
    <h4 class="modal-title">[ECTopUp] Transaction Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-ectopup-transaction
      [requestId]="requestId"
      *ngIf="transaction.transactionType == 'TOP_UP_ECPAY'"
    ></app-ectopup-transaction>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>
<ng-template #viewIBayadBankTransfer let-modal>
  <div class="modal-header">
    <h4 class="modal-title">[IBAYAD: INSTAPAY/PESONET] Transaction Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-instapay-transaction
      [requestId]="requestId"
      *ngIf="transaction.transactionType == 'IBAYAD_TRANSFER'"
    ></app-instapay-transaction>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>

<ng-template #viewPHPay let-modal>
  <div class="modal-header">
    <h4 class="modal-title">[PHPay] Transaction Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-phpay-transaction
      [requestId]="requestId"
      *ngIf="transaction.transactionType == 'PHPAY'"
    ></app-phpay-transaction>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>

<ng-template #viewVIPProtect let-modal>
  <div class="modal-header">
    <h4 class="modal-title">[VIP Protect] Transaction Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-vip-protect-transaction
      [requestId]="requestId"
      *ngIf="transaction.transactionType == 'VIP_PROTECT'"
    ></app-vip-protect-transaction>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Close</button>
  </div>
</ng-template>
