<div class="container-fluid --home">
    <div class="row">
        <div class="col-12">
            <div class="header mt-3">
                <div class="text">
                    <img src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file260fa82a-0150-4c07-a413-e289dffd98fb.png" width="65px" height="65px" alt=""> 
                    VIPS SEND V-WALLET
                </div>
            </div>
        </div>
        <div class="col-lg-5 col-md-12 col-sm-12 pl-5 pr-4" style="max-height: 316px;">
            <div class="box p-0 m-0">
                <app-widget-basic-profile></app-widget-basic-profile>
            </div>
        </div>
        <div class="col-lg-7 col-md-12 col-sm-12 pl-4 pr-5">
            <div class="row box1 box">
                <div class="col-12 pt-2" style="min-height: 180.56px;">
                    <h5>Send V-Wallet Funds</h5>
                    <hr>
                    <div class="form-group">
                      <app-textbox-currency
                      [parent-form-group]="sendFormGroup" 
                      tab-index-start="0"
                      control-name="bank"
                      [read-only]="false"
                      [error]="sendFormGroup?.controls.bank.errors | estatus"
                      number-only="false"
                      read-only="false"
                      label="Bank Name"
                      placeholder="Bank Name"
                    >
                    </app-textbox-currency>
                    </div>

                    <div class="form-group">
                      <app-textbox
                        [parent-form-group]="sendFormGroup"
                        tab-index-start="0"
                        control-name="accountNumber"
                        [read-only]="false"
                        [error]="sendFormGroup?.controls.accountNumber.errors | estatus"
                        read-only="false"
                        alpha-numeric="false"
                        label="Account Number"
                        placeholder="Account Number"
                        max-length="20"
                      >
                      </app-textbox>
                    </div>

                    <div class="form-group">
                      <app-textbox
                        [parent-form-group]="sendFormGroup"
                        tab-index-start="0"
                        control-name="accountName"
                        [read-only]="false"
                        [error]="sendFormGroup?.controls.accountName.errors | estatus"
                        read-only="false"
                        alpha-numeric="false"
                        label="Account Name"
                        placeholder="Account Name"
                        max-length="50"
                      >
                      </app-textbox>
                    </div>

                    <div class="form-group">
                      <app-textbox-currency
                      [parent-form-group]="sendFormGroup" 
                      tab-index-start="0"
                      control-name="amount"
                      [read-only]="false"
                      [error]="sendFormGroup?.controls.amount.errors | estatus"
                      number-only="false"
                      read-only="false"
                      label="Amount"
                      placeholder="0.00"
                      max-length="10"
                    >
                    </app-textbox-currency>
                    </div>
                  
                    <hr>
                    <a (click)="requestOtp()" class="btn btn-info button-next float-right mb-3"
                      style="color: white;" >
                      Request OTP &nbsp;
                      <span class="fa fa-hand-pointer"></span>
                    </a>
                    
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 pl-5 pr-5 mt-3">
            <div class="row box1 mt-3">
                <div class="col-12 pt-2">
                    <h5>V-WALLET SEND HISTORY</h5>
                    <div class="row mt-2 p-3">
                        <div class="col-lg-5 col-md-5 col-sm-12"></div>
                        <div class="col-lg-7 col-md-7 col-sm-12 pr-0">
                            <div class="input-group">
                                <input type="search" class="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                                <button type="button" class="btn btn-outline-primary">Search</button>
                            </div>
                        </div>
        
                        <div class="col-12 mt-3"  style="max-height: 230px; overflow-y: scroll;">
                            <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
                                <thead>
                                  <tr>
                                    <th data-sortable="true" data-field="name">
                                      <div>Date</div>
                                    </th>
                                    <th data-sortable="true" data-field="value">
                                      <div>Transaction No</div>
                                    </th>
                                    <th data-sortable="true" data-field="description">
                                      <div>Amount</div>
                                    </th>
                                    <th data-field="finish">
                                        <span class="d-none d-sm-block">Status</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    *ngFor="let transaction of transactions"
                                    (click)="viewUser(transaction?.id)"
                                    style="cursor: pointer"
                                    class="odd"
                                    role="row"
                                  >
                                    <td class="flex">
                                      <div class="item-except text-sm h-1x">
                                        {{ transaction?.date }}
                                      </div>
                                    </td>
                                    <td class="flex">
                                        <div class="item-except text-sm h-1x">
                                          {{ transaction?.transactionNo }}
                                        </div>
                                      </td>
                                    <td class="flex">
                                      <div class="item-except text-sm h-1x">
                                        {{ transaction?.amount }}
                                      </div>
                                    </td>
                    
                                    <td class="flex" style="max-width: 75px">
                                        <app-status-table-data [status]="transaction?.status"></app-status-table-data>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- <div class="col-lg-7 col-md-12 col-sm-12 pl-4 pr-5 mt-3">
          <div class="row box1 mt-3">
              <div class="col-12 pt-2">
                  <h5>REWARDS TRANSACTION HISTORY</h5>
                  <div class="row mt-2 p-3">
                      <div class="col-lg-5 col-md-5 col-sm-12"></div>
                      <div class="col-lg-7 col-md-7 col-sm-12 pr-0">
                          <div class="input-group">
                              <input type="search" class="form-control rounded" placeholder="Search" aria-label="Search" aria-describedby="search-addon" />
                              <button type="button" class="btn btn-outline-primary">Search</button>
                          </div>
                      </div>
      
                      <div class="col-12 mt-3"  style="max-height: 230px; overflow-y: scroll;">
                          <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
                              <thead>
                                <tr>
                                  <th data-sortable="true" data-field="name">
                                    <div>Date</div>
                                  </th>
                                  <th data-sortable="true" data-field="value">
                                    <div>Transaction No</div>
                                  </th>
                                  <th data-sortable="true" data-field="description">
                                    <div>User Type</div>
                                  </th>
                                  <th data-sortable="true" data-field="description">
                                      <div>Rewards</div>
                                  </th>
                                  <th data-field="finish">
                                      <span class="d-none d-sm-block">Status</span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  *ngFor="let transaction of transactions"
                                  (click)="viewUser(transaction?.id)"
                                  style="cursor: pointer"
                                  class="odd"
                                  role="row"
                                >
                                  <td class="flex">
                                    <div class="item-except text-sm h-1x">
                                      {{ transaction?.date }}
                                    </div>
                                  </td>
                                  <td class="flex">
                                      <div class="item-except text-sm h-1x">
                                        {{ transaction?.transactionNo }}
                                      </div>
                                    </td>
                                  <td class="flex">
                                    <div class="item-except text-sm h-1x">
                                      {{ transaction?.userType }}
                                    </div>
                                  </td>
                                  <td class="flex">
                                      <div class="item-except text-sm h-1x">
                                        {{ transaction?.rewards | number : "1.2-2"}}
                                      </div>
                                    </td>
                  
                                  <td class="flex" style="max-width: 75px">
                                      <app-status-table-data [status]="transaction?.status"></app-status-table-data>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                      </div>
                  </div>
              </div>
          </div>

      </div> -->
    </div>
</div>