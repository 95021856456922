<button (click)="waybill(myWaybillTemplate)">print</button>
<div class="bg-primary" style="height: 4014px ; width: 2167px; border: 1px solid black; font-family: 'HarmonyOS Sans'; margin: auto; margin-top: 20px; display: flex; flex-direction: column; align-items: center;" #myWaybillTemplate>
    <!-- ... your existing content ... -->

        <div style="display: flex; width: 100%;">
            <div class="d-flex justify-content-center align-items-center" style="width:70.8px; height: 42.5px; border: 1px solid black; border-width: 0 1px 1px 0;">
                <img src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file7c37f30a-639e-418b-8481-88b2972bcd46.png" alt="" width="55px">
            </div>
            <div style="width:155.9px; height: 42.5px; border: 1px solid black; border-width: 0 1px 1px 0;">
                BARCODE
            </div>
            <div style="width: 42px; height: 42.5px; border: 1px solid black; border-width: 0 1px 1px 0; display: flex; justify-content: center; align-items: center">
                <span style="font-family: HarmonyOS Sans; font-size: 15px; font-weight: bold">EZ</span>
            </div>
        </div>
        <div style="display: flex; width: 100%">
            <div style="width:157px; height: 14.1px; border: 1px solid black; border-width: 0 1px 1px 0; display:flex; justify-content: start; align-items:center">
                <span style="font-size: 7px;">
                    &nbsp;Order No: 000000000
                </span>
            </div>
            <div style="width:113.3px; height: 14.1px; border: 1px solid black; border-width: 0 1px 1px 0; display:flex; justify-content: center; align-items:center">
                
                <span style="font-size: 7px;">
                    &nbsp;2023/07/00 00:00:00
                </span>
            </div>
        </div>
        <div style="width: 100%; margin:0; padding: 0">
            <div style="width:271px; height: 40.269px; border: 1px solid black; border-width: 0 1px 1px 0; margin:0; padding-top: 2px">
                <p style="font-size: 5px; margin:0 0 2px 0; padding: 0">
                    &nbsp; RECEIVER:
                </p>
                <p style="font-size: 6px; margin:0; padding: 0">
                    &nbsp; <span style="font-weight: bold">Juan Dela Cruz</span> <br>
                    &nbsp; BATANGAS, BALAYAN, House No. 54 barangay (10) <br> 
                    &nbsp; Poblacion, balayan, Batangas
                </p>
            </div>
        </div>
        <div style="display: flex; width: 100%">
            <div style="width:157px; height: 40.5px; border: 1px solid black; border-width: 0 1px 0 0; border-bottom: 1px dashed black; padding-top: 2px">
                <p style="font-size: 5px; margin:0 0 4px 0; padding: 0">
                    &nbsp; SENDER:
                </p>
                <p style="font-size: 7px; margin:0; padding: 0">
                    &nbsp; <span style="font-weight: bold">
                        Maria Makiling</span> <br>
                    &nbsp; AH26, Calamba, 4027 Laguna
                </p>
            </div>
            <div style="width: 113.3px; height: 42.5px; border: 1px solid black; border-width: 0 1px 0 0; border-bottom: 1px dashed black; display: flex; justify-content: space-between; flex-direction: column;">
                <div style="width: 70%; display: flex; justify-content: space-between; margin-right: 30%;">
                    <span style="font-size: 6px; margin: 4px 0 0 0; padding: 0;">
                        &nbsp; <span style="font-weight:bold">COD:</span>
                    </span>
                    <span style="font-size: 6px; margin:  4px 0 0 0; padding: 0;">
                        &nbsp; Shipping Fee:
                    </span>
                </div>
                <div style="width: 70%; display: flex; justify-content: space-between; margin-right: 30%;">
                    <span style="font-size: 6px; margin: 0; padding: 0;">
                        &nbsp; Goods:
                    </span>
                </div>
                <div style="width: 70%; display: flex; justify-content: space-between;">
                    <span style="font-size: 6px; margin: 0 0 7px 0; padding: 0;">
                        &nbsp; Price:
                    </span>
                    <span style="font-size: 6px; margin: 0 0 7px 0; padding: 0;">
                        &nbsp; Weight:
                    </span>
                </div>
            </div>
    
    
        </div>
        <div style="display: flex; width: 100%">
            <div class="d-flex justify-content-center align-items-center"  style="width:71px; height: 42.5px; border: 1px solid black; border-width: 0 1px 1px 0;">
                <img src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file7c37f30a-639e-418b-8481-88b2972bcd46.png" alt="" width="55px">
            </div>
            <div style="width:155.9px; height: 42.5px; border: 1px solid black; border-width: 0 1px 1px 0;">
                Barcode
            </div>
            <div style="width: 42px; height: 42.5px; border: 1px solid black; border-width: 0 1px 1px 0; display: flex; justify-content: center; align-items: center">
                <span style="font-family: HarmonyOS Sans; font-size: 20px; font-weight: bold">EZ</span>
            </div>
        </div>
        <div style="display: flex; width: 100%">
            <div style="width:157px; height: 14.1px; border: 1px solid black; border-width: 0 1px 1px 0; display:flex; justify-content: start; align-items:center">
                <span style="font-size: 7px;">
                    &nbsp;Order No: 000000000
                </span>
            </div>
            <div style="width:113.3px; height: 14.1px; border: 1px solid black; border-width: 0 1px 1px 0; display:flex; justify-content: center; align-items:center">
                <span style="font-size: 7px;">
                    &nbsp;2023/07/00 00:00:00
                </span>
            </div>
        </div>
        <div style="display: flex; width: 100%">
            <div style="width:271px; height: 28.3px; border: 1px solid black; border-width: 0 1px 1px 0; display: flex; justify-content: center; align-items:center">
                <span style="font-size: 23px; font-weight: 900">000-0000000 - 000</span>
            </div>
        </div>
        
        <div style="display: flex; width: 100%">
            <div>
                <div style="width: 227px; height: 28.3px; border: 1px solid black; border-width: 0 1px 1px 0;">
                    <span>&nbsp;Barangay &nbsp;&nbsp;&nbsp;&nbsp;(0123)</span>
                </div>
                <div style="width: 226.7px; height: 54.6px; border: 1px solid black; border-width: 0 1px 1px 0; padding-top: 2px">
                    <p style="font-size: 5px; margin:0 0 4px 0; padding: 0">
                        &nbsp; RECEIVER:
                    </p>
                    <p style="font-size: 7px; margin:0; padding: 0">
                        &nbsp; <span style="font-weight: bold">Juan Dela Cruz</span> <br>
                        &nbsp; BATANGAS, BALAYAN, House No. 54 barangay (10) <br> 
                        &nbsp; Poblacion, balayan, Batangas
                    </p>
                </div>
                <div style="width: 226.7px; height: 26.34px; border: 1px solid black; border-width: 0 1px 1px 0; padding-top: 2px">
                    <p style="font-size: 5px; margin:0 0 2px 0; padding: 0">
                        &nbsp; SENDER:
                    </p>
                <p style="font-size: 7px; margin:0; padding: 0">
                    &nbsp; <span style="font-weight: bold">Maria Makiling</span> <br>
                    &nbsp; AH26, Calamba, 4027 Laguna
                </p>
                </div>
                <div style="display: flex; width: 227.5px; height: 42.5px; ">
                    <div style="width: 141.7px; height: 42.5px; border: 1px solid black; border-width: 0 1px 1px 0; display: flex; justify-content: space-between; flex-direction: column;">
                        <div style="width: 70%; display: flex; justify-content: space-between; margin-right: 30%;">
                            <span style="font-size: 6px; margin: 4px 0 0 0; padding: 0;">
                                &nbsp; <span style="font-weight:bold">COD:</span>
                            </span>
                            <span style="font-size: 6px; margin:  4px 0 0 0; padding: 0;">
                                &nbsp; Shipping Fee:
                            </span>
                        </div>
                        <div style="width: 70%; display: flex; justify-content: space-between; margin-right: 30%;">
                            <span style="font-size: 6px; margin: 0; padding: 0;">
                                &nbsp; Goods:
                            </span>
                        </div>
                        <div style="width: 70%; display: flex; justify-content: space-between;">
                            <span style="font-size: 6px; margin: 0 0 7px 0; padding: 0;">
                                &nbsp; Price:
                            </span>
                            <span style="font-size: 6px; margin: 0 0 7px 0; padding: 0;">
                                &nbsp; Weight:
                            </span>
                        </div>
                    </div>
                    <div style="width: 87px; height: 42.5px; border-right: 1px solid black;">
                        <div style="height: 14.17px; width: 87px; border: 1px solid black; border-width: 0 0 1px 0; display: flex; justify-content: center; align-items: center">
                            <span style="font-size: 8px">DELIVERY ATTEMPS</span>
                        </div>
                        <div style="display:flex">
                            <div style="height: 27.5px; width: 28.3px; border: 1px solid black; border-width: 0 1px 1px 0;display: flex; justify-content: center; align-items: center">
                                1
                            </div>
                            <div style="height: 27.5px; width: 28.3px; border: 1px solid black; border-width: 0 1px 1px 0;display: flex; justify-content: center; align-items: center">
                                2
                            </div>
                            <div style="height: 27.5px; width: 28.3px; border: 1px solid black; border-width: 0 0 1px 0;display: flex; justify-content: center; align-items: center">
                                3
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div style="width: 42.5px; border: 1px solid black; border-width: 0 1px 1px 0;">
                
            </div>
        </div>
        <div style="display: flex; width: 100%">
            <div style="width:138.73px; height: 109.3px; border: 1px solid black; border-width: 0 1px 1px 0; padding-top: 4px">
                <p style="font-size: 5px; margin:0 0 4px 0; padding: 0">
                    &nbsp; Remarks / Signature:
                </p>
            </div>
            <div style="width:131px; height: 113.3px; border: 1px solid black; border-width: 0 1px 1px 0; display: flex; justify-content: center; align-items: center">
                QR
            </div>
        </div>
        <div style="height: 14.1px; margin: 0; padding: 0; display: flex">
            <span style="font-size: 8px; margin: 0; padding: 0">JT0000</span>
        </div>
    </div>
 