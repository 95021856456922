<!-- <app-loader *ngIf="processUpdate"></app-loader> -->
<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">Service Charge History</h2>
          <small class="text-highlight font-weight-normal font-italic"
            >JuanPay list of Service Charge transactions</small
          >
        </div>
        <div class="flex"></div>
        <div>
          <div *ngIf="transactions">
            <button class="btn btn-md btn-success w-100" (click)="exportToExcel()">
              <i class="fa fa-file-excel"></i> &nbsp; Export to Excel
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="padding">
        <div class="toolbar">
          <!-- Filters -->

          <div class="card pr-2 pt-2 pl-2 mb-2 w-100" style="background-color: #182038">
            <div class="row" [formGroup]="searchFormGroup">
              <div class="col-sm-4 mb-2">
                <ng-select
                  [items]="userIdFilterOptions"
                  [placeholder]="
                    '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Select Username &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
                  "
                  [allowClear]="true"
                  formControlName="username"
                  bindLabel="text"
                  bindValue="id"
                ></ng-select>
              </div>

              <div class="col-sm-3 pr-0">
                <input
                  type="text"
                  placeholder="Date Range"
                  class="form-control"
                  bsDaterangepicker
                  formControlName="dateRange"
                  style="background-color: white; color: black"
                  [bsConfig]="{
                    rangeInputFormat: 'YYYY-MM-DD',
                    dateInputFormat: 'YYYY-MM-DD',
                    showWeekNumbers: false
                  }"
                />
              </div>

              <div class="col-sm-3 pr-0">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control search"
                    placeholder="Request ID"
                    formControlName="requestId"
                    style="background-color: white; color: black"
                  />
                </div>
              </div>

              <div class="col-2">
                <button class="btn btn-sm btn-primary w-100" (click)="getTransaction()">
                  <i class="fa fa-search"></i> &nbsp; Search
                </button>
              </div>
              <div class="col-2"></div>
            </div>
          </div>

          <!-- List Toolbar Form -->
          <form class="form-inline"></form>

          <!-- Search Button -->
        </div>

        <div class="card">
          <table
            id="table"
            class="table table-theme table-row v-middle dataTable no-footer table-hover"
            data-plugin="bootstrapTable"
            data-toolbar="#toolbar"
            data-search="true"
            data-search-align="left"
            data-show-export="true"
            data-show-columns="true"
            data-detail-view="false"
            data-mobile-responsive="true"
            data-pagination="true"
            data-page-list="[10, 25, 50, 100, ALL]"
          >
            <thead>
              <tr>
                <th data-sortable="true" data-field="id">Details</th>
                <th data-sortable="true" data-field="owner">Identity</th>
                <th data-sortable="true" data-field="project">Amount</th>
                <th data-field="finish"><span class="d-none d-sm-block">Status</span></th>
              </tr>
            </thead>

            <tbody>
              <tr
                class=" "
                *ngFor="
                  let transaction of transactions?.data
                    | paginate: { itemsPerPage: pageRows, currentPage: p, totalItems: transactions.totalRecords }
                "
                class="odd"
                role="row"
                (click)="setSelectedTransaction(transaction, viewServiceCharge)"
              >
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary" style="font-weight: bold">Req. ID :</span> {{ transaction?.requestId }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary" style="font-weight: bold">Amount :</span>
                    {{ makeMoney(transaction?.amount) }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary" style="font-weight: bold">Remarks :</span>
                    {{ transaction?.remarks }}
                  </div>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary" style="font-weight: bold">Transact by :</span>
                    {{ transaction?.transactByUser.username }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary" style="font-weight: bold">Created date </span>:
                    {{ transaction?.createdDate | date: "MMMM dd, yyyy hh:mm:ss a" }}
                  </div>
                  <div class="item-except text-sm h-1x">
                    <span class="text-secondary" style="font-weight: bold">Completed date :</span>
                    {{ transaction?.completedDate | date: "MMMM dd, yyyy hh:mm:ss a" }}
                    <!-- {{transaction?.completedByUsername}} -->
                  </div>
                </td>
                <td>
                  <div class="text-center">
                    <!-- <strong>{{makeMoney(transaction?.amount)}} </strong> -->
                    <strong style="color: green">{{ makeMoney(transaction?.amount) }} </strong>
                  </div>
                </td>
                <td>
                  <app-status-table-data [status]="transaction?.statusId"></app-status-table-data>
                </td>
                <td>
                  <div class="item-action dropdown">
                    <!-- <a href="#" data-toggle="dropdown" class="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-more-vertical"
                        >
                          <circle cx="12" cy="12" r="1"></circle>
                          <circle cx="12" cy="5" r="1"></circle>
                          <circle cx="12" cy="19" r="1"></circle>
                        </svg>
                      </a> -->
                    <div class="dropdown-menu dropdown-menu-right bg-black" role="menu">
                      <!-- <a *ngIf="roleLevelId == 1" data-toggle="modal" (click)="setSelectedTransaction(transaction)"
                          data-target="#modal-approve" data-toggle-class-target=".animate" class="dropdown-item trash">
                          Approve
                        </a>
                        <div *ngIf="roleLevelId == 1" class="dropdown-divider"></div>
                        <a *ngIf="roleLevelId == 1" data-toggle="modal" (click)="setSelectedTransaction(transaction)"
                          data-target="#modal-reject" data-toggle-class-target=".animate" class="dropdown-item trash">
                          Reject
                        </a>
                        <div *ngIf="roleLevelId == 1" class="dropdown-divider"></div> -->
                      <!-- <a
                          data-toggle="modal"
                         
                          data-target="#modal-receipt"
                          data-toggle-class-target=".animate"
                          class="dropdown-item trash"
                        >
                          See Details
                        </a> -->
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
              <div class="col-sm-12 col-md-12">
                <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                  Showing {{ (transactions?.pageNumber - 1) * transactions?.pageSize + 1 }} to
                  {{ (transactions?.pageNumber - 1) * transactions?.pageSize + transactions?.data?.length }} of
                  {{ transactions?.data?.length }} entries
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-7" style="float: right">
            <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
              <ul class="pagination" style="float: right; margin-right: 36px">
                <pagination-controls (pageChange)="getTransaction(buildPageUrl($event, 10))"></pagination-controls>
              </ul>
            </div>
          </div>
        </div>

        <ng-template #viewServiceCharge let-modal>
          <div class="modal-header">
            <h4 class="modal-title">[Service Charge] Transaction Details</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <app-service-charge-transaction
              (closeModal)="closeModal()"
              [requestId]="requestId"
            ></app-service-charge-transaction>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" (click)="modal.dismiss('Cross click')">Close</button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>
