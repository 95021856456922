import { FormGroup } from "@angular/forms";
import { Input } from "@angular/core";
import { Component } from "@angular/core";

@Component({
  selector: "app-password",
  templateUrl: "./password.component.html",
  styleUrls: ["./password.component.less"],
})
export class PasswordComponent {
  @Input("parent-form-group") parentFormGroup: FormGroup;
  @Input("control-name") controlName: string;
  @Input("label") label: string;
  @Input("placeholder") placeholder: string;
  @Input("upper-case") upperCase: boolean;
  @Input("no-special-char") noSpecialChar: boolean;
  @Input("max-length") maxLength: number;
  @Input("tab-index-start") tabIndexStart: number;

  @Input("error") error: boolean;
  @Input("error-message") errorMessage: string;

  constructor() {}

  ngOnInit(): void {}
}
