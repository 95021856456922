import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { RolesService } from "src/data/services/web/roles.service";
import { WebSettingsService } from "src/data/services/web/web-settings.service";
import { environment } from "src/environments/environment";
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: "app-web-settings-edit",
  templateUrl: "./web-settings-edit.component.html",
  styleUrls: ["./web-settings-edit.component.less"],
})
export class WebSettingsEditComponent implements OnInit {
  public webSettingsFormGroup: FormGroup;
  public webSettingValuesFormGroup: FormGroup;
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public rolesService: RolesService;
  public emptyString = "";
  public bsModalRefConfirmation: BsModalRef;
  public modalService: BsModalService;

  public accessToken: string;
  public newWebSettings: boolean;
  public WebSettings: any;
  public loadFlag = false;
  public WebSettingsId: any;
  public base64Photo: string | ArrayBuffer;
  public roleLevelId: any;
  public formBuilder: FormBuilder;

  processCreate = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    private webSettingsService: WebSettingsService,
    modalService: BsModalService,
    formBuilder: FormBuilder
  ) {
    this.formBuilder = formBuilder;
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.rolesService = rolesService;
    this.modalService = modalService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
    PNotifyButtons;
  }

  ngOnInit(): void {
     
    this.roleLevelId = this.localStorageCacheService.getStorage("role_level");
    this.route.params.subscribe((params) => {
      const id = decodeURI(params.id || "");
      const status = decodeURI(params.status || "");

      if (id) {
        this.WebSettingsId = this.cryptoService.get(this.data.authentication.privateKey, id);
        this.getWebSettings(this.WebSettingsId);
      }
    });
  }

  initWebSettingsFormGroup(webSettings: any): void {

    this.webSettingsFormGroup = new FormGroup({
      id: new FormControl(webSettings.id ? webSettings.id : this.emptyString),
      name: new FormControl(webSettings.name ? webSettings.name : this.emptyString),
      description: new FormControl(webSettings.description ? webSettings.description : this.emptyString),
      value: new FormControl(webSettings.value ? webSettings.value : this.emptyString),
      status: new FormControl(webSettings.status ? webSettings.status : this.emptyString),
      values: this.formBuilder.array([]),
    });

    this.populateWebSettingValues(webSettings.values);

    this.loadFlag = true;

    this.webSettingsFormGroup.valueChanges.subscribe((value) => {
    });
  }

  populateWebSettingValues(webSettings: any): void {
  
    const values = JSON.parse(JSON.stringify(JSON.parse(webSettings)));
    values.forEach((data) => {
      const webSettingValuesFromGroup = this.formBuilder.group({
        parameter: this.formBuilder.control(data.parameter, Validators.required),
        value: this.formBuilder.control(data.value, Validators.required),
      });

      (this.webSettingsFormGroup.controls.values as FormArray).push(webSettingValuesFromGroup);
    });
  }

  addWebSettingValues(): void {
    const webSettingValuesFromGroup = this.formBuilder.group({
      parameter: this.formBuilder.control(this.emptyString, Validators.required),
      value: this.formBuilder.control(this.emptyString, Validators.required),
    });

    (this.webSettingsFormGroup.controls.values as FormArray).push(webSettingValuesFromGroup);
  }

  addUpWebSettingValues(): void {
    const webSettingValuesFromGroup = this.formBuilder.group({
      parameter: this.formBuilder.control(this.emptyString, Validators.required),
      value: this.formBuilder.control(this.emptyString, Validators.required),
    });

    (this.webSettingsFormGroup.controls.values as FormArray).insert(0,webSettingValuesFromGroup);
  }

  deleteWebSettingValues(webSettingIndex: number): void {
    (this.webSettingsFormGroup.controls.values as FormArray).removeAt(webSettingIndex);
  }

  getWebSettings(id: any): void {
    this.webSettingsService.getWebSettingsSingle(id).subscribe((result) => {
      this.initWebSettingsFormGroup(result);
    });
  }

  public actionEventSaveWebSettings(): void {
    
    this.processCreate = true;

    this.webSettingsService.updateWebSettings(this.webSettingsFormGroup.value, this.WebSettingsId).subscribe(
      (result) => {
          PNotify.success({
          title: "Success",
          text: "Web setting updated successfully.",
        });
        this.processCreate = false;
      },
      (error) => {
        if (error.status === 400) {

          const obj = error.error.errors;
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              let str: string = key;
              str = str[0].toLowerCase() + str.slice(1);
              this.webSettingsFormGroup.controls[str].setErrors({
                required: Validators.required,
                apiMessage: obj[key],
              });
            }
          }

          this.processCreate = false;
        } else {
          PNotify.error({
            title: "Error",
            text: "Internal server error.",
          });
          // Other error
          this.processCreate = false;
        }
      }
    );
  }

  backToWebSettingssList(): void {
    this.router.navigate(["acm", "web-settings-list"]);
  }
}
