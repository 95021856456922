import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-home-holidays",
  templateUrl: "./home-holidays.component.html",
  styleUrls: ["./home-holidays.component.less"],
})
export class HomeHolidaysComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
