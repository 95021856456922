import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { FileUploadService } from 'src/data/services/web/file-upload.service';
import { RolesService } from 'src/data/services/web/roles.service';

@Component({
  selector: 'app-all-files-list',
  templateUrl: './all-files-list.component.html',
  styleUrls: ['./all-files-list.component.less']
})
export class AllFilesListComponent implements OnInit {

  public listFilterForm = new FormGroup({
    searchString: new FormControl(""),
    searchStringField: new FormControl(""),
  });

  public rolesService: RolesService;
  public fileUploadService: FileUploadService;
  public localStorageCacheService: LocalStorageCacheService;

  public userID: any;
  public managerFiles: any;
  public managerFile: any;

  // myThumbnail="../../../assets/images/banner/login-bg-3.jpg";
  // myFullresImage="../../../assets/images/banner/login-bg-3.jpg";
  
  constructor( 
    rolesService: RolesService,
    private modalService: NgbModal, 
    localStorageCacheService: LocalStorageCacheService,
    fileUploadService: FileUploadService
    ) { 
      this.fileUploadService = fileUploadService;
      this.rolesService = rolesService;
      this.localStorageCacheService = localStorageCacheService;
    }

  ngOnInit(): void {
    this.getAllManagerFiles();
  }

  getAllManagerFiles(){
    this.fileUploadService.getAllManagerFiles().subscribe((result) => {
      this.managerFiles = result;
    });

     
  }

  getCompanyUsers(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
  
  }

  openFileUpload(content: any) : void {
    this.modalService.open(content, { centered: true, size: "lg" });
  }

  viewContent(content: any, managerFile) : void {
    this.managerFile = managerFile;
    this.modalService.open(content, { centered: true, size: "lg" });
  }

  buildUserPhoto(managerFile: any): any {
    return managerFile?.photoFormat + "," + managerFile?.photo;
  }
}
