import { Injectable } from '@angular/core';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailSubsService {
  private localStorageCacheService: LocalStorageCacheService;
  private httpClientNoInterceptor: HttpClient;
  constructor(
    private httpClient : HttpClient,
    httpBackend: HttpBackend,
    localStorageCacheService: LocalStorageCacheService
  ) {
    this.httpClientNoInterceptor = new HttpClient(httpBackend);
    this.localStorageCacheService = localStorageCacheService;
   }
   private baseAPIUrl = environment.API_URL;
   public PostSubscribe(FormData: any): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/Vips/Subscriber`;
    const accessToken: any = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Content-Type", "application/json");

    return this.httpClient.post(`${endPointUrl}`,FormData, { headers: httpHeaders });
   }
}
