<app-loader *ngIf="loader"></app-loader>

<div *ngIf="!loader" [class.isPrinting]="printService.isPrinting" class="col-lg-12 col-md-12 col-sm-12">
  <div class="col-lg-12 col-md-12 col-sm-12">
    <div class="card">
      <div class="list list-row">
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-md d-none d-md-block color-red">
              <span class="text-dark">Request ID: </span> <strong>{{ requestId }}</strong>
            </div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">
              <a style="color: blue" (click)="printReceipt()">Print Receipt <span class="fa fa-print"></span></a> <br />
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" [formControl]="isA4" />
                <label class="form-check-label text-muted" for="flexCheckDefault" style="font-size: 12px">
                  Print via A4
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-6 col-md-6 col-sm-12 pull-right">
    <div class="card">
      <div class="list list-row">
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">
              <strong
                >Service Charge <br />
                Service Charge (GO_SC)</strong
              >
            </div>
          </div>
          <!-- <div class="no-wrap">
            <div class="item-feed h-2x">
              <img
                src="https://i.ibb.co/7gFfXJZ/banner-mobile-3.png"
                height="50"
                class="img-responsive"
                style="object-fit: cover"
              />
            </div>
          </div> -->
        </div>
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Status</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x"><app-status-table-data [status]="data?.statusId"></app-status-table-data></div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Amount</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.amount | number: "1.2-2" }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Remarks</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.remarks }}</div>
          </div>
        </div>

        <hr />
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Total Amount</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.amount | number: "1.2-2" }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-6 col-md-6 col-sm-12 pull-left">
    <div class="card">
      <div class="list list-row">
        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">
              <strong
                >{{ data?.transactByUser.cashierUser.firstName + " " + data?.transactByUser.cashierUser.lastName }}
                <br />
                {{ data?.transactByUser.email }}</strong
              >
            </div>
          </div>
          <!-- <div class="no-wrap">
            <div class="item-feed h-2x">
              <img
                [src]="buildUserPhoto(data?.transactByUser?.cashierUser) | authImage | async"
                height="50"
                class="avatar w-40 img-responsive"
                style="object-fit: cover"
              />
            </div>
          </div> -->
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Created Date</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.createdDate | date: "MMM dd, yyyy hh:mm:ss a" }}</div>
          </div>
        </div>

        <div class="list-item" data-id="19">
          <div class="flex">
            <div class="item-date text-muted text-sm d-none d-md-block">Completed Date</div>
          </div>
          <div class="no-wrap">
            <div class="item-feed h-2x">{{ data?.completedDate | date: "MMM dd, yyyy hh:mm:ss a" }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div #vipLogo hidden>
  <img
    src="https://juanpaycdn.azureedge.net/juanpayassets/LOGO_logowithtagline-removebg-preview.png"
    alt="Go-VIP-Logo-Logo-Small"
    height="80px"
  />
</div>
