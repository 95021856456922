<app-loader *ngIf="loader"></app-loader>

<div class="card p-3 w-100" >

    <div *ngIf="ticketHistories && ticketHistories?.length > 0" class="row row-lg">
        
        <div class="col-lg-12">
            <div class="card" data-sr-id="19" style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;">
                <div class="p-3-4">
                    <div class="d-flex">
                        <div>
                            <div>Tickets History</div>
                            <small class="text-muted">Filed : {{ticketHistories.length}}</small>
                        </div>
                        <span class="flex"></span>
                        <div *ngIf="reOpenButton(ticketHistories[0].status) && isOpenTicketVisible">
                            <a  (click)="reOpenTicket()" class="btn btn-sm btn-warning">Re-Open Ticket &nbsp; <i class="fa fa-envelope-open"></i></a>
                        </div>
                    </div>
                </div>
                <table  class="table table-striped table-hover v-middle m-0" role="grid">
                    <thead>
                        <tr>
                          <th data-sortable="true" >
                            <div>Ticket No.</div>
                          </th>
                          <th data-sortable="true" >
                            <div class="pull-end" >Trail</div>
                          </th>
                          <th data-sortable="true">
                            <div class="pull-end" >Last Status</div>
                          </th>
                          <th data-sortable="true" >
                            <div class="pull-end">Date Created</div>
                          </th>
                        </tr>
                      </thead>
                    <tbody>
                        <tr class=" " data-id="16"  *ngFor="let ticketHistory of ticketHistories" >
                            <td style="min-width:30px;">
                                {{ticketHistory.code}}
                            </td>
                            <td>
                                <div class="avatar-group ">
                                    <button *ngFor="let history of ticketHistory.history" class="avatar ajax w-32 gd-{{trailColor(history.status)}}" data-toggle="popover"
                                    data-trigger="hover" data-html="false" data-content="{{lastStatus(history.status)}}" data-original-title="" title="" >
                                        {{history.status}}
                                    </button>
                                </div>
                            </td>
                            <td class="flex">
                                <label class="badge badge-{{trailColor(ticketHistory.status)}} text-uppercase">
                                    {{lastStatus(ticketHistory.status)}}
                                </label>
                            </td>
                            <td>
                                <span class="item-amount d-none d-sm-block text-sm "> {{ticketHistory.date_created}}</span>
                            </td>

                            
                          
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        </div>
    <div *ngIf="ticketHistories?.length == 0 && isOpenTicketVisible">
        <div class="w-100 d-flex justify-content-end">
            <button class="btn btn-sm btn-primary"  (click)="submitTicket()"> Submit Ticket &nbsp; <i class="fa fa-paper-plane"></i> </button>
        </div>   

        <hr>
        <div [formGroup]="ticketFormGroup" class="col-sm-12 mb-12">

            <label> Reason for Ticket Filing : </label> &nbsp;
            <ng-select2
              [data]="statusOPtion"
              [placeholder]="'&nbsp;&nbsp;&nbsp;&nbsp; Select Status &nbsp;&nbsp;&nbsp;&nbsp;'"
              [allowClear]="true"
              formControlName="status"
            ></ng-select2>
          </div>

        <hr>
        <div class="w-100">
            <angular-editor [(ngModel)]="htmlContent" [config]="config"></angular-editor>
        </div>
    </div>
    



</div>



  <!-- <td>
                                <div class="item-action dropdown">
                                    <a href="#" data-toggle="dropdown" class="text-muted">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-more-vertical"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right bg-black" role="menu">
                                        <a class="dropdown-item" href="#">
                                            See detail
                                        </a>
                                        <a class="dropdown-item download">
                                            Download
                                        </a>
                                        <a class="dropdown-item edit">
                                            Edit
                                        </a>
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item trash">
                                            Delete item
                                        </a>
                                    </div>
                                </div>
                            </td> -->