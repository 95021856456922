<app-loader *ngIf="loader"></app-loader>
<div id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="pr-5 pl-5" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">Food Order Activity History</h2>
          <small class="text-highlight font-weight-normal font-italic">JuanPay list of Vips Transactions</small>
        </div>
      </div>
    </div>

    <div class="page-content pr-5 pl-5" id="page-content">
      <div class="card p-2">
        <div class="card mb-2 mt-0" style="box-shadow: 0 2px 4px 0 #182038, 0 2px 5px 0 #182038">
          <div class="row" [formGroup]="searchByFormGroup">
            <table id="datatable" class="table" role="grid" aria-describedby="datatable_info">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>
                    <button
                      class="btn btn-sm mt-2 btn-info button--search font-weight-bold"
                      style="float: right"
                      (keyup.enter)="searchOrderID()"
                      (click)="searchOrderID()"
                    >
                      Search &nbsp; <i class="fa fa-search text-highlight"></i>
                    </button>

                    <button
                      class="btn btn-sm mr-2 mt-2 btn-success button--create font-weight-bold"
                      (click)="exportexcel()"
                    >
                      Export Data &nbsp; <i class="fa fa-file-export text-highlight"></i>
                    </button>
                  </th>
                  <th style="width: 50px"></th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor: pointer" class="odd" role="row">
                  <td class="td-title pl-5">
                    <!-- <label class="label-filter" style="color: #182038; font-weight: bold;">Date Range</label> -->
                    &nbsp;
                  </td>

                  <td class="td-value">
                    &nbsp;
                    <!-- <input
                        type="text"
                        placeholder="Date Range"
                        class="form-control"
                        bsDaterangepicker
                        formControlName="dateRange"
                        style="background-color: white; color: black; height: 37px; border-color: #182038;"
                        [bsConfig]="{
                        rangeInputFormat: 'YYYY-MM-DD',
                        dateInputFormat: 'YYYY-MM-DD',
                        showWeekNumbers: false
                        }"
                      /> -->
                  </td>

                  <td class="td-title">
                    <label class="label-filter" style="color: #182038; font-weight: bold">Search</label>
                  </td>
                  <td class="td-value">
                    <input
                      type="text"
                      class="form-control search w-100"
                      placeholder="Order ID"
                      (keyup.enter)="searchOrderID()"
                      formControlName="searchString"
                      style="background-color: white; color: #182038; height: 37px; border-color: #182038"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card">
          <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
            <thead style="background-color: #182038; color: #fff">
              <tr>
                <th data-sortable="true" data-field="id">Transaction Id</th>
                <th data-sortable="true" data-field="project">Customer Name</th>
                <th data-sortable="true" data-field="project">Customer Number</th>
                <th data-sortable="true" data-field="owner">Payment Method</th>
                <th data-sortable="true" data-field="project">Order Type</th>
                <th data-sortable="true" data-field="project">Order Status</th>
                <th class="text-center" data-sortable="true" data-field="id">Total Amount</th>
                <th class="text-center" data-sortable="true" data-field="project">Transaction Date</th>
              </tr>
            </thead>
            <tbody>
              <tr
                (click)="openDetails(transactionDetails, food)"
                style="cursor: pointer"
                class="odd"
                role="row"
                *ngFor="let food of foods"
              >
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ food?.order_id }}
                  </div>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ food?.user_delivery_instructions?.name }}
                  </div>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ food?.user_delivery_instructions?.mobile_number }}
                  </div>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ food?.payment_method }}
                  </div>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ food?.order_method }}
                  </div>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x text-right">
                    {{ makeMoney(getTotalAmount(food?.orders)) }}
                  </div>
                </td>

                <td class="flex">
                  <div
                    class="item-except text-sm h-1x text-center font-weight-bold"
                    [ngClass]="
                      food?.order_status == 6 ? 'text-danger' : food?.order_status == 5 ? 'text-primary' : 'text-dark'
                    "
                  >
                    {{ _stepperTitle[food?.order_status] }}
                  </div>
                </td>

                <!-- <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ food?.riders[0] }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ food?.riders[0]?.plate_number }}
                  </div>
                </td> -->
                <td class="flex">
                  <div class="item-except text-sm h-1x text-center">
                    {{ food?.order_created_date?.toDate() | date: "MMM dd,yyyy h:mm:ss a" }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <ng-template #transactionDetails let-modal>
      <div class="modal-header">
        <h3 class="modal-title">Order Details</h3>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div
            class="col-5"
            style="display: flex; flex-direction: column; justify-content: center; align-items: flex-start"
          >
            <div
              *ngIf="selectedOrder.rider_id"
              class="card mb-3 w-100 d-flex"
              style="
                flex-direction: row;
                align-items: center;
                background: #9fcbef80;
                padding-inline: 30px;
                gap: 20px;
                padding-block: 10px;
              "
            >
              <div class="overflow-hidden" style="height: 55px">
                <img class="rounded-circle shadow-4-strong" id="circle" [src]="selectedRider?.profile_pic" alt="" />
              </div>
              <div class="d-block">
                <h6>
                  {{ selectedRider?.first_name.toUpperCase() }} {{ selectedRider?.middle_name.toUpperCase() }}
                  {{ selectedRider?.last_name.toUpperCase() }}
                  &nbsp;
                  <span
                    >5.0&nbsp;
                    <i style="color: yellow" class="fa fa-star" aria-hidden="true"></i>
                  </span>
                </h6>
                <h6 class="font-weight-bold">
                  Plate No. <span>{{ selectedRider?.vehicle_plate_no }}</span>
                </h6>
                <h6 class="font-weight-bold text-primary">
                  <i class="fa fa-heartbeat" aria-hidden="true"></i>&nbsp;&nbsp; "I am fully vaccinated"
                </h6>
              </div>
              <i
                style="font-size: 30px; color: #182038; position: absolute; right: 50px"
                class="fa fa-phone"
                aria-hidden="true"
              ></i>
            </div>
            <div class="d-flex">
              <div class="icons">
                <i style="font-size: 20px" class="fa fa-crosshairs text-primary" aria-hidden="true"></i>
                <i
                  style="font-size: 20px; padding-left: 5px"
                  class="fa fa-ellipsis-v text-muted"
                  aria-hidden="true"
                ></i>
                <i
                  style="font-size: 20px; padding-left: 2px"
                  class="fa fa-map-marker text-danger"
                  aria-hidden="true"
                ></i>
              </div>
              <div class="text_location">
                <div class="pick_up">
                  <h5>Pick-up</h5>
                  <label class="text-muted mt-1"
                    >{{ selectedOrder.store_name }}
                    <!-- - (Latitue: {{selectedOrder.store_longlat.latitude}} - Longitude: {{selectedOrder.store_longlat.longitude}}) -->
                  </label>
                </div>
                <div class="drop_off">
                  <h5 style="padding-top: 5px">Drop-off</h5>
                  <label class="text-muted mt-1">{{ selectedOrder.user_address }} </label>
                </div>
              </div>
            </div>
            <div class="order_summary w-100 mt-2">
              <div class="d-flex mt-2" style="height: 20px">
                <h5>Order summary: {{ selectedOrder.order_id }}</h5>
              </div>
              <table class="table">
                <tbody>
                  <tr *ngFor="let order of selectedOrder.orders">
                    <th scope="row" style="display: flex; flex-direction: row; gap: 20px">
                      <h6>{{ order.quantity }}x</h6>
                      <div class="d-block">
                        <h6>{{ order.product_name }}</h6>
                        <span class="text-muted" *ngIf="order.options.length != 0">
                          <span *ngFor="let option of order.options">
                            Options: ({{ option.option_name + " - " }}
                            <span *ngFor="let choice of option.choices; let i = index">
                              {{ choice.choice_name }} {{ i === option.choices.length - 1 ? "" : ",&nbsp;" }} </span
                            >)
                          </span>
                        </span>
                        <span class="text-muted" *ngIf="order.options.length == 0">No options</span>
                      </div>
                    </th>
                    <td></td>
                    <td class="text-right">₱ {{ makeMoney(order.total_price) }}</td>
                  </tr>

                  <tr>
                    <th>
                      <h6>Created Date</h6>
                    </th>
                    <td></td>
                    <td class="text-right">
                      {{ selectedOrder?.order_created_date?.toDate() | date: "MMM dd,yyyy hh:mm:ss aa" }}
                    </td>
                  </tr>

                  <tr>
                    <th>
                      <div class="fee">
                        <h6>Subtotal</h6>
                        <h6>Rider Fee</h6>
                      </div>
                    </th>
                    <td></td>
                    <td>
                      <div class="subtotal_price text-right">
                        <h6>₱ {{ makeMoney(getTotalAmount(selectedOrder.orders)) }}</h6>
                        <h6>₱ {{ makeMoney(selectedOrder.rider_fee) }}</h6>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th>
                      <div class="total">
                        <h6 style="font-weight: 500">Total</h6>
                        <h6>Payment Method</h6>
                        <h6>Order Type</h6>
                      </div>
                    </th>
                    <td></td>
                    <td>
                      <div class="total_price text-right">
                        <h6>₱ {{ makeMoney(getTotalAmount(selectedOrder.orders) + selectedOrder.rider_fee) }}</h6>
                        <h6>VIPCo Wallet</h6>
                        <h6>Delivery</h6>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="rounded bg-success d-flex p-2 justify-content-between">
                <h4 class="text-white m-0">INSTANT REWARD!</h4>
                <h4 class="text-right text-white m-0" *ngIf="selectedOrder">₱ {{ getInstantReward(selectedOrder) }}</h4>
              </div>
              <div class="food_status" *ngIf="selectedOrder?.order_status != 6">
                <div class="line"></div>
                <div style="display: flex; align-items: center; justify-content: center; color: white" class="circle">
                  <i
                    class="fa fa-undo text-danger"
                    *ngIf="
                      selectedOrder?.order_status == 0 &&
                      selectedOrder?.rider_accept == true &&
                      selectedOrder?.store_accept == true
                    "
                    style="font-size: 20px"
                    aria-hidden="true"
                    id="target"
                    (click)="updateFoodOrder(selectedOrder?.order_id)"
                  >
                  </i>
                  <i
                    *ngIf="selectedOrder?.order_status > 0"
                    style="font-size: 20px"
                    class="fa fa-check-circle"
                    aria-hidden="true"
                  ></i>
                  <div
                    *ngIf="
                      selectedOrder?.order_status == 0 &&
                      (selectedOrder?.rider_accept == false || selectedOrder?.store_accept == false)
                    "
                    class="lds-ring"
                  >
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <div class="line"></div>
                <div style="display: flex; align-items: center; justify-content: center; color: white" class="circle">
                  <i
                    *ngIf="selectedOrder?.order_status > 1"
                    style="font-size: 20px"
                    class="fa fa-check-circle"
                    aria-hidden="true"
                  ></i>
                  <div *ngIf="selectedOrder?.order_status == 1" class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <div class="line"></div>
                <div style="display: flex; align-items: center; justify-content: center; color: white" class="circle">
                  <i
                    *ngIf="selectedOrder?.order_status > 2"
                    style="font-size: 20px"
                    class="fa fa-check-circle"
                    aria-hidden="true"
                  ></i>
                  <div *ngIf="selectedOrder?.order_status == 2" class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <div class="line"></div>
                <div style="display: flex; align-items: center; justify-content: center; color: white" class="circle">
                  <i
                    *ngIf="selectedOrder?.order_status > 3"
                    style="font-size: 20px"
                    class="fa fa-check-circle"
                    aria-hidden="true"
                  ></i>
                  <div *ngIf="selectedOrder?.order_status == 3" class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <div class="line"></div>
                <div style="display: flex; align-items: center; justify-content: center; color: white" class="circle">
                  <i
                    *ngIf="selectedOrder?.order_status > 4"
                    style="font-size: 20px"
                    class="fa fa-check-circle"
                    aria-hidden="true"
                  ></i>
                  <div *ngIf="selectedOrder?.order_status == 4" class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
                <!-- <div class="line"></div>
            <div style="display: flex; align-items: center; justify-content: center; color: white;" class="circle">
              <i *ngIf="selectedOrder?.order_status >= 5" style="font-size: 20px;" class="fa fa-check-circle" aria-hidden="true"></i>
              <div *ngIf="selectedOrder?.order_status == 4" class="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div> -->
                <div class="line"></div>
              </div>
              <div class="status">
                <h5>Food Delivery Status:</h5>
                <h5>
                  <span [ngClass]="selectedOrder?.order_status == 6 ? 'text-danger' : 'text-primary'">
                    {{ _stepperTitle[selectedOrder?.order_status] }}
                  </span>
                  <span class="text-muted" *ngIf="selectedOrder?.order_status == 5">
                    ({{ selectedOrder?.order_completed_date?.toDate() | date: "MMM dd, yyyy hh:mm:ss aa" }})
                  </span>
                  <span class="text-muted" *ngIf="selectedOrder?.order_status == 6">
                    ({{ selectedOrder?.order_cancelled_date?.toDate() | date: "MMM dd, yyyy hh:mm:ss aa" }})
                  </span>
                </h5>
              </div>
            </div>
          </div>
          <div class="col-7">
            <div class="box">
              <div class="row">
                <div class="col-8 ml-3 mt-3">
                  <h5 class="font-weight-bold text-primary">User</h5>
                  <h3 class="mt-2">{{ selectedUser.name.toUpperCase() }}</h3>
                  <p>{{ selectedUser.mobile_number }}</p>
                </div>
                <div class="col-4">
                  <!-- <img src="" alt=""> -->
                </div>
              </div>
            </div>
            <div class="box mt-5">
              <div class="row p-3">
                <div class="col-9">
                  <h5 class="font-weight-bold text-primary">Merchant</h5>
                  <h3 class="mt-2">
                    {{
                      selectedMerchant?.first_name.toUpperCase() +
                        " " +
                        selectedMerchant?.middle_name.toUpperCase() +
                        " " +
                        selectedMerchant?.last_name.toUpperCase()
                    }}
                  </h3>
                  <p>{{ selectedOrder.store_id }}</p>
                </div>
                <div class="col-3">
                  <h6 *ngIf="selectedOrder.store_accept" class="text-success">Order Accepted</h6>
                  <!-- <img src="" alt=""> -->
                </div>
              </div>
            </div>
            <div class="box mt-5" *ngIf="selectedOrder.rider_id">
              <div class="row">
                <div class="col-8 ml-3 mt-3">
                  <h5 class="font-weight-bold text-primary">Rider</h5>
                  <h3 class="mt-2">
                    {{
                      selectedRider?.first_name.toUpperCase() +
                        " " +
                        selectedRider?.middle_name.toUpperCase() +
                        " " +
                        selectedRider?.last_name.toUpperCase()
                    }}
                  </h3>
                  <p>{{ selectedOrder.rider_id }}</p>
                </div>
                <div class="col-4">
                  <!-- <img src="" alt=""> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="modal-body">
    <button class="btn btn-sm btn-danger float-right" (click)="processVerification(false)">Submit</button>
    <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
      Cancel
    </button> -->
      <!-- </div> -->
    </ng-template>
  </div>
</div>
