import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-bank-from-create",
  templateUrl: "./bank-from-create.component.html",
  styleUrls: ["./bank-from-create.component.less"],
})
export class BankFromCreateComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
