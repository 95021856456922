
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { environment } from 'src/environments/environment';
import { TreeDiagramNodesList } from './nodes-list.class';

export class TreeDiagramNode {
  public parentId: string | null;
  public guid: string;
  public regType: string;
  public width: number;
  public height: number;
  public isDragover: boolean;
  public isDragging: boolean;
  public children: Set<string>;
  public displayName: string;
  private toggleState: boolean;

  public get isMaker() {
    return false;
  }

  constructor(
    props,
    config,
    public getThisNodeList: () => TreeDiagramNodesList, 
    private httpClient: HttpClient,
    private localStorageCacheService: LocalStorageCacheService
  ) {
    if (!props.guid) {
      return;
    }

    for (const prop in props) {
      if (props.hasOwnProperty(prop)) {
        this[prop] = props[prop];
      }
    }

    this.toggleState = false;

    if (config.nodeWidth) {
      this.width = config.nodeWidth;
    }

    if (config.nodeHeight) {
      this.height = config.nodeHeight;
    }

    this.children = new Set(props.children as string[]);
  }

  public get isExpanded() {
    return this.toggleState;
  }

  public destroy() {
    this.getThisNodeList().destroy(this.guid);
  }

  public hasChildren() {
    return !!this.children.size;
  }

  public toggle(state = !this.toggleState) {
    this.toggleState = state;


     //,
        // {
        //   "guid": "28aac445-83b1-464d-9695-a4157dab6eac",
        //   "displayName": "Cytrek",
        //   "parentId": "bc4c7a02-5379-4046-92be-12c67af4295a",
        //   "children": []
        // },
        // {
        //   "guid": "85d412c2-ebc1-4d56-96c9-7da433ac9bb2",
        //   "displayName": "Asimiline",
        //   "parentId": "bc4c7a02-5379-4046-92be-12c67af4295a",
        //   "children": []
        // }

    if (state) {
      this.getThisNodeList().toggleSiblings(this.guid);
    }
  }

  public childrenCount() {
    return this.children.size;
  }

  public isRoot() {
    return this.parentId == null;
  }

  public dragenter(event) {
    event.dataTransfer.dropEffect = 'move';
  }

  public dragleave(event) {
    this.isDragover = false;
  }

  public dragstart(event) {
    event.dataTransfer.effectAllowed = 'move';
    this.isDragging = true;
    this.toggle(false);
    this.getThisNodeList().draggingNodeGuid = this.guid;
  }

  public dragover(event) {
    event.preventDefault();

    if (!this.isDragging) {
      this.isDragover = true;
    }

    event.dataTransfer.dropEffect = 'move';
    return false;
  }

  public dragend() {
    this.isDragover = false;
    this.isDragging = false;
  }

  public drop(event) {
    event.preventDefault();

    const guid = this.getThisNodeList().draggingNodeGuid;

    this.getThisNodeList().transfer(guid, this.guid);
    return false;
  }

  public addChild(state = !this.toggleState) {

    if(this.displayName == "Available"){
      return;
    }


    this.toggleState = state;
    if (state) {
      console.log(this.children.size);
      if(this.children.size == 0){
        this.getDownlines(this.guid).subscribe(
          (data: any) => {
              data.forEach(element => {
                const newNodeGuid = this.getThisNodeList().newNode(this.guid,element);
                this.children.add(newNodeGuid);
                
              });
              this.toggle(true);
              this.getThisNodeList().toggleSiblings(this.guid);
          });
      }else{
        this.toggle(true);
        this.getThisNodeList().toggleSiblings(this.guid);
      }
    }
  }

  private baseAPIUrl = environment.API_URL;
  public getDownlines(id): Observable<any> {
    const endPointUrl = `${this.baseAPIUrl}/api/SalesUsers/Community/${id}`;
    const accessToken = this.localStorageCacheService.getStorage("access_token");

    const httpHeaders: HttpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + accessToken)
      .append("Accept", "application/json");

      return this.httpClient.get(`${endPointUrl}`, { headers: httpHeaders });
  }
}
