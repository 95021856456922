<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex">
  <div>
    <div class="page-hero page-container" id="page-hero" *ngIf="roleLevelId == 2">
      <div class="padding d-flex pr-0">
        <div class="page-title">
          <h2 class="text-md text-highlight">Application Progress</h2>
          <small class="">JuanPay application progress</small>
        </div>
        <div class="flex"></div>
        <div>
          <button (click)="goBack()" class="btn btn-sm btn-secondary">
            <i class="fa fa-arrow-left"></i> &nbsp;
            <span class="d-none d-sm-inline mx-1">Back</span>
          </button>
        </div>
      </div>
    </div>

    <div class="page-content page-container" id="page-content">
      <div class="padding" style="padding-right: 0px">
        <div class="alert alert-danger" role="alert" *ngIf="application?.declined">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-info"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="16" x2="12" y2="12"></line>
            <line x1="12" y1="8" x2="12" y2="8"></line>
          </svg>
          <span class="mx-2">Application Declined</span>
        </div>

        <div class="card bg-white p-3">
          <div class="row d-flex justify-content-center" *ngIf="valueFormGroup">
            <div class="col-sm-2 d-flex justify-content-center align-items-center">
              <img height="100" width="100" [src]="valueFormGroup.value.thumbnail.logo" />
            </div>
            <div class="col-sm-5">
              <h5>Application Details</h5>
              <hr class="mt-0" />
              <h6>
                Application: <span class="font-weight-normal">{{ valueFormGroup.value.thumbnail.title }}</span>
              </h6>
              <h6>
                Network: <span class="font-weight-normal">{{ valueFormGroup.value.network }}</span>
              </h6>
            </div>
            <div class="col-sm-5">
              <h5>Manager Details</h5>
              <hr class="mt-0" />
              <h6 *ngIf="user">
                Name:
                <span class="font-weight-normal">{{ user.managerUser.firstName }} {{ user.managerUser.lastName }}</span>
              </h6>
              <h6 *ngIf="user">
                Username: <span class="font-weight-normal">{{ user.username }}</span>
              </h6>
              <h6 *ngIf="user">
                Email: <span class="font-weight-normal">{{ user.email }}</span>
              </h6>
              <h6 *ngIf="user">
                Mobile No: <span class="font-weight-normal">{{ user.managerUser.contactNumber }}</span>
              </h6>
              <button
                type="button"
                class="btn btn-primary"
                *ngIf="roleLevelId == 1"
                (click)="openSendSms(sendSmsModal, user.managerUser?.contactNumber)"
              >
                <span aria-hidden="true">Write Message</span>
              </button>
            </div>
          </div>
        </div>

        <div class="card bg-white">
          <div class="row d-flex justify-content-center">
            <div class="col-12">
              <ul id="progressbar" class="text-center">
                <div class="d-flex justify-content-center">
                  <li
                    *ngFor="let progress of purchaseProgressFormArray?.controls; let ii = index"
                    [ngClass]="checkProgressClass(progress.controls.status.value)"
                  >
                    {{ progress.controls.title.value }}<br />
                    <img class="icon" [src]="progress.controls.icon.value" (click)="viewRequirements(ii)" />
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>

        <div class="row">
          <!-- <div class="col-sm-8">
            <div class="card p-3 bg-white" style="max-height: 500px; overflow-y: scroll">
              <h6>List of Manager Files</h6>

              <table
                id="datatable"
                class="table table-striped table-hover"
                role="grid"
                aria-describedby="datatable_info"
              >
                <thead>
                  <tr>
                    <th data-sortable="true" data-field="name">File Name</th>
                    <th data-field="finish"><span class="d-none d-sm-block">Date Upload</span></th>
                    <th data-sortable="true" data-field="name">Purpose</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    style="cursor: pointer"
                    class="odd"
                    role="row"
                    *ngFor="let managerFile of managerFiles?.data"
                    (click)="viewContent(contentModal, managerFile)"
                  >
                    <td>
                      <small class="">{{ managerFile?.fileName }}</small>
                    </td>

                    <td class="flex">
                      <div class="item-except text-sm h-1x">
                        {{ managerFile?.createdDate | date: "dd-MMM-yyyy hh:mm:ss a" }}
                      </div>
                    </td>
                    <td class="flex">
                      <div class="item-except text-sm h-1x">{{ managerFile?.purpose }}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> -->

          <div class="col-sm-9 pr-0">
            <div class="card p-3 pb-0 mt-0 bg-white">
              <!-- *ngIf="purchaseProgressFormArray.controls[progressIndex].value" -->
              <h5>Requirements ( {{ purchaseProgressFormArray?.controls[progressIndex].controls.title.value }} )</h5>

              <h6 class="mt-3">
                &nbsp;&nbsp;&nbsp;&nbsp; Guide:
                <span class="font-weight-normal">{{
                  purchaseProgressFormArray?.controls[progressIndex].controls.instruction.value
                }}</span>
              </h6>
              <hr />

              <div
                class="form-check mb-1 pb-0"
                *ngFor="
                  let req of purchaseProgressFormArray?.controls[progressIndex].controls.requirements.controls;
                  let i = index
                "
              >
                <input
                  class="form-check-input mt-1"
                  type="checkbox"
                  value="req.controls.req_status.value"
                  id="flexCheckChecked"
                  [checked]="req.controls.req_status.value"
                  (change)="reqStatusChange(req.controls.req_status)"
                  *ngIf="req.controls.is_upload.value && roleId != 3"
                  [disabled]="purchaseProgressFormArray?.controls[progressIndex].controls.status.value"
                />

                <i
                  class="form-check-input fa fa-check-circle text-success"
                  *ngIf="req.controls.is_upload.value && req.controls.req_status.value && roleId == 3"
                ></i>

                <div
                  class="item-action dropdown"
                  *ngIf="
                    req.controls.is_upload.value &&
                    !req.controls.req_status.value && roleId == 3 && req.controls.remarks.value != ''
                  "
                >
                  <a href="#" data-toggle="dropdown" class="" (click)="getRemarks(i)">
                    <i class="form-check-input fa fa-exclamation-triangle text-danger"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-top bg-black" role="menu">
                    <div class="p-2">
                      <h6 class="text-danger ml-2 mb-0">Remarks</h6>
                      <hr class="mt-1 mb-1" />
                      <p class="text-highlight mb-0 ml-3 mr-2 text-white">{{ req.controls.remarks.value }}</p>
                    </div>
                  </div>
                </div>

                <!-- for label -->
                <label
                  class="form-check-label font-weight-bold"
                  *ngIf="
                    (req.controls.is_input.value &&
                      req.controls.is_image.value != true &&
                      !req.controls.isHidden.value &&
                      !req.controls.is_for_manager.value && roleId == 3) ||
                    (req.controls.is_for_manager.value && roleId != 3) ||
                    !req.controls.is_input.value
                  "
                  for="flexCheckChecked"
                >
                  {{ req.controls.name.value }}
                  <span *ngIf="req.controls.is_input.value">: &nbsp; {{ req.controls.input_value.value }}</span>
                </label>

                <!-- for normal input -->
                <div
                  class="pr-5"
                  *ngIf="
                    (req.controls.is_input.value && req.controls.is_for_manager.value && roleId == 3) ||
                    (!req.controls.is_for_manager.value &&
                      roleId != 3 &&
                      req.controls.isHidden.value != true && req.controls.is_input.value)
                  "
                >
                  <div class="input-group input-group-sm mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="inputGroup-sizing-sm">{{ req.controls.name.value }}</span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      [value]="req.controls.input_value.value"
                      [FormControl]="req.controls.input_value.controls"
                      (input)="onSearchChange($event.target.value, req.controls.input_value)"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                    />
                  </div>
                </div>

                <!-- for hidden input -->
                <div
                  class="pr-5"
                  *ngIf="
                    req.controls.is_input.value &&
                    !req.controls.is_for_manager.value && roleId != 3 && req.controls.isHidden.value &&
                    purchaseProgressFormArray?.controls[progressIndex].controls.status.value != true
                  "
                >
                  <div class="input-group input-group-sm mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text" id="inputGroup-sizing-sm">{{ req.controls.name.value }}</span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      [value]="req.controls.input_value.value"
                      [FormControl]="req.controls.input_value.controls"
                      (input)="onSearchChange($event.target.value, req.controls.input_value)"
                      aria-label="Small"
                      aria-describedby="inputGroup-sizing-sm"
                    />
                  </div>
                </div>

                <!-- for image -->
                <div *ngIf="req.controls.is_image.value == true">
                  <img
                    [src]="req.controls.input_value.value"
                    onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
                    style="width: 100%"
                  />
                </div>

                <div class="pr-5" *ngIf="req.controls.is_api_hook.value">
                  <table
                    id="datatable"
                    class="table table-striped table-hover"
                    role="grid"
                    aria-describedby="datatable_info"
                  >
                    <tbody>
                      <tr
                        *ngFor="let params of parametersFormArray(req.controls.api_hook_meta)"
                        style="cursor: pointer"
                        class="odd"
                        role="row"
                      >
                        <td>
                          <small class="">{{ params.label }}</small>
                        </td>
                        <td>
                          <small class="">{{ params.value }}</small>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <button
                  class="btn btn-xs btn-danger float-right"
                  *ngIf="roleId != 3 && req.controls.is_upload.value"
                  (click)="
                    addRemarks(
                      addRemarkModal,
                      i,
                      purchaseProgressFormArray.controls[progressIndex].controls.requirements
                    )
                  "
                  [disabled]="req.controls.req_status.value"
                >
                  Add Remarks
                </button>

                <button
                  class="btn btn-xs btn-success mr-2 float-right"
                  *ngIf="req.controls.is_upload.value && !application?.declined"
                  (click)="uploadFileModal(content, i)"
                  [disabled]="req.controls.req_status.value && roleLevelId == 2"
                >
                  Upload
                </button>
                <button
                  class="btn btn-xs btn-info mr-2 float-right"
                  *ngIf="req.controls.is_upload.value"
                  (click)="preview(viewImageModal, req.controls.name.value)"
                  [disabled]="req.controls.file_id.value == ''"
                >
                  &nbsp; <i class="fa fa-eye"></i> &nbsp;
                </button>

                <button
                  class="btn btn-xs btn-success mr-2 float-right"
                  *ngIf="roleId != 3 && req.controls.is_upload.value"
                  (click)="downloadImage(req.controls.file_name.value, i)"
                  [disabled]="req.controls.file_name.value == ''"
                >
                  &nbsp; <i class="fa fa-download"></i> &nbsp;
                </button>

                <span *ngIf="req.controls.is_upload.value" id="filename" class="float-right mr-2" text-highlight>
                  {{ req.controls.file_name.value }}
                </span>
              </div>
              <hr *ngIf="roleId != 3" />

              <div
                *ngIf="
                  purchaseProgressFormArray?.controls[progressIndex].controls.requirements.controls &&
                  !application?.declined
                "
              >
                <!-- <button
                  class="btn btn-sm btn-dark float-right"
                  *ngIf="
                    roleId == 8 &&
                    !checkCompleteRequirements(
                      purchaseProgressFormArray?.controls[progressIndex].controls.requirements.controls
                    ) && !purchaseProgressFormArray?.controls[progressIndex].controls.status.value
                  "
                  (click)="proceedToNext(purchaseProgressFormArray?.controls[progressIndex].controls.status)"
                >
                  Save Changes &nbsp; <i class="fa fa-check-circle text-success"></i>
                </button> -->

                <button
                  style="margin-right: 10px"
                  class="btn btn-sm btn-dark float-left"
                  *ngIf="roleId != 3"
                  (click)="confrimProcess(confirmProcessDeclined)"
                >
                  Declined &nbsp; <i class="fa fa-times-circle text-danger"></i>
                </button>
                <button
                  class="btn btn-sm btn-dark float-right"
                  *ngIf="
                    roleId != 3 &&
                    progressIndex != 0 && progressIndex != 1 &&
                    !checkCompleteRequirements(
                      purchaseProgressFormArray?.controls[progressIndex].controls.requirements.controls
                    ) &&
                    !purchaseProgressFormArray?.controls[progressIndex].controls.status.value &&
                    !purchaseProgressFormArray?.controls[progressIndex].controls.systemTrigger.value &&
                    !purchaseProgressFormArray?.controls[progressIndex].controls.conditional.value
                  "
                  (click)="confrimProcess(confirmProcess)"
                >
                  Proceed to Next&nbsp; <i class="fa fa-check-circle text-success"></i>
                </button>

                <button
                  class="btn btn-sm btn-dark float-right"
                  *ngIf="
                    roleId == 3 && progressIndex == 0 && !purchaseProgressFormArray?.controls[0].controls.status.value
                  "
                  (click)="confrimProcess(submitReqqqq)"
                >
                  Submit Requirements&nbsp; <i class="fa fa-check-circle text-success"></i>
                </button>

                <button
                  class="btn btn-sm btn-dark float-right"
                  *ngIf="
                    roleId != 3 &&
                    !checkCompleteRequirements(
                      purchaseProgressFormArray?.controls[progressIndex].controls.requirements.controls
                    ) &&
                    !purchaseProgressFormArray?.controls[progressIndex].controls.status.value &&
                    !purchaseProgressFormArray?.controls[progressIndex].controls.systemTrigger.value &&
                    purchaseProgressFormArray?.controls[progressIndex].controls.conditional.value
                  "
                  (click)="confrimProcess(confirmProcessApproved)"
                >
                  Approved &nbsp; <i class="fa fa-check-circle text-success"></i>
                </button>

                <div
                  class="float-right"
                  *ngIf="
                    roleId != 3 &&
                    !checkCompleteRequirements(
                      purchaseProgressFormArray?.controls[progressIndex].controls.requirements.controls
                    ) &&
                    !purchaseProgressFormArray?.controls[progressIndex].controls.status.value &&
                    purchaseProgressFormArray?.controls[progressIndex].controls.systemTrigger.value &&
                    !purchaseProgressFormArray?.controls[progressIndex].controls.conditional.value
                  "
                >
                  Awaiting Lazada's approval.
                </div>

                <button
                  class="btn btn-sm btn-dark float-right"
                  *ngIf="
                    roleId != 3 &&
                    checkCompleteRequirements(
                      purchaseProgressFormArray?.controls[progressIndex].controls.requirements.controls
                    ) &&
                    !purchaseProgressFormArray?.controls[progressIndex].controls.status.value
                  "
                  (click)="saveProgress(false)"
                >
                  Save Changes &nbsp; <i class="fa fa-save text-info"></i>
                </button>

                <button
                  class="btn btn-sm btn-dark float-right"
                  *ngIf="
                    roleId == 3 &&
                    checkIfHasInput(purchaseProgressFormArray?.controls[progressIndex].controls.requirements.controls)
                  "
                  (click)="saveProgress(false)"
                >
                  Save Changes &nbsp; <i class="fa fa-save text-info"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="row">
              <div class="col-sm-12" *ngIf="purchaseProgressFormArray?.controls[progressIndex].controls.download.value">
                <div class="card pl-3 pr-3 pt-3 pb-0 mt-0 mb-2 bg-white">
                  <!-- *ngIf="purchaseProgressFormArray.controls[progressIndex].value" -->
                  <h5>Download Area</h5>
                  <hr class="mt-0" />

                  <h6>Click the file name to download: <span class="font-weight-normal"></span></h6>
                  <ul>
                    <li class="pl-0">
                      <button
                        class="btn btn-xs btn-warning"
                        *ngIf="roleLevelId == 1"
                        (click)="openUpdateLinkModal(downloadAreaUploadModal)"
                      >
                        <i class="fa fa-edit"></i>
                      </button>
                      &nbsp;
                      <a
                        href="{{
                          purchaseProgressFormArray?.controls[progressIndex].controls.download?.controls.link.value
                        }}"
                        target="_blank"
                        >{{
                          purchaseProgressFormArray?.controls[progressIndex].controls.download?.controls.file_name
                            ?.value
                        }}</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-12">
                <div
                  class="card p-3 mt-0 bg-white"
                  *ngIf="purchaseProgressFormArray?.controls[0].controls.requirements.controls"
                >
                  <div class="modal-header p-0 pb-2">
                    <h6 class="modal-title">File Preview</h6>
                    <button
                      *ngIf="fileData"
                      type="button"
                      class="btn btn-xs btn-info float-right"
                      (click)="viewLargeImage(viewImageModal)"
                      aria-label="Close"
                    >
                      <i class="fa fa-expand"></i>
                    </button>
                  </div>

                  <div *ngIf="!fileData" class="dropzone white b-a b-3x b-dashed b-primary p-a rounded p-5 text-center">
                    <br />
                    <h6 class="my-4">File Preview Here.</h6>
                    <br />
                  </div>
                  <img
                    *ngIf="fileData"
                    [src]="buildUserPhoto(fileData) | authImage | async"
                    alt="VIP"
                    onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
                    style="width: 100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Upload File</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div>
      <div class="page-content page-container" id="page-content">
        <div class="padding pt-0" *ngIf="fileUploadFormGroup">
          <div class="input-group mb-3 w-100">
            <div class="input-group-prepend">
              <input type="file" (change)="onPhotoChange($event)" class="custom-file-input" id="inputFile" />
              <label class="custom-file-label" for="inputFile">{{ fileName }}</label>
            </div>
            <span *ngIf="fileUploadFormGroup?.controls.photoFile.errors | estatus" class="text-error"
              ><small>{{ fileUploadFormGroup?.controls.photoFile.errors | econtent }}</small>
            </span>
          </div>

          <div
            *ngIf="!fileUploadFormGroup?.controls.photoFile.value"
            class="dropzone white b-a b-3x b-dashed b-primary p-a rounded p-5 text-center"
          >
            <br />
            <br />
            <br />

            <h4 class="my-4">Upload File Here.</h4>

            <br />
            <br />
            <br />
          </div>
          <img
            *ngIf="fileUploadFormGroup?.controls.photoFile.value"
            [src]="fileUploadFormGroup?.controls.photoFile.value | authImage | async"
            alt="VIP"
            (click)="viewLargeImage(viewImageModal)"
            onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
            style="width: 100%"
          />
          <hr />
          <button
            class="btn btn-sm btn-primary mt-2 float-right"
            *ngIf="fileUploadFormGroup?.controls.photoFile.value"
            (click)="
              submitFile(
                purchaseProgressFormArray.controls[progressIndex].controls.requirements.controls[reqIndex].controls
              )
            "
          >
            <i class="fa fa-upload"></i> &nbsp; Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #viewImageModal let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Image Viewer</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div>
      <ngx-extended-pdf-viewer
        [base64Src]="imagePreview"
        *ngIf="fileType == 'PDF'"
        backgroundColor="#ffffff"
        height="100%"
        [useBrowserLocale]="true"
        [handTool]="false"
        [showHandToolButton]="true"
      >
      </ngx-extended-pdf-viewer>
      <img
        *ngIf="fileType == 'IMAGE'"
        [src]="imagePreview"
        onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
        style="width: 100%"
      />
    </div>
    <!-- <img
      *ngIf="fileData"
      [src]="buildUserPhoto(fileData) | authImage | async"
      alt="VIP"
      onerror="this.src='https://via.placeholder.com/64x64?text=VIP';"
      style="width: 100%"
    /> -->
  </div>
</ng-template>

<ng-template #addRemarkModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Add Remarks</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="purchaseProgressFormArray?.controls[progressIndex].controls.requirements.controls">
      <textarea type="text" class="form-control" id="remm" [(ngModel)]="remarksStr"></textarea>
      <!-- <app-textarea
        [parent-form-group]="requestFundFormGroup"
        tab-index-start="0"
        control-name="managerRemarks"
        [error]="requestFundFormGroup?.controls.managerRemarks.errors | estatus"
        [error-message]="requestFundFormGroup?.controls.managerRemarks.errors | econtent"
        read-only="false"
        label="Note"
        placeholder="Note ... "
      >
      </app-textarea> -->
    </div>
    <button
      class="btn btn-sm btn-primary mt-2 float-right"
      (click)="
        submitRemarks(
          purchaseProgressFormArray?.controls[progressIndex].controls.requirements.controls[reqIndex].controls.remarks
        )
      "
    >
      <i class="fa fa-upload"></i> &nbsp; Submit
    </button>
  </div>
</ng-template>

<ng-template #confirmProcess let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Are you sure you want to process application?</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <button
      class="btn btn-sm btn-primary float-right"
      (click)="proceedToNext(purchaseProgressFormArray?.controls[progressIndex].controls.status)"
    >
      Proceed
    </button>
    <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #submitReqqqq let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Are you sure you want to process application?</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <button
      class="btn btn-sm btn-primary float-right"
      (click)="submitReq(purchaseProgressFormArray?.controls[0].controls.status)"
    >
      Proceed
    </button>
    <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #confirmProcessApproved let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Are you sure you want to approve the application?</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <button
      class="btn btn-sm btn-success float-right"
      (click)="proceedToNextConditional(purchaseProgressFormArray?.controls[progressIndex].controls.status, true)"
    >
      Approved
    </button>
    <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #confirmProcessDeclined let-modal>
  <div class="modal-header">
    <h6 class="modal-title">Are you sure you want to decline the application?</h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <textarea type="text" class="form-control mb-2" id="remm" [(ngModel)]="remarksStr"></textarea>

    <button
      class="btn btn-sm btn-danger float-right"
      (click)="proceedToNextConditional(purchaseProgressFormArray?.controls[progressIndex].controls.status, false)"
    >
      Declined
    </button>
    <button class="btn btn-sm btn-dark float-right mr-2" aria-label="Close" (click)="modal.dismiss('Cross click')">
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #sendSmsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">SMS Sender</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <app-sms-sender [mobileNo]="mobileNo" (closeModal)="closeModal()"> </app-sms-sender>
  </div>
</ng-template>

<ng-template #downloadAreaUploadModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">
      Update {{ purchaseProgressFormArray?.controls[progressIndex].controls.download.controls.file_name.value }} ?
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">Download Link </span>
      </div>
      <input
        type="fileLink"
        class="form-control mr-2"
        [(ngModel)]="linkStr"
        placeholder="Paste the link here..."
        aria-label="fileLInk"
        aria-describedby="basic-addon1"
      />
      <button
        class="btn btn-sm btn-dark float-right mr-2"
        aria-label="Close"
        (click)="
          submitDownloadLinkUpdate(purchaseProgressFormArray?.controls[progressIndex].controls.download.controls.link)
        "
      >
        Save
      </button>
    </div>
  </div>
</ng-template>
