import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageCacheService } from 'src/data/local-storage/local-storage-cache.service';
import { StoreItemService } from 'src/data/services/web/store-item.service';
import { WebSettingsService } from 'src/data/services/web/web-settings.service';
import { CryptoService } from 'src/data/crypto/crypto.service';
import { Router } from '@angular/router';
import { Data } from 'src/data/data';
import { result } from 'lodash';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-check-out',
  templateUrl: './check-out.component.html',
  styleUrls: ['./check-out.component.less']
})
export class CheckOutComponent implements OnInit {

  loader: any = false;
  totalAmount: any = 0;
  subTotal: any = 0;
  totalQuantity: any = 0;
  message: any = "";

  constructor(
    private modalServe: NgbModal,
    private data: Data,
    private webService: WebSettingsService,
    private cryptoService: CryptoService,
    public datepipe: DatePipe,
    private router: Router,
    public storeItemService: StoreItemService,
    private currencyPipe: CurrencyPipe,
    private formBuilder: FormBuilder,
    private localStorageCacheService: LocalStorageCacheService
  ) { }

  ngOnInit(): void {
    this.initFormGroups();
    this.getCategories();
    this.getCheckout();
    this.getAddress();
  }

  itemFormGroups: FormGroup[] = [];
  cartItems: any;
  getCheckout(){
    this.storeItemService.getCheckout().subscribe((result) => {
      this.cartItems = result;

      for (let i = 0; i < this.cartItems.length; i++) {
        this.quantities.push(this.cartItems[i].quantity);
      }

      this.getTotal();
    });
  }

  checkoutFormGroup: FormGroup;
  initFormGroups(){
    this.checkoutFormGroup = new FormGroup({
      payment: new FormControl(null, [Validators.required]),
      address: new FormControl(null, [Validators.required]),
      meesage: new FormControl(null)
    });
  }

  addresses: any;
  selectedAddress: any;
  getAddress(){
    this.storeItemService.getAddresses().subscribe((result) => {
      this.addresses = result;
      this.selectedAddress = result[0];
    });
  }

  getTotal(){
    this.subTotal = this.cartItems.reduce((acc, curr) => acc + curr.price * curr.quantity, 0);
    this.totalAmount = this.cartItems.reduce((acc, curr) => acc + curr.price * curr.quantity, 0);
    this.totalQuantity = this.sum(this.cartItems.map(x => x.quantity));
  }

  sum(array: number[]): number {
    return array.reduce((a, b) => a + b, 0);
  }

  quantities = [];
  quantityChanged(item){

  }
  
  makeMoney(money: any) {
    return this.currencyPipe.transform(money, "PHP").replace("PHP", "");
  }

  categories: any;
  getCategories(){
    this.storeItemService.getCategories().subscribe((result) => {
      //this.categories = result;
      this.categories = result.map((status) => {
        return {
          id: status.id,
          text: status.category,
        };
      });
    });
  }

  closeModal(){
    this.modalServe.dismissAll();
  }

  addressModalRef: NgbModalRef;
  changeAddressModal(content){
    this.addressModalRef = this.modalServe.open(content, 
      { 
        backdrop: 'static',
        centered: true,
        size: 'md',
        keyboard: false 
      });
  }

  changeAddress(address: any) {
    this.selectedAddress = address; // assign the selected address to the variable
    this.addressModalRef.close();
  }

  methodModalRef: NgbModalRef;
  changeMethod(content){
    this.methodModalRef = this.modalServe.open(content, 
      { 
        backdrop: 'static',
        centered: true,
        size: 'md',
        keyboard: false 
      });
  }

  paymentMethod: any = [
    {
      id: 1,
      method: "V-Wallet",
      status: 2
    },
    {
      id: 1,
      method: "Vips",
      status: 1
    },
    {
      id: 1,
      method: "Over The Counter (JuanPay Outlet)",
      status: 1
    }
  ];

  selectedPayment: any = this.paymentMethod[0];
  changePayment(method: any) {
    this.selectedPayment = method; // assign the selected address to the variable
    this.methodModalRef.close();
  }

  placeOrderModalRef: NgbModalRef;
  placeOrderModal(content){
    this.placeOrderModalRef = this.modalServe.open(content, { centered: true, size: "sm" });
  }

  placeOrder(){
    if(this.cartItems.length == 0){
      PNotify.error({
        title: "Invalid",
        text: "There's no item in your cart!",
      });

      return;
    }

    let json = {
      poId: this.cartItems[0].purchaseOrderId,
      address: this.selectedAddress.address,
      contact: this.selectedAddress.contact,
      name: this.selectedAddress.name,
      paymentMethod: this.selectedPayment.id,
      note: this.message
    };
    
    this.storeItemService.placeOrder(json).subscribe((result) => {
      if(result.status){
        PNotify.success({
          title: "Place Order",
          text: "Your order has been successfully placed",
        });
        this.placeOrderModalRef.close();

        this.router.navigate(['manager','purchase-order'])
      } else {
        PNotify.error({
          title: "Place Order",
          text: "Something went wrong!",
        });
      }
    });
  }

  backToCart(){
    this.router.navigate(['manager','my-cart']);
  }
}
