import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { CryptoService } from "src/data/crypto/crypto.service";
import { Data } from "src/data/data";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { AuthService } from "src/data/services/authentication/auth.service";
import { WebSettingsService } from "src/data/services/web/web-settings.service";
import { RolesService } from "src/data/services/web/roles.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-web-settings-list",
  templateUrl: "./web-settings-list.component.html",
  styleUrls: ["./web-settings-list.component.less"],
})
export class WebSettingsListComponent implements OnInit {
  public webSettingsFilterFormGroup: FormGroup;
  public authService: AuthService;
  public localStorageCacheService: LocalStorageCacheService;
  public webSettingsService: WebSettingsService;
  public emptyString = "";
  public statuses: any;
  public status: any;
  public modalService: BsModalService;

  public accessToken: string;
  public newWebSettings: boolean;
  public webSettings: any;
  public pageCount: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private cryptoService: CryptoService,
    private data: Data,
    authService: AuthService,
    localStorageCacheService: LocalStorageCacheService,
    rolesService: RolesService,
    webSettingsService: WebSettingsService,
    modalService: BsModalService
  ) {
    this.authService = authService;
    this.localStorageCacheService = localStorageCacheService;
    this.webSettingsService = webSettingsService;
    this.modalService = modalService;
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
  }

  ngOnInit(): void {
    this.getWebSettings();
  }

  private _sortBy: any;
  private _sort: any;

  getWebSettings(url = null, _sortBy = null, _sort = null, fromSearch = false): void {
    if (fromSearch) {
      this.name = this.emptyString;
      this.value = this.emptyString;
      this.values = this.emptyString;
      this.description = this.emptyString;
    }

    const queryString_orderby: string = _sortBy ? `sortBy=${_sortBy}&` : "";
    const queryString_order: string = _sort ? `sort=${_sort}&` : "";
    const queryString_searchString: string = this.searchString ? `searchString=${this.searchString}&` : "";
    const queryString = queryString_orderby + queryString_order + queryString_searchString;

    let endPointUrl: string;
    if (url) {
      endPointUrl = `${url}&${queryString}`;
    } else {
      if (queryString) {
        endPointUrl = `${environment.API_URL}/api/websettings?${queryString}`;
      } else {
        endPointUrl = `${environment.API_URL}/api/websettings${queryString}`;
      }
    }

    this.webSettingsService.getWebSettings(endPointUrl).subscribe((result) => {
    
      if (result.data.length == 0 && result.pageNumber != 1) {
        this.getWebSettings(result.previousPage, _sortBy, _sort);
      } else {
        this.webSettings = result;
      }
    });
  }

  name = "";
  value = "";
  values = "";
  description = "";
  searchString = "";

  setPageSorting(field: any, page: any = null) {
    let sortBy: any;
    let sort: any;

    if (this._sort == field) {
      sort = field;
      sortBy = this._sortBy == "desc" ? "asc" : "desc";
    } else {
      sort = field;
      sortBy = "asc";
    }
    this._sort = sort;
    this._sortBy = sortBy;

    this.name = field == "name" ? "th-inner sortable both " + sortBy : "";
    this.value = field == "value" ? "th-inner sortable both " + sortBy : "";
    this.values = field == "values" ? "th-inner sortable both " + sortBy : "";
    this.description = field == "description" ? "th-inner sortable both " + sortBy : "";
    
    this.getWebSettings(
      `${environment.API_URL}/api/websettings?pageNumber=${page ? page : this.webSettings.pageNumber}`,
      this._sortBy,
      this._sort
    );
  }

  getPageNumberClass(pageNumber: any) {
    if (pageNumber == this.webSettings?.pageNumber) {
      return "paginate_button page-item active";
    } else {
      return "paginate_button page-item";
    }
  }

  totalPages(totalPages: any) {
    return Array(totalPages)
      .fill(1)
      .map((x, i) => i);
  }

  editWebSettingsProfile(id: any): void {
   
    const encId = this.cryptoService.set(this.data.authentication.privateKey, id);
    this.router.navigate(["acm", "web-settings-edit", { id: encId }]);
  }

  createWebSettings(): void {
    this.router.navigate(["acm", "web-settings-create"]);
  }
  
  buildPageUrl(pageNumber: any, pageSize: any): any {
    return `${environment.API_URL}/api/websettings?pageNumber=${pageNumber}&pageSize=${pageSize}`;
  }
}


