import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-store-transaction-view",
  templateUrl: "./store-transaction-view.component.html",
  styleUrls: ["./store-transaction-view.component.less"],
})
export class StoreTransactionViewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
