import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { LocalStorageCacheService } from "src/data/local-storage/local-storage-cache.service";
import { Schedule } from "src/app/models/schedule";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CashierUserScheduleService {
  private baseApiUrl = environment.API_URL;
  private accessToken: string;
  private httpHeaders: HttpHeaders;

  constructor(private httpClient: HttpClient, private localStorageCacheService: LocalStorageCacheService) {
    this.accessToken = this.localStorageCacheService.getStorage("access_token");
    this.httpHeaders = new HttpHeaders()
      .append("Authorization", "Bearer " + this.accessToken)
      .append("Accept", "application/json");
  }

  /**
   * Get the schedule of a Cashier User from the API.
   *
   * @param id The unique Id of the user to get.
   */
  public get(id: string): Observable<Schedule> {
    const url = `${this.baseApiUrl}/api/cashiers/${id}`;
    return this.httpClient.get<Schedule>(`${url}`, { headers: this.httpHeaders });
  }

  /**
   * Update the schedule of a Cashier User on the API.
   *
   * @param id The Id of the CashierUser object to update.
   * @param schedule The CashierUser object to update.
   */
  public update(id: string, schedule: string): Observable<Schedule> {
    const url = `${this.baseApiUrl}/api/cashiers/${id}/schedule`;
    return this.httpClient.patch<Schedule>(url, { schedule: schedule }, { headers: this.httpHeaders });
  }
}
