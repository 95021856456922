<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">SPX Rider Pay Settlements</h2>
          <small class="text-highlight font-weight-normal font-italic">JuanPay list of SPX Rider Pay settlements</small>
        </div>
        <div class="flex"></div>
        <div></div>
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="" style="padding-right: 0px">
        <div class="card">
          <div class="b-b p-2">
            <div class="nav-active-border b-primary bottom">
              <ul class="nav" id="myTab" role="tablist">
                <li class="nav-item pt-3 pr-3 pl-3">
                  <a
                    class="nav-link active"
                    id="home-tab"
                    data-toggle="tab"
                    href="#shopeeTransaction"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    Shopee Transaction
                  </a>
                </li>
                <li class="nav-item pt-3 pr-3 pl-3">
                  <a
                    class="nav-link"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#gvcToShopee"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    JuanPay to Shopee Settlement
                  </a>
                </li>
                <li class="nav-item pt-3 pr-3 pl-3">
                  <a
                    class="nav-link"
                    id="contact-tab"
                    data-toggle="tab"
                    href="#shopeeToGvc"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    Shopee to JuanPay Settlement
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="tab-content p-2">
            <div class="tab-pane fade active show" id="shopeeTransaction" role="tabpanel" aria-labelledby="home-tab">
              <app-spx-remittance-report></app-spx-remittance-report>
            </div>

            <div class="tab-pane fade" id="gvcToShopee" role="tabpanel" aria-labelledby="profile-tab">
              <div class="card mb-2 mt-0" style="box-shadow: 0 2px 4px 0 #ee4d2d, 0 2px 5px 0 #ee4d2d">
                <div class="row" [formGroup]="searchGVCByFormGroup">
                  <table id="datatable" class="table" role="grid" aria-describedby="datatable_info">
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                          <button
                            class="btn btn-sm mt-2 btn-primary button--search font-weight-bold"
                            style="float: right"
                            (click)="searchGVCData()"
                          >
                            Search &nbsp; <i class="fa fa-filter text-highlight"></i>
                          </button>

                          <button
                            class="btn btn-sm mr-2 mt-2 btn-success button--create font-weight-bold"
                            style="float: right"
                            *ngIf="roleId == 1 || roleId == 2 || roleId == 7 || roleId != 19"
                            (click)="createModal(uploadTransactions)"
                          >
                            Create Settlement &nbsp; <i class="fa fa-plus text-highlight"></i>
                          </button>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style="cursor: pointer" class="odd" role="row">
                        <td class="td-title">
                          <label class="label-filter" style="color: #ee4d2d; font-weight: bolder"
                            >Set No. of Rows</label
                          >
                        </td>
                        <td class="td-value">
                          <ng-select
                            [items]="showRowsOption"
                            placeholder="No of Rows"
                            bindLabel="text"
                            bindValue="id"
                            formControlName="showRows"
                          >
                          </ng-select>
                        </td>

                        <td class="td-title">
                          <label class="label-filter" style="color: #ee4d2d; font-weight: bold">Date Range</label>
                        </td>

                        <td class="td-value">
                          <input
                            type="text"
                            placeholder="Date Range"
                            class="form-control"
                            bsDaterangepicker
                            formControlName="dateRange"
                            style="background-color: white; color: black; height: 37px; border-color: #ee4d2d"
                            [bsConfig]="{
                              rangeInputFormat: 'YYYY-MM-DD',
                              dateInputFormat: 'YYYY-MM-DD',
                              showWeekNumbers: false
                            }"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="card">
                <table id="table" class="table table-hover table-striped">
                  <thead style="background-color: #ee4d2d">
                    <tr>
                      <th>&nbsp;</th>
                      <th class="text-white" style="width: 150px; text-align: center">
                        <span>Date of Transaction</span>
                      </th>
                      <th class="text-white" style="text-align: center"><span>SPX Remittance Receipt</span></th>
                      <th class="text-white" colspan="2" style="width: 250px; text-align: center">
                        <span>Transaction Details </span>
                      </th>
                      <th class="text-white" style="width: 170px; text-align: center"><span>Status</span></th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      style="padding-bottom: 20px"
                      *ngFor="
                        let transaction of gvctransactions?.data
                          | paginate
                            : {
                                id: 'pagination1',
                                itemsPerPage: gvctransactions.pageSize,
                                currentPage: pgvc,
                                totalItems: gvctransactions.totalRecords
                              }
                      "
                      (click)="selectGVCSettlement(gvcToSpxEditModal, transaction)"
                    >
                      <td class="mt-2 pb-2"></td>

                      <td class="mt-2 pb-2" style="vertical-align: middle; text-align: center">
                        <span class="mt-4 mb-4" style="color: cadetblue; font-weight: bold">
                          {{ transaction.settlementDate | date: "MMM dd, yyyy" }}</span
                        >
                      </td>

                      <td
                        style="vertical-align: middle; text-align: center"
                        class="text-primary font-weight-bold t-2 pb-2"
                      >
                        <div class="mt-4 mb-4">
                          {{
                            transaction.goVIPReceiptURL == null
                              ? null
                              : "GVC - " + (transaction.createdDate | date: "MMMddyyyy")
                          }}
                          &nbsp;
                        </div>
                      </td>

                      <td class="mt-2 pb-2" style="vertical-align: middle; text-align: center">
                        Total Item :
                        <span style="color: cadetblue; font-weight: bold">{{ transaction.totalItem }}</span>
                      </td>

                      <td class="mt-2 pb-2" style="vertical-align: middle; text-align: center">
                        Total Amount :
                        <span style="color: cadetblue; font-weight: bold">{{
                          transaction.totalAmount | number: "1.2-3"
                        }}</span>
                      </td>

                      <td style="vertical-align: middle; text-align: center" class="mt-2 pb-2">
                        <div *ngIf="transaction.statusId == 3">
                          <span class="badge badge-warning" style="width: 63px">Pending</span>
                        </div>
                        <div *ngIf="transaction.statusId == 2">
                          <span class="badge badge-success" style="width: 63px">Completed</span>
                        </div>
                      </td>

                      <td class="mt-2 pb-2"></td>
                    </tr>
                  </tbody>
                </table>

                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                      <div class="col-sm-12 col-md-12">
                        <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                          Showing {{ (gvctransactions?.pageNumber - 1) * gvctransactions?.pageSize + 1 }} to
                          {{
                            (gvctransactions?.pageNumber - 1) * gvctransactions?.pageSize +
                              gvctransactions?.data?.length
                          }}
                          of {{ gvctransactions?.totalRecords }} entries
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-7" style="float: right">
                    <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                      <ul class="pagination" style="float: right; margin-right: 36px">
                        <pagination-controls
                          id="pagination1"
                          (pageChange)="getGVCTransaction(buildGVCPageUrl($event, 10))"
                        ></pagination-controls>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="tab-pane fade" id="shopeeToGvc" role="tabpanel" aria-labelledby="contact-tab">
              <div class="card mb-2 mt-0" style="box-shadow: 0 2px 4px 0 #ee4d2d, 0 2px 5px 0 #ee4d2d">
                <div class="row" [formGroup]="searchByFormGroup">
                  <table id="datatable" class="table" role="grid" aria-describedby="datatable_info">
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>
                          <button
                            class="btn btn-sm mt-2 btn-primary button--search font-weight-bold"
                            style="float: right"
                            (click)="searchData()"
                          >
                            Search &nbsp; <i class="fa fa-filter text-highlight"></i>
                          </button>

                          <button
                            class="btn btn-sm mr-2 mt-2 btn-danger button--create font-weight-bold"
                            *ngIf="roleId == 1 || roleId == 2 || roleId == 7 || roleId != 19"
                            style="float: right"
                            (click)="createModal(uploadCreateBilling)"
                          >
                            Create Billing &nbsp; <i class="fa fa-plus text-highlight"></i>
                          </button>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style="cursor: pointer" class="odd" role="row">
                        <td class="td-title">
                          <label class="label-filter" style="color: #ee4d2d; font-weight: bolder"
                            >Set No. of Rows</label
                          >
                        </td>
                        <td class="td-value">
                          <ng-select
                            [items]="showRowsOption"
                            placeholder="No of Rows"
                            bindLabel="text"
                            bindValue="id"
                            formControlName="showRows"
                          >
                          </ng-select>
                        </td>

                        <td class="td-title">
                          <label class="label-filter" style="color: #ee4d2d; font-weight: bold">Date Range</label>
                        </td>

                        <td class="td-value">
                          <input
                            type="text"
                            placeholder="Date Range"
                            class="form-control"
                            bsDaterangepicker
                            formControlName="dateRange"
                            style="background-color: white; color: black; height: 37px; border-color: #ee4d2d"
                            [bsConfig]="{
                              rangeInputFormat: 'YYYY-MM-DD',
                              dateInputFormat: 'YYYY-MM-DD',
                              showWeekNumbers: false
                            }"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="card">
                <table id="table" class="table table-hover table-striped">
                  <thead style="background-color: #ee4d2d">
                    <tr>
                      <th>&nbsp;</th>
                      <th class="text-white" style="width: 200px; text-align: center">
                        <span>Dates of Transaction</span>
                      </th>
                      <th class="text-white" style="text-align: center"><span>Billing Invoice</span></th>
                      <th class="text-white" style="text-align: center"><span>SPX Remittance Receipt</span></th>
                      <th class="text-white" colspan="2" style="width: 250px; text-align: center">
                        <span>Transaction Details </span>
                      </th>
                      <th class="text-white" style="width: 170px; text-align: center"><span>Status</span></th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      style="padding-bottom: 20px"
                      *ngFor="
                        let transaction of transactions?.data
                          | paginate
                            : {
                                id: 'pagination2',
                                itemsPerPage: transactions.pageSize,
                                currentPage: p,
                                totalItems: transactions.totalRecords
                              }
                      "
                      (click)="selectSettlement(spxToGvcEditModal, transaction)"
                    >
                      <td class="mt-2 pb-2"></td>

                      <td class="mt-2 pb-2" style="vertical-align: middle; text-align: center">
                        <span class="mt-4 mb-4" style="color: cadetblue; font-weight: bold">
                          {{ transaction.dateStart | date: "MMM dd, yyyy" }}</span
                        >
                        to
                        <span style="color: cadetblue; font-weight: bold">
                          {{ getDate(transaction.dateEnd) | date: "MMM dd, yyyy" }}
                        </span>
                      </td>

                      <td
                        style="vertical-align: middle; text-align: center"
                        class="text-primary font-weight-bold t-2 pb-2"
                      >
                        <div class="mt-4 mb-4">
                          {{
                            transaction.spxInvoiceURL == null
                              ? null
                              : "INV - " + (transaction.createdDate | date: "MMMddyyyy")
                          }}
                          &nbsp;
                        </div>
                      </td>

                      <td
                        style="vertical-align: middle; text-align: center"
                        class="text-primary font-weight-bold t-2 pb-2"
                      >
                        <div class="mt-4 mb-4">
                          {{
                            transaction.spxReceiptURL == null
                              ? null
                              : "SPX - " + (transaction.createdDate | date: "MMMddyyyy")
                          }}
                          &nbsp;
                        </div>
                      </td>

                      <td class="mt-2 pb-2" style="vertical-align: middle; text-align: center">
                        Total Item :
                        <span style="color: cadetblue; font-weight: bold">{{ transaction.totalItem }}</span>
                      </td>

                      <td class="mt-2 pb-2" style="vertical-align: middle; text-align: center">
                        Total Amount :
                        <span style="color: cadetblue; font-weight: bold">{{
                          transaction.totalAmount | number: "1.2-3"
                        }}</span>
                      </td>

                      <td style="vertical-align: middle; text-align: center" class="mt-2 pb-2">
                        <div *ngIf="transaction.statusId == 4">
                          <span class="badge badge-warning">Pending Invoice</span>
                        </div>
                        <div *ngIf="transaction.statusId == 3">
                          <span class="badge badge-warning">Pending Settlement</span>
                        </div>
                        <div *ngIf="transaction.statusId == 2">
                          <span class="badge badge-success" style="width: 63px">Completed</span>
                        </div>
                      </td>

                      <td class="mt-2 pb-2"></td>
                    </tr>
                  </tbody>
                </table>

                <div class="row">
                  <div class="col-sm-12 col-md-5">
                    <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                      <div class="col-sm-12 col-md-12">
                        <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                          Showing {{ (transactions?.pageNumber - 1) * transactions?.pageSize + 1 }} to
                          {{ (transactions?.pageNumber - 1) * transactions?.pageSize + transactions?.data?.length }} of
                          {{ transactions?.totalRecords }} entries
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-7" style="float: right">
                    <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                      <ul class="pagination" style="float: right; margin-right: 36px">
                        <pagination-controls
                          id="pagination2"
                          (pageChange)="getTransaction(buildPageUrl($event, 10))"
                        ></pagination-controls>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #uploadTransactions let-modal>
      <div class="modal-header">
        <h4 class="modal-title">SPX Create Settlement</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="card" style="box-shadow: 0 2px 4px 0 #ee4d2d, 0 2px 5px 0 #ee4d2d">
          <div class="row p-5" *ngIf="fileUploadFormGroup" [formGroup]="fileUploadFormGroup">
            <div class="col-lg-6"></div>
            <div class="col-lg-6">
              <div class="row">
                <div class="col-3 d-flex align-items-center justify-content-center">
                  <label>Select Date</label>
                </div>
                <div class="col-9">
                  <input
                    bsDatepicker
                    autocomplete="off"
                    class="form-control"
                    bsConfig="dpConfig"
                    formControlName="deyt"
                    placeholder="Select Date"
                    upper-case="true"
                    tabindex="0"
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="p-2">
          <table style="width: 100%">
            <tr>
              <th style="text-align: center; color: white; background-color: rgb(79, 79, 79)">Rider Fullname</th>
              <th style="text-align: center; color: white; background-color: rgb(79, 79, 79)">Mobile No</th>
              <th style="text-align: center; color: white; background-color: rgb(79, 79, 79)">Rider Reference ID</th>
              <th style="text-align: center; color: white; background-color: rgb(79, 79, 79)">RequestID</th>
              <th style="text-align: right; color: white; background-color: rgb(79, 79, 79)">Amount</th>
            </tr>
            <tr *ngFor="let item of selectedGVCDateRangeTransaction">
              <td style="text-align: center">{{ item?.fmsDriverName }}</td>
              <td style="text-align: center">{{ item.mobileNo }}</td>
              <td style="text-align: center">{{ item.lifetimeID }}</td>
              <td style="text-align: center">{{ item.requestId }}</td>
              <td style="text-align: right">{{ item.amount }}</td>
            </tr>

            <tr>
              <td style="text-align: center"></td>
              <td style="text-align: center"></td>
              <td style="text-align: center"></td>
              <td style="text-align: center"></td>
              <td style="text-align: right"></td>
            </tr>
            <tr>
              <td style="text-align: right"></td>
              <td style="text-align: right"></td>
              <td style="text-align: right"></td>
              <td style="text-align: right"><strong>Settlement Count :</strong></td>
              <td style="text-align: right">
                <strong>{{ selectedGVCDateRangeTransaction.length }}</strong>
              </td>
            </tr>
            <tr>
              <td style="text-align: right"></td>
              <td style="text-align: right"></td>
              <td style="text-align: right"></td>
              <td style="text-align: right"><strong>Settlement Total Amount :</strong></td>
              <td style="text-align: right">
                <strong>{{ totalGVCAmount }}</strong>
              </td>
            </tr>
          </table>
        </div>

        <button
          class="btn btn-sm btn-primary mt-2 float-right"
          (click)="uploadSettlement(selectedGVCDateRangeTransaction.length, totalGVCAmount)"
        >
          <i class="fa fa-upload"></i> &nbsp; Create Shopee Settlement
        </button>
      </div>
    </ng-template>

    <ng-template #uploadCreateBilling let-modal>
      <div class="modal-header">
        <h4 class="modal-title">SPX Billing Form</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="card" style="box-shadow: 0 2px 4px 0 #ee4d2d, 0 2px 5px 0 #ee4d2d">
          <div class="row p-5" *ngIf="fileUploadFormGroup" [formGroup]="fileUploadFormGroup">
            <div class="col-lg-6"></div>
            <div class="col-lg-6">
              <div class="row">
                <div class="col-3 d-flex align-items-center justify-content-center">
                  <label>Date Range</label>
                </div>
                <div class="col-9">
                  <input
                    type="text"
                    placeholder="Date Range"
                    class="form-control form-control-sm"
                    bsDaterangepicker
                    formControlName="daterange"
                    style="background-color: white; color: black; height: 37px; border-color: #ee4d2d"
                    [bsConfig]="{
                      rangeInputFormat: 'YYYY-MM-DD',
                      dateInputFormat: 'YYYY-MM-DD',
                      showWeekNumbers: false
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="p-2">
          <table style="width: 100%">
            <tr>
              <th style="text-align: center; color: white; background-color: #ee4d2d">Rider Fullname</th>
              <th style="text-align: center; color: white; background-color: #ee4d2d">Mobile No</th>
              <th style="text-align: center; color: white; background-color: #ee4d2d">SPX Rider ID No.</th>
              <th style="text-align: center; color: white; background-color: #ee4d2d">RequestID</th>
              <th style="text-align: right; color: white; background-color: #ee4d2d">Amount</th>
            </tr>
            <tr *ngFor="let item of selectedDateRangeTransaction">
              <td style="text-align: center">{{ item?.fmsDriverName }}</td>
              <td style="text-align: center">{{ item.mobileNo }}</td>
              <td style="text-align: center">{{ item.lifetimeID }}</td>
              <td style="text-align: center">{{ item.requestId }}</td>
              <td style="text-align: right">{{ item.amount }}</td>
            </tr>

            <tr>
              <td style="text-align: center"></td>
              <td style="text-align: center"></td>
              <td style="text-align: center"></td>
              <td style="text-align: center"></td>
              <td style="text-align: right"></td>
            </tr>
            <tr>
              <td style="text-align: right"></td>
              <td style="text-align: right"></td>
              <td style="text-align: right"></td>
              <td style="text-align: right"><strong>Settlement Count :</strong></td>
              <td style="text-align: right">
                <strong>{{ selectedDateRangeTransaction.length }}</strong>
              </td>
            </tr>
            <tr>
              <td style="text-align: right"></td>
              <td style="text-align: right"></td>
              <td style="text-align: right"></td>
              <td style="text-align: right"><strong>Settlement Total Amount :</strong></td>
              <td style="text-align: right">
                <strong>{{ totalAmount }}</strong>
              </td>
            </tr>
          </table>
        </div>

        <button
          class="btn btn-sm btn-primary mt-2 float-right"
          (click)="uploadBilling(selectedDateRangeTransaction.length, totalAmount)"
        >
          <i class="fa fa-upload"></i> &nbsp; Create Shopee Billing
        </button>
      </div>
    </ng-template>

    <ng-template #spxToGvcEditModal let-modal>
      <div class="modal-header">
        <h4 class="modal-title" style="color: #ee4d2d; font-weight: bolder; font-size: 20px">
          SPX Rider Pay Settlement
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <app-spx-remittance-settlement-edit
          (closeModal)="closeModal()"
          [transaction]="selectedtransaction"
          [remittance]="selectedRemittances"
          (getTransaction)="getTransaction()"
        >
        </app-spx-remittance-settlement-edit>
      </div>
    </ng-template>

    <ng-template #gvcToSpxEditModal let-modal>
      <div class="modal-header">
        <h4 class="modal-title" style="color: #ee4d2d; font-weight: bolder; font-size: 20px">
          GoVIPCenter Rider Pay Settlement
        </h4>
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <app-gvc-spx-settlement-edit
          (closeModal)="closeModal()"
          [transaction]="selectedGVCtransaction"
          [remittance]="selectedGVCRemittances"
          (getTransaction)="getGVCTransaction()"
        >
        </app-gvc-spx-settlement-edit>
      </div>
    </ng-template>
  </div>
</div>
