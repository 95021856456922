import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Console } from "console";
import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { AuthService } from "../services/authentication/auth.service";
import { UserService } from "../services/web/user.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private systemSettings : any;

  constructor(private router: Router, private userService: UserService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // this.userService.getSystemSettings().subscribe((value) => {
    //  this.systemSettings = value.data[0].isHoliday;
    // });
    

    return next.handle(request).pipe(
      tap(
        () => {
          
          // if(this.systemSettings == true){
          //   this.router.navigate(["holiday"]);
          // }
          
        },
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status !== 401) {
              return;
            }
            this.router.navigate(["login"]);
          }
        }
      )
    );
  }
}
