import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserService } from 'src/data/services/web/user.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-sms-sender',
  templateUrl: './sms-sender.component.html',
  styleUrls: ['./sms-sender.component.less']
})
export class SmsSenderComponent implements OnInit {
  @Output("closeModal") closeModal = new EventEmitter();
  @Input("mobileNo") mobileNo: any;
  public smsFormGroup: FormGroup;
  emptyString = "";
  loader : any = false;
  constructor(private userService : UserService) {
    PNotifyButtons;
   }

  ngOnInit(): void {
    this.initSmsFormGroup();
  }

  initSmsFormGroup(){
    this.smsFormGroup = new FormGroup({
      mobileNo: new FormControl(this.mobileNo),
      message: new FormControl(this.emptyString)
    });
  }

  sendSms(){
    this.loader = true;
    this.userService.sendSMSPrivate(this.smsFormGroup.value).subscribe((result) => {
    PNotify.success({
        title: "Information",
        text: "SMS sent successfully.",
      });
      this.loader = false;
      this.closeModal.emit();
    },(error)=>{
      PNotify.error({
        title: "Error",
        text: "SMS unable to sent at this time. Please try again later.",
      });
      this.loader = false;
      this.closeModal.emit();
    });
  }

}
