<app-loader *ngIf="loader"></app-loader>

<body class="layout-row">
  <div class="d-flex flex-column flex">
    <div class="row no-gutters h-100">
      <div
        class="col-md-6 bg-primary"
        style="
          background-image: url('https://juanpaycdn.azureedge.net/juanpayassets/JuanPay-Portrait1.jpg');
          background-size: cover;
          background-position: center;
        "
      >
        <div class="p-3 p-md-5 d-flex flex-column h-100">
          <!-- <h4 class="mb-3 text-white">GO VIP Center</h4>
          <div class="text-fade">The Business of the Current</div> -->
          <div class="d-flex flex align-items-center justify-content-center"></div>

          <div class="d-flex text-sm text-fade">
            <a routerLink="/about" class="text-white">About</a>
            <span class="flex"></span>
            <a routerLink="/terms-and-policies" class="text-white mx-1">Terms and Policies</a>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div id="content-body" class="p-5">
          <h1>Welcome to PIN Code Reset.</h1>
          <h2>Please wait...</h2>
        </div>
      </div>
    </div>
  </div>
  <!-- build:js ../assets/js/site.min.js -->
  <!-- jQuery -->
  <script src="../libs/jquery/dist/jquery.min.js"></script>
  <!-- Bootstrap -->
  <script src="../libs/popper.js/dist/umd/popper.min.js"></script>
  <script src="../libs/bootstrap/dist/js/bootstrap.min.js"></script>
  <!-- ajax page -->
  <script src="../libs/pjax/pjax.min.js"></script>
  <script src="../assets/js/ajax.js"></script>
  <!-- lazyload plugin -->
  <script src="../assets/js/lazyload.config.js"></script>
  <script src="../assets/js/lazyload.js"></script>
  <script src="../assets/js/plugin.js"></script>
  <!-- scrollreveal -->
  <script src="../libs/scrollreveal/dist/scrollreveal.min.js"></script>
  <!-- feathericon -->
  <script src="../libs/feather-icons/dist/feather.min.js"></script>
  <script src="../assets/js/plugins/feathericon.js"></script>
  <!-- theme -->
  <script src="../assets/js/theme.js"></script>
  <script src="../assets/js/utils.js"></script>
  <!-- endbuild -->
</body>
