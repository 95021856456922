import { Component, OnInit } from '@angular/core';
import { TransactionService } from 'src/data/services/web/transaction.service';
import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";
PNotify.defaults.styling = "bootstrap4"; // Bootstrap version 4
PNotify.defaults.icons = "fontawesome5"; // Font Awesome 5

@Component({
  selector: 'app-audit-wallet',
  templateUrl: './audit-wallet.component.html',
  styleUrls: ['./audit-wallet.component.less']
})
export class AuditWalletComponent implements OnInit {

  constructor(private transactionService : TransactionService) {
    PNotifyButtons; }
  audits : any;
  loader : any = true;


  ngOnInit(): void {
    this.getDebitCreditAll();
  }
  getDebitCreditAll(): void {
    this.loader = true;
    this.transactionService.getDebitCreditAll().subscribe((result) => {
     this.audits = result.data;
     
     this.loader = false;
    });
  }

  
  adjustWallet(username : any): void {

    if(confirm(`Are you sure you want to adjust the discrepancy? Audit checker list will reload after adjustment.`)) {
      this.loader = true;
      
      this.transactionService.adjustWallet(username).subscribe((result) => {
        this.loader = false;
        PNotify.success({
          title: "Success",
          text: "Adjustment processed successfully.",
        });
        this.getDebitCreditAll();
      }, error => {
        this.loader = false;
        PNotify.error({
          title: "Error",
          text: "Unable to adjust wallet. Please contact IT Department.",
        });
      });
    }
  }

 
}
