<!-- <app-loader *ngIf="!(users && roles)"></app-loader> -->

<!-- *ngIf="users && roles"w -->
<div id="content" class="flex">
  <!-- ############ Main START-->
  <div>
    <div class="page-hero page-container" id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="text-md text-highlight">List of Sales Director</h2>
          <small class="text-highlight font-weight-normal font-italic">JuanPay list of Sales Director</small>
        </div>
        <div class="flex"></div>
        <!-- <div *ngIf="addCashierStatus">
            <div (click)="createCashierUser()" class="btn btn-md btn-info">
              <span class="d-none d-sm-inline mx-1">Create New</span>
              <i data-feather="arrow-right"></i>
            </div>
          </div> -->
      </div>
    </div>
    <div class="page-content page-container" id="page-content">
      <div class="" style="padding-right: 0px">
        <div class="card mb-2 p-1" style="background-color: #182038">
          <div class="row">
            <div class="col-sm-12 col-md-7"></div>
            <div class="col-sm-12 col-md-5">
              <form class="flex" [formGroup]="listFilterForm">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control form-control-theme form-control-sm search"
                    placeholder="Search..."
                    formControlName="searchString"
                    (keyup.enter)="getSalesDirectorUsers(null, null, null, true)"
                    style="background-color: white; color: black"
                  />
                  <span class="input-group-append">
                    <button
                      class="btn btn-white no-border btn-sm"
                      type="button"
                      (click)="getSalesDirectorUsers(null, null, null, true)"
                      style="background-color: gainsboro"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                  </span>
                </div>
              </form>
            </div>
          </div>

          <!-- List Toolbar Form -->

          <!-- Search Button -->
        </div>

        <div class="card mt-2">
          <table id="datatable" class="table table-striped table-hover" role="grid" aria-describedby="datatable_info">
            <thead>
              <tr>
                <th data-sortable="true" data-field="owner">Avatar</th>

                <th data-sortable="true" data-field="owner">
                  <div>Username</div>
                </th>

                <th data-sortable="true" data-field="owner">
                  <div>Email</div>
                </th>
                <th data-sortable="true" data-field="project">
                  <div>First Name</div>
                </th>
                <th data-sortable="true" data-field="project">
                  <div>Last Name</div>
                </th>

                <th data-field="finish"><span class="d-none d-sm-block">Status</span></th>
                <th data-field="finish"><span class="d-none d-sm-block">Actions</span></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let user of users?.data
                    | paginate: { itemsPerPage: 10, currentPage: p, totalItems: users?.totalRecords }
                "
                style="cursor: pointer"
                class="odd"
                role="row"
              >
                <td>
                  <span>
                    <img class="w-32 avatar" src="https://i.ibb.co/cczMjzp/male.jpg" alt="vip" />
                  </span>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ user?.username }}
                  </div>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ user?.email }}
                  </div>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ user?.salesUser.firstName }}
                  </div>
                </td>
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    {{ user?.salesUser.lastName }}
                  </div>
                </td>

                <td class="flex" style="max-width: 75px">
                  <div class="item-except text-sm h-1x">
                    <label *ngIf="user?.salesUser.status == true" class="ui-switch ui-switch-sm dark mt-1 mr-2">
                      <input type="checkbox" checked disabled />
                      <i></i>
                    </label>

                    <label *ngIf="user?.salesUser.status != true" class="ui-switch ui-switch-sm dark mt-1 mr-2">
                      <input type="checkbox" disabled />
                      <i></i>
                    </label>
                  </div>
                </td>

                <td>
                  <button type="button" class="btn btn-success" (click)="viewSalesUserProfile(user?.id)">
                    <span aria-hidden="true">Edit</span>
                  </button>
                  &nbsp;
                  <button
                    *ngIf="roleLevel == 1"
                    type="button"
                    class="btn btn-primary"
                    (click)="openSendSms(sendSmsModal, user?.contactNumber)"
                  >
                    <span aria-hidden="true">Write Message</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
              <div class="col-sm-12 col-md-12">
                <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                  Showing {{ (users?.pageNumber - 1) * users?.pageSize + 1 }} to
                  {{ (users?.pageNumber - 1) * users?.pageSize + users?.data?.length }} of
                  {{ users?.data?.length }} entries
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-7" style="float: right">
            <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
              <ul class="pagination" style="float: right">
                <pagination-controls
                  (pageChange)="getSalesDirectorUsers(buildPageUrl($event, 10))"
                ></pagination-controls>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<ng-template #sendSmsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">SMS Sender</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <!-- <app-sms-sender [mobileNo]="mobileNo" (closeModal)="closeModal()">
       
      </app-sms-sender> -->
  </div>
</ng-template>
