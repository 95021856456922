<!-- ############ Content START-->
<div id="content" class="flex">
    <!-- ############ Main START-->
    <div>
      <div class="page-hero page-container" id="page-hero">
        <div class="padding d-flex">
          <div class="page-title">
            <h2 class="text-md text-highlight">Enterprise Dashboard</h2>
            <small class="text-highlight font-weight-normal font-italic">Welcome to your Dashboard page!</small>
          </div>
          <!-- <div class="flex"></div> -->
          <!-- <div>
            <a
              href="https://themeforest.net/item/basik-responsive-bootstrap-web-admin-template/23365964"
              class="btn btn-md text-muted"
            >
              <span class="d-none d-sm-inline mx-1">Buy this Item</span>
              <i data-feather="arrow-right"></i>
            </a>
          </div> -->
        </div>
      </div>
      <div class="page-content page-container" id="page-content">
        <div class="padding pt-0">
  
  
    <div class="row row-sm">
  
      
    <!-- ENTERPRISE -->
      
  
      <div class="col-md-12 d-flex">
        <div class="card flex"  >
        <app-chart-report title="Multisys Daily Transaction - ENTERPRISE"  apiRoute="GetEnterpriseTransactions" provider="MULTISYS" isEnterprise=true hideBillers=false  role="1"></app-chart-report>
      </div>
      </div>
  
      <div class="col-md-12 d-flex">
        <div class="card flex"  >
        <app-chart-report title="ECPay Bills Daily Transaction - ENTERPRISE"  apiRoute="GetEnterpriseTransactions" provider="ECPAY-BILLS" isEnterprise=true hideBillers=false   role="1"></app-chart-report>
      </div>
      </div>
  
      <div class="col-md-12 d-flex">
        <div class="card flex"  >
        <app-chart-report title="ECPay Remittance Daily Transaction - ENTERPRISE"  apiRoute="GetEnterpriseTransactions" provider="ECPAY-CASH" isEnterprise=true hideBillers=false   role="1"></app-chart-report>
      </div>
      </div>
  
      <div class="col-md-12 d-flex">
        <div class="card flex"  >
        <app-chart-report title="ECPay Airtime Daily Transaction - ENTERPRISE"  apiRoute="GetEnterpriseTransactions" provider="ECPAY-TOPUP" isEnterprise=true hideBillers=false   role="1"></app-chart-report>
      </div>
      </div>
  
      <div class="col-md-12 d-flex">
        <div class="card flex"  >
        <app-chart-report title="IBAYAD Daily Transaction - ENTERPRISE"  apiRoute="GetEnterpriseTransactions" provider="IBAYAD" isEnterprise=true hideBillers=false   role="1"></app-chart-report>
      </div>
      </div>
  
      
  
      <!-- GOVIPCENTER -->
      
  
       
      </div>
  
        </div>
      </div>
    </div>
    <!-- ############ Main END-->
  
  
  </div>
  <!-- ############ Content END-->
  
  
  
  
  