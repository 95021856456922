<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css" />

<app-loader *ngIf="!inventory"></app-loader>
<app-loader *ngIf="loader"></app-loader>

<div id="content" class="flex" *ngIf="inventory">
  <!-- ############ Main START-->
  <div class="pl-3 pr-3">
    <div id="page-hero">
      <div class="padding d-flex">
        <div class="page-title">
          <h2 class="--spam">Inventory</h2>
          <small class="--desc">JuanPay Store Inventory</small>
        </div>
        <div class="flex"></div>
      </div>
    </div>
    <div id="page-content">
      <div class="card p-2">
        <!-- <div class="card mb-2 p-1" style="background-color: #182038"> -->
        <!-- Filters -->

        <div class="card mb-2 mt-0" style="box-shadow: 0 2px 4px 0 #182038, 0 2px 5px 0 #182038">
          <div class="row" [formGroup]="listFilterForm">
            <table id="datatable" class="table" role="grid" aria-describedby="datatable_info">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>
                    <!-- <button
                          class="btn btn-sm mt-2 button-blue button--search btn-hover font-weight-bold"
                          (click)="createStockItem(createItemModal)">
                          Add Stock Item &nbsp; <i class="fa fa-plus text-highlight"></i>
                      </button> -->

                    <button
                      class="btn btn-sm mt-2 btn-info button--search font-weight-bold"
                      style="float: right"
                      (click)="getInventory(null, null, null, true)"
                    >
                      Search &nbsp; <i class="fa fa-search text-highlight"></i>
                    </button>

                    <!-- <button
                          class="btn btn-sm mr-2 mt-2 btn-success button--create font-weight-bold"
                          *ngIf="roleId == 1"
                          (click)="getManagerUsersForExcel()">
                          Export Data &nbsp; <i class="fa fa-file-export text-highlight"></i>
                      </button> -->

                    <!-- <button
                          class="btn btn-sm mr-2 mt-2 btn-primary button--create font-weight-bold"
                          *ngIf="roleId == 1"
                          (click)="createManagerUser(uploadCreateBilling)">
                          Create Manager &nbsp; <i class="fa fa-plus text-highlight"></i>
                      </button> -->
                  </th>
                  <th style="width: 50px"></th>
                </tr>
              </thead>
              <tbody>
                <tr style="cursor: pointer" class="odd" role="row">
                  <td class="td-title">
                    <label class="label-filter" style="color: #182038; font-weight: bold"> &nbsp;</label>
                  </td>
                  <td class="td-value">&nbsp;</td>

                  <td class="td-title" style="text-align: end; vertical-align: middle">
                    <label class="label-filter" style="color: #182038; font-weight: bold">Search</label>
                  </td>

                  <td class="td-value" style="width: 50%">
                    <input
                      type="text"
                      (keyup.enter)="getInventory(null, null, null, true)"
                      placeholder="Search..."
                      class="form-control w-100"
                      formControlName="searchString"
                      style="background-color: rgb(255, 255, 255); color: black; height: 36px; border-color: #182038"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card mt-2">
          <table id="datatable" class="table table-hover" role="grid" aria-describedby="datatable_info">
            <thead>
              <tr>
                <th>
                  <div class="color-blue">Item Code</div>
                </th>
                <th>
                  <div class="color-blue">Item Name</div>
                </th>

                <th class="color-blue" style="text-align: center">On Hand</th>

                <th class="color-blue" style="text-align: center">Pick Count</th>

                <th class="color-blue" style="text-align: center">Available</th>

                <th class="color-blue" style="text-align: center">Discrepancy</th>

                <th class="color-blue" style="text-align: center">Re-order Level</th>

                <th class="color-blue" style="text-align: center">Critical Level</th>

                <th class="pl-0 pr-0"></th>

                <!-- <th  class="color-blue" data-sortable="true" data-field="project" style="text-align: right;">
                  <div>Unit Price</div>
                </th>
                
                <th class="color-blue" style="text-align: center;"><span class="d-none d-sm-block">Status</span></th> -->
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let item of inventory?.data
                    | paginate: { itemsPerPage: 10, currentPage: p, totalItems: inventory.totalRecords }
                "
                (dblclick)="selectItem(replenishmentModal, item)"
                [ngClass]="{
                  'bg-red-100': item.onHand <= item.reOrderLevel && item.onHand > item.criticalLevel,
                  'bg-red-critical-100': item.onHand <= item.criticalLevel
                }"
                style="cursor: pointer"
                class="odd"
                role="row"
              >
                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    <span style="font-weight: bolder">{{ item?.storeItem.itemCode }}</span>
                  </div>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x">
                    <span style="color: rgb(60, 62, 62); font-weight: bold">{{ item?.storeItem.name }}</span>
                  </div>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x" style="text-align: center">
                    <span style="font-weight: bolder">{{ item?.onHand }}</span>
                  </div>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x" style="text-align: center">
                    <span style="font-weight: bolder">{{ item?.pickCount }}</span>
                  </div>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x" style="text-align: center">
                    <span style="font-weight: bolder">{{ item?.available }}</span>
                  </div>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x" style="text-align: center">
                    <span style="font-weight: bolder">{{ item?.discrepancy }}</span>
                  </div>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x" style="text-align: center">
                    <span style="font-weight: bolder">{{ item?.reOrderLevel }}</span>
                  </div>
                </td>

                <td class="flex">
                  <div class="item-except text-sm h-1x" style="text-align: center">
                    <span style="font-weight: bolder">{{ item?.criticalLevel }}</span>
                  </div>
                </td>

                <td class="text-center pl-0 pr-0">
                  <div class="dropdown">
                    <button id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i class="bi bi-three-dots-vertical"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item text-center" (click)="updateCriticalModal(criticalmodal, item)"
                        >Update level</a
                      >
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-5">
            <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
              <div class="col-sm-12 col-md-12">
                <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">
                  Showing {{ (users?.pageNumber - 1) * users?.pageSize + 1 }} to
                  {{ (users?.pageNumber - 1) * users?.pageSize + users?.data?.length }} of
                  {{ users?.data?.length }} entries
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-7" style="float: right">
            <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
              <ul class="pagination" style="float: right">
                <pagination-controls (pageChange)="getInventory(buildPageUrl($event, 10))"></pagination-controls>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ############ Main END-->
</div>

<ng-template #replenishmentModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{ selectedItem.storeItem.name }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body pt-0 pb-0">
    <div class="card">
      <div class="b-b">
        <div class="nav-active-border b-success top">
          <ul class="nav" id="myTab" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active color-blue font-weight-bold"
                id="home-tab"
                data-toggle="tab"
                href="#home4"
                role="tab"
                aria-controls="home"
                aria-selected="false"
              >
                Replenishment
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link color-red font-weight-bold"
                id="profile-tab"
                data-toggle="tab"
                href="#profile4"
                role="tab"
                aria-controls="profile"
                aria-selected="true"
              >
                Depletion
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="tab-content p-3">
        <div class="tab-pane fade active show" id="home4" role="tabpanel" aria-labelledby="home-tab">
          <app-textbox-number-only
            [parent-form-group]="inventoryFormGroup"
            tab-index-start="0"
            control-name="count"
            read-only="false"
            number-only="true"
            label="Count"
            placeholder="Inventory Count"
          >
          </app-textbox-number-only>

          <hr />

          <button class="color-blue button-blue btn btn-sm float-right text-white" (click)="addStock(selectedItem)">
            Add Stock &nbsp;<i class="fa fa-plus"></i>
          </button>
        </div>
        <div class="tab-pane fade" id="profile4" role="tabpanel" aria-labelledby="profile-tab">
          <app-textbox-number-only
            [parent-form-group]="inventoryFormGroup"
            tab-index-start="0"
            control-name="count"
            read-only="false"
            number-only="true"
            label="Count"
            placeholder="Inventory Count"
          >
          </app-textbox-number-only>

          <app-textarea
            [parent-form-group]="inventoryFormGroup"
            tab-index-start="4"
            control-name="reason"
            read-only="false"
            label="Reason"
            placeholder="Reason"
            max-length="250"
          >
          </app-textarea>

          <hr />

          <button class="color-blue button-red btn btn-sm float-right text-white" (click)="reduceStock(selectedItem)">
            Reduce Stock &nbsp;<i class="fa fa-minus"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #criticalmodal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Update Level</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body pt-0 pb-0">
    <app-textbox-number-only
      [parent-form-group]="inventoryLevelFormGroup"
      tab-index-start="0"
      control-name="reorder"
      read-only="false"
      number-only="true"
      label="Re-order Level"
      placeholder="Re-order Level"
    >
    </app-textbox-number-only>

    <app-textbox-number-only
      [parent-form-group]="inventoryLevelFormGroup"
      tab-index-start="0"
      control-name="critical"
      read-only="false"
      number-only="true"
      label="Critical Level"
      placeholder="Critical Level"
    >
    </app-textbox-number-only>

    <hr />

    <button class="color-blue button-red btn btn-sm float-right text-white mb-2" (click)="updateLevel(selectedItem)">
      Update &nbsp;<i class="fa fa-edit"></i>
    </button>
  </div>
</ng-template>
