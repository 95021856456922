<app-loader *ngIf="loader"></app-loader>
<div class="modal-body" *ngIf="activeTab != 3 && ibayadMeralcoFormGroup">
  <form id=" form" novalidate="">
    <div id="rootwizard">
      <ul class="nav mb-3">
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 1 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >1</span
            >
            <div class="mt-2">
              <div class="text-muted">Meralco</div>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-center" [ngClass]="activeTab == 2 ? 'active' : ''">
            <span class="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-primary"
              >2</span
            >
            <div class="mt-2">
              <div class="text-muted">Confirmation</div>
            </div>
          </a>
        </li>
        <li  class="nav-item" style="margin-left: auto">
          <a class="nav-link text-center" [ngClass]="activeTab == 3 ? 'active' : ''">
            <img src="https://govipcenterstorage.blob.core.windows.net/govipcenterassets/file0501503b-cb8f-4705-8ece-c694860d19c9.png" height="50" class="img-responsive" style="object-fit: cover" />
          </a>
        </li>
      </ul>

      <hr class="bg-dark" />

      <div class="tab-content p-3">
        <div class="tab-pane" [ngClass]="activeTab == 1 ? 'active' : ''" id="selectBillerTab">
          <div class="row">
            <!-- <div class="col-sm-12 col-md-2 d-flex justify-content-center align-items-center">
              <img
                [src]="application.value.thumbnail.logo"
                height="100"
                width="100"
                class="img-responsive"
                style="object-fit: cover"
              />
            </div> -->
            <div class="col-sm-12 col-md-12 d-flex flex-wrap">
              <h4>Meralco</h4>
              <p>
                Meralco celebrates 118 years of service in 2021.  The Company is the largest private sector electric distribution utility company in the Philippines covering 36 cities and 75 municipalities.  Its franchise area of over 9,685 km2  includes the country’s industrial, commercial, and population centers.
              </p>
              
            <hr/>
            </div>
            <div class="col-sm-12 col-md-2"></div>
            <div class="col-sm-12 col-md-8">


          

              <app-textbox
                [parent-form-group]="ibayadMeralcoFormGroup"
                tab-index-start="0"
                control-name="firstName"
                [error]="ibayadMeralcoFormGroup?.controls.firstName.errors"
                read-only="false"
                no-special-char="false"
                label="First Name"
                placeholder="First Name"
                max-length="50"
              >
              </app-textbox>

              <app-textbox
              [parent-form-group]="ibayadMeralcoFormGroup"
              tab-index-start="0"
              control-name="middleInitial"
              [error]="ibayadMeralcoFormGroup?.controls.middleInitial.errors"
              read-only="false"
              no-special-char="false"
              label="Middle Initial"
              placeholder="Middle Initial"
              max-length="50"
            >
            </app-textbox>
            
            
            
            <app-textbox
            [parent-form-group]="ibayadMeralcoFormGroup"
            tab-index-start="0"
            control-name="lastName"
            [error]="ibayadMeralcoFormGroup?.controls.lastName.errors"
            read-only="false"
            no-special-char="false"
            label="Last Name"
            placeholder="Last Name"
            max-length="50"
          ></app-textbox>

          <app-textbox
          [parent-form-group]="ibayadMeralcoFormGroup"
          tab-index-start="0"
          control-name="serviceReference"
          [error]="ibayadMeralcoFormGroup?.controls.serviceReference.errors"
          read-only="false"
          no-special-char="false"
          label="Customer Account Number"
          placeholder="Customer Account Number"
          max-length="50"
        >
        </app-textbox>

          <app-textbox-currency
          [parent-form-group]="ibayadMeralcoFormGroup"
          tab-index-start="0"
          control-name="billAmount"
          [read-only]="roleLevelId == 2"
          [error]="ibayadMeralcoFormGroup?.controls.billAmount?.errors | estatus"
          [error-message]="ibayadMeralcoFormGroup?.controls.billAmount?.errors | econtent"
          read-only="false"
          number-only="false"
          label="Bill Amount"
          placeholder="0.00"
          max-length="20"
        >
        </app-textbox-currency>

          <app-textbox-currency
          [parent-form-group]="ibayadMeralcoFormGroup"
          tab-index-start="0"
          control-name="amount"
          [read-only]="roleLevelId == 2"
          [error]="ibayadMeralcoFormGroup?.controls.amount?.errors | estatus"
          [error-message]="ibayadMeralcoFormGroup?.controls.amount?.errors | econtent"
          read-only="false"
          number-only="false"
          label="Amount"
          placeholder="0.00"
          max-length="20"
        >
        </app-textbox-currency>
         

          
            </div>
            <div class="col-sm-12 col-md-2"></div>
          </div>
        </div>

        <div class="tab-pane" [ngClass]="activeTab == 2 ? 'active' : ''" id="newTransTab">
          <div class="form-group">
            <p>
              <strong>Meralco Confirmation</strong><br />
              <small>Please make sure that the all details is correct.</small>
            </p>
            <div class="card">
              <div class="list list-row">
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Product Name</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">Meralco</div>
                  </div>
                </div>
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">First Name</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ ibayadMeralcoFormGroup?.controls.firstName.value }}
                    </div>
                  </div>
                </div>
                <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date text-muted text-sm d-none d-md-block">Middle Initial</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ ibayadMeralcoFormGroup?.controls.middleInitial.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date text-muted text-sm d-none d-md-block">Last Name</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ ibayadMeralcoFormGroup?.controls.lastName.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date text-muted text-sm d-none d-md-block">Service Reference No.</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ ibayadMeralcoFormGroup?.controls.serviceReference.value }}
                      </div>
                    </div>
                  </div>

                  <div class="list-item" data-id="19">
                    <div class="flex">
                      <div class="item-date text-muted text-sm d-none d-md-block">Bill Amount</div>
                    </div>
                    <div class="no-wrap">
                      <div class="item-feed h-2x">
                        {{ ibayadMeralcoFormGroup?.controls.billAmount.value }}
                      </div>
                    </div>
                  </div>
          
                <hr />
                <div class="list-item" data-id="19">
                  <div class="flex">
                    <div class="item-date text-muted text-sm d-none d-md-block">Total Amount</div>
                  </div>
                  <div class="no-wrap">
                    <div class="item-feed h-2x">
                      {{ ibayadMeralcoFormGroup?.controls.amount.value }}
                    </div>
                  </div>
                </div>
                <hr />
                <div class="col-sm-12 col-md-12">
                  <div class="col-sm-12 col-md-6" style="float: none; margin: auto">
                    <div class="item-feed h-2x text-center">ENTER PINCODE</div>
                    <code-input
                      [isCodeHidden]="true"
                      [codeLength]="6"
                      (codeChanged)="onCodeChanged($event)"
                      (codeCompleted)="onCodeCompleted($event)"
                      [code]="code"
                    >
                    </code-input>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>

        <!-- previous next -->
        <div class="row py-3">
          <div class="col-6">
            <a class="btn btn-white button-previous" (click)="setActiveTabPrev()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-arrow-left"
              >
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
              &nbsp; Previous
            </a>
          </div>
          <div class="col-6">
            <div class="d-flex justify-content-end">
              <a *ngIf="activeTab != 2" class="btn btn-white button-next" (click)="setActiveTab()">
                Next &nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-arrow-right"
                >
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                  <polyline points="12 5 19 12 12 19"></polyline>
                </svg>
              </a>
              <a
                *ngIf="activeTab == 2 && codeStatus"
                appDebounceClick 
                [debounceTime]="500" 
                (debounceClick)="processPayment()"
                class="btn btn-info button-next"
                style="color: white"
              >
                Process Meralco &nbsp;
                <span class="fa fa-hand-pointer"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
